export const VIDEO_LINK_REQUESTED = "VIDEO_LINK_REQUESTED";
export const VIDEO_LINK_RESPONSE = "VIDEO_LINK_RESPONSE";
export const VIDEO_LINK_FAILED = "VIDEO_LINK_FAILED";
export const RESET_LINK = "RESET_LINK";

export const requestVideoLink = (videoReq) => ({
  type: VIDEO_LINK_REQUESTED,
  videoReq,
});

export const responseVideoLink = (videoReq) => ({
  type: VIDEO_LINK_RESPONSE,
  videoReq,
});

export const failedVideoLink = () => ({
  type: VIDEO_LINK_FAILED,
});

export const resetLink = () => ({
  type: RESET_LINK,
});
