export const EVENT_ACTIVITY_REQUEST = "EVENT_ACTIVITY_REQUEST";
export const EVENT_ACTIVITY_RESPONSE = "EVENT_ACTIVITY_RESPONSE";
export const EVENT_ACTIVITY_FAILED = "EVENT_ACTIVITY_FAILED";

export const requestEventActivity = (requestParam) => ({
  type: EVENT_ACTIVITY_REQUEST,
  requestParam,
});

export const eventActivityResponse = (eventActivityMap) => ({
  type: EVENT_ACTIVITY_RESPONSE,
  eventActivityMap,
});

export const eventActivityFailed = () => ({
  type: EVENT_ACTIVITY_FAILED,
});
