import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DesktopIcon,
  DesktopActiveIcon,
  MobileIcon,
  MobileActiveIcon,
} from "../../../app/commons/Icons";
import {
  isAnnoucementDesktopType,
  getProjectNameByKey,
  getSelectedCampaignMessage,
  getStyleOfPreviewTyping,
} from "../../../app/commons/Utility.js";
import { updatePreviewType } from "../../actions/BroadcastMessageStatsAction";

const AnnouncementQuickPreview = (props) => {
  const { color } = props;
  const [projectName, setProjectName] = useState("");
  const [isHTMLTemplate, setisHTMLTemplate] = useState(false);
  const { campaignDetails, projects, popupDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    projects: state.ProjectReducer,
    popupDetails: state.PopupReducer,
  }));
  const miniNavBar = isAnnoucementDesktopType(campaignDetails);
  const dispatch = useDispatch();
  const { isPopupPreview } = props;
  // const campaignFromImageRef = useRef("");
  const togglePreviewType = (previewType) => {
    console.log("toggling preview type:", previewType);
    dispatch(updatePreviewType(previewType));
  };

  // useEffect(() => {
  //   campaignFromImageRef.current.style = {};
  // }, [campaignDetails.selectedCampaign.campaignFromImage]);

  useEffect(() => {
    if (projects.isFetched) {
      let projectName = getProjectNameByKey(
        `LS-${props.params.projectkey}`,
        projects.projectList
      );
      setProjectName(projectName);
    }
  }, [projects.isFetched]);

  useEffect(() => {
    let { isHTMLTemplateEnabled } = campaignDetails.selectedCampaign;
    if (isHTMLTemplateEnabled && !popupDetails.popupProps.display)
      setisHTMLTemplate(isHTMLTemplateEnabled);
  }, [popupDetails.popupProps]);

  const getPreviewHtml = () => {
    let message = getSelectedCampaignMessage(campaignDetails.selectedCampaign);
    let replacedMessage = message.replace(
      "+_add_content",
      `<p>...</p>
      `
    );
    replacedMessage = replacedMessage.replace(
      "+_from_",
      `<span
       style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;color:#181818"
        >
       
        From
      </span>`
    );
    replacedMessage = replacedMessage.replace("+_sender_info", "");
    replacedMessage = getProperReplacedFallbackValues(replacedMessage);
    return { __html: replacedMessage };
  };

  const imageError = (e) => {
    e.target.style.display = "none";
  };

  const showImage = (e) => {
    e.target.style.display = "block";
  };

  const getStaffDetails = () => {
    let staff = props.randomStaffData() || {};
    if (props.showFallbackValues) {
      return {
        "{{firstName}}": staff.firstName || "Richard",
        "{{lastName}}": staff.lastName || "Tyler",
        "{{fullName}}": staff.fullName || "Richard Tyler",
        "{{companyName}}":
          campaignDetails.selectedCampaign.projectName || "CES Consulting",
      };
    } else {
      return {
        "{{firstName}}": "First Name",
        "{{lastName}}": "Last Name",
        "{{fullName}}": "Full Name",
        "{{companyName}}": "Company Name",
      };
    }
  };

  const getProperReplacedFallbackValues = (message) => {
    let fallbackStaffData = getStaffDetails();
    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `${fallbackStaffData[match]}`;
      }
    );

    return message;
  };

  let { campaignFromImage, campaignFromName, isInteractableDisabled } = campaignDetails.selectedCampaign;

  return (
    <section
      className={
        "cs-campaign-preview quick-preview cs-announcment-preview " +
        (miniNavBar ? "desktop " : "")+ (isHTMLTemplate ? "iframe" : "")
      }
    >
      <code>
        <i
          id="desktop_icon"
          className={miniNavBar ? "active" : ""}
          onClick={() => {
            togglePreviewType("Desktop");
          }}
        >
          <DesktopIcon />
          <DesktopActiveIcon />
        </i>
        <i
          id="mobile_icon"
          className={miniNavBar ? "" : "active"}
          onClick={() => {
            togglePreviewType("Mobile");
          }}
        >
          <MobileIcon />
          <MobileActiveIcon />
        </i>
      </code>
      <div>
        <header>
          <h2>
            We're
            <br />
            happy to chat<span>.</span>
          </h2>
          <code>&times;</code>
        </header>
        <div className="widget-camp-cont">
          <section>
            <div style={{ backgroundColor: "#ffffff" }}>
              <cite>&times;</cite>
              {isHTMLTemplate ? (
                <iframe srcDoc={getPreviewHtml().__html} />
              ) : (
                <React.Fragment>
                  <div
                    id="announcement_content"
                    dangerouslySetInnerHTML={getPreviewHtml()}
                  />
                  <div className="preview-sender">
                    <figure>
                      <img
                        id="sender_image"
                        onError={imageError}
                        onLoad={showImage}
                        src={
                          campaignFromImage ||
                          props.agentImage
                        }
                      />
                    </figure>
                    <label id="sender_name">
                      {campaignFromName ||
                        props.agentName ||
                        "Agent Name"}{" "}
                    </label>
                    <span id="project_name">
                      {campaignDetails.selectedCampaign.projectName ||
                        projectName}
                    </span>
                  </div>
                </React.Fragment>
              )}
            </div>
            <div
              className="prview-typing"
              style={getStyleOfPreviewTyping(isInteractableDisabled)}
            >
              <i></i>
              <i
                id="widget_color"
                style={{ backgroundColor: color ? `#${color}` : "#4684ff" }}
              ></i>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default AnnouncementQuickPreview;
