import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { Button } from 'src/features/form/components/Button'
import { IProps } from 'src/features/returningFlow/components/Wizard/steps/3/Preview/types'
import { ContainerButtons } from 'src/features/returningFlow/components/Wizard/styled'
import { Link2, SubTitle, Text1 } from 'src/features/ui/components/Typography'

export const WidgetSetupPreview: FC<IProps> = ({ showContent, skipStep }) => (
  <>
    <SubTitle bolderer>Personalize your widget.</SubTitle>
    <Margin top={4} />
    <Text1 color="raven">
      Customize your widget to maximize engagement and offer an on-brand
      experience with every chat.
    </Text1>
    <Margin top={24} />
    <ContainerButtons maxWidth={34} hasNoMargin>
      <Button width={16.8} type="button" color="primary" onClick={showContent}>
        Let’s do it
      </Button>
      <Margin left={32} />
      <Link2 hasPointerCursor onClick={skipStep} color="raven" underline>
        Not now, I’ll do it later.
      </Link2>
    </ContainerButtons>
  </>
)
