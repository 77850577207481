import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import SeenSegments from './SeenSegments.jsx';
import {getProjectId} from '../../commons/Utility.js'
import {getMatchingSegmentsRequest} from '../../actions/PeopleAction.js'
import { useSelector } from "react-redux";

const SeenSegmentsContainer = (props) => {
    const {projectkey, visitorId} = props;
    const [segments, setSegments] = useState([]);
    const projectId = getProjectId(projectkey);
    const dispatch = useDispatch();
    const { peopleReducer } = useSelector((state) => ({
        peopleReducer : state.PeopleReducer
      }));
    useEffect(() => {
        if(!peopleReducer.isFetchingMatchingSegments && peopleReducer['matchingSegments'][visitorId])
            setSegments(peopleReducer['matchingSegments'][visitorId]);
    }, [peopleReducer.isFetchingMatchingSegments]); 
    useEffect(() => {
        if(!peopleReducer['matchingSegments'][visitorId] && !peopleReducer.isFetchingMatchingSegments)
            dispatch(getMatchingSegmentsRequest(visitorId, projectId));
    });
    return (
        <SeenSegments segments={segments}/>
    )
}

export default SeenSegmentsContainer;