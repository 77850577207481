import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Button } from 'src/features/form/components/Button'
import { mq } from 'src/features/ui/styles/mq'

export const StyledButton = styled(Button)<{ noLeftMargin?: boolean }>`
  padding: 1.2rem 5.5rem;

  ${mq.sm} {
    width: 26.8rem;
    margin-left: 4.5rem;

    ${is('noLeftMargin')`
      margin-left: 0;
    `}
  }
`

export const ContainerButtons = styled.div<{
  maxWidth?: number
  hasNoMargin?: boolean
  marginTop?: number
}>`
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: space-between;

  ${is('hasNoMargin')`
    margin-top: 0;
  `}

  ${is('maxWidth')`
    max-width: ${R.prop('maxWidth')}rem;
  `}

  ${mq.sm} {
    margin-top: 8rem;
    max-width: 46.8rem;

    ${is('marginTop')`
      margin-top: ${R.prop('marginTop')}rem;
    `}

    ${is('hasNoMargin')`
      margin-top: 0;
    `}
  }
`

export const FormContent = styled.div`
  max-width: 46.8rem;
`
