import React, { FC } from 'react'
import { connect } from 'react-redux'
import { WidgetIcon } from 'src/features/wizard/setup/components/WidgetIcon'
import { IProps } from 'src/features/wizard/setup/components/WidgetIconPreview/types'
import { selectIcon } from 'src/features/wizard/setup/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const WidgetIconPreviewComponent: FC<IProps> = ({
  icon: { color, url },
}): JSX.Element => (
  <WidgetIcon color={color} imageUrl={url} width="6rem" height="6rem" />
)

const mapStateToProps = (state: IAppState) => ({
  icon: selectIcon(state.setup),
})

export const WidgetIconPreview = connect(mapStateToProps)(
  WidgetIconPreviewComponent
)
