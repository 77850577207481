import { connect } from 'formik'
import { FormikContextType } from 'formik/dist/types'
import { ComponentType, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IFormValues } from 'src/features/wizard/install/forms/EmailInstructions/data'
import { syncEmailInstructions } from 'src/features/wizard/install/store/actions'

const SyncInstructionsComponent = ({
  formik,
  id,
}: {
  formik: FormikContextType<IFormValues>
  id: number
}) => {
  const { values } = formik
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(syncEmailInstructions(id, values.email))
  }, [values.email])

  return null
}

export const SyncInstructions = connect(
  SyncInstructionsComponent
) as ComponentType<{ id: number }>
