import * as R from 'ramda'
import styled from 'styled-components'

export const Container = styled.header`
  max-width: 76.8rem;
  box-sizing: border-box;
  background-color: ${R.path(['theme', 'color', 'white'])};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4rem 10rem;
`
