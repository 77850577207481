import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router";
import { ACTIVE, EMPTY_STRING } from "../../commons/Constants.js";
import { getMyProfilePageUrl, getWorkingHoursUrl } from "../../commons/Utility.js";
import { MyProfileComponentLoader } from "../commons/loaders.js";
import WorkingHoursContainer from "./WorkingHoursContainer.js";

const MyProfileComponent = (props) => {
  const phoneNumbers = props.getPhoneNumbers();
  const showPhone = phoneNumbers.length > 0 ? "Phone" : "";
  const [isWorkingHoursPage, setIsWorkingHoursPage] = useState(false);

  useEffect(() => {
    if (document.location.pathname.includes("workinghours")) {
      setIsWorkingHoursPage(true);
    } else {
      setIsWorkingHoursPage(false);
    }
  });
  const getProfileDetails = () => {
    if (props && props.emailId)
      return (
        <div>
          <label>Email</label>
          <p>{props.emailId}</p>
          {props.getEmailIds()}
          <label>{showPhone}</label>
          {phoneNumbers}
        </div>
      );
    return <MyProfileComponentLoader />;
  };
  return (
    <ul class="tab-nav">
      <li class={!isWorkingHoursPage ? ACTIVE : EMPTY_STRING}>
        <Link to={getMyProfilePageUrl(props.projectKey)}>Profile</Link>
        {getProfileDetails()}
      </li>
      <li className={isWorkingHoursPage ? ACTIVE : EMPTY_STRING}>
        <Link to={getWorkingHoursUrl(props.projectKey)}>Working hours</Link>
        <WorkingHoursContainer
          displayText="Working hours"
          projectKey={props.projectKey}
          userId={props.userId}
          accountId={props.accountId}
        />
      </li>
    </ul>
  );
};

export default MyProfileComponent;
