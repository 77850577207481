import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { ErrorMessage } from 'src/features/form/components/ErrorMessage'
import { Container } from 'src/features/wizard/install/components/Email/EmailSingle/styled'
import {
  IOwnProps,
  IProps,
} from 'src/features/wizard/install/components/Email/EmailSingle/types'
import { EmailInstructions } from 'src/features/wizard/install/forms/EmailInstructions'
import { IFormValues } from 'src/features/wizard/install/forms/EmailInstructions/data'
import {
  clearEmailInstructions,
  requestEmailInstructions,
} from 'src/features/wizard/install/store/actions'
import {
  selectDidSendEmailById,
  selectEmailingErrorById,
  selectIsEmailingById,
} from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const EmailSingle: FC<IProps> = ({
  error,
  id,
  isEmailing,
  emailInstructions,
  hasFinished,
  clearInstructions,
}) => {
  useEffect(() => clearInstructions, [])

  const handleSubmit = (values: IFormValues) =>
    emailInstructions(id, values.email)

  return (
    <Container>
      <EmailInstructions
        id={id}
        handleSubmit={handleSubmit}
        isLoading={isEmailing}
        hasFinished={!error && hasFinished}
      />
      {error && <ErrorMessage message={error} />}
    </Container>
  )
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => ({
  error: selectEmailingErrorById(ownProps.id)(state.install),
  isEmailing: selectIsEmailingById(ownProps.id)(state.install),
  hasFinished: selectDidSendEmailById(ownProps.id)(state.install),
})

const mapDispatchToProps = {
  emailInstructions: requestEmailInstructions,
  clearInstructions: clearEmailInstructions,
}

export const EmailInstructionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailSingle)
