export const HANDLE_ERROR = "HANDLE_ERROR";
export const MAIL_ERROR = "MAIL_ERROR";
export const ADD_ERROR_STACK = "ADD_ERROR_STACK";

export const handleGlobalError = (error) => ({
  type: HANDLE_ERROR,
  error,
});
export const mailGlobalError = (error) => ({
  type: MAIL_ERROR,
  error,
});
export const addStackTrace = (error) => ({
  type: ADD_ERROR_STACK,
  error,
});
