import React, {useState, useEffect} from "react";
import { CustomerEmptyIcon } from '../../commons/Icons'
import {TYPE_OF_CAMPAIGN_STATS } from '../../commons/Constants'

const NoCustomersLoader = (props) => {
    const [zeroStats, setZeroStats] = useState(false);
    const [typeOfStats, setTypeOfStats] = useState("");
    const [campaignStatsType, setCampaignStatsType] = useState("");
    useEffect(() => {
        if (props.detailedStatsLoader) {
            setZeroStats(true);
            setTypeOfStats(props.typeOfStats);
            setCampaignStatsType(props.campaignStatsType.toLowerCase());
        }
    }, [])
    const getInformationToDisplay = () => {
        if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.DELIVERED)
            return `Your ${campaignStatsType} hasn’t been sent to anybody yet`;
        else if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.OPENED)
            return `Nobody has opened your ${campaignStatsType} yet`;
        else if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.CLICKED)
            return `Nobody has clicked a link in your ${campaignStatsType} yet`;
        else if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.REPLIED)
            return `Nobody has interacted with your ${campaignStatsType} yet`;
        else if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.NOT_DELIVERED)
            return `Mail was successfully delivered to all of the intended recipients`;
    }
    return (
        <section class="placeholder-wrp">
            <div class={"placeholder-content " + (zeroStats ? "zero-stats" : "")}>
                <CustomerEmptyIcon />
                {!zeroStats ? 
                <><label>No customers to display</label><p>View customer information and keep on top of customer engagement.</p></>
                    : <><label>No Information to Display</label><p>{ getInformationToDisplay()}</p></>}
            </div>
        </section>
    )
}

export default NoCustomersLoader;