import {
  USER_FETCH_REQUESTED,
  USER_FETCH_RESPONSE,
  USER_FETCH_FAILED,
  USER_UPDATE_REQUESTED,
  USER_UPDATE_RESPONSE,
  USER_UPDATE_FAILED,
  UPDATE_SELECTED_PROJECT_KEY,
  UPDATE_TIME_DIFFERENCE,
  ON_TAB_FOCUS,
  ON_TAB_BLUR,
  UPDATE_DEFAULT_PROJECT_KEY,
  UPDATE_LOCATION_DETAILS,
  STAFF_UPDATE_FAILED,
  STAFF_UPDATE_RESPONSE,
  STAFF_UPDATE_REQUESTED,
  ACKNOWLEDGE_PLAN_UPGRADE,
  TOGGLE_PLAN_UPGRADE_POPUP,
  UPDATE_USER_MODE,
  UPDATE_SB_WINDOW_STATUS,
} from "../actions/UserAction";
import { INTERNAL_ACCOUNTS } from "../commons/Constants";

export const initialState = {
  isFetching: false,
  isFetched: false,
  isAuthencationFailed: false,
  data: undefined,
  isInternalUser: undefined,
  selectedProjectKey: "",
  isActiveOnTab: "",
  isSBFocused: true,
  timeDifference: 0,
  isUpdating: false,
  userRole: "",
  planType: undefined,
  planAcknowledged: false,
  showUpgradePopup: false,
  oldUserPermission: "",
  isFullScreenMode: false,
  fullscreenQuery: {},
  connectAuthId: undefined
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_FETCH_REQUESTED:
      return {
        ...state,
        data: action.user,
        isFetched: false,
        isFetching: true,
      };
    case USER_FETCH_RESPONSE:
      let userRole = state.userRole;
      let oldUserPermission = state.oldUserPermission;
      let { connectAuthId } = state;
      if (action.user.photoID) {
        action.user.photoID = action.user.photoID + "?=" + new Date().getTime();
        console.info("photoID:" + action.user.photoID);
      }

      if (action.user.hasOwnProperty("userRole") && !!action.user.userRole)
        userRole = action.user.userRole;

      if (
        action.user.hasOwnProperty("oldUserPermission") &&
        !!action.user.oldUserPermission
      )
        oldUserPermission = action.user.oldUserPermission;
      if (action.user.hasOwnProperty("connectAuthId"))
        connectAuthId = action.user.connectAuthId;

      return {
        ...state,
        data: action.user,
        userRole: userRole,
        oldUserPermission: oldUserPermission,
        planType: action.user.planType,
        isFetched: true,
        isFetching: false,
        isInternalUser:
          action.user &&
          action.user.accountID &&
          INTERNAL_ACCOUNTS.includes(action.user.accountID)
            ? true
            : false,
        connectAuthId: connectAuthId
      };
    case USER_FETCH_FAILED:
      return {
        ...state,
        data: undefined,
        isFetched: false,
        isFetching: false,
      };
    case USER_UPDATE_REQUESTED:
      return {
        ...state,
        isUpdating: true,
      };
    case USER_UPDATE_RESPONSE:
      if (action.user.photoID) {
        action.user.photoID = action.user.photoID + "?=" + new Date().getTime();
        console.info("photoID:" + action.user.photoID);
      }

      return {
        ...state,
        data: action.user,
        isUpdating: false,
      };
    case UPDATE_TIME_DIFFERENCE:
      return {
        ...state,
        timeDifference: action.timeDifference,
      };
    case UPDATE_LOCATION_DETAILS:
      return {
        ...state,
        locationDetails: action.locationDetails,
      };
    case UPDATE_SELECTED_PROJECT_KEY:
      return {
        ...state,
        selectedProjectKey: action.projectKey,
      };
    case UPDATE_DEFAULT_PROJECT_KEY:
      return {
        ...state,
        defaultProjectKey: action.projectKey,
      };
    case ON_TAB_FOCUS: {
      return {
        ...state,
        isActiveOnTab: true,
      };
    }

    case ON_TAB_BLUR: {
      return {
        ...state,
        isActiveOnTab: false,
      };
    }
    case STAFF_UPDATE_REQUESTED:
      return {
        ...state,
        isUpdating: true,
      };
    case STAFF_UPDATE_RESPONSE:
      return {
        ...state,
        isUpdating: false,
      };
    case STAFF_UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case ACKNOWLEDGE_PLAN_UPGRADE:
      return {
        ...state,
        planAcknowledged: true,
      };
    case TOGGLE_PLAN_UPGRADE_POPUP:
      return {
        ...state,
        showUpgradePopup: !state.showUpgradePopup,
      };
    case UPDATE_USER_MODE:
      return {
        ...state,
        isFullScreenMode: action.isFullScreenMode,
        fullscreenQuery: action.fullscreenQuery
      };
    case UPDATE_SB_WINDOW_STATUS:
      return {
        ...state,
        isSBFocused: action.isSBFocused,
      }
  }
  return state;
};

export default UserReducer;
