import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

import { TeamDropDownIcon, TickIconBlue } from "../../commons/Icons";

const DisconnectTimerComponent = (props) => {
  const inputRef = useRef(null);
  const [showInputField, setShowInputField] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { urls } = props;
  const [showTimerDropDown, setShowTimerDropDown] = useState(false);

  useEffect(() => {
    if (props.isUpdated) {
      props.setUpdated(false);
    }
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClickInComponent);
    return () => {
      document.removeEventListener("mousedown", handleClickInComponent);
    };
  });

  const handleClickInComponent = (e) => {
    if (e.target.dataset.elementname == "timer") {
      let time = e.target.dataset.value;
      props.updateDisconnectTimer(time);
      setShowTimerDropDown(false);
    } else if (e.target.dataset.elementname == "selected-timer") {
      setShowTimerDropDown(!showTimerDropDown);
    } else if (showTimerDropDown == true) setShowTimerDropDown(false);
  };

  const selectDropDownElement = (e) => {};

  const toggleDropDown = () => {};

  const getDisplayTime = (time) => {
    let timeTextMap = {};
    timeTextMap[0] = ["No Timer Set"];
    timeTextMap[45] = ["45 Seconds"];
    timeTextMap[60] = ["1 Minute"];
    timeTextMap[90] = ["1 Minute 30 Seconds"];
    timeTextMap[120] = ["2 Minutes"];
    timeTextMap[150] = ["2 Minutes 30 Seconds"];
    timeTextMap[180] = ["3 Minutes"];
    timeTextMap[210] = ["3 Minutes 30 Seconds"];
    timeTextMap[240] = ["4 Minutes"];
    timeTextMap[270] = ["4 Minutes 30 Seconds"];
    timeTextMap[300] = ["5 Minutes"];
    timeTextMap[600] = ["10 Minutes"];

    if (timeTextMap.hasOwnProperty(time)) return timeTextMap[time];

    return timeTextMap[180];
  };

  return (
    <section>
      <div class="cs-widget-config cs-business-hrs">
        <div>
          <label>Chat Disconnect Timer</label>
          <p>
            Disconnect the chat if unresponsive after a certain amount of time.
          </p>
        </div>
        <div class={"cs-dropdwn" + (showTimerDropDown ? " open" : "")}>
          <p data-elementname="selected-timer" onClick={toggleDropDown}>
            {getDisplayTime(props.currentTime)}
          </p>
          <TeamDropDownIcon />

          <div>
            <ul>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="0"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  No Timer Set
                </a>
              </li>
              {/* <li><a  data-elementtype="dropdown" data-elementname="timer"  data-value="45" onClick={selectDropDownElement} href="javascript:void(0)" >45 Seconds</a></li> */}
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="60"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  1 Minute
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="90"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  1 Minute 30 Seconds
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="120"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  2 Minutes
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="150"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  2 Minutes 30 Seconds
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="180"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  3 Minutes
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="210"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  3 Minutes 30 Seconds
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="240"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  4 Minutes
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="270"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  4 Minutes 30 Seconds
                </a>
              </li>
              <li>
                <a
                  data-elementtype="dropdown"
                  data-elementname="timer"
                  data-value="300"
                  onClick={selectDropDownElement}
                  href="javascript:void(0)"
                >
                  5 Minutes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DisconnectTimerComponent;
