import * as R from 'ramda'

export const addListItem = <TItem>(value: TItem, list: TItem[]) =>
  R.append<TItem>(value)(list)

export const removeListItem = <TItem>(value: TItem, list: TItem[]) =>
  R.without<TItem>([value])(list)

export const toggleListItem = <TItem>(value: TItem, list: TItem[]) =>
  R.ifElse(
    R.contains(value),
    R.without<TItem>([value]),
    R.append<TItem>(value)
  )(list) as TItem[]

export const upsertListItemById = <TItem extends { id: number }>(
  id: number,
  item: Optional<TItem>
) => (list: TItem[]) => {
  const index = R.findIndex<TItem>(R.propEq<string>('id', id), list)
  const currentItem = list[index]

  if (index === -1) {
    return [
      ...list,
      {
        ...currentItem,
        ...item,
      },
    ]
  }

  return R.update(
    index,
    {
      ...currentItem,
      ...item,
    },
    list
  )
}

export const updateAtIndex = <TItem>(
  targetIndex: number,
  updates: Optional<TItem>
) => (list: TItem[]) => {
  const currentItem = list[targetIndex]

  return R.update<TItem>(targetIndex, {
    ...currentItem,
    ...updates,
  })(list)
}
