import React, { useState, useEffect } from "react";
import SurveyFormComponent from "./SurveyFormComponent.jsx";
import { Link } from "react-router";
import ProactiveChatContainer from "./ProactiveChatContainer.js";
import * as util from "../../commons/Utility.js";
import { useSelector } from "react-redux";
import WidgetVisibilityContainer from "./WidgetVisibilityContainer";
import DisconnectTimerContainer from "./DisconnectTimerContainer";
import { getUser } from "../../actions/UserAction.js";
import {  useDispatch } from "react-redux";

const WidgetBehaviourContainer = (props) => {
  const [activePage, setActivePage] = useState("prompts");
  const dispatch = useDispatch();
  let { user, staffReducer } = useSelector((state) => ({
    user: state.UserReducer,
    staffReducer: state.StaffReducer,
  }));

  useEffect(() => {
    let {
      location: { pathname },
    } = props;
    if (pathname.includes("prompts")) setActivePage("prompts");
    else if (pathname.includes("surveyform")) setActivePage("surveyform");
    else if (pathname.includes("visibility")) setActivePage("whitelist");
    else setActivePage("disconnecttimer");
  }, []);

  useEffect(() => {
    if(!user.isFetched)
      dispatch(getUser());
  } , [])

  const isValidUser = () => {
    let staffMap = staffReducer.dashboardAgents
      ? staffReducer.dashboardAgents[util.getProjectId(props.params.projectkey)]
      : {};
    if (staffMap && user.data && user.data.id) {
      let currentStaff = staffMap[user.data.id];
      let currentStaffPermission = currentStaff.userPermission
        ? currentStaff.userPermission
        : "RESTRICTED";

      return currentStaffPermission === "FULL_ACCESS";
    }
    return false;
  };

  const getDisplayComponent = () => {
    if (activePage === "prompts")
      return <ProactiveChatContainer projectKey={props.params.projectkey} />;
    else if (activePage === "surveyform")
      return (
        <SurveyFormComponent
          user={user}
          isValidUser={isValidUser}
          projectKey={props.params.projectkey}
        />
      );
    else if (activePage === "whitelist")
      return <WidgetVisibilityContainer projectKey={props.params.projectkey} />;
    else if (activePage === "disconnecttimer")
      return <DisconnectTimerContainer projectKey={props.params.projectkey} />;
  };

  return (
    <div className="cs-settings-wrp">
      <section className="widget-configuration">
        <h3>Widget Behaviour</h3>
        <p>Configure widget behavior for improved visitor engagement</p>
        <ul class="tab-nav">
          <li class={activePage === "prompts" ? "active" : ""}>
            <Link
              onClick={() => setActivePage("prompts")}
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/behaviour/prompts"
              }
            >
              Proactive Chat
            </Link>
          </li>
          <li class={activePage === "surveyform" ? "active" : ""}>
            <Link
              onClick={() => setActivePage("surveyform")}
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/behaviour/surveyform"
              }
            >
              Survey Forms
            </Link>
          </li>
          <li class={activePage === "whitelist" ? "active" : ""}>
            <Link
              onClick={() => setActivePage("whitelist")}
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/behaviour/visibility"
              }
            >
              Widget Visibility
            </Link>
          </li>
          <li class={activePage === "disconnecttimer" ? "active" : ""}>
            <Link
              onClick={() => setActivePage("disconnecttimer")}
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/behaviour/disconnecttimer"
              }
            >
              Disconnect Timer
            </Link>
          </li>
        </ul>
        {getDisplayComponent()}
      </section>
    </div>
  );
};
export default WidgetBehaviourContainer;
