import React from "react";
import {columnAlise} from '../../commons/PeopleESIndex.js'
import { getFormattedValue, getRelativeDate } from '../commons/ContactsUtility.js';

const Info = (props) => {
    const {people={}, customFields={}} = props;
    const defaults = ['firstName', 'lastName', 'email', 'phoneNumber', 'country', 'createdDate', 'lastSeenTime']
    return (
        <div className="info-detail">
            <div>
                <label>Email</label>
                <p>{people.email || '-'}</p>
            </div>
            <div>
                <label>Phone Number</label>
                <p>{people.phoneNumber || '-'}</p>  
            </div>       
            <div>
                <label>Technology</label>
                <p>Web</p>
            </div>   
            <div>
                <label>Country</label>
                <p>{people.country || '-'}</p>
            </div>
            <div>
            <label>No. of Visits</label>
            <p>{people.totalVisit || '-'}</p>
            </div>
            <div>
            <label>First Seen</label>
            <p>{people.createdDate ? getRelativeDate(people.createdDate) : '-'}</p>
            </div>
            <div>
            <label>Last Seen</label>
            <p>{people.lastSeenTime ? getRelativeDate(people.lastSeenTime) : '-'}</p>
            </div>
            {
                Object.keys(people).map(property=>{
                    if(people[property] && !defaults.includes(property)
                         && !property.startsWith("EF-") && !property.startsWith("CF-")
                            && columnAlise[property]) {
                        return (
                            <div>
                                <label>{columnAlise[property]}</label>
                                <p>{getFormattedValue(property, people)}</p>
                            </div>
                        )
                    }
                })
            }
            {
                Object.keys(customFields).map(customFieldKey=>{
                    if(customFields[customFieldKey]) {
                        let formattedValue = getFormattedValue(customFieldKey, people, customFields);
                        return (
                            <div>
                                <label>{customFields[customFieldKey].name}</label>
                                <p>{ formattedValue || '-'}</p>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default Info;