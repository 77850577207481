import {
  IWidgetConfigData,
  WidgetInstallationStatus,
} from 'src/features/wizard/setup/api/types'
import { IFormValues } from 'src/features/wizard/setup/forms/Setup/data'

export const denormalizeSetupValuesToWidgetData = (
  values: IFormValues,
  currentData: IWidgetConfigData
): IWidgetConfigData => ({
  ...currentData,
  chatWidgetIconURL: values.icon.url === '' ? 'DEFAULT' : values.icon.url,
  widgetPosition: values.position,
  widgetType: values.widgetType,
  widgetThemeColor: values.icon.color?.replace('#', ''),
})

export const denormalizeWebScrapToWidgetData = (
  status: WidgetInstallationStatus,
  domainName: string,
  currentData: IWidgetConfigData
): IWidgetConfigData => ({
  ...currentData,
  installationStatus: status,
  domainName,
})
