import React from 'react'
import {
  Content,
  Layout,
  Left,
  Right,
} from 'src/features/ui/components/Layout/styled'
import { WidgetInstallFull } from 'src/features/wizard/install/components/WidgetInstallFull'
import { WebsitePreview } from 'src/features/wizard/setup/components/WebsitePreview'
import { WizardHeader } from 'src/features/wizard/shared/components/WizardHeader'
import { WebsitePreviewContainer } from 'src/pages/WidgetSetup/styled'

export const WidgetInstallationPage = () => (
  <Layout>
    <WizardHeader currentStep={2} currentStepMobile={2} />
    <Content>
      <Left>
        <WidgetInstallFull />
      </Left>
      <Right>
        <WebsitePreviewContainer>
          <WebsitePreview />
        </WebsitePreviewContainer>
      </Right>
    </Content>
  </Layout>
)
