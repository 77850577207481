import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Notifications from "../components/common/Notifications.jsx";
import * as NotificationActions from "../actions/NotificationAction";

@connect((state) => ({ notificationInfo: state.NotificationReducer }))
class NotificationsContainer extends Component {
  // static propTypes = {

  // 	notificationInfo : PropTypes.object.isRequired
  // }
  constructor() {
    super();

    this.closeNotification = this.closeNotification.bind(this);
    this.dismissNotification = this.dismissNotification.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.hasReceivedNewNotification(newProps)) {
      let newNotification =
        newProps.notificationInfo.notifications[
          newProps.notificationInfo.notifications.length - 1
        ];

      setTimeout(() => {
        this.dismissNotification(newNotification.notificationId);
      }, newNotification.dismissAfter);
    }
  }

  closeNotification(event) {
    event.persist();

    let notificationId = event.target.parentNode.id;

    this.props.dispatch(NotificationActions.removeNotification(notificationId));
  }

  dismissNotification(notificationId) {
    let notificationIndex = this.props.notificationInfo.notifications.findIndex(
      (notification) => {
        return notification.notificationId == notificationId;
      }
    );

    if (notificationIndex > -1) {
      this.props.dispatch(
        NotificationActions.removeNotification(notificationId)
      );
    }
  }

  hasReceivedNewNotification(newProps) {
    let hasReceivedNewNotification = false;

    if (
      newProps.notificationInfo.notifications.length >
      this.props.notificationInfo.notifications.length
    ) {
      hasReceivedNewNotification = true;
    }

    return hasReceivedNewNotification;
  }

  render() {
    return (
      <Notifications
        ref={(component) => (this.child = component)}
        closeNotification={this.closeNotification}
        notifications={this.props.notificationInfo.notifications}
        progress={this.props.notificationInfo.progress}
      />
    );
  }
}

export default NotificationsContainer;
