import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router";

class ManageEmailOptOut extends Component {
  render() {
    console.info("Manage Email Props:", this.props);
    let {
      localState: {
        emailOptOutPreferences: { unsubscribedList },
        emailId,
        hasFetchedPreferences,
      },
    } = this.props;
    let {
      changeOptOutPreferences,
      saveOptOutPreferences,
      optOutAllPreferences,
    } = this.props;

    let optedOutBroadcast = unsubscribedList.has("EMAIL_BROADCAST");
    let optedOutCampaign = unsubscribedList.has("EMAIL_CAMPAIGN");
    let optedOutTranscripts = unsubscribedList.has(
      "OFFLINE_MESSAGE_EMAIL_NOTIFIATION"
    );

    return (
      <section
        className="optOutSection"
        style={{ display: hasFetchedPreferences ? "block" : "none" }}
      >
        <h3 className="optOutEmail">{emailId}</h3>
        <p className="optOutDescription">
          You can choose to opt out of any of the following types of email
          communications we send
        </p>
        <div className="formSection fadeIn">
          <div className="formField" style={{ textAlign: "center" }}>
            <button className="blueBtn" onClick={optOutAllPreferences}>
              Opt Out of All Emails
            </button>
          </div>
          <div className="formField">
            <div className="formHead">
              <h5>
                <b>Email Type</b>
              </h5>
            </div>
            <div className="formContent">
              <h5>
                <b>Opt Out</b>
              </h5>
            </div>
          </div>
          <div className="formField">
            <div className="formHead">
              <h5>Email Broadcast</h5>
            </div>
            <div className="formContent">
              <div
                onClick={changeOptOutPreferences}
                className={
                  "switch " + (optedOutBroadcast ? "switchYes" : "switchNo")
                }
                id="EMAIL_BROADCAST"
              ></div>
              <p>Relay annoucnements, product offers and coupons.</p>
            </div>
          </div>
          <div className="formField">
            <div className="formHead">
              <h5>Email Campaign</h5>
            </div>
            <div className="formContent">
              <div
                onClick={changeOptOutPreferences}
                className={
                  "switch " + (optedOutCampaign ? "switchYes" : "switchNo")
                }
                id="EMAIL_CAMPAIGN"
              ></div>
              <p>Automated announcements, product updates and new features.</p>
            </div>
          </div>
          <div className="formField">
            <div className="formHead">
              <h5>Offline Transcripts</h5>
            </div>
            <div className="formContent">
              <div
                onClick={changeOptOutPreferences}
                className={
                  "switch " + (optedOutTranscripts ? "switchYes" : "switchNo")
                }
                id="OFFLINE_MESSAGE_EMAIL_NOTIFIATION"
              ></div>
              <p>
                Automated mail when agent replies to your chat while you are
                offline.
              </p>
            </div>
          </div>
          <div
            className="formField"
            style={{ textAlign: "center", border: "0px" }}
          >
            <button
              onClick={saveOptOutPreferences}
              className="blueBtn"
              style={{ textAlign: "center" }}
            >
              Save Preferences
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default ManageEmailOptOut;
