import { IReturningFlowState } from 'src/features/returningFlow/store/types'

export const selectIsFinished = (state: IReturningFlowState) =>
  state.currentStepIndex >= state.steps.length

export const selectStepsNames = (state: IReturningFlowState) =>
  state.steps.map(
    (step) => `${step.name}${step.wasSkipped ? ' (skipped)' : ''}`
  )

export const selectSteps = (state: IReturningFlowState) => state.steps

export const selectCurrentStepIndex = (state: IReturningFlowState) =>
  state.currentStepIndex

export const selectCreatedDate = (state: IReturningFlowState) => {
  if (state.createdAt) {
    return new Date(state.createdAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  return ''
}

export const selectIsInitialised = (state: IReturningFlowState) =>
  Boolean(selectCreatedDate(state))
