import React from 'react'
import { config } from 'src/config'
import {
  Center,
  Container,
  Left,
  Right,
  SignInLabel,
  Spacer,
} from 'src/features/ui/components/Header/styled'
import { IProps } from 'src/features/ui/components/Header/types'
import { Icon } from 'src/features/ui/components/Icon'
import { Link1 } from 'src/features/ui/components/Typography'

export const Header: React.FC<IProps> = ({
  hasShadow,
  hasSignIn,
  children,
}) => (
  <>
    <Container hasSignIn={hasSignIn} hasShadow={hasShadow}>
      <Left>
        <a href={config.chatsupportUrl}>
          <Icon type="logo" width={160} height={45} />
        </a>
      </Left>
      {children && <Center>{children}</Center>}
      <Right>
        {hasSignIn && (
          <>
            <SignInLabel>Already have an account?</SignInLabel>{' '}
            <Link1
              color="dodgerBlue"
              href={`${config.chatsupportAppUrl}/login`}
            >
              {' '}
              Sign In
            </Link1>
          </>
        )}
      </Right>
    </Container>
    <Spacer hasChildren={Boolean(children)} />
  </>
)
