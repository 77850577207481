/**
 * Created by arun on 20/02/20.
 */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFullName, isValidURL } from "../../commons/Utility";

const TeamListItemComponent = (props) => {
  useEffect(() => {}, []);

  const handleBrokenImage = (e) => {
    e.target.src =  "https://app.chatsupport.co/images/profile_Icon.png";
  };

  const getImageElement = () => {
    let staff = props.staffItem;
    let avatarColor =
      staff && staff.avatarColor ? staff.avatarColor : "#A79580";
    let imageElement;
    const staffName = getFullName(props.staffItem);
    let firstLetter =
      staffName && staffName.length > 0 ? staffName.charAt(0) : "S";
    firstLetter = firstLetter.toUpperCase();

    if (
      staff.photoUrl &&
      staff.photoUrl.indexOf("null") == -1 &&
      isValidURL(staff.photoUrl)
    )
      imageElement = (
        <figure className="fs-exclude">
          <img
            data-staffKey={props.staffItem.key}
            onClick={props.directoryEventHandler.switchActiveStaff}
            src={staff.photoUrl}
            alt={staffName}
            onError={handleBrokenImage}
          />
        </figure>
      );
    else
      imageElement = (
        <figure
          className="fs-exclude"
          data-staffKey={props.staffItem.key}
          onClick={props.directoryEventHandler.switchActiveStaff}
          style={staff ? { backgroundColor: avatarColor } : {}}
        >
          {" "}
          {firstLetter}
        </figure>
      );
    return imageElement;
  };

  const getStaffItem = () => {
    const photoUrl = "https://app.chatsupport.co/images/profile_Icon.png";
    // const presenceClassName = this.getPresenceClassName();
    const staffName = getFullName(props.staffItem);

    let teamClassName =
      props.directoryEventHandler.getActiveStaffId() == props.staffItem.key &&
      !props.directoryProps.location.pathname.includes("myteam/invite")
        ? "active"
        : "";
    // teamClassName = teamClassName +  ( ( true) ?  " invite-people" : ""); //if the staff is invited for any project need to add this class
    props.staffItem.invitationPending
      ? (teamClassName += " invite-people")
      : "";
    return (
      <li
        className={teamClassName}
        data-staffKey={props.staffItem.key}
        key={props.staffItem.key}
        onClick={props.directoryEventHandler.switchActiveStaff}
      >
        {getImageElement()}
        {/* <img  data-staffKey={ props.staffItem.key }  onClick={props.directoryEventHandler.switchActiveStaff}  src={ props.staffItem.photoUrl && props.staffItem.photoUrl != "null" ? props.staffItem.photoUrl : photoUrl } alt={staffName} onError={handleBrokenImage}/> */}

        <a
          className="cs-caps-txt fs-exclude"
          href="#"
          data-staffKey={props.staffItem.key}
          onClick={props.directoryEventHandler.switchActiveStaff}
        >
          {staffName}
        </a>
      </li>
    );
  };

  return getStaffItem();
};

export default TeamListItemComponent;
