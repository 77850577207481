import { call, put, select } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import {
  addCampaign,
  broadcastMessageStatsResponse,
  processingCampaign,
  updateCampaignStatusResponse,
  updateCampaignType,
} from "../actions/BroadcastMessageStatsAction.js";
import { getReplacedMessage, getFallBackValues, getProjectKey, getfilterQueryArray } from "../commons/Utility";
import { browserHistory } from "react-router";

export function* fetchBroadcastMessagesEntities(data) {
  console.log("Fetching broadcast message details ->", data);
  const {projectKey} = data;
  const url = "/broadcast/get/" + projectKey;

  try {
    let { response, data } = yield call(Ajax.post, url);
    // response = response.data;

    if (response && data.length) {
      for (let broadcastMessageEntity of data) {
        let { message } = Object.assign({}, broadcastMessageEntity);
        broadcastMessageEntity.fallBackValues = getFallBackValues(
          message.value
        );
        broadcastMessageEntity.isHTMLTemplateEnabled =
          broadcastMessageEntity.isHTMLTemplateEnabled ? true : false;
        broadcastMessageEntity.isInteractableDisabled =
          broadcastMessageEntity.isInteractableDisabled ? true : false;
        broadcastMessageEntity.message.value = getReplacedMessage(
          broadcastMessageEntity.message.value
        );
      }
    }
    yield put(broadcastMessageStatsResponse(data , projectKey));
    //yield put(updateCampaignType("CHAT"));
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* updateBroadcastStatus(data) {
  console.log("Updating broadcast message details ->", data);
  const url = "/broadcast/status/update";

  try {
    let updateResponse = yield call(Ajax.post, url, data.payload);
    let broadcastMessageEntity = updateResponse.data;
    if (updateResponse.response) {
      let { message } = Object.assign({}, broadcastMessageEntity);
      message = message.value || message;
      broadcastMessageEntity.fallBackValues = getFallBackValues(message);
      broadcastMessageEntity.message.value = getReplacedMessage(
        broadcastMessageEntity.message.value
      );
    }

    yield put(updateCampaignStatusResponse(broadcastMessageEntity));
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}
export function* saveCampaign(data)
{
  const url = "/broadcast/save";
  try{
    yield put(processingCampaign(true));
    let filterQuery = getfilterQueryArray(data.campaign.filterQuery);
    data.campaign['filterQuery'] = JSON.stringify(filterQuery);
    let response = yield call(Ajax.post, url, data.campaign);
    let broadcastMessageEntity = response.data;
    broadcastMessageEntity.fallBackValues = getFallBackValues(
      broadcastMessageEntity.message.value
    );
    broadcastMessageEntity.message.value = getReplacedMessage(
      broadcastMessageEntity.message.value
    );
    broadcastMessageEntity.filterQuery = JSON.parse(broadcastMessageEntity.filterQuery);
    if( response.response == true )
    {
      broadcastMessageEntity.isHTMLTemplateEnabled =
        broadcastMessageEntity.isHTMLTemplateEnabled ? true : false;
      broadcastMessageEntity.isInteractableDisabled =
        broadcastMessageEntity.isInteractableDisabled ? true : false;
      if( data.isNewCampaign == true )
      {
        broadcastMessageEntity.isNewCampaign = true;
        yield put(addCampaign(broadcastMessageEntity, false));
        browserHistory.push("/app/" + getProjectKey(broadcastMessageEntity.projectId) + "/campaign/design/"+broadcastMessageEntity.key);
      }
      else 
        yield put(addCampaign(broadcastMessageEntity, true));
    }
    else
      yield put(handleGlobalError(e));
  } catch (e) {
    yield put(handleGlobalError(e));
  }
  finally{
    yield put(processingCampaign(false));
  }
}
export function* deleteCampaign(data)
{
  try{
    const url = "/broadcast/delete/"+data.campaignId;
    let response = yield call(Ajax.deleteReq, url);
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}