import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Button } from 'src/features/form/components/Button'
import { Text8 } from 'src/features/ui/components/Typography'

export const Position = styled.div<{
  top?: number
  right?: number
  bottom?: number
  left?: number
}>`
  position: absolute;

  ${is('top')`
    top: ${R.prop('top')}px;
  `}

  ${is('right')`
    right: ${R.prop('right')}px;
  `}

  ${is('bottom')`
    bottom: ${R.prop('bottom')}px;
  `}

  ${is('left')`
    left: ${R.prop('left')}px;
  `}
`

export const Container = styled.div<{
  isArrowLeftTop?: boolean
  isArrowLeftBottom?: boolean
}>`
  background-color: ${R.path(['theme', 'color', 'white'])};
  padding: 1.2rem 1.6rem;
  max-width: 22.1rem;
  border-radius: 0.9rem;
  position: relative;
  margin-left: 0.8rem;

  &::after {
    content: '';
    position: absolute;
    z-index: 99;
    width: 0;
    height: 0;
    border-top: 0.6rem solid transparent;
    border-right: 0.8rem solid ${R.path(['theme', 'color', 'white'])};
    border-bottom: 0.6rem solid transparent;

    ${is('isArrowLeftTop')`
      top: 1.4rem;
      left: -0.8rem;
    `}

    ${is('isArrowLeftBottom')`
      top: 13.4rem;
      left: -0.8rem;
    `}
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled(Text8)`
  line-height: 1.6rem;
`

export const Content = styled.div`
  margin-top: 1.1rem;
  max-width: 19.1rem;
`

export const Footer = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: flex-end;
`

export const IconButton = styled(Button)`
  max-height: 1.6rem;
  margin: -1.5rem;
  padding: 1.5rem;
`

export const StepButton = styled(Button)<{ maxHeight: number }>`
  font-size: 1.2rem;
  line-height: 1.44rem;

  & + & {
    margin-left: 1.6rem;
  }

  ${is('maxHeight')`
    max-height: ${R.prop('maxHeight')}rem;
  `}
`
