export const USER_PROJECT_REQUESTED = "USER_PROJECT_REQUESTED";
export const USER_PROJECT_RECEIVED = "USER_PROJECT_RECEIVED";
export const USER_PROJECT_REQUEST_FAILED = "USER_PROJECT_REQUEST_FAILED";
export const UPDATE_USER_RESPONSE = "UPDATE_USER_RESPONSE";
export const UPDATE_USER_PROJECT_REQUESTED = "UPDATE_USER_PROJECT_REQUESTED";
export const UPDATE_CONNECT_CONFIG_REQUESTED = "UPDATE_CONNECT_CONFIG_REQUESTED";
export const UPDATE_CONNECT_CONFIG_RESPONSE = "UPDATE_CONNECT_CONFIG_RESPONSE";
export const CONNECT_ENABLED_PROJECTS_REQUESTED = "CONNECT_ENABLED_PROJECTS_REQUESTED";
export const CONNECT_ENABLED_PROJECTS_RESPONSE = "CONNECT_ENABLED_PROJECTS_RESPONSE";

export const getUserProject = (staffid, isInternalUser) => ({
  type: USER_PROJECT_REQUESTED,
  isInternalUser,
  staffid,
});

export const userProjectResponse = (response) => ({
  type: USER_PROJECT_RECEIVED,
  response,
});

export const getUserProjectFailed = (userProjectMapping) => ({
  type: USER_PROJECT_REQUEST_FAILED,
  userProjectMapping,
});

export const updateUserProjectRequested = () => ({
  type: UPDATE_USER_PROJECT_REQUESTED,
});

export const updateUserResponse = (user) => ({
  type: UPDATE_USER_RESPONSE,
  user,
});

export const updateConnectConfigRequest = connectConfigurationDetails => ({
  type: UPDATE_CONNECT_CONFIG_REQUESTED,
  connectConfigurationDetails,
});

export const updateConnectConfigRespone = connectIntegrationStatus => ({
  type: UPDATE_CONNECT_CONFIG_RESPONSE,
  connectIntegrationStatus,
});

export const getConnectEnabledProjectsRequest = () => ({
  type: CONNECT_ENABLED_PROJECTS_REQUESTED,
});

export const connectEnabledProjectsResponse = connectEnabledProjects => ({
  type: CONNECT_ENABLED_PROJECTS_RESPONSE,
  connectEnabledProjects,
});
