import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { Title } from 'src/features/ui/components/Typography'
import { DesktopOnly } from 'src/features/ui/components/Visibility'
import {
  Container,
  ContainerDesktop,
} from 'src/features/wizard/setup/components/WidgetSetup/styled'
import { IProps } from 'src/features/wizard/setup/components/WidgetSetup/types'
import { PreviewForm } from 'src/features/wizard/setup/forms/Preview'
import { IFormValues as IPreviewFormValues } from 'src/features/wizard/setup/forms/Preview/data'
import { SetupForm } from 'src/features/wizard/setup/forms/Setup'
import { IFormValues as ISetupFormValues } from 'src/features/wizard/setup/forms/Setup/data'
import {
  requestUpdatePreview,
  requestUpdateWidget,
} from 'src/features/wizard/setup/store/actions'
import {
  selectIsUpdatingPreview,
  selectIsUpdatingWidget,
  selectWebsiteUrl,
} from 'src/features/wizard/setup/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const WidgetSetupComponent: FC<IProps> = ({
  isLoadingPreview,
  handlePreviewSubmit,
  isUpdatingWidget,
  handleWidgetSubmit,
  websiteUrl,
}) => {
  const submitPreview = (values: IPreviewFormValues) =>
    handlePreviewSubmit(values.url)

  const handleSubmitSetup = (values: ISetupFormValues) =>
    handleWidgetSubmit(values)

  return (
    <Container>
      <ContainerDesktop>
        <Title>Personalize your widget</Title>
      </ContainerDesktop>
      <DesktopOnly>
        <PreviewForm
          isLoading={isLoadingPreview}
          handleSubmit={submitPreview}
          defaultWebsiteUrl={websiteUrl}
        />
        <Margin top={40} />
      </DesktopOnly>
      <SetupForm
        isLoading={isUpdatingWidget}
        handleSubmit={handleSubmitSetup}
        buttonLabel={{
          mobile: 'Continue to installation',
          desktop: 'Continue to installation',
        }}
      />
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  isLoadingPreview: selectIsUpdatingPreview(state.setup),
  isUpdatingWidget: selectIsUpdatingWidget(state.setup),
  websiteUrl: selectWebsiteUrl(state.setup),
})

const mapDispatchToProps = {
  handlePreviewSubmit: requestUpdatePreview,
  handleWidgetSubmit: requestUpdateWidget,
}

export const WidgetSetup = connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetSetupComponent)
