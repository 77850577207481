export const LOGIN_REQUESTED = "LOGIN_REQUESTED";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGOUT_REQUESTED = "LOGOUT_REQUESTED";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
export const REQUEST_SESSION_BY_CODE = "REQUEST_SESSION_BY_CODE";

export const loginRequest = (login) => ({
  type: LOGIN_REQUESTED,
  login,
});

export const loginResponse = (login) => ({
  type: LOGIN_RESPONSE,
  login,
});

export const authFailed = (login) => ({
  type: AUTHENTICATION_FAILED,
  login,
});

export const logout = (login) => ({
  type: LOGOUT_REQUESTED,
  login,
});

export const requestSessionByCode = (credential) => ({
  type: REQUEST_SESSION_BY_CODE,
  credential,
});
