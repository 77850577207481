import {
  ON_MESSAGE,
  ON_CONNECT,
  ON_FAILED,
  ADD_MESSAGE,
  MESSAGE_REQUEST,
  MESSAGE_RESPONSE,
  CLOSE_CONNECTION,
  RESET_MESSAGES,
  ADD_MESSAGE_TO_TO_BE_SENT_LIST,
  ON_SUBSCRIBE,
  ON_NETWORKUP,
  ACK_MESSAGE,
  QUEUE_UNACK_MESSAGE,
  FAIL_MESSAGE,
  SUBSCRIBE,
  ADD_OFFLINE_MESSAGE,
  RESET_OFFLINE_MESSAGES,
  UPDATE_AVAILABILITY_REQUEST,
  UPDATE_AVAILABILITY_RESPONSE,
  RESET_CONVERSATION_MESSAGES,
  UPDATE_MESSAGE_RESPONSE,
  RESET_MESSAGE_CURSOR,
  ON_ABNORMAL_CLOSE,
  UPDATE_AW_MESSAGE_ID,
  CLEAR_CONVERSATION_MESSAGES,
  ON_CONNECT_RESUBSCRIBE,
  ON_MESSAGE_LIST_FAILED,
  ON_SORT_MESSAGES_VALIDATION,
  ON_OLD_CONVERSATION_REQUESTED,
  ON_OLD_CONVERSATION_RESPONSE
} from "../actions/MessageAction";
import {
  CURSOR_STATUS,
  MESSAGE_TYPE,
  EMOJI_TYPES,
  MESSAGE_STATUSES,
  DATE,
  AGENT_NOTIFIABLE_TYPES,
} from "../commons/Constants.js";
import {
  isAwConnection,
  getNextFeedbackMessage,
  sortMessageMapByProperty,
  constructMessageStoreObject,
} from "../commons/Utility";

const MessageReducer = (
  state = {
    messageMap: {},
    newMessage: {},
    agentNotifiableMessage:{},
    convMsgCursorMap: {},
    isFetching: false,
    isFetched: false,
    isConnected: false,
    queryParam: {},
    messagesToBeSend: [],
    isSubscribedToChannels: false,
    unackMessages: {},
    failedMessages: {},
    offlineMessages: [],
    updateAvailability: false,
    awIntegrationMessageID: undefined,
    messagesToBeValidated: {},
    isInitialMessageFetchCompletedInOverflow: false,
    resubscribeSyncFromConnect: true,
    isOldConversationFetching: false,
  },
  action
) => {
  //Cloning previous state values
  let messageMap = Object.assign({}, state.messageMap);
  let convMsgCursorMap = Object.assign({}, state.convMsgCursorMap);
  let messagesToBeValidated = Object.assign({}, state.messagesToBeValidated);

  switch (action.type) {
    case RESET_MESSAGES:
      console.log("RESET_MESSAGE===========", action);
      return {
        ...state,
        messageMap: {},
        convMsgCursorMap: {},
      };

    case MESSAGE_REQUEST:
      console.log("MESSAGE_REQUEST==============", action);
      return {
        ...state,
        queryParam: action.message.queryParam,
        isFetching: true,
        newMessage: {},
      };
    case MESSAGE_RESPONSE: {
      //If conversation already exist, add new messages into it
      let conversationMessageMap = {};
      let createdDateMap = {};
      if(action.messages.feedbackList) {
        for(let feebackMessage of action.messages.feedbackList){
          feebackMessage.message = String.fromCodePoint(EMOJI_TYPES[feebackMessage.rating]);
          createdDateMap[feebackMessage.createdDate] = feebackMessage;
      }
    }

      for (let message of action.messages.entity){
        let feedbackMessage = getNextFeedbackMessage(message.createdDate,createdDateMap)
        if(Object.keys(feedbackMessage).length > 0 && message.conversationId == feedbackMessage.conversationId){
          feedbackMessage.message = String.fromCodePoint(EMOJI_TYPES[feedbackMessage.rating]);
          conversationMessageMap[feedbackMessage.key] = feedbackMessage;
        }
        conversationMessageMap[message.key] = message;
      }        

      if (messageMap[state.queryParam.conversationId])
        messageMap[state.queryParam.conversationId] = sortMessageMapByProperty(
          Object.assign(
            messageMap[state.queryParam.conversationId],
            conversationMessageMap
          ),
          DATE.CREATED_DATE,
          false
        );
      //Else add conversation, then add messages into it
      else
        messageMap[state.queryParam.conversationId] = conversationMessageMap;

      //To set new cursor value
      convMsgCursorMap[state.queryParam.conversationId] =
        action.messages.cursor && action.messages.cursor !== "null"
          ? action.messages.cursor
          : CURSOR_STATUS.NO_CURSOR;

      //To find out the prompt message of the conversation
      if (
        convMsgCursorMap[state.queryParam.conversationId] ==
        CURSOR_STATUS.NO_CURSOR
      ) {
        let totalMessages =
          action.messages.entity && action.messages.entity.length
            ? action.messages.entity.length
            : 0;
        if (totalMessages) {
          let message = action.messages.entity[totalMessages - 1];
          message.isFirstMessage = true;
        }
      }

      return {
        ...state,
        messageMap: Object.assign({}, messageMap),
        convMsgCursorMap: Object.assign({}, convMsgCursorMap),
        queryParam: {},
        isFetching: false,
        newMessage: {},
        isOldConversationFetching: false
      };
    }
    case SUBSCRIBE: {
      return {
        ...state,
        isSubscribingToChannels: true,
      };
    }
    case ON_MESSAGE: {
      let newMessage = action.message;
      let messageId = newMessage.key ? newMessage.key : newMessage.messageId;
      let messageStatusTypes = [
        "CHAT_SENT",
        "CHAT_DELIVERED",
        "CHAT_READ",
        "CHAT_FAILED",
      ];
      // if (
      //   newMessage.type == "BROADCAST_STATS_UPDATE" ||
      //   newMessage.type == "BROADCAST_STATS_SYNC"
      // )
      //   return;
      if (messageStatusTypes.indexOf(newMessage.type) != -1) {
        if (messageMap[newMessage.conversationId]) {
          let messages = messageMap[newMessage.conversationId];

          if (messages[messageId])
            messages[messageId].messageStatus = newMessage.type;

          messageMap[newMessage.conversationId] = messages;
        }
      } else if (
        !newMessage.type.includes("TYPING") &&
        !newMessage.type.includes("server-fetchalluserstatus")
      ) {
        console.log("new message  -->", JSON.stringify(newMessage));
        //If conversation already exist
        if (messageMap[newMessage.conversationId]) {
          messageMap[newMessage.conversationId] = Object.assign(
            { [messageId]: newMessage },
            messageMap[newMessage.conversationId]
          );
        }
        //For new conversation
        else {
          messageMap[newMessage.conversationId] = { [messageId]: newMessage };

          //First time assigning cursor value for real-time new conversation
          convMsgCursorMap[newMessage.conversationId] =
            CURSOR_STATUS.NOT_INITIALIZED;
        }
      }

      return {
        ...state,
        messageMap: Object.assign({}, messageMap),
        newMessage: newMessage,
        convMsgCursorMap: Object.assign({}, convMsgCursorMap),
      };
    }
      
    case ON_SORT_MESSAGES_VALIDATION:
      let messageData = action.messageData;
      let conversationMessageMap = {};
      let messagesValidationConverastionId;
      for (let message of messageData) {
        message = constructMessageStoreObject(message.data);
        conversationMessageMap[message.messageId] = message;
        messagesValidationConverastionId = message.conversationId;
      }        
        messageMap[messagesValidationConverastionId] = sortMessageMapByProperty(
          Object.assign(
            messageMap[messagesValidationConverastionId],
            conversationMessageMap
          ),
          DATE.CREATED_DATE,
          false
        );
      return {
        ...state,
        messageMap: Object.assign({}, messageMap),
      }
    
    case ON_OLD_CONVERSATION_REQUESTED:
      return {
        ...state,
        isOldConversationFetching: true,
      }
    
    case ON_OLD_CONVERSATION_RESPONSE:
      return {
        ...state,
        isOldConversationFetching: false,
      }
    case ON_CONNECT: {
      return {
        ...state,
        isConnected: true,
        socketId: action.socketId,
        newMessage: {},
      };
    }

    case RESET_OFFLINE_MESSAGES: {
      return {
        ...state,
        offlineMessages: [],
      };
    }

    case ON_FAILED:
      return {
        ...state,
        isConnected: false,
        isSubscribingToChannels: false,
        isSubscribedToChannels: false,
      };
    case ADD_MESSAGE:
      let newMessgMap = {};
      if (
        action.message.hasOwnProperty("messageId") &&
        !action.message.hasOwnProperty("key")
      )
        action.message.key = action.message.messageId;
      let msgStatusTypes = [
        "CHAT_SENT",
        "CHAT_DELIVERED",
        "CHAT_READ",
        "CHAT_FAILED",
      ];
      console.log(`messageId ->  ${action.message.messageId} - ${action.message.type}`);
      if (msgStatusTypes.indexOf(action.message.type) != -1) {
        if (messageMap[action.message.conversationId]) {
          let messages = messageMap[action.message.conversationId];
          if(messages[action.message.key]) {
            messages[action.message.key].messageStatus = action.message.type;
            messageMap[action.message.conversationId] = messages;
          }
          
        }
      } else if (
        !action.message.type.includes("TYPING") &&
        !action.message.type.includes("server-fetchalluserstatus")
      ) {
        let isExistingMessage = false;
        if (!!messageMap[action.message.conversationId]) {
          let messages = messageMap[action.message.conversationId];

          if (messages[action.message.key]) {
            messages[action.message.key] = action.message;
            isExistingMessage = true;
          }

          if (!isExistingMessage)
            newMessgMap[action.message.conversationId] = Object.assign(
              { [action.message.key]: action.message },
              messageMap[action.message.conversationId]
            );
        } else
          newMessgMap[action.message.conversationId] = {
            [action.message.key]: action.message,
          };
      }
      let messagesToBeSend = Object.assign([], state.messagesToBeSend);
      messagesToBeSend = messagesToBeSend.filter(
        (message) => message.messageId !== action.message.messageId
      );
      
      return {
        ...state,
        messageMap: Object.assign(messageMap, newMessgMap),
        newMessage: {},
        agentNotifiableMessage: AGENT_NOTIFIABLE_TYPES.includes(
          action.message.type
        )
          ? action.message
          : {},
        messagesToBeSend: messagesToBeSend,
        isAcceptedMessageSent:
          action.message.type == MESSAGE_TYPE.chat_auto_assign_on_overflow ||
          (action.message.type == MESSAGE_TYPE.staff_joined_conversation &&
            isAwConnection())
            ? true
            : state.isAcceptedMessageSent,
      };
    case ADD_MESSAGE_TO_TO_BE_SENT_LIST: {
      let messagesToBeSend = Object.assign([], state.messagesToBeSend);
      messagesToBeSend.push(action.message);
      return {
        ...state,
        messagesToBeSend: messagesToBeSend,
      };
    }
    case ON_SUBSCRIBE:
      let resubscribeSyncFromConnect;
      if (state.resubscribeSyncFromConnect) {
        resubscribeSyncFromConnect = false;
      }
      return {
        ...state,
        isSubscribedToChannels: true,
        isSubscribingToChannels: false,
        isReSubscribed: state.isSubscribedToChannels,
        resubscribeSyncFromConnect: resubscribeSyncFromConnect
      };

    case ON_NETWORKUP: {
      return {
        ...state,
        isConnected: true,
      };
    }

    case ADD_OFFLINE_MESSAGE: {
      state.offlineMessages.push(action.message);
      return {
        ...state,
        offlineMessages: state.offlineMessages,
      };
    }

    case ACK_MESSAGE:
      let unackmessage = state.unackMessages[action.messageId];
      let updatedMap = {};

      if (unackmessage && Object.keys(unackmessage).length > 0) {
        console.info("Marking the message acknowledged:", unackMessage);
        let { conversationId, messageId } = unackmessage;
        let message = messageMap[conversationId][messageId];
        message.messageStatus = "CHAT_SENT";

        updatedMap = Object.keys(state.unackMessages)
          .filter((key) => key !== messageId)
          .reduce((result, current) => {
            result[current] = state.unackMessages[current];
            return result;
          }, {});

        state.failedMessages[messageId];
      } else {
        console.info("Unacknowledge message not found:", unackMessage);
        updatedMap = Object.assign({}, state.unackMessages);
      }

      return {
        ...state,
        messageMap,
        unackMessages: updatedMap,
      };

    case FAIL_MESSAGE:
      let unakmessage = Object.assign(
        {},
        state.unackMessages[action.failedMessage.messageId]
      );
      let { conversationId: convId, messageId: messId } = unakmessage;
      if (messageMap && messageMap[convId] && messageMap[convId][messId]) {
        let messageObj = messageMap[convId][messId];
        messageObj.messageStatus = MESSAGE_STATUSES[2];

        let failedMessages = Object.assign({}, state.failedMessages);
        failedMessages[messId] = action.failedMessage;

        let updatedAckMap = Object.keys(state.unackMessages)
          .filter((key) => key !== messId)
          .reduce((result, current) => {
            result[current] = state.unackMessages[current];
            return result;
          }, {});

        return {
          ...state,
          messageMap,
          failedMessages,
          unackMessages: updatedAckMap,
        };
      }
      return state;

    case QUEUE_UNACK_MESSAGE:
      let { unackMessages = {} } = state;
      let { unackMessage } = action;
      unackMessages[unackMessage.messageId] = unackMessage;

      return {
        ...state,
        unackMessages,
      };
    case UPDATE_AVAILABILITY_REQUEST:
      return {
        ...state,
        updateAvailability: true,
      };
    case UPDATE_AVAILABILITY_RESPONSE:
      return {
        ...state,
        updateAvailability: false,
      };
    case RESET_CONVERSATION_MESSAGES:
      let conversationMessages = messageMap[action.conversationId];
      if (conversationMessages) {
        let filteredMessages = {};
        for (const key in conversationMessages) {
          if (
            conversationMessages[key].conversationId === action.conversationId
          )
            filteredMessages[key] = conversationMessages[key];
        }
        messageMap[action.conversationId] = filteredMessages;
      }
      return {
        ...state,
        messageMap,
      };
    case UPDATE_MESSAGE_RESPONSE:
      let msgId = action.message.key;
      for(const convoId in messageMap){
        let conversation = messageMap[convoId];
        if(conversation[msgId]){
          conversation[msgId]= action.message;
        }
      }
      return {
        ...state,
        messageMap,
      };

    case RESET_MESSAGE_CURSOR:
      let newConvMsgCursorMap = {};
      for (const convoId in convMsgCursorMap){
        if(convoId !== action.conversationId)
          newConvMsgCursorMap[convoId] = convMsgCursorMap[convoId];
      }
      convMsgCursorMap = newConvMsgCursorMap;
      return {
        ...state,
        convMsgCursorMap
      }
    case ON_ABNORMAL_CLOSE:
      return {
        ...state,
        isSubscribedToChannels: false,
        socketId : ""
      };
    case UPDATE_AW_MESSAGE_ID:
      return {
        ...state,
        awIntegrationMessageID: action.messageId
      }

    case CLEAR_CONVERSATION_MESSAGES:
      delete messageMap[action.conversationId];
      return {
        ...state,
        messageMap: Object.assign({}, messageMap)
      }
    case ON_CONNECT_RESUBSCRIBE:
      return {
        ...state,
        isSubscribedToChannels: false,
        resubscribeSyncFromConnect: true
      }
    case ON_MESSAGE_LIST_FAILED:
      return {
        ...state, 
        queryParam: {},
        isFetching: false,
        newMessage: {}
      }
  }
  return state;
};

export default MessageReducer;
