import React, { FC, useState } from 'react'
import { Margin } from 'styled-components-spacing'
import tagManagerSrc from 'src/assets/google-tag-manager.png'
import joomlaSrc from 'src/assets/joomla.png'
import wordpressSrc from 'src/assets/wordpress.png'
import { Text1 } from 'src/features/ui/components/Typography'
import { DesktopOnly, MobileOnly } from 'src/features/ui/components/Visibility'
import { PluginButton } from 'src/features/wizard/install/components/PluginInstall/PluginButton'
import { PluginGoogle } from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginGoogle'
import { PluginJoomla } from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginJoomla'
import { PluginWordpress } from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginWordpress'
import { Section } from 'src/features/wizard/install/components/PluginInstall/Section'
import { EnumOpenType } from 'src/features/wizard/install/components/PluginInstall/Selection/data'
import { PluginButtonsGrid } from 'src/features/wizard/install/components/PluginInstall/Selection/styled'
import { IProps } from 'src/features/wizard/install/components/PluginInstall/Selection/types'

// TODO: refactor 🤯 and simplify
// eslint-disable-next-line max-lines-per-function
export const Selection: FC<IProps> = ({ hasFixedHeader, footer }) => {
  const [currentSection, setCurrentSection] = useState<
    undefined | EnumOpenType
  >(undefined)

  const toggleSection = (type: EnumOpenType) => {
    if (currentSection === type) {
      return setCurrentSection(undefined)
    }

    return setCurrentSection(type)
  }

  const getPluginId = (type: EnumOpenType, isMobile: boolean) =>
    isMobile ? `mobile-plugin-${type}` : `plugin-${type}`

  const handleScrollToElement = (openType: EnumOpenType, isMobile: boolean) => {
    const $elementContainer = document.getElementById(
      getPluginId(openType, isMobile)
    )

    if ($elementContainer) {
      const targetTop = $elementContainer?.offsetTop

      if (hasFixedHeader) {
        const top = isMobile ? targetTop - 230 : targetTop - 100

        window.scrollTo({
          top,
          left: 0,
          behavior: 'smooth',
        })
      } else {
        const top = isMobile ? targetTop + 250 : targetTop + 300

        window.scrollTo({
          top,
          left: 0,
          behavior: 'smooth',
        })
      }
    }
  }

  const handleClickPlugin = (
    openType: EnumOpenType,
    isMobile: boolean
  ) => () => {
    toggleSection(openType)

    setTimeout(() => {
      handleScrollToElement(openType, isMobile)
    }, 50)
  }

  const renderJoomlaSection = (isMobile: boolean) =>
    currentSection === EnumOpenType.joomla && (
      <Section
        id={getPluginId(EnumOpenType.joomla, isMobile)}
        footer={footer}
        myself={<PluginJoomla />}
      />
    )
  const renderWordpressSection = (isMobile: boolean) =>
    currentSection === EnumOpenType.wordpress && (
      <Section
        id={getPluginId(EnumOpenType.wordpress, isMobile)}
        footer={footer}
        myself={<PluginWordpress />}
      />
    )
  const renderGoogleSection = (isMobile: boolean) =>
    currentSection === EnumOpenType.googleTagManager && (
      <Section
        id={getPluginId(EnumOpenType.googleTagManager, isMobile)}
        footer={footer}
        myself={<PluginGoogle />}
      />
    )

  const renderButtons = (isMobile: boolean) => (
    <PluginButtonsGrid>
      <PluginButton
        image={{
          src: wordpressSrc,
          width: 32,
          height: 31,
        }}
        label="Wordpress"
        isActive={currentSection === EnumOpenType.wordpress}
        onClick={handleClickPlugin(EnumOpenType.wordpress, isMobile)}
      />
      {isMobile ? renderWordpressSection(true) : null}
      <PluginButton
        image={{
          src: joomlaSrc,
          width: 32,
          height: 32,
        }}
        label="Joomla"
        isActive={currentSection === EnumOpenType.joomla}
        onClick={handleClickPlugin(EnumOpenType.joomla, isMobile)}
      />
      {isMobile ? renderJoomlaSection(true) : null}
      <PluginButton
        image={{
          src: tagManagerSrc,
          width: 32,
          height: 32,
        }}
        label="Google Tag Manager"
        isActive={currentSection === EnumOpenType.googleTagManager}
        onClick={handleClickPlugin(EnumOpenType.googleTagManager, isMobile)}
      />
      {isMobile ? renderGoogleSection(true) : null}
    </PluginButtonsGrid>
  )

  return (
    <>
      <Text1>Select a plugin</Text1>
      <Margin top={16} />
      <MobileOnly>{renderButtons(true)}</MobileOnly>
      <DesktopOnly>
        {renderButtons(false)}
        <Margin top={{ base: 8, sm: 40 }} />
        {renderWordpressSection(false)}
        {renderJoomlaSection(false)}
        {renderGoogleSection(false)}
      </DesktopOnly>
    </>
  )
}
