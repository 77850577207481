import { IFormValues } from 'src/features/wizard/setup/forms/Setup/data'
import { IError } from 'src/utils/state/types'

export enum EnumStatus {
  updatingPreview = 'updatingPreview',
  updatingWidget = 'updatingWidget',
  uploadingIcon = 'uploadingIcon',
}

export enum WidgetPosition {
  BOTTOM_RIGHT = '400a5821-2ac2-4344-b8c4-c28dfef882bb',
  BOTTOM_LEFT = '767ff2dc-8e23-4996-beb7-60c1bdbbba52',
  TOP_RIGHT = '9a4ce71a-442c-11eb-b378-0242ac130002',
  TOP_LEFT = '28f6928d-187b-43a8-a5b5-1526e11b705d',
}

export enum WidgetType {
  DEFAULT = '9f305580-3df8-11eb-b378-0242ac130002',
  ICON = '06fac4f2-3df9-11eb-b378-0242ac130002',
}

export interface ISetupState {
  status: EnumStatus[]
  values: IFormValues
  websiteUrl?: string
  previewUrl?: string
  iconUrls: string[]
  error?: IError
}
