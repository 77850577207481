import { fork } from "redux-saga/effects";

import * as Sagas from "./watcher";
import * as SettingSagas from "../settings/sagas/watcher";

export default function* allSagas() {
  let sagasForkArr = [];

  for (let sagaFunc in Sagas) sagasForkArr.push(fork(Sagas[sagaFunc]));

  for (let sagaFunc in SettingSagas)
    sagasForkArr.push(fork(SettingSagas[sagaFunc]));

  yield sagasForkArr;
}
