import React from "react";
import ReactDom from "react-dom";
import ActiveChatView from "../../containers/ActiveChatViewContainer.js";
import VisitorDetails from "../../containers/VisitorDetailsContainer";

const AnywhereworksComponent = (props) => {
  let conversationId = props.conversationId ? props.conversationId : "";
  let visitorId = props.visitorId ? props.visitorId : "";
  let overflowedAgent = props.overflowedAgent ? props.overflowedAgent : {};
  let interactionId = props.interactionId ? props.interactionId : "";
  let autoDisconnectOverflowChatTimer = props.autoDisconnectOverflowChatTimer
    ? props.autoDisconnectOverflowChatTimer
    : 0;
  let awOverflowInfo = props.awOverflowInfo;
  return (
    <section className="col3 overflow-col3">
      <ActiveChatView
        overflowedAgent={overflowedAgent}
        showVisitorDetails={props.showVisitorDetails}
        isAwWindow={true}
        conversationId={conversationId}
        interactionId={interactionId}
        visitorId={visitorId}
        autoDisconnectOverflowChatTimer={autoDisconnectOverflowChatTimer}
        awOverflowInfo={awOverflowInfo}
      />
      <code
        onClick={props.showVisitorDetails}
        className={
          "showVisitorDetails " + (props.displayVisitorDetails ? "dn" : "")
        }
        id="showVisitorDetailsButton"
      ></code>
      <VisitorDetails
        isAwWindow={true}
        params={props.params}
        visitorId={visitorId}
        displayVisitorDetails={props.displayVisitorDetails}
        hideVisitorDetails={props.hideVisitorDetails}
      />
    </section>
  );
};
export default AnywhereworksComponent;
