export const FETCH_BROADCAST_MESSAGE_STATS_REQUEST =
  "FETCH_BROADCAST_MESSAGE_STATS_REQUEST";
export const FETCH_BROADCAST_MESSAGE_STATS_RESPONSE =
  "FETCH_BROADCAST_MESSAGE_STATS_RESPONSE";
export const ADD_SELECTED_CAMPAIGN = "ADD_SELECTED_CAMPAIGN";
export const SAVE_CAMPAIGN = "SAVE_CAMPAIGN";
export const UPDATE_CAMPAIGN_TYPE = "UPDATE_CAMPAIGN_TYPE";
export const UPDATE_PREVIEW_TYPE = "UPDATE_PREVIEW_TYPE";
export const UPDATE_CAMPAIGN_STATUS_REQUEST = "UPDATE_CAMPAIGN_STATUS_REQUEST";
export const UPDATE_CAMPAIGN_STATUS_RESPONSE =
  "UPDATE_CAMPAIGN_STATUS_RESPONSE";
export const SEND_TEST_BROADCAST_EMAILS_REQUEST =
  "SEND_TEST_BROADCAST_EMAILS_REQUEST";
export const SEND_TEST_BROADCAST_EMAILS_RESPONSE =
  "SEND_TEST_BROADCAST_EMAILS_RESPONSE";
export const ADD_CAMPAIGN_POPUP_PROJECTID = "ADD_CAMPAIGN_POPUP_PROJECTID";
export const MARK_FETCHED = "MARK_FETCHED";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const UPDATE_CAMPAIGN_CONTENT = "UPDATE_CAMPAIGN_CONTENT";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const CAMPAIGN_FROM_DASHBOARD = "CAMPAIGN_FROM_DASHBOARD";
export const CAMPAIGN_SIDEBAR_TYPE = "CAMPAIGN_SIDEBAR_TYPE";
export const UPDATE_CAMPAIGN_STATS_COUNT = "UPDATE_CAMPAIGN_STATS_COUNT";
export const REMOVE_CAMPAIGN = "REMOVE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATING_CAMPAIGN = "UPDATING_CAMPAIGN";
export const PROCESSING_CAMPAIGN = "PROCESSING_CAMPAIGN";

export const fetchBroadcastMessageStats = (projectKey) => ({
  type: FETCH_BROADCAST_MESSAGE_STATS_REQUEST,
  projectKey,
});
export const saveCampaign = ( campaign, isNewCampaign ) =>({
  type: SAVE_CAMPAIGN,
  campaign,
  isNewCampaign
})
export const deleteCampaign = ( campaignId ) =>({
  type: DELETE_CAMPAIGN,
  campaignId
})
export const broadcastMessageStatsResponse = (
  broadcastMessageStatsResponse, 
  projectKey
) => ({
  type: FETCH_BROADCAST_MESSAGE_STATS_RESPONSE,
  broadcastMessageStatsResponse,
  projectKey
});

export const addSelectedCampaign = (selectedCampaign) => ({
  type: ADD_SELECTED_CAMPAIGN,
  selectedCampaign,
});

export const updateCampaignType = (campaignType) => ({
  type: UPDATE_CAMPAIGN_TYPE,
  campaignType,
});

export const updatePreviewType = (previewType) => ({
  type: UPDATE_PREVIEW_TYPE,
  previewType,
});

export const updateCampaignStatus = (payload) => ({
  type: UPDATE_CAMPAIGN_STATUS_REQUEST,
  payload,
});

export const updateCampaignStatusResponse = (updateResponse) => ({
  type: UPDATE_CAMPAIGN_STATUS_RESPONSE,
  updateResponse,
});
export const sentTestMailsRequest = (testMailData, projectId) => ({
  type: SEND_TEST_BROADCAST_EMAILS_REQUEST,
  testMailData,
  projectId,
});
export const sentTestMailsResponse = () => ({
  type: SEND_TEST_BROADCAST_EMAILS_RESPONSE,
});
SEND_TEST_BROADCAST_EMAILS_RESPONSE;

export const addCampaignPopupProjectId = (projectKey) => ({
  type: ADD_CAMPAIGN_POPUP_PROJECTID,
  projectKey,
});

export const markFetched = (isFetched) => ({
  type: MARK_FETCHED,
  isFetched,
});

export const addCampaign = (campaign, isUpdate) => ({
  type : ADD_CAMPAIGN,
  campaign, 
  isUpdate
});

export const updateCampaignContent = () => ({
  type:UPDATE_CAMPAIGN_CONTENT,
})

export const updateCampaignFromDashboard = (isCampaignFromDashboard) => ({
  type: CAMPAIGN_FROM_DASHBOARD,
  isCampaignFromDashboard
})

export const updateCampaignSideBarType = (sideBar) => ({
  type: CAMPAIGN_SIDEBAR_TYPE,
  sideBar
})

export const updateCampaignStatsCount = (campaignStats) => ({
  type: UPDATE_CAMPAIGN_STATS_COUNT,
  campaignStats
});

export const removeCampaign = (campaignId) => ({
  type: REMOVE_CAMPAIGN,
  campaignId
});
export const updateCampaign = (campaign) => ({
  type: UPDATE_CAMPAIGN,
  campaign
});
export const updatingCampaign = (campaign) => ({
  type: UPDATING_CAMPAIGN,
});

export const processingCampaign = (isProcessing) => ({
  type: PROCESSING_CAMPAIGN,
  isProcessing
});