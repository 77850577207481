import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../app';
import { getStore } from '../commons/Utility';

const wrapStore = (Comp) => {
    return (props) => (<Provider store={getStore()}>
      <Comp {...props}/>
    </Provider>);
}

export default wrapStore;