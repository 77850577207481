import { useEffect, useState } from 'react'

export const useImageLoader = (imageUrl?: string) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setIsLoaded(true)
    }

    if (imageUrl) {
      setIsLoaded(false)
      img.src = imageUrl
    }
  }, [imageUrl])

  return isLoaded
}
