import React, { useState, useRef, useEffect } from "react";
import { MoreOptionsIcon, EditIcon, DeleteIcon } from "../../commons/Icons";

const ProactiveDetailsCardComponent = (props) => {
  const {
    proactiveConfig: config,
    toggleDeletePopup,
    onEditPrompt,
    isValidUser,
  } = props;
  let promptName =
    (config && config.promptName && config.promptName.trim()) ||
    config.promptIndexName;
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const node = useRef(null);
  const promptNameref = useRef(null);
  const enabledUrlRef = useRef(null);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (event) => {
    if (!(node && node.current.contains(event.target))) {
      hideMoreOptions();
    }
  };

  const showMoreOptionsPopUp = (event) => {
    setShowMoreOptions(true);
    event.stopPropagation();
  };

  const hideMoreOptions = () => {
    setShowMoreOptions(false);
  };

  const getURL = (urlString) => {
    let url = null;
    try {
      url = new URL(urlString);
    } catch (error) {
      url = new URL("https://" + urlString);
    }
    return url;
  };

  const getPromptUrl = () => {
    let url = getURL(config.enabledUrl);
    if (url.pathname && url.pathname.length > 1) {
      if (url.pathname.length >= 20) return `...${url.pathname.slice(-18)}`;
      else return `...${url.pathname}`;
    } else {
      return url.host;
    }
  };

  const showPrompt = () => {
    hideMoreOptions();
    onEditPrompt(event);
  };

  const getMoreOptions = () => {
    if (isValidUser()) {
      return (
        <figure
          style={{ cursor: "pointer" }}
          data-id={config.key}
          onClick={showMoreOptionsPopUp}
          class={showMoreOptions ? "open" : ""}
        >
          <MoreOptionsIcon data-id={config.key} />
          <ul class="edit-delete-container">
            <li
              data-prompt_index_name={config.promptIndexName}
              data-id={config.key}
              onClick={onEditPrompt}
            >
              <EditIcon data-id={config.key} />
              Edit
            </li>
            <li
              class="delete"
              data-id={config.key}
              onClick={(event) => {
                toggleDeletePopup(event);
              }}
            >
              <DeleteIcon data-id={config.key} />
              Delete
            </li>
          </ul>
        </figure>
      );
    } else {
      return "";
    }
  };

  return (
    <li
      ref={node}
      data-prompt_index_name={config.promptIndexName}
      data-id={config.key}
      onClick={showPrompt}
      class={showMoreOptions ? "active" : ""}
    >
      <label
        className="fs-exclude"
        ref={promptNameref}
        data-prompt_index_name={config.promptIndexName}
        data-id={config.key}
        onClick={showPrompt}
      >
        {promptName}
      </label>
      <p
        className="fs-exclude"
        ref={enabledUrlRef}
        data-prompt_index_name={config.promptIndexName}
        data-id={config.key}
        onClick={showPrompt}
      >
        {getPromptUrl()}/
      </p>
      {getMoreOptions()}
    </li>
  );
};

export default ProactiveDetailsCardComponent;
