import * as R from 'ramda'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const LogoContainer = styled.div`
  background-color: ${R.path(['theme', 'color', 'royalBlue'])};
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
