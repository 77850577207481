import * as R from 'ramda'
import { EnumStatus, ISetupState } from 'src/features/wizard/setup/store/types'

export const selectValues = (state: ISetupState) => state.values

export const selectColor = (state: ISetupState) => state.values.color

export const selectIconUrls = (state: ISetupState) => state.iconUrls

export const selectIcon = (state: ISetupState) => state.values.icon

export const selectPosition = (state: ISetupState) => state.values.position

export const selectWidgetType = (state: ISetupState) => state.values.widgetType

export const selectPreviewUrl = (state: ISetupState) => state.previewUrl

export const selectWebsiteUrl = (state: ISetupState) => state.websiteUrl

export const selectIsUpdatingPreview = (state: ISetupState) =>
  R.contains<EnumStatus>(EnumStatus.updatingPreview)(state.status)

export const selectIsUpdatingWidget = (state: ISetupState) =>
  R.contains<EnumStatus>(EnumStatus.updatingWidget)(state.status)

export const selectIsUploadingIcon = (state: ISetupState) =>
  R.contains<EnumStatus>(EnumStatus.uploadingIcon)(state.status)
