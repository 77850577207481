import * as BroadcastEmailStatsActions from "../actions/BroadcastEmailStatsAction.js";

const BroadcastEmailStatsReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    broadcastEmailEntities: [],
    searchValue: "",
  },
  action
) => {
  switch (action.type) {
    case BroadcastEmailStatsActions.FETCH_BROADCAST_EMAIL_STATS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };

    case BroadcastEmailStatsActions.FETCH_BROADCAST_EMAIL_STATS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        broadcastEmailEntities: action.broadcastEmailStatsResponse,
      };

    default:
      return state;
  }
};

export default BroadcastEmailStatsReducer;
