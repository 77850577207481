import { useField } from 'formik'
import React, { FC } from 'react'
import {
  Container,
  Input,
  Label,
  OptionContainer,
  OptionLabel,
  Options,
} from 'src/features/form/components/OptionsRadio/styled'
import {
  IProps,
  ItemValue,
} from 'src/features/form/components/OptionsRadio/types'

export const OptionsRadio: FC<IProps> = ({
  name,
  label,
  options,
  ...inputProps
}): JSX.Element => {
  const [field, _meta, helpers] = useField(name)

  const handleChange = (newPosition: ItemValue) => () => {
    helpers.setValue(newPosition)
  }

  return (
    <Container>
      {label && <Label bolder>{label}</Label>}
      <Options>
        {options.map((option) => (
          <OptionContainer key={option.value}>
            <Input
              {...inputProps}
              type="radio"
              value={option.value}
              name={name}
              checked={option.value === field.value}
              onChange={handleChange(option.value)}
            />
            <OptionLabel checked={option.value === field.value}>
              {option.label}
            </OptionLabel>
          </OptionContainer>
        ))}
      </Options>
    </Container>
  )
}
