/**
 * Created by melvin on 25/04/17.
 */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import StaffListComponent from "../components/staff/StaffListComponent.jsx";
import {
  staffListRequest,
  deleteStaffRequested,
  updateStaffRoleRequested,
} from "../actions/StaffAction";
import { STAFF_ROLE_TO_USER_ROLE_MAP } from "../commons/Constants";
import * as InvitationActions from "../actions/InvitationAction";
import * as NotificationActions from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as util from "../commons/Utility.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import SearchableDropDown from "../components/common/SearchableDropdown.jsx";
import * as Utilities from "../commons/Utility.js";

@connect((state) => ({
  staffReducer: state.StaffReducer,
  projectData: state.ProjectReducer,
  invitationReducer: state.InvitationReducer,
  userData: state.UserReducer,
}))
class StaffListContainer extends Component {
  constructor() {
    super();
    //this.renderStaffList				=	this.renderStaffList.bind(this);
    this.updateUserRole = this.updateUserRole.bind(this);
    this.deleteStaff = this.deleteStaff.bind(this);

    this.showAddStaffPopup = this.showAddStaffPopup.bind(this);
    this.hideAddStaffPopup = this.hideAddStaffPopup.bind(this);

    this.addStaffToInvite = this.addStaffToInvite.bind(this);
    this.onEnterHandler = this.onEnterHandler.bind(this);
    this.removeStaff = this.removeStaff.bind(this);
    this.inviteStaffs = this.inviteStaffs.bind(this);
    this.showStaffDeletePopup = this.showStaffDeletePopup.bind(this);
    this.hideStaffDeletePopup = this.hideStaffDeletePopup.bind(this);
    this.getStaffList = this.getStaffList.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.getAgentNameById = this.getAgentNameById.bind(this);
    this.isEmailAlreadyAStaff = this.isEmailAlreadyAStaff.bind(this);

    this.state = {
      activeProject: {},
      invitedStaffs: new Map(),
      activeProjectStaffMap: {},
      loggedInUser: {},
      loggedInUserPermission: "RESTRICTED",
      showPopUpClass: false,
      showStaffDeletePopup: false,
      staffToDelete: undefined,
      currentlySelectedStaff: undefined,
      showInviteButton: false,
    };
  }
  componentWillMount() {
    let projectKey = this.props.params.projectkey;

    //To fetch agents by project for the first time
    if (
      this.props.staffReducer.dashboardAgents[
        Utilities.getProjectId(projectKey)
      ] === undefined
    ) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({ message: "Fetching Staff..." })
      );
      this.props.dispatch(staffListRequest(Utilities.getProjectId(projectKey)));
    }

    this.setDefaultStates(this.props);
  }

  setDefaultStates(props) {
    let projectKey = props.params.projectkey;

    //To set selected project details
    if (props.projectData && props.projectData.projectList.length > 0) {
      this.setState({
        activeProject: props.projectData.projectList.filter(
          (project) => project.key === "LS-" + props.params.projectkey
        )[0],
        projectKey,
      });
    }

    //To set selected project staffs list
    if (
      props.staffReducer.dashboardAgents[Utilities.getProjectId(projectKey)]
    ) {
      this.setState({
        activeProjectStaffMap:
          props.staffReducer.dashboardAgents[
            Utilities.getProjectId(projectKey)
          ],
      });
    }

    //To set logged in user details
    if (props.userData.data) {
      this.setState({ loggedInUser: props.userData.data });
    }

    //To set logged in user's permission in selected project
    if (
      props.userData.data &&
      props.staffReducer.dashboardAgents[Utilities.getProjectId(projectKey)] &&
      Object.keys(
        props.staffReducer.dashboardAgents[Utilities.getProjectId(projectKey)]
      ).length > 0
    ) {
      let user =
        props.staffReducer.dashboardAgents[Utilities.getProjectId(projectKey)][
          props.userData.data.id
        ];
      let userPermission =
        user && user.userPermission ? user.userPermission : "RESTRICTED";
      this.setState({ loggedInUserPermission: userPermission });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setDefaultStates(nextProps);

    let newProjectKey = nextProps.params.projectkey;

    //To handle project change from project dropdown
    if (
      this.props.params.projectkey !== nextProps.params.projectkey &&
      nextProps.staffReducer.dashboardAgents[
        Utilities.getProjectId(newProjectKey)
      ] === undefined &&
      !nextProps.staffReducer.isFetchFailed
    ) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({ message: "Fetching Staff..." })
      );
      this.props.dispatch(
        staffListRequest(Utilities.getProjectId(nextProps.params.projectkey))
      );
    }
  }

  addStaffToInvite() {
    let staffEmailId = this.child.refs.staffEmailId.value.trim().toLowerCase();
    let permission = this.child.refs.staff_permission.value;
    let displayPermission = permission == "RESTRICTED" ? "Staff" : "Owner";
    let isActiMailid = staffEmailId.endsWith("a-cti.com");

    if (staffEmailId == "") {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "email can't be empty !",
          dismissAfter: 2500,
        })
      );
      return;
    } else if (!util.isValidEmail(staffEmailId)) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "email is not valid !",
          dismissAfter: 2500,
        })
      );
      return;
    } else if (this.isEmailAlreadyAStaff(staffEmailId)) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: staffEmailId + " is already a staff !",
          dismissAfter: 2500,
        })
      );
      return;
    } else if (this.state.invitedStaffs.get(staffEmailId)) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: staffEmailId + " is already in the invite list",
          dismissAfter: 2500,
        })
      );
      return;
    } else if (isActiMailid) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "Oops, invalid email domain!",
          dismissAfter: 2500,
        })
      );
      return;
    }

    this.setState((prevState, props) => ({
      invitedStaffs: prevState.invitedStaffs.set(
        staffEmailId,
        displayPermission
      ),
    }));
    this.child.refs.staffEmailId.value = "";
    this.child.refs.staffEmailId.focus();
  }

  removeStaff(event) {
    event.persist();
    let staff = event.target.parentNode.id;
    let staffEmailId = this.child.refs.staffEmailId.value.trim();
    let alreadyAddedStaff = this.state.invitedStaffs;
    alreadyAddedStaff.delete(staff);

    this.setState({ invitedStaffs: alreadyAddedStaff });
    this.checkAndDisplayInviteButton(staffEmailId);
  }

  onEnterHandler(event) {
    event.persist();

    if (event.keyCode == 13) {
      this.addStaffToInvite();
    } else {
      let email = event.target.value.trim();
      this.checkAndDisplayInviteButton(email);
    }
  }

  checkAndDisplayInviteButton(email) {
    let { invitedStaffs, prevShowInviteButton } = this.state;

    if (
      (util.isValidEmail(email) || invitedStaffs.size > 0) &&
      !prevShowInviteButton
    ) {
      this.setState({ showInviteButton: true });
    } else {
      this.setState({ invitedStaffs: new Map() });
      this.setState({ showInviteButton: false });
    }
  }

  inviteStaffs() {
    let staffEmailId = this.child.refs.staffEmailId.value.trim().toLowerCase();
    let permission = this.child.refs.staff_permission.value;
    let displayPermission = permission == "RESTRICTED" ? "Staff" : "Owner";
    let staffsToInvite = this.state.invitedStaffs;

    if (
      this.state.invitedStaffs.size == 0 &&
      !util.isValidEmail(staffEmailId)
    ) {
      this.props.dispatch(
        NotificationActions.addNotification({
          message: "Please add email to invite!",
          level: NOTIFICATION_LEVEL.Warning,
          dismissAfter: 3000,
        })
      );
      return;
    } else if (this.isEmailAlreadyAStaff(staffEmailId)) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: staffEmailId + " is already a staff !",
          dismissAfter: 2500,
        })
      );
      return;
    }

    if (util.isValidEmail(staffEmailId))
      staffsToInvite.set(staffEmailId, displayPermission);

    this.props.dispatch(
      InvitationActions.sendInvitationRequest({
        projectKey: "LS-" + this.props.params.projectkey,
        staffList: staffsToInvite,
      })
    );

    this.initializeInvitePopup();
  }

  isEmailAlreadyAStaff(email) {
    let staffMap = this.props.staffReducer.dashboardAgents[
      Utilities.getProjectId(this.props.params.projectkey)
    ];
    for (let key in staffMap) {
      if (staffMap[key].login == email && staffMap[key].status == "ACTIVE")
        return true;
    }
  }

  showAddStaffPopup() {
    this.setState({ showPopUpClass: true });
  }

  hideAddStaffPopup() {
    this.initializeInvitePopup();
  }

  initializeInvitePopup() {
    this.setState({
      invitedStaffs: new Map(),
      showPopUpClass: false,
      showInviteButton: false,
    });
    this.child.refs.staffEmailId.value = "";
    this.child.refs.staff_permission.value = "RESTRICTED";
  }

  deleteStaff() {
    let staffKey = this.state.staffToDelete;
    let projectKey = this.props.params.projectkey;
    let staffMap = this.props.staffReducer.dashboardAgents;
    let overflowAgents = this.props.staffReducer.overflowAgents;
    let userKey = this.props.userData.data.id;
    let assignedUserKey = this.state.currentlySelectedStaffId;

    this.props.dispatch(
      deleteStaffRequested(projectKey, staffKey, assignedUserKey)
    );
    this.hideStaffDeletePopup();
  }

  updateUserRole(e) {
    let userRole = e.target.innerHTML;
    let userKey = e.target.dataset.staffkey;
    let staff = this.state.activeProjectStaffMap[userKey];
    staff.userPermission = STAFF_ROLE_TO_USER_ROLE_MAP[userRole];
    const projectKey = this.props.params.projectkey;

    this.props.dispatch(updateStaffRoleRequested({ staff, projectKey }));
  }

  showStaffDeletePopup(e) {
    this.setState({
      ...this.state,
      showStaffDeletePopup: true,
      staffToDelete: e.target.dataset.staffkey,
      currentlySelectedStaffId: undefined,
    });
  }

  hideStaffDeletePopup() {
    this.setState({
      ...this.state,
      showStaffDeletePopup: false,
      staffToDelete: undefined,
      currentlySelectedStaffId: undefined,
    });
  }

  getStaffList() {
    let options = [];
    let { staffToDelete, currentlySelectedStaffId } = this.state;
    let {
      staffReducer,
      params: { projectkey },
    } = this.props;

    let dropDownInfo = {
      dropDownVisibilityClass: "",
      dropDownTypeClass: "dwn img-dwn search-dwn",
    };

    let staffList = [];
    let dashboardAgents =
      staffReducer.dashboardAgents[Utilities.getProjectId(projectkey)];

    if (!currentlySelectedStaffId) {
      for (let staffId in dashboardAgents)
        if (
          dashboardAgents[staffId].status == "ACTIVE" &&
          staffId != staffToDelete
        )
          currentlySelectedStaffId = staffId;
      this.setState({ currentlySelectedStaffId });
    }
    for (let key in dashboardAgents)
      if (
        dashboardAgents[key].status == "ACTIVE" &&
        key != staffToDelete &&
        key != currentlySelectedStaffId
      )
        //We dont want the selected staff, already deleted staff and currently being staff listed in the dropdown
        staffList.push(dashboardAgents[key]);

    let filteredList = [dashboardAgents[currentlySelectedStaffId]];
    let currentAssignedStaff = Utilities.getDropdownPayload(
      filteredList,
      "key",
      "photoUrl",
      ["firstName", "lastName"],
      "isOnline",
      "openConversationCount",
      "avatarColor",
      "isTakingChat"
    )[0];

    options.push(
      ...Utilities.getDropdownPayload(
        staffList,
        "key",
        "photoUrl",
        ["firstName", "lastName"],
        "isOnline",
        "openConversationCount",
        "avatarColor",
        "isTakingChat"
      )
    );
    return (
      <SearchableDropDown
        shrinkSelected={false}
        options={options}
        defaultImage={"https://app.chatsupport.co/images/Staff-Avatar.svg"}
        currentlySelected={currentAssignedStaff}
        showSelectedPhoto={true}
        onSelect={this.onSelectHandler}
        dropdownInfo={dropDownInfo}
        style={{
          display: "inline-block",
          "border-radius": "4px",
          margin: "7px 0px 0px",
          "background-color": "rgb(255, 255, 255)",
          border: "1px solid #e4e6ea",
          position: "relative",
          width: "212px",
        }}
      />
    );
  }

  onSelectHandler(e) {
    console.log("on selceted ::", e.currentTarget.dataset.id);
    this.setState({
      ...this.state,
      currentlySelectedStaffId: e.currentTarget.dataset.id,
    });
  }

  getAgentNameById(agentId) {
    let assignedAgentName = "";

    let staffMap = this.props.staffReducer.dashboardAgents[
      Utilities.getProjectId(this.props.params.projectkey)
    ];

    if (agentId && staffMap) {
      if (staffMap[agentId]) {
        assignedAgentName = Utilities.getFullName({ ...staffMap[agentId] });
      } else {
        assignedAgentName = agentId;
      }
    }

    return assignedAgentName;
  }

  render() {
    let currentProjectDetails = this.props.projectData.projectList.filter(
      (project) => project.key === "LS-" + this.props.params.projectkey
    )[0];
    if (currentProjectDetails !== undefined) {
      this.createdUserKey = currentProjectDetails.createdBy;
    }

    return (
      <StaffListComponent
        parentState={this.state}
        deleteStaffHandler={this.deleteStaff}
        updateUserRoleHandler={this.updateUserRole}
        ref={(component) => (this.child = component)}
        invitedStaffs={this.state.invitedStaffs}
        removeStaff={this.removeStaff}
        showPopUpClass={this.state.showPopUpClass}
        onEnterHandler={this.onEnterHandler}
        showAddStaffPopup={this.showAddStaffPopup}
        hideAddStaffPopup={this.hideAddStaffPopup}
        inviteStaffs={this.inviteStaffs}
        showStaffDeletePopup={this.showStaffDeletePopup}
        hideStaffDeletePopup={this.hideStaffDeletePopup}
        getStaffList={this.getStaffList}
        getAgentNameById={this.getAgentNameById}
        addStaffToInvite={this.addStaffToInvite}
      />
    );
  }
}
export default StaffListContainer;
