import React, { useEffect, useState, useRef } from "react";
import {
  InviteStaffIcon,
  TeamDropDownIcon,
  TickIcon,
} from "../../commons/Icons";
import InviteStaffListItem from "./InviteStaffListItem.jsx";
import { usePrevious } from "../../settings/hooks/usePrevious";

const InviteStaffComponent = ({
  staffMap,
  projectList,
  addStaff,
  removeStaff,
  updateStaff,
  inviteStaffs,
  inviteButtonEnabled,
}) => {
  const staffListDivRef = useRef(null);

  const getStaffListElements = () => {
    let toRender = [];
    for (let key in staffMap)
      toRender.push(
        <InviteStaffListItem
          removeStaff={removeStaff}
          updateStaff={updateStaff}
          staff={staffMap[key]}
          projectList={projectList}
          showRemoveIcon={Object.keys(staffMap).length > 1}
        />
      );
    return toRender;
  };
  const prevCount = usePrevious(Object.keys(staffMap).length);
  useEffect(() => {
    if (prevCount < Object.keys(staffMap).length)
      staffListDivRef.current.scrollTo({
        top: staffListDivRef.current.scrollHeight,
        behavior: "smooth",
      });
  }, [Object.keys(staffMap).length]);

  return (
    <div className="cs-settings-wrp">
      <section className="invite-people-wrp" ref={staffListDivRef}>
        <InviteStaffIcon />
        <h3>
          Invite members
          <br />
          to join your team<cite>.</cite>
        </h3>
        <p>Send invites, assign permission and skills.</p>
        <div>
          <ul className={projectList.length > 1 ? "col5" : ""}>
            {getStaffListElements()}
          </ul>
          <a href="javascript:void(0)" onClick={addStaff}>
            + Add more people
          </a>
          <div className="invite-btn-wrp">
            <a
              href="javascript:void(0)"
              className={
                "cs-btn" +
                (inviteButtonEnabled ? " cs-primary-btn" : " cs-disabled-btn")
              }
              disabled={!inviteButtonEnabled}
              style={inviteButtonEnabled ? {} : { opacity: "0.5" }}
              onClick={inviteStaffs}
            >
              Send Invitation
            </a>
          </div>
        </div>
        <footer></footer>
      </section>
    </div>
  );
};
export default InviteStaffComponent;
