import { call, put, select } from "redux-saga/effects";
import * as Ajax from "../../xhr/XhrRequestHandler";
import {
  receiveNewlyAddedProjects,
  PROJECTLIST_FAILED,
  receiveAvailableProject,
  receiveCreatedProject,
  DELETEPROJECT_FAILED,
  deleteProjectresponse,
  CREATE_PROJECT_FAILED,
  updateProjectResponse,
  UPDATE_PROJECT_FAILED,
} from "../actions/ProjectAction";
import { addNotification } from "../../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../../commons/Constants.js";
import * as PopupActions from "../../actions/PopupAction";
import { showVoicebox, hideVoicebox } from "../../actions/VoiceboxAction";
import {
  getFailedInvitesPopupProps,
  getQueryParam,
} from "../../commons/Utility.js";
import {
  trackProjectCreation,
  trackMemberInvited,
} from "../../commons/EventsTracking.js";
import { handleGlobalError } from "../../actions/GlobalErrorAction";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import { updateTimeDifference } from "../../actions/UserAction";
import { updateChatConfigurationListResponse , requestChatConfiguration } from "../actions/ChatConfigurationAction";
import { store } from "../../app.js";

export function* getProjectList() {
  const url = "/user/get/projects";
  console.log("Inside project Saga");
  try {
    let projectFetchWithConfigResponse = yield call(Ajax.get, url);
    let permissionResponse = yield call(Ajax.get, "/project/isAllowedToCreate");
    let isAllowedToCreate = true;
    if (permissionResponse && permissionResponse.data)
      isAllowedToCreate = permissionResponse.data.isAllowedToCreate;

    console.log(
      "The response from the project list :",
      projectFetchWithConfigResponse
    );
    let {
      chatConfigurations,
      projects: projectList,
      widgetStatus
    } = projectFetchWithConfigResponse.data;
    console.log("Data received", projectList);
    console.log("Data received", JSON.stringify(chatConfigurations));
    let chatConfigResponse = chatConfigurations.reduce(
      (processedChatConfig, config) => {
        processedChatConfig[config.key] = config;
        return processedChatConfig;
      },
      {}
    );

    projectList = projectList.filter((project) => project.status != "INACTIVE");
    projectList.forEach((project) => project.widgetStatus = widgetStatus[project.key]);
    yield put(receiveAvailableProject(projectList, isAllowedToCreate));
    yield put(updateChatConfigurationListResponse(chatConfigResponse));
  } catch (e) {
    yield put({ type: PROJECTLIST_FAILED });
    yield put(handleGlobalError(e));
  }
}

export function* getNewlyAddedProjectsList(projectData) {
  const url = "/user/get/new/projects";
  console.log("Inside project Saga");
  try {
    let projectList = yield call(Ajax.post, url, projectData.projectList);
    let permissionResponse = yield call(Ajax.get, "/project/isAllowedToCreate");
    let isAllowedToCreate = true;
    if (permissionResponse && permissionResponse.data)
      isAllowedToCreate = permissionResponse.data.isAllowedToCreate;
    projectList = projectList.data;
    console.log("Data received", projectList);

    projectList = projectList.filter((project) => project.status != "INACTIVE");
    yield put(receiveNewlyAddedProjects(projectList, isAllowedToCreate));
  } catch (e) {
    yield put({ type: PROJECTLIST_FAILED });
    yield put(handleGlobalError(e));
  }
}
export function* getProjectOverflow(requestData) {
  let projectId = requestData.projectId;
  projectId = projectId.startsWith("LS-") ? projectId : "LS-" + projectId;
  const url =
    "/project/overflow/get/" +
    projectId +
    "?connectionId=" +
    getQueryParam("connection_id");
  try {
    let response = yield call(Ajax.getUnauthenticated, url);
    if (response.response == true) {
      let isAllowedToCreate = false;
      response = response.data;
      response = JSON.parse(response);
      console.log(
        "the response overflow project is " +
          JSON.stringify(response.projectEntity)
      );
      let projectList = [response.projectEntity];
      console.log("The project list is " + response.serverTime);
      let timeDifference =
        response.serverTime && parseInt(response.serverTime)
          ? new Date().getTime() - parseInt(response.serverTime)
          : 0;

      yield put(receiveAvailableProject(projectList, isAllowedToCreate));
      yield put(updateTimeDifference(timeDifference));
    } else {
      yield put({ type: PROJECTLIST_FAILED });
      yield put(handleGlobalError(e));
    }
  } catch (e) {
    yield put({ type: PROJECTLIST_FAILED });
    yield put(handleGlobalError(e));
  }
}
export function* deleteProject(details) {
  const projectKey = details.projectDetails.projectkey;
  const url = "project/delete/" + projectKey;
  try {
    yield put(showVoicebox({ message: "deleting widget..." }));
    yield call(Ajax.deleteReq, url);

    yield put(showVoicebox({ message: "Widget deleted!", dismissAfter: 3000 }));
    yield put(deleteProjectresponse(projectKey));
  } catch (e) {
    yield put(hideVoicebox());
    yield put(
      addNotification({
        message: "Widget Deletion Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put({ type: DELETEPROJECT_FAILED });
    yield put(handleGlobalError(e));
  }
}

export function* createProject(projectObject) {
  const url = "/project/create";
  let failedInvitations = [];
  let error = "";
  let invitedStaffs = [];
  let currentWidgetconfiguration = store.getState().ChatConfigurationReducer.data;
  console.log("Inside create project Saga");
  try {
    yield put(showVoicebox({ message: "Creating Widget" }));
    let projectInfo = yield call(Ajax.post, url, projectObject.project);

    console.info("Widget Information =>", projectInfo);

    let project = projectInfo.data.project;

    console.log("Widget=>", project);

    yield put(showVoicebox({ message: "Widget Created!", dismissAfter: 3000 }));

    if (projectInfo.data.invitationInfo) {
      failedInvitations = projectInfo.data.invitationInfo.failedInvites;
      error = projectInfo.data.invitationInfo.error;
      invitedStaffs = projectInfo.data.invitationInfo.lsInvitations
        ? projectInfo.data.invitationInfo.lsInvitations
        : [];

      if (failedInvitations && failedInvitations.length > 0) {
        let popupProps = getFailedInvitesPopupProps(failedInvitations, error);
        yield put(
          PopupActions.showPopup({
            popupProps: popupProps,
            closeOnOkButton: true,
          })
        );
      }
    }

    trackProjectCreation(project);
    trackMemberInvited(invitedStaffs);
    yield put(receiveCreatedProject(project));
    yield put(requestChatConfiguration(project.key , currentWidgetconfiguration));
  } catch (e) {
    console.info("Error during project creation", e);
    yield put(hideVoicebox());
    yield put(
      addNotification({
        message: "Widget Creation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put({ type: "CREATE_PROJECT_FAILED" });
    yield put(handleGlobalError(e));
  }
}

export function* updateProject(data) {
  const url = "/project/update";
  console.log("Inside Update project Saga");
  console.log("The Data we put is " + JSON.stringify(data));
  try {
    const isTimezoneUpdate = data.project.isTimezoneUpdate;
    delete data.project.isTimezoneUpdate;
    yield put(VoiceboxActions.showVoicebox({ message: "Updating" }));
    let project = yield call(Ajax.put, url, data.project);
    project = project.data;

    yield put(updateProjectResponse(project));
    if (!isTimezoneUpdate)
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Update Successful",
          dismissAfter: 2000,
        })
      );
  } catch (e) {
    console.log(e.name);
    yield put(
      addNotification({
        message: "Widget Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put({ type: "UPDATE_PROJECT_FAILED" });
    yield put(handleGlobalError(e));
  }
}
export function* updateLogo(data) {
  const url = "project/logo/update";
  try {
    let project = yield call(Ajax.postFormData, url, data.logoData.formData);
    project = project.data;

    yield put(
      addNotification({
        message: "Update Successful",
        level: NOTIFICATION_LEVEL.Success,
        dismissAfter: 3000,
      })
    );
    yield put(updateProjectResponse(project));
  } catch (e) {
    console.log(e.name);
    yield put(
      addNotification({
        message: "Update Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put({ type: "UPDATE_PROJECT_FAILED" });
    yield put(handleGlobalError(e));
  }
}

export function* captureWebsiteScreenShot(data) {
  const url = "/project/upload/screenshot";
  console.log(
    "The Data we put to capture website url  is" + JSON.stringify(data)
  );
  try {
    let updateResponse = yield call(Ajax.post, url, data.requestObj);
    if (updateResponse.response) {
      let { projectEntity } = updateResponse.data;
      yield put(updateProjectResponse(projectEntity));
    } else {
      //retry the same api
    }
  } catch (e) {
    yield put(
      NotificationActions.addNotification({
        message: "Website url Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}
