import { Formik } from 'formik'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Button } from 'src/features/form/components/Button'
import { ServerMessage } from 'src/features/form/components/ServerMessage'
import { TextInput } from 'src/features/form/components/TextInput'
import {
  IFormValues,
  validationSchema,
} from 'src/features/wizard/install/forms/TestConnection/data'
import {
  InputContainer,
  ButtonContainer,
  StyledForm,
  ServerMessageContainer,
} from 'src/features/wizard/install/forms/TestConnection/styled'
import { EnumStatus } from 'src/features/wizard/install/forms/TestConnection/types'
import { selectDomainUrl } from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export interface IProps {
  handleSubmit: (values: IFormValues) => void
  isLoading: boolean
  status?: keyof typeof EnumStatus
  domainUrl: string
}

export const TestConnectionComponent: FC<IProps> = ({
  isLoading,
  handleSubmit,
  status,
  domainUrl,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={{
      url: domainUrl,
    }}
    validationSchema={validationSchema}
  >
    <>
      {status !== EnumStatus.success && (
        <StyledForm>
          <InputContainer>
            <TextInput placeholder="https://" name="url" />
          </InputContainer>
          <ButtonContainer>
            <Button
              isLoading={isLoading}
              hasFullWidth
              color="primary"
              colorType="hollow"
              type="submit"
            >
              Test connection
            </Button>
          </ButtonContainer>
        </StyledForm>
      )}
      {status === EnumStatus.success && (
        <ServerMessageContainer>
          <ServerMessage
            message="Your widget is successfully installed."
            type="success"
          />
        </ServerMessageContainer>
      )}
      {status === EnumStatus.error && (
        <ServerMessageContainer>
          <ServerMessage
            message="Seems like your widget is not connected. Please check the instructions and try again. Need help? Start a chat with us."
            type="error"
          />
        </ServerMessageContainer>
      )}
    </>
  </Formik>
)

const mapStateToProps = (state: IAppState) => ({
  domainUrl: selectDomainUrl(state.install),
})

export const TestConnection = connect(mapStateToProps)(TestConnectionComponent)
