import React, { FC } from 'react'
import {
  SpinnerContainer,
  SpinnerIcon,
} from 'src/features/ui/components/Spinner/styled'
import { IProps } from 'src/features/ui/components/Spinner/types'

export const Spinner: FC<IProps> = ({ size = 16, fill }) => (
  <SpinnerContainer size={size}>
    <SpinnerIcon type="spinner" width={size} height={size} fill={fill} />
  </SpinnerContainer>
)
