import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getGroupMap } from "../commons/ContactsUtility.js";
import { createPeopleGroupRequest } from "../actions/ContactsAction.js";
import { store } from "../../app.js";
import { SegmentIcon, EditPopupIcon } from "../../commons/Icons.js";

const SaveSegmentPopup = (props) => {
  const { showSaveSegment, setShowSaveSegment, query } = props;
  const {
    params: { projectkey, groupId },
  } = props;
  const [segmentName, setSegmentName] = useState("");
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(false);

  const onEdit = ({ target }) => {
    let { value } = target;
    setSegmentName(value);
  };

  const onCancel = () => {
    resetView();
  };

  const resetView = () => {
    setSegmentName("");
    setShowSaveSegment(false);
  };

  const onSave = () => {
    if (segmentName && segmentName.length > 0) {
      let {
        UserReducer: {
          data: { id: userId },
        },
      } = store.getState();
      let groupInfo = getGroupMap(query, projectkey, segmentName, userId);
      dispatch(createPeopleGroupRequest(groupInfo));
      resetView();
    }
  };

  return (
    <div>
      <div
        className="cs-popupbg"
        style={{ display: showSaveSegment ? "block" : "none" }}
      ></div>
      <div
        className="cs-popup-model popup-new cs-popup-segment"
        style={{ display: showSaveSegment ? "block" : "none" }}
      >
        <header>
          <h2>
            <SegmentIcon />
            Save segment
          </h2>
          <cite onClick={onCancel}>×</cite>
        </header>
        <section>
          <div
            className={
              isActive ? "cs-input-field cs-active-field" : "cs-input-field"
            }
          >
            <label>Name your segment</label>
            <input
              autoFocus
              type="text"
              value={segmentName}
              onChange={onEdit}
              placeholder="Segment Name"
              onFocus={() => {
                setIsActive(true);
              }}
              onBlur={() => {
                setIsActive(false);
              }}
            />
          </div>
        </section>
        <footer>
          <a
            href="javascript:void(0)"
            onClick={onCancel}
            className="cs-btn cs-decline-btn"
          >
            Cancel
          </a>
          <a
            href="javascript:void(0)"
            onClick={onSave}
            className="cs-btn cs-primary-btn"
          >
            Save
          </a>
        </footer>
      </div>
    </div>
  );
};

export default SaveSegmentPopup;
