import { SHOW_CONFIRM_POPUP, HIDE_CONFIRM_POPUP } from "../actions/ConfirmPopupAction";

export const initialState = { popupData: {} }
const ConfirmPopupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_CONFIRM_POPUP:
      return {
          ...state,
        popupData: action.popupData,
        displayPopup: true
      };
    case HIDE_CONFIRM_POPUP:
      return {
          ...state,
        popupData: {},
        displayPopup: false
      };
    default:
      return state;
  }
};

export default ConfirmPopupReducer;
