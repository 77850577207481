import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  isUrlLoadableResponse,
  isUrlLoadableFailed,
} from "../actions/PreviewActions";

import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* isUrlLoadable(requestData) {
  const url = "demo/isUrlLoadable";

  try {
    let response = yield call(Ajax.postUnauthenticated, url, {
      url: requestData.previewUrl,
    });
    if (response.response == true) {
      isUrlLoadable = response.data.isUrlLoadable;
      yield put(isUrlLoadableResponse(requestData.previewUrl, isUrlLoadable));
    } else yield put(isUrlLoadableResponse(requestData.previewUrl, false));
  } catch (e) {
    yield put(isUrlLoadableResponse(requestData.previewUrl, false));
    yield put(handleGlobalError(e));
  }
}
