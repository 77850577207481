export const PEOPLE_LIST_REQUEST = "PEOPLE_LIST_REQUEST";
export const PEOPLE_NEW_LIST_RESPONSE = "PEOPLE_LIST_RESPONSE";
export const PEOPLE_LIST_FAILED = "PEOPLE_LIST_FAILED";
export const PEOPLE_SCROLL_RESPONSE = "PEOPLE_SCROLL_RESPONSE";
export const REQUEST_PEOPLE_LIST_BY_SCROLL = "REQUEST_PEOPLE_LIST_BY_SCROLL";
export const UPDATE_PEOPLE_ENTITY = "UPDATE_PEOPLE_ENTITY";
export const CREATE_PEOPLE_GROUP_REQUEST = "CREATE_PEOPLE_GROUP_REQUEST";
export const CREATE_PEOPLE_GROUP_RESPONSE = "CREATE_PEOPLE_GROUP_RESPONSE";
export const GET_PEOPLE_GROUPS_REQUEST = "GET_PEOPLE_GROUPS_REQUEST";
export const GET_PEOPLE_COUNT_REQUEST = "GET_PEOPLE_COUNT_REQUEST";
export const GET_PEOPLE_COUNT_RESPONSE = "GET_PEOPLE_COUNT_RESPONSE";
export const GET_PEOPLE_GROUP_COUNT_REQUEST = "GET_PEOPLE_GROUP_COUNT_REQUEST";
export const GET_PEOPLE_GROUP_COUNT_RESPONSE =
  "GET_PEOPLE_GROUP_COUNT_RESPONSE";
export const GET_PEOPLE_GROUPS_RESPONSE = "GET_PEOPLE_GROUPS_RESPONSE";
export const UPDATE_PEOPLE_GROUP_REQUEST = "UPDATE_PEOPLE_GROUP_REQUEST";
export const UPDATE_PEOPLE_GROUP_RESPONSE = "UPDATE_PEOPLE_GROUP_RESPONSE";
export const FLUSH_PEOPLE_ENTITY = "FLUSH_PEOPLE_ENTITY";
export const REMOVE_PEOPLE_ENTITY = "REMOVE_PEOPLE_ENTITY";
export const UPDATE_GROUP_SETTINGS_REQUEST = "UPDATE_GROUP_SETTINGS_REQUEST";
export const UPDATE_GROUP_SETTINGS_RESPONSE = "UPDATE_GROUP_SETTINGS_RESPONSE";
export const UPDATE_FIELD_SETTINGS_REQUEST = "UPDATE_FIELD_SETTINGS_REQUEST";
export const UPDATE_FIELD_SETTINGS_RESPONSE = "UPDATE_FIELD_SETTINGS_RESPONSE";
export const EXPORT_CONTACT_REQUEST = "EXPORT_CONTACT_REQUEST";
export const EXPORT_CONTACT_RESPONSE = "EXPORT_CONTACT_RESPONSE";
export const SEND_BROADCAST_MESSAGE_REQUEST = "SEND_BROADCAST_MESSAGE_REQUEST";
export const SEND_BROADCAST_MESSAGE_RESPONSE =
  "SEND_BROADCAST_MESSAGE_RESPONSE";
export const SEND_BROADCAST_MESSAGE_REQUEST_FAILED =
  "SEND_BROADCAST_MESSAGE_REQUEST_FAILED";
export const SEND_BROADCAST_EMAIL_REQUEST = "SEND_BROADCAST_EMAIL_REQUEST";
export const SEND_BROADCAST_EMAIL_RESPONSE = "SEND_BROADCAST_EMAIL_RESPONSE";
export const SEND_TEST_EMAIL_REQUEST = "SEND_TEST_EMAIL_REQUEST";
export const SEND_TEST_EMAIL_RESPONSE = "SEND_TEST_EMAIL_RESPONSE";

export const SEND_BROADCAST_EMAIL_REQUEST_FAILED =
  "SEND_BROADCAST_EMAIL_REQUEST_FAILED";
export const IMPORT_CONTACT_REQUEST = "IMPORT_CONTACT_REQUEST";
export const IMPORT_CONTACT_RESPONSE = "IMPORT_CONTACT_RESPONSE";
export const FETCH_PRESENCE_BY_USERIDS = "FETCH_PRESENCE_BY_USERIDS";
export const FETCH_AND_UDPATE_USER_PRESENCE = "FETCH_AND_UDPATE_USER_PRESENCE";
export const DELETE_PEOPLE_GROUP_REQUEST = "DELETE_PEOPLE_GROUP_REQUEST";
export const DELETE_PEOPLE_GROUP_RESPONSE = "DELETE_PEOPLE_GROUP_RESPONSE";

export const peopleNewListResponse = (peopleResponse) => ({
  type: PEOPLE_NEW_LIST_RESPONSE,
  peopleResponse,
});

export const peopleScrollResponse = (peopleResponse) => ({
  type: PEOPLE_SCROLL_RESPONSE,
  peopleResponse,
});

export const peopleListFailed = (peopleObject) => ({
  type: PEOPLE_LIST_FAILED,
  peopleObject,
});

export const getPeopleByScroll = (scrollReq) => ({
  type: REQUEST_PEOPLE_LIST_BY_SCROLL,
  scrollReq,
});

export const updatePeopleEntity = (peopleEntity) => ({
  type: UPDATE_PEOPLE_ENTITY,
  peopleEntity,
});

export const createPeopleGroupRequest = (groupInfo) => ({
  type: CREATE_PEOPLE_GROUP_REQUEST,
  groupInfo,
});

export const updatePeopleGroupRequest = (existingGroupInfo) => ({
  type: UPDATE_PEOPLE_GROUP_REQUEST,
  existingGroupInfo,
});

export const getPeopleGroupsRequest = (projectId, isForEmailCampaign) => ({
  type: GET_PEOPLE_GROUPS_REQUEST,
  projectId,
  isForEmailCampaign,
});

export const getPeopleGroupsResponse = (peopleGroupsMap) => ({
  type: GET_PEOPLE_GROUPS_RESPONSE,
  peopleGroupsMap,
});

export const createPeopleGroupResponse = (groupInfoResponse) => ({
  type: CREATE_PEOPLE_GROUP_RESPONSE,
  groupInfoResponse,
});

export const updatePeopleGroupResponse = (groupInfoResponse) => ({
  type: UPDATE_PEOPLE_GROUP_RESPONSE,
  groupInfoResponse,
});

export const removePeopleEntity = (visitorIds, currentGroupId) => ({
  type: REMOVE_PEOPLE_ENTITY,
  visitorIds,
  currentGroupId,
});

export const getPeopleCountRequest = (countRequestPayload) => ({
  type: GET_PEOPLE_COUNT_REQUEST,
  peopleGroupsList: countRequestPayload.peopleGroupsList,
  projectId: countRequestPayload.projectId,
});

export const getPeopleGroupCountRequest = (groupsSubList, projectId) => ({
  type: GET_PEOPLE_GROUP_COUNT_REQUEST,
  groupsSubList,
  projectId,
});

export const getPeopleGroupCountResponse = (peopleCountInfo) => ({
  type: GET_PEOPLE_GROUP_COUNT_RESPONSE,
  peopleCountInfo,
});

export const getPeopleCountResponse = (peopleGroupInfo) => ({
  type: GET_PEOPLE_COUNT_RESPONSE,
  peopleGroupInfo,
});

export const flushPeopleEntity = (currentGroupId) => ({
  type: FLUSH_PEOPLE_ENTITY,
  currentGroupId,
});

export const updateGroupSettingsRequest = (groupSettings) => ({
  type: UPDATE_GROUP_SETTINGS_REQUEST,
  groupSettings,
});

export const updateGroupSettingsResponse = (groupSettingsResponse) => ({
  type: UPDATE_GROUP_SETTINGS_RESPONSE,
  hiddenGroupIds: groupSettingsResponse.hiddenGroupIds,
});

export const updateFieldSettingsRequest = (fieldSettings) => ({
  type: UPDATE_FIELD_SETTINGS_REQUEST,
  fieldSettings,
});

export const updateFieldSettingsResponse = (fieldSettingsResponse) => ({
  type: UPDATE_FIELD_SETTINGS_RESPONSE,
  visibleFields: fieldSettingsResponse.visibleFields,
});

export const exportContactRequest = (esQueryInfo) => ({
  type: EXPORT_CONTACT_REQUEST,
  esQueryInfo,
});

export const exportContactResponse = (exportContactResponse) => ({
  type: EXPORT_CONTACT_RESPONSE,
  exportContactResponse,
});

export const sendBroadcastMessage = (sendBroadcastMessageData, projectId) => ({
  type: SEND_BROADCAST_MESSAGE_REQUEST,
  sendBroadcastMessageData,
  projectId,
});

export const sendBroadcastMessageFailed = () => ({
  type: SEND_BROADCAST_MESSAGE_REQUEST_FAILED,
});

export const sendBroadcastEmail = (sendBroadcastMessageData, projectId) => ({
  type: SEND_BROADCAST_EMAIL_REQUEST,
  sendBroadcastMessageData,
  projectId,
});

export const sendBroadcastEmailFailed = () => ({
  type: SEND_BROADCAST_EMAIL_REQUEST_FAILED,
});

export const importContactRequest = (contacts, projectId) => ({
  type: IMPORT_CONTACT_REQUEST,
  contacts,
  projectId,
});

export const importContactResponse = (importContactsResponse) => ({
  type: IMPORT_CONTACT_RESPONSE,
  importContactsResponse,
});

export const fetchPresenceByUserIds = (
  userIds,
  projectId,
  isFromOverflow,
  isAwWindow
) => ({
  type: FETCH_PRESENCE_BY_USERIDS,
  isFromOverflow,
  userIds,
  projectId,
  isAwWindow,
});

export const fetchAndUpdateUserPresence = (
  userId,
  takingChatStatus,
  isTakingChat,
  projectId
) => ({
  type: FETCH_AND_UDPATE_USER_PRESENCE,
  userId,
  takingChatStatus,
  isTakingChat,
  projectId,
});

export const sendTestEmailRequest = (sendMessageData, projectId) => ({
  type: SEND_TEST_EMAIL_REQUEST,
  sendMessageData,
  projectId,
});

export const deleteGroupRequest = (groupId, projectId, mainGroupId) => ({
  type: DELETE_PEOPLE_GROUP_REQUEST,
  groupId,
  projectId,
  mainGroupId,
});

export const deleteGroupResponse = (groupId) => ({
  type: DELETE_PEOPLE_GROUP_RESPONSE,
  groupId,
});
