import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

class CommonDropDown extends Component {
  constructor() {
    super();
    this.state = {
      valueMap: {},
      value: "",
      filteredValueMap: {},
      showDropDown: false,
      valueKey: "",
      type: "",
      inputFocused: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.isAlreadyTyped = this.isAlreadyTyped.bind(this);
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.onClear = this.onClear.bind(this);
  }
  componentWillMount() {
    this.setLocalState(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.setLocalState(nextProps);
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextState.inputFocused) ReactTooltip.hide();
    else ReactTooltip.rebuild();
  }
  setLocalState(props) {
    let valueKey = this.state.valueKey
      ? this.state.valueKey
      : props.initialKey
      ? props.initialKey
      : "";
    let valueMap = Object.assign({}, props.valueMap);
    let typeMap = Object.assign({}, props.typeMap ? props.typeMap : {});
    let type = typeMap[valueKey] ? typeMap[valueKey] : "";
    let presentValue = this.state.value
      ? this.state.value
      : valueKey && valueMap[valueKey]
      ? valueMap[valueKey]
      : "";
    let filteredValueMap = {};
    let isMultipleValue = props.isMultipleValue;
    let values = presentValue.split(",");
    let lastValue = values[values.length - 1];
    for (let key in valueMap) {
      let typedValues = this.state.value.split(",");
      if (
        valueMap[key] &&
        valueMap[key]
          .toLowerCase()
          .includes(
            isMultipleValue
              ? lastValue.toLowerCase().trim()
              : presentValue.toLowerCase().trim()
          ) &&
        (this.props.isMultipleValue
          ? !this.isAlreadyTyped(valueMap[key], typedValues)
          : true)
      )
        filteredValueMap[key] = valueMap[key];
    }
    this.setState({
      ...this.state,
      valueMap: valueMap,
      value: presentValue,
      valueKey: valueKey,
      type: type,
      filteredValueMap: filteredValueMap,
      typeMap: typeMap,
    });
  }
  renderDropDown(valueMap, typeMap) {
    let toRender = [];
    for (let key in valueMap)
      toRender.push(
        <li
          key={key}
          data-value={valueMap[key]}
          data-value_key={key}
          data-value_type={typeMap[key] ? typeMap[key] : ""}
          onMouseDown={this.onSelect}
        >
          <cite className="tags-icon"></cite>
          {valueMap[key]}
        </li>
      );

    return toRender;
  }
  onFocus(e) {
    this.setState({
      ...this.state,
      showDropDown: this.props.showOnFocus ? true : this.state.showDropDown,
      inputFocused: true,
    });
  }
  onBlur(e) {
    let isMultipleValue = this.props.isMultipleValue;
    if (this.props.selectOnBlur) {
      let valueKey = Object.keys(this.state.filteredValueMap)[0];
      let value =
        valueKey && this.state.filteredValueMap[valueKey]
          ? this.state.filteredValueMap[valueKey]
          : "";
      let type =
        valueKey && this.state.typeMap[valueKey]
          ? this.state.typeMap[valueKey]
          : "";
      let selectedValue = value;
      console.log("valueKey: ", valueKey);
      let isMultipleValue = this.props.isMultipleValue;
      if (isMultipleValue) {
        let values = this.state.value.split(",");
        values.pop();
        if (this.props.appendMultipleValues) value = values.join(",");
        else value = this.state.value;
      }
      this.setState(
        {
          ...this.state,
          value: value,
          valueKey: valueKey,
          type: type,
          filteredValueMap: {},
          showDropDown: false,
          inputFocused: false,
        },
        () => {
          this.props.onSelect
            ? this.props.onSelect(selectedValue, valueKey, type)
            : null;
          if (this.props.onBlur) this.props.onBlur(e);
        }
      );
    } else if (this.props.enterOnBlur && e.target.dataset.value) {
      let value = e.target.dataset.value;
      let valueKey = e.target.dataset.value_key;
      let type = e.target.dataset.value_type;
      let valueMap = this.state.valueMap;
      let filteredValueMap = {};
      let values = value.split(",");
      let lastValue = values[values.length - 1];

      for (let key in valueMap) {
        let typedValues = this.state.value.split(",");
        if (
          valueMap[key] &&
          valueMap[key]
            .toLowerCase()
            .includes(
              isMultipleValue
                ? lastValue.toLowerCase().trim()
                : searchInput.toLowerCase().trim()
            ) &&
          (isMultipleValue
            ? !this.isAlreadyTyped(valueMap[key], typedValues)
            : true)
        )
          filteredValueMap[key] = valueMap[key];
      }
      this.setState(
        {
          ...this.state,
          value: "",
          valueKey: "",
          type: "",
          filteredValueMap: filteredValueMap,
          showDropDown: false,
          inputFocused: false,
        },
        () => {
          if (this.props.enterKeyHandler)
            this.props.enterKeyHandler(value, valueKey, type);
          if (this.props.onBlur) this.props.onBlur(e);
        }
      );
    } else {
      this.setState(
        { ...this.state, showDropDown: false, inputFocused: false },
        () => {
          if (this.props.onBlur) this.props.onBlur(e);
        }
      );
    }
  }
  onSelect(e) {
    ReactTooltip.hide();
    let value = e.currentTarget.dataset.value;
    let selectedValue = value;
    let valueKey = e.currentTarget.dataset.value_key;
    let type = e.currentTarget.dataset.value_type;
    let isMultipleValue = this.props.isMultipleValue;
    if (isMultipleValue) {
      let values = value.split(",");
      values.pop();
      if (this.props.appendMultipleValues) value = values.join(",");
      else value = value;
    }
    this.setState(
      {
        ...this.state,
        value: value,
        valueKey: valueKey,
        type: type,
        filteredValueMap: {},
        showDropDown: false,
        inputFocused: false,
      },
      () => {
        this.props.onSelect
          ? this.props.onSelect(selectedValue, valueKey, type)
          : null;
      }
    );
  }
  onInputChange(e) {
    let searchInput = e.currentTarget.value;
    let valueMap = this.state.valueMap;
    let filteredValueMap = {};
    let isMultipleValue = this.props.isMultipleValue;
    let values = searchInput.split(",");
    let lastValue = values[values.length - 1];
    let typedValues = searchInput.split(",");
    for (let key in valueMap)
      if (
        valueMap[key] &&
        valueMap[key]
          .toLowerCase()
          .includes(
            isMultipleValue
              ? lastValue.toLowerCase().trim()
              : searchInput.toLowerCase().trim()
          ) &&
        (isMultipleValue
          ? !this.isAlreadyTyped(valueMap[key], typedValues)
          : true)
      )
        filteredValueMap[key] = valueMap[key];
    let valueKey = Object.keys(filteredValueMap)[0];
    let type = this.state.typeMap[valueKey] ? this.state.typeMap[valueKey] : "";
    this.setState({
      ...this.state,
      value: searchInput,
      valueKey: valueKey,
      type: type,
      filteredValueMap: filteredValueMap,
      showDropDown: true,
      inputFocused: true,
    });
  }
  onKeyPressHandler(e) {
    console.log("inside key press");
    if (e.key === "Enter") {
      let value = e.currentTarget.dataset.value;
      let valueKey = e.currentTarget.dataset.value_key;
      let type = e.currentTarget.dataset.value_type;
      this.setState(
        {
          ...this.state,
          value: "",
          valueKey: "",
          type: "",
          showDropDown: false,
        },
        () => {
          if (this.props.enterKeyHandler)
            this.props.enterKeyHandler(value, valueKey, type);
        }
      );
    } else if (e.key == "Escape") e.currentTarget.blur();
  }
  onAddButtonClick() {
    let element = this.refs[
      this.props.inputRefValue ? this.props.inputRefValue : "searchInput"
    ];
    let value = element.dataset.value;
    let valueKey = element.dataset.value_key;
    let type = element.dataset.value_type;
    this.setState(
      {
        ...this.state,
        value: "",
        valueKey: "",
        type: "",
        showDropDown: false,
        inputFocused: false,
      },
      () => {
        if (this.props.enterKeyHandler)
          this.props.enterKeyHandler(value, valueKey, type);
      }
    );
  }
  isAlreadyTyped(testValue, valueList) {
    /*         let typedValues = this.state.value.split(','); */
    if (valueList.length > 1)
      for (let typedValue of valueList)
        if (typedValue.toLowerCase().trim() == testValue.toLowerCase().trim())
          return true;
    return false;
  }
  onClear(e) {
    this.setState(
      { ...this.state, value: "", valueKey: "", type: "", inputFocused: true },
      () => {
        this.refs[
          this.props.inputRefValue ? this.props.inputRefValue : "searchInput"
        ].focus();
        this.props.onClear();
      }
    );
  }
  render() {
    let valueMapKeyLength = Object.keys(this.state.valueMap).length;
    let filteredValueMapKeyLength = Object.keys(this.state.filteredValueMap)
      .length;
    let disableInput = this.props.disableInputforEmpty
      ? valueMapKeyLength < 1
      : false;

    return (
      <div
        tabIndex="0"
        onBlur={this.onBlur}
        style={this.props.inlineStyle ? this.props.inlineStyle : {}}
        className={
          "tag-input " +
          (this.props.classNames && this.props.classNames.length > 0
            ? " " + this.props.classNames.join(" ")
            : "") +
          (this.state.showDropDown && filteredValueMapKeyLength > 0
            ? " open"
            : "")
        }
      >
        <code
          className={"icon-" + (this.state.value ? "remove" : "search")}
          onClick={this.onClear}
        ></code>
        <input
          data-delay-hide={0}
          disabled={disableInput}
          style={{ opacity: disableInput ? "0.5" : "1.0" }}
          ref={
            this.props.inputRefValue ? this.props.inputRefValue : "searchInput"
          }
          data-value={this.state.value}
          data-value_key={this.state.valueKey}
          data-value_type={
            this.state.typeMap[this.state.valueKey]
              ? this.state.typeMap[this.state.valueKey]
              : ""
          }
          aria-label="search"
          type="text"
          onFocus={this.onFocus}
          onKeyDown={this.onKeyPressHandler}
          onChange={this.onInputChange}
          value={this.state.value}
          placeholder={
            this.props.placeHolder ? this.props.placeHolder : "Input here"
          }
          className={
            this.state.value && this.props.nonEmptyinputStyle
              ? this.props.nonEmptyinputStyle
              : ""
          }
        />
        <div>
          <ul>
            {this.renderDropDown(
              this.state.filteredValueMap,
              this.state.typeMap
            )}
          </ul>
        </div>
      </div>
    );
  }
}
export default CommonDropDown;
