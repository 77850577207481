import React, { FC, useState } from 'react'
import {
  Bottom,
  Container,
  Label,
  StyledIcon,
  Top,
} from 'src/features/ui/components/ToggleView/styled'
import { IProps } from 'src/features/ui/components/ToggleView/types'

export const ToggleView: FC<IProps> = ({ children, isVisibleDefault }) => {
  const [isVisible, setIsVisible] = useState(Boolean(isVisibleDefault))

  const handleTopClick = () => setIsVisible(true)

  return (
    <Container>
      {!isVisible && (
        <Top onClick={handleTopClick}>
          <StyledIcon width={8} height={13} type="chevron" fill="black" />
          <Label>Show more options</Label>
        </Top>
      )}
      {isVisible && <Bottom>{children}</Bottom>}
    </Container>
  )
}
