import {
  PROJECT_EVENT_REQUEST,
  PROJECT_EVENT_RESPONSE,
  PROJECT_EVENT_FAILED,
  UPDATE_PROJECT_EVENT_REQUEST,
  UPDATE_PROJECT_EVENT_RESPONSE,
  UPDATE_PROJECT_EVENT_FAILED,
  DELETE_PROJECT_EVENT_REQUEST,
  DELETE_PROJECT_EVENT_RESPONSE,
  DELETE_PROJECT_EVENT_FAILED,
} from "../actions/ProjectEventsAction.js";

const ProjectEventReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isUpdated: false,
    isDeleting: false,
    isDeleted: false,
    projectId: "",
    projectEventMap: {},
  },
  action
) => {
  switch (action.type) {
    case PROJECT_EVENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        projectEventMap: {},
        projectId: action.projectId,
      };
    case PROJECT_EVENT_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        projectEventMap: Object.assign({}, action.projectEventsMap),
      };
    case PROJECT_EVENT_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case UPDATE_PROJECT_EVENT_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_PROJECT_EVENT_RESPONSE: {
      let projectEventMap = Object.assign({}, state.projectEventMap);
      projectEventMap[action.projectEvent.key] = action.projectEvent;
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        projectEventMap: projectEventMap,
      };
    }
    case UPDATE_PROJECT_EVENT_FAILED:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
      };
    case DELETE_PROJECT_EVENT_REQUEST:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
      };
    case DELETE_PROJECT_EVENT_RESPONSE: {
      let projectEventMap = Object.assign({}, state.projectEventMap);
      delete projectEventMap[action.projectEventId];
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
        projectEventMap: projectEventMap,
      };
    }
    case DELETE_PROJECT_EVENT_FAILED:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
      };
  }
  return state;
};

export default ProjectEventReducer;
