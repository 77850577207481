import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import {ErrorIcon} from '../../commons/Icons'
import {
  getAppMode,
  initiateClipboard,
  isValidEmail,
  getProjectId,
} from "../../commons/Utility";
import Clipboard from "clipboard";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import * as SetupActions from "../../actions/SetupAction";
import * as peopleAction from "../../actions/PeopleAction";

@connect((state) => ({
  setupReducer: state.SetupReducer,
  user: state.UserReducer,
}))
class EmbedSetup extends Component {
  constructor() {
    super();
    this.state = { devEmail: "" , hasInvalidEmail : false , isEmailFocused : false };
    this.checkIntegration = this.checkIntegration.bind(this);
    this.devEmailInputChange = this.devEmailInputChange.bind(this);
    this.getChatCode = this.getChatCode.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.getSkipLink = this.getSkipLink.bind(this);
    this.sendMailOnEnter = this.sendMailOnEnter.bind(this);
    this.onEmailBlur = this.onEmailBlur.bind(this);
    this.onEmailFocus = this.onEmailFocus.bind(this);
  }
  getServerUrl() {
    if (getAppMode() == "live") return "https://api.chatsupport.co/api";
    else return "https://staging.api.chatsupport.co/api";
  }
  componentWillMount() {
    initiateClipboard(
      ".copyScript",
      () => {
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: "Widget code copied to clipboard",
            dismissAfter: 3000,
            showFullScreenLoader: true,
          })
        );
      },
      () => {
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: "Please use CTRL/CMD+C to copy.",
            dismissAfter: 3000,
            showFullScreenLoader: true,
            showError: true,
          })
        );
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.setupReducer.isFetching &&
      !nextProps.setupReducer.isFetching &&
      this.state.checkRequsted
    ) {
      if (
        nextProps.setupReducer.setupState &&
        nextProps.setupReducer.setupState.isEmbedded
      )
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: "Embedded successfully",
            dismissAfter: 3000,
            showFullScreenLoader: true,
          })
        );
      else
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: "Not Embedded",
            showFullScreenLoader: true,
            dismissAfter: 3000,
            showFullScreenLoader: true,
            showError: true,
          })
        );

      this.setState({ checkRequsted: false });
    }
  }
  checkIntegration() {
    this.setState({ checkRequsted: true });
    this.props.dispatch(
      VoiceboxActions.showVoicebox({
        message: "Checking...",
        dismissAfter: 3000,
        showFullScreenLoader: true,
      })
    );
    this.props.dispatch(
      SetupActions.setupRequest("LS-" + this.props.params.projectkey)
    );
  }

  devEmailInputChange(e) {
    if (e.target.value || e.target.value == "") {
      let devEmail = e.target.value.trim();
      this.setState({ devEmail });
    }
  }

  sendMailOnEnter(e) {
    this.setState({ hasInvalidEmail: false });
    if (e.keyCode == 13) {
      this.sendEmail();
    }
  }

  sendEmail() {
    if (isValidEmail(this.state.devEmail)) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "Sending Chat Script...",
          dismissAfter: 1000,
          showFullScreenLoader: true,
        })
      );
      this.props.dispatch(
        SetupActions.mailChatScriptRequest(
          this.state.devEmail,
          getProjectId(this.props.params.projectkey)
        )
      );
      //browserHistory.push( { pathname : '/app/' + this.props.params.projectkey + "/widget/customise/info" } );
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "Widget code sent",
          showFullScreenLoader: true,
          dismissAfter: 2000,
        })
      );
      this.setState({ hasInvalidEmail: false });
    } else {
      this.setState({ hasInvalidEmail: true });
    }
  }
  getChatCode() {
    return (
      "&lt;script&gt; ( function( a , b , c , d , e , f , g ) { c[d] = c[d] || function() { (c[d].q = c[d].q || []).push(arguments); }; c[ '_lsAlias' ] = c[ d ]; e = a.createElement(b); e.type = 'text/javascript'; e.async = true; e.src = '" +
      this.getServerUrl() +
      "/client/get/script/LS-" +
      this.props.params.projectkey +
      "'; f = function() { g = a.getElementsByTagName(b)[0]; g.parentNode.insertBefore( e , g ); }; c.addEventListener( 'load' , f ); } )( document , 'script' , window , '_ls' ); _ls( 'init' , { 'projectId' :'LS-" +
      this.props.params.projectkey +
      "' } ); &lt;/script&gt;"
    );
  }

  getSkipLink() {
    let { setupReducer } = this.props;
    if (setupReducer && setupReducer.setupState) {
      if (setupReducer.setupState.isChatConfigured == false)
        return (
          "/app/" + this.props.params.projectkey + "/widget/customise/info"
        );

      if (setupReducer.setupState.isPromptConfigured == false)
        return "/app/" + this.props.params.projectkey + "/widget/prompt/info";

      return "/app/" + this.props.params.projectkey + "/widget";
    }
  }

  onEmailFocus() {
    this.setState({ isEmailFocused : true , hasInvalidEmail : false })
  }

  onEmailBlur() {
    this.setState({ isEmailFocused : false })
  }

  getDivClassName() {
    let className = "cs-input-field";
    if(this.state.hasInvalidEmail){
      className += " cs-error-field" 
    }
    else if( this.state.isEmailFocused){
      className += " cs-active-field" 
    }
   
    return className;
  }

  render() {
    return (
      // <section className="main-content">
      //   <div className="setup-wrp">
      //     <h2>Install ChatSupport On Your Website</h2>
      //     {/* <p>Deploy live chat on your website</p> */}
      //     <div className="embed-chat-content">
      //       <section style={{ display: "table", margin: "0px auto" }}>
      //         {/* <span>Step 1/3</span> */}
      //         {/* <Link to={"/app/"+this.props.params.projectkey+"/widget/embed/info"} activeClassName="active" className="back-btn" >Back</Link> */}

      //         <div className="embed-chat">
      //           <h4>Integrate the widget code</h4>
      //           <p>
      //             Copy the code and add before the <b>&lt;/body&gt;</b> tag of
      //             your website’s source code to embed the widget. Once embedded,
      //             the widget allows you to discover who your visitors are, along
      //             with their location, page visits, Operating System, and other
      //             device-specific information automatically.
      //           </p>
      //           <span>
      //             <div
      //               ref="script_div"
      //               className="codeTxt fs-exclude"
      //               dangerouslySetInnerHTML={{ __html: this.getChatCode() }}
      //             ></div>
      //             {Clipboard.isSupported() ? (
      //               <i
      //                 className="copy-icon copyScript"
      //                 data-clipboard-target=".codeTxt"
      //               ></i>
      //             ) : null}
      //           </span>
      //           {/*<a href="#" className="setup-btn" onClick={ this.checkIntegration } >Copy</a>*/}
      //           <a
      //             href="javascript:void(0)"
      //             className="setup-btn copyScript"
      //             data-clipboard-target=".codeTxt"
      //           >
      //             Copy
      //           </a>
      //           {/* <Link to={this.getSkipLink()} activeClassName="active" className="skip-btn" >Skip</Link> */}
      //         </div>
      //         <div
      //           className="embed-chat"
      //           style={{ margin: "0px 0px 0px 100px" }}
      //         >
      //           <h4>Email the widget code</h4>
      //           <p>
      //             Already have a website guy at your disposal? Email the widget
      //             code to your website developer along with installation
      //             instructions and activate the chat widget on your website.
      //           </p>
      //           <input
      //             className="fs-exclude"
      //             type="text"
      //             placeholder="Enter the Email ID"
      //             onChange={this.devEmailInputChange}
      //             onKeyDown={this.sendMailOnEnter}
      //             value={this.state.devEmail ? this.state.devEmail : ""}
      //           />
      //           {this.state.devEmail ? (
      //             <a
      //               className={
      //                 isValidEmail(this.state.devEmail)
      //                   ? "setup-btn"
      //                   : "setup-btn disable"
      //               }
      //               onClick={this.sendEmail}
      //               href="#"
      //             >
      //               Email Code
      //             </a>
      //           ) : null}
      //         </div>
      //         <div className="clear"></div>
      //       </section>
      //     </div>
      //   </div>
      // </section>
      <div className="cs-settings-wrp">
          <section className="widget-configuration">
              <h3>Install Your Widget</h3>
              <p>Adding ChatSupport to your site is easy. Just follow the steps below.</p>
              <div className="cs-widget-config">
                <div>
                  <label>Integrate Chat Widget</label>
                  <p>Copy the code and add before the <b>&lt;/body&gt;</b> tag of your websites source code to install the widget. Once installed, the widget will appear on every page allowing you to chat with your website visitors.</p>
                </div>
                <span className="embed-code">
                  <div
                    ref="script_div"
                    className="codeTxt fs-exclude"
                    dangerouslySetInnerHTML={{ __html: this.getChatCode() }}
                  ></div>
                  {Clipboard.isSupported() ? ( <i className="copy-icon copyScript" data-clipboard-target=".codeTxt"></i> ) : null}
                </span>
              </div>
              <div className="cs-widget-config cs-or">
                  <div><label>OR</label></div>
              </div>
              <div className="cs-widget-config email-inputid">
                <div>
                  <label>Email the widget code</label>
                  <p>Email the code to your website developer along with instructions to install the widget on your site.</p>
                </div>
              
                <div className={this.getDivClassName()}>
                  <input
                  className="fs-exclude"
                  type="text"
                  placeholder="Enter Email"
                  onChange={this.devEmailInputChange}
                  onKeyDown={this.sendMailOnEnter}
                  value={this.state.devEmail ? this.state.devEmail : ""}
                  onFocus={this.onEmailFocus}
                  onBlur={this.onEmailBlur}
                />
                  {this.state.hasInvalidEmail ? (
                    <span>
                      <ErrorIcon/>
                    Please enter a valid email id
                  </span>
                  ) : null}
                </div>
                {/* <a className="cs-btn cs-primary-btn" id="">Email Code</a> */}
                
              </div>
          </section>
      </div>
    );
  }
}
export { EmbedSetup };
