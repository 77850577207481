import { AxiosResponse } from 'axios'
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { selectWidgetId } from 'src/features/authorization/store/selectors'
import {
  getWidgetConfig,
  postGoogleStorage,
  postStorageLocate,
  putWebsitePreview,
  putWidgetConfig,
} from 'src/features/wizard/setup/api'
import {
  IFullStorageResponse,
  IGetWidgetConfigResponse,
  IPostStorageLocateResponse,
  ISetupResponseError,
  IWebsitePreviewResponse,
} from 'src/features/wizard/setup/api/types'
import * as actions from 'src/features/wizard/setup/store/actions'
import { denormalizeSetupValuesToWidgetData } from 'src/features/wizard/setup/store/denormalize'
import { history } from 'src/history'
import { createWidgetInstallationRoute } from 'src/pages/WidgetInstallation/data'
import { UNKNOWN_ERROR } from 'src/utils/api/constants'
import { IAppState } from 'src/utils/state/store'

export function* sagaRequestUploadIcon(
  action: ReturnType<typeof actions.requestUploadIcon>
): any {
  const state: IAppState = yield select()
  const widgetId = selectWidgetId(state.auth)!

  try {
    const {
      data: {
        data: { formParams },
      },
    }: AxiosResponse<IPostStorageLocateResponse> = yield call(
      postStorageLocate,
      {
        fileName: action.payload.file.name,
        projectId: widgetId,
      }
    )
    const {
      data: {
        data: {
          file: { img_serve_url: iconUrl },
        },
      },
    }: AxiosResponse<IFullStorageResponse> = yield call(postGoogleStorage, {
      ...formParams,
      file: action.payload.file,
    })

    yield put(actions.requestUploadIconSuccess(iconUrl, action.payload.color))
  } catch (error) {
    const apiError = error as ISetupResponseError
    yield put(actions.setError(apiError?.message || UNKNOWN_ERROR))
  }
}

export function* sagaRequestUpdatePreview(
  action: ReturnType<typeof actions.requestUpdatePreview>
): any {
  const state: IAppState = yield select()
  const widgetId = selectWidgetId(state.auth)!

  try {
    const {
      data: {
        data: { websiteScreenshotURL },
      },
    }: AxiosResponse<IWebsitePreviewResponse> = yield call(putWebsitePreview, {
      domainName: action.payload.websiteUrl,
      projectId: widgetId,
    })

    yield put(actions.requestUpdatePreviewSuccess(websiteScreenshotURL))
  } catch (error) {
    const apiError = error as ISetupResponseError
    yield put(actions.setError(apiError?.message || UNKNOWN_ERROR))
  }
}

export function* sagaRequestUpdateWidget(
  action: ReturnType<typeof actions.requestUpdateWidget>
): any {
  const state: IAppState = yield select()
  const widgetId = selectWidgetId(state.auth)!

  try {
    const {
      data: { data: widgetConfigData },
    }: AxiosResponse<IGetWidgetConfigResponse> = yield call(
      getWidgetConfig,
      widgetId
    )
    yield call(putWidgetConfig, {
      widgetId,
      values: denormalizeSetupValuesToWidgetData(
        action.payload.values,
        widgetConfigData
      ),
    })

    yield put(actions.requestUpdateWidgetSuccess(action.payload.type))
    history.push(createWidgetInstallationRoute(widgetId))
  } catch (error) {
    const apiError = error as ISetupResponseError
    yield put(actions.setError(apiError?.message || UNKNOWN_ERROR))
  }
}

/* istanbul ignore next */
export function* setupSagas() {
  yield takeLatest(actions.REQUEST_UPLOAD_ICON, sagaRequestUploadIcon)
  yield takeLatest(actions.REQUEST_UPDATE_PREVIEW, sagaRequestUpdatePreview)
  yield takeLatest(actions.REQUEST_UPDATE_WIDGET, sagaRequestUpdateWidget)
}
