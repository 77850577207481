import React, { Component } from "react";
import ReactDOM from "react-dom";

class Notifications extends Component {
  render() {
    console.log("props=>", this.props);
    return (
      <div className="notifyWrapper">
        <ul>
          {this.props.notifications.map((notification) => (
            <li
              className={notification.level + " active"}
              id={notification.notificationId.toString()}
              key={notification.notificationId.toString()}
            >
              <span>{notification.message}</span>
              <code onClick={this.props.closeNotification}>&times;</code>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Notifications;
