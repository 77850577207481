import React, { Component } from "react";
import ReactDOM from "react-dom";
import { browserHistory } from "react-router";
import AdminAccessComponent from "../components/admin/AdminAccessComponent.jsx";
import { connect } from "react-redux";
import {} from "../commons/Constants";
import {
  isValidEmail,
  isValidProjectId,
  isValidObject,
  getIconUrl,
} from "../commons/Utility";
import * as UserActions from "../actions/UserAction";
import * as ProjectActions from "../settings/actions/ProjectAction.js";
import * as LoginActions from "../actions/LoginAction";
import * as AdminActions from "../actions/AdminAction";
import { showVoicebox } from "../actions/VoiceboxAction";

@connect((state) => ({
  userReducer: state.UserReducer,
  adminReducer: state.AdminReducer,
  projectReducer: state.ProjectReducer,
}))
class AdminAccessContainer extends Component {
  constructor() {
    super();
    this.state = {
      userEmail: "",
      searchValue: "",
      searchedValue: "",
      searchedType: "",
      errorMessage: "",
      isChatWidgetHidden: false,
      showSearchList: false,
    };
    this.setLocalState = this.setLocalState.bind(this);
    this.onSearchValueChange = this.onSearchValueChange.bind(this);
    this.switchUser = this.switchUser.bind(this);
    this.myAccount = this.myAccount.bind(this);
    this.checkAndFetchAdminDetails = this.checkAndFetchAdminDetails.bind(this);
    this.logout = this.logout.bind(this);
    this.checkAndHideWidget = this.checkAndHideWidget.bind(this);
    this.showSearchList = this.showSearchList.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentWillMount() {
    if (!this.props.userReducer.isFetched)
      this.props.dispatch(UserActions.getUser());
    else this.props.userReducer.data.isRedirectedToAdminPage = true;
    if (!this.props.projectReducer.isFetched)
      this.props.dispatch(ProjectActions.requestAvailableProject());
    this.checkAndRedirect(this.props);
    this.checkAndHideWidget(this.props);
    this.setLocalState(this.props);
  }
  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
  }
  componentWillReceiveProps(nextProps) {
    if (
      (!this.props.userReducer.isFetched && nextProps.userReducer.isFetched) ||
      this.props.adminReducer.isFetching != nextProps.adminReducer.isFetching
    )
      this.setLocalState(nextProps);
    if (!this.props.userReducer.isFetched && nextProps.userReducer.isFetched)
      nextProps.userReducer.data.isRedirectedToAdminPage = true;
    this.checkAndRedirect(nextProps);
    this.checkAndHideWidget(nextProps);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }
  handleClick(event) {
    if (
      this.state.showSearchList &&
      !ReactDOM.findDOMNode(this.child.refs.serachInputDiv).contains(
        event.target
      )
    )
      this.setState({ ...this.state, showSearchList: false });
  }
  checkAndRedirect(props) {
    if (props.userReducer.data)
      if (props.userReducer.data.isAdmin != "true")
        browserHistory.push("/choose/profile");
      else if (props.userReducer.data.originalId) {
        if (
          props.projectReducer.projectList &&
          props.projectReducer.projectList.length > 0
        )
          browserHistory.push(
            "/app/" +
              props.projectReducer.projectList[0].key.replace("LS-", "") +
              "/settings"
          );
      }
  }
  checkAndHideWidget(props) {
    if (
      props.userReducer.data &&
      props.userReducer.data.isAdmin === "true" &&
      !this.state.isChatWidgetHidden &&
      typeof _ls !== "undefined"
    ) {
      _ls("showCsWidget", false);
      this.setState({ isChatWidgetHidden: true });
    }
  }
  setLocalState(props) {
    if (isValidObject(props.userReducer.data))
      console.log(props.userReducer.data.login);
    this.setState(
      {
        ...this.state,
        userEmail: isValidObject(props.userReducer.data)
          ? props.userReducer.data.login
          : "",
        optionList:
          props.adminReducer.searchedType == "EMAIL_ID"
            ? props.adminReducer.projectList
              ? props.adminReducer.projectList
              : []
            : props.adminReducer.staffList
            ? props.adminReducer.staffList
            : [],
        isFetching: props.adminReducer.isFetching,
        errorMessage: props.adminReducer.errorMessage,
        searchedValue: props.adminReducer.searchedValue,
        searchedType: props.adminReducer.searchedType,
      },
      () => {
        console.log("state:::", this.state);
      }
    );
  }

  onSearchValueChange(e) {
    if (e.type == "change")
      this.setState({ searchValue: e.target.value, errorMessage: "" });
    else if (e.type == "keypress" && e.key === "Enter") {
      this.checkAndFetchAdminDetails();
    }
  }
  checkAndFetchAdminDetails() {
    let searchValue = this.state.searchValue.trim();
    if (searchValue != this.state.searchedValue) {
      if (isValidEmail(searchValue)) {
        searchValue = searchValue.toLowerCase();
        this.setState(
          { ...this.state, searchValue, showSearchList: true },
          () => {
            this.props.dispatch(
              AdminActions.requestProjectForEmail(searchValue)
            );
          }
        );
        return;
      } else if (isValidProjectId(searchValue)) {
        this.setState(
          { ...this.state, searchValue, showSearchList: true },
          () => {
            this.props.dispatch(
              AdminActions.requestStaffForProject(searchValue)
            );
          }
        );
        return;
      }
      //this.props.dispatch(showVoicebox({message:"Invalid search value!", dismissAfter : 3000}));
      this.setState({
        ...this.state,
        showSearchList: false,
        errorMessage: "Invalid search value!",
      });
    } else if (!searchValue)
      this.setState({
        ...this.state,
        showSearchList: false,
        errorMessage: "Please enter value!",
      });
    //this.props.dispatch(showVoicebox({message:"Please enter value", dismissAfter : 3000}));
    else this.setState({ ...this.state, showSearchList: true, searchValue });
  }
  showSearchList(e) {
    this.setState({ ...this.state, showSearchList: true });
  }

  switchUser(e) {
    let selectedProjectid = "";
    if (this.state.searchedType == "EMAIL_ID") {
      selectedProjectid = e.target.dataset.value;
      this.props.dispatch(
        UserActions.switchUser(
          this.state.searchedValue,
          e.target.dataset.value.replace("LS-", "")
        )
      );
    } else if (this.state.searchedType == "PROJECT_ID") {
      selectedProjectid = this.state.searchValue;
      this.props.dispatch(
        UserActions.switchUser(
          e.target.dataset.value,
          this.state.searchedValue.replace("LS-", "")
        )
      );
    }
    this.props.dispatch(
      UserActions.updateSelectedProjectKey(selectedProjectid)
    );
  }

  myAccount() {
    browserHistory.push("/choose/profile");
  }
  logout(e) {
    if (e) e.preventDefault();
    this.props.dispatch(LoginActions.logout());
  }

  render() {
    if (
      this.props.userReducer.isFetching ||
      this.props.projectReducer.isFetching
    ) {
      return (
        <section className="manage_profile">
          <div className="loading-bg"></div>
          <aside className="loading-data">
            <img src={getIconUrl(document.location.origin)}/>
            <p>Loading Admin Page...</p>
          </aside>
        </section>
      );
    }
    return (
      <AdminAccessComponent
        ref={(component) => (this.child = component)}
        state={this.state}
        onSearchValueChange={this.onSearchValueChange}
        switchUser={this.switchUser}
        myAccount={this.myAccount}
        checkAndFetchAdminDetails={this.checkAndFetchAdminDetails}
        logout={this.logout}
        showSearchList={this.showSearchList}
      />
    );
  }
}
export default AdminAccessContainer;
