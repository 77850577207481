import {
  ADD_TAGS_REQUESTED,
  ADD_TAGS_RESPONSE,
  ADD_TAGS_FOR_FILTER_REQUESTED,
  ADD_TAGS_FOR_FILTER_FAILED,
  ADD_TAGS_FAILED,
  TAG_LIST_REQUESTED,
  TAG_LIST_RESPONSE,
  TAG_LIST_FAILED,
  REMOVE_TAG_FAILED,
  REMOVE_TAG_RESPONSE,
  REMOVE_TAG_REQUESTED,
  EDIT_TAG_REQUESTED,
  EDIT_TAG_RESPONSE,
  EDIT_TAG_FAILED,
  DELETE_TAG_REQUESTED,
  DELETE_TAG_RESPONSE,
  DELETE_TAG_FAILED,
} from "../actions/TagActions";

const TagReducer = (
  state = { isFetching: false, isFetched: false, tagMap: {}, projectId: "" },
  action
) => {
  switch (action.type) {
    case ADD_TAGS_REQUESTED:
      return {
        ...state,
      };
    case ADD_TAGS_RESPONSE: {
      return {
        ...state,
        tagMap: Object.assign(state.tagMap, action.tagMap),
      };
    }
    case ADD_TAGS_FAILED:
      return {
        ...state,
      };
    case ADD_TAGS_FOR_FILTER_REQUESTED:
      return {
        ...state,
      };
    case ADD_TAGS_FOR_FILTER_FAILED:
      return {
        ...state,
      };
    case REMOVE_TAG_REQUESTED:
      return {
        ...state,
      };
    case REMOVE_TAG_RESPONSE:
      return {
        ...state,
      };
    case REMOVE_TAG_FAILED:
      return {
        ...state,
      };
    case TAG_LIST_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        projectId: action.projectId,
      };
    case TAG_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        tagMap: Object.assign({}, action.tagMap),
      };
    case TAG_LIST_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case EDIT_TAG_REQUESTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case EDIT_TAG_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        tagMap: {
          ...state.tagMap,
          [action.tagEntity.key]: action.tagEntity,
        },
      };
    case EDIT_TAG_FAILED:
      return {
        ...state,
        isUpdated: false,
        isUpdating: false,
      };
    case DELETE_TAG_REQUESTED:
      return {
        ...state,
        isDeleted: true,
        isDeleting: false,
      };
    case DELETE_TAG_RESPONSE:
      let tagMap = Object.assign({}, state.tagMap);
      delete tagMap[action.tagId];
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
        tagMap: tagMap,
      };
    case DELETE_TAG_FAILED:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
      };
  }
  return state;
};

export default TagReducer;
