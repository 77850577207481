import { AxiosResponse } from 'axios'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import {
  selectUserId,
  selectWidgetId,
} from 'src/features/authorization/store/selectors'
import { postEmail, postWebScrap } from 'src/features/wizard/install/api'
import {
  EnumPostEmailErrors,
  IEmailResponse,
  IWebScrapResponse,
} from 'src/features/wizard/install/api/types'
import * as actions from 'src/features/wizard/install/store/actions'
import { getWidgetConfig, putWidgetConfig } from 'src/features/wizard/setup/api'
import {
  IGetWidgetConfigResponse,
  WidgetInstallationStatus,
} from 'src/features/wizard/setup/api/types'
import { denormalizeWebScrapToWidgetData } from 'src/features/wizard/setup/store/denormalize'
import { mapExceptionMessage } from 'src/utils/api/mapper/mapExceptionMessage'
import { IAppState } from 'src/utils/state/store'
declare let _ls: any
declare let window: any

const getAccountId = () => localStorage.getItem('accountId') ?? ''
const setDataLayerForWidgetInstallation = () => {
  const gtmWindow = window as any
  const accountID = getAccountId()
  if (accountID) {
    gtmWindow.dataLayer = gtmWindow.dataLayer || []
    gtmWindow.dataLayer.push({
      event: 'widget',
      accountid: accountID,
    })
  }
}

const setCustomDataForWidgetInstalled = (
  installationStatus: boolean,
  userId: string
) => {
  const email = localStorage.getItem('login') ?? undefined
  const userInfo = {
    email,
    userId,
    customFields: {
      installationStatus,
    },
  }
  /*eslint no-underscore-dangle: ["error", { "allow": ["_ls"] }]*/
  if (_ls && typeof _ls !== 'undefined') {
    _ls('update', userInfo)
  }
}

const setTrackingDataForTapfiliate = () => {
  const accountId = getAccountId()
  if (window.tap && typeof window.tap === 'function') {
    window.tap('lead', accountId)
  }
}

export function* sagaRequestTestConnection(
  action: ReturnType<typeof actions.requestTestConnection>
): any {
  try {
    const state: IAppState = yield select()
    const widgetId = selectWidgetId(state.auth)!
    const userId = selectUserId(state.auth)!

    const {
      data: { installationStatus },
    }: AxiosResponse<IWebScrapResponse> = yield call(
      postWebScrap,
      widgetId,
      action.payload.url
    )

    // We cant use the isActive from the response for some reason...
    const isActive = installationStatus === WidgetInstallationStatus.active

    if (isActive) setDataLayerForWidgetInstallation()

    setCustomDataForWidgetInstalled(isActive, userId)
    setTrackingDataForTapfiliate()

    // We need to manually tell BE if the widget is active or not for some reason...
    // (we could save the value and wait for the user to update the widget info
    // but in the main app the connection check happens after the widget update)
    const {
      data: { data: widgetConfigData },
    }: AxiosResponse<IGetWidgetConfigResponse> = yield call(
      getWidgetConfig,
      widgetId
    )
    yield call(putWidgetConfig, {
      widgetId,
      values: denormalizeWebScrapToWidgetData(
        installationStatus,
        action.payload.url,
        widgetConfigData
      ),
    })

    yield put(actions.requestTestConnectionSuccess(isActive))
  } catch (error) {
    console.log('Error ::: ', error)
    yield put(actions.requestTestConnectionSuccess(false))
  }
}

export function* sagaRequestEmailInstruction(
  action: ReturnType<typeof actions.requestEmailInstructions>
): any {
  const state: IAppState = yield select()
  const widgetId = selectWidgetId(state.auth)!

  try {
    const {
      data: {
        data: { success: isSuccess },
      },
    }: AxiosResponse<IEmailResponse> = yield call(
      postEmail,
      widgetId,
      action.payload.email
    )

    yield put(
      actions.requestEmailInstructionsFinished(action.payload.id, isSuccess)
    )
  } catch (error) {
    const errorMessage = mapExceptionMessage(
      EnumPostEmailErrors,
      error?.Exception
    )
    yield put(
      actions.requestEmailInstructionsFinished(
        action.payload.id,
        false,
        errorMessage
      )
    )
  }
}

/* istanbul ignore next */
export function* installSagas() {
  yield takeLatest(actions.REQUEST_TEST_CONNECTION, sagaRequestTestConnection)
  yield takeLatest(
    actions.REQUEST_EMAIL_INSTRUCTIONS,
    sagaRequestEmailInstruction
  )
}
