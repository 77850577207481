import React, { Component, Fragment } from "react";
import { store } from "../../app.js";
import {getValueFromNestedObject} from "../../commons/Utility.js"
import MessageWrapper from './MessageWrapper.jsx'

class MessageComponent extends Component {
  constructor() {
    super();
    this.getTags = this.getTags.bind(this);
  }

  getTags() {
    const { key: messageId } = this.props.messageData;
    let {
      MessageReducer: { messageMap },
    } = store.getState();
    for (const conversationId in messageMap) {
      let tags = getValueFromNestedObject(
        messageMap,
        `${conversationId}.${messageId}.tags`
      );
      if (tags) return tags;
    }
    return [];
  }

  render() {
    const {
      getMessageContentByType,
      messageData,
      isFromChatHistory,
      senderName,
      timeStamp,
      messageTooltipTime,
      isFromPeopleProfilePage
    } = this.props;
    return (
      <Fragment>
        <label class="fs-exclude">
          {messageData.type == "NOTES"
            ? (isFromChatHistory ? "T" : senderName + " t") + "ook a note"
            : senderName}
        </label>
        <MessageWrapper
          tags={this.getTags()}
          messageData={messageData}
          getMessageContentByType={getMessageContentByType}
          isFromChatHistory={isFromChatHistory}
          isFromPeopleProfilePage={isFromPeopleProfilePage}
        />
        <small data-class="tooltip info" data-tip={messageTooltipTime}>
          {timeStamp}
        </small>
      </Fragment>
    );
  }
}
export default MessageComponent;
