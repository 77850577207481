/**
 * Created by melvin on 27/09/17.
 */
import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as TagActions from "../actions/TagActions";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { receiveUpdateContact } from "../actions/ContactInfoAction";
import {
  updatePeopleEntity,
  peopleNewListResponse,
  deleteGroupResponse,
} from "../actions/PeopleAction";
import { sendMessage, updateMessageResponse } from "../actions/MessageAction";
import { MESSAGE_TYPE } from "../commons/Constants";
import { store } from "../app.js";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import {
  getPeopleGroupsResponse,
  getPeopleCountRequest,
} from "../actions/PeopleAction";
import { updateConversationResponse } from "../actions/ConversationInfoAction";
import {
  createGroupsFromTags,
  getPayloadOfShowVoicBoxForErrorMessage,
  getQueryParam,
} from "../commons/Utility.js";

export function* doFetchTagList(requestObj) {
  try {
    let url = "/tags/get/" + requestObj.projectId;
    let response = undefined;
    if (requestObj.isFromOverflow) {
      url =
        "tags/overflow/get/" +
        requestObj.projectId +
        "?connectionId=" +
        getQueryParam("connection_id");
      response = yield call(Ajax.getUnauthenticated, url);
    } else if (requestObj.isAwWindow) {
      url = "tags/overflow/get/" + requestObj.projectId;
      response = yield call(Ajax.getUnauthenticated, url);
    } else response = yield call(Ajax.get, url);
    if (response.response) {
      let tagList = response.data;

      let tagMap = {};
      tagList.map((tag) => {
        tagMap[tag.key] = tag;
      });
      yield put(TagActions.tagListResponse(tagMap));
    } else yield put(TagActions.tagListFailed());
  } catch (e) {
    console.error(e);
    yield put(TagActions.tagListFailed());
    yield put(handleGlobalError(e));
  }
}

export function* doAddTags(requestObject) {
  const url = "/contact/tag/add";
  try {
    let requestData = {};
    let tagGroupsMap = {};
    requestData.tags = requestObject.names;
    requestData.projectId = requestObject.projectId;
    requestData.peopleId = requestObject.peopleId;
    let response = yield call(Ajax.post, url, requestData);
    if (response.response === true) {
      response = response.data;
      let tagEntities = response.tagEntities;
      let peopleEntity = response.peopleEntity;

      if(peopleEntity.sourceUrl && !peopleEntity.sourceUrl_keyword)
        peopleEntity.sourceUrl_keyword = peopleEntity.sourceUrl;
      if(peopleEntity.currentUrl && !peopleEntity.currentUrl_keyword)
        peopleEntity.currentUrl_keyword = peopleEntity.currentUrl;
      if(peopleEntity.lastPageUrl && !peopleEntity.lastPageUrl_keyword)
        peopleEntity.lastPageUrl_keyword = peopleEntity.lastPageUrl

      let tagMap = {};
      tagEntities.map((tag) => {
        tagMap[tag.key] = tag;
      });
      yield put(TagActions.addTagsResponse(tagMap));
      yield put(receiveUpdateContact({ contact: peopleEntity }));
      yield put(
        sendMessage({
          type: MESSAGE_TYPE.realtime_visitor_update,
          channel: "private/agent/" + peopleEntity.projectId,
          contact: peopleEntity,
        })
      );
      peopleEntity = Object.assign(peopleEntity, peopleEntity.customFields);
      yield put(updatePeopleEntity(peopleEntity));
      yield put(VoiceboxActions.hideVoicebox());

      tagGroupsMap = { ...createGroupsFromTags(tagEntities) };
      yield put(getPeopleGroupsResponse(tagGroupsMap));
      yield put(
        getPeopleCountRequest({
          peopleGroupsList: Object.values(tagGroupsMap),
          projectId: requestObject.projectId,
        })
      );
    } else yield put(TagActions.addTagsFailed());
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doAddTagsToConversation(requestObject) {
  let url = "/conversation/tag/add";
  try {
    let requestData = {};
    let tagGroupsMap = {};
    requestData.tags = requestObject.names;
    requestData.projectId = requestObject.conversation.projectId;
    requestData.conversationId = requestObject.conversation.key;
    requestData.messageId = requestObject.messageId;
    requestData.createdBy = requestObject.createdBy;
    let response = undefined;
    if (requestObject.isFromOverflow) {
      url =
        "/conversation/tag/overflow/add?connectionId=" +
        getQueryParam("connection_id");
      response = yield call(Ajax.postUnauthenticated, url, requestData);
    } else response = yield call(Ajax.post, url, requestData);
    if (response.response === true) {
      response = response.data;
      let tagEntities = response.tagEntities;
      let conversationEntity = response.conversationEntity;
      let messageEntity = response.messageEntity;
      conversationEntity.lastMessage = requestObject.conversation.lastMessage;
      conversationEntity.messageFrom = requestObject.conversation.messageFrom;
      let tagMap = {};
      tagEntities.map((tag) => {
        tagMap[tag.key] = tag;
      });
      yield put(TagActions.addTagsResponse(tagMap));
      yield put(updateConversationResponse(conversationEntity));
      yield put(updateMessageResponse(messageEntity));
      yield put(VoiceboxActions.showVoicebox({
        message: "Tag successfully added",
        showBelowHeader: true,
        dismissAfter: 3000,
      }));
    } else yield put(TagActions.addTagsFailed());
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doAddTagsForFilter(requestObject) {
  const url = "/contact/filter/tag/add";
  try {
    let response = yield call(Ajax.put, url, requestObject);
    if (response.response === true) {
      response = response.data;
      let tagEntities = response.tagEntities;
      let tagGroupsMap = {};
      let tagMap = {};
      let peopleResponse = {};
      let peopleReducer = store.getState().PeopleReducer;
      let peopleMap = peopleReducer.peopleMap;

      tagEntities.map((tag) => {
        tagMap[tag.key] = tag;
        if (requestObject.query.filter.key) {
          for (let key of requestObject.query.filter.key)
            if (!peopleMap[key].tags) peopleMap[key].tags = [tag.key];
            else if (!peopleMap[key].tags.includes(tag.key))
              peopleMap[key].tags.push(tag.key);
        } else {
          for (let key in peopleMap)
            if (!peopleMap[key].tags) peopleMap[key].tags = [tag.key];
            else if (!peopleMap[key].tags.includes(tag.key))
              peopleMap[key].tags.push(tag.key);
        }
      });
      yield put(TagActions.addTagsResponse(tagMap));

      peopleResponse.peopleMap = peopleMap;
      peopleResponse.totalPeople = peopleReducer.totalPeople;
      peopleResponse.isSingleRequested = peopleReducer.isSingleRequested;
      peopleResponse.fieldSettings = {
        visibleFields: peopleReducer.visibleFields,
      };
      yield put(peopleNewListResponse(peopleResponse));

      tagGroupsMap = { ...createGroupsFromTags(tagEntities) };
      yield put(getPeopleGroupsResponse(tagGroupsMap));
      yield put(
        getPeopleCountRequest({
          peopleGroupsList: Object.values(tagGroupsMap),
          projectId: requestObject.projectId,
        })
      );
    } else yield put(TagActions.addTagsForFilterFailed());
    yield put(VoiceboxActions.hideVoicebox());
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doRemoveTag(requestObject) {
  let tagId = requestObject.tagId;
  let peopleEntity = requestObject.peopleEntity;
  const url =
    "/contact/tag/remove/" +
    peopleEntity.projectId +
    "/" +
    peopleEntity.key +
    "/" +
    requestObject.tagId;
  try {
    yield put(VoiceboxActions.showVoicebox({ message: "removing tag.." }));
    let response = yield call(Ajax.deleteReq, url);
    if (response.response === true) {
      yield put(TagActions.removeTagResponse(requestObject.tagId));

      peopleEntity.tags = peopleEntity.tags.filter((tag) => tag != tagId);
      yield put(receiveUpdateContact({ contact: peopleEntity }));
      yield put(
        sendMessage({
          type: MESSAGE_TYPE.realtime_visitor_update,
          channel: "private/agent/" + peopleEntity.projectId,
          contact: peopleEntity,
        })
      );
      peopleEntity = Object.assign(peopleEntity, peopleEntity.customFields);
      yield put(updatePeopleEntity(peopleEntity));
    } else yield put(TagActions.addTagsFailed());
    yield put(VoiceboxActions.hideVoicebox());
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doRemoveTagFromConversation(requestObject) {
  let tagId = requestObject.tagId;
  let conversation = requestObject.conversation;
  let url =
    "/conversation/tag/remove/" +
    conversation.projectId +
    "/" +
    conversation.key +
    "/" +
    requestObject.tagId;
  try {
    yield put(VoiceboxActions.showVoicebox({ message: "removing tag.." }));
    let response = undefined;
    if (requestObject.isOverflowConversation) {
      url =
        "/conversation/tag/overflow/remove/" +
        conversation.projectId +
        "/" +
        conversation.key +
        "/" +
        requestObject.tagId +
        "?connectionId=" +
        getQueryParam("connection_id");
      response = yield call(Ajax.deleteUnauthenticated, url);
    } else if (requestObject.isAwWindow) {
    } else response = yield call(Ajax.deleteReq, url);
    if (response.response === true) {
      yield put(TagActions.removeTagResponse(requestObject.tagId));

      conversation.tags = conversation.tags.filter((tag) => tag != tagId);

      yield put(updateConversationResponse(conversation));
    } else yield put(TagActions.addTagsFailed());
    yield put(VoiceboxActions.hideVoicebox());
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doUpdateTag(requestObj) {
  try {
    let url = "/tags/update/";
    yield put(VoiceboxActions.showVoicebox({ message: "Updating tag", showBelowHeader:true}));
    let response = yield call(Ajax.put, url, requestObj.tagEntity);
    let updateTag = response.data;
    yield put(TagActions.editTagResponse(updateTag));
    let tagGroupsMap = { ...createGroupsFromTags([updateTag]) };
    yield put(getPeopleGroupsResponse(tagGroupsMap));

    yield put(
      VoiceboxActions.showVoicebox({
        message: "Tag successfully updated",
        showBelowHeader: true,
        dismissAfter: 3000,
      })
    );

  } catch (e) {
    console.error(e);
    yield put(TagActions.editTagFailed());
    yield put(handleGlobalError(e));
  }
}
export function* doDeleteTag(requestObj) {
  try {
    let url = "/tags/delete/" + requestObj.projectId + "/" + requestObj.tagId;
    yield put(VoiceboxActions.showVoicebox({ message: "Deleting..." }));
    let response = yield call(Ajax.deleteReq, url);

    yield put(TagActions.deleteTagResponse(requestObj.tagId));
    yield put(VoiceboxActions.hideVoicebox());
    yield put(deleteGroupResponse(requestObj.tagId));
  } catch (e) {
    console.error(e);
    yield put(TagActions.deleteTagFailed());
    yield put(handleGlobalError(e));
  }
}

export function* doRemoveTagFromMessage(requestObject) {
  yield put(VoiceboxActions.showVoicebox({
    message: "Removing tag",
    showBelowHeader: true,
  }));
  const { tagId, messageId, projectId } = requestObject;
  let url = `/conversation/messageTag/${projectId}/${messageId}/${tagId}`;
  try {
    let response = undefined;
    if (requestObject.isOverflow) {
      url = `/conversation/messageTag/overflow/${projectId}/${messageId}/${tagId}/?connectionId=${getQueryParam(
        "connection_id"
      )}`;
      response = yield call(Ajax.deleteUnauthenticated, url);
    } else response = yield call(Ajax.deleteReq, url);
    if (response.response === true) {
      response = response.data;
      let conversationEntity = response.conversationEntity;
      let messageEntity = response.messageEntity;
      yield put(updateMessageResponse(messageEntity));
      if (conversationEntity)
        yield put(updateConversationResponse(conversationEntity));
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Tag successfully removed",
          showBelowHeader: true,
          dismissAfter: 3000,
        })
      );
    } else
      yield put(
        VoiceboxActions.showVoicebox(
          getPayloadOfShowVoicBoxForErrorMessage("Failed To Remove Tag")
        )
      );
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}
