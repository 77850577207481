import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

// TODO: use window.matchMedia and don't render hidden content

export const MobileOnly = styled.div`
  display: block;

  ${mq.sm} {
    display: none;
  }
`

export const DesktopOnly = styled.div`
  display: none;
  width: 100%;

  ${mq.sm} {
    display: unset;
  }
`
