import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import svg from 'src/assets/camera.svg'

export const Container = styled.div``

export const Images = styled.div`
  display: flex;
`

export const IconButtonContainer = styled.div`
  position: relative;
  margin-left: 1.6rem;
`

export const IconButton = styled.button.attrs({ type: 'button' })<{
  selected: boolean
  color: string
  backgroundColor?: string
  disabled?: boolean
}>`
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  width: 4rem;
  height: 4rem;
  outline-offset: 0.5rem;
  border-radius: 50%;
  cursor: pointer;

  & + & {
    margin-left: 1.6rem;
  }

  ${is('selected')`
    &::after {
      border-radius: 50%;
      position: absolute;
      width: 4.4rem;
      height: 4.4rem;
      left: -0.4rem;
      top: -0.4rem;
      border: 0.2rem solid ${R.prop('color')};
      content: '';
    }
  `}

  ${is('disabled')`
    cursor: not-allowed;
  `}
`

export const InputContainer = styled.div<{ $disabled: boolean }>`
  position: relative;
  width: 4rem;
  height: 4rem;

  ${is('$disabled')`
    opacity: 0.7;
  `}

  &::after {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    transform: translate(-50%, -50%);
    width: 1.6rem;
    height: 1.3rem;
    background-image: url(${svg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    ${is('$disabled')`
      background-image: unset;
    `}
  }
`

export const StyledInput = styled.input<{ $disabled: boolean }>`
  -webkit-appearance: none;
  width: 4rem;
  height: 4rem;
  border: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &::file-selector-button {
    visibility: hidden;
  }

  ${is('$disabled')`
    opacity: 0.7;
    cursor: not-allowed;
  `}
`

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
