import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TeamListComponent from "./TeamListComponent.jsx";

const DirectoryComponent = (props) => {
  const [showStaffsList, setShowStaffsList] = useState(false);

  const getStaffList = () => {
    return (
      <TeamListComponent
        directoryProps={props.directoryProps}
        directoryEventHandler={props.directoryEventHandler}
        staffReducer={props.staffReducer}
        userReducer={props.userReducer}
      />
    );
  };

  return (
    <div class={"cs-conversation-wrp "}>
      {getStaffList()}
      {props.directoryProps.children}
    </div>
  );
};
export default DirectoryComponent;
