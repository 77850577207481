import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { isOverflowConnection } from "../commons/Utility.js";
import {
  addNotification,
  removeNotification,
} from "../actions/NotificationAction";
import { showVoicebox } from "../actions/VoiceboxAction";
import {
  userAuthFailed,
  userAuthChanged,
  apiInternalException,
} from "../actions/ExceptionAction";
import { browserHistory } from "react-router";
import {
  GlobalErrorReducer,
  mailGlobalError,
  addStackTrace,
} from "../actions/GlobalErrorAction";
import { switchToOwnAccount } from "../actions/UserAction";
import { ERROR_MESSAGE, NOTIFICATION_LEVEL, WEBAPP_ENVIRONMENT_TYPE } from "../commons/Constants.js";
import { store } from "../app";

export function* globleHandler(error) {
  try {
    error = error.error;
    console.error("error is:", error);
    if (error && error.message && error.message == "Network Error") {
      let activeNotifications = store.getState().NotificationReducer
        .notifications;
      if (activeNotifications) {
        //To remove existing notifications
        for (var key in activeNotifications) {
          if (activeNotifications.hasOwnProperty(key))
            yield put(
              removeNotification(activeNotifications[key].notificationId)
            );
        }
      }
      // yield put( addNotification( { message : 'Network Disconnected!' , level : NOTIFICATION_LEVEL.Error , dismissAfter : 5000 } ) );
      let page = document.location.href;
      if (page.includes("chat/") || isOverflowConnection())
        yield put(
          showVoicebox({
            message: "Network Disconnected!",
            dismissAfter: 5000,
            showError: true,
            showBelowHeader: !isOverflowConnection(),
            showFullScreenLoader: isOverflowConnection(),
          })
        );
      else if (
        page.includes("admin") ||
        page.includes("choose/profile") ||
        page.includes("embed/setup")
      )
        yield put(
          showVoicebox({
            message: "Network Disconnected!",
            dismissAfter: 5000,
            showFullScreenLoader: true,
            showError: true,
            showBelowHeader: true,
          })
        );
      else
        yield put(
          showVoicebox({
            message: "Network Disconnected!",
            dismissAfter: 5000,
            showError: true,
          })
        );
    } else if (
      (error && error.message && error.message === ERROR_MESSAGE.AUTHENTICATION) ||
      (error &&
        error.response &&
        error.response.data &&
        error.response.data.message == "UserAuthenticationFailed")
    ) {
      yield put(userAuthFailed());
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message == "UserChanged"
    ) {
      //yield put( userAuthChanged() );
    } else if (
      error &&
      error.response &&
      error.response.data &&
      !error.response.data.message == "Access Denied"
    ) {
      //yield put( apiInternalException() );
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message == "Access Denied"
    ) {
      if (
        store.getState().UserReducer.data &&
        store.getState().UserReducer.data.originalId &&
        error.response.request.responseURL.indexOf("/get") > -1
      ) {
        //The reason why we do window push instead of browser history push is
        //because we want to refetch information again including userReducer
        yield put(switchToOwnAccount("/admin"));
        yield put(
          showVoicebox({
            message: "Switching account",
            showFullScreenLoader: true,
          })
        );
        setTimeout(() => {
          let url = window.location.origin;
          if (!window.location.origin.contains("https"))
            url = url.replace(/^http:\/\//i, "https://");
          window.location.href = url + "/login";
        }, 2000);
      } else if (error.response.request.responseURL.indexOf("/get") > -1) {
        yield put(switchToOwnAccount());
        yield put(
          showVoicebox({
            message: "Switching account",
            showFullScreenLoader: true,
          })
        );
        setTimeout(() => {
          let url = window.location.origin;
          if (!window.location.origin.contains("https"))
            url = url.replace(/^http:\/\//i, "https://");
          window.location.href = url + "/login";
        }, 2000);
      }
    } else {
      yield put(mailGlobalError(error));
      //syield put( apiInternalException() );
    }
  } catch (e) {
    console.error("SEVERE ERROR: ", e);
  }
}

export function* sendMail(error) {
  const url = "/dev/webError";
  const postMethod = isOverflowConnection() ? Ajax.postUnauthenticated : Ajax.post;
  const webappType = isOverflowConnection() ? WEBAPP_ENVIRONMENT_TYPE.OVERFLOW : WEBAPP_ENVIRONMENT_TYPE.DASHBOARD
  try {
    error = error.error;
    let capturedErrorList = store.getState().GlobalErrorReducer
      .errorCapturedList;
    if (
      capturedErrorList.filter(function (el) {
        return el == error.stack;
      }).length == 0
    ) {
      yield put(addStackTrace(error));
      yield call(postMethod, url, {
        stack: error.stack,
        message: error.message,
        appName: `Chat Console - ${webappType}`
      });
    } else console.log("Already captured", error);
  } catch (e) {
    console.error("SEVERE ERROR: ", e);
  }
}
