import React from "react";
import { useSelector } from "react-redux";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import PropTypes from "prop-types";

const VisitorQueueDetails = (props) => {
  const projectReducer = useSelector((state) => state.ProjectReducer);
  const { projectList } = projectReducer;
  const { details, createNewConversation, projectColorMap } = props;

  const showOrHideProjectColor = () => {
    if (projectList && projectList.length > 1)
      return (
        <cite
          style={{ backgroundColor: getProjectColor(details.projectName) }}
        ></cite>
      );
    else return "";
  };

  const getProjectColor = (project_id) => {
    return `#${projectColorMap[project_id]}`;
  };

  const constructURL = (currentPage) => {
    if (currentPage) {
      let url = new URL(currentPage);
      return url.host + url.pathname;
    } else {
      return "";
    }
  };

  const handleClick = (e) => {
    createNewConversation(e);
    Event(
      details.projectName,
      "Visitor Queue",
      "Start Chat",
      "Start Chat Clicked"
    );
  };

  return (
    // <li>
    //     <span>{details.name.trim() ? details.name : "Visitor"}</span>
    //     {showOrHideSourceData()}
    //     <span>{details.currentPage}</span>
    //     <span>{details.isOnline ? "Active" : "InActive"}</span>
    //     <span>{details.totalVisits}</span>

    // </li>
    <li>
      <span>
        {showOrHideProjectColor()}
        <p className="fs-exclude">
          {details.name.trim() ? details.name : "Visitor"}
        </p>
        <a className="fs-exclude">
          {details.pageTitle || constructURL(details.currentPage)}
        </a>
      </span>
      <span>{details.isOnline ? "Active" : "Inactive"}</span>
      <span
        onClick={handleClick}
        data-visitor_id={details.key}
        data-project_id={details.projectName}
      >
        Start chat
      </span>
    </li>
  );
};

VisitorQueueDetails.propTypes = {
  details: PropTypes.shape({
    projectName: PropTypes.string,
  }),
};

export default VisitorQueueDetails;
