import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import Activity from './Activity.jsx';
import {getProjectId} from '../../commons/Utility.js';
import {requestProjectEvent} from '../../actions/ProjectEventsAction.js';
import {requestEventActivity} from '../../actions/EventsActivityAction.js';

const ActivityContainer = (props) => {
    const {projectkey, visitorId } = props.params;
    const {staff, conversations} = props;
    const projectId = getProjectId(projectkey);
    const { eventActivities, projectEvents } = useSelector((state) => ({
        eventActivities: state.EventsActivityReducer,
        projectEvents: state.ProjectEventReducer
      }));
    const dispatch = useDispatch();
    useEffect(() => {
        if(visitorId) {
            if((!eventActivities.isFetched && !eventActivities.isFetching)
                    || eventActivities.projectId != projectId
                        || (!eventActivities.isFetching && !eventActivities.eventActivityMap[visitorId])) {
                            let eventRequestParam = {projectId , visitorId, order : "-createdDate"};
                            dispatch(requestEventActivity(eventRequestParam));
             }
            if((!projectEvents.isFetched &&!projectEvents.isFetching)
                    || projectEvents.projectId != projectId) {
                        dispatch(requestProjectEvent(projectId));
            } 
        }
    }, [visitorId]);
    return(
        <Activity visitorId={visitorId}
                  staff={staff}
                  conversations={conversations}
                  eventActivities={eventActivities}
                  projectEvents={projectEvents}
                  projectId={projectId}/>
    )
}

export default ActivityContainer;