import React, { Component } from 'react';
import { Link } from 'react-router';
import { getBrandLogoByDomainURL } from '../commons/Utility.js';

class Login extends Component {
  render() {
    const { localState } = this.props;
    const { handlers } = this.props;

    const { isLoggingIn } = localState;
    const { isGoogleLoggingIn } = localState;
    const { error } = localState;

    return (
      <div
        className="loginWrp"
        style={{ display: 'none' }}
      >
        <div
          className="noConversationMsg"
          style={{ display: localState.isAuthenticated ? 'block' : 'none' }}
        />
        <div style={{ display: localState.isAuthenticated ? 'none' : 'block' }}>
          <a
            href="javascript:void(0)"
            className={getBrandLogoByDomainURL()}
          />
          <section className="signinHolder">
            <h2>Sign in to your account</h2>
            <button
              className="loginGoogleBtn"
              onClick={handlers.onGoogleLogin}
              disabled={isGoogleLoggingIn || isLoggingIn}
            >
              Sign in with Google
            </button>
            <div className="or">
              <span>or</span>
            </div>
            <fieldset>
              <div
                className={`inputGroup ${error.email ? 'inputError' : ''}`}
              >
                <label>Email address</label>
                <input
                  className="fs-exclude"
                  type="text"
                  aria-label="Email"
                  ref="login_email"
                  onKeyUp={handlers.onKeyUp}
                  disabled={isGoogleLoggingIn || isLoggingIn}
                  onChange={handlers.onChange}
                  value={localState.emailId}
                  readOnly={localState.isInvitationLogin ? 'readOnly' : false}
                  tabIndex="1"
                />
                <p style={{ display: error.email ? 'block' : 'none' }}>
                  {error.email ? error.email : ''}
                </p>
              </div>
              <div
                className={`inputGroup ${error.password ? 'inputError' : ''}`}
              >
                <label>Password</label>
                <input
                  className="fs-exclude"
                  type="password"
                  ref="login_password"
                  onKeyUp={handlers.onKeyUp}
                  disabled={isGoogleLoggingIn || isLoggingIn}
                  tabIndex="2"
                />
                <p style={{ display: error.password ? 'block' : 'none' }}>
                  {error.password ? error.password : ''}
                </p>
              </div>
              <a className="forgotPass">
                <Link to={{ pathname: '/reset/password' }} tabIndex="4">
                  Forgot your password?
                </Link>
              </a>
              <p
                className="errorTxt"
                style={{ display: error.general ? 'block' : 'none' }}
              >
                {error.general ? error.general : ''}
              </p>
              <button
                style={{ opacity: isLoggingIn ? '0.5' : '1' }}
                className="btnLogin"
                onClick={handlers.onLogin}
                disabled={isGoogleLoggingIn || isLoggingIn}
                tabIndex="3"
              >
                {!isLoggingIn ? 'Login' : 'Logging in...'}
              </button>
              <p className="dntAccount">
                Don't have an account?
                {' '}
                <a
                  tabindex="4"
                  id="signUpLink"
                  href="https://signup.chatsupport.co/signup/freetrial"
                >
                  Sign Up
                </a>
              </p>
            </fieldset>
          </section>
        </div>
      </div>
    );
  }
}

export default Login;
