import { EVENTS_TRACKING } from "../commons/Constants.js";

export const trackProjectCreation = (project) => {
  try {
    _ls("track", EVENTS_TRACKING.project_created, "", project);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackMemberInvited = (invitedStaffs) => {
  try {
    for (let i = 0; i < invitedStaffs.length; i++) {
      _ls("track", EVENTS_TRACKING.member_invited, "", invitedStaffs[i]);
    }
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackAcceptInvitation = (invitation) => {
  try {
    _ls("track", EVENTS_TRACKING.invitation_accepted, "", invitation);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackDeclineInvitation = (invitation) => {
  try {
    _ls("track", EVENTS_TRACKING.invitation_declined, "", invitation);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackPeopleGroupCreation = (gropuInfo) => {
  try {
    _ls("track", EVENTS_TRACKING.group_created, "", gropuInfo);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackImportContacts = (contactInfo) => {
  try {
    _ls("track", EVENTS_TRACKING.contact_imported, "", contactInfo);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackCustomFieldCreation = (customField) => {
  try {
    _ls("track", EVENTS_TRACKING.customfield_created, "", customField);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackOutOfOfficeUpdated = (updatedStatus) => {
  try {
    _ls("track", EVENTS_TRACKING.out_of_office_updated, "", updatedStatus);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackInstantReplyUpdated = (updatedStatus) => {
  _ls("track", EVENTS_TRACKING.instant_reply_updated, "", updatedStatus);
};

export const trackChatPromptCreation = (chatPromt) => {
  try {
    _ls("track", EVENTS_TRACKING.chat_prompt_created, "", chatPromt);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackBroadCastMailSent = (broadCastData) => {
  try {
    _ls("track", EVENTS_TRACKING.broadcast_email_sent, "", broadCastData);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};

export const trackBroadCastMessageSent = (broadCastData) => {
  try {
    _ls("track", EVENTS_TRACKING.broadcast_message_sent, "", broadCastData);
  } catch (exception) {
    console.log("Exception in tracking the events ", exception);
  }
};
