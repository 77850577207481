import React, { useState, useEffect, useRef, useCallback } from "react";
import Flatpickr from "react-flatpickr";
import { getLocalDateEndTimeOfDay } from "../../commons/Utility.js";

const DateFilter = (props) => {
  const { showPicker } = props;
  const { value } = props;
  const { parentNode, isCustomElement, isEndDate } = props;
  const [dateTime, setDateTime] = useState(new Date());
  const [container, setContainer] = useState(null);
  const maxDate = getLocalDateEndTimeOfDay();
  const containerRef = useRef();
  const { onEdit } = props;
  const { minDate } = props;

  useEffect(() => {
    if (value) setDateTime(new Date(value));
  }, [value]);

  useEffect(() => {
    if (containerRef.current) setContainer(containerRef.current);
  }, [containerRef]);

  const pickerRef = useCallback((node) => {
    if (node && showPicker) {
      node.flatpickr.open();
    }
  }, []);

  const onChange = (date) => {
    setDateTime(date);
    let changedDate = new Date(date).getTime();
    onEdit(null, changedDate);
  };

  const getOptions = () => {
    if (minDate) {
      return {
        appendTo: container,
        minDate: minDate,
        altInput: true,
        dateformat: "d M Y",
        altFormat: "d M Y",
        enableTime: false,
        wrap: true,
      };
    } else {
      return {
        appendTo: container,
        maxDate: maxDate,
        altInput: true,
        dateformat: "d M Y",
        altFormat: "d M Y",
        enableTime: false,
      };
    }
  };

  const getPicker = () => {
    let picker = "";
    if (container) {
      picker = (
        <Flatpickr
          ref={pickerRef}
          value={dateTime}
          options={getOptions()}
          onChange={onChange}
        />
      );
    }
    return picker;
  };

  const getCustomPicker = () => {
    let picker = "";
    if (container) {
      picker = (
        <Flatpickr
          ref={pickerRef}
          value={dateTime}
          options={getOptions()}
          onChange={onChange}
        >
          <input type="hidden" type="text" data-input />
          <a href="#" data-toggle>
            Custom
          </a>
        </Flatpickr>
      );
    }
    return picker;
  };

  const getEndDatePicker = () => {
    let picker = "";
    if (container) {
      picker = (
        <Flatpickr
          ref={pickerRef}
          value={dateTime}
          options={getOptions()}
          onChange={onChange}
        >
          <input type="hidden" type="text" data-input />
          <a href="#" data-toggle>
            Custom
          </a>
        </Flatpickr>
      );
    }
    return picker;
  };

  const getProperPicker = () => {
    try {
      return isCustomElement ? getCustomPicker() : getPicker();
    } catch (error) {
      console.error("Error :", error);
    }
  };

  return (
    <div ref={containerRef} className={"date-field"}>
      {getProperPicker()}
    </div>
  );
};

export default DateFilter;
