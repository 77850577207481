import React, { useRef } from "react";

const SenderCard = (props) => {
  const imageRef = useRef(null);
  const onImgError = () => {
    imageRef.current.style.display = "none";
  };
  const onImgLoad = () => {
    imageRef.current.style.display = "block";
  };
  return (
    <div
      ref={props.blockProps.ref}
      onClick={props.blockProps.handleOnClick}
      style={{
        padding: "0px 0px 0px 46px",
        position: "relative",
      }}
    >
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {props.blockProps.agentName}
      </span>
      <figure>
        <img
          ref={imageRef}
          src={props.blockProps.agentImage}
          onError={onImgError}
          onLoad={onImgLoad}
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "100%",
          }}
        ></img>
      </figure>
      {/* <span>{props.blockProps.agentName}</span> */}
      <span
        style={{
          display: "block",
          fontSize: "12px",
          opacity: "0.5",
          lineHeight: "1.67",
        }}
      >
        {props.blockProps.projectName}
      </span>
    </div>
  );
};

export default SenderCard;
