import {
  EVENT_REQUESTED,
  EVENT_RESPONSE,
  EVENT_FAILED,
} from "../actions/EventConfigurationAction.js";

const EventConfigurationReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isUpdated: false,
    isDeleting: false,
    isDeleted: false,
    projectId: "",
    eventMap: {},
  },
  action
) => {
  switch (action.type) {
    case EVENT_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        eventMap: {},
        projectId: action.projectId,
      };
    case EVENT_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        eventMap: Object.assign({}, action.eventMap),
      };
    case EVENT_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
  }
  return state;
};

export default EventConfigurationReducer;
