import React, { useRef, useEffect, useState } from "react";
import BusinessHoursComponent from "./BusinessHoursComponent.jsx";
import { useSelector, useDispatch } from "react-redux";
import { updateProjectRequest } from "../actions/ProjectAction.js";
import * as VoiceboxActions from "../../actions/VoiceboxAction.js";
import { useChatConfigurationEntity } from "../hooks/useChatConfigurationEntity.js";
import { useUpdateChatConfigurationEntity } from "../hooks/useUpdateChatConfigurationEntity.js";
import { usePrevious } from "../hooks/usePrevious.js";
import { useGetBusinessHours } from "../hooks/useGetWorkingHours.js";
import { useUpdateWorkingHours } from "../hooks/useUpdateWorkingHours.js";
import { useCreateBusinessHours } from "../hooks/useCreateWorkingHours.js";
var ctzc = require("country-tz-currency");

const BusinessHoursContainer = (props) => {
  const { userReducer, staffReducer, project } = useSelector((state) => ({
    userReducer: state.UserReducer.data,
    staffReducer:
      state.StaffReducer.dashboardAgents["LS-" + props.params.projectkey],
    project: state.ProjectReducer,
  }));

  const [timezone, setTimezone] = useState("");
  const [activeProject, setActiveProject] = useState({});
  const [chatConfigState, setChatConfigState] = useState({});
  const [outOfOfficeMessasge, setOutOfOfficeMessasge] = useState(null);
  const dispatch = useDispatch();
  const projectId = "LS-" + props.params.projectkey;
  const { workingHours: businessHoursFromReducer } = useGetBusinessHours(
    projectId,
    activeProject.accountId
  );
  const updateBusinessHours = useUpdateWorkingHours();
  const createBusinessHours = useCreateBusinessHours();
  const updateChatConfigurationEntity = useUpdateChatConfigurationEntity();
  const { chatConfiguration, isUpdating } = useChatConfigurationEntity(
    "LS-" + props.params.projectkey
  );
  let prevIsUpdating = usePrevious(isUpdating);
  let isOutOfOfficeEnabled = chatConfiguration
    ? chatConfiguration.isOutOfOfficeEnabled
    : false;

  useEffect(() => {
    if (Object.keys(activeProject).length <= 0 && project.isFetched) {
      let projectEntity = getCurrentProjectEntity();
      setActiveProject(projectEntity);
      setTimezone(projectEntity.timezoneId);
    }
    if (chatConfiguration && Object.keys(chatConfigState).length <= 0)
      setChatConfigState(chatConfiguration);
    if (
      chatConfiguration &&
      (outOfOfficeMessasge === null || outOfOfficeMessasge === undefined)
    )
      setOutOfOfficeMessasge(chatConfiguration.oooAnswerPhrase);
  });

  useEffect(() => {
    if (prevIsUpdating && !isUpdating && chatConfiguration) {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "Update Successful",
          dismissAfter: 2000,
        })
      );
    }
  }, [isUpdating]);

  const getCurrentProjectEntity = () => {
    const projectKey = "LS-" + props.params.projectkey;
    let projectEntity = project.projectList.find(
      (project) => project && project.key === projectKey
    );
    return { ...projectEntity };
  };

  const updateTimezone = (timezone, timezoneRef) => {
    let projectEntity = Object.assign(activeProject, {});
    projectEntity.timezoneId = timezone.id;
    setActiveProject(projectEntity);
    setTimezone(timezone.id);
    if (validateUser()) dispatch(updateProjectRequest(projectEntity));

    timezoneRef.current.style.display = "none";
  };

  const validateUser = () => {
    let staffMap = staffReducer ? staffReducer : {};
    let currentStaff = staffMap ? staffMap[userReducer.id] : {};
    let currentStaffPermission = currentStaff.userPermission
      ? currentStaff.userPermission
      : "RESTRICTED";
    if (currentStaffPermission !== "FULL_ACCESS") {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "You should be an owner to do this!",
          dismissAfter: 3000,
          showError: true,
        })
      );
      return false;
    }
    return true;
  };

  const toggleOutOfOffice = () => {
    if (validateUser()) {
      let newChatConfig = Object.assign({}, chatConfigState);
      newChatConfig.isOutOfOfficeEnabled = !newChatConfig.isOutOfOfficeEnabled;
      setChatConfigState(newChatConfig);
      dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
      updateChatConfigurationEntity({
        key: newChatConfig.key,
        isOutOfOfficeEnabled: newChatConfig.isOutOfOfficeEnabled,
      });
    }
  };

  const handleInputChange = (e) => {
    if (e.type == "keypress" && e.key === "Enter") {
      e.preventDefault();
      onBlurHandler(e);
      e.target.blur();
    } else if (e.type === "input") {
      let value = e.target.textContent;
      //e.target.textContent = "";
      setOutOfOfficeMessasge(value);
    }
  };

  const handleBlur = (e) => {
    let chatConfig = Object.assign(chatConfigState, {});
    let message = e.target.textContent;
    message = message.trim();
    if (message) {
      updateOutOfficeMessage(message);
    } else {
      e.target.textContent = unescape(chatConfig.oooAnswerPhrase);
      setOutOfOfficeMessasge(chatConfig.oooAnswerPhrase);
      dispatch(
        VoiceboxActions.showVoicebox({
          message:
            "The out of office message can't be empty so updating with previous value!",
          dismissAfter: 3000,
          showError: true,
        })
      );
    }
  };

  const updateOutOfficeMessage = (message) => {
    let chatConfig = Object.assign({}, chatConfigState);
    if (escape(message) !== chatConfig.oooAnswerPhrase) {
      chatConfig.oooAnswerPhrase = escape(message);
      setChatConfigState(chatConfig);
      setOutOfOfficeMessasge(message);
      dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
      let { key, oooAnswerPhrase } = chatConfig;
      updateChatConfigurationEntity({ key, oooAnswerPhrase });
    }
  };

  // let activeProject = project ? getCurrentProjectEntity() : {};
  let currentTimeZone = "loading";
  let currentTimeZoneId = "Asia/Calcutta";
  if (
    activeProject &&
    activeProject.timezoneId &&
    activeProject.timezoneId !== "null"
  ) {
    currentTimeZoneId = activeProject.timezoneId;
    let timeZoneMap = ctzc.getAllTimezones();

    let tempTimezone = timeZoneMap[currentTimeZoneId];

    if (!tempTimezone) tempTimezone = timeZoneMap["Asia/Calcutta"];

    currentTimeZone = "(GMT " + tempTimezone.GMT + ") " + tempTimezone.id;
  }
  const projectKey = "LS-" + props.params.projectkey;
  if (Object.keys(businessHoursFromReducer).length > 0)
    return (
      <BusinessHoursComponent
        timezone={currentTimeZone}
        updateTimezone={updateTimezone}
        toggleOutOfOffice={toggleOutOfOffice}
        isEnabled={isOutOfOfficeEnabled}
        outOfOfficeMessasge={outOfOfficeMessasge}
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        updateOutOfficeMessage={updateOutOfficeMessage}
        projectKey={projectKey}
        accountId={activeProject.accountId}
        timezoneId={activeProject.timezoneId}
        validateUser={validateUser}
        businessHoursFromReducer={businessHoursFromReducer}
        updateBusinessHours={updateBusinessHours}
        createBusinessHours={createBusinessHours}
      />
    );
  else {
    return <div></div>;
  }
};

export default BusinessHoursContainer;
