import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import { DEFAULT_ICON } from "../../commons/Constants";

class SearchableDropDown extends Component {
  constructor(props) {
    super();

    this.state = {
      options: [],
      dropDownVisibilityClass: "",
      dropDownTypeClass: props.dropdownInfo.dropDownTypeClass,
    };
    this.onSearch = this.onSearch.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.imageError = this.imageError.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentWillMount() {
    document.addEventListener("click", this.handleClick, false);
    this.setState({
      options: this.props.options,
      selectedOption: this.props.currentlySelected,
    });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      options: nextProps.options,
      selectedOption: nextProps.currentlySelected,
    });
  }

  toggleDropdown() {
    if (this.state.dropDownVisibilityClass == "") {
      this.setState(
        { dropDownVisibilityClass: "open", options: this.props.options },
        () => {
          (this.refs.dropdown_search.value = ""),
            this.refs.dropdown_search.focus();
        }
      );
    } else {
      this.setState({ dropDownVisibilityClass: "" });
    }
  }

  onSearch(event) {
    let searchString = event.target.value.trim();

    let filteredOptions = [];

    if (searchString) {
      filteredOptions = this.props.options.filter((option) => {
        return option.text.toLowerCase().includes(searchString.toLowerCase());
      });

      this.setState({ options: filteredOptions });
    } else {
      this.setState({ options: this.props.options });
    }
  }

  handleClick(event) {
    if (!ReactDOM.findDOMNode(this).contains(event.target)) {
      if (this.state.dropDownVisibilityClass)
        this.setState({ dropDownVisibilityClass: "" });
    }
  }
  imageError(e) {
    e.target.src = this.props.defaultImage;
  }
  getInitials(firstName, lastName) {
    return (
      (firstName ? firstName.charAt(0) : "") +
      (lastName ? lastName.charAt(0) : "")
    ).toUpperCase();
  }
  onSelect(e) {
    this.props.onSelect(e);
    this.setState({ dropDownVisibilityClass: "" });
  }
  render() {
    let selectedOptText =
      this.state.selectedOption && this.state.selectedOption.text
        ? this.state.selectedOption.text
        : "";
    let appendToolTip = !!this.props.appendToolTip
      ? this.props.appendToolTip
      : "";
    let appendToolTipClass = !!this.props.appendToolTipClass
      ? this.props.appendToolTipClass
      : "";
    let names = selectedOptText.split(" ");
    let firstName = names.shift();
    let lastName = names.join(" ");
    return (
      <div
        className={
          this.state.dropDownTypeClass +
          " " +
          this.state.dropDownVisibilityClass
        }
        style={this.props.style ? this.props.style : {}}
        tabIndex="0"
        onBlur={this.closeDropdown}
      >
        <p
          ref={this.props.triggerRef}
          data-tip={
            this.refs.selectedValue &&
            this.refs.selectedValue.scrollWidth >
              this.refs.selectedValue.offsetWidth
              ? this.state.selectedOption && this.state.selectedOption.text
                ? this.state.selectedOption.text + " " + appendToolTip
                : ""
              : appendToolTip
          }
          data-class={"tooltip info searchableDropdown " + appendToolTipClass}
          onClick={this.toggleDropdown}
        >
          {this.props.shrinkSelected ? (
            <span ref="selectedValue">{selectedOptText} </span>
          ) : (
            selectedOptText
          )}
          {this.state.selectedOption &&
          this.props.showSelectedPhoto === true ? (
            this.state.selectedOption.isOverflowedAgent ? (
              <code>
                {this.getInitials(
                  firstName,
                  lastName ? lastName : firstName.charAt(1)
                )}
              </code>
            ) : (
              <figure
                className={
                  this.state.selectedOption &&
                  this.state.selectedOption.optionPresense
                    ? "available"
                    : "offline"
                }
              >
                <img
                  onError={this.imageError}
                  src={
                    this.state.selectedOption &&
                    this.state.selectedOption != "null"
                      ? this.state.selectedOption.img
                      : DEFAULT_ICON.STAFF_ICON
                  }
                  alt={selectedOptText}
                />
              </figure>
            )
          ) : (
            ""
          )}
        </p>
        <div>
          <input
            ref="dropdown_search"
            type="text"
            aria-label="search"
            placeholder="Search"
            onChange={this.onSearch}
          />
          <ul>
            {this.state.options.map((option, index) => (
              <li
                key={index}
                ref={index}
                data-id={option.key}
                onClick={this.onSelect}
              >
                <a
                  href="javascript:void(0)"
                  data-tip={
                    option.text && option.text.length > 16 ? option.text : ""
                  }
                  data-class="tooltip info searchableDropdown"
                >
                  <figure
                    className={
                      option.optionPresense == true
                        ? "available"
                        : option.optionPresense == false
                        ? "offline"
                        : ""
                    }
                  >
                    <img
                      onError={this.imageError}
                      src={
                        option.img && option.img != "null"
                          ? option.img
                          : DEFAULT_ICON.STAFF_ICON
                      }
                      alt={option.text ? option.text : ""}
                    />
                  </figure>
                  {option.text ? option.text : ""}{" "}
                  <small>
                    {option.openConversationCount != undefined
                      ? "(" + option.openConversationCount + ")"
                      : ""}
                  </small>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default SearchableDropDown;
