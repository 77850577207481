/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react'
import { Container, Item } from 'src/features/ui/components/List/styled'
import { IProps } from 'src/features/ui/components/List/types'

export const List: FC<IProps> = ({ items }) => (
  <Container>
    {items.map((item, index) => (
      <Item key={index}>{item}</Item>
    ))}
  </Container>
)
