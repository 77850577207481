import * as Yup from 'yup'

export const initialValues = { emailId: '', password: '' }

export const validationSchema = Yup.object().shape({
  emailId: Yup.string()
    .email("Oops, email address doesn't look right")
    .required("Email can't be empty"),
  password: Yup.string()
    .trim('Password must not begin or end with a space')
    .strict()
    .min(6, 'Password must have at least 6 characters')
    .max(64, 'Password must be no more than 64 characters')
    .matches(/(?=.*[A-Za-z])/u, 'Password must contain at least one letter')
    .matches(/(?=.*[0-9])/u, 'Password must contain at least one number')
    .required("Password can't be empty"),
})
