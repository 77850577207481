import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import { WIDGET_TYPE } from "../../../commons/Constants";
import { useChatConfigurationEntity } from "../../hooks/useChatConfigurationEntity";
import { useUpdateChatConfigurationEntity } from "../../hooks/useUpdateChatConfigurationEntity";

const WidgetType = (props) => {
  const projectId = `LS-${props.params.projectkey}`;
  const { chatConfiguration, isConfigFetched } = useChatConfigurationEntity(
    projectId
  );

  const [widgetType, setWidgetType] = useState("");
  const updateChatConfigurationEntity = useUpdateChatConfigurationEntity();
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatConfiguration.widgetType)
      setWidgetType(chatConfiguration.widgetType);
    else if(isConfigFetched) setWidgetType(WIDGET_TYPE.DEFAULT);
  }, [isConfigFetched]);

  const setDefaultType = () => {
    toggleWidgetType(WIDGET_TYPE.DEFAULT);
  };

  const setIconType = () => {
    toggleWidgetType(WIDGET_TYPE.ICON);
  };

  const toggleWidgetType = (selectedType) => {
    if (widgetType !== selectedType) {
      setWidgetType(selectedType);
      let chatConfig = { key: chatConfiguration.key, widgetType: selectedType };
      dispatch(showVoicebox({ message: "Updating" }));
      updateChatConfigurationEntity(chatConfig);
    }
  };

  return (
    <div>
    <div className="cs-widget-style">
      <label>Widget Type</label>
      <div
        className={
          widgetType === WIDGET_TYPE.DEFAULT
            ? "widget-type default-icon selected"
            : "widget-type default-icon"
        }
        onClick={setDefaultType}
      >
        <small>Default</small>
      </div>
      <div
        className={
          widgetType === WIDGET_TYPE.ICON
            ? "widget-type normal-icon selected"
            : "widget-type normal-icon"
        }
        onClick={setIconType}
      >
        <small>Icon only</small>
      </div>
    </div>
    </div>
  );
};

export default WidgetType;
