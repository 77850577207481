import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  displayVisitorType,
  SHORT_CHAT_STATUS_MAP,
  MESSAGE_TYPE,
  FEEDBACK_STATUSES
} from "../../commons/Constants";
import TimeAgo from "../../containers/TimeAgo";
import { browserHistory } from "react-router";
import { clearAllNewMessageCount } from "../../actions/NewMessageAction";
import { reSetUnreadCount } from "../../actions/ConversationInfoAction";
import { removeTagFromConversationRequest } from "../../actions/TagActions";
import { getAvatarColor, getNewFormatedTime } from "../../commons/Utility.js";
import { ChatHistoryIcon , AtassignIcon  , ClockIcon } from "../../commons/Icons";
import { resetNewMessage } from "../../actions/ConversationInfoAction";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import { store } from "../../app";
import {MentionedIcon} from '../../commons/Icons'
class ConversationTab extends Component {
  constructor() {
    super();
    this.onClickHandler = this.onClickHandler.bind(this);
    this.removeFromPresentTags = this.removeFromPresentTags.bind(this);
  }
  onClickHandler(e) {
    let conversation = this.props.conversation;
    if (this.props.isFromChatHistory) {
      Event(
        conversation.projectId,
        "History",
        "Previous Chat History  ",
        "Previous Chat History Clicked"
      );
      this.props.conversationListData.expandConversation(e);
    } else {
      let params = this.props.params;
      let projectKey = conversation.projectId.replace("LS-", "");
      let chatStatus = params.chatStatus;
      let sortBy = params.sortBy;
      let assignedTo = params.assignedTo;
      let visitorOption = params.visitorOption;
      browserHistory.push({
        pathname:
          "/app/" +
          projectKey +
          "/chat/dashboard/" +
          visitorOption +
          "/" +
          chatStatus +
          "/" +
          sortBy +
          "/" +
          assignedTo +
          "/" +
          conversation.key,
        search: this.props.pathQueryString ? this.props.pathQueryString : "",
      });
      store.dispatch(clearAllNewMessageCount());
      if (
        params &&
        params.conversationId &&
        params.conversationId !== conversation.key
      )
        store.dispatch(resetNewMessage(params.conversationId));
      if(!conversation.key){
        console.log("the conversation is not having any key in conversation tab :" )
      }
      else{
        store.dispatch(reSetUnreadCount(conversation.key));
      }
    }
  }
  removeFromPresentTags(e) {
    e.stopPropagation();
    let tagId = e.currentTarget.dataset.tag_id;
    let conversation = this.props.conversation;
    console.log("tagId: ", tagId);
    store.dispatch(removeTagFromConversationRequest(tagId, conversation));
  }
  renderTags() {
    let conversationListData = this.props.conversationListData;
    let conversationListState = conversationListData.state;
    let tagMap = conversationListState.tagMap;
    let conversation = this.props.conversation;
    let toRender = [];
    for (let tag of conversation.tags) {
      if (tagMap[tag])
        toRender.push(
          <cite className="fs-exclude">
            {tagMap[tag].name}
            <i
              data-conversation_id={conversation.key}
              data-tag_id={tag}
              onClick={this.removeFromPresentTags}
              dangerouslySetInnerHTML={{ __html: "&times;" }}
            ></i>
          </cite>
        );
    }
    return toRender;
  }

  getFullName(assignedStaff) {
    if (assignedStaff.aliasName && assignedStaff.aliasName !== "null") {
      if (assignedStaff.lastName && assignedStaff.lastName !== "null") {
        return (
          assignedStaff.aliasName +
          " " +
          assignedStaff.lastName.charAt(0).toUpperCase()
        );
      } else {
        return assignedStaff.aliasName;
      }
    } else {
      return assignedStaff.fullName;
    }
  }
  getLastMessage(conversation,fullName) {
    fullName = fullName ? fullName.charAt(0).toUpperCase() + fullName.slice(1) : fullName;
    if(conversation && conversation.feedbackStatus === FEEDBACK_STATUSES.FEEDBACK_STATUS_SUBMITTED)
      return `${fullName} has left a feedback`;
    if (conversation && conversation.lastMessage)
      return conversation.lastMessage;
    return this.props.lastMessage;
  }
  render() {
    let contact = this.props.contact || {};
    let conversation = this.props.conversation;
    let fullName = "";
    let displayName = "";
    let initials = contact
      ? contact.firstName
        ? contact.firstName.charAt(0)
        : contact.type
        ? displayVisitorType[contact.type].charAt(0)
        : "V"
      : "V";
    initials += contact && contact.lastName ? contact.lastName.charAt(0) : "";
    let messageFrom = conversation.messageFrom
      ? conversation.messageFrom
      : "AGENT";
    let status = conversation.chatStatus;
    let assignedStaff = this.props.assignedStaff
      ? this.props.assignedStaff
      : {};
    let interactionType = conversation.interactionType;
    let overflowClass = "";
    let unAnswered =
      messageFrom == "VISITOR" && status == "OPEN" ? true : false;
    let userId = this.props.userId;
    let lastMessageFromText = "";
    let unReadElement = "";

    if (this.props.isFromChatHistory) {
      if (conversation.assignedTo) {
        if (userId == conversation.assignedTo) fullName = "You";
        else fullName = this.getFullName(assignedStaff);
      } else fullName = "Unassigned";
    } else {
      fullName = contact
        ? contact.firstName
          ? contact.firstName
          : contact.type
          ? displayVisitorType[contact.type]
          : "Visitor"
        : "Visitor";
      fullName += contact && contact.lastName ? " " + contact.lastName : "";

      if (
        (fullName == "Lead" || fullName == "User") &&
        contact &&
        contact.email
      ) {
        displayName = contact.email;
        initials = displayName ? displayName.charAt(0) : "L";
      }
    }

    let assignedTo =
      conversation.lastMessageSenderId || conversation.assignedTo;
    if (conversation.messageFrom == "AGENT") {
      if (assignedTo && assignedTo == userId) lastMessageFromText = "Me: ";
      else if (assignedTo)
        lastMessageFromText = this.props.getAgentNameById(assignedTo) + ": "; //assignedStaff.fullName;
    }
    /* else if( conversation.messageFrom == "BOT")
        {
            lastMessageFromText = "Automated Response: ";
        } */

    let displayIcon = "";

    if (interactionType == "OVERFLOW") overflowClass = "overflow-chat-diff";

    if (this.props.unReadCount > 0)
      unReadElement = (
        <code className="fs-exclude">{this.props.unReadCount}</code>
      );
    if(conversation.mentionedStaffs && (conversation.mentionedStaffs.includes(userId) || (conversation.closedMentionChats && conversation.closedMentionChats.includes(userId))) && !this.props.isFromChatHistory) {
      unReadElement = <MentionedIcon />
    }

    if( conversation.assignedTo === userId && conversation.isManualyAssignedAgent){
      unReadElement = <AtassignIcon/>
    } 

    if( conversation.chatStatus === 'PENDING') {
      unReadElement = <ClockIcon/>
    }

    /* if(this.props.isFromChatHistory)
            {
                displayIcon = <figure className= { assignedStaff && assignedStaff.isOnline && assignedStaff.staffType != "OVERFLOW" ? "available" : "" } >
                                    <img src={ assignedStaff.staffType == "OVERFLOW" ? location.origin+"https://app.chatsupport.co/images/overflow.svg" :this.props.getAgentImageURLById(conversation.assignedTo) }  ref={img => this.img = img} onError={() => this.img.src =location.origin+'https://app.chatsupport.co/images/profile_Icon.png' } style={{"cursor":"pointer"}} data-tip={fullName} data-class="tooltip info"/>
                              </figure>;
            }
            else  */ if (
      contact &&
      !!contact.profileImage
    ) {
      let visitorName = contact && contact.firstName ? contact.firstName : "";
      visitorName += contact && contact.lastName ? contact.lastName : "";
      displayIcon = (
        <figure
          className="fs-exclude"
          style={contact ? { backgroundColor: contact.avatarColor } : {}}
        >
          <img
            src={contact.profileImage}
            ref={(img) => (this.img = img)}
            onError={() =>
              (this.img.src =  "https://app.chatsupport.co/images/profile_Icon.png")
            }
            data-tip={visitorName}
            data-class="tooltip info"
          />
        </figure>
      );
    } else {
      displayIcon = (
        <figure
          className="fs-exclude"
          style={contact ? { backgroundColor: contact.avatarColor } : {}}
        ></figure>
      );
    }
    let lastMessage = this.getLastMessage(conversation,fullName);
    let modifiedDate = conversation.modifiedDate + store.getState().UserReducer.timeDifference;
    return (
      <li
        className={overflowClass + " " + this.props.styles}
        data-conversation_id={conversation.key}
        onClick={this.onClickHandler}
      >
        {this.props.isFromChatHistory ? <ChatHistoryIcon /> : ""}
        {this.props.isFromChatHistory ? "" : displayIcon}
        {this.props.isFromChatHistory ? (
          ""
        ) : (
          <cite className={contact.isOnline ? "available" : "offline"}></cite>
        )}
        <label className="fs-exclude">{fullName}</label>
        <p className="fs-exclude">
          <code>
            {conversation &&
            conversation.feedbackStatus ===
              FEEDBACK_STATUSES.FEEDBACK_STATUS_SUBMITTED
              ? ""
              : lastMessageFromText}
          </code>
          {lastMessage}
        </p>
        {this.props.isFromChatHistory ? (
          ""
        ) : (
          <span className="fs-exclude">{this.props.projectName}</span>
        )}
        {this.props.isFromChatHistory ? (
          <span className="fs-exclude">
            {getNewFormatedTime(modifiedDate, false)}
          </span>
        ) : (
          <small>{getNewFormatedTime(modifiedDate)}</small>
        )}
        {unReadElement}
      </li>
    );

    return (
      <a
        href="javascript:void(0)"
        data-conversation_id={conversation.key}
        className={overflowClass}
        onClick={this.onClickHandler}
      >
        {displayIcon}
        <div>
          <label
            ref="fullName"
            className={conversation.assignedTo ? "" : "unassigned"}
            data-class="tooltip info"
            data-tip={
              this.refs.fullName &&
              this.refs.fullName.scrollWidth > this.refs.fullName.offsetWidth
                ? fullName
                : ""
            }
          >
            {displayName ? displayName : fullName}
          </label>
          <p>{lastMessage}</p>
          {this.props.projectName ? <span>{this.props.projectName}</span> : ""}
          <TimeAgo
            tag={"small"}
            lastSeenTime={
              conversation.modifiedDate ||
              (lastMessage && lastMessage.createdDate) ||
              conversation.createdDate
            }
          />
          {this.props.isFromChatHistory ? this.renderTags() : ""}
          {/* unAnswered ? <code className="unread"></code> : '' */}
        </div>
      </a>
    );
  }
}
export default ConversationTab;
