export const PROJECT_EVENT_REQUEST = "PROJECT_EVENT_REQUEST";
export const PROJECT_EVENT_RESPONSE = "PROJECT_EVENT_RESPONSE";
export const PROJECT_EVENT_FAILED = "PROJECT_EVENT_FAILED";
export const UPDATE_PROJECT_EVENT_REQUEST = "UPDATE_PROJECT_EVENt_REQUEST";
export const UPDATE_PROJECT_EVENT_RESPONSE = "UPDATE_PROJECT_EVENT_RESPONSE";
export const UPDATE_PROJECT_EVENT_FAILED = "UPDATE_PROJECT_EVENT_FAILED";
export const DELETE_PROJECT_EVENT_REQUEST = "DELETE_PROJECT_EVENT_REQUEST";
export const DELETE_PROJECT_EVENT_RESPONSE = "DELETE_PROJECT_EVENT_RESPONSE";
export const DELETE_PROJECT_EVENT_FAILED = "DELETE_PROJECT_EVENT_FAILED";

export const requestProjectEvent = (projectId) => ({
  type: PROJECT_EVENT_REQUEST,
  projectId,
});

export const projectEventResponse = (projectEventsMap) => ({
  type: PROJECT_EVENT_RESPONSE,
  projectEventsMap,
});

export const projectEventFailed = () => ({
  type: PROJECT_EVENT_FAILED,
});

export const updateProjectEventRequest = (projectEvent) => ({
  type: UPDATE_PROJECT_EVENT_REQUEST,
  projectEvent,
});

export const updateProjectEventResponse = (projectEvent) => ({
  type: UPDATE_PROJECT_EVENT_RESPONSE,
  projectEvent,
});

export const updateProjectEventFailed = () => ({
  type: UPDATE_PROJECT_EVENT_FAILED,
});
export const deleteProjectEventRequest = (projectEventId) => ({
  type: DELETE_PROJECT_EVENT_REQUEST,
  projectEventId,
});
export const deleteProjectEventResponse = (projectEventId) => ({
  type: DELETE_PROJECT_EVENT_RESPONSE,
  projectEventId,
});
export const deleteProjectEventFailed = () => ({
  type: DELETE_PROJECT_EVENT_FAILED,
});
