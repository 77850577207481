import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSelectedCampaignMessage } from "../../commons/Utility";

const ChatQuickPreview = (props) => {
  const { color } = props;
  const { campaignDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
  }));
  const campaignFromImageRef = useRef("");
  const previewContainerRef = useRef("");

  // useEffect(() => {
  //   if (previewContainerRef.current)
  //     previewContainerRef.current.scrollTop =
  //       previewContainerRef.current.scrollHeight;
  // }, [campaignDetails.selectedCampaign.message]);

  useEffect(() => {
    campaignFromImageRef.current.style = {};
  }, [campaignDetails.selectedCampaign.campaignFromImage]);

  const getPreviewHtml = () => {
    let message = getSelectedCampaignMessage(campaignDetails.selectedCampaign);
    let replacedMessage = message.replace(
      "+_add_content",
      `<p>...</p>
    `
    );
    getStaffDetails();
    replacedMessage = replacedMessage.replace(
      "+_from_",
      `<p>
        <span
       style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;color:#181818"
        >
         From
        </span>
        ${props.agentName || "Agent Name"}
      </p>`
    );
    replacedMessage = getProperReplacedFallbackValues(replacedMessage);
    return { __html: replacedMessage };
  };

  const getStaffDetails = () => {
    let staff = props.randomStaffData() || {};
    if (props.showFallbackValues) {
      return {
        "{{firstName}}": staff.firstName || "Richard",
        "{{lastName}}": staff.lastName || "Tyler",
        "{{fullName}}": staff.fullName || "Richard Tyler",
        "{{companyName}}":
          campaignDetails.selectedCampaign.projectName || "CES Consulting",
      };
    } else {
      return {
        "{{firstName}}": "First Name",
        "{{lastName}}": "Last Name",
        "{{fullName}}": "Full Name",
        "{{companyName}}": "Company Name",
      };
    }
  };

  const getProperReplacedFallbackValues = (message) => {
    let fallbackStaffData = getStaffDetails();
    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `${fallbackStaffData[match]}`;
      }
    );

    return message;
  };

  const imageError = (e) => {
    e.target.style.display = "none";
  };

  const showImage = (e) => {
    e.target.style.display = "block";
  };

  return (
    <section className="cs-campaign-preview quick-preview">
      <div>
        <header>
          <h2>
            We're
            <br />
            happy to chat<span>.</span>
          </h2>
          <code>&times;</code>
        </header>
        <div ref={previewContainerRef} class="widget-camp-cont">
          <div>
            <figure>
              <img
                id="campaign_from_image"
                onError={imageError}
                onLoad={showImage}
                ref={campaignFromImageRef}
                src={campaignDetails.selectedCampaign.campaignFromImage}
              />
            </figure>
            <div
              id="preview_content"
              dangerouslySetInnerHTML={getPreviewHtml()}
            />
          </div>
        </div>
        <footer>
          <input
            type="text"
            value=""
            placeholder="Send your message..."
            disabled
          />
          <i class="attachment-icon-btn"></i>
          <i
            id="campaign_send_color"
            style={{ backgroundColor: color ? `#${color}` : "#4684ff" }}
            class="send-icon-btn"
          ></i>
        </footer>
      </div>
    </section>
  );
};

export default ChatQuickPreview;
