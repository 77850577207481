import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { IProps } from 'src/features/returningFlow/components/Wizard/steps/2/Content/types'
import { SubTitle } from 'src/features/ui/components/Typography'
import { WidgetInstall } from 'src/features/wizard/install/components/WidgetInstall'

export const WidgetInstallationContent: FC<IProps> = ({ nextStep }) => (
  <>
    <SubTitle bolderer>How do you want to install your widget?</SubTitle>
    <Margin top={16} />
    <WidgetInstall
      hasFixedHeader={false}
      continueButtonProps={{
        handleOnClick: nextStep,
        label: 'Continue',
      }}
    />
  </>
)
