import { call, put, fork } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as EventsActivityAction from "../actions/EventsActivityAction";
import { authFailed } from "../actions/LoginAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* doFetchEventsActivity(request) {
  const url = "/events/activity/get";
  const requestParam = request.requestParam;

  try {
    let response = yield call(Ajax.post, url, requestParam, "application/json");
    let visitorEventMap = {};
    if (response.response == true) {
      let eventActivityMap = {};
      response = response.data.entity;
      response.map((activity, i) => {
        eventActivityMap[activity.key] = activity;
      });

      visitorEventMap[requestParam.visitorId] = eventActivityMap;
      yield put(EventsActivityAction.eventActivityResponse(visitorEventMap));
    } else yield put(EventsActivityAction.eventActivityFailed());
  } catch (e) {
    console.error(e);
    yield put(EventsActivityAction.EventsActivityAction());
    yield put(handleGlobalError(e));
  }
}
