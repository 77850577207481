export const UPLOAD_FILE_URL_REQUESTED = "UPLOAD_FILE_URL_REQUESTED";
export const UPLOAD_ATTACHMENT_REQUESTED = "UPLOAD_ATTACHMENT_REQUESTED";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS";
export const UPLOAD_ATTACHMENT_FAILED = "UPLOAD_ATTACHMENT_FAILED";
export const UPLOAD_WIDGET_ICON_URL_REQUESTED =
  "UPLOAD_WIDGET_ICON_URL_REQUESTED";
export const UPLOAD_WIDGET_ICON_REQUESTED = "UPLOAD_WIDGET_ICON_REQUESTED";
export const UPLOAD_WIDGET_ICON_RESPONSE = "UPLOAD_WIDGET_ICON_RESPONSE";
export const UPLOAD_WIDGET_ICON_FAILED = "UPLOAD_WIDGET_ICON_FAILED";

export const requestFileUploadUrl = (
  fileDetails,
  isOverflowConversation,
  isAwWindow
) => ({
  type: UPLOAD_FILE_URL_REQUESTED,
  fileDetails,
  isOverflowConversation,
  isAwWindow,
});

export const requestWidgetIconUploadUrl = (fileDetails) => ({
  type: UPLOAD_WIDGET_ICON_URL_REQUESTED,
  fileDetails,
});

export const requestAttachmentUpload = (
  fileDetails,
  isOverflowConversation,
  isAwWindow
) => ({
  type: UPLOAD_ATTACHMENT_REQUESTED,
  fileDetails,
  isOverflowConversation,
  isAwWindow,
});

export const requestWidgetIcontUpload = (fileDetails) => ({
  type: UPLOAD_WIDGET_ICON_REQUESTED,
  fileDetails,
});

export const successAttachmentUpload = (fileResponse) => ({
  type: UPLOAD_ATTACHMENT_SUCCESS,
  fileResponse,
});

export const failedAttachmentUpload = () => ({
  type: UPLOAD_ATTACHMENT_FAILED,
});
