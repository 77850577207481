import React, { Component } from "react";
import ReactDom from "react-dom";

class OverflowSettingsComp extends Component {
  isOverFlowEnabled() {
    return (
      this.props.state &&
      this.props.state.chatConfig &&
      this.props.state.chatConfig.isOverFlowEnabled
    );
  }

  isDisconnectTimerEnabled() {
    //endChatOnVisitorDisconnect
    return (
      this.props.state &&
      this.props.state.chatConfig &&
      this.props.state.chatConfig.endChatOnVisitorDisconnect
    );
  }

  getDisConnectTimerValue() {
    return (
      this.props.state &&
      this.props.state.chatConfig &&
      this.props.state.chatConfig.autoDisconnectOverflowChatTimer
    );
  }

  getFormattedDisconnectedValue() {
    let disconnectedTimers = {
      0: "OFF",
      15: "15 seconds",
      30: "30 seconds",
      45: "45 seconds",
      60: "1 minute",
      90: "1 minute and 30 seconds",
      120: "2 minutes",
      150: "2 minutes and 30 seconds",
      180: "3 minutes",
      210: "3 minutes and 30 seconds",
      240: "4 minutes",
      300: "5 minutes",
      600: "10 minutes",
      900: "15 minutes",
      1200: "20 minutes",
    };
    let timerValue =
      this.props.state &&
      this.props.state.chatConfig &&
      this.props.state.chatConfig.autoDisconnectOverflowChatTimer;
    return disconnectedTimers[timerValue] || "Loading..";
  }

  getDropDownStyle() {
    return {
      width: "230px",
      display:
        this.props.state && this.props.state.toggleDisconnectTimerDropDown
          ? "block"
          : "none",
    };
  }

  render() {
    return (
      <div>
        <section className="settings-content">
          <div>
            <h3>Redirect Chats to Agents</h3>
            <code
              onClick={this.props.handlers.toggleOverFlow}
              className={
                this.isOverFlowEnabled()
                  ? "switch-btn switch-on"
                  : "switch-btn switch-off"
              }
            ></code>
            <span className="text">
              Enable overflow chats and let us handle your chats when your team
              is unavailable to take them.
            </span>
          </div>
        </section>

        <section className="settings-content">
          <div>
            <h3>Set Disconnect Time </h3>
            {/* <code onClick={this.props.handlers.toggleDisconnectTimer} className={this.isDisconnectTimerEnabled() ? "switch-btn switch-on" : "switch-btn switch-off"}></code> */}
            <span className="text">
              Disconnect the interaction, if the interaction is unresponsive
              after certain amount of time.
            </span>
            <div className="input-fields">
              <label style={{ width: "280px" }}>
                Disconnect unresponsive chats after{" "}
              </label>
              <div
                onClick={this.props.handlers.toggleDisconnectTimerDropdown}
                className="dropdown-fields"
                style={{ width: "230px" }}
              >
                <p>{this.getFormattedDisconnectedValue()}</p>
                <ul
                  onClick={this.props.handlers.updateDisconnectTimer}
                  style={this.getDropDownStyle()}
                >
                  <li data-value="0">OFF</li>
                  <li data-value="15">15 seconds</li>
                  <li data-value="30">30 seconds</li>
                  <li data-value="45">45 seconds</li>
                  <li data-value="60">1 minute</li>
                  <li data-value="90">1 minute 30 seconds</li>
                  <li data-value="120">2 minutes</li>
                  <li data-value="150">2 minute 30 seconds</li>
                  <li data-value="180">3 minutes</li>
                  <li data-value="210">3 minute 30 seconds</li>
                  <li data-value="240">4 minutes</li>
                  <li data-value="300">5 minutes</li>
                  <li data-value="600">10 minutes</li>
                  <li data-value="900">15 minutes</li>
                  <li data-value="1200">20 minutes</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default OverflowSettingsComp;
