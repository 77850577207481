import {
  createQueryByReference,
  generateReferenceId,
  getEncrypted,
  getDecrypted,
} from "../../commons/Utility.js";
import {
  FILTER_PEOPLE_TYPE_MAP,
  DEFAULT_COLUMN_HEADERS,
  DEFAULT_COLUMN_FIELDS,
  CONTACT_DATE_FIELDS,
} from "../commons/ContactConstants.js";
import { fieldDetails } from "../../commons/PeopleESIndex.js";
import { getDisplayTime } from "../../commons/Utility.js";
import moment from "moment";
import { TYPE_OF_CAMPAIGN_STATS, EMAIL_CAMPAIGN_VIEW, EMAIL_SUBSCRIPTION_PROPERTY, filterTypes } from "../../commons/Constants.js";

const EMAIL_CAMPAIGN_REPLY = "reply";

export const getOrderedMap = (sourceObject = {}, ordering = []) => {
  let objectKeys = Object.keys(sourceObject);
  let orderedMap = new Map();
  ordering.forEach((element) => {
    if (sourceObject.hasOwnProperty(element))
      orderedMap.set(element, sourceObject[element]);
  });
  objectKeys.forEach((key) => {
    if (!ordering.hasOwnProperty(key)) orderedMap.set(key, sourceObject[key]);
  });
  console.info("orderedMap:", orderedMap);
  return orderedMap;
};

export const getSearchQuery = (queryInfo) => {
  let filterType = "all";
  let { references, groupId, projectkey } = queryInfo;
  return {
    references: references,
    query: createQueryByReference(references, filterType, projectkey),
    scrollId: "",
    groupId: groupId,
    filterType,
  };
};

//This method goes away when we migrate the companyname to default property for support projects
export const insertCompanyName = (updatedSelectedFields, companyNameProperty) => {
  let positionOfCompanyName = 0;
  if(updatedSelectedFields.indexOf('name') > -1) 
    positionOfCompanyName++; 
  if(updatedSelectedFields.indexOf('email') > -1)
    positionOfCompanyName++;
  if(updatedSelectedFields.indexOf('phoneNumber') > -1)
    positionOfCompanyName++;
 
  updatedSelectedFields.splice(positionOfCompanyName, 0, companyNameProperty); 
  let lastIndex = updatedSelectedFields.lastIndexOf(companyNameProperty);
  if(lastIndex!=positionOfCompanyName)
    updatedSelectedFields.splice(lastIndex, 1);
return updatedSelectedFields;
}

export const getContactHeader = (defaultColumns, headers = [], columns = [], customFields = {}) => {
  headers = headers.length ? headers : DEFAULT_COLUMN_HEADERS;
  let headerInfo = [];
  let allHeaders = [];
  let { properties } = fieldDetails;
  defaultColumns = Object.keys(defaultColumns);
  headers = headers.filter((header) => !DEFAULT_COLUMN_FIELDS.includes(header));
  headers = reOrderHeader(headers, defaultColumns);
  headerInfo.push({
    name: "fullName",
    prependImage: true,
    imageSrc: "profileImage",
    alias: "type",
    type: "text"
  });
  allHeaders.unshift("fullName");
  headers.map((header) => {
    if (columns[header] && !DEFAULT_COLUMN_FIELDS.includes(header)) {
      if(isCustomProperty(header))
        headerInfo.push({ name: header, type: customFields[header].type });
      else if (properties[header] && properties[header].type == "Number")
        headerInfo.push({ name: header, align: "center"});
      else headerInfo.push({ name: header});

      allHeaders.push(header);
    }
  });
  headers.map((header) => columns[header]);
  return { headerInfo, headers: allHeaders };
};

export const reOrderHeader = (source = [], orderKeys = []) => {
  let leadingMembers = [];
  let trailingMembers = [];
  orderKeys.forEach((key) => {
    if (source.includes(key)) leadingMembers.push(key);
  });
  source.forEach((key) => {
    if (!leadingMembers.includes(key)) trailingMembers.push(key);
  });
  return [...leadingMembers, ...trailingMembers];
};

export const getInitials = (firstName, lastName) => {
  return (
    (firstName && firstName != "null" && firstName != "undefined" ? firstName.charAt(0) : "") +
    (lastName && lastName != "null" && lastName != "undefined" ? lastName.charAt(0) : "")
  ).toUpperCase();
};

export const getPropertyType = (property, events, customFields) => {
  let type = "tags";

  if (fieldDetails.properties[property])
    type = fieldDetails.properties[property].type;
  else if (events[property]) type = "event";
  else if (customFields[property])
    type = customFields[property].type;
  else if(property === EMAIL_SUBSCRIPTION_PROPERTY)
    type = property

  return type;
};

export const getFilterDescripton = (filterType, value, tags) => {
  let filterDescription = {
    filterTypeAlias: "filtered as",
    valueAlias: `${value}`,
  };
  switch (filterType) {
    case "contains":
      filterDescription = {
        filterTypeAlias: "contains",
        valueAlias: `${value}`,
      };
      break;
    case "not_contains":
      filterDescription = {
        filterTypeAlias: "doesn't contains",
        valueAlias: `${value}`,
      };
      break;
    case "exactly":
      filterDescription = {
        filterTypeAlias: "exactly",
        valueAlias: `${value}`,
      };
      break;
    case "not_exactly":
      filterDescription = {
        filterTypeAlias: "not exactly",
        valueAlias: `${value}`,
      };
      break;
    case "has_no_value":
      filterDescription = { filterTypeAlias: "has", valueAlias: `no value` };
      break;
    case "has_any_value":
      filterDescription = { filterTypeAlias: "has", valueAlias: `any value` };
      break;
    case "more_then_times":
      filterDescription = {
        filterTypeAlias: "more than",
        valueAlias: `${value} times`,
      };
      break;
    case "less_then_times":
      filterDescription = {
        filterTypeAlias: "less than",
        valueAlias: `${value} times`,
      };
      break;
    case "more_then_days":
      filterDescription = {
        filterTypeAlias: "not within last",
        valueAlias: `${value} days`,
      };
      break;
    case "less_then_days":
      filterDescription = {
        filterTypeAlias: "within last",
        valueAlias: `${value} days`,
      };
      break;
    case "is_bool_true":
      filterDescription = { filterTypeAlias: "is", valueAlias: `true` };
      break;
    case "is_bool_false":
      filterDescription = { filterTypeAlias: "is", valueAlias: `false` };
      break;
    case "is_user":
      filterDescription = { filterTypeAlias: "is", valueAlias: `customer` };
      break;
    case "is_lead":
      filterDescription = { filterTypeAlias: "is", valueAlias: `lead` };
      break;
    case "is_visitor":
      filterDescription = { filterTypeAlias: "is", valueAlias: `visitor` };
      break;
    case "is_company":
      filterDescription = { filterTypeAlias: "is", valueAlias: `company` };
      break;
    case "after_date": {
      let valueAlias = moment(value || new Date().getTime()).format(
        "YYYY-MM-DD"
      );
      filterDescription = {
        filterTypeAlias: "after",
        valueAlias: `${valueAlias}`,
      };
      break;
    }
    case "before_date": {
      let valueAlias = moment(value || new Date().getTime()).format(
        "YYYY-MM-DD"
      );
      filterDescription = {
        filterTypeAlias: "before",
        valueAlias: `${valueAlias}`,
      };
      break;
    }
    case "on_date": {
      let valueAlias = moment(value || new Date().getTime()).format(
        "YYYY-MM-DD"
      );
      filterDescription = {
        filterTypeAlias: "on",
        valueAlias: `${valueAlias}`,
      };
      break;
    }
    case "more_then_no_days":
      filterDescription = {
        filterTypeAlias: "is not in last",
        valueAlias: `${value} days`,
      };
      break;
    case "less_then_no_days":
      filterDescription = {
        filterTypeAlias: "is in last",
        valueAlias: `${value} days`,
      };
      break;
    case "more_then":
      filterDescription = {
        filterTypeAlias: "is more than",
        valueAlias: `${value} times`,
      };
      break;
    case "less_then":
      filterDescription = {
        filterTypeAlias: "is less than",
        valueAlias: `${value} times`,
      };
      break;
    case "less_then_no_days":
      filterDescription = {
        filterTypeAlias: "is in last",
        valueAlias: `${value} days`,
      };
      break;
    case "equals_to":
      filterDescription = {
        filterTypeAlias: "equals to",
        valueAlias: `${value} times`,
      };
      break;
    case "is_tagged": {
      let tagAlias = tags[value] ? tags[value].name : value;
      filterDescription = {
        filterTypeAlias: "added as",
        valueAlias: `${tagAlias}`,
      };
      break;
    }
    case "is_not_tagged": {
      let tagAlias = tags[value] ? tags[value].name : value;
      filterDescription = {
        filterTypeAlias: "not added as",
        valueAlias: `${tagAlias}`,
      };
      break;
    }
    case "subscribed": {
      filterDescription = {
        filterTypeAlias: "is",
        valueAlias: filterTypes.subscribed,
      };
      break;
    }
    case "unsubscribed": {
      filterDescription = {
        filterTypeAlias: "is",
        valueAlias: filterTypes.unsubscribed,
      };
      break;
    }
    default:
      filterDescription = {
        filterTypeAlias: "filtered as",
        valueAlias: `${value}`,
      };
  }
  return filterDescription;
};

export const sanitizeValue = (value, selectedFilter) => {
  let { type, filterType } = selectedFilter;
  if (
    type == "date" &&
    (filterType == "more_then_no_days" || filterType == "less_then_no_days")
  )
    value = value && value > 365 ? 7 : value;

  return value;
};

export const createFilterReference = (
  selectedFilter,
  events = {},
  customFields = {},
  key
) => {
  let { property, filterType, value } = selectedFilter;
  let type = getPropertyType(property, events, customFields);
  let isCustomField = customFields.hasOwnProperty(property);
  let isEvent = events.hasOwnProperty(property);
  return {
    key: key || generateReferenceId(),
    type: type,
    c: isCustomField,
    isEventProp: isEvent,
    filterType: filterType,
    value: value,
    property: property,
  };
};

export const validMinDate = (value) => {
  value = parseInt(value);
  let year = moment(value).format("YYYY");
  if (year > 1970) return true;
  return false;
};

export const validateInput = ({ property, value, type, filterType }, tags) => {
  let validationObject = { isValid: true };
  if (type == "text" && value.trim().length < 3) {
    if (
      (filterType == "exactly" || filterType == "not_exactly") &&
      value.trim().length == 0
    )
      validationObject = {
        isValid: false,
        message: "Input should have some value",
      };
    else if (
      (filterType == "contains" || filterType == "not_contains") &&
      value.trim().length < 2
    )
      validationObject = {
        isValid: false,
        message: "Input should be more than one characters",
      };
  } else if (type == "Number" && value.trim().length == 0) {
    if (
      filterType == "more_then" ||
      filterType == "less_then" ||
      filterType == "equals_to"
    )
      validationObject = { isValid: false, message: "Please type a number!" };
  } else if (type == "Tags" && !tags[value]) {
    validationObject = { isValid: false, message: "Please select a tag!" };
  }
  return validationObject;
};

export const encodeReferences = (references) => {
  let toEncrypt = { references, filterType: "all" };
  return getEncrypted(JSON.stringify(toEncrypt));
};

export const decodeReferences = (encodedFilters) => {
  let decryptedJSON = getDecrypted(JSON.stringify(encodedFilters));
  let decrypted = JSON.parse(decryptedJSON);
  return decrypted;
};

export const getGroupMap = (searchQuery, projectkey, groupName, userId) => {
  return {
    projectId: `LS-${projectkey}`,
    createdBy: userId,
    groupName: groupName,
    completeQuery: JSON.stringify(searchQuery.query),
    references: JSON.stringify(searchQuery.references),
    filterType: "all",
  };
};

export const getGroupType = (group = {}) => {
  if (!group.references) return;
  let refMap = JSON.parse(group.references);
  let refs = refMap ? Object.values(refMap) : [];
  let contactTypes = new Set();
  let type = "segment";
  refs.forEach(({ type, filterType }) => {
    if (type == "peopleType" && filterType)
      contactTypes.add(FILTER_PEOPLE_TYPE_MAP[filterType]);
  });
  type = contactTypes.size == 1 ? contactTypes.values().next().value : type;
  return type;
};

export const getGroupIconSrc = (groupType) => {
  let groupIconSrc = "https://app.chatsupport.co/images/visitors-icon.svg";
  if (groupType == "leads") groupIconSrc = "https://app.chatsupport.co/images/leads-icon.svg";
  else if (groupType == "visitors") groupIconSrc = "https://app.chatsupport.co/images/visitors-icon.svg";
  else if (groupType == "customers")
    groupIconSrc = "https://app.chatsupport.co/images/customers-icon.svg";
  else if (groupType == "companies")
    groupIconSrc = "https://app.chatsupport.co/images/companies-icon.svg";

  return groupIconSrc;
};

export const getRelativeDate = (value) => {
  let dateTime = new Date(value);
  let { displayTime } = getDisplayTime(dateTime);
  return displayTime;
};

export const getCustomPropertySortKey = (sortProperty , type)  => {
  return `${sortProperty}_${type}` + (type == "String" ? `.keyword` : ``);
}

export const getSortProperty = (property, customFields) => {
  let sortProperty =
    property == "name" || property == "fullName" ? "firstName" : property;
  sortProperty = customFields[sortProperty]
    ? getCustomPropertySortKey(sortProperty, customFields[sortProperty].type)
    : sortProperty;
  if(fieldDetails.properties[property] && fieldDetails.properties[property].type == "text"
          && !sortProperty.includes("keyword") && !sortProperty.includes('companyKey'))
          sortProperty += ".keyword";
  return sortProperty;
};

export const getInitialValue = (value, queryType, type) => {
  if (type == "date") {
    if (
      queryType == "after_date" ||
      queryType == "before_date" ||
      queryType == "on_date"
    ) {
      if ((value && !validMinDate(value)) || !value)
        value = moment().startOf("day").valueOf();
    } else if (
      queryType == "more_then_no_days" ||
      queryType == "less_then_no_days"
    ) {
      if (value && !isNaN(value) && value > 365) value = 7;
    }
  }
  return value;
};

export const searchKeys = (propertyKeys, search, properties) => {
  let keys = [];
  if (!search) keys = propertyKeys;
  else
    keys = propertyKeys.filter((property) =>
      properties[property].toLowerCase().includes(search.trim().toLowerCase())
    );
  return keys;
};

export const getColumnName = (name = "", type = "") => {
  if(name.startsWith("CF-")) {
    if(type)
      return `${name}_${type}` 
    return `${name}_String`;
  }
  return name;
};

export const getFormattedValueForActualValue = (actualValue, columnName, placeholder) =>{
  if (CONTACT_DATE_FIELDS.includes(columnName)) {
    return getRelativeDate(actualValue);
  } else if (isCustomProperty(columnName) && hasISOTimeChars(actualValue)) {
    return getAbsoluteDate(actualValue);
  } else if (isCustomProperty(columnName) && typeof actualValue == "boolean") {
    return actualValue.toString();
  } else if (isCustomProperty(columnName)) {
    return actualValue;
  } else if (columnName == "state") {
    return actualValue ? actualValue.toUpperCase() : placeholder;
  } else if (columnName == "tags") {
    return placeholder;
  }
  return actualValue;
}

export const getFormattedValue = (columnNameParam, contactParam) => {
  let columnName = columnNameParam || "";
  let contact = contactParam || {};
  let actualValue = contact[columnName];
  let formattedValue;
  let placeholder = "-";
  try {
    if (actualValue) {
     formattedValue = getFormattedValueForActualValue(actualValue, columnName, placeholder);
    } else {
      if (isCustomProperty(columnName)) {
        formattedValue =
          contact[columnName] ||
          contact[`${columnName}_keyword`] ||
          contact[`${columnName}_String_standard`] ||
          contact[`${columnName}_String_whitespace`] ||
          placeholder;
      } else if (columnName === EMAIL_CAMPAIGN_REPLY) {
        let conversationId = contact["conversationId"];
        let placeholderValue = [];
        placeholderValue.push(EMAIL_CAMPAIGN_VIEW);
        placeholderValue.push(conversationId);
        return placeholderValue;
      } else if (columnName === "Not Delivered") {
        return getNotDeliveredMessage(contact[TYPE_OF_CAMPAIGN_STATS.NOT_DELIVERED]);
      }else{
        formattedValue = placeholder;
      }
    }
  } catch (e) {
    formattedValue = placeholder;
  }
  return formattedValue;
};

export const getNotDeliveredMessage = (notDeliveredReason) => {
  if (notDeliveredReason === "Unsubscribed Address")
    return "Unsubscribed";
  else
    return notDeliveredReason;
}

export const isCustomProperty = (propertyName = "") => {
  return propertyName.startsWith("CF-");
};

export const hasISOTimeChars = (value = "") => {
  return typeof value == "string" && value.includes("T") && value.includes(":");
};

export const getAbsoluteDate = (customFieldValue = "") => {
  let formattedValue = "";
  try {
    let isValidISO = moment(customFieldValue, moment.ISO_8601).isValid();
    if (isValidISO)
      formattedValue = moment(customFieldValue).format("DD MMM YYYY hh:mm A");
    else formattedValue = customFieldValue;
  } catch (e) {
    formattedValue = customFieldValue;
  }
  return formattedValue;
};

export const differentiateSegments = (segmentDetails) => {
  if (segmentDetails.key == "bda809a7-007a-48bf-b447-06fe11dd2479")
    segmentDetails.groupName = "Drifting Away Visitors";
  else if (segmentDetails.key == "f4e8c142-4649-4de1-b735-43fd759a6263")
    segmentDetails.groupName = "Drifting Away Customers";
  return segmentDetails;
};

export const getCategories = (groupsList) => {
  let categories = {};
  groupsList.forEach((group) => {
    let groupType = getGroupType(group);
    categories[groupType] = categories[groupType] ? categories[groupType] : {};
    categories[groupType][group.key] = group;
  });
  return categories;
};