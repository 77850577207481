import React, { FC } from 'react'
import { Container } from 'src/features/ui/components/ChatBubble/styled'
import { IProps } from 'src/features/ui/components/ChatBubble/types'

export const ChatBubble: FC<IProps> = ({
  children,
  isPositionedLeft,
  isPositionedRight,
}) => (
  <Container isLeft={isPositionedLeft} isRight={isPositionedRight}>
    {children}
  </Container>
)
