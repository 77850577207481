import React, { useEffect, useRef, useState } from "react";
import { InfoCircleIcon, SearchIcon, WhiteTickIcon } from "../../../commons/Icons";
import {isValidString} from "../../../commons/Utility";

const ImportContactsDropDown = (props) => {
  const {
    existingColumns,
    setColumnName,
    columnIndex,
    setCustomField,
    isCustomField,
    columnName,
    unMappedExistingColumns,
    setDropDownColumn,
    customFieldsMap,
  } = props;
  const [ selectedColumnIndex, setSelectedColumnIndex] = useState(-1);
  const [displayableColumns, setDisplayableColumns] = useState([]);
  const [unMappedColumns, setUnMappedColumns] = useState([]);
  const [isDuplicateCustomField, setIsDuplicateCustomField] = useState(false);
  const columnRef = useRef([]);
  const dropDownRef = useRef(null);
  const searchRef = useRef(null);
  const inputRef = useRef(null);

  const FILE_EXISTS = "file-exists";
  const FILE_ALREADY_EXISTS = "Field already exists";

  useEffect(() => {
    let columnsDisplay = Object.assign([], unMappedExistingColumns);
    columnsDisplay[existingColumns.indexOf(columnName)] = columnName;
    setDisplayableColumns(columnsDisplay);
    setUnMappedColumns(columnsDisplay)
    setSelectedColumnIndex(existingColumns.indexOf(columnName));
    if(searchRef && searchRef.current)
      searchRef.current.focus();
    document.addEventListener("mousedown",handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown",handleOutsideClick);
    }
  },[]);

  useEffect(() => {  
    if(columnRef.current.length - 1 === unMappedExistingColumns.length && columnRef.current[selectedColumnIndex]){
      columnRef.current[selectedColumnIndex].scrollIntoView({ block: "start" });
    }
  },[columnRef.current.length]);

  const handleOutsideClick = (e) => {
    if (
      dropDownRef &&
      dropDownRef.current &&
      !dropDownRef.current.contains(e.target)&&
      e.target.tagName !== "SPAN"
    )
      setDropDownColumn(-1);
    e.stopPropagation();
  };

  const getExistingColumns = () => {
    let toRender = [];
    let currentExistingColumns = Object.assign([], displayableColumns);
    let customFieldValue = isCustomField ? columnName : "";
    let classNameForDuplicateCF = isDuplicateCustomField ? FILE_EXISTS : "";
    let infoCirCleIcon = isDuplicateCustomField ? <InfoCircleIcon /> : "";
    let errorMessage = isDuplicateCustomField ? FILE_ALREADY_EXISTS : "";
    if(displayableColumns.length === unMappedColumns.length)
      currentExistingColumns.unshift("Add Custom");
    currentExistingColumns.forEach((propertyName,index) => {
      toRender.push(
        <li
          key={index}
          className={getClassName(index)}
          onClick={() => setColumn(existingColumns.indexOf(propertyName))}
          ref={setRefs}
          onKeyDown={ () => {} }
        >
          <a href="javascript:void(0)">
            <cite>
              <WhiteTickIcon />
            </cite>
            {propertyName}
          </a>
          {index == 0 && (
            <React.Fragment>
              <input
                type="text"
                ref={inputRef}
                Value={customFieldValue}
                placeholder="Custom Field"
                onKeyDown={addCustomField}
                className={classNameForDuplicateCF}
              ></input>
              {isDuplicateCustomField ? (
                <div className="unmaped-list errors-list">
                  {infoCirCleIcon}
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </li>
      );
    });
    return toRender;
  };

  const setRefs = (el) => {
    if (el && columnRef.current && !columnRef.current.includes(el))
      columnRef.current.push(el);
  }

  const addCustomField = (e) => {
    if (e.keyCode === 13 || e.which === 13 && isValidString(e.target.value)) {
      if (
        isExistingDefaultFieldValue(e.target.value) ||
        isExistingCustomFieldValue(e.target.value)
      )
        setIsDuplicateCustomField(true);
      else {
        setIsDuplicateCustomField(false);
        setCustomField(e.target.value, columnIndex, false);
      }
    }
  }

  const setColumn = (index) => {
    setColumnName(index, columnIndex);
    setSelectedColumnIndex(index);
    if (index === -1)
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    if (selectedColumnIndex == -1 && index != -1)
      setCustomField(columnName, index + 1, true);
  };

  const getClassName = (index) => {
    if(index == -1)
      return "active";
   return selectedColumnIndex === index - 1 && displayableColumns.length === unMappedColumns.length ? "active" : "";
  }

  const searchColumnName = (e) => {
    let value = e.target.value;
    let searchedColumnsResult = unMappedColumns.filter((propertyName) => propertyName.toLowerCase().includes(value.toLowerCase()));
    searchedColumnsResult = value ? searchedColumnsResult : unMappedColumns;
    setDisplayableColumns(searchedColumnsResult);
    e.stopPropagation();
    if (isDuplicateCustomField) removeErrorMessageAndCustomName();
  }

  const removeErrorMessageAndCustomName = () => {
    inputRef.current.value = "";
    setIsDuplicateCustomField(false);
  };

  const isExistingCustomFieldValue = (propertyName) => {
    const CFValues = Object.assign({}, customFieldsMap);
    const customName = propertyName;
    return Object.values(CFValues).some(
      (value) =>
        value.toLocaleLowerCase().replace(/ +/g, "") ===
        customName.toLocaleLowerCase().replace(/ +/g, "")
    );
  }

  const isExistingDefaultFieldValue = (propertyName) => {
    const defaultColumns = Object.assign([], existingColumns);
    const customName = propertyName;
    return defaultColumns.some(
      (value, index) =>
        value.toLocaleLowerCase().replace(/ +/g, "") ===
        customName.toLocaleLowerCase().replace(/ +/g, "")
    );
  };

  const submitCustomField = () => {
    if (inputRef && inputRef.current && isValidString(inputRef.current.value)) {
      if (
        isExistingDefaultFieldValue(inputRef.current.value) ||
        isExistingCustomFieldValue(inputRef.current.value)
      ) {
        setIsDuplicateCustomField(true);
      } else {
        setIsDuplicateCustomField(false);
        setCustomField(inputRef.current.value, columnIndex, false);
      }
    }
  };
  
  return (
    <div ref={dropDownRef}>
      <div class="search-field">
        <input type="text"  onInput={searchColumnName}  placeholder="Search columns" ref={searchRef}/>
        <SearchIcon />
      </div>
      <ul className={selectedColumnIndex === -1 && displayableColumns.length === unMappedColumns.length ? "" : "normal-dwn"}>{getExistingColumns()}</ul>
      {selectedColumnIndex === -1 && displayableColumns.length === unMappedColumns.length ? <button class="cs-btn cs-btn-xs cs-primary-btn fr" onClick={submitCustomField}>Apply</button>: ""}
    </div>
  );
};

export default ImportContactsDropDown;
