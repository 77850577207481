import {
  USER_AUTHENTICATION_FAILED,
  USER_AUTHENTICATION_CHANGED,
  API_INTERNAL_EXCEPTION,
} from "../actions/ExceptionAction.js";

const ExceptionReducer = (
  state = {
    userAuthenticationFailed: false,
    userAuthenticationChanged: false,
    apiInternalException: false,
  },
  action
) => {
  switch (action.type) {
    case USER_AUTHENTICATION_FAILED:
      return {
        ...state,
        userAuthenticationFailed: true,
        userAuthenticationChanged: false,
        apiInternalException: false,
      };
    case USER_AUTHENTICATION_CHANGED:
      return {
        ...state,
        userAuthenticationFailed: false,
        userAuthenticationChanged: true,
        apiInternalException: false,
      };
    case API_INTERNAL_EXCEPTION:
      return {
        ...state,
        userAuthenticationFailed: false,
        userAuthenticationChanged: false,
        apiInternalException: true,
      };
  }
  return state;
};

export default ExceptionReducer;
