import * as R from 'ramda'
import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.ul`
  padding: 0;
  margin: 0;
  counter-reset: list-counter;
`

export const Item = styled.li`
  padding: 0 0 0 4rem;
  margin: 0;
  list-style-type: none;
  counter-increment: list-counter;
  position: relative;

  & + & {
    margin-top: 2.4rem;

    ${mq.md} {
      margin-top: 3.2rem;
    }
  }

  &::before {
    content: counter(list-counter);
    position: absolute;
    left: 0;
    top: 0;
    width: 2.4rem;
    height: 2.4rem;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${R.path(['theme', 'color', 'raven'])};
    background-color: ${R.path(['theme', 'color', 'athensGray'])};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
