import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import sha1 from 'sha1'
import { Success } from 'src/features/authorization/components/Success'
import { Icon } from 'src/features/ui/components/Icon'
import {
  Content,
  Layout,
  Left,
  Right,
} from 'src/features/ui/components/Layout/styled'
import { WizardHeader } from 'src/features/wizard/shared/components/WizardHeader'
declare let _ls: any
declare let window: any

export const SignUpSuccessPage = () => {
  const [cookies, setCookie] = useCookies(['name'])

  const setCustomDataForWidgetInstalled = (
    installationStatus: boolean,
    userId: string,
    email: string
  ) => {
    const userInfo = {
      email,
      userId,
      customFields: {
        installationStatus,
      },
    }
    /*eslint no-underscore-dangle: ["error", { "allow": ["_ls"] }]*/
    if (_ls && typeof _ls !== 'undefined') {
      _ls('update', userInfo)
    }
  }

  const setTrackingDataForTapfiliate = (accountId: string) => {
    if (window.tap && typeof window.tap === 'function') {
      window.tap('trial', accountId)
    }
  }

  const getAccountId = () => localStorage.getItem('accountId') ?? ''
  const getUserId = () => localStorage.getItem('ls_user_id') ?? ''
  const getLogin = () => localStorage.getItem('login') ?? ''
  const getTrackStatus = () => localStorage.getItem('impactTrackStatus') ?? ''

  //Pushing the signup details to the impact
  const pushTrackingFormImpact = () => {
    const accountId = getAccountId()
    const userId = getUserId()
    const login = getLogin()
    const trackStatus = getTrackStatus()
    if (
      accountId &&
      userId &&
      login &&
      window.ire &&
      typeof window.ire === 'function' &&
      trackStatus !== 'true'
    ) {
      window.ire('identify', { customerId: userId, customerEmail: sha1(login) })
      window.ire(
        'trackConversion',
        28369,
        {
          orderId: accountId,
          customerId: userId,
          customerEmail: sha1(login),
        },
        {
          verifySiteDefinitionMatch: true,
        }
      )
    }
  }

  const setDataLayerForSignup = () => {
    const gtmWindow = window as any
    const accountID = getAccountId()
    if (accountID) {
      gtmWindow.dataLayer = gtmWindow.dataLayer || []
      gtmWindow.dataLayer.push({
        event: 'Transaction',
        type: 'Free plan',
        plan_type: 'Monthly',
        account: accountID,
        transactionId: accountID,
        transactionAffiliation: 'ChatSupport',
        transactionTotal: 0,
        transactionTax: 0,
        transactionShipping: 0,
        transactionProducts: [
          {
            sku: 'free',
            name: accountID,
            category: 'Monthly',
            price: 0,
            quantity: 1,
          },
        ],
      })
    }
  }

  useEffect(() => {
    if (!cookies.clrstatus) {
      setCookie('clrstatus', true, { path: '/' })
    }

    setDataLayerForSignup()
    pushTrackingFormImpact()

    setCustomDataForWidgetInstalled(false, getUserId(), getLogin())
    setTrackingDataForTapfiliate(getAccountId())
  }, [])

  return (
    <Layout>
      <WizardHeader currentStep={0} />
      <Content>
        <Left shouldCenterContent>
          <Success />
        </Left>
        <Right shouldCenterContent>
          <Icon fill="dodgerBlue" type="checkMark2" width={115} height={115} />
        </Right>
      </Content>
    </Layout>
  )
}
