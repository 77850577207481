import React from "react";

const NoConversationsHeader = (props) => {
    return (
        <section className="cs-conversation">
            <header>
                <figure className="cs-chat-group" style={{backgroundColor: "rgb(244, 244, 244)"}}>
                    <em className="progress" style={{width: "100%" ,  height: "100%" ,  borderRadius: "100%" , "backgroundColor": "#f4f4f4"}}></em>
                </figure>
                <label><em className="progress" style={{"width":"190px"}}></em></label>
                <p><em className="progress" style={{"width":"100px","margin":"6px 0px 0px"}}></em></p>
            </header>
        </section>
    )
}

export default NoConversationsHeader;