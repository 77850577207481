import React, { useState, useEffect, useRef } from "react";
import { ErrorIcon, ArrowIcon } from "../../commons/Icons";
import {
  PopupCloseIcon,
  EditPopupIcon,
  DeletePopupIcon,
} from "../../commons/Icons";

const ProactivePopUpComponent = (props) => {
  const {
    toggleProactivePopup,
    onEditMode,
    proactiveConfigObject,
    saveChangesFromPopup,
  } = props;
  const { isMessageValid, isURLValid, isDuplicateURL } = props;
  const { setIsURLValid, setIsDuplicateURL, setIsMessageValid } = props;
  const [showSecondsPopup, setShowSecondsPopup] = useState(false);
  const [promptWaitingSeconds, setPromptWaitingSeconds] = useState(0);
  const [promptName, setPromptName] = useState("");
  const [enabledUrl, setEnabledUrl] = useState("");
  const [message, setMessage] = useState("");
  const [delayFocus, setDelayFocus] = useState(false);
  const [dropDwnIndex, setDropDwnIndex] = useState(0);

  const [isNameFieldEnabled, setIsNameFieldEnabled] = useState(false);
  const [isUrlFieldEnabled, setIsUrlFieldEnabled] = useState(false);
  const [isMessageFieldEnabled, setIsMessageFieldEnabled] = useState(false);
  const [isDropDownEnabled, setIsDropDownEnabled] = useState(false);

  useEffect(() => {
    setPromptWaitingSeconds(proactiveConfigObject.promptWaitingSeconds);
    if (promptNameRef && promptNameRef.current)
      setTimeout(() => {
        promptNameRef.current.focus();
      }, 100);
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    if (dropDwnIndex > -1 && secondsRef.current) {
      let element = secondsRef.current[dropDwnIndex];
      if (element) element.scrollIntoView({ block: "nearest" });
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropDwnIndex, showSecondsPopup]);

  const messageRef = useRef(null);
  const enabledUrlRef = useRef(null);
  const promptNameRef = useRef(null);
  const dropdownRef = useRef(null);
  const secondsRef = useRef([]);

  const handleOnFocus = (enableFunction) => {
    enableFunction(true);
    let node = event.target;
    let text = node.firstChild;
    let caret = event.target.innerText.length;
    if (caret > 0) {
      let range = document.createRange();
      range.setStart(text, caret);
      range.setEnd(text, caret);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handleKeyDown = (e) => {
    if (e.which === 27 || e.keyCode === 27) {
      if (!showSecondsPopup) toggleProactivePopup(false);
      else toggleSecondspopup();
    } else if (showSecondsPopup) {
      let indexValue = dropDwnIndex;
      if ((e.which === 38 || e.keyCode === 38) && indexValue > 0) {
        indexValue--;
      } else if ((e.which === 40 || e.keyCode === 40) && indexValue < 60) {
        indexValue++;
      } else if (e.which === 13 || e.keyCode === 13) {
        if (indexValue > -1 && secondsRef.current)
          secondsRef.current[indexValue].click();
      }
      e.preventDefault();
      setDropDwnIndex(indexValue);
    }
  };

  const getPopupHeader = () => {
    if (onEditMode)
      return (
        <h2>
          <EditPopupIcon />
          Edit
        </h2>
      );
    else
      return (
        <h2>
          <EditPopupIcon />
          Create
        </h2>
      );
  };

  const addRefs = (el) => {
    if (el && !secondsRef.current.includes(el)) secondsRef.current.push(el);
  };

  const getSecondsArray = () => {
    let secondsArray = [];
    for (let second = 0; second <= 60; second++) {
      secondsArray.push(
        <li
          onClick={onChangeSeconds}
          ref={addRefs}
          data-value={second}
          key={second}
        >
          <a
            data-value={second}
            href="javascript:void(0)"
            className={second === dropDwnIndex ? "select" : ""}
          >
            {second + (second < 2 ? " second" : " seconds")}
          </a>
        </li>
      );
    }

    return <ul>{secondsArray}</ul>;
  };

  const toggleSecondspopup = () => {
    setIsDropDownEnabled(true);
    setShowSecondsPopup(!showSecondsPopup);
  };

  const focusDropDown = () => {
    setDelayFocus(true);
  };

  const blurDropDown = () => {
    setDelayFocus(false);
    //setIsDropDownEnabled(false);
  };

  const onEditHandler = () => {
    if (canShowSaveButton()) {
      let promptDataToEdit = {
        ...proactiveConfigObject,
        message: messageRef.current.textContent
          ? messageRef.current.textContent.trim()
          : "",
        enabledUrl: enabledUrlRef.current.textContent
          ? enabledUrlRef.current.textContent.trim()
          : "",
        promptName: promptNameRef.current.textContent
          ? promptNameRef.current.textContent.trim()
          : "",
        promptWaitingSeconds: promptWaitingSeconds,
      };
      saveChangesFromPopup(promptDataToEdit);
    }
  };

  const onChangeSeconds = (event) => {
    setPromptWaitingSeconds(event.target.dataset.value);
    setIsDropDownEnabled(false);
    toggleSecondspopup();
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    console.log("Text befor pasting :", text);
    document.execCommand("insertHTML", false, text);
  };

  const updatePromptName = () => {
    setPromptName(promptNameRef.current.textContent);
  };

  const updateEnabledUrl = () => {
    setEnabledUrl(enabledUrlRef.current.textContent);
    setIsURLValid(true);
    setIsDuplicateURL(false);
  };

  const updateMessage = () => {
    setMessage(messageRef.current.textContent);
    setIsMessageValid(true);
  };

  const canShowSaveButton = () => {
    if (messageRef.current && enabledUrlRef.current && promptNameRef.current) {
      if (
        enabledUrlRef.current.textContent.length === 0 &&
        messageRef.current.textContent.length === 0
      )
        return false;

      if (
        proactiveConfigObject.promptName ===
          promptNameRef.current.textContent &&
        proactiveConfigObject.enabledUrl ===
          enabledUrlRef.current.textContent &&
        proactiveConfigObject.message === messageRef.current.textContent &&
        proactiveConfigObject.promptWaitingSeconds ===
          Number(promptWaitingSeconds)
      ) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  };

  const getUrlFieldClassName = () => {
    if (isURLValid && !isDuplicateURL && isUrlFieldEnabled)
      return "cs-input-field cs-active-field";
    else if (isURLValid && !isDuplicateURL) return "cs-input-field";
    else return "cs-input-field cs-error-field";
  };

  const getMessageFieldEnabled = () => {
    if (isMessageValid && isMessageFieldEnabled)
      return "cs-input-field cs-active-field";
    else if (isMessageValid) return "cs-input-field";
    else return "cs-input-field cs-error-field";
  };

  const getDropDownClass = () => {
    if (isDropDownEnabled && showSecondsPopup)
      return "cs-dropdwn cs-active-field open";
    else if (showSecondsPopup) return "cs-dropdwn open";
    else if (delayFocus) return "cs-dropdwn focus";
    else return "cs-dropdwn";
  };

  const handleClick = (event) => {
    if (
      !(dropdownRef && dropdownRef.current.contains(event.target)) &&
      showSecondsPopup
    ) {
      toggleSecondspopup();
    }
  };

  const handleKeyPress = (e) => {
    if (e.which == 13 || e.keyCode == 13) e.preventDefault();
  };

  const handleOnEnter = (e) => {
    if (
      (e.which === 13 ||
        e.keyCode === 13 ||
        e.which === 32 ||
        e.keyCode === 32) &&
      !showSecondsPopup
    ) {
      e.target.click();
      e.preventDefault();
    }
    handleTab(e);
  };

  const handleTab = (e) => {
    if (e.target.nodeName !== "P" && (e.which === 9 || e.keyCode === 9)) {
      if (promptNameRef && promptNameRef.current)
        setTimeout(() => {
          promptNameRef.current.focus();
        }, 100);
      e.preventDefault();
    }
  };

  return (
    <div>
      <div className="cs-popupbg"></div>
      <div
        className="cs-popup-model popup-new cs-popup-new-prompt"
        onClick={handleClick}
      >
        <header>
          {getPopupHeader()}
          <cite
            onClick={() => {
              toggleProactivePopup(false);
            }}
          >
            ×
          </cite>
        </header>
        <section class="popup-new-content">
          <div className="cs-add-prompt">
            <div
              className={
                isNameFieldEnabled
                  ? "cs-input-field cs-active-field"
                  : "cs-input-field"
              }
            >
              <label>Name of the Prompt</label>
              <p
                className="fs-exclude"
                ref={promptNameRef}
                spellCheck={false}
                onFocus={() => {
                  handleOnFocus(setIsNameFieldEnabled);
                }}
                onBlur={() => {
                  setIsNameFieldEnabled(false);
                }}
                onInput={updatePromptName}
                contentEditable
                onPaste={onPasteHandler}
                placeholder="Assign a name for your reference"
                onKeyDown={handleKeyPress}
                dangerouslySetInnerHTML={{
                  __html: proactiveConfigObject.promptName,
                }}
              ></p>
            </div>
            <div className={getUrlFieldClassName()}>
              <label>Trigger URL</label>
              <p
                className="fs-exclude"
                ref={enabledUrlRef}
                spellCheck={false}
                onFocus={() => {
                  handleOnFocus(setIsUrlFieldEnabled);
                }}
                onBlur={() => {
                  setIsUrlFieldEnabled(false);
                }}
                onInput={updateEnabledUrl}
                contentEditable
                onPaste={onPasteHandler}
                placeholder="Enter the URL of the page where this prompt will trigger"
                onKeyDown={handleKeyPress}
                dangerouslySetInnerHTML={{
                  __html: proactiveConfigObject.enabledUrl,
                }}
              ></p>
              <span>
                <ErrorIcon />
                {isURLValid ? "" : "Invalid Url"}
                {isDuplicateURL ? "Contains duplicate Url" : ""}
              </span>
            </div>
            <div className={getMessageFieldEnabled()}>
              <label>Prompt Message</label>
              <p
                className="fs-exclude"
                ref={messageRef}
                spellCheck={false}
                onFocus={() => {
                  handleOnFocus(setIsMessageFieldEnabled);
                }}
                onBlur={() => {
                  setIsMessageFieldEnabled(false);
                }}
                onInput={updateMessage}
                contentEditable
                onPaste={onPasteHandler}
                placeholder="Enter the message to be displayed"
                style={{ lineHeight: 1.5 }}
                onKeyDown={handleKeyPress}
                dangerouslySetInnerHTML={{
                  __html: proactiveConfigObject.message,
                }}
              ></p>
              <span>
                <ErrorIcon />
                {isMessageValid ? "" : "Message Cannot be Empty"}
              </span>
            </div>
            <div className={getDropDownClass()}>
              <label>Delay </label>
              <p
                tabIndex="0"
                ref={dropdownRef}
                onFocus={focusDropDown}
                onBlur={blurDropDown}
                onClick={toggleSecondspopup}
                onKeyDown={handleOnEnter}
              >{`${promptWaitingSeconds} ${
                promptWaitingSeconds < 2 ? " second" : " seconds"
              }`}</p>
              <ArrowIcon />
              <div>{getSecondsArray()}</div>
            </div>
          </div>
        </section>

        <footer>
          <a
            href="javascript:void(0)"
            class="cs-btn cs-decline-btn"
            onClick={() => {
              toggleProactivePopup(false);
            }}
          >
            Cancel
          </a>
          <a
            href="javascript:void(0)"
            className={
              canShowSaveButton()
                ? "cs-btn cs-primary-btn"
                : "cs-btn cs-disabled-btn"
            }
            style={{ opacity: !canShowSaveButton() ? "0.5" : "1.0" }}
            disabled={!canShowSaveButton() ? true : false}
            onClick={onEditHandler}
            onKeyDown={handleOnEnter}
          >
            Save
          </a>
        </footer>
      </div>
    </div>
  );
};

export default ProactivePopUpComponent;
