export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_RESPONSE = "DELETE_USER_RESPONSE";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";

export const deleteUserRequest = (request) => ({
  type: DELETE_USER_REQUEST,
  request,
});

export const deleteUserResponse = () => ({
  type: DELETE_USER_RESPONSE,
});

export const deleteUserFailed = () => ({
  type: DELETE_USER_FAILED,
});
