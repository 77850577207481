import React, { Component } from "react";
import { connect } from "react-redux";
import OverflowComponent from "../components/chat/OverflowComponent.jsx";
import * as ConnectionAction from "../actions/MessageAction";
import * as ConversationInfoAction from "../actions/ConversationInfoAction";
import { requestChatConfiguration } from "../settings/actions/ChatConfigurationAction";
import { requestProjectById } from "../settings/actions/ProjectAction";
import { staffListRequest } from "../actions/StaffAction";
import { requestTagList } from "../actions/TagActions";
import { MESSAGE_FROM, MESSAGE_TYPE, CHAT_STATUS, STATUS_TRACKER_TOOL } from "../commons/Constants.js";
import {
  getOverflowApiKey,
  getAppMode,
  generateMessageId,
  isValidObject,
  getServerTime,
  getProjectKey,
  getWebhookVersion,
  constructNonDefaultHooks,
  getApiKey,
  searchToObject,
} from "../commons/Utility.js";
import { browserHistory } from "react-router";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";
@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  messageReducer: state.MessageReducer,
  ChatConfigurationReducer: state.ChatConfigurationReducer,
  staffReducer: state.StaffReducer,
  tagsReducer: state.TagReducer,
  projectReducer: state.ProjectReducer,
}))
class OverflowContainer extends Component {
  constructor() {
    super();
    this.state = {
      conversationId: "",
      conversationMap: {},
      interactionId: "",
      projectId: "",
      agentInfo: {},
      socketId: "",
      isConnected: false,
      params: {},
      isSubscriptionRequested: false,
      displayVisitorDetails: false,
      autoDisconnectOverflowChatTimer: 0,
    };
    this.checkAndSubscribeAgent = this.checkAndSubscribeAgent.bind(this);
    this.showVisitorDetails = this.showVisitorDetails.bind(this);
    this.hideVisitorDetails = this.hideVisitorDetails.bind(this);
    this.sendAcceptedMessage = this.sendAcceptedMessage.bind(this);
    this.sendAcceptedMessage_beta = this.sendAcceptedMessage_beta.bind(this);
  }
  componentWillMount() {
    console.log("The component will mount :", this.props);
    this.setLocalState(this.props, undefined, () => {
      console.log("this.props:::::: ", this.props);
      this.props.dispatch(ConnectionAction.init(true));
      this.props.dispatch(requestProjectById(this.state.projectId));
      if (!this.props.ConversationReducer.isSingleFetching) {
        console.log("Making the first request...");
        this.props.dispatch(
          ConversationInfoAction.conversationRequest({
            isFromOverflow: true,
            conversationId: this.state.conversationId,
            projectId: this.state.projectId,
          })
        );

        if (
          !this.props.ChatConfigurationReducer.isFetching &&
          !this.props.ChatConfigurationReducer.isFetched &&
          this.state.projectId.length
        )
          this.props.dispatch(
            requestChatConfiguration(this.state.projectId.slice(3), {}, true)
          );
        if (!this.props.staffReducer.dashboardAgents[this.state.projectId])
          this.props.dispatch(
            staffListRequest(this.state.projectId, true, this.state.agentInfo)
          );

        if (
          !this.props.tagsReducer.isFetching &&
          !this.props.tagsReducer.isFetched
        )
          this.props.dispatch(requestTagList(this.state.projectId, true));
      }
    });
    this.checkAndSubscribeAgent();
  }
  componentWillReceiveProps(nextProps) {
    this.setLocalState(nextProps, this.props, () => {
      if (
        !this.state.conversationMap[this.state.conversationId] &&
        !nextProps.ConversationReducer.isSingleFetching
      )
        nextProps.dispatch(
          ConversationInfoAction.conversationRequest({
            isFromOverflow: true,
            conversationId: this.state.conversationId,
            projectId: this.state.projectId,
          })
        ); 
      this.checkAndSubscribeAgent();
      if (
        nextProps.messageReducer.isSubscribedToChannels &&
        !nextProps.messageReducer.isAcceptedMessageSent
      )
        this.sendAcceptedMessage();
    });
  }
  setLocalState(nextProps, props, callback) {
    if (!nextProps.location.query) nextProps.location.query = searchToObject();
    let conversationId = nextProps.location.query.conversation_id
      ? nextProps.location.query.conversation_id
      : "";
    let interactionId = nextProps.location.query.connection_id
      ? nextProps.location.query.connection_id
      : "";
    let conversationMap =
      nextProps.ConversationReducer.conversationObject.conversationMap;
    let autoDisconnectOverflowChatTimer =
      nextProps.ChatConfigurationReducer.data &&
      nextProps.ChatConfigurationReducer.data[this.state.projectId] &&
      nextProps.ChatConfigurationReducer.data[this.state.projectId][
        "autoDisconnectOverflowChatTimer"
      ]
        ? nextProps.ChatConfigurationReducer.data[this.state.projectId][
            "autoDisconnectOverflowChatTimer"
          ]
        : 0;
    let projectId = nextProps.location.query.project_id;
    let agentInfo = {};
    agentInfo.key = nextProps.location.query.agent_id;
    agentInfo.userId = nextProps.location.query.agent_id;
    agentInfo.firstName = nextProps.location.query.agent_first_name;
    agentInfo.lastName = nextProps.location.query.agent_last_name;
    agentInfo.name = agentInfo.firstName + " " + agentInfo.lastName;
    agentInfo.photoUrl = nextProps.location.query.agent_photo_url;
    agentInfo.agentLogin = nextProps.location.query.agent_login;
    agentInfo.login = nextProps.location.query.agent_login;
    agentInfo.messageFrom = MESSAGE_FROM.AGENT;
    agentInfo.projectId = nextProps.location.query.project_id;
    agentInfo.contactId = nextProps.location.query.agent_id;
    agentInfo.aliasName = nextProps.location.query.alias_name;

    this.setState(
      {
        ...this.state,
        conversationId: conversationId,
        conversationMap: conversationMap,
        interactionId: interactionId,
        projectId,
        agentInfo: agentInfo,
        //project         : nextProps.projectReducer.projectList.find(project=>project.key == projectId),
        socketId: nextProps.messageReducer.socketId,
        autoDisconnectOverflowChatTimer: autoDisconnectOverflowChatTimer,
        params: {
          ...nextProps.params,
          projectkey: getProjectKey(nextProps.location.query.project_id),
        },
        isConnected:
          (nextProps.messageReducer.isConnected &&
            props &&
            !props.messageReducer.isConnected) ||
          this.state.isConnected
            ? true
            : false,
      },
      callback
    );
  }
  checkAndSubscribeAgent() {
    if (
      this.state.isConnected &&
      this.state.projectId &&
      !this.state.isSubscriptionRequested &&
      isValidObject(this.state.agentInfo) /* && this.state.project */
    ) {
      let agentInfo = Object.assign({}, this.state.agentInfo);

      let { isNondefault, nonDefaulthost } = getWebhookVersion();
      if (isNondefault) {
        agentInfo.nonDefaultHooks = constructNonDefaultHooks(nonDefaulthost);
        const mode = getAppMode() === "live" ? "live" : "staging";
        agentInfo.apiKey = getApiKey(mode);
      } else {
        console.log("Its loading in default version ");
      }

      const mode = getAppMode() === "live" ? "live" : "staging";
      this.props.dispatch(
        ConnectionAction.subscribe({
          isFromOverflow: true,
          apiKey: getOverflowApiKey(mode),
          interactionId: this.state.interactionId,
          socketId: this.state.socketId,
          agentInfo: agentInfo,
        })
      );
      this.state.isSubscriptionRequested = true;
    }
  }
  sendAcceptedMessage() {
    // if( this.state.agentInfo.userId != '05db384a-a862-4a55-8fd4-708ecacc70eb' ) {

    let conversationId = this.state.conversationId;
    let conversation = this.state.conversationMap[conversationId];
    console.log("Conversation received => " + JSON.stringify(conversation));
    let agentInfo = Object.assign({}, this.state.agentInfo);
    if (conversation && conversation.overflowStatus == "OVERFLOWED") {
      let visitorId = conversation.visitorId;
      let acceptedMessage = {
        channel: "server/routing",
        type: MESSAGE_TYPE.chat_auto_assign_on_overflow,
        message: "",
        actionTo: agentInfo.userId,
        senderId: agentInfo.userId,
        conversationId: conversationId,
        messageFrom: MESSAGE_FROM.AGENT,
        messageId: generateMessageId(),
        visitorId: visitorId,
        projectId: agentInfo.projectId,
        timestamp: getServerTime(),
      };
      if (conversation.chatStatus != CHAT_STATUS.Closed) {
        this.props.dispatch(
          ConnectionAction.sendMessage(acceptedMessage, {
            interactionId: this.state.interactionId,
          })
        );
        let statusTrackerInfo = {
          connectionId: this.state.interactionId,
          tool: [STATUS_TRACKER_TOOL.overflow],
          isConnected: true,
          loadedDate: getServerTime(),
        };
    
        this.props.dispatch(
          ConversationInfoAction.trackChatStatus(statusTrackerInfo)
        );
      }
      console.log("Accepted signal sent - " + conversationId);
    }
    
    // } else  {

    //     console.info( "Should be handling the agents UI if loads for both of them" );
    //     // let self = this;
    //     // setTimeout( () => {
    //     //     self.sendAcceptedMessage_beta();
    //     // } , 300000 )
    // }
  }

  sendAcceptedMessage_beta() {
    let conversationId = this.state.conversationId;
    let conversation = this.state.conversationMap[conversationId];
    if (conversation && conversation.overflowStatus == "OVERFLOWED") {
      let agentInfo = Object.assign({}, this.state.agentInfo);

      let visitorId = conversation.visitorId;
      let acceptedMessage = {
        channel: "server/routing",
        type: MESSAGE_TYPE.chat_auto_assign_on_overflow,
        message: "",
        actionTo: agentInfo.userId,
        senderId: agentInfo.userId,
        conversationId: conversationId,
        messageFrom: MESSAGE_FROM.AGENT,
        messageId: generateMessageId(),
        visitorId: visitorId,
        projectId: agentInfo.projectId,
        timestamp: getServerTime(),
      };
      this.props.dispatch(
        ConnectionAction.sendMessage(acceptedMessage, {
          interactionId: this.state.interactionId,
        })
      );
    }
  }

  showVisitorDetails() {
    Event(
      "LS-" + this.state.projectId,
      "Overflow - Third Column",
      "Details Expand Button",
      "Details Expand Button Clicked"
    );
    this.setState({ ...this.state, displayVisitorDetails: true });
  }
  hideVisitorDetails() {
    this.setState({ ...this.state, displayVisitorDetails: false });
  }
  render() {
    let conversationId = this.state.conversationId;
    let conversationMap = this.state.conversationMap;
    let interactionId = this.state.interactionId;
    let visitorId =
      conversationMap && conversationMap[conversationId]
        ? conversationMap[conversationId].visitorId
        : "";
    let overflowedAgent = this.state.agentInfo;
    let autoDisconnectOverflowChatTimer =
      this.state.autoDisconnectOverflowChatTimer;
    return (
      <OverflowComponent
        conversationId={conversationId}
        visitorId={visitorId}
        interactionId={interactionId}
        params={this.state.params}
        overflowedAgent={overflowedAgent}
        showVisitorDetails={this.showVisitorDetails}
        hideVisitorDetails={this.hideVisitorDetails}
        displayVisitorDetails={this.state.displayVisitorDetails}
        autoDisconnectOverflowChatTimer={autoDisconnectOverflowChatTimer}
      />
    );
  }
}
export default OverflowContainer;
