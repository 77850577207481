export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";

export const showPopup = (popup) => ({
  type: SHOW_POPUP,
  popupProps: { ...popup.popupProps, display: true },
});

export const hidePopup = (type) => ({
  type: HIDE_POPUP,
  popupProps: { display: false },
});
