import {
  UPLOAD_ATTACHMENT_REQUESTED,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENT_FAILED,
  UPLOAD_FILE_URL_REQUESTED,
  UPLOAD_WIDGET_ICON_REQUESTED,
  UPLOAD_WIDGET_ICON_RESPONSE,
  UPLOAD_WIDGET_ICON_FAILED,
} from "../actions/AttachmentAction.js";

const AttachmentReducer = (
  state = {
    isUploading: false,
    isUploaded: false,
    isFailed: false,
    error: "",
    messageData: {},
    isUploadingIcon: false,
    isUploadedIcon: false,
    isIconUploadFailed: false,
  },
  action
) => {
  switch (action.type) {
    case UPLOAD_WIDGET_ICON_REQUESTED:
      return {
        ...state,
        isUploadingIcon: true,
        isUploadedIcon: false,
      };
    case UPLOAD_WIDGET_ICON_RESPONSE:
      return {
        ...state,
        isUploadingIcon: false,
        isUploadedIcon: true,
      };
    case UPLOAD_WIDGET_ICON_FAILED:
      return {
        ...state,
        isUploadingIcon: false,
        isUploadedIcon: false,
      };
    case UPLOAD_FILE_URL_REQUESTED:
      return {
        ...state,
        isUploading: true,
        isUploaded: false,
        messageData: Object.assign(
          action.fileDetails.message,
          state.messageData
        ),
      };
    case UPLOAD_ATTACHMENT_REQUESTED:
      return {
        ...state,
        isUploading: true,
        isUploaded: false,
      };

    case UPLOAD_ATTACHMENT_SUCCESS:
      let isUploading = state.isUploading;
      delete state.messageData[action.fileResponse.id];
      if (Object.getOwnPropertyNames(state.messageData).length <= 0)
        isUploading = false;

      return {
        ...state,
        isUploading: isUploading,
        isUploaded: isUploading,
        messageData: state.messageData,
      };
    case UPLOAD_ATTACHMENT_FAILED:
      return {
        ...state,
        isUploading: false,
        isUploaded: false,
        error: "Error occured while Uploading the file to the Server!",
      };
    default:
      return state;
  }
};
export default AttachmentReducer;
