
import React, {  useState , useRef , useEffect } from "react";
import ReactTooltip from "react-tooltip";


import { ResolveIcon ,ClockIcon, TickIcon } from "../../commons/Icons";

const ResolveChatDropDown = ( props ) => {

  

    let {closeHandler , type } = props;

    let resolveChatBox = useRef("null");

    const [showResolveDropDown , setShowResolveDropDown] = useState(false);


    const handleClickOutside = (e) => {
      if (resolveChatBox.current && !resolveChatBox.current.contains(e.target)) {
        setShowResolveDropDown(false);
      }
  }

  const onEsc = () => {
    setShowResolveDropDown(false);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", onEsc);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", onEsc);
    };
  });

    const openResolveDropdown = () => {
        ReactTooltip.hide();
        setShowResolveDropDown(true);
    }

    const getResolveDropdownClass = () => {
        return showResolveDropDown ? "resolve-btn open" : "resolve-btn";
    }

    const handleCloseEvent = (event) => {
      setShowResolveDropDown(false);
      closeHandler(event);
    }

    // type === "pending_closed"

    const getPendingClosedClass = () => {
      if(type === "pending_closed") 
        return "disabled-pending"
      else 
        return ""
    }

    const getWrapperStyleForPending = () => {
        if(type === 'pending_closed')
          return {"cursor" : "not-allowed"};
    }

    const getDataTip = () => {
      return showResolveDropDown ? "" : "Close Conversation"
    }

    const getResolveIcon = () => {
      return (
              <div ref={resolveChatBox} class={getResolveDropdownClass()}>
              <TickIcon
                className="resolve-icon"
                onClick={openResolveDropdown}
                tabIndex="-3"
                data-tip={getDataTip()}
                data-delay-hide={0}
                data-class="tooltip info"
              />
            <div class="resolve-btn-dwn">
                <ul style={getWrapperStyleForPending()}>
                <li data-closetype="closed" onClick={handleCloseEvent}>
                      <ResolveIcon
                              data-closetype="closed"
                            className="resolve-icon"
                            tabIndex="-3"
                            data-delay-hide={0}
                      />
                        Close as resolved 
                    </li>
                    <li className={getPendingClosedClass()} data-closetype="pending" onClick={handleCloseEvent}>
                        <ClockIcon data-closetype="pending" className="pending-icon"/>
                        Close as pending
                    </li>
                    
                </ul>
            </div>
          </div>
            );
      }

    return getResolveIcon();
}


export default ResolveChatDropDown;