import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CampaignQuickPreview from "./CampaignQuickPreview";
import AnnouncementPopupPreview from "./AnnouncementPopupPreview.jsx";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";

import { Link } from "react-router";
import { isAnnoucementDesktopType } from "../../../app/commons/Utility.js";
import { useChatConfigurationEntity } from "../../../app/settings/hooks/useChatConfigurationEntity";
import * as Utility from "../../commons/Utility";
import { BlueTickIcon, ErrorIcon } from "../../commons/Icons";
import {
  sentTestMailsRequest,
  addSelectedCampaign,
  updatePreviewType,
  updateCampaignFromDashboard,
} from "../../actions/BroadcastMessageStatsAction";
import ReactTooltip from "react-tooltip";
import { browserHistory } from "react-router";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";

const CampaignPreview = (props) => {
  const { projectkey, campaignId } = props.params;
  const [showPreview, setShowPreview] = useState(false);
  const [previewType, setPreviewType] = useState("");
  const [showPopupPreview, setShowPopupPreview] = useState(false);
  const [emailMap, setEmailMap] = useState({
    [Utility.createUUId()]: { email: "" },
  });
  const dispatch = useDispatch();
  const { campaignDetails, staffDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    staffDetails: state.StaffReducer,
  }));
  const chatConfiguration = useChatConfigurationEntity(
    "LS-" + props.params.projectkey
  );
  const { campaignData, isCampaignFetched } = useBroadcastMessageStats(
    projectkey,
    campaignId
  );
  useEffect(() => {
    if (!previewType) {
      setPreviewType("Mobile");
      togglePreviewType("Mobile");
    }
  }, [previewType]);
  let color = chatConfiguration.chatConfiguration.widgetThemeColor;

  const miniNavBar = isAnnoucementDesktopType(campaignDetails);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(campaignDetails.selectedCampaign.isTestMailSending);
  }, [campaignDetails.selectedCampaign.isTestMailSending]);
  const togglePreviewType = (previewType) => {
    dispatch(updatePreviewType(previewType));
  };

  const getPreviewData = () => {
    return showPreview ? (
      <CampaignQuickPreview
        color={color}
        agentName={getSenderName()}
        agentImage={getSelectedAgentImage()}
        randomStaffData={randomStaffData}
        showFallbackValues={true}
      />
    ) : (
      ""
    );
  };

  const getPopupPreview = () => {
    return showPopupPreview ? (
      <AnnouncementPopupPreview
        params={props.params}
        color={color}
        setShowPopupPreview={setShowPopupPreview}
        showPopupPreview={showPopupPreview}
        agentName={getSenderName()}
        agentImage={getSelectedAgentImage()}
        randomStaffData={randomStaffData}
        showFallbackValues={true}
      />
    ) : (
      ""
    );
  };

  const showPreviewWidget = () => {
    setShowLoader(true);
    if (campaignDetails.campaignType == "CHAT") {
      setTimeout(() => {
        setShowLoader(false);
        setShowPreview(true);
      }, 1500);
    } else if (campaignDetails.campaignType == "ANNOUNCEMENT") {
      setTimeout(() => {
        setShowLoader(false);
        setShowPopupPreview(true);
      }, 1500);
    }
    let action = campaignDetails.campaignType.toLowerCase();
    Event( Utility.getProjectId(props.params.projectkey), "Campaigns", 
    "CampaignPreview"+action[0].toUpperCase()+action.substring(1, action.length), campaignDetails.campaignType.toLowerCase() )
  };

  const getRunContent = () => {
    return showLoader ? (
      <code id="loader" className="btn-spinner"></code>
    ) : (
      "Run"
    );
  };

  const getSelectedAgentName = () => {
    let { key, firstName = "" } = staffDetails.currentStaffStatus;
    let { senderId, campaignFromName } = campaignDetails.selectedCampaign;
    if (senderId) {
      if (key === senderId) return `${campaignFromName}`;
      else return campaignFromName || "";
    } else {
      if (firstName) {
        return `${firstName} (Me)`;
      }
    }
  };

  const getSelectedAgentImage = () => {
    let { key, photoUrl = "" } = staffDetails.currentStaffStatus;
    let { campaignFrom, campaignFromImage } = campaignDetails.selectedCampaign;
    if (campaignFrom) {
      if (key === campaignFrom) return `${campaignFromImage}`;
      else return campaignFromImage || "";
    } else {
      if (photoUrl) {
        return `${photoUrl}`;
      }
    }
  };

  const onCancel = () => {
    if (campaignDetails.isCampaignFromDashboard) {
      browserHistory.push({
        pathname: "/app/" + props.params.projectkey + "/contacts/dashboard/" + campaignDetails.selectedCampaign.segmentIds[0],
      });
    } else {
    browserHistory.push({
      pathname: "/app/" + props.params.projectkey + "/campaign",
    });
    }
    ReactTooltip.hide();
  };

  const getSenderName = () => {
    let name = getSelectedAgentName() || "";
    let properName = name.replace("(Me)", "");
    return properName;
  };

  // const randomStaffData = () => {
  //   let { campaignFrom } = campaignDetails.selectedCampaign;
  //   const projectId = staffDetails.activeProjectId;
  //   const staffData = staffDetails.dashboardAgents[projectId];
  //   for (const key in staffData) {
  //     let staff = staffData[key];
  //     if (key !== campaignFrom) return staff;
  //   }
  // };

  const randomStaffData = () => {
    let { campaignFrom } = campaignDetails.selectedCampaign;
    const projectId = staffDetails.activeProjectId;
    const staffData = staffDetails.dashboardAgents[projectId];
    let randomStaff = undefined;

    for (const key in staffData) {
      console.log(`${key}: ${staffData[key]}`);
      let staff = staffData[key];
      if (key !== campaignFrom && staff.status === "ACTIVE" && !randomStaff) {
        randomStaff = staff;
      }
    }
    return randomStaff;
  };
  const getEmailInput = () => {
    let toRender = [];
    let emailKeys = Object.keys(emailMap);
    for (let id of emailKeys) {
      let inputDivcssClasses = "";
      inputDivcssClasses += emailMap[id].isFocused ? " cs-active-field" : "";
      inputDivcssClasses += emailMap[id].errorMessage ? " cs-error-field" : "";
      toRender.push(
        <div className="email-camp-list">
          <div className={"cs-input-field" + inputDivcssClasses}>
            <label>Email</label>
            <input
              autocomplete="off"
              type="text"
              onChange={emailonChange}
              onFocus={emailOnFocus}
              onBlur={emailOnBlur}
              data-id={id}
              placeholder="Enter Email"
              value={emailMap[id].email}
            />
            {emailMap[id].errorMessage ? (
              <span>
                <ErrorIcon />
                {emailMap[id].errorMessage}
              </span>
            ) : (
              ""
            )}
          </div>
          <code
            style={emailKeys.length == 1 ? { display: "none" } : {}}
            data-id={id}
            onClick={removeEmail}
          >
            ×
          </code>
        </div>
      );
    }
    return toRender;
  };
  const removeEmail = (e) => {
    if (Object.keys(emailMap).length > 1) {
      let id = e.target.dataset.id;
      delete emailMap[id];
      setEmailMap({ ...emailMap });
    }
  };
  const emailonChange = (e) => {
    let id = e.target.dataset.id;
    let typedEmail = e.target.value;
    emailMap[id] = { ...emailMap[id], email: typedEmail, errorMessage: "" };
    setEmailMap({ ...emailMap });
  };
  const emailOnFocus = (e) => {
    let id = e.target.dataset.id;
    emailMap[id] = { ...emailMap[id], isFocused: true };
    setEmailMap({ ...emailMap });
  };

  const emailOnBlur = (e) => {
    let id = e.target.dataset.id;
    let emailIds = [];
    for (let key in emailMap) {
      if (key != id) {
        let emailObj = emailMap[key];
        emailIds.push(emailObj.email.toLowerCase());
      }
    }
    if (!Utility.isValidEmail(emailMap[id].email.toLowerCase())) {
      emailMap[id] = {
        ...emailMap[id],
        errorMessage: "invalid email",
        isFocused: false,
      };
    } else if (isDuplicateEmail(emailMap[id].email.toLowerCase(), emailIds))
      emailMap[id] = {
        ...emailMap[id],
        errorMessage: "duplicate email",
        isFocused: false,
      };
    else emailMap[id] = { ...emailMap[id], isFocused: false };
    setEmailMap({ ...emailMap });
  };
  const addEmail = () => {
    if (Object.keys(emailMap).length < 4) {
      let emailIds = [];
      let isValidEmails = true;
      for (let key in emailMap) {
        let emailObj = emailMap[key];
        let email = emailObj.email.toLowerCase();
        if (!Utility.isValidEmail(email)) {
          emailObj.errorMessage = "invalid email";
          isValidEmails = false;
        } else if (isDuplicateEmail(email, emailIds)) {
          emailObj.errorMessage = "duplicate email";
          isValidEmails = false;
        } else emailIds.push(emailObj.email.toLowerCase());
      }
      if (isValidEmails) {
        setEmailMap({ ...emailMap, [Utility.createUUId()]: { email: "" } });
      } else setEmailMap({ ...emailMap });
    }
  };

  const constructMessage = () => {
    let {
      fallBackValues,
      message,
      projectName,
    } = campaignDetails.selectedCampaign;
    fallBackValues = {
      ...fallBackValues,
      "{{firstName}}": "Jane",
      "{{lastName}}": "Doe",
      "{{fullName}}": "Jane Doe",
      "{{companyName}}": projectName,
    };
    let fallbackStripedMap = {
      "{{firstName}}": "firstName",
      "{{lastName}}": "lastName",
      "{{fullName}}": "fullName",
      "{{companyName}}": "companyName",
    };

    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `{{ ${fallbackStripedMap[match]} | ${fallBackValues[match]} }}`;
      }
    );

    message = message.replace(
      "+_from_",
      `<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;">From</span>${campaignDetails.selectedCampaign.campaignFromName}</p>`
    );
    return message;
  };
  const resetValues = () => {
    setShowPreview(false);
    setShowPopupPreview(false);
    setEmailMap({ [Utility.createUUId()]: { email: "" } });
    campaignDetails.selectedCampaign.isTestMailSent = false;
    dispatch(addSelectedCampaign(campaignDetails.selectedCampaign));
  };
  const sendEmails = (emailIds) => {
    let { senderId, subject, key} = campaignDetails.selectedCampaign;
    let testmailPayload = {
      message: constructMessage(),
      senderId,
      subject,
      emailIds,
      campaignId: key
    };
    
    Event(
      Utility.getProjectId(props.params.projectkey),
    "Campaigns",
    "CampaignPreviewEmail",
    "email"
    )

    if(emailIds.length >1)
    {
      Event(
        Utility.getProjectId(props.params.projectkey),
      "Campaigns",
      "EmailPreviewMultipleUsers",
      emailIds.length+""
      )
    }
    dispatch(
      sentTestMailsRequest(testmailPayload, Utility.getProjectId(projectkey))
    );
  };
  const sendPreviewEmails = () => {
    let emailIds = [];
    let isValidEmails = true;
    for (let key in emailMap) {
      let emailObj = emailMap[key];
      let email = emailObj.email.toLowerCase();
      if (!Utility.isValidEmail(email)) {
        emailObj.errorMessage = "invalid email";
        isValidEmails = false;
      } else if (isDuplicateEmail(email, emailIds)) {
        emailObj.errorMessage = "duplicate email";
        isValidEmails = false;
      } else emailIds.push(emailObj.email.toLowerCase());
    }
    if (isValidEmails) {
      //send test here
      console.log("valid emails: " + emailIds);
      sendEmails(emailIds);
    } else setEmailMap({ ...emailMap });
  };
  const isDuplicateEmail = (email, emailIds) => {
    if (emailIds.includes(email)) return true;
    return false;
  };
  const updateNotSend = () => {
    campaignDetails.selectedCampaign.isTestMailSent = false;
    dispatch(addSelectedCampaign(campaignDetails.selectedCampaign));
  };
  const getPreviewCampaignOptions = () => {
    let toRender = [];
    let { typeOfMessage } = campaignDetails.selectedCampaign;

    if (typeOfMessage === "EMAIL") {
      toRender.push(<p>Test run your email campaign with an internal group</p>);
      toRender.push(
        <div className="cs-widget-config cs-btn-fixed">
          <div>
            <section
              style={
                campaignDetails.selectedCampaign.isTestMailSent
                  ? { display: "none" }
                  : {}
              }
            >
              {getEmailInput()}
              <a
                href="javascript:void(0)"
                onClick={addEmail}
                className="cs-link-add"
              >
                + Add more people
              </a>
              <a
                className="cs-btn cs-secondary-btn cs-email-send"
                onClick={sendPreviewEmails}
              >
                {showLoader ? <code className="btn-spinner "></code> : "Send"}
              </a>{" "}
            </section>
            <BlueTickIcon
              className="tick-circle-icon"
              style={
                campaignDetails.selectedCampaign.isTestMailSent
                  ? {}
                  : { display: "none" }
              }
            />
            <label
              style={
                campaignDetails.selectedCampaign.isTestMailSent
                  ? {}
                  : { display: "none" }
              }
            >
              Test Emails sent successfully!
            </label>
            <p
              style={
                campaignDetails.selectedCampaign.isTestMailSent
                  ? {}
                  : { display: "none" }
              }
            >
              Check your inbox for the test email.
            </p>
            <div
              style={
                campaignDetails.selectedCampaign.isTestMailSent
                  ? {}
                  : { display: "none" }
              }
              className="cs-input-button cs-email-sendagain"
            >
              <a
                id="resend_button"
                className="cs-btn cs-secondary-btn"
                onClick={updateNotSend}
              >
                Resend
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      toRender.push(
        <p>Find out how your campaign will appear to the recipients</p>
      );
      toRender.push(
        <div class="cs-widget-config">
          <div>
            <label>Run Test</label>
            <p>
              Send a test message to yourself to find out how the campaign
              looks.
            </p>
            <div className="cs-input-button cs-input-run-btn">
              <a
                id="run_button"
                onClick={showPreviewWidget}
                className="cs-btn cs-secondary-btn"
              >
                {getRunContent()}
              </a>
            </div>
          </div>
        </div>
      );
    }
    return toRender;
  };
  return (
    <div className={"cs-settings-wrp " + (miniNavBar ? "slide-col3" : "")}>
      <section className="widget-configuration">
        <h3>Preview Campaign</h3>
        {getPreviewCampaignOptions()}
        <div className="campaign-btn-wrp cs-btn-bottom">
          <Link className="cs-btn cs-decline-btn" onClick={onCancel}>
            {" "}
            Cancel{" "}
          </Link>
          <Link
            className="cs-btn cs-primary-btn"
            to={
              props.params.campaignId
                ? "/app/" +
                  props.params.projectkey +
                  "/campaign/publish/" +
                  props.params.campaignId
                : "/app/" + props.params.projectkey + "/campaign/publish"
            }
            onClick={resetValues}
          >
            {" "}
            Next{" "}
          </Link>
        </div>
      </section>
      {getPreviewData()}
      {getPopupPreview()}
    </div>
  );
};

export default CampaignPreview;
