export const ACCEPT_CHAT = "ACCEPT_CHAT";
export const ENQUEUE_CHAT = "ENQUEUE_CHAT";
export const DEQUEUE_CHAT = "DEQUEUE_CHAT";
export const FLUSH_QUEUE = "FLUSH_QUEUE";

export const acceptChat = (answeringObject) => ({
  type: ACCEPT_CHAT,
  answeringObject,
});

export const enqueueChat = (incomingChatInfo) => ({
  type: ENQUEUE_CHAT,
  incomingChatInfo,
});

export const dequeueChat = (conversationId) => ({
  type: DEQUEUE_CHAT,
  conversationId,
});

export const flushQueue = (visitorId) => ({
  type: FLUSH_QUEUE,
  visitorId,
});
