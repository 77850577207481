import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Voicebox from "../components/common/Voicebox.jsx";
import * as VoiceboxActions from "../actions/VoiceboxAction";

@connect((state) => ({ voiceboxInfo: state.VoiceboxReducer }))
class VoiceboxContainer extends Component {
  // static propTypes = {

  // 	voiceboxInfo : PropTypes.object.isRequired
  // }

  constructor() {
    super();

    this.dismissVoicebox = this.dismissVoicebox.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.voiceboxInfo.dismissAfter) {
      setTimeout(() => {
        this.dismissVoicebox();
      }, newProps.voiceboxInfo.dismissAfter);
    }
  }

  dismissVoicebox(voiceboxId) {
    this.props.dispatch(VoiceboxActions.hideVoicebox());
  }

  render() {
    return (
      <Voicebox
        ref={(component) => (this.child = component)}
        voicebox={this.props.voiceboxInfo}
        dismissVoicebox={this.dismissVoicebox}
      />
    );
  }
}

export default VoiceboxContainer;
