export const IS_URL_LOADABLE_REQUEST = "IS_URL_LOADABLE_REQUEST";
export const IS_URL_LOADABLE_RESPONSE = "IS_URL_LOADABLE_RESPONSE";
export const RESET_PREVIEW_REDUCER = "RESET_PREVIEW_REDUCER";

export const isUrlLoadableRequest = (previewUrl) => ({
  type: IS_URL_LOADABLE_REQUEST,
  previewUrl,
});

export const isUrlLoadableResponse = (previewUrl, isUrlLoadable) => ({
  type: IS_URL_LOADABLE_RESPONSE,
  isUrlLoadable,
  previewUrl,
});

export const resetPreviewReducer = () => ({
  type: RESET_PREVIEW_REDUCER,
});
