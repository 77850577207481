import {
  USER_PROJECT_REQUESTED,
  USER_PROJECT_RECEIVED,
  USER_PROJECT_REQUEST_FAILED,
  UPDATE_USER_RESPONSE,
  UPDATE_CONNECT_CONFIG_RESPONSE,
  UPDATE_USER_PROJECT_REQUESTED,
  CONNECT_ENABLED_PROJECTS_REQUESTED,
  CONNECT_ENABLED_PROJECTS_RESPONSE,
} from "../Action/UserProjectMappingAction";

const UserProjectMappingReducer = (
  state = {
    userProjectMapping: {},
    accountProjectMapping: {},
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    projectMap: {},
    chatConfigMap: {},
    isConnectProjectsFetching: false,
    isConnectProjectsFetched: false,
    connectEnabledProjects: {},
  },
  action
) => {
  switch (action.type) {
    case USER_PROJECT_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };

    case USER_PROJECT_RECEIVED: {
      let userProjectMapping = state.userProjectMapping;
      let projectMap = state.projectMap;
      let chatConfigMap = state.chatConfigMap;

      if (action.response.userProjectMapping.length > 0) {
        userProjectMapping[action.response.userProjectMapping[0].userKey] =
          action.response.userProjectMapping;
        projectMap[
          action.response.userProjectMapping[0].userKey
        ] = Object.assign({}, action.response.projectMap);
        chatConfigMap[
          action.response.userProjectMapping[0].userKey
        ] = Object.assign({}, action.response.chatConfigMap);
      } else {
        userProjectMapping[action.response.staffId] = {};
      }

      return {
        ...state,
        isFetching: false,
        isFetched: true,
        userProjectMapping: userProjectMapping,
        projectMap: projectMap,
        chatConfigMap: chatConfigMap,
      };
    }
    case USER_PROJECT_REQUEST_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };

    case UPDATE_USER_PROJECT_REQUESTED: {
      return {
        ...state,
        isUpdating: true,
      };
    }

    case UPDATE_USER_RESPONSE: {
      let userProjectMapping = state.userProjectMapping;
      if (action.user.userProjectMapping.length > 0) {
        userProjectMapping[action.user.userProjectMapping[0].userKey] =
          action.user.userProjectMapping;
      }
      return {
        ...state,
        isUpdating: false,
        userProjectMapping: userProjectMapping,
      };
    }
      
    case UPDATE_CONNECT_CONFIG_RESPONSE: {
      let userProjectMapping = state.userProjectMapping;
      let connectIntegrationStatus = action.connectIntegrationStatus;
      let userMappingEntity = userProjectMapping[connectIntegrationStatus.userId];
      for (let projectId in connectIntegrationStatus) {
        if (projectId != "userId") {
          userMappingEntity.forEach(projectDetails => {
            if (projectDetails.projectKey === projectId)
              projectDetails.isConnectIntegrationEnabled = (connectIntegrationStatus[projectId] === "true");
          });
        }
      }
      userProjectMapping[connectIntegrationStatus.userId] = userMappingEntity;
      userProjectMapping = Object.assign({}, userProjectMapping);
      return {
        ...state,
        userProjectMapping: userProjectMapping
      }
    }
      
    case CONNECT_ENABLED_PROJECTS_REQUESTED: {
      return {
        ...state,
        isConnectProjectsFetching: true,
        isConnectProjectsFetched: false
      }
    }
      
    case CONNECT_ENABLED_PROJECTS_RESPONSE: {
      let connectProjects = action.connectEnabledProjects ? action.connectEnabledProjects : {};
      return {
        ...state,
        connectEnabledProjects: connectProjects,
        isConnectProjectsFetching: false,
        isConnectProjectsFetched: true,
      }
    }
      
    default:
      return state;
  }
};
export default UserProjectMappingReducer;
