import React,{ Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { EMAIL_OPT_OUT_DESCRIPTION , EMAIL_OPT_OUT_DISPLAY_NAMES } from '../commons/Constants.js'
import { getDecrypted , getProjectId} from '../commons/Utility'
import { requestEmailOptOutPreferences , requestUpdateEmailOptOut } from '../actions/EmailOptOutAction'
import UnsubscribeEmail from '../components/emailoptout/UnsubscribeEmail.jsx'

@connect( ( state ) => ( { emailOptOut : state.EmailOptOutReducer } ) )
class UnsubscribeEmailContainer extends Component
{
	constructor()
	{
		super();
		this.state = { emailOptOutPreferences : {
							unsubscribedList : new Set() ,
							unsubscribeFromAll : false ,
							projectId : "" , 
							key : ""
						},
						emailId : "",
						unsubcribeId : "",
						unsubcribeIdDescription : "",
						unsubcribeIdDisplayName : "", 
						hasFetchedPreferences : false
					 };

		this.routeToPreferencePage = this.routeToPreferencePage.bind(this);
		this.routeToOptedOutAllPage = this.routeToOptedOutAllPage.bind(this);
		this.hideChatBoxWhenLoaded = this.hideChatBoxWhenLoaded.bind(this);
	}

	componentWillMount() 
	{
		let { params : { projectId , visitorInfo : base64VisitorInfo } } = this.props;
		let { emailOptOut : { isFetched : preferencesAlreadyFetched , emailOptOutPreferences }  } = this.props;

		projectId = getProjectId( projectId );
		let { visitorId , emailId , unsubcribeId }  = JSON.parse ( getDecrypted( base64VisitorInfo ) );
		
		if( ! preferencesAlreadyFetched ) {
			this.setState( { emailOptOutPreferences : { ...this.state.emailOptOutPreferences , key : visitorId , projectId  } ,
				emailId ,
				unsubcribeId , 
				unsubcribeIdDescription : EMAIL_OPT_OUT_DESCRIPTION[ unsubcribeId ]  ,
				unsubcribeIdDisplayName : EMAIL_OPT_OUT_DISPLAY_NAMES[ unsubcribeId ] }
			);

			this.props.dispatch( requestEmailOptOutPreferences( visitorId ) );
		} else  {
			if( emailOptOutPreferences.unsubscribeFromAll )
				this.routeToOptedOutAllPage();

			this.setState( { emailOptOutPreferences , emailId , hasFetchedPreferences : true });
		}				 
		
	}

	componentDidMount() {
		window.onload =  () => {
			this.hideChatBoxWhenLoaded();
		};
	}

	hideChatBoxWhenLoaded() {
		var chatbox = document.getElementById('ls-chat');

		if (chatbox) 
			chatbox.style.display = "none";
		else 
			setTimeout( this.hideChatBoxWhenLoaded , 100);
	}

	componentWillReceiveProps( nextProps )
	{
		let { isFetching : wasFetchingPreferences , isUpdating : wasUpdatingPreferences , notFound : wasNotFound }  = this.props.emailOptOut ;
		let { isFetched : hasFetchedPreferences , isUpdated : hasUpdatedPreferences , notFound : isNotFound  } = nextProps.emailOptOut;
		let { emailOptOutPreferences } = nextProps.emailOptOut;

		if( ! wasNotFound && isNotFound ) {
			this.setState( { hasFetchedPreferences : true } );
			let { key , projectId } = this.state.emailOptOutPreferences;
			let { unsubcribeId } = this.state;
			let { unsubscribedList } = emailOptOutPreferences;

			unsubscribedList.add( unsubcribeId );
			emailOptOutPreferences.unsubscribedList = unsubscribedList;
			emailOptOutPreferences.unsubscribeFromAll = false;
			emailOptOutPreferences.key = key;
			emailOptOutPreferences.projectId = projectId;

			this.props.dispatch( requestUpdateEmailOptOut ( emailOptOutPreferences , "unsubscribe" ) );
		}
	
		if( wasFetchingPreferences && hasFetchedPreferences ) {
			if( emailOptOutPreferences.unsubscribeFromAll ) {
				this.routeToOptedOutAllPage();
				return;
			}

			this.setState( { emailOptOutPreferences , hasFetchedPreferences : true } );
			let { unsubcribeId } = this.state;
			let { unsubscribedList } = emailOptOutPreferences;

			unsubscribedList.add( unsubcribeId );
			emailOptOutPreferences.unsubscribedList = unsubscribedList;

			this.props.dispatch( requestUpdateEmailOptOut ( emailOptOutPreferences , "unsubscribe" ) );
		}

		if( wasUpdatingPreferences && hasUpdatedPreferences ) {
			if( emailOptOutPreferences.unsubscribeFromAll ) {
				this.routeToOptedOutAllPage();
				return;
			}

			this.setState( { emailOptOutPreferences , hasFetchedPreferences : true } );
		}
	}

	routeToPreferencePage() {
		let { visitorInfo , projectId  } = this.props.params;
		browserHistory.push( { pathname : "/email/optout/" + projectId + "/" + visitorInfo } )
	}

	routeToOptedOutAllPage() {
		let { visitorInfo , projectId } = this.props.params;
		browserHistory.push( { pathname : "/email/optedOutAll/" + projectId + "/" + visitorInfo } )
	}
		
	render()
	{
		return( 
			<UnsubscribeEmail ref={ component => this.child = component } routeToPreferencePage = { this.routeToPreferencePage } localState={ this.state }  />
		)
	}
}

export default UnsubscribeEmailContainer;