import {
  GET_URL_PREVIEW_INFO_REQUEST,
  GET_URL_PREVIEW_INFO_RESPONSE,
  GET_URL_PREVIEW_INFO_FAILED,
  UPDATE_URL_PREVIEW_DIMENSIONS,
  REQUEST_URL_PREVIEW_DIMENSIONS,
} from "../actions/UrlPreviewAction.js";

const UrlPreviewReducer = (state = { previewsMap: {} }, action) => {
  switch (action.type) {
    case GET_URL_PREVIEW_INFO_REQUEST:
      let fetchingUrls = {};
      action.urls.map((url) => {
        fetchingUrls[url] = {};
      });
      return {
        ...state,
        previewsMap: {
          ...state.previewsMap,
          ...fetchingUrls,
        },
      };
    case GET_URL_PREVIEW_INFO_RESPONSE:
      return {
        ...state,
        previewsMap: {
          ...state.previewsMap,
          ...action.urlPreviews,
        },
      };

    case REQUEST_URL_PREVIEW_DIMENSIONS:
      let url = action.urlInfo.url;
      let urlPreview = state.previewsMap[url];
      urlPreview["isFetchingDimension"] = true;

      return {
        ...state,
        previewsMap: {
          ...state.previewsMap,
          url: urlPreview,
        },
      };

    case UPDATE_URL_PREVIEW_DIMENSIONS:
      let previewToUpdate = {};
      let requestUrl = action.previewDimensions.requestUrl;

      previewToUpdate = state.previewsMap[requestUrl];
      previewToUpdate["dimensions"] = Object.assign(
        {},
        action.previewDimensions.dimensions
      );
      previewToUpdate["fetchedDimensions"] =
        action.previewDimensions.fetchedDimensions;
      previewToUpdate["fetchFailed"] = action.previewDimensions.fetchFailed;
      previewToUpdate["isFetchingDimension"] = false;

      return {
        ...state,
        previewsMap: {
          ...state.previewsMap,
          requestUrl: previewToUpdate,
        },
      };
    case GET_URL_PREVIEW_INFO_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};
export default UrlPreviewReducer;
