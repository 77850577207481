/**
 * Created by arun on 25/05/17.
 */
import React, { Component } from "react";
import ReactDOM from "react-dom";

class DropDown extends Component {
  render() {
    console.info("onselect : ", this.props);

    let valuesMap = this.props.valuesMap; // it should be key-value map
    let list = [];

    let classNames = this.props.classNames ? this.props.classNames : [];
    let classNamesStr = " ";
    for (let i = 0; i < classNames.length; i++)
      classNamesStr += classNames[i] + " ";

    for (let key in valuesMap) {
      list.push(
        <li onMouseDown={this.props.onStaffSelect} key={key}>
          <a href="javascript:void(0)" value={key}>
            {valuesMap[key]}
          </a>
        </li>
      );
    }
    console.info("showDropdown " + this.props.showDropDown);
    return (
      <div
        className={
          "emailListWrapper " +
          classNamesStr +
          (this.props.showDropDown && list.length > 0 ? "db" : "dn")
        }
      >
        <ul
          className={
            "emailList " +
            (this.props.showDropDown && list.length > 0 ? "db" : "dn")
          }
          style={{ width: this.props.width ? this.props.width : "100%" }}
        >
          {list}
        </ul>
      </div>
    );
  }
}
export default DropDown;
