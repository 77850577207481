import { initialState } from 'src/features/returningFlow/store/reducer'
import { IStep, StepViewType } from 'src/features/returningFlow/store/types'
import { updateAtIndex } from 'src/utils/state/operators'

export const NEXT_STEP = 'returning/next-step' as const

export const nextStep = () => ({
  type: NEXT_STEP,
})

export const SKIP_STEP = 'returning/skip-step' as const

export const skipStep = () => ({
  type: SKIP_STEP,
})

export const SHOW_STEP = 'returning/show-step' as const

export const showStep = () => ({
  type: SHOW_STEP,
})

export const SET_CREATED_AT = 'returning/set-created-at' as const
export const setCreatedAt = (createdAt: number) => ({
  type: SET_CREATED_AT,
  payload: {
    createdAt,
  },
})

export const INITIALIZE = 'returning/initialize' as const
export const initializeReturningFlow = (
  createdAt: number,
  isInstalled: boolean
) => {
  if (isInstalled) {
    const INSTALL_STEP_INDEX = 1
    const WIDGET_SETUP_INDEX = 2

    return {
      type: INITIALIZE,
      payload: {
        createdAt,
        steps: updateAtIndex<IStep>(INSTALL_STEP_INDEX, {
          wasSkipped: false,
          viewType: StepViewType.confirmation,
        })(initialState.steps),
        currentStepIndex: WIDGET_SETUP_INDEX,
      },
    }
  }

  return {
    type: INITIALIZE,
    payload: {
      createdAt,
      steps: initialState.steps,
      currentStepIndex: initialState.currentStepIndex,
    },
  }
}

export type IReturningFlowActions = ReturnType<
  | typeof nextStep
  | typeof skipStep
  | typeof showStep
  | typeof setCreatedAt
  | typeof initializeReturningFlow
>
