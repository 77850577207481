import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/LoginAction";
import { destroyRtmServer } from "../../actions/MessageAction";
import { LogOutIcon } from "../../commons/Icons";

const MoreOptions = () => {
  const dispatch = useDispatch();
  const { loginReducer } = useSelector((state) => ({
    loginReducer: state.LoginReducer,
  }));
  const handleLogout = (e) => {
    if (e) e.preventDefault();
    if (!loginReducer.isLoggedIn) return;
    dispatch(destroyRtmServer());
    dispatch(logout());
  };
  return (
    <ul class="cs-nav-list">
      <li onClick={handleLogout} onKeyDown={ () => {} }>
        <a>Logout</a>
        <figure>
          <LogOutIcon />
        </figure>
      </li>
    </ul>
  );
};
export default MoreOptions;
