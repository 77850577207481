import {
  GET_STAFF_BY_PROJECT,
  SET_STAFF_BY_PROJECT,
  FAILED_STAFF_BY_PROJECT,
  GET_PROJECT_LIST_BY_EMAIL,
  PROJECT_LIST_BY_EMAIL_RESPONSE,
  PROJECT_LIST_BY_EMAIL_FAILED,
  ADD_STYLE_TO_ADMIN_CONTAINER,
  REMOVE_STYLE_FROM_ADMIN_CONTAINER,
} from "../actions/AdminAction";

const AdminReducer = (
  state = {
    staffList: [],
    projectList: [],
    errorMessage: "",
    styleClasses: [],
  },
  action
) => {
  switch (action.type) {
    case GET_STAFF_BY_PROJECT:
      return {
        ...state,
        staffList: [],
        projectList: [],
        isFetching: true,
        searchedType: "PROJECT_ID",
        searchedValue: action.projectId,
        errorMessage: "",
      };
    case SET_STAFF_BY_PROJECT:
      let staffList = action.staffList || [];
      staffList.filter((staff) => staff.status == "ACTIVE");
      return {
        ...state,
        staffList: Object.assign([], staffList),
        isFetching: false,
      };
    case FAILED_STAFF_BY_PROJECT:
      return {
        ...state,
        staffList: [],
        isFetching: false,
        errorMessage: action.errorMessage,
      };
    case GET_PROJECT_LIST_BY_EMAIL:
      return {
        ...state,
        staffList: [],
        projectList: [],
        isFetching: true,
        searchedType: "EMAIL_ID",
        searchedValue: action.staffEmail,
        errorMessage: "",
      };
    case PROJECT_LIST_BY_EMAIL_RESPONSE:
      return {
        ...state,
        projectList: Object.assign([], action.projectList),
        isFetching: false,
      };
    case PROJECT_LIST_BY_EMAIL_FAILED:
      return {
        ...state,
        projectList: [],
        isFetching: false,
        errorMessage: action.errorMessage,
      };
    case ADD_STYLE_TO_ADMIN_CONTAINER: {
      return {
        ...state,
        styleClasses: [
          ...new Set([...state.styleClasses, ...action.styleClasses.split(" ")]),
        ],
      };
    }
    case REMOVE_STYLE_FROM_ADMIN_CONTAINER: {
      return {
        ...state,
        styleClasses: state.styleClasses.filter((element) => {
          return !action.styleClasses.includes(element);
        }),
      };
    }
    default:
      return state;
  }
};
export default AdminReducer;
