import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import {
  SearchIcon,
  PublishIcon,
  PublishActiveIcon,
  ChatCampaignIcon,
  ChatCampaignActiveIcon,
  AnnouncementsIcon,
  AnnouncementsActiveIcon,
  EmailCampaignIcon,
  EmailCampaignActiveIcon,
} from "../../commons/Icons";
import { useDispatch } from "react-redux";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import { browserHistory } from "react-router";
import * as loaders from "../../settings/commons/loaders";
import * as BroadcastMessageStatsAction from "../../actions/BroadcastMessageStatsAction";

const CampaignSideBar = (props) => {
  const { projectkey, type } = props.params;
  const { campaignData, isCampaignFetched } = useBroadcastMessageStats(
    projectkey
  );
  const dispatch = useDispatch();

  const onSelect = (selectedType) => {
    if (type != selectedType)
      browserHistory.push({
        pathname: `/app/${projectkey}/campaign/${selectedType}`,
      });
    dispatch(BroadcastMessageStatsAction.updateCampaignSideBarType(selectedType));
  };

  const getCount = (campaginType) => {
    let compaignCount = "";
    let campaigns = campaignData || [];
    if (isCampaignFetched) {
      compaignCount =
        campaginType == "all"
          ? campaigns.filter((campaign) => {
              return campaign.status === "ACTIVE";
            }).length
          : campaigns.filter((campaign) => {
              return (
                campaign.typeOfMessage == campaginType &&
                campaign.status === "ACTIVE"
              );
            }).length;
    }
    return compaignCount;
  };

  const getLoader = () => {
    let groupLoaders = [];
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    return groupLoaders;
  };

  if (isCampaignFetched) {
    return (
      <section className="cs-my-conversations people-search">
        <header>
          <h2>Campaigns</h2>
        </header>
        <ul className="cs-accordian-menu cs-campaigns-menu">
          <li
            onClick={() => {
              onSelect("all");
            }}
            className={type == "all" ? "active" : ""}
          >
            <div>
              <figure>
                <PublishIcon />
                <PublishActiveIcon />
              </figure>
              <a href="javascript:void(0)">All</a>
              <span>{getCount("all")}</span>
            </div>
          </li>
          <li
            onClick={() => {
              onSelect("chat");
            }}
            className={type == "chat" ? "active" : ""}
          >
            <div>
              <figure>
                <ChatCampaignIcon />
                <ChatCampaignActiveIcon />
              </figure>
              <a href="javascript:void(0)">Chats</a>
              <span>{getCount("CHAT")}</span>
            </div>
          </li>
          <li
            onClick={() => {
              onSelect("announcement");
            }}
            className={type == "announcement" ? "active" : ""}
          >
            <div>
              <figure>
                <AnnouncementsIcon />
                <AnnouncementsActiveIcon />
              </figure>
              <a href="javascript:void(0)">Announcements</a>
              <span id="announc">{getCount("ANNOUNCEMENT")}</span>
            </div>
          </li>
          <li
            onClick={() => {
              onSelect("email");
            }}
            className={type == "email" ? "active" : ""}
          >
            <div>
              <figure>
                <EmailCampaignIcon />
                <EmailCampaignActiveIcon />
              </figure>
              <a href="javascript:void(0)">Emails</a>
              <span>{getCount("EMAIL")}</span>
            </div>
          </li>
        </ul>
      </section>
    );
  }

  return (
    <section className="cs-my-conversations people-search">
      <header>
        <h2>Campaigns</h2>
      </header>
      <ul className="cs-accordian-menu cs-campaigns-menu">{getLoader()}</ul>
    </section>
  );
};

export default CampaignSideBar;
