import React, { useState } from "react";
import ContactsFilter from "./ContactsFilter.jsx";
import ContactsGrid from "./ContactsGrid.js";

const ContactsHolder = (props) => {
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [headerHeight, setHeaderHeight] = useState("89");

  return (
    <section
      className={
        "cs-conversation cs-people-wrp " + (showFilterSection ? "open" : "")
      }
    >
      <ContactsFilter
        {...props}
        setShowFilterSection={setShowFilterSection}
        setHeaderHeight={setHeaderHeight}
        showFilterSection={showFilterSection}
      />
      <ContactsGrid {...props} headerHeight={headerHeight} />
    </section>
  );
};

export default ContactsHolder;
