import React, { Component } from "react";
import ReactDom from "react-dom";
import { connect } from "react-redux";
import { isAuthenticated } from "../commons/Authenticator";
import { browserHistory } from "react-router";
import * as utility from "../commons/Utility";
import { switchUserProfileToSpecificPage } from "../actions/UserAction";

@connect((state) => ({ userReducer: state.UserReducer }))
class SwitchProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  switchProjectRequested() {
    let data = utility.getQueryParam("data");

    if (typeof data == "string" && data.length > 0) {
      data = utility.getDecrypted(data);
      data = JSON.parse(data);

      let login = data.email;
      let project = data.project;
      let type = data.type;

      if (utility.isValidEmail(login) && project) {
        this.props.dispatch(
          switchUserProfileToSpecificPage(
            login,
            project,
            "/settings/widget/configuration"
          )
        );
      }
    }
  }
  render() {
    this.switchProjectRequested();
    return (
      <section className="manage_profile">
        <div className="loading-bg"></div>
        <aside className="loading-data">
          <img src={utility.getIconUrl(document.location.origin)}/>
          <p>Loading Your Data...</p>
        </aside>
      </section>
    );
  }
}

export default SwitchProfileContainer;
