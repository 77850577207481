import { Formik } from 'formik'
import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { Button } from 'src/features/form/components/Button'
import { TextInput } from 'src/features/form/components/TextInput'
import { Icon } from 'src/features/ui/components/Icon'
import {
  IFormValues,
  initialValues,
  validationSchema,
} from 'src/features/wizard/install/forms/EmailInstructions/data'
import {
  StyledForm,
  InputContainer,
  ButtonContainer,
} from 'src/features/wizard/install/forms/EmailInstructions/styled'
import { SyncInstructions } from 'src/features/wizard/install/forms/EmailInstructions/SyncInstructions'

export interface IProps {
  id: number
  handleSubmit: (values: IFormValues) => void
  isLoading: boolean
  hasFinished: boolean
}

export const EmailInstructions: FC<IProps> = ({
  handleSubmit,
  isLoading,
  hasFinished,
  id,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    validationSchema={validationSchema}
  >
    <StyledForm>
      <InputContainer>
        <TextInput
          label="Email"
          placeholder="Enter email address"
          name="email"
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          isLoading={isLoading}
          hasFullWidth
          color="primary"
          colorType={hasFinished ? 'full' : 'hollow'}
          type="submit"
          disabled={hasFinished}
        >
          {hasFinished ? (
            <>
              <Icon fill="white" type="checkMark" width={18} height={18} />
              <Margin left={8} />
              Sent
            </>
          ) : (
            'Send'
          )}
        </Button>
      </ButtonContainer>
      <SyncInstructions id={id} />
    </StyledForm>
  </Formik>
)
