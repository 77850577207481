import React, { Component } from "react";
import ReactDOM from "react-dom";

class ConversationListLoader extends Component {
  render() {
    const loader = [];
    for (let i = 0; i < this.props.count; i++) {
      loader.push(
        <li key={i}>
          <figure style={{ backgroundColor: "#f4f4f4" }}>
            <em
              class="progress"
              style={{ width: "100%", height: "100%", borderRadius: "100%" }}
            ></em>
          </figure>
          <label>
            <em class="progress"></em>
          </label>
          <p>
            <code></code>
            <em class="progress"></em>
          </p>
          <span>
            <em class="progress"></em>
          </span>
        </li>
      );
    }
    return (
      <ul ref="conversationList" className={this.props.conversationClass}>
        {loader}
      </ul>
    );
  }
}

export default ConversationListLoader;
