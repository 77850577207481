import * as R from 'ramda'
import styled from 'styled-components'

export const PluginStoreLink = styled.a`
  text-decoration: none;
  display: block;
`

export const PluginStoreButton = styled.button`
  font-family: ${R.path(['theme', 'font', 'family'])};
  background: ${R.path(['theme', 'color', 'codGray'])};
  border-radius: 0.3rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  color: ${R.path(['theme', 'color', 'white'])};
  display: block;
  padding: 1.2rem 2.1rem;
  margin: 2.4rem 0;
  border: none;
`
