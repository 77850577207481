import React, { Component } from "react";

class ConversationListOpenChatsPageLoader extends Component {
  render() {
    return (
      <ul ref="conversationList" className={this.props.conversationClass}>
        <li>
          <figure style={{ backgroundColor: "#f4f4f4" }}>
            <em
              class="progress"
              style={{ width: "100%", height: "100%", borderRadius: "100%" }}
            ></em>
          </figure>
          <label>
            <em class="progress"></em>
          </label>
          <p>
            <em class="progress"></em>
          </p>
        </li>
        <li>
          <figure style={{ backgroundColor: "#f4f4f4" }}>
            <em
              class="progress"
              style={{ width: "100%", height: "100%", borderRadius: "100%" }}
            ></em>
          </figure>
          <label>
            <em class="progress"></em>
          </label>
          <p>
            <em class="progress"></em>
          </p>
        </li>
      </ul>
    );
  }
}

export default ConversationListOpenChatsPageLoader;