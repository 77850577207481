import React, { useState, useEffect } from "react";
import { NewCampaignIcon } from "../../../app/commons/Icons";
import { Link, browserHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  campaignTypes,
  defaultCampaignData,
  CAMPAIGN_TYPE_PLACEHOLDERS_IMAGES,
} from "../../commons/Constants";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import {
  getProjectId,
  getProjectNameByKey,
  constructNewCampaign
} from "../../../app/commons/Utility";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";

const NewCompaignPopup = (props) => {
  const { closePopup } = props;
  const { type, projectkey, groupId } = props.params;
  const { campaignDetails, staffDetails, projects } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    staffDetails: state.StaffReducer,
    projects: state.ProjectReducer,
  }));
  const { campaignType } = campaignDetails;
  const dispatch = useDispatch();

  const updateCampaignType = (type) => {
    dispatch(campaignActions.updatePreviewType("Mobile"));
    dispatch(campaignActions.updateCampaignType(campaignTypes[type]));
  };

  useEffect(() => {
    dispatch(campaignActions.updateCampaignType("CHAT"));
  }, []);
  const trackNewCampaignInGA = ()=>{
    Event(
      getProjectId(props.params.projectkey),
    "Campaigns",
    "NewCampaignClicked",
    "New campaign"
    )
    let action = campaignDetails.campaignType.toLowerCase();
    Event(
      getProjectId(props.params.projectkey),
    "Campaigns",
    "CampaignType"+action[0].toUpperCase()+action.substring(1, action.length),
    campaignDetails.campaignType.toLowerCase(),
    )
  }
  const createNewCampaign = () => {
    const newCampaignData = constructNewCampaign( projectkey, projects, campaignDetails.campaignType.toLowerCase(), campaignDetails, groupId  )
    trackNewCampaignInGA();
    dispatch(campaignActions.saveCampaign(newCampaignData, true));
  };

  return (
    <div>
      <div className="cs-popupbg"></div>
      <div className="cs-popup-model popup-new campaign-popup">
        <header>
          <h2>
            <figure>
              <NewCampaignIcon />
            </figure>
            Campaign Type
          </h2>
          <cite id="close_btn" onClick={closePopup}>
            ×
          </cite>
        </header>
        <section className="popup-new-content">
          <div className="inline-block">
            <h3>Choose the type of campaign you would like to send</h3>
            <div
              id="chat_box"
              onClick={() => updateCampaignType("chat")}
              className={
                "cs-radio-form " + (campaignType == "CHAT" ? "active" : "")
              }
            >
              <label>Chat</label>
              <p>Trigger a chat message that will appear on the Chat Widget</p>
            </div>
            <div
              id="announcement_box"
              onClick={() => updateCampaignType("announcement")}
              className={
                "cs-radio-form " +
                (campaignType == "ANNOUNCEMENT" ? "active" : "")
              }
            >
              <label>Announcement</label>
              <p>
                Launch a pop-up message that appears when the recipient is on a
                specific page
              </p>
            </div>
            <div
              id="email_box"
              onClick={() => updateCampaignType("email")}
              data-campaign_type="email"
              className={
                "cs-radio-form " + (campaignType == "EMAIL" ? "active" : "")
              }
            >
              <label>Email</label>
              <p>
                Send automated emails that will be delivered to your contact’s
                inbox
              </p>
            </div>
          </div>
          <img
            src={CAMPAIGN_TYPE_PLACEHOLDERS_IMAGES[campaignType]}
            className="preview-temp fr"
          />
        </section>
        <footer>
          <a
            id="cancel_btn"
            onClick={closePopup}
            href="javascript:void(0)"
            className="cs-btn cs-decline-btn"
          >
            Cancel
          </a>
          <a id="createNewCampaign" className="cs-btn cs-primary-btn" onClick={createNewCampaign} onKeyDown={() => {}}>
            Next
          </a>
        </footer>
      </div>
    </div>
  );
};

export default NewCompaignPopup;
