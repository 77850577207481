import React from "react";


const LoaderComponent = ({ loaderCount })=> {

    const getLoader = () => {
        let loader = [];
        while (loaderCount) {
            loader.push(<div class="conversation-msg  fs-exclude progress-chat">
                <figure>
                    <em class="progress"></em>
                </figure>
                <label>
                    <em class="progress"></em>
                </label>
                <p>
                    <em class="progress"></em>
                </p>
            </div>)
            loaderCount--;
        }
        return loader;
  };
  return (
    <React.Fragment>
          {getLoader()}
    </React.Fragment>
  );
    
  
}

export default LoaderComponent;
