import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import AddTagsPopup from "../components/chat/AddTagsPopup.jsx";
import * as PopupActions from "../actions/PopupAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { TAG_TYPES, taggingType } from "../commons/Constants";
import { isValidTagName } from "../commons/Utility";
import * as TagActions from "../actions/TagActions";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import { getProjectId } from "../commons/Utility";

@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  tagsReducer: state.TagReducer,
}))
class AddTagsPopupContainer extends Component {
  constructor() {
    super();
    this.state = {
      conversationObject: {
        conversationMap: {},
        contactsMap: {},
      },
      conversationId: "",
      currentConversation: {},
      contact: {},
      dropDownValuesMap: {},
      selectedTagNames: [],
      presentTagNames: [],
      showTagsDropDown: false,
      tagsMap: {},
    };
    this.setLocalState = this.setLocalState.bind(this);
    this.onTagSelect = this.onTagSelect.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
    this.onTagsBlur = this.onTagsBlur.bind(this);
    this.addTagsToSelectedTagList = this.addTagsToSelectedTagList.bind(this);
    this.isAlreadyPresent = this.isAlreadyPresent.bind(this);
    this.removeFromSelectedTags = this.removeFromSelectedTags.bind(this);
    this.removeFromPresentTags = this.removeFromPresentTags.bind(this);
    this.isValidTag = this.isValidTag.bind(this);
  }
  componentWillMount() {
    this.setLocalState(this.props);
  }
  componentDidMount() {
    this.props.getChildRef(this);
  }
  componentWillReceiveProps(nextProps) {
    this.setLocalState(nextProps);
  }
  setLocalState(props, callBack) {
    let conversationObject = Object.assign(
      {},
      props.ConversationReducer.conversationObject
    );
    let currentConversationId = props.conversationId
      ? props.conversationId
      : "";
    let conversation = conversationObject.conversationMap[currentConversationId]
      ? conversationObject.conversationMap[currentConversationId]
      : {};
    let contact = conversationObject.contactsMap[conversation.visitorId]
      ? conversationObject.contactsMap[conversation.visitorId]
      : {};
    let tagMap = props.tagsReducer.tagMap;
    let dropDownValuesMap = {};
    let presentTagNames = [];
    for (let key in tagMap)
      if (
        tagMap[key] &&
        tagMap[key].name &&
        !this.isAlreadyPresent(tagMap[key].name.toLowerCase())
      )
        if (
          (props.taggingType == taggingType.conversation_tag &&
            tagMap[key].tagType === TAG_TYPES.staff_created &&
            (!conversation ||
              !conversation.tags ||
              !conversation.tags.includes(key))) ||
          props.taggingType == taggingType.people_tag
        )
          dropDownValuesMap[tagMap[key].name] = tagMap[key].name;
    if (
      props.taggingType == taggingType.conversation_tag &&
      conversation &&
      conversation.tags
    ) {
      for (let tagId of conversation.tags)
        if (tagMap && tagMap[tagId]) presentTagNames.push(tagMap[tagId]);
    }
    this.setState({
      ...this.state,
      conversationObject: conversationObject,
      conversationId: currentConversationId,
      currentConversation: conversation,
      contact: contact,
      dropDownValuesMap: dropDownValuesMap,
      tagsMap: tagMap,
      presentTagNames: presentTagNames,
    });
  }

  isValidTag(e) {
    let isValid = false;
    let alreadySelectedTags = this.state.selectedTagNames;
    let tagNames = this.addTagsPopup.refs.tagNames.value.split(",");
    tagNames.map((tag) => {
      if (isValidTagName(tag) || alreadySelectedTags.length > 0) isValid = true;
    });
    let tagsMap = this.state.tagsMap;
    let dropDownValuesMap = {};
    let conversationMap = Object.assign(
      {},
      this.state.conversationObject.conversationMap
    );
    let conversation = conversationMap[this.state.conversationId];
    for (let key in tagsMap) {
      let tag = tagsMap[key].name.toLowerCase().trim();
      let tagType = tagsMap[key].tagType;
      if (
        tag &&
        !this.isAlreadyPresent(tag, tagNames) &&
        tag.includes(tagNames[tagNames.length - 1].toLowerCase().trim())
      )
        if (
          (this.props.taggingType == taggingType.conversation_tag &&
            !conversation.tags.includes(key) &&
            tagType === TAG_TYPES.staff_created) ||
          this.props.taggingType == taggingType.people_tag
        )
          dropDownValuesMap[tagsMap[key].name] = tagsMap[key].name;
    }
    this.setState({
      ...this.state,
      showAddTag: isValid,
      dropDownValuesMap: dropDownValuesMap,
      showTagsDropDown: true,
    });
  }
  keyPressHandler(e) {
    if (e.key == "Enter") this.addTagsToSelectedTagList();
  }
  onTagSelect(e) {
    let selectedTag = e.target.innerHTML;
    let tagsMap = this.state.tagsMap;
    let dropDownValuesMap = {};
    let conversationMap = Object.assign(
      {},
      this.state.conversationObject.conversationMap
    );
    let conversation = conversationMap[this.state.conversationId];
    for (let key in tagsMap) {
      let tag = tagsMap[key].name.toLowerCase().trim();
      let tagType = tagsMap[key].tagType;
      if (
        tag &&
        !this.isAlreadyPresent(tag) &&
        tag !== selectedTag.toLowerCase().trim()
      )
        if (
          (this.props.taggingType == taggingType.conversation_tag &&
            !conversation.tags.includes(key) &&
            tagType === TAG_TYPES.staff_created) ||
          this.props.taggingType == taggingType.people_tag
        )
          dropDownValuesMap[tagsMap[key].name] = tagsMap[key].name;
    }
    let selectedTagNames = Object.assign([], this.state.selectedTagNames);
    selectedTagNames.push(selectedTag);
    let tagNames = this.addTagsPopup.refs.tagNames.value.split(",");
    let lastTagName = tagNames[tagNames.length - 1];
    if (selectedTag.toLowerCase().includes(lastTagName.toLowerCase().trim()))
      tagNames.pop();

    this.setState(
      {
        ...this.state,
        showTagsDropDown: false,
        showAddTag: selectedTagNames.length > 0 ? true : false,
        selectedTagNames: selectedTagNames,
        dropDownValuesMap: dropDownValuesMap,
      },
      () => {
        this.addTagsPopup.refs.tagNames.value = tagNames.join(", ");
      }
    );
  }

  addTagsToSelectedTagList() {
    let tagNameString = this.addTagsPopup.refs.tagNames.value;
    let tagNames = tagNameString.split(",");
    let selectedTagNames = Object.assign([], this.state.selectedTagNames);
    if (!tagNameString.trim() && selectedTagNames.length > 0) {
      this.props.requestAddTags();
      return;
    }
    let invalidTags = [];
    tagNames.map((tag) => {
      if (
        tag.trim() &&
        !this.isAlreadyPresent(tag.toLowerCase().trim()) &&
        isValidTagName(tag)
      )
        selectedTagNames.push(tag);
      else if (!isValidTagName(tag)) invalidTags.push(tag);
    });

    let tagMap = this.state.tagsMap;
    let conversationMap = Object.assign(
      {},
      this.state.conversationObject.conversationMap
    );
    let conversation = conversationMap[this.state.conversationId];
    let dropDownValuesMap = {};
    for (let key in tagMap)
      if (
        tagMap[key] &&
        tagMap[key].name &&
        !this.isAlreadyPresent(
          tagMap[key].name.toLowerCase(),
          null,
          selectedTagNames
        )
      )
        if (
          (this.props.taggingType == taggingType.conversation_tag &&
            tagMap[key].tagType === TAG_TYPES.staff_created &&
            !conversation.tags.includes(key)) ||
          this.props.taggingType == taggingType.people_tag
        )
          dropDownValuesMap[tagMap[key].name] = tagMap[key].name;

    this.setState(
      {
        ...this.state,
        showAddTag: selectedTagNames.length > 0 ? true : false,
        selectedTagNames: selectedTagNames,
        dropDownValuesMap: dropDownValuesMap,
        showTagsDropDown: false,
      },
      () => {
        if (invalidTags.length > 0) {
          this.props.dispatch(
            showVoicebox({
              message: "Invalid Tag names ( " + invalidTags.toString() + " )",
              dismissAfter: 3000,
            })
          );
          this.addTagsPopup.refs.tagNames.value = invalidTags.toString();
        } else this.addTagsPopup.refs.tagNames.value = "";
      }
    );
  }

  isAlreadyPresent(tagName, tagNames, alreadySelectedTags) {
    if (!alreadySelectedTags) alreadySelectedTags = this.state.selectedTagNames;

    let isPresent = false;
    alreadySelectedTags.map((tag) => {
      if (tag.toLowerCase().trim() == tagName.toLowerCase().trim())
        isPresent = true;
    });
    if (!isPresent && tagNames)
      tagNames.map((tag) => {
        if (
          tag &&
          tag.toLowerCase().trim() == tagName.toLowerCase().trim() &&
          tagNames.indexOf(tag) < tagNames.length - 1
        )
          isPresent = true;
      });
    return isPresent;
  }

  returnState() {
    return this.state;
  }
  /* 
    onTagsFocus() {
    		this.setState({...this.state, showTagsDropDown : true})
    }
     */
  onTagsBlur() {
    this.setState({ ...this.state, showTagsDropDown: false });
  }
  removeFromSelectedTags(e) {
    let tag = e.currentTarget.dataset.tag_name;
    let selectedTagNames = this.state.selectedTagNames;
    selectedTagNames.splice(selectedTagNames.indexOf(tag), 1);
    let tagMap = this.state.tagsMap;
    let dropDownValuesMap = {};
    let conversationMap = Object.assign(
      {},
      this.state.conversationObject.conversationMap
    );
    let conversation = conversationMap[this.state.conversationId];
    for (let key in tagMap)
      if (
        tagMap[key] &&
        tagMap[key].name &&
        !this.isAlreadyPresent(tagMap[key].name.toLowerCase())
      )
        if (
          (this.props.taggingType == taggingType.conversation_tag &&
            tagMap[key].tagType === TAG_TYPES.staff_created &&
            !conversation.tags.includes(key)) ||
          this.props.taggingType == taggingType.people_tag
        )
          dropDownValuesMap[tagMap[key].name] = tagMap[key].name;

    this.setState({
      ...this.state,
      showAddTag: selectedTagNames.length > 0 ? true : false,
      selectedTagNames: selectedTagNames,
      dropDownValuesMap: dropDownValuesMap,
    });
  }
  removeFromPresentTags(e) {
    let tagId = e.currentTarget.dataset.tag_id;
    let conversationMap = this.state.conversationObject.conversationMap;
    let conversation = this.state.currentConversation;
    console.log("tagId: ", tagId);
    this.props.dispatch(
      TagActions.removeTagFromConversationRequest(
        tagId,
        conversation,
        this.props.isOverflowConversation
      )
    );
  }

  render() {
    return (
      <AddTagsPopup
        ref={(component) => (this.addTagsPopup = component)}
        removeFromSelectedTags={this.removeFromSelectedTags}
        removeFromPresentTags={this.removeFromPresentTags}
        /* onTagsFocus={this.onTagsFocus} */ onTagsBlur={this.onTagsBlur}
        isValidTag={this.isValidTag}
        state={this.state}
        onTagSelect={this.onTagSelect}
        contact={this.state.contact}
        keyPressHandler={this.keyPressHandler}
      />
    );
  }
}

export default AddTagsPopupContainer;
