import React, { useEffect, useState, useRef } from "react";
import { browserHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
//import { initiateClipboard , getAppMode , isValidChatPromptURL } from '../../commons/Utility'
import { showVoicebox } from "../../actions/VoiceboxAction";
import {
  updateProjectRequest,
  createNewProject,
} from "../actions/ProjectAction";
import { staffListRequest } from "../../actions/StaffAction";
import {
  ErrorIcon,
  DisablePopupIcon,
  EnableWidgetIcon,
} from "../../commons/Icons";
import { usePrevious } from "../hooks/usePrevious";
import { useChatConfigurationEntity } from "../hooks/useChatConfigurationEntity";
import { updateWebsiteUrlRequest } from "../actions/ChatConfigurationAction";
import * as ProjectActions from "../actions/ProjectAction";
import Clipboard from "clipboard";
import * as UserActions from "../../actions/UserAction";
import { getUserProject } from "../../components/myteam/Action/UserProjectMappingAction";
import { fetchChatPromptConfiguration } from "../actions/PromptActions";
import * as AccountProjectHook from "../../components/myteam/Hook/AccountProjectHook";
import * as Utilities from "../../commons/Utility.js";
import { useUpdateChatConfigurationEntity } from "../hooks/useUpdateChatConfigurationEntity";

const WidgetConfiguration = (props) => {
  const {
    projects = {},
    chatConfiguration = {},
    staffReducer,
    userReducer,
  } = useSelector((state) => ({
    projects: state.ProjectReducer,
    chatConfiguration: state.ChatConfigurationReducer,
    staffReducer: state.StaffReducer,
    userReducer: state.UserReducer,
    promptConfiguration: state.ChatPromptConfigurationReducer,
  }));

  useEffect(() => {
    console.log("initiating clip board");
    initiateClipboard(
      ".copy-code-icon",
      () => {
        dispatch(
          showVoicebox({
            message: "Widget code copied to clipboard",
            dismissAfter: 3000,
          })
        );
      },
      () => {
        dispatch(
          showVoicebox({
            message: "Please use CTRL/CMD+C to copy.",
            dismissAfter: 3000,
          })
        );
      }
    );
  }, []);

  const dispatch = useDispatch();
  const [widgetName, setWidgetName] = useState("");
  const [isInputBoxfocused, setIsInputBoxfocused] = useState(false);
  const [isUrlBoxfocused, setIsUrlBoxfocused] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUrlError, setIsUrlError] = useState(false);
  const [projectDetails, setProject] = useState({});
  const [chatConfigDetails, setChatConfig] = useState({});
  const [domainName, setDomainName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isUpdatingUrl, setIsUpdatingUrl] = useState(false);
  const [isChangingUrl, setIsChangingUrl] = useState(false);
  const [isNewWidget, setIsNewWidget] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const valueRef = useRef(null);
  const urlRef = useRef(null);

  useEffect(()=>{
    if (!projects.isFetched) {
      dispatch(ProjectActions.requestAvailableProject());
    }
    if(!userReducer.isFetched){
      dispatch(UserActions.getUser());
    }
  } , [])

  const isValidChatPromptURL = (url) => {
    try {
      let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
      return pattern.test(url);
    } catch (e) {
      return false;
    }
  };

  const initiateClipboard = (
    selector,
    successFunction,
    errorFunction,
    config
  ) => {
    let clipboard = new Clipboard(selector, config);
    clipboard.on("success", function (e) {
      successFunction();
      e.clearSelection();
    });

    clipboard.on("error", function (e) {
      errorFunction();
    });
    return clipboard;
  };

  const getAppMode = () => {
    let host = document.location.host;

    if (
      host.endsWith("livesupport-app.appspot.com") ||
      host == "chatsupport.co" ||
      host == "app.chatsupport.co" ||
      host == "anywherehelp.io" ||
      host == "app.anywherehelp.io" ||
      host == "beta.chatsupport.co" ||
      host == "chatsupport.answerforce.app" 
    ) {
      return "live";
    } else if (
      host.endsWith("staging-live-support.appspot.com") ||
      host == "staging.chatsupport.co" ||
      host == "staging.app.chatsupport.co"
    ) {
      return "staging";
    }
    return "local";
  };

  const getProjectDetails = (projectList) => {
    let projectObj = {};
    if (projectList) projectObj = projectList;
    else projectObj = projects;
    const projectKey = Utilities.getProjectId(props.params.projectkey);
    let projectDetail = projectObj.projectList.find(
      (project) => project && project.key === projectKey
    );
    projectDetail = Object.assign({}, projectDetail);
    return projectDetail;
  };

  const getServerUrl = () => {
    if (getAppMode() == "live") {
      return "https://app.chatsupport.co/api";
    } else {
      return "https://staging.chatsupport.co/api";
    }
  };

  let project = getProjectDetails() || {};

  const chatConfigurationData = useChatConfigurationEntity(project.key) || {};
  const updateChatConfig = useUpdateChatConfigurationEntity();
  useEffect(() => {
    const projectKey = Utilities.getProjectId(props.params.projectkey);
    const domainName = chatConfiguration.data.hasOwnProperty(projectKey)
      ? chatConfiguration.data[projectKey].domainName
      : "";
    if (isNewWidgetConfiguration()) {
      valueRef.current.previousValue = "";
      valueRef.current.projectDetails = {};
      return;
    }
    setWidgetName(project.name);
    setDomainName(domainName);
    setProject(project);
    valueRef.current.previousValue = project.name;
    valueRef.current.projectDetails = project;
    setChatConfig(
      chatConfiguration.data.hasOwnProperty(projectKey)
        ? chatConfiguration.data[projectKey]
        : {}
    );
  }, [project.name, chatConfiguration.data, isNewWidget]);

  useEffect(() => {
    //let domainName = chatConfigurationData && chatConfigurationData.chatConfiguration && chatConfigurationData.chatConfiguration.domainName || "";
    if (isNewWidgetConfiguration()) {
      urlRef.current.previousValue = "";
      return;
    }
    const projectKey = Utilities.getProjectId(props.params.projectkey);
    const domainName = chatConfiguration.data.hasOwnProperty(projectKey)
      ? chatConfiguration.data[projectKey].domainName || ""
      : "";

    setDomainName(domainName);
    urlRef.current.previousValue = domainName;
  }, [chatConfigurationData.chatConfiguration, isNewWidget]);

  useEffect(() => {
    if (prevIsUpdating && !projects.isUpdating) {
      setIsUpdatingName(false);
      setIsUpdatingUrl(false);
    }
  }, [projects.isUpdating]);

  useEffect(() => {
    if (!!projects.createdProject) {
      let projectkey = projects.createdProject.split("-")[1];

      let settingsURL = "/app/" + projectkey + "/settings/widget/configuration";
      let redirectTo = settingsURL;

      dispatch(UserActions.updateSelectedProjectKey(projectkey));
      browserHistory.push(redirectTo);

      dispatch(
        fetchChatPromptConfiguration(Utilities.getProjectId(projectkey))
      );
      dispatch(ProjectActions.clearCreatedProjectKey());

      if (!!userReducer && userReducer.data.hasOwnProperty("id"))
        dispatch(getUserProject(userReducer.data["id"]));
      // window.location.href = window.location.origin + "/app/"+ projectkey + "/settings/widget";
    }
  }, [projects.createdProject]);

  useEffect(() => {
    let projectKey = props.params.projectkey;
    if (
      !staffReducer ||
      (!!staffReducer &&
        staffReducer.dashboardAgents[Utilities.getProjectId(projectKey)] ===
          undefined &&
        staffReducer.isLoading != true)
    ) {
      dispatch(staffListRequest(Utilities.getProjectId(projectKey)));
    }
  });

  // useEffect( () => {
  //     if(!prevIsUpdating && projects.isUpdating)
  //     {
  //         dispatch(showVoicebox( { message : 'Updating Widget Name', showLoader : true } ) );
  //     }
  // } , [projects.isUpdating]);

  useEffect(() => {
    if (projects.isFetched && !isNewWidgetConfiguration()) {
      setWidgetName(project.name);
      if (!!project.name && isError) setIsError(false);
    } else {
      valueRef.current.focus();
    }
  }, [projects.isFetched, isNewWidget]);

  //Did mount

  //willunmount
  useEffect(() => {
    window.addEventListener("keyup", onEnter);
    return () => {
      window.removeEventListener("keyup", onEnter);
    };
  }, []);

  const onEnter = (event) => {
    if (event.key === "Enter" && event.keyCode == 13) {
      if (event.target.dataset.field === "name" && !isUpdatingName) {
        setIsUpdatingName(true);
        saveWidgetName(projects);
      } else if (event.target.dataset.field === "url" && !isUpdatingUrl) {
        setIsUpdatingUrl(true);
        saveUrl(projects);
      }
    }
  };

  const getInputBoxClassName = () => {
    if (isInputBoxfocused) return "cs-input-field cs-active-field";
    if (isError) return "cs-input-field cs-error-field";
    else return "cs-input-field";
  };

  const getUrlBoxClassName = () => {
    if (isUrlBoxfocused) return "cs-input-field cs-active-field";
    if (isUrlError) return "cs-input-field cs-error-field";
    else return "cs-input-field";
  };

  const onChangeWidgetName = (event) => {
    let widgetName = valueRef.current.value;
    setWidgetName(widgetName);
  };

  const setUrlValue = (event) => {
    let domainName = urlRef.current.value;
    setDomainName(domainName);
    const projectKey = Utilities.getProjectId(props.params.projectkey);
    const testName = chatConfiguration.data.hasOwnProperty(projectKey)
      ? chatConfiguration.data[projectKey].domainName || ""
      : "";
    setIsChangingUrl(true);
  };

  const saveWidgetName = (project) => {
    if (!valueRef) return;
    let widgetName = valueRef ? valueRef.current.value : "";
    if (!widgetName || widgetName.trim().length === 0) {
      setIsError(true);
      setIsInputBoxfocused(false);
    } else {
      setIsError(false);
      setIsInputBoxfocused(false);
      if (canSaveWidgetName(widgetName)) {
        if (
          !AccountProjectHook.isProjectNameAvailable(
            projects.projectList,
            widgetName
          )
        ) {
          setIsError(true);
          setIsInputBoxfocused(false);
          return;
        }

        let projectDetailsToUpdate = valueRef.current.projectDetails;
        projectDetailsToUpdate.name = widgetName.trim();
        valueRef.current.previousValue = widgetName.trim();

        if (isNewWidgetConfiguration()) {
          createNewWidget(
            projectDetailsToUpdate.name,
            urlRef ? urlRef.current.value : ""
          );
          console.log("New Project Creation");
        } else dispatch(updateProjectRequest(projectDetailsToUpdate));
      }
    }
  };

  const saveUrl = (projects) => {
    if (!urlRef) return;
    let domainName = urlRef ? urlRef.current.value : "";

    if (!domainName || domainName.trim().length === 0) {
      setIsUrlError(true);
      setIsUrlBoxfocused(false);
    } else {
      setIsUrlError(false);
      setIsUrlBoxfocused(false);
      if (domainName.startsWith("http") && isValidChatPromptURL(domainName)) {
        if (canSaveWebsiteUrl(domainName)) {
          domainName = domainName.toLowerCase();
          urlRef.current.previousValue = domainName;
          setDomainName(domainName);
          let websiteDetailsToUpdate = {
            domainName: domainName,
            projectId: Utilities.getProjectId(props.params.projectkey),
          };
          if (isNewWidgetConfiguration())
            console.log("New Project Creation url");
          else dispatch(updateWebsiteUrlRequest(websiteDetailsToUpdate));
        }
      } else {
        setIsUrlError(true);
        setIsUrlBoxfocused(false);
      }
    }
  };

  const canSaveWidgetName = (widgetName) => {
    if (!widgetName) return false;
    return valueRef.current.previousValue !== widgetName.trim();
  };

  const canSaveWebsiteUrl = (widgetUrl) => {
    if (!widgetUrl) return false;
    return urlRef.current.previousValue !== widgetUrl.trim();
  };

  const createNewWidget = (projectName, url) => {
    dispatch(
      createNewProject({
        name: projectName,
        weburl: url,
        staffList: [],
      })
    );
  };

  const isNewWidgetConfiguration = () => {
    let pathurl = document.location.pathname;

    if (pathurl.includes("/widget/newwidget")) {
      if (isNewWidget == false) setIsNewWidget(true);
      return true;
    } else {
      if (isNewWidget == true) setIsNewWidget(false);
      return false;
    }
  };

  const getWidgetTitle = () => {
    let pathurl = document.location.pathname;

    if (isNewWidgetConfiguration()) return "Create New Widget";
    else return "Install your widget";
  };

  const toggleWidgetDisplay = () => {
    let chatConfig = Object.assign({}, chatConfigurationData.chatConfiguration);

    if (chatConfig) {
      const isChatEnabled = chatConfig.isChatEnabled;
      chatConfig.isChatEnabled = !isChatEnabled;
      updateChatConfig({
        key: chatConfig.key,
        isChatEnabled: chatConfig.isChatEnabled,
      });
      dispatch(showVoicebox({ message: "Updating" }));
      if (isWidgetEnabled) {
        setShowPopup(false);
      }
    }
  };
  const isSuperAdmin = () => {
    const { data } = userReducer;
    if (data && (data.isAdmin === "true" || data.originalId)) {
      return true;
    }
    return false;
  };

  const isWidgetEnabled = () => {
    let chatConfig = chatConfigurationData.chatConfiguration;
    if (chatConfig) {
      return chatConfig.isChatEnabled;
    }
  };

  const prevIsUpdating = usePrevious(projects.isUpdating);

  const scriptCode =
    "<script> ( function( a , b , c , d , e , f , g ) { c[d] = c[d] || function() { (c[d].q = c[d].q || []).push(arguments); }; c[ '_lsAlias' ] = c[ d ]; e = a.createElement(b); e.type = 'text/javascript'; e.async = true; e.src = '" +
    getServerUrl() +
    "/client/get/script/" +
    project.key +
    "'; f = function() { g = a.getElementsByTagName(b)[0]; g.parentNode.insertBefore( e , g ); }; c.addEventListener( 'load' , f ); } )( document , 'script' , window , '_ls' ); _ls( 'init' , { 'projectId' : '" +
    project.key +
    "' } ); </script>";

  const showDisablePopup = () => {
    if (!isNewWidgetConfiguration()) setShowPopup(true);
  };

  return (
    <div className="cs-settings-wrp">
      <section className="widget-configuration">
        <h3>{getWidgetTitle()}</h3>
        <p>Get the widget code to embed it on your website</p>
        <div className="cs-widget-config">
          <div>
            <label>Widget Name</label>
            <p>
              Provide a suitable name for the widget in line with the website
            </p>
          </div>
          <div className={getInputBoxClassName()}>
            <input
              className="fs-exclude"
              ref={valueRef}
              data-field="name"
              onFocus={() => {
                setIsInputBoxfocused(true);
              }}
              onBlur={saveWidgetName}
              onChange={onChangeWidgetName}
              type="text"
              value={widgetName}
              placeholder="Widget Name"
            />
            <span>
              <ErrorIcon />
              Please enter a valid widget name
            </span>
          </div>
        </div>
        <div
          className={
            "cs-widget-config" + (isNewWidgetConfiguration() ? " dn" : " dn")
          }
        >
          <div>
            <label>Website URL</label>
            <p>Enter the URL of your website</p>
          </div>
          <div className={getUrlBoxClassName()}>
            <input
              className="fs-exclude"
              ref={urlRef}
              data-field="url"
              onFocus={() => {
                setIsUrlBoxfocused(true);
              }}
              onBlur={saveUrl}
              type="text"
              value={domainName}
              onChange={setUrlValue}
              placeholder="Website URL"
            />
            <span>
              <ErrorIcon />
              Please enter a valid URL (https://yourwebsite.com)
            </span>
          </div>
        </div>
        <div className="cs-widget-config">
          <div>
            <label>Widget Code</label>
            <p>
              Copy the code and add before the <b>&lt;/body&gt; </b> tag of your
              website’s source code to embed the widget
            </p>
          </div>
          <a
            data-clipboard-text={scriptCode}
            className={
              isNewWidgetConfiguration()
                ? " copy-code-disabled"
                : " copy-code-icon"
            }
          >
            Copy code
          </a>
        </div>
        {isSuperAdmin() ? (
          <div className="cs-widget-config">
            <div>
              <label>Disable Widget</label>
              <p>
                Remove the chat widget from the website on which it is currently
                active
              </p>
            </div>
            <a
              href="javascript:void(0)"
              className={
                isNewWidgetConfiguration()
                  ? "copy-code-disabled"
                  : "copy-code-icon"
              }
              onClick={showDisablePopup}
            >
              {isWidgetEnabled() ? "Disable" : "Enable"}
            </a>
          </div>
        ) : (
          ""
        )}
      </section>
      <div
        className="cs-popupbg"
        style={showPopup ? {} : { display: "none" }}
      ></div>
      <div
        className="cs-popup-model popup-new cs-popup-delete"
        style={showPopup ? {} : { display: "none" }}
      >
        <header>
          {isWidgetEnabled() ? (
            <h2>
              <DisablePopupIcon />
              Disable Widget
            </h2>
          ) : (
            <h2>
              <EnableWidgetIcon />
              Enable Widget
            </h2>
          )}
          {/* <h2><DisablePopupIcon/>{isWidgetEnabled() ? "Disable Widget" : "Enable Widget"}</h2> */}
          <cite
            onClick={() => {
              setShowPopup(false);
            }}
          >
            ×
          </cite>
        </header>
        <section>
          <div class="cs-model-msg">
            {isWidgetEnabled()
              ? "Are you sure you would like to remove the widget from the website where it is currently active? "
              : "Are you sure you would like to add the widget to the website where it is currently inactive?"}
          </div>
        </section>
        <footer>
          <a
            href="javascript:void(0)"
            class="cs-btn cs-decline-btn"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Cancel
          </a>
          <a
            href="javascript:void(0)"
            class="cs-btn cs-primary-btn"
            id="toggle_widgetdispaly"
            onClick={toggleWidgetDisplay}
          >
            {isWidgetEnabled() ? "Disable" : "Enable"}
          </a>
        </footer>
      </div>
    </div>
  );
};

export default WidgetConfiguration;
