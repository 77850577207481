import React, { FC, useState } from 'react'
import { Margin } from 'styled-components-spacing'
import {
  fadeIn,
  fadeOut,
} from 'src/features/returningFlow/components/SuccessBullets/data'
import {
  Container,
  Item,
} from 'src/features/returningFlow/components/SuccessBullets/styled'
import { IProps } from 'src/features/returningFlow/components/SuccessBullets/types'
import { Icon } from 'src/features/ui/components/Icon'
import { useDelay } from 'src/utils/hooks/useDelay'

export const SuccessBullets: FC<IProps> = ({ items, onComplete }) => {
  const getMsUntilFadeOutStart = () =>
    fadeIn.delay * items.length + fadeIn.visibilityBuffer
  const getMsUntilFadeOutComplete = () =>
    fadeIn.delay * items.length + fadeIn.visibilityBuffer + fadeOut.duration

  const [shouldFadeOut, setShouldFadeOut] = useState(false)

  useDelay(() => {
    setShouldFadeOut(true)
  }, getMsUntilFadeOutStart())

  useDelay(() => {
    if (onComplete) {
      onComplete()
    }
  }, getMsUntilFadeOutComplete())

  return (
    <Container
      id="success_steps"
      shouldFadeOut={shouldFadeOut}
      durationMs={fadeOut.duration}
    >
      {items.map((item, index) => (
        <Item
          key={item}
          durationMs={fadeIn.duration}
          delayMs={index * fadeIn.delay}
        >
          <Icon fill="dodgerBlue" type="checkMark" width={32} height={32} />
          <Margin right={32} />
          {item}
        </Item>
      ))}
    </Container>
  )
}
