/* eslint-disable react/no-array-index-key */
import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  StyledButton,
} from 'src/features/wizard/install/components/Email/EmailMultiple/styled'
import { IProps } from 'src/features/wizard/install/components/Email/EmailMultiple/types'
import { EmailInstructionsContainer } from 'src/features/wizard/install/components/Email/EmailSingle'
import { hasLastInstructionsValue } from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const EmailMultipleComponent: FC<IProps> = ({
  isAddAnotherDisabled,
}) => {
  const [addressesCount, setAddressesCount] = useState(1)

  const handleIncrementCount = () => setAddressesCount(addressesCount + 1)

  return (
    <Container>
      {Array(addressesCount)
        .fill(undefined)
        .map((_, index) => (
          <EmailInstructionsContainer key={index} id={index} />
        ))}
      <StyledButton
        disabled={isAddAnotherDisabled}
        onClick={handleIncrementCount}
      >
        + Add another email
      </StyledButton>
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  isAddAnotherDisabled: !hasLastInstructionsValue(state.install),
})

export const EmailMultiple = connect(mapStateToProps)(EmailMultipleComponent)
