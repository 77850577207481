export const debounce = <TCallback extends Function>(
  cb: TCallback,
  wait = 20
) => {
  let time: NodeJS.Timeout

  const callable = (...args: any) => {
    if (time) {
      clearTimeout(time)
    }

    time = setTimeout(() => cb(...args), wait)
  }

  return (callable as any) as TCallback
}
