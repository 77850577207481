import React, { FC } from 'react'

export const CrossedEye: FC<any> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.04289 1.29289C5.43342 0.902369 6.06658 0.902369 6.45711 1.29289L22.9565 17.7923C23.347 18.1828 23.347 18.816 22.9565 19.2065C22.566 19.597 21.9328 19.597 21.5423 19.2065L5.04289 2.70711C4.65237 2.31658 4.65237 1.68342 5.04289 1.29289Z"
      fill="#8F99AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62568 7.03989C6.16162 7.41078 5.74607 7.80168 5.38062 8.18452C4.64282 8.95746 4.08023 9.72677 3.70189 10.3021C3.5121 10.5907 3.36706 10.833 3.26803 11.0059C3.21848 11.0925 3.18034 11.1618 3.15377 11.2111C3.14049 11.2358 3.13009 11.2554 3.1226 11.2697L3.11353 11.2872L3.11064 11.2928L3.1096 11.2948C3.1096 11.2948 3.10884 11.2963 4 11.75L3.10884 11.2963C2.96372 11.5814 2.96372 11.9186 3.10884 12.2037L3.1096 12.2052L3.11064 12.2072L3.11353 12.2128L3.1226 12.2303C3.13009 12.2446 3.14049 12.2642 3.15377 12.2889C3.18034 12.3382 3.21848 12.4075 3.26803 12.4941C3.36706 12.667 3.5121 12.9093 3.70189 13.1979C4.08023 13.7732 4.64282 14.5425 5.38062 15.3155C6.83927 16.8436 9.09616 18.5 12.05 18.5C13.8482 18.5 15.3881 17.8861 16.6469 17.0611L13.2797 13.6939C12.9241 13.9194 12.5023 14.05 12.05 14.05C10.7797 14.05 9.75 13.0202 9.75 11.75C9.75 11.2977 9.88054 10.8759 10.106 10.5202L6.62568 7.03989ZM14.3431 11.9289C14.3477 11.8699 14.35 11.8102 14.35 11.75C14.35 10.4797 13.3203 9.44995 12.05 9.44995C11.9898 9.44995 11.9301 9.45227 11.871 9.45681L8.33708 5.92286C9.41165 5.37057 10.6529 5 12.05 5C15.0038 5 17.2607 6.65641 18.7194 8.18452C19.4572 8.95746 20.0198 9.72677 20.3981 10.3021C20.5879 10.5907 20.7329 10.833 20.832 11.0059C20.8815 11.0925 20.9197 11.1618 20.9462 11.2111C20.9595 11.2358 20.9699 11.2554 20.9774 11.2697L20.9865 11.2872L20.9894 11.2928L20.9908 11.2956C21.1359 11.5807 21.1363 11.9186 20.9912 12.2037L20.1 11.75C20.9912 12.2037 20.9904 12.2052 20.9904 12.2052L20.9894 12.2072L20.9865 12.2128L20.9774 12.2303C20.9699 12.2446 20.9595 12.2642 20.9462 12.2889C20.9197 12.3382 20.8815 12.4075 20.832 12.4941C20.7329 12.667 20.5879 12.9093 20.3981 13.1979C20.0198 13.7732 19.4572 14.5425 18.7194 15.3155C18.5631 15.4792 18.3976 15.6444 18.2231 15.8089L14.3431 11.9289Z"
      fill="#8F99AD"
    />
  </svg>
)
