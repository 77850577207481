import React, { useRef, useEffect, useState } from "react";
import { ArrowIcon, SearchIcon } from "../../../commons/Icons";
import EditDayComponent from "./EditDayComponent.jsx";
import { getFormattedTimeFromMinutes } from "../../../commons/Utility";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectRequest } from "../../actions/ProjectAction.js";
import { HOURS_TYPE } from "../../../commons/Constants";
import { browserHistory } from "react-router";
import { usePrevious } from "../../hooks/usePrevious";
var ctzc = require("country-tz-currency");

const EditWorkingHoursComponent = (props) => {
  const { projects } = useSelector((state) => ({
    projects: state.ProjectReducer.projectList,
  }));

  const timezoneRef = useRef(null);
  const hourTypeRef = useRef(null);
  const searchIndexRef = useRef(null);
  const timezonesRef = useRef([]);
  const typesRef = useRef([]);
  const hoursTypeIdxRef = useRef(-1);
  const selectedTimezoneRef = useRef(null);
  const timezoneDropDwnRef = useRef(null);
  const hoursTypeDropDwnRef = useRef(null);
  const dispatch = useDispatch();

  const [workingHours, setWorkingHours] = useState({});
  const [timeZone, setTimezone] = useState("");
  const [showBusinessHours, setShowBusinessHours] = useState(false);
  const [showTimezone, setShowTimezone] = useState(false);
  const [showHoursType, setShowHoursType] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchIndex, setSearchIndex] = useState(-1);
  const [typeIndex, setTypeIndex] = useState(-1);
  const [timezones, setTimezones] = useState([]);

  let projectId = props.isFromBusinessHoursPage
    ? props.projectKey
    : "LS-" + props.projectKey;

  const {
    workingHoursFromreducer,
    updateWorkingHours,
    createWorkingHours,
    updateBusinessHours,
    createBusinessHours,
    isUpdating,
  } = props;

  let prevIsUpdating = usePrevious(isUpdating);
  let initialTimezone =
    workingHoursFromreducer &&
    workingHoursFromreducer.hoursList &&
    workingHoursFromreducer.hoursList[0]
      ? workingHoursFromreducer.hoursList[0].timezone
      : "";

  const convertWorkingHoursResponse = (weekDayConfig, roundTheClock) => {
    let workingHours = {};
    if (weekDayConfig) {
      Object.keys(weekDayConfig).forEach((item) => {
        let hours = weekDayConfig[item].hours;
        if (hours) {
          let startTime = hours[0].start;
          let endTime = hours[0].end;
          workingHours[item] = { startTime: startTime, endTime: endTime };
          workingHours[item].startTime = hours[0].start;
          workingHours[item].endTime = hours[0].end;
          workingHours[item].isChecked = true;
        }
      });
    }
    console.log("The search index is " + searchIndex);
    if (Object.keys(workingHours).length <= 0) {
      let endTime = 0;
      if (roundTheClock) endTime = 1439;
      else endTime = 1410;
      let weekdays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
      for (let day in weekdays) {
        workingHours[weekdays[day]] = { startTime: 0, endTime: endTime };
        workingHours[weekdays[day]].isChecked = roundTheClock
          ? roundTheClock
          : false;
      }
    }
    return workingHours;
  };

  let weekDayConfig =
    workingHoursFromreducer &&
    workingHoursFromreducer.hoursList &&
    workingHoursFromreducer.hoursList[0]
      ? convertWorkingHoursResponse(
          workingHoursFromreducer.hoursList[0].weekDayConfig,
          workingHoursFromreducer.hoursList[0].roundTheClock
        )
      : convertWorkingHoursResponse("");

  if (
    workingHoursFromreducer &&
    (workingHoursFromreducer.weekDayConfig ||
      workingHoursFromreducer.roundTheClock)
  ) {
    weekDayConfig = convertWorkingHoursResponse(
      workingHoursFromreducer.weekDayConfig,
      workingHoursFromreducer.roundTheClock
    );
    initialTimezone = workingHoursFromreducer.timezone;
  }

  useEffect(() => {
    document.addEventListener("keydown", handleArrows);
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleArrows);
    };
  }, [showTimezone, showHoursType]);

  useEffect(() => {
    if (
      workingHoursFromreducer &&
      (workingHoursFromreducer.hoursList ||
        workingHoursFromreducer.weekDayConfig ||
        workingHoursFromreducer.roundTheClock) &&
      Object.keys(workingHours).length <= 0
    )
      setState();
  }, [weekDayConfig]);

  useEffect(() => {
    if (prevIsUpdating && !isUpdating)
      browserHistory.push(
        "/app/" + props.projectKey + "/settings/myprofile/workinghours"
      );
  }, [isUpdating]);

  useEffect(() => {
    if (
      timezonesRef &&
      timezonesRef.current &&
      timezonesRef.current[searchIndex]
    )
      timezonesRef.current[searchIndex].scrollIntoView({ block: "nearest" });
  }, [searchIndex]);

  useEffect(() => {
    if (
      props.isFromBusinessHoursPage &&
      timeZone &&
      timeZone !== initialTimezone
    ) {
      saveBusinessHours();
      let project = projects.find(
        (project) => project && project.key === props.projectKey
      );
      let timezoneArray = timeZone.split(" ");
      let currentTimeZoneId = timezoneArray[timezoneArray.length - 1];
      if (currentTimeZoneId !== project.timezoneId) {
        project.timezoneId = currentTimeZoneId;
        project.isTimezoneUpdate = true;
        dispatch(updateProjectRequest(project));
      }
    }
  }, [timeZone]);

  const setState = () => {
    setTimezone(currentTimeZone);
    setWorkingHours(weekDayConfig);
  };

  const setSearchRef = (value) => {
    searchIndexRef.current = value;
  };

  const setSelectedTimezoneRef = (selectedTimezones) => {
    selectedTimezoneRef.current = selectedTimezones;
  };

  const getTimezone = (initialTimezone) => {
    let currentTimeZone = "(GMT+5:30) Asia/Calcutta";
    let currentTimeZoneId = "Asia/Calcutta";
    let timeZoneMap = ctzc.getAllTimezones();
    let project = projects.find(
      (project) => project && project.key === props.projectKey
    );

    if (props.isFromBusinessHoursPage && project) {
      currentTimeZoneId = project.timezoneId;
      let timezone = timeZoneMap[currentTimeZoneId];
      if (timezone)
        currentTimeZone = "(GMT" + timezone.GMT + ") " + timezone.id;
    }
    if (initialTimezone && initialTimezone !== "null") {
      //to get the timezone id
      let timezoneArray = initialTimezone.split(" ");
      currentTimeZoneId = timezoneArray[timezoneArray.length - 1];

      let timezone = timeZoneMap[currentTimeZoneId];

      if (!timezone) timezone = timeZoneMap[currentTimeZoneId];

      currentTimeZone = "(GMT" + timezone.GMT + ") " + timezone.id;
    }
    return currentTimeZone;
  };

  let currentTimeZone = getTimezone(initialTimezone);

  const handleOutsideClick = () => {
    if (
      !(
        timezoneRef &&
        timezoneRef.current &&
        timezoneRef.current.contains(event.target)
      ) &&
      showTimezone
    ) {
      if (
        timezoneDropDwnRef &&
        timezoneDropDwnRef.current &&
        !timezoneDropDwnRef.current.contains(event.target)
      ) {
        toggleTimezone();
      }
    }
    if (
      !(
        hourTypeRef &&
        hourTypeRef.current &&
        hourTypeRef.current.contains(event.target)
      ) &&
      showHoursType
    ) {
      if (
        hoursTypeDropDwnRef &&
        hoursTypeDropDwnRef.current &&
        !hoursTypeDropDwnRef.current.contains(event.target)
      )
        toggleHoursType();
    }
  };

  const setHoursTypeIdxRef = (indexValue) => {
    hoursTypeIdxRef.current = indexValue;
  };

  const handleArrows = () => {
    let type = ["24/7 - 12:00 AM to 11:59 PM", "Weekdays", "Custom"];
    let availableTimezones = selectedTimezoneRef.current;
    let length = availableTimezones.length;

    if (showHoursType) {
      let indexValue = hoursTypeIdxRef.current;
      if (event.which === 40 && indexValue < 3) indexValue++;
      else if (event.which === 38 && indexValue > 0) indexValue--;
      else if (event.which === 13) {
        if (indexValue > -1 && indexValue < 3)
          updateHoursType(type[indexValue]);
      } else if (event.which === 27 || event.keyCode === 27) {
        if (hoursTypeDropDwnRef && hoursTypeDropDwnRef.current)
          hoursTypeDropDwnRef.current.click();
      }
      event.preventDefault();
      if (indexValue < 3) {
        setHoursTypeIdxRef(indexValue);
        setTypeIndex(indexValue);
      }
    }

    if (showTimezone && availableTimezones.length > 0) {
      let searchIndexValue = searchIndexRef.current;
      if (event.which === 40) {
        //down
        if (searchIndexValue < length - 1) {
          searchIndexValue++;
        }
        event.preventDefault();
      } else if (event.which === 38) {
        //up
        if (searchIndexValue > -1) {
          searchIndexValue--;
        }
        event.preventDefault();
      } else if (event.which === 13) {
        if (availableTimezones[searchIndexValue])
          changeTimezone(availableTimezones[searchIndexValue]);
        return;
      }
      setSearchIndex(searchIndexValue);
      setSearchRef(searchIndexValue);
    }
    if (showTimezone && (event.which === 27 || event.keyCode === 27)) {
      if (timezoneRef && timezoneRef.current) timezoneRef.current.click();
    }
  };

  const changeTimezone = (timezone) => {
    let newTimezone = "(GMT" + timezone.GMT + ") " + timezone.id;
    setTimezone(newTimezone);
    setSearchValue("");
    setSearchIndex(0);
    setSearchRef(0);
    toggleTimezone();
  };

  const hoverHandler = (e) => {
    if (searchIndex !== e.target.dataset.index) {
      setSearchRef(parseInt(e.target.dataset.index));
      setSearchIndex(parseInt(e.target.dataset.index));
    }
  };

  const addToRefs = (el) => {
    if (el && !timezonesRef.current.includes(el)) {
      timezonesRef.current.push(el);
    }
  };

  const getTimezoneComponent = () => {
    let allTimezones = Object.values(ctzc.getAllTimezones());
    let value = searchValue.toLowerCase();
    let selectedTimeozones = allTimezones.filter(
      (timzone) =>
        timzone.GMT.toLowerCase().includes(value) ||
        timzone.id.toLowerCase().includes(value)
    );
    if (selectedTimeozones.length !== timezones.length) {
      setTimezones(selectedTimeozones);
      setSelectedTimezoneRef(selectedTimeozones);
    }
    if (timezonesRef) timezonesRef.current = [];
    let timezoneComponent = selectedTimeozones.map((timezone, index) => (
      <li
        key={index}
        data-index={index}
        data-id={timezone.id}
        onClick={() => changeTimezone(timezone)}
        onMouseOver={hoverHandler}
      >
        <a
          href="javascript:void(0)"
          ref={addToRefs}
          data-index={index}
          className={searchIndex === index ? "select" : ""}
        >
          {"(GMT" + timezone.GMT + ")"} {timezone.id}
        </a>
      </li>
    ));
    return timezoneComponent;
  };

  const toggleTimezone = () => {
    setShowTimezone(!showTimezone);
    if (!showTimezone) {
      setSearchValue("");
      setSearchIndex(0);
      setSearchRef(0);
    }
    event.stopPropagation();
  };

  const toggleHoursType = () => {
    setShowHoursType(!showHoursType);
    setHoursTypeIdxRef(0);
    setTypeIndex(0);
    event.stopPropagation();
  };

  const getEditHoursComponent = () => {
    let weekDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let toRender = [];
    let hoursType = workingHours.hoursType || HOURS_TYPE.weekdays;
    let isRoundTheClock = true;
    let currentWorkingHours = Object.assign(workingHours, {});
    if (Object.keys(workingHours).length > 0) {
      for (let day in weekDays) {
        let key = weekDays[day].slice(0, 2).toUpperCase();
        let isChecked = false;
        if (key in workingHours && workingHours[key].isChecked) {
          isChecked =
            workingHours[key].isChecked !== undefined
              ? workingHours[key].isChecked
              : true;
          if ((key === "SA" || key === "SU") && isChecked) {
            hoursType = HOURS_TYPE.custom;
          }
          if (
            workingHours[key].startTime !== 0 ||
            workingHours[key].endTime !== 1439
          )
            isRoundTheClock = false;
          toRender.push(
            <EditDayComponent
              day={weekDays[day]}
              value={key}
              startTime={workingHours[key].startTime}
              endTime={workingHours[key].endTime}
              isChecked={isChecked}
              selectDay={selectDay}
              workingHours={workingHours}
              updateTime={updateTime}
              hoursType={workingHours.hoursType}
            />
          );
        } else {
          isRoundTheClock = false;
          if (key !== "SA" && key !== "SU") hoursType = HOURS_TYPE.custom;
          toRender.push(
            <EditDayComponent
              day={weekDays[day]}
              value={key}
              isChecked={isChecked}
              selectDay={selectDay}
              startTime={workingHours[key] ? workingHours[key].startTime : 0}
              endTime={workingHours[key] ? workingHours[key].endTime : 0}
              workingHours={workingHours}
              updateTime={updateTime}
              hoursType={workingHours.hoursType}
            />
          );
        }

        if (!currentWorkingHours[key]) currentWorkingHours[key] = {};

        currentWorkingHours[key].isChecked = isChecked;
      }
      currentWorkingHours.isUpdated = true;
      hoursType = isRoundTheClock ? HOURS_TYPE.allDays : hoursType;
      if (hoursType !== currentWorkingHours.hoursType)
        setWorkingHours((prevState) => {
          return { ...prevState, hoursType: hoursType };
        });

      if (!workingHours.isUpdated) setWorkingHours(currentWorkingHours);
    }
    return toRender;
  };

  const selectDay = (e) => {
    let currentWorkingHours = Object.assign(workingHours, {});
    let day = e.target.id;
    let isSelected = currentWorkingHours[day].isChecked;
    currentWorkingHours[day] = {
      ...currentWorkingHours[day],
      isChecked: !isSelected,
    };
    setWorkingHours((prevState) => {
      return { ...prevState, [day]: currentWorkingHours[day] };
    });
    saveBusinessHours();
  };

  const updateTime = (type, value, day) => {
    let currentWorkingHours = Object.assign(workingHours, {});
    currentWorkingHours[day] = {
      ...currentWorkingHours[day],
      [type]: parseInt(value),
    };
    currentWorkingHours[day].isChecked = true;
    setWorkingHours((prevState) => {
      return { ...prevState, [day]: currentWorkingHours[day] };
    });
    saveBusinessHours();
  };

  const updateHoursType = (type) => {
    let currentWorkingHours = Object.assign(workingHours, {});
    currentWorkingHours.hoursType = type;
    let hoursType = type;
    let isChecked = false;
    if (hoursType === HOURS_TYPE.allDays || hoursType === HOURS_TYPE.weekdays)
      isChecked = true;
    for (let day in currentWorkingHours) {
      if (day !== "isUpdated") {
        let isWorking = isChecked;
        if (
          currentWorkingHours[day].isChecked === true ||
          currentWorkingHours[day].isChecked === false
        ) {
          if (
            (day === "SA" || day === "SU") &&
            hoursType === HOURS_TYPE.weekdays
          )
            isWorking = false;
          if (hoursType === HOURS_TYPE.allDays) {
            currentWorkingHours[day].startTime = 0;
            currentWorkingHours[day].endTime = 1439;
          }
          if (hoursType === HOURS_TYPE.weekdays) {
            currentWorkingHours[day].startTime = 540;
            currentWorkingHours[day].endTime = 1020;
          }
          currentWorkingHours[day].isChecked = isWorking;
          currentWorkingHours[day] = {
            ...currentWorkingHours[day],
            isChecked: isWorking,
          };
          setWorkingHours((prevState) => {
            return { ...prevState, [day]: currentWorkingHours[day] };
          });
        }
      }
    }
    setWorkingHours((prevState) => {
      return { ...prevState, hoursType: hoursType };
    });
    toggleHoursType();
    saveBusinessHours();
  };

  const addHoursTypeRef = (el) => {
    if (el && !typesRef.current.includes(el)) {
      typesRef.current.push(el);
    }
  };

  const getHoursType = () => {
    let hoursType = [];
    let type = ["24/7 - 12:00 AM to 11:59 PM", "Weekdays", "Custom"];
    for (let index = 0; index < 3; index++) {
      hoursType.push(
        <li
          data-id={type[index]}
          ref={addHoursTypeRef}
          onClick={() => updateHoursType(type[index])}
        >
          <a
            href="javascript:void(0)"
            className={index === hoursTypeIdxRef.current ? "select" : ""}
          >
            {type[index]}
          </a>
        </li>
      );
    }
    return hoursType;
  };

  const getTiming = () => {
    let currentWorkingHours = Object.assign(workingHours, {});
    let daysCount = 0;
    let workingTime = "";
    if (
      Object.keys(currentWorkingHours).length > 0 &&
      (!currentWorkingHours.hoursType ||
        currentWorkingHours.hoursType === HOURS_TYPE.weekdays)
    ) {
      let mondayStartTime =
        currentWorkingHours["MO"] && currentWorkingHours["MO"].startTime >= 0
          ? currentWorkingHours["MO"].startTime
          : "";
      let mondayEndTime =
        currentWorkingHours["MO"] && currentWorkingHours["MO"].endTime >= 0
          ? currentWorkingHours["MO"].endTime
          : "";
      for (let day in currentWorkingHours) {
        if (day !== "isUpdated" && day !== "hoursType") {
          let startTime = currentWorkingHours[day].startTime;
          let endTime = currentWorkingHours[day].endTime;
          if (startTime >= 0 && endTime >= 0) {
            if (
              mondayStartTime !== startTime ||
              mondayEndTime !== endTime ||
              !currentWorkingHours[day].isChecked
            ) {
              continue;
            }
            daysCount++;
          }
        }
      }
      if (daysCount === 5)
        workingTime =
          " - " +
          getFormattedTimeFromMinutes(mondayStartTime) +
          " to " +
          getFormattedTimeFromMinutes(mondayEndTime);
    }
    return workingTime;
  };

  const convertToPayload = (toUpdateWorkingHours, updatedWorkingHours) => {
    let weekDayConfig = {};
    for (let day in updatedWorkingHours) {
      if (updatedWorkingHours[day] !== "isUpdated") {
        if (updatedWorkingHours[day].isChecked) {
          let startTime = updatedWorkingHours[day].startTime || 0;
          let endTime = updatedWorkingHours[day].endTime || 0;
          let hours = [{ start: startTime, end: endTime }];
          weekDayConfig[day] = { hours: hours };
        }
      }
    }
    toUpdateWorkingHours.weekDayConfig = weekDayConfig;
    return toUpdateWorkingHours;
  };

  const applyAll = () => {
    let currentWorkingHours = Object.assign(workingHours, {});
    let mondayStartTime = currentWorkingHours["MO"].startTime;
    let mondayEndTime = currentWorkingHours["MO"].endTime;
    for (let day in currentWorkingHours) {
      if (
        currentWorkingHours[day] &&
        day !== "isUpdated" &&
        day !== "hoursType" &&
        currentWorkingHours[day].isChecked
      ) {
        if (
          (day === "SU" || day === "SA") &&
          currentWorkingHours.hoursType === HOURS_TYPE.weekdays
        )
          continue;
        currentWorkingHours[day].startTime = mondayStartTime;
        currentWorkingHours[day].endTime = mondayEndTime;
        currentWorkingHours[day].isChecked =
          currentWorkingHours["MO"].isChecked;
        setWorkingHours((prevState) => {
          return { ...prevState, [day]: currentWorkingHours[day] };
        });
      }
    }
    saveBusinessHours();
  };

  const hasStateChanged = () => {
    if (currentTimeZone !== timeZone) return true;
    for (let day in workingHours) {
      if (workingHours[day] && day !== "isUpdated" && weekDayConfig[day]) {
        if (
          workingHours[day].startTime !== weekDayConfig[day].startTime ||
          workingHours[day].endTime !== weekDayConfig[day].endTime ||
          workingHours[day].isChecked !== weekDayConfig[day].isChecked
        )
          return true;
      } else if (
        workingHours[day] !== "isUpdated" &&
        !weekDayConfig[day] &&
        workingHours[day].isChecked
      ) {
        return true;
      }
    }
    return false;
  };

  const getSaveButtonClass = () => {
    if (hasStateChanged()) return "cs-primary-btn";
    return "cs-disabled-btn";
  };

  const saveWorkingHours = () => {
    if (workingHours && hasStateChanged()) {
      let workingHoursPayload = getWorkingHoursFromState();
      if (workingHoursPayload.workingHoursId) {
        updateWorkingHours(
          workingHoursPayload.workingHoursFromState,
          workingHoursPayload.workingHoursId
        );
      } else {
        createWorkingHours(workingHoursPayload.workingHoursFromState);
      }
      dispatch(showVoicebox({ message: "Updating" }));
    }
  };

  const saveBusinessHours = (newTimezone) => {
    if (workingHours && props.isFromBusinessHoursPage && hasStateChanged()) {
      let businessHoursPayload = getWorkingHoursFromState(newTimezone);
      delete businessHoursPayload.userId;
      if (businessHoursPayload.workingHoursId) {
        updateBusinessHours(
          businessHoursPayload.workingHoursFromState,
          businessHoursPayload.workingHoursId
        );
      } else {
        createBusinessHours(businessHoursPayload.workingHoursFromState);
      }
      dispatch(showVoicebox({ message: "Updating" }));
    }
  };

  const getWorkingHoursFromState = (newTimezone) => {
    let toUpdateWorkingHours = {};
    let workingHoursId = "";
    if (
      workingHoursFromreducer.hoursList &&
      !workingHoursFromreducer.hoursList.length > 0
    ) {
      toUpdateWorkingHours.resourceId = projectId;
      toUpdateWorkingHours.userId = props.userId;
      toUpdateWorkingHours.accountId = props.accountId;
    } else {
      workingHoursId =
        workingHoursFromreducer.id || workingHoursFromreducer.hoursList[0].id;
      toUpdateWorkingHours.id = workingHoursId;
    }
    toUpdateWorkingHours.timezone = newTimezone || timeZone;
    toUpdateWorkingHours.roundTheClock = workingHours.hoursType
      ? workingHours.hoursType === HOURS_TYPE.allDays
      : false;
    if (!toUpdateWorkingHours.roundTheClock)
      toUpdateWorkingHours.hoursType = "WEEKDAY";

    let workingHoursFromState = convertToPayload(
      toUpdateWorkingHours,
      workingHours
    );
    let updatedWorkingHours = {};
    updatedWorkingHours.workingHoursFromState = workingHoursFromState;
    updatedWorkingHours.workingHoursId = workingHoursId;
    return updatedWorkingHours;
  };

  const handleCancel = () => {
    browserHistory.push(
      "/app/" + props.projectKey + "/settings/myprofile/workinghours"
    );
    setState();
  };

  const handleInputChange = (e) => {
    let allTimezones = Object.values(ctzc.getAllTimezones());
    let value = e.target.value || "";
    let selectedTimeozones = allTimezones.filter(
      (timzone) =>
        timzone.GMT.toLowerCase().includes(value) ||
        timzone.id.toLowerCase().includes(value)
    );
    setSearchValue(value);
    setTimezones(selectedTimeozones);
    setSelectedTimezoneRef(selectedTimeozones);
    setSearchIndex(0);
    setSearchRef(0);
  };

  if (props.isFromBusinessHoursPage) {
    return (
      <div>
        <div class="cs-widget-config cs-business-hrs">
          <div>
            <label>Time Zone</label>
            <p>
              Choose your time zone that will be applied to all event date and
              time
            </p>
          </div>
          <div
            className={
              "cs-dropdwn cs-dropdwn-search-txt " +
              (showTimezone ? " open" : "")
            }
          >
            <p onClick={toggleTimezone} data-type="timezone" ref={timezoneRef}>
              {timeZone}
            </p>
            <ArrowIcon />
            <div ref={timezoneDropDwnRef}>
              <div>
                <input
                  type="text"
                  placeholder="Search Time Zone"
                  value={searchValue}
                  onInput={handleInputChange}
                />
                <SearchIcon />
              </div>
              <ul>{getTimezoneComponent()}</ul>
            </div>
          </div>
        </div>
        <div class="cs-widget-config cs-business-hrs">
          <div>
            <label>Business Hours</label>
            <p>
              Set your business hours to let visitors know your availability
            </p>
          </div>
          <div className={"cs-dropdwn " + (showHoursType ? " open" : "")}>
            <p
              ref={hoursTypeDropDwnRef}
              onClick={toggleHoursType}
              data-type="hoursType"
            >
              {(workingHours.hoursType || "Weekdays") + getTiming()}
            </p>
            <ArrowIcon />
            <div ref={hourTypeRef}>
              <ul>{getHoursType()}</ul>
            </div>
          </div>
          <span onClick={() => setShowBusinessHours(!showBusinessHours)}>
            {!showBusinessHours ? "Show days" : "Hide days"}
          </span>
        </div>
        <section class="edit-workhours">
          <div
            class="cs-working-hours-form"
            style={
              showBusinessHours ? { display: "block" } : { display: "none" }
            }
          >
            <a
              href="javascript:void(0)"
              onClick={applyAll}
              style={
                showBusinessHours ? { display: "block" } : { display: "none" }
              }
            >
              Apply All
            </a>
            <ul>{getEditHoursComponent()}</ul>
          </div>
        </section>
      </div>
    );
  }
  return (
    <section class="edit-profile edit-workhours">
      <h3>Availability</h3>
      <p>Select workdays and hours</p>
      <div
        className={
          "cs-dropdwn cs-dropdwn-search-txt " + (showTimezone ? " open" : "")
        }
      >
        <label>Time Zone</label>
        <span>Choose your time zone</span>
        <p onClick={toggleTimezone} data-type="timezone" ref={timezoneRef}>
          {timeZone}
        </p>
        <ArrowIcon />
        <div ref={timezoneDropDwnRef}>
          <div>
            <input
              type="text"
              placeholder="Search Time Zone"
              value={searchValue}
              onInput={handleInputChange}
            />
            <SearchIcon />
          </div>
          <ul>{getTimezoneComponent()}</ul>
        </div>
      </div>
      <div className={"cs-dropdwn " + (showHoursType ? " open" : "")}>
        <label>Work Hours</label>
        <span>
          Visible to your team - this lets them know your availability
        </span>
        <p
          ref={hoursTypeDropDwnRef}
          onClick={toggleHoursType}
          data-type="hoursType"
        >
          {(workingHours.hoursType || "Weekdays") + getTiming()}
        </p>
        <ArrowIcon />
        <div ref={hourTypeRef}>
          <ul>{getHoursType()}</ul>
        </div>
      </div>
      <div class="cs-working-hours-form">
        <a href="javascript:void(0)" onClick={applyAll}>
          Apply All
        </a>
        <ul>{getEditHoursComponent(workingHours)}</ul>
      </div>
      <div class="input-form-button bottom-space">
        <button class="cs-btn cs-decline-btn" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className={"cs-btn " + getSaveButtonClass()}
          onClick={saveWorkingHours}
        >
          Save
        </button>
      </div>
    </section>
  );
};
export default EditWorkingHoursComponent;
