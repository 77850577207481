import { store } from "../app";
import { spawnDesktopNotification, unescapeHTML } from "./Utility";
import {
  NOTIFICATION_DEFAULT_OPTIONS,
  NEW_CONVERSATION_NOTIFIER,
  NEW_MESSAGE_NOTIFIER,
  NEW_ASSIGNMENT_NOTIFIER,
  NEW_MENTION_NOTIFIER,
} from "./Constants";
import { browserHistory } from "react-router";
import { hidePopup } from "../actions/PopupAction";
import {
  showNotification,
  hideNotification,
} from "../actions/NotificationAction";

class DesktopNotificationMiddleware {
  triggerShowNotification() {
    store.dispatch(showNotification());
  }
  processNotification(action) {
    //return;
    if (
      action.type == "ON_MESSAGE" &&
      store.getState().UserReducer.data &&
      !store.getState().UserReducer.data.originalId
    ) {
      console.log("action is", action);
      console.log("Inside DesktopNotification", action);
      if (
        !store.getState().UserReducer.data ||
        !store.getState().NotificationSettingsReducer.isFetched
      )
        return;
      const notificationSettings = store.getState().NotificationSettingsReducer
        .data;
      const userId = store.getState().UserReducer.data.id;
      const loginId = store.getState().UserReducer.data.login;
      console.log("UserId", store.getState().UserReducer.data);
      console.log("Notificationsettings", notificationSettings);
      const message = action.message;

      // if(store.getState().ChatConfigurationReducer && store.getState().ChatConfigurationReducer.data[message.projectId])
      // {
      // 	let chatConfig = store.getState().ChatConfigurationReducer.data[message.projectId];
      // 	 if(chatConfig.isExcludeStaffEnabled && chatConfig.excludeStaffIds.indexOf(loginId) >= 0)
      // 	 {
      // 		console.log("Excluded as notification is disabled for this user")
      // 		return;
      // 	 }
      // }

      if (
        (message.type == "CHAT_AUTO_ASSIGN_AVAILABLE" || message.type == "CHAT_ASSIGN") &&
        store.getState().UserReducer.data.id == message.actionTo
      ) {
        let conversationId = message.conversationId;
        let projectId = message.projectId;
        let notificationOptions = Object.assign(
          {},
          NOTIFICATION_DEFAULT_OPTIONS,
          "New Conversaiton"
        );

        if (
          !browserHistory
            .getCurrentLocation()
            .pathname.includes(message.actionTo)
        ) {
          browserHistory.push({
            pathname:
              "/app/" +
              projectId.replace("LS-", "") +
              "/chat/dashboard/D/" +
              "O" +
              "/" +
              "N" +
              "/" +
              message.actionTo +
              "/" +
              conversationId,
          });
        }
        let onClickHandler = () => {
          if (store.getState().PopupReducer.popupProps.display == true)
            store.dispatch(hidePopup());
          browserHistory.push({
            pathname:
              "/app/" +
              projectId.replace("LS-", "") +
              "/chat/dashboard/D/" +
              "O" +
              "/" +
              "N" +
              "/" +
              userId +
              "/" +
              conversationId,
          });
        };

        spawnDesktopNotification(
          NEW_CONVERSATION_NOTIFIER.TITLE,
          notificationOptions,
          onClickHandler,
          NEW_CONVERSATION_NOTIFIER.TIMEOUT,
          NEW_CONVERSATION_NOTIFIER.TONEPATH,
          message.key,
          message.projectId
        );
        this.triggerShowNotification();
      }

      if (
        message.type == "CHAT" ||
        message.type == "ATTACHMENT" ||
        message.type == "VISITOR_INITIATED_CONVERSATION" ||
        message.type === "CHAT_ANSWERING_MESSAGE"
      ) {
        const converstationId = message.conversationId;
        const assignedTo = message.assignedTo;
        const projectId = message.projectId;
        let displayMessage = message.message;

        if (message.type == "ATTACHMENT") {
          try {
            let messageObj = JSON.parse(message.message);
            displayMessage = messageObj.fileName;
          } catch (ex) {
            console.log("failed parsing to json for notification", ex);
          }
        }

        if (message.type == "VISITOR_INITIATED_CONVERSATION")
          displayMessage = "Visitor has initiated a conversation";

        let notificationOptions = Object.assign(
          {},
          NOTIFICATION_DEFAULT_OPTIONS,
          { body: unescapeHTML(displayMessage) }
        );
        let onClickHandler = () => {
          if (store.getState().PopupReducer.popupProps.display == true)
            store.dispatch(hidePopup());
          browserHistory.push({
            pathname:
              "/app/" +
              projectId.replace("LS-", "") +
              "/chat/dashboard/D/" +
              "O" +
              "/" +
              "N" +
              "/" +
              userId +
              "/" +
              converstationId,
          });
        };

        if (
          message.type === "CHAT_ANSWERING_MESSAGE" &&
          notificationSettings.conversations == "ACTIVE" &&
          message.agentDetails.contactId === userId
        ) {
          spawnDesktopNotification(
            NEW_CONVERSATION_NOTIFIER.TITLE,
            notificationOptions,
            onClickHandler,
            NEW_CONVERSATION_NOTIFIER.TIMEOUT,
            NEW_CONVERSATION_NOTIFIER.TONEPATH,
            message.key,
            message.projectId
          );
          this.triggerShowNotification();
        } else if (
          assignedTo == userId &&
          notificationSettings.messages == "ACTIVE"
        ) {
          spawnDesktopNotification(
            NEW_MESSAGE_NOTIFIER.TITLE,
            notificationOptions,
            onClickHandler,
            NEW_MESSAGE_NOTIFIER.TIMEOUT,
            NEW_MESSAGE_NOTIFIER.TONEPATH,
            message.key,
            message.projectId
          );
          this.triggerShowNotification();
        }
      } else if (
        notificationSettings.assignment == "ACTIVE" &&
        message.type == "CHAT_ASSIGN"
      ) {
        //NOTIFY for assignment message conversation
        const converstationId = message.conversationId;
        const assignedTo = message.actionTo;
        const projectId = message.projectId;
        if (assignedTo != userId || assignedTo == message.senderId) return;

        let notificationOptions = Object.assign(
          {},
          NOTIFICATION_DEFAULT_OPTIONS,
          { body: "New Chat Assigned" }
        );
        let onClickHandler = () => {
          if (store.getState().PopupReducer.popupProps.display == true)
            store.dispatch(hidePopup());
          browserHistory.push({
            pathname:
              "/app/" +
              projectId.replace("LS-", "") +
              "/chat/dashboard/D/" +
              "O" +
              "/" +
              "N" +
              "/" +
              userId +
              "/" +
              converstationId,
          });
        };
        spawnDesktopNotification(
          NEW_ASSIGNMENT_NOTIFIER.TITLE,
          notificationOptions,
          onClickHandler,
          NEW_ASSIGNMENT_NOTIFIER.TIMEOUT,
          NEW_ASSIGNMENT_NOTIFIER.TONEPATH,
          message.key,
          message.projectId
        );
        this.triggerShowNotification();
      } else if (
        notificationSettings.onMention == "ACTIVE" &&
        message.type == "NOTES"
      ) {
        const converstationId = message.conversationId;
        const projectId = message.projectId;
        const mentionedStaffs = message.mentionedStaffs
          ? message.mentionedStaffs
          : [];
        console.log("mentioned staff:", mentionedStaffs);
        if (!mentionedStaffs.includes(userId)) return;

        let notificationOptions = Object.assign(
          {},
          NOTIFICATION_DEFAULT_OPTIONS,
          { body: "New Chat Mention" }
        );
        let onClickHandler = () => {
          if (store.getState().PopupReducer.popupProps.display == true)
            store.dispatch(hidePopup());
          browserHistory.push({
            pathname:
              "/app/" +
              projectId.replace("LS-", "") +
              "/chat/dashboard/D/" +
              "O" +
              "/" +
              "N" +
              "/" +
              userId +
              "/" +
              converstationId,
          });
        };
        spawnDesktopNotification(
          NEW_MENTION_NOTIFIER.TITLE,
          notificationOptions,
          onClickHandler,
          NEW_MENTION_NOTIFIER.TIMEOUT,
          NEW_MENTION_NOTIFIER.TONEPATH,
          message.key,
          message.projectId
        );
        this.triggerShowNotification();
      }
    }
  }
}

export default new DesktopNotificationMiddleware();
