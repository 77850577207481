import { call, put, fork } from "redux-saga/effects";
import cookie from "react-cookie";
import axios from "axios";
import * as Ajax from "../xhr/XhrRequestHandler";
import { loginResponse, authFailed } from "../actions/LoginAction";
import {
  CLIENT_LOGIN_REFERENCE,
  STAGING_LOGOUT_URL,
  LIVE_LOGOUT_URL,
  PLAN_ID,
  USER_ID,
  EMAIL_ID_KEY,
  USER_ID_KEY,
} from "../commons/Constants.js";
import * as Utils from "../commons/Utility.js";
import { fetchUser } from "./UserSaga";
import { getProjectList } from "../settings/sagas/ProjectSaga";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { browserHistory } from "react-router";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import sha1 from "sha1";

export function* doUserLogin(loginObj) {
  const url = "/user/authenticate";

  try {
    let loginResp = yield call(Ajax.login, url, loginObj.login);
    loginResp = loginResp.data;
    //To config auth header for all xhr request on login success
    cookie.save(CLIENT_LOGIN_REFERENCE, "true", { path: "/" });

    if (loginResp.data["isInvitationAccepted"] === "true") {
      browserHistory.push({ search: "" });
    }
    console.error("Authenticating user");
    //To fetch user info
    yield call(fetchUser, {});
    yield call(getProjectList, {});

    yield put(loginResponse());
    yield put(VoiceboxActions.hideVoicebox());
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doUserLogout(action) {
  const url = "/user/logout";
  yield put(
    VoiceboxActions.showVoicebox({
      message: "Logging out",
      showFullScreenLoader: true,
    })
  );
  const cacheUrl = "/user/clearAdminSkill";
  yield call(Ajax.get, cacheUrl);
  if (!Utils.iswebapp()) yield call(Ajax.getUnauthenticated, url);
  cookie.remove(CLIENT_LOGIN_REFERENCE, { path: "/" });
  //To config auth header for all xhr request on login success
  let logoutUrl =
    Utils.getAppMode() === "live" ? LIVE_LOGOUT_URL : STAGING_LOGOUT_URL;
  console.log("Logout URL:", logoutUrl);
  window.location.href = logoutUrl + Utils.getOrigin() + "/login";
}

export function* doRequestSession(requestCode) {
  const url = "/user/authcode";
  console.error("Requesting session");
  try {
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Loading",
        showFullScreenLoader: true,
      })
    );
    let code = requestCode.credential.code;
    let state = requestCode.credential.state
      ? requestCode.credential.state
      : undefined;
    delete requestCode.credential.state;
    let loginResp = yield call(Ajax.login, url, requestCode.credential);
    loginResp = loginResp.data;
    Utils.checkAndStorePlanIdWithKeyOfRespectiveUserId(
      loginResp.data[USER_ID],
      loginResp.data[PLAN_ID]
    );
    Utils.checkAndStoreValueInLocalStorage(
      EMAIL_ID_KEY,
      loginResp.data[EMAIL_ID_KEY]
    );
    Utils.checkAndStoreValueInLocalStorage(
      USER_ID_KEY,
      loginResp.data[USER_ID]
    );
    if(loginResp.data["isNewWidget"] === "true"){
      console.log("Sending impact Tracking Status " , loginResp.data); 
      let {userId , login } = loginResp.data;
      if(window.ire){
        window.ire('identify', {customerId: userId, customerEmail: sha1(login)});
      }      
      Utils.sendImpactTrackingForTrailSubscription(loginResp.data);
    }
    
    //To config auth header for all xhr request on login success
    cookie.save(CLIENT_LOGIN_REFERENCE, "true", { path: "/" });
    if (loginResp.data["isInvitationAccepted"] === "true") {
      browserHistory.push({ search: "" });
    }
    let widgetId = loginResp.data["widgetId"];
    if (widgetId != undefined)
      widgetId = widgetId.replace("LS-", "");
    if (loginResp.data["isNewWidget"] === "true")
      window.location.href =
        Utils.getOrigin() + `/session-step-2?widgetId=${widgetId}&code=${code}&userId=${loginResp.data["userId"]}`;
    yield call(fetchUser, {});
    yield call(getProjectList, {});
    yield put(loginResponse(loginResp.data));
    if (state) {
      if (state.source != "wordpress" && state.source != "joomla" && state.source != "shopify")
        browserHistory.push({ query: state });
    }
  } catch (e) {
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}
