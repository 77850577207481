import React, { Component } from "react";

class NewMessagesNotifier extends Component {

  getNewMessageCount(){
    let count = this.props.getNewMessages().length || 0;
    return count ? <div onClick={this.props.scrollToMessage} class="chat-unread">{count}</div> : "";
  }
  render() {
    // let { newMessageInfo } = this.props;
    // let message = "";
    // let messageIds = newMessageInfo.messageIds;
    // let count = messageIds ? messageIds.length : 0;

    // if (!count) return "";

    // if (count && count == 1) {
    //   message = "New Message";
    // } else if (count && count > 1) {
    //   message = "New Messages";
    // }

    return (this.getNewMessageCount())
    // return (
    //   <div
    //     className={"new-message-voicebox db"}
    //   >
    //     <p className="new-messages">{"2" + " " + "message"} </p>
    //   </div>
    // );
  }
}

export default NewMessagesNotifier;
