import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import svg from 'src/assets/preview.svg'

export const Container = styled.div<{ maxWidth?: number }>`
  background-image: url(${svg});
  display: grid;
  width: 100%;
  max-width: 60rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  & > * {
    grid-area: 1/1;
  }

  ${is('maxWidth')`
    max-width: ${R.prop('maxWidth')}rem;
  `}
`

export const Preview = styled.div<{ imageUrl: string }>`
  margin-top: 7.8%;
  width: 100%;
  height: 95.5%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${R.prop('imageUrl')});
`

export const IconContainer = styled.div<{
  topLeft?: boolean
  topRight?: boolean
  bottomLeft?: boolean
  bottomRight?: boolean
}>`
  position: absolute;

  ${is('topLeft')`
    top: 3.8rem;
    left: 1.3rem;
  `}

  ${is('topRight')`
    top: 3.8rem;
    right: 1.3rem;
  `}

  ${is('bottomLeft')`
    bottom: 1.4rem;
    left: 1.3rem;
  `}

  ${is('bottomRight')`
    bottom: 1.4rem;
    right: 1.3rem;
  `}
`

export const BubbleContainer = styled.div`
  position: relative;
`

export const BubbleContent = styled.div<{
  isPositionedLeft: boolean
  isPositionedRight: boolean
}>`
  position: absolute;
  width: 8.8rem;

  ${is('isPositionedLeft')`
    top: 0.55rem;
    left: -0.4rem;
    transform: translateX(-100%);
  `}

  ${is('isPositionedRight')`
    top: 0.55rem;
    right: -0.4rem;
    transform: translateX(100%);
  `}
`
