import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Icon } from 'src/features/ui/components/Icon'
import { Text1 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div<{
  isOpen: boolean
  hasBottomBorder?: boolean
}>`
  display: flex;
  border-radius: 0.9rem;
  box-sizing: border-box;
  box-shadow: 0 0.7rem 0.8rem rgba(22, 45, 61, 0.08);
  flex-direction: column;
  border: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  padding: 1.6rem;

  ${mq.sm} {
    border: none;
    box-shadow: none;
    padding: 0;
  }

  ${is('isOpen')`
    border-color: ${R.path(['theme', 'color', 'dodgerBlue'])};

    ${mq.md} {
      ${is('hasBottomBorder')`
        border-bottom: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
        border-radius: 0;
      `}
    }
  `}

  & + & {
    margin-top: 2.4rem;

    ${mq.md} {
      margin-top: 0;
    }
  }
`

export const Header = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: none;

  ${mq.sm} {
    display: none;
  }
`

export const TitleIcon = styled(Icon)``

export const Title = styled(Text1)`
  margin-left: 1.6rem;
  font-weight: 700;
  color: ${R.path(['theme', 'color', 'dodgerBlue'])};
`

export const Content = styled.div`
  padding-top: 2.8rem;

  ${mq.md} {
    padding-bottom: 4rem;
  }
`
