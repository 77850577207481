import * as actions from 'src/features/returningFlow/store/actions'
import {
  IReturningFlowState,
  IStep,
  StepViewType,
} from 'src/features/returningFlow/store/types'
import { updateAtIndex } from 'src/utils/state/operators'

export const initialState: IReturningFlowState = {
  createdAt: undefined,
  currentStepIndex: 1,
  steps: [
    {
      name: 'Account created',
      wasSkipped: false,
      viewType: StepViewType.confirmation,
    },
    {
      name: 'Widget installed',
      wasSkipped: false,
      viewType: StepViewType.content,
    },
    {
      name: 'Widget personalized',
      wasSkipped: false,
      viewType: StepViewType.preview,
    },
  ],
}

export const returningFlowReducer = (
  state: IReturningFlowState = initialState,
  action: actions.IReturningFlowActions
) => {
  switch (action.type) {
    case actions.NEXT_STEP:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
        steps: updateAtIndex<IStep>(state.currentStepIndex, {
          viewType: StepViewType.confirmation,
        })(state.steps),
      }
    case actions.SHOW_STEP:
      return {
        ...state,
        steps: updateAtIndex<IStep>(state.currentStepIndex, {
          viewType: StepViewType.content,
        })(state.steps),
      }
    case actions.SKIP_STEP:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
        steps: updateAtIndex<IStep>(state.currentStepIndex, {
          wasSkipped: true,
          viewType: StepViewType.confirmation,
        })(state.steps),
      }
    case actions.SET_CREATED_AT:
      return {
        ...state,
        createdAt: action.payload.createdAt,
      }
    case actions.INITIALIZE:
      return {
        ...state,
        createdAt: action.payload.createdAt,
        currentStepIndex: action.payload.currentStepIndex,
        steps: action.payload.steps,
      }
    default:
      return state
  }
}
