import React from "react";
import {ReactDOM} from "react-dom";
import {createRoot} from "react-dom/client";
import { Provider } from "react-redux";
import { Router, browserHistory } from "react-router";
import Routes from "./Routes";
import ConfigureStore from "./store/ConfigureStore";
import NotificationsContainer from "./containers/NotificationsContainer";
import UpgradePopup from './commons/UpgradePopup.jsx';
import VoiceboxContainer from "./containers/VoiceboxContainer";
import PopupContainer from "./containers/PopupContainer";
import ConfirmPopup from "./components/common/ConfirmPopup.jsx";
import NewProjectContainer from "./containers/NewProjectContainer";
import {
  getAppMode,
  isOverflowConnection,
  isAwConnection,
  sendCloseSignal,
  searchToObject,
  checkAndAddBabelPolyfil,
  injectBillingStyle
} from "./commons/Utility.js";
import ErrorBoundary from "./containers/ErrorBoundary";
import {
  initGA,
  PageView,
  Event,
} from './commons/EventsTrackingGoogleAnalytics.js'
import ReactTooltip from 'react-tooltip'

import '../../css/style.css'
import '../../css/reset.css'
import '../../css/react-datepicker.css'
import '../../css/new-style.css'
import '../../css/cs-styleguide.css'
import '../../css/codemirror.css'
import '../../css/material.css'
import '../../css/plugin.css'
import '../../css/flatpicker.css'
import '../../css/flag.css'
import "../../../node_modules/@adaptavant/billing-component/dist/css/billing.css";
import SettingsWrapper from './SettingWrapper.jsx'
import SettingsComponent from './settings_component_helper.js'
initGA()
PageView()

checkAndAddBabelPolyfil()


const initListener = () => {
  console.log("Event Registered");
  window.addEventListener("ElectronMessage", function( options ){
    console.log("Event triggered :" , options);
    let { keyCode } = options.detail;
    if(keyCode === 119)
      sendCloseSignal();
  });

}

//To share the store with SocketConnection
export const store = ConfigureStore()

function widgetConfigurationComponent({ id, routePath }) {
  const element = document.getElementById(id)
  if (element) {
    const root = createRoot(element);
    root.render(
      <Provider store={store}>
        <ErrorBoundary>
          <UpgradePopup />
          <NotificationsContainer />
          <PopupContainer />
          <NewProjectContainer />
          <VoiceboxContainer />
          <ConfirmPopup />
          <ReactTooltip
            delayHide={500}
            effect="solid"
            place="top"
            class="tooltip"
          />
          <SettingsWrapper parentRoute={routePath} />
        </ErrorBoundary>
      </Provider>
    )
  }
}

function renderSettingsComponentWithAuthentication({
  userAccessToken,
  id,
  routePath,
  mode
}) {
  SettingsComponent.renderSettingsComponent({
    userAccessToken,
    id,
    routePath,
    widgetConfigurationComponent,
    mode
  })
}

if (!process.env.isSettingsComponent && !process.env.isAWRecentComponent) {
    const element = document.getElementById("root");
    const root = createRoot(element);
    root.render(
    <Provider store={store}>
      <ErrorBoundary>
        <Router history={browserHistory} routes={Routes}></Router>
        {/* <UpgradeNotifier/> */}
        <UpgradePopup />
        <NotificationsContainer />
        <PopupContainer />
        <NewProjectContainer />
        <VoiceboxContainer />
        <ConfirmPopup />
        <ReactTooltip
          delayHide={500}
          effect="solid"
          place="top"
          class="tooltip"
        />
      </ErrorBoundary>
    </Provider>
  )
} else {
  window.ChatSupportApp = {
    initWidgetConfiguration: renderSettingsComponentWithAuthentication,
  }
}



initListener();

// To include LiveSupport script
const appMode = getAppMode() == "live" ? "live" : "staging";
let query = searchToObject();
let isDemoPage = document.location.pathname.includes("preview");
if (!isDemoPage) {
  let projectId = appMode == 'live' ? 'LS-a0d3fcb1' : 'LS-3ebd154d'
  let scriptSrc =
    appMode == "live"
      ? "//livesupport-app.appspot.com/client/get/script/"
      : "//staging-live-support.appspot.com/client/get/script/";

  if (
    (appMode == "live" || appMode == "staging") &&
    !isOverflowConnection() &&
    ((!isAwConnection() && !query.isFullscreen) || 
    (!isAwConnection() && !process.env.isSettingsComponent))
    && !process.env.isAWRecentComponent
  ) {
    (function (a, b, c, d, e, f, g) {
      c[d] =
        c[d] ||
        function () {
          (c[d].q = c[d].q || []).push(arguments);
        };
      c["_lsAlias"] = c[d];
      e = a.createElement(b);
      e.type = "text/javascript";
      e.async = true;
      e.src = scriptSrc + projectId;
      f = function () {
        g = a.getElementsByTagName(b)[0];
        g.parentNode.insertBefore(e, g);
      };
      c.addEventListener("load", f);
    })(document, "script", window, "_ls");

    _ls("init", { projectId: projectId });
  }
}

if(!process.env.isAWRecentComponent) {
  const cwaIntegrationScript = document.createElement('script');
  if(appMode == "live")
    cwaIntegrationScript.src = 'https://live-cwa.appspot.com/source/v2.0/common/integration-component/setup.js';
  else
    cwaIntegrationScript.src = 'https://stagingclientwebaccess-hrd.appspot.com/source/v2.0/common/integration-component/setup.js';
  document.body.appendChild(cwaIntegrationScript);
  cwaIntegrationScript.onload = () => {
    console.log("cwa script loaded")
  };

  injectBillingStyle();
}
  