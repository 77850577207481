import { INPUT_TEXT_LIMIT } from "../../commons/Constants";
import { isValidName } from "../../commons/Utility";
import { isEmpty } from "../../commons/Validators";

export function userNameErrorHandler(text) {
  if(isEmpty(text) || !isValidName(text))
    return 'Enter a valid Full name';
  if(text.length > INPUT_TEXT_LIMIT.userName)
    return "Enter up to 64 characters";
  return ''
}

export function designationErrorHandler(text) {
  if(text.length > INPUT_TEXT_LIMIT.designation)
    return "Enter a valid Designation";
  return '';
}