import React, { Component } from "react";
import ReactDOM from "react-dom";
import { getDisplayTime } from "../commons/Utility.js";

class TimeAgo extends Component {
  constructor(props) {
    super(props);
    this.state = { lastSeenTime: "" };
    this.timeInterval = "";
    this.timeOut = "";
    this.setIntervalForTime = this.setIntervalForTime.bind(this);
    this.setTimeoutForTime = this.setTimeoutForTime.bind(this);
  }

  componentWillMount() {
    if (this.props.lastSeenTime)
      this.setTimeoutForTime(this.props.lastSeenTime);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lastSeenTime) this.setTimeoutForTime(nextProps.lastSeenTime);
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
    clearTimeout(this.timeOut);
  }

  setTimeoutForTime(timeStamp) {
    clearInterval(this.timeInterval);
    clearTimeout(this.timeOut);
    let displayTimeObject = getDisplayTime(timeStamp, false);
    this.setState({
      ...this.state,
      displayTime: displayTimeObject.displayTime,
    });
    this.timeOut = setTimeout(() => {
      displayTimeObject = getDisplayTime(timeStamp, true);
      this.setState(
        { ...this.state, displayTime: displayTimeObject.displayTime },
        () => {
          this.setIntervalForTime(timeStamp, displayTimeObject.timeout);
        }
      );
    }, displayTimeObject.timeout);
  }

  setIntervalForTime(timeStamp, timeInterval) {
    clearInterval(this.timeInterval);
    clearTimeout(this.timeOut);
    this.timeInterval = setInterval(() => {
      let displayTimeObject = getDisplayTime(timeStamp, true);
      if (timeInterval < displayTimeObject.timeout)
        this.setIntervalForTime(timeStamp, displayTimeObject.timeout);
      this.setState({
        ...this.state,
        displayTime: displayTimeObject.displayTime,
      });
    }, timeInterval);
  }
  render() {
    let Tag = this.props.tag ? this.props.tag : "span";
    let style = this.props.style ? this.props.style : {};
    let prefix = this.props.prefix ? this.props.prefix : "";
    return (
      <Tag style={style}>
        {prefix + (this.state.displayTime ? this.state.displayTime : "")}
      </Tag>
    );
  }
}

export default TimeAgo;
