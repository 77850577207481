import * as ConnectionAction from "../actions/MessageAction.js";
console.log("the location is in connection middle ware :", document.location);

export const action = (connection, rtmEvent) => {
  switch (rtmEvent.action) {
    case ConnectionAction.INIT: {
      connection.initialize(rtmEvent.isOverflowConversation);
      return true;
    }
    case ConnectionAction.SEND_MESSAGE: {
      connection.sendMessage(rtmEvent.message, rtmEvent.meta);
      return true;
    }
    case ConnectionAction.CLOSE_CONNECTION: {
      connection.closeConnection();
      return true;
    }
    case ConnectionAction.DESTROY_RTMSERVER: {
      connection.destroy();
      return true;
    }
    case ConnectionAction.RECONNECT: {
      connection.reconnect();
      return true;
    }
  }
  return false;
};
