import React, { FC } from 'react'
import {
  Container,
  Divider,
} from 'src/features/returningFlow/components/Progress/Section/styled'
import { IProps } from 'src/features/returningFlow/components/Progress/Section/types'

export const Section: FC<IProps> = ({ children, completed }) => (
  <Container completed={completed}>
    {children}
    {completed && <Divider />}
  </Container>
)
