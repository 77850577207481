import React from "react";
import { useSelector } from "react-redux";
import ChatQuickPreview from "./ChatQuickPreview.jsx";
import AnnouncementQuickPreview from "./AnnouncementQuickPreview.jsx";
import EmailQuickPreview from "./EmailQuickPreview.jsx";

const CampaignQuickPreview = (props) => {
  const { campaignDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
  }));

  const getQuickPreview = () => {
    let { campaignType } = campaignDetails;
    let quickPreview;
    if (campaignType == "CHAT")
      quickPreview = (
        <ChatQuickPreview
          color={props.color}
          agentName={props.agentName}
          agentImage={props.agentImage}
          randomStaffData={props.randomStaffData}
          showFallbackValues={props.showFallbackValues}
          params={props.params}
        />
      );
    else if (campaignType == "ANNOUNCEMENT")
      quickPreview = (
        <AnnouncementQuickPreview
          color={props.color}
          agentName={props.agentName}
          agentImage={props.agentImage}
          randomStaffData={props.randomStaffData}
          showFallbackValues={props.showFallbackValues}
          params={props.params}
        />
      );
    else if (campaignType === "EMAIL")
      quickPreview = (
        <EmailQuickPreview
          agentName={props.agentName}
          randomStaffData={props.randomStaffData}
          showFallbackValues={props.showFallbackValues}
        />
      );
    return quickPreview;
  };

  // const getWrapperClassName = () => {
  //     let {campaignType} = campaignDetails;
  //     let className = "cs-campaign-preview quick-preview";
  //     if(campaignType=='ANNOUNCEMENT')
  //         className = className + ' cs-announcment-preview desktop';
  //     return className;
  // }

  return <section>{getQuickPreview()}</section>;
};

export default CampaignQuickPreview;
