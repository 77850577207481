import React from "react";
import { PlantIcon } from "../../../app/commons/Icons.js";

const NoVisitorPlaceHolder = (props) => {
  return (
    <section className="cs-no-visitor">
      <PlantIcon />
      <strong>You’re all caught up!</strong>
      <p>{!props.isConversationQueue ? "Start a chat when a new visitor lands on your website" : "You have no active chats"}</p>
    </section>
  );
};

export default NoVisitorPlaceHolder;
