import React, { useState, useEffect, useRef } from "react";
import { getURLRestrictionContentConfig } from "../../commons/Utility.js";
import WhiteBlackListPopup from './whiteblacklistiPopup.jsx'

// import { PlantIcon } from "../../../app/commons/Icons.js";

const WhiteBlackList = (props) => {

  const [componentType , setComponentType] = useState("");
  const popupRef = useRef(null);
  const {getDomainsByType , hasLocalStateChanged , allowedUrlListCount , blockedUrlsListCount } = props;
  let {blacklistedUrls , whitelistedUrls , handleEnter , addUrlsToMap , removeDomainFromList, resetUrls , getPreviousUrl} = props;
  const [showPopup , setShowPopup] = useState(false);
  console.log("the value i got here second level :" , whitelistedUrls );


useEffect(() => {
  document.addEventListener("keydown", handleKeyDown);
  return () => {
    document.removeEventListener("keydown", handleKeyDown);
  };
});

  const getCountString = (count) => {
      if(count === 1 ) return `${count} URL`
      else if(count > 1) return `${count} URLs`
  }

  const getCountComponent = (urlType ) => {
    
    if(urlType === 'whitelist' && whitelistedUrls && allowedUrlListCount > 0){
      return <span className="col-txt">{`${getCountString(allowedUrlListCount)} allowed`}</span>
    } else if(urlType === 'blacklist' &&  blacklistedUrls && blockedUrlsListCount > 0) {
      return <span className="col-txt">{`${getCountString(blockedUrlsListCount)} excluded`}</span>
    } else {
      return "";
    }
  }
  
  const getConfig = () => {
    return getURLRestrictionContentConfig(componentType);
  }

  const getPopupComponent = () => {
    return showPopup ? <WhiteBlackListPopup getPreviousUrl={getPreviousUrl} hasLocalStateChanged={hasLocalStateChanged} getDomainsByType={getDomainsByType} popupRef={popupRef} resetUrls={resetUrls} hidePopup={setShowPopup} removeDomainFromList={removeDomainFromList} addUrlsToMap={addUrlsToMap} popupType={componentType} handleEnter={handleEnter} hideURLConfigPopup={hideURLConfigPopup} getUrls={getUrls} getConfig={getConfig} popupType={componentType}/> : "";
  }

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      if(popupRef.current){
        setShowPopup(false);
        resetUrls();
      }
    }
  };

  const showURLConfigPopup = (event) => {
     let {type} = event.target.dataset;
      setComponentType(type);
      setShowPopup(true);
  }

  const hideURLConfigPopup = () => {
    setShowPopup(false);
  }

  const getUrls = () => {
    if(componentType === 'allowlist')
      return whitelistedUrls;
    else if(componentType === 'blocklist')
      return blacklistedUrls;
    else 
      return []
  }

  return (
    <section>
      <div className="cs-form-col">
        <div className="col">
          <label>Allowlist</label>
          <p>URLs of the pages where the widget<br/>should appear.</p>
        </div>
        {getCountComponent("whitelist")}
        <a data-type="allowlist" href="javascript:void(0)" className="col-link" onClick={showURLConfigPopup}>Configure</a>
      </div>
      <div className="cs-form-col">
          <div className="col">
            <label>Excludelist</label>
            <p>URLs of the pages where the widget<br/>should be hidden.</p>
          </div>
          {getCountComponent("blacklist")}
          <a data-type="blocklist" href="javascript:void(0)" className="col-link" onClick={showURLConfigPopup}>Configure</a>
      </div>
      {getPopupComponent()}
    </section>
  );
};

export default WhiteBlackList;
