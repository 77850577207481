export const QUEUE_CONVERSATION_UPDATE = "QUEUE_CONVERSATION_UPDATE";
export const QUEUE_CONVERSATION_REMOVE = "QUEUE_CONVERSATION_REMOVE";
export const QUEUE_CONVERSATION_REQUEST = "QUEUE_CONVERSATION_REQUEST";
export const QUEUE_CONVERSATION_RESPONSE = "QUEUE_CONVERSATION_RESPONSE";

export const queueConversationRequest = () => {
  return {
    type: QUEUE_CONVERSATION_REQUEST,
  };
};

export const queueConversationResponse = (conversationObject) => {
  return {
    type: QUEUE_CONVERSATION_RESPONSE,
    conversationObject,
  };
};

export const queueConversationRemove = (conversationId) => {
  return {
    type: QUEUE_CONVERSATION_REMOVE,
    conversationId,
  };
};
