import {
  CHAT_CONFIGURATION_REQUESTED,
  CHAT_CONFIGURATION_RECEIVED,
  CHAT_CONFIGURATION_FETCH_FAILED,
  UPDATE_CHAT_CONFIG_REQUEST,
  UPDATE_CHAT_CONFIG_RESPONSE,
  UPDATE_CHAT_CONFIG_FAILED,
  ADD_CONTAINER_INFO,
  CHAT_CONFIGURATION_LIST_RESPONSE,
} from "../actions/ChatConfigurationAction.js";

export const initialState = {
  isUpdating: false,
  isFetching: false,
  isFetched: false,
  data: {},
  error: "",
  success: false,
};

const ChatConfigurationReducer = (
  state = {
    isUpdating: false,
    isFetching: false,
    isFetched: false,
    data: {},
    error: "",
    success: false,
    containerInfo: {},
  },
  action
) => {
  switch (action.type) {
    case CHAT_CONFIGURATION_REQUESTED:
      //State will not be mutated. Always a new copy of 'state' will be created
      //Ref: { …state, isFetching: true } evaluates to Object.assign( {}, state, { isFetching: true } ).
      return {
        ...state,
        isFetching: true,
      };

    case CHAT_CONFIGURATION_RECEIVED:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        success: true,
        fetchedAt: Date.now(),
        data: Object.assign({}, action.chatConfiguration),
      };
    case CHAT_CONFIGURATION_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        success: false,
        error: "Error occured while fetching chat configuration!",
      };
    case UPDATE_CHAT_CONFIG_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_CHAT_CONFIG_RESPONSE:
      state.data[action.chatConfiguration.key] = action.chatConfiguration;
      return {
        ...state,
        isUpdating: false,
        data: state.data,
      };
    case UPDATE_CHAT_CONFIG_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case ADD_CONTAINER_INFO:
      return {
        ...state,
        containerInfo: action.containerInfo,
      };
    case CHAT_CONFIGURATION_LIST_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        isFetched: true,
        success: true,
        fetchedAt: Date.now(),
        data: action.configurationResponse,
      };
    default:
      return state;
  }
};
export default ChatConfigurationReducer;
