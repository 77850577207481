export const PROJECTLIST_REQUESTED = "PROJECTLIST_REQUESTED";
export const PROJECTLIST_RESPONSE = "PROJECTLIST_RESPONSE";
export const PROJECTLIST_FAILED = "PROJECTLIST_FAILED";
export const NEW_PROJECTLIST_REQUESTED = "NEW_PROJECTLIST_REQUESTED";
export const NEW_PROJECTLIST_RESPONSE = "NEW_PROJECTLIST_RESPONSE";
export const NEW_PROJECTLIST_FAILED = "NEW_PROJECTLIST_FAILED";
export const UPDATE_REMOVED_PROJECTS = "UPDATE_REMOVED_PROJECTS";
export const PROJECT_REQUESTED = "PROJECT_REQUESTED";
export const DELETEPROJECT_REQUESTED = "DELETEPROJECT_REQUESTED";
export const DELETEPROJECT_RESPONSE = "DELETEPROJECT_RESPONSE";
export const DELETEPROJECT_FAILED = "DELETEPROJECT_FAILED";
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_RESPONSE = "CREATE_PROJECT_RESPONSE";
export const CLEAR_CREATED_PROJECTKEY = "CLEAR_CREATED_PROJECTKEY";
export const CREATE_PROJECT_FAILED = "CREATE_PROJECT_FAILED";
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_RESPONSE = "UPDATE_PROJECT_RESPONSE";
export const UPDATE_PROJECT_FAILED = "UPDATE_PROJECT_FAILED";
export const UPDATE_PROJECT_LOGO_REQUEST = "UPDATE_PROJECT_LOGO_REQUEST";
export const SHOW_NEW_PROJECT_POPUP = "SHOW_NEW_PROJECT_POPUP";
export const HIDE_NEW_PROJECT_POPUP = "HIDE_NEW_PROJECT_POPUP";
export const UPDATE_UNASSIGNED_CONVERSATION_COUNT =
  "UPDATE_UNASSIGNED_CONVERSATION_COUNT";
export const UPDATE_UNASSIGNED_CONVERSATION_COUNT_REALTIME =
  "UPDATE_UNASSIGNED_CONVERSATION_COUNT_REALTIME";
export const UPDATE_WEBSITE_SCREENSHOT = "UPDATE_WEBSITE_SCREENSHOT";

export const requestAvailableProject = (projectList) => ({
  type: PROJECTLIST_REQUESTED,
  projectList,
});

export const receiveAvailableProject = (projectList, isAllowedToCreate) => ({
  type: PROJECTLIST_RESPONSE,
  projectList,
  isAllowedToCreate,
});

export const availableProjectFailed = (projectList) => ({
  type: PROJECTLIST_FAILED,
  projectList,
});

export const updateRemovedProjects = (projectList) => ({
  type: UPDATE_REMOVED_PROJECTS,
  projectList,
});

export const requestNewlyAddedProjects = (projectList) => ({
  type: NEW_PROJECTLIST_REQUESTED,
  projectList,
});

export const receiveNewlyAddedProjects = (projectList, isAllowedToCreate) => ({
  type: NEW_PROJECTLIST_RESPONSE,
  projectList,
  isAllowedToCreate,
});

export const requestProjectById = (projectId) => ({
  type: PROJECT_REQUESTED,
  projectId,
});

export const deleteProjectRequested = (projectDetails) => ({
  type: DELETEPROJECT_REQUESTED,
  projectDetails,
});

export const deleteProjectresponse = (projectKey) => ({
  type: DELETEPROJECT_RESPONSE,
  projectKey,
});

export const deleteProjectfailed = (projectList) => ({
  type: DELETEPROJECT_FAILED,
  projectList,
});

export const createNewProject = (project) => ({
  type: CREATE_PROJECT_REQUEST,
  project,
});

export const receiveCreatedProject = (project) => ({
  type: CREATE_PROJECT_RESPONSE,
  project,
});

export const clearCreatedProjectKey = () => ({
  type: CLEAR_CREATED_PROJECTKEY,
});

export const createProjectFailed = () => ({
  type: CREATE_PROJECT_FAILED,
});

export const updateProjectRequest = (project) => ({
  type: UPDATE_PROJECT_REQUEST,
  project,
});

export const updateProjectResponse = (project) => ({
  type: UPDATE_PROJECT_RESPONSE,
  project,
});

export const updateProjectFailed = () => ({
  type: UPDATE_PROJECT_FAILED,
});

export const requestLogoUpdate = (logoData) => ({
  type: UPDATE_PROJECT_LOGO_REQUEST,
  logoData,
});

export const updateUnassingedConversationCount = (project) => ({
  type: UPDATE_UNASSIGNED_CONVERSATION_COUNT,
  project,
});

export const updateUnassingedConversationCountRealtime = (message , conversation) => ({
  type: UPDATE_UNASSIGNED_CONVERSATION_COUNT_REALTIME,
  message,
  conversation
});

export const showNewProjectPopup = () => ({
  type: SHOW_NEW_PROJECT_POPUP,
});

export const hideNewProjectPopup = () => ({
  type: HIDE_NEW_PROJECT_POPUP,
});

export const updateWebsiteScreenshot = (requestObj) => ({
  type: UPDATE_WEBSITE_SCREENSHOT,
  requestObj,
});
