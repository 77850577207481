import {
  IEmailResponse,
  IWebScrapResponse,
} from 'src/features/wizard/install/api/types'
import { appClient, rtmClient } from 'src/utils/api'

export const postWebScrap = (widgetId: string, websiteUrl: string) =>
  rtmClient.post<IWebScrapResponse>(
    '/web/scrap',
    {
      widgetId,
      websiteUrl,
    },
    {
      withCredentials: false,
    }
  )

export const postEmail = (widgetId: string, emailId: string) =>
  appClient.post<IEmailResponse>(
    `/widget/script/email/LS-${widgetId}`,
    {
      emailId,
    },
    {
      withCredentials: true,
    }
  )
