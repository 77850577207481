import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import { browserHistory} from 'react-router'
import {
  getProjectId,
  createConversationId,
  getDefaultConversationObject,
  getConversationLimitByContainerHeight,
} from "../../commons/Utility";
import Conversations from "./Conversations.jsx";
import * as ConversationActions from "../../actions/ConversationInfoAction";
import * as StaffActions from "../../actions/StaffAction";

const ConversationsContainer = (props) => {
    const {PeopleReducer, ConversationReducer,
            staffReducer, messageReducer, userReducer} = useSelector((state) => ({
            PeopleReducer: state.PeopleReducer,
            ConversationReducer: state.ConversationInfoReducer,
            staffReducer: state.StaffReducer,
            messageReducer: state.MessageReducer,
            userReducer: state.UserReducer,
    }));
    const dispatch = useDispatch();

    useEffect(()=>{
        let projectKey = props.params.projectkey;
        let projectId = getProjectId(projectKey);
        if (!staffReducer.dashboardAgents[projectId])
          dispatch(StaffActions.staffListRequest(projectId));
        if (props.params.visitorId) {
            dispatch(
              ConversationActions.conversationListRequest({
                filterUrl:location.pathname,
                projectId: projectId,
                visitorId: props.params.visitorId,
                isConversationStarted: true,
                cursor: "",
                limit: getConversationLimitByContainerHeight(),
                isNewList: true,
                orderBy: "-modifiedDate",
                desc: true,
              })
            );
        }
    }, []);

    const getLocalState = () => {
        let visitorId = props.params.visitorId;
        let peopleEntity = Object.assign({},PeopleReducer.peopleMap[visitorId]);
        let filteredConversations = {};
        let conversationMap = Object.assign({},ConversationReducer.conversationObject.conversationMap);
        let staffMap = Object.assign({},staffReducer.dashboardAgents[getProjectId(props.params.projectkey)]);
        let overflowStaffMap = Object.assign({},staffReducer.overflowAgents);
        for (let key in conversationMap) {
        let conversation = conversationMap[key];
        if (conversation.visitorId == peopleEntity.key)
            filteredConversations[conversation.key] = conversation;
        }
        return {
            peopleEntity: peopleEntity,
            conversationMap: filteredConversations,
            messageMap : messageReducer.messageMap,
            PeopleReducer : PeopleReducer,
            isConversationLoading: ConversationReducer.isFetching,
            staffMap: staffMap,
            overflowStaffMap : overflowStaffMap,
            params: Object.assign({}, props.params),
            expandedConversationIds:
                ConversationReducer.expandedConversationIds,
            tempConversationId: ConversationReducer.tempConversationId
        };
    }

    const showListing = () => {
        dispatch(ConversationActions.removeAllConversationFromExpandedList());
    }

    const expandConversation = (e) => {
        let conversationId = e.currentTarget.dataset.conversation_id;
        console.log("expandConversation with conversationId: ", conversationId);
        let conversationMap = Object.assign({},ConversationReducer.conversationObject.conversationMap);
        let conversation = Object.assign({}, conversationMap[conversationId]);
        if (conversation)
          dispatch(
            ConversationActions.addConversationToExpandedList(conversation.key)
          );
      }

    const shrinkConversation = (e) => {
      let conversationId = e.target.dataset.conversation_id;
      if (conversationId && ConversationReducer.expandedConversationIds.includes(conversationId)) {
        if (ConversationReducer.tempConversationId === conversationId)
          dispatch(ConversationActions.removeTempConversation(conversationId));
        else
          dispatch(ConversationActions.removeConversationFromExpandedList(conversationId));
      }
    }

    const redirectToConverstion = (conversationKey) => {
      let { projectkey } = props.params;
      let userId = userReducer.data.id;
      browserHistory.push({pathname:"/app/" +projectkey + "/chat/dashboard/D/O/N" + "/" + userId + "/" + conversationKey});
    }

    const createNewConversation = () => { 
      let {projectkey, visitorId} = props.params; 
      let projectId = getProjectId(projectkey);
      let peopleEntity = Object.assign({},PeopleReducer.peopleMap[visitorId]);
      let conversationMap = Object.assign({},ConversationReducer.conversationObject.conversationMap);
      let tempConversationId = createConversationId();
      while (conversationMap[tempConversationId])
        tempConversationId = createConversationId();
      let newConversation = getDefaultConversationObject(tempConversationId,projectId,visitorId,userReducer.data.id);
      newConversation.conversationType = "CHAT";
      dispatch(ConversationActions.addTempConversation(Object.assign({}, newConversation), peopleEntity));
      setTimeout(() => {redirectToConverstion(newConversation.key)}, 500);
    }

    return (
      <Conversations
          location={props.location}
          parentState={getLocalState()}
          createNewConversation={createNewConversation}
          expandConversation={expandConversation}
          shrinkConversation={shrinkConversation}
          showListing={showListing}
      />
    )
}

export default ConversationsContainer;
