import React from "react";
import { useDispatch } from "react-redux";
import {
  sanitizeValue,
  createFilterReference,
  validateInput,
  encodeReferences,
  getInitialValue,
} from "../commons/ContactsUtility.js";
import { showVoicebox } from "../../actions/VoiceboxAction.js";
import {
  addFilterReference,
  updatePeopleScrollId,
} from "../actions/SearchAction.js";
import Filter from "../filters/Filter.jsx";
import { browserHistory } from "react-router";
import { getFilterDescripton, alias } from "../commons/ContactsUtility.js";

const AppliedFilter = (props) => {
  const {
    reference,
    selectedFilter,
    setSelectedFilter,
    setShowAddProperty,
    onRemove,
    onSelect,
  } = props;
  const {
    newFilter,
    propertiesInfo: { properties, events, customFields, tags },
    setShowProperties,
  } = props;
  const property = reference ? reference.property : selectedFilter.property;
  const value = reference ? reference.value : selectedFilter.value;
  const filterType = reference
    ? reference.filterType
    : selectedFilter.filterType;
  const {
    query: { references = {}, scrollId },
    params: { groupId, projectkey },
  } = props;
  const { filterTypeAlias, valueAlias } = getFilterDescripton(
    filterType,
    value,
    tags
  );

  const showSelection =
    (reference && selectedFilter && reference.key == selectedFilter.key) ||
    newFilter;
  const dispatch = useDispatch();

  const onOption = (option, type) => {
    let { value, filterType } = selectedFilter;
    let initialValue = getInitialValue(value, option, type);

    setSelectedFilter({
      ...selectedFilter,
      value: initialValue,
      filterType: option,
      options: { [option]: true },
    });
  };

  const onEdit = (event = {}, editValue) => {
    let value = editValue ? editValue : event.target.value;
    value = sanitizeValue(value, selectedFilter);
    let updatedSelection = { ...selectedFilter, value: value };
    setSelectedFilter(updatedSelection);
  };

  const onApply = () => {
    let { isValid, message } = validateInput(selectedFilter, tags);
    if (!isValid) {
      dispatch(showVoicebox({ message, dismissAfter: 3000 }));
      return;
    }
    if (scrollId) {
      dispatch(updatePeopleScrollId(''));
    }
    let { key } = selectedFilter;
    setSelectedFilter({});
    setShowAddProperty(true);
    let referenceKey = references[key] ? key : "";
    let reference = createFilterReference(
      selectedFilter,
      events,
      customFields,
      referenceKey
    );
    references[reference.key] = reference;
    let encodedReferences = encodeReferences(references);
    browserHistory.push({
      pathname: `/app/${projectkey}/contacts/dashboard/${groupId}/${encodedReferences}`,
    });
  };

  const onInputEnter = (event) => {
    if (event.keyCode == 13) onApply();
  };

  const onLabelClick = (event, property, reference) => {
    let { target } = event;
    if (target.tagName != "I") onSelect(property, reference);
  };

  return (
    <div className={"filter-checkbox-dwn " + (showSelection ? "open" : "")}>
      <label
        onClick={(event) => {
          onLabelClick(event, property, reference);
        }}
      >
        {`${properties[property]} ${filterTypeAlias}:`}
        <span>{`${valueAlias}`}</span>
        <i
          onClick={() => {
            onRemove(reference, newFilter);
          }}
        >
          &times;
        </i>
      </label>
      <Filter
        {...props}
        selectedFilter={selectedFilter}
        onOption={onOption}
        onEdit={onEdit}
        onApply={onApply}
        onInputEnter={onInputEnter}
      />
    </div>
  );
};

export default AppliedFilter;
