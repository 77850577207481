import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { config } from 'src/config'
import {
  Container,
  Highlight,
} from 'src/features/authorization/components/Success/styled'
import {
  selectUserId,
  selectWidgetId,
} from 'src/features/authorization/store/selectors'
import { Button } from 'src/features/form/components/Button'
import { Icon } from 'src/features/ui/components/Icon'
import { Link2, SubTitle, Title } from 'src/features/ui/components/Typography'
import { MobileOnly } from 'src/features/ui/components/Visibility'
import { history } from 'src/history'
import { createWidgetSetupRoute } from 'src/pages/WidgetSetup/data'
import { IAppState } from 'src/utils/state/store'

export interface IProps {
  widgetId: string
  userId: string
}

export const SuccessComponent: FC<IProps> = ({ userId, widgetId }) => {
  const handleStartSetup = () => {
    history.push(createWidgetSetupRoute(widgetId))
  }

  return (
    <Container>
      <MobileOnly>
        <Icon fill="dodgerBlue" type="checkMark2" width={95} height={95} />
        <Margin top={34} />
      </MobileOnly>
      <Title>
        Your account has <br /> been successfully created.
      </Title>
      <Margin top={32} />
      <SubTitle>
        <Highlight>You are now ready to</Highlight> install and <br /> customize
        your chat widget.
      </SubTitle>
      <Margin top={42} />
      <Button
        maxWidth={26.8}
        onClick={handleStartSetup}
        color="primary"
        hasFullWidth
      >
        Let&apos;s set up my widget
      </Button>
      <Margin top={24} />
      <Link2
        href={`${config.chatsupportAppUrl}/app/${widgetId}/chat/guests/D/O/N/${userId}`}
        underline
        color="raven"
      >
        Not now, I&apos;ll do it later.
      </Link2>
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  widgetId: selectWidgetId(state.auth),
  userId: selectUserId(state.auth),
})

export const Success = connect(mapStateToProps)(SuccessComponent)
