import React, {forwardRef} from "react";
import { isValidURL, getFullName } from "../../commons/Utility.js";
import { getInitials } from "../commons/ContactsUtility.js";

const GridImage = forwardRef((props, ref) => {
  let {
    source = "",
    firstName = "",
    lastName = "",
    isOnline = false,
    avatarColor = "",
    alias = "",
    showToolTip,
    setIsShown,
    isShown,
  } = props;
  let initials = getInitials(firstName);
  let isValid = isValidURL(source);
  let fullName = getFullName({ firstName, lastName }) || "-";
  let aliasInitial = alias ? alias.charAt(0) : "";

  return (
    <div ref={ref} onClick={props.toggleCard} >
      <figure
        style={!isValid && avatarColor ? { backgroundColor: avatarColor } : {}}
      >
        {isValid ? <img src={source} /> : initials || aliasInitial}
      </figure>
      <cite className={isOnline ? "available" : "offline"}></cite>
      <span
        className="cs-caps-txt"
        onMouseEnter={showToolTip}
        onMouseLeave={() => setIsShown(false)}
        data-class="tooltip info"
        data-tip={isShown ? fullName : ""}
      >
        {fullName}
      </span>
    </div>
  );
});

export default GridImage;
