import { call, put, select } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as authTokenAction from "../actions/AuthTokenAction";
import { addNotification } from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as PopupActions from "../actions/PopupAction";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import { getFailedInvitesPopupProps } from "../commons/Utility.js";
import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* getTokenList() {
  const url = "/auth/get";
  console.log("Inside token list Saga");
  try {
    let tokenList = yield call(Ajax.get, url);
    tokenList = tokenList.data;
    console.log("Data received", tokenList);
    yield put(authTokenAction.receiveAvailableTokens(tokenList));
  } catch (e) {
    yield put(authTokenAction.availableTokenFailed());
    yield put(handleGlobalError(e));
  }
}

export function* deleteToken(details) {
  const projectKey = details.projectKey;
  const url = "/auth/delete/" + projectKey;
  try {
    yield put(showVoicebox({ message: "deleting token..." }));
    let tokenInfo = yield call(Ajax.deleteReq, url);

    yield put(showVoicebox({ message: "token deleted!", dismissAfter: 3000 }));
    if (tokenInfo.data && tokenInfo.data.success)
      yield put(authTokenAction.deleteTokenresponse(projectKey));
    else yield put(authTokenAction.deleteTokenfailed());
  } catch (e) {
    yield put(hideVoicebox());
    yield put(
      addNotification({
        message: "Project Deletion Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(authTokenAction.deleteTokenfailed());
    yield put(handleGlobalError(e));
  }
}

export function* createToken(tokenDetails) {
  tokenDetails = tokenDetails.tokenDetails;
  const url = "/auth/create/" + tokenDetails.key;
  let failedInvitations = [];
  let error = "";
  console.log("Inside create token Saga");
  try {
    yield put(showVoicebox({ message: "creating token..." }));
    let tokenInfo = yield call(Ajax.post, url, tokenDetails);
    tokenInfo = tokenInfo.data;
    yield put(showVoicebox({ message: "Token created!", dismissAfter: 3000 }));

    yield put(authTokenAction.receiveCreatedToken(tokenInfo));
  } catch (e) {
    console.info("Error during project creation", e);
    yield put(hideVoicebox());
    yield put(
      addNotification({
        message: "Token Creation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(authTokenAction.createTokenFailed());
    yield put(handleGlobalError(e));
  }
}

export function* updateToken(tokenDetails) {
  tokenDetails = tokenDetails.tokenMap;
  console.log("requested is:", tokenDetails);
  const url = "/auth/update/" + tokenDetails.key;
  let failedInvitations = [];
  let error = "";
  console.log("Inside create token Saga");
  try {
    yield put(showVoicebox({ message: "updating scopes..." }));
    let tokenInfo = yield call(Ajax.put, url, tokenDetails);

    tokenInfo = tokenInfo.data;

    console.log("tokenInfo=>", tokenInfo);

    yield put(showVoicebox({ message: "Token updated!", dismissAfter: 3000 }));

    yield put(authTokenAction.updateTokenResponse(tokenInfo));
  } catch (e) {
    console.info("Error during token creation", e);
    yield put(hideVoicebox());
    yield put(
      addNotification({
        message: "Token Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(authTokenAction.createTokenFailed());
    yield put(handleGlobalError(e));
  }
}
