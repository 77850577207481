/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react'
import { connect } from 'react-redux'
import {
  Container,
  Content,
  Header,
  Title,
  TitleIcon,
} from 'src/features/wizard/install/components/InstallTypeSelection/TypeSection/styled'
import { IProps } from 'src/features/wizard/install/components/InstallTypeSelection/TypeSection/types'
import { selectWasConnectionTested } from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const TypeSectionComponent: FC<IProps> = ({
  isOpen,
  label,
  icon,
  children,
  onToggle,
  hasBottomBorder,
  wasConnectionTested,
}) => {
  const handleToggle = () => {
    onToggle(icon)
  }

  return (
    <Container
      hasBottomBorder={hasBottomBorder && !wasConnectionTested}
      isOpen={isOpen}
    >
      <Header onClick={handleToggle}>
        <TitleIcon width={27} height={27} type={icon} />
        <Title>{label}</Title>
      </Header>
      {isOpen && <Content>{children}</Content>}
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  wasConnectionTested: selectWasConnectionTested(state.install),
})

export const TypeSection = connect(mapStateToProps)(TypeSectionComponent)
