import { useDispatch } from "react-redux";
import * as WorkingHoursAction from "../actions/WorkingHoursAction";

export const useCreateWorkingHours = (workingHours) => {
  const dispatch = useDispatch();

  return (workingHours) => {
    if (workingHours) {
      dispatch(WorkingHoursAction.createWorkingHoursRequest(workingHours));
      return true;
    } else {
      return false;
    }
  };
};

export const useCreateBusinessHours = (businessHours) => {
  const dispatch = useDispatch();

  return (businessHours) => {
    if (businessHours) {
      dispatch(WorkingHoursAction.createBusinessHoursRequest(businessHours));
      return true;
    } else {
      return false;
    }
  };
};
