import React, { FC } from 'react'
import {
  Container,
  IconContainer,
  Label,
} from 'src/features/form/components/ServerMessage/styled'
import {
  EnumServerMessageType,
  IProps,
} from 'src/features/form/components/ServerMessage/types'
import { Icon } from 'src/features/ui/components/Icon'

export const ServerMessage: FC<IProps> = ({ message, type }) => {
  const isSuccess = type === EnumServerMessageType.success

  const isError = type === EnumServerMessageType.error

  const getIconType = () => (isSuccess ? 'serverSuccess' : 'serverError')

  return (
    <Container isSuccess={isSuccess} isError={isError}>
      <IconContainer>
        <Icon width={24} height={24} type={getIconType()} />
      </IconContainer>
      <Label isSuccess={isSuccess} isError={isError}>
        {message}{' '}
      </Label>
    </Container>
  )
}
