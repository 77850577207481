/* eslint-disable react/no-multi-comp */
import React from 'react'
import { JoinedAtConfirmation } from 'src/features/returningFlow/components/Wizard/steps/1/Confirmation'
import { WidgetInstallationConfirmation } from 'src/features/returningFlow/components/Wizard/steps/2/Confirmation'
import { WidgetInstallationContent } from 'src/features/returningFlow/components/Wizard/steps/2/Content'
import { WidgetSetupContent } from 'src/features/returningFlow/components/Wizard/steps/3/Content'
import { WidgetSetupPreview } from 'src/features/returningFlow/components/Wizard/steps/3/Preview'
import { IView } from 'src/features/returningFlow/components/Wizard/types'

export const stepsView: IView[] = [
  {
    confirmation: () => <JoinedAtConfirmation />,
  },
  {
    content: (nextStep: VoidFunction) => (
      <WidgetInstallationContent nextStep={nextStep} />
    ),
    confirmation: () => <WidgetInstallationConfirmation />,
  },
  {
    content: () => <WidgetSetupContent />,
    preview: (
      _: VoidFunction,
      skipStep: VoidFunction,
      showContent: VoidFunction
    ) => <WidgetSetupPreview showContent={showContent} skipStep={skipStep} />,
  },
]
