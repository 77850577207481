import React, { Component } from "react";
import { isValidEmail } from "../../commons/Utility.js";

class MailTranscriptPopup extends Component {
  constructor() {
    super();
    this.onTranscriptKeyPress = this.onTranscriptKeyPress.bind(this);
    this.validateAndMailTranscript = this.validateAndMailTranscript.bind(this);
    this.state = { error: { mail: false } };
  }

  onTranscriptKeyPress(event) {
    if (event.key == "Enter") {
      this.validateAndMailTranscript();
    }

    if (this.state.error.mail) {
      if (document.activeElement == this.refs.transcriptRecipient) {
        if (
          isValidEmail(this.refs.transcriptRecipient.value) ||
          !this.refs.transcriptRecipient.value
        )
          this.setState({ error: { mail: false } });
      }
    }
  }

  validateAndMailTranscript() {
    if (isValidEmail(this.refs.transcriptRecipient.value))
      this.props.mailTranscript();
    else this.setState({ error: { mail: true } });
  }

  render() {
    let { shouldShowAddTranscriptMessage } = this.props.state;
    let { error } = this.state;

    return (
      <div>
        <div className="formField">
          <div className="inputTxt">
            <input
              type="text"
              aria-label="Name"
              tabIndex="1"
              onKeyDown={this.onTranscriptKeyPress}
              ref="transcriptRecipient"
              placeholder="Enter Email ID"
            />
            <code style={{ display: error.mail ? "block" : "none" }}></code>
          </div>
          <div
            className="inputTxt"
            style={{
              display: shouldShowAddTranscriptMessage ? "block" : "none",
            }}
          >
            <textarea
              tabIndex="2"
              onKeyDown={this.onTranscriptKeyPress}
              ref="transcriptTicketNote"
              placeholder="Enter Message (Optional)"
            ></textarea>
          </div>
          <a
            href="#"
            tabIndex="3"
            className="add-link"
            onClick={this.props.toggleAddMessageSection}
            style={{
              display: shouldShowAddTranscriptMessage ? "none" : "block",
            }}
          >
            &#43;Add a Message
          </a>
        </div>
        <div className="popupBtnHolder">
          <button
            className="blueBtn"
            tabIndex="4"
            onClick={this.validateAndMailTranscript}
          >
            Send
          </button>
        </div>
      </div>
    );
  }
}

export default MailTranscriptPopup;
