import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistCombineReducers } from 'redux-persist'
import createFilter from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { authReducer } from 'src/features/authorization/store/reducer'
import { returningFlowReducer } from 'src/features/returningFlow/store/reducer'
import { installReducer } from 'src/features/wizard/install/store/reducer'
import { setupReducer } from 'src/features/wizard/setup/store/reducer'
import { history } from 'src/history'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'setup', 'install'],
  transforms: [
    createFilter('auth', ['widgetId', 'userId', 'ssoToken', 'code']),
    createFilter('setup', ['values', 'previewUrl', 'iconUrls', 'websiteUrl']),
    createFilter('install', ['domainUrl']),
  ],
}

const createSagas = () =>
  createSagaMiddleware({
    onError: (error, other) => {
      // TODO: handle critical errors
      // eslint-disable-next-line no-console
      console.error(`Fatal Saga Error: ${error.message} in ${other.sagaStack}`)
    },
  })

const reducers = {
  auth: authReducer,
  setup: setupReducer,
  install: installReducer,
  returning: returningFlowReducer,
  router: connectRouter(history),
}

const combinedReducer = combineReducers(reducers)

const persistedReducer = persistCombineReducers(persistConfig, reducers)

// this variable will be set if you have redux-dev-tools extension installed in your browser
// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers =
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const storeFactory = (preloadedState = {}) => {
  const sagaMiddleware = createSagas()

  const store = createStore(
    combinedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  ) as any

  return {
    store,
    sagaMiddleware,
  }
}

export const persistedStoreFactory = () => {
  const sagaMiddleware = createSagas()

  const store = createStore(
    persistedReducer,
    undefined,
    composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
  )

  return {
    store,
    sagaMiddleware,
  }
}

export type IAppState = ReturnType<typeof combinedReducer>
