export const EMAIL_OPT_OUT_PREFERENCES_REQUEST =
  "EMAIL_OPT_OUT_PREFERENCES_REQUEST";
export const EMAIL_OPT_OUT_PREFERENCES_RESPONSE =
  "EMAIL_OPT_OUT_PREFERENCES_RESPONSE";
export const EMAIL_OPT_OUT_PREFERENCES_FAILED =
  "EMAIL_OPT_OUT_PREFERENCES_FAILED";
export const EMAIL_OPT_OUT_UPDATE_REQUEST = "EMAIL_OPT_OUT_UPDATE_REQUEST";
export const EMAIL_OPT_OUT_UPDATE_RESPONSE = "EMAIL_OPT_OUT_UPDATE_RESPONSE";
export const EMAIL_OPT_OUT_DELETE_REQUEST = "EMAIL_OPT_OUT_DELETE_REQUEST";
export const EMAIL_OPT_OUT_UPDATE_FAILED = "EMAIL_OPT_OUT_UPDATE_FAILED";
export const PREFERENCE_NOT_FOUND = "PREFERENCE_NOT_FOUND";

export const requestEmailOptOutPreferences = (visitorId) => ({
  type: EMAIL_OPT_OUT_PREFERENCES_REQUEST,
  visitorId,
});

export const responseEmailOptOutPreferences = (emailOptOutPreferences) => ({
  type: EMAIL_OPT_OUT_PREFERENCES_RESPONSE,
  emailOptOutPreferences,
});

export const optOutPreferencesFetchFailed = () => ({
  type: EMAIL_OPT_OUT_PREFERENCES_FAILED,
});

export const requestUpdateEmailOptOut = (
  emailOptOutPreferences,
  optOutType
) => ({
  type: EMAIL_OPT_OUT_UPDATE_REQUEST,
  emailOptOutPreferences,
  optOutType,
});

export const requestDeleteEmailOptOut = (
  emailOptOutPreferences,
  optOutType
) => ({
  type: EMAIL_OPT_OUT_DELETE_REQUEST,
  emailOptOutPreferences,
  optOutType,
});

export const responseUpdateEmailOptOut = (emailOptOutPreferences) => ({
  type: EMAIL_OPT_OUT_UPDATE_RESPONSE,
  emailOptOutPreferences,
});

export const optOutPreferencesUpdateFailed = () => ({
  type: EMAIL_OPT_OUT_UPDATE_FAILED,
});

export const optOutPreferencesNotFound = () => ({
  type: PREFERENCE_NOT_FOUND,
});
