import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { WhiteTickIcon } from "../../../commons/Icons";
import ImportContactsDropDown from "./ImportContactsDropDown.jsx";

const ImportContactColumns = (props) => {
  const {
    importedColumns = [],
    existingColumns = [],
    setImportedColumns,
    setCustomFieldsMap,
    customFieldsMap,
    forceImport,
    setHeaderToFieldMap,
    headerToFieldMap,
    setSelectAll,
    selectAll
  } = props;
  const [dropDownColumn, setDropDownColumn] = useState(-1);
  const [unMappedFieldsState, setUnMappedFieldsCount] = useState(0);
  const [unMappedExistingColumns, setUnMappedExistingColumns] = useState([]);

  useEffect(() => {
    let unMappedFields = [];
    existingColumns.forEach((propertyName) => {
      if (importedColumns.length > 0 && (!importedColumns.includes(propertyName) || customFieldsMap[propertyName])) {
        unMappedFields.push(propertyName);
      }
    });
    setUnMappedExistingColumns(unMappedFields);
  }, [JSON.stringify(importedColumns),Object.keys(customFieldsMap).length]);

  const getColumns = () => {
    let toRender = [];
    let unMappedCount = 0;
    importedColumns.forEach((propertyName, index) => {
      let displayColumnName = customFieldsMap[propertyName] || propertyName;
      if (
        !existingColumns.includes(propertyName) &&
        !customFieldsMap[propertyName]
      ) {
        unMappedCount++;
        displayColumnName = "Unmapped";
      }
      toRender.push(
        <div className={getClassName(displayColumnName, index)}>
          {index == 0 ? (
            <cite className={!selectAll ? "noselect" : "" } onClick={() => setSelectAll(!selectAll)}>
              <WhiteTickIcon />
            </cite>
          ) : (
            ""
          )}
          <span onClick={() => toggleDropDown(index)}>{displayColumnName}</span>
          {dropDownColumn === index ? (
            <ImportContactsDropDown
              existingColumns={existingColumns}
              columnIndex={index}
              columnName={displayColumnName}
              setColumnName={setColumnName}
              setCustomField={setCustomField}
              unMappedExistingColumns={unMappedExistingColumns}
              isCustomField={customFieldsMap[propertyName]? true : false}
              setDropDownColumn={setDropDownColumn}
              customFieldsMap={customFieldsMap}
            />
          ) : (
            ""
          )}
        </div>
      );
    });
    if (unMappedCount != unMappedFieldsState)
      setUnMappedFieldsCount(unMappedCount);
    return toRender;
  };

  const toggleDropDown = (index) => {
    if (index === dropDownColumn) setDropDownColumn(-1);
    else setDropDownColumn(index);
  };

  const setColumnName = (index, replacingIndex) => {
    if(index < 0)
      return;
    toggleDropDown(replacingIndex);
    let currentImportedColumns = Object.assign([], importedColumns);
    mapHeaderToField(currentImportedColumns[replacingIndex],existingColumns[index]);
    currentImportedColumns[replacingIndex] = existingColumns[index];
    setImportedColumns(currentImportedColumns);
  };

  const mapHeaderToField = (oldColumnName,newColumnName) => {
    let currentFielddMap = Object.assign({},headerToFieldMap);
    let fieldName = Object.keys(currentFielddMap).find(key => currentFielddMap[key] === oldColumnName);
    currentFielddMap[fieldName] = newColumnName; 
    setHeaderToFieldMap(currentFielddMap)
  }

  const setCustomField = (propertyName, index, shouldDelete) => {
    let customField = Object.assign({}, customFieldsMap);
    if(shouldDelete){
      let fieldName = Object.keys(customField).find(key => customField[key] === propertyName);
      delete customField[fieldName];
    }
    else{
      customField[importedColumns[index]] = propertyName;
      toggleDropDown(index);
    }
    setCustomFieldsMap(customField);
  };

  const getClassName = (displayColumnName, index) => {
    let classNames = "";
    if(index === dropDownColumn)
      classNames += " filter-checkbox-dwn open ";
    else if(displayColumnName === "Unmapped" && forceImport)
      classNames += " error "
    return classNames;
  };

  return (
    <div
      class="import-grid-head"
      style={{ width: `${importedColumns.length * 180}px` }}
    >
      {getColumns()}
    </div>
  );
};
export default ImportContactColumns;
