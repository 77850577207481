import React from "react";
import { TeamDropDownIcon } from "../../../commons/Icons";
const TeamEditConnectConfigComponent = (props) => {
  const {
    activeEditTab,
    projectListState,
    toggleAddWidget,
    getConnectAddedWidgetsDisplay,
    getConnectWidgetDropDown,
  } = props;

  const dropdownClassName =
    "cs-dropdwn cs-dropdwn-checkbox " +
    (projectListState.showProjectDropDown ? " open" : "");

  const toggleDropDown = () => {
    toggleAddWidget();
  };

  const getActiveConnectDisplay = () => {
    return activeEditTab == "" || activeEditTab == "connectChats"
      ? "block"
      : "none";
  };

  const AssignWidget = () => {
    return (
      <div>
        <label>Connect</label>
        <p>Choose the widgets from which chats will be assigned via Connect</p>
      </div>
    );
  };

  const Section = () => {
    return (
      <section
        class="cs-popup-team-info cs-overflow-inherit"
        style={{
          display: getActiveConnectDisplay(),
        }}
      >
        <div class="cs-widget-config">
          <AssignWidget />
          <div class={dropdownClassName}>
            <p onClick={toggleDropDown} onKeyDown={ () => {} } data-elementname="project">
              .
            </p>
            <TeamDropDownIcon />
            {getConnectAddedWidgetsDisplay()}
            <div>
              <ul>{getConnectWidgetDropDown()}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return <Section />;
};
export default TeamEditConnectConfigComponent;
