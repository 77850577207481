export const EVENT_REQUESTED = "EVENT_REQUESTED";
export const EVENT_RESPONSE = "EVENT_RESPONSE";
export const EVENT_FAILED = "EVENT_FAILED";

export const eventRequest = (projectId) => ({
  type: EVENT_REQUESTED,
  projectId,
});

export const eventResponse = (eventMap) => ({
  type: EVENT_RESPONSE,
  eventMap,
});

export const eventFailed = () => ({
  type: EVENT_FAILED,
});
