import {
  WORKING_HOURS_REQUESTED,
  WORKING_HOURS_RESPONSE,
  WORKING_HOURS_FETCH_FAILED,
  UPDATE_WORKING_HOURS_REQUESTED,
  UPDATE_WORKING_HOURS_RESPONSE,
  UPDATE_WORKING_HOURS_FAILED,
  CREATE_WORKING_HOURS_REQUESTED,
  CREATE_WORKING_HOURS_RESPONSE,
  CREATE_WORKING_HOURS_FAILED,
  BUSINESS_HOURS_REQUESTED,
  BUSINESS_HOURS_RESPONSE,
  BUSINESS_HOURS_FETCH_FAILED,
  CREATE_BUSINESS_HOURS_REQUESTED,
  CREATE_BUSINESS_HOURS_RESPONSE,
  CREATE_BUSINESS_HOURS_FAILED,
  UPDATE_BUSINESS_HOURS_REQUESTED,
  UPDATE_BUSINESS_HOURS_RESPONSE,
  UPDATE_BUSINESS_HOURS_FAILED,
} from "../actions/WorkingHoursAction";

const WorkingHoursReducer = (
  state = {
    isUpdating: false,
    isFetching: false,
    isFetched: false,
    data: {},
    error: "",
    success: false,
    businessHours: {
      isUpdating: false,
      isFetching: false,
      isFetched: false,
      data: {},
      error: "",
    },
  },
  action
) => {
  switch (action.type) {
    case WORKING_HOURS_REQUESTED:
      return {
        ...state,
        isFetching: true,
      };
    case WORKING_HOURS_RESPONSE:
      let workingHours = {};
      let userId = action.userId;
      if (action.workingHours.error)
        workingHours[userId] = Object.assign({}, { hoursList: [] });
      else workingHours = Object.assign({}, action.workingHours);
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        success: true,
        fetchedAt: Date.now(),
        data: { ...state.data, [userId]: workingHours[userId] },
      };
    case WORKING_HOURS_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        success: false,
        error: "Error occured while fetching working hours!",
      };
    case CREATE_WORKING_HOURS_REQUESTED:
      return {
        ...state,
        isUpdating: true,
      };
    case CREATE_WORKING_HOURS_RESPONSE:
      state.data = action.workingHours;
      return {
        ...state,
        isUpdating: false,
        data: state.data,
      };
    case CREATE_WORKING_HOURS_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case UPDATE_WORKING_HOURS_RESPONSE: {
      let userId = action.userId;
      state.data = { ...state.data, [userId]: action.workingHours[userId] };
      return {
        ...state,
        isUpdating: false,
        data: state.data,
      };
    }
    case UPDATE_WORKING_HOURS_REQUESTED:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_WORKING_HOURS_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case BUSINESS_HOURS_REQUESTED:
      return {
        ...state,
        businessHours: { ...state.businessHours, isFetching: true },
      };
    case BUSINESS_HOURS_RESPONSE: {
      let businessHoursData = {};
      let projectKey = action.projectKey;
      if (action.businessHours.error)
        businessHoursData[projectKey] = Object.assign({}, { hoursList: [] });
      else businessHoursData = Object.assign({}, action.businessHours);
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isFetching: false,
          isFetched: true,
          success: true,
          data: {
            ...state.businessHours.data,
            [projectKey]: businessHoursData[projectKey],
          },
        },
      };
    }
    case BUSINESS_HOURS_FETCH_FAILED:
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isFetching: false,
          isFetched: false,
          success: false,
          error: "Error occured while fetching working hours!",
        },
      };
    case CREATE_BUSINESS_HOURS_REQUESTED:
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isUpdating: true,
        },
      };
    case CREATE_BUSINESS_HOURS_RESPONSE:
      state.businessHours.data = action.businessHours;
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isUpdating: false,
          data: state.businessHours.data,
        },
      };
    case CREATE_BUSINESS_HOURS_FAILED:
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isUpdating: false,
        },
      };
    case UPDATE_BUSINESS_HOURS_REQUESTED:
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
        },
      };
    case UPDATE_BUSINESS_HOURS_RESPONSE: {
      let projectKey = action.projectKey;
      state.businessHours.data = {
        ...state.businessHours.data,
        [projectKey]: action.businessHours[projectKey],
      };
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isUpdating: false,
          data: state.businessHours.data,
        },
      };
    }
    case UPDATE_BUSINESS_HOURS_FAILED:
      return {
        ...state,
        businessHours: {
          ...state.businessHours,
          isUpdating: false,
        },
      };
    default:
      return state;
  }
};

export default WorkingHoursReducer;
