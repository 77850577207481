// export const CLIENT_TOKEN_COOKIE 	=	"X-Client-Token"

export const CLIENT_LOGIN_REFERENCE = "clrstatus";

export const DEFAULT_PAGE_URL = "/chat/dashboard";

export const STAGING_AUTH_URL =
  "https://auth.staging.chatsupport.co/o/oauth2/auth?" +
  "response_type=code" +
  "&client_id=20962-541454688853a91024b7" +
  "&scope=contacts-api.full_access awapis.users.invite awapis.account.create&project=livesupport" +
  "&access_type=OFFLINE";

export const STAGING_AUTH_PARAMS = 'response_type=code&client_id=20962-541454688853a91024b7&scope=contacts-api.full_access awapis.users.invite awapis.account.create&project=livesupport&access_type=OFFLINE';

//Change this to live later
export const LIVE_AUTH_URL =
  "https://auth.app.chatsupport.co/o/oauth2/auth?" +
  "response_type=code" +
  "&client_id=29354-2293d948e4ba952fc067d7201533b157" +
  "&scope=contacts-api.full_access awapis.users.invite awapis.account.create&project=anywherehelp" +
  "&access_type=OFFLINE";

export const LIVE_AUTH_PARAMS = 'response_type=code&client_id=29354-2293d948e4ba952fc067d7201533b157&scope=contacts-api.full_access awapis.users.invite awapis.account.create&project=anywherehelp&access_type=OFFLINE';

export const LIVE_LOGOUT_URL =
  "https://auth.app.chatsupport.co/o/logout?continue=";
export const STAGING_LOGOUT_URL =
  "https://auth.staging.chatsupport.co/o/logout?continue=";

export const STAGING_SSO_URL = "https://auth.staging.chatsupport.co/o/sso/";
export const LIVE_SSO_URL = "https://auth.app.chatsupport.co/o/sso/";

export const USER_ROLE_TO_STAFF_ROLE_MAP = {
  FULL_ACCESS: "Owner",
  RESTRICTED: "Staff",
};
export const STAFF_ROLE_TO_USER_ROLE_MAP = {
  Owner: "FULL_ACCESS",
  Staff: "RESTRICTED",
};

export const STAFF_AND_USER_ROLE_MAP = {
  Admin: "FULL_ACCESS",
  Member: "RESTRICTED",
};

export const ID_USER_ROLE_MAP = {
  "3eab08ba-c754-dbfb-a24d-dc4803c6ef39": "Owner",
  "9799640b-77c3-dcbd-945f-eade6cfeab38": "Admin",
  "0ef4b0ef-9972-ebed-e229-aac6d3b6e345": "Member",
  "39733437-d841-a9f9-a40c-ae1722d77b97": "Restricted Member",
  // "d992f517-4587-fafa-a30c-896063faeb65"   :  "Billing",
};

export const USER_ROLE_ID_MAP = {
  Owner: "3eab08ba-c754-dbfb-a24d-dc4803c6ef39",
  Admin: "9799640b-77c3-dcbd-945f-eade6cfeab38",
  Member: "0ef4b0ef-9972-ebed-e229-aac6d3b6e345",
  "Restricted Member": "39733437-d841-a9f9-a40c-ae1722d77b97",
  // "Billing" : "d992f517-4587-fafa-a30c-896063faeb65",
};

export const ID_USER_SKILL_MAP = {
  "0f6223e9-b68d-8eb9-9ece-dab8c3d88a81": "Beginner",
  "40189571-836c-9aac-954f-8822c365869d": "Intermediate",
  "576e6688-4ac4-eeac-f3c9-8f441f73c4cf": "Expert",
};

export const USER_SKILL_ID_MAP = {
  Beginner: "0f6223e9-b68d-8eb9-9ece-dab8c3d88a81",
  Intermediate: "40189571-836c-9aac-954f-8822c365869d",
  Expert: "576e6688-4ac4-eeac-f3c9-8f441f73c4cf",
};

export const BETA_ACCESS_URL = "beta";
export const ALPHA_ACCESS_URL = "chatsupport.co";

export const NOTIFICATION_LEVEL = {
  Success: "greenNty",
  Warning: "warningNty",
  Error: "errorNty",
};

export const MESSAGE_STATUS_CLASS = {
  CHAT_SENDING: "sendnow-chat",
  CHAT_FAILED: "resend-chat",
  CHAT_DELIVERED: "delivered-status",
  CHAT_READ: "read-status",
};
export const MESSAGE_STATUSES = [
  "CHAT_SENT",
  "CHAT_SENDING",
  "CHAT_FAILED",
  "CHAT_DELIVERED",
  "CHAT_READ",
];

export const NOTIFICATION_PERMISSIONS = {
  GRANTED: "granted",
  DENIED: "denied",
  DEFAULT: "default",
};

export const NOTIFICATION_DEFAULT_OPTIONS = {
  icon: "https://assets.chatsupport.co/website/stag/images/chatsupport-circle-logo.png",
};

export const NEW_CONVERSATION_NOTIFIER = {
  TITLE: "New Incoming Conversation",
  TIMEOUT: 5000,
  TONEPATH: "https://assets.chatsupport.co/chat/sounds/new-incoming-chat.wav",
};

export const NEW_MESSAGE_NOTIFIER = {
  TITLE: "New Incoming Message",
  TIMEOUT: 5000,
  TONEPATH: "https://assets.chatsupport.co/chat/sounds/new-incoming-chat.wav",
};

export const NEW_ASSIGNMENT_NOTIFIER = {
  TITLE: "Incoming Chat Alert",
  TIMEOUT: 5000,
  TONEPATH: "https://assets.chatsupport.co/chat/sounds/new-incoming-chat.wav",
};

export const NEW_MENTION_NOTIFIER = {
  TITLE: "Mention Alert",
  TIMEOUT: 5000,
  TONEPATH: "https://assets.chatsupport.co/chat/sounds/new-incoming-chat.wav",
};

export const SHORT_CHAT_STATUS_MAP = {
  Open: "O",
  Closed: "C",
  NotInitiated: "N",
  O: "Open",
  C: "Closed",
  N: "NotInitiated",
  M: "Mention",
  Mention: "M",
  Pending : "P",
  P : "Pending"
};

export const SHORT_SORT_BY_MAP = {
  N: "Newest",
  O: "Oldest",
  Oldest: "O",
  Newest: "N",
  Pending: "P",
  P : "Pending"
};

export const CHAT_STATUS = {
  Open: "OPEN",
  Closed: "CLOSED",
  NotInitiated: "NOTINITIATED",
  Mention: "MENTION_CHATS",
  Pending: "PENDING"
};

export const PRE_CHAT_SURVEY_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  REQUESTED: "REQUESTED",
  INITIAL: "INITIAL",
};

export const MESSAGE_TYPE = {
  chat: "CHAT",
  chat_closed: "CHAT_CLOSED",
  chat_assigned: "CHAT_ASSIGN",
  chat_assigned_on_connect: "CHAT_ASSIGN_ON_CONNECT",
  chat_accepted: "CHAT_ACCEPTED",
  chat_rejected: "CHAT_REJECTED",
  forwarding_message: "FORWARDING_MESSAGE",
  chat_auto_assigned_on_reply: "CHAT_AUTO_ASSIGN_ON_REPLY",
  chat_auto_assigned_available: "CHAT_AUTO_ASSIGN_AVAILABLE",
  chat_auto_assigned_available_on_connect: "CHAT_AUTO_ASSIGN_AVAILABLE_ON_CONNECT",
  chat_unassigned: "CHAT_UNASSIGN",
  chat_reopen: "CHAT_REOPEN",
  notes: "NOTES",
  realtime_visitor_update: "REALTIME_VISITOR_UPDATE",
  initiated_new_conversation: "INITIATED_NEW_CONVERSATION",
  chat_auto_assign_on_overflow: "CHAT_AUTO_ASSIGN_ON_OVERFLOW",
  realtime_staff_update: "REALTIME_STAFF_UPDATE",
  realtime_staff_delete: "REALTIME_STAFF_DELETE",
  realtime_project_delete: "REALTIME_PROJECT_DELETE",
  realtime_staff_added: "REALTIME_STAFF_ADDED",
  realTime_staff_permission_added: "REALTIME_STAFF_PERMISSION_ADDED",
  realTime_staff_resubscription: "REALTIME_STAFF_RESUBSCRIPTION",
  realtime_staff_removed: "REALTIME_STAFF_REMOVED",
  realtime_project_update: "REALTIME_PROJECT_UPDATE",
  overflow_chat_closed: "OVERFLOW_CHAT_CLOSED",
  overflow_chat_closed_on_available: "OVERFLOW_CHAT_CLOSED_ON_AVAILABLE",
  overflow_chat_disconnected: "OVERFLOW_CHAT_DISCONNECTED",
  dashboard_visitor_left: "DASHBOARD_VISITOR_LEFT",
  dashboard_chat_closed_no_response: "DASHBOARD_CHAT_CLOSED_NO_RESPONSE",
  dashboard_agent_disconnected: "DASHBOARD_AGENT_DISCONNECTED",
  attachment: "ATTACHMENT",
  chat_lead_capture: "CHAT_LEAD_CAPTURE",
  video_chat_link: "CHAT_VIDEO_LINK",
  overflow_chat_closed_no_response: "OVERFLOW_CHAT_CLOSED_NO_RESPONSE",
  overflow_prompt_closed_on_ignore: "OVERFLOW_PROMPT_CLOSED_ON_IGNORE",
  chat_assign_and_reopen: "CHAT_ASSIGN_AND_REOPEN",
  screen_share_link: "CHAT_SCREEN_SHARE_LINK",
  out_of_office_lead_capture: "OUT_OF_OFFICE_LEAD_CAPTURE",
  overflow_chat_takenover_by_staff: "OVERFLOW_CHAT_TAKENOVER_BY_STAFF", //Will exist only in the front end
  overflow_chat_closed_by_staff: "OVERFLOW_CHAT_CLOSED_BY_STAFF", //Will exist only in the front end
  overflow_chat_interrupted : "OVERFLOW_CHAT_INTERRUPTED", //Will exist only in the front end
  cs_prechatsurvey: "CS_PRECHATSURVEY",
  cs_offlineform: "CS_OFFLINEFORM",
  cs_answerphrase: "CS_ANSWERPHRASE",
  cs_chatdisconnect: "CS_CHATDISCONNECT",
  cs_visitornavigation: "CS_VISITORNAVIGATION",
  post_user_prescence: "USER_PRESCENCE_UPDATE",
  visitor_initiated_conversation: "VISITOR_INITIATED_CONVERSATION",
  staff_joined_conversation: "STAFF_JOINED_CONVERSATION",
  unanswered_auto_reply: "UNANSWERED_AUTO_REPLY",
  chat_prompt_triggered: "CHAT_PROMPT_TRIGGERED",
  chat_prompt_ignored: "CHAT_PROMPT_IGNORED",
  chat_answering_message: "CHAT_ANSWERING_MESSAGE",
  broadcast_message: "BROADCAST_MESSAGE",
  announcement: "CAMPAIGN_ANNOUNCEMENT",
  campaign_email: "CAMPAIGN_EMAIL",
  out_of_office_ack: "OUT_OF_OFFICE_ACK",
  out_of_office_auto_reply: "OUT_OF_OFFICE_AUTO_REPLY",
  overflow_chat_disconnected_abnormally:
    "OVERFLOW_CHAT_DISCONNECTED_ABNORMALLY",
  initiate_new_chat_by_email_reply: "INITIATE_NEW_CHAT_BY_EMAIL_REPLY",
  messages_validation: "MESSAGES_VALIDATION",
  agent_messages_sync: "AGENT_MESSAGES_SYNC",
  agent_messages_validation: "AGENT_MESSAGES_VALIDATION",
  mention_closed : "MENTION_CLOSED",
  feedback_sumitted:"FEEDBACK_SUBMITTED",
  single_agent_conversation_closed: "SINGLE_AGENT_CONVERSATION_CLOSED",
  add_conversation_queue : "ADD_CONVERSATION_QUEUE",
  remove_conversation_queue: "REMOVE_CONVERSATION_QUEUE",
  chat_closed_as_pending : "CHAT_CLOSED_AS_PENDING",
  email_reply : "EMAIL_REPLY",
  next_campaign_triggered: "NEXT_CAMPAIGN_TRIGGERED",
  visitor_left_before_chat_routed: "VISITOR_LEFT_BEFORE_CHAT_ROUTED"
};
export const CAMPAIGN_TYPES = [
  "CAMPAIGN_ANNOUNCEMENT",
  "BROADCAST_MESSAGE",
  "CAMPAIGN_EMAIL",
];

export const FEEDBACK_MESSAGE_TYPES = [
  "FEEDBACK_SUBMITTED",
  "FEEDBACK_COMMENT",
  "FEEDBACK_RATING"
]

export const DISPLAY_TEXT_TYPE_MESSAGES = [
  "CHAT",
  "UNANSWERED_AUTO_REPLY",
  "BROADCAST_MESSAGE",
  "CAMPAIGN_ANNOUNCEMENT",
  "CAMPAIGN_EMAIL",
  "OUT_OF_OFFICE_ACK",
  "OUT_OF_OFFICE_ACK",
  "OUT_OF_OFFICE_AUTO_REPLY",
  "UNANSWERED_AUTO_REPLY",
  "EMAIL_REPLY",
];

export const ACTIONABLE_TYPES = [
  "CHAT",
  "ATTACHMENT",
  "CHAT_VIDEO_LINK",
  "CHAT_SCREEN_SHARE_LINK",
];

export const MESSAGE_SENDING_TYPE = {
  automatic: "AUTOMATIC",
  manual: "MANUAL",
};

export const CHAT_HISTORY_CLASSES = {
  CHAT_CLOSED: "chat-date",
  CHAT_ASSIGN: "chat-date",
  CHAT_AUTO_ASSIGN_ON_REPLY: "chat-date",
  CHAT_UNASSIGN: "chat-date",
  NOTES: "chat-note",
  CHAT_AUTO_ASSIGN_AVAILABLE: "chat-date",
  AGENT: "chats",
  VISITOR: "chats",
  CHAT_REOPEN: "chat-date",
  INITIATED_NEW_CONVERSATION: "chat-date",
  AUTO_REPLY_FOR_NEW_CONVERSATION: "chat-date",
  OVERFLOW_CHAT_CLOSED: "chat-date",
  OVERFLOW_CHAT_CLOSED_ON_AVAILABLE: "chat-date",
  OVERFLOW_CHAT_DISCONNECTED: "chat-date",
  CHAT_AUTO_ASSIGN_ON_OVERFLOW: "chat-date",
  CHAT_LEAD_CAPTURE: "chat-date",
  BROADCAST_MESSAGE: "chats",
  CAMPAIGN_ANNOUNCEMENT: "chats",
  CAMPAIGN_EMAIL: "chats",
  CHAT_VIDEO_LINK: "chats",
  OVERFLOW_CHAT_CLOSED_NO_RESPONSE: "chat-date",
  OVERFLOW_PROMPT_CLOSED_ON_IGNORE: "chat-date",
  ATTACHMENT: "upload",
  CHAT_ASSIGN_AND_REOPEN: "chat-date",
  OUT_OF_OFFICE_LEAD_CAPTURE: "chat-date",
  OUT_OF_OFFICE_ACK: "chat-date",
  OUT_OF_OFFICE_AUTO_REPLY: "chat-date",
  OVERFLOW_CHAT_CLOSED_BY_STAFF: "chat-date",
  OVERFLOW_CHAT_TAKENOVER_BY_STAFF: "chat-date",
  CS_PRECHATSURVEY: "chat-date",
  CS_OFFLINEFORM: "chat-date",
  CS_ANSWERPHRASE: "chats",
  CS_CHATDISCONNECT: "chat-date",
  CS_VISITORNAVIGATION: "chat-date",
  VISITOR_INITIATED_CONVERSATION: "chat-date",
  UNANSWERED_AUTO_REPLY: "chat-date",
  CHAT_PROMPT_TRIGGERED: "chat-date",
  CHAT_PROMPT_IGNORED: "chat-date",
  DASHBOARD_VISITOR_LEFT: "chat-date",
  DASHBOARD_CHAT_CLOSED_NO_RESPONSE: "chat-date",
  DASHBOARD_AGENT_DISCONNECTED: "chat-date",
};

export const TAG_LINE_TYPES = [
  "CHAT_CLOSED",
  "CHAT_ASSIGN",
  "CHAT_ASSIGN_ON_CONNECT",
  "CHAT_AUTO_ASSIGN_ON_REPLY",
  "CHAT_UNASSIGN",
  "CHAT_AUTO_ASSIGN_AVAILABLE",
  "CHAT_AUTO_ASSIGN_AVAILABLE_ON_CONNECT",
  "CHAT_REOPEN",
  "INITIATED_NEW_CONVERSATION",
  "OVERFLOW_CHAT_CLOSED",
  "OVERFLOW_CHAT_CLOSED_ON_AVAILABLE",
  "OVERFLOW_CHAT_DISCONNECTED",
  "OVERFLOW_CHAT_DISCONNECTED_ABNORMALLY",
  "CHAT_AUTO_ASSIGN_ON_OVERFLOW",
  "CHAT_LEAD_CAPTURE",
  "OVERFLOW_CHAT_CLOSED_NO_RESPONSE",
  "OVERFLOW_PROMPT_CLOSED_ON_IGNORE",
  "CHAT_ASSIGN_AND_REOPEN",
  "OUT_OF_OFFICE_LEAD_CAPTURE",
  "OVERFLOW_CHAT_CLOSED_BY_STAFF",
  "OVERFLOW_CHAT_TAKENOVER_BY_STAFF",
  "CS_CHATDISCONNECT",
  "CS_PRECHATSURVEY",
  "CS_OFFLINEFORM",
  "VISITOR_INITIATED_CONVERSATION",
  "CHAT_PROMPT_TRIGGERED",
  "CHAT_PROMPT_IGNORED",
  "CS_VISITORNAVIGATION",
  "STAFF_JOINED_CONVERSATION",
  "CHAT_ANSWERING_MESSAGE",
  "FORWARDING_MESSAGE",
  "DASHBOARD_VISITOR_LEFT",
  "DASHBOARD_CHAT_CLOSED_NO_RESPONSE",
  "DASHBOARD_AGENT_DISCONNECTED",
  "INITIATE_NEW_CHAT_BY_EMAIL_REPLY",
  "FEEDBACK_SUBMITTED",
  "FEEDBACK_COMMENT",
  "CHAT_CLOSED_AS_PENDING",
  "OVERFLOW_CHAT_INTERRUPTED",
  "VISITOR_LEFT_BEFORE_CHAT_ROUTED"
];

export const NON_DISPLAYABLE_TYPES = [
  "CHAT_ACCEPTED",
  "CHAT_REJECTED",
  "FORWARDING_MESSAGE",
  "STAFF_JOINED_CONVERSATION",
  "FORWARDING_MESSAGE",
  "MESSAGES_VALIDATION",
  "AGENT_MESSAGES_SYNC",
  "AGENT_MESSAGES_VALIDATION",
  "ROUTING_CHAT_RESPONSE",
  "MENTION_CLOSED",
  "FEEDBACK_FORM_CLOSED",
  "SINGLE_AGENT_CONVERSATION_CLOSED",
  "ADD_CONVERSATION_QUEUE",
  "REMOVE_CONVERSATION_QUEUE",
  "NEXT_CAMPAIGN_TRIGGERED"
];

export const TAKING_CHATS_STATUS_MAP = {
  AVAILABLE: "I'm Available",
  OFFLINE: "Offline",
  DND: "Do Not Disturb",
  VACATION: "Vacation",
  LUNCH: "At Lunch",
};

export const TAKING_CHATS_TEXT_MAP = {
  AVAILABLE: "Taking chats",
  OFFLINE: "You're not taking chats",
  DND: "Not taking chats",
  VACATION: "Not taking chats",
  LUNCH: "Not taking chats",
};

export const NOT_TAKING_CHAT_STATUSES = ["OFFLINE", "DND", "LUNCH", "VACATION"];

export const TAKING_CHAT_STATUSES = ["AVAILABLE","CALL"];

export const OFFLINE_CHAT_STATUSES = ["OFFLINE"];

export const DND_CHAT_STATUS = "DND";

export const AVAILABILITY_STATUS = {
  OFFLINE: "OFFLINE",
  DND: "DND",
  LUNCH: "LUNCH",
  VACATION: "VACATION",
  AVAILABLE: "AVAILABLE"
}

export const AVAILABILITY_CLASS = {
  AVAILABLE: "available",
  OFFLINE: "offline",
  DND: "busy",
  VACATION: "busy",
  LUNCH: "busy",
};

export const IMAGE_PREVIEW_PATH =
  "/api/bucket/image/get?fileName={fileName}&download=true";

export const EMAIL_OPT_OUT_DESCRIPTION = {
  EMAIL_BROADCAST: "Relay annoucnements, product offers and coupons.",
  EMAIL_CAMPAIGN: "Automated announcements, product updates and new features",
  OFFLINE_MESSAGE_EMAIL_NOTIFIATION:
    "Automated mail when agent replies to your chat while you are offline.",
};

export const EMAIL_OPT_OUT_DISPLAY_NAMES = {
  EMAIL_BROADCAST: "Email Broadcast",
  EMAIL_CAMPAIGN: "Email Campaign",
  OFFLINE_MESSAGE_EMAIL_NOTIFIATION: "Offline Transcripts",
};

export const IMAGE_TYPES = ["jpg", "jpeg", "png", "gif"];

export const AUDIO_TYPES = ["mp3", "mpeg", "ogg", "wav"];

export const VIDEO_TYPES = ["webm", "mp4", "ogg"];

export const SPREAD_SHEET_TYPES = ["ods", "xlr", "xls", "xlsx"];

export const DOCUMENT_TYPES = [
  "doc",
  "docx",
  "odt",
  "rtf",
  "tex",
  "txt",
  "wks",
  "wps",
  "wpd",
];

export const COMPRESSED_TYPES = [
  "zip",
  "z",
  "tar.gz",
  "rpm",
  "rar",
  "pkg",
  "deb",
  "arj",
  "7z",
];

export const INTERNAL_ACCOUNTS = [
  "5192a635-fc8a-46eb-9af0-ea1499c77994",
  "G3YJN",
  "7aeb96d4-7ca7-475b-91d7-04f92f9a1620",
  "2d7e49c4-05a5-43dc-8496-4f61487d680a",
  "c49b4e32-8072-4a9f-b6e2-38d77cef3908",
  "SEN42",
  "ef0748a9-1547-443f-91d7-429156c96534",
  "88bf33f0-af1f-4dc6-8368-06beb466447b",
  "b745f451-c960-4045-b4f7-943c736ca484",
  "X15XG6",
  "sen42",
];

export const MANUAL_ASSIGNMENT_TYPES = [
  "CHAT_ASSIGN",
  "CHAT_ASSIGN_ON_CONNECT",
  "CHAT_UNASSIGN",
  "CHAT_ASSIGN_AND_REOPEN",
];

export const MESSAGE_FROM = {
  AGENT: "AGENT",
  VISITOR: "VISITOR",
};

export const CHAT_MESSAGE_LIMIT = 30;

export const CURSOR_STATUS = {
  NOT_INITIALIZED: "NOT_INITIALIZED",
  NO_CURSOR: "NO_CURSOR",
};

export const MODIFICATION_STATUS = {
  RE_OPEN: "RE_OPEN",
  CLOSE: "CLOSE",
  CHAT_ASSIGN: "CHAT_ASSIGN",
  CHAT_ASSIGN_ON_CONNECT: "CHAT_ASSIGN_ON_CONNECT",
  RE_OPEN_ON_ASSIGN: "RE_OPEN_ON_ASSIGN",
  MENTION_CLOSED: "MENTION_CLOSED"
};
export const displayVisitorType = {
  VISITOR: "Visitor",
  LEAD: "Lead",
  USER: "User",
  COMPANY: "Company",
  STAFF: "User"
};
export const filterTypes = {
  contains: "contains",
  not_contains: "doesn't contains",
  exactly: "exactly",
  not_exactly: "not exactly",
  has_no_value: "has no value",
  has_any_value: "has any value",
  more_then_times: "More than how many times",
  less_then_times: "Less than how many times",
  more_then_days: "More than how many days",
  less_then_days: "Less than how many days",
  is_bool_true: "Is true",
  is_bool_false: "Is false",
  after_date: "After",
  before_date: "Before",
  on_date: "On",
  more_then_no_days: "Not in last how many days",
  less_then_no_days: "In last how many days",
  equals_to: "Equal to",
  more_then: "More then",
  less_then: "Less then",
  is_tagged: "Tagged to",
  is_not_tagged: "Not tagged to",
  subscribed: "Subscribed",
  unsubscribed: "Unsubscribed"
};

export const BRANDLOGO_CLASS_BY_BRANDNAME = {
  answerconnect: "answerconnect-logo",
  answerforce: "answerforce-logo",
};

export const LIVE_INTERNAL_PROJECT_ID_NAME_MAP = {
  "LS-9418ffaa": "AnswerConnect",
  "LS-bf15c287": "AnswerConnect Support",
  "LS-da44d78b": "AnswerConnect UK",
  "LS-86e070e0": "AnswerConnect UK Support",
  "LS-f41076de": "AnswerForce",
  "LS-f1b08e84": "AnswerForce Support",
  "LS-e1d5843d": "AnswerForce UK",
  "LS-cc9fe2a1": "AnswerForce UK Support",
  "LS-cb146eee": "HelloSells",
  "LS-984bf45c": "HelloSells Support",
  "LS-85fd177b": "LexReception",
  "LS-ee1b719c": "LexReception Support",
  "LS-2b4c081e": "LexReception UK",
  "LS-cda97f50": "LexReception UK Support",
  "LS-436fb029": "AnywhereWorks",
  "LS-14b68929": "AnywhereWorks Support",
  "LS-f28e57fc": "FranForce",
  "LS-f823d19d": "YoCoBoard",
  "LS-7140b122": "Distributed Source",
  "LS-389d1168": "AdaptiveU",
  "LS-64fec8e4": "ConversionSupport",
};

export const PRODUCTION_SUPPORT_PROJECTS = [
  "LS-bf15c287",
  "LS-86e070e0",
  "LS-f1b08e84",
  "LS-cc9fe2a1",
  "LS-984bf45c",
  "LS-ee1b719c",
  "LS-ab4fedf5", 
  "LS-cda97f50",
  "LS-8ad8e9c3"
];

export const UI_DROPPED_CUSTOM_FIELDS = [
  'brandId', 
  'status', 
  'fcmToken'
]

export const UI_DROPPED_DEFAULT_FIELDS = [
  'companyKey'
]

export const defaultImages = [
  "/images/user1.png",
  "/images/user2.png",
  "/images/user3.png",
  "/images/user4.png",
  "/images/user5.png",
  "/images/user6.png",
  "/images/user7.png",
  "/images/user8.png",
  "default",
];

export const defaultImagesURLs = [
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user2.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user3.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user4.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user5.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user6.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user7.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user8.png",
  "DEFAULT",
];

export const defaultImageURLsMap = {
  "/images/user1.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png",
  "/images/user2.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user2.png",
  "/images/user3.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user3.png",
  "/images/user4.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user4.png",
  "/images/user5.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user5.png",
  "/images/user6.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user6.png",
  "/images/user7.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user7.png",
  "/images/user8.png":
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user8.png",
  default: "DEFAULT",
};

export const defaultURLImageMap = {
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png":
    "/images/user1.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user2.png":
    "/images/user2.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user3.png":
    "/images/user3.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user4.png":
    "/images/user4.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user5.png":
    "/images/user5.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user6.png":
    "/images/user6.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user7.png":
    "/images/user7.png",
  "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user8.png":
    "/images/user8.png",
  DEFAULT: "default",
};

export const isValidURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const cropperWhiteBackground =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=";
export const cropperGrayBackground =
  "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP0DI2pBwADZAF7poFwuQAAAABJRU5ErkJggg==";

export const peopleESDefaultQuery = {
  query: {
    projectId: "",
    filter: { type: ["USER"] },
    not_filter: {},
    match: {},
    not_match: {},
    multi_match: {},
    not_multi_match: {},
    exists: { field: [] },
    not_exist: { field: [] },
    sort: { lastSeenTime: "desc" },
    range: {},
    size: 50,
    aggr: {},
    scroll: "6h",
  },
  scrollId: "",
  references: {},
  groupId: "",
};

export const REALTIME_MESSAGES = [
  "REALTIME_STAFF_DELETE",
  "REALTIME_STAFF_UPDATE",
  "REALTIME_VISITOR_UPDATE",
  "REALTIME_PROJECT_DELETE",
  "REALTIME_PROJECT_UPDATE",
  "REALTIME_STAFF_ADDED",
  "REALTIME_STAFF_REMOVED",
  "REALTIME_STAFF_PERMISSION_ADDED",
  "REALTIME_STAFF_RESUBSCRIPTION",
];
export const GUEST_MESSAGES = [
  "GUEST_ADDED",
  "GUEST_ONLINE",
  "GUEST_AWAY",
  "GUEST_UPDATE",
  "GUEST_REMOVE",
];
export const GUEST_EVENT = "guest-event";
export const TAG_TYPES = {
  staff_created: "STAFF_CREATED",
  import_contact: "IMPORT_CONTACT",
};
export const taggingType = {
  conversation_tag: "CONVERSATION_TAG",
  people_tag: "PEOPLE_TAG",
};
// export const MESSAGE_STATUS		=	{
//     CHAT_SENT       : 'Sent',
//     CHAT_DELIVERED	: 'Delivered',
//     CHAT_READ       : 'Read',
//     CHAT_FAILED     : 'Failed'
// }

export const MESSAGE_STATUS = {
  CHAT_SENT: "unread-icon",
  CHAT_DELIVERED: "delivered-icon",
  CHAT_READ: "read-icon",
  CHAT_FAILED: "",
};

export const BROADCAST_TYPE = {
  EMAIL: "EMAIL",
  MESSAGE: "MESSAGE",
};

export const OPEN_DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const PROPERTY_TEMPLATE_KEYS = [
  {
    name: "createdDate",
  },
  {
    name: "modifiedDate",
  },
  {
    name: "type",
  },
  {
    name: "firstName",
  },
  {
    name: "lastName",
  },
  {
    name: "email",
  },
  {
    name: "userId",
  },
  {
    name: "phoneNumber",
  },
  {
    name: "totalVisit",
  },
  {
    name: "sourceUrl",
  },
  {
    name: "currentUrl",
  },
  {
    name: "country",
  },
  {
    name: "state",
  },
  {
    name: "ip",
  },
  {
    name: "last_seen_user_agent",
  },
  {
    name: "firstContacted",
  },
  {
    name: "lastContacted",
  },
  {
    name: "displayPhoneNumber",
  },
  {
    name: "companyKey",
  },
  {
    name: "companyName",
  },
  {
    name: "companySize",
  },
  {
    name: "companyIndustry",
  },
  {
    name: "lastSeenTime",
  },
  {
    name: "browserName",
  },
  {
    name: "browserVersion",
  },
  {
    name: "os",
  },
  {
    name: "browserLanguage",
  },
  {
    name: "lastPageTitle",
  },
  {
    name: "lastPageUrl",
  },
  {
    name: "webSessions",
  },
  {
    name: "androidWebSessions",
  },
  {
    name: "iosWebSessions",
  },
  {
    name: "androidDevice",
  },
  {
    name: "androidAppVersion",
  },
  {
    name: "androidOSVersion",
  },
  {
    name: "lastSeenOnAndroid",
  },
  {
    name: "iosDevice",
  },
  {
    name: "iosAppVersion",
  },
  {
    name: "iosOsVersion",
  },
  {
    name: "lastSeenOnIos",
  },
  {
    name: "campaignName",
  },
  {
    name: "campaignMedium",
  },
  {
    name: "campaignSource",
  },
  {
    name: "campaignUrl",
  },
  {
    name: "profileImage",
  },
  {
    name: "pageTitle",
  },
  {
    name: "emailSubscription",
  },
];

export const EVENTS_TRACKING = {
  project_created: "PROJECT_CREATED",
  member_invited: "MEMBER_INVITED",
  invitation_accepted: "INVITATION_ACCEPTED",
  invitation_declined: "INVITATION_DECLINED",
  group_created: "GROUP_CREATED",
  contact_imported: "CONTACT_IMPORTED",
  customfield_created: "CUSTOM_FIELD_CREATED",
  out_of_office_updated: "OUT_OF_OFFICE_UPDATED",
  instant_reply_updated: "INSTANT_REPLY_UPDATED",
  chat_prompt_created: "CHAT_PROMPT_CREATED",
  broadcast_email_sent: "BROADCAST_EMAIL_SENT",
  broadcast_message_sent: "BROADCAST_MESSAGE_SENT",
};

export const INVITE_FAILURE_MESSSAGE_MAP = {
  "contact already exist": "staff already exists",
  "contact can't have two accounts": "staff is part of different company",
  "invitation already registered": "invitation already sent",
};

export const PREVIEW_WIDGET_TYPES = {
  INITIAL_PAGE: "INITIAL_PAGE",
  MESSAGE_LIST: "MESSAGE_LIST",
  PROJECT_LOGO: "PROJECT_LOGO",
  INSTANT_REPLY: "INSTANT_REPLY",
  PROMPT: "PROMPT",
  PRE_CHAT_SURVEY: "PRE_CHAT_SURVEY",
  OOO_ANSWER_PHRASE: "OOO_ANSWER_PHRASE",
};
export const PREVIEW_WIDGET_TYPES_NAVIGATION = [
  "INITIAL_PAGE",
  "PROJECT_LOGO",
  "MESSAGE_LIST",
  "PRE_CHAT_SURVEY",
];

export const PREVIEW_WIDGET_PROPERTY_MAP = {
  DEFAULT: "defaultMessage",
  INSTANT_REPLY: "defaultAnswerPhrase",
  EMAIL_CAPTURE: "leadCaptureMessage",
  OOO_ANSWER_PHRASE: "oooAnswerPhrase",
  PROMPT: "message",
  OOO_LEAD_CAPTURE_GREETING: "oooLeadCaptureGreeting",
  OOO_LEAD_CAPTURE_ACK: "oooleadCaptureAckMessage",
};

export const DEFAULT_ICON = {
  PROJECT_LOGO:
    "https://storage.googleapis.com/livesupport/chat/images/logo-placeholder.svg",
  AUTO_MESSAGE:
    "https://storage.googleapis.com/livesupport/chat/images/auto-message-icon.svg",
  STAFF_ICON: "/images/Staff-Avatar.svg",
  CHAT_WIDGET_ICON:
    "https://storage.googleapis.com/livesupport/chat/images/icon-chat.svg",
};

export const PREVIEW_WIDGET_TITLE = {
  DEFAULT: "Chat Widget",
  INITIAL_PAGE: "Initial View",
  PROJECT_LOGO: "Project Logo",
  MESSAGE_LIST: "Chat View",
  INSTANT_REPLY: "Instant Reply",
  OOO_ANSWER_PHRASE: "Out of Office Instant Message",
  PROMPT: "Prompt",
  PRE_CHAT_SURVEY: "Pre Chat Survey",
};

export const DEFAULT_MESSAGES = {
  INSTANT_REPLY:
    "Hi there, thanks for getting in touch! One of our team members will respond to your message shortly.",
  EMAIL_CAPTURE:
    "We are evolving as each day passes by. Submit your email ID to stay connected with us.",
  OOO_LEAD_CAPTURE_GREETING:
    "Oh no! The team is away right now. Don’t worry, simply post your Email-id here and a way for us to contact you once we are back in office.",
  OOO_LEAD_CAPTURE_ACK:
    "Thank you for providing your email address. We'll reach out to you as soon as we are back.",
  OOO_ANSWER_PHRASE:
    "I’m sorry we are currently closed and can’t respond right now. Please leave a message and we’ll get back to you as soon as possible.",
  PROMPT: "Hi there, we're People, not bots. Any questions, chat us.",
};

export const STAGING_GA4_TRACKING_CODE = "G-6Y5KFWY5LB";
export const LIVE_GA4_TRACKING_CODE = "G-2KLHZC7XCT";

export const SEARCH_FILTERS = {
  MY_CHATS: "My Chats",
  MY_RESOLVED_CHATS: "My Resolved Chats",
  MY_MENTIONED_CHATS: "Mention Chats",
  ALL_CHATS: "All Chats",
  OPEN_CHATS: "Open Chats",
  RESOLVED_CHATS: "Resolved Chats",
  RECEPTIONIST: "Chats taken by Receptionist",
  PENDING_CHATS : "Pending Chats",
  MY_PENDING_CHATS : "My Pending Chats"
};

export const SEARCH_FILTERS_PLACEHOLDER = {
  MY_CHATS: "My Chats",
  ALL_CHATS: "No chats taken yet",
  OPEN_CHATS: "No open chats",
  RESOLVED_CHATS: "No resolved chats",
  RECEPTIONIST: "No chats taken by receptionist",
  MENTION_CHATS: "No mentioned chats",
  PENDING_CHATS : "No Pending Chats"
};

export const LOCAL_STORAGE_KEY = "recentSearchHistory";

export const CUSTOMER_NAME_SEARCH = "CUSTOMER_NAME_SEARCH";

export const COMPANY_NAME_SEARCH = "COMPANY_NAME_SEARCH";

export const TAG_NAME_SEARCH = "TAG_NAME_SEARCH";

export const DISPLAY_VISITOR_PROPERTY_NAME = {
  type: "Type",
  campaignName: "Campaign Name",
  campaignMedium: "Campaign Medium",
  campaignUrl: "Campaign URL",
  campaignSource: "Campaign Source",
  pageTitle: "Page Title",
  sourceUrl : "Landing Page Url",
  lastPageTitle: "Last Page Title",
  lastPageUrl: "Last Page URL",
  /* ip : "IP Address", */
  browserName: "Browser",
  browserVersion: "Browser Version",
  browserLanguage: "Browser Language",
  os: "Operating System",
  iosAppVersion: "iOS App Version",
  iosDevice: "iPhone Device Version",
  iosOsVersion: "iOS Version",
  androidAppVersion: "Android App Version",
  androidDevice: "Device Name",
  androidOSVersion: "Android OS Version",
  firstContacted: "First Contacted",
  companyKey: "Company Key",
  userId: "User Id",
  last_seen_user_agent: "User Agent",

  /* viewportDimensions : "View Port Dimensions",
    deviceDimensions : "Display Dimensions",
    location : "Location",
    companyIndustry : "Campaign Industry",
    companySize : "Company Size",
    lastSeenOnAndroid : "Last Seen On Android",
    lastSeenOnIos : "Last Seen On IOS",
    lastSeenTime : "Last Seen Time",
    location : "Location",
    */
};
export const TIME_TYPES = [
  "firstContacted",
  "lastSeenOnAndroid",
  "lastSeenOnIos",
  "lastSeenTime",
];

export const URL_TYPES = ["lastPageUrl", "sourceUrl", "campaignUrl", "currentUrl"];

export const TEST_PROJECTS = {
  staging: [
    "LS-5964edb7",
    "LS-635ab4e0",
    "LS-894cf565",
    "LS-89c2fa4e",
    "LS-b43c0eb9",
    "LS-bae10234",
    "LS-be800e42",
    "LS-d5b0ef53",
    "LS-20bbe166",
    "LS-07922ce7",
    "LS-0ee18666",
    "LS-153b5ce8",
    "LS-1b4e4e0a",
    "LS-1bc49319",
    "LS-2240ac5b",
    "LS-22598fc1",
    "LS-250c7107",
    "LS-272b01a2",
    "LS-286ba338",
    "LS-28ba2c8b",
    "LS-2b704409",
    "LS-2f5ad0ea",
    "LS-3c4c447f",
    "LS-3e300517",
    "LS-3ec28b00",
    "LS-3fb070c8",
    "LS-44d7ca5a",
    "LS-45c36001",
    "LS-4b59a6d0",
    "LS-4f52782e",
    "LS-52dabcfb",
    "LS-59963543",
    "LS-60ab5b5d",
    "LS-61ba6609",
    "LS-684ff0aa",
    "LS-685488c8",
    "LS-8054bf80",
    "LS-861da393",
    "LS-8cae26dd",
    "LS-8cb7a99e",
    "LS-8ddba236",
    "LS-9234ae75",
    "LS-a477a567",
    "LS-a498fe12",
    "LS-a545f8f4",
    "LS-a5e5a148",
    "LS-ab652e2c",
    "LS-b4acbf8f",
    "LS-b4c7b4fe",
    "LS-b6f177a1",
    "LS-becfc777",
    "LS-c1eb00cf",
    "LS-c2129ab8",
    "LS-c9d1c27e",
    "LS-ccf06d00",
    "LS-cf80f1fd",
    "LS-d4fc8f2c",
    "LS-d6e179a7",
    "LS-d761213c",
    "LS-e2761eb4",
    "LS-e3530bc0",
    "LS-e43565cc",
    "LS-e5c6702d",
    "LS-ea228afb",
    "LS-eb7cbbe3",
    "LS-ec26e884",
    "LS-f33479da",
    "LS-f643a635",
    "LS-f848d2cf",
    "LS-f8d43752",
    "LS-88426432",
    "LS-a1b90948",
    "LS-cd878bc8",
    "LS-d01f3c68",
    "LS-0d1c9d26",
    "LS-1f2e98cc",
    "LS-309c6f40",
    "LS-62b0f965",
    "LS-d53c5daa",
    "LS-e78b1eea",
    "LS-04e35da8",
    "LS-15e56607",
    "LS-183bbbca",
    "LS-1ef9f6ad",
    "LS-1ff7ab31",
    "LS-2d59c872",
    "LS-3d41d3a3",
    "LS-4a950087",
    "LS-5d413ae8",
    "LS-66592ce8",
    "LS-6703ba69",
    "LS-68caf4da",
    "LS-76cb5b7e",
    "LS-7bed10db",
    "LS-8158d2b4",
    "LS-82710d0e",
    "LS-88ab08e1",
    "LS-8ef9c01f",
    "LS-91b128e8",
    "LS-934eb581",
    "LS-bb3926f1",
    "LS-d20e4c1b",
    "LS-fafa28f0",
    "LS-fe4dafd8",
    "LS-fed09958",
    "LS-01e934f2",
    "LS-0afaffd7",
    "LS-943297be",
    "LS-f3af1ed0",
  ],
  live: [
    "LS-320027cc",
    "LS-6668b0e6",
    "LS-81228adf",
    "LS-84c77ac9",
    "LS-e35307e2",
    "LS-fcab97f6",
    "LS-3e12ef5f",
    "LS-041b7a48",
    "LS-10b41589",
    "LS-14e7d549",
    "LS-154f3541",
    "LS-15517f4f",
    "LS-1596c0a7",
    "LS-164dc85d",
    "LS-1980a533",
    "LS-1a2dac05",
    "LS-24db0972",
    "LS-37fa219f",
    "LS-3bd09c16",
    "LS-453184ac",
    "LS-462744c1",
    "LS-566b94c4",
    "LS-5e5107f2",
    "LS-634f097b",
    "LS-803219db",
    "LS-8724d64c",
    "LS-8f0fd5da",
    "LS-934cbabc",
    "LS-97575ed8",
    "LS-998a5839",
    "LS-aeefaca0",
    "LS-b71c742f",
    "LS-bf4fba6d",
    "LS-c8716f55",
    "LS-cb1d1ddb",
    "LS-cd500584",
    "LS-cf3b5c77",
    "LS-cfbde668",
    "LS-d70f94f5",
    "LS-d947570e",
    "LS-dbc07acc",
    "LS-e220865e",
    "LS-eb5d5e63",
    "LS-eead9d1d",
    "LS-f297700d",
    "LS-f7f0933e",
    "LS-0c1e5fc9",
    "LS-25bcc675",
    "LS-35c818b2",
    "LS-45aeb99a",
    "LS-48676298",
    "LS-4a8ee685",
    "LS-4ad18a36",
    "LS-9201bf88",
    "LS-b0f8a512",
    "LS-dd5038da",
    "LS-016c67af",
    "LS-04881a15",
    "LS-0abeccf6",
    "LS-2c3a6513",
    "LS-6264afa4",
    "LS-84f42de0",
    "LS-8ef31f56",
    "LS-ab17761b",
    "LS-c1aecf22",
    "LS-c54770d7",
    "LS-d2eb2557",
    "LS-205013e7",
    "LS-8db3e419",
    "LS-b6739993",
    "LS-e482d3c2",
    "LS-081156ff",
    "LS-2713ed9a",
    "LS-685f7bb7",
    "LS-d8fe3222",
  ],
};

export const INTERNAL_PROJECTS = {
  staging: [
    "LS-018ab86e",
    "LS-07341d0c",
    "LS-0a4a80b2",
    "LS-0e2fc773",
    "LS-0e669a12",
    "LS-14c9a010",
    "LS-1bf36beb",
    "LS-1dede184",
    "LS-1fc09f5d",
    "LS-20250bb2",
    "LS-24fef126",
    "LS-25ae7a60",
    "LS-2d36919a",
    "LS-2fee584c",
    "LS-3d1f4ae1",
    "LS-40ea4119",
    "LS-41c42b7e",
    "LS-4901e9b4",
    "LS-4d3a5ab3",
    "LS-4d88d17b",
    "LS-57d4cbce",
    "LS-5823911c",
    "LS-58c22a6b",
    "LS-592ed5a4",
    "LS-5afcadbc",
    "LS-633540ba",
    "LS-6492c86d",
    "LS-6c2bd843",
    "LS-6c62c4b4",
    "LS-6ecc6dd1",
    "LS-703e3704",
    "LS-72d19828",
    "LS-73c871bd",
    "LS-759c11cb",
    "LS-77a190f3",
    "LS-77ef99b1",
    "LS-78d65a83",
    "LS-7f9644fb",
    "LS-8dff47eb",
    "LS-91b67e53",
    "LS-923d2c44",
    "LS-9973b99f",
    "LS-9da22f0b",
    "LS-a1969eab",
    "LS-a1c4f85b",
    "LS-a5724473",
    "LS-a875dd77",
    "LS-aacb2940",
    "LS-aafc7c8c",
    "LS-ae0b81a5",
    "LS-ae370c31",
    "LS-b106e686",
    "LS-b6888d2a",
    "LS-b99b917a",
    "LS-b9ce4ec8",
    "LS-ba86df06",
    "LS-bbeb3f36",
    "LS-c1663218",
    "LS-c25eb94b",
    "LS-c4fae4e2",
    "LS-d28bc8db",
    "LS-d38295a7",
    "LS-d4e9ef2f",
    "LS-d51b8b2e",
    "LS-d6f4c325",
    "LS-d8d319e3",
    "LS-df5873e1",
    "LS-e581f768",
    "LS-e8693467",
    "LS-f69afada",
    "LS-f6b6bf45",
    "LS-9fcc96da",
    "LS-7fb322af"
  ],
  live: [
    "LS-f41076de",
    "LS-984bf45c",
    "LS-70fe53cb",
    "LS-016371b6",
    "LS-389d1168",
    "LS-64fec8e4",
    "LS-436fb029",
    "LS-9418ffaa",
    "LS-14b68929",
    "LS-7140b122",
    "LS-cb146eee",
    "LS-f823d19d",
    "LS-86e070e0",
    "LS-da44d78b",
    "LS-f1b08e84",
    "LS-cc9fe2a1",
    "LS-85fd177b",
    "LS-f2c8f87a",
    "LS-bf15c287",
    "LS-f28e57fc",
    "LS-e1d5843d",
    "LS-ee1b719c",
    "LS-2b4c081e",
    "LS-cda97f50"
  ],
};
export const GA_DIMENSION_MAP = {
  projectId: "projectId",
  projectType: "projectType",
  emailId:
    "dimension3" /* ,
	visitorId : 'dimension3',
	conversationId : 'dimension4' */,
};

export const INTERNAL_CUSTOM_FIELDS_MAP = {
  uniquePin: "Unique Pin",
  accountNumber: "Account Number",
  companyName: "Company Name",
};

export const HOURS_TYPE = {
  weekdays: "Weekdays",
  allDays: "24/7 - 12:00 AM to 11:59 PM",
  custom: "Custom",
};

export const campaignTypes = {
  chat: "CHAT",
  announcement: "ANNOUNCEMENT",
  email: "EMAIL",
};

export const CAMPAIGN_STATUS_TO_ID = {
  LIVE : "668e085f-c4e7-6eb0-baea-2d5a05e114dd",
  PAUSED : "3f2b51bc-179c-383f-87d3-c59f0d0ead79",
  DRAFT : "73b261db-0c78-5340-88dc-ad5d6ded3f7b",
  DELETE : "04323764-4642-dafb-9c87-3aeac25d9725"
}

export const CAMPAIGN_STATUS_IDS_TO_TEXTS = {
  "668e085f-c4e7-6eb0-baea-2d5a05e114dd" : "Live",
  "3f2b51bc-179c-383f-87d3-c59f0d0ead79" : "Paused",
  "73b261db-0c78-5340-88dc-ad5d6ded3f7b" : "Draft"
}
export const CAMPAIGN_TYPE_TO_NAME = {
  "CHAT" : "Chat",
  "ANNOUNCEMENT" : "Announcement",
  "EMAIL" : "Email"
}
export const defaultCampaignData = {
  campaignStatus: CAMPAIGN_STATUS_TO_ID.DRAFT,
  projectId: "",
  filterQuery: {},
  senderId: "",
  message: "",
  totalNoOfRecipients: 0,
  numberOfDeliveredMsgs: 0,
  numberOfOpenedMsgs: 0,
  numberOfRepliedMsgs: 0,
  lastMessageSentTo: "",
  lastMessagSentAt: "",
  typeOfMessage: "CHAT",
  references: "{}",
  campaignName: "",
  startDate: 0,
  endDate: 1,
  campaignPublishingTime: "",
  campaignFromName: "",
  campaignFromImage: "",
  segmentIds: [],
};

export const DEFAULT_END_DATE = 1;

export const CAMPAIGN_DISPLAY_NAMES = {
  all: "All",
  chat: "Chats",
  email: "Emails",
  announcement: "Announcements",
};

export const CAMPAIGN_TYPE_PLACEHOLDERS_IMAGES = {
  CHAT:
    "https://storage.googleapis.com/livesupport/webapp/images/desk-preview-temp.png",
  ANNOUNCEMENT:
    "https://storage.googleapis.com/livesupport/webapp/images/announcemets-preview-temp.png",
  EMAIL:
    "https://storage.googleapis.com/livesupport/webapp/images/email-preview-temp.png",
};

export const CHATSUPPORT_BRAND_ID = "9b0cfd9c-0704-4982-bb02-48d3f468f732";
//trigger jenkins
export const INTERACTION_TYPE = {
  OVERFLOW : "OVERFLOW",
  DASHBOARD: 'DASH_BOARD',
};

export const TYPING_STATUS = {
  START : "TYPING_START",
  STOP: "TYPING_STOP"
}
export const STYLE_TO_ACTIVE_STATUS_CLASSES = {
  "H1":"header-one",
  "H2": "header-two",
  "ITALIC": "ITALIC",
  "BOLD": "BOLD"
}

export const WIDGET_POSITIONS_MAPPING = {
  "400a5821-2ac2-4344-b8c4-c28dfef882bb": "Bottom Right",
  "767ff2dc-8e23-4996-beb7-60c1bdbbba52": "Bottom Left",
  "9a4ce71a-442c-11eb-b378-0242ac130002": "Top Right",
  "28f6928d-187b-43a8-a5b5-1526e11b705d": "Top Left",
}

export const WIDGET_POSITIONS = [
  "400a5821-2ac2-4344-b8c4-c28dfef882bb",
  "767ff2dc-8e23-4996-beb7-60c1bdbbba52",
  "9a4ce71a-442c-11eb-b378-0242ac130002",
  "28f6928d-187b-43a8-a5b5-1526e11b705d",
];

export const WIDGET_TYPE = {
  DEFAULT : "9f305580-3df8-11eb-b378-0242ac130002",
  ICON : "06fac4f2-3df9-11eb-b378-0242ac130002"
}

export const CONTACT_DATATYPES_NUMBERS = [
  "totalVisit",
  "firstContacted",
  "lastContacted",
  "companySize",
  "lastSeenTime",
  "webSessions",
  "androidWebSessions",
  "iosWebSessions",
  "lastSeenOnAndroid",
  "lastSeenOnIos",
  "viewportHeight",
  "viewportWidth",
  "deviceHeight",
  "deviceWidth",
  "createdDate"
]

export const CONTACT_DATATYPES_DATE = [
  "createdDate",
  "lastSeenTime",
  "firstContacted",
  "lastContacted",
]

export const SURVEY_TYPE = {
  POST: "Post Chat",
  PRE: "Pre Chat"
}

export const EMOJI_TYPES = {
  "5" : "0x1F929",
  "4" : "0x1F601",
  "3" : "0x1f610" ,
  "2" : "0x1F614",
  "1" : "0x1F620",
}

export const FEEDBACK_STATUSES = {
  FEEDBACK_STATUS_SUBMITTED:"67077ca9-9644-49be-a0fa-79867d798722",
  FEEDBACK_STATUS_PENDING:"c817a083-5572-4b39-8236-92e480e1ee99"
}

export const EMOJI_URLS = {
  "5":"https://storage.googleapis.com/livesupport/chat/images/5-stars.png",
  "4":"https://storage.googleapis.com/livesupport/chat/images/4-stars.png",
  "3":"https://storage.googleapis.com/livesupport/chat/images/3-stars.png",
  "2":"https://storage.googleapis.com/livesupport/chat/images/2-stars.png",
  "1":"https://storage.googleapis.com/livesupport/chat/images/1-stars.png",
}

export const WIDGET_STATUS = {
  "INACTIVE":"inactive",
  "ACTIVE":"active",
  "INVALID":"invalid",
  "BROKEN":"broken",
}

export const DEFAULT_WIDGET_HEADER = "We're happy to chat.";

export const DEFAULT_WIDGET_BUBBLE_CONTENT = "Chat with us";

export const MY_CHAT_FILTERS = {
  OPEN_CHATS : "Active",
  MENTION_CHATS: "Mentions",
  PENDING_CHATS: "Pending",
  RESOLVED_CHATS: "Resolved"
}

export const ALL_CHAT_FILTERS = {
  OPEN_CHATS : "Active",
  PENDING_CHATS: "Pending",
  RESOLVED_CHATS: "Resolved",
  RECEPTION_CHATS: "Reception"
}


export const CHAT_FILTERS = {
  OPEN_CHATS : "OPEN_CHATS",
  PENDING_CHATS: "PENDING_CHATS",
  RESOLVED_CHATS: "RESOLVED_CHATS",
  RECEPTION_CHATS: "RECEPTION_CHATS",
  MENTION_CHATS: "MENTION_CHATS",
}
export const CHAT_FILTERS_CHAT_STATUS = {
  OPEN_CHATS : "O",
  PENDING_CHATS: "P",
  RESOLVED_CHATS: "C",
  RECEPTION_CHATS: "R",
  MENTION_CHATS: "M",
}

export const SESSION_BY_TOKEN_ENUMS = {
  SUCCESS_CODE: 200,
  BAD_REQUEST: 400,
  MISSING_REQUIRED_PARAM: 'MISSING_REQUIRED_PARAM',
  INVALID_VALUE: 'INVALID_VALUE',
  INVALID_TOKEN: 'Invalid Token Provided',
  TOKEN_NOT_PROVIDED: 'User Access Token not provided',
}
export const RATE_LIMIT_MESSAGE = {
  EXCEED_LIMIT: "Exceeded your limit"
}

export const HTML_SUPPORTED_CAMPAIGN = [
  "CAMPAIGN_ANNOUNCEMENT",
  "CAMPAIGN_EMAIL",
]
export const SIGNUP_SUCCESS = "signup-success";

export const DROPDOWN_TAG_CLASSNAME = {
  ACTIVE: 'tag-drop selected',
  DEFAULT: 'tag-drop'
}
export const FREE_PLAN = "FREE";
export const STARTUP_PLAN_ID = "5042";
export const SUPPORT_PLAN_ID = "5043";
export const PRO_PLAN_ID = "5044";
export const UPGRADE_PLAN = "upgradePlanId";

export const CAMPAIGN_DEFAULT_DATE_VALUES ={
  START_DATE: "Right Away",
  END_DATE: "Never"
}

export const RECIPIENTS_LABEL = {
  SELECT_RECIPIENTS: "Select Recipients",
  RECIPIENTS: "Recipients"
}

export const STRINGIFIED_BOOL = {
  TRUE: "true",
  FALSE: "false"
}

export const IS_CAMPAIGN_FROM_DASHBOARD = "isCampaignFromDashboard";

export const CS_BUTTON = {
  PRIMARY: "cs-primary-btn",
  DISABLED: "cs-disabled-btn",
};

export const CS_CONVERSATION_HEADER_HEIGHT = 90;
export const CS_TYPING_HEIGHT = 113;

export const POSITION ={
  TOP: "top",
  BOTTOM: "bottom"
}

export const STYLE = {
  OVERFLOW: "overflow",
  Z_INDEX: "z-index"
}
export const PLAN_NAME_DETAILS = {  
  "5042" : "STARTUP",
  "5043" : "SUPPORT",
  "5044" : "PRO"
}
export const TYPE_OF_CAMPAIGN_STATS = {
  NOT_DELIVERED: "notDelivered",
  DELIVERED: "delivered",
  CLICKED: "clicked",
  OPENED: "opened",
  REPLIED: "replied",
}

export const EMAIL_CAMPAIGN_VIEW = "View";

export const TAG_ERROR_MESSAGE = {
  DUPLICATE:
    "Tag is already in use. Choose a tag from your list of stored tags.",
  INVALID:
    "Tag name is incorrect. At least two characters and/or numbers must be present in the tag name.",
};

export const ABNORMAL_DISCONNECTION = {
  NETWORK:
    "This chat has been disconnected due to some connectivity issue. The chat will be routed to another agent.",
  SOCKET:
    "This chat has been disconnected due to some connectivity issue. The chat will be routed to another agent.",
  PLACE_HOLDER: "This chat has ended. You are no longer able to send messages.",
};

export const ADVANCED_SEARCH = {
  CUSTOMER: "SN",
  COMPANY: "SC",
  TAG: "ST"
}

export const SEARCH_LABEL = {
  TAG: "Tags:",
  CUSTOMER: "Customer name:",
  COMPANY: "Company name:"
}

export const OVERFLOW_STATUS = {
  NOT_OVERFLOWED: "NOT_OVERFLOWED",
  OVERFLOWED: "OVERFLOWED"
}

export const AW_LOGGEDIN_PRESENCE = "aw_currentuser_presence_sync";

export const ANSWERING_MEDIUMS = {
  ANYWHERE: "a2728780-8f2c-4139-be86-fcb136efc42d",
  DASHBOARD: "fbf94826-c524-11ec-9d64-0242ac120002"
}

export const DELIMETER = "-";

export const AW_CHATSUPPORT_ASSETS = "https://assets.chatsupport.co/staging/components/aw-chatsupport.css";
export const AW_CHATSUPPORT_ASSETS_LIVE = "https://assets.chatsupport.co/components/aw-chatsupport.css";
export const WEBAPP_ENVIRONMENT_TYPE = {
  DASHBOARD: "DASHBOARD",
  OVERFLOW: "OVERFLOW"
}

export const ERROR_MESSAGE = {
  AUTHENTICATION: "Authentication failed!",
  CONTACT_DETAILS_MISSING: {
    MESSAGE: "Contact Details Empty",
    STACK:
      "UserId and Key are not available for contact, details are sent empty in request payload",
  },
  INVALID_AW_USER_PRESENCE_PAYLOAD: {
    MESSAGE: "Invalid presence payload of an AW user",
    STACK: "Getting invalid presence payload for this user id : ",
  },
};

export const CONVERSATION_LIST_CONTAINER_CLASSNAME = "cs-chat-list";

export const CHAT_ANSWERING_POPUP_NOTIFICATION_SOUND = "https://assets.chatsupport.co/chat/sounds/chat_notifcations_sound.mp3";

export const SCROLL_HEIGHT_FROM_BOTTOM = 140;

export const MAIN_TAB_APP = "main_tab_APP";

export const VISITOR_INITIATED_CONVERSATION = "Visitor Initiated Conversation";

export const SETTINGS_COMPONENT_MODE = "settingsComponentAppMode";

export const STATUS_TRACKER_TOOL = {
  overflow: "Overflow"
}

export const CHAT_ASSIGN_TYPES = [
  "CHAT_ASSIGN",
  "CHAT_ASSIGN_ON_CONNECT"
]

export const CHAT_AUTO_ASSIGN_AVAILABLE_TYPES = [
  "CHAT_AUTO_ASSIGN_AVAILABLE",
  "CHAT_AUTO_ASSIGN_AVAILABLE_ON_CONNECT"
]
export const SIGN_UP_ERROR_MESSAGE = {
  PASSWORD_EMPTY: "Password can't be empty",
  PASSWORD_BEGIN_OR_END_WITH_SPACE:
    "Password must not begin or end with a space",
  PASSWORD_MINIMUM_LENGTH_SIX: "Password should be a minimum of six characters",
  PASSWORD_MAXIMUM_LENGTH_SIXTY_FOUR:
    "Password must be no more than 64 characters",
  ATLEAST_ONE_LETTER: "Password must contain at least one letter",
  ATLEAST_ONE_NUMBER: "Password must contain at least one number",
  USERNAME_EMPTY: "Name can't be empty",
  USERNAME_MAXIMUM_LENGTH_SIXTY_FOUR: "Name must be no more than 64 characters",
  USERNAME_BEGIN_OR_END_WITH_SPACE: "Name must not begin or end with a space",
  USERNAME_EMOJI: "Name must not contain Emoji",
  USERNAME_SPECIAL_CHARACTER:
    "Name can contain only letters, spaces, hyphens, apostrophe and period.",
  USERNAME_ONE_LETTER: "Name must contain at least one letter",
};
export const AR_WINDOW_MESSAGE = "ar-window-message";  
export const INCOMING_WIDGET_POPUP_EVENT = "incoming-widget";
export const CONNECT_USER_AVATAR_COLOR  = {
  ACTIVE : "#FFFFFF",
  INACTIVE : "#EEF0F2"
}
export const LIVE = "live";
export const STAGING = "staging";
export const LOCAL = "local";
export const CONNECT_ANSWERING_POPUP_TIME = 13 * 1000;
export const WIDGETS_WITH_EXTENDED_AUTO_OVERFLOW_TIME = [
  "LS-b9de92ad",
  "LS-85cc0009",
  "LS-07744501",
  "LS-5b3d5f4e",
  "LS-7216a089",
];
export const AUTO_OVERFLOW_TIME = 12 * 1000;
export const AUTO_OVERFLOW_TIME_EXTENDED = 30 * 1000;
export const MAX_MESSAGE_COUNT = 6;
export const DATE = {
  CREATED_DATE: "createdDate"
}
export const GUEST_EVENT_TYPE = {
  GUEST_ADDED: "GUEST_ADDED",
  GUEST_ONLINE: "GUEST_ONLINE",
  GUEST_AWAY: "GUEST_AWAY",
  GUEST_REMOVE:"GUEST_REMOVE"
}
export const GUEST_PRESENCE = "GUEST_PRESENCE";
export const PRESENCE_CHANNEL = "presence/guest/";

export const LIVE_APP_MODE_HOST = [
  "chatsupport.co",
  "app.chatsupport.co",
  "anywherehelp.io",
  "app.anywherehelp.io",
  "beta.chatsupport.co", 
  "chatsupport.answerforce.app",
  "webchat.hellosells.app",
  "webchat.wellreceived.app"
];

export const STAGING_APP_MODE_HOST = [
  "staging.chatsupport.co",
  "staging.app.chatsupport.co"
];
export const EMAIL_SUBSCRIPTION_FILTER_TYPE = {
  SUBSCRIBED: "subscribed",
  UNSUBSCRIBED: "unsubscribed"
}

export const EMAIL_SUBSCRIPTION_PROPERTY = "emailSubscription"

export const EMAIL_SUBSCRIPTION_UUID = "c20807be-c5c9-46bb-83ea-e4f4caca2879";
export const EMAIL_UNSUBSCRIPTION_UUID = "8ccce0d9-6cee-4c1d-a7f3-a70032cdd2ce";
export const MANAGE_WIDGET="ManageWidget";
export const NOTIFICATIONS="Notifications";
export const BILLING = "Billing";
export const ACTIVE = "active";
export const EMPTY_STRING = "";
export const QUEUE_TYPE = {
  CONVERSATION: "conversation",
  VISITOR: "visitor"
}
export const ACTIVE_EDIT_TAB = {
  PERMISSIONS: "permissions",
  CONNECT_CHATS: "connectChats"
}

export const CONNECT_SUBSCRIBE_SYNC = {
  SUBCRIBE_SYNC: "subscribeSync",
  PRESENCE_UNSUBSCRIBE: "SYNC_UNSUBSCRIBE",
};

export const CONNECT_AUTH = {
  ALLOWED: "3e12dd40-d179-4636-bdbf-09311ce1d916",
  NOT_ALLOWED: "2b24900c-c881-4945-8646-c46ef13b2930"
}

export const FALLBACK_VALUES = {
  SKILL: "Skill Not Assigned",
  ROLE: "Role Not Assigned"
}

export const USER_STATUS = {
  ACTIVE: "ACTIVE"
}

export const MAX_CONNECT_WIDGET_TO_SHOW = 3

export const OVERFLOW_CHAT_DISCONNECTION_TYPES = [
  MESSAGE_TYPE.overflow_chat_disconnected,
  MESSAGE_TYPE.overflow_chat_disconnected_abnormally,
]
export const AGENT_PRESENCE_CHANNEL_PREFIX = "presence/agent/";

export const DASHBOARD_SKILL = {
  MEMBER : "MEMBER"
}

export const DASHBOARD_ROLE = {
  EXPERT : "Expert"
}

export const DOCKER_UNREAD_COUNT_CONNECT = {
  DEFAULT: 0,
  COUNT: 1
}

export const TYPES_TO_ADD_CONNECT_CHAT_IN_RECENT_LIST = [
  MESSAGE_TYPE.chat,
  MESSAGE_TYPE.attachment,
  MESSAGE_TYPE.dashboard_chat_closed_no_response,
  MESSAGE_TYPE.dashboard_visitor_left
]

export const PLAN_ID = "planId";
export const USER_ID = "userId";
export const EMAIL_ID_KEY = "login";
export const USER_ID_KEY = "ls_user_id";
export const OVERFLOW_CHAT_CLOSED_TYPES = [
  ...OVERFLOW_CHAT_DISCONNECTION_TYPES,
  MESSAGE_TYPE.overflow_chat_closed,
  MESSAGE_TYPE.overflow_chat_closed_no_response,
  MESSAGE_TYPE.overflow_chat_takenover_by_staff,
  MESSAGE_TYPE.overflow_chat_closed_on_available,
  MESSAGE_TYPE.overflow_chat_interrupted,
  MESSAGE_TYPE.overflow_prompt_closed_on_ignore,
];

export const SB_WINDOW_EVENTS = {
  close: "sb-window-close",
  focus: "sb-window-status",
};

export const KEYS = {
  ENTER: "Enter",
  ESCAPE: "Escape",
};

export const AGENT_NOTIFIABLE_TYPES = [
  MESSAGE_TYPE.overflow_chat_closed_no_response,
];
export const CONNECT_CHATS_NOT_ASSIGNED = "Widget Not Assigned";

export const MESSAGE_TYPE_LOWERCASE = {
  chat : "chat"
}

export const REACT_MAJOR_VERSION_18 = 18;

export const USER_SESSION_SYNC = "user-session-sync";

export const SOCKET_EVENTS = {
  CAMPAIGN_UPDATE: "CAMPAIGN-UPDATE",
  CAMPAIGN_DELETE: "CAMPAIGN-DELETE",
  CAMPAIGN_NEW: "CAMPAIGN-NEW",
  CHAT: "chat",
  SUBSCRIBED: "subscribed",
  BROADCAST_STATS_SYNC: "BROADCAST_STATS_SYNC",
};

export const SINGULAR_TIME_INDICATORS = {
  just_now: "just now",
  a_minute_ago: "a minute ago",
  an_hour_ago: "an hour ago",
  yesterday: "yesterday",
  a_month_ago: "a month ago",
  one_year_ago: "one year ago",
};

export const PLURAL_TIME_INDICATORS_SUFFIX = {
  minutes_ago: " minutes ago",
  days_ago: " days ago",
  hours_ago: " hours ago",
  months_ago: " months ago",
  years_ago: " years ago",
}

export const MILLIS = {
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  month: 2628000000,
  year: 31536000000,
  two_minutes: 120000,
  two_hours: 7200000,
  two_days: 172800000,
  two_months: 5256000000,
  two_years: 63072000000,
}

export const DATA_TYPES = {
  number: "number",
}

export const INPUT_TEXT_LIMIT = {
  userName: 64,
  designation: 64,
};

export const DEFAULT_CAMPAIGN_CONTENT = "+_add_content";

export const ANSWERING_MEDIUMS_HEADER = "answering-mediums";

export const READABLE_MESSAGE_TYPES = ["CHAT", "ATTACHMENT", "CHAT_LEAD_CAPTURE"];