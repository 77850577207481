import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TeamEditProfileComponent from "./TeamEditProfileComponent.jsx";
import { getStaffContact } from "../Action/StaffContactAction";
import {
  getUserProject,
  updateUserProjectRequested,
} from "../Action/UserProjectMappingAction";
import { updateStaffRequested } from "../../../actions/UserAction";

import * as VoiceboxActions from "../../../actions/VoiceboxAction";

const TeamEditProfileContainer = (props) => {
  const {
    userLocation,
    staffContactReducer,
    accountStaffs,
    userProjectMappingReducer,
    projectReducer,
  } = useSelector((state) => ({
    userLocation: state.UserReducer.locationDetails,
    staffContactReducer: state.StaffContactReducer,
    accountStaffs: state.StaffReducer.accountStaffs,
    userProjectMappingReducer: state.UserProjectMappingReducer,
    projectReducer: state.ProjectReducer,
  }));

  const [activeStaff, setActiveStaff] = useState({});
  const [activeStaffContact, setActiveStaffContact] = useState({});
  const [activeUserProjects, setActiveUserProjects] = useState([]);
  const [accountProjects, setAccountProjects] = useState([]);
  const [accountMappingConfigs, setAccountMappingConfigs] = useState({});
  const [accountMappingProjects, setAccountMappingProjects] = useState({});

  const dispatch = useDispatch();

  const setDefaultState = () => {};
  useEffect(() => {
    let projectKey = props.params.projectkey;
    let staffId = props.params.staffid;
    if (
      staffId != "invite" &&
      (!staffContactReducer ||
        (!!staffContactReducer &&
          !staffContactReducer.contactMap.hasOwnProperty(staffId) &&
          staffContactReducer.isFetching != true))
    ) {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "Loading...",
          dismissAfter: 2000,
        })
      );
      dispatch(getStaffContact(staffId));
    }
    // else if(staffContactReducer.contactMap.hasOwnProperty(staffId) )
    // {
    //         setActiveStaffContact(staffContactReducer.contactMap[staffId]);
    // }

    if (
      staffId != "invite" &&
      (!userProjectMappingReducer ||
        !userProjectMappingReducer.userProjectMapping.hasOwnProperty(
          staffId
        )) &&
      userProjectMappingReducer.isFetching == false
    ) {
      dispatch(getUserProject(staffId));
    }
    // else if(userProjectMappingReducer.userProjectMapping.hasOwnProperty(staffId))
    //     setActiveUserProjects(userProjectMappingReducer.userProjectMapping[staffId])

    // if(userProjectMappingReducer.projectMap.hasOwnProperty(staffId)
    //     && Object.keys(userProjectMappingReducer.projectMap[staffId]).length > 0)
    //     setAccountProjects(Object.values(userProjectMappingReducer.projectMap[staffId]))

    // if(accountStaffs.hasOwnProperty(staffId))
    //     setActiveStaff(accountStaffs[staffId]);
  });

  useEffect(() => {
    let staffId = props.params.staffid;

    if (staffContactReducer.contactMap.hasOwnProperty(staffId)) {
      setActiveStaffContact(staffContactReducer.contactMap[staffId]);
    }
  }, [staffContactReducer.isFetching]);

  useEffect(() => {
    let staffId = props.params.staffid;
    if (userProjectMappingReducer.userProjectMapping.hasOwnProperty(staffId))
      setActiveUserProjects(
        userProjectMappingReducer.userProjectMapping[staffId]
      );

    if (
      userProjectMappingReducer.projectMap.hasOwnProperty(staffId) &&
      Object.keys(userProjectMappingReducer.projectMap[staffId]).length > 0
    ) {
      setAccountProjects(
        Object.values(userProjectMappingReducer.projectMap[staffId])
      );
    }

    if (
      userProjectMappingReducer.projectMap.hasOwnProperty(staffId) &&
      Object.keys(userProjectMappingReducer.chatConfigMap[staffId]).length > 0
    ) {
      setAccountMappingConfigs(
        userProjectMappingReducer.chatConfigMap[staffId]
      );
    }

    if (
      userProjectMappingReducer.projectMap.hasOwnProperty(staffId) &&
      Object.keys(userProjectMappingReducer.projectMap[staffId]).length > 0
    ) {
      setAccountMappingProjects(userProjectMappingReducer.projectMap[staffId]);
    }
  }, [
    userProjectMappingReducer.userProjectMapping,
    userProjectMappingReducer.isFetched,
  ]);

  useEffect(() => {
    let staffId = props.params.staffid;
    if (accountStaffs.hasOwnProperty(staffId))
      setActiveStaff(accountStaffs[staffId]);
  }, [accountStaffs]);

  const getEventHandler = () => {
    let eventHandler = {};
    // eventHandler["switchActiveStaff"] = switchActiveStaff;
    return eventHandler;
  };

  const handleInputFieldChange = (attrName) => {
    const attributeValue = event.target.value;
    let attribute = attrName;
    let emailOrMobile = contactState.linkedContactMethods;

    if (event.target.parentElement.className.includes("active")) {
      event.target.parentElement.className = "cs-input-field ";
    }

    let className = event.target.parentElement.className;

    if (
      attribute === "role" &&
      (isValidName(attributeValue) || isEmpty(attributeValue))
    ) {
      setContact((prevState) => {
        return {
          ...prevState,
          userState: { ...prevState.userState, role: attributeValue },
        };
      });
    } else if (attribute === "role") {
      if (!className.includes("error")) addErrorClass(event);
      return;
    }

    if (
      validateForm(attribute, attributeValue) ||
      attribute === "displayNumber"
    ) {
      if (attribute === "fullName" && isValidName(attributeValue)) {
        let fullNameArr = attributeValue.replace(/\s\s+/g, " ").split(" ");
        let lastName = fullNameArr.length > 1 ? fullNameArr.pop() : "";
        let firstName = fullNameArr.join(" ");
        setContact((prevState) => {
          return {
            ...prevState,
            userState: {
              ...prevState.userState,
              firstName: firstName,
              lastName: lastName,
            },
          };
        });
      } else if (attribute === "fullName") {
        if (!className.includes("error")) addErrorClass(event);
        return;
      }
      if (
        attribute === "email" ||
        attribute === "phone" ||
        attribute === "displayNumber"
      ) {
        if (
          attribute === "email" &&
          !isEmail(attributeValue) &&
          !className.includes("error")
        ) {
          addErrorClass(event);
          return;
        }

        if (
          (attribute === "phone" || attribute === "displayNumber") &&
          !isEmpty(attributeValue) &&
          !isValidPhoneNumber(attributeValue) &&
          !className.includes("error")
        ) {
          addErrorClass(event);
          return;
        }

        let contactMethodId = event.target.dataset.id || "newcontactmethod";
        contactMethodId = contactMethodId.includes("newcontactmethod")
          ? createUUId()
          : contactMethodId;
        if (event.target.dataset.id !== contactMethodId)
          event.target.dataset.id = contactMethodId;

        if (attribute === "displayNumber")
          setContact((prevState) => {
            return {
              ...prevState,
              userState: {
                ...prevState.userState,
                displayMobileNumber: event.target.value,
              },
            };
          });
        updateContactMethod(
          emailOrMobile,
          contactMethodId,
          attributeValue,
          attribute
        );
      } else
        setContact((prevState) => {
          return {
            ...prevState,
            userState: { ...prevState.userState, [attribute]: attributeValue },
          };
        });
    } else if (
      !className.includes("error") &&
      attribute !== "displayNumber" &&
      attribute !== "role"
    )
      addErrorClass(event);
  };

  const updateUserProfile = (updatedMap) => {
    dispatch(updateStaffRequested(updatedMap, true));
    dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
    dispatch(updateUserProjectRequested());
  };

  setDefaultState();
  return (
    <TeamEditProfileComponent
      parentProps={props}
      showEditProfile={document.location.pathname.includes("teamedit")}
      userLocation={userLocation}
      activeStaffContact={activeStaffContact}
      activeUserProjects={activeUserProjects}
      activeStaff={activeStaff}
      accountProjects={accountProjects}
      accountMappingConfigs={accountMappingConfigs}
      accountMappingProjects={accountMappingProjects}
      updateUserProfile={updateUserProfile}
    />
  );
};
export default TeamEditProfileContainer;
