import { ISignUpDTO } from 'src/features/authorization/api/types'
import { ISignUpSuccessDTO } from 'src/features/authorization/store/types'

export const REQUEST_SIGN_UP = 'auth/request-sign-up' as const
export const REQUEST_SIGN_UP_SUCCESS = 'auth/request-sign-up-success' as const

export const requestSignUp = (payload: ISignUpDTO) => ({
  type: REQUEST_SIGN_UP,
  payload,
})

export const requestSignUpSuccess = (data: ISignUpSuccessDTO) => ({
  type: REQUEST_SIGN_UP_SUCCESS,
  payload: data,
})

export const REQUEST_SESSION_STEP_2 = 'auth/request-session-step-2' as const

export const requestSessionStep2 = () => ({
  type: REQUEST_SESSION_STEP_2,
})
export const REQUEST_SESSION_STEP_3 = 'auth/request-session-step-3' as const

export const requestSessionStep3 = (code: string) => ({
  type: REQUEST_SESSION_STEP_3,
  payload: {
    code,
  },
})

export const SET_ERROR = 'auth/error' as const

export const setError = (message: string) => ({
  type: SET_ERROR,
  payload: {
    message,
  },
})

export const INITIALIZE = 'auth/initialize' as const
export const initializeAuth = (widgetId: string, userId: string) => ({
  type: INITIALIZE,
  payload: {
    widgetId,
    userId,
  },
})

export type IAuthActions = ReturnType<
  | typeof requestSignUp
  | typeof requestSignUpSuccess
  | typeof setError
  | typeof requestSessionStep2
  | typeof requestSessionStep3
  | typeof initializeAuth
>
