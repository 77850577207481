import * as R from 'ramda'

export const createSpacing = R.compose(
  R.reduce(
    (carry, value) =>
      R.set(R.lensProp<string>(value), `${value / 10}rem`, carry),
    {}
  ),
  () => R.range(1, 200)
)
