import cookie from "react-cookie";
import { CLIENT_LOGIN_REFERENCE } from "./Constants.js";

export const Authenticator = (nextState, replace) => {
  if (!isAuthenticated()) {
    console.info("nextState =>", nextState);
    console.log("Redirect to login!");
    replace({
      pathname: "/login",
      state: {
        nextPathname: nextState.location.pathname,
        search: nextState.location.search,
      },
    });
  }
};

export const isAuthenticated = () => {
  console.log("clr===>>", cookie.load(CLIENT_LOGIN_REFERENCE));

  if (cookie.load(CLIENT_LOGIN_REFERENCE)) return true;
  return false;
};
