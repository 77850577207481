import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import {
  selectUserId,
  selectWidgetId,
} from 'src/features/authorization/store/selectors'
import { ColorInput } from 'src/features/form/components/ColorInput'
import { ImageInput } from 'src/features/form/components/ImageInput'
import { OptionsRadio } from 'src/features/form/components/OptionsRadio'
import { ToggleView } from 'src/features/ui/components/ToggleView'
import { Link2, Text1 } from 'src/features/ui/components/Typography'
import { DesktopOnly, MobileOnly } from 'src/features/ui/components/Visibility'
import { WidgetTypeRadio } from 'src/features/wizard/setup/components/WidgetTypeRadio'
import {
  colors,
  options,
  validationSchema,
} from 'src/features/wizard/setup/forms/Setup/data'
import {
  ContainerButtons,
  FormContent,
  StyledButton,
} from 'src/features/wizard/setup/forms/Setup/styled'
import { SyncValues } from 'src/features/wizard/setup/forms/Setup/SyncValues'
import { IProps } from 'src/features/wizard/setup/forms/Setup/types'
import { requestUploadIcon } from 'src/features/wizard/setup/store/actions'
import {
  selectColor,
  selectIconUrls,
  selectIsUploadingIcon,
  selectPreviewUrl,
  selectValues,
} from 'src/features/wizard/setup/store/selectors'
import { IAppState } from 'src/utils/state/store'

const getColor = (color: string): string =>
  color.includes('#') ? color : `#${color}`

export const SetupFormComponent: FC<IProps> = ({
  initialValues,
  widgetColor,
  widgetId,
  userId,
  isUploading,
  iconsUrls,
  handleFileUpload,
  handleSubmit,
  isLoading,
  buttonLabel,
  shouldHideSkipButton,
  isMoreAlwaysVisible,
  formFooterMarginTop,
  maxSubmitButtonWidth,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    validationSchema={validationSchema}
  >
    <Form>
      <FormContent>
        <Text1 block>Choose the primary widget color</Text1>
        <Margin top={14} />
        <ColorInput name="color" colorsSelection={colors} />
        <Margin top={40} />
        <Text1 block>Select a widget icon</Text1>
        <Margin top={14} />
        <ImageInput
          imageSelection={iconsUrls}
          isUploading={isUploading}
          onFileUpload={handleFileUpload}
          color={getColor(widgetColor)}
          name="icon"
        />
        <Margin top={40} />
        <ToggleView isVisibleDefault={isMoreAlwaysVisible}>
          <>
            <WidgetTypeRadio name="widgetType" label="Select widget icon" />
            <Margin top={40} />
            <OptionsRadio
              name="position"
              label="Choose widget position"
              options={options}
            />
          </>
        </ToggleView>
      </FormContent>
      <ContainerButtons marginTop={formFooterMarginTop}>
        {!shouldHideSkipButton && (
          <Link2
            href={`/app/${widgetId}/chat/guests/D/O/N/${userId}`}
            color="raven"
            underline
          >
            Not now, I’ll do it later.
          </Link2>
        )}
        <StyledButton
          type="submit"
          color="primary"
          isLoading={isLoading}
          noLeftMargin={shouldHideSkipButton}
          maxWidth={maxSubmitButtonWidth}
        >
          <MobileOnly>{buttonLabel.mobile}</MobileOnly>
          <DesktopOnly>{buttonLabel.mobile}</DesktopOnly>
        </StyledButton>
      </ContainerButtons>
      <SyncValues />
    </Form>
  </Formik>
)

const mapStateToProps = (state: IAppState) => ({
  initialValues: selectValues(state.setup),
  widgetColor: selectColor(state.setup),
  widgetId: selectWidgetId(state.auth),
  userId: selectUserId(state.auth),
  isUploading: selectIsUploadingIcon(state.setup),
  iconsUrls: selectIconUrls(state.setup),
  previewUrl: selectPreviewUrl(state.setup),
})

const mapDispatchToProps = {
  handleFileUpload: requestUploadIcon,
}

export const SetupForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupFormComponent)
