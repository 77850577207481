import React, { useState } from "react";

const YourWidget = (props) => {
  const [showImgTag, setShowImgTag] = useState(true);
  const handleClick = (e) => {
    e.target.dataset.projectkey = props.projectKey;
    props.navigateProject(e);
  };

  const getBackGroundColor = () => {
    let color = "";
    if (props.widgetColor && props.widgetIcon === "DEFAULT")
      color = "#" + props.widgetColor;
    else if (!props.widgetColor) color = "#3c4859";
    else color = "#ffffff";

    return color;
  };

  const onImgLoadError = () => {
    setShowImgTag(false);
  };

  const getPTagStyle = () => {
    let domainName = props.domainName ? props.domainName : "";
    // console.log("To check style :", domainName);
    if (domainName.length === 0) {
      return { margin: 0 };
    } else {
      return {};
    }
  };

  return (
    <li onClick={handleClick}>
      <div>
        <figure style={{ backgroundColor: getBackGroundColor() }}>
          <i
            style={{
              border: props.widgetColor
                ? "2px solid #" + props.widgetColor
                : "2px solid #3c4859",
            }}
          ></i>
          {showImgTag && props.logoUrl ? (
            <img src={props.logoUrl} onError={onImgLoadError} />
          ) : (
            ""
          )}
        </figure>
        <label className="fs-exclude">{props.projectName}</label>
        <p className="fs-exclude" style={getPTagStyle()}>
          {props.domainName &&
          props.domainName !== "" &&
          props.domainName != "nourl"
            ? props.domainName
            : ""}
        </p>
      </div>
    </li>
  );
};

export default YourWidget;
