import {
  INITIALIZE_RESET_REQUEST,
  INITIALIZE_RESET_RESPONSE,
  INITIALIZE_RESET_FAILED,
  CLEAR_ERROR,
  UPDATE_VERIFICATION_ID,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RESPONSE,
  RESET_PASSWORD_FAILED,
  VERIFICATION_ID_EXPIRED,
  GENERAL_ERROR_MESSAGE,
} from "../actions/ResetPasswordAction";

const ResetPasswordReducer = (
  state = {
    isInitializingReset: false,
    isInitializedReset: false,
    isResetting: false,
    isResetted: false,
    login: "",
    error: "",
    passwordError: "",
    generalErrorMessage: "",
    verificationId: "",
    contactId: "",
  },
  action
) => {
  switch (action.type) {
    case INITIALIZE_RESET_REQUEST:
      return {
        ...state,
        isInitializingReset: true,
        login: action.login,
      };
    case INITIALIZE_RESET_RESPONSE:
      return {
        ...state,
        isInitializingReset: false,
        isInitializedReset: true,
        login: "",
        error: "",
        expired: false,
      };
    case INITIALIZE_RESET_FAILED:
      return {
        ...state,
        isInitializingReset: false,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: "",
        passwordError: "",
      };
    case UPDATE_VERIFICATION_ID:
      return {
        ...state,
        verificationId: action.verificationId,
        contactId: action.contactId,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetting: true,
      };
    case RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        isResetting: false,
        isResetted: true,
        passwordError: "",
      };
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isResetting: false,
        passwordError: action.passwordError,
      };
    case GENERAL_ERROR_MESSAGE:
      return {
        ...state,
        generalErrorMessage: action.errorMessage,
      };
  }
  return state;
};

export default ResetPasswordReducer;
