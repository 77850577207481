import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { requestSessionStep3 } from 'src/features/authorization/store/actions'
import { Layout, Left } from 'src/features/ui/components/Layout/styled'
import { Spinner } from 'src/features/ui/components/Spinner'
import { IProps } from 'src/pages/SessionStep3/types'
import { searchParams } from 'src/utils/router/utils/params'

export const SessionStep3Component: FC<IProps> = ({ sessionStep3 }) => {
  const location = useLocation()

  const code = searchParams('code', location.search)

  useEffect(() => {
    if (code) {
      sessionStep3(code)
    }
  }, [])

  return (
    <Layout shouldHideGradient>
      <Left shouldCenterContent>
        <Spinner size={50} />
      </Left>
    </Layout>
  )
}

const mapDispatchToProps = {
  sessionStep3: requestSessionStep3,
}

export const SessionStep3 = connect(
  null,
  mapDispatchToProps
)(SessionStep3Component)
