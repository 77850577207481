import * as R from 'ramda'
import { AnchorHTMLAttributes } from 'react'
import styled, { DefaultTheme, StyledComponent } from 'styled-components'
import { is } from 'typescript-styled-is'
import { mq } from 'src/features/ui/styles/mq'

export interface IBaseProps {
  bold?: boolean
  bolderer?: boolean
  bolder?: boolean
  color?: keyof DefaultTheme['color']
  underline?: boolean
  block?: boolean
  hasPointerCursor?: boolean
}

export type HTMLAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>

export type AnchorStyledProps = HTMLAnchorProps & IBaseProps

// we need to define this manually because styled-components don't know how to infer when using "as" in "attrs"
export type AnchorStyledComponent = StyledComponent<
  'a',
  DefaultTheme,
  AnchorStyledProps
>

export const Base = styled.span<IBaseProps>`
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-weight: 400;
  color: ${(props) => props.theme.color[props.color ?? 'codGray']};
  margin: 0;
  padding: 0;
  text-decoration: none;

  ${is('bolder')`
    font-weight: 500;
  `}

  ${is('bolderer')`
    font-weight: 600;
  `}

  ${is('bold')`
    font-weight: 700;
  `}

  ${is('underline')`
    text-decoration: underline;
  `}

  ${is('block')`
    display: block;
  `}

  ${is('hasPointerCursor')`
    cursor: pointer;
  `}
`

export const Title = styled(Base).attrs({ as: 'h1', bold: true })`
  font-size: 3.2rem;
  line-height: 3.8rem;

  ${mq.sm} {
    font-size: 4rem;
    line-height: 4.8rem;
  }
`

export const SubTitle = styled(Base).attrs({ as: 'h2' })`
  font-size: 2rem;
  line-height: 2.8rem;
`

export const Text1 = styled(Base)`
  font-size: 1.6rem;
  line-height: 2.4rem;
`

export const Text2 = styled(Base)`
  font-size: 1.4rem;
  line-height: 1.6rem;
`

export const Text3 = styled(Base)`
  font-size: 1.4rem;
  line-height: 2.4rem;
`

export const Text4 = styled(Base)`
  font-size: 1.4rem;
  line-height: 2rem;
`

export const Text5 = styled(Base)`
  font-size: 1.4rem;
  line-height: 1.7rem;
`

export const Text6 = styled(Base)`
  font-size: 1.4rem;
  line-height: 2.4rem;
`

export const Text7 = styled(Base)`
  font-size: 1.2rem;
  line-height: 1.4rem;
`

export const Text8 = styled(Base)`
  font-size: 1.6rem;
  line-height: 1.9rem;
`

export const Link1 = styled(Base).attrs({ as: 'a' })`
  font-size: 1.6rem;
  line-height: 1.9rem;

  ${mq.sm} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
` as AnchorStyledComponent

export const Link2 = styled(Base).attrs({ as: 'a' })`
  font-size: 1.4rem;
  line-height: 2.4rem;
` as AnchorStyledComponent

export const Highlight = styled.span<{ color?: keyof DefaultTheme['color'] }>`
  color: ${(props) => props.theme.color[props.color ?? 'black']};
`

export const Bold = styled(Base).attrs({ bold: true })``
