import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { importContactRequest, toggleImportPopUp } from "../../../actions/PeopleAction.js";
import { CONTACT_DATATYPES_DATE, CONTACT_DATATYPES_NUMBERS } from "../../../commons/Constants.js";
import { flipObject } from "../../../commons/Utility.js";
import useProperties from "../../hooks/useProperties.js";
import ImportContactColumns from "./ImportContactColumns.jsx";
import ImportContactFooter from "./ImportContactFooter.jsx";
import ImportContactHeader from "./ImportContactHeader.jsx";
import ImportContactRows from "./ImportContactRows.jsx";

const ImportContactsPopUp = (props) => {
  const { importedContacts } = props;
  const { columns = {}, customFields = {} } = useProperties(
    props.params.projectkey
  );
  const [importedColumns, setImportedColumns] = useState([]);
  const [importedContactInfo, setImportedContactsInfo] = useState([]);
  const [customFieldsToCreate, setCustomFieldsToCreate] = useState({});
  const [forceImport,setForceImport] = useState(false);
  const [columnNameToFieldNameMap, setColumnNameToFieldNameMap] = useState({});
  const [headerToFieldMap,setHeaderToFieldMap] = useState({});
  const [selectAll,setSelectAll] = useState(true);
  const dispatch = useDispatch();
  const popupRef = useRef(null);

  const getColumns = () => {
    let nameStrippedColumns = Object.assign({},columns);
    if(nameStrippedColumns.fullName){
      delete nameStrippedColumns.fullName;
      nameStrippedColumns.firstName = "First Name";
      nameStrippedColumns.lastName = "Last Name";
      nameStrippedColumns.userId = "User ID";
    }
    return nameStrippedColumns;
  }
  let strippedColumns = getColumns();

  useEffect(() => {
    setImportedColumns(importedContacts.contactColumns);
    let currentImportContactsInfo = Object.assign(
      [],
      importedContacts.information
    );
    currentImportContactsInfo.forEach((contactInfo) => {
      contactInfo.isSelected = true;
    });
    setImportedContactsInfo(currentImportContactsInfo);
    setColumnNameToFieldNameMap(flipObject(strippedColumns));
    if (importedContacts.contactColumns)
      setHeaderToFieldMap(
        importedContacts.contactColumns.reduce((object, value) => {
          object[value] = value;
          return object;
        }, {})
      );
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    let currentImportContactsInfo = Object.assign(
      [],
      importedContacts.information
    );
    currentImportContactsInfo.forEach((contactInfo) => {
      contactInfo.isSelected = selectAll;
    });
    setImportedContactsInfo(currentImportContactsInfo);
  },[selectAll]);

  const handleOutsideClick = (e) => {
    if (popupRef && popupRef.current && !popupRef.current.contains(e.target))
      closeImportPopUp();
  };

  const getUnmappedFieldCount = () => {
    let unmappedCount = 0;
    let existingColumns = Object.values(strippedColumns);
    importedColumns.forEach((columnName) => {
      if (!existingColumns.includes(columnName)) unmappedCount++;
    });
    return unmappedCount - Object.keys(customFieldsToCreate).length;
  };

  const closeImportPopUp = () => {
    dispatch(toggleImportPopUp("",{},false));
  }

  const setRowIsSelected = (index) => {
    let currentImportedContactInfo = Object.assign([], importedContactInfo);
    if (currentImportedContactInfo[index])
      currentImportedContactInfo[
        index
      ].isSelected = !currentImportedContactInfo[index].isSelected;
    setImportedContactsInfo(currentImportedContactInfo);
  };
  
  const isCustomField = (name) => {
    if(name && name.includes("CF-"))
      return true;
    let customFieldsMap = customFields.customFieldsMap
      ? Object.values(customFields.customFieldsMap)
      : [];
    let customField =
      customFieldsMap.find((field) => {
        return field.name === name;
      }) || {};
    return Object.keys(customField).length || name in customFieldsToCreate;
  };
  
  const importContacts = () => {
    if (getUnmappedFieldCount() > 0 && !forceImport) {
      setForceImport(true);
      return;
    } else {
      let projectId = `LS-${props.params.projectkey}`;
      let peopleEntities = [];
      importedContactInfo.forEach((contact) => {
        if (!contact.isSelected) return;
        delete contact.isSelected;
        let peopleEntity = {};
        peopleEntity.customFields = {};
        for (let fieldName in contact) {
          fieldName = fieldName.trim();
          if (isCustomField(fieldName) || isCustomField(columnNameToFieldNameMap[headerToFieldMap[fieldName]])) {
            if (fieldName in customFieldsToCreate)
              peopleEntity.customFields[customFieldsToCreate[fieldName].replaceAll(" ","")] =
                contact[fieldName];
            else peopleEntity.customFields[headerToFieldMap[fieldName].replaceAll(" ","")] = contact[fieldName];
          } else {
            peopleEntity[
              columnNameToFieldNameMap[headerToFieldMap[fieldName]]
            ] = convertData(contact[fieldName],columnNameToFieldNameMap[headerToFieldMap[fieldName]]);
          }
        }
        delete peopleEntity.undefined;
        peopleEntities.push(peopleEntity);
      });
      dispatch(importContactRequest(peopleEntities,projectId));
      closeImportPopUp();
    }
  };

  const convertData = (fieldValue, columnName) => {
    if(CONTACT_DATATYPES_NUMBERS.includes(columnName) && CONTACT_DATATYPES_DATE.includes(columnName))
      return Number(fieldValue) ? Number(new Date(fieldValue).getTime()) : 0;
    if (CONTACT_DATATYPES_NUMBERS.includes(columnName))
      return Number(fieldValue) || 0;
    return fieldValue;
  };
  
  const getContactCount = () => {
    let count = 0;
    importedContactInfo.forEach((contact) => {
      if (contact.isSelected) count++;
    });
    return count;
  };

  return (
    <React.Fragment>
      <div class="cs-popupbg"></div>
      <div class="cs-popup-model popup-new import-popup" ref={popupRef}>
        <ImportContactHeader contactsCount={getContactCount()} closeImportPopUp={closeImportPopUp}/>
        <section class="popup-new-content import-grid-wrp">
          <div>
            <ImportContactColumns
              existingColumnsMap={strippedColumns}
              existingColumns={Object.values(strippedColumns)}
              importedColumns={importedColumns}
              setImportedColumns={setImportedColumns}
              setCustomFieldsMap={setCustomFieldsToCreate}
              customFieldsMap={customFieldsToCreate}
              forceImport={forceImport}
              setHeaderToFieldMap={setHeaderToFieldMap}
              headerToFieldMap={headerToFieldMap}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
            />
            <ImportContactRows
              importedColumns={importedColumns}
              importedContactsInfo={importedContactInfo}
              setRowIsSelected={setRowIsSelected}
              headerToFieldMap={flipObject(headerToFieldMap)}
              initialImportedColumns={importedContacts.contactColumns}
            />
          </div>
        </section>
        <ImportContactFooter
          unmappedFieldCount={getUnmappedFieldCount()}
          importContacts={importContacts}
          forceImport={forceImport}
          closeImportPopUp={closeImportPopUp}
          contactCount={getContactCount()}
        />
      </div>
    </React.Fragment>
  );
};

export default ImportContactsPopUp;
