import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import { CampaingnIcon } from "../../../app/commons/Icons";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import { CAMPAIGN_DISPLAY_NAMES } from "../../../app/commons/Constants.js";
import * as loaders from "../../settings/commons/loaders";

const CampaignHeader = (props) => {
  const { projectkey, type } = props.params;
  const { openPopup, getCurrentFilterCount } = props;
  const [postFetchAction, setPostFetchAction] = useState(false);
  const {
    campaignData,
    isCampaignFetched,
    // campaignPopupProjectIds,
  } = useBroadcastMessageStats(projectkey);
  // const dispatch = useDispatch();
  const getCount = (campaginType) => {
    let campaigns = campaignData || [];
    let filteredcampaigns = [];
    if (campaginType == "announcement") campaginType = "ANNOUNCEMENT";
    else if (campaginType == "chat") campaginType = "CHAT";
    else if (campaginType == "email") campaginType = "EMAIL";
    if (isCampaignFetched) {
      if (campaginType == "all") {
        filteredcampaigns = campaigns.filter((campaign) => {
          return campaign.status === "ACTIVE";
        });
      } else {
        filteredcampaigns = campaigns.filter((campaign) => {
          return (
            campaign.typeOfMessage == campaginType &&
            campaign.status === "ACTIVE"
          );
        });
      }
    }
    return filteredcampaigns.length;
  };

  const getLoader = () => {
    let headerLoader = [];
    headerLoader.push(loaders.ImageLoader());
    headerLoader.push(loaders.PTagLoader());
    return headerLoader;
  };

  if (isCampaignFetched) {
    return (
      <header>
        <div className="cs-people-header">
          <figure className="campigaing-icon">
            <CampaingnIcon />
          </figure>
          <label>{CAMPAIGN_DISPLAY_NAMES[type]}</label>
          <p>{`${getCount(type)} campaigns`}</p>
        </div>
        { getCurrentFilterCount() <= 0 ? "" : <button
          id="newCampaignButton"
          onClick={openPopup}
          className="cs-btn cs-btn-xs cs-primary-btn run-btn"
          style={{ right: "30px" }}
        >
          <CampaingnIcon />
          New Campaign
        </button>}
      </header>
    );
  }

  return (
    <header>
      <div className={"cs-people-header"}>{getLoader()}</div>
      <button
        className="cs-btn cs-btn-xs cs-primary-btn run-btn"
        style={{ right: "30px" }}
      >
        <CampaingnIcon />
        New Campaign
      </button>
    </header>
  );
};

export default CampaignHeader;
