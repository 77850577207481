import React, { Component } from "react";
import SearchBar from "./search/SearchBar.jsx";
import CustomerNameSearch from "./search/CustomerNameSearch.jsx";
import CompanyNameSearch from "./search/CompanyNameSearch.jsx";
import RecentSearchHistory from "./search/RecentSearchHistory.jsx";
import TagNameSearch from "./search/TagNameSearch.jsx";
import { RemoveIcon, SearchIcon } from "../../commons/Icons.js";
import { TAG_NAME_SEARCH } from "../../commons/Constants.js";
import { hideConversationFilter } from "../../actions/ConversationInfoAction.js";


class SearchCmp extends Component {
  constructor() {
    super();
    this.closeSearch = this.closeSearch.bind(this);
    this.state = {
      filterElements: [],
      searchType: "",
      searchValue: "",
    };
    this.searchRef = React.createRef();
    this.handleOutSideClick = this.handleOutSideClick.bind(this);
  }

  handleRecentSearchHistory = (recentSearchVal, searchType) => {
    this.setState({
      filterElements: recentSearchVal,
      searchType: searchType,
    });
  };

  componentWillUnmount() {
    if (this.props.ConversationReducer && !this.props.ConversationReducer.showConversationFilter) {
      document.removeEventListener("click", this.handleOutSideClick, true); 
    }
  }

  componentDidMount(){
    if (this.props.ConversationReducer && this.props.ConversationReducer.showConversationFilter) {
      document.addEventListener("click", this.handleOutSideClick, true);
    }
  }

  handleOutSideClick(e) {
  if ( (this.searchRef.current && !this.searchRef.current.contains(e.target)) || this.searchRef.current === null) {
    this.closeSearch();
  }
}

  closeSearch() {
    document.removeEventListener("click", this.handleOutSideClick, true);
    this.props.dispatch(hideConversationFilter());
    this.props.setIsAdvanceMode(false);
  }
  render() {
    let dashboardData = this.props;

    return (
      <section class="cs-my-conversations-filter"
      ref={this.searchRef}
      >
        <div>
          {this.state.filterElements}
          {this.state.searchType ? "" : <SearchIcon />}
          <SearchBar
            dashboardData={dashboardData}
            isTagSearch={this.state.searchType === TAG_NAME_SEARCH}
          />
          <RemoveIcon
            className="search-close-icon"
            onClick={this.closeSearch}
          />
        </div>
        <section class="cs-filter-list">
          <RecentSearchHistory dashboardData={dashboardData} />
          <ul>
            <h5>Search by:</h5>
            <TagNameSearch
              recentSearchHistory={this.handleRecentSearchHistory}
            />
            <CustomerNameSearch
              recentSearchHistory={this.handleRecentSearchHistory}
            />
            <CompanyNameSearch
              recentSearchHistory={this.handleRecentSearchHistory}
            />
          </ul>
          {/*<small>Chats taken by</small>
                        <small>Agent name</small>*/}
        </section>
      </section>
    );
  }
}
export default SearchCmp;
