export const SHOW_VOICEBOX = "SHOW_VOICEBOX";
export const HIDE_VOICEBOX = "HIDE_VOICEBOX";

export const showVoicebox = (voicebox) => ({
  type: SHOW_VOICEBOX,
  ...voicebox,
});

export const hideVoicebox = () => ({
  type: HIDE_VOICEBOX,
});
