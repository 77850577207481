import React from 'react'

export enum EnumOpenType {
  widgetType = 'widgetType',
  plugin = 'plugin',
}

export interface IProps {
  label: string
  icon: keyof typeof EnumOpenType
  children: React.ReactNode
  isOpen: boolean
  onToggle: (type: keyof typeof EnumOpenType) => void
  hasBottomBorder?: boolean
  wasConnectionTested?: boolean
}
