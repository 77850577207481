export const CONVERSATION_INFO_REQUESTED = "CONVERSATION_INFO_REQUESTED";
export const CONVERSATION_INFO_RESPONSE = "CONVERSATION_INFO_RESPONSE";
export const CONVERSATION_INFO_FAILED = "CONVERSATION_INFO_FAILED";
export const CONVERSATION_LIST_INFO_REQUESTED =
  "CONVERSATION_LIST_INFO_REQUESTED";
export const CONVERSATION_LIST_INFO_RESPONSE =
  "CONVERSATION_LIST_INFO_RESPONSE";
export const UPDATE_CANCEL_TOKEN = "UPDATE_CANCEL_TOKEN";
export const CONVERSATION_LIST_INFO_FAILED = "CONVERSATION_LIST_INFO_FAILED";
export const VISITOR_CONVERSATION_LIST_INFO_REQUESTED =
  "VISITOR_CONVERSATION_LIST_INFO_REQUESTED";
export const VISITOR_CONVERSATION_LIST_INFO_RESPONSE =
  "VISITOR_CONVERSATION_LIST_INFO_RESPONSE";
export const VISITOR_CONVERSATION_LIST_INFO_FAILED =
  "VISITOR_CONVERSATION_LIST_INFO_FAILED";
export const CONVERSATION_UPDATE_REQUESTED = "CONVERSATION_UPDATE_REQUESTED";
export const CONVERSATION_UPDATE_RESPONSE = "CONVERSATION_UPDATE_RESPONSE";
export const CONVERSATION_UPDATE_FAILED = "CONVERSATION_UPDATE_FAILED";
export const CONVERSATION_UPDATE_REALTIME = "CONVERSATION_UPDATE_REALTIME";
export const CONVERSATION_LIST_FORCE_UPDATE = "CONVERSATION_LIST_FORCE_UPDATE";
export const ADD_CONVERSATION_TO_EXPANDEDLIST =
  "ADD_CONVERSATION_TO_EXPANDEDLIST";
export const REMOVE_CONVERSATION_FROM_EXPANDEDLIST =
  "REMOVE_CONVERSATION_FROM_EXPANDEDLIST";
export const REMOVE_ALL_CONVERSATION_FROM_EXPANDEDLIST = "REMOVE_ALL_CONVERSATION_FROM_EXPANDEDLIST";
export const ADD_TEMP_CONVERSATION = "ADD_TEMP_CONVERSATION";
export const REMOVE_TEMP_CONVERSATION = "REMOVE_TEMP_CONVERSATION";
export const CREATE_NEW_CONVERSATION_REQUESTED =
  "CREATE_NEW_CONVERSATION_REQUESTED";
export const CREATE_NEW_CONVERSATION_RESPONSE =
  "CREATE_NEW_CONVERSATION_RESPONSE";
export const CREATE_NEW_CONVERSATION_FAILED = "CREATE_NEW_CONVERSATION_FAILED";
export const SEND_TRANSCRIPT_EMAIL_REQUESTED =
  "SEND_TRANSCRIPT_EMAIL_REQUESTED";
export const SEARCH_INFO_REQUESTED = "SEARCH_INFO_REQUESTED";
export const RESET_UNREAD_COUNT = "RESET_UNREAD_COUNT";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const RESET_NEW_MESSAGE = "RESET_NEW_MESSAGE";
export const TRACK_CHAT_STATUS = "TRACK_CHAT_STATUS";
export const UPDATE_CURRENT_CONVERSATION_ID = "UPDATE_CURRENT_CONVERSATION_ID"
export const CONVERSATION_COUNT_RESPONSE = "CONVERSATION_COUNT_RESPONSE";
export const CONVERSATION_COUNT_REQUESTED = "CONVERSATION_COUNT_REQUESTED";
export const ADD_CONVERSATION_QUEUE = "ADD_CONVERSATION_QUEUE";
export const REMOVE_CONVERSATION_QUEUE = "REMOVE_CONVERSATION_QUEUE";
export const FETCH_CONVERSATION_QUEUE = "FETCH_CONVERSATION_QUEUE";
export const FETCH_CONVERSATION_QUEUE_RESPONSE = "FETCH_CONVERSATION_QUEUE_RESPONSE";
export const RESET_CONVERSATION_QUEUE = "RESET_CONVERSATION_QUEUE";
export const UPDATE_SEARCH_TEXT_CURSOR = "UPDATE_SEARCH_TEXT_CURSOR"
export const SHOW_CONVERSATION_FILTER = "SHOW_CONVERSATION_FILTER";
export const HIDE_CONVERSATION_FILTER = "HIDDEN_CONVERSATION_FILTER";
export const CONVERSATION_IS_LOADING = "CONVERSATION_IS_LOADING";
export const CONVERSATION_STOPPED_LOADING = "CONVERSATION_STOPPED_LOADING";
export const UPDATE_AW_UNREAD_COUNT = "UPDATE_AW_UNREAD_COUNT";
export const UPDATE_TYPING_STATUS = "UPDATE_TYPING_STATUS";
export const CLEAR_CONVERSATION = "CLEAR_CONVERSATION";
export const UPDATE_VISITOR_OFFLINE_PRESENCE = "UPDATE_VISITOR_OFFLINE_PRESENCE";
export const BLOCK_UNLOAD_EVENT = "BLOCK_UNLOAD_EVENT";
export const UNBLOCK_UNLOAD_EVENT = "UNBLOCK_UNLOAD_EVENT";

export const conversationRequest = (conversationObject,tagObject) => ({
  type: CONVERSATION_INFO_REQUESTED,
  conversationObject,
  tagObject
});

export const conversationResponse = (conversationObject,userReducer) => ({
  type: CONVERSATION_INFO_RESPONSE,
  conversationObject,
  userReducer
});

export const conversationFailed = (conversationObject) => ({
  type: CONVERSATION_INFO_FAILED,
  conversationObject,
});
export const conversationListRequest = (conversationObject) => ({
  type: CONVERSATION_LIST_INFO_REQUESTED,
  conversationObject,
});

export const conversationListResponse = (conversationObject) => ({
  type: CONVERSATION_LIST_INFO_RESPONSE,
  conversationObject,
});

export const updateCancelToken = (cancelToken) => ({
  type: UPDATE_CANCEL_TOKEN,
  cancelToken,
});

export const conversationListFailed = (conversationObject) => ({
  type: CONVERSATION_LIST_INFO_FAILED,
  conversationObject,
});
export const visitorConversationListRequest = (conversationObject) => ({
  type: VISITOR_CONVERSATION_LIST_INFO_REQUESTED,
  conversationObject,
  isVisitorConversationList: true,
});

export const visitorConversationListResponse = (conversationObject) => ({
  type: VISITOR_CONVERSATION_LIST_INFO_RESPONSE,
  conversationObject,
  isVisitorConversationList: true,
});

export const visitorConversationListFailed = (conversationObject) => ({
  type: VISITOR_CONVERSATION_LIST_INFO_FAILED,
  conversationObject,
  isVisitorConversationList: true,
});

export const updateConversationRequest = (conversationObject) => ({
  type: CONVERSATION_UPDATE_REQUESTED,
  conversationObject,
});

export const updateConversationResponse = (conversation, contactsMap) => ({
  type: CONVERSATION_UPDATE_RESPONSE,
  conversation,
  contactsMap,
});

export const updateConversationFailed = () => ({
  type: CONVERSATION_UPDATE_FAILED,
});

export const updateConversationRealTime = (message, userId , staffMap,isFullScreenMode ) => ({
  type: CONVERSATION_UPDATE_REALTIME,
  message,
  userId,
  staffMap,
  isFullScreenMode
});

export const forceUpdateConversationList = (forceUpdate) => ({
  type: CONVERSATION_LIST_FORCE_UPDATE,
  forceUpdate,
});

export const addConversationToExpandedList = (conversationId) => ({
  type: ADD_CONVERSATION_TO_EXPANDEDLIST,
  conversationId,
});

export const removeConversationFromExpandedList = (conversationId) => ({
  type: REMOVE_CONVERSATION_FROM_EXPANDEDLIST,
  conversationId,
});

export const removeAllConversationFromExpandedList = () => ({
  type: REMOVE_ALL_CONVERSATION_FROM_EXPANDEDLIST
});

export const addTempConversation = (tempConversation, contact) => ({
  type: ADD_TEMP_CONVERSATION,
  tempConversation,
  contact
});

export const removeTempConversation = (tempConversationId) => ({
  type: REMOVE_TEMP_CONVERSATION,
  tempConversationId,
});

export const createNewConversationRequested = (tempConversation , userId , staffMap) => ({
  type: CREATE_NEW_CONVERSATION_REQUESTED,
  tempConversation,
  userId,
  staffMap
});

export const createNewConversationResponse = (
  conversation,
  tempConversationId
) => ({
  type: CREATE_NEW_CONVERSATION_RESPONSE,
  conversation,
  tempConversationId,
});

export const createNewConversationFailed = () => ({
  type: CREATE_NEW_CONVERSATION_FAILED,
});

export const sendTranscriptEmailRequest = (transcriptPayload) => ({
  type: SEND_TRANSCRIPT_EMAIL_REQUESTED,
  transcriptPayload,
});

export const searchInfoRequested = (conversationObject) => ({
  type: SEARCH_INFO_REQUESTED,
  conversationObject,
});

export const reSetUnreadCount = (conversationId) => ({
  type: RESET_UNREAD_COUNT,
  conversationId,
});

export const setNewMessage = (message) => ({
  type: SET_NEW_MESSAGE,
  message,
});

export const resetNewMessage = (conversationId) => ({
  type: RESET_NEW_MESSAGE,
  conversationId,
});

export const trackChatStatus = (statusTrackerInfo) => ({
  type: TRACK_CHAT_STATUS,
  statusTrackerInfo,
});

export const updateCurrentConversationId = (currentConversationId) => ({
  type: UPDATE_CURRENT_CONVERSATION_ID,
  currentConversationId,
});

export const updateAwUnreadCount = (currentConversationId) => ({
  type: UPDATE_AW_UNREAD_COUNT,
  currentConversationId
})

export const getConversationCount = (projectId) => ({
  type: CONVERSATION_COUNT_REQUESTED,
  projectId,
});

export const setConversationCount = (conversationCount) => ({
  type: CONVERSATION_COUNT_RESPONSE,
  conversationCount
});

export const addToQueue = (conversationId) => ({
  type: ADD_CONVERSATION_QUEUE,
  conversationId
});

export const removeFromQueue = (conversationId) => ({
  type: REMOVE_CONVERSATION_QUEUE,
  conversationId
});

export const fetchConversationQueue = (projectId) => ({
  type: FETCH_CONVERSATION_QUEUE,
  projectId
});

export const conversationQueueResponse = (conversationObject) => ({
  type: FETCH_CONVERSATION_QUEUE_RESPONSE,
  conversationObject
});

export const resetConversationQueue = () => ({
  type:RESET_CONVERSATION_QUEUE,
})

export const updateSearchTextCursor = (searchTextCursor) => ({
  type: UPDATE_SEARCH_TEXT_CURSOR,
  searchTextCursor,
});
export const showConversationFilter = () => ({
  type: SHOW_CONVERSATION_FILTER,
});

export const hideConversationFilter = () => ({
  type: HIDE_CONVERSATION_FILTER,
});

export const conversationIsLoading = () => ({
  type: CONVERSATION_IS_LOADING,
})

export const conversationStoppedLoading = () => ({
  type: CONVERSATION_STOPPED_LOADING,
})

export const updateTypingStatus = (typingStatus, typingMessage) => ({
  type: UPDATE_TYPING_STATUS,
  typingStatus,
  typingMessage,
});

export const clearConversation = (conversationId) => ({
  type: CLEAR_CONVERSATION,
  conversationId
})

export const updateVisitorOfflinePresence = (visitorId) => ({
  type: UPDATE_VISITOR_OFFLINE_PRESENCE,
  visitorId
});

export const blockUnloadEvent = () => ({
    type: BLOCK_UNLOAD_EVENT,
});

export const unloadEventBlocked = () => ({
    type: UNBLOCK_UNLOAD_EVENT,
});