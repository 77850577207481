import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getPropertyType,
  getGroupMap,
  encodeReferences,
} from "../commons/ContactsUtility.js";
import {
  FILTER_DEFAULT_OPTION,
  DEFAULT_GROUP_IDS,
} from "../commons/ContactConstants.js";
import { updatePeopleGroupRequest } from "../actions/ContactsAction.js";
import { showVoicebox } from "../../actions/VoiceboxAction.js";
import AppliedFilter from "./AppliedFilter.js";
import FilterProperties from "./FilterProperties.jsx";
import useProperties from "../hooks/useProperties.js";
import { store } from "../../app.js";
import {
  deepClone,
  getTypeOfStats,
  getLocalDateEndTimeOfDay,
} from "../../commons/Utility.js";
import { removeFilterReference } from "../actions/SearchAction.js";
import { browserHistory } from "react-router";
import useOnClickOutside from "../hooks/useOnClickOutside.js";

const Filters = (props) => {
  const {
    params: { projectkey },
    typeOfStats,
  } = props;
  const [showProperties, setShowProperties] = useState(false);
  const [showAddProperty, setShowAddProperty] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const [showSave, setShowSave] = useState(false);
  const [showSaveAs, setShowSaveAs] = useState(false);
  const propertiesInfo = useProperties(projectkey);
  const {
    contacts: { peopleGroupMap = {}, totalPeople },
    setShowSaveSegment,
  } = props;
  const {
    showFilterSection,
    setShowFilterSection,
    query: { references },
    params: { groupId, filters },
    showMoreOptions,
  } = props;
  const group = peopleGroupMap[groupId];
  const dispatch = useDispatch();

  const filtersRef = useRef();
  useOnClickOutside(filtersRef, () => {
    if (selectedFilter && selectedFilter.key) {
      setSelectedFilter({});
      setShowAddProperty(true);
    }
  });

  useEffect(() => {
    onGroupChange();
  }, [groupId]);

  useEffect(() => {
    onFilterChange();
  }, [filters]);

  useEffect(() => {
    if (showFilterSection && !filters) setShowProperties(true);
  }, [showFilterSection]);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) {
      setSelectedFilter({});
      setShowProperties(false);
      setShowAddProperty(true);
    }
  };

  const checkAndShowSave = () => {
    if (DEFAULT_GROUP_IDS.includes(groupId)) setShowSave(false);
    else if (!filters) setShowSave(false);
    else setShowSave(true);
  };

  const checkAndShowSaveAs = () => {
    if (!filters) setShowSaveAs(false);
    else setShowSaveAs(true);
  };

  const onGroupChange = () => {
    resetFilterView();
    checkAndShowSave();
    checkAndShowSaveAs();
  };

  const onFilterChange = () => {
    if (filters && !showFilterSection) setShowFilterSection(true);
    checkAndShowSave();
    checkAndShowSaveAs();
  };

  const resetFilterView = () => {
    setShowFilterSection(false);
    setShowAddProperty(true);
    setSelectedFilter({});
    setShowProperties(false);
  };

  const onSelectProperty = (selectedProperty, reference = {}) => {
    let key = reference.key || selectedProperty;
    let property = reference.property || selectedProperty;
    let { events, customFields, properties } = propertiesInfo;
    let propertyType = getPropertyType(property, events, customFields);
    let queryType = reference.filterType || FILTER_DEFAULT_OPTION[propertyType];
    let value = reference.value || (propertyType == 'date' ? getLocalDateEndTimeOfDay() : '');
    let options = { [queryType]: true };
    setSelectedFilter({
      key: key,
      type: propertyType,
      filterType: queryType,
      options,
      value: value,
      property: property,
      alias: properties[property],
    });
    setShowProperties(false);
    setShowAddProperty(false);
  };

  const onSaveAs = () => {
    setShowSaveSegment(true);
    setShowAddProperty(true);
    setSelectedFilter({});
    setShowProperties(false);
  };

  const onSave = () => {
    if (!filters) {
      //dispatch(showVoicebox({ message : 'No changes to save...', dismissAfter : 3000}));
      return;
    } else if (DEFAULT_GROUP_IDS.includes(groupId)) {
      //dispatch(showVoicebox({ message : 'Not allowed to edit default group...', dismissAfter : 3000}));
      return;
    }
    let {
      UserReducer: {
        data: { id: userId },
      },
    } = store.getState();
    let { groupName } = group;
    let groupInfo = getGroupMap(props.query, projectkey, groupName, userId);
    groupInfo = { ...group, ...groupInfo };
    dispatch(updatePeopleGroupRequest(groupInfo));
    resetFilterView();
  };

  const getFilters = () => {
    let filters = [];
    let referencesList = Object.values(references) || [];
    let currentReferences = referencesList.forEach((reference) => {
      if (reference.type != "peopleType")
        filters.push(
          <AppliedFilter
            {...props}
            setShowProperties={setShowProperties}
            onSelect={onSelectProperty}
            onRemove={onRemove}
            propertiesInfo={propertiesInfo}
            reference={reference}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setShowAddProperty={setShowAddProperty}
          />
        );
    });
    if (selectedFilter && selectedFilter.key && !references[selectedFilter.key])
      filters.push(
        <AppliedFilter
          {...props}
          setShowProperties={setShowProperties}
          onRemove={onRemove}
          propertiesInfo={propertiesInfo}
          newFilter={true}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          setShowAddProperty={setShowAddProperty}
        />
      );
    if (showMoreOptions === false) {
      return <span />;
    }
    return filters;
  };

  const getMatchesInfo = () => {
    let matches = "";
    let groupCount = group ? group.count : 0;
    if (!filters && groupCount) matches = `${groupCount} matches`;
    else if (filters && totalPeople >= groupCount)
      matches = `${totalPeople} matches`;
    else if (filters && groupCount)
      matches = `${totalPeople} matches from ${groupCount} contacts`;
    else if (showMoreOptions === false)
      return "";
    else matches = `0 matches`;
    return matches;
  };

  const onRemove = (reference, newFilter) => {
    if (newFilter) {
      setSelectedFilter({});
      setShowProperties(false);
      setShowAddProperty(true);
      return;
    }
    let { property, alias, key } = reference;
    let { properties } = propertiesInfo;
    let referenceKeys = Object.keys(references) || [];
    let currentReferences = deepClone(references);
    if (selectedFilter.key && referenceKeys.includes(key)) {
      //dispatch(showVoicebox({ message : `Please add ${alias}`, dismissAfter : 3000}));
      return;
    }
    delete currentReferences[key];
    let encodedReferences = encodeReferences(currentReferences);
    setSelectedFilter({});
    browserHistory.push({
      pathname: `/app/${projectkey}/contacts/dashboard/${groupId}/${encodedReferences}`,
    });
  };

  const getSaveText = () => {
    let saveText = "Save as";
    if (DEFAULT_GROUP_IDS.includes(groupId)) saveText = "Save";
    return saveText;
  };
  const getClassName = () => {
    console.log("showMoreOptions: " + showMoreOptions);
    if (showMoreOptions === false) {
      return "";
    } else {
      return "people-filter";
    }
  }
  return (
    <section className={getClassName()} >
      <div>
        {showMoreOptions===undefined ?
          <label>Filter:</label>
          : ""}
        {showMoreOptions!==undefined ?
          <div className="filter-checkbox-dwn ">
            <label>FILTER:</label>
            <label>Campaign:
              <span>{getTypeOfStats(typeOfStats)}</span>
            </label>
          </div>
          : <div></div>}
        <div ref={filtersRef} className="checkbox-dwn-wrp">
          {getFilters()}
          <FilterProperties
            {...props}
            setShowProperties={setShowProperties}
            showProperties={showProperties}
            onSelect={onSelectProperty}
            showAddProperty={showAddProperty}
          />
        </div>
      </div>
      <div>
        <p>{getMatchesInfo()}</p>
        <button
          style={{ display: showSaveAs ? "block" : "none" }}
          className="cs-btn cs-btn-xs cs-primary-btn fr"
          onClick={onSaveAs}
        >
          {getSaveText()}
        </button>
        <button
          style={{ display: showSave ? "block" : "none" }}
          className="cs-btn cs-btn-xs cs-decline-btn fr"
          onClick={onSave}
        >
          Save
        </button>
      </div>
      {/**<button disabled={filters?false:true} style={{opacity:(filters?"1":"0.5")}} className="cs-btn cs-btn-xs cs-primary-btn fr" onClick={onSaveSegment}>Save as segment</button> </div>**/}
    </section>
  );
};

export default Filters;
