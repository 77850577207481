import React, { Component } from "react";
import MailTranscriptPopup from "../components/common/MailTranscriptPopup.jsx";
import { hidePopup } from "../actions/PopupAction";
import { sendTranscriptEmailRequest } from "../actions/ConversationInfoAction";
import { getProjectId } from "../commons/Utility";
import { store } from "../app";

class MailTranscriptPopupContainer extends Component {
  constructor() {
    super();
    this.state = {
      shouldShowAddTranscriptMessage: false,
    };
    this.mailTranscript = this.mailTranscript.bind(this);
    this.toggleAddMessageSection = this.toggleAddMessageSection.bind(this);
  }

  componentDidMount() {
    this.refs.chatTranscript.refs.transcriptRecipient.focus();
    this.refs.chatTranscript.refs.transcriptRecipient.value = "";
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevState.shouldShowAddTranscriptMessage &&
      this.state.shouldShowAddTranscriptMessage
    )
      this.refs.chatTranscript.refs.transcriptTicketNote.focus();
  }

  toggleAddMessageSection() {
    this.setState({
      shouldShowAddTranscriptMessage: !this.state
        .shouldShowAddTranscriptMessage,
    });
  }

  mailTranscript() {
    let { projectKey, conversationId } = this.props.state;
    let transcriptRequest = {};
    transcriptRequest[
      "ticketNote"
    ] = this.refs.chatTranscript.refs.transcriptTicketNote.value;
    transcriptRequest["projectId"] = "LS-" + projectKey;
    transcriptRequest[
      "recipient"
    ] = this.refs.chatTranscript.refs.transcriptRecipient.value;
    transcriptRequest["conversationId"] = conversationId;

    this.state.shouldShowAddTranscriptMessage = false;
    store.dispatch(sendTranscriptEmailRequest(transcriptRequest));
    store.dispatch(hidePopup());
  }

  render() {
    return (
      <MailTranscriptPopup
        ref="chatTranscript"
        mailTranscript={this.mailTranscript}
        toggleAddMessageSection={this.toggleAddMessageSection}
        state={this.state}
      />
    );
  }
}

export default MailTranscriptPopupContainer;
