export const CONTACT_UPDATE_REQUESTED = "CONTACT_UPDATE_REQUESTED";
export const CONTACT_UPDATE_RESPONSE = "CONTACT_UPDATE_RESPONSE";
export const CONTACT_UPDATE_FAILED = "CONTACT_UPDATE_FAILED";
export const UPDATE_CONTACT_PRESENCE = "UPDATE_CONTACT_PRESENCE";
export const GET_VISITOR_TIMEZONE = "GET_VISITOR_TIMEZONE";
export const VISITOR_TIMEZONE_RESPONSE = "VISITOR_TIMEZONE_RESPONSE";
export const VISITOR_TIMEZONE_FAILED = "VISITOR_TIMEZONE_FAILED";
export const requestUpdateContact = (contactObj) => ({
  type: CONTACT_UPDATE_REQUESTED,
  contactObj,
});

export const receiveUpdateContact = (contactObj) => ({
  type: CONTACT_UPDATE_RESPONSE,
  contactObj,
});

export const updateContactFailed = () => ({
  type: CONTACT_UPDATE_FAILED,
});

export const updateContactPresence = (
  presenceDetails,
  isNewSubscriber,
  isSubscriberLeft,
  projectId,
  userId
) => ({
  type: UPDATE_CONTACT_PRESENCE,
  presenceDetails,
  isNewSubscriber,
  isSubscriberLeft,
  projectId,
  userId
});

export const getVisitorTimeZone = (peopleKey, latitude, longitude, people) => ({
  type: GET_VISITOR_TIMEZONE,
  peopleKey,
  latitude,
  longitude,
  people
});
export const visitorTimeZoneResponse = (
  peopleKey,
  latitude,
  longitude,
  timeZoneObject, 
  people
) => ({
  type: VISITOR_TIMEZONE_RESPONSE,
  peopleKey,
  latitude,
  longitude,
  timeZoneObject,
  people
});
export const visitorTimeZoneFailed = (peopleKey, people) => ({
  type: VISITOR_TIMEZONE_FAILED,
  peopleKey,
  people
});
