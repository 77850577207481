import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { config } from 'src/config'
import { Container } from 'src/features/authorization/components/GoogleButton/styled'
import { Button } from 'src/features/form/components/Button'
import { IFullButtonProps } from 'src/features/form/components/Button/types'
import { Icon } from 'src/features/ui/components/Icon'
import { Text5 } from 'src/features/ui/components/Typography'

export const GoogleButton: FC<IFullButtonProps> = (buttonProps) => (
  <Container href={`${config.chatsupportApiUrl}/v1/account/signup/google`}>
    <Button {...buttonProps} color="primary" colorType="hollow" hasFullWidth>
      <Icon width={16} height={16} type="google" />
      <Margin left={20} />
      <Text5 color="dodgerBlue" bold>
        Sign up with Google
      </Text5>
    </Button>
  </Container>
)
