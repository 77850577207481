import * as R from 'ramda'
import * as Yup from 'yup'
import {
  WidgetPosition,
  WidgetType,
} from 'src/features/wizard/setup/store/types'

export const colors = ['#2355F3', '#03c599', '#646178', '#3c4859', '#1F84A1']

export const options = [
  { label: 'Bottom Right', value: WidgetPosition.BOTTOM_RIGHT },
  { label: 'Bottom Left', value: WidgetPosition.BOTTOM_LEFT },
  { label: 'Top Right', value: WidgetPosition.TOP_RIGHT },
  { label: 'Top Left', value: WidgetPosition.TOP_LEFT },
]

export const initialValues = {
  color: '#2355F3',
  icon: { url: '', color: '#2355F3' },
  widgetType: WidgetType.DEFAULT,
  position: options[0].value,
}

export const validationSchema = Yup.object().shape({
  color: Yup.string(),
  icon: Yup.object().shape({
    url: Yup.string().url(),
    color: Yup.string(),
  }),
  widgetType: Yup.mixed().oneOf([WidgetType.DEFAULT, WidgetType.ICON]),
  position: Yup.mixed().oneOf(R.pluck('value', options)),
})

export interface IFormValues {
  color: string
  icon: {
    url: string
    color: string
  }
  widgetType: WidgetType
  position: WidgetPosition
}
