import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { config } from 'src/config'
import {
  selectUserId,
  selectWidgetId,
} from 'src/features/authorization/store/selectors'
import { Button } from 'src/features/form/components/Button'
import { Container } from 'src/features/returningFlow/components/Perfect/styled'
import { IProps } from 'src/features/returningFlow/components/Perfect/types'
import { Bold, SubTitle, Title } from 'src/features/ui/components/Typography'
import { IAppState } from 'src/utils/state/store'

export const PerfectComponent: FC<IProps> = ({ widgetId, userId }) => {
  const handleGoToConversations = () => {
    window.location.href = `${config.chatsupportAppUrl}/app/${widgetId}/chat/guests/D/O/N/${userId}`
  }

  return (
    <Container id="acknowledge-container">
      <Title>
        Perfect! <br />
        You&apos;re ready to start taking chats.
      </Title>
      <Margin top={32} />
      <SubTitle color="raven">
        Go to <Bold color="raven">Conversations</Bold> to start interacting with
        your website visitors.
      </SubTitle>
      <Margin top={40} />
      <Button
        onClick={handleGoToConversations}
        maxWidth={16.8}
        color="primary"
        hasFullWidth
      >
        Go to Conversations
      </Button>
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  widgetId: selectWidgetId(state.auth)!,
  userId: selectUserId(state.auth)!,
})

export const Perfect = connect(mapStateToProps)(PerfectComponent)
