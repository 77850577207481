import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { store } from "../../app.js";
import { updatePeopleGroupRequest } from "../actions/ContactsAction.js";
import { SubFilterIcon, TeamEditIcon } from "../../commons/Icons.js";
import {
  MAIN_GROUP_IDS,
  DEFAULT_GROUP_IDS,
  DEFAULT_GROUP_FILTER_COLOR,
} from "../commons/ContactConstants.js";
import {
  getGroupType,
  getGroupIconSrc,
  getGroupMap,
} from "../commons/ContactsUtility.js";
import * as loaders from "../../settings/commons/loaders";

const FilterHeader = (props) => {
  const [name, setName] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const {
    contacts: { peopleGroupMap = {}, initialLoadComplete },
  } = props;
  const {
    params: { groupId, projectkey },
  } = props;
  const group = peopleGroupMap[groupId] || {};
  const { groupName = "", count = 0, key } = group;
  const { setShowFilterSection, showFilterSection } = props;
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    setName(groupName);
  }, [group, groupName]);

  useEffect(() => {
    setShowLabel(true);
    setShowEdit(false);
  }, [groupId]);

  useEffect(() => {
    if (showEdit) inputRef.current.focus();
  }, [showEdit]);

  const getFilterIcon = () => {
    if (group && group.references) {
      let iconColor = group.avatarColor || DEFAULT_GROUP_FILTER_COLOR;
      let groupType = getGroupType(group);
      let imageSrc = getGroupIconSrc(groupType);
      let subIcon = !MAIN_GROUP_IDS.includes(groupId) ? (
        <i style={{ backgroundColor: iconColor }}>
          <SubFilterIcon />
        </i>
      ) : (
        ""
      );
      return (
        <figure>
          <img src={imageSrc} />
          {subIcon}
        </figure>
      );
    }
    return "";
  };

  const toggleLabel = (showLabel) => {
    setShowEdit(!showLabel);
    setShowLabel(showLabel);
  };

  const onBlur = ({ target }) => {
    let { value } = target;
    let groupName = value.trim();
    if (!groupName || groupName.length < 3 || groupName == group.groupName) {
      setName(group.groupName);
      toggleLabel(true);
      return;
    }
    let {
      UserReducer: {
        data: { id: userId },
      },
    } = store.getState();
    let groupInfo = Object.assign(group, { groupName });
    dispatch(updatePeopleGroupRequest(groupInfo));
    toggleLabel(true);
  };

  const onChange = ({ target }) => {
    let { value } = target;
    setName(value);
  };

  const getLoader = () => {
    let headerLoader = [];
    headerLoader.push(loaders.ImageLoader());
    headerLoader.push(loaders.PTagLoader());
    return headerLoader;
  };

  if (key && initialLoadComplete) {
    return (
      <div className={"cs-people-header"}>
        {getFilterIcon()}
        <label
          className={DEFAULT_GROUP_IDS.includes(key) ? "" : "label-edit"}
          style={{ display: showLabel ? "inline-block" : "none" }}
        >
          {name}
          <TeamEditIcon
            onClick={() => {
              toggleLabel(false);
            }}
          />
        </label>
        <input
          ref={inputRef}
          type="text"
          onChange={onChange}
          onBlur={onBlur}
          value={name}
          style={{ display: showEdit ? "block" : "none" }}
        />
        <p>
          {`${count} contacts`}
          <a
            style={{ display: showFilterSection ? "none" : "inline-block" }}
            onClick={() => {
              setShowFilterSection(true);
            }}
            href="#"
          >
            Add Filters
          </a>
        </p>
      </div>
    );
  }
  return <div className={"cs-people-header"}>{getLoader()}</div>;
};

export default FilterHeader;
