import styled, { keyframes } from 'styled-components'
import { Icon } from 'src/features/ui/components/Icon'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const SpinnerContainer = styled.div<{ size: number }>`
  height: ${(props) => props.size / 10 + 0.1}rem;
  width: ${(props) => props.size / 10 + 0.1}rem;
  overflow: hidden;
`

export const SpinnerIcon = styled(Icon)`
  animation: ${rotate360} 1s linear infinite;
`
