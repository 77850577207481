import React from "react";
import { SearchIcon } from "../../commons/Icons.js";

const GroupsSearch = (props) => {
  return (
    <header>
      <SearchIcon style={{ display: "none" }} className="search-icon" />
      <h2>Dashboard</h2>
    </header>
  );
};

export default GroupsSearch;
