import * as R from 'ramda'
import styled from 'styled-components'
import { Text6 } from 'src/features/ui/components/Typography'

export const Divider = styled(Text6)`
  display: flex;
  flex-direction: row;
  color: ${R.path(['theme', 'color', 'baliHai'])};

  &::after,
  &::before {
    content: '';
    flex: 1 1;
    border-bottom: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
    margin: auto;
  }

  &::before {
    margin-right: 0.8rem;
  }

  &::after {
    margin-left: 0.8rem;
  }
`
