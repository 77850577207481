import React, { Component } from "react";
import ReactDOM from "react-dom";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import ChatAnsweringPopup from "../components/chat/ChatAnsweringPopup.jsx";
import { MESSAGE_TYPE, MESSAGE_FROM, INCOMING_WIDGET_POPUP_EVENT, CONNECT_ANSWERING_POPUP_TIME } from "../commons/Constants";
import {
  generateMessageId,
  getProjectKey,
  getServerTime,
  isValidObject,
  generateHashCode,
  getIsAWRecentComponent,
  getFullName,
  getChatAssignedTypeByComponent
} from "../commons/Utility";
import { dequeueChat } from "../actions/ChatAnsweringAction";
import { showVoicebox } from "../actions/VoiceboxAction";
import { acceptChat, enqueueChat } from "../actions/ChatAnsweringAction";
import { sendMessage } from "../actions/MessageAction";
import { updateOpenConversationCountRealtime } from "../actions/StaffAction";
import { updateUnassingedConversationCountRealtime } from "../settings/actions/ProjectAction";
import {
  updateConversationResponse,
  trackChatStatus,
} from "../actions/ConversationInfoAction.js";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";
import ExternalBaseApis from "../aw_recents/ExternalBaseApis.js";
import { removeMessagesAndConversationIfOverflowedOrRejected } from "../aw_recents/AwRecentsUtils.js";
import IncomingPopup from "../aw_recents/components/IncomingPopup";
@connect((state) => ({
  ChatAnswering: state.ChatAnsweringReducer,
  projectReducer: state.ProjectReducer,
  userReducer: state.UserReducer,
  ConversationReducer: state.ConversationInfoReducer,
}))
class ChatAnsweringContainer extends Component {
  constructor(props) {
    super(props);

    this.onAccept = this.onAccept.bind(this);
    this.onReception = this.onReception.bind(this);
    this.onReject = this.onReject.bind(this);
    this.getChatMessage = this.getChatMessage.bind(this);
    this.showEnqueuedRing = this.showEnqueuedRing.bind(this);
    this.setAutoOverflowTimer = this.setAutoOverflowTimer.bind(this);
    this.autoOverflowChat = this.autoOverflowChat.bind(this);
    this.state = {
      autoOverflowTimer: undefined,
      removedConversationId : undefined
    };
    this.clearOverflowTimeout = this.clearOverflowTimeout.bind(this);
  }


  componentWillUnmount() {
    if (this.state.autoOverflowTimer)
      clearTimeout(this.state.autoOverflowTimer);
  }

  onAccept(conversationId) {
    /* this.props.dispatch( showVoicebox( { message : 'Assigning Chat...', dismissAfter : 2000 } ) ); */
    let incomingLiveChat = this.props.ChatAnswering.incomingChatsQueue[
      conversationId
    ];

    this.clearOverflowTimeout(conversationId);

    let conversationObject = this.props.ConversationReducer.conversationObject
      .conversationMap[conversationId];

    this.props.dispatch(dequeueChat(conversationId));

    let agentId = this.props.userReducer.data.id;

    let acceptedMessage = this.getChatMessage(
      incomingLiveChat,
      conversationId,
      agentId,
      MESSAGE_TYPE.chat_accepted
    );

    if(getIsAWRecentComponent()){
      acceptedMessage.isAWAgent = true;
    }

    this.props.dispatch(
      sendMessage(acceptedMessage, {
        echoChannels: [
          "private/agent/LS-" + getProjectKey(acceptedMessage.projectId),
        ],
      })
    );

    // if( incomingLiveChat.isOverflowChat ) {
    //     //window.open( `https://aw-cs-integration-dot-webapp-dot-staging-live-support.appspot.com/anywhereworks/D?conversation_id=${incomingLiveChat.conversationId}&agent_id=05db384a-a862-4a55-8fd4-708ecacc70eb&agent_first_name=Karthik&agent_last_name=Amar&agent_photo_url=https://storage.googleapis.com/test_crm/91dfed2f-d29f-4302-89ee-341e9364b941/05db384a-a862-4a55-8fd4-708ecacc70eb/profile_picture?test=1568296663324&project_id=${incomingLiveChat.projectId}&alias_name=null&agent_login=karthik.amar@anywhere.co&agent_accountId=undefined"`, '_blank' );
    //     window.open( `http://localhost:8888/anywhereworks/D?conversation_id=${incomingLiveChat.conversationId}&agent_id=05db384a-a862-4a55-8fd4-708ecacc70eb&agent_first_name=Karthik&agent_last_name=Amar&agent_photo_url=https://storage.googleapis.com/test_crm/91dfed2f-d29f-4302-89ee-341e9364b941/05db384a-a862-4a55-8fd4-708ecacc70eb/profile_picture?test=1568296663324&project_id=${incomingLiveChat.projectId}&alias_name=null&agent_login=karthik.amar@anywhere.co&agent_accountId=undefined&isOverflowChat=${incomingLiveChat.isOverflowChat}&isOverflowChat=${incomingLiveChat.isOverflowChat}&isOverflowChat=${incomingLiveChat.isOverflowChat}` , '_blank');
    //     return;
    // }

    let conversation = this.props.ConversationReducer.conversationObject
      .conversationMap[conversationId];
    let assigningMessage = this.getChatMessage(
      incomingLiveChat,
      conversationId,
      agentId,
      getChatAssignedTypeByComponent()
    );

    this.sendStaffJoiningMessage(incomingLiveChat, conversationId, agentId);
    assigningMessage["isAlreadyAssigned"] = false;

    conversation.assignedTo = agentId;
    conversation.isModified = false;
    conversation.chatStatus = "OPEN";
    conversation.overflowStatus = "NOT_OVERFLOWED";

    /* this.props.dispatch( acceptChat(  {
            conversationObject : conversationObject ,
            assigningMessage  : assigningMessage ,
            answeredAgent : agentId
        } ) ); */

    console.info("updated conversation:" + JSON.stringify(conversation));

    let meta = {
      echoChannels: ["private/agent/" + conversation.projectId],
    };

    this.props.dispatch(updateOpenConversationCountRealtime(assigningMessage));
    this.props.dispatch(
      updateUnassingedConversationCountRealtime(assigningMessage)
    );

    this.props.dispatch(sendMessage(assigningMessage, meta, false));

    this.props.dispatch(updateConversationResponse(conversation));

    let statusTrackerInfo = {
      loadedDate: getServerTime(),
      webAppId: generateHashCode(conversation.key + conversation.createdDate),
      isConnected: true,
      tool: ["Dashboard"],
    };
    this.props.dispatch(trackChatStatus(statusTrackerInfo));

    if (getIsAWRecentComponent() && window.FrontOfficeService) {
        ExternalBaseApis.addItemToConnectRecent({ itemId: conversation.key });
        ExternalBaseApis.navigateToRecentItem(conversation.key);
      } else {
        browserHistory.push({
          pathname:
            "/app/" +
            getProjectKey(conversation.projectId) +
            "/chat/dashboard/D/O/N/" +
            conversation.assignedTo +
            "/" +
            conversationId,
        });
      }

    // Event("LS-" + conversationObject.projectId , "Chat Answering","Accept","Accept Chat Clicked");

    //this.props.resetRinging();
  }

  sendStaffJoiningMessage(incomingLiveChat, conversationId, agentId) {
    let joiningMessage = this.getChatMessage(
      incomingLiveChat,
      conversationId,
      agentId,
      MESSAGE_TYPE.staff_joined_conversation
    );

    this.props.dispatch(
      sendMessage(joiningMessage, {
        echoChannels: [
          "private/agent/LS-" + getProjectKey(joiningMessage.projectId),
        ],
      })
    );
  }

  getChatMessage(incomingLiveChat, conversationId, agentId, type) {
    let userData = this.props.userReducer.data;
    let { projectId, visitorId, answeringId } = incomingLiveChat;

    return {
      type: type,
      channel: "private/visitor/" + projectId + "/" + visitorId,
      message: "",
      actionTo: agentId,
      senderId: agentId,
      conversationId: conversationId,
      messageFrom: MESSAGE_FROM.AGENT,
      messageId: generateMessageId(),
      visitorId: visitorId,
      projectId: projectId,
      messageStatus: "CHAT_SENDING",
      interactionType: "DASH_BOARD",
      agentDetails: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        photoUrl: userData.photoID,
        aliasName: userData.aliasName,
      },
      answeringId: answeringId,
    };
  }

  clearOverflowTimeout(conversationId){
    if(this.state.autoOverflowTimer){
      clearTimeout(this.state.autoOverflowTimer);
      this.setState({autoOverflowTimer : undefined , removedConversationId : conversationId});
    }
  }

  onReception(conversationId, isAutoOverflow) {
    let incomingLiveChat = this.props.ChatAnswering.incomingChatsQueue[
      conversationId
    ];
    this.clearOverflowTimeout(conversationId);
    if (!incomingLiveChat || !incomingLiveChat.isOverFlowEnabled) {
      if (!isAutoOverflow)
        this.props.dispatch(
          showVoicebox({
            message: "Overflow is not enabled...",
            dismissAfter: 2000,
          })
        );
      return;
    }

    if (!getIsAWRecentComponent()) {
      this.props.dispatch(
        showVoicebox({
          message: "Overflowing Chat...",
          showFullScreenLoader: !window.location.href.includes("/app/"),
          dismissAfter: 2000,
        })
      );
    }
    this.props.dispatch(dequeueChat(conversationId));
    let agentId = this.props.userReducer.data.id;
    let forwardingMessage = this.getChatMessage(
      incomingLiveChat,
      conversationId,
      agentId,
      "FORWARDING_MESSAGE"
    );

    if (!isAutoOverflow)
      Event(
        "LS-" + incomingLiveChat.projectId,
        "Chat Answering",
        "Reception",
        "Reception Icon Clicked"
      );
    console.info("forwardingMessage:" + JSON.stringify(forwardingMessage));
    //this.props.resetRinging();
    this.props.dispatch(
      sendMessage(forwardingMessage, {
        echoChannels: ["private/agent/" + forwardingMessage.projectId],
      })
    );
    removeMessagesAndConversationIfOverflowedOrRejected(conversationId);
  }

  onReject(conversationId) {
    let incomingLiveChat = this.props.ChatAnswering.incomingChatsQueue[
      conversationId
    ];

    this.props.dispatch(dequeueChat(conversationId));
    let agentId = this.props.userReducer.data.id;
    let rejectionMessage = this.getChatMessage(
      incomingLiveChat,
      conversationId,
      agentId,
      "CHAT_REJECTED"
    );
    let rejectedAgentIds = incomingLiveChat.rejectedAgentIds || [];

    rejectedAgentIds.push(agentId);
    rejectionMessage.rejectedAgentIds = rejectedAgentIds;
    rejectionMessage.rejected = true;

    console.info("rejectionMessage:" + JSON.stringify(rejectionMessage));
    //this.props.resetRinging();
    this.props.dispatch(
      sendMessage(rejectionMessage, {
        echoChannels: ["private/agent/" + rejectionMessage.projectId],
      })
    );
    removeMessagesAndConversationIfOverflowedOrRejected(conversationId);
  }

  showEnqueuedRing() {
    let converationIds = Object.keys(
      this.props.ChatAnswering.incomingChatsQueue
    );
    if (converationIds.length > 0) {
      let incomingLiveChat = this.props.ChatAnswering.incomingChatsQueue[
        converationIds[0]
      ];
      this.props.dispatch(enqueueChat(incomingLiveChat));
    }
  }

  getProjectName = (answeringRing) => {
    let {projectId} = answeringRing;
    let projects = this.props.projectReducer && this.props.projectReducer.projectList
       ? this.props.projectReducer.projectList : [];
    let project = projects
      ? projects.find(
          (_project) => _project.key == projectId
        )
      : {};
    return project && project.name ? project.name : "Customer Chat";
  }

   setAutoOverflowTimer(answeringRing={}) {
    if(!this.state.autoOverflowTimer){
      let autoOverflowTimer = setTimeout(() => {
        this.autoOverflowChat(answeringRing);
      }, CONNECT_ANSWERING_POPUP_TIME);
      this.setState({ autoOverflowTimer });
    }
  }

  autoOverflowChat(answeringRing) {
    let { conversationId, isOverFlowEnabled} = answeringRing;
    if(getIsAWRecentComponent() && isOverFlowEnabled){
      this.onReception(conversationId, true);
    }
    
    Event(
      "",
      "Chat Answering From Connect",
      "Auto Overflowed from Connect",
      "Chat Auto Overflowed - Connect"
    );
  }

  getReceptionButton = () => {
    return (
      <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6701 8.5078C14.8594 8.3216 15.1557 8.3356 15.3464 8.5078C15.5378 8.68 15.523 9.0013 15.3464 9.1756L14.9973 9.52L12.5492 11.9378L13.3894 11.1069L18.2449 11.1076C18.4933 11.1076 18.6826 11.2945 18.7242 11.5318L18.7319 11.6046C18.7446 11.8734 18.5011 12.1016 18.2456 12.1016L13.3922 12.1023L15.3464 14.0322C15.535 14.2184 15.5209 14.511 15.3464 14.6993C15.1719 14.8883 14.8467 14.8743 14.6708 14.6993L14.3218 14.3549L11.8729 11.9378L11.9433 12.0078C11.8985 11.9741 11.8598 11.9331 11.8286 11.8867C11.7681 11.8042 11.7361 11.7046 11.7371 11.6025C11.7359 11.5255 11.7533 11.4494 11.788 11.3805C11.8227 11.3117 11.8735 11.2521 11.9362 11.207L12.2219 10.9256L14.6708 8.5078H14.6701ZM8.87371 4.284C9.04893 4.0957 9.37334 4.1097 9.55067 4.284L9.89971 4.6291L12.3486 7.0462L12.2606 6.958C12.3291 7.00507 12.3847 7.06833 12.4225 7.1421C12.4633 7.2121 12.4844 7.2905 12.4858 7.3689V7.3794C12.4877 7.46104 12.4688 7.54182 12.4309 7.61426C12.393 7.6867 12.3374 7.74846 12.2691 7.7938L12.0003 8.0584L9.55067 10.4762C9.36208 10.6624 9.06512 10.6484 8.87652 10.4762C8.68582 10.304 8.69989 9.9827 8.87652 9.8084L9.22556 9.464C10.0404 8.6576 10.8582 7.8505 11.6723 7.0462L10.8307 7.8764H6.2123C5.97163 7.8764 5.78797 7.6874 5.74786 7.4515L5.74012 7.3794C5.73781 7.2517 5.78586 7.12817 5.87398 7.03527C5.96211 6.94238 6.0833 6.8875 6.2116 6.8824H10.83L8.87371 4.9518C8.68582 4.7656 8.69989 4.473 8.87371 4.2847V4.284Z" fill="white"/>
<path d="M11.7694 17.7737C11.7694 18.1972 11.7448 19.3067 11.7399 19.3137C11.6991 19.3942 11.7913 19.3347 11.7399 19.3557C11.723 19.3634 10.6667 19.3557 10.1784 19.3557H4.00267C3.29404 19.3557 1.46723 19.3039 1.47497 19.3137C1.46089 19.1408 1.47004 18.2665 1.47004 17.8479V1.4875C1.46511 1.3916 1.47215 1.5155 1.46019 1.5155C1.45808 1.5155 1.47215 1.463 1.47426 1.4539C1.49185 1.3629 1.47215 1.4868 1.46019 1.4819C1.45808 1.4819 1.48693 1.4343 1.48904 1.4294C1.53126 1.3482 1.47708 1.4609 1.46793 1.4539C1.46511 1.4511 1.50663 1.4154 1.51156 1.4084C1.57489 1.3363 1.44541 1.4392 1.49889 1.4154C1.51799 1.40748 1.53596 1.39713 1.55237 1.3846C1.59108 1.3531 1.45808 1.4084 1.5383 1.3888C1.53337 1.3888 1.60163 1.3727 1.60163 1.3727C1.60374 1.3867 1.48904 1.3769 1.56926 1.3769H1.61289C2.09634 1.3699 2.58189 1.3769 3.06534 1.3769H11.3789C11.4521 1.3769 11.7652 1.3727 11.7652 1.5995V3.3635C11.7652 3.6141 11.9883 3.8535 12.2508 3.8416C12.5133 3.8297 12.7363 3.6316 12.7363 3.3635V1.5491C12.7363 1.169 12.5886 0.832299 12.2677 0.607599C12.0129 0.430499 11.7286 0.421399 11.4352 0.421399H2.04567C1.9 0.421399 1.75152 0.418599 1.60585 0.421399C0.989411 0.433299 0.498225 0.901599 0.494003 1.5113C0.491188 1.8501 0.494003 2.1875 0.494003 2.5263V19.1415C0.494003 19.5818 0.707225 20.0074 1.13015 20.1957C1.37785 20.3056 1.61078 20.3035 1.86834 20.3035H11.2691C11.3958 20.3035 11.5239 20.3056 11.6505 20.3035C12.1459 20.2916 12.59 19.9542 12.7019 19.4719C12.7335 19.3305 12.7335 19.1919 12.7335 19.0512V15.4511C12.7335 15.2005 12.5097 14.9611 12.248 14.973C11.9925 14.9898 11.7694 15.1886 11.7694 15.456V17.7744V17.7737Z" fill="white" stroke="white"/>
</svg>
    )
  }
  // #codesmells can be accepted or rejected button
  getAccceptButton() {
    return (<svg
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0005 0C11.8237 0 13.4965 0.374727 15.0197 1.125C16.5437 1.87527 17.7541 2.88655 18.6525 4.15882C19.5509 5.43109 20.0005 6.83018 20.0005 8.35691C20.0005 9.88364 19.5509 11.2835 18.6525 12.5558C17.7541 13.8281 16.5437 14.8385 15.0205 15.5896C13.4965 16.3391 11.8237 16.7146 10.0005 16.7146C8.77651 16.7146 7.56531 16.5265 6.36771 16.1517C4.67491 17.3839 2.86531 18 0.938112 18C0.755712 18 0.586112 17.9468 0.430112 17.8396C0.276272 17.7356 0.154263 17.5893 0.0781124 17.4175C0.000590003 17.2482 -0.0199456 17.0575 0.0197124 16.875C0.0597124 16.6868 0.137312 16.5338 0.254112 16.4127C0.371712 16.2925 0.599712 15.9914 0.938112 15.5086C1.45811 14.7854 1.83651 14.0891 2.07091 13.4198C0.690912 11.9193 0.000512393 10.2322 0.000512393 8.35691C0.000512393 6.83018 0.450112 5.43109 1.34851 4.15882C2.24691 2.88655 3.45731 1.87527 4.98051 1.125C6.50451 0.374727 8.17811 0 10.0005 0ZM10.0005 14.7854C11.4589 14.7854 12.8133 14.4982 14.0629 13.9222C15.3133 13.3462 16.3029 12.5624 17.0317 11.5715C17.7613 10.5807 18.1253 9.50891 18.1253 8.35691C18.1253 7.20573 17.7613 6.13391 17.0317 5.14309C16.3029 4.15145 15.3133 3.36764 14.0637 2.79245C12.8133 2.21645 11.4589 1.92845 10.0005 1.92845C8.54211 1.92845 7.18771 2.21645 5.93811 2.79245C4.68771 3.36845 3.69811 4.15145 2.96931 5.14309C2.23971 6.13391 1.87571 7.20491 1.87571 8.35691C1.87571 9.72327 2.39571 10.9685 3.43811 12.0935L4.21891 12.9772L3.82851 14.103C3.62051 14.6381 3.35971 15.1879 3.04771 15.75C3.84781 15.472 4.5994 15.0651 5.27411 14.5448L6.05491 14.022L6.95331 14.3035C7.94291 14.625 8.95891 14.7862 10.0005 14.7862V14.7854Z"
        fill="white"
      />
    </svg>)
  }
  
  getRejectButton() {
    return (<svg
      width={20}
      height={18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0005 0C11.8237 0 13.4965 0.374727 15.0197 1.125C16.5437 1.87527 17.7541 2.88655 18.6525 4.15882C19.5509 5.43109 20.0005 6.83018 20.0005 8.35691C20.0005 9.88364 19.5509 11.2835 18.6525 12.5558C17.7541 13.8281 16.5437 14.8385 15.0205 15.5896C13.4965 16.3391 11.8237 16.7146 10.0005 16.7146C8.77651 16.7146 7.56531 16.5265 6.36771 16.1517C4.67491 17.3839 2.86531 18 0.938112 18C0.755712 18 0.586112 17.9468 0.430112 17.8396C0.276272 17.7356 0.154263 17.5893 0.0781124 17.4175C0.000590003 17.2482 -0.0199456 17.0575 0.0197124 16.875C0.0597124 16.6868 0.137312 16.5338 0.254112 16.4127C0.371712 16.2925 0.599712 15.9914 0.938112 15.5086C1.45811 14.7854 1.83651 14.0891 2.07091 13.4198C0.690912 11.9193 0.000512393 10.2322 0.000512393 8.35691C0.000512393 6.83018 0.450112 5.43109 1.34851 4.15882C2.24691 2.88655 3.45731 1.87527 4.98051 1.125C6.50451 0.374727 8.17811 0 10.0005 0ZM10.0005 14.7854C11.4589 14.7854 12.8133 14.4982 14.0629 13.9222C15.3133 13.3462 16.3029 12.5624 17.0317 11.5715C17.7613 10.5807 18.1253 9.50891 18.1253 8.35691C18.1253 7.20573 17.7613 6.13391 17.0317 5.14309C16.3029 4.15145 15.3133 3.36764 14.0637 2.79245C12.8133 2.21645 11.4589 1.92845 10.0005 1.92845C8.54211 1.92845 7.18771 2.21645 5.93811 2.79245C4.68771 3.36845 3.69811 4.15145 2.96931 5.14309C2.23971 6.13391 1.87571 7.20491 1.87571 8.35691C1.87571 9.72327 2.39571 10.9685 3.43811 12.0935L4.21891 12.9772L3.82851 14.103C3.62051 14.6381 3.35971 15.1879 3.04771 15.75C3.84781 15.472 4.5994 15.0651 5.27411 14.5448L6.05491 14.022L6.95331 14.3035C7.94291 14.625 8.95891 14.7862 10.0005 14.7862V14.7854Z"
        fill="white"
      />
    </svg>)
  }

  getDeclineObjects(isOverFlowEnabled, conversationId) {
    if(isOverFlowEnabled) {
      return {
        icon : this.getReceptionButton,
        handler : () => {
          this.onReception(conversationId , true );
        },
        text : "Reception"
      }

    } else {
      return {
        icon : this.getRejectButton,
        handler : () => {
          this.onReject(conversationId) 
        },
        text : "Reject"
      }
    }
  }

  getAcceptObject(conversationId) {
    return {
      icon : this.getAccceptButton,
      handler : () => {
        this.onAccept(conversationId);
      },
      text : "Accept"
    }
  }

  getCSChatAnsweringPopupComponent(answeringRing) { 
    return (
      <ChatAnsweringPopup
        incomingLiveChat={answeringRing}
        userReducer = {this.props.userReducer}
        projects={this.props.projectReducer.projectList}
        onAccept={this.onAccept}
        onReception={this.onReception}
        onReject={this.onReject}
        dispatch={this.props.dispatch}
      />
    );
   }

   hasAnsweringPopupRendered() {
    return document.getElementById('incoming-widget');
   }

  getChatAnsweringPopupByMode(answeringRing) {
    console.log("Going to add popup  :" , answeringRing.conversationId);
    if(getIsAWRecentComponent() && (answeringRing.conversationId !== this.state.removedConversationId)){
      console.log("Going to add AW popup  :" , answeringRing.conversationId);
      this.setAutoOverflowTimer(answeringRing);
      ExternalBaseApis.makeConnectAppFocus();
      return this.getAWChatAnsweringPopupComponent(answeringRing)
    } else if(!getIsAWRecentComponent()){
      return this.getCSChatAnsweringPopupComponent(answeringRing);
    }
  }


  triggerIncomingPopupFromParentContainer(propsToRenderPopup) {
    try {
      return <IncomingPopup props={propsToRenderPopup} />;
    } catch (error) {
      console.error(
        "Error occured on triggerIncomingPopupFromParentContainer: ",
        error
      );
    }
  }
  

  getAWChatAnsweringPopupComponent(answeringRing={}) {    
    let userReducer = this.props.userReducer || {};
    let agentContact = userReducer && userReducer.data && userReducer.data.contact ? userReducer.data.contact : {};
    let { visitorDetails = {} } = answeringRing;
    let fullName = getFullName(visitorDetails) || visitorDetails.type;
    let contact = {
      fullName : fullName,
      photoID : visitorDetails.profileImage
    }
    let {isOverFlowEnabled, conversationId} = answeringRing;
    let { icon: declineIcon, handler : declineHandler, text: declineText } = this.getDeclineObjects(isOverFlowEnabled, conversationId);
    let { icon: acceptIcon, handler : acceptHandler, text: acceptText } = this.getAcceptObject(conversationId);
    function forwardEvent(){
          // This is intentional
    }
    let propsToRenderPopup = {
      detail: {
          contact: contact,
          calllerName : contact.fullName,
          title : this.getProjectName(answeringRing),
          forwardEnable:false,
          forwardEvent: forwardEvent,
          brandId: agentContact.brandID,
          acceptIncomingEvent: acceptHandler,
          declineIncomingEvent: declineHandler,
          isBusinessLine: false,
          acceptIcon: acceptIcon,
          acceptText: acceptText,
          declineText: declineText,
          declineIcon: declineIcon,
          elementToRemove : INCOMING_WIDGET_POPUP_EVENT

      }
    }
    console.log("Rendering Popup in AW :" , propsToRenderPopup);
    return this.triggerIncomingPopupFromParentContainer(propsToRenderPopup);
  }

  render() {
    let chatAnsweringRings = Object.values(
      this.props.ChatAnswering.answeringRing
    );
    let answeringRings = chatAnsweringRings.map((answeringRing) => {
      if (
        isValidObject(
          this.props.ConversationReducer.conversationObject.conversationMap[
            answeringRing.conversationId
          ]
        )
      ) {
        return this.getChatAnsweringPopupByMode(answeringRing);
      }
    });
    return <div> {answeringRings} </div>;
  }
}

export default ChatAnsweringContainer;
