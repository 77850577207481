import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import AddNewTag from "./AddNewTag.jsx";
import { store } from "../../../app.js";
import {
  getDOMRootElement,
  getValueFromNestedObject,
  isClickedInside,
  isEscKey,
  isOverflowConnection,
} from "../../../commons/Utility.js";
import { conversationRequest } from "../../../actions/ConversationInfoAction";
import TagSearchBar from "./TagSearchBar.jsx";
import TagDropdown from "./TagDropdown.jsx";
import { INTERACTION_TYPE } from "../../../commons/Constants.js";

function TagMessageDropdown({
  messageId,
  conversationId,
  tags,
  openDropDown,
  position,
  hideIcon,
  messageOptionPosition,
}) {
  const [showTagInput, setShowTagInput] = useState(false);
  const [tagInputValue,setTagInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isMouseOverAddTag, setIsMouseOverAddTag] = useState();
  const tagInputRef = useRef("");
  const searchInputRef = useRef("");
  const DIST_BETWEEN_MESSAGEOPTIONBTM_AND_DROPDOWNBTM = 40;
  const DIST_BETWEEN_MESSAGEOPTIONTOP_AND_DROPDOWNTOP = 40;

  const dispatch = useDispatch();
  useEffect(() => {
    if (dropDown && dropDown.current) {
      dropDown.current.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      if (dropDown && dropDown.current) {
        dropDown.current.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);
  
  const handleClickOutside = (event) => {
    if (!isClickedInside("tag-dropdown", event)) {
      openDropDown(false);
    }
  };

  useEffect(() => {
    getDOMRootElement().addEventListener("click", handleClickOutside);
    return () => {
      getDOMRootElement().removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    hideIcon(!searchTerm && !tagInputValue);
  }, [searchTerm, tagInputValue]);

  const handleKeyDown = (event) => {
    if (isEscKey(event)) {
      event.stopPropagation();
      tagInputRef.current ? setShowTagInput(false) : openDropDown(false);
    }
  };

  const updateSearchTerm = (srchTerm) => {
    setSearchTerm(srchTerm);
  };

  const updateTagInputValue = (tagName) => {
    setTagInputValue(tagName);
  };

  const requestSingleConversationAndAddTagToIt = (tagNameList) => {
    const tagObject = { tagNameList, messageId };
    const { projectId, interactionType } = getMessageObject();
    if (interactionType === INTERACTION_TYPE.DASHBOARD)
      dispatch(conversationRequest({ projectId, conversationId }, tagObject));
    else if (interactionType === INTERACTION_TYPE.OVERFLOW)
      dispatch(
        conversationRequest(
          { isFromOverflow: true, projectId, conversationId },
          tagObject
        )
      );
  };

  const getMessageObject = () => {
    const {
      MessageReducer: { messageMap },
    } = store.getState();
    for (const conversation in messageMap) {
      if (messageMap[conversation][messageId])
        return messageMap[conversation][messageId];
    }
  };

  const getConversationByMessage = () => {
    let conversation = getValueFromNestedObject(
      store.getState(),
      `ConversationInfoReducer.conversationObject.conversationMap.${conversationId}`
    );
    return conversation ? conversation : {};
  };

  const getCreatedBy = () => {
    const { assignedTo } = getConversationByMessage();
    let userId = getValueFromNestedObject(
      store.getState(),
      `UserReducer.data.id`
    );
    userId = userId ? userId : null;
    return isOverflowConnection() ? assignedTo : userId;
  };

  const getPayloadForTag = () => {
    const { isOverflowChat } = getConversationByMessage();
    const conversation = getConversationByMessage();
    const createdBy = getCreatedBy();
    return [conversation, createdBy, messageId, isOverflowChat];
  };

  const getClasNameOfAddNewTag = () => {
    return `tag-message-add-tag ${showTagInput ? "add" : ""} `;
  };

  const dropDown = useRef();

  const setTagKey = (tagKey) => {
    setShowTagInput(tagKey);
  };

  const setTagOperation = (tagOperation) => {
    setShowTagInput(tagOperation);
  };

  const doShowTagInput = (shwTagInput) => {
    setShowTagInput(shwTagInput);
  };

  const { messageOptionTop, messageOptionLeft, messageOptionBottom } = messageOptionPosition();

  const getStyleOfDropdown = () => {
    return position === "top"
      ? {
          bottom:
            messageOptionBottom + DIST_BETWEEN_MESSAGEOPTIONBTM_AND_DROPDOWNBTM,
          left: messageOptionLeft,
        }
      : {
          top: messageOptionTop + DIST_BETWEEN_MESSAGEOPTIONTOP_AND_DROPDOWNTOP,
          left: messageOptionLeft,
        };
  };

  return (
    <div
      id="tag-dropdown"
      className={`tag-message-dropdown ${position}`}
      ref={dropDown}
      style={getStyleOfDropdown()}
    >
      {tags.length > 3 ? (
        <div className="tag-search-container">
          <TagSearchBar
            tags={tags}
            setSearchTerm={updateSearchTerm}
            searchInputRef={searchInputRef}
          />
        </div>
      ) : null}
      {tags.length ? (
        <TagDropdown
          tags={tags}
          payload={getPayloadForTag()}
          searchTerm={searchTerm}
          openDropDown={openDropDown}
          searchInputRef={searchInputRef}
          showTagInput={showTagInput}
          setTagKey={setTagKey}
          tagInputRef={tagInputRef}
          messageId={messageId}
          conversationId={conversationId}
          isMouseOverAddTag={isMouseOverAddTag}
          requestSingleConversationAndAddTagToIt={
            requestSingleConversationAndAddTagToIt
          }
        />
      ) : (
        ""
      )}
      <div
        className={getClasNameOfAddNewTag()}
        onMouseOver={(event) => setIsMouseOverAddTag(true)}
        onMouseLeave={(event) => setIsMouseOverAddTag(false)}
        onFocus={ () => {} }
      >
        {showTagInput ? (
          <AddNewTag
            payload={getPayloadForTag()}
            tagOperationOrKey={showTagInput}
            openDropDown={openDropDown}
            tagInputRef={tagInputRef}
            doShowTagInput={doShowTagInput}
            requestSingleConversationAndAddTagToIt={
              requestSingleConversationAndAddTagToIt
            }
            setTagInputValue={updateTagInputValue}
            conversationId={conversationId}
          />
        ) : (
          <div onClick={(event) => setTagOperation("add")} onKeyDown={ () => {} }>
            <span id="add-tag-icon">+ Add tag</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default TagMessageDropdown;
