import React, { FC } from 'react'
import { Free } from 'src/features/authorization/components/Free'
import { FreePlan } from 'src/features/authorization/components/FreePlan'
import { Join } from 'src/features/authorization/components/Join'
import { Header } from 'src/features/ui/components/Header'
import {
  Content,
  Layout,
  Left,
  Right,
} from 'src/features/ui/components/Layout/styled'

export const SignUpPage: FC = () => (
  <Layout>
    <Header hasSignIn />
    <Content>
      <Left shouldCenterContent>
        <Join />
      </Left>
      <Right shouldCenterContent>
        <FreePlan />
        <Free />
      </Right>
    </Content>
  </Layout>
)
