import { Form } from 'formik'
import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const StyledForm = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${mq.sm} {
    max-width: 56.8rem;
    flex-direction: row;
    align-items: flex-end;
  }
`

export const InputContainer = styled.div`
  flex-grow: 1;

  ${mq.md} {
    max-width: 36.8rem;
  }
`

export const ButtonContainer = styled.div`
  flex-grow: 1;
  margin-top: 2rem;

  ${mq.sm} {
    margin-left: 3.2rem;
  }

  ${mq.md} {
    max-width: 16.8rem;
    margin-top: 0;
  }
`
