import { SHOW_VOICEBOX, HIDE_VOICEBOX } from "../actions/VoiceboxAction";

const VoiceboxReducer = (
  state = { message: "", dismissAfter: undefined, display: false },
  action
) => {
  switch (action.type) {
    case SHOW_VOICEBOX:
      return {
        message: action.message,
        dismissAfter: action.dismissAfter,
        display: true,
        showLoader: action.showLoader,
        showFullScreenLoader: action.showFullScreenLoader,
        showError: action.showError,
        // showFullScreen : action.showFullScreen,
        showBelowHeader: action.showBelowHeader,
        showCloseIcon: action.showCloseIcon,
      };
    case HIDE_VOICEBOX:
      return {
        dismissAfter: undefined,
        message: "",
        display: false,
      };

    default:
      return state;
  }
};

export default VoiceboxReducer;
