import React, { Component } from "react";
import ReactDom from "react-dom";
import { Link } from "react-router";
import {
  updateSelectedProjectKey,
  updateUserRequested,
} from "../../actions/UserAction";
import { getProjectKey } from "../../commons/Utility";
import { store } from "../../../app/app";

class ProfileListComponent extends Component {
  constructor(props) {
    super(props);
    this.updateSelectedProfile = this.updateSelectedProfile.bind(this);
  }

  getProjectURL(projectURL) {
    let projectDefaultURL =
      "https://storage.googleapis.com/livesupport/chat/images/logo-placeholder.svg";
    return projectURL && projectURL !== "null" ? projectURL : projectDefaultURL;
  }

  getRedirectURL() {
    let projectId = this.props.projectDetails.key
      ? this.props.projectDetails.key.split("-")[1]
      : "";
    let inviteeId = this.props.inviteeId
      ? `?inviteeId=${this.props.inviteeId}`
      : "";
    let dashboardURL = `/app/${projectId}/chat/dashboard/${inviteeId}`;
    let settingsURL = `/app/${projectId}/settings`;
    if (this.props.userDetails.data && this.props.userDetails.data.originalId)
      return settingsURL;
    else return dashboardURL;
  }

  updateSelectedProfile() {
    const { projectDetails } = this.props;
    store.dispatch(updateSelectedProjectKey(getProjectKey(projectDetails.key)));
  }

  render() {
    return (
      <li key={this.props.projectDetails.key}>
        <div className="container">
          <img
            className="fs-exclude"
            src={this.getProjectURL(this.props.projectDetails.logoUrl)}
          />
          <label className="fs-exclude">{this.props.projectDetails.name}</label>
          <Link to={this.getRedirectURL()} onClick={this.updateSelectedProfile}>
            Choose widget
          </Link>
        </div>
      </li>
    );
  }
}

export default ProfileListComponent;
