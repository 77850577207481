import React, { Component } from "react";
import ActivChatView from "../../containers/ActiveChatViewContainer.js";
import {stripHTML} from "../../commons/Utility";
import { displayVisitorType} from "../../commons/Constants";
import {PlantIcon} from '../../commons/Icons.js';
import { getDisplayTime, isValidURL} from "../../commons/Utility";
import ReactTooltip from "react-tooltip";
import { browserHistory } from "react-router";
import SeenSegments from './SeenSegmentsContainer.js';

class Conversations extends Component {
  constructor() {
    super();
    this.renderConversationList = this.renderConversationList.bind(this);
    this.routeToPeopleTag = this.routeToPeopleTag.bind(this);
    this.getStaffName = this.getStaffName.bind(this);
    this.renderConversationHeader = this.renderConversationHeader.bind(this);
    this.getLastMessage = this.getLastMessage.bind(this);
    this.showPlaceholder = this.showPlaceholder.bind(this);
    this.canShowWrapper = this.canShowWrapper.bind(this);
    this.shouldRenderLoader = this.shouldRenderLoader.bind(this);
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  shouldRenderLoader() {
    let parentState = this.props.parentState;
    let conversationMap = parentState.conversationMap;
    let conversationMapLength = Object.keys(conversationMap).length;
    let isLoading = parentState.isConversationLoading;
    return isLoading && conversationMapLength == 0;
  }
  showPlaceholder() {
    let parentState = this.props.parentState;
    let conversationMap = parentState.conversationMap;
    let conversationMapLength = Object.keys(conversationMap).length;
    let isLoading = parentState.isConversationLoading;
    if(!isLoading && conversationMapLength == 0) {
      let peopleEntity = this.props.parentState.peopleEntity;
      let firstName = peopleEntity.firstName;
      let type = displayVisitorType[peopleEntity.type];
      return (
          <div class="no-interaction">
            <PlantIcon/>
            <strong>No interactions with {firstName ? firstName : type }</strong>
            <p>Click to have your first conversation with<br/>{firstName ? firstName : type}</p>
            <button onClick={this.props.createNewConversation} class="cs-btn cs-primary-btn ">Start Conversation</button>
          </div>
      )
    } 
    return "";
  }

  routeToPeopleTag(event) {
    browserHistory.push({
      pathname: `/app/${this.props.parentState.params.projectkey}/people/${event.target.dataset.tagkey}`,
    });
  }

  getLastMessage(conversation) {
    // fullName = fullName ? fullName.charAt(0).toUpperCase() + fullName.slice(1) : fullName;
    // if(conversation && conversation.feedbackStatus === FEEDBACK_STATUSES.FEEDBACK_STATUS_SUBMITTED)
    //   return `${fullName} has left a feedback`;
    if (conversation && conversation.lastMessage)
      return conversation.lastMessage;    
  }

  renderConversationList() {
    let parentState = this.props.parentState;
    let toRender = [];
    let conversationMap = parentState.conversationMap;
    let messageMap = parentState.messageMap;
    let peopleEntity = parentState.peopleEntity;
    let contact = peopleEntity || {};     
    console.log("conversationMap : ", conversationMap);
    for (let key in conversationMap) {      
      let conversationMessageMap = messageMap[key] ? messageMap[key] : {};
      let {staffName, photoUrl} = this.getStaffName(key);
        let lastMessage = "";
        for (let conversationKey in conversationMessageMap) {
          let message = conversationMessageMap[conversationKey];
          if (message.type == "CHAT") {
            lastMessage = stripHTML(message.message ? message.message : "");
            break;
          } else if (message.type == "VISITOR_INITIATED_CONVERSATION") {
            lastMessage = "Initiated conversation...";
            break;
          } else if (message.type == "ATTACHMENT") {
            lastMessage =
              message && message.message && message.message.fileName
                ? message.message.fileName
                : "";
            break;
          }
        }
    let conversation = conversationMap[key];    
    let fullName = "";
    let displayIcon;
    let lastMessageFromText = "";
    fullName = contact
      ? contact.firstName
        ? contact.firstName
        : contact.type
        ? displayVisitorType[contact.type]
        : "Visitor"
      : "Visitor";
    fullName += contact && contact.lastName ? " " + contact.lastName : "";
    if(!lastMessage)   
      lastMessage = this.getLastMessage(conversationMap[key], fullName);
    let assignedTo =
      conversation.lastMessageSenderId || conversation.assignedTo;
    if (conversation.messageFrom == "AGENT") {
      if (assignedTo ) {
        lastMessageFromText =  (staffName ? staffName : 'Agent')  + ": ";
      }
    } else if(conversation.messageFrom == "VISITOR") {
      lastMessageFromText = (fullName ? fullName : 'Visitor') + ": ";
    }

    if (photoUrl) {
      displayIcon = (
        <figure
          className="fs-exclude"
          style={contact ? { backgroundColor: contact.avatarColor } : {}}
        >
         { isValidURL(photoUrl) ? <img
            src={photoUrl}
            ref={(img) => (this.img = img)}
              onError={() =>
                (this.img.style.display = 'none')
              }
            data-class="tooltip info"
          /> : "" } 
        </figure>
      );
    } else {
      displayIcon = (
        <figure
          className="fs-exclude"
          style={contact ? { backgroundColor: contact.avatarColor } : {}}
        ></figure>
      );
    }  
      if (!parentState.expandedConversationIds.includes(conversation.key)) {
        toRender.push(
          <li
            key={key}
            data-conversation_id={conversation.key}
            onClick={this.props.expandConversation}
          >
          {displayIcon}
            <label>{staffName ? `Conversation with `+staffName : `${fullName} has initiated a chat`}</label>
            <p>{lastMessageFromText+" "+ (lastMessage ? lastMessage : "has initiated chat")}</p>
            <span>{getDisplayTime(conversation.modifiedDate).displayTime}</span>
          </li>  
        );
      }
    }
    return toRender;
  }

  getStaffName(key) {
    let parentState = this.props.parentState;
    let conversationMap = parentState.conversationMap;
    let staffMap = parentState.staffMap;
    let overflowStaffmap = parentState.overflowStaffmap || {};
    let photoUrl;
    let conversation = conversationMap[key];
    let assignedTo = conversation.assignedTo;
    let staffName;
    let staffInitials;
    if (!assignedTo) {
      staffName = "";
      staffInitials = "S";
    } else {
      let staff = staffMap[assignedTo] ; 
      staff = staff? staff : overflowStaffmap[assignedTo];
      if(staff)
        photoUrl = staff['photoUrl'];
      if (staff && staff.aliasName && staff.aliasName !== "null") {
        staffName = staff.aliasName ? staff.aliasName : "";
        staffName +=
          staff.lastName ? " " + staff.lastName.charAt(0) : "";
        staffInitials =
          staff.aliasName ? staff.aliasName.charAt(0) : "A";
        staffInitials +=
          staff.lastName ? staff.lastName.charAt(0) : "";
      } else {
        staffName = staff && staff.firstName ? staff.firstName : "";
        staffName +=
          staff && staff.lastName ? " " + staff.lastName.charAt(0) : "";
        staffInitials =
          staff && staff.firstName ? staff.firstName.charAt(0) : "A";
        staffInitials +=
          staff && staff.lastName ? staff.lastName.charAt(0) : "";
      }
    }      
    return { staffName, staffInitials, photoUrl};
  }

  renderConversationHeader(){
    let toRender = [];
    let parentState = this.props.parentState;
    let peopleEntity = parentState.peopleEntity || {};
    let firstName = peopleEntity.firstName ?  peopleEntity.firstName : "";
    let displayName = peopleEntity.type ? displayVisitorType[peopleEntity.type] : "Visitor";
    let conversationMap = parentState.conversationMap;
    for (let key in conversationMap) {
      let conversation = conversationMap[key];      
      let {staffName} = this.getStaffName(key);
      if (parentState.expandedConversationIds.includes(conversation.key)) {
        toRender.push( <h4><code onClick={() => { this.props.showListing() }} ></code>{staffName ? `Conversation with ${staffName}` : `${firstName||displayName} has initiated a conversation`}</h4>); 
      }
    }
    return toRender;
  }

  renderConversation() {
    let toRender = [];
    let parentState = this.props.parentState;
    let conversationMap = parentState.conversationMap;
    for (let key in conversationMap) {
      let conversation = conversationMap[key];      
      if (parentState.expandedConversationIds.includes(conversation.key)) {
        toRender.push( 
          <ActivChatView
            params={parentState.params}
            conversationId={conversation.key}
            shrinkConversation={this.props.shrinkConversation}
            isFromPeopleProfilePage={true}
          />
        ); 
      }
    }
    return toRender;
  }

  canShowWrapper() {
      let showWrapper = (this.props.parentState.isConversationLoading == true) || (Object.keys(this.props.parentState.conversationMap).length > 0 && this.props.parentState.expandedConversationIds.length == 0);
      console.info("showWrapper:"+showWrapper);   
      return showWrapper;
  }
  render() {
    return (
        <div>
          {this.showPlaceholder()}
          <div style={{'display' : (this.canShowWrapper() ? "block" : "none")}} class="conversation-history-wrp">                
            <code style={{'display' : (this.props.parentState.isConversationLoading == false && Object.keys(this.props.parentState.conversationMap).length == 0 ? "none" : "block")}}>All interactions</code>            
              {
              this.shouldRenderLoader() ? (
                <ul style={{'display' : (this.props.parentState.isConversationLoading == false && Object.keys(this.props.parentState.conversationMap).length == 0 ? "none" : "block")}} class="conversation-history-list">
                <li key={"loader"}>
                          <figure><em className="progress"></em></figure>
                          <label><em className="progress"></em></label>
                          <p><em className="progress"></em></p>       
                        </li> 
                        <li key={"loader2"}>
                        <figure><em className="progress"></em></figure>
                        <label><em className="progress"></em></label>
                        <p><em className="progress"></em></p>       
                </li>
                </ul>  
              ) : ""
            }
            {
              !this.shouldRenderLoader() && Object.keys(this.props.parentState.conversationMap).length != 0 ? 
              (<ul style={{'display' : (this.props.parentState.isConversationLoading == false && Object.keys(this.props.parentState.conversationMap).length == 0 ? "none" : "block")}} class="conversation-history-list">
                {this.renderConversationList()}
              </ul>) : ""
            }
          </div>
          <SeenSegments projectkey={this.props.parentState.params.projectkey} visitorId={this.props.parentState.params.visitorId} />
          <section className="history-conversation-wrp" style={{display:(this.props.parentState.expandedConversationIds.length > 0? "block" : "none")}}>
            {this.renderConversationHeader()}
            {this.renderConversation()}
          </section>
      </div>
    );
  }
}
export default Conversations;
