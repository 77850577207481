/* eslint-disable react/forbid-dom-props */
import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { Tabs } from 'src/features/ui/components/Tabs'
import { Text1 } from 'src/features/ui/components/Typography'
import { SomeoneElse } from 'src/features/wizard/install/components/ManualInstall/SomeoneElse'
import { FooterPadding } from 'src/features/wizard/install/components/PluginInstall/Section/styled'
import { IProps } from 'src/features/wizard/install/components/PluginInstall/Section/types'

export const Section: FC<IProps> = ({ id, myself, footer }) => (
  <div id={id}>
    <Text1>Who is installing your widget?</Text1>
    <Margin top={{ base: 16, sm: 24 }} />
    <Tabs
      options={[
        {
          label: 'Myself',
          children: (
            <>
              {myself}
              <FooterPadding>{footer}</FooterPadding>
            </>
          ),
        },
        {
          label: 'Someone else',
          children: (
            <>
              <SomeoneElse />
              {footer}
            </>
          ),
        },
      ]}
    />
  </div>
)
