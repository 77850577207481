import React, { Component } from "react";
import {
  EditorState,
  RichUtils,
  convertToRaw,
  getDefaultKeyBinding,
  ContentState,
  Modifier
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import { stateToHTML } from "draft-js-export-html";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import {
  getMentionSuggestions,
  isOverflowConnection,
  getUserPermissionUtility,
  getPlaceHolderText,
  isOverflowAbnormalDisConnection
} from "../../commons/Utility.js";

const Entry = (props) => {
  const {
    mention,
    ...parentProps
  } = props;
  let image = mention.get('avatar');
  let getImageElement = () => {
    if(image === 'no-image'){
      return <span></span>
    }
    return <img src={image} alt={mention.get("name")}></img>;
  }
  let imageElement = getImageElement();
  let backgroundColor = image === 'no-image' ? mention.get("color") : "#ffffff";
  return (
    <div {...parentProps} className="mention-list">
      {/* {`${mention.get("name")} -  ${mention.get("status")} -  ${mention.get("color")} `} */}
      <figure style={{backgroundColor: backgroundColor}}>
        {imageElement}
      </figure>
      <cite className={mention.get("status")}></cite>
      {mention.get("name")}
    </div>
  );
};

class ChatInput extends Component {
  constructor() {
    super();

    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handlePastedText = this.handlePastedText.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.mentionsOnOpen = this.mentionsOnOpen.bind(this);
    this.mentionsOnClose = this.mentionsOnClose.bind(this);
    this.getPlaceHolderText = this.getPlaceHolderText.bind(this);
    this.state = {
      isInitialRender: true,
      editorState: EditorState.createEmpty(),
      suggestions: [],
      mentions: [],
    };
    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: "@",
      positionSuggestions: (settings) => {
        return {
          left: settings.decoratorRect.left + "px",
          top: settings.decoratorRect.top - 40 + "px", // change this value (40) for manage the distance between cursor and bottom edge of popover
          display: this.state.suggestions.length > 0 ? "block" : "none",
          transform: "scale(1) translateY(-100%)", // transition popover on the value of its height
          transformOrigin: "1em 0% 0px",
          transition: "all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)",
          position: "fixed",
          minHeight: "auto",
          maxHeight: "200px",
          overflow: "auto",
        };
      },
    });
  }
  componentWillReceiveProps(nextProps) {
    console.log("this. props . " , this.props.staffMapData)
    if (
      this.props.clearText != nextProps.clearText &&
      nextProps.clearText == true
    )
      this.clearEditorText();
      let mentionedAgents = this.stripMentionedAgents(this.state.editorState);
      let mentions = this.getMentions(nextProps, mentionedAgents);
      this.setState({ mentions, suggestions: mentions.slice() });
    if (
      this.state.mentions.length == 0 &&
      Object.keys(nextProps.staffMap).length > 0
    ) {
      //let mentionedAgents = this.stripMentionedAgents(this.state.editorState);
      // let mentions = this.getMentions(nextProps, mentionedAgents);
      //this.setState({ mentions, suggestions: mentions.slice() });
    }

    if (this.props.conversationId !== nextProps.conversationId) {
      this.focusInput();
      //EditorState.moveFocusToEnd( this.state.editorState );
    }
  }
  componentWillUpdate(nextProps, nextState) {
    let nextMentionedAgents = this.stripMentionedAgents(nextState.editorState);
    let presentMentionedAgents = this.stripMentionedAgents(
      this.state.editorState
    );
    if (nextMentionedAgents.length != presentMentionedAgents.length) {
      let mentions = this.getMentions(nextProps, nextMentionedAgents);
      this.setState({ mentions, suggestions: mentions.slice() });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    this.props.handleDivSize();
  }
  componentDidMount() {
    this.focusInput();
  }
  // componentDidMount()
  // {
  //     //EditorState.moveFocusToEnd( this.state.editorState );
  // }
  focusInput() {
    let tryCount = 0;
    let focusTimeout = () => {
      setTimeout(() => {
        console.debug("Editor : ", this.refs.editor);
        if (this.refs.editor) this.refs.editor.focus();
        else if (tryCount < 4) {
          tryCount++;
          focusTimeout();
        }
      }, 500);
    };
    focusTimeout();
  }
  getMentions(props, mentionedAgents) {
    let mentionedAgentIds = [];
    for (var mentionedAgent of mentionedAgents) {
      for (var key in props.staffMapData) {
        let staff = props.staffMapData[key];
        if (
          staff["fullName"].trim() == mentionedAgent.trim() ||
          staff["firstName"].trim() == mentionedAgent.trim() ||
          staff["lastName"] && staff["lastName"].trim() == mentionedAgent.trim()
        )
          mentionedAgentIds.push(staff.key);
      }
    }
    return getMentionSuggestions(
      props.staffMapData,
      props.user,
      mentionedAgentIds,
      props.overflowedAgent
    );
  }
  onChange(editorState) {
    this.setState({ editorState });
    if (editorState.getLastChangeType() === "insert-characters") {
      this.props.onMessageKeyUp(editorState.getCurrentContent().getPlainText());
    } 
    if (
      editorState &&
      editorState.getCurrentContent().getPlainText().trim().length > 0 &&
      !this.props.showBlueSendIcon
    )
      this.props.toggleSendIconColor(true);
    else if (
      editorState &&
      editorState.getCurrentContent().getPlainText().trim().length == 0 &&
      this.props.showBlueSendIcon
    )
      this.props.toggleSendIconColor(false);
  }

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, this.state.mentions),
    });
  };

  handleKeyDown(e) {
    let editorState = this.state.editorState;

    this.props.onMessageKeyDown(
      e,
      editorState.getCurrentContent().getPlainText()
    );
    return getDefaultKeyBinding(e);
  }

  handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );

    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  }

  handleReturn(e, editorState) {
    if (e && e.shiftKey || (this.props.isEmailConversation && !this.props.isNotesActive)) {  
      return "not-handled";
    }
    this.handleKeyDown(e);
    console.log(
      "Message Length : ",
      editorState.getCurrentContent().getPlainText().trim().length
    );

    if (!editorState.getCurrentContent().getPlainText().trim().length)
      return "handled";

    if (
      this.isLastWordAMention(editorState.getCurrentContent().getPlainText()) &&
      this.state.isSuggestionShowing
    )
      return "not-handled";

    let htmlState = stateToHTML(editorState.getCurrentContent());
    let anchorRegex = /<a[\s]+([^>]+)>((?:.(?!\<\/a\>))*.)/g;
    if (anchorRegex.test(htmlState)) {
      htmlState = htmlState.replace(/<a[^>]*>|<\/a>/g, "");
    }
    let mentionedAgents = [];
    if (this.props.isNotesActive)
      mentionedAgents = this.stripMentionedAgents(editorState);

    console.log("htmlState : ", htmlState);
    this.props.processAndSend(null, htmlState, false, mentionedAgents);

    this.clearEditorText();

    return "handled";
  }

  isLastWordAMention(plainText) {
    let words = plainText.split(" ");
    return words[words.length - 1].startsWith("@");
  }

  stripMentionedAgents(editorState) {
    let rawContent = JSON.parse(
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
    let entityMap = rawContent["entityMap"];
    let mentionedAgents = [];
    let entity;

    for (let key in entityMap) {
      if (entityMap.hasOwnProperty(key)) {
        let entity = entityMap[key];
        if (entity["type"] == "mention")
          mentionedAgents.push(entity.data.mention["name"]);
      }
    }
    return mentionedAgents;
  }

  handlePastedText(text, html, editorState) {
    const blockMap = ContentState.createFromText(text.trim()).blockMap;
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    );
    this.onChange(EditorState.push(editorState, newState, "insert-characters"));
    EditorState.moveFocusToEnd(editorState);
    return "handled";
  }

  clearEditorText() {
    const newState = EditorState.createEmpty();
    this.setState({ editorState: EditorState.moveFocusToEnd(newState) });
    this.props.toggleSendIconColor(false);
  }

  getMentionSuggestions() {
    const { MentionSuggestions } = this.mentionPlugin;
    let staffMapLength = Object.keys(this.props.staffMap).length;

    if (this.props.isNotesActive && staffMapLength > 1)
      return (
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
          onAddMention={this.onAddMention}
          onOpen={this.mentionsOnOpen}
          onClose={this.mentionsOnClose}
          entryComponent={Entry}
        />
      );

    return <span></span>;
  }

  mentionsOnOpen() {
    this.setState({ ...this.state, isSuggestionShowing: true });
  }
  mentionsOnClose() {
    this.setState({ ...this.state, isSuggestionShowing: false });
  }

  isReadOnly() {
    if (isOverflowAbnormalDisConnection() && !this.props.isNotesActive) 
      return true;
    if (isOverflowConnection()) return false;
    else if (
      this.props.isConnectedToNetwork &&
      this.props.isSubscribedToChannels
    )
      return false;
    else if (
      !navigator.onLine &&
      (!this.props.isConnectedToNetwork || !this.props.isSubscribedToChannels)
    )
      return true;
    else if (navigator.onLine) return false;
  }

  getPlaceHolderText() {
      return getPlaceHolderText(this.props.isEmailConversation, this.props.isNotesActive, 
                                    this.props.chatStatus, this.props.isReopened, 
                                          this.props.isConnectedToNetwork, this.props.visitorEmailId);
  }
  render() {
    const plugins = [this.mentionPlugin];
    let userCanChat = getUserPermissionUtility("CanSendMessage");

    return (
      <div
        className={
          "cs-typing-area " +
          (this.props.isNotesActive ? "notes-typing-area" : "" + " fs-exclude")
        }
      >
        <Editor
          spellCheck={true}
          readOnly={this.isReadOnly() || !userCanChat}
          placeholder={this.getPlaceHolderText()}
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          handleKeyCommand={this.handleKeyCommand}
          handleReturn={this.handleReturn}
          keyBindingFn={this.handleKeyDown}
          handlePastedText={this.handlePastedText}
          ref="editor"
        />
        {this.getMentionSuggestions()}
      </div>
    );
  }
}

export default ChatInput;
