import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Icon } from 'src/features/ui/components/Icon'
import { Text1 } from 'src/features/ui/components/Typography'

export const Container = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  border-radius: 0.9rem;
  border: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  box-sizing: border-box;
  box-shadow: 0 0.7rem 0.8rem rgba(22, 45, 61, 0.08);
  padding-top: 4.2rem;
  padding-bottom: 3.6rem;
  background-color: transparent;

  ${is('isActive')`
    border-color: ${R.path(['theme', 'color', 'dodgerBlue'])};
  `}
`

export const LabelIcon = styled(Icon)``

export const Label = styled(Text1)`
  margin-top: 2.2rem;
  font-weight: 700;
  color: ${R.path(['theme', 'color', 'dodgerBlue'])};
`
