import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActiveChatViewContainer from "../../containers/ActiveChatViewContainer";
import { getUser } from "../../actions/UserAction";
import wrapStore from "../WrapStore";
import { init } from "../../actions/MessageAction";
import ChatDashboardHeader from "../../components/chat/ChatDashboardHeader";
import VisitorDetails from "../../containers/VisitorDetailsContainer";
import { getConversationComponentParamsPayload } from "../AwRecentsUtils";

const ConversationComponent = (props) => {
  const { userReducer, conversationReducer } = useSelector((state) => ({
    userReducer: state.UserReducer,
    conversationReducer: state.ConversationInfoReducer,
  }));

  const params = getConversationComponentParamsPayload(
    conversationReducer,
    props
  );
  const empty = {};
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("fetching Users alone");
    if (!userReducer.data && !userReducer.isFetching) {
      dispatch(getUser());
    }
    dispatch(init());
  }, []);

  const getContactHeader = () => {
    let contactMap =
      conversationReducer.conversationObject &&
      conversationReducer.conversationObject.contactsMap;
    let contact = contactMap["fa3c1242-a398-480d-b7aa-335f141fb930"];
    return contact ? (
      <section style={{ left: "0px" }} className="cs-conversation">
        <ChatDashboardHeader location={{}} contact={contact} />{" "}
      </section>
    ) : (
      <span />
    );
  };

  const getVisitorDetails = () => {
    let contactMap =
      conversationReducer.conversationObject &&
      conversationReducer.conversationObject.contactsMap;
    let contact = contactMap["fa3c1242-a398-480d-b7aa-335f141fb930"];
    if (contact) {
      return (
        <VisitorDetails
          classNames={[]}
          params={params}
          visitorId={contact.key}
        />
      );
    }
  };

  return (
      <div id="error-bound" style={{ position: "relative", width: "100%" }}>
        {getContactHeader()}
        <ActiveChatViewContainer
          isFromChatDashboard={true}
          params={params}
          pathQueryString={""}
          query={empty}
        />
        {getVisitorDetails()}
      </div>
  );
};

export default wrapStore(ConversationComponent);
