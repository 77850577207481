import React from "react";
import * as Constants from "../commons/Constants.js";

const Pricing = (props) => {
  const { toggleUpgradePopup, redirectToPaymentPage } = props;
  return (
    <div className="pricing-popup">
      <div className='cs-popupbg'></div>
      <svg
        onClick={toggleUpgradePopup}
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.52"
          d="M22.5957 22.5391C22.1855 22.9492 21.4336 22.9492 21.0234 22.5391L12 13.4473L2.9082 22.5391C2.49805 22.9492 1.74609 22.9492 1.33594 22.5391C0.925781 22.1289 0.925781 21.377 1.33594 20.9668L10.4277 11.875L1.33594 2.85156C0.925781 2.44141 0.925781 1.68945 1.33594 1.2793C1.74609 0.869141 2.49805 0.869141 2.9082 1.2793L12 10.3711L21.0234 1.2793C21.4336 0.869141 22.1855 0.869141 22.5957 1.2793C23.0059 1.68945 23.0059 2.44141 22.5957 2.85156L13.5039 11.875L22.5957 20.9668C23.0059 21.377 23.0059 22.1289 22.5957 22.5391Z"
          fill="white"
        />
      </svg>
      <strong>Upgrade Plan.</strong>
      <p>
        Let a team of live chat agents answer your chats and take <br /> your
        customer support 24/7.
      </p>
      <div className="wrapper">
        <div className="pricing-box">
          <div>
            <h3>
              STARTUP<span className="blue-dot">.</span>
            </h3>
            <p>25 Chats Included ($1.25 per chat)</p>
            <div className="cost">
              <span>$</span>
              <strong>19</strong>
              <small>/ mo.</small>
            </div>
            <ul>
              <li>25 chats</li>
              <li>$1.25 per chat</li>
              <li>Proactive chat</li>
              <li>Multi-chat handling</li>
              <li>Send outbound campaigns</li>
            </ul>
            <button onClick={() => {redirectToPaymentPage(Constants.STARTUP_PLAN_ID);}}>Upgrade</button>
          </div>
        </div>
        <div className="pricing-box">
          <div>
            <h3>
              SUPPORT<span className="blue-dot">.</span>
            </h3>
            <p>50 Chats Included ($1.10 per chat)</p>
            <div className="cost">
              <span>$</span>
              <strong>49</strong>
              <small>/ mo.</small>
            </div>
            <ul>
              <li>50 chats</li>
              <li>$1.10 per chat</li>
              <li>Proactive chat</li>
              <li>Multi-chat handling</li>
              <li>Send outbound campaigns</li>
            </ul>
            <button onClick={() => {redirectToPaymentPage(Constants.SUPPORT_PLAN_ID);}}>Upgrade</button>
          </div>
        </div>
        <div className="pricing-box">
          <span>Best Value</span>
          <div>
            <h3>
              PRO<span className="blue-dot">.</span>
            </h3>
            <p>100 Chats Included ($1 per chat)</p>
            <div className="cost">
              <span>$</span>
              <strong>59</strong>
              <small>/ mo.</small>
            </div>
            <ul>
              <li>100 chats</li>
              <li>$1 per chat</li>
              <li>Proactive chat</li>
              <li>Multi-chat handling</li>
              <li>Send outbound campaigns</li>
            </ul>
            <button onClick={() => {redirectToPaymentPage(Constants.PRO_PLAN_ID);}}>Upgrade</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
