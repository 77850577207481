import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { config } from 'src/config'
import {
  selectUserId,
  selectWidgetId,
} from 'src/features/authorization/store/selectors'
import { Link2, Text1, Title } from 'src/features/ui/components/Typography'
import { WidgetInstall } from 'src/features/wizard/install/components/WidgetInstall'
import { FooterButtons } from 'src/features/wizard/install/components/WidgetInstall/styled'
import { IProps } from 'src/features/wizard/install/components/WidgetInstallFull/types'
import {
  selectIsTestingConnectionOk,
  selectWasConnectionTested,
} from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const WidgetInstallFullComponent: FC<IProps> = ({
  widgetId,
  userId,
  wasConnectionTested,
  isTestingConnectionOk,
}) => {
  const handleGoToConversations = () => {
    window.location.href = `${config.chatsupportAppUrl}/app/${widgetId}/chat/guests/D/O/N/${userId}`
  }

  return (
    <>
      <Title>Install your widget</Title>
      <Margin top={{ base: 16, sm: 40 }} />
      <Text1>Choose how you want to install your widget</Text1>
      <Margin top={{ base: 24, md: 0 }} />
      <WidgetInstall
        hasFixedHeader
        continueButtonProps={{
          label: isTestingConnectionOk
            ? 'Hurray! Go to dashboard'
            : 'Install later',
          handleOnClick: handleGoToConversations,
        }}
        hasBottomBorder
      />
      {!wasConnectionTested && (
        <FooterButtons>
          <Link2
            href={`/app/${widgetId}/chat/guests/D/O/N/${userId}`}
            color="raven"
            underline
          >
            Not now, I’ll do it later.
          </Link2>
        </FooterButtons>
      )}
    </>
  )
}

const mapStateToProps = (state: IAppState) => ({
  widgetId: selectWidgetId(state.auth)!,
  userId: selectUserId(state.auth)!,
  wasConnectionTested: selectWasConnectionTested(state.install)!,
  isTestingConnectionOk: selectIsTestingConnectionOk(state.install)!,
})

export const WidgetInstallFull = connect(mapStateToProps)(
  WidgetInstallFullComponent
)
