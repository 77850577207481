import React, {useState} from "react";
import {getFormattedDate, getDisplayDate, isValidString} from '../../commons/Utility.js';
import {
    CircleChatIcon,
    CircleBrowseIcon
  } from "../../commons/Icons.js";

const Activity = (props) => {
    const {eventActivities={}, projectEvents={}, visitorId, staff, conversations, projectId} = props;
    const [isShown, setIsShown] = useState(false);
    const getStaffName = (conversationId) => {
        let staffName = "";
        if(staff && conversations) {
            let conversation = conversations.conversationObject.conversationMap[conversationId];
            if(conversation) {
                let {assignedTo} = conversation;
                if(assignedTo && staff && staff['dashboardAgents'] && staff['overflowAgents']) {
                    let dashboardStaffs = staff['dashboardAgents'][projectId] || {};
                    let overflowAgents = staff['overflowAgents'] || {};
                    let staffInfo = dashboardStaffs[assignedTo] || overflowAgents[assignedTo];
                    if(staffInfo)
                        staffName = isValidString(staffInfo.aliasName) || staffInfo.firstName;
                } 
            }
        }
        return staffName || "";
    }
    const showToolTip = (event) => {
        if (event.target.scrollWidth > event.target.offsetWidth)
            setIsShown(true);
    };
    const seggregateActivityByDate = () => {
        let eventsByDate = {};
        let {eventActivityMap} = eventActivities;
        if(!eventActivityMap || !visitorId) return {};
        eventActivityMap = Object.assign({},eventActivityMap[visitorId]);
        Object.keys(eventActivityMap).forEach(eventActivityKey => {
            let eventActivityInfo = eventActivityMap[eventActivityKey];
            let formattedDate = getDisplayDate(eventActivityInfo.createdDate);
            if(eventsByDate[formattedDate])
                eventsByDate[formattedDate] = [ ...eventsByDate[formattedDate], eventActivityInfo ];
            else 
                eventsByDate[formattedDate] = [ eventActivityInfo ];
        });
        return eventsByDate;
    }
    const loadWebpage = (webpage) => {
        window.open(webpage, '_blank');
    }
    const getActivities = (eventActivityList) => {
        let {projectEventMap} = projectEvents;
        if(!projectEventMap || Object.keys(projectEventMap).length == 0 )
            return;            
        let activities = [];
        for (var index=0; index<eventActivityList.length; index++) {
            let eventActivity = eventActivityList[index];
            if (projectEventMap.hasOwnProperty(eventActivity.eventId)) {
              let eventName = projectEventMap[eventActivity.eventId].eventName;
              if (eventName == "pageVisit") {
                activities.push(
                    <p className="fs-exclude">
                    <CircleBrowseIcon />Visited webpage
                    <span 
                        style={{"cursor" : "pointer"}} 
                        onClick={() => {loadWebpage(eventActivity.description)}}
                        onMouseEnter={showToolTip}
                        onMouseLeave={() => setIsShown(false)}
                        data-class="tooltip info"
                        data-tip={isShown ? eventActivity.description : ""}
                        >{eventActivity.description}</span>
                    <small>{getFormattedDate(new Date(eventActivity.createdDate), "hh:mm A")}</small>
                    </p>                  
                );
              } else if(eventName == "Chat_Initiated") {
                let customProperty = eventActivity.customProperty ?  JSON.parse(eventActivity.customProperty) : undefined;
                let conversationId = customProperty ? customProperty['conversationId'] : "";
                let staffName = getStaffName(conversationId);
                activities.push(
                    <p className="fs-exclude">
                    <CircleChatIcon/>
                    {
                        staffName 
                            ? (`Initiated conversation with`)
                            : (`Initiated conversation`)
                    }
                    {
                        staffName 
                        ? <b>{'@'+staffName}</b>
                        : ''
                    }
                    <small>{getFormattedDate(new Date(eventActivity.createdDate), "hh:mm A")}</small>
                    </p>    
                )
              }
            }
        } 
        return activities;   
    }
    let eventActivityByDate = seggregateActivityByDate();
    return (
        <div className="activity_list">
        {
            Object.keys(eventActivityByDate).map( eventActivityDate => {
                let eventActivitiesForDate = eventActivityByDate[eventActivityDate];
                    return (
                        <div>       
                            <label>{eventActivityDate}</label>
                             {getActivities(eventActivitiesForDate)}
                        </div>
                    )
            })
        }
        </div>
    )
}

export default Activity;