import { Fragment } from "react";
import React from "react";
import ReactDOM from "react-dom";
import { IndexRedirect,Route , Router , browserHistory } from "react-router";
import YourWidgetContainer from "./settings/yourwidget/YourWidgetsContainer";
import WidgetSettingsLayout from "./settings/layouts/WidgetSettingsLayout";

import CustomiseWidget from "./settings/customisewidget/CustomiseWidget.jsx";
import WidgetBehaviourContainer from "./settings/widgetbehaviour/WidgetBehaviourContainer";
import ChatRoutingComp from "./settings/chatRouting/ChatRoutingComp.jsx";
import BusinessHoursContainer from "./settings/businessHours/BusinessHoursContainer";
import PreviewUrlGeneratorComponent from "./settings/previewUrl/PreviewUrlGeneratorComponent.jsx";
import WidgetConfiguration from "./settings/widgetconfigurationsettings/WidgetConfiguration.jsx";
import { Component } from "react";
import IntegrationContainer from "./settings/customisewidget/IntegrationContainer";
const routes = (parentPath) => {
  return (<Fragment>
    <Route path={parentPath} component={YourWidgetContainer}/>
      <Route path="app/:projectkey/settings/manage/widget" component={YourWidgetContainer}></Route>
      <Route path="app/:projectkey/settings/widget" component={WidgetSettingsLayout}>
        <IndexRedirect to="configuration" />
        <Route path="configuration" component={WidgetConfiguration} />
        <Route path="behaviour" component={WidgetBehaviourContainer}>
        <IndexRedirect to="prompts" />
        <Route path="prompts" component={WidgetBehaviourContainer} />
        <Route path="visibility" component={WidgetBehaviourContainer} />
        <Route path="surveyform" component={WidgetBehaviourContainer} />
        <Route
          path="disconnecttimer"
          component={WidgetBehaviourContainer}
        />
      </Route>
        <Route path="newwidget" component={WidgetConfiguration} />
        <Route path="customize" component={CustomiseWidget} />
        <Route path="integration" component={IntegrationContainer} />
        <Route path="assignment" component={ChatRoutingComp} />
        <Route path="businesshours" component={BusinessHoursContainer} />
        <Route path="demo" component={PreviewUrlGeneratorComponent} />
      </Route>
     
  </Fragment>)
}
  
  class SettingsWrapper extends Component {
    constructor(props){
      super(props);
      console.log("this . props in setting wrap" , this.props);
    }
    render() {
      const routesComponent = routes(this.props.parentRoute)
      return (
        <Router history={browserHistory} routes={routesComponent}></Router>
      )
    }
  }

  export default SettingsWrapper;