import React, { FC } from 'react'

export const Smiley: FC<any> = (props) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6193 6.33428C7.29157 7.38956 6.32805 8.09745 5.22034 8.09745C4.11262 8.09745 3.1491 7.38956 2.82137 6.33428C2.74927 6.11142 2.8738 5.88201 3.09666 5.80991C3.31296 5.73781 3.54892 5.86235 3.62102 6.08521C3.83732 6.78654 4.48623 7.25847 5.22034 7.25847C5.95445 7.25847 6.60335 6.78654 6.81965 6.08521C6.89175 5.86235 7.12771 5.73781 7.35057 5.80991C7.56687 5.88201 7.6914 6.11142 7.6193 6.33428ZM4.38136 3.48305C4.38136 3.94842 4.00775 4.32203 3.54237 4.32203C3.077 4.32203 2.70339 3.94842 2.70339 3.48305C2.70339 3.01768 3.077 2.64407 3.54237 2.64407C4.00775 2.64407 4.38136 3.01768 4.38136 3.48305ZM7.73729 3.48305C7.73729 3.94842 7.36368 4.32203 6.8983 4.32203C6.43293 4.32203 6.05932 3.94842 6.05932 3.48305C6.05932 3.01768 6.43293 2.64407 6.8983 2.64407C7.36368 2.64407 7.73729 3.01768 7.73729 3.48305ZM9.41525 5.16102C9.41525 2.84726 7.5341 0.966102 5.22034 0.966102C2.90658 0.966102 1.02542 2.84726 1.02542 5.16102C1.02542 7.47478 2.90658 9.35593 5.22034 9.35593C7.5341 9.35593 9.41525 7.47478 9.41525 5.16102ZM10.2543 5.16097C10.2543 7.94011 7.99949 10.1949 5.22036 10.1949C2.44123 10.1949 0.186462 7.94011 0.186462 5.16097C0.186462 2.38184 2.44123 0.127075 5.22036 0.127075C7.99949 0.127075 10.2543 2.38184 10.2543 5.16097Z"
      fill="#50555C"
    />
  </svg>
)
