import {
  GET_INVITATION_REQUEST,
  GET_INVITATION_RESPONSE,
  GET_INVITATION_FAILED,
  ACCEPT_INVITATION_REQUEST,
  ACCEPT_INVITATION_RESPONSE,
  ACCEPT_INVITATION_FAILED,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_RESPONSE,
  SEND_INVITATION_FAILED,
  DECLINE_INVITATION_REQUEST,
  DECLINE_INVITATION_RESPONSE,
  DECLINE_INVITATION_FAILED,
} from "../actions/InvitationAction";

import {
  GET_SIGNUP_DETAILS_REQUEST,
  GET_SIGNUP_DETAILS_RESPONSE,
  GET_SIGNUP_DETAILS_FAILED,
} from "../actions/InvitationAction";

const InvitationReducer = (
  state = {
    invitation: {},
    isFetching: false,
    isFetched: false,
    isInviting: false,
  },
  action
) => {
  switch (action.type) {
    case GET_INVITATION_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    case GET_INVITATION_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        invitation: action.invitation,
      };
    case GET_INVITATION_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
      };
    case GET_SIGNUP_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    case GET_SIGNUP_DETAILS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        invitation: action.invitation,
      };
    case GET_SIGNUP_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
      };
    case ACCEPT_INVITATION_REQUEST:
      return {
        ...state,
      };
    case ACCEPT_INVITATION_RESPONSE:
      return {
        ...state,
      };
    case ACCEPT_INVITATION_FAILED:
      return {
        ...state,
      };
    case DECLINE_INVITATION_REQUEST:
      return {
        ...state,
      };
    case DECLINE_INVITATION_RESPONSE:
      return {
        ...state,
      };
    case DECLINE_INVITATION_FAILED:
      return {
        ...state,
      };
    case SEND_INVITATION_REQUEST:
      return {
        ...state,
        isInviting: true,
      };
    case SEND_INVITATION_RESPONSE:
      return {
        ...state,
        isInviting: false,
      };
    case SEND_INVITATION_FAILED:
      return {
        ...state,
        isInviting: false,
      };
  }
  return state;
};

export default InvitationReducer;
