import { call, put, fork } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as CustomFieldsActions from "../actions/CustomFieldsAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { getSortedCustomFields, getQueryParam } from "../commons/Utility";
import { store } from "../app.js";
import { updateFieldSettingsRequest } from "../actions/PeopleAction";

export function* doFetchCustomFields(customFieldsObject) {
  let url = "";
  console.log("customFieldsObject: ", customFieldsObject);
  try {
    let response;
    if (customFieldsObject.isFromOverflow) {
      delete customFieldsObject.isFromOverflow;
      url =
        "/contact/overflow/customFields?connectionId=" +
        getQueryParam("connection_id");
      response = yield call(Ajax.postUnauthenticated, url, customFieldsObject);
    } else if (customFieldsObject.isAwWindow) {
      delete customFieldsObject.isAwWindow;
      url = "/contact/overflow/customFields";
      response = yield call(Ajax.postUnauthenticated, url, customFieldsObject);
    } else {
      delete customFieldsObject.isFromOverflow;
      url = "/contact/customFields";
      response = yield call(Ajax.post, url, customFieldsObject);
    }

    if (response.response == true) {
      let customFieldsMap = {};
      response = getSortedCustomFields(response.data);
      response.map((item, i) => {
        customFieldsMap[item.customPropId] = item;
      });
      yield put(VoiceboxActions.hideVoicebox());
      yield put(CustomFieldsActions.customFieldsResponse(customFieldsMap));
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to fetch!",
          dismissAfter: 2000,
          showError: true,
        })
      );
      yield put(CustomFieldsActions.customFieldsFailed());
    }
  } catch (e) {
    console.error(e);
    console.log(e.name);
    // yield put( VoiceboxActions.showVoicebox( { message : 'Failed to fetch!', dismissAfter : 2000 , showError : true } )  )
    yield put(CustomFieldsActions.customFieldsFailed());
    // yield put( handleGlobalError( e ) );
  }
}

export function* doUpdateCustomField(requestData) {
  const url = "/contact/customFields/update";

  try {
    let response = yield call(Ajax.put, url, requestData.customField);
    console.log("response", response);
    if (response.response == true) {
      let customField = response.data;
      yield put(VoiceboxActions.hideVoicebox());
      yield put(CustomFieldsActions.updateCustomFieldResponse(customField));
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to update!",
          dismissAfter: 2000,
          showError: true,
        })
      );
      yield put(CustomFieldsActions.updateCustomFieldFailed());
    }
  } catch (e) {
    console.error(e);
    if (
      e.response &&
      e.response.data &&
      e.response.data.Exception == "EXISTING_PARAM"
    )
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Existing Field!",
          dismissAfter: 2000,
          showError: true,
        })
      );
    else
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to update!",
          dismissAfter: 2000,
          showError: true,
        })
      );
    yield put(CustomFieldsActions.updateCustomFieldFailed());
    //yield put( handleGlobalError( e ) );
  }
}
export function* doDeleteCustomField(requestData) {
  let { projectId, key, customPropId } = requestData.customField;
  const url = "/contact/customFields/delete/" + projectId + "/" + key;

  try {
    let response = yield call(Ajax.deleteReq, url);
    if (response.response == true) {
      yield put(VoiceboxActions.hideVoicebox());
      yield put(
        CustomFieldsActions.deleteCustomFieldResponse(
          requestData.customField.customPropId
        )
      );
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to delete!",
          dismissAfter: 2000,
          showError: true,
        })
      );
      yield put(CustomFieldsActions.deleteCustomFieldFailed());
    }
  } catch (e) {
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Failed to delete!",
        dismissAfter: 2000,
        showError: true,
      })
    );
    yield put(CustomFieldsActions.deleteCustomFieldFailed());
    //yield put( handleGlobalError( e ) );
  }
}
