import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Text1, Text4 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Options = styled.div`
  display: flex;
  flex-direction: row;

  ${mq.sm} {
    flex-direction: row;
  }
`

export const OptionContainer = styled.label<{ checked: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: ${R.path(['theme', 'color', 'raven'])};
  position: relative;
  padding: 0.6rem;
  width: calc(50% - 0.8rem);
  max-width: 16.8rem;
  height: 8rem;
  border-radius: 0.9rem;
  border: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  box-sizing: border-box;
  box-shadow: 0 0.7rem 0.8rem rgba(22, 45, 61, 0.08);

  ${is('checked')`
    border-color: ${R.path(['theme', 'color', 'dodgerBlue'])};
  `}

  ${mq.sm} {
    width: 18.8rem;
    max-width: unset;
  }

  & + & {
    margin-left: 1.6rem;
    margin-top: unset;

    ${mq.sm} {
      margin-left: 3.2rem;
    }
  }
`

export const Circle = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: ${R.path(['theme', 'color', 'mystic'])};
`

export const Square = styled.div`
  width: 5rem;
  height: 1.6rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background-color: ${R.path(['theme', 'color', 'mystic'])};
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const OptionLabel = styled(Text4)`
  color: ${R.path(['theme', 'color', 'raven'])};
`

export const Label = styled(Text1).attrs({ as: 'label' })`
  margin-bottom: 1.6rem;
`

export const Input = styled.input`
  padding: 0.4rem 0;
  border: none;
  border-bottom: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
  color: ${R.path(['theme', 'color', 'codGray'])};
  font-size: 1.6rem;
  line-height: 1.9rem;
  border-radius: 0;
  opacity: 0;
  position: absolute;
`
