import React, { useEffect } from "react";
import { browserHistory } from "react-router";
import { campaignTypes, CAMPAIGN_TYPE_PLACEHOLDERS_IMAGES, defaultCampaignData } from "../../commons/Constants";
import { useSelector, useDispatch } from "react-redux";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import { constructNewCampaign, getProjectId, getProjectNameByKey } from "../../commons/Utility";
import { addStyleToAdminContainer, removeStyleFromAdminContainer } from "../../actions/AdminAction";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";

const DefaultNewCampaignSection = (props)=>{
  const { type, projectkey } = props.params;
  const { campaignDetails, projects, user={} } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    projects: state.ProjectReducer,
    user: state.UserReducer
  }));
  const { campaignType } = campaignDetails || {};
  const dispatch = useDispatch();

  const updateCampaignType = (campaign_type) => {
    dispatch(campaignActions.updatePreviewType("Mobile"));
    dispatch(campaignActions.updateCampaignType(campaignTypes[campaign_type]));
  };

  useEffect(() => {
    dispatch(campaignActions.updateCampaignType(campaignTypes[type] ? campaignTypes[type] : "CHAT"));
  }, [type]);
  useEffect(()=>{
    if(user.isFetched)
    {
      let isAdminUser = user && user.data && user.data.isAdmin === "true" ? true : false;
      if(isAdminUser)
        dispatch(addStyleToAdminContainer("dn"));
    }
  },[user.isFetched])
  useEffect(() => {
      if(window._ls)
        _ls("showCsWidget", false);
    return () => {
      doOnUnmountActions();
    };
  }, []);
  const doOnUnmountActions = ()=>{
    let isAdminUser = user && user.data && user.data.isAdmin === "true" ? true : false;

    if(isAdminUser)
      dispatch(removeStyleFromAdminContainer("dn"));
    else if(window._ls)
      _ls("showCsWidget", true);
  }
  
  const trackNewCampaignCreationToGA = ()=>{
    Event(
      getProjectId(props.params.projectkey),
    "Campaigns",
    "NewCampaignClicked",
    "New campaign"
    )
    let action = campaignDetails && campaignDetails.campaignType ? campaignDetails.campaignType.toLowerCase() : "";
    action = action ? action[0].toUpperCase()+action.substring(1, action.length): ""
    Event(
      getProjectId(props.params.projectkey),
    "Campaigns",
    "CampaignType"+action,
    campaignDetails && campaignDetails.campaignType ? campaignDetails.campaignType.toLowerCase() : ""
    )
  }
  const createNewCampaign = () => {
    let typeOfMsg = ( type && type != "all" ) ? type : ( campaignDetails ? campaignDetails.campaignType: "" ).toLowerCase();
    const newCampaignData = constructNewCampaign(projectkey, projects, typeOfMsg, campaignDetails)
    trackNewCampaignCreationToGA();
    dispatch(campaignActions.saveCampaign(newCampaignData, true));
  };

    return (<section class="campaign-placeholder">
          <div>
            <h3>Create your campaign</h3>
            <p>Choose the type of campaign you would like to send</p>
            <div 
            id="chat_box"
            onClick={() => updateCampaignType("chat")}
            className={
              "cs-radio-form " + (campaignType == "CHAT" ? "active" : "")
            }>
              <label>Chat</label>
              <p class="min-screen">Trigger a chat message that will appear on the<br/>Chat Widget</p>
              <p class="max-screen">Trigger a chat message that will appear on the Chat Widget</p>
            </div>
            <div 
              id="announcement_box"
              onClick={() => updateCampaignType("announcement")}
              className={
                "cs-radio-form " +
                (campaignType == "ANNOUNCEMENT" ? "active" : "")
              }>
              <label>Announcements</label>
              <p>Launch a pop-up message that appears when the recipient is on a specific page</p>
            </div>
            <div 
              id="email_box"
              onClick={() => updateCampaignType("email")}
              data-campaign_type="email"
              className={
                "cs-radio-form " + (campaignType == "EMAIL" ? "active" : "")
              }>
              <label>Emails</label>
              <p>Send automated emails that will be delivered to your contact's inbox</p>
            </div>
          </div>
          <img src={CAMPAIGN_TYPE_PLACEHOLDERS_IMAGES[campaignType]} alt=""/>
          <footer>
            {/* <a href="javascript:void(0)" class="cs-btn cs-decline-btn">Cancel</a> */}
            <a class="cs-btn cs-primary-btn" id="createNewCampaignButton" onClick={createNewCampaign}>Next</a>
          </footer>
        </section>)
}
export default DefaultNewCampaignSection;