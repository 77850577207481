import styled from 'styled-components'
import { Divider } from 'src/features/ui/components/Divider'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div``

export const Content = styled.div`
  max-width: unset;

  ${mq.sm} {
    max-width: 36.8rem;
  }
`

export const DividerMobile = styled(Divider)`
  display: flex;

  ${mq.sm} {
    display: none;
  }
`

export const DividerDesktop = styled(Divider)`
  display: none;

  ${mq.sm} {
    display: flex;
  }
`
