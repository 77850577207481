import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { mq } from 'src/features/ui/styles/mq'

interface IProps {
  primary?: boolean
  hasFullWidth?: boolean
  hasFullWidthMobile?: boolean
  disabled?: boolean
  hollow?: boolean
  hasSmallerPadding?: boolean
  width?: number
  maxWidth?: number
}

export const Container = styled.button<IProps>`
  border-radius: 0.3rem;
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  color: ${R.path(['theme', 'color', 'raven'])};
  border: none;
  margin: 0;
  padding: 1.2rem 2rem 1.3rem;
  width: auto;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  ${is('primary')`
    color: ${R.path(['theme', 'color', 'white'])};
    background: ${R.path(['theme', 'color', 'dodgerBlue'])};
    font-size: 1.4rem;
  `}

  ${is('primary', 'hollow')`
    background: ${R.path(['theme', 'color', 'white'])};
    color: ${R.path(['theme', 'color', 'dodgerBlue'])};
    border: 0.1rem solid ${R.path(['theme', 'color', 'dodgerBlue'])};
    font-size: 1.4rem;
  `}

  ${is('disabled')`
    opacity: 0.7;
    cursor: not-allowed;
  `}

  ${is('hasFullWidthMobile')`
    width: 100%;

    ${mq.sm} {
      width: auto;
    }
  `}

  ${is('hasFullWidth')`
    width: 100%;
  `}

  ${is('hasSmallerPadding')`
    padding: 0.3rem 1.1rem;
  `}

  ${is('width')`
    width: ${R.prop('width')}rem;
  `}

  ${is('maxWidth')`
    max-width: ${R.prop('maxWidth')}rem;
  `}
`
