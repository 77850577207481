/**
 * Created by melvin on 11/04/17.
 */
export const STAFF_LIST_REQUESTED = "STAFF_LIST_REQUESTED";
export const STAFF_LIST_RESPONSE = "STAFF_LIST_RESPONSE";
export const STAFF_LIST_FAILED = "STAFF_LIST_FAILED";
export const STAFF_REQUESTED = "STAFF_REQUESTED";
export const STAFF_INFO_REQUESTED = "STAFF_INFO_REQUESTED";
export const STAFF_INFO_RESPONSE = "STAFF_INFO_RESPONSE";
export const STAFF_INFO_FAILED = "STAFF_INFO_FAILED";
export const UPDATE_STAFF_ROLE_REQUESTED = "UPDATE_STAFF_ROLE_REQUESTED";
export const UPDATE_STAFF_ROLE_RESPONSE = "UPDATE_STAFF_ROLE_RESPONSE";
export const UPDATE_STAFF_ROLE_FAILED = "UPDATE_STAFF_ROLE_FAILED";
export const DELETE_STAFF_REQUESTED = "DELETE_STAFF_REQUESTED";
export const DELETE_STAFF_RESPONSE = "DELETE_STAFF_RESPONSE";
export const DELETE_STAFF_FAILED = "DELETE_STAFF_FAILED";
export const UPDATE_STAFF_OPEN_CONVERSATION_COUNT_RESPONSE =
  "UPDATE_STAFF_OPEN_CONVERSATION_COUNT_RESPONSE";
export const UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED =
  "UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED";
export const UPDATE_STAFF_CONVERSATION_COUNT_FAILED =
  "UPDATE_STAFF_CONVERSATION_COUNT_FAILED";
export const UPDATE_STAFF_WHEN_USER_UPDATE = "UPDATE_STAFF_WHEN_USER_UPDATE";
export const UPDATE_STAFF_OPEN_CONVERSATION_COUNT_REALTIME =
  "UPDATE_STAFF_OPEN_CONVERSATION_COUNT_REALTIME";
export const UPDATE_STAFF_REQUESTED = "UPDATE_STAFF_REQUESTED";
export const UPDATE_STAFF_RESPONSE = "UPDATE_STAFF_RESPONSE";
export const UPDATE_STAFF_FAILED = "UPDATE_STAFF_FAILED";
export const DELETE_ACCOUNT_STAFF_REQUESTED = "DELETE_ACCOUNT_STAFF_REQUESTED";
export const DELETE_ACCOUNT_STAFF_RESPONSE = "DELETE_ACCOUNT_STAFF_RESPONSE";
export const UPDATE_CURRENT_STAFF_PROJECT_ID = "UPDATE_CURRENT_STAFF_PROJECT_ID";

export const staffListRequest = (
  projectId,
  isFromOverflow,
  overflowAgent,
  isAwWindow
) => ({
  type: STAFF_LIST_REQUESTED,
  projectId,
  isFromOverflow,
  overflowAgent,
  isAwWindow,
});

export const staffListResponse = (
  dashboardAgents,
  overflowAgents,
  projectId
) => ({
  type: STAFF_LIST_RESPONSE,
  dashboardAgents,
  overflowAgents,
  projectId,
});

export const staffListFailed = () => ({
  type: STAFF_LIST_FAILED,
});

export const staffRequest = (projectId, staffId) => ({
  type: STAFF_REQUESTED,
  projectId,
  staffId,
});

export const updateStaffRoleRequested = (updateData) => ({
  type: UPDATE_STAFF_ROLE_REQUESTED,
  updateData,
});

export const updateStaffRoleResponse = (staff) => ({
  type: UPDATE_STAFF_ROLE_RESPONSE,
  staff,
});

export const updateStaffRoleFailed = () => ({
  type: UPDATE_STAFF_ROLE_FAILED,
});

export const deleteStaffRequested = (
  projectKey,
  staffKey,
  assignedUserKey
) => ({
  type: DELETE_STAFF_REQUESTED,
  projectKey,
  staffKey,
  assignedUserKey,
});

export const deleteStaffResponse = (staffId, projectId) => ({
  type: DELETE_STAFF_RESPONSE,
  staffId,
  projectId,
});

export const deleteAccountStaffRequested = (
  staffKey,
  projectKey,
  showVoiceBox
) => ({
  type: DELETE_ACCOUNT_STAFF_REQUESTED,
  staffKey,
  projectKey,
  showVoiceBox,
});

export const deleteAccountStaffResponse = (staffId, projectId) => ({
  type: DELETE_ACCOUNT_STAFF_RESPONSE,
  staffId,
  projectId,
});

export const deleteStaffFailed = () => ({
  type: DELETE_STAFF_FAILED,
});

export const updateStaffOpenConversationStatusRequested = (data) => ({
  type: UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED,
  data,
});

export const updateStaffOpenConversationStatusResponse = (data) => ({
  type: UPDATE_STAFF_OPEN_CONVERSATION_COUNT_RESPONSE,
  data,
});

export const updateOpenConversationCountRealtime = (message , conversation) => ({
  type: UPDATE_STAFF_OPEN_CONVERSATION_COUNT_REALTIME,
  message,
  conversation
});

export const updateStaffOpenConversationStatusFailed = () => ({
  type: UPDATE_STAFF_CONVERSATION_COUNT_FAILED,
});

export const updateStaffWhenUserUpdate = (data) => ({
  type: UPDATE_STAFF_WHEN_USER_UPDATE,
  data,
});

export const getContactByStaffIdRequest = (staffId) => ({
  type: STAFF_INFO_REQUESTED,
  staffId,
});

export const getContactByStaffIdResponse = (data) => ({
  type: STAFF_INFO_RESPONSE,
  data,
});

export const getContactByStaffIdFailed = () => ({
  type: STAF_INFO_FAILED,
});

export const updateStaffByIdRequest = (data) => ({
  type: UPDATE_STAFF_REQUESTED,
  data,
});

export const updateStaffResponse = (data) => ({
  type: UPDATE_STAFF_RESPONSE,
  data,
});

export const updateStaffFailed = () => ({
  type: UPDATE_STAFF_FAILED,
});

export const updateCurrentStaffProjectID = (currentStaffProjectId) => ({
  type: UPDATE_CURRENT_STAFF_PROJECT_ID,
  currentStaffProjectId,
});
