export const SHOW_CONFIRM_POPUP = "SHOW_CONFIRM_POPUP";
export const HIDE_CONFIRM_POPUP = "HIDE_CONFIRM_POPUP";

export const showConfirmPopup = (popupData) => ({
  type: SHOW_CONFIRM_POPUP,
  popupData
});

export const hideConfirmPopup = () => ({
  type: HIDE_CONFIRM_POPUP
});
