import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
@connect((state) => ({
  UserReducer: state.UserReducer
}))
class ChatLayout extends Component {
  render() {
    return (
      // <div className={"cs-conversation-wrp "+(this.props.UserReducer.planType == "FREE" && this.props.UserReducer.planAcknowledged == false ? "update-header" : "")}>
      <div className={"cs-conversation-wrp "}>
        {this.props.leftComponent}
        {this.props.rightComponent}
        {this.props.footerComponent}
      </div>
    );
  }
}

export default ChatLayout;
