import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DirectoryComponent from "./DirectoryComponent.jsx";
import { browserHistory, Link } from "react-router";
import * as Utilities from "../../commons/Utility.js";
import {
  staffListRequest,
  deleteStaffRequested,
  updateStaffRoleRequested,
} from "../../actions/StaffAction";

import * as VoiceboxActions from "../../actions/VoiceboxAction";

const DirectoryContainerHooks = (props) => {
  const { staffReducer, userReducer } = useSelector((state) => ({
    staffReducer: state.StaffReducer,
    userReducer: state.UserReducer,
  }));
  const [activeStaffId, setActiveStaffId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    let projectKey = props.params.projectkey;
    if (
      !staffReducer ||
      (!!staffReducer &&
        staffReducer.dashboardAgents[Utilities.getProjectId(projectKey)] ===
          undefined &&
        staffReducer.isLoading != true)
    ) {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "Retrieving Team...",
          dismissAfter: 2000,
        })
      );
      dispatch(staffListRequest(Utilities.getProjectId(projectKey)));
    }
  });

  const setActiveStaff = (staffId) => {
    setActiveStaffId(staffId);
    if (
      !props.location.pathname.includes("myteam/invite") &&
      props.params.staffid != staffId
    )
      browserHistory.push({
        pathname:
          "/app/" +
          props.params.projectkey +
          "/directory/myteam/profile/" +
          staffId,
      });
  };

  const getActiveStaffId = () => {
    return activeStaffId;
  };

  const switchActiveStaff = (e) => {
    setActiveStaffId(e.target.dataset.staffkey);
    browserHistory.push({
      pathname:
        "/app/" +
        props.params.projectkey +
        "/directory/myteam/profile/" +
        e.target.dataset.staffkey,
    });
  };
  const gotoInvitePeople = (e) => {
    browserHistory.push({
      pathname: "/app/" + props.params.projectkey + "/directory/myteam/invite",
    });
  };

  const getEventHandler = () => {
    let eventHandler = {};
    eventHandler["switchActiveStaff"] = switchActiveStaff;
    eventHandler["gotoInvitePeople"] = gotoInvitePeople;
    eventHandler["setActiveStaff"] = setActiveStaff;
    eventHandler["getActiveStaffId"] = getActiveStaffId;
    return eventHandler;
  };

  return (
    <DirectoryComponent
      directoryProps={props}
      directoryEventHandler={getEventHandler()}
      staffReducer={staffReducer}
      userReducer={userReducer}
    />
  );
};
export default DirectoryContainerHooks;
