import React from "react";
import { ImportIconBlue } from "../../../commons/Icons";

const ImportContactHeader = ({ contactsCount,closeImportPopUp }) => {

  return (
    <header>
      <h2>
        <ImportIconBlue />
        Import contacts
      </h2>
      <cite onClick={closeImportPopUp}>×</cite>
      <label>{contactsCount} Contacts imported successfully</label>
    </header>
  );
};
export default ImportContactHeader;
