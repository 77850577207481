import { UPDATE_CONTACT_PRESENCE } from "../actions/ContactInfoAction";
import {
  QUEUE_CONVERSATION_RESPONSE,
  QUEUE_CONVERSATION_REMOVE,
} from "../actions/ConversationQueueAction";

export const initialState = {
  conversationObject: {
    conversationMap: {},
    contactsMap: {},
  },
  queuedConversations: [],
  isFetched: false,
};

const ConversationQueueReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case QUEUE_CONVERSATION_RESPONSE: {
      const queueConversationMap = Object.assign(
        state.conversationObject.conversationMap,
        action.conversationObject.conversationsInfo.conversationMap
      );
      const queueContactsMap = Object.assign(
        state.conversationObject.contactsMap,
        action.conversationObject.conversationsInfo.contactsMap
      );
      const queueConversationIds = [
        ...new Set([
          ...state.queuedConversations,
          ...action.conversationObject.queuedConversations,
        ]),
      ];
      return {
        ...state,
        conversationObject: {
          conversationMap: queueConversationMap,
          contactsMap: queueContactsMap,
        },
        queuedConversations: queueConversationIds,
        isFetched: true,
      };
    }
    case QUEUE_CONVERSATION_REMOVE: {
      const removeConversationId = action.conversationId;
      const queuedConversationIds = state.queuedConversations.filter(
        (conversationId) => conversationId != removeConversationId
      );
      delete state.conversationObject.conversationMap[removeConversationId];
      return {
        ...state,
        queuedConversations: queuedConversationIds,
      };
    }
    case UPDATE_CONTACT_PRESENCE: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      if (action.presenceDetails) {
        for (var data of action.presenceDetails) {
          if (contactsMap[data.userInfo.userId]) {
            contactsMap[data.userInfo.userId].isOnline =
              data.status.connectionStatus === "online";
            if (action.isSubscriberLeft)
              contactsMap[data.userInfo.userId].lastSeenTime =
                new Date().getTime();
          }
        }
      }
      return {
        ...state,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
      };
    }
    default:
      return state;
  }
};

export default ConversationQueueReducer;
