import React, { useRef } from "react";
import WhitelistedUrlComponent from "./WhitelistedUrlComponent.jsx";
import { ErrorIcon, AddFieldIcon } from "../../commons/Icons.js";
import { useState } from "react";
import { useEffect } from "react";
import WhiteBlackList from './whiteblacklist.jsx'

const WidgetVisibilityComponent = (props) => {

  const {allowedUrls , blockedUrls , addUrlsToMap , removeDomainFromList , resetUrls , getPreviousUrl } = props;
  console.log("The urls i got :" , allowedUrls);
  const {getDomainsByType , hasLocalStateChanged , allowedUrlListCount , blockedUrlsListCount} = props;
  const inputRef = useRef(null);
  const [showInputField, setShowInputField] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { urls } = props;

  useEffect(() => {
    if (urls && urls.length === 0) setShowInputField(true);
    if (props.isUpdated) {
      setShowInputField(false);
      props.setUpdated(false);
    }
  });

  const getWhiteListedUrls = () => {
    let toRender = [];
    if (urls) {
      for (let index = 0; index < urls.length; index++) {
        toRender.push(
          <WhitelistedUrlComponent
            key={index}
            url={urls[index]}
            removeDomainFromList={props.removeDomainFromList}
            updateNewUrl={props.updateNewUrl}
          />
        );
      }
    }
    return toRender;
  };

  const getDisplayForErrorMessage = () => {
    let style = {};
    if (props.error) style["display"] = "block";
    else style["display"] = "none";
    return style;
  };

  const handleBlur = (e , urlType) => {
    setIsActive(false);
    props.addDomainToList( urlType);
  };

  const getClassName = () => {
    if (props.error) return "cs-input-form cs-error-form";
    else if (!props.error && isActive) return "cs-input-form cs-active-field";
    else return "cs-input-form";
  };

  const addInputField = () => {
    if (inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
    setShowInputField(true);
  };

  const handleEnter = (urlType ) => {
    props.addDomainToList( urlType );
  };

  return (<WhiteBlackList blockedUrlsListCount={blockedUrlsListCount} allowedUrlListCount={allowedUrlListCount}  getPreviousUrl={getPreviousUrl} hasLocalStateChanged={hasLocalStateChanged} getDomainsByType={getDomainsByType} resetUrls={resetUrls} removeDomainFromList={removeDomainFromList} addUrlsToMap={addUrlsToMap} handleBlur={handleBlur} handleEnter={handleEnter} blacklistedUrls={blockedUrls} whitelistedUrls={allowedUrls}/>)
};
export default WidgetVisibilityComponent;
