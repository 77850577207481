import { call, put, fork } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { RATE_LIMIT_MESSAGE } from '../commons/Constants.js';
import {
  initializeResetFailed,
  initializeResetResponse,
  resetPasswordResponse,
  generalErrorMessage,
} from "../actions/ResetPasswordAction";

export function* initializeResetRequest(loginData) {
  const url = `/user/initialize/resetpassword/${loginData.login}`;

  try {
    let initializationResponse = yield call(Ajax.getUnauthenticated, url);
    initializationResponse = initializationResponse.data;
    yield put(initializeResetResponse());
    console.info("initializationResponse:", initializationResponse);
  } catch (error) {
    let { response } = error;
    if (response && response.data && response.data.Exception)
      yield put(initializeResetFailed(response.data.Exception));
    else if (response && response.data && response.data.message === RATE_LIMIT_MESSAGE.EXCEED_LIMIT)
      yield put(initializeResetFailed("Reset password limit reached. Please try again after 24 hours or contact our support team."));
    else yield put(initializeResetFailed("Oops,Something went wrong"));
  }
}

export function* resetPasswordRequest(resetObj) {
  const url = `/user/v2/reset/password`;

  try {
    console.info("resetObj:", resetObj.resetInfo);
    let resetResponse = yield call(
      Ajax.postUnauthenticated,
      url,
      resetObj.resetInfo
    );
    resetResponse = resetResponse.data;
    yield put(resetPasswordResponse());
  } catch (error) {
    yield put(generalErrorMessage("Oops,Something went wrong"));
  }
}
