import {
  STAFF_CONTACT_REQUESTED,
  STAFF_CONTACT_RECEIVED,
  STAFF_CONTACT_REQUEST_FAILED,
  STAFF_CONTACT_UPDATE_REQUEST,
  STAFF_CONTACT_UPDATE_RESPONSE,
  STAFF_CONTACT_UPDATE_FAILED,
} from "../Action/StaffContactAction";

const StaffContactReducer = (
  state = {
    contactMap: {},
    isUpdating: false,
    isFetching: false,
    isFetched: false,
    success: false,
  },
  action
) => {
  switch (action.type) {
    case STAFF_CONTACT_REQUESTED:
      return {
        ...state,
        isFetching: true,
      };

    case STAFF_CONTACT_RECEIVED:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        success: true,
        contactMap: Object.assign(state.contactMap, action.contactMap),
      };
    case STAFF_CONTACT_REQUEST_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        success: false,
        error: "Error occured while fetching Staff contact!",
      };
    case STAFF_CONTACT_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case STAFF_CONTACT_UPDATE_RESPONSE:
      state.data[action.chatConfiguration.key] = action.chatConfiguration;
      return {
        ...state,
        isUpdating: false,
        data: state.data,
      };
    case STAFF_CONTACT_UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    default:
      return state;
  }
};
export default StaffContactReducer;
