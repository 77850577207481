import React, { Component } from "react";
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as ConversationInfoAction from "../../actions/ConversationInfoAction";
import * as StaffAction from "../../actions/StaffAction";
import { browserHistory } from "react-router";
import {
  getProjectId,
  getProjectKey,
  generateMessageId,
  isAwWindow,
  getFullName,
  getEncrypted,
  getCountryCode,
  getProjectByKey,
  resetConversationMessagesIfNotOpenChat,
  getValueFromNestedObject,
  isAdvancedFilterSearch,
  isTagSearch,
  isNameSearch,
  isCompanySearch,
  requestConversationListForAdvancedSearch,
  setSearchTextFromLocalStorageIfEmptySearchText,
  searchToObject,
  getConversationLimitByContainerHeight,
  hasReachedBottomOfTheContainer,
} from "../../commons/Utility.js";
import { staffListRequest } from "../../actions/StaffAction";
import {
  CHAT_STATUS,
  MESSAGE_TYPE,
  MESSAGE_FROM,
  CHAT_MESSAGE_LIMIT,
  CURSOR_STATUS,
  SHORT_CHAT_STATUS_MAP,
  SHORT_SORT_BY_MAP,
  MODIFICATION_STATUS,
  MANUAL_ASSIGNMENT_TYPES,
  TAG_TYPES,
  MESSAGE_STATUS,
} from "../../commons/Constants";
import { showVoicebox } from "../../actions/VoiceboxAction";
import * as TagActions from "../../actions/TagActions";
import ConversationHeader from "./ConversationHeader.jsx";
import ConversationList from "./ConversationList.jsx";
import {
  FETCH_VISITOR_QUEUE,
  fetchVisitorQueue,
  enableTabSwitching,
} from "../../actions/VisitorQueueActions";
import {
  updateUserRequested,
  updateSelectedProjectKey,
  updateDefaultProjectKey,
} from "../../actions/UserAction.js";
import ConversationFilterComponent from "./filters/ConversationFilterComponent";
import { queueConversationRequest } from "../../actions/ConversationQueueAction";

@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  project: state.ProjectReducer,
  messageReducer: state.MessageReducer,
  user: state.UserReducer,
  staffReducer: state.StaffReducer,
  tagsReducer: state.TagReducer,
  VisitorQueueReducer: state.VisitorQueueReducer,
  ConversationQueueReducer: state.ConversationQueueReducer,
}))
class ConversationSection extends Component {
  constructor() {
    super();
    this.loadMore = this.loadMore.bind(this);
    this.switchAssignedTo = this.switchAssignedTo.bind(this);
    this.getAgentNameById = this.getAgentNameById.bind(this);
    this.getOverflowAgentNameById = this.getOverflowAgentNameById.bind(this);
    this.getAgentImageURLById = this.getAgentImageURLById.bind(this);
    this.setLocalState = this.setLocalState.bind(this);
    this.getFilteredConversations = this.getFilteredConversations.bind(this);
    this.resizeHandler = this.resizeHandler.bind(this);
    this.onSearchSelect = this.onSearchSelect.bind(this);
    this.onSearchClear = this.onSearchClear.bind(this);
    this.setContactStateFromProps = this.setContactStateFromProps.bind(this);
    this.isMyChatsFilter = this.isMyChatsFilter.bind(this);
    this.isMentionedFilter = this.isMentionedFilter.bind(this);
    this.state = {
      conversationObject: {
        conversationMap: {},
        contactsMap: {},
        isConvFetched: false,
        isConvFetching: false,
        isQueueFetched: false,
      },
      messages: {},
      staffMap: {},
      overflowAgents: {},
      user: {},
      params: {
        projectkey: "",
        chatStatus: "",
        sortBy: "",
        assignedTo: "",
        conversationId: "",
      },
      chatStatuses: ["Open", "Closed"],
      sortBys: ["Newest", "Oldest"],
      assignedTos: {},
      filtersForUrl: {
        chatStatus: "",
        sortBy: "",
        assignedTo: "",
        conversationId: "",
        visitorOption: "",
      },
      messageMap: {},
      queryString: "",
      queryParams: {},
      isAwWindow: false,
    };
    this.timer = null;
    this.isTypingStarted = false;
  }

  componentWillMount() {
    let { ConversationQueueReducer, VisitorQueueReducer, dispatch } =
      this.props;
    if(!ConversationQueueReducer.isFetched){
      dispatch(queueConversationRequest());
    }
    if(!VisitorQueueReducer.isInitialFetchComplete){
      dispatch(fetchVisitorQueue());
    }
    this.setLocalState(this.props, () => {
      //framd ein here
      const { user } = this.props;
      const { params } = this.state;
      let selectedProjectKey = user ? user.selectedProjectKey : null;
      let projectKey =
        selectedProjectKey && selectedProjectKey !== "null"
          ? selectedProjectKey
          : params.projectkey;
      let chatStatus = this.state.filtersForUrl.chatStatus;
      if (
        isAdvancedFilterSearch(chatStatus) &&
        !getValueFromNestedObject(this.props,'location.state.searchTextFilter')
      )
        setSearchTextFromLocalStorageIfEmptySearchText(chatStatus, this.props.location);
      let searchText = getValueFromNestedObject(this.props, "location.state.searchTextFilter");
      searchText = searchText ? searchText : '';
      let searchTextCursor = new Date().getTime();
      if (this.props.route.props.replaceDefaultFilters) {
        // to check whether there are filter params in the url else push the default one to it.
        if (this.state.filtersForUrl.chatStatus === "T") {
          this.props.dispatch(
            ConversationInfoAction.conversationListRequest({
              projectId: getProjectId(projectKey),
              chatStatus: "T",
              searchText: getEncrypted(
                this.props.location.state &&
                  this.props.location.state.searchTextFilter
                  ? this.props.location.state.searchTextFilter
                  : ""
              ),
              isNewList: true,
            })
          );
          return;
        }
        if (
          isAdvancedFilterSearch(this.state.filtersForUrl.chatStatus)
        ) {
          if(!getValueFromNestedObject(this.props, 'location.state.searchTextFilter'))
            return;
          requestConversationListForAdvancedSearch(
            chatStatus,
            getEncrypted(searchText),
            searchTextCursor,
            true
          );
          return;
        }
        if (this.props.user.data && this.props.params.assignedTo !== "All") {
          browserHistory.push({
            pathname:
              "/app/" +
              projectKey +
              "/chat/dashboard/" +
              this.state.filtersForUrl.visitorOption +
              "/" +
              this.state.filtersForUrl.chatStatus +
              "/" +
              this.state.filtersForUrl.sortBy +
              "/" +
              this.props.user.data.id +
              (this.state.filtersForUrl.conversationId
                ? "/" + this.state.filtersForUrl.conversationId
                : ""),
            search: this.state.queryString ? this.state.queryString : "",
          });
          let tempConversationId = this.props.ConversationReducer.tempConversationId;
          let conversationMap = this.props.ConversationReducer.conversationObject.conversationMap;
          this.props.dispatch(
            ConversationInfoAction.conversationListRequest({
              projectId: getProjectId(projectKey),
              isConversationStarted: true,
              cursor: "",
              limit:  getConversationLimitByContainerHeight(),
              isNewList: tempConversationId && conversationMap[tempConversationId] && getProjectId(selectedProjectKey) ==  conversationMap[tempConversationId].projectId ? false : true,
              assignedTo: this.props.user.data.id,
              chatStatus:
                CHAT_STATUS[
                  SHORT_CHAT_STATUS_MAP[this.state.filtersForUrl.chatStatus]
                ],
              overflowStatus:
                this.state.filtersForUrl.chatStatus === "R"
                  ? ["DISCONNECTED", "OVERFLOWED", "ACCEPTED"]
                  : [],
              orderBy:
                SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest"
                  ? "-modifiedDate"
                  : "modifiedDate",
              desc:
                SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest",
              search:
                '{"searchType":"' +
                (this.state.queryParams.search_type
                  ? this.state.queryParams.search_type
                  : "") +
                '", "searchValue":"' +
                (this.state.queryParams.search_value
                  ? this.state.queryParams.search_value
                  : "") +
                '"}',
            })
          );
          return;
        }
      }

      //lets updated here
      //to fetch the conversations //Should check it for delay
      let query = searchToObject() || this.props.user.fullscreenQuery;
      if(query && query.isFullscreen){
        this.props.dispatch(
          ConversationInfoAction.conversationRequest({
            projectId: getProjectId(projectKey),
            isConversationStarted: true,
            conversationId:this.state.filtersForUrl.conversationId || "",
            filters: this.state.filtersForUrl,
            projectKey: projectKey,
            isFullScreenFetch : true
          })
        );
      } else {
        this.props.dispatch(
          ConversationInfoAction.conversationListRequest({
            projectId: getProjectId(projectKey),
            isConversationStarted: true,
            cursor: "",
            limit:  getConversationLimitByContainerHeight(),
            isNewList: true,
            assignedTo:
              this.state.filtersForUrl.assignedTo !== "All"
                ? this.state.filtersForUrl.assignedTo
                : "",
            chatStatus:
              CHAT_STATUS[
                SHORT_CHAT_STATUS_MAP[this.state.filtersForUrl.chatStatus]
              ],
            overflowStatus:
              this.state.filtersForUrl.chatStatus === "R"
                ? ["DISCONNECTED", "OVERFLOWED", "ACCEPTED"]
                : [],
            orderBy:
              SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest"
                ? "-modifiedDate"
                : "modifiedDate",
            desc: SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest",
            search:
              '{"searchType":"' +
              (this.state.queryParams.search_type
                ? this.state.queryParams.search_type
                : "") +
              '", "searchValue":"' +
              (this.state.queryParams.search_value
                ? this.state.queryParams.search_value
                : "") +
              '"}',
          })
        );
      }
      

      //to fetch the staffList for the assignedTos
      if (!this.props.staffReducer.dashboardAgents[getProjectId(projectKey)])
        this.props.dispatch(staffListRequest(getProjectId(projectKey)));

      if (
        !this.props.tagsReducer.isFetching &&
        !this.props.tagsReducer.isFetched &&
        this.state.params.projectkey &&
        getProjectId(this.state.params.projectkey) !==
          this.props.tagsReducer.projectId
      )
        this.props.dispatch(
          TagActions.requestTagList(getProjectId(this.state.params.projectkey))
        );
    });
  }

  componentDidMount() {
    //this.fetchPresenceDetails();
    if(this.props.ConversationReducer.currentConversationId != this.props.params.conversationId)
      this.props.dispatch(ConversationInfoAction.updateCurrentConversationId(this.props.params.conversationId));
    window.addEventListener("resize", this.resizeHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    this.resizeHandler();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
    // this.props.dispatch(ConversationInfoAction.resetConversationQueue());
  }
  componentWillReceiveProps(nextProps) {
    const {
      user,
      params,
      ConversationReducer,
      dispatch,
    } = this.props;

    const oldParams = params;
    const newParams = nextProps.params;
    const selectedProjectKey = this.getSelectedProjectKey(user, params);

    if (ConversationReducer.currentConversationId !== newParams.conversationId) {
      resetConversationMessagesIfNotOpenChat(ConversationReducer.currentConversationId);
      dispatch(ConversationInfoAction.updateCurrentConversationId(newParams.conversationId));
    }

    if (this.shouldReplaceDefaultFilters(nextProps)) {
      this.replaceDefaultFilters(nextProps, selectedProjectKey);
      return;
    }

    if (this.shouldUpdateConversationMap(oldParams, newParams)) {
      this.updateConversationMap(nextProps, oldParams.conversationId);
    }

    if (this.shouldFetchStaffList(nextProps, oldParams)) {
      dispatch(staffListRequest(getProjectId(newParams.projectkey)));
    }

    this.setLocalState(nextProps, () => {
      if (this.shouldFetchConversations(user, oldParams, newParams, nextProps)) {
        this.fetchConversations(dispatch, selectedProjectKey, this.state.filtersForUrl, this.state.queryParams);
        return;
      }

      if (this.shouldFetchMoreConversations(nextProps)) {
        this.fetchMoreConversations(dispatch, selectedProjectKey, this.state.filtersForUrl, this.state.queryParams);
      }

      if (this.shouldFetchConversationById(nextProps, newParams)) {
        this.fetchConversationById(dispatch, selectedProjectKey, newParams.conversationId, this.state.filtersForUrl);
        return;
      }

      if (this.shouldRedirectToDefaultConversation(nextProps, newParams)) {
        this.redirectToDefaultConversation(newParams.projectkey, this.state.filtersForUrl, this.state.queryString);
        return;
      }

      if (this.shouldFetchNewMessageInfo(nextProps)) {
        this.fetchNewMessageInfo(dispatch, selectedProjectKey, nextProps, this.state.filtersForUrl);
      }
    });

    this.setContactStateFromProps(nextProps);
  }

  getSelectedProjectKey(user, params) {
    return user?.selectedProjectKey && user.selectedProjectKey !== "null"
      ? user.selectedProjectKey
      : params?.projectkey;
  }

  shouldReplaceDefaultFilters(nextProps) {
    const { user, route, params } = nextProps;
    return (
      user?.data &&
      route?.props?.replaceDefaultFilters &&
      !(params?.chatStatus && params?.sortBy && params?.assignedTo)
    );
  }

  replaceDefaultFilters(nextProps, selectedProjectKey) {
    const { dispatch } = this.props;
    const { filtersForUrl, queryString } = this.state;
    browserHistory.push({
      pathname: `/app/${this.state.params.projectkey}/chat/dashboard/${filtersForUrl.visitorOption}/${filtersForUrl.chatStatus}/${filtersForUrl.sortBy}/${nextProps.user.data.id}${filtersForUrl.conversationId ? "/" + filtersForUrl.conversationId : ""}`,
      search: queryString || "",
    });
    dispatch(
      ConversationInfoAction.conversationListRequest({
        projectId: getProjectId(selectedProjectKey),
        isConversationStarted: true,
        cursor: "",
        limit: getConversationLimitByContainerHeight(),
        isNewList: true,
        assignedTo: nextProps.user.data.id,
        chatStatus: CHAT_STATUS[SHORT_CHAT_STATUS_MAP[filtersForUrl.chatStatus]],
        orderBy: SHORT_SORT_BY_MAP[filtersForUrl.sortBy] === "Newest" ? "-modifiedDate" : "modifiedDate",
        desc: SHORT_SORT_BY_MAP[filtersForUrl.sortBy] === "Newest",
        search: `{"searchType":"${this.state.queryParams.search_type || ""}", "searchValue":"${this.state.queryParams.search_value || ""}"}`,
      })
    );
  }

  shouldUpdateConversationMap(oldParams, newParams) {
    return (
      this.state.conversationObject.conversationMap[oldParams.conversationId] &&
      oldParams.conversationId !== newParams.conversationId &&
      this.state.conversationObject.conversationMap[oldParams.conversationId].isModified
    );
  }

  updateConversationMap(nextProps, oldConversationId) {
    const conversation = this.state.conversationObject.conversationMap[oldConversationId];
    conversation.isModified = false;
    const userId = nextProps.user.data ? nextProps.user.data.id : "";

    switch (conversation.modifiedType) {
      case MODIFICATION_STATUS.CLOSE:
        conversation.chatStatus = "CLOSED";
        break;
      case MODIFICATION_STATUS.RE_OPEN:
      case MODIFICATION_STATUS.RE_OPEN_ON_ASSIGN:
        conversation.chatStatus = "OPEN";
        break;
      case MODIFICATION_STATUS.MENTION_CLOSED:
        conversation.mentionedStaffs = conversation.mentionedStaffs.filter(staff => staff !== userId);
        conversation.hasClosedByMentionedStaff = false;
        this.props.dispatch(ConversationInfoAction.updateConversationResponse(conversation));
        break;
      default:
        break;
    }
  }

  shouldFetchStaffList(nextProps, oldParams) {
    const { staffReducer } = nextProps;
    return (
      (nextProps.params.projectkey !== oldParams.projectkey &&
        !staffReducer.dashboardAgents[getProjectId(nextProps.params.projectkey)]) ||
      (!staffReducer.isLoading &&
        !staffReducer.isFetchFailed &&
        oldParams.projectkey &&
        !staffReducer.dashboardAgents[getProjectId(oldParams.projectkey)])
    );
  }

  shouldFetchConversations(user, oldParams, newParams, nextProps) {
    const { ConversationReducer } = nextProps;
    const isInternalUser = user?.isInternalUser || false;
    return (
      (!isInternalUser && oldParams.projectkey !== newParams.projectkey) ||
      (!ConversationReducer.isFetching &&
        (oldParams.chatStatus !== newParams.chatStatus ||
          oldParams.sortBy !== newParams.sortBy ||
          oldParams.assignedTo !== newParams.assignedTo ||
          this.state.queryString !== nextProps.location.search ||
          ConversationReducer.forceUpdate) &&
        !this.props.user.isFullScreenMode)
    );
  }

  fetchConversations(dispatch, selectedProjectKey, filtersForUrl, queryParams) {
    dispatch(
      StaffAction.updateStaffOpenConversationStatusRequested(getProjectId(selectedProjectKey))
    );
    dispatch(
      ConversationInfoAction.conversationListRequest({
        projectId: getProjectId(selectedProjectKey),
        isConversationStarted: true,
        cursor: "",
        limit: getConversationLimitByContainerHeight(),
        isNewList: true,
        assignedTo: filtersForUrl.assignedTo !== "All" ? filtersForUrl.assignedTo : "",
        chatStatus: CHAT_STATUS[SHORT_CHAT_STATUS_MAP[filtersForUrl.chatStatus]],
        orderBy: SHORT_SORT_BY_MAP[filtersForUrl.sortBy] === "Newest" ? "-modifiedDate" : "modifiedDate",
        desc: SHORT_SORT_BY_MAP[filtersForUrl.sortBy] === "Newest",
        search: `{"searchType":"${queryParams.search_type || ""}", "searchValue":"${queryParams.search_value || ""}"}`,
      })
    );
  }

  shouldFetchMoreConversations(nextProps) {
    const { ConversationReducer } = nextProps;
    return (
      Object.keys(this.state.conversationObject.conversationMap).length < 20 &&
      ConversationReducer.cursor &&
      !ConversationReducer.isFetching
    );
  }

  fetchMoreConversations(
    dispatch,
    selectedProjectKey,
    filtersForUrl,
    queryParams
  ) {
    const { cursor, modifiedDateCursor } = this.props.ConversationReducer;
    const { conversationMap } = this.state.conversationObject;
    const limit = this.getConversationLimit(conversationMap);
    const orderBy = this.getOrderBy(filtersForUrl.sortBy);
    const search = this.buildSearchQuery(queryParams);

    const requestPayload = {
      projectId: getProjectId(selectedProjectKey),
      isConversationStarted: true,
      cursor: cursor,
      modifiedDate:
        modifiedDateCursor !== "NO_CURSOR" ? modifiedDateCursor : undefined,
      limit: limit,
      isNewList: false,
      assignedTo:
        filtersForUrl.assignedTo !== "All" ? filtersForUrl.assignedTo : "",
      chatStatus: CHAT_STATUS[SHORT_CHAT_STATUS_MAP[filtersForUrl.chatStatus]],
      orderBy: orderBy,
      desc: orderBy === "-modifiedDate",
      search: search,
    };

    dispatch(ConversationInfoAction.conversationListRequest(requestPayload));
  }

  getConversationLimit(conversationMap) {
    const maxLimit = 20;
    return maxLimit - Object.keys(conversationMap).length;
  }

  getOrderBy(sortBy) {
    return SHORT_SORT_BY_MAP[sortBy] === "Newest"
      ? "-modifiedDate"
      : "modifiedDate";
  }

  buildSearchQuery(queryParams) {
    const searchType = queryParams.search_type || "";
    const searchValue = queryParams.search_value || "";
    return `{"searchType":"${searchType}", "searchValue":"${searchValue}"}`;
  }

  shouldFetchConversationById(nextProps, newParams) {
    return (
      newParams.conversationId &&
      !this.state.conversationObject.conversationMap[newParams.conversationId] &&
      this.state.triedConversationId !== newParams.conversationId
    );
  }

  fetchConversationById(dispatch, selectedProjectKey, conversationId, filtersForUrl) {
    dispatch(
      ConversationInfoAction.conversationRequest({
        projectId: getProjectId(selectedProjectKey),
        isConversationStarted: true,
        conversationId: conversationId,
        filters: filtersForUrl,
        projectKey: selectedProjectKey,
      })
    );
    this.state.triedConversationId = conversationId;
  }

  shouldRedirectToDefaultConversation(nextProps, newParams) {
    const { conversationMap } = this.state.conversationObject;
    const { conversationId } = this.state.params;
    const { isSingleFetching, tempConversationId } =
      nextProps.ConversationReducer;
    const { replaceDefaultFilters } = nextProps.route.props;

    const isInvalidConversationId =
      !conversationId || !conversationMap[conversationId];
    const hasNotFetchedConversation =
      isSingleFetching === false || !this.state.triedConversationId;
    const isTempConversationMismatch = conversationId !== tempConversationId;

    return (
      replaceDefaultFilters &&
      isInvalidConversationId &&
      hasNotFetchedConversation &&
      isTempConversationMismatch
    );
  }

  redirectToDefaultConversation(newProjectkey, filtersForUrl, queryString) {
    const { conversationMap } = this.state.conversationObject;
    const { data: userData } = this.state.user;
    const { assignedTo } = this.state.params;
    const conversationsKeyList = Object.keys(conversationMap);

    if (conversationsKeyList.length > 0) {
      this.redirectToConversation(
        newProjectkey,
        filtersForUrl,
        queryString,
        conversationMap[conversationsKeyList[0]].key
      );
      return;
    }

    const isUserAssigned = userData && assignedTo === userData.id;
    const isChatStatusOpen =
      filtersForUrl.chatStatus === SHORT_CHAT_STATUS_MAP.Open;
    const isFullScreen = document.location.search?.includes("isFullscreen");

    if (isUserAssigned && isChatStatusOpen) {
      if (isFullScreen) {
        console.log("It's fullscreen, so not moving to queue.");
      } else {
        this.props.dispatch(enableTabSwitching());
        this.redirectToQueue(newProjectkey, filtersForUrl, queryString);
      }
    }
  }

  redirectToConversation(
    newProjectkey,
    filtersForUrl,
    queryString,
    conversationKey
  ) {
    browserHistory.push({
      pathname: `/app/${newProjectkey}/chat/dashboard/${filtersForUrl.visitorOption}/${filtersForUrl.chatStatus}/${filtersForUrl.sortBy}/${filtersForUrl.assignedTo}/${conversationKey}`,
      search: queryString || "",
    });
  }

  redirectToQueue(newProjectKey, filtersForUrl, queryString) {
    browserHistory.push({
      pathname: `/app/${newProjectKey}/chat/queue/${filtersForUrl.visitorOption}/${filtersForUrl.chatStatus}/${filtersForUrl.sortBy}/${filtersForUrl.assignedTo}`,
      search: queryString || "",
    });
  }
  
  shouldFetchNewMessageInfo(nextProps) {
    const { newMessage } = nextProps.messageReducer;
    return (
      newMessage?.type &&
      !newMessage.type.includes("TYPING") &&
      newMessage.type !== "CHAT_PROMPT_IGNORED" &&
      !Object.keys(MESSAGE_STATUS).includes(newMessage.type)
    );
  }

  fetchNewMessageInfo(dispatch, selectedProjectKey, nextProps, filtersForUrl) {
    const { messageReducer, ConversationReducer, user } = nextProps;
    const { newMessage } = messageReducer;
    const lastConversationId = newMessage.conversationId;
    const userId = user?.data?.id || "";
    const conversation =
      ConversationReducer.conversationObject.conversationMap[
        lastConversationId
      ];

    const shouldFetchNewMessage =
      lastConversationId &&
      (!conversation ||
        (newMessage.type === "NOTES" &&
          newMessage.mentionedStaffs.includes(userId))) &&
      !ConversationReducer.isSingleFetching;

    if (!shouldFetchNewMessage) {
      return;
    }
    nextProps.messageReducer.newMessage = {};

    const visitorFirstMessageTime = conversation?.createdDate || 0;
    const isMatchingProjectOrAction =
      getProjectId(this.state.params.projectkey) === newMessage.projectId ||
      newMessage.actionTo === userId;

    if (isMatchingProjectOrAction) {
      dispatch(
        ConversationInfoAction.conversationRequest({
          visitorFirstMessageTime,
          projectId: getProjectId(selectedProjectKey),
          isConversationStarted: true,
          conversationId: lastConversationId,
          filters: filtersForUrl,
          projectKey: selectedProjectKey,
          mentionedStaffs: newMessage.mentionedStaffs,
        })
      );
    }
  }

  //setting the staff to prevent null values in phone number
  setContactStateFromProps(props) {
    let staffMap =
      props.staffReducer.dashboardAgents[props.staffReducer.activeProjectId];
    const { selectedProjectKey, defaultProjectKey } = props.user;
    if (staffMap) {
      let contact = Object.assign({}, props.user.data);
      let staff = Object.assign({}, staffMap[contact.id]);
      let fullName = contact && contact.firstName ? contact.firstName : "";
      fullName += contact && contact.lastName ? " " + contact.lastName : "";
      contact.fullName = fullName;
      contact.mobileNumber = staff ? staff.mobileNumber : "";
      contact.countryCode =
        staff && staff.countryCode ? staff.countryCode : "IN";
      contact.displayMobileNumber =
        staff &&
        staff.displayMobileNumber &&
        staff.displayMobileNumber !== "null"
          ? staff.displayMobileNumber
          : "";
      if (
        selectedProjectKey &&
        selectedProjectKey !== "null" &&
        (!defaultProjectKey || selectedProjectKey !== defaultProjectKey)
      ) {
        contact.defaultProject = props.user.selectedProjectKey;
        this.props.dispatch(updateDefaultProjectKey(contact.defaultProject));
        this.props.dispatch(updateUserRequested(contact, false));
      }
    }
  }

  setLocalState(props, callback) {
    let oldConversationId = this.props.params.conversationId;
    let currentConversationId = props.params.conversationId;
    let conversationMap = this.getFilteredConversations(props);

    let conversationObject = {
      ...props.ConversationReducer.conversationObject,
      conversationMap: Object.assign({}, conversationMap),
      isConvFetched: props.ConversationReducer.isFetched,
      isConvFetching: props.ConversationReducer.isFetching,
      isQueueFetched: props.ConversationReducer.isQueueFetched,
      nextConversationId: props.ConversationReducer.nextConversationId,
    };
    let tagMap = props.tagsReducer.tagMap;
    let dropDownValuesMap = {};
    let dropDownValuesType = {};
    for (let key in tagMap) {
      if (
        tagMap[key] &&
        tagMap[key].name &&
        tagMap[key].tagType === TAG_TYPES.staff_created
      ) {
        dropDownValuesMap[key] = tagMap[key].name;
        dropDownValuesType[key] = "TAG";
      }
    }
    this.setState(
      {
        ...this.state,
        conversationObject: Object.assign({}, conversationObject),
        currentConversationId: props.params.conversationId,
        projectKey: props.params.projectkey,
        convMsgCursor: props.messageReducer.convMsgCursorMap,
        params: props.params,
        scrollToLstMsg: oldConversationId != currentConversationId,
        messages: props.messageReducer.messageMap[props.params.conversationId]
          ? props.messageReducer.messageMap[props.params.conversationId]
          : {},
        user: props.user ? Object.assign({}, props.user) : {},
        staffMap: props.staffReducer.dashboardAgents[
          getProjectId(props.params.projectkey)
        ]
          ? Object.assign(
              {},
              props.staffReducer.dashboardAgents[
                getProjectId(props.params.projectkey)
              ]
            )
          : {},
        overflowAgents: props.staffReducer.overflowAgents
          ? Object.assign({}, props.staffReducer.overflowAgents)
          : {},
        assignedTos: props.staffReducer.dashboardAgents[
          getProjectId(props.params.projectkey)
        ]
          ? Object.assign(
              {},
              props.staffReducer.dashboardAgents[
                getProjectId(props.params.projectkey)
              ]
            )
          : {},
        filtersForUrl: Object.assign(
          {},
          {
            sortBy: props.params.sortBy ? props.params.sortBy : "N",
            chatStatus: props.params.chatStatus ? props.params.chatStatus : "O",
            assignedTo: props.params.assignedTo
              ? props.params.assignedTo
              : "All",
            conversationId: props.params.conversationId
              ? props.params.conversationId
              : "",
            visitorOption:
              props.params.visitorOption && props.params.visitorOption == "H"
                ? "H"
                : "D",
          }
        ),
        messageMap: props.messageReducer.messageMap,
        queryString: props.location.search,
        queryParams: props.location.query,
        dropDownValuesMap: dropDownValuesMap,
        dropDownValuesType: dropDownValuesType,
        isAwWindow: isAwWindow(),
      },
      callback
    );
  }
  resizeHandler() {
    //let conversationListDiv 		=  undefined;
    let conversationList = undefined;
    const { user } = this.props;
    const { params } = this.state;
    let selectedProjectKey =
      user && user.selectedProjectKey && user.selectedProjectKey !== "null"
        ? user.selectedProjectKey
        : params.projectkey;
    if (
      this.child &&
      this.child.ConversationSection &&
      this.child.ConversationSection.ConversationList
    ) {
      //conversationListDiv 	= 	ReactDOM.findDOMNode( this.child.ConversationSection.ConversationList.refs.conversationListDiv );
      conversationList = ReactDOM.findDOMNode(
        this.child.ConversationSection.ConversationList.refs.conversationList
      );
    }
    if (
      conversationList &&
      conversationList.scrollTop + 300 > conversationList.scrollHeight &&
      !this.props.ConversationReducer.isFetched &&
      !this.props.ConversationReducer.isFetching
    ) {
      this.props.dispatch(
        ConversationInfoAction.conversationListRequest({
          projectId: getProjectId(selectedProjectKey),
          isConversationStarted: true,
          cursor: this.props.ConversationReducer.cursor,
          limit:  getConversationLimitByContainerHeight(),
          isNewList: false,
          assignedTo:
            this.state.filtersForUrl.assignedTo !== "All"
              ? this.state.filtersForUrl.assignedTo
              : "",
          chatStatus:
            CHAT_STATUS[
              SHORT_CHAT_STATUS_MAP[this.state.filtersForUrl.chatStatus]
            ],
          orderBy:
            SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest"
              ? "-modifiedDate"
              : "modifiedDate",
          desc: SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest",
          search:
            '{"searchType":"' +
            (this.state.queryParams.search_type
              ? this.state.queryParams.search_type
              : "") +
            '", "searchValue":"' +
            (this.state.queryParams.search_value
              ? this.state.queryParams.search_value
              : "") +
            '"}',
        })
      );
      //this.fetchPresenceDetails();
    }
  }

  getFilteredConversations(props) {
    let conversationObject = props.ConversationReducer.conversationObject;
    let conversationMap = conversationObject.conversationMap;
    let filteredConversation = {};
    let sortedConversationMap = {};
    let sortBy = props.params.sortBy ? props.params.sortBy : "N";
    let sortKey = "modifiedDate";
    let currentConversationId = props.params.conversationId
      ? props.params.conversationId
      : "";
    for (let key in conversationMap) {
      if (
        conversationMap[key].isModified &&
        conversationMap[key].isModified === true &&
        key == currentConversationId
      ) {
        filteredConversation[key] = conversationMap[key];
      } else {
          if ( props.params.assignedTo !== 'All' &&
               props.params.assignedTo !== 'None' &&
                ( ( conversationMap[key].mentionedStaffs && 
                    props.user.data &&
                    (conversationMap[key].mentionedStaffs.includes(
                    props.user.data.id ) || (conversationMap[key].closedMentionChats && conversationMap[key].closedMentionChats.includes(props.user.data.id)))
                  ) || 
                  ( conversationMap[key].assignedAgentsObject && 
                    props.params.assignedTo &&
                    conversationMap[key].assignedAgentsObject.open.includes(
                    props.params.assignedTo ) 
                  )
                ) 
              ) {
                  filteredConversation[key] = conversationMap[key];
                }
            if (
              props.params.assignedTo !== "All" &&
              props.params.assignedTo !== "None" &&
              this.props.params.conversationId === key
            )
              filteredConversation[key] = conversationMap[key];
          else if (props.params.assignedTo !== 'All' &&
          (props.params.assignedTo !== 'None' &&
            conversationMap[key].assignedAgentsObject && 
            props.params.assignedTo &&
            conversationMap[key].assignedAgentsObject.open.includes(
              props.params.assignedTo
            ))) {
              filteredConversation[key] = conversationMap[key];
            }
         else  if(this.isMentionedFilter(props) && (conversationMap[key].mentionedStaffs.includes(props.params.assignedTo) || (conversationMap[key].closedMentionChats && conversationMap[key].closedMentionChats.includes(props.params.assignedTo))))
            filteredConversation[key] = conversationMap[key];
        if (
          conversationMap[key].chatStatus ===
            (props.params.chatStatus
              ? CHAT_STATUS[SHORT_CHAT_STATUS_MAP[props.params.chatStatus]]
              : "") ||
          props.params.chatStatus === "A" ||
          props.params.chatStatus === "R" ||
          props.params.chatStatus === "T" ||
          isAdvancedFilterSearch(props.params.chatStatus)
        ) {
          if (
            (props.params.assignedTo === "All" ||
              (props.params.assignedTo === "None" &&
                conversationMap[key].assignedTo === "") ||
              (props.params.assignedTo != "All" &&
                props.params.assignedTo != "None" &&
                props.params.assignedTo != "@Mentioned" &&
                (!this.isMyChatsFilter() ||
                  (this.isMyChatsFilter() &&
                    (!conversationMap[key].overflowStatus || conversationMap[key].overflowStatus == "NOT_OVERFLOWED"))) &&
                conversationMap[key].assignedTo == props.params.assignedTo)) &&
            (!props.location.query.search_type ||
              (props.location.query.search_type &&
                props.location.query.search_value &&
                props.location.query.search_type === "TAG" &&
                conversationMap[key].tags.includes(
                  props.location.query.search_value
                )))
          )
            filteredConversation[key] = conversationMap[key];
        }
      }
    }

    filteredConversation = Object.values(filteredConversation);

    filteredConversation = filteredConversation.sort((a, b) => {
      if (sortBy === "O") return a[sortKey] - b[sortKey];
      if (sortBy === "N") return b[sortKey] - a[sortKey];
    });

    filteredConversation.map((conversation, i) => {
      sortedConversationMap[conversation.key] = conversation;
    });

    return sortedConversationMap;
  }

  isMentionedFilter(props){
    return this.isMyChatsFilter() && props.params.chatStatus === "M";
  }

  isMyChatsFilter() {
    let assignedTo =
      this.state.params && this.state.params.assignedTo
        ? this.state.params.assignedTo
        : this.props.params.assignedTo
        ? this.props.params.assignedTo
        : undefined;
    let userId =
      this.state.user && this.state.user.data && this.state.user.data.id
        ? this.state.user.data.id
        : undefined;
    return assignedTo && userId && assignedTo == userId;
  }
  //to load more on scroll down.
  loadMore(e) {
    let userScrolledHeight = e.target.scrollTop + e.target.clientHeight;
    let chatStatus = this.state.filtersForUrl.chatStatus;
    let searchText = getEncrypted(this.props.ConversationReducer.searchText);
    let searchTextCursor = this.props.ConversationReducer.searchTextCursor;
    let selectedProjectKey =
      this.props.user &&
      this.props.user.selectedProjectKey &&
      this.props.user.selectedProjectKey !== "null"
        ? this.props.user.selectedProjectKey
        : this.state.params.projectkey;
    if (
      hasReachedBottomOfTheContainer(
        userScrolledHeight,
        e.target.scrollHeight
      ) &&
      !this.props.ConversationReducer.isFetching &&
      (!this.props.ConversationReducer.isFetched ||
        this.props.user.isInternalUser)
    ) {

      if (
        isNameSearch(this.state.filtersForUrl.chatStatus) ||
        isCompanySearch(this.state.filtersForUrl.chatStatus)
      )
        return;

      if (isTagSearch(this.state.filtersForUrl.chatStatus)) {
        if (
          this.props.ConversationReducer.searchTextCursor !==
          CURSOR_STATUS.NO_CURSOR
        ) {
          requestConversationListForAdvancedSearch(
            chatStatus,
            searchText,
            searchTextCursor,
            false
          );
          return;
        } else return;
      }

      if (
        this.props.user.isInternalUser &&
        this.props.ConversationReducer.modifiedDateCursor != "NO_CURSOR" &&
        this.props.ConversationReducer.modifiedDateCursor
      )
        this.props.dispatch(
          ConversationInfoAction.conversationListRequest({
            projectId: getProjectId(selectedProjectKey),
            isConversationStarted: true,
            modifiedDate: this.props.ConversationReducer.modifiedDateCursor,
            limit: getConversationLimitByContainerHeight(),
            isNewList: false,
            desc:
              SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest",
            assignedTo:
              this.state.filtersForUrl.assignedTo !== "All"
                ? this.state.filtersForUrl.assignedTo
                : "",
            chatStatus:
              CHAT_STATUS[
                SHORT_CHAT_STATUS_MAP[this.state.filtersForUrl.chatStatus]
              ],
            orderBy:
              SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest"
                ? "-modifiedDate"
                : "modifiedDate",
            search:
              '{"searchType":"' +
              (this.state.queryParams.search_type
                ? this.state.queryParams.search_type
                : "") +
              '", "searchValue":"' +
              (this.state.queryParams.search_value
                ? this.state.queryParams.search_value
                : "") +
              '"}',
          })
        );
      else if (!this.props.user.isInternalUser  && this.props.ConversationReducer.modifiedDateCursor != "NO_CURSOR" &&
      this.props.ConversationReducer.modifiedDateCursor)
        this.props.dispatch(
          ConversationInfoAction.conversationListRequest({
            projectId: getProjectId(selectedProjectKey),
            isConversationStarted: true,
            modifiedDate: this.props.ConversationReducer.modifiedDateCursor,
            cursor: this.props.ConversationReducer.cursor,
            limit: getConversationLimitByContainerHeight(),
            isNewList: false,
            assignedTo:
              this.state.filtersForUrl.assignedTo !== "All"
                ? this.state.filtersForUrl.assignedTo
                : "",
            chatStatus:
              CHAT_STATUS[
                SHORT_CHAT_STATUS_MAP[this.state.filtersForUrl.chatStatus]
              ],
            orderBy:
              SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest"
                ? "-modifiedDate"
                : "modifiedDate",
            desc:
              SHORT_SORT_BY_MAP[this.state.filtersForUrl.sortBy] === "Newest",
            search:
              '{"searchType":"' +
              (this.state.queryParams.search_type
                ? this.state.queryParams.search_type
                : "") +
              '", "searchValue":"' +
              (this.state.queryParams.search_value
                ? this.state.queryParams.search_value
                : "") +
              '"}',
          })
        );
      //this.fetchPresenceDetails();
    }
  }

  // fetchPresenceDetails()
  // {
  // 	if(this.props.messageReducer.isConnected)
  // 	{
  // 			let message = {
  // 				type : 'server-fetchalluserstatus',
  // 				channel : 'server/presence'
  // 			}
  // 		this.props.dispatch(sendMessage( message ,{} ))
  // 	}
  // }

  getAgentNameById(agentId) {
    let assignedAgentName = "";

    let staffMap = this.props.staffReducer.dashboardAgents[
      getProjectId(this.state.params.projectkey)
    ];
    let overflowAgents = this.props.staffReducer.overflowAgents
      ? this.props.staffReducer.overflowAgents
      : {};

    if (agentId && staffMap) {
      if (staffMap[agentId]) {
        let assignedAgent = staffMap[agentId]
          ? Object.assign({}, staffMap[agentId])
          : undefined;
        if (
          assignedAgent &&
          assignedAgent.aliasName &&
          assignedAgent.aliasName != "null"
        ) {
          if (assignedAgent.lastName && assignedAgent.lastName !== "null") {
            assignedAgentName =
              assignedAgent.aliasName +
              " " +
              assignedAgent.lastName.charAt(0).toUpperCase();
          } else {
            assignedAgentName = assignedAgent.aliasName;
          }
        } else {
          assignedAgentName =
            assignedAgent &&
            assignedAgent.firstName &&
            assignedAgent.firstName.toLowerCase() !== "null"
              ? assignedAgent.firstName
              : "";
        }
      } else if (!assignedAgentName && overflowAgents) {
        let assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
        if (
          assignedAgent &&
          assignedAgent.aliasName &&
          assignedAgent.aliasName !== "null"
        ) {
          if (assignedAgent.lastName && assignedAgent.lastName !== "null") {
            assignedAgentName =
              assignedAgent.aliasName +
              " " +
              assignedAgent.lastName.charAt(0).toUpperCase();
          } else {
            assignedAgentName = assignedAgent.aliasName;
          }
        } else {
          assignedAgentName =
            assignedAgent &&
            assignedAgent.firstName &&
            assignedAgent.firstName.toLowerCase() !== "null"
              ? assignedAgent.firstName
              : "";
        }
      } else if (agentId === "System") {
        assignedAgentName = "System";
      } else if (agentId && !this.props.staffReducer.isLoading) {
        assignedAgentName = "Agent";
      } else {
        assignedAgentName = agentId;
      }
    }

    return assignedAgentName;
  }

  getOverflowAgentNameById(agentId) {
    let agentName = "";
    let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
    let overflowAgents = this.props.StaffReducer.overflowAgents
      ? this.props.StaffReducer.overflowAgents
      : {};
    if (agentId && staffMap) {
      let assignedAgent = staffMap[agentId]
        ? Object.assign({}, staffMap[agentId])
        : undefined;

      if (!assignedAgent && overflowAgents) {
        assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
      }

      if (
        assignedAgent &&
        assignedAgent.aliasName &&
        assignedAgent.aliasName != "null"
      ) {
        agentName = assignedAgent.aliasName;
      } else if (
        assignedAgent &&
        assignedAgent.firstName &&
        assignedAgent.firstName != "null"
      ) {
        agentName = assignedAgent.firstName;
        if (assignedAgent.lastName && assignedAgent.lastName != "null")
          agentName += " " + assignedAgent.lastName.charAt(0);
      }
    }
    return agentName;
  }
  getAgentImageURLById(agentId) {
    let agentImageURL =  "https://app.chatsupport.co/images/Staff-Avatar.svg";

    let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
    let overflowAgents = this.props.StaffReducer.overflowAgents
      ? this.props.StaffReducer.overflowAgents
      : {};

    if (agentId && staffMap) {
      let assignedAgent = staffMap[agentId]
        ? Object.assign({}, staffMap[agentId])
        : undefined;
      if (!assignedAgent && overflowAgents) {
        //Lets check in the agents
        assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
      }
      agentImageURL =
        assignedAgent &&
        assignedAgent.photoUrl &&
        assignedAgent.photoUrl != "null"
          ? assignedAgent.photoUrl
          : agentImageURL;
    }

    return agentImageURL;
  }
  onSearchSelect(value, valueKey, type) {
    if (value && valueKey && type)
      browserHistory.push(
        "/app/" +
          this.props.params.projectkey +
          "/chat/dashboard/" +
          this.props.params.visitorOption +
          "/" +
          this.props.params.chatStatus +
          "/" +
          this.state.filtersForUrl.sortBy +
          "/" +
          this.state.filtersForUrl.assignedTo +
          "?search_type=" +
          type +
          "&search_value=" +
          valueKey
      );
    else if (!value || !valueKey || !type) {
      if (value)
        this.props.dispatch(
          showVoicebox({ message: "Invalid value", dismissAfter: 3000 })
        );
      browserHistory.push(
        "/app/" +
          this.props.params.projectkey +
          "/chat/dashboard/" +
          this.props.params.visitorOption +
          "/" +
          this.props.params.chatStatus +
          "/" +
          this.state.filtersForUrl.sortBy +
          "/" +
          this.state.filtersForUrl.assignedTo
      );
    }
  }
  onSearchClear() {
    if (
      this.state.queryParams.search_type &&
      this.state.queryParams.search_value
    )
      browserHistory.push({
        pathname:
          "/app/" +
          this.props.params.projectkey +
          "/chat/dashboard/" +
          this.props.params.visitorOption +
          "/" +
          this.state.filtersForUrl.chatStatus +
          "/" +
          this.state.filtersForUrl.sortBy +
          "/" +
          this.state.filtersForUrl.assignedTo,
      });
  }

  switchAssignedTo(e) {
    let projectKey =
      this.props.user.isInternalUser &&
      this.props.user.selectedProjectKey &&
      this.props.user.selectedProjectKey !== "null"
        ? this.props.user.selectedProjectKey
        : this.props.params.projectkey;
    browserHistory.push({
      pathname:
        "/app/" +
        projectKey +
        "/chat/dashboard/" +
        this.props.params.visitorOption +
        "/" +
        this.state.filtersForUrl.chatStatus +
        "/" +
        this.state.filtersForUrl.sortBy +
        "/" +
        e.currentTarget.dataset.id,
      query: this.props.location.query ? this.props.location.query : "",
    });
  }

  render() {
    let dashboardData = {
      state: this.state,
      props: this.props,
      loadMore: this.loadMore,
      loadMessagesOnScroll: this.loadMessagesOnScroll,
      switchAssignedTo: this.switchAssignedTo,
      getAgentNameById: this.getAgentNameById,
      getAgentImageURLById: this.getAgentImageURLById,
      getOverflowAgentNameById: this.getOverflowAgentNameById,
      onSearchSelect: this.onSearchSelect,
      onSearchClear: this.onSearchClear,
      VisitorQueueReducer: this.props.VisitorQueueReducer,
      ConversationQueueReducer: this.props.ConversationQueueReducer,
    };
    return (
      <div>     
          <ConversationFilterComponent
          {...this.props}
          />  
        <section
          className={
            "cs-my-conversations" + (this.state.isAwWindow ? " dn" : "")
          }
        >
          <ConversationHeader
            dashboardData={dashboardData}
            dispatch={this.props.dispatch}
          />
          <ConversationList
            dashboardData={dashboardData}
            ConversationReducer={this.props.ConversationReducer}
          />
        </section>
      </div>
    );
    //return ( <ChatDashboard dashboardData={ dashboardData } ref={ component => this.child = component } />);
  }
}
// add prop type validation for this alone nextProps.ConversationReducer.isSingleFetching

ConversationSection.propTypes = {
  ConversationReducer: PropTypes.shape({
    isSingleFetching: PropTypes.bool,
    tempConversationId: PropTypes.string,
    conversationObject: PropTypes.shape({
      conversationMap: PropTypes.objectOf(PropTypes.object),
    }),
    currentConversationId: PropTypes.string,
    isFetched: PropTypes.bool,
    isFetching: PropTypes.bool,
    isQueueFetched: PropTypes.bool,
    nextConversationId: PropTypes.string,
    cursor: PropTypes.string,
    searchText: PropTypes.string,
    searchTextCursor: PropTypes.string,
    modifiedDateCursor: PropTypes.string,
  }),
  project: PropTypes.object,
  messageReducer: PropTypes.shape({
    newMessage: PropTypes.shape({
      type: PropTypes.string,
      conversationId: PropTypes.string,
      mentionedStaffs: PropTypes.arrayOf(PropTypes.string),
    }),
    convMsgCursorMap: PropTypes.object,
    messageMap: PropTypes.object,
    isConnected: PropTypes.bool,
  }),
  user: PropTypes.shape({
    isFullScreenMode: PropTypes.bool,
    isInternalUser: PropTypes.bool,
    selectedProjectKey: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  staffReducer: PropTypes.shape({
    dashboardAgents: PropTypes.object,
    activeProjectId: PropTypes.string,
    overflowAgents: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  tagsReducer: PropTypes.shape({
    tagMap: PropTypes.object,
  }),
  VisitorQueueReducer: PropTypes.object,
  ConversationQueueReducer: PropTypes.object,
  params: PropTypes.shape({
    conversationId: PropTypes.string,
    projectkey: PropTypes.string,
    sortBy: PropTypes.string,
    chatStatus: PropTypes.string,
    assignedTo: PropTypes.string,
    visitorOption: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    query: PropTypes.object,
  }),
  route: PropTypes.shape({
    props: PropTypes.shape({
      replaceDefaultFilters: PropTypes.bool,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
};

  
export default ConversationSection;