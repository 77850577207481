import React, { Component, Fragment } from "react";
import { CUSTOMER_NAME_SEARCH, SEARCH_LABEL } from "../../../commons/Constants";
import { CompanyIcon } from "../../../commons/Icons";
class CompanyNameSearch extends Component {
  constructor() {
    super();
  }

  renderTags() {
    let recentSearchHistory = [];
    recentSearchHistory.push(
      <Fragment>
        <CompanyIcon className={'filter-icon'}/> 
        <strong>{SEARCH_LABEL.COMPANY}</strong>
      </Fragment>
    );
    const inputField = document.querySelector(
      ".cs-my-conversations-filter input"
    );
    inputField.focus();
    inputField.placeholder = "";
    this.props.recentSearchHistory(recentSearchHistory, CUSTOMER_NAME_SEARCH);
  }

  render() {
    return <li id="company-name-search" onClick={this.renderTags.bind(this)} onKeyDown={ () => {} }>
      <CompanyIcon />
    Company</li>
  }
}

export default CompanyNameSearch;
