import {
  PEOPLE_LIST_REQUESTED,
  PEOPLE_LIST_RESPONSE,
  RESET_NAVIGATION_SETTINGS,
  PEOPLE_LIST_FAILED,
  PEOPLE_NEW_LIST_RESPONSE,
  PEOPLE_SCROLL_RESPONSE,
  UPDATE_PEOPLE_ENTITY,
  CREATE_PEOPLE_GROUP_REQUEST,
  CREATE_PEOPLE_GROUP_RESPONSE,
  GET_PEOPLE_GROUPS_REQUEST,
  GET_PEOPLE_GROUP_COUNT_REQUEST,
  GET_PEOPLE_GROUP_COUNT_RESPONSE,
  GET_PEOPLE_GROUPS_RESPONSE,
  UPDATE_PEOPLE_GROUP_REQUEST,
  UPDATE_PEOPLE_GROUP_RESPONSE,
  REMOVE_PEOPLE_ENTITY,
  FLUSH_PEOPLE_ENTITY,
  UPDATE_GROUP_SETTINGS_REQUEST,
  UPDATE_GROUP_SETTINGS_RESPONSE,
  UPDATE_FIELD_SETTINGS_REQUEST,
  UPDATE_FIELD_SETTINGS_RESPONSE,
  GET_PEOPLE_COUNT_REQUEST,
  GET_PEOPLE_COUNT_RESPONSE,
  EXPORT_CONTACT_REQUEST,
  EXPORT_CONTACT_RESPONSE,
  SEND_BROADCAST_MESSAGE_RESPONSE,
  SEND_BROADCAST_MESSAGE_REQUEST_FAILED,
  IMPORT_CONTACT_REQUEST,
  IMPORT_CONTACT_RESPONSE,
  DELETE_PEOPLE_GROUP_REQUEST,
  DELETE_PEOPLE_GROUP_RESPONSE,
  REQUEST_PEOPLE_LIST_BY_SCROLL,
  PEOPLE_LIST_REQUEST,
  UPDATE_IS_LAST_BATCH,
  RESET_IS_FETCHED,
  GET_IP_STATUS_REQUEST,
  GET_IP_STATUS_RESPONSE,
  GET_IP_STATUS_FAILED,
  UPDATE_IP_ACTIVITY_RESPONSE,
  GET_MATCHING_SEGMENTS_REQUEST,
  GET_MATCHING_SEGMENTS_RESPONSE,
  GET_MATCHING_SEGMENTS_FAILED,
  ADD_CARD_VISITOR_ID,
  TOGGLE_IMPORT_CONTACT_POPUP,
  ADD_CARD_POSITION,
  BROADCAST_EVENT_STATS_RESPONSE,
  BROADCAST_EVENT_STATS_REQUEST,
  BROADCAST_EVENT_STATS_SCROLL,
  BROADCAST_EVENT_STATS_RESET,
} from "../actions/PeopleAction";
import { UPDATE_CONTACT_PRESENCE } from "../actions/ContactInfoAction";
export const initialState = {
  peopleMap: {},
  hiddenGroupIds: [],
  visibleFields: [],
  groupsProjectId: "",
  peopleGroupMap: {},
  isUpdatingGroup: false,
  isFetchingGroups: false,
  isGroupsFetched: false,
  fetchedCount: 0,
  totalPeople: 0,
  isSingleRequested: false,
  isExportingContact: false,
  showBroadcastPopup: false,
  isDeletingGroup: false,
  isGroupDeleted: false,
  isLoadingMore: false,
  isLoading: false,
  initialLoadComplete: false,
  fetchingCount: 0,
  isFetched: false,
  isFetchingIPStatus : false,
  ipStatusMap : {},
  isFetchingCount : false,
  matchingSegments : {},
  isFetchingMatchingSegments : false,
  currentlySelectedVisitorId : '',
  importedContacts: {},
  showImportContactPopUp: false,
  broadCastPeopleMap: [],
  isBroadCastPeopleMapLoading: "empty",
  scrollDate: "",
}
const PeopleReducer = (
  state = initialState,
  action = {}
) => {
  switch (action.type) {
    case PEOPLE_LIST_REQUEST:
      return {
        ...state,
        peopleMap: {},
        isLoading: true,
      };
    case REQUEST_PEOPLE_LIST_BY_SCROLL:
      return {
        ...state,
        isLoadingMore: true,
      };
    case RESET_NAVIGATION_SETTINGS:
      return {
        ...state,
        ...initialState
      };
    case PEOPLE_NEW_LIST_RESPONSE:
      return {
        ...state,
        peopleMap: Object.assign({}, action.peopleResponse.peopleMap),
        fetchedCount: Object.keys(action.peopleResponse.peopleMap).length,
        totalPeople: action.peopleResponse.totalPeople,
        isSingleRequested: action.peopleResponse.isSingleRequested,
        visibleFields: action.peopleResponse.fieldSettings.visibleFields.slice(),
        isLoadingMore: false,
        isLoading: false,
        initialLoadComplete: true,
      };
    case PEOPLE_SCROLL_RESPONSE:
      return {
        ...state,
        peopleMap: Object.assign(
          state.peopleMap,
          action.peopleResponse.peopleMap
        ),
        fetchedCount:
          state.fetchedCount +
          Object.keys(action.peopleResponse.peopleMap).length,
        totalPeople: action.peopleResponse.totalPeople,
        isLoadingMore: false,
        isLoading: false,
        initialLoadComplete: true,
      };
    case PEOPLE_LIST_FAILED:
      return {
        ...state,
        isLoadingMore: false,
        isLoading: false,
        initialLoadComplete: true,
      };
    case UPDATE_PEOPLE_ENTITY: {
      let peopleMap = Object.assign({}, state.peopleMap);
      let oldContact = peopleMap[action.peopleEntity.key];
      if (oldContact) {
        let newContact = action.peopleEntity;
        let updatedContact = Object.assign({}, oldContact, newContact);
        peopleMap[action.peopleEntity.key] = updatedContact;
      }
      return {
        ...state,
        peopleMap: peopleMap,
      };
    }
    case REMOVE_PEOPLE_ENTITY: {
      let userMap = Object.assign({}, state.peopleMap);
      let peopleGroupMap = Object.assign({}, state.peopleGroupMap);
      peopleGroupMap[action.currentGroupId].count =
        peopleGroupMap[action.currentGroupId].count - action.visitorIds.length;
      console.log("visitorIds", action.visitorIds);
      for (let index in action.visitorIds)
        delete userMap[action.visitorIds[index]];

      return {
        ...state,
        peopleMap: userMap,
        totalPeople: peopleGroupMap[action.currentGroupId].count,
      };
    }
    case FLUSH_PEOPLE_ENTITY: {
      return {
        ...state,
        peopleMap: {},
        totalPeople: 0,
      };
    }
    case CREATE_PEOPLE_GROUP_REQUEST:
      return {
        ...state,
      };
    case GET_MATCHING_SEGMENTS_REQUEST: 
      return {
        ...state,
        isFetchingMatchingSegments : true
      }
    case GET_MATCHING_SEGMENTS_RESPONSE: 
      let matchingSegments = state.matchingSegments;
      matchingSegments = { ...action.matchingGroupsByVisitorId , ...matchingSegments }
      return {
        ...state,
        matchingSegments : matchingSegments,
        isFetchingMatchingSegments : false
      };
    case GET_MATCHING_SEGMENTS_FAILED: 
      return {
        ...state,
        isFetchingMatchingSegments : false
      }
    case CREATE_PEOPLE_GROUP_RESPONSE:
      let groupInfoResponse = action.groupInfoResponse;
      return {
        ...state,
        peopleGroupMap: { ...state.peopleGroupMap, ...groupInfoResponse },
      };
    case UPDATE_PEOPLE_GROUP_REQUEST:
      return {
        ...state,
        isUpdatingGroup: true,
      };
    case UPDATE_PEOPLE_GROUP_RESPONSE:
      let groupInfo = action.groupInfoResponse;
      return {
        ...state,
        isUpdatingGroup: false,
        peopleGroupMap: { ...state.peopleGroupMap, ...groupInfo },
      };
    case DELETE_PEOPLE_GROUP_REQUEST:
      return {
        ...state,
        isDeletingGroup: true,
      };
    case DELETE_PEOPLE_GROUP_RESPONSE:
      let groupId = action.groupId;
      let existingGroupMap = Object.assign({}, state.peopleGroupMap);
      delete existingGroupMap[groupId];
      let updatedGroupLength = Object.keys(existingGroupMap).length;
      return {
        ...state,
        peopleGroupMap: { ...existingGroupMap },
        isDeletingGroup: false,
        isGroupDeleted: true,
        fetchingCount: updatedGroupLength
      };
    case GET_PEOPLE_GROUPS_REQUEST:
      return {
        ...state,
        groupsProjectId: action.projectId,
        isFetchingGroups: true,
        isGroupsFetched: false,
        peopleGroupMap: {},
        fetchingCount : 0
      };
    case GET_PEOPLE_COUNT_REQUEST:
      return {
        ...state,
      };
    case GET_PEOPLE_COUNT_RESPONSE:
      let peopleGroup = action.peopleGroupInfo.peopleGroups;
      return {
        ...state,
        peopleGroupMap: { ...peopleGroup },
      };
    case GET_PEOPLE_GROUP_COUNT_REQUEST:
      return {
        ...state,
        isFetchingCount: true,
        isFetched: false,
      };
    case GET_PEOPLE_GROUP_COUNT_RESPONSE:
      let peopleGroupMap = Object.assign({}, state.peopleGroupMap);
      let prevFetchingCount = state.fetchingCount;
      let newCount = Object.keys(action.peopleCountInfo).length || 0
      let peopleGroupCount =  Object.keys(peopleGroupMap).length;
      let updatedCount = (prevFetchingCount + newCount);
      if( updatedCount > peopleGroupCount ){
        console.log("updated count is larger than the group count")
      }

      let currentIsFetched = state.isFetched;

      for (var key in action.peopleCountInfo) {
        if (
          action.peopleCountInfo.hasOwnProperty(key) &&
          peopleGroupMap.hasOwnProperty(key)
        ) {
          peopleGroupMap[key].count = action.peopleCountInfo[key];
        }
      }

      return {
        ...state,
        fetchingCount: updatedCount > peopleGroupCount ? peopleGroupCount : updatedCount,
        peopleGroupMap: peopleGroupMap,
        isFetched : currentIsFetched ? currentIsFetched : action.isFetched
      };
    case UPDATE_IS_LAST_BATCH: 
      return {
        ...state,
        isFetched: true,
      }
    case GET_PEOPLE_GROUPS_RESPONSE:
      return {
        ...state,
        isFetchingGroups: false,
        isGroupsFetched: true,
        peopleGroupMap: { ...state.peopleGroupMap, ...action.peopleGroupsMap },
      };
    case RESET_IS_FETCHED:
      return {
        ...state,
        isFetched: false,
      }
    case UPDATE_GROUP_SETTINGS_REQUEST:
      return {
        ...state,
      };
    case UPDATE_GROUP_SETTINGS_RESPONSE:
      return {
        ...state,
        hiddenGroupIds: action.hiddenGroupIds,
      };
    case UPDATE_FIELD_SETTINGS_REQUEST:
      return {
        ...state,
      };
    case UPDATE_FIELD_SETTINGS_RESPONSE:
      return {
        ...state,
        visibleFields: action.visibleFields,
      };
    case EXPORT_CONTACT_REQUEST:
      return {
        ...state,
        isExportingContact: true,
      };
    case EXPORT_CONTACT_RESPONSE:
      return {
        ...state,
        isExportingContact: false,
      };
    case IMPORT_CONTACT_REQUEST:
      return {
        ...state,
        isImportingContact: true,
      };
    case IMPORT_CONTACT_RESPONSE:
      return {
        ...state,
        isImportingContact: false,
      };
    case UPDATE_IP_ACTIVITY_RESPONSE:
      let ipStatuses = state.ipStatusMap;
      let projectIpStatus = ipStatuses[action.ipStatus.projectId] || {};
      projectIpStatus[action.ipStatus.ip] = action.ipStatus;
      ipStatuses[action.ipStatus.projectId] = projectIpStatus;
      return {
        ...state,
        ipStatusMap : ipStatuses || {}
      }
    case GET_IP_STATUS_REQUEST:
      return {
        ...state,
        isFetchingIPStatus : true
      };
    case GET_IP_STATUS_RESPONSE:
      let ipStatusMap = state.ipStatusMap;
      ipStatusMap[action.projectId] = action.ipStatus;
      return {
        ...state,
        ipStatusMap : ipStatusMap || {},
        isFetchingIPStatus : false
      };
    case GET_IP_STATUS_FAILED:
      let ipStatus = state.ipStatusMap;
      ipStatus[action.projectId] = {};
      return {
        ...state,
        ipStatusMap: ipStatus || {},
        isFetchingIPStatus : false
      }
    case ADD_CARD_VISITOR_ID : {
      let cardPosition = state.cardPosition;
      if(!action.visitorId)
        cardPosition = '';
      return {
        ...state,
        currentlySelectedVisitorId : action.visitorId,
        cardPosition
      }
    }
    case ADD_CARD_POSITION : {
      return {
        ...state,
        cardPosition : action.cardPosition
      }
    }
    case UPDATE_CONTACT_PRESENCE: {
      let contactsMap = Object.assign({}, state.peopleMap);

      if (action.presenceDetails) {
        for (var data of action.presenceDetails) {
          if (contactsMap[data.userInfo.userId]) {
            contactsMap[data.userInfo.userId].isOnline =
              data.status.connectionStatus === "online";
            if (action.isSubscriberLeft)
              contactsMap[
                data.userInfo.userId
              ].lastSeenTime = new Date().getTime();
          }
        }
      }
      return {
        ...state,
        peopleMap: contactsMap,
      };
    }
    case TOGGLE_IMPORT_CONTACT_POPUP: {
      return {
        ...state,
        showImportContactPopUp: action.showPopUp,
        importedContacts: action.contacts
      };
    }
    case BROADCAST_EVENT_STATS_RESPONSE: {
      if (state.broadCastPeopleMap.length === 0) {
        return {
          ...state,
          broadCastPeopleMap: action.dataRes,
          isBroadCastPeopleMapLoading: "loaded"
        }
      } else {
        return {
          ...state, 
          broadCastPeopleMap: state.broadCastPeopleMap.concat(action.dataRes),
          isBroadCastPeopleMapLoading: "loaded",
        }
      }
    }
    case BROADCAST_EVENT_STATS_REQUEST: {
      return {
        ...state,
        isBroadCastPeopleMapLoading: "loading"
      }
    }
    case BROADCAST_EVENT_STATS_SCROLL: {
      return {
        ...state,
        scrollDate: action.dataRes
      }
    }
    case BROADCAST_EVENT_STATS_RESET: {
      return {
        ...state,
        broadCastPeopleMap: [],
        isBroadCastPeopleMapLoading: "empty",
      }
    }
  }
  return state;
};

export default PeopleReducer;
