import { call, put, select } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  conversationResponse,
  conversationFailed,
  conversationListResponse,
  visitorConversationListResponse,
  conversationListFailed,
  updateConversationResponse,
  createNewConversationResponse,
  createNewConversationFailed,
  updateConversationRealTime,
  trackChatStatus,
  setConversationCount,
  updateSearchTextCursor,
  updateCancelToken,
  conversationStoppedLoading,
} from "../actions/ConversationInfoAction";
import { updateOpenConversationCountRealtime, updateCurrentStaffProjectID } from "../actions/StaffAction";
import { updateUnassingedConversationCountRealtime } from "../settings/actions/ProjectAction";
import {
  receiveAvailableContacts,
  receiveUpdateContact,
  updateContactFailed,
  visitorTimeZoneResponse,
  visitorTimeZoneFailed,
} from "../actions/ContactInfoAction";
import {
  sendMessage,
  getMessage,
  deleteMessageFromToSendList,
} from "../actions/MessageAction";
import { requestCustomFields } from "../actions/CustomFieldsAction";
import {
  MESSAGE_TYPE,
  MESSAGE_FROM,
  MAIN_TAB_APP,
  CHAT_ASSIGN_TYPES,
} from "../commons/Constants";
import { store } from "../app";
import {
  generateMessageId,
  spawnDesktopNotification,
  stripHTML,
  visitorFirstMessageTime,
  getQueryParam,
  getProjectKey,
  isAwConnection,
  getServerTime,
  generateHashCode,
  isOverflowConnection,
  getMentionedStaffs,
  getProjectByKey,
  constructPayloadForAddTag,
  getValueFromNestedObject,
  isTagSearch,
  isAdvancedFilterSearch,
  getIsAWRecentComponent,
  isValidString,
  validateContact,
  isValidResponse,
  desktopNotifyPayloadTrigger,
  getChatAssignedTypeByComponent,
  constructConversationInReducer
} from "../commons/Utility";
import {
  NOTIFICATION_DEFAULT_OPTIONS,
  NEW_CONVERSATION_NOTIFIER,
} from "../commons/Constants";
import { browserHistory } from "react-router";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import {
  updatePeopleEntity,
  fetchPresenceByUserIds,
} from "../actions/PeopleAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { enqueueChat } from "../actions/ChatAnsweringAction";
import { addTagsToConversationRequest } from "../actions/TagActions";
import { checkAndUpdatePrechatSurveyForAwIntegration, isConversationIdExist } from "../aw_recents/AwRecentsUtils";
import ExternalBaseApis from "../aw_recents/ExternalBaseApis.js";
import { getUnreadMessageCount, getVisitorName } from "../aw_recents/components/RecentListTab/awRecentListUtility";
import { queueConversationResponse } from "../actions/ConversationQueueAction";
export function* getConversationList(conversationRequest) {
  console.log("This is from getConversationList: ", conversationRequest.conversationObject.projectId);
  let url = "";
  if (
    conversationRequest &&
    conversationRequest.conversationObject &&
    conversationRequest.conversationObject.chatStatus === "T"
  ) {
  let { conversationObject : { projectId } } = conversationRequest;
  let project = getProjectByKey(projectId, store.getState().ProjectReducer.projectList);
  let accountId = project && project.accountId || 'SEN42';
    url =
      "/search/message/"+accountId+"/" +
      projectId +
      "/" +
      conversationRequest.conversationObject.searchText;
  }
  else if (
    conversationRequest &&
    conversationRequest.conversationObject &&
    conversationRequest.conversationObject.chatStatus === "SN"
  )
    url =
      "/search/contacts/customer/name/" +
      conversationRequest.conversationObject.projectId +
      "/" +
      conversationRequest.conversationObject.searchText;
  else if (
    conversationRequest &&
    conversationRequest.conversationObject &&
    conversationRequest.conversationObject.chatStatus === "SC"
  )
    url =
      "/search/contacts/company/name/" +
      conversationRequest.conversationObject.projectId +
      "/" +
      conversationRequest.conversationObject.searchText;
  else if (
    getValueFromNestedObject(
      conversationRequest,
      "conversationObject.chatStatus"
    ) &&
    isTagSearch(conversationRequest.conversationObject.chatStatus)
  )
    url =
      "/search/tag/" +
      conversationRequest.conversationObject.projectId +
      "/" +
      conversationRequest.conversationObject.searchText +
      "/" +
      conversationRequest.conversationObject.searchTextCursor;
  else if (conversationRequest.conversationObject.isFromOverflow) {
    url = "/conversation/agent/overflow/get/v2";
    conversationRequest.conversationObject = {
      ...conversationRequest.conversationObject,
    };
  }
  else if(conversationRequest.conversationObject.isQueuedConversation){
    url = "/conversation/queue/get";
    conversationRequest.conversationObject = {
      ...conversationRequest.conversationObject,
    };
  }
   else {
    url = "/conversation/agent/get/v2";
    conversationRequest.conversationObject = {
      ...conversationRequest.conversationObject,
    };
  }
  const cancelToken = Ajax.createCancelToken();
  yield put(updateCancelToken(cancelToken));
  const method =
    conversationRequest.conversationObject.chatStatus === "T" ||
    isAdvancedFilterSearch(conversationRequest.conversationObject.chatStatus)
      ? Ajax.get
      : Ajax.postWithToken;
  try {
    let response = {};
    if (conversationRequest.conversationObject.isFromOverflow) {
      response = yield call(Ajax.callWithRetry,
        isValidResponse,
        Ajax.postUnauthenticated,
        url,
        conversationRequest.conversationObject
      );
    }
    else {
      response = yield call(
        Ajax.callWithRetry,
        isValidResponse,
        method,
        url,
        conversationRequest.conversationObject,
        cancelToken.token,
      );
    }
    if (response.data.hasOwnProperty("searchTextCursor"))
      yield put(updateSearchTextCursor(response.data.searchTextCursor));
    let {responseObj,contactKeys} = constructConversationInReducer(url,response,conversationRequest);
    console.info("conversation responseObj =>", responseObj);
    if (conversationRequest.isVisitorConversationList)
      yield put(visitorConversationListResponse(responseObj));
    else {
      yield put(conversationListResponse(responseObj));
      if (contactKeys.length > 0)
        yield put(
          fetchPresenceByUserIds(
            contactKeys,
            conversationRequest.conversationObject.projectId
          )
        );
    }
    yield put(conversationStoppedLoading());
  } catch (e) {
    console.log("Error fetching conversationList:" + e);
    yield put(conversationListFailed());
    yield put(conversationStoppedLoading());
    if (!Ajax.isRequestCanceled(e)) {
      yield put(handleGlobalError(e));
      if(url === "/conversation/agent/get/v2")
        browserHistory.push(`${document.location.origin}/admin`); 
    }
  }
}

export function* getConversation(conversationRequest) {
  var reqData = conversationRequest.conversationObject;
  let mentionedStaffs = reqData.mentionedStaffs;
  delete reqData.mentionedStaffs;
  var visitorFirstMessageTime =
    conversationRequest.conversationObject.visitorFirstMessageTime;
  let url = "";
  console.info("Visitor first message time:" + visitorFirstMessageTime);
  try {
    let response;
    if (reqData.isFromOverflow) {
      url =
        "/conversation/agent/overflow/get/" +
        reqData.projectId +
        "/" +
        reqData.conversationId +
        "?connectionId=" +
        getQueryParam("connection_id");
        response = yield call(
        Ajax.getCallWithRetry,
        (resp) => {
          return resp && resp.data && resp.response == true;
        },
        Ajax.getUnauthenticated,
        url,
        2500
      );
    } else if (reqData.isAwWindow) {
      url = "/conversation/agent/overflow/get/" + reqData.conversationId + "/" +reqData.projectId;
      response = yield call(Ajax.getUnauthenticated, url);
    } else if (reqData.conversationId != MAIN_TAB_APP) {
      console.log("Going to request coversation: ", reqData.conversationId);
      url =
        "/conversation/agent/get/" +
        reqData.projectId +
        "/" +
        reqData.conversationId;
        response = yield call(
        Ajax.getCallWithRetry,
        (resp) => {
          return resp && resp.data && resp.response == true;
        },
        Ajax.get,
        url,
        3600
      );
    }
    console.log("Hurray got the response to Saga", JSON.stringify(response));
    response = response.data;
    console.log("This is conversationInfo response for /agent/get/", JSON.stringify(response));
    response.isFromOverflow = reqData.isFromOverflow;
    response.isAwWindow = reqData.isAwWindow;
    console.log("Befor prechat checking: ", JSON.stringify(response.conversation));
    checkAndUpdatePrechatSurveyForAwIntegration(response);
    let isQueuePage = document.location.href.includes("queue");
    let conversation = response.conversation;
    let unReadCountMap = response.hasOwnProperty("unReadCount")
      ? response["unReadCount"]
      : {};
    let awUnreadCount = response["awUnreadCount"];
    conversation.awUnreadCount = awUnreadCount;
    let newMessageMap = response.hasOwnProperty("newMessage")
      ? response["newMessage"]
      : {};
    if (response.message) {
      if (response.message.type == "ATTACHMENT") {
        try {
          let messageObj = JSON.parse(response.message.message);
          conversation["lastMessage"] = messageObj.fileName;
        } catch (err) {
          conversation["lastMessage"] = stripHTML(response.message.message);
        }
      } else conversation["lastMessage"] = stripHTML(response.message.message);
      if (
        response.message.type === MESSAGE_TYPE.cs_prechatsurvey ||
        response.message.type === MESSAGE_TYPE.cs_offlineform
      )
        conversation["lastMessage"] = "";
      conversation["interactionType"] = response.message.interactionType;
      conversation["messageFrom"] = response.message.messageFrom;
      conversation["lastMessageSenderId"] = response.message.senderId;
    }
    if(mentionedStaffs && mentionedStaffs.length)
      conversation.mentionedStaffs = getMentionedStaffs(conversation.mentionedStaffs, mentionedStaffs);
  
    if (conversation.chatStatus == "NOTINITIATED")
      conversation.chatStatus = "OPEN";
    let filters = reqData.filters;
    let isFullscreen = store.getState().UserReducer.isFullScreenMode;
    if (!reqData.isFromOverflow && filters) {
      if (
        filters.chatStatus != "A" &&
        conversation.chatStatus.charAt(0) !== filters.chatStatus &&
        (!conversation.assignedAgentsObject ||
          !conversation.assignedAgentsObject.open.includes(
            filters.assignedTo
          )) &&
        !conversation.mentionedStaffs.includes(filters.assignedTo) &&
        !isQueuePage &&
        !isFullscreen
      ) {
        conversation.isModified = true;
        conversation.modifiedType =
          conversation.chatStatus == "OPEN" ? "RE_OPEN" : "CLOSE";
      } else if (
        filters.assignedTo !== "All" &&
        conversation.assignedTo !== filters.assignedTo &&
        !conversation.mentionedStaffs.includes(filters.assignedTo) &&
        !isQueuePage &&
        !isFullscreen
      ) {
        conversation.isModified = true;
        conversation.modifiedType = getChatAssignedTypeByComponent();
      }
    }
    // backup condition to update Visitor first Message Time other whole analytics will be missed for the conversation
    if (visitorFirstMessageTime && !conversation.visitorFirstMessageTime)
      conversation.visitorFirstMessageTime = visitorFirstMessageTime;

    console.info("reqData:" + JSON.stringify(reqData));

    conversation.unReadCount = unReadCountMap.hasOwnProperty(conversation.key)
      ? unReadCountMap[conversation.key]
      : 0;
    conversation.newMessage = newMessageMap.hasOwnProperty(conversation.key)
      ? newMessageMap[conversation.key]
      : "";
    
    response.conversation = conversation;
    let userReducer = store.getState().UserReducer;
    let isFromAWIntegration = getIsAWRecentComponent();
    response.isFromAWIntegration = isFromAWIntegration;
    console.log("This is conversationInfo response for /agent/get/ after data manipulation", JSON.stringify(response));
    yield put(conversationResponse(response, userReducer));
    yield put(updateCurrentStaffProjectID(response.conversation.projectId));

    if (response.visitor)
      yield put(
        fetchPresenceByUserIds(
          [response.visitor.key],
          reqData.projectId,
          reqData.isFromOverflow,
          reqData.isAwWindow
        )
      );
    let conversationFromResponse = response.conversation;
    let tagObject = conversationRequest.tagObject;
    if(conversationFromResponse && tagObject){
      let payload = constructPayloadForAddTag(conversation, tagObject); 
      yield put(addTagsToConversationRequest(...payload));
    }
    console.log("Before adding recents to Aw: ", reqData.isFromAWRecentsComponent);
    console.log("This is before appending conversation Key: ", conversation.key);
     if (getIsAWRecentComponent() && window.FrontOfficeService) {
      let data = { type: response.message.type, conversationId: conversation.key };
      let notificationReducer = store.getState().NotificationReducer;
      if (response.message.key !== notificationReducer.lastNotifiedMessageIdInAW && response.message.messageFrom != "AGENT")
          desktopNotifyPayloadTrigger(response.message.message, getVisitorName(response.visitor), data, response.message.key);
      console.log("Adding new chat to Aw window: ", conversation.key);
      let messageReducer = store.getState().MessageReducer;
      let conversationReducer = store.getState().ConversationInfoReducer;
      let dockerUnreadCount = getUnreadMessageCount(messageReducer, conversation.key, conversationReducer);
       let isAnsweringEnabled =
         store.getState().ChatAnsweringReducer.answeringRing[conversation.key];
       if (!isAnsweringEnabled) {
         ExternalBaseApis.makeConnectAppFocus();
         ExternalBaseApis.addItemToConnectRecent(
           { itemId: conversation.key },
           dockerUnreadCount
         );
       }
    }

    // yield put( sendMessage({
    // 	type : 'server-fetchalluserstatus',
    // 	channel : 'server/presence'
    // } , {}));

    // let notificationOptions = Object.assign( {} , NOTIFICATION_DEFAULT_OPTIONS , { body : response.message.message } );
    // let onClickHandler      = () => {
    // 	browserHistory.push( { pathname : "/app/" + conversation.projectKey + "/chat/dashboard/" + "O" + "/" + conversation.filters.sortBy + "/" +"None" + "/" + conversation.conversationId } )
    // };
    // let timeout = 5000;

    // spawnDesktopNotification(
    // 	NEW_CONVERSATION_NOTIFIER.TITLE,
    // 	notificationOptions ,
    // 	onClickHandler,
    // 	NEW_CONVERSATION_NOTIFIER.TIMEOUT,
    // 	NEW_CONVERSATION_NOTIFIER.TONEPATH
    // );
  } catch (e) {
    console.log(e);
    console.log("Got the error in ConversastionInfoRequest: ", JSON.stringify(e));
    yield put(conversationFailed());
    if (
      e.response &&
      e.response.data &&
      e.response.data.Exception &&
      e.response.data.Exception === "INVALID_CONVERSATION_ID"
    ) {
      //yield put( VoiceboxActions.showVoicebox( { message : 'Invalid Conversation Id ! ', dismissAfter : 3000  } ) );
    } else yield put(handleGlobalError(e));
  } finally {
    if (getIsAWRecentComponent() && window.FrontOfficeService && reqData &&
      !ExternalBaseApis.hasItemInFrontOffice(reqData.conversationId)) {
      let dockerUnreadCount = "";
      console.log("going to add connectItem in finally: ", reqData.conversationId);
      let isAnsweringEnabled = store.getState().ChatAnsweringReducer.answeringRing[reqData.conversationId];
      if (isConversationIdExist(reqData.conversationId) && !isAnsweringEnabled) {
        console.log("Going to add the item in finally after second validation: ", reqData.conversationId);
        ExternalBaseApis.addItemToConnectRecent({ itemId: reqData.conversationId }, dockerUnreadCount);
      } else {
        console.log("Coversation id is not there in finally: ", reqData.conversationId);
      }
    }
  }
}

export function* acceptChat(requestObject) {
  var answeringObject = requestObject.answeringObject;
  var conversation = answeringObject.conversationObject;
  var assigningMessage = answeringObject.assigningMessage;
  let url = "";

  try {
    let response;
    let updateResponse;

    if (!conversation) {
      url =
        "/conversation/agent/get/" +
        assigningMessage.projectId +
        "/" +
        assigningMessage.conversationId;
      response = yield call(Ajax.get, url);
      response = response.data;
      conversation = response.conversation;
    }
    conversation.assignedTo = answeringObject.answeredAgent;
    conversation.chatStatus = "OPEN";
    conversation.overflowStatus = "NOT_OVERFLOWED";
    if (isAwConnection()) url = "/conversation/overflow/agent/update";
    else url = "/conversation/agent/update";
    let lastMessageId = conversation.lastMessageId;
    updateResponse = yield call(Ajax.postUnauthenticated, url, conversation);
    updateResponse = updateResponse.data;
    updateResponse.lastMessageId = lastMessageId;

    console.info("reqData:" + JSON.stringify(updateResponse));

    let meta = {
      echoChannels: ["private/agent/" + updateResponse.projectId],
    };

    yield put(updateOpenConversationCountRealtime(assigningMessage));
    yield put(updateUnassingedConversationCountRealtime(assigningMessage));

    yield put(sendMessage(assigningMessage, meta, false));

    if (answeringObject.joiningMessage)
      yield put(sendMessage(answeringObject.joiningMessage, meta, false));
    yield put(queueConversationResponse(updateResponse));
    yield put(updateConversationResponse(updateResponse));

    if (!isAwConnection())
      browserHistory.push({
        pathname:
          "/app/" +
          getProjectKey(updateResponse.projectId) +
          "/chat/dashboard/D/O/N/" +
          updateResponse.assignedTo +
          "/" +
          updateResponse.key,
      });
  } catch (e) {
    console.log(e);
    yield put(conversationFailed());
    if (
      e.response &&
      e.response.data &&
      e.response.data.Exception &&
      e.response.data.Exception === "INVALID_CONVERSATION_ID"
    ) {
      //yield put( VoiceboxActions.showVoicebox( { message : 'Invalid Conversation Id ! ', dismissAfter : 3000  } ) );
    } else yield put(handleGlobalError(e));
  }
}

export function* updateConversationDetails(requestData) {
  let url = "";
  try {
    let updateResponse;

    if (requestData.conversationObject.isFromOverflow) {
      url =
        "/conversation/overflow/agent/update?connectionId=" +
        getQueryParam("connection_id");
      updateResponse = yield call(
        Ajax.postUnauthenticated,
        url,
        requestData.conversationObject.currentConversationObject
      );
    } else {
      url = "/conversation/agent/update";
      updateResponse = yield call(
        Ajax.post,
        url,
        requestData.conversationObject.currentConversationObject
      );
    }

    console.info("The response data is:", updateResponse);
    let messageId =
      requestData.conversationObject.currentConversationObject.lastMessageId;
    let isModified =
      requestData.conversationObject.currentConversationObject.isModified;
    let modifiedType =
      requestData.conversationObject.currentConversationObject.modifiedType;
    let modifiedDate =
      requestData.conversationObject.currentConversationObject.modifiedDate;
    let isClosedByMe =
      requestData.conversationObject.currentConversationObject.isClosedByMe;
    let interactionType =
      requestData.conversationObject.currentConversationObject.interactionType;
    let lastMessageSenderId =
      requestData.conversationObject.currentConversationObject
        .lastMessageSenderId;
    updateResponse = updateResponse.data;

    let messageMap = store.getState().MessageReducer.messageMap;
    let conversationId = updateResponse.key;
    let conversationMesssageMap = messageMap[conversationId];
    let expectedMessage = {};
    let meta = {
      echoChannels: ["private/agent/" + updateResponse.projectId],
    };

    for (let key in conversationMesssageMap) {
      let message = conversationMesssageMap[key];
      if (message.key == messageId || message.messageId == messageId) {
        expectedMessage = message;
      }
    }

    updateResponse["lastMessageId"] = messageId;
    updateResponse["isModified"] = isModified;
    updateResponse["modifiedType"] = modifiedType;
    updateResponse["isClosedByMe"] = isClosedByMe;
    updateResponse["interactionType"] = interactionType;
    updateResponse["lastMessageSenderId"] = lastMessageSenderId;
    if (requestData.conversationObject.updationType == MESSAGE_TYPE.chat_closed)
      updateResponse["modifiedDate"] = modifiedDate;
    if (expectedMessage.type == "ATTACHMENT") {
      //			let message = JSON.parse(expectedMessage.message);
      //			updateResponse["lastMessage"] 	=  stripHTML( message.fileName )

      updateResponse["unReadCount"] =
        requestData.conversationObject.currentConversationObject.unReadCount;
      updateResponse["newMessage"] =
        requestData.conversationObject.currentConversationObject.newMessage;

      let message;
      try {
        message = JSON.parse(expectedMessage.message);
        updateResponse["lastMessage"] = message.fileName;
      } catch (err) {
        message = expectedMessage.message;
        updateResponse["lastMessage"] = stripHTML(message);
      }
    } else updateResponse["lastMessage"] = stripHTML(expectedMessage.message);
    updateResponse["messageFrom"] = expectedMessage.messageFrom;
    if (
      requestData.conversationObject.updationType == MESSAGE_TYPE.chat_closed
    ) {
      yield put(
        sendMessage(
          requestData.conversationObject.closingMessageToAgents,
          meta,
          requestData.conversationObject.isFromOverflow
        )
      );
    } else if (
      CHAT_ASSIGN_TYPES.includes(requestData.conversationObject.updationType) ||
      requestData.conversationObject.updationType ==
        MESSAGE_TYPE.chat_unassigned ||
      requestData.conversationObject.updationType ===
        MESSAGE_TYPE.chat_assign_and_reopen
    ) {
      yield put(
        sendMessage(
          requestData.conversationObject.assigningMessageToAgents,
          meta,
          requestData.conversationObject.isFromOverflow
        )
      );
    }

    yield put(updateConversationResponse(updateResponse));

    if (requestData.conversationObject.updationType == MESSAGE_TYPE.chat_closed)
      yield put(
        updateConversationRealTime({
          ...requestData.conversationObject.closingMessageToAgents,
          isClosedByMe,
        })
      );
  } catch (e) {
    console.log(
      "Exception occured while updating the conversation details :: ",
      e
    );
    yield put(handleGlobalError(e));
  }
}

export function* updateContactDetails(requestData) {
  let url = "";
  try {
    let response;
    let contactObj = requestData.contactObj;
    let contact = contactObj.contact;
    let interactionId = contactObj.interactionId;
    let sbEvent = contactObj.sbEvent || {};
    if (contactObj.isFromOverflow)
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Updating",
          showFullScreenLoader: true,
        })
      );
    else
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Updating",
          showBelowHeader: true,
        })
      );

    if (validateContact(contact)) {
      if (contactObj.isFromOverflow) {
        url =
          "/contact/overflow/update?connectionId=" +
          getQueryParam("connection_id");
        response = yield call(Ajax.putUnauthenticated, url, {
          contact,
          interactionId: interactionId,
          isSBContactUpdate: contactObj.isSBContactUpdate,
          sbEvent : sbEvent
        });
      } else {
        url = "/contact/update";
        response = yield call(Ajax.put, url, contact);
      }
    }
    if (response.response === true) {
      let updatedContact = response.data;

      let present_CF_Ids = contactObj.present_CF_Ids;
      if (present_CF_Ids) {
        let new_CF_Ids = Object.keys(updatedContact.customFields);
        new_CF_Ids = new_CF_Ids.filter((id) => {
          return !present_CF_Ids.includes(id);
        });
        if (new_CF_Ids.length > 0)
          yield put(
            requestCustomFields(
              updatedContact.projectId,
              contactObj.isFromOverflow,
              new_CF_Ids
            )
          );
      }
      if(updatedContact.sourceUrl && !updatedContact.sourceUrl_keyword)
        updatedContact.sourceUrl_keyword = updatedContact.sourceUrl;
      if(updatedContact.currentUrl && !updatedContact.currentUrl_keyword)
        updatedContact.currentUrl_keyword = updatedContact.currentUrl;
      if(updatedContact.lastPageUrl && !updatedContact.lastPageUrl_keyword)
        updatedContact.lastPageUrl_keyword = updatedContact.lastPageUrl
        
      yield put(receiveUpdateContact({ contact: updatedContact }));
      updatedContact = Object.assign(
        updatedContact,
        updatedContact.customFields
      );
      yield put(updatePeopleEntity(updatedContact));
    } else {
      yield put(updateContactFailed());
    }
    if (contactObj.isFromOverflow)
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Update Successful",
          dismissAfter: 2000,
          showFullScreenLoader: true,
        })
      );
    else
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Update Successful",
          dismissAfter: 2000,
          showBelowHeader: true,
        })
      );
  } catch (e) {
    console.error(e);
    yield put(updateContactFailed());
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* createNewConversation(requestObject) {
  const url = "/conversation/agent/create";
  let projectId = requestObject.tempConversation.projectId;
  let visitorId = requestObject.tempConversation.visitorId;
  let tempConversationId = requestObject.tempConversation.key;
  let assignedTo = requestObject.tempConversation.assignedTo;
  let conversationType = requestObject.tempConversation.conversationType;
  let chatStatus = requestObject.tempConversation.chatStatus;
  let { pathname, search } = document.location;
  try {
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Initiating new conversation...",
        showBelowHeader: true,
      })
    );
    let response = yield call(Ajax.post, url, {
      projectId: projectId,
      visitorId: visitorId,
      assignedTo: assignedTo,
      conversationType: conversationType,
      chatStatus: chatStatus,
    });
    if (response.response == true) {
      let conversation = response.data;
      conversation.chatStatus = "OPEN";
      conversation.isConversationStarted = true;

      if (conversation.conversationType === "VISITOR_QUEUE_CONVERSATION") {
        let constrctedUrl = pathname + search;
        constrctedUrl = constrctedUrl.replace(
          tempConversationId,
          conversation.key
        );
        browserHistory.push(constrctedUrl);
      }

      yield put(
        createNewConversationResponse(conversation, tempConversationId)
      );
      let toBeSentMessages = store.getState().MessageReducer.messagesToBeSend;
      toBeSentMessages = toBeSentMessages.filter(
        (message) => message.conversationId === tempConversationId
      );
      let { staffMap, userId } = requestObject;
      for (let i = 0; i < toBeSentMessages.length; i++) {
        let message = toBeSentMessages[i];
        message.conversationId = conversation.key;

        if (i === 0) {
          let messageId = generateMessageId();
          let initiatedMessage = {
            ...message,
            key: messageId,
            type: MESSAGE_TYPE.initiated_new_conversation,
            message: "",
            conversationId: conversation.key,
            messageFrom: MESSAGE_FROM.AGENT,
            messageId: messageId,
          };

          yield put(
            sendMessage(initiatedMessage, {
              echoChannels: ["private/agent/" + message.projectId],
            })
          );
          yield put(updateOpenConversationCountRealtime(initiatedMessage));
          yield put(updateConversationRealTime(initiatedMessage, userId, staffMap));
        }

        yield put(
          sendMessage(message, {
            echoChannels: ["private/agent/" + message.projectId],
          })
        );
        yield put(updateOpenConversationCountRealtime(message));
        yield put(updateConversationRealTime(message, userId, staffMap));
      }
      yield put(VoiceboxActions.hideVoicebox());
    } else yield put(createNewConversationFailed());
  } catch (e) {
    yield put(createNewConversationFailed());
    yield put(handleGlobalError(e));
  }
}

export function* sendTranscriptEmail(request) {
  let {
    transcriptPayload: { conversationId },
  } = request;
  let transcriptUrl = `/emailTranscripts/send/${conversationId}`;

  try {
    let response;
    let { transcriptPayload } = request;
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Mailing transcript...",
        showBelowHeader: !isOverflowConnection(),
        showFullScreenLoader: isOverflowConnection(),
      })
    );
    response = yield call(
      Ajax.postUnauthenticated,
      transcriptUrl,
      transcriptPayload
    );
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Transcript sent!",
        dismissAfter: 2000,
        showBelowHeader: !isOverflowConnection(),
        showFullScreenLoader: isOverflowConnection(),
      })
    );
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
  }
}

function isValidGeoLocation(latitude, longitude) {
  return latitude !== "0.00" && longitude !== "0.00";
}

function getDisplayPlace(response) {
  let displayPlace = "";
  if (isValidString(response.unformattedLocation))
    displayPlace = response.unformattedLocation;
  else if (isValidString(response.city))
    displayPlace = response.city;
  else if (isValidString(response.state))
    displayPlace = response.state;
  else if (isValidString(response.country))
    displayPlace = response.country;
  return displayPlace;
}

export function* getVisitorTimeZone(request) {
  let { latitude, longitude, peopleKey } = request;
  const url = "/contact/get/geodata";
  const requestData = { latitude, longitude };
  try {
    if (!isValidGeoLocation(latitude, longitude)) {
      yield put(visitorTimeZoneFailed(peopleKey));
    } else {
      let response = yield call(Ajax.postUnauthenticated, url, requestData);
      if (response.response == true) {
        response = response.data;
        const DISPLAY_PLACE = getDisplayPlace(response);
        yield put(
          visitorTimeZoneResponse(peopleKey, latitude, longitude, {
            ...response,
            displayPlace: DISPLAY_PLACE,
          })
        );
      } else yield put(visitorTimeZoneFailed(peopleKey));
    }
  } catch (e) {
    console.error(e);
    yield put(visitorTimeZoneFailed(peopleKey));
  }
}

export function* updateChatStatus(request) {
  const { statusTrackerInfo } = request;
  let url = "";
  url = "/chat/connection/status/webapp/queue/trigger";
  console.log("came inside updatechatstatus  " + url);
  try {
    let response = yield call(Ajax.putUnauthenticated, url, statusTrackerInfo);
    console.log("updated chat tracking entity");
  } catch (e) {
    console.error(e);
  }
}

export function* fetchConversationCount(request){
  let {projectId} = request;
  let url = `/conversation/count/${projectId}`;
  try{
    let response = yield call(Ajax.get,url);
    if(response.data){
      yield put(setConversationCount(response.data));
    }
  }
  catch(e){
    console.error(e);
  }
}