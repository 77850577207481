import React, { Component } from "react";

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      isListenerAdded: false,
    };
    this.setMapLoaded = this.setMapLoaded.bind(this);
  }

  componentDidMount() {
    if (
      this.props.contact &&
      Object.keys(this.props.contact).length != 0 &&
      window.google
    )
      this.setDefaultState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.contact &&
      Object.keys(nextProps.contact).length != 0 &&
      (!this.state.contact ||
        this.state.contact.latitude != nextProps.contact.latitude ||
        this.state.contact.longitude != nextProps.contact.longitude ||
        this.props.latitude != nextProps.latitude ||
        this.props.longitude != nextProps.longitude)
    )
      this.setDefaultState(nextProps);
  }
  setMapLoaded() {
    this.setState({ ...this.state, mapLoaded: true });
  }
  setDefaultState(props) {
    if (window.google) {
      this.setState({ contact: props.contact }, () => {
        this.getMap();
        if (!this.state.isListenerAdded && this.map && !this.state.mapLoaded) {
          this.setState({ isListenerAdded: true }, () => {
            google.maps.event.addListenerOnce(
              this.map,
              "tilesloaded",
              this.setMapLoaded
            );
          });
        }
      });
    }
  }

  getMap() {
      let styles = [
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#333333",
              "saturation": 36,
              "lightness": 40
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#ffffff",
              "lightness": 16,
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#fefefe",
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#fefefe",
              "lightness": 17,
              "weight": 1.2
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5",
              "lightness": 20
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "landscape.natural.terrain",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5",
              "lightness": 21
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dedede",
              "lightness": 21
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff",
              "lightness": 18
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff",
              "lightness": 17
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#ffffff",
              "lightness": 29,
              "weight": 0.2
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff",
              "lightness": 16
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f2f2f2",
              "lightness": 19
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e9e9e9",
              "lightness": 17
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        }
      ];
    let latitude = this.props.isFromProfilePage
      ? this.props.latitude
      : this.state.contact.latitude;
    let longitude = this.props.isFromProfilePage
      ? this.props.longitude
      : this.state.contact.longitude;
    let zoom = this.props.isFromProfilePage ? 12.8 : 6;
    let options = {
      center: {
        lat: Number(latitude),
        lng: Number(longitude),
      },
      zoom: zoom,
      styles: styles,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      disableDoubleClickZoom: false,
      draggable: false,
      draggableCursor: "default",
    };

    this.map = new window.google.maps.Map(this.mapElement, options);
  }
  render() {
    let width = this.props.isFromProfilePage ? "100%" : "270px";
    let visibility = this.props.isFromProfilePage ? "visible" : "hidden";
    return (
      <div
        className={
          this.state.mapLoaded ? "cs-map-location" : "" + " fs-exclude"
        }
        ref={(c) => (this.mapElement = c)}
        style={{
          height: "100%",
          position: "absolute",
          overflow: "hidden",
          top: "0px",
          width: width,
          right: "0px",
          visibility: this.state.mapLoaded ? "" : visibility,
        }}
      />
    );
  }
}

export default MapComponent;
