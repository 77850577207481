import React from "react";
import {VisitorEmptyIcon} from '../../commons/Icons'

const NoVisitorLoader = (props) => {
    return (
        <section class="placeholder-wrp">
            <div class="placeholder-content">
                <VisitorEmptyIcon />
                <label>No visitors to display</label>
		        <p>View website visitors and evaluate website performance.</p>
            </div>
        </section>
    )
}

export default NoVisitorLoader;