import { useSelector, useDispatch } from "react-redux";
import * as WorkingHoursAction from "../actions/WorkingHoursAction";

export const useGetWorkingHours = (userId, accountId, projectKey) => {
  const workingHoursReducer = useSelector((state) => state.WorkingHoursReducer);
  const dispatch = useDispatch();

  const { isFetched, isFetching } = workingHoursReducer;

  let workingHours = {};

  if (
    workingHoursReducer &&
    workingHoursReducer.data &&
    userId &&
    workingHoursReducer.data[userId]
  )
    workingHours = workingHoursReducer.data[userId];

  const isUpdating = workingHoursReducer
    ? workingHoursReducer.isUpdating
    : false;
  const isConfigFetched = workingHoursReducer
    ? workingHoursReducer.isFetched
    : false;

  if (
    workingHoursReducer &&
    !isConfigFetched &&
    !isFetching &&
    userId &&
    projectKey &&
    accountId
  )
    dispatch(WorkingHoursAction.getWorkingHours(userId, accountId, projectKey));
  else if (
    workingHoursReducer &&
    !isFetching &&
    userId &&
    accountId &&
    projectKey &&
    Object.keys(workingHours).length <= 0
  )
    dispatch(WorkingHoursAction.getWorkingHours(userId, accountId, projectKey));
  return {
    workingHours: workingHours,
    isUpdating: isUpdating,
    isFetched: isConfigFetched,
  };
};

export const useGetBusinessHours = (projectKey, accountId) => {
  const businessHoursReducer = useSelector(
    (state) => state.WorkingHoursReducer.businessHours
  );
  const dispatch = useDispatch();

  const { isFetched, isFetching } = businessHoursReducer;
  let businessHours =
    businessHoursReducer &&
    businessHoursReducer.data &&
    projectKey &&
    businessHoursReducer.data[projectKey]
      ? businessHoursReducer.data[projectKey]
      : {};

  const isUpdating = businessHoursReducer
    ? businessHoursReducer.isUpdating
    : false;
  const isConfigFetched = businessHoursReducer
    ? businessHoursReducer.isFetched
    : false;

  if (
    businessHoursReducer &&
    !isConfigFetched &&
    !isFetching &&
    projectKey &&
    accountId
  )
    dispatch(WorkingHoursAction.getBusinessHours(projectKey, accountId));
  else if (
    businessHoursReducer &&
    !isFetching &&
    projectKey &&
    accountId &&
    Object.keys(businessHours).length <= 0
  )
    dispatch(WorkingHoursAction.getBusinessHours(projectKey, accountId));
  return {
    workingHours: businessHours,
    isUpdating: isUpdating,
    isFetched: isConfigFetched,
  };
};
