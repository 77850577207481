import React, { useState , useRef, useEffect } from "react";
import {isValidChatPromptURL , getAllIndexes } from '../../commons/Utility'
import { EditPopupIcon , EditIcon, DeleteIcon, MoreOptionsIcon , AddFieldIcon, ErrorIcon} from "../../commons/Icons.js";


const AddNewUrl = (props) => {
  //trigger commit
  let {handleEnter , popupType , handleKeyPress , hideURLConfigPopup , addUrlsToMap , toggleEdit} = props;
  let { url , isEdit , getDomainsByType , hideShowAddNewComponent , getPreviousUrl } = props;
  const [ error , setError ] = useState(false);
  const [ errorMessage , setErrorMessage ] = useState("");
  const inputRef = useRef(null);
  const [isInputActive , setIsInputActive] = useState(false);
  const placeholderText = {
    allowlist : "Enter URL to allow list",
    blocklist : "Enter URL to exclude list"
  }

  useEffect(()=>{
    if(url)
    {
      if(inputRef && inputRef.current){
        inputRef.current.value = url;
      }
    } else {
      if(inputRef && inputRef.current){
        inputRef.current.focus();
      }
    }
  } , [])

  useEffect(()=>{
    if(isEdit){
      if(inputRef && inputRef.current){
        inputRef.current.focus();
      }
    }
  } , [isEdit])

  const getPlaceholder = () => {
    return placeholderText[popupType];
  }

  const addUrl = (event) => {
    let newUrl = event.target.value;
    if(event.key === 'Enter'){
      setIsInputActive(false);
     checkAndSaveUrl(newUrl , event);
     handleKeyPress(event);
    }   
  }

  const handleOnBlur = (event) => {
    let newUrl = inputRef.current.value; 
     checkAndSaveUrl(newUrl , event);
     setIsInputActive(false);
  }

  const checkAndSaveUrl = (newUrl , event) => {
    console.log("URL :" , isValidChatPromptURL(newUrl))
    if(!isValidChatPromptURL(newUrl)){
      setError(true)
      setErrorMessage("Please enter valid URL (https://example.com)")
    }
    else if(hasDuplicateUrl(newUrl , event)){
      setError(true)
      setErrorMessage("URL already exists")
    } else {
      addUrlsToMap(event , popupType);
      if(toggleEdit && isEdit)
        toggleEdit();
      if(hideShowAddNewComponent)
        hideShowAddNewComponent();
      setError(false)
      setErrorMessage("");
    }
  }

  const getContent = () => {
    let type = popupType === 'allowlist' ? 'allowlist' : 'excludelist';
    let contnet = `Note: Use /* at the end of a url to include all subdomains that follow in the ${type}.`
    return error && !isInputActive ? "" : <span>{contnet}</span>;
  }

  const hasDuplicateUrl = ( urlEntry , event ) => {
    let {url : previousUrl } = event.target.dataset;
    let allowedUrls = getDomainsByType('allowlist');
    let blockedUrls = getDomainsByType('blocklist');
    if(isEdit && (urlEntry === previousUrl)) return false;
    if(isEdit){
      
      let combinedArray = [...allowedUrls , ...blockedUrls];
      let indexes = getAllIndexes(combinedArray , urlEntry);
      return (indexes.length > 1)
    } else {
      return (allowedUrls.indexOf(urlEntry) !== -1 || blockedUrls.indexOf(urlEntry) !== -1)
    } 

    
  }

  
  const keyPressHandler = (event) => {
    if(event.key !== 'Enter'){
      setError(false);
      setErrorMessage("");
    }
    handleKeyPress(event)
  }

  const getInputClass = () => {
   if(isInputActive)
    return "cs-input-form cs-active-field";
   else if(error){
    return "cs-input-form cs-error-form";
   } else {
    return "cs-input-form";
   }
  }

  const onFocus = () => {
    setIsInputActive(true);
  }

  const getInput = () => {
    return <div className={getInputClass()}>
    <input  
      ref={inputRef}
      type="text"
      data-url={getPreviousUrl(url , popupType)}
      data-isEdit={isEdit}
      onKeyUp={keyPressHandler}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      onPaste={keyPressHandler}
      onKeyDown={addUrl} type="text" placeholder={getPlaceholder()} />
    {getContent()}
    <span className={error && !isInputActive ? "" : "dn"}><ErrorIcon />{errorMessage}</span>
  </div> ;
  }

  return (
    <li className="cs-input-edit">
        {getInput()}
    </li>
  );
};

export default AddNewUrl;
