import { call, put, fork } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as DeleteUserActions from "../actions/DeleteUserAction";
import * as PeopleActions from "../actions/PeopleAction";
import { authFailed } from "../actions/LoginAction";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import { peopleESDefaultQuery } from "../commons/Constants.js";
import { delay } from "redux-saga";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { store } from "../app.js";

export const getPeopleGroups = (state) => state.PeopleReducer.peopleGroupMap;

export function* deleteUsers(request) {
  request = request.request;
  const url = "/contact/delete/" + request.projectId;

  try {
    let response = yield call(Ajax.put, url, request.query);
    if (response.response == true) {
      yield put(DeleteUserActions.deleteUserResponse());
      if (request.type == "keys")
        yield put(
          PeopleActions.removePeopleEntity(
            request.query.filter.key,
            request.currentGroupId
          )
        );
      else if (request.type == "group")
        yield put(PeopleActions.flushPeopleEntity(request.currentGroupId));

      let countRequestPayload = {};
      countRequestPayload.projectId = request.projectId;
      countRequestPayload.peopleGroupsList = Object.values(
        store.getState().PeopleReducer.peopleGroupMap
      );

      yield delay(4000);
      yield put(PeopleActions.getPeopleCountRequest(countRequestPayload));
      yield put(hideVoicebox());
      yield put(
        showVoicebox({
          message: "Users deleted successfully !",
          dismissAfter: 3000,
        })
      );
    } else {
      yield put(DeleteUserActions.deleteUserFailed());
      yield put(hideVoicebox());
      yield put(
        showVoicebox({ message: "Deletion failed !", dismissAfter: 3000 })
      );
    }
  } catch (e) {
    console.error(e);
    yield put(DeleteUserActions.deleteUserFailed());
    yield put(hideVoicebox());
    yield put(
      showVoicebox({ message: "Deletion failed !", dismissAfter: 3000 })
    );
    yield put(handleGlobalError(e));
  }
}
