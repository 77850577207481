import { useSelector, useDispatch } from "react-redux";
import * as ChatConfigurationAction from "../actions/ChatConfigurationAction";

export const useChatConfigurationEntity = (projectId) => {
  const chatConfigurationReducer =
    useSelector((state) => state.ChatConfigurationReducer) || {};
  const dispatch = useDispatch();

  const { isFetched, isFetching } = chatConfigurationReducer;

  const chatConfiguration =
    chatConfigurationReducer && chatConfigurationReducer.data
      ? chatConfigurationReducer.data[projectId]
      : undefined;
  const isUpdating = chatConfigurationReducer
    ? chatConfigurationReducer.isUpdating
    : false;

  const isConfigFetched = chatConfigurationReducer
    ? chatConfigurationReducer.isFetched
    : false;

  if (!chatConfiguration && !isFetched && !isFetching) {
    dispatch(
      ChatConfigurationAction.requestChatConfiguration(
        projectId,
        chatConfigurationReducer.data
      )
    );
  }
  return {
    chatConfiguration: chatConfiguration || {},
    isUpdating: isUpdating,
    isConfigFetched: isConfigFetched,
  };
};
