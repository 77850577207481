import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Progress } from 'src/features/returningFlow/components/Progress'
import { Section } from 'src/features/returningFlow/components/Progress/Section'
import { stepsView } from 'src/features/returningFlow/components/Wizard/steps'
import {
  IProps,
  IView,
} from 'src/features/returningFlow/components/Wizard/types'
import {
  nextStep,
  showStep,
  skipStep,
} from 'src/features/returningFlow/store/actions'
import {
  selectCurrentStepIndex,
  selectSteps,
} from 'src/features/returningFlow/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const WizardComponent: FC<IProps> = ({
  currentIndex,
  steps,
  dispatchNextStep,
  dispatchSkipStep,
  dispatchShowStep,
}) => {
  const getStepName = (index: number) => steps[index].name

  const getStepViewType = (index: number) => steps[index].viewType

  const renderStepView = (view: IView, index: number) => {
    const renderer = view[getStepViewType(index)]

    if (renderer) {
      return renderer(dispatchNextStep, dispatchSkipStep, dispatchShowStep)
    }

    return null
  }

  return (
    <Progress>
      {stepsView.slice(0, currentIndex + 1).map((view, stepIndex) => (
        <Section
          key={getStepName(stepIndex)}
          completed={stepIndex < currentIndex}
        >
          {renderStepView(view, stepIndex)}
        </Section>
      ))}
    </Progress>
  )
}

const mapStateToProps = (state: IAppState) => ({
  currentIndex: selectCurrentStepIndex(state.returning),
  steps: selectSteps(state.returning),
})

const mapDispatchToProps = {
  dispatchNextStep: nextStep,
  dispatchSkipStep: skipStep,
  dispatchShowStep: showStep,
}

export const Wizard = connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardComponent)
