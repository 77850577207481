import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addTagsToConversationRequest } from "../../../actions/TagActions.js";
import * as VoiceboxActions from "../../../actions/VoiceboxAction.js";
import { DROPDOWN_TAG_CLASSNAME } from "../../../commons/Constants.js";
import {
  isEnterKey,
  isSpaceKey,
  isConversationExisted,
  getActiveProjectId,
} from "../../../commons/Utility.js";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import { EditIcon } from "../../../commons/Icons";
function TagMessageDropdownList(props) {
  const {
    tag,
    payload,
    openDropDown,
    addClassNameAndScrollTo,
    searchInputRef,
    tagInputRef,
    conversationId,
    requestSingleConversationAndAddTagToIt,
    setTagKey
  } = props;
  const dispatch = useDispatch();
  const liRef = useRef("");
  const spanRef = useRef("");
  const [toolTipValue, setToolTipValue] = useState("");
  const maxWidthOfSpan = 100;

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    setToolTipValue(
      spanRef.current.offsetWidth == maxWidthOfSpan ? tag.name : ""
    );
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [tag.name]);
  
  const addTagToMessage = () => {
    let tagNameList = [tag.name];
    if (!isConversationExisted(conversationId))
      requestSingleConversationAndAddTagToIt(tagNameList);
    else dispatch(addTagsToConversationRequest(tagNameList, ...payload));
    dispatch(
      VoiceboxActions.showVoicebox({
        message: "Applying saved tag",
        showBelowHeader: true,
      })
    );
    Event(
      getActiveProjectId(),
      "Tags",
      "Existing Tag",
      "Existing tag added to a Message"
    );
    openDropDown(false);
  };

  const tagInputFocused = () => {
    return tagInputRef.current
      ? tagInputRef.current === document.activeElement
      : false;
  };

  const doAddTag = (event) => {
    let classNameOfTagLi = liRef.current.className;
    return (
      classNameOfTagLi === DROPDOWN_TAG_CLASSNAME.ACTIVE &&
      !tagInputFocused() &&
      (isEnterKey(event) || (isSpaceKey(event) && !searchInputRef.current.value))
    );
  };

  const handleKeyDown = (event) => {
    if (doAddTag(event)) {
      event.preventDefault();
      addTagToMessage();
    }
  };

  const handleMouseOver = (event) => {
    addClassNameAndScrollTo(liRef.current, true);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    setTagKey(tag.key);
  }

  return (
    <li
      id={tag.key}
      className={DROPDOWN_TAG_CLASSNAME.DEFAULT}
      onClick={addTagToMessage}
      onKeyDown={ () => {} }
      onMouseOver={handleMouseOver}
      onFocus={ () => {} }
      ref={liRef}
      data-tip={toolTipValue}
      data-place="left"
      data-delay-hide="0"
    >
      <span ref={spanRef}>{tag.name}</span>
      <EditIcon class={'edit-tag-icon'} handleEditClick={handleEditClick} />
    </li>
  );
}

export default TagMessageDropdownList;

        