export const UPDATE_PEOPLE_SCROLL_ID = "UPDATE_PEOPLE_SCROLL_ID";
export const UPDATE_PEOPLE_QUERY = "UPDATE_PEOPLE_QUERY";
export const RESET_PEOPLE_ES_QUERY = "RESET_PEOPLE_ES_QUERY";
export const DELETE_PEOPLE_SCROLL_ID = "DELETE_PEOPLE_SCROLL_ID";
export const ADD_FILTER_REFERENCE = "ADD_FILTER_REFERENCE";
export const REMOVE_FILTER_REFERENCE = "REMOVE_FILTER_REFERENCE";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";

export const updatePeopleScrollId = (scroll_Id) => ({
  type: UPDATE_PEOPLE_SCROLL_ID,
  scroll_Id,
});

export const updatePeopleESQuery = (esObj) => ({
  type: UPDATE_PEOPLE_QUERY,
  query: esObj.query,
  scrollId: esObj.scrollId,
  groupId: esObj.groupId,
  references: esObj.references,
  filterType: esObj.filterType,
  isSingleRequested: esObj.isSingleRequested,
});

export const resetPeopleESQuery = () => ({
  type: RESET_PEOPLE_ES_QUERY,
});

export const addFilterReference = (referenceObj) => ({
  type: ADD_FILTER_REFERENCE,
  referenceObj,
});

export const removeFilterReference = (referenceObj) => ({
  type: REMOVE_FILTER_REFERENCE,
  referenceObj,
});

export const setFilterType = (filterType) => ({
  type: SET_FILTER_TYPE,
  filterType,
});
