import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
//import AcceptInvitationPopup from './AcceptInvitationPopup'
import Popup from "../components/common/Popup.jsx";
import * as PopupActions from "../actions/PopupAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";

@connect((state) => ({ popup: state.PopupReducer }))
class PopupContainer extends Component {
  constructor() {
    super();

    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onOk() {
    if (
      this.props.popup.popupProps.okButton &&
      this.props.popup.popupProps.okButton.action &&
      typeof this.props.popup.popupProps.okButton.action === "function"
    )
      this.props.popup.popupProps.okButton.action();
    else if (this.props.popup.popupProps.okButton.action)
      this.props.dispatch(this.props.popup.popupProps.okButton.action);
    if (this.props.popup.popupProps.closeOnOkButton) this.onClose();
  }

  onCancel() {
    if (
      this.props.popup.popupProps.cancelButton &&
      this.props.popup.popupProps.cancelButton.action &&
      typeof this.props.popup.popupProps.cancelButton.action === "function"
    )
      this.props.popup.popupProps.cancelButton.action();
    else if (this.props.popup.popupProps.cancelButton.action)
      this.props.dispatch(this.props.popup.popupProps.cancelButton.action);

    this.onClose();
  }

  onClose() {
    if (
      this.props.popup.popupProps.closeButton &&
      this.props.popup.popupProps.closeButton.action &&
      typeof this.props.popup.popupProps.closeButton.action === "function"
    )
      this.props.popup.popupProps.closeButton.action();
    this.props.dispatch(PopupActions.hidePopup());
    /* 		this.props.dispatch(VoiceboxActions.hideVoicebox());
     */
  }

  render() {
    console.info("Render method of popup container!");
    return (
      <Popup
        {...this.props.popup.popupProps}
        onOk={this.onOk}
        onCancel={this.onCancel}
        onClose={this.onClose}
      />
    );
  }
}

export default PopupContainer;
