import * as Yup from 'yup'

export const initialValues = { email: '' }

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Oops, email address doesn't look right")
    .required(),
})

export type IFormValues = typeof initialValues
