import React, { Component, Fragment } from "react";
import { CUSTOMER_NAME_SEARCH, SEARCH_LABEL } from "../../../commons/Constants";
import { CustomerIcon } from "../../../commons/Icons";
class CustomerNameSearch extends Component {
  constructor() {
    super();
  }

  renderTags() {
    let recentSearchHistory = [];
    recentSearchHistory.push(
      <Fragment>
        <CustomerIcon className={'filter-icon'} />
        <strong>{SEARCH_LABEL.CUSTOMER}</strong>
      </Fragment>
    );
    const inputField = document.querySelector(
      ".cs-my-conversations-filter input"
    );
    inputField.focus();
    inputField.placeholder = "";
    this.props.recentSearchHistory(recentSearchHistory, CUSTOMER_NAME_SEARCH);
  }

  render() {
    return <li id="customer-name-search" onClick={this.renderTags.bind(this)} onKeyDown={ () => {} }>
      <CustomerIcon />
    Customer</li>;
  }
}

export default CustomerNameSearch;
