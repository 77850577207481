import { combineReducers } from "redux";
import ChatConfigurationReducer from "../settings/reducers/ChatConfigurationReducer";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import ProjectReducer from "../settings/reducers/ProjectReducer";
import ConversationInfoReducer from "./ConversationInfoReducer";
import StaffReducer from "./StaffReducer";
import SignUpReducer from "./SignUpReducer";
import InvitationReducer from "./InvitationReducer";
import ChatPromptConfigurationReducer from "../settings/reducers/PromptConfigurationReducer";
import NotificationReducer from "./NotificationReducer";
import VoiceboxReducer from "./VoiceboxReducer";
import PopupReducer from "./PopupReducer";
import MessageReducer from "./MessageReducer";
import PeopleReducer from "./PeopleReducer";
import NotificationSettingsReducer from "./NotificationSettingsReducer";
import CustomFieldsReducer from "./CustomFieldsReducer";
import PeopleESQueryReducer from "./PeopleESQueryReducer";
import EventConfigurationReducer from "./EventConfigurationReducer";
import DeleteUserReducer from "./DeleteUserReducer";
import ProjectEventReducer from "./ProjectEventReducer";
import EventsActivityReducer from "./EventsActivityReducer";
import TagReducer from "./TagReducer";
import AttachmentReducer from "./AttachmentReducer";
import BroadcastMessageStatsReducer from "./BroadcastMessageStatsReducer";
import VideoChatReducer from "./VideoChatReducer";
import ExceptionReducer from "./ExceptionReducer";
import UrlPreviewReducer from "./UrlPreviewReducer";
import BroadcastEmailStatsReducer from "./BroadcastEmailStatsReducer";
import AuthTokenReducer from "./AuthTokenReducer";
import BroadcastESQueryReducer from "./BroadcastESQueryReducer";
import EmailOptOutReducer from "./EmailOptOutReducer";
import GlobalErrorReducer from "./GlobalErrorReducer";
import AdminReducer from "./AdminReducer";
import SetupReducer from "./SetupReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import NewMessageReducer from "./NewMessageReducer";
import PreviewReducer from "./PreviewReducer";
import ChatAnsweringReducer from "./ChatAnsweringReducer";
import VisitorQueueReducer from "./VisitorQueueReducer";
import WorkingHoursReducer from "../settings/reducers/WorkingHoursReducer";
import StaffContactReducer from "../components/myteam/Reducer/StaffContactReducer";
import UserProjectMappingReducer from "../components/myteam/Reducer/UserProjectMappingReducer";
import ConfirmPopupReducer from "./ConfirmPopupReducer"
import ConversationQueueReducer from "./ConversationQueueReducer";

export default combineReducers({
  AuthTokenReducer,
  SignUpReducer,
  LoginReducer,
  ChatConfigurationReducer,
  UserReducer,
  ProjectReducer,
  StaffReducer,
  InvitationReducer,
  ChatPromptConfigurationReducer,
  ConversationInfoReducer,
  NotificationReducer,
  VoiceboxReducer,
  PopupReducer,
  MessageReducer,
  PeopleReducer,
  NotificationSettingsReducer,
  CustomFieldsReducer,
  PeopleESQueryReducer,
  EventConfigurationReducer,
  DeleteUserReducer,
  ProjectEventReducer,
  EventsActivityReducer,
  TagReducer,
  AttachmentReducer,
  BroadcastMessageStatsReducer,
  VideoChatReducer,
  ExceptionReducer,
  UrlPreviewReducer,
  BroadcastEmailStatsReducer,
  BroadcastESQueryReducer,
  EmailOptOutReducer,
  GlobalErrorReducer,
  AdminReducer,
  ResetPasswordReducer,
  SetupReducer,
  NewMessageReducer,
  PreviewReducer,
  ChatAnsweringReducer,
  VisitorQueueReducer,
  WorkingHoursReducer,
  StaffContactReducer,
  UserProjectMappingReducer,
  ConfirmPopupReducer,
  ConversationQueueReducer,
});
