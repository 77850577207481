import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ArrowIcon } from "../../commons/Icons.js";
import { GROUP_ICONS, MAIN_GROUP_IDS } from "../commons/ContactConstants.js";
import { resetPeopleESQuery } from "../actions/SearchAction.js";

const Group = ({ main, groups, params, routeToGroup }) => {
  let [showSubGroups, setShowSubGroups] = useState(false);
  let mainGroup = groups.get(main) || {};
  let [Icon, ActiveIcon] = GROUP_ICONS[main];
  let { groupId: activeGroupId } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (groups.has(activeGroupId)) setShowSubGroups(true);
    else setShowSubGroups(false);
  }, [activeGroupId]);

  const getDefaultSubGroups = () => {
    let subGroups = [];
    groups.forEach((value, key, map) => {
      let group = map.get(key);
      if (key != mainGroup.key && group.defaultGroup)
        subGroups.push(
          <li
            onClick={() => changeGroup(key)}
            className={params.groupId == key ? "active" : ""}
          >
            <a>{group.groupName}</a>
            <span>{group.count}</span>
          </li>
        );
    });
    return subGroups;
  };

  const getSegments = () => {
    let segments = [];
    //Seperator not as a seperator element but handled in the class name of the first element. Speak to @Vinoth sengutuvan
    let seperatorAdded = false;
    groups.forEach((value, key, map) => {
      let group = map.get(key);
      if (key != mainGroup.key && !group.defaultGroup) {
        segments.push(
          <li
            onClick={() => changeGroup(key)}
            className={
              (!seperatorAdded ? "segment" : "") +
              " " +
              (params.groupId == key ? "active" : "")
            }
          >
            <a>{group.groupName}</a>
            <span>{group.count}</span>
          </li>
        );
        seperatorAdded = true;
      }
    });
    return segments;
  };

  const changeGroup = (key) => {
    dispatch(resetPeopleESQuery());
    routeToGroup(key);
  };

  return (
    <li
      className={
        (activeGroupId == mainGroup.key ? "active" : "") +
        " " +
        (showSubGroups ? "open" : "")
      }
    >
      <div onClick={() => changeGroup(mainGroup.key)}>
        <figure>
          <Icon className={"icon"} />
          <ActiveIcon className={"icon"} />
        </figure>
        <a href="javascript:void(0)" className="arrow">
          {mainGroup.groupName}
        </a>
        <span>{mainGroup.count}</span>
      </div>
      <ul>
        {getDefaultSubGroups()}
        {getSegments()}
      </ul>
    </li>
  );
};

export default Group;
