import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Group from "./Group.jsx";
import { GROUP_ORDERING_MAP } from "../commons/ContactConstants.js";
import { getOrderedMap } from "../commons/ContactsUtility.js";
import { browserHistory } from "react-router";
import { getPeopleGroupsRequest } from "../actions/ContactsAction.js";
import {
  deleteScrollId,
  updatePeopleScrollId,
} from "../actions/SearchAction.js";
import * as loaders from "../../settings/commons/loaders";

const Groups = (props) => {
  const {
    groups,
    params,
    contacts,
    query: { scrollId },
  } = props;
  const { leads, visitors, customers, companies } = groups;
  const { initialLoadComplete , isFetchingGroups, isGroupsFetched } = contacts;
  const { groupId: activeGroupId, projectkey } = params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (!isFetchingGroups && !isGroupsFetched) ||
      (contacts.groupsProjectId &&
        "LS-" + projectkey != contacts.groupsProjectId)
    ) {
      dispatch(getPeopleGroupsRequest("LS-" + projectkey));
    } else {
      console.log("tried to fetch when already fetching");
    }
  }, [projectkey]);

  const routeToGroup = (groupId) => {
    if (activeGroupId != groupId) {
      if (scrollId) {
        dispatch(updatePeopleScrollId(''));
      }
      browserHistory.push({
        pathname: `/app/${projectkey}/contacts/dashboard/${groupId}`,
      });
    }
  };

  const getLoader = () => {
    let groupLoaders = [];
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.SubgroupCardLoader());
    groupLoaders.push(loaders.SubgroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    return groupLoaders;
  };

  if (leads && initialLoadComplete) {
    return (
      <ul className="cs-accordian-menu">
        <Group
          main={"all-lead"}
          params={params}
          routeToGroup={routeToGroup}
          groups={getOrderedMap(leads, GROUP_ORDERING_MAP["all-lead"])}
        />
        <Group
          main={"all-visitor"}
          params={params}
          routeToGroup={routeToGroup}
          groups={getOrderedMap(visitors, GROUP_ORDERING_MAP["all-visitor"])}
        />
        <Group
          main={"all-user"}
          params={params}
          routeToGroup={routeToGroup}
          groups={getOrderedMap(customers, GROUP_ORDERING_MAP["all-user"])}
        />
        <Group
          main={"all-company"}
          params={params}
          routeToGroup={routeToGroup}
          groups={getOrderedMap(companies, GROUP_ORDERING_MAP["all-company"])}
        />
      </ul>
    );
  }

  return <ul className="cs-accordian-menu">{getLoader()}</ul>;
};

export default Groups;
