import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { config } from 'src/config'
import {
  initialValues,
  validationSchema,
} from 'src/features/authorization/forms/SignUp/data'
import { IProps } from 'src/features/authorization/forms/SignUp/types'
import { Button } from 'src/features/form/components/Button'
import { ErrorMessage } from 'src/features/form/components/ErrorMessage'
import { TextInput } from 'src/features/form/components/TextInput'
import { Link2, Text6, Text7 } from 'src/features/ui/components/Typography'

export const SignUp: FC<IProps> = ({
  handleSubmit,
  serverErrorMessage,
  isLoading,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={initialValues}
    validationSchema={validationSchema}
  >
    <Form>
      <TextInput
        name="emailId"
        label="Email"
        placeholder="Your email address"
      />
      <Margin top={39} />
      <TextInput
        type="password"
        name="password"
        label="Password"
        placeholder="Your password"
      />
      <Margin top={6} />
      <Text7 color="baliHai">
        Password must contain at least 6 characters, a minimum of one letter and
        at least one number.
      </Text7>
      <Margin top={20} />
      {Boolean(serverErrorMessage) && (
        <>
          <ErrorMessage message={serverErrorMessage!} />
          <Margin top={28} />
        </>
      )}
      <Button hasFullWidth color="primary" isLoading={isLoading} type="submit">
        Create an account
      </Button>
      <Margin top={16} />
      <Text6 color="baliHai">
        By clicking this button, you agree to our{' '}
        <Link2
          underline
          color="baliHai"
          href={`${config.chatsupportUrl}/terms`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </Link2>{' '}
        and{' '}
        <Link2
          color="baliHai"
          underline
          href={`${config.chatsupportUrl}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link2>
        .
      </Text6>
    </Form>
  </Formik>
)
