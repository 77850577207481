import React from "react";
import { useSelector } from "react-redux";
import { Link, browserHistory } from "react-router";
import { ACTIVE, BILLING, EMPTY_STRING, MANAGE_WIDGET, NOTIFICATIONS } from "../../commons/Constants";
import {
  BillingIcon,
  BillingIconActive,
  ManageWidgetActiveIcon,
  ManageWidgetIcon,
  NotificationsActiveIcon,
  NotificationsIcon,
} from "../../commons/Icons";
import {
  canShowBilling,
  getManageWidgetsPageUrl,
  getNotificationsPageUrl,
  getSettingsBillingPageUrl,
  getUserPermissionUtility,
} from "../../commons/Utility";

const MyAccountOptions = (props) => {
  const { handleSubMenu, params, location } = props;
  const { userReducer } = useSelector((state) => ({
    userReducer: state.UserReducer,
  }));

  const isManageWidgetActive = location.pathname.includes("manage/widget/yours")
    ? ACTIVE
    : EMPTY_STRING;
  const isNotificationActive = location.pathname.includes(
    "/settings/notifications"
  )
    ? ACTIVE
    : EMPTY_STRING;
  const isBillingActive = location.pathname.includes("/settings/billing")
    ? ACTIVE
    : EMPTY_STRING;

  return (
    <ul class="cs-nav-list">
      {getUserPermissionUtility(MANAGE_WIDGET) ? (
        <li
          data-id="chat_settings"
          className={isManageWidgetActive}
          onClick={(event) => {
            browserHistory.push(getManageWidgetsPageUrl(params.projectkey));
            handleSubMenu(event);
          }}
          onKeyDown={ () => {} }
        >
          <figure>
            <ManageWidgetIcon />
            <ManageWidgetActiveIcon />
          </figure>
          <Link
            data-id="chat_settings"
            to={getManageWidgetsPageUrl(params.projectkey)}
            onClick={handleSubMenu}
          >
            Manage Widgets
          </Link>
        </li>
      ) : (
        <span />
      )}
      {getUserPermissionUtility(NOTIFICATIONS) ? (
        <li
          className={isNotificationActive}
          onClick={(event) => {
            browserHistory.push(
              getNotificationsPageUrl(params.projectkey)
            );
            handleSubMenu(event);
          }}
          onKeyDown={ () => {} }
        >
          <figure>
            <NotificationsIcon />
            <NotificationsActiveIcon />
          </figure>
          <Link
            to={getNotificationsPageUrl(params.projectkey)}
            onClick={handleSubMenu}
          >
            Notifications
          </Link>
        </li>
      ) : (
        <span />
      )}
      {canShowBilling(userReducer) && getUserPermissionUtility(BILLING) ? (
        <li
          className={isBillingActive}
          onClick={() => {
            browserHistory.push(getSettingsBillingPageUrl(params.projectkey));
          }}
          onKeyDown={ () => {} }
        >
          <figure>
            <BillingIcon />
            <BillingIconActive />
          </figure>
          <Link to={getSettingsBillingPageUrl(params.projectkey)}>
            Billing History
          </Link>
        </li>
      ) : (
        <span />
      )}
    </ul>
  );
};

export default MyAccountOptions;
