export const GET_INVITATION_REQUEST = "GET_INVITATION_REQUEST";
export const GET_INVITATION_RESPONSE = "GET_INVITATION_RESPONSE";
export const GET_INVITATION_FAILED = "GET_INVITATION_FAILED";
export const ACCEPT_INVITATION_REQUEST = "ACCEPT_INVITATION_REQUEST";
export const ACCEPT_INVITATION_RESPONSE = "ACCEPT_INVITATION_RESPONSE";
export const ACCEPT_INVITATION_FAILED = "ACCEPT_INVITATION_FAILED";
export const DECLINE_INVITATION_REQUEST = "DECLINE_INVITATION_REQUEST";
export const DECLINE_INVITATION_RESPONSE = "DECLINE_INVITATION_RESPONSE";
export const DECLINE_INVITATION_FAILED = "DECLINE_INVITATION_FAILED";
export const SEND_INVITATION_REQUEST = "SEND_INVITATION_REQUEST";
export const SEND_INVITATION_RESPONSE = "SEND_INVITATION_RESPONSE";
export const SEND_INVITATION_FAILED = "SEND_INVITATION_FAILED";
export const GET_SIGNUP_DETAILS_REQUEST = "GET_SIGNUP_DETAILS_REQUEST";
export const GET_SIGNUP_DETAILS_RESPONSE = "GET_SIGNUP_DETAILS_RESPONSE";
export const GET_SIGNUP_DETAILS_FAILED = "GET_SIGNUP_DETAILS_FAILED";

export const getInvitationRequest = (invitationId) => ({
  type: GET_INVITATION_REQUEST,
  invitationId,
});

export const getInvitationResponse = (invitation) => ({
  type: GET_INVITATION_RESPONSE,
  invitation,
});

export const getInvitationFailed = (invitationId) => ({
  type: GET_INVITATION_FAILED,
  invitationId,
});

export const acceptInvitationRequest = (invitationId) => ({
  type: ACCEPT_INVITATION_REQUEST,
  invitationId,
});

export const declineInvitationRequest = (invitationId) => ({
  type: DECLINE_INVITATION_REQUEST,
  invitationId,
});

export const declineInvitationResponse = (invitation) => ({
  type: DECLINE_INVITATION_RESPONSE,
  invitation,
});

export const acceptInvitationResponse = (invitation) => ({
  type: ACCEPT_INVITATION_RESPONSE,
  invitation,
});

export const acceptInvitationFailed = (invitationId) => ({
  type: ACCEPT_INVITATION_FAILED,
  invitationId,
});

export const sendInvitationRequest = (invitationInfo) => ({
  type: SEND_INVITATION_REQUEST,
  invitationInfo,
});

export const sendInvitationResponse = (invitation) => ({
  type: SEND_INVITATION_RESPONSE,
  invitation,
});

export const sendInvitationFailed = (invitationInfo) => ({
  type: SEND_INVITATION_FAILED,
  invitationInfo,
});

export const getSignupDetailsRequest = (invitationId) => ({
  type: GET_SIGNUP_DETAILS_REQUEST,
  invitationId,
});

export const getSignupDetailsResponse = (invitation) => ({
  type: GET_SIGNUP_DETAILS_RESPONSE,
  invitation,
});

export const getSignupDetailsFailed = (invitationId) => ({
  type: GET_SIGNUP_DETAILS_FAILED,
  invitationId,
});
