import {
  VIDEO_LINK_REQUESTED,
  VIDEO_LINK_RESPONSE,
  VIDEO_LINK_FAILED,
  RESET_LINK,
} from "../actions/VideoChatAction.js";

const AttachmentReducer = (state = { isFetching: false }, action) => {
  switch (action.type) {
    case VIDEO_LINK_REQUESTED:
      return {
        ...state,
        isFetching: true,
        videoLink: "",
      };

    case VIDEO_LINK_RESPONSE:
      return {
        ...state,
        isFetching: false,
        videoLink: action.videoReq.room_link,
      };
    case VIDEO_LINK_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case RESET_LINK:
      return {
        ...state,
        videoLink: "",
      };
    default:
      return state;
  }
};
export default AttachmentReducer;
