export enum EnumArrowPosition {
  leftTop = 'leftTop',
  leftBottom = 'leftBottom',
}

export interface IProps {
  title: string
  description: string
  onClickNext?: VoidFunction
  onClickPrevious?: VoidFunction
  onClose?: VoidFunction
  arrowPosition: keyof typeof EnumArrowPosition
  position?: {
    left?: number
    top?: number
    right?: number
    bottom?: number
  }
}
