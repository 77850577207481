import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { isAuthenticated } from "../commons/Authenticator";
import {
  DEFAULT_PAGE_URL,
  NOTIFICATION_LEVEL,
  CLIENT_LOGIN_REFERENCE,
  SIGN_UP_ERROR_MESSAGE,
} from "../commons/Constants";
import * as SignUpActions from "../actions/SignUpAction";
import * as InvitationActions from "../actions/InvitationAction";
import * as LoginActions from "../actions/LoginAction";
import * as ProjectActions from "../settings/actions/ProjectAction";
import Onboard from "../components/Onboard.jsx";
import * as Utils from "../commons/Utility.js";
import * as NotificationsActions from "../actions/NotificationAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { STAGING_SSO_URL, LIVE_SSO_URL } from "../commons/Constants.js";
import axios from "axios";
import cookie from "react-cookie";

@connect((state) => ({
  signUp: state.SignUpReducer,
  login: state.LoginReducer,
  invitationReducer: state.InvitationReducer,
  projectReducer: state.ProjectReducer,
}))
class OnboardingContainer extends Component {
  constructor() {
    super();
    this.switchBreadCrumbs = this.switchBreadCrumbs.bind(this);
    this.addEmailBox = this.addEmailBox.bind(this);
    this.doSignup = this.doSignup.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
    this.onSkipInvite = this.onSkipInvite.bind(this);
    this.updateFirstName = this.updateFirstName.bind(this);
    this.passwordErrorMessageHandler =
      this.passwordErrorMessageHandler.bind(this);
    this.userNameValidationHandler= this.userNameValidationHandler.bind(this);
    this.isValidPassword = this.isValidPassword.bind(this);
    this.state = {
      signupInfo: {},
      currentBreadCrumb: "About",
      isSigningUp: false,
      isInviting: false,
      isInvitationSignup: false,
      error: {},
      emailInputCount: 3,
      invitationInfo: {},
      signedInAlready: false,
      isContact: false,
      clientToken: "",
      ssoToken: "",
      firstName: "",
      emailId: "",
      password: "",
      signupInfo: { accountName: "Widget", clientToken: "" },
    };
  }

  componentWillMount() {
    this.setState({
      ...this.setState,
      firstName: this.props.location.query.userName,
    });
    if (isAuthenticated()) {
      this.setState({ signedInAlready: true });
      this.redirectToDefaultPage();
    } else if (
      !this.props.location.query.inviteeId &&
      !this.props.signUp.leadData.id &&
      this.props.location.query.isContact === undefined
    ) {
      browserHistory.push({ pathname: "/login" });
    } else if (this.props.location.query.inviteeId) {
      this.setState({ isInvitationSignup: true, currentBreadCrumb: "About" });

      this.props.dispatch(
        InvitationActions.getSignupDetailsRequest(
          this.props.location.query.inviteeId
        )
      );
    } else if (this.props.location.query.isContact !== undefined) {
      if (
        this.props.location.query.isContact === "true" &&
        this.props.location.query.clientToken
      ) {
        let clientToken = this.props.location.query.clientToken;
        let firstName = this.props.location.query.userName;
        let emailId = this.props.location.query.email;
        let ssoToken = this.props.location.query.ssoToken;
        this.setState({
          isContact: true,
          clientToken,
          firstName,
          emailId,
          ssoToken,
        });
      } else if (
        this.props.location.query.isContact === "false" &&
        this.props.location.query.leadId
      ) {
        let firstName = this.props.location.query.userName;
        let leadId = this.props.location.query.leadId;
        let emailId = this.props.location.query.email;

        this.setState({ isContact: false, firstName, emailId });
        this.props.dispatch(
          SignUpActions.updateLeadResponse({
            id: leadId,
            email: emailId,
            password: "password123",
          })
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.invitationReducer.isFetching &&
      nextProps.invitationReducer.isFetched
    ) {
      if (
        nextProps.invitationReducer.invitation.invitationStatus == "PENDING"
      ) {
        this.setState({
          invitationInfo: nextProps.invitationReducer.invitation,
          isInvitationSignup: true,
        });
      } else {
        browserHistory.push({ pathname: "/login", search: "" });
      }
    } else if (
      this.props.signUp.isSettingUpAccount &&
      nextProps.signUp.hasSetUpAccount
    ) {
      cookie.save(CLIENT_LOGIN_REFERENCE, "true", { path: "/" });
      //REDIRECTING to login to other apps when google user setup the account
      let ssoUrl =
        Utils.getAppMode() == "live" ? LIVE_SSO_URL : STAGING_SSO_URL;
      window.location.href =
        ssoUrl +
        this.state.ssoToken +
        "?continue=" +
        Utils.getOrigin() +
        "/login?landingPath=/widget/embed/setup";
    } else if (this.props.signUp.isLoading && !nextProps.signUp.isLoading) {
      if (nextProps.signUp.isLoaded) {
        browserHistory.push({
          pathname: this.props.location.pathname,
          search: "",
        });

        this.props.dispatch(VoiceboxActions.hideVoicebox());
        //SeT token in cookie
        let clientToken =
          nextProps.signUp.data.data &&
          nextProps.signUp.data.data["X-Client-Token"]
            ? nextProps.signUp.data.data["X-Client-Token"]
            : "";
        let ssoToken =
          nextProps.signUp.data.data && nextProps.signUp.data.data.ssotoken
            ? nextProps.signUp.data.data.ssotoken
            : "";
        if (clientToken) {
          cookie.save(CLIENT_LOGIN_REFERENCE, "true", { path: "/" });
          let ssoUrl =
            Utils.getAppMode() == "live" ? LIVE_SSO_URL : STAGING_SSO_URL;
          window.location.href =
            ssoUrl +
            ssoToken +
            "?continue=" +
            Utils.getOrigin() +
            "/login?landingPath=/widget/embed/setup";
        } else {
          this.props.dispatch(
            LoginActions.loginRequest({
              loginId: this.state.signupInfo.emailId,
              password: this.state.signupInfo.password,
            })
          );
        }
      } else {
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: "Account creation Failed!",
            dismissAfter: 3000,
          })
        );
        browserHistory.push({ pathname: "/signup" });
      }
    }
  }

  componentDidMount() {
    this.child.refs.first_name.value=this.state.firstName;
    if (!this.state.isInvitationSignup)
      this.child.refs.first_name.focus();

    let chatIcon = document.getElementsByClassName("ConversationButton");

    if (chatIcon && chatIcon[0]) chatIcon[0].style.display = "none";
  }

  componentWillUnmount() {
    let chatIcon = document.getElementsByClassName("ConversationButton");

    if (chatIcon && chatIcon[0]) chatIcon[0].style.display = "block";
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentBreadCrumb == "Project" &&
      this.state.currentBreadCrumb == "About"
    )
      this.child.refs.password.focus();
    /* else if( prevState.currentBreadCrumb == "About" && this.state.currentBreadCrumb == "Invite" )
			this.child.refs.inviteeEmail_0.focus(); */

    if (this.state.emailInputCount > prevState.emailInputCount) {
      const node = ReactDOM.findDOMNode(this.child.refs.bottomElement);
      node.scrollIntoView({ behavior: "smooth" });
    }
  }

  componentWillUpdate(nextProps) {
    if (
      (!this.props.login.isLoggedIn && nextProps.login.isLoggedIn) ||
      (this.props.projectReducer.isFetching &&
        nextProps.projectReducer.isFetched)
    ) {
      const defaultProjKey =
        nextProps.projectReducer.projectList[0].key.replace("LS-", "");
      this.redirectToDefaultPage(defaultProjKey);
    }
  }

  setClientTokenAsCookie(clientToken) {
    console.info("Client Token before setting in cookie", clientToken);
    cookie.save(CLIENT_LOGIN_REFERENCE, "true", { path: "/" });

    this.props.dispatch(LoginActions.loginResponse());

    let tokenRemovedQuery = Object.assign({}, this.props.location.query);
    delete tokenRemovedQuery.clientToken;
    delete tokenRemovedQuery.isContact;
    delete tokenRemovedQuery.userName;
    delete tokenRemovedQuery.email;

    browserHistory.push({ query: tokenRemovedQuery });

    //this.redirectToDefaultPage();
  }

  redirectToDefaultPage(defaultProjKey) {
    if (
      !this.props.projectReducer.isFetched &&
      !this.props.projectReducer.isFetching
    ) {
      this.props.dispatch(ProjectActions.requestAvailableProject());
      console.info("I am the one");
    } else {
      if (this.state.signedInAlready && this.props.location.query.inviteeId)
        browserHistory.push({
          pathname: "/app/" + defaultProjKey + DEFAULT_PAGE_URL,
          search: "?inviteeId=" + this.props.location.query.inviteeId,
        });
      else browserHistory.push("/app/" + defaultProjKey + DEFAULT_PAGE_URL);
    }
  }

  updateFirstName(event) {
    this.setState({ firstName: event.target.value });
  }

  switchBreadCrumbs() {
    if (this.state.currentBreadCrumb == "Project") {
      let breadCrumb = "";
      let projectName = this.child.refs.project_name.value.trim();

      if (!this.isValidProjectName(projectName)) return;

      this.setState({
        signupInfo: {
          accountName: projectName,
          clientToken: this.state.clientToken,
        },
        currentBreadCrumb: "About",
      });
    } else if (this.state.currentBreadCrumb == "About") {
      const userName = Utils.splitFullName(
        Utils.capitalizeFirstLetter(this.child.refs.first_name.value.trim())
      );
      const { firstName, lastName } = userName;

      let invitation = this.props.invitationReducer.invitation;

      let isInvitationSignup = this.state.isInvitationSignup;

      let signupInfo = {};

      let emailId = isInvitationSignup
        ? invitation.emailId
        : this.props.signUp.leadData.email
        ? this.props.signUp.leadData.email
        : this.state.emailId;
      let password = isInvitationSignup
        ? this.child.refs.password.value
        : this.props.signUp.leadData.password
        ? this.props.signUp.leadData.password
        : this.state.password;
      let accountName = isInvitationSignup
        ? invitation.projectName
        : this.state.signupInfo.accountName;

      signupInfo = {
        ...this.state.signupInfo,
        firstName,
        lastName,
        password,
        emailId,
        accountName,
      };
      const isValidCredentials = () =>
        !this.isValidUserName(Utils.getFullName(userName)) ||
        !this.isValidPassword(password);

      if (isValidCredentials()) return;

      console.info("signupInfo=>", signupInfo);

      if (isInvitationSignup) {
        signupInfo["inviteeId"] = this.props.location.query.inviteeId;
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message:
              "Joining " +
              (invitation.projectName ? invitation.projectName : "widget"),
            showFullScreenLoader: true,
          })
        );
        this.doSignup(signupInfo);
        return;
      } else if (this.state.isContact) {
        /* this.setState( { signupInfo , currentBreadCrumb : "Invite" } ); */
      } else {
        signupInfo["leadId"] = this.props.signUp.leadData.id;
        /* this.setState( { signupInfo , currentBreadCrumb : "Invite" } ); */
      }
      if (this.state.isContact != true) {
        this.state.currentBreadCrumb = "Invite";
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: "Account Setup In Progress",
            showFullScreenLoader: true,
          })
        );
        this.doSignup(signupInfo);
      } else this.setupAccount(signupInfo);
    }
    /* else if( this.state.currentBreadCrumb == "Invite" ) {
			
			let inviteeEmails = [];
			let inputElements = ReactDOM.findDOMNode( this.child.refs.inviteeEmails ).getElementsByTagName( 'input' );
		
			for( var index in inputElements ) {
				if( inputElements[index].value && inputElements[index].value.trim().length != 0 ) {
					if( ! inviteeEmails.includes( inputElements[index].value.trim() )) 
						inviteeEmails.push( inputElements[index].value.trim() );
				}
			}
	
			if( ! this.isValidInviteeEmails( inviteeEmails ) ) {
				return;
			}
			
			let signupInfo = this.state.signupInfo ;
			
			if( inviteeEmails.length > 0 ) {
				signupInfo[ "inviteeEmails" ] = inviteeEmails;
 			}
			
			this.setState( { isInviting : true } )
			
			if( this.state.isContact != true )
				this.doSignup( signupInfo );
			else
				this.setupAccount( signupInfo );
			
		} */
  }

  setupAccount(accountInfo) {
    this.props.dispatch(SignUpActions.setupAccountRequest(accountInfo));
  }

  addEmailBox() {
    let count = this.state.emailInputCount;

    this.setState({ emailInputCount: count + 1 });
  }

  doSignup(signupInfo) {
    this.setState({ signupInfo: signupInfo, isSigningUp: true, error: {} });

    console.info("doSignup signupInfo:", signupInfo);

    this.props.dispatch(SignUpActions.signUpRequest(signupInfo));
  }

  onSkipInvite() {
    this.props.dispatch(
      VoiceboxActions.showVoicebox({ message: "processing..." })
    );
    let signupInfo = this.state.signupInfo;

    if (!this.state.isContact) this.doSignup(signupInfo);
    else this.setupAccount(signupInfo);
  }

  keyPressHandler(event) {
    if (Utils.isEnterKeyPressed(event)) 
      this.switchBreadCrumbs();
    else if (event && event.target) {
      this.userNameValidationHandler(event.target.value);
      this.passwordErrorMessageHandler(event.target.value);
    }
  }

  isValidProjectName(projectName) {
    let isNameEmpty = projectName.length == 0;
    let isValidName = Utils.isValidProjectName(projectName);

    if (!isNameEmpty && isValidName) {
      this.setState({ error: {} });
      return true;
    }

    let error = {
      projectName: "",
      firstName: "",
      lastName: "",
      password: "",
      inviteeEmails: "",
    };

    if (isNameEmpty)
      error = { ...error, projectName: "Project name can't be empty" };
    else if (!isValidName)
      error = {
        ...error,
        projectName: "Only alphabets, numbers, underscores and spaces allowed",
      };

    this.child.refs.project_name.focus();

    this.setState({ error });

    return false;
  }

  isValidAboutFields(firstName) {
    let isNameEmpty = firstName.length == 0;
    let isInvitationSignup = this.state.isInvitationSignup;
    let password,
      isPasswordEmpty,
      isLengthMinimum,
      exceededMaximum,
      isAlphaNumeric;

    if (isInvitationSignup) {
      password = this.child.refs.password.value.trim();
      isPasswordEmpty = password.length == 0;
      isLengthMinimum = password.length >= 6;
      exceededMaximum = password.length >= 64;
      isAlphaNumeric = Utils.isAlphaNumeric(password);
    }

    if (
      isInvitationSignup &&
      !isPasswordEmpty & isLengthMinimum & !exceededMaximum &&
      isAlphaNumeric
    ) {
      this.setState({ error: {} });
      return true;
    } else if (!isInvitationSignup && !isNameEmpty) {
      this.setState({ error: {} });
      return true;
    }

    let error = {
      projectName: "",
      firstName: "",
      lastName: "",
      password: "",
      inviteeEmails: "",
    };

    if (isNameEmpty && !isInvitationSignup)
      error = { ...error, firstName: "First Name can't be empty" };
    else if (isInvitationSignup && isPasswordEmpty)
      error = { ...error, password: "Password can't be empty" };
    else if (isInvitationSignup && !isLengthMinimum)
      error = {
        ...error,
        password: "Password should be a minimum of six characters",
      };
    else if (isInvitationSignup && !isAlphaNumeric)
      error = {
        ...error,
        password: "Password must contain atleast 1 number & 1 alphabet",
      };
    else if (isInvitationSignup && exceededMaximum)
      error = { ...error, password: "Password can't exceed 64 characters" };

    if (error.firstName) this.child.refs.first_name.focus();
    else this.child.refs.password.focus();

    this.setState({ error });

    return false;
  }

  userNameValidationHandler(userName) {
    const userNameRef = this.child.refs.first_name;
    if (Utils.isActiveElement(userNameRef)) this.isValidUserName(userName);
  }

  passwordErrorMessageHandler(password) {
    const passwordRef = this.child.refs.password;
    if (Utils.isActiveElement(passwordRef)) this.isValidPassword(password);
  }

  isValidUserName(userName) {
    const setUserNameErrorMessage = (frstName) =>
      this.setState(prevState => ({ ...prevState, error: { firstName: frstName } }));
    if (userName.length === 0) {
      setUserNameErrorMessage(SIGN_UP_ERROR_MESSAGE.USERNAME_EMPTY);
    } else if (Utils.isStringBeginOrEndWithSpace(userName)) {
      setUserNameErrorMessage(
        SIGN_UP_ERROR_MESSAGE.USERNAME_BEGIN_OR_END_WITH_SPACE
      );
    } else if (userName.length > 64) {
      setUserNameErrorMessage(
        SIGN_UP_ERROR_MESSAGE.USERNAME_MAXIMUM_LENGTH_SIXTY_FOUR
      );
    } else if (Utils.containsEmoji(userName)) {
      setUserNameErrorMessage(
        SIGN_UP_ERROR_MESSAGE.USERNAME_EMOJI
      );
    } else if (Utils.userNameRules(userName)) {
      setUserNameErrorMessage(
        SIGN_UP_ERROR_MESSAGE.USERNAME_SPECIAL_CHARACTER
      );
    } else if (!Utils.containsAlphabet(userName)) {
      setUserNameErrorMessage(
        SIGN_UP_ERROR_MESSAGE.USERNAME_ONE_LETTER
      );
    } else {
      setUserNameErrorMessage("");
      return true;
    }
    return false;
  }

  isValidPassword(password) {
    const setPasswordErrorMessage = (passwrd) =>
      this.setState(prevState => ({ ...prevState, error: { password: passwrd } }));
      if (password.length === 0) {
        setPasswordErrorMessage(SIGN_UP_ERROR_MESSAGE.PASSWORD_EMPTY);
      } else if (Utils.isStringBeginOrEndWithSpace(password)) {
        setPasswordErrorMessage(
          SIGN_UP_ERROR_MESSAGE.PASSWORD_BEGIN_OR_END_WITH_SPACE
        );
      } else if (password.length < 6) {
        setPasswordErrorMessage(SIGN_UP_ERROR_MESSAGE.PASSWORD_MINIMUM_LENGTH_SIX);
      } else if (password.length > 64) {
      setPasswordErrorMessage(SIGN_UP_ERROR_MESSAGE.PASSWORD_MAXIMUM_LENGTH_SIXTY_FOUR);
    } else if (!Utils.containsAlphabet(password)) {
      setPasswordErrorMessage(SIGN_UP_ERROR_MESSAGE.ATLEAST_ONE_LETTER);
    } else if (!Utils.containsNumbers(password)) {
      setPasswordErrorMessage(SIGN_UP_ERROR_MESSAGE.ATLEAST_ONE_NUMBER);
    } else {
      setPasswordErrorMessage("");
      return true;
    }
    return false;
  }

  isValidInviteeEmails(inviteeEmails) {
    let error = {
      projectName: "",
      firstName: "",
      lastName: "",
      password: "",
      inviteeEmails: "",
    };
    let allEmailsValid = true;
    let isEmailsToInviteEmpty = inviteeEmails.length == 0;

    for (var index in inviteeEmails) {
      if (!Utils.isValidEmail(inviteeEmails[index])) {
        allEmailsValid = false;
        break;
      } else if (inviteeEmails[index].trim().endsWith("a-cti.com")) {
        allEmailsValid = false;
        break;
      }
    }

    if (allEmailsValid && !isEmailsToInviteEmpty) {
      this.setState({ error: {} });
      return true;
    }

    if (!allEmailsValid)
      error = {
        ...error,
        inviteeEmails: "Oops, some of the emails doesn't look right",
      };
    else if (isEmailsToInviteEmpty)
      error = {
        ...error,
        inviteeEmails: "At least one mail id has to be provided",
      };

    this.setState({ error });

    return false;
  }

  render() {
    return (
      <Onboard
        ref={(component) => (this.child = component)}
        handlers={{
          switchCrumbs: this.switchBreadCrumbs,
          onSignup: this.doSignup,
          onKeyPress: this.keyPressHandler,
          addEmailBox: this.addEmailBox,
          onSkipInvite: this.onSkipInvite,
          onChange: this.updateFirstName,
        }}
        localState={this.state}
        location={this.props.location}
      />
    );
  }
}

export default OnboardingContainer;
