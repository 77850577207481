import React, { Component } from "react";
import ReactDom from "react-dom";
import { connect } from "react-redux";

import OverflowConfigurationComp from "./OverflowSettingsComp.jsx";
import { getProjectId, isValidObject } from "../../commons/Utility.js";
import * as ChatConfigActions from "../actions/ChatConfigurationAction.js";
import * as voiceboxActions from "../../actions/VoiceboxAction";
import { browserHistory } from "react-router";

@connect((state) => ({
  chatConfig: state.ChatConfigurationReducer,
  userDetails: state.UserReducer,
  staffDetails: state.StaffReducer,
}))
class OverflowConfigurationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatConfig: {},
      userDetails: {},
      isSuperAdmin: false,
      toggleDisconnectTimerDropDown: false,
    };

    this.toggleDisconnectTimerDropdown = this.toggleDisconnectTimerDropdown.bind(
      this
    );
    this.updateDisconnectTimer = this.updateDisconnectTimer.bind(this);
    this.toggleDisconnectTimer = this.toggleDisconnectTimer.bind(this);
    this.toggleOverFlow = this.toggleOverFlow.bind(this);
  }

  componentDidMount() {
    this.setDefaultStateFromProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userDetails.data &&
      nextProps.userDetails.data.isAdmin !== "true"
    )
      browserHistory.push({
        pathname: "/app/" + nextProps.params.projectkey + "/settings/",
      });
    else this.setDefaultStateFromProps(nextProps);
  }

  setDefaultStateFromProps = (props) => {
    let projectkey = getProjectId(props.params.projectkey);
    this.setState(
      {
        chatConfig: props.chatConfig.data[projectkey],
        userDetails: (props.userDetails && props.userDetails.data) || {},
      },
      () => {
        this.setState({
          ...this.state,
          isSuperAdmin: this.isSuperAdmin(),
        });
      }
    );
  };

  isSuperAdmin = () => {
    return isValidObject(this.state.userDetails)
      ? this.state.userDetails.isAdmin
      : false;
  };

  toggleOverFlow() {
    if (this.isOwner()) {
      this.props.dispatch(
        voiceboxActions.showVoicebox({ message: "Updating" })
      );
      this.setState(
        {
          ...this.state,
          chatConfig: {
            ...this.state.chatConfig,
            isOverFlowEnabled: !this.state.chatConfig.isOverFlowEnabled,
          },
        },
        () => {
          this.props.dispatch(
            ChatConfigActions.updateChatConfigRequest(this.state.chatConfig)
          );
        }
      );
    } else {
      this.props.dispatch(
        voiceboxActions.showVoicebox({
          message: "You should be an owner to change the settings",
          dismissAfter: 3000,
        })
      );
    }
  }

  toggleDisconnectTimer() {
    this.props.dispatch(voiceboxActions.showVoicebox({ message: "Updating" }));
    this.setState(
      {
        ...this.state,
        chatConfig: {
          ...this.state.chatConfig,
          endChatOnVisitorDisconnect: !this.state.chatConfig
            .endChatOnVisitorDisconnect,
        },
      },
      () => {
        this.props.dispatch(
          ChatConfigActions.updateChatConfigRequest(this.state.chatConfig)
        );
      }
    );
  }

  updateDisconnectTimer(e) {
    this.props.dispatch(voiceboxActions.showVoicebox({ message: "Updating" }));
    e.stopPropagation();
    if (this.isOwner()) {
      let timerValue = e.target.dataset.value;
      this.setState(
        {
          ...this.state,
          chatConfig: {
            ...this.state.chatConfig,
            autoDisconnectOverflowChatTimer: timerValue,
          },
          toggleDisconnectTimerDropDown: !this.state
            .toggleDisconnectTimerDropDown,
        },
        () => {
          this.props.dispatch(
            ChatConfigActions.updateChatConfigRequest(this.state.chatConfig)
          );
        }
      );
    } else {
      this.props.dispatch(
        voiceboxActions.showVoicebox({
          message: "You should be an owner to change the settings",
          dismissAfter: 3000,
        })
      );
    }
  }

  toggleDisconnectTimerDropdown() {
    this.setState({
      ...this.state,
      toggleDisconnectTimerDropDown: !this.state.toggleDisconnectTimerDropDown,
    });
  }

  isOwner = () => {
    let agentId =
      (this.props.userDetails &&
        this.props.userDetails.data &&
        this.props.userDetails.data.id) ||
      "";
    let staffs =
      this.props.staffDetails &&
      this.props.staffDetails.dashboardAgents &&
      this.props.staffDetails.dashboardAgents[
        getProjectId(this.props.params.projectkey)
      ];
    if (isValidObject(staffs) && agentId) {
      for (let staffId in staffs) {
        if (staffId === agentId) {
          return staffs[staffId].userPermission === "FULL_ACCESS";
        }
      }
    }
  };

  render() {
    let handlers = {
      toggleDisconnectTimerDropdown: this.toggleDisconnectTimerDropdown,
      updateDisconnectTimer: this.updateDisconnectTimer,
      toggleDisconnectTimer: this.toggleDisconnectTimer,
      toggleOverFlow: this.toggleOverFlow,
    };
    if (this.state.isSuperAdmin === "true")
      return (
        <OverflowConfigurationComp state={this.state} handlers={handlers} />
      );
    return "";
  }
}

export default OverflowConfigurationContainer;
