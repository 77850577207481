import React, { FC } from 'react'
import {
  ErrorContainer,
  IconContainer,
  Label,
} from 'src/features/form/components/ErrorMessage/styled'
import { IProps } from 'src/features/form/components/ErrorMessage/types'
import { Icon } from 'src/features/ui/components/Icon'

export const ErrorMessage: FC<IProps> = ({ message }) => (
  <ErrorContainer>
    <IconContainer>
      <Icon width={14} height={14} type="errorHollow" />
    </IconContainer>
    <Label>{message}</Label>
  </ErrorContainer>
)
