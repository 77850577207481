import React, { useState, useRef, useEffect } from "react";
import {
  EmailCampaingnWhiteIcon,
  AnnouncementCampaignWhiteIcon,
  ChatCampaignWhiteIcon,
  MoreOptionsIcon,
  EditIcon,
  DeleteIcon,
  PauseButton,
  ResumeButton,
} from "../../../app/commons/Icons";
import { browserHistory } from "react-router";

import { useDispatch } from "react-redux";
import { updateCampaignStatus } from "../../../app/actions/BroadcastMessageStatsAction";
import ReactTooltip from "react-tooltip";
import { CAMPAIGN_STATUS_IDS_TO_TEXTS, CAMPAIGN_STATUS_TO_ID, BROADCAST_TYPE } from "../../commons/Constants";

const CampaignRecord = (props) => {
  const {
    campaignName,
    campaignType,
    campaignStatus,
    sentData,
    openedData,
    clickedData,
    interactedData,
    notDeliveredData,
    projectkey,
    campaignId,
    campaignColor
  } = props;
  const { moreOptionsId, setMoreOptionsId } = props;

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const dispatch = useDispatch();
  const moreOptionRef = useRef();

  useEffect(() => {
    ReactTooltip.rebuild();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      ReactTooltip.hide();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      moreOptionRef &&
      moreOptionRef.current &&
      !moreOptionRef.current.contains(event.target)
    ) {
      setShowMoreOptions(false);
    }
  };

  const getIcon = () => {
    if (campaignType == "CHAT") return <ChatCampaignWhiteIcon />;
    else if (campaignType == "ANNOUNCEMENT")
      return <AnnouncementCampaignWhiteIcon />;
    else return <EmailCampaingnWhiteIcon />;
  };

  const onClickHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let { campaignkey, action } = event.target.dataset;
    let payload = {
      broadcastMessageEntityId: campaignkey,
      campaignStatus: action,
    };
    dispatch(updateCampaignStatus(payload));
    toggleMoreOptionsState(event);
  };

  const getMoreOptionsClass = () => {
    return showMoreOptions && moreOptionsId && moreOptionsId == campaignId
      ? "my-team-more-btn open"
      : "my-team-more-btn";
  };

  const toggleMoreOptionsState = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowMoreOptions(!showMoreOptions);
    if (!showMoreOptions) setMoreOptionsId(campaignId);
  };

  const getActionButton = () => {
    if (campaignStatus && campaignStatus === CAMPAIGN_STATUS_TO_ID.PAUSED) {
      return (
        <li
          onClick={onClickHandler}
          data-campaignKey={campaignId}
          data-action={CAMPAIGN_STATUS_TO_ID.LIVE}
        >
          <ResumeButton />
          Resume
        </li>
      );
    } else if (campaignStatus && campaignStatus === CAMPAIGN_STATUS_TO_ID.LIVE) {
      return (
        <li
          onClick={onClickHandler}
          data-campaignKey={campaignId}
          data-action={CAMPAIGN_STATUS_TO_ID.PAUSED}
        >
          <PauseButton />
          Pause
        </li>
      );
    }
  };

  const onSelect = () => {
    ReactTooltip.hide();

    browserHistory.push({
      pathname: `/app/${projectkey}/campaign/design/${campaignId}`,
    });
  };

  const onSelectStats = (stats) => {
    if (stats !== "notDelivered" || campaignType === "EMAIL") {
      ReactTooltip.hide();
      stats = window.btoa(stats);
      browserHistory.push({
        pathname: `/app/${projectkey}/campaign/${campaignId}/${stats}/${campaignType}`,
      });
    }
  };
  const getNotDelivered = ()=>{
    if (campaignType != BROADCAST_TYPE.EMAIL) {
      return "-";
    } else {
      return notDeliveredData + "%";
    }
  }
  return (
    <div className="campaigns-grid-list">
      <div style={{ cursor: "default" }}>
        <figure style={{ backgroundColor: campaignColor }}>{getIcon()}</figure>
        <span
          style={{ cursor: "pointer" }}
          data-class={
            campaignName && campaignName.trim().length > 25
              ? "tooltip info"
              : ""
          }
          data-tip={
            campaignName && campaignName.trim().length > 25 ? campaignName : ""
          }
          onClick={onSelect}
        >
          {campaignName}
        </span>
      </div>
      <div style={{ cursor: "default" }}>
        <span>{CAMPAIGN_STATUS_IDS_TO_TEXTS[campaignStatus]}</span>
      </div>
      <div >
        <span id="sent" style={{ cursor: "pointer" }} onClick={()=> onSelectStats("delivered")} onKeyDown={() => {}}>{sentData}</span>
      </div>
      <div >
        <span id="opened" style={{ cursor: "pointer" }} onClick={()=>onSelectStats("opened")} onKeyDown={() => {}}>{`${openedData}%`}</span>
      </div>
      <div >
        <span id="clicked" style={{ cursor: "pointer" }} onClick={()=>onSelectStats("clicked")} onKeyDown={() => {}}>{`${clickedData}%`}</span>
      </div>
      <div >
        <span id="replied" style={{ cursor: "pointer" }} onClick={()=>onSelectStats("replied")} onKeyDown={() => {}}>{`${interactedData}%`}</span>
      </div>
      <div style={ campaignType !== "EMAIL" ?{ cursor: "default" }: {cursor: "pointer"}}>
      <span id="notDelivered" onClick={()=>onSelectStats("notDelivered")} onKeyDown={() => {}}>{getNotDelivered()}</span>
      </div>
      <aside ref={moreOptionRef} className={getMoreOptionsClass()}>
        <a onClick={toggleMoreOptionsState}>
          <MoreOptionsIcon />
        </a>
        <ul className="edit-delete-container">
          <li onClick={onSelect} data-campaignKey={campaignId}>
            <EditIcon />
            Edit
          </li>
          {getActionButton()}
          <li
            onClick={onClickHandler}
            data-campaignKey={campaignId}
            data-action={CAMPAIGN_STATUS_TO_ID.DELETE}
            className="delete"
          >
            <DeleteIcon />
            Delete
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default CampaignRecord;
