import {
  ENQUEUE_CHAT,
  DEQUEUE_CHAT,
  FLUSH_QUEUE,
} from "../actions/ChatAnsweringAction";

const ChatAnsweringReducer = (
  state = { incomingChatsQueue: {}, answeringRing: {} },
  action
) => {
  switch (action.type) {
    case ENQUEUE_CHAT:
      let incomingChatsQueue = Object.assign({}, state.incomingChatsQueue);
      let chatAnsweringRing = Object.assign({}, state.answeringRing);

      incomingChatsQueue[action.incomingChatInfo.conversationId] =
        action.incomingChatInfo;

      if (Object.keys(chatAnsweringRing) == 0)
        chatAnsweringRing[action.incomingChatInfo.conversationId] =
          action.incomingChatInfo;

      return {
        ...state,
        incomingChatsQueue: incomingChatsQueue,
        answeringRing: chatAnsweringRing,
      };
    case DEQUEUE_CHAT:
      let incomingChats = Object.assign({}, state.incomingChatsQueue);
      let answeringRing = Object.assign({}, state.answeringRing);

      delete incomingChats[action.conversationId];
      delete answeringRing[action.conversationId];

      return {
        ...state,
        incomingChatsQueue: incomingChats,
        answeringRing: {},
      };
    case FLUSH_QUEUE:
      let incomingChat = Object.assign({}, state.incomingChatsQueue);
      let answerRing = Object.assign({}, state.answeringRing);
      let leavingVisitorId = action.visitorId;

      let incomingChatsIds = Object.keys(incomingChat);

      for (let index in incomingChatsIds) {
        let conversationId = incomingChatsIds[index];
        if (incomingChat[conversationId].visitorId == leavingVisitorId) {
          delete incomingChat[conversationId];
          delete answerRing[conversationId];
        }
      }

      return {
        ...state,
        incomingChatsQueue: incomingChat,
        answeringRing: answerRing,
      };
    default:
      return state;
  }
};

export default ChatAnsweringReducer;
