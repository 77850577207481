import React, { useEffect, useRef, useState } from "react";
import { ArrowIcon, BlueDownArrowSettings } from "../../../commons/Icons";
import {
  WIDGET_POSITIONS,
  WIDGET_POSITIONS_MAPPING,
} from "../../../commons/Constants";
import { useChatConfigurationEntity } from "../../hooks/useChatConfigurationEntity";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import { useUpdateChatConfigurationEntity } from "../../hooks/useUpdateChatConfigurationEntity";
import { useDispatch } from "react-redux";
import WidgetTextComponent from "./WidgetTextComponent.jsx";

const WidgetPosition = ({
  projectId,
  updateWidgetHeader,
  updateWidgetBubbleContent,
  widgetHeader,
  widgetBubbleContent,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [widgetPosition, setWidegetPosition] = useState("");
  const [cursor, setCursor] = useState(-1);
  const { chatConfiguration, isConfigFetched } = useChatConfigurationEntity(
    projectId
  );
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);
  const updateChatConfig = useUpdateChatConfigurationEntity();
  const dispatch = useDispatch();
  const dropDownRef = useRef(null);
  const positionRef = useRef(null);
  const positionsRef = useRef([]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("keydown", handleKeyDown);
    if (showDropDown && positionsRef.current[cursor])
      positionsRef.current[cursor].scrollIntoView({ block: "nearest" });
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [cursor, showDropDown]);

  useEffect(() => {
    let currentPosition =
      chatConfiguration.widgetPosition || WIDGET_POSITIONS[0];
    if (isConfigFetched) setWidegetPosition(currentPosition);
  }, [isConfigFetched]);

  const handleKeyDown = (e) => {
    if (!showDropDown) return;
    let isUpArrow = e.keyCode === 38 || e.which === 38;
    let isDownArrow = e.keyCode === 40 || e.which === 40;
    let isEscapeKey = e.keyCode === 27 || e.which === 27;
    let isSpaceBar = e.keyCode === 32 || e.which === 32;
    let isEnterKey = e.keyCode === 13 || e.which === 13;
    e.preventDefault();
    if (isUpArrow && cursor > 0) setCurrentCursor(cursor - 1);
    else if (isDownArrow && cursor < WIDGET_POSITIONS.length - 1)
      setCurrentCursor(cursor + 1);
    else if ((isEnterKey || isSpaceBar) && positionsRef.current[cursor]) {
      positionsRef.current[cursor].click();
      setShowDropDown(false);
      setCurrentCursor(0);
    }
    if (isEscapeKey) setShowDropDown(false);
  };

  const handleOutSideClick = () => {
    if (
      containsElement(dropDownRef, event.target) &&
      containsElement(positionRef, event.target)
    )
      setShowDropDown(false);
  };

  const containsElement = (elementRef, element) => {
    return (
      elementRef && elementRef.current && !elementRef.current.contains(element)
    );
  };
  const setCurrentCursor = (value) => {
    setCursor(value);
  };

  const getClassName = (index) => {
    if (index === cursor) return "select";
    return "";
  };

  const setRefs = (element) => {
    if (
      element &&
      positionsRef.current &&
      !positionsRef.current.includes(element)
    )
      positionsRef.current.push(element);
  };

  const handleHover = (e) => {
    if (cursor > -1 && cursor != e.target.dataset.index)
      setCurrentCursor(Number(e.target.dataset.index));
  };

  const getPositionList = () => {
    let positionList = [];
    let index = 0;
    for (let position in WIDGET_POSITIONS_MAPPING) {
      positionList.push(
        <li>
          <a
            onClick={() => updatePosition(position)}
            className={getClassName(index)}
            ref={setRefs}
            onMouseOver={handleHover}
            data-index={index}
          >
            {WIDGET_POSITIONS_MAPPING[position]}
          </a>
        </li>
      );
      index++;
    }

    return positionList;
  };

  const updatePosition = (position) => {
    toggleDropdown();
    setWidegetPosition(position);
    if (position != chatConfiguration.widgetPosition) {
      let chatConfig = Object.assign({}, chatConfiguration);
      chatConfig.widgetPosition = position;
      dispatch(showVoicebox({ message: "Updating" }));
      updateChatConfig({
        key: chatConfig.key,
        widgetPosition: chatConfig.widgetPosition,
      });
    }
  };

  const toggleDropdown = () => {
    setShowDropDown(!showDropDown);
    setCurrentCursor(0);
  };

  const toggleAdvanceSettings = () => {
    setShowAdvanceSettings(!showAdvanceSettings);
  };

  const getAdvanceSettingsText = () => {
    return showAdvanceSettings ? "Hide Advanced" : "Show Advanced";
  };

  return (
    <div
      className={
        showAdvanceSettings ? "widget-customise show" : "widget-customise"
      }
    >
      <a
        href="javascript:void(0)"
        className="advance-link"
        onClick={toggleAdvanceSettings}
      >
        {getAdvanceSettingsText()}
        <BlueDownArrowSettings />
      </a>
      <div class="cs-widget-style" style={{ margin: "0px" }}>
        <div className={showDropDown ? "cs-dropdwn open" : "cs-dropdwn"}>
          <label>Widget Position</label>
          <p onClick={toggleDropdown} ref={positionRef}>
            {WIDGET_POSITIONS_MAPPING[widgetPosition]}
          </p>
          <ArrowIcon />
          <div ref={dropDownRef}>
            <ul>{getPositionList()}</ul>
          </div>
        </div>
      </div>
      <WidgetTextComponent
        chatConfiguration={Object.assign({},chatConfiguration)}
        updateChatConfig={updateChatConfig}
        isConfigFetched={isConfigFetched}
        updateWidgetHeader={updateWidgetHeader}
        updateWidgetBubbleContent={updateWidgetBubbleContent}
        widgetBubbleContent={widgetBubbleContent}
        widgetHeader={widgetHeader}
        showAdvanceSettings={showAdvanceSettings}
      />
    </div>
  );
};

export default WidgetPosition;
