import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StyledButton = styled.button<{ disabled: boolean }>`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  font-family: ${R.path(['theme', 'font', 'family'])};
  color: ${R.path(['theme', 'color', 'dodgerBlue'])};
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 2rem 0 0;
  cursor: pointer;

  ${is('disabled')`
    opacity: 0.7;
    cursor: not-allowed;
  `}
`
