import React from "react";
import {LeadsEmptyIcon} from '../../commons/Icons'

const NoLeadsLoader = (props) => {
    return (
        <section class="placeholder-wrp">
            <div class="placeholder-content">
                <LeadsEmptyIcon />
                <label>No leads to display</label>
		        <p>Analyze lead information and convert them into customers.</p>
            </div>
        </section>
    )
}

export default NoLeadsLoader;