import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useLoginWithUrlParams } from 'src/features/authorization/hooks/useLoginWithUrlParams'
import { SIGN_UP_ROUTE } from 'src/pages/SignUp/data'
import { IProps } from 'src/utils/router/components/PrivateRouteGuard/types'

export const PrivateGuardedRoute: FC<IProps> = ({
  component: Component,
  isLoggedIn,
  ...rest
}) => {
  const hasLoggedIn = useLoginWithUrlParams()
  if (hasLoggedIn) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={SIGN_UP_ROUTE} />
      }
    />
  )
}
