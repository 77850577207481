import {
  WidgetPosition,
  WidgetType,
} from 'src/features/wizard/setup/store/types'

export interface ISetupResponseError {
  response: false
  exception: string // empty string
  message: string
}

export interface IWebsitePreviewDTO {
  domainName: string
  projectId: string
}

export interface IWebsitePreviewResponse {
  data: {
    websiteScreenshotURL: string
  }
}

export enum WidgetInstallationStatus {
  active = 'e3e20e2f-65cf-4155-9f0a-e1217433255b',
  inactive = 'e485563f-3838-4386-97af-ef11d00340a9',
}

export interface IWidgetConfigData {
  key: string
  createdDate: number
  modifiedDate: number
  status: string
  isBlockListingUrlEnabled: boolean
  isAllowUrlsEnabled: boolean
  blockedUrlsList: any[]
  allowedUrlsList: any[]
  isChatEnabled: boolean
  whiteListedDomain: any[]
  isWhiteListEnabled: boolean
  isAnonymousUserChatEnabled: boolean
  isNewMsgAssignedToAgents: boolean
  isExcludeStaffEnabled: boolean
  assignedAgentIds: any[]
  excludeStaffIds: any[]
  isAutoAssigned: boolean
  isOverFlowEnabled: boolean
  subAccountNumber: string
  overFlowSkill: string
  isDefaultAnswerPhraseEnabled: boolean
  defaultAnswerPhrase: string
  placeHolderText: string
  isLeadCaptureEnabled: boolean
  leadCaptureMessage: string
  isCSSurveyEnabled: boolean
  preChatSurveyStatus: string
  headerText: string
  widgetThemeColor: string
  autoDisconnectOverflowChatTimer: number
  isOutOfOfficeEnabled: boolean
  openTime: number
  closeTime: number
  openDays: any[]
  oooLeadCaptureGreeting: string
  oooleadCaptureAckMessage: string
  oooAnswerPhrase: string
  accountNumber: string
  endChatOnVisitorDisconnect: boolean
  fontColor: string
  chatWidgetIconURL: string
  emailTranscript: boolean
  transcriptEmailIds: any[]
  validateVisitorChatInTranscript: boolean
  domainName: string | null
  widgetPosition: WidgetPosition
  widgetType: WidgetType
  showFeedbackComment: boolean
  websiteScreenshotURL: string
  widgetHeaderText: string
  widgetBubbleContent: string
  postChatSurveyEnabled: boolean
  installationStatus?: WidgetInstallationStatus
}

export interface IGetWidgetConfigResponse {
  data: IWidgetConfigData
}

export interface IPutWidgetConfigResponse {
  data: IWidgetConfigData
}

export interface IPostStorageLocateResponse {
  data: {
    formParams: {
      policy: string
      signature: string
      key: string
      GoogleAccessId: string
      success_action_redirect: string
    }
    fileUrl: string
    id: string
    status: boolean
  }
  response: boolean
}

export interface IFullStorageResponse {
  ok: boolean
  data: {
    file: {
      id: string
      acctId: string
      userId: string
      slug: string
      title: string
      mimeType: string
      size: number
      fileType: string
      accessType: string
      users: [string]
      md5Hash: string
      imageInfo: {
        orientation: number
        width: number
        height: number
        ratio: number
      }
      expiresAt: number
      appId: 'chatsupport'
      createdAt: number
      updatedAt: number
      raw_url: string
      raw_url_download: string
      img_serve_url: string
      get_signed_url: string
    }
  }
}
