import React, { FC } from 'react'
import { defaultValues } from 'src/features/authorization/components/FreePlan/data'
import {
  Container,
  Item,
  Items,
  Label,
} from 'src/features/authorization/components/FreePlan/styled'
import { IProps } from 'src/features/authorization/components/FreePlan/types'
import { Icon } from 'src/features/ui/components/Icon'
import { SubTitle } from 'src/features/ui/components/Typography'

export const FreePlan: FC<IProps> = ({ items = defaultValues }) => (
  <Container>
    <SubTitle bold>Includes</SubTitle>
    <Items>
      {items.map((item) => (
        <Item key={item}>
          <Icon width={16} height={16} type="checkMark" fill="dodgerBlue" />
          <Label>{item}</Label>
        </Item>
      ))}
    </Items>
  </Container>
)
