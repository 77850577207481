import React, { FC } from 'react'

export const ServerSuccess: FC<any> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM15.8182 10.8275C16.2087 10.4369 16.2087 9.80378 15.8182 9.41325C15.4277 9.02273 14.7945 9.02273 14.404 9.41325L10.8333 12.9839L9.596 11.7466C9.20548 11.3561 8.57231 11.3561 8.18179 11.7466C7.79126 12.1371 7.79126 12.7703 8.18179 13.1608L10.1262 15.1052C10.5168 15.4958 11.1499 15.4958 11.5404 15.1052L15.8182 10.8275Z"
      fill="#00865A"
    />
  </svg>
)
