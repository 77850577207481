export const SETUP_REQUEST = "SETUP_REQUEST";
export const SETUP_RESPONSE = "SETUP_RESPONSE";
export const SETUP_FAILED = "SETUP_FAILED";
export const MAIL_CHAT_SCRIPT_REQUEST = "MAIL_CHAT_SCRIPT_REQUEST";
export const MAIL_CHAT_SCRIPT_RESPONSE = "MAIL_CHAT_SCRIPT_RESPONSE";
export const MAIL_CHAT_SCRIPT_FAILED = "MAIL_CHAT_SCRIPT_FAILED";
export const UPDATE_APP_VERSION = "UPDATE_APP_VERSION";

export const setupRequest = (projectId) => ({
  type: SETUP_REQUEST,
  projectId,
});

export const setupResponse = (setupState) => ({
  type: SETUP_RESPONSE,
  setupState,
});

export const setupFailed = () => ({
  type: SETUP_FAILED,
});

export const mailChatScriptRequest = (developerEmailId, projectId) => ({
  type: MAIL_CHAT_SCRIPT_REQUEST,
  developerEmailId,
  projectId,
});

export const updateAppVersion = (isBeta) => ({
  type: UPDATE_APP_VERSION,
  isBeta,
});
