import React from "react";
import { WhiteTickIcon } from "../../../commons/Icons";
import TimeInputFieldComponent from "./TimeInputFieldComponent.jsx";
import { HOURS_TYPE } from "../../../commons/Constants";

const EditDayComponent = (props) => {
  const getIsDisabled = () => {
    if (
      (props.day === "Saturday" || props.day === "Sunday") &&
      props.hoursType === HOURS_TYPE.weekdays
    )
      return true;
    return false;
  };

  const getFormattedTime = (min) => {
    min = min ? min : 0;
    let hours = Math.floor(min / 60);
    let amOrPm = hours >= 12 ? " PM" : " AM";
    hours = hours % 12 == 0 ? 12 : hours % 12;
    let minutes = min % 60;
    return (
      (("" + hours).length < 2 ? "0" + hours : hours) +
      ":" +
      (("" + minutes).length < 2 ? "0" + minutes : minutes) +
      amOrPm
    );
  };

  const handleTimeChage = (e) => {
    let type = e.target.dataset.type;
    let value = e.target.dataset.value;
    let day = e.target.dataset.day;
    props.updateTime(type, value, day);
  };
  return (
    <li className={getIsDisabled() ? "disable" : ""}>
      <div class="cs-checkbox-field">
        <label>
          <WhiteTickIcon />
          <input
            type="checkbox"
            name="checkbox"
            id={props.value}
            disabled={getIsDisabled()}
            checked={props.isChecked ? true : false}
            onChange={props.selectDay}
            data-id={props.value}
          />
          <cite></cite>
          {props.day}
        </label>
      </div>
      <TimeInputFieldComponent
        time={getFormattedTime(props.startTime)}
        type="startTime"
        handleTimeChange={handleTimeChage}
        day={props.value}
        hoursType={props.hoursType}
        timeInMin={props.startTime}
      />
      <TimeInputFieldComponent
        time={getFormattedTime(props.endTime)}
        startTime={props.startTime}
        type="endTime"
        handleTimeChange={handleTimeChage}
        day={props.value}
        hoursType={props.hoursType}
        timeInMin={props.endTime}
      />
    </li>
  );
};

export default EditDayComponent;
