import styled from 'styled-components'
import is from 'typescript-styled-is'

export const Container = styled.div`
  position: relative;
  width: fit-content;
`

export const PreviewButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
`

export const PreviewContainer = styled.div`
  position: relative;
`

export const WidgetContainer = styled.div<{
  topLeft?: boolean
  topRight?: boolean
  bottomLeft?: boolean
  bottomRight?: boolean
}>`
  position: absolute;

  ${is('topLeft')`
    top: 0;
    left: 0;
  `}

  ${is('topRight')`
    top: 0;
    right: 0;
  `}

  ${is('bottomLeft')`
    bottom: 0;
    left: 0;
  `}

  ${is('bottomRight')`
    bottom: 0;
    right: 0;
  `}
`
