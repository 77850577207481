import React, { FC } from 'react'
import {
  Container,
  Label,
  StyledImage,
} from 'src/features/wizard/install/components/PluginInstall/PluginButton/styled'
import { IProps } from 'src/features/wizard/install/components/PluginInstall/PluginButton/types'

export const PluginButton: FC<IProps> = ({
  image,
  label,
  isActive,
  onClick,
}) => (
  <Container onClick={onClick} isActive={isActive}>
    <StyledImage src={image.src} $width={image.width} $height={image.height} />
    <Label>{label}</Label>
  </Container>
)
