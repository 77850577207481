import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router";

class OptedOutAll extends Component {
  render() {
    console.info("OptedOutAll Email Props:", this.props);
    let {
      optBackInAndRestorePreferences,
      localState: { emailId, hasFetchedPreferences },
    } = this.props;

    return (
      <section
        className="optOutAllSection"
        style={{ display: hasFetchedPreferences ? "block" : "none" }}
      >
        <h3 className="optOutEmail">{emailId}</h3>
        <div className="formSection fadeIn">
          <div
            className="formField"
            style={{ textAlign: "center", paddingTop: "40px", border: "0px" }}
          >
            <h3>
              <b style={{ paddingTop: "20px", fontSize: "20px" }}>
                You will no longer receive any emails from us.{" "}
              </b>
            </h3>
          </div>
          <div
            className="formField"
            style={{ textAlign: "center", paddingTop: "20px", border: "0px" }}
          >
            <button
              onClick={optBackInAndRestorePreferences}
              className="blueBtn"
            >
              Opt In & Restore Preferences
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default OptedOutAll;
