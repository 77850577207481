import React, { FC } from 'react'
import { Text1, Highlight } from 'src/features/ui/components/Typography'
import { PluginGeneric } from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginGeneric'

export const PluginGoogle: FC = () => (
  <PluginGeneric
    steps={[
      <React.Fragment key={1}>
        <Text1 color="raven">
          Go to your Google Tag Manager dashboard and select{' '}
          <Highlight>New Tag</Highlight>.
        </Text1>
      </React.Fragment>,
      <React.Fragment key={2}>
        <Text1 color="raven">
          Continue by choosing <Highlight>Tag Configuration</Highlight> and then
          the <Highlight>Custom HTML</Highlight> option.
        </Text1>
      </React.Fragment>,
      <React.Fragment key={3}>
        <Text1 color="raven">
          Copy the copy found under{' '}
          <Highlight>Install widget manually</Highlight>
        </Text1>
      </React.Fragment>,
      <React.Fragment key={4}>
        <Text1 color="raven">
          Paste the ChatSupport code into the HTML field and click Save
        </Text1>
      </React.Fragment>,
      <React.Fragment key={5}>
        <Text1 color="raven">
          Once the code has been pasted, go to the{' '}
          <Highlight>Triggering</Highlight> section, select{' '}
          <Highlight>All Pages</Highlight> and click <Highlight>Save</Highlight>
          .
        </Text1>
      </React.Fragment>,
      <React.Fragment key={5}>
        <Text1 color="raven">
          Name your tag and click <Highlight>Submit</Highlight> to complete the
          installation.
        </Text1>
      </React.Fragment>,
    ]}
  />
)
