import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { WidgetSetupContainer } from 'src/features/returningFlow/components/Wizard/styled'
import { SubTitle, Text1 } from 'src/features/ui/components/Typography'
import { DesktopOnly } from 'src/features/ui/components/Visibility'
import { WebsitePreview } from 'src/features/wizard/setup/components/WebsitePreview'
import { SetupForm } from 'src/features/wizard/setup/forms/Setup'
import {
  IFormValues as ISetupFormValues,
  IFormValues,
} from 'src/features/wizard/setup/forms/Setup/data'
import {
  EnumWidgetUpdateType,
  requestUpdateWidget,
} from 'src/features/wizard/setup/store/actions'
import { selectIsUpdatingWidget } from 'src/features/wizard/setup/store/selectors'
import { IAppState } from 'src/utils/state/store'

const WidgetSetupContentComponent: FC<{
  updateWidget: (values: IFormValues, type?: EnumWidgetUpdateType) => void
  isUpdating: boolean
}> = ({ updateWidget, isUpdating }) => {
  const handleSubmitSetup = (values: ISetupFormValues) => {
    updateWidget(values, EnumWidgetUpdateType.returningFlow)
  }

  return (
    <>
      <SubTitle bolderer>Personalize your widget.</SubTitle>
      <Margin top={4} />
      <Text1 color="raven">
        Customize your widget to maximize engagement and offer an on-brand
        experience with every chat.
      </Text1>
      <Margin top={40} />
      <WidgetSetupContainer>
        <SetupForm
          handleSubmit={handleSubmitSetup}
          isLoading={isUpdating}
          shouldHideSkipButton
          buttonLabel={{
            mobile: 'Save',
            desktop: 'Save',
          }}
          isMoreAlwaysVisible
          formFooterMarginTop={4}
          maxSubmitButtonWidth={16.8}
        />
        <DesktopOnly>
          <WebsitePreview maxWidth={46} />
        </DesktopOnly>
      </WidgetSetupContainer>
    </>
  )
}

const mapStateToProps = (state: IAppState) => ({
  isUpdating: selectIsUpdatingWidget(state.setup),
})

const mapDispatchToProps = {
  updateWidget: requestUpdateWidget,
}

export const WidgetSetupContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetSetupContentComponent)
