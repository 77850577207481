import React from "react"
import { browserHistory } from "react-router";

const SearchWrapperComponent = (props) => {
    let pathName = document.location.pathname;
    let chatsectionState = pathName.split("/")[4];
    pathName = pathName.replace(chatsectionState, "dashboard");
    browserHistory.push({
      pathname: pathName,
      state: props.location.state,
    });
    return <></>;
};

export default SearchWrapperComponent;