import React from "react";
import useProperties from "../hooks/useProperties.js";

const TagFilter = (props) => {
  const {
    params: { projectkey },
    tagSearch = "",
    onTagSelection,
    setShowTagFilter,
    showTagFilter,
  } = props;
  const { tags } = useProperties(projectkey);
  const tagObjs = Object.values(tags);

  const onSelect = (key, name) => {
    onTagSelection(key, name);
    setShowTagFilter(false);
  };

  const getTags = () => {
    let tagsList = [];
    tagObjs.forEach((tag) => {
      if (!tagSearch)
        tagsList.push(
          <li>
            <a
              key={tag.key}
              onClick={() => {
                onSelect(tag.key, tag.name);
              }}
              href="javascript:void(0)"
              className="active"
            >
              {tag.name}
            </a>
          </li>
        );
      else if (tagSearch && tag.name.toLowerCase().includes(tagSearch))
        tagsList.push(
          <li>
            <a
              key={tag.key}
              onClick={() => {
                onSelect(tag.key, tag.name);
              }}
              href="javascript:void(0)"
              className="active"
            >
              {tag.name}
            </a>
          </li>
        );
    });
    return tagsList;
  };

  return (
    <div class={"cs-dropdwn-checkbox-sub " + (showTagFilter ? "db" : "dn")}>
      <p>Tags</p>
      <ul>{getTags()}</ul>
    </div>
  );
};

export default TagFilter;
