import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Text1, Text4 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Options = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.sm} {
    flex-direction: row;
  }
`

export const OptionContainer = styled.label`
  display: flex;

  & + & {
    margin-top: 1.8rem;
  }

  ${mq.sm} {
    flex-direction: row;

    & + & {
      margin-top: unset;
      margin-left: 2.4rem;
    }
  }
`

export const OptionLabel = styled(Text4)<{ checked: boolean }>`
  display: block;
  color: ${R.path(['theme', 'color', 'codGray'])};
  position: relative;
  padding-left: 3rem;

  &::placeholder {
    color: ${R.path(['theme', 'color', 'casper'])};
    font-family: ${R.path(['theme', 'font', 'family'])};
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0.1rem;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
    border: 0.1rem solid ${R.path(['theme', 'color', 'dodgerBlue'])};
    box-sizing: border-box;
  }

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    left: 0.5rem;
    top: 0.6rem;
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
    transition: background-color 200ms ease-in-out;
  }

  ${is('checked')`
    &::after {
      background: ${R.path(['theme', 'color', 'dodgerBlue'])};
    }
  `}
`

export const Label = styled(Text1).attrs({ as: 'label' })`
  margin-bottom: 1.6rem;
`

export const Input = styled.input`
  padding: 0.4rem 0;
  border: none;
  border-bottom: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
  color: ${R.path(['theme', 'color', 'codGray'])};
  font-size: 1.6rem;
  line-height: 1.9rem;
  border-radius: 0;
  opacity: 0;
  position: absolute;
`
