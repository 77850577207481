import React, { FC, useRef } from 'react'
import {
  Container,
  Image,
  ImageButton,
} from 'src/features/form/components/ImageInput/ImageSelection/styled'
import { IProps } from 'src/features/form/components/ImageInput/ImageSelection/types'
import { useClickOutside } from 'src/utils/hooks/useClickOutside'

export const ImageSelection: FC<IProps> = ({
  selectedUrl,
  imageSelection,
  selectImage,
  onClickOutside,
}) => {
  const containerRef = useRef(null)
  useClickOutside(containerRef, onClickOutside)

  return (
    <Container ref={containerRef}>
      {imageSelection.map((imageUrl) => (
        <ImageButton
          type="button"
          isSelected={selectedUrl === imageUrl}
          key={imageUrl}
          onClick={() => selectImage(imageUrl)}
        >
          <Image
            isSelected={selectedUrl === imageUrl}
            backgroundUrl={imageUrl}
          />
        </ImageButton>
      ))}
    </Container>
  )
}
