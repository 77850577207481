import {
  INCREMENT_NEW_MESSAGE_COUNT,
  DECREMENT_NEW_MESSAGE_COUNT,
  CLEAR_NEW_MESSAGE_COUNT,
  CLEAR_ALL_NEW_MESSAGE_COUNT,
  SCROLL_TO_NEW_MESSAGE,
} from "../actions/NewMessageAction";

const NewMessageReduer = (state = { data: {} }, action) => {
  let conversationId = action.conversationId;
  let newMessageInfo = Object.assign({}, state.data[conversationId]);
  let messageIds =
    newMessageInfo && newMessageInfo.messageIds
      ? newMessageInfo.messageIds
      : [];
  let newMessageData = {};

  switch (action.type) {
    case INCREMENT_NEW_MESSAGE_COUNT:
      messageIds.push(action.messageId);
      newMessageData = Object.assign({}, state.data);
      newMessageData[conversationId] = { messageIds };
      return {
        ...state,
        data: Object.assign({}, newMessageData),
      };

    case DECREMENT_NEW_MESSAGE_COUNT:
      messageIds.splice(messageIds.indexOf(action.messageId), 1);
      newMessageData = Object.assign({}, state.data);
      newMessageData[conversationId] = { messageIds };
      return {
        ...state,
        data: Object.assign({}, newMessageData),
      };

    case CLEAR_NEW_MESSAGE_COUNT:
      newMessageData = Object.assign({}, state.data);
      delete newMessageData[conversationId];
      return {
        ...state,
        data: Object.assign({}, newMessageData),
      };

    case CLEAR_ALL_NEW_MESSAGE_COUNT:
      return {
        ...state,
        data: {},
      };

    case SCROLL_TO_NEW_MESSAGE:
      let messageToScrollTo = messageIds[0];
      newMessageData = Object.assign({}, state.data);
      newMessageData[conversationId] = { messageIds, messageToScrollTo };

      return {
        ...state,
        data: Object.assign({}, newMessageData),
      };
  }

  return state;
};

export default NewMessageReduer;
