import * as R from 'ramda'
import { EnumStatus } from 'src/features/wizard/install/forms/TestConnection/types'
import {
  IEmailInstructions,
  IInstallState,
} from 'src/features/wizard/install/store/types'

export const selectIsTestingConnection = (state: IInstallState) =>
  state.testConnectionResponse.isLoading

export const selectTestingConnectionStatus = (state: IInstallState) => {
  if (state.testConnectionResponse.ok === true) {
    return EnumStatus.success
  }

  if (state.testConnectionResponse.ok === false) {
    return EnumStatus.error
  }

  return undefined
}

export const selectIsTestingConnectionOk = (state: IInstallState) =>
  Boolean(state.testConnectionResponse.ok)

export const selectEmailingErrorById = (id: number) => (state: IInstallState) =>
  R.find<IEmailInstructions>(R.propEq('id', id), state.emailInstructions)?.error

export const selectIsEmailingById = (id: number) => (state: IInstallState) =>
  R.find<IEmailInstructions>(R.propEq('id', id), state.emailInstructions)
    ?.isLoading

export const selectDidSendEmailById = (id: number) => (state: IInstallState) =>
  R.find<IEmailInstructions>(R.propEq('id', id), state.emailInstructions)
    ?.ok !== undefined

export const hasTestConnectionPassed = (state: IInstallState) =>
  state.testConnectionResponse.ok

export const hasEmailInvitePassed = (state: IInstallState) =>
  state.emailInstructions.reduce((hasOnePassed, emailInstructions) => {
    if (hasOnePassed) {
      return hasOnePassed
    }

    return emailInstructions.ok
  }, false)

export const hasFinishedInstall = (state: IInstallState) =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  hasTestConnectionPassed(state) || hasEmailInvitePassed(state)

export const hasLastInstructionsValue = (state: IInstallState) =>
  Boolean(R.last(state.emailInstructions)?.value)

export const selectWasConnectionTested = (state: IInstallState) =>
  Boolean(selectTestingConnectionStatus(state))

export const selectDomainUrl = (state: IInstallState) => state.domainUrl
