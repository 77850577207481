/* eslint-disable complexity */
import { initialValues as initialFormValues } from 'src/features/wizard/setup/forms/Setup/data'
import * as actions from 'src/features/wizard/setup/store/actions'
import { EnumStatus, ISetupState } from 'src/features/wizard/setup/store/types'
import { addListItem, removeListItem } from 'src/utils/state/operators'

export const initialState = {
  status: [],
  iconUrls: [
    'https://app.chatsupport.co/images/user1.png',
    'https://app.chatsupport.co/images/user2.png',
    'https://app.chatsupport.co/images/user3.png',
    'https://app.chatsupport.co/images/user4.png',
    'https://app.chatsupport.co/images/user5.png',
    'https://app.chatsupport.co/images/user6.png',
    'https://app.chatsupport.co/images/user7.png',
    'https://app.chatsupport.co/images/user8.png',
  ],
  values: initialFormValues,
  websiteUrl: undefined,
  previewUrl: undefined,
  error: undefined,
}

export const setupReducer = (
  state: ISetupState = initialState,
  action: actions.ISetupActions
) => {
  switch (action.type) {
    case actions.UPDATE_VALUES:
      return {
        ...state,
        values: action.payload,
      }
    case actions.REQUEST_UPLOAD_ICON:
      return {
        ...state,
        status: addListItem(EnumStatus.uploadingIcon, state.status),
      }
    case actions.REQUEST_UPLOAD_ICON_SUCCESS:
      return {
        ...state,
        status: removeListItem(EnumStatus.uploadingIcon, state.status),
        iconUrls: addListItem(action.payload.iconUrl, state.iconUrls),
        values: {
          ...state.values,
          icon: {
            color: action.payload.iconColor,
            url: action.payload.iconUrl,
          },
        },
      }
    case actions.REQUEST_UPDATE_PREVIEW:
      return {
        ...state,
        status: addListItem(EnumStatus.updatingPreview, state.status),
        websiteUrl: action.payload.websiteUrl,
      }
    case actions.REQUEST_UPDATE_PREVIEW_SUCCESS:
      return {
        ...state,
        status: removeListItem(EnumStatus.updatingPreview, state.status),
        previewUrl: action.payload.previewUrl,
      }
    case actions.REQUEST_UPDATE_WIDGET:
      return {
        ...state,
        values: action.payload.values,
        status: addListItem(EnumStatus.updatingWidget, state.status),
      }
    case actions.REQUEST_UPDATE_WIDGET_SUCCESS:
      return {
        ...state,
        status: removeListItem(EnumStatus.updatingWidget, state.status),
      }
    case actions.INITIALIZE:
      return {
        ...state,
        previewUrl: action.payload.previewUrl,
        iconUrls: action.payload.iconUrl
          ? [...initialState.iconUrls, action.payload.iconUrl]
          : initialState.iconUrls,
        values: {
          ...action.payload.formValues,
          icon: {
            color: action.payload.iconColor,
            url: action.payload.iconUrl,
          },
        },
      }
    case actions.SET_ERROR:
      return {
        ...state,
        status: [],
        error: action.payload,
      }
    default:
      return state
  }
}
