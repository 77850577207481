import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import { removeTagFromMessageRequest } from "../../../actions/TagActions";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import { TagSearchIcon } from "../../../commons/Icons";
import { getActiveProjectId } from '../../../commons/Utility.js';

function MessageTagItem({ tagKey, tagName, removeOption, payload }) {
  const dispatch = useDispatch();
  const spanRef = useRef("");
  const [toolTipValue, setToolTipValue] = useState("");
  const maxWidthOfSpan = 150;

  useEffect(() => {
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  useEffect(() => {
    setToolTipValue(
      spanRef.current.offsetWidth == maxWidthOfSpan ? tagName : ""
    );
  });

  const removeTag = () => {
    dispatch(removeTagFromMessageRequest(...payload, tagKey));
    Event(getActiveProjectId(), "Tags", "Remove Message Tag", "Tag removed from message");
  };

  return (
      <li
        key={tagKey}
        id={tagKey}
        data-tip={toolTipValue}
        data-delay-hide="0"
        onMouseLeave={() => {
          ReactTooltip.hide();
        }}
      >
        <TagSearchIcon className={'translateY-1'} />
        <span ref={spanRef}>{tagName}</span>
        {removeOption ? (
          <i className="message-tags-remove" onClick={removeTag} onKeyDown={ () => {} }>
            &times;
          </i>
        ) : (
          ""
        )}
      </li>
  );
}

export default MessageTagItem;
