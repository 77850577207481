import React, { useEffect, useRef } from "react";
import { Link } from "react-router";
import MapComponent from "../../components/chat/MapComponent.jsx";
import { useState } from "react";
import { EditIcon, DeleteIcon } from "../../commons/Icons.js";
import EditProfileComponent from "./EditProfileComponent.jsx";
import MyProfileComponent from "./MyProfileComponent.jsx";
import NotificationSettingsComponent from "../notifications/NotificationSettingsComponent.jsx";
import EditWorkingHoursComponent from "./workingHours/EditWorkingHoursComponent.jsx";
import CropperComponent from "./CropperComponent.jsx";
import { ProfileNameLoader } from "../commons/loaders.js";

const OuterProfileComponent = (props) => {
  const { userProps, locationProps, imageProps } = props;
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const node = useRef(null);
  let latitude = locationProps.currentLocation
    ? locationProps.currentLocation.latitude
    : "";
  let longitude = locationProps.currentLocation
    ? locationProps.currentLocation.longitude
    : "";

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = () => {
    if (!(node && node.current && node.current.contains(event.target)))
      setShowUploadOptions(false);
    console.log("got clicked " + showUploadOptions);
  };

  const getClassName = () => {
    let name = "";
    if (
      document.location.pathname.includes("edit") &&
      !document.location.pathname.includes("workinghours")
    ) {
      name += showUploadOptions
        ? "cs-profile cs-profile-edit open"
        : "cs-profile cs-profile-edit";
      if (name.includes("open")) console.log("open");
    } else name += "cs-profile";
    return name + " fs-exclude";
  };
  const getDisplay = () => {
    let style = "";
    if (
      document.location.pathname.includes("edit") ||
      document.location.pathname.includes("notifications")
    )
      style += "none";
    else style += "block";
    return style;
  };
  const getComponent = () => {
    if (
      document.location.pathname.includes("edit") &&
      !document.location.pathname.includes("workinghours")
    )
      return (
        <EditProfileComponent
          userName={userProps.userName}
          handleChange={props.handleChange}
          projectkey={props.projectKey}
          addNewField={props.addNewField}
          handleImageChange={imageProps.handleImageChange}
          getExtraField={props.getExtraField}
          title={userProps.title}
          submitDetails={props.submitDetails}
          removeErrorField={props.removeErrorField}
          setActiveField={props.setActiveField}
          imageChange={imageProps.imageChange}
          resetState={props.resetState}
          hasStateChanged={props.hasStateChanged}
          detailsSubmitted={props.detailsSubmitted}
        />
      );

    if (document.location.pathname.includes("notifications"))
      return (
        <NotificationSettingsComponent
          updateNotification={props.updateNotification}
          activeMode={props.activeMode}
        />
      );
    else if (
      document.location.pathname.includes("edit") &&
      document.location.pathname.includes("workinghours")
    ) {
      return (
        <EditWorkingHoursComponent
          projectKey={props.projectKey}
          userId={userProps.userId}
          accountId={userProps.accountId}
          workingHoursFromreducer={props.workingHoursFromreducer}
          createWorkingHours={props.createWorkingHours}
          updateWorkingHours={props.updateWorkingHours}
          isUpdating={props.isUpdating}
        />
      );
    } else
      return (
        <MyProfileComponent
          emailId={userProps.emailId}
          phoneNumber={userProps.phoneNumber}
          getEmailIds={props.getEmailIds}
          getPhoneNumbers={props.getPhoneNumbers}
          projectKey={props.projectKey}
          userId={userProps.userId}
          accountId={userProps.accountId}
        />
      );
  };

  const setUploadState = () => {
    if (event.target.nodeName === "FIGURE") setShowUploadOptions(true);
    else setShowUploadOptions(false);
  };
  const handleRemoveImage = () => {
    if (
      userProps.imgSrc &&
      !userProps.imgSrc.includes("no-image") &&
      userProps.imgSrc !== "null"
    ) {
      setShowUploadOptions(false);
      imageProps.removeImage();
    }
  };

  const getClass = () => {
    let name = "";
    if (userProps.imgSrc) name = userProps.imgSrc === "null" ? "" : "png-pic";
    return name;
  };
  const getProfileName = () => {
    if (userProps.userName) {
      return <h2 className="cs-caps-txt fs-exclude">{userProps.userName}</h2>;
    }
    return <ProfileNameLoader />;
  };
  const getStyle = () => {
    let style = {};
    if (
      userProps.imgSrc &&
      !userProps.imgSrc.includes("no-image") &&
      !userProps.imgSrc.includes("null")
    )
      style.backgroundColor = "white";
    return style;
  };

  const getImageElement = () => {
    return (
      <div className={getClassName()}>
        <figure
          className="fs-exclude"
          onClick={() => {
            setUploadState();
          }}
          className={getClass()}
          style={getStyle()}
        >
          {imageProps.showImgTag &&
          userProps.imgSrc &&
          userProps.imgSrc !== "null" ? (
            <img src={userProps.imgSrc} onError={imageProps.onImgLoadError} />
          ) : (
            ""
          )}
          <ul ref={node}>
            <li
              onClick={() => {
                setUploadState();
              }}
            >
              <EditIcon />
              Upload
              <input
                type="file"
                onChange={imageProps.handleImageChange}
                name="uploadFile"
                id="uploadFile"
              />
            </li>
            <li
              class="delete-user-pic"
              style={{
                cursor:
                  userProps.imgSrc &&
                  !userProps.imgSrc.includes("no-image") &&
                  userProps.imgSrc !== "null"
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={() => handleRemoveImage()}
            >
              <DeleteIcon />
              Remove
            </li>
          </ul>
        </figure>
        {getProfileName()}
        <p className="fs-exclude">{userProps.title}</p>
        <Link
          to={"/app/" + props.projectKey + "/settings/myprofile/edit"}
          style={{ display: getDisplay() }}
        >
          Edit
        </Link>
      </div>
    );
  };

  const getFlagIcon =() => {
    if(locationProps.countryCode && latitude && longitude) {
        let countryCodeClass = locationProps.countryCode.toLowerCase();
        return <i className={"flag-ele "+countryCodeClass}></i>;
    }
    return "";
  }

  const getLocationDetails = () => {
    if (!latitude || !longitude || !userProps.contact) return "";
    return `${locationProps.cityName}, ${locationProps.countryCode}`;
  };

  return (
    <div class="cs-settings-wrp">
      <section>
        <div class="cs-profile-map fs-exclude">
          {userProps.contact && latitude && longitude ? (
            <MapComponent
              contact={userProps.contact}
              isFromProfilePage={true}
              latitude={latitude}
              longitude={longitude}
            />
          ) : (
            <img src={"https://app.chatsupport.co/images/map-placeholder.png"} />
          )}
          <span
            className={
              userProps.contact && latitude && longitude ? "" : "no-found"
            }
          >
            {getLocationDetails()} {getFlagIcon()}
            <br />
            <p>{latitude && longitude ? props.time : ""}</p>
          </span>
        </div>
        {getImageElement()}
        {getComponent()}
        <div
          class="cs-popupbg"
          style={{ display: imageProps.openCropperWindow ? "block" : "none" }}
        ></div>
        <div
          class="cs-popup-model cs-popup-cropping"
          style={{ display: imageProps.openCropperWindow ? "block" : "none" }}
        >
          <header>
            <h2>Crop Image</h2>
            <cite
              onClick={() => {
                imageProps.toggleCropperWindowState(false);
              }}
            >
              &times;
            </cite>
          </header>
          <CropperComponent
            imgSrc={imageProps.uploadedImageUrl}
            saveImage={imageProps.saveImage}
          />
        </div>
      </section>
    </div>
  );
};
export default OuterProfileComponent;
