import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div``

export const ContainerDesktop = styled.div`
  margin-bottom: 4rem;
  display: none;

  ${mq.sm} {
    display: inline-block;
  }
`
