export const GET_URL_PREVIEW_INFO_REQUEST = "GET_URL_PREVIEW_INFO_REQUEST";
export const GET_URL_PREVIEW_INFO_RESPONSE = "GET_URL_PREVIEW_INFO_RESPONSE";
export const GET_URL_PREVIEW_INFO_FAILED = "GET_URL_PREVIEW_INFO_FAILED";
export const UPDATE_URL_PREVIEW_DIMENSIONS = "UPDATE_URL_PREVIEW_DIMENSIONS";
export const REQUEST_URL_PREVIEW_DIMENSIONS = "REQUEST_URL_PREVIEW_DIMENSIONS";

export const getUrlPreviewInfoRequest = (
  urls,
  isOverflowConversation,
  isAwWindow
) => ({
  type: GET_URL_PREVIEW_INFO_REQUEST,
  urls,
  isOverflowConversation,
  isAwWindow,
});

export const getUrlPreviewInfoResponse = (urlPreviews) => ({
  type: GET_URL_PREVIEW_INFO_RESPONSE,
  urlPreviews,
});

export const requestUrlPreviewDimensions = (urlInfo) => ({
  type: REQUEST_URL_PREVIEW_DIMENSIONS,
  urlInfo,
});
export const updateUrlPreviewDimensions = (previewDimensions) => ({
  type: UPDATE_URL_PREVIEW_DIMENSIONS,
  previewDimensions,
});

export const getUrlPreviewInfoFailed = () => ({
  type: GET_URL_PREVIEW_INFO_FAILED,
});
