import { call, put, fork } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as ProjectEventAction from "../actions/ProjectEventsAction";
import { authFailed } from "../actions/LoginAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* doFetchProjectEvents(projectEvent) {
  const url = "/events/getByProject";
  const requestParam = {
    projectId: projectEvent.projectId,
    limit: "",
    cursor: "",
    order: "",
  }; // Would be edited in future

  try {
    let response = yield call(Ajax.post, url, requestParam, "application/json");
    if (response.response == true) {
      let projectEventMap = {};
      response = response.data.entity;
      response.map((item, i) => {
        projectEventMap[item.key] = item;
      });
      yield put(ProjectEventAction.projectEventResponse(projectEventMap));
      yield put(VoiceboxActions.hideVoicebox());
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to Fetch!",
          dismissAfter: 2000,
        })
      );
      yield put(ProjectEventAction.projectEventFailed());
    }
  } catch (e) {
    console.error(e);
    yield put(ProjectEventAction.projectEventFailed());
    yield put(handleGlobalError(e));
  }
}

export function* updateProjectEvent(data) {
  const url = "events/projectEvent/update";
  let projectEvent = "";
  try {
    let response = yield call(Ajax.put, url, data.projectEvent);
    projectEvent = response.data;

    if (response.response == true) {
      yield put(ProjectEventAction.updateProjectEventResponse(projectEvent));
      yield put(VoiceboxActions.hideVoicebox());
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to Update!",
          dismissAfter: 2000,
        })
      );
      yield put(ProjectEventAction.updateProjectEventFailed());
    }
  } catch (e) {
    console.log(e.name);
    if (e.response.data.Exception == "EXISTING_PARAM")
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Existing Event!",
          dismissAfter: 2000,
        })
      );
    else
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to update!",
          dismissAfter: 2000,
        })
      );

    yield put(ProjectEventAction.updateProjectEventFailed());
    yield put(handleGlobalError(e));
  }
}

export function* deleteProjectEvent(data) {
  const url = "events/deleteEvent/" + data.projectEventId;
  let projectEvent = "";
  try {
    let response = yield call(Ajax.deleteReq, url);

    if (response.response == true) {
      yield put(VoiceboxActions.hideVoicebox());
      yield put(
        ProjectEventAction.deleteProjectEventResponse(data.projectEventId)
      );
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Failed to delete!",
          dismissAfter: 2000,
        })
      );
      yield put(ProjectEventAction.deleteProjectEventFailed());
    }
  } catch (e) {
    console.log(e.name);
    yield put(ProjectEventAction.deleteProjectEventFailed());
    yield put(handleGlobalError(e));
  }
}
