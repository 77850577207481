import { IError } from 'src/utils/state/types'

export enum EnumStatus {
  signingUp = 'signingUp',
  registeringSessionStep1 = 'registeringSessionStep1',
  registeringSessionStep2 = 'registeringSessionStep2',
  registeringSessionStep3 = 'registeringSessionStep3',
}

export interface IAuthState {
  status: EnumStatus[]
  widgetId?: string
  userId?: string
  ssoToken?: string | null
  code?: string | null
  error?: IError
}

export interface ISignUpSuccessDTO {
  widgetId: string
  userId: string
  code?: string | null
  ssoToken?: string | null
}
