import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { responseVideoLink, failedVideoLink } from "../actions/VideoChatAction";
import { addNotification } from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import { sendMessage } from "../actions/MessageAction";
import { store } from "../app";
import { getQueryParam, getScreenShareLink } from "../commons/Utility";

export function* getVideoLink(request) {
  let url = "/video/create";
  console.log("Inside getVideoLink saga");
  try {
    if (request.videoReq.isOverflowConversation)
      url =
        "video/overflow/create?connectionId=" + getQueryParam("connection_id");
    let linkInfo = undefined;
    console.info("link Information =>", request);
    if (request.videoReq.type == "videochat")
      yield put(showVoicebox({ message: "sending video link..." }));
    else yield put(showVoicebox({ message: "sending screenshare link..." }));
    if (request.videoReq.isOverflowConversation)
      linkInfo = yield call(Ajax.postUnauthenticated, url, request.videoReq);
    else linkInfo = yield call(Ajax.post, url, request.videoReq);
    console.log("link response=>", linkInfo);
    if (request.videoReq.type != "videochat") {
      //linkInfo.data.room_link +=  "?s&a";
      let videoLink = linkInfo.data.room_link;
      let pathName = new URL(videoLink).pathname;
      let screenShareLink = getScreenShareLink(videoLink, "/share", pathName);
      linkInfo.data.room_link = screenShareLink + "?s&a";
    }

    yield put(responseVideoLink(linkInfo.data));
    yield put(hideVoicebox());
  } catch (e) {
    console.info("Error while fetching video Link", e);
    yield put(hideVoicebox());
    yield put(
      addNotification({
        message: "Failed!",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}
