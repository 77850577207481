import React, {useState, useRef, useEffect} from "react";
import GridImage from "./GridImage.jsx";
import { useSelector, useDispatch } from "react-redux";
import {getFullName, getProjectId, createConversationId, getDefaultConversationObject, getDisplayPlace, getVisitorDisplayTime} from '../../commons/Utility.js';
import {visitorTimeZoneResponse, getVisitorTimeZone, visitorTimeZoneFailed} from '../../actions/ContactInfoAction.js';
import {addCardVisitorId, addCardPosition} from '../../actions/PeopleAction.js';
import {
  getColumnName,
  getFormattedValue,
  getInitials
} from "../commons/ContactsUtility.js";
import { addTempConversation } from "../../actions/ConversationInfoAction";
import {removeVisitorToQueue} from '../../actions/VisitorQueueActions';
import { browserHistory } from "react-router";
import {
  ShareIcon,
  ConversationIcon
} from "../../commons/Icons.js";
import { displayVisitorType, EMAIL_CAMPAIGN_VIEW } from "../../commons/Constants.js";

const GridRow = (props) => {
  const [isShown, setIsShown] = useState(false);
  const {userReducer, conversationReducer, peopleReducer} = useSelector((state) => ({
    userReducer: state.UserReducer,
    conversationReducer : state.ConversationInfoReducer,
    peopleReducer : state.PeopleReducer
  }));
  const {currentlySelectedVisitorId, cardPosition} = peopleReducer;  
  const {
    headerInfo = [],
    rowData = {},
    style,
    gridListingHeight,
    gridHeaderBottom
  } = props;
  const {projectkey, groupId} = props.params;
  const dispatch = useDispatch();
  const infoCardRef = useRef();
  const gridRef = useRef();
  const gridImageRef = useRef();
  const showToolTip = (event) => {
    const scrollWidth = event.target.scrollWidth;
    const offSetWidth = event.target.offsetWidth;
    if (scrollWidth > offSetWidth) setIsShown(true);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [currentlySelectedVisitorId]);
  const handleClickOutside = (event) => {
    if (
      infoCardRef &&
      infoCardRef.current &&
      !infoCardRef.current.contains(event.target) && 
      !gridImageRef.current.contains(event.target)
    ) {
      dispatch(addCardVisitorId(''));
    }
  };
  const handleEscKey = (e) => {
    if ((e.which === 27 || e.keyCode === 27) && currentlySelectedVisitorId == rowData['key']){
      dispatch(addCardVisitorId(''));
    }
  };
  

  useEffect(() => {
    if(!currentlySelectedVisitorId || currentlySelectedVisitorId != rowData['key']) return;
    console.info("Getting timezone");
    let timeZoneMap = conversationReducer.timeZoneMap || {};
        if (rowData.key && !rowData.timeZoneObject && !rowData.isTimeZoneFetching && !rowData.isTimeZoneFetchFailed) {
          if (rowData.latitude && rowData.latitude != "null" && rowData.longitude && rowData.longitude != "null") {
            let latitude = parseFloat(rowData.latitude).toFixed(2);
            let longitude = parseFloat(rowData.longitude).toFixed(2);
            if (timeZoneMap[latitude + "," + longitude]) {
                dispatch(visitorTimeZoneResponse(rowData.key, latitude, longitude, {
                  ...timeZoneMap[latitude + "," + longitude],
                },rowData));
            }
            else {
                dispatch(getVisitorTimeZone(rowData.key, latitude, longitude, rowData));
            }
          }
         } 
         else 
            dispatch(visitorTimeZoneFailed(rowData.key, rowData));
  }, [currentlySelectedVisitorId]);
  const toggleCard = (e) => {
    let rowPos = gridRef.current.getBoundingClientRect();
    let positionOfRowFromHeader = rowPos.bottom - gridHeaderBottom;
    let spaceRemainingAtBottomOrRow = gridListingHeight - positionOfRowFromHeader;
    let cardHeight = 185;
    let hasEnoughSpace = spaceRemainingAtBottomOrRow > cardHeight;
      if(!hasEnoughSpace)
        dispatch(addCardPosition('top'));
      else 
        dispatch(addCardPosition('bottom'));
      dispatch(addCardVisitorId(rowData['key']));
  }
  const onEmailReplied = (value) => {
    if (value[0] !== EMAIL_CAMPAIGN_VIEW){
      return;
    }
    browserHistory.push(`/app/${projectkey}/chat/dashboard/D/A/N/All/${conversationId}`)
  }

  const getReplyClassName = (value) => {
    if (value === EMAIL_CAMPAIGN_VIEW) {
      return "enabled";
    } else {
      return "";
    }
  }
  const getMember = (header) => {
    const { name, align, prependImage, imageSrc, alias, type } = header;
    const columnName = getColumnName(name, type);
    let rowMember = {};
    let value = "";
    let getToolTipContent = () => value.length > 17 ? value: "";
    if (!rowData["isLoader"]) {
      value = getFormattedValue(columnName, rowData);
      rowMember = prependImage ? (
        <GridImage
          toggleCard={toggleCard}
          alias={rowData[alias]}
          firstName={rowData["firstName"]}
          lastName={rowData["lastName"]}
          avatarColor={rowData["avatarColor"]}
          source={rowData[imageSrc]}
          isOnline={rowData["isOnline"]}
          showToolTip={showToolTip}
          setIsShown={setIsShown}
          isShown={isShown}
          ref={gridImageRef}
        ></GridImage>
      ) : (
        <div style={{cursor:"auto"}} className={align ? "txt-center" : ""}>
          <span className={getReplyClassName(value[0])}
            data-class="tooltip info"
            data-tip={getToolTipContent()}
            onMouseEnter={showToolTip}
            onMouseLeave={() => setIsShown(false)}
            onClick={onEmailReplied}
            onKeyDown={ () => {} }
          >
            {value[0] === EMAIL_CAMPAIGN_VIEW ? value[0] : value}
          </span>
        </div>
      );
    } else {
      rowMember = prependImage ? (
        <div>
          <figure>
            <em className="progress"></em>
          </figure>
          <em className="progress"></em>
        </div>
      ) : (
        <div>
          <em className="progress"></em>
        </div>
      );
    }
    return rowMember;
  };
  const onRowClick = () => {
    if(rowData['key'] && groupId)
      browserHistory.push(`/app/${projectkey}/contacts/dashboard/${groupId}/detail/history/${rowData['key']}`)
    else
      browserHistory.push(`/app/${projectkey}/contacts/dashboard/campaign/detail/history/${rowData['key']}/${props.campaignId}/${props.stats}/CHAT`)
  }

  const redirectToConverstion = (conversationKey) => {
    browserHistory.push({
      pathname:
        "/app/" +
        projectkey +
        "/chat/dashboard/D/O/N" +
        "/" +
        userReducer.data.id +
        "/" +
        conversationKey,
    });
  };

  const createNewConversation = () => {
    let visitorId = rowData['key'];
    let projectId = getProjectId(projectkey);
    let conversationMap = Object.assign(
      {},
      conversationReducer.conversationObject.conversationMap
    );
    // if(!contactsMap[visitorId]) 
    //     dispatch(addVisitorToContacts(rowData));
    let tempConversationId = createConversationId();
    while (conversationMap[tempConversationId])
      tempConversationId = createConversationId();
    let newConversation = getDefaultConversationObject(
      tempConversationId,
      projectId,
      visitorId,
      userReducer.data.id
    );
    newConversation.conversationType = "VISITOR_QUEUE_CONVERSATION";
    dispatch(addTempConversation(Object.assign({}, newConversation), rowData));
    dispatch(removeVisitorToQueue(visitorId));
    setTimeout(() => {
      redirectToConverstion(newConversation.key)
    }, 500);
  };

  const getInitialOrPhoto = () => {
    try {
    if(rowData['type'] && !rowData['profileImage'])
      return getInitials(rowData['firstName']) || displayVisitorType[rowData['type']].charAt(0) || "V"
    else if(rowData['type'] && rowData['profileImage'])
      return <img src={rowData['profileImage']}/>;
    else 
      return "V";
    }catch(error) {
      return "V";
    }
  }

  const getCard = () => {
      if(currentlySelectedVisitorId == rowData['key']) {
        let country = rowData && rowData.country ? rowData.country : "";
        let contactsMap = Object.assign({},conversationReducer.conversationObject.contactsMap);
        let contact = contactsMap[rowData['key']];
        let timeZoneObject = contact && contact.timeZoneObject ? contact.timeZoneObject : undefined;
        let isTimeZoneFetching = rowData.isTimeZoneFetching;
        let displayTime = getVisitorDisplayTime(timeZoneObject);
        let displayPlace = getDisplayPlace(timeZoneObject, country);
        let timezone = isTimeZoneFetching 
                      ? (<em class="progress"></em>) 
                      : (displayPlace || displayTime ? `${displayPlace} ( ${displayTime} Local Time )` : `Not Available`);
          return <section ref={infoCardRef} class="detail-info">
                    <div>
                      <figure className={rowData['isOnline'] ? "online" : ""} style={rowData['avatarColor'] ? { backgroundColor: rowData['avatarColor'] } : {}}>{getInitialOrPhoto()}<cite></cite></figure>
                      <label>{getFullName(rowData) || displayVisitorType[rowData['type']] || "Visitor"}</label>
                      <p> {timezone}</p>
                    </div>
                    <ul>
                      <li style={{"cursor" : "pointer"}} onClick={createNewConversation}><a><ConversationIcon />Start Conversation</a></li>
                      <li style={{"cursor" : "pointer"}} onClick={onRowClick}><a><ShareIcon />View Profile</a></li>
                    </ul>
                  </section>
      }
      return "";
  }

  return (
    <div style={style} ref={gridRef} className={"people-grid-list "+(cardPosition=='top' ? 'card-top' : "")}>
      {headerInfo.map((header) => getMember(header))}
      {getCard()}
    </div>
  );
};

export default GridRow;
