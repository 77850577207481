import React, { FC } from 'react'

export const Clip: FC<any> = (props) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5932 2.97085V9.11078C5.5932 10.1681 4.69066 11.0333 3.55931 11.0333C2.42795 11.0333 1.52541 10.1802 1.52541 9.11078V2.49023C1.52541 1.82938 2.09744 1.28868 2.79659 1.28868C3.49575 1.28868 4.06778 1.82938 4.06778 2.49023V8.16155C4.06778 8.42589 3.83897 8.64217 3.55931 8.64217C3.27964 8.64217 3.05083 8.42589 3.05083 8.16155V2.97085H2.28812V8.16155C2.28812 8.8224 2.86015 9.3631 3.55931 9.3631C4.25846 9.3631 4.83049 8.8224 4.83049 8.16155V2.49023C4.83049 1.43287 3.92795 0.567749 2.79659 0.567749C1.66524 0.567749 0.762695 1.42085 0.762695 2.49023V9.11078C0.762695 10.5767 2.00846 11.7542 3.55931 11.7542C5.11015 11.7542 6.35592 10.5767 6.35592 9.11078V2.97085H5.5932Z"
      fill="#50555C"
    />
  </svg>
)
