import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import tagManagerSrc from 'src/assets/oval.svg'
import trees from 'src/assets/trees.png'
import { Icon } from 'src/features/ui/components/Icon'

export const Container = styled.div`
  width: 19.8rem;
  background: ${R.path(['theme', 'color', 'white'])};
  border: 0.05rem solid ${R.path(['theme', 'color', 'athensGray'])};
  box-sizing: border-box;
  box-shadow: 0 0.45rem 1.6rem rgba(0, 0, 0, 0.19);
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
`

export const Top = styled.div`
  border-bottom: 0.05rem solid ${R.path(['theme', 'color', 'athensGray'])};
  padding: 1.3rem 1.3rem 0.95rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 1.23rem;
    background-image: url(${trees});
    background-size: contain;
    background-repeat: no-repeat;
    width: 5.9rem;
    height: 5rem;
    pointer-events: none;
  }
`

export const Dot = styled.div`
  display: inline-block;
  content: '';
  width: 0.4rem;
  height: 0.4rem;
  background-image: url(${tagManagerSrc});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 0.07rem;
  margin-bottom: -0.07rem;
`

export const Title = styled.span`
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${R.path(['theme', 'color', 'codGray'])};
  position: relative;
`

export const Content = styled.div`
  padding: 1.34rem 0.65rem 1.55rem 1.34rem;
  flex-grow: 1;
`

export const Bottom = styled.div`
  padding: 0.89rem 1.1rem;
  border-top: 0.05rem solid ${R.path(['theme', 'color', 'athensGray'])};
`

export const StyledButton = styled.button`
  position: absolute;
  top: 0.1rem;
  right: 0.67rem;
  padding: 0.67rem;
  margin: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: transform 250ms ease-in-out;

  &:hover {
    transform: scale(1.3);
  }
`

export const StyledIcon = styled(Icon)``

export const TextMessage = styled.div`
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-size: 0.89rem;
  line-height: 1.1rem;
  letter-spacing: 0.01rem;
  color: ${R.path(['theme', 'color', 'cadetBlue'])};
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
`

export const SendButton = styled.div`
  background-color: ${R.path(['theme', 'color', 'dodgerBlue'])};
  width: 1.67rem;
  height: 1.67rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
`

export const ButtonIcon = styled(Icon)`
  margin-top: 0.45rem;
`

export const ChatDividerText = styled.div`
  font-family: ${R.path(['theme', 'font', 'family'])};
  color: ${R.path(['theme', 'color', 'oxfordBlue'])};
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: 0;
  opacity: 0.5;
  width: 100%;
  text-align: center;
`

export const MineBubble = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Bubble = styled.div<{ isMine?: boolean }>`
  display: inline-block;
  padding: 0.6rem 0.8rem;
  background: rgba(68, 82, 102, 0.05);
  max-width: 15rem;
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-size: 0.78rem;
  line-height: 1.1rem;
  color: ${R.path(['theme', 'color', 'codGray'])};
  border-radius: 0.8rem;
  border-bottom-left-radius: 0;

  ${is('isMine')`
    background-color: #e4e8ee;
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0;
    max-width: 12rem;
  `}
`

export const Profile = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: url('https://app.chatsupport.co/images/user7.png');
  background-size: contain;
  background-position: center;
`

export const BubbleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
