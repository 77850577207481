import { getAwItemClosedComponentId } from "./AwRecentsUtils";

class ExpectedBaseApis {
    constructor(FrontOffice) {
        this.FrontOffice = FrontOffice;
        this.ComponentId = '';
    }

    setFrontOffice(FrontOffice) {
        this.FrontOffice = FrontOffice;
    }
    setComponentId(id) {
        this.ComponentId = id;
    }

    getComponentId() {
        return this.ComponentId;
    }

    getFrontOffice() {
        return this.FrontOffice;
    }

    getBaseEventEmitter() {
        return this.getFrontOffice()?.EventEmitter;
    }

    addItemToConnectRecent(recentDetails, dockerUnreadCount) {
        let method = this.hasItemInFrontOffice(recentDetails.itemId) ? "updateItem" : "addItem";
        this.getFrontOffice()?.connect.shared.services.recents[method]({ ...recentDetails, componentId: this.getComponentId(), notificationCount: dockerUnreadCount, recentTime: Date.now() });
    }

    removeItemInConnectRecent(itemId) {
        this.getFrontOffice()?.connect.shared.services.recents.removeItem({ itemId, componentId: this.getComponentId() });
    }

    updateItemNoificationCountInConnectRecent(itemId, count) {
        this.getFrontOffice()?.connect.shared.services.recents.updateItem({ itemId, componentId: this.getComponentId() });
    }

    hasItemInFrontOffice(itemId) {
       return this.getFrontOffice().connect.shared.services.recents.hasItem({ itemId: itemId, componentId: this.getComponentId() });
    }

    showClientNotification(notificationData) {
        this.getFrontOffice()?.Notification.showDesktopNotification({ componentId: this.getComponentId(), ...notificationData });
    }

    navigateToRecentItem(itemId) {
        this.getFrontOffice()?.connect.shared.services.recents.navigate({ itemId, componentId: this.getComponentId() })
    }

    makeConnectAppFocus() {
        this.getFrontOffice()?.App.focus();
    }

    makeRecentItemActive(itemId) {
        this.getFrontOffice()?.connect.shared.services.recents.makeItemActive({ itemId, componentId: this.getComponentId() });
    }
    
    addPluginComponentsToConnect(components) {
        this.getFrontOffice()?.connect.shared.services.addPluginComponents(this.getComponentId(), components);
    }

    attachDefaultEventEmitterListeners() {
        const EventEmitter = this.getBaseEventEmitter();
        if (EventEmitter) {
            EventEmitter.addListener(  getAwItemClosedComponentId(this.getComponentId()), onRecentElementClose);
        }
    }
    updateItemInConnect(itemId,notificationCount ) {
        this.getFrontOffice()?.connect.shared.services.recents.updateItem({
            itemId,
            componentId: this.getComponentId(),
            notificationCount: notificationCount,
            recentTime: Date.now(),
        });
    }

}

function onRecentElementClose(item) {
    console.log("onRecentElementClose closed");
}

const ExternalBaseApis = new ExpectedBaseApis();

export default ExternalBaseApis;


