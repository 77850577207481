import ReactGA from "react-ga4";
import { getAppMode, getDefaultCustomDimensions } from "./Utility";
import {
  STAGING_GA4_TRACKING_CODE, 
  LIVE_GA4_TRACKING_CODE,
  GA_DIMENSION_MAP,
} from "./Constants";

const appMode = getAppMode() == "live" ? "live" : "staging";

export const initGA = () => {
  if (appMode == "live") ReactGA.initialize(LIVE_GA4_TRACKING_CODE);
  else if (appMode == "staging") ReactGA.initialize(STAGING_GA4_TRACKING_CODE);
};

export const PageView = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: document.title,
  });
};

/**
 * Event - Add custom tracking event.
 * @param {string} projectId
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {string} customDimensions
 * @param {string} value
 */
export const Event = (
  projectId,
  category,
  action,
  label,
  customDimensions,
  value
) => {
  try {
    if (projectId)
      customDimensions = {
        ...getDefaultCustomDimensions(projectId),
        customDimensions,
      };
  
    let dimensions = {};
    if (customDimensions && Object.keys(customDimensions).length > 0) {
      Object.keys(customDimensions).map((key) => {
        if(GA_DIMENSION_MAP[key])
          dimensions[GA_DIMENSION_MAP[key]] = customDimensions[key];
      });
    }
  
    ReactGA.event(action, {
      category,
      action,
      label,
      value,
      ...dimensions,
    });
  } catch (error) {
    console.log('Error in EventTrackingGoogleAnalytics for action - ', action, ': ', error)
  }

};
