import React, { Component } from "react";
import { getDisplayDate, isContactDetail } from "../../commons/Utility";
class DateTagLineComponent extends Component {
  constructor() {
    super();
    this.state = {
      scrollable: false,
    };
  }
  componentDidUpdate() {
    if (
      this.props.stickTop &&
      this.props.isScrollable() !== this.state.scrollable
    ) {
      this.setState({ scrollable: this.props.isScrollable() });
    }
  }

  getStyle() {
    let style = {};
    if (this.props.createdDate && !this.props.atTop && this.state.scrollable) {
      style["visibility"] = "visible";
    } else {
      style["visibility"] = "hidden";
    }
    if (this.props.top) {
      style["width"] = "100%";
      style["top"] = this.props.top;
    }
    if (this.props.isOverflowConversation) {
      style["top"] = "15px";
      style["width"] = "100%";
    }
    if(isContactDetail()) {
      style["top"] = "72px";
      style["width"] = "100%";
    }
    return style;
  }
  render() {
    if (this.props.stickTop) {
      return (
        <div class="tag-date" style={this.getStyle()}>
          <span>{this.props.createdDate}</span>
        </div>
      );
    } else if (
      this.props.newMessage &&
      this.props.newMessage !== "" &&
      this.props.newMessage === this.props.message &&
      !this.props.isDate
    ) {
      return (
        <div class="conversation-msg tag-msg tag-new-msg">
          <span>New Message</span>
        </div>
      );
    } else {
      return (
        <div class="conversation-msg tag-msg">
          <span>{getDisplayDate(this.props.createdDate)}</span>
        </div>
      );
    }
  }
}
export default DateTagLineComponent;
