import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router";

class UnsubscribeEmail extends Component {
  render() {
    console.info("UnsubscribeEmail Email Props:", this.props);
    let {
      localState: {
        unsubcribeId,
        unsubcribeIdDescription,
        unsubcribeIdDisplayName,
        emailId,
        hasFetchedPreferences,
      },
      routeToPreferencePage,
    } = this.props;

    return (
      <section
        className="unsubscriptionSection"
        style={{ display: hasFetchedPreferences ? "block" : "none" }}
      >
        <h3 className="optOutEmail" style={{ marginTop: "100px" }}>
          {emailId}
        </h3>
        <p className="optOutDescription">
          You'll no longer receive the following type of email communication
          from this sender
        </p>
        <div className="formSection fadeIn">
          <div className="formField">
            <div className="formHead">
              <h5>
                <b>Email Type</b>
              </h5>
            </div>
            <div className="formContent">
              <h5>
                <b>Opted Out</b>
              </h5>
            </div>
          </div>
          <div className="formField">
            <div className="formHead">
              <h5>{unsubcribeIdDisplayName}</h5>
            </div>
            <div className="formContent">
              <h5>
                <b style={{ color: "maroon", fontSize: "20px" }}>Yes</b>
              </h5>
              <p>{unsubcribeIdDescription}</p>
            </div>
          </div>
          <div
            className="formField"
            style={{ textAlign: "center", paddingTop: "20px", border: "0px" }}
          >
            <button onClick={routeToPreferencePage} className="blueBtn">
              View Opt Out Preferences
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default UnsubscribeEmail;
