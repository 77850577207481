import React, { Component } from "react";
import { connect } from "react-redux";
import { getAppMode } from "./Utility";
import ChatAnswering from "../containers/ChatAnsweringContainer";

@connect((store) => ({
  userReducer: store.UserReducer,
  projectReducer: store.ProjectReducer,
  chatConfigurationReducer: store.ChatConfigurationReducer,
}))
class AuthenticatedContainer extends Component {
  constructor() {
    super();
    this.state = {
      isLScalled: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    // TO UPDATE LIVESUPPORT USER INFORMATION
    console.log("typeof _ls==========>>", typeof _ls);
    if (
      typeof _ls !== "undefined" &&
      nextProps.userReducer.isFetched &&
      nextProps.projectReducer.isFetched &&
      !this.state.isLScalled
    ) {
      try {
        let userData = nextProps.userReducer.data;
        let accountNumber = "";
        let userObject = {};
        userObject["customFields"] = {};

        if (userData.isAdmin == "true" && userData.originalId) {
          console.log(
            "Not tracking user as this is admin user switched to other account!"
          );
        } else {
          let projectList = nextProps.projectReducer.projectList;
          let projNames = [];
          let projKeys = [];

          for (let i in projectList) {
            projNames.push(projectList[i].name);
            projKeys.push(projectList[i].key);
          }

          userObject = {
            customFields: {
              projects: projNames + "",
              projectKeys: projKeys + "",
            },
          };

          //For FUllStory tracking
          // if( getAppMode() === 'live' )
          // {
          //     FS.identify( userData.id , {
          //                                 displayName: userData.firstName+' '+userData.lastName,
          //                                 email: userData.login,
          //                                 projects :  projNames+"",
          //                                 projectKeys : projKeys+""
          //                             });
          // }
          if (
            nextProps.chatConfigurationReducer.data.hasOwnProperty(
              "LS-" + nextProps.activeProjectKey
            )
          ) {
            if (
              nextProps.chatConfigurationReducer.data[
                "LS-" + nextProps.activeProjectKey
              ].subAccountNumber
            )
              accountNumber =
                nextProps.chatConfigurationReducer.data[
                  "LS-" + nextProps.activeProjectKey
                ].subAccountNumber;

            if (
              nextProps.chatConfigurationReducer.data[
                "LS-" + nextProps.activeProjectKey
              ].accountNumber
            )
              accountNumber =
                nextProps.chatConfigurationReducer.data[
                  "LS-" + nextProps.activeProjectKey
                ].accountNumber;
          }

          userObject["firstName"] = userData.firstName;
          userObject["lastName"] = userData.lastName;
          userObject["email"] = userData.login;
          userObject["userId"] = userData.id;
          userObject["customFields"]["accountNumber"] = accountNumber;
          userObject["customFields"]["accountId"] = userData.accountID;
          userObject["customFields"]["uniquePin"] = userData.accountID;

          let isFromSignup = localStorage.getItem("isFromSignup");
          if (isFromSignup !== "true") _ls("update", userObject);

          this.setState({ isLScalled: true });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  render() {
    return (
      <div>
        <ChatAnswering dispatch={this.props.dispatch} />
        {this.props.children}
      </div>
    );
  }
}
export default AuthenticatedContainer;
