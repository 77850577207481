import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { browserHistory } from "react-router";
import * as Utilities from "../../commons/Utility.js";
import TeamProfileComponent from "./TeamProfileComponent.jsx";
import { getStaffContact } from "./Action/StaffContactAction";
import { getUserProject } from "./Action/UserProjectMappingAction";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import * as StaffContactHook from "./Hook/StaffContactHook";
import * as AccountProjectHook from "./Hook/AccountProjectHook";

const TeamProfileContainer = (props) => {
  const {
    isInternalUser,
    userLocation,
    staffContactReducer,
    userProjectMappingReducer,
    projectReducer,
    staffReducer,
    userReducer,
  } = useSelector((state) => ({
    isInternalUser: state.UserReducer.isInternalUser,
    userLocation: state.UserReducer.locationDetails,
    staffContactReducer: state.StaffContactReducer,
    // accountStaffs : state.StaffReducer.accountStaffs,
    userProjectMappingReducer: state.UserProjectMappingReducer,
    projectReducer: state.ProjectReducer,
    staffReducer: state.StaffReducer,
    userReducer: state.UserReducer,
  }));

  const [activeStaffId, setActiveStaffId] = useState("");
  const [activeStaffContact, setActiveStaffContact] = useState({});
  const [activeUserProjects, setActiveUserProjects] = useState({
    userProjectsMapping: [],
    projectEntitiesMap: {},
    chatConfigMap: {},
  });
  const [accountProjects, setAccountProjects] = useState([]);
  const [activeStaff, setActiveStaff] = useState({});
  const accountStaffs = staffReducer.accountStaffs;
  const loggedInUserId =
    !!userReducer.data && userReducer.data.hasOwnProperty("id")
      ? userReducer.data["id"]
      : "";
  const dispatch = useDispatch();

  useEffect(() => {
    let projectKey = props.params.projectkey;
    let staffId = props.params.staffid;

    if (
      staffId != "invite" &&
      (!staffContactReducer ||
        (!!staffContactReducer &&
          !staffContactReducer.contactMap.hasOwnProperty(staffId) &&
          staffContactReducer.isFetching != true))
    ) {
      // dispatch( VoiceboxActions.showVoicebox( { message : 'Loading...', dismissAfter : 2000  } ) );
      dispatch(getStaffContact(staffId));
    }
    // else if(staffContactReducer.contactMap.hasOwnProperty(staffId) )
    // {
    //         setActiveStaffContact(staffContactReducer.contactMap[staffId]);
    // }

    if (
      staffId != "invite" &&
      (!userProjectMappingReducer ||
        !userProjectMappingReducer.userProjectMapping.hasOwnProperty(
          staffId
        )) &&
      userProjectMappingReducer.isFetching == false
    ) {
      dispatch(getUserProject(staffId, isInternalUser));
    }
    // else if(userProjectMappingReducer.userProjectMapping.hasOwnProperty(staffId))
    //     setActiveUserProjects({ userProjectsMapping :userProjectMappingReducer.userProjectMapping[staffId],
    //                             projectEntitiesMap: userProjectMappingReducer.projectMap[staffId],
    //                             chatConfigMap : userProjectMappingReducer.chatConfigMap[staffId]});
  });

  useEffect(() => {
    let staffId = props.params.staffid;
    if (
      accountStaffs.hasOwnProperty(staffId) &&
      accountStaffs[staffId].status == "ACTIVE"
    )
      setActiveStaff(accountStaffs[staffId]);
    else {
      if (
        accountStaffs.hasOwnProperty(staffId) &&
        accountStaffs[staffId].status == "INACTIVE"
      )
        browserHistory.push({
          pathname:
            "/app/" +
            props.params.projectkey +
            "/directory/myteam/profile/" +
            StaffContactHook.getNextActiveStaffId(accountStaffs),
        });
      else {
        setActiveStaffId(staffId);
        browserHistory.push({
          pathname:
            "/app/" +
            props.params.projectkey +
            "/directory/myteam/profile/" +
            staffId,
        });
      }
    }
  }, [
    props.params.staffid,
    activeStaffId,
    staffReducer.isLoaded,
    staffReducer.isDeleting,
  ]);

  useEffect(() => {
    let staffId = props.params.staffid;
    if (userProjectMappingReducer.userProjectMapping.hasOwnProperty(staffId))
      setActiveUserProjects({
        userProjectsMapping:
          userProjectMappingReducer.userProjectMapping[staffId],
        projectEntitiesMap: userProjectMappingReducer.projectMap[staffId],
        chatConfigMap: userProjectMappingReducer.chatConfigMap[staffId],
      });

    if (
      userProjectMappingReducer.projectMap.hasOwnProperty(staffId) &&
      Object.keys(userProjectMappingReducer.projectMap[staffId]).length > 0 &&
      accountProjects.length == 0
    )
      setAccountProjects(
        Object.values(userProjectMappingReducer.projectMap[staffId])
      );
  }, [
    staffContactReducer.isFetching,
    userProjectMappingReducer.isFetching,
    userProjectMappingReducer.isUpdating,
    props.params.staffid,
  ]);

  useEffect(() => {
    let staffId = props.params.staffid;
    if (staffContactReducer.contactMap.hasOwnProperty(staffId)) {
      setActiveStaffContact(staffContactReducer.contactMap[staffId]);
    }
  }, [staffContactReducer.isFetching, props.params.staffid]);

  const getEventHandler = () => {
    let eventHandler = {};
    // eventHandler["switchActiveStaff"] = switchActiveStaff;
    return eventHandler;
  };

  return (
    <TeamProfileComponent
      teamProfileProps={props}
      userLocation={userLocation}
      activeStaffContact={activeStaffContact}
      activeUserProjects={activeUserProjects.userProjectsMapping}
      projectEntitiesMap={activeUserProjects.projectEntitiesMap}
      chatConfigMap={activeUserProjects.chatConfigMap}
      activeStaff={activeStaff}
      accountProjects={accountProjects}
      loggedInUserId={loggedInUserId}
    />
  );
};
export default TeamProfileContainer;
