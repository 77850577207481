import { UNKNOWN_ERROR } from 'src/utils/api/constants'

export interface IErrorsMap {
  [key: string]: string
}

export const mapExceptionMessage = (
  errorsMap: IErrorsMap,
  exception: string
) => {
  for (const errorKey of Object.keys(errorsMap)) {
    if (errorKey === exception) {
      return errorsMap[errorKey]
    }
  }

  return UNKNOWN_ERROR
}
