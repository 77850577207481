import React, { Component } from "react";
import ReactDOM from "react-dom";
import { displayVisitorType } from "../../commons/Constants";
import ReactTooltip from "react-tooltip";
import MapComponent from "./MapComponent.jsx";
import { browserHistory } from "react-router";
import {
  isAwWindow,
  calcTimeForOffset,
  getDisplayTimeInTimeZone,
  getAvatarColor,
  isValidObject,
  getIsAWRecentComponent,
  getRespectiveStyleforAWChat,
} from "../../commons/Utility";
import NoConversationsHeader from '../../components/chat/NoConversationsHeader.jsx'
import { AWChatHeaderSectionIcon } from "../../commons/Icons";
class ChatDashboardHeader extends Component {
  constructor() {
    super();
    this.routeToPeopleDetail = this.routeToPeopleDetail.bind(this);
    this.state = { headerStatus: "" };
    this.updateHeaderStatus = this.updateHeaderStatus.bind(this);
  }
  componentWillMount() {
    this.updateHeaderStatus(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.updateHeaderStatus(nextProps);
  }
  componentWillUnmount() {
    if (this.timeInterval) clearInterval(this.timeInterval);
  }
  updateHeaderStatus(props) {
    if (this.timeInterval) clearInterval(this.timeInterval);

    let contact = props.contact ? props.contact : {};
    let locationName =
      contact && contact.state && contact.country
        ? contact.state + ", " + contact.country
        : "NA";
    let state = contact && contact.state ? contact.state : "NA";
    let country = contact && contact.country ? contact.country : "NA";
    let companyName =
      contact && contact.companyName ? contact.companyName : "NA";
    let timeZoneObject =
      contact && contact.timeZoneObject ? contact.timeZoneObject : undefined;
    let isTimeZoneFetching = contact ? contact.isTimeZoneFetching : undefined;
    let headerStatus = "";
    if (isTimeZoneFetching == true) {
      if (this.state.headerStatus != "loading...")
        this.setState({ headerStatus: "loading..." });
    } else if (timeZoneObject) {
      headerStatus = getDisplayTimeInTimeZone(
        timeZoneObject.timeZoneId,
        timeZoneObject.dstOffset + timeZoneObject.rawOffset,
        timeZoneObject.timeZoneAbbr,
        timeZoneObject.displayPlace
      );
      this.setState({ headerStatus }, () => {
        this.timeInterval = setInterval(() => {
          headerStatus = getDisplayTimeInTimeZone(
            timeZoneObject.timeZoneId,
            timeZoneObject.dstOffset + timeZoneObject.rawOffset,
            timeZoneObject.timeZoneAbbr,
            timeZoneObject.displayPlace
          );
          if (this.state.headerStatus != headerStatus)
            this.setState({ headerStatus });
        }, 1000);
      });
    } else {
      if (this.state.headerStatus != state + "  |  " + country)
        this.setState({ headerStatus: state + "  |  " + country });
    }
  }

  routeToPeopleDetail() {
    ReactTooltip.hide();
    if (isAwWindow()) return;
    let contact = this.props.contact;
    if (contact && contact.projectId)
      browserHistory.push({
        pathname: `/app/${contact.projectId.split("-")[1]}/people/details/${
          contact.key
        }`,
        state: { prevPath: this.props.location.pathname },
      });
  }

  render() {
    let contact = this.props.contact ? this.props.contact : {};
    let isFetching = this.props.isFetching;
    let isEmptyConverastion = this.props.isEmptyConverastion;
    let isQueueFetched = this.props.isQueueFetched;

    let fullName = contact
      ? contact.firstName
        ? contact.firstName
        : contact.type
        ? displayVisitorType[contact.type]
        : "Visitor"
      : "Visitor";
    fullName += contact && contact.lastName ? " " + contact.lastName : "";
    let initials = contact
      ? contact.firstName
        ? contact.firstName.charAt(0)
        : contact.type
        ? displayVisitorType[contact.type].charAt(0)
        : "V"
      : "V";
    initials += contact && contact.lastName ? contact.lastName.charAt(0) : "";
    let displayName = "";

    if (
      (fullName == "Lead" || fullName == "User") &&
      contact &&
      contact.email
    ) {
      displayName = contact.email;
      initials = displayName ? displayName.charAt(0) : "L";
    }

    let visitorIcon = "";
    if (contact && !!contact.profileImage) {
      let visitorName = contact && contact.firstName ? contact.firstName : "";
      visitorName += contact && contact.lastName ? contact.lastName : "";
      visitorIcon = (
        <figure
          className="fs-exclude"
          style={contact ? { backgroundColor: contact.avatarColor } : {}}
        >
          <img
            src={contact.profileImage}
            ref={(img) => (this.img = img)}
            onError={() =>
              (this.img.src =  "https://app.chatsupport.co/images/profile_Icon.png")
            }
            onClick={this.routeToPeopleDetail}
            onKeyDown={ () => {} }
            style={{ cursor: "pointer" }}
            data-tip={visitorName}
            data-class="tooltip info"
          />
        </figure>
      );
    } else if (getIsAWRecentComponent()) {
      visitorIcon = (
        <figure
        className="fs-exclude testing"
        style={{
          ...getRespectiveStyleforAWChat()
        }}
      >
        <AWChatHeaderSectionIcon />
        </figure>
      );
    } else {
      visitorIcon = (
        <figure
          className="fs-exclude"
          style={contact ? { backgroundColor: contact.avatarColor } : {}}
        ></figure>
      );
    }
    let lastPageUrl = contact.lastPageUrl ? contact.lastPageUrl : "";
    let displayPageText = lastPageUrl ? lastPageUrl.split("/").pop() : "";
    displayPageText = displayPageText
      ? "/" + displayPageText.split("?")[0]
      : "";
    let pageTitle = contact.pageTitle ? contact.pageTitle : displayPageText;

      if((isFetching && isEmptyConverastion && !isQueueFetched) || this.props.isLoading){
        return <NoConversationsHeader/>
      } else {
       return (<header>
        {visitorIcon}
        <cite className={contact.isOnline ? "available" : "offline"}></cite>
        <label className="cs-caps-txt fs-exclude ">{fullName}</label>
        <p className="fs-exclude">{pageTitle}</p>
        {isValidObject(contact) ? <MapComponent contact={contact} /> : ""}
      </header>)
      }
  
    return (
      <div
        className="col3-hd-wrp chat-user-details"
        style={
          contact && contact.latitude && contact.longitude
            ? {}
            : {
                background:
                  '#ffffff url("..https://app.chatsupport.co/images/Location-Info.png") right no-repeat !important',
              }
        }
      >
        {visitorIcon}

        <div className="chat-user-heading">
          <h3
            onClick={this.routeToPeopleDetail}
            style={{ cursor: "pointer" }}
            ref="fullName"
            data-tip={
              this.refs.fullName &&
              this.refs.fullName.scrollWidth > this.refs.fullName.offsetWidth
                ? fullName
                : ""
            }
            /* className={(contact && contact.isOnline) ? "available" : "offline"} */ data-class="tooltip info"
          >
            {displayName ? displayName : fullName}
          </h3>
          <a href="javascript:void(0)">{this.state.headerStatus}</a>
        </div>
        <MapComponent contact={contact} />
      </div>
    );
  }
}
export default ChatDashboardHeader;
