import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { differentiateSegments } from "../../contacts/commons/ContactsUtility.js";
import {
  isAnnoucementDesktopType,
  sortListByProperty,
  getfilterQueryArray,
  getProjectId,
  hasCampaignInfoUpdated,
  getLocalDateEndTimeOfDay,
  getLocalDateStartTimeOfDay
} from "../../../app/commons/Utility.js";
import {
  TeamDropDownIcon,
  WhiteTickIcon,
  SearchIcon,
  ErrorIcon,
} from "../../../app/commons/Icons";
import { updatePreviewType } from "../../actions/BroadcastMessageStatsAction";
import { sendBroadcastMessage } from "../../actions/PeopleAction.js";
import { getPeopleGroupsRequest } from "../../../app/contacts/actions/ContactsAction";
import { getGroupType } from "../../../app/contacts/commons/ContactsUtility.js";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import { CAMPAIGN_STATUS_TO_ID, DEFAULT_END_DATE, CAMPAIGN_DEFAULT_DATE_VALUES, RECIPIENTS_LABEL, IS_CAMPAIGN_FROM_DASHBOARD, STRINGIFIED_BOOL } from "../../commons/Constants";
import DateFilter from "../../../app/contacts/filters/DateFilter";
import moment from "moment";
import { Link } from "react-router";
import ReactTooltip from "react-tooltip";
import { browserHistory } from "react-router";
import { usePrevious } from "../../settings/hooks/usePrevious.js";
import Flatpickr from "react-flatpickr";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics.js";

const PublishCampaign = (props) => {
  const { projectkey, campaignId } = props.params;

  const { campaignDetails, staffDetails, peopleReducer } = useSelector(
    (state) => ({
      campaignDetails: state.BroadcastMessageStatsReducer,
      peopleReducer: state.PeopleReducer,
    })
  );

  const [search, setSearch] = useState("");
  const miniNavBar = isAnnoucementDesktopType(campaignDetails);

  const selectRecipientsRef = useRef("");
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [peopleCount, setPeopleCount] = useState(0);
  const [invalids, setInvalids] = useState([]);
  const [showStartTimeDatePicker, setShowStartTimeDatePicker] = useState(false);
  const [showEndTimeDatePicker, setShowEndTimeDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [previewType, setPreviewType] = useState("");
  const [showStartDateDropDown, setShowStartDateDropdown] = useState(false);
  const [showEndDateDropDown, setShowEndDateDropdown] = useState(false);
  const [showSegmentDropdown, setShowSegmentDropdown] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const searchRef = useRef(null);
  const [enablePublishButton, setEnablePublishButton] = useState(false);
  const [validStartAndEndDate, setValidStartAndEndDate] = useState(true);
  const [initialSegmentIds , setInitialSegmentIds] = useState([]);
  const [rightAwayStartDate, setRightAwayStartDate] = useState(false);
  const prevIsCampaignFetched = usePrevious(isCampaignFetched) === false ? false : prevIsCampaignFetched;
  const [isLoaded , setIsLoaded] = useState(false);
  const [isPeopleCountFetched, setIsPeopleCountFetched] = useState(false);
  const [recipients, setRecipients] = useState(false);
  const { campaignData, isCampaignFetched, fetchedProjectId : fetchedBroadcastsProjectId, isCampaignFromDashboard } = useBroadcastMessageStats(
    projectkey,
    campaignId
  );
  const dispatch = useDispatch();
  const startDateRef = useRef("");
  const endDateRef = useRef("");
  const startDateDropDownRef = useRef("");
  const endDateDropDownRef = useRef("");
  const segmentListRef = useRef("");
  useEffect(() => {
    if (!previewType) {
      setPreviewType("Mobile");
      togglePreviewType("Mobile");
    }
  }, [previewType]);
  let start = getLocalDateStartTimeOfDay();

  const togglePreviewType = (previewType) => {
    dispatch(updatePreviewType(previewType));
  };
  const handleClickOutside = (e) => {
    if (
      selectRecipientsRef.current &&
      !selectRecipientsRef.current.contains(e.target) &&
      showSegmentDropdown
    ) {
      validateSelectedSegment();
      setShowSegmentDropdown(false);
    }
    if (
      startDateRef.current &&
      !startDateRef.current.contains(e.target) &&
      showStartDateDropDown
    ) {
      validateStartDate();
      setShowStartDateDropdown(false);
    }
    if (
      endDateRef.current &&
      !endDateRef.current.contains(e.target) &&
      showEndDateDropDown
    ) {
      validateEndDate();
      setShowEndDateDropdown(false);
    }
  };
  
  useEffect(() => {
    let { startDate, endDate, segmentIds= [] } = campaignDetails.selectedCampaign;
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedSegments(segmentIds);
    if (segmentIds.length > 0) {
      setRecipients(true);
    }

    /* if (Object.keys(peopleReducer.peopleGroupMap).length === 0) */
    if(!peopleReducer.isGroupsFetched 
        || (peopleReducer.groupsProjectId && !peopleReducer.groupsProjectId.includes(projectkey))
        || !peopleReducer.groupsProjectId) {
      setIsPeopleCountFetched(false);
        dispatch(
          getPeopleGroupsRequest(
            "LS-" + projectkey,
            campaignDetails.selectedCampaign.typeOfMessage === "EMAIL"
          )
        );
      }
  }, []);

  useEffect(() => {
    campaignDetails.selectedCampaign.filterQuery = constructFilterQuery();
    dispatch(
      campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
    );
  }, [
    ...selectedSegments.map((segmentId) => {
      return peopleReducer.peopleGroupMap[segmentId] &&
        peopleReducer.peopleGroupMap[segmentId].count
        ? peopleReducer.peopleGroupMap[segmentId].count
        : 0;
    }), peopleReducer.isFetchingCount, peopleReducer.isFetched 
   ]);

  useEffect(() => {
    !showSegmentDropdown ? setSearch("") : searchRef.current.focus();
  }, [showSegmentDropdown]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  useEffect(() => {
    if (campaignId) initializeFields();
  }, [isCampaignFetched, campaignDetails.selectedCampaign.key]);
  
  const constructFilterQuery = () => {
    let { segmentIds = [], key } = campaignDetails.selectedCampaign;
    let constructedFilterQuery = {};
    let count = 0;

    segmentIds.map((segmentId) => {
      let segmentDetails = peopleReducer.peopleGroupMap[segmentId] || {};
      if (segmentDetails.completeQuery) {
        constructedFilterQuery[segmentId] = JSON.parse(
          segmentDetails.completeQuery
        );
      }
      if (segmentDetails.count != undefined) {
        count = count + Number(segmentDetails.count);
      }
    });
    if ( Object.keys(campaignDetails.selectedCampaign).length > 2 && peopleReducer.isFetched )
      setIsPeopleCountFetched(true);
    setPeopleCount(count);
    // setPeopleCountUpdated(true);
    return constructedFilterQuery;
  };

  const initializeFields = () => {
    if (
      isCampaignFetched &&
      campaignData &&
      campaignDetails.selectedCampaign.key
    ) {
      let { segmentIds=[], startDate, endDate } = campaignDetails.selectedCampaign;
      setSelectedSegments([...segmentIds]);
      if (!startDate) {
        setStartDate(CAMPAIGN_DEFAULT_DATE_VALUES.START_DATE);
        let rightAwayDate = new Date();
        rightAwayDate.setHours(0, 0, 0, 0);
        setRightAwayStartDate(rightAwayDate.getTime());
        campaignDetails.selectedCampaign.startDate = rightAwayDate.getTime();
      } else {
        setStartDate(startDate);
      }
      setEndDate(endDate);
      setInitialSegmentIds([...segmentIds]);
      campaignDetails.selectedCampaign.filterQuery = constructFilterQuery();
      dispatch(
        campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
      );
    }
  };

  // useEffect(() => {
  //   if (
  //     campaignDetails.selectedCampaign.segmentIds &&
  //     campaignDetails.selectedCampaign.segmentIds.length > 0 &&
  //   )
  //     setEnablePublishButton(true);
  //   else setEnablePublishButton(false);
  // }, [
  //   campaignDetails.selectedCampaign.segmentIds &&
  //     campaignDetails.selectedCampaign.segmentIds.length,
  // ]);

  useEffect(() => {
    if (
      campaignDetails.selectedCampaign.segmentIds &&
      campaignDetails.selectedCampaign.segmentIds.length > 0 &&
      campaignDetails.selectedCampaign.startDate &&
      campaignDetails.selectedCampaign.endDate != undefined &&
      peopleReducer.groupsProjectId == `LS-${projectkey}` &&
      peopleReducer.isGroupsFetched &&
      validStartAndEndDate && 
      isCampaignStateChanged()
    )
      setEnablePublishButton(true);
    else setEnablePublishButton(false);
  }, [
    campaignDetails.selectedCampaign.startDate,
    campaignDetails.selectedCampaign.endDate,
    campaignDetails.selectedCampaign.segmentIds &&
      campaignDetails.selectedCampaign.segmentIds.length,
      peopleReducer.groupsProjectId,
      peopleReducer.isGroupsFetched,
      validStartAndEndDate
  ]);

  useEffect(() =>{
    let selectedCampaign = campaignDetails.selectedCampaign;
    if( selectedCampaign.startDate && 
        selectedCampaign.endDate && 
        selectedCampaign.endDate != "Never" && 
        selectedCampaign.startDate > 
        selectedCampaign.endDate) {
      addToInvalids("invalidEndDate");
    }
    if( !selectedCampaign.startDate && selectedCampaign.endDate && selectedCampaign.endDate != DEFAULT_END_DATE ) {
      addToInvalids("startDate");
    }
  },[])

  useEffect(() => {
    if (
      fetchedBroadcastsProjectId && fetchedBroadcastsProjectId.includes(projectkey) &&
      peopleReducer.groupsProjectId && peopleReducer.groupsProjectId.includes(projectkey) &&
      Object.keys(peopleReducer.peopleGroupMap).length > 0 &&
      isPeopleCountFetched
    ) {
      setIsLoaded(true);
    }
  }, [prevIsCampaignFetched, isCampaignFetched, isPeopleCountFetched,campaignDetails.selectedCampaign.key,peopleReducer.isFetched,peopleReducer.isGroupsFetched]);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      if (showSegmentDropdown) {
        validateSelectedSegment();
        setShowSegmentDropdown(false);
      }
      if (showStartDateDropDown) {
        validateStartDate();
        setShowStartDateDropdown(false);
      }
      if (showEndDateDropDown) {
        validateEndDate();
        setShowEndDateDropdown(false);
      }
    }
  };

  const updateCount = (segmentId, updateType) => {
    let segmentDetails = peopleReducer.peopleGroupMap[segmentId];
    if (updateType === "ADD")
      setPeopleCount(peopleCount + Number(segmentDetails.count));
    else setPeopleCount(peopleCount - Number(segmentDetails.count));
  };

  const getSegmentsList = () => {
    let segmentListToRender = [];
    let validSegments = [];
    for (let segmentId in peopleReducer.peopleGroupMap) {
      let segmentDetails = peopleReducer.peopleGroupMap[segmentId];
      segmentDetails = differentiateSegments(segmentDetails);
      if (
        getGroupType(segmentDetails) != "companies" &&
        getGroupType(segmentDetails) != "segment"
      ) {
        if (!search) validSegments.push(segmentDetails);
        else if (
          search &&
          segmentDetails.groupName.toLowerCase().indexOf(search.toLowerCase()) >
            -1
        )
          validSegments.push(segmentDetails);
      }
    }
    validSegments = sortListByProperty(validSegments, "groupName");
    for (let segment of validSegments) {
      segmentListToRender.push(
        <li>
          <a
            key={segment.key}
            href="#"
            className={selectedSegments.includes(segment.key) ? "active" : ""}
            data-segmentId={segment.key}
            onClick={selectSegment}
          >
            <cite>
              <WhiteTickIcon />
            </cite>
            {segment.groupName}
          </a>
        </li>
      );
    }
    return segmentListToRender;
  };

  const toggleSegmentDropDown = () => {
    if (!showSegmentDropdown) {
      if (invalids.includes("segmentIds"))
        invalids.splice(invalids.indexOf("segmentIds"), 1);
      setInvalids(invalids);
    } else validateSelectedSegment();
    setShowSegmentDropdown(!showSegmentDropdown);
  };
  const validateSelectedSegment = () => {
    let { segmentIds = [] } = campaignDetails.selectedCampaign;
    if (segmentIds.length == 0 && !invalids.includes("segmentIds")) {
      invalids.push("segmentIds");
      setInvalids(invalids);
    }
  };

  const toggleShowAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const selectSegment = (event) => {
    event.preventDefault();
    event.stopPropagation();
    var selectedId = event.currentTarget.dataset.segmentid;
    let indexOfSelectedId = selectedSegments.indexOf(selectedId);
    if (indexOfSelectedId !== -1) {
      selectedSegments.splice(indexOfSelectedId, 1);
      updateReferences(event.currentTarget.dataset.segmentid, "DELETE");
      updateCount(selectedId, "DELETE");
      setSelectedSegments([...selectedSegments]);
    } else {
      selectedSegments.push(event.currentTarget.dataset.segmentid);
      setSelectedSegments([...selectedSegments]);
      updateReferences(event.currentTarget.dataset.segmentid, "ADD"); //should be changed based on all the segments
      updateCount(event.currentTarget.dataset.segmentid, "ADD");
    }
  };
  const getDropDownClassName = () => {
    console.log("Checking for classname :" + showSegmentDropdown);
    let className = "cs-dropdwn cs-dropdwn-checkbox cs-dropdwn-search-txt";
    if (showSegmentDropdown) className += " open";
    if (invalids.includes("segmentIds")) className += " cs-error-field";
    if (selectedSegments.length == 0) className += " cs-placeholder-txt";
    console.log("The value :", className);
    return className;
  };

  const updateReferences = (segmentId, updateType) => {
    let currentCampaignInfo = campaignDetails.selectedCampaign;
    let { references, filterQuery } = currentCampaignInfo;
    let currentReferences =
      typeof references === "string" ? JSON.parse(references) : references;
    let currentFilterQueries = filterQuery;
    let segmentDetails = peopleReducer.peopleGroupMap[segmentId];
    if (updateType === "ADD") {
      currentFilterQueries[segmentId] = JSON.parse(
        segmentDetails.completeQuery
      );
      currentReferences[segmentId] = segmentDetails.references;
    } else {
      delete currentFilterQueries[segmentId];
      delete currentReferences[segmentId];
    }

    currentCampaignInfo.references = JSON.stringify(currentReferences);
    currentCampaignInfo.filterQuery = currentFilterQueries;
    currentCampaignInfo.segmentIds = selectedSegments;
    dispatch(
      campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
    );
  };

  const getSelectedSegmentClips = () => {
    let segmentsToRender = [];
    if (selectedSegments.length && peopleReducer.peopleGroupMap) {
      let segmentId = selectedSegments[0];
      if (peopleReducer.peopleGroupMap[segmentId]) {
        let segmentDetails = peopleReducer.peopleGroupMap[segmentId];
        segmentsToRender.push(
          <div style={{ cursor: "pointer" }} class="cs-porject-clip">
            <i style={{ backgroundColor: "#6699ff" }}></i>
            <cite style={{ backgroundColor: "#6699ff" }}></cite>
            <span>{segmentDetails.groupName}</span>
          </div>
        );
      }
    }
    return segmentsToRender;
  };

  const getMoreSegmentsClip = () => {
    if (selectedSegments.length > 1) {
      return (
        <span
          style={{ cursor: "pointer" }}
          onClick={toggleSegmentDropDown}
          class="cs-porject-more"
          data-elementname="project"
        >{`${selectedSegments.length - 1} More`}</span>
      );
    } else {
      return "";
    }
  };

  const sendCampaignMessage = () => {
    if (enablePublishButton) {
      let currentCampaignInfo = campaignDetails.selectedCampaign;
      currentCampaignInfo.filterQuery = getfilterQueryArray(campaignDetails.selectedCampaign.filterQuery);
      if(currentCampaignInfo.campaignStatus == CAMPAIGN_STATUS_TO_ID.DRAFT)
        currentCampaignInfo.isPublishingForFirstTime = true;

      currentCampaignInfo.campaignStatus = CAMPAIGN_STATUS_TO_ID.LIVE;
      currentCampaignInfo.isNewCampaign = false;
      currentCampaignInfo.message = constructMessage();
      if (campaignDetails.selectedCampaign.endDate === "Never")
        currentCampaignInfo.endDate = 0;
      
      Event( getProjectId(projectkey), "Campaigns", "PublishCampaign", currentCampaignInfo.key );
      Event( getProjectId(projectkey), "Campaigns", "CampaignStart"+( startDate == "Right Away" ? "RightAway": "Custom"), startDate == "Right Away" ? "Right Away" : "Custom" )
      Event( getProjectId(projectkey), "Campaigns", "CampaignEnd"+( endDate == "Never" ? "Never" : "Custom"), endDate == "Never" ? "Never" : "Custom" )
      let segmentNames = "";
      for(let i = 0;i<selectedSegments.length; i++)
      {
        let segmentDetails = peopleReducer.peopleGroupMap[selectedSegments[i]];
        segmentNames += (segmentDetails.groupName+", ")
      }
      Event( getProjectId(projectkey),  "Campaigns", "CampaignSegments", segmentNames )
      Event( getProjectId(projectkey), "Campaigns", "NoOfSegment", selectedSegments.length+"" )
      Event( getProjectId(projectkey), "Campaigns", "NoOfContacts", peopleCount+"" )
    
      dispatch(sendBroadcastMessage(currentCampaignInfo, "LS-" + projectkey));
      dispatch(campaignActions.addSelectedCampaign({}));
      dispatch(campaignActions.updateCampaignFromDashboard(false));
      localStorage.setItem('isCampaignFromDashboard', "false");
    } else validateFields();
  };

  const getPeopleCount = () => {
    if (peopleCount === 1) {
      return <span>{`${peopleCount} Contact`}</span>;
    } else {
      return <span>{`${peopleCount} Contacts`}</span>;
    }
  };

  const getAdvancedOptions = () => {
    if (showAdvancedOptions) {
      return (
        <div class="cs-widget-config cs-business-hrs">
          <div>
            <label>Send time</label>
            <p>
              Configure timings for your campaign messages to be sent to your
              recipients
            </p>
          </div>
          <div class="cs-radio-custom">
            <div class="cs-radio-field">
              <label>
                <input type="radio" name="radio" />
                <cite></cite>9am - 5pm (Mon - Fri)
              </label>
            </div>
            <div class="cs-radio-field">
              <label>
                <input type="radio" name="radio" />
                <cite></cite>24/7
              </label>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  const onEditStartDate = (event, date) => {
    let startDateFromPicker = new Date(date);
    startDateFromPicker.setHours(0, 0, 0, 0);
    if (endDate && endDate != "Never" && startDateFromPicker > endDate) {
      addToInvalids("invalidEndDate");
      setValidStartAndEndDate(false);
    }
    if (endDate && endDate != "Never" && startDateFromPicker < endDate) {
      removeFromInvalids("invalidEndDate");
      setValidStartAndEndDate(true);
    }
    setStartDate(startDateFromPicker.getTime());
    setShowStartDateDropdown(false);
    setShowStartTimeDatePicker(false);
    let { selectedCampaign } = campaignDetails;
    selectedCampaign.startDate = startDateFromPicker.getTime();
    dispatch(campaignActions.addSelectedCampaign(selectedCampaign));
  };

  const onEditEndDate = (event, date) => {
    let endDateFromPicker = new Date(date);
    endDateFromPicker.setHours(23, 59, 59, 0);
    let startDateTime = startDate ? startDate === "Right Away" ? rightAwayStartDate : startDate : ""; 
    if (startDateTime && startDateTime < endDateFromPicker.getTime()) {
      removeFromInvalids("invalidEndDate");
      setValidStartAndEndDate(true);
    }
    else {
      addToInvalids("startDate");
      setShowEndDateDropdown(!showEndDateDropDown);
      setValidStartAndEndDate(false);
    }
    setEndDate(endDateFromPicker.getTime());
    setShowEndTimeDatePicker(false);
    setShowEndDateDropdown(false);
    let { selectedCampaign } = campaignDetails;
    selectedCampaign.endDate = endDateFromPicker.getTime();
    dispatch(campaignActions.addSelectedCampaign(selectedCampaign));
  };

  const updateStartTime = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let { selectedCampaign } = campaignDetails;
    setShowStartDateDropdown(false);
    if (event.target.dataset.value === "Right Away") {
      let startDateFromPicker = new Date();
      startDateFromPicker.setHours(0, 0, 0, 0);
      selectedCampaign.startDate = startDateFromPicker.getTime();
      setStartDate(event.target.dataset.value);
      setRightAwayStartDate(startDateFromPicker.getTime());
      setShowStartTimeDatePicker(false);
      removeFromInvalids("invalidEndDate");
      if (endDate && endDate === "Never" || endDate > startDateFromPicker.getTime()){
        removeFromInvalids("invalidEndDate");
        setValidStartAndEndDate(true);
      }
      else{
        addToInvalids("invalidEndDate");
        setValidStartAndEndDate(false);
      }
      dispatch(campaignActions.addSelectedCampaign(selectedCampaign));
    } else {
      setShowStartTimeDatePicker(true);
      setStartDate(event.target.dataset.value);
    }
  };

  const updateEndTime = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let { selectedCampaign } = campaignDetails;
    setShowEndDateDropdown(false);
    if (!startDate) addToInvalids("startDate");
    if (startDate) setValidStartAndEndDate(true);
    removeFromInvalids("invalidEndDate");
    if (event.target.dataset.value === "Never") {
      selectedCampaign.endDate = event.target.dataset.value;
      setEndDate(event.target.dataset.value);
      setShowEndTimeDatePicker(false);

      dispatch(campaignActions.addSelectedCampaign(selectedCampaign));
    } else {
      setShowEndTimeDatePicker(true);
      setEndDate(event.target.dataset.value);
    }
  };

  const toggleStartDateDropDown = (e) => {
    if (!startDateDropDownRef.current.contains(e.target)) {
      if (!showStartDateDropDown) {
        if (invalids.includes("startDate")) {
          invalids.splice(invalids.indexOf("startDate"), 1);
          setInvalids(invalids);
        }
        else if (invalids.includes("invalid start date")) {
          invalids.splice(invalids.indexOf("invalid start date"), 1);
          setInvalids(invalids);
        }
      } else validateStartDate();
      setShowStartDateDropdown(!showStartDateDropDown);
    }
  };
  const validateStartDate = () => {
    if (!startDate && !invalids.includes("startDate")) {
      invalids.push("startDate");
      setInvalids(invalids);
    }
  };

  const toggleEndDateDropDown = (e) => {
    if (!endDateDropDownRef.current.contains(e.target)) {
      if (!showEndDateDropDown) {
        if (invalids.includes("endDate"))
          invalids.splice(invalids.indexOf("endDate"), 1);
        setInvalids(invalids);
      } else validateEndDate();
      setShowEndDateDropdown(!showEndDateDropDown);
    }
  };
  const validateEndDate = () => {
    if (!endDate && !invalids.includes("endDate")) {
      invalids.push("endDate");
      setInvalids(invalids);
    }
  };
  const getStartDate = () => {
    var start = new Date();
    start.setHours(0, 0, 0, 0);
    if (
      typeof startDate === "string" &&
      startDate.toLowerCase() === "right away"
    ) {
      return startDate;
    } else if (!startDate) {
      return "Select Start Date";
    } else if (typeof startDate === "number") {
      return moment(startDate || new Date().getTime()).format("DD MMM YYYY");
    }
    // else {
    //   return (
    //     <DateFilter
    //       showPicker={showStartTimeDatePicker}
    //       onEdit={onEditStartDate}
    //       minDate={"today"}
    //       selectedFilter={{ value: start }}
    //     />
    //   );
    // }
  };

  const getEndDate = () => {
    var start = new Date();
    start.setHours(0, 0, 0, 0);
    if (typeof endDate === "string" && endDate.toLowerCase() === "never") {
      return endDate;
    }
    else if ( endDate == 0 ) {
      setEndDate("Never");
      return "Never";
    }
    else if (endDate == DEFAULT_END_DATE) {
      return "Select End Date";
    }
    /* else if (!endDate) {
      return "Select End Date";
    } */ else if (typeof endDate === "number") {
      return moment(endDate || new Date().getTime()).format("DD MMM YYYY");
    }
    // else {
    //   return (
    //     <DateFilter
    //       showPicker={showEndTimeDatePicker}
    //       onEdit={onEditEndDate}
    //       minDate={startDate === "Right Away" ? start : startDate}
    //       selectedFilter={{
    //         value: startDate === "Right Away" ? start : startDate,
    //       }}
    //     />
    //   );
    // }
  };

  const onCancel = () => {
   if (localStorage.getItem(IS_CAMPAIGN_FROM_DASHBOARD) !== STRINGIFIED_BOOL.FALSE) {
      browserHistory.push({
        pathname: "/app/" + props.params.projectkey + "/contacts/dashboard/" + localStorage.getItem(IS_CAMPAIGN_FROM_DASHBOARD),
      });
    } else {
    browserHistory.push({
      pathname: "/app/" + props.params.projectkey + "/campaign",
    });
    }
    ReactTooltip.hide();
  };

  const searchSegments = (event) => {
    let search = event.target.value;
    setSearch(search);
  };

  const constructMessage = () => {
    let {
      fallBackValues = {},
      message = "",
    } = campaignDetails.selectedCampaign;
    let fallbackStripedMap = {
      "{{firstName}}": "firstName",
      "{{lastName}}": "lastName",
      "{{fullName}}": "fullName",
      "{{companyName}}": "companyName",
    };

    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `{{ ${fallbackStripedMap[match]} | ${
          fallBackValues[match] || ""
        } }}`;
      }
    );

    message = message.replace(
      "+_from_",
      `<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;">From</span>${campaignDetails.selectedCampaign.campaignFromName}</p>`
    );
    // message = message.replace(
    //   "+_sender_info_",
    //   `<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;">Sender Name</span>${campaignDetails.selectedCampaign.campaignFromName}</p>`
    // );
    return message;
  };

  const getStartDropdownClass = () => {
    let dropdownClass = "cs-dropdwn";
    let startDate = getStartDate();
    if (showStartDateDropDown) dropdownClass += " open";
    if (typeof startDate === "string" && startDate.indexOf("Select") > -1)
      dropdownClass += " cs-placeholder-txt";
    if (invalids.includes("startDate") || invalids.includes("invalid start date")) dropdownClass += " cs-error-field";
    return dropdownClass;
  };
  const getEndDropdownClass = (type) => {
    let dropdownClass = "cs-dropdwn end-date";
    let endDate = getEndDate();
    if (showEndDateDropDown) dropdownClass += " open";
    if (typeof endDate === "string" && endDate.indexOf("Select") > -1)
      dropdownClass += " cs-placeholder-txt";
    if (invalids.includes("endDate") || invalids.includes("invalidEndDate")) dropdownClass += " cs-error-field";
    return dropdownClass;
  };
  const validateFields = () => {
    let invalidFields = Object.assign([], invalids);
    let { segmentIds = [] } = campaignDetails.selectedCampaign;
    if (segmentIds.length == 0) invalidFields.push("segmentIds");
    if (!startDate) invalidFields.push("startDate");
    if (!endDate || endDate == DEFAULT_END_DATE) invalidFields.push("endDate");
    setInvalids(invalidFields);
  };

  const getMinDateForEndDate = () => {
    return startDate
      ? startDate === "Right Away"
      : new Date().setHours(0, 0, 0, 0);
  };
  const getSelectedValueForEndDate = () => {
    let value = startDate
      ? startDate === "Right Away"
      : new Date().setHours(0, 0, 0, 0);
    return {
      value: value,
    };
  };

  const getMinValueForEndDate = () => {
    
    if( !startDate || startDate && startDate === "Right Away")
      return getLocalDateEndTimeOfDay();
    else 
      return startDate;
  }

 const addToInvalids = (invalidType) => {
   if(!invalids.includes(invalidType)) {
    let tempInvalids = Object.assign([],invalids);
    tempInvalids.push(invalidType);
    setInvalids(tempInvalids);
   }
 }
 const removeFromInvalids = (invalidType) => {
   if(invalids.includes(invalidType)) {
    let tempInvalids = Object.assign([], invalids);
    tempInvalids.splice(tempInvalids.indexOf(invalidType),1);
    setInvalids(tempInvalids);
   }
 }

 const hasSegmentIdsChanged = () => {
   if(initialSegmentIds.length != selectedSegments.length)
    return true;
   for (let index in selectedSegments)
     if (!initialSegmentIds.includes(selectedSegments[index])) return true;
   return false;
 };

 const hasCampaignInfoChanged = () => {
   const initialCampaignInfo = campaignData.filter((campaignInfo) => {
     return campaignInfo.key === campaignDetails.selectedCampaign.key;
   });
   if (!campaignId || !initialCampaignInfo[0]) return true;

   const currentCampaignInfo = Object.assign(
     {},
     campaignDetails.selectedCampaign
   );
   if (
     hasCampaignInfoUpdated(currentCampaignInfo, initialCampaignInfo[0]) ||
     hasSegmentIdsChanged() ||
     campaignDetails.selectedCampaign.campaignStatus == CAMPAIGN_STATUS_TO_ID.DRAFT
   )
     return true;
   return false;
 };

 const isCampaignStateChanged = () => {
   return hasCampaignInfoChanged();
 };

 if (isLoaded) {
  return (
    <div className={"cs-settings-wrp " + (miniNavBar ? "slide-col3" : "")}>
    <section class="widget-configuration">
      <h3>Publish</h3>
      <p>
        Finalize the recipients, send time, and how long your campaign will
        remain active.
      </p>
      <div class="cs-widget-config cs-business-hrs">
        <div>
            <label>{recipients ? RECIPIENTS_LABEL.RECIPIENTS: RECIPIENTS_LABEL.SELECT_RECIPIENTS }</label>
          <p>
            Choose the segment of contacts who will receive the campaign.{" "}
          </p>
        </div>

        <div
          id="segment_dropdown"
          className={getDropDownClassName()}
          ref={selectRecipientsRef}
        >
          <p onClick={toggleSegmentDropDown}>
            {selectedSegments.length > 0 ? "." : "Select Recipients"}
          </p>
          <TeamDropDownIcon />
          <aside
            onClick={toggleSegmentDropDown}
            class="cs-project-wrp segment-dwn"
          >
            {getSelectedSegmentClips()}
            {getMoreSegmentsClip()}
          </aside>
          <div>
            <div>
              <input
                ref={searchRef}
                type="text"
                placeholder="Select Segment"
                value={search}
                onChange={searchSegments}
              />
              <SearchIcon />
            </div>
            <ul id="segments_dropdown">{getSegmentsList()}</ul>
          </div>
          {invalids.includes("segmentIds") ? (
            <span>
              <ErrorIcon />
              Please select atleast one segment
            </span>
          ) : (
            ""
          )}
        </div>

        <span
          id="people_count"
          class="publish-count"
        >{`${peopleCount} Contacts`}</span>
      </div>

      <div class="cs-widget-config cs-business-hrs">
        <div>
          <label>Start Date</label>
          <p>
            Determine when your campaign should be set to live. You can set it
            live immediately or schedule it for a later day.
          </p>
        </div>
        <div
          id="start_date_div"
          className={getStartDropdownClass()}
          onClick={toggleStartDateDropDown}
          ref={startDateRef}
        >
          <p id="start_date_value">{getStartDate()}</p>

          <TeamDropDownIcon />
          <div ref={startDateDropDownRef}>
            <ul>
              <li>
                <a
                  id="start_date"
                  data-value="Right Away"
                  onClick={updateStartTime}
                  href="javascript:void(0)"
                >
                  Right Away
                </a>
              </li>
              <li>
                <DateFilter
                  showPicker={showStartTimeDatePicker}
                  onEdit={onEditStartDate}
                  minDate={"today"}
                  value={start}
                  isCustomElement={true}
                />
              </li>
            </ul>
          </div>
          {invalids.includes("startDate") ? (
            <span>
            <ErrorIcon />
            Please select start date
          </span>
          ) : invalids.includes("invalid start date") ? (
            <span>
              <ErrorIcon />
              invalid Start date
            </span>
          ) : ("")}
        </div>
      </div>

      <div class="cs-widget-config cs-business-hrs cs-btn-fixed">
        <div>
          <label>End Date</label>
          <p>
            Specify how long the campaign should be active. You can have it as
            a one-time campaign, choose when the campaign should stop sending.
          </p>
        </div>
        <div
          id="end_date_div"
          className={getEndDropdownClass()}
          onClick={toggleEndDateDropDown}
          ref={endDateRef}
        >
          <p id="end_date_value">{getEndDate()}</p>
          <TeamDropDownIcon />
          <div ref={endDateDropDownRef}>
            <ul>
              <li>
                <a
                  id="end_date"
                  data-value="Never"
                  onClick={updateEndTime}
                  href="javascript:void(0)"
                >
                  Never
                </a>
              </li>
              <li>
                { 
                  <DateFilter
                    showPicker={showEndTimeDatePicker}
                    onEdit={onEditEndDate}
                    minDate={getMinValueForEndDate()}
                    value={
                        startDate && startDate === "Right Away"
                          ? getLocalDateEndTimeOfDay()
                          : startDate
                    }
                    // minDate={getMinDateForEndDate()}
                    // selectedFilter={getSelectedValueForEndDate()}
                    isCustomElement={true}
                    isEndDate={true}
                  />
                 }
              </li>
            </ul>
          </div>
          {invalids.includes("endDate") ? (
            <span>
              <ErrorIcon />
              Please select end date
            </span>
          ) : invalids.includes("invalidEndDate") ? (
            <span>
              <ErrorIcon />
              Invalid end date
            </span>
          ) :  (
            ""
          )}
        </div>
      </div>

      {/* <a href="#" class="showmore-link">{showAdvancedOptions ? "Hide Advanced" : "Show Advanced"}
                  <svg>
                      <use xlinkHref="#dwn-uparrow-icon-blue"></use>
                  </svg>
              </a >

              <div class="cs-widget-config cs-business-hrs">
                  <div>
                      <label>Send time</label>
                      <p>Choose the time when your campaign should be sent to recipients.</p>
                  </div>
                  <div class="cs-radio-custom">
                      <div class="cs-radio-field">
                          <label><input type="radio" name="radio" /><cite></cite>9am - 5pm (Mon - Fri)</label>
                      </div>
                      <div class="cs-radio-field">
                          <label><input type="radio" name="radio" /><cite></cite>24/7</label>
                      </div>
                  </div>
              </div>
  */}
      <div class="campaign-btn-wrp cs-btn-bottom">
        <Link className="cs-btn cs-decline-btn" onClick={onCancel}>
          {" "}
          Cancel{" "}
        </Link>
        <a
          onClick={sendCampaignMessage}
          className={
            enablePublishButton
              ? "cs-btn cs-primary-btn"
              : "cs-btn cs-disabled-btn"
          }
          style={{ opacity: !enablePublishButton ? "0.5" : "1.0" }}
        >
          {campaignDetails &&
          campaignDetails.selectedCampaign &&
          campaignDetails.selectedCampaign.key &&
          campaignDetails.selectedCampaign.campaignStatus != CAMPAIGN_STATUS_TO_ID.DRAFT
            ? "Save"
            : "Publish"}
        </a>
      </div>
    </section>
  </div>
  )
 }
 return (
  <div className={"cs-settings-wrp " + (miniNavBar ? "slide-col3" : "")}>
  <section class="widget-configuration">
  <h3><em class="progress" style={{width : 200}}></em></h3>
      <p>
      <em class="progress" style={{width : 362}}></em>
      </p>
    <div class="cs-widget-config cs-business-hrs">
      <div>
      <h3><em class="progress" style={{width : 160}}></em></h3>
          <p>
          <em class="progress" style={{width : 280}}></em>
          </p>
      </div>

      <div
        id="segment_dropdown"
        className={getDropDownClassName()}
        ref={selectRecipientsRef}
      >
        <em class="progress" style={{width : 280}}></em>
      </div>

      <span
        id="people_count"
        class="publish-count"
      ><em class="progress" ></em></span>
    </div>

    <div class="cs-widget-config cs-business-hrs">
      <div>
      <h3><em class="progress" style={{width : 160}}></em></h3>
        <p>
        <em class="progress" style={{width : 280}}></em>
        </p>
      </div>
      <div
        id="start_date_div"
        className={getStartDropdownClass()}
        onClick={toggleStartDateDropDown}
        ref={startDateRef}
      >
        <em class="progress" style={{width : 280}}></em>
      </div>
    </div>

    <div class="cs-widget-config cs-business-hrs cs-btn-fixed">
      <div>
      <h3><em class="progress" style={{width : 160}}></em></h3>
        <p>
        <em class="progress" style={{width : 280}}></em>
        </p>
      </div>
      <div
        id="end_date_div"
        className={getEndDropdownClass()}
        onClick={toggleEndDateDropDown}
        ref={endDateRef}
      >
        <em class="progress" style={{width : 280}}></em>
      </div>
    </div>
  </section>
</div>
//end of the first part)
 )
  
  };

export default PublishCampaign;
