/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react'
import {
  Container,
  Progress,
  ProgressContainer,
  StepLabel,
} from 'src/features/wizard/shared/components/BreadcrumbMobile/styled'
import { IProps } from 'src/features/wizard/shared/components/BreadcrumbMobile/types'

export const BreadcrumbMobile: FC<IProps> = ({ stepsTotal, currentStep }) => (
  <Container>
    <ProgressContainer>
      {Array(stepsTotal)
        .fill(undefined)
        .map((_value, index) => (
          <Progress
            key={index}
            isComplete={currentStep > index}
            widthPercent={100 / stepsTotal}
          />
        ))}
    </ProgressContainer>
    <StepLabel>
      Step {currentStep} of {stepsTotal}
    </StepLabel>
  </Container>
)
