import React, { useState , useEffect } from "react";
import { useSelector } from "react-redux";
import GroupsHolder from "../groups/GroupsHolder.js";
import ContactsHolder from "../grid/ContactsHolder.js";
import SaveSegmentPopup from "../popups/SaveSegmentPopup.js";
import NoCustomersLoader from '../commons/NoCustomersLoader.jsx'
import NoCompaniesLoader from '../commons/NoCompaniesLoader.jsx'
import NoLeadsLoader from '../commons/NoLeadsLoader.jsx'
import NoVisitorLoader from '../commons/NoVisitorLoader.jsx'
import { getCategories, getGroupType } from "../commons/ContactsUtility.js";
import Detail from '../detail/DetailContainer.js'
import ImportContactsPopUp from "../popups/ImportContactsPopUp/ImportContactsPopUp.jsx";


const ContactsWrapper = (props) => {
  const [showSaveSegment, setShowSaveSegment] = useState(false);
  const [groups, setGroups] = useState({});
  const { contacts, query } = useSelector((state) => ({
    contacts: state.PeopleReducer,
    query: state.PeopleESQueryReducer,
  }));

  let { fetchingCount=0 ,isGroupsFetched , isFetched } = contacts;
  const [isEmptyGroup , setIsEmptyGroup ] = useState(false);
  const setCategories = (groupsList) => {
    let categories = getCategories(groupsList);
    setGroups({ ...categories });
  };
  useEffect(()=>{
    if(isGroupsFetched && isFetched && fetchingCount === Object.keys(contacts.peopleGroupMap).length){
      let group = contacts.peopleGroupMap[props.params.groupId];
      if(group){
        let groupCount = group.count ? group.count : 0;
        setIsEmptyGroup(groupCount == 0);
      }
    }
  } ,  
  [isGroupsFetched, isFetched, fetchingCount, props.params.groupId])

  const getEmptyPlaceHolder = () => {
    let group = contacts.peopleGroupMap[props.params.groupId];
    let placeHolderObject = {
      'customers' : <NoCustomersLoader/>,
      'leads' : <NoLeadsLoader/>,
      'visitors' : <NoVisitorLoader/>,
      'companies' : <NoCompaniesLoader/>,
    }

    let groupType = getGroupType(group);
      return placeHolderObject[groupType];
  }

  const getRightColumn = () => {
      let {visitorId} = props.params;
      let rightColumn;
      if(visitorId) 
        rightColumn = <Detail {...props}/>
      else 
        rightColumn = <ContactsHolder
                        isEmptyGroup={isEmptyGroup}
                        placholderComponent = {getEmptyPlaceHolder()}
                        contacts={contacts}
                        query={query}
                        groups={groups}
                        params={props.params}
                        setShowSaveSegment={setShowSaveSegment}/>
      return rightColumn;
  } 

  return (
    <div>
      <GroupsHolder
        groups={groups}
        contacts={contacts}
        query={query}
        setCategories={setCategories}
        params={props.params}
      />
      {getRightColumn()}
      <SaveSegmentPopup
        contacts={contacts}
        query={query}
        params={props.params}
        showSaveSegment={showSaveSegment}
        setShowSaveSegment={setShowSaveSegment}
      />
      {contacts.showImportContactPopUp ? <ImportContactsPopUp  params={props.params} importedContacts={contacts.importedContacts}/> : ""}
    </div>
  );
};

export default ContactsWrapper;
