import React, { Component } from "react";
import { getDOMRootElement, getErrorMessageStyle, getLogoUrl } from "../commons/Utility";
import OnboardFooter from "./OnboardFooter";
class Onboard extends Component {
  constructor() {
    super();
    this.riseWidgetPosition = this.riseWidgetPosition.bind(this);
    this.resetWidgetPosition = this.resetWidgetPosition.bind(this);
  }

  componentDidMount() {
    this.refs.password.focus();
    this.riseWidgetPosition();
  }

  componentWillUnmount() {
    this.resetWidgetPosition();
  }

  riseWidgetPosition() {
    const rootElement = getDOMRootElement();
    if (this.isRootElementExist(rootElement, "onboard")) {
      rootElement.classList.add("onboard");
    }
  }

  resetWidgetPosition() {
    const rootElement = getDOMRootElement();
    if (this.isContainedInRootElement(rootElement, "onboard")) {
      rootElement.classList.remove("onboard");
    }
  }

  isRootElementExist(rootElement, string){
    return (
      rootElement &&
      rootElement.classList &&
      !rootElement.classList.contains(string)
    );
  }

  isContainedInRootElement(rootElement, string) {
    return (
      rootElement &&
      rootElement.classList &&
      rootElement.classList.contains(string)
    );
  }
  
  render() {
    const { localState, handlers } = this.props;
    const { error } = localState;
    return (
      <div className="loginWrp">
        <section
          className="onboardHolder"
        >
          <div className="left-side">
            <a href="https://chatsupport.co/" target="_blank" class="chatsupport-logo">
              <img src={getLogoUrl(document.location.origin)} />
            </a>
            <div>
              <h2>
                Join your team on ChatSupport<span className="blue-dot">.</span>
              </h2>
              <fieldset>
              <div className={getErrorMessageStyle(error.firstName)}>
                  <label>Name</label>
                  <input
                    className="fs-exclude capitalized-case"
                    type="text"
                    aria-label="First Name"
                    ref="first_name"
                    onKeyUp={handlers.onKeyPress}
                    onChange={handlers.onKeyPress}
                    tabIndex="-1"
                    placeholder="First Name"
                  />
                  <p style={{ display: error.firstName ? "block" : "none" }}>
                    {error.firstName ? error.firstName : ""}
                  </p>
                </div>

                <div className={getErrorMessageStyle(error.password)}>
                  <label>Password</label>
                  <input
                    className="fs-exclude"
                    type="password"
                    ref="password"
                    onKeyDown={handlers.onKeyPress}
                    onKeyUp={handlers.onKeyPress}
                    tabIndex="1"
                    placeholder="Atleast 6 characters"
                  />
                  <p style={{ display: error.password ? "block" : "none" }}>
                    {error.password ? error.password : ""}
                  </p>
                </div>
              </fieldset>
              <small>
                By creating an account, you agree to our{" "}
                <a
                  href="https://chatsupport.co/terms"
                  target="_blank"
                  className="blue-dot"
                >
                  Terms of Use
                </a>
                <span>&nbsp;&&nbsp;</span>
                <a
                  href="https://chatsupport.co/privacy-policy"
                  target="_blank"
                  className="blue-dot"
                >
                  Privacy Policy.
                </a>
              </small>
              <div className="btn-wrapper">
                <a
                  className="cs-btn-new"
                  tabIndex="2"
                  onClick={handlers.switchCrumbs}
                  onKeyDown={() => {}}
                >
                  Join Team
                </a>
              </div>
            </div>
          </div>
          <div className="right-side">
            <picture data-aos="fade-up">
              <source
                srcset="https://assets.chatsupport.co/webapp/images/lady-inside-frame.webp"
                type="image/webp"
              />
              <source
                srcset="https://assets.chatsupport.co/webapp/images/lady-inside-frame.png"
                type="image/png"
              />
              <img
                src="https://assets.chatsupport.co/webapp/images/lady-inside-frame.png"
                alt="Lady smiling inside a frame"
              />
            </picture>
          </div>
          <OnboardFooter />
        </section>
      </div>
    );
  }
}

export default Onboard;
