import React, { useEffect } from "react";
import Detail from './Detail.jsx';
import { useSelector, useDispatch } from "react-redux";
import * as peopleESQueryAction from '../actions/SearchAction';
import {getProjectId} from '../../commons/Utility.js';

const DetailContainer = (props) => {
    const { visitorId, projectkey } = props.params;
    let projectId = getProjectId(projectkey);
    console.info("visiorId:",visitorId);
    const { contacts, esQuery } = useSelector((state) => ({
        contacts: state.PeopleReducer,
        esQuery: state.PeopleESQueryReducer
    }));
    const dispatch = useDispatch();
    const people = contacts && contacts.peopleMap ? contacts.peopleMap[visitorId] : null;
    useEffect(() => {
        if(visitorId && !people) {
            let esQueryObj = Object.assign({}, esQuery);
            let peopleReq = esQueryObj.query;
            peopleReq.projectId = projectId;
            peopleReq.filter = {};
            peopleReq.match = { key: visitorId };
            esQueryObj.isSingleRequested = true;
            dispatch(peopleESQueryAction.updatePeopleESQuery(esQueryObj));
        }
    }, [visitorId])
    return (<Detail people={people} {...props}/>)
}

export default DetailContainer;