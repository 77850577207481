import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import NewProjectPopup from "../components/projects/NewProjectPopup.jsx";
import * as ProjectActions from "../settings/actions/ProjectAction";
import * as NotificationActions from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as util from "../commons/Utility.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";

@connect((state) => ({
  project: state.ProjectReducer,
  user: state.UserReducer,
}))
class NewProjectContainer extends Component {
  constructor() {
    super();
    this.state = {
      invitedStaffs: [],
      display: false,
      error: {},
    };

    this.createNewProject = this.createNewProject.bind(this);
    this.addStaffToInvite = this.addStaffToInvite.bind(this);
    this.onEnterHandler = this.onEnterHandler.bind(this);
    this.hideNewProjectPopup = this.hideNewProjectPopup.bind(this);
    this.removeStaff = this.removeStaff.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.project.showNewProjectPopup &&
      nextProps.project.showNewProjectPopup
    ) {
      this.setState({ display: true });
    } else if (
      this.props.project.showNewProjectPopup &&
      !nextProps.project.showNewProjectPopup
    ) {
      this.setState({ display: false });
    }
  }

  createNewProject() {
    let url = this.child.refs.project_url.value.trim();
    let projectName = this.child.refs.project_name.value.trim();

    if (projectName == "") {
      this.setState({
        error: {
          ...this.state.error,
          projectName: "project name can't be empty",
        },
      });
      return;
    }
    if (url != "" && !util.isValidURL(url)) {
      this.setState({
        error: { ...this.state.error, projectUrl: "project url is not valid" },
      });
      return;
    }

    this.props.dispatch(
      ProjectActions.createNewProject({
        name: projectName,
        weburl: url,
        staffList: this.state.invitedStaffs,
      })
    );

    this.props.dispatch(ProjectActions.hideNewProjectPopup());

    this.child.refs.project_name.value = "";
    this.child.refs.project_url.value = "";
    this.child.refs.staffEmailId.value = "";
    this.setState({ invitedStaffs: [], error: {} });
  }

  hideNewProjectPopup() {
    this.props.dispatch(ProjectActions.hideNewProjectPopup());
    this.child.refs.project_name.value = "";
    this.child.refs.project_url.value = "";
    this.child.refs.staffEmailId.value = "";
    this.setState({ invitedStaffs: [], error: {} });
  }

  addStaffToInvite() {
    let staffEmailId = this.child.refs.staffEmailId.value.trim();
    let isActiMail = staffEmailId.endsWith("a-cti.com");

    if (!util.isValidEmail(staffEmailId)) {
      this.setState({
        error: {
          ...this.state.error,
          projectStaff: "Oops, email doesn't look right",
        },
      });
      return;
    } else if (staffEmailId == this.props.user.data.login) {
      this.setState({
        error: {
          ...this.state.error,
          projectStaff: "You can't invite yourself",
        },
      });
      return;
    } else if (isActiMail) {
      this.setState({
        error: {
          ...this.state.error,
          projectStaff: "Oops, invalid email domain!",
        },
      });
      return;
    }

    this.setState((prevState, props) => ({
      invitedStaffs: prevState.invitedStaffs.concat(staffEmailId),
    }));
    this.child.refs.staffEmailId.value = "";
    this.child.refs.staffEmailId.focus();
  }

  onEnterHandler(event) {
    if (event.charCode == 13) {
      this.addStaffToInvite();
    }
  }

  removeStaff(event) {
    event.persist();
    let staff = event.target.parentNode.id;

    this.setState((prevState, props) => {
      let staffIndex = prevState.invitedStaffs.indexOf(staff);
      let invitedStaffs = prevState.invitedStaffs.slice();

      invitedStaffs.splice(staffIndex, 1);

      return { invitedStaffs };
    });
  }

  keyUpHandler(event) {
    let url = this.child.refs.project_url.value.trim();
    let name = this.child.refs.project_name.value.trim();
    let email = this.child.refs.staffEmailId.value.trim();

    if (
      this.state.error.projectName ||
      this.state.error.projectUrl ||
      this.state.error.projectStaff
    ) {
      if (document.activeElement == this.child.refs.project_name) {
        if (name.length > 0)
          this.setState({ error: { ...this.state.error, projectName: "" } });
      } else if (document.activeElement == this.child.refs.project_url) {
        if (url.length == 0 || util.isValidURL(url))
          this.setState({ error: { ...this.state.error, projectUrl: "" } });
      } else if (document.activeElement == this.child.refs.staffEmailId) {
        if (
          email.length == 0 ||
          (util.isValidEmail(email) && event.key != "Enter")
        )
          this.setState({ error: { ...this.state.error, projectStaff: "" } });
      }
    }
  }

  render() {
    return (
      <NewProjectPopup
        ref={(component) => (this.child = component)}
        onClick={this.createNewProject}
        onKeyUp={this.keyUpHandler}
        onClose={this.hideNewProjectPopup}
        addStaff={this.addStaffToInvite}
        removeStaff={this.removeStaff}
        onKeyPress={this.onEnterHandler}
        staff={this.state.invitedStaffs}
        error={this.state.error}
        display={this.state.display}
      />
    );
  }
}

export default NewProjectContainer;
