import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import {
  EMAIL_OPT_OUT_DESCRIPTION,
  EMAIL_OPT_OUT_DISPLAY_NAMES,
} from "../commons/Constants.js";
import { getDecrypted, getProjectId } from "../commons/Utility";
import {
  requestEmailOptOutPreferences,
  requestUpdateEmailOptOut,
} from "../actions/EmailOptOutAction";
import OptedOutAll from "../components/emailoptout/OptedOutAll.jsx";

@connect((state) => ({ emailOptOut: state.EmailOptOutReducer }))
class OptOutAllContainer extends Component {
  constructor() {
    super();
    this.state = {
      emailOptOutPreferences: {
        unsubscribedList: new Set(),
        unsubscribeFromAll: false,
        projectId: "",
        key: "",
      },
      emailId: "",
      hasFetchedPreferences: false,
    };

    this.optBackInAndRestorePreferences = this.optBackInAndRestorePreferences.bind(
      this
    );
    this.hideChatBoxWhenLoaded = this.hideChatBoxWhenLoaded.bind(this);
  }

  componentWillMount() {
    let {
      params: { projectId, visitorInfo: base64VisitorInfo },
    } = this.props;
    let {
      emailOptOut: {
        isFetched: preferencesAlreadyFetched,
        emailOptOutPreferences,
      },
    } = this.props;

    projectId = getProjectId(projectId);
    let { visitorId, emailId } = JSON.parse(getDecrypted(base64VisitorInfo));

    if (!preferencesAlreadyFetched) {
      this.setState({
        emailOptOutPreferences: {
          ...this.state.emailOptOutPreferences,
          key: visitorId,
          projectId,
        },
        emailId,
        hasFetchedPreferences: false,
      });
      this.props.dispatch(requestEmailOptOutPreferences(visitorId));
    } else {
      this.setState({
        emailOptOutPreferences,
        emailId,
        hasFetchedPreferences: true,
      });
      if (!emailOptOutPreferences.unsubscribeFromAll)
        this.routeToPreferencePage();
    }
  }

  componentWillReceiveProps(nextProps) {
    let {
      isFetching: wasFetchingPreferences,
      isUpdating: wasUpdatingPreferences,
    } = this.props.emailOptOut;
    let {
      isFetched: hasFetchedPreferences,
      isUpdated: hasUpdatedPreferences,
    } = nextProps.emailOptOut;
    let { emailOptOutPreferences } = nextProps.emailOptOut;

    if (hasFetchedPreferences && !emailOptOutPreferences.unsubscribeFromAll)
      this.routeToPreferencePage();

    if (wasFetchingPreferences && hasFetchedPreferences) {
      this.setState({ emailOptOutPreferences, hasFetchedPreferences: true });
      if (!emailOptOutPreferences.unsubscribeFromAll)
        this.routeToPreferencePage();
    }

    if (wasUpdatingPreferences && hasUpdatedPreferences) {
      this.setState({ emailOptOutPreferences, hasFetchedPreferences: true });
      if (!emailOptOutPreferences.unsubscribeFromAll)
        this.routeToPreferencePage();
    }
  }

  componentDidMount() {
    window.onload = () => {
      this.hideChatBoxWhenLoaded();
    };
  }

  hideChatBoxWhenLoaded() {
    var chatbox = document.getElementById("ls-chat");

    if (chatbox) chatbox.style.display = "none";
    else setTimeout(this.hideChatBoxWhenLoaded, 100);
  }

  optBackInAndRestorePreferences() {
    let { emailOptOutPreferences } = this.state;
    emailOptOutPreferences.unsubscribeFromAll = false;
    let { visitorInfo } = this.props.params;
    let { projectId } = emailOptOutPreferences;

    this.props.dispatch(
      requestUpdateEmailOptOut(emailOptOutPreferences, "optBackIn")
    );
  }

  routeToPreferencePage() {
    let { visitorInfo, projectId } = this.props.params;
    browserHistory.push({
      pathname: "/email/optout/" + projectId + "/" + visitorInfo,
    });
  }

  render() {
    return (
      <OptedOutAll
        ref={(component) => (this.child = component)}
        optBackInAndRestorePreferences={this.optBackInAndRestorePreferences}
        localState={this.state}
      />
    );
  }
}

export default OptOutAllContainer;
