import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_WIDGET_BUBBLE_CONTENT, DEFAULT_WIDGET_HEADER, WIDGET_POSITIONS, WIDGET_TYPE } from "../../commons/Constants";
import { ScreenshotImage } from "../../commons/Icons";
import { EventEmitter } from "../../settings/commons/event";

const PreviewWidget = ({
  widgetColor,
  widgetIcon,
  projectId,
  opacity,
  widgetPosition,
  widgetType,
  widgetHeader,
  widgetBubbleContent,
  showMessages,
}) => {
  const [showWidget, setShowWidget] = useState(true);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");

  const { projects = {}, userReducer = {} } = useSelector((state) => ({
    projects: state.ProjectReducer,
    userReducer: state.UserReducer,
  }));

  useEffect(() => {
    let userData = userReducer.data || {};
    let { firstName, photoID } = userData;
    if (firstName && photoID) {
      setUserName(firstName);
      setUserImage(photoID);
    }
  }, [userReducer.isFetched]);

  useEffect(() => {
    setShowChatWindow(showMessages);
  },  [showMessages]);

  const getProjectDetails = () => {
    const projectKey = projectId;
    let projectList = projects.projectList || [];
    let projectDetail = projectList.find(
      (project) => project && project.key === projectKey
    );
    projectDetail = Object.assign({}, projectDetail);
    return projectDetail;
  };

  const showWidgetHandler = () => {
    setShowChatWindow(false);
    setShowWidget(true);
  };

  const showChatWindowHandler = () => {
    setShowChatWindow(true);
    setShowWidget(false);
  };

  const getUrl = () => {
    if (project && project.websiteScreenshotURL) {
      return <img src={project.websiteScreenshotURL} />;
    } else {
      return <ScreenshotImage />;
    }
  };

  const getUserName = () => {
    if (userName) return userName;
    else return (project && project.name) || "";
  };

  // user details has to be there
  if (!widgetIcon) {
    widgetIcon = "default";
  }

  if (!widgetColor) {
    widgetColor = "3c4859";
  }

  let project = getProjectDetails() || {};

  const getImageWithIcon = () => {
    console.log("Style :", {
      borderColor: `#${widgetColor} `,
      opacity: opacity,
    });
    if (widgetIcon && widgetIcon.toLowerCase() !== "default")
      return (
        <img
          src={widgetIcon}
          style={{ borderColor: `#${widgetColor}`, opacity: opacity }}
        />
      );
    else return "";
  };

  const getUserImage = () => {
    if (userImage && !userImage.includes("no-image")) {
      return userImage;
    } else {
      return `https://storage.googleapis.com/livesupport/chat/images/Staff-Avatar.svg`;
    }
  };

  console.log(`Color is ${widgetColor} and the icon ${widgetIcon}`);

  const getClassName = () => {
    if (widgetPosition === WIDGET_POSITIONS[0]) return "bottom-right";
    else if (widgetPosition === WIDGET_POSITIONS[1] || !widgetPosition)
      return "bottom-left";
    else if (widgetPosition === WIDGET_POSITIONS[2]) return "top-right";
    return "top-left";
  };

  return (
    <div>
      <div className="cs-iframe fs-exclude">{getUrl()}</div>
      <div className={"preview-chat-panel hide-chat-view " + getClassName()}>
        <code
          onClick={showChatWindowHandler}
          className={showWidget ? "widget-icon db" : "widget-icon dn"}
          style={
            widgetIcon && widgetIcon.toLowerCase() === "default"
              ? { backgroundColor: `#${widgetColor}` }
              : {}
          }
        >
          <i style={{ borderColor: `#${widgetColor}` }}></i>
          {widgetType === WIDGET_TYPE.DEFAULT || !widgetType ? (
            <span>
              <b>
                {!widgetBubbleContent || widgetBubbleContent === DEFAULT_WIDGET_BUBBLE_CONTENT
                  ? "Chat with us"
                  : widgetBubbleContent}
              </b>
            </span>
          ) : (
            ""
          )}
          {getImageWithIcon()}
        </code>
        <div className={showChatWindow ? "preview-chat db" : "preview-chat dn"}>
          <div className="preview-chat-heading">
            <code onClick={showWidgetHandler} id="close-btn">×</code>
            {!widgetHeader || widgetHeader === DEFAULT_WIDGET_HEADER ? (
              <h2>
                We're
                <br />
                happy to chat<span>.</span>
              </h2>
            ) : (
              <h2>{widgetHeader}</h2>
            )}
          </div>
          <div className="preview-chat-body">
            <div className="chat-from chat-first">
              <div className="txt">
                We're real people here, not bots. Please ask your question and
                we'll be with you in a moment.
              </div>
            </div>

            <div className="chat-from chat-join">
              <div id="staff_join_msg">{`- You are now chatting with  ${getUserName()} -`}</div>
            </div>

            <div className="chat-from">
              <img id="user_img" src={getUserImage()} />
              <div className="txt">
                <p>{`Hey there, I’m ${getUserName()}.`}</p>
              </div>
              <small>1 minute ago</small>
            </div>

            <div className="chat-from">
              <img src={getUserImage()} />
              <div className="txt">
                <p id="name_txt">{`Welcome to ${
                  project.name || "our website!"
                } How may I help you today?`}</p>
              </div>
              <small>1 minute ago</small>
            </div>

            <div className="chat-to">
              <div className="txt">
                Hi, I have added the widget to my website. Is there a way to
                customize it?
              </div>
              <small>
                just now<i className="read-icon"></i>
              </small>
            </div>
          </div>
          <div className="preview-chat-text">
            <textarea
              placeholder={`Send your message to ${getUserName()}...`}
              data-gramm_editor="false"
              value=""
              disabled
            />
            <div className="preview-chat-composer">
              <i className="attachment"></i>
              <i
                className="send-msg"
                style={{ backgroundColor: `#${widgetColor}` }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewWidget;
