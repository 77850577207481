export enum EnumPostAccountErrors {
  MISSING_REQUIRED_PARAM = 'Invalid form data.',
  RESOURCE_ALREADY_EXISTS = 'An account already exists with this email. Please choose another or sign-in.',
}

export interface ISignUpDTO {
  emailId: string
  password: string
}

export interface ISignUpResponse {
  data: {
    'type': string
    'X-Client-Token': string
    'ssotoken': string | null
    'account': {
      deleted: boolean
      createdDate: number
      lastUpdatedDate: number
      brandID: string
      name: string
      linkedContacts: string[]
      parentAccountID: string
      id: string
      source: string
      linkedProducts: string[]
      isFHIR: boolean
    }
    'contact': {
      deleted: boolean
      id: string
      login: string
      firstName: string
      lastName: string | null
      password: string
      linkedAccounts: string[]
      createdDate: number
      lastUpdatedDate: number
      accountID: string
    }
    'id': string
    'linkedAccounts': string[]
    'accountID': string
    'login': string
    'widget': {
      id: string
    }
  }
}
