import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ChatDashboardHeader from "./ChatDashboardHeader.jsx";
import ActivChatView from "../../containers/ActiveChatViewContainer.js";
import VisitorDetails from "../../containers/VisitorDetailsContainer.js";
import { getIPActivity } from '../../actions/PeopleAction.js'
import {
  isAwWindow,
  getProjectId,
  isAdvancedFilterSearch,
  isMineActiveChatPage,
  isAssigneeIdExistInChatDashboardRoute,
} from "../../commons/Utility.js";
import NoChatsLoader from '../../components/chat/NoChatsLoader.jsx'
import { SEARCH_FILTERS_PLACEHOLDER } from "../../commons/Constants.js";
import NoConversationsHeader from "./NoConversationsHeader.jsx";
import { enableConversationTabSwitching } from "../../actions/VisitorQueueActions.js";

 
@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  VisitorQueueReducer: state.VisitorQueueReducer,
  PeopleReducer: state.PeopleReducer,
  setUpReducer: state.SetupReducer
}))
class ChatSection extends Component {
  constructor() {
    super();
    this.state = {
      displayVisitorDetails: false,
    };
    this.showVisitorDetails = this.showVisitorDetails.bind(this);
    this.hideVisitorDetails = this.hideVisitorDetails.bind(this);
  }
  componentWillMount() {
    /* let isAwWindow = this.props.parentProps.dashboardData.state.isAwWindow;
		this.setState({...this.state, displayVisitorDetails : isAwWindow ? false : true }) */
  }
  showVisitorDetails() {
    if (this.state.displayVisitorDetails != true)
      this.setState({ ...this.state, displayVisitorDetails: true });
  }
  hideVisitorDetails() {
    if (this.state.displayVisitorDetails != false)
      this.setState({ ...this.state, displayVisitorDetails: false });
  }
  componentDidUpdate(prevProps, prevState) {
    let projectId = getProjectId(this.props.params.projectkey);
    if( (this.props.params.projectkey && prevProps.params.projectkey != this.props.params.projectkey
          || !this.props.PeopleReducer['ipStatusMap'][projectId]) && !this.props.PeopleReducer.isFetchingIPStatus)  
      this.props.dispatch(getIPActivity(projectId));
  }


  getConversationListPlaceHolder () {
    if (
      this.props &&
      this.props.params
    ) {
      let chatStatus = this.props.params.chatStatus;
      let assignedTo = this.props.params.assignedTo;
      let pathName = document.location.pathname;
      if (chatStatus === "A") return SEARCH_FILTERS_PLACEHOLDER.ALL_CHATS;
      if (chatStatus === "O") return SEARCH_FILTERS_PLACEHOLDER.OPEN_CHATS;
      if (chatStatus === "C") return SEARCH_FILTERS_PLACEHOLDER.RESOLVED_CHATS;
      if (chatStatus === "R") return SEARCH_FILTERS_PLACEHOLDER.RECEPTIONIST;
      if (chatStatus === "P") return SEARCH_FILTERS_PLACEHOLDER.PENDING_CHATS;
      if (
        (chatStatus === "T" || isAdvancedFilterSearch(chatStatus)) &&
        (this.props.location.state ||
          this.props.ConversationReducer.searchText)
      ) {
        // let searchTextVal = this.props.dashboardData.props.location.state
        //   ? this.props.dashboardData.props.location.state
        //       .nonEncryptedSearchTextFilter
        //   : this.props.dashboardData.props.ConversationReducer.searchText;
          return `No chats found`
      }
      if (assignedTo !== "All" && pathName.includes(assignedTo))
        return SEARCH_FILTERS_PLACEHOLDER.ALL_CHATS;
    }
  }

  render() {
    let params = this.props.params;
    let conversationReducer = this.props.ConversationReducer;
    let isFetching = conversationReducer.isFetching;
    let conversationObject = conversationReducer.conversationObject;
    let conversationMap = conversationObject.conversationMap;
    let visitorConversationMap =
      conversationReducer.visitorConversationObject.conversationMap;
    let totalVisitorConversations = Object.keys(visitorConversationMap).length;
    let chosenConvesationId = params.conversationId;

    // if( chosenConvesationId && visitorConversationMap && totalVisitorConversations == 1 && visitorConversationMap.hasOwnProperty( chosenConvesationId )  )

    let contactsMap = conversationObject.contactsMap;
    let conversation = conversationMap[params.conversationId]
      ? conversationMap[params.conversationId]
      : {};
    let contact = contactsMap[conversation.visitorId]
      ? contactsMap[conversation.visitorId]
      : null;
    let pathQueryString = this.props.location.search;
    let queryParams = this.props.location.query;
    let visitorDetailsClassNames = [];
    if (contact == null) {
      console.log(
        "The contact information is not there so copying it from visitor queue reducer"
      );
      contact = this.props.VisitorQueueReducer.visitorMap[
        conversation.visitorId
      ]
        ? this.props.VisitorQueueReducer.visitorMap[conversation.visitorId]
            .peopleEntity
        : null;
    }

    let isEmptyConverastion = Object.keys(conversationMap).length === 0;
    let isSearchPage = this.props.location.pathname.includes("search");
    let isFullScreenPage = document.location.search ? document.location.search.includes("isFullscreen") : false;
    let isLoadingMore = isFetching && !isEmptyConverastion;
    const isNoChats = () =>
      !isSearchPage &&
      !isFullScreenPage &&
      !isFetching &&
      !isMineActiveChatPage() &&
      !isAssigneeIdExistInChatDashboardRoute() &&
      conversationObject.isConversationMapEmpty;
    if (!isEmptyConverastion && contact && this.props.VisitorQueueReducer.isInitialFetchComplete) {
      if(!this.props.VisitorQueueReducer.showConversationList){
        this.props.dispatch(enableConversationTabSwitching());
      }
      return (
        <section
          className={
            "cs-conversation" + (isAwWindow() ? " aw-integration" : "")
          }
          style={isAwWindow() ? { left: "0px" } : {}}
        >
          <ChatDashboardHeader
            location={this.props.location}
            contact={contact}
          />
          <ActivChatView
            isFromChatDashboard={true}
            params={params}
            pathQueryString={pathQueryString}
            query={queryParams}
          />
          <VisitorDetails
            classNames={visitorDetailsClassNames}
            params={params}
            visitorId={contact.key}
            displayVisitorDetails={this.state.displayVisitorDetails}
            hideVisitorDetails={this.hideVisitorDetails}
          />
          {isAwWindow() ? (
            <code
              data-tip="Show Visitor Details"
              data-delay-hide={0}
              data-class="tooltip info"
              className={
                "showVisitorDetails" +
                (this.state.displayVisitorDetails ? " dn" : "")
              }
              onClick={this.showVisitorDetails}
              id="showVisitorDetailsButton"
            ></code>
          ) : (
            ""
          )}
        </section>
      );
    }

    if (isNoChats()) {
      let widgetState = (this.props.setUpReducer && this.props.setUpReducer.setupState) || {};
      return (
        <NoChatsLoader
          placeHolderText={this.getConversationListPlaceHolder()}
          widgetState={widgetState}
        />
      );
    }

    return <NoConversationsHeader />;
  }
}
export default ChatSection;
