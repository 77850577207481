import React from "react";
// import ReactDOM from "react-dom";
import { browserHistory, Link } from "react-router";
import {
    WidgetConfigurationIcon,
    WidgetConfigurationActiveIcon,
  } from "../../app/commons/Icons";

  const SetupLayout = (props) => {

    return (
    <div className={"cs-conversation-wrp "}>
      <div className="cs-setup-returning">
        {props.children}
      </div>
    </div>)
   
}
export default SetupLayout;
