import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ErrorNotificationIcon } from "../../commons/Icons.js";
import { connect } from "react-redux";
import { searchToObject } from "../../commons/Utility.js";

@connect((state) => ({ user: state.UserReducer }))
class Voicebox extends Component {

  getNotificationStyle = () => {
    console.log("The settings Compo : " , process.env.isSettingsComponent);
    let query = searchToObject();
    query = Object.keys(query).length ? query : this.props.user.fullscreenQuery;
    if (
     ( this.props.voicebox.showFullScreenLoader &&
      this.props.voicebox.display) || 
      (process.env.isSettingsComponent && this.props.voicebox.display) ||
      (query && query.isFullscreen === "true" && this.props.voicebox.display)
    ) {
      return { left: 0 };
    } else if (!this.props.voicebox.display) {
      return { display: "none" };
    } else {
      return {};
    }
  };
  getNotificationClass = () => {
    let className = "";
    if (this.props.voicebox.showBelowHeader) {
      className += " top-88";
    }
    if (this.props.voicebox.showError) {
      className += " error-notification";
    }
    if (this.props.voicebox.showFullScreenLoader) {
      className += " left-0";
    }
    return className;
  };
  render() {
    return (
      <div
        class={"cs-widget-notification" + this.getNotificationClass()}
        style={this.getNotificationStyle()}
      >
        {this.props.voicebox.showCloseIcon ? <cite onClick={this.props.dismissVoicebox}>x</cite> : ""}
        <div
          class="cs-loading-circle"
          style={this.props.voicebox.showLoader ? {} : { display: "none" }}
        >
          <div class="cs-circle1 cs-circle"></div>
          <div class="cs-circle2 cs-circle"></div>
          <div class="cs-circle3 cs-circle"></div>
          <div class="cs-circle4 cs-circle"></div>
          <div class="cs-circle5 cs-circle"></div>
          <div class="cs-circle6 cs-circle"></div>
          <div class="cs-circle7 cs-circle"></div>
          <div class="cs-circle8 cs-circle"></div>
          <div class="cs-circle9 cs-circle"></div>
          <div class="cs-circle10 cs-circle"></div>
          <div class="cs-circle11 cs-circle"></div>
          <div class="cs-circle12 cs-circle"></div>
        </div>
        <span>
          {this.getNotificationClass().includes("error-notification") ? (
            <ErrorNotificationIcon />
          ) : (
            ""
          )}
          {this.props.voicebox.message ? this.props.voicebox.message : ""}
        </span>
      </div>
    );
    return (
      <div
        className={"progress " + (this.props.voicebox.display ? "db" : "dn")}
      >
        <p id="notification">
          {this.props.voicebox.message ? this.props.voicebox.message : ""}
        </p>
      </div>
    );
  }
}

export default Voicebox;
