import React, { useEffect } from "react";
import TagMessageDropdown from "./TagMessageDropdown.jsx";
import { NewTagIcon } from "../../../commons/Icons";
import {
  POSITION,
} from "../../../commons/Constants.js";
import {
} from "../../../commons/Utility.js";
const TagMessageOption = ({
  tags,
  messageId,
  conversationId,
  showIcon,
  messageOptionsRef,
  hideIcon,
  isActive,
  toggleElement,
  setIsActive
}) => {

  const DIST_BETWEEN_MESSAGEOPTIONLFT_AND_DROPDOWNLFT = 150;

  const getPositionOfDropdown = () => {
    const { top, bottom } = messageOptionsRef.current.getBoundingClientRect();
    const bottomDifference = window.innerHeight - bottom;
    return bottomDifference > top ? POSITION.BOTTOM : POSITION.TOP;
  };

  const messageOptionPosition = () => {
    const position = messageOptionsRef.current.getBoundingClientRect();
    const messageOptionTop = position.top;
    const messageOptionLeft =
      position.left - DIST_BETWEEN_MESSAGEOPTIONLFT_AND_DROPDOWNLFT;
    const messageOptionBottom = window.innerHeight - position.bottom;
    return { messageOptionTop, messageOptionLeft, messageOptionBottom };
  };

  useEffect(() => {
    if (!showIcon) setIsActive(false);
  }, [showIcon]);

  useEffect(() => {
    if (!isActive) hideIcon(true);
  }, [isActive]);

  return (
    <React.Fragment>
      <figure onClick={toggleElement} onKeyDown={ () => {} }>
        <NewTagIcon className={'translateY-1'} isActive={isActive} />
      </figure>
      {isActive ? (
        <TagMessageDropdown
          openDropDown={setIsActive}
          messageId={messageId}
          conversationId={conversationId}
          tags={tags}
          hideIcon={hideIcon}
          position={getPositionOfDropdown()}
          messageOptionPosition={messageOptionPosition}
        />
      ) : null}
    </React.Fragment>
  );
};

export default TagMessageOption;
