export const CUSTOM_FIELDS_REQUESTED = "CUSTOM_FIELDS_REQUESTED";
export const CUSTOM_FIELDS_RESPONSE = "CUSTOM_FIELDS_RESPONSE";
export const CUSTOM_FIELDS_FAILED = "CUSTOM_FIELDS_FAILED";
export const UPDATE_CUSTOM_FIELD_REQUESTED = "UPDATE_CUSTOM_FIELD_REQUESTED";
export const UPDATE_CUSTOM_FIELD_RESPONSE = "UPDATE_CUSTOM_FIELD_RESPONSE";
export const UPDATE_CUSTOM_FIELD_FAILED = "UPDATE_CUSTOM_FIELD_FAILED";
export const DELETE_CUSTOM_FIELD_REQUESTED = "DELETE_CUSTOM_FIELD_REQUESTED";
export const DELETE_CUSTOM_FIELD_RESPONSE = "DELETE_CUSTOM_FIELD_RESPONSE";
export const DELETE_CUSTOM_FIELD_FAILED = "DELETE_CUSTOM_FIELD_FAILED";

export const requestCustomFields = (
  projectId,
  isFromOverflow = false,
  CF_Ids = [],
  isAwWindow
) => ({
  type: CUSTOM_FIELDS_REQUESTED,
  projectId,
  CF_Ids,
  isFromOverflow,
  isAwWindow,
});

export const customFieldsResponse = (customFieldsMap) => ({
  type: CUSTOM_FIELDS_RESPONSE,
  customFieldsMap,
});

export const customFieldsFailed = () => ({
  type: CUSTOM_FIELDS_FAILED,
});

export const requestUpdateCustomField = (customField) => ({
  type: UPDATE_CUSTOM_FIELD_REQUESTED,
  customField,
});

export const updateCustomFieldResponse = (customField) => ({
  type: UPDATE_CUSTOM_FIELD_RESPONSE,
  customField,
});

export const updateCustomFieldFailed = () => ({
  type: UPDATE_CUSTOM_FIELD_FAILED,
});
export const requestDeleteCustomField = (customField) => ({
  type: DELETE_CUSTOM_FIELD_REQUESTED,
  customField,
});
export const deleteCustomFieldResponse = (customFieldId) => ({
  type: DELETE_CUSTOM_FIELD_RESPONSE,
  customFieldId,
});
export const deleteCustomFieldFailed = () => ({
  type: DELETE_CUSTOM_FIELD_FAILED,
});
