import { call, put } from "redux-saga/effects";
import * as Ajax from "../../xhr/XhrRequestHandler";
import {
  createChatPromptConfigurationResponse,
  fetchChatPromptConfigurationResponse,
  updateChatPromptConfigurationResponse,
  deleteChatPromptConfigurationResponse,
  fetchChatPromptConfigurationFailed,
  createChatPromptConfigurationFailed,
  updateChatPromptConfigurationRequestFailed,
  deleteChatPromptConfigurationRequestFailed,
} from "../actions/PromptActions";
import { showVoicebox, hideVoicebox } from "../../actions/VoiceboxAction";
import { handleGlobalError } from "../../actions/GlobalErrorAction";

export function* fetchChatPromptConfiguration(data) {
  const url = "/chat/prompt/configuration/get/" + data.projectKey;
  try {
    let fetchConfigResponse = yield call(Ajax.get, url);
    if (fetchConfigResponse && fetchConfigResponse.response) {
      yield put(
        fetchChatPromptConfigurationResponse(
          JSON.parse(fetchConfigResponse.data),
          data.projectKey
        )
      );
      yield put(hideVoicebox());
    } else {
      yield put(fetchChatPromptConfigurationFailed());
      yield put(hideVoicebox());
    }
  } catch (e) {
    console.log("Fetch Failed :: ", e);
    yield put(fetchChatPromptConfigurationFailed());
    yield put(hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* createChatPromptConfiguration(data) {
  const url = "/chat/prompt/configuration/create";
  try {
    let createConfigResponse = yield call(
      Ajax.post,
      url,
      data.chatPromptconfiguration
    );
    if (createConfigResponse && createConfigResponse.response) {
      yield put(
        createChatPromptConfigurationResponse(createConfigResponse.data)
      );
      yield put(
        showVoicebox({ message: "Creation Successful", dismissAfter: 3000 })
      );
    } else {
      yield put(createChatPromptConfigurationFailed());
      yield put(
        showVoicebox({
          message: "New Prompt Creation Failed",
          dismissAfter: 3000,
          showError: true,
        })
      );
    }
  } catch (e) {
    console.log("Creation Failed :: ", e);
    yield put(
      showVoicebox({
        message: "New Prompt Creation Failed",
        dismissAfter: 3000,
        showError: true,
      })
    );
    yield put(createChatPromptConfigurationFailed());
    yield put(handleGlobalError(e));
  }
}

export function* updateChatPromptConfiguration(data) {
  const url = "/chat/prompt/configuration/update";
  try {
    let chatPromptConfig = yield call(
      Ajax.put,
      url,
      data.chatPromptConfiguration
    );
    if (chatPromptConfig.response) {
      yield put(updateChatPromptConfigurationResponse(chatPromptConfig.data));
      yield put(
        showVoicebox({ message: "Update Successful", dismissAfter: 3000 })
      );
    } else {
      yield put(updateChatPromptConfigurationRequestFailed());
      yield put(
        showVoicebox({
          message: "Updating Failed",
          dismissAfter: 3000,
          showError: true,
        })
      );
    }
  } catch (e) {
    console.log("update failed--->" + JSON.stringify(e));
    yield put(updateChatPromptConfigurationRequestFailed());
    yield put(showVoicebox({ message: "Updating Failed", dismissAfter: 3000 }));
    yield put(handleGlobalError(e));
  }
}

export function* deleteChatPromptConfiguration(data) {
  const url =
    "/chat/prompt/configuration/delete/" +
    data.projectKey +
    "/" +
    data.promptId;
  try {
    let deletedPromptId = yield call(Ajax.deleteReq, url);
    if (deletedPromptId.response) {
      yield put(
        deleteChatPromptConfigurationResponse(JSON.parse(deletedPromptId.data))
      );
      yield put(
        showVoicebox({ message: "Delete Successful", dismissAfter: 3000 })
      );
    } else {
      yield put(deleteChatPromptConfigurationRequestFailed());
      yield put(
        showVoicebox({
          message: "Deleting Failed",
          dismissAfter: 3000,
          showError: true,
        })
      );
    }
  } catch (e) {
    console.log("Delete Failed :: ", JSON.stringify(e));
    yield put(deleteChatPromptConfigurationRequestFailed());
    yield put(
      showVoicebox({
        message: "Deleting Failed",
        dismissAfter: 3000,
        showError: true,
      })
    );
    yield put(handleGlobalError(e));
  }
}
