import {
  TAKING_CHAT_STATUSES,
  DELIMETER,
  MESSAGE_FROM,
  MESSAGE_TYPE,
  AVAILABILITY_STATUS,
  DOCKER_UNREAD_COUNT_CONNECT,
  TYPES_TO_ADD_CONNECT_CHAT_IN_RECENT_LIST,
} from "../commons/Constants";
import {
  getCurrentTimeStamp,
  getIsAWRecentComponent,
  getProjectKey,
  isInternalUser,
  getUserIdFromState,
  getProjectReducer,
  getAwPrefixUserId,
  getProjectIdFromStaffReducer,
  isValidProjectId,
  isValidPresencePayload,
  handleInvalidPresencePayload,
} from "../commons/Utility";
import { store } from "../app";
import * as ContactInfoAction from "../actions/ContactInfoAction";
import * as PeopleAction from "../actions/PeopleAction";
import {
  clearConversationMessages,
  sendAWDesktopNotification,
} from "../actions/MessageAction";
import {
  clearConversation,
  conversationRequest,
} from "../actions/ConversationInfoAction";
import ExternalBaseApis from "./ExternalBaseApis";
import { getProjectIds } from "../../app/aw_recents/components/RecentListTab/awRecentListUtility";
import { fetchAndUpdateUserPresence } from "../../app/actions/PeopleAction";
import { requestAvailableProject } from "../settings/actions/ProjectAction";
import { getUser } from "../actions/UserAction";
import { staffListRequest } from "../actions/StaffAction";

export const getPresenceUserId = (_isInternalUser, userId, projectId) => {
  return _isInternalUser ? userId : projectId + "-" + userId;
};

export const getCurrentAWLoggedInUserPresence = (
  FrontOfficeService,
  userId
) => {
  let response = {
    isTakingChat: false,
    takingChatStatus: "",
  };
  if (FrontOfficeService) {
    console.log("Contact presence request for userId:", userId);
    let userPresence = FrontOfficeService.Contact.getPresenceByUserIds([userId])[0];
    if (isValidPresencePayload(userPresence, userId)) {
      response.isTakingChat = userPresence.receiveCalls;
      response.takingChatStatus = getTrimmedStatus(userPresence.presence);
    } else handleInvalidPresencePayload(userId);
  }
  return response;
};

export const getTrimmedStatus = (presence) => {
  return presence && presence.msg ? presence.msg.trim().toUpperCase() : "";
};

export const getDashboardStaffFromReducer = (
  projectId,
  userId,
  staffReducer
) => {
  let staffs = staffReducer.dashboardAgents[projectId] || {};
  return staffs[userId] || {};
};

export const getAwUserId = (projectId, key) => {
  return "aw/" + projectId + DELIMETER + key;
};

export const getConversationComponentParamsPayload = (
  conversationReducer,
  props
) => {
  const {
    conversationObject: { conversationMap },
  } = conversationReducer;
  let projectId = conversationMap[props.itemId]
    ? conversationMap[props.itemId].projectId
    : "";
  return {
    projectkey: getProjectKey(projectId),
    visitorOption: "D",
    chatStatus: "O",
    sortBy: "N",
    assignedTo: "All",
    conversationId: props.itemId,
  };
};

export const getConversationCompFilters = (conversationReducer, props) => {
  return {
    sortBy: "N",
    chatStatus: "O",
    assignedTo: conversationReducer.searchFilters.assignedTo,
    conversationId: props.itemId,
    visitorOption: "D",
  };
};

export const getPresenceMessagePayload = (
  currentStaff,
  projectId,
  takingChatStatus
) => {
  let isTakingChat = TAKING_CHAT_STATUSES.includes(takingChatStatus);
  let {
    lastAssignedTime,
    lastClosedTime,
    conversationIds,
    lastRingTime,
    shouldRouteChats,
    firstName,
    lastName,
    login,
    photoUrl,
    key,
    fullName,
    skill,
    role,
  } = currentStaff;
  return [
    {
      status: {
        applicationStatus: {
          lastAssignedTime: lastAssignedTime,
          lastClosedTime: lastClosedTime,
          conversationIds: conversationIds,
          isTakingChat,
          takingChatStatus,
          isRinging: false,
          lastRingTime: lastRingTime,
          shouldRouteChats: shouldRouteChats,
          eventTime: getCurrentTimeStamp(),
        },
        connectionStatus: "online",
      },
      userInfo: {
        firstName: firstName,
        lastName: lastName,
        agentLogin: login,
        photoUrl: photoUrl,
        contactId: key,
        name: fullName,
        userId: getAwUserId(projectId, key),
        projectId: [projectId],
        messageFrom: MESSAGE_FROM.AGENT,
        dashboardSkill: skill,
        dashboardRole: role,
      },
    },
  ];
};

export const getAwItemClosedComponentId = (getComponentId) => {
  return `${getComponentId}_RECENT_ITEM_CLOSED`;
};

export const isValidContactUpdation = (response) => {
  let contactsMap =
    store.getState().ConversationInfoReducer.conversationObject.contactsMap;
  let peopleMap = store.getState().PeopleReducer.peopleMap;
  return (
    ((response.message &&
      response.message.type === MESSAGE_TYPE.cs_prechatsurvey) ||
      (response.visitor && contactsMap[response.visitor.key]) ||
      peopleMap[response.visitor.key]) &&
    JSON.stringify(contactsMap[response.visitor.key]) !==
      JSON.stringify(response.visitor)
  );
};

export const updateContactDetails = (response) => {
  let contact = response.visitor;
  if (contact.sourceUrl && !contact.sourceUrl_keyword)
    contact.sourceUrl_keyword = contact.sourceUrl;
  if (contact.currentUrl && !contact.currentUrl_keyword)
    contact.currentUrl_keyword = contact.currentUrl;
  if (contact.lastPageUrl && !contact.lastPageUrl_keyword)
    contact.lastPageUrl_keyword = contact.lastPageUrl;
  store.dispatch(ContactInfoAction.receiveUpdateContact({ contact: contact }));
  contact = Object.assign(contact, contact.customFieldsMap);
  store.dispatch(PeopleAction.updatePeopleEntity(contact));
};

export const checkAndUpdatePrechatSurveyForAwIntegration = (response) => {
  if (getIsAWRecentComponent() && isValidContactUpdation(response)) {
    updateContactDetails(response);
  }
};

export const isConversationIdExist = (conversationId) => {
  let conversationReducer = store.getState().ConversationInfoReducer;
  return !!(
    conversationReducer.conversationObject.conversationMap[conversationId] &&
    conversationReducer.conversationObject.conversationMap[conversationId]
      .chatStatus == "OPEN"
  );
};

export const removeMessagesAndConversationIfOverflowedOrRejected = (
  conversationId
) => {
  if (getIsAWRecentComponent()) {
    store.dispatch(clearConversationMessages(conversationId));
    store.dispatch(clearConversation(conversationId));
  }
};

export const sendUnsubcriptionOnConnectConfigSync = (
  takingChatStatus,
  isTakingChat
) => {
  let isInternalUsers = isInternalUser();
  let userIdFromState = getUserIdFromState();
  let projectReducer = getProjectReducer();
  let projectIds = getProjectIds(projectReducer);
  if (userIdFromState) {
    let userId = getPresenceUserId(
      isInternalUsers,
      userIdFromState,
      projectIds[0]
    );
    let prefixedUserId = getAwPrefixUserId(userId);
    store.dispatch(
      fetchAndUpdateUserPresence(
        prefixedUserId,
        takingChatStatus,
        isTakingChat,
        getProjectIdFromStaffReducer()
      )
    );
  }
};
export const isConversationUnavailableInReducer = (
  { conversationObject },
  conversationId
) => {
  return (
    getIsAWRecentComponent() &&
    conversationId &&
    conversationObject.conversationMap &&
    !conversationObject.conversationMap[conversationId]
  );
};

export const isConversationEligibleToBeAddedInRecentList = (
  conversationId,
  type
) => {
  return (
    getIsAWRecentComponent() &&
    conversationId &&
    !ExternalBaseApis.hasItemInFrontOffice(conversationId) &&
    TYPES_TO_ADD_CONNECT_CHAT_IN_RECENT_LIST.includes(type)
  );
};

const isValidChatType = (type) => {
  return (
    type !== MESSAGE_TYPE.dashboard_chat_closed_no_response &&
    type !== MESSAGE_TYPE.dashboard_visitor_left
  );
};

const isNotValidChatType = (type) => {
  return (
    type === MESSAGE_TYPE.dashboard_chat_closed_no_response ||
    type === MESSAGE_TYPE.dashboard_visitor_left
  );
};

const validateAndAppendTheChatInRecentList = (
  { type, messageId, conversationId },
  StaffReducer
) => {
  if (isValidChatType(type)) {
    store.dispatch(sendAWDesktopNotification(messageId));
    ExternalBaseApis.addItemToConnectRecent(
      { itemId: conversationId },
      DOCKER_UNREAD_COUNT_CONNECT.COUNT
    );
  } else if (isNotValidChatType(type)) {
    if (
      StaffReducer.currentStaffStatus.takingChatStatus !=
      AVAILABILITY_STATUS.AVAILABLE
    ) {
      ExternalBaseApis.updateItemInConnect(
        conversationId,
        DOCKER_UNREAD_COUNT_CONNECT.DEFAULT
      );
    }
  }
};

export const validateConversationAndAddToConnectRecentList = (
  data,
  ConversationInfoReducer,
  StaffReducer
) => {
  let { conversationId, projectId, type } = data;
  if (
    isConversationUnavailableInReducer(
      ConversationInfoReducer,
      conversationId
    ) &&
    isConversationEligibleToBeAddedInRecentList(conversationId, type)
  ) {
    store.dispatch(conversationRequest({ projectId, conversationId }));
  }
  if (
    isConversationEligibleToBeAddedInRecentList(conversationId, type) &&
    !isConversationUnavailableInReducer(ConversationInfoReducer, conversationId)
  ) {
    validateAndAppendTheChatInRecentList(data, StaffReducer);
  }
};

export const getUserId = (userReducer) => {
  return (userReducer.data && userReducer.data.id) || "";
};

export const triggerUpdateContactPresence = (
  staffReducer,
  takingChatStatus,
  userId,
  selectedProjectId
) => {
  let currentStaff = getDashboardStaffFromReducer(
    selectedProjectId,
    userId,
    staffReducer
  );
  let presenceResponse = getPresenceMessagePayload(
    currentStaff,
    selectedProjectId,
    takingChatStatus
  );
  store.dispatch(
    ContactInfoAction.updateContactPresence(
      presenceResponse,
      false,
      false,
      selectedProjectId,
      userId
    )
  );
};

export const constructSubscribePayload = (
  messageReducer,
  projectIds,
  isLoadedAsAdmin
) => {
  return {
    socketId: messageReducer.socketId,
    projectId: projectIds[0],
    projectIds: projectIds,
    isAdminSubscription: isLoadedAsAdmin,
    isFromAWIntegration: getIsAWRecentComponent(),
  };
};

export const fetchCurrentUser = (userReducer) => {
  if (!userReducer.data && !userReducer.isFetching) {
    store.dispatch(getUser());
  }
};

export const fetchProjects = (projectReducer) => {
  if (!projectReducer.isFetched) store.dispatch(requestAvailableProject());
};

export const fetchStaffs = (reduxState, selectedProjectId) => {
  let { staffReducer } = reduxState;
  if (
    staffReducer.dashboardAgents[selectedProjectId] === undefined &&
    !staffReducer.isLoading
  ) {
    let projectID = checkForDefaultProjectAvailabilityandFetch(reduxState);
    if (
      isValidProjectId(staffReducer.currentStaffProjectId) &&
      !staffReducer.dashboardAgents[staffReducer.currentStaffProjectId]
    ) {
      store.dispatch(
        staffListRequest(staffReducer.currentStaffProjectId, false)
      );
    } else if (
      !staffReducer.dashboardAgents[staffReducer.currentStaffProjectId]
    ) {
      store.dispatch(staffListRequest(projectID, false));
    }
  }
};

export const checkForDefaultProjectAvailabilityandFetch = (reduxState) => {
  const { userReducer, projectReducer } = reduxState;
  let projectID;
  console.log("This is userSelected: ", userReducer.selectedProjectKey);
  if (isValidProjectId("LS-" + userReducer.selectedProjectKey)) {
    projectID = "LS-" + userReducer.selectedProjectKey;
  } else {
    projectID = projectReducer.projectList[0].key;
  }
  return projectID;
};

export const getUserProjectMappingLength = (userProjectMappingReducer) => {
  return Object.keys(userProjectMappingReducer.userProjectMapping).length;
};

export const canSubscribeAgents = (reduxState) => {
  const { messageReducer, projectReducer, staffReducer } = reduxState;
  return (
    messageReducer.isConnected &&
    messageReducer.socketId &&
    projectReducer.isFetched &&
    staffReducer.isLoaded
  );
};

export const canSubscribeWhenProjectsFetched = (reduxState) => {
  let { userProjectMappingReducer } = reduxState;
  return (
    canSubscribeAgents(reduxState) &&
    userProjectMappingReducer.isConnectProjectsFetched
  );
};

export const getProjectIdForChatConfig = (reduxState) => {
  let { userReducer, projectReducer } = reduxState;
  if (isValidProjectId("LS-" + userReducer.selectedProjectKey)) {
    return userReducer.selectedProjectKey;
  } else {
    return getProjectKey(projectReducer.projectList[0].key);
  }
};

export const getVisitorId = (reduxState, props) => {
  let { conversationReducer } = reduxState;
  let { conversationObject } = conversationReducer;
  let { conversationMap } = conversationObject;
  let conversationId = props.itemId;
  let conversation = conversationMap[conversationId]
    ? conversationMap[conversationId]
    : {};
  return conversation.visitorId;
};

export const getConversationReqPayload = (reduxState, props) => {
  console.log(
    "Triggered conversation request from ConversationComponent",
    props.itemId
  );
  const { conversationReducer } = reduxState;
  const filters = getConversationCompFilters(conversationReducer, props);
  const projectId = getProjectIdForConversationRequest(reduxState);
  return {
    conversationId: props.itemId,
    isConversationStarted: true,
    projectId: projectId,
    projectKey: getProjectKey(projectId),
    filters: filters,
  };
};

export const getProjectIdForConversationRequest = (reduxState) => {
  let { projectReducer, userReducer } = reduxState;
  if (isValidProjectId("LS-" + userReducer.selectedProjectKey)) {
    return "LS-" + userReducer.selectedProjectKey;
  } else {
    return projectReducer.projectList[0].key;
  }
};
