import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { requestSessionStep2 } from 'src/features/authorization/store/actions'
import { Layout, Left } from 'src/features/ui/components/Layout/styled'
import { Spinner } from 'src/features/ui/components/Spinner'
import { IProps } from 'src/pages/SessionStep2/types'

export const SessionStep2Component: FC<IProps> = ({ sessionStep2 }) => {
  useEffect(() => {
    sessionStep2()
  }, [])

  return (
    <Layout shouldHideGradient>
      <Left shouldCenterContent>
        <Spinner size={50} />
      </Left>
    </Layout>
  )
}

const mapDispatchToProps = {
  sessionStep2: requestSessionStep2,
}

export const SessionStep2 = connect(
  null,
  mapDispatchToProps
)(SessionStep2Component)
