import React, { Component } from "react";
import ReactDom from "react-dom";
import { connect } from "react-redux";
import ProfileComponent from "./ProfileComponent.jsx";
import { browserHistory } from "react-router";
import { resetPeopleESQuery } from "../../actions/PeopleESQueryAction";
import * as UserActions from "../../actions/UserAction";
import { getIconUrl, sortListByProperty } from "../../commons/Utility";
import * as ProjectActions from "../actions/ProjectAction";

@connect((store) => ({
  project: store.ProjectReducer,
  user: store.UserReducer,
  setup: store.SetupReducer,
}))
class ManageProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };

    this.onChooseProfile = this.onChooseProfile.bind(this);
  }

  componentDidMount() {
    if (!this.props.project.isFetched) {
      this.props.dispatch(ProjectActions.requestAvailableProject());
    }
  }

  switchProject = (projectkey) => {
    this.props.dispatch(resetPeopleESQuery());
    let dashboardURL = `/app/${projectkey}/chat/dashboard/`;
    let settingsURL = `/app/${projectkey}/settings`;
    let redirectTo =
      this.props.user.data && this.props.user.data.originalId
        ? settingsURL
        : dashboardURL;
    browserHistory.push(redirectTo);

    this.props.dispatch(UserActions.updateSelectedProjectKey(projectkey));

    if (this.props.user.data && !this.props.user.data.originalId) {
      var user = this.props.user.data;
      user.defaultProject = "LS-" + projectkey;
      this.props.dispatch(UserActions.updateUserRequested(user, false));
    }
  };

  onChooseProfile(e) {
    let projectkey = e.target.dataset.projectkey.split("-")[1];
    this.setState({
      ...this.state,
      showLoader: true,
    });

    setTimeout(() => {
      this.setState(
        {
          ...this.state,
          showLoader: false,
        },
        () => {
          this.switchProject(projectkey);
        }
      );
    }, 3 * 1000);
  }

  getProjectList() {
    let toRenderList = [];
    if (
      this.props.project.projectList &&
      this.props.project.projectList.length
    ) {
      sortListByProperty(this.props.project.projectList, "name").map(
        (project) => {
          toRenderList.push(
            <ProfileComponent
              key={project.key}
              projectName={project.name}
              isActive={
                this.props.params.projectkey === project.key.split("-")[1]
              }
              projectKey={project.key}
              navigateProject={this.onChooseProfile}
              logoUrl={project.logoUrl}
            />
          );
        }
      );
    }

    return toRenderList;
  }

  showNewProjectPopup = () => {
    this.props.dispatch(ProjectActions.showNewProjectPopup());
  };

  render() {
    let { user, setup } = this.props;
    let isAdmin = user.data && user.data.isAdmin === "true";
    let isBeta = setup.isBeta;

    console.info("isAdmin:" + isAdmin);

    return (
      <section className="manage_profile">
        <div className={this.state.showLoader ? "loading-bg" : "dn"}></div>
        <aside className={this.state.showLoader ? "loading-data" : "dn"}>
          <img src={getIconUrl(document.location.origin)} />
          <p>Loading Your Data...</p>
        </aside>
        <div className="content-header">
          {isAdmin || isBeta ? (
            <a onClick={this.showNewProjectPopup}>+ Create New Profile</a>
          ) : (
            ""
          )}
          <p>Manage Profile</p>
        </div>
        <ul className="manage-project-list">{this.getProjectList()}</ul>
      </section>
    );
  }
}

export default ManageProfileComponent;
