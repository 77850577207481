import React, { useState } from "react";
import { Link } from "react-router";
import { getFormattedTimeFromMinutes } from "../../../commons/Utility";

const WorkingHoursComponent = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { weekDayConfig } = props;
  const expandOrCollapse = () => {
    if (isExpanded) setIsExpanded(false);
    else setIsExpanded(true);
  };
  const getClass = () => {
    if (isExpanded) return " open";
    return "";
  };

  const getWorkingHours = () => {
    let toRender = [];
    let weekDays = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let startTime = "",
      endTime = "";
    let today = new Date().getDay();
    today = today === 0 ? 6 : today - 1;
    for (let day in weekDays) {
      let timings = "Offline";
      let key = weekDays[day].slice(0, 2).toUpperCase();
      if (key in weekDayConfig && weekDayConfig[key].isChecked) {
        startTime =
          getFormattedTimeFromMinutes(weekDayConfig[key].startTime) + " to ";
        endTime = getFormattedTimeFromMinutes(weekDayConfig[key].endTime);
        timings = startTime + endTime;
      }
      toRender.push(
        <li class={day == today ? " active" : ""}>
          <span>{weekDays[day]}</span>
          <span>{timings}</span>
        </li>
      );
    }
    return toRender;
  };
  let updateMessage =
    props.createOrUpdate === "Create" ? "Configure your" : "Edit";
  return (
    <div class={"cs-dwn" + getClass()} onClick={expandOrCollapse}>
     
      {props.isFromTeamsPage && props.createOrUpdate === "Create" ? (
        ""
      ) : (
        <label>{props.displayText}</label>
      )}
      <div className="work-hours-wrapper">
      <cite
        style={
          props.createOrUpdate === "Create"
            ? { display: "none" }
            : { display: "block" }
        }
      ></cite>
      <p
        style={
          isExpanded || props.createOrUpdate === "Create"
            ? { display: "none" }
            : { display: "block" }
        }
      >
        {props.message}
      </p>
      </div>
      
      <ul style={props.createOrUpdate === "Create" ? { display: "none" } : {}}>
        {getWorkingHours()}
      </ul>
      <Link
        to={
          "/app/" + props.projectKey + "/settings/myprofile/workinghours/edit"
        }
        style={
          props.isFromTeamsPage ? { display: "none" } : { display: "block" }
        }
      >
        {updateMessage} working hours
      </Link>
    </div>
  );
};

export default WorkingHoursComponent;

