import styled from 'styled-components'
import { Text1 } from 'src/features/ui/components/Typography'

export const Container = styled.div`
  z-index: 2;
  min-width: 22rem;
`

export const Items = styled.ul`
  padding: 0;
  margin: 1.7rem 0 0;
`

export const Item = styled.li`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  display: flex;
  align-items: center;

  & + & {
    margin-top: 1.6rem;
  }
`

export const Label = styled(Text1)`
  margin-left: 1.2rem;
`
