import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from 'src/features/ui/styles/global'
import { theme } from 'src/features/ui/styles/theme'
import { history } from 'src/history'
import { Routes } from 'src/routes'
import { rootSagas } from 'src/utils/state/sagas'
import { persistedStoreFactory } from 'src/utils/state/store'

export const { store, sagaMiddleware } = persistedStoreFactory()
export const persistor = persistStore(store) as any // typescript module declaration issue

sagaMiddleware.run(rootSagas)

export const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Routes />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
)
