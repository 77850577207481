import { DefaultTheme } from 'styled-components'
import { breakPoints } from 'src/features/ui/styles/mq'
import { createSpacing } from 'src/features/ui/styles/spacing'

export const theme: DefaultTheme = {
  spacing: createSpacing(),
  breakpoints: {
    base: 0,
    sm: breakPoints.sm,
    md: breakPoints.md,
    lg: breakPoints.lg,
  },
  font: {
    family: 'Lato',
  },
  color: {
    alizarinCrimson: '#E21C1C',
    appleGreen: '#E5F4EF',
    athensGray: '#F0F2F5',
    baliHai: '#8F99AD',
    black: '#000000',
    cadetBlue: '#AEB7C3',
    casper: '#ACB7CC',
    crimson: '#DF0D29',
    codGray: '#181818',
    deepSea: '#00865A',
    dodgerBlue: '#4684ff',
    geyser: '#D3D9E5',
    harp: '#E8F4EC',
    mystic: '#E6E9F0',
    oxfordBlue: '#3C4859',
    raven: '#727C8F',
    royalBlue: '#3C60DA',
    solitude: '#E0EBFF',
    white: '#FFFFFF',
  },
}
