import React from "react";

export const ImageLoader = () => {
  return (
    <figure>
      <em className="progress"></em>
    </figure>
  );
};

export const LabelLoader = () => {
  return (
    <label>
      <em class="progress"></em>
    </label>
  );
};

export const PTagLoader = () => {
  return (
    <p>
      <em class="progress"></em>
    </p>
  );
};

export const DivLoader = () => {
  return (
    <div className="progress-chat-md">
      <PTagLoader />
    </div>
  );
};

export const AnchorTagLoader = () => {
  return (
    <a>
      <em class="progress"></em>
    </a>
  );
};

export const WidgetCardLoader = () => {
  return (
    <li>
      <div>
        {ImageLoader()}
        {LabelLoader()}
        {PTagLoader()}
      </div>
    </li>
  );
};

export const ProactiveCardLoader = () => {
  return (
    <li>
      <div>
        {LabelLoader()}
        {PTagLoader()}
      </div>
    </li>
  );
};

export const GroupCardLoader = () => {
  return (
    <li>
      <div>
        {ImageLoader()}
        {AnchorTagLoader()}
      </div>
    </li>
  );
};

export const SubgroupCardLoader = () => {
  return (
    <li>
      <div>{AnchorTagLoader()}</div>
    </li>
  );
};

export const GridDataLoader = () => {
  return (
    <div>
      <em className="progress"></em>
    </div>
  );
};

export const GridImageLoader = () => {
  return (
    <div>
      <figure>
        <em className="progress"></em>
      </figure>
      <em className="progress"></em>
    </div>
  );
};

export const MyProfileComponentLoader = () => {
  return (
    <div>
      <label>Email</label>
      <p className="fs-exclude">
        <DivLoader />
      </p>
      <label>Phone </label>
      <p className="fs-exclude">
        <DivLoader />
      </p>
    </div>
  );
};

export const SettingsLayoutLoader = () => {
  return (
    <section class="cs-navigation">
      <strong>Settings</strong>
      <div class="settings-left-menu">
        <ul class="cs-nav-list">
          <li class="">
            <div class="progress-chat-md">
              <ImageLoader />
              <PTagLoader />
            </div>
          </li>
        </ul>
        <div class="sub-title-menu">My Account</div>
        <ul class="cs-nav-list">
          <li data-id="chat_settings" class="">
            <div class="progress-chat-md">
              <ImageLoader />
              <PTagLoader />
            </div>
          </li>
          <li>
            <div class="progress-chat-md">
              <ImageLoader />
              <PTagLoader />
            </div>
          </li>
        </ul>
        <div class="sub-title-menu">More</div>
        <ul class="cs-nav-list">
          <li>
            <div class="progress-chat-md">
              <ImageLoader />
              <PTagLoader />
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export const ProfileNameLoader = () => {
  return (
    <h2 className="cs-caps-txt fs-exclude">
      <div class="progress-chat">
        <LabelLoader />
      </div>
    </h2>
  );
};

export const TeamMemberListLoader = ({ loaderCount }) => {
  const loader = [];
  for (let i = 0; i < loaderCount; i++) {
    loader.push(
      <li>
        <ImageLoader />
        <PTagLoader />
      </li>
    );
  }
  return (
    <section class="cs-navigation">
      <h2>Team Members</h2>
      <ul class="tab-nav">
        <li class="active">
          <a>My Team</a>
        </li>
      </ul>
      <ul className="cs-navigation-list cs-my-team-list">{loader}</ul>
    </section>
  );
};

export const TeamMemberProfileTabLoader = () => {
  const loader = [];
  const propsArray = ["Email", "Phone", "Working Hours"];
  propsArray.forEach((element) => {
    loader.push(<label>{element}</label>);
    loader.push(
      <p class="fs-exclude">
        <DivLoader />
      </p>
    );
  });
  return <div>{loader}</div>;
};

export const TeamMemberPermissionTabLoader = () => {
  return (
    <div>
      <label>Role</label>
      <PTagLoader />
      <label>Skill</label>
      <PTagLoader />
      <label>Widgets Assigned</label>
      <PTagLoader />
    </div>
  );
};

export const VisitorQueueDetailsTabLoader = ({loaderCount}) => {
  const getVisitorQueueLoader = () => {
    let loader = [];
    while (loaderCount) {
      loader.push(
        <li>
          <span>
            <p style={{ width: 150 }} className="progress"></p>
            <a className="progress"></a>
          </span>
          <span className="progress-chat">
            <PTagLoader></PTagLoader>
          </span>
          <span className="progress-chat">
            <PTagLoader></PTagLoader>
          </span>
        </li>
      )
      loaderCount--;
    }
    return loader;
  };
  return (
    <React.Fragment>
      {getVisitorQueueLoader()}
    </React.Fragment>
  );
}

export const ConversationQueueDetailsTabLoader = ({loaderCount}) => {
  const getConversationQueueLoader = () => {
    let loader = [];
    while (loaderCount) {
      loader.push(
        <li>
          <span class="queue-info">
            <figure className="progress">
            </figure>
            <p className="progress" style={{ width: 100 }}></p>
            <p class="queue-msg progress"></p>
            <a className="progress"></a>
          </span>
          <span className="progress-chat">
            <PTagLoader></PTagLoader>
          </span>
          <span className="progress-chat">
            <PTagLoader></PTagLoader>
          </span>
        </li>
      )
      loaderCount--;
    }
    return loader;
  };
  return (
    <React.Fragment>
      {getConversationQueueLoader()}
    </React.Fragment>
  );
}
