import { useField } from 'formik'
import React, { FC, useState } from 'react'
import { Margin } from 'styled-components-spacing'
import {
  Color,
  Colors,
  Container,
  InputContainer,
  StyledInput,
} from 'src/features/form/components/ColorInput/styled'
import { IProps } from 'src/features/form/components/ColorInput/types'
import { debounce } from 'src/utils/debounce'

export const ColorInput: FC<IProps> = ({
  name,
  colorsSelection,
}): JSX.Element => {
  const [field, _meta, helpers] = useField(name)
  let defaultColor = colorsSelection.includes(field.value) ? '' : field.value
  if (defaultColor)
    defaultColor = defaultColor.includes('#')
      ? defaultColor
      : `#${defaultColor}`
  const [customColor, setCustomColor] = useState(defaultColor)

  const setColor = debounce((color: string, shouldUpdateCustom?: boolean) => {
    helpers.setValue(color)

    if (shouldUpdateCustom) {
      setCustomColor(color)
    }
  }, 100)

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setColor(event?.target?.value, true)
  }

  const isSelectedColor = (color: string) => {
    const currentColor = color.replace('#', '')
    const fieldValue = field.value.replace('#', '')
    return currentColor === fieldValue
  }

  return (
    <Container>
      <Colors>
        {[...colorsSelection, customColor].map(
          (color) =>
            color && (
              <Color
                type="button"
                key={color}
                onClick={() => setColor(color)}
                selected={isSelectedColor(color)}
                color={color}
              />
            )
        )}
        <Margin left={16} />
        <InputContainer>
          <StyledInput onChange={handleColorChange} type="color" />
        </InputContainer>
      </Colors>
    </Container>
  )
}
