import {
  LeadsIcon,
  VisitorsIcon,
  CustomersIcon,
  LeadsActiveIcon,
  VisitorsActiveIcon,
  CustomersActiveIcon,
  MyProfileIcon,
  MyProfileActiveIcon,
  ArrowIcon,
  CompaniesIcon,
  CompaniesActiveIcon,
} from "../../commons/Icons.js";

export const FILTER_PEOPLE_TYPE_MAP = {
  is_lead: "leads",
  is_visitor: "visitors",
  is_user: "customers",
  is_company: "companies",
};

export const GROUP_ORDERING_MAP = {
  "all-lead": [
    "a737b8e5-96c1-4123-9935-c26f9eb169e8",
    "ce196ae1-ad37-4cf9-8f2c-34c7b1f9c6f7",
    "f383a9db-9e53-4271-8f36-93cfc32736b7",
  ],
  "all-visitor": [
    "74690b5e-2278-4ec1-a582-58179c0e27f1",
    "25b30462-22c2-4799-8ed8-e0b6d7d87157",
    "bda809a7-007a-48bf-b447-06fe11dd2479",
  ],
  "all-user": [
    "71205643-aa44-43e8-b141-89c0ac7a55a4",
    "1f1ddaff-2b07-42b0-89d7-914f5eb7b27e",
    "f4e8c142-4649-4de1-b735-43fd759a6263",
  ],
  "all-company": [
    "1fd3e53b-d471-4775-a274-4f49d1012611",
    "12ecf68f-22c0-482b-a32b-e912686c61c5",
    "4579a203-0aef-40bb-84b5-c39c04db939f",
  ],
};

export const DEFAULT_GROUP_IDS = [
  "all-lead",
  "a737b8e5-96c1-4123-9935-c26f9eb169e8",
  "ce196ae1-ad37-4cf9-8f2c-34c7b1f9c6f7",
  "f383a9db-9e53-4271-8f36-93cfc32736b7",
  "all-visitor",
  "74690b5e-2278-4ec1-a582-58179c0e27f1",
  "25b30462-22c2-4799-8ed8-e0b6d7d87157",
  "bda809a7-007a-48bf-b447-06fe11dd2479",
  "all-user",
  "71205643-aa44-43e8-b141-89c0ac7a55a4",
  "1f1ddaff-2b07-42b0-89d7-914f5eb7b27e",
  "f4e8c142-4649-4de1-b735-43fd759a6263",
  "all-company",
  "1fd3e53b-d471-4775-a274-4f49d1012611",
  "12ecf68f-22c0-482b-a32b-e912686c61c5",
  "4579a203-0aef-40bb-84b5-c39c04db939f",
];

export const GROUP_ICONS = {
  "all-lead": [LeadsIcon, LeadsActiveIcon],
  "all-visitor": [VisitorsIcon, VisitorsActiveIcon],
  "all-user": [CustomersIcon, CustomersActiveIcon],
  "all-company": [CompaniesIcon, CompaniesActiveIcon],
};

export const GROUP_ICONS_SRC = {
  "all-lead": "https://app.chatsupport.co/images/leads-icon.svg",
  "all-visitor": "https://app.chatsupport.co/images/visitors-icon.svg",
  "all-user": "https://app.chatsupport.co/images/customers-icon.svg",
  "all-company": "https://app.chatsupport.co/images/companies-icon.svg",
};

export const MAIN_GROUP_IDS = [
  "all-lead",
  "all-visitor",
  "all-user",
  "all-company",
];

export const FILTER_DEFAULT_OPTION = {
  text: "contains",
  String: "contains",
  event: "more_then_times",
  Number: "more_then",
  Boolean: "is_bool_true",
  date: "after_date",
  peopleType: "is_user",
  Tags: "is_tagged",
  emailSubscription: "contains",
};

export const FILTER_QUERY_INPUT_TYPES = {
  text: {
    contains: { type: "string", name: "Contains" },
    not_contains: { type: "string", name: "Not contains" },
    has_no_value: { type: "radio", name: "Has no value" },
    has_any_value: { type: "radio", name: "Has any value" },
    exactly: { type: "string", name: "Exactly" },
    not_exactly: { type: "string", name: "Not exactly" },
  },
  String: {
    contains: { type: "string", name: "Contains" },
    not_contains: { type: "string", name: "Not contains" },
    has_no_value: { type: "radio", name: "Has no value" },
    has_any_value: { type: "radio", name: "Has any value" },
    exactly: { type: "string", name: "Exactly" },
    not_exactly: { type: "string", name: "Not exactly" },
  },
  Number: {
    more_then: { type: "number", name: "More than", trailingText: "times" },
    less_then: { type: "number", name: "Less than", trailingText: "times" },
    equals_to: { type: "number", name: "Equals to", trailingText: "times" },
    has_no_value: { type: "radio", name: "Has no value" },
  },
  event: {
    more_then_times: {
      type: "number",
      name: "More than",
      trailingText: "times",
    },
    less_then_times: {
      type: "number",
      name: "Less than",
      trailingText: "times",
    },
    less_then_days: {
      type: "number",
      name: "within last",
      trailingText: "days",
    },
    more_then_days: {
      type: "number",
      name: "Not within last",
      trailingText: "days",
    },
  },
  Boolean: {
    is_bool_true: { type: "radio", name: "is True" },
    is_bool_false: { type: "radio", name: "is False" },
    has_no_value: { type: "radio", name: "Has no value" },
  },
  date: {
    after_date: { type: "date", name: "After" },
    before_date: { type: "date", name: "Before" },
    on_date: { type: "date", name: "On" },
    more_then_no_days: {
      type: "number",
      name: "Not Within last",
      trailingText: "days",
    },
    less_then_no_days: {
      type: "number",
      name: "Within last",
      trailingText: "days",
    },
    has_no_value: { type: "radio", name: "Has no value" },
  },
  Tags: {
    is_tagged: { type: "tag", name: "Tagged to" },
    is_not_tagged: { type: "tag", name: "Not tagged to" },
  },
  emailSubscription: {
    subscribed: { type: "radio", name: "Subscribed" },
    unsubscribed: { type: "radio", name: "Unsubscribed" },
    has_no_value: { type: "radio", name: "Has no value" },
    has_any_value: { type: "radio", name: "Has any value" },
  }
};

export const DASHBOARD_DEFAULT_GROUP_ID = "all-lead";

export const DEFAULT_COLUMN_FIELDS = ["name", "fullName"];

export const DEFAULT_COLUMN_HEADERS = [
  "name",
  "email",
  "phoneNumber",
  "companyName",
  "lastSeenTime",
  "campaignMedium",
  "campaignSource",
  "os",
  "state",
  "country",
];

export const CONTACT_DATE_FIELDS = [
  "createdDate",
  "firstContacted",
  "modifiedDate",
  "lastContacted",
  "lastSeenOnAndroid",
  "lastSeenOnIos",
  "lastSeenTime",
];

export const TYPE_MAIN_GROUP_ID_MAP = {
  leads: "all-lead",
  visitors: "all-visitor",
  customers: "all-user",
  companies: "all-company",
};

export const DEFAULT_GROUP_FILTER_COLOR = "#ff6836";

export const WILDCARD_PROPERTIES = [
  "campaignUrl_keyword",
  "lastPageUrl_keyword",
  "last_seen_user_agent_keyword",
  "sourceUrl_keyword",
  "currentUrl_keyword",
];
