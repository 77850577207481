import React from "react";
import {
  Editor,
  CharacterMetadata,
  DefaultDraftBlockRenderMap,
  ContentBlock,
  EditorBlock,
  genKey,
  ContentState,
  EditorState,
} from "draft-js";

const AddContent = (props) => {
  let { handleOnClick } = props.blockProps;
  return (
    <div
      onClick={handleOnClick}
      style={{
        border: "1.5px solid #4684ff",
        borderRadius: "6px",
        fontSize: "12px",
        fontWeight: "600",
        color: "#4684ff",
        textAlign: "center",
        padding: "34px 0px",
        borderStyle: "dashed",
        cursor: "pointer",
        margin: "0px 0px 12px",
        position: "relative",
        cursor: "pointer",
      }}
    >
      {" "}
      + Add content{" "}
    </div>
  );
};

export default AddContent;
