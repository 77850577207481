import React, { useState, useRef, useEffect } from "react";

const OutOfOfficeComponent = (props) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const [isContentEnabled, setIsContentEnabled] = useState(false);
  const suggestionsRef = useRef(null);
  const content = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = () => {
    if (
      !(
        suggestionsRef &&
        suggestionsRef.current &&
        suggestionsRef.current.contains(event.target)
      )
    )
      setShowDropDown(false);
  };

  const handleBlur = (e) => {
    setIsContentEnabled(false);
    setActiveField(false);
    props.handleBlur(e);
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    console.log("Text befor pasting :", text);
    document.execCommand("insertHTML", false, text);
  };

  const handleEnter = (e) => {
    if (e.which == 13 || e.keyCode == 13) {
      handleBlur(e);
      e.target.blur();
      e.preventDefault();
    }
  };
  return (
    <div>
      <div
        class="cs-widget-config cs-business-hrs"
        style={
          !props.showMoreOutOfOffice
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <div>
          <label>Out of office Response</label>
          <p>
            Trigger an automated response when visitors start a chat during your
            off-hours
          </p>
        </div>
        <div
          id="outofoffice_toggle"
          className={"cs-switch-btn" + (props.isEnabled ? " on" : " off")}
          onClick={props.toggleOutOfOffice}
          onKeyDown={ () => {} }
        ></div>
      </div>
      <div
        class="cs-widget-config cs-business-hrs"
        style={
          !props.showMoreOutOfOffice
            ? { display: "none" }
            : { display: "block" }
        }
      >
        <div>
          <label>Out of office Message</label>
          <p>Enter an automated message for all out-of-office chats</p>
        </div>
        <div
          className={
            "cs-input-field" + (!activeField ? "" : " cs-active-field")
          }
        >
          <font
            id="outofoffice_message_input"
            spellCheck={false}
            className={
              !props.isEnabled ? "cs-placeholder-txt" : "" + " fs-exclude"
            }
            onBlur={handleBlur}
            onKeyDown={handleEnter}
            contentEditable={props.isEnabled ? true : false}
            onPaste={onPasteHandler}
            placeholder="Enable the response to enter message"
            dangerouslySetInnerHTML={{
              __html: unescape(props.outOfOfficeMessasge),
            }}
          ></font>
        </div>
      </div>
    </div>
  );
};
export default OutOfOfficeComponent;
