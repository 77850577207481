import React, { Component } from "react";
import ReactDOM from "react-dom";

class Cards extends Component {
  render() {
    let { cards, onClose } = this.props;
    let displayCardsSection = cards.size;
    let cardsList = [];

    cards.forEach((info, text) => {
      cardsList.push({ info, text });
    });

    cardsList.reverse();

    return (
      <section
        class="members-List"
        style={{ display: displayCardsSection ? "block" : "none" }}
      >
        {cardsList.map((card) => (
          <div class="members" id={card.text}>
            <cite>{card.text.toUpperCase()[0]}</cite>
            <span>{card.text}</span>
            <small>({card.info})</small>
            <code onClick={onClose}>×</code>
          </div>
        ))}
      </section>
    );
  }
}

export default Cards;
