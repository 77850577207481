/**
 * Created by melvin on 11/04/17.
 */
import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  staffListFailed,
  staffListResponse,
  updateStaffRoleResponse,
  updateStaffRoleFailed,
  deleteStaffResponse,
  deleteStaffFailed,
  updateStaffOpenConversationStatusRequested,
  updateStaffOpenConversationStatusResponse,
  updateStaffOpenConversationStatusFailed,
  deleteAccountStaffResponse,
} from "../actions/StaffAction";
import { addNotification } from "../actions/NotificationAction";
import {
  deleteProjectresponse,
  updateUnassingedConversationCount,
} from "../settings/actions/ProjectAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { browserHistory } from "react-router";
import {
  getUnsubscriptionData,
  getNameSortedStaffs,
  getStaffUserIdWithProjectId,
  getStaffUserIds,
  getProjectId,
  setFullNameAndInitial,
  getQueryParam,
  isValidStaffMap,
  updateTeamMembersList,
  isTeamMembersPage,
  isOverflowConnection,
  getIsAWRecentComponent,
} from "../commons/Utility";
import { store } from "../app";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { fetchPresenceByUserIds } from "../actions/PeopleAction";
import { showVoicebox } from "../actions/VoiceboxAction";

export function populateOverflowAgentsFromAgentList(requestData = {}, staffList = []) {
  let overflowAgents = {};
  for (let staff of staffList) {
    overflowAgents[staff.key] = setFullNameAndInitial(staff);
  }
  if (
    (requestData?.isFromOverflow || requestData?.isAwWindow) &&
    !overflowAgents[requestData?.overflowAgent.key]
  ) {
    let overflowAgent = requestData?.overflowAgent;
    overflowAgent.staffType = "OVERFLOW";
    overflowAgents[overflowAgent.key] = setFullNameAndInitial(
      overflowAgent
    );
  }
  return overflowAgents;
}

export function populateDashbaoardAgentsFromStaffList(staffList = []) {
  let dashboardAgents = {};
  if (staffList.length > 1) staffList = getNameSortedStaffs(staffList);
  else if (staffList.length == 1) {
    staffList[0] = setFullNameAndInitial(staffList[0]);
  }
  if (staffList.length > 0) {
    for (let staff of staffList) {
      dashboardAgents[staff.key] = staff;
    }
  }
  return dashboardAgents;
}

export function fetchFormattedUserIds(projectId, dashboardAgents = {}) {
  let formattedUserIds = [];
  if (!store.getState().UserReducer.isInternalUser)
    formattedUserIds = getStaffUserIdWithProjectId(
      dashboardAgents,
      projectId
    );
  else formattedUserIds = getStaffUserIds(dashboardAgents);
  return formattedUserIds;
}

export function* doFetchStaffList(requestData) {
  console.log("doFetchStaffList-------------------------------> ", requestData);
  try {
    let projectId = requestData?.projectId;
    if (projectId) {
      projectId = projectId.startsWith("LS-") ? projectId : "LS-" + projectId;
      let url = "";
      let staffMap = {};
      if (requestData.isFromOverflow) {
        url =
          "project/staff/overflow/get/" +
          projectId +
          "?connectionId=" +
          getQueryParam("connection_id");
        staffMap = yield call(
          Ajax.callWithRetry,
          isValidStaffMap,
          Ajax.getUnauthenticated,
          url);
      } else if (requestData.isAwWindow) {
        url = "project/staff/overflow/get/" + projectId;
        staffMap = yield call(Ajax.getUnauthenticated, url);
      } else {
        url = "project/staff/get/" + projectId;
        staffMap = yield call(Ajax.get, url);
      }
      let dashboardAgents = {};
      let overflowAgents = {};
      let staffList = staffMap["agents"] || [];
      overflowAgents = populateOverflowAgentsFromAgentList(requestData, staffList);
      
      staffList = staffMap["staffs"] || [];
      dashboardAgents = populateDashbaoardAgentsFromStaffList(staffList);
      
      yield put(VoiceboxActions.hideVoicebox());
      yield put(staffListResponse(dashboardAgents, overflowAgents, projectId));
      if (!requestData.isFromOverflow && !requestData.isAwWindow)
        yield put(updateStaffOpenConversationStatusRequested(projectId));

      if (dashboardAgents && staffList.length) {
        let formattedUserIds = [];
        formattedUserIds = fetchFormattedUserIds(projectId, dashboardAgents);
        if (formattedUserIds.length > 0)
          yield put(
            fetchPresenceByUserIds(
              formattedUserIds,
              projectId,
              requestData.isFromOverflow,
              requestData.isAwWindow
            )
          );
      }
      if(!isTeamMembersPage() && !isOverflowConnection() && !getIsAWRecentComponent()){
        updateTeamMembersList();
      }
    } else {
      yield put(staffListFailed());
      yield put(VoiceboxActions.hideVoicebox());
    }
  } catch (e) {
    console.error(e);
    yield put(staffListFailed());
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doFetchStaff(requestData) {
  console.log("doFetchStaff-------------------------------> ", requestData);
  try {
    let projectId = requestData.projectId;
    let staffId = requestData.staffId;
    const url = "project/staff/get/" + staffId + "/" + projectId;
    let response = yield call(Ajax.get, url);
    if (response.response === true) {
      let staff = response.data;
      staff = setFullNameAndInitial(staff);
      let overflowAgents = {};
      let dashboardAgents = {};
      if (staff.staffType == "OVERFLOW") overflowAgents[staff.key] = staff;
      else dashboardAgents[staff.key] = staff;
      yield put(VoiceboxActions.hideVoicebox());
      yield put(staffListResponse(dashboardAgents, overflowAgents, projectId));
    } else {
      yield put(staffListFailed());
      yield put(VoiceboxActions.hideVoicebox());
    }
  } catch (e) {
    console.error(e);
    yield put(staffListFailed());
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* doFetchStaffConversationCount(projectData) {
  try {
    console.log("ProjectKey :", projectData.data);
    let projectKey = projectData.data;
    const url = "project/staff/conversationCount/" + projectKey;
    let staffCountMap = yield call(Ajax.get, url);
    let unassignedConversationCount = staffCountMap["None"];
    let mentionedConversationCount = staffCountMap["mentionedCount"];
    delete staffCountMap["None"];
    delete staffCountMap["mentionedCount"];
    yield put(
      updateUnassingedConversationCount({
        projectKey,
        unassignedConversationCount,
      })
    );
    let userid = store.getState().UserReducer.data
      ? store.getState().UserReducer.data.id
      : "";
    yield put(
      updateStaffOpenConversationStatusResponse({
        staffCountMap,
        projectKey,
        userid,
        mentionedConversationCount,
      })
    );

    //TODO: UPDATE PROJECT REQUEST TO DISPLAY UNASSIGNED COUNT
  } catch (e) {
    console.info("Bug fix..", e);
    console.error(e);
    // yield put( updateStaffOpenConversationStatusFailed());
    // yield put( handleGlobalError( e ) );
  }
}

export function* doChangeStaffRole(doChangeStaffRoleData) {
  console.log("doChangeStaffRoleData", doChangeStaffRoleData);
  const url = "/project/staff/update";
  try {
    let requestData = doChangeStaffRoleData.updateData;
    let response = yield call(Ajax.put, url, {
      projectKey: getProjectId(requestData.projectKey),
      userKey: requestData.staff.key,
      userPermission: requestData.staff.userPermission,
    });
    if (response.response === true) {
      response = response.data;
      if (response.permission === requestData.staff.userPermission) {
        yield put(
          addNotification({
            message: "Staff Role Updation Successful",
            level: NOTIFICATION_LEVEL.Success,
            dismissAfter: 3000,
          })
        );
        yield put(
          updateStaffRoleResponse({
            ...requestData.staff,
            projectId: getProjectId(requestData.projectKey),
          })
        );
      } else {
        yield put(updateStaffRoleFailed());
      }
    }
  } catch (e) {
    console.error(e);
    console.log(e.name);
    yield put(
      addNotification({
        message: "Staff Role Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(updateStaffRoleFailed());
    yield put(handleGlobalError(e));
  }
}
export function* doDeleteStaff(doDeleteStaffData) {
  console.log("doDeleteStaff");
  const url =
    "/project/staff/remove/LS-" +
    doDeleteStaffData.projectKey +
    "/" +
    doDeleteStaffData.staffKey +
    "/" +
    doDeleteStaffData.assignedUserKey;
  try {
    let response = yield call(Ajax.deleteReq, url);
    if (response.response === true) {
      yield put(
        addNotification({
          message: "Staff Removal Successful",
          level: NOTIFICATION_LEVEL.Success,
          dismissAfter: 3000,
        })
      );
      yield put(
        deleteStaffResponse(
          doDeleteStaffData.staffKey,
          doDeleteStaffData.projectKey
        )
      );
    }
  } catch (e) {
    console.error(e);
    yield put(
      addNotification({
        message: "Staff Removal Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(deleteStaffFailed());
    yield put(handleGlobalError(e));
  }
}

export function* deleteAccountStaff(action) {
  console.log("doDeleteStaff");
  // const url 			                    =	    '/project/staff/remove/LS-'+doDeleteStaffData.projectKey+'/'+doDeleteStaffData.staffKey+'/'+doDeleteStaffData.assignedUserKey;
  const url =
    "/project/staff/removeAccountStaff/LS-" +
    action.projectKey +
    "/" +
    action.staffKey;
  try {
    let response = yield call(Ajax.deleteReq, url);
    if (response.response === true) {
      if (action.showVoiceBox == true)
        yield put(showVoicebox({ message: "Deleted", dismissAfter: 3000 }));
      //  yield put( addNotification( { message : 'Staff Removal Successful' , level : NOTIFICATION_LEVEL.Success , dismissAfter : 3000 } ) );
      yield put(deleteAccountStaffResponse(action.staffKey, action.projectKey));
    }
  } catch (e) {
    console.error(e);
    yield put(
      addNotification({
        message: "Staff Removal Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(deleteStaffFailed());
    yield put(handleGlobalError(e));
  }
}
