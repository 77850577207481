import React, { FC } from 'react'
import {
  Container,
  Label,
  LabelIcon,
} from 'src/features/wizard/install/components/InstallTypeSelection/TypeButton/styled'
import { IProps } from 'src/features/wizard/install/components/InstallTypeSelection/TypeButton/types'

export const TypeButton: FC<IProps> = ({ icon, label, isActive, onClick }) => (
  <Container isActive={isActive} onClick={onClick}>
    <LabelIcon width={36} height={36} type={icon} />
    <Label>{label}</Label>
  </Container>
)
