export const RTM = "RTM";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_HTTP_MESSAGE = "SEND_HTTP_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SUBSCRIBE = "SUBSCRIBE";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const INIT = "INIT";
export const ON_MESSAGE = "ON_MESSAGE";
export const ON_CONNECT = "ON_CONNECT";
export const ON_FAILED = "ON_FAILED";
export const MESSAGE_REQUEST = "MESSAGE_REQUEST";
export const MESSAGE_RESPONSE = "MESSAGE_RESPONSE";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const CLOSE_CONNECTION = "CLOSE_CONNECTION";
export const DESTROY_RTMSERVER = "DESTROY_RTMSERVER";
export const RECONNECT = "RECONNECT";
export const ADD_MESSAGE_TO_TO_BE_SENT_LIST = "ADD_MESSAGE_TO_TO_BE_SENT_LIST";
export const SEND_EMAIL_REPLY = "SEND_EMAIL_REPLY";
export const ON_SUBSCRIBE = "ON_SUBSCRIBE";
export const ON_NETWORKUP = "ON_NETWORKUP";
export const ACK_MESSAGE = "ACK_MESSAGE";
export const QUEUE_UNACK_MESSAGE = "QUEUE_UNACK_MESSAGE";
export const FAIL_MESSAGE = "FAIL_MESSAGE";
export const ADD_OFFLINE_MESSAGE = "ADD_OFFLINE_MESSAGE";
export const RESET_OFFLINE_MESSAGES = "RESET_OFFLINE_MESSAGES";
export const UPDATE_AVAILABILITY_REQUEST = "UPDATE_AVAILABILITY_REQUEST";
export const UPDATE_AVAILABILITY_RESPONSE = "UPDATE_AVAILABILITY_RESPONSE";
export const RESET_CONVERSATION_MESSAGES = "RESET_CONVERSATION_MESSAGES"
export const UPDATE_MESSAGE_RESPONSE = "UPDATE_MESSAGE_RESPONSE";
export const RESET_MESSAGE_CURSOR = "RESET_MESSAGE_CURSOR";
export const ON_ABNORMAL_CLOSE = "ON_ABNORMAL_CLOSE";
export const UPDATE_AW_MESSAGE_ID = "UPDATE_AW_MESSAGE_ID";
export const CLEAR_CONVERSATION_MESSAGES = "CLEAR_CONVERSATION_MESSAGES";
export const ON_CONNECT_RESUBSCRIBE = "ON_CONNECT_RESUBSCRIBE";
export const ON_MESSAGE_LIST_FAILED = "ON_MESSAGE_LIST_FAILED";
export const ON_SORT_MESSAGES_VALIDATION = "ON_SORT_MESSAGES_VALIDATION";
export const ON_OLD_CONVERSATION_REQUESTED = "ON_OLD_CONVERSATION_REQUESTED";
export const ON_OLD_CONVERSATION_RESPONSE = "ON_OLD_CONVERSATION_RESPONSE";

export const messageRequest = (message) => ({
  type: MESSAGE_REQUEST,
  message,
});

export const messageResponse = (messages, conversationId) => ({
  type: MESSAGE_RESPONSE,
  messages,
  conversationId
});

export const sendMessage = (message, meta) => ({
  type: RTM,
  action: SEND_MESSAGE,
  message,
  meta,
});

export const addMessage = (message) => ({
  type: ADD_MESSAGE,
  message,
});

export const queueUnackMessage = (unackMessage) => ({
  type: QUEUE_UNACK_MESSAGE,
  unackMessage,
});

export const ackMessage = (messageId) => ({
  type: ACK_MESSAGE,
  messageId,
});

export const failMessage = (failedMessage) => ({
  type: FAIL_MESSAGE,
  failedMessage,
});

export const sendHttpMessage = (message) => ({
  type: SEND_HTTP_MESSAGE,
  message,
});

export const addOfflineMessage = (message) => ({
  type: ADD_OFFLINE_MESSAGE,
  message,
});

export const resetOfflineMessage = (message) => ({
  type: RESET_OFFLINE_MESSAGES,
  message,
});

export const resetMessage = () => ({
  type: RESET_MESSAGES,
});

export const subscribe = (subscription) => ({
  type: SUBSCRIBE,
  subscription,
});

export const updateSubscription = (subscriptionData) => ({
  type: UPDATE_SUBSCRIPTION,
  subscriptionData,
});

export const init = (isOverflowConversation) => ({
  type: RTM,
  action: INIT,
  isOverflowConversation,
});

export const reconnect = () => ({
  type: RTM,
  action: RECONNECT,
});

export const onMessage = (message) => ({
  type: ON_MESSAGE,
  message,
});

export const onAbnormalClose = () => ({
  type : ON_ABNORMAL_CLOSE
})

export const onConnect = (socketId) => ({
  type: ON_CONNECT,
  socketId,
});

export const onFailed = (message) => ({
  type: ON_FAILED,
});

export const closeConnection = (isOverflowConnection) => ({
  type: RTM,
  action: CLOSE_CONNECTION,
  isOverflowConnection,
});

export const destroyRtmServer = () => ({
  type: RTM,
  action: DESTROY_RTMSERVER,
});

export const addMessageToToBeSentList = (message) => ({
  type: ADD_MESSAGE_TO_TO_BE_SENT_LIST,
  message,
});

export const sendEmailMessage = (message) => ({
  type: SEND_EMAIL_REPLY,
  message,
});

export const onSubscribed = () => ({
  type: ON_SUBSCRIBE,
});

export const onNetworkUp = () => ({
  type: ON_NETWORKUP,
});

export const updateAvailabilityRequest = () => ({
  type: UPDATE_AVAILABILITY_REQUEST,
});

export const updateAvailabilityResponse = () => ({
  type: UPDATE_AVAILABILITY_RESPONSE,
});

export const resetConversationMessages = (conversationId) => ({
  type: RESET_CONVERSATION_MESSAGES,
  conversationId
});
export const updateMessageResponse = (message) => ({
  type: UPDATE_MESSAGE_RESPONSE,
  message
});

export const sendAWDesktopNotification = (messageId) => ({
  type: UPDATE_AW_MESSAGE_ID,
  messageId
})

export const resetMessageCursor = (conversationId) => ({
  type: RESET_MESSAGE_CURSOR,
  conversationId
});

export const addMessageValidationMessages = (messages, conversationId) => ({
  type: ADD_MESSAGES_TO_BE_VALIDATED,
  messages,
  conversationId
});

export const removeMessagesValidationMessages = (conversationId) => ({
  type: REMOVE_MESSAGES_TO_BE_VALIDATED,
  conversationId
});

export const clearConversationMessages = (conversationId) => ({
  type: CLEAR_CONVERSATION_MESSAGES,
  conversationId
})

export const onConnectResubscribe = () => ({
  type: ON_CONNECT_RESUBSCRIBE,
})
export const onMessageListFailed = () => ({
  type: ON_MESSAGE_LIST_FAILED,
});

export const sortMessagesValidation = (messageData) => ({
  type: ON_SORT_MESSAGES_VALIDATION,
  messageData
})

export const onOldConversationRequested = () => ({
  type: ON_OLD_CONVERSATION_REQUESTED,
})

export const onOldConversationResponse = () => ({
  type: ON_OLD_CONVERSATION_RESPONSE
})