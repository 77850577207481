import { SentIcon } from "../../../commons/Icons";
import { useChatConfigurationEntity } from "../../hooks/useChatConfigurationEntity";
import { useUpdateChatConfigurationEntity } from "../../hooks/useUpdateChatConfigurationEntity";
import axios from "axios";
import { createFileFromBlob, generateMessageId } from "../../../commons/Utility";
import { requestWidgetIconUploadUrl } from "../../../actions/AttachmentAction"; //should be changed to settings folder
import { useDispatch } from "react-redux";

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
} from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";
import {
  cropperWhiteBackground,
  cropperGrayBackground,
  isValidURL,
} from "../../../commons/Constants";
import { showVoicebox } from "../../../actions/VoiceboxAction.js";
import { Circle } from "rc-progress";
import "rc-progress/assets/index.css";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import { updateChatConfigRequest } from "../../actions/ChatConfigurationAction";
import { usePrevious } from "../../hooks/usePrevious";
import { PopupCloseIcon, CameraUploadButton } from "../../../commons/Icons";
import { EventEmitter } from "../../../settings/commons/event";

const WidgetStyle = (props) => {
  const dispatch = useDispatch();

  const [previewImageUrl, setPreviewImageUrl] = useState("default");
  const [isSelectingImage, setIsSelectingImage] = useState(false);
  const [isUploadingInprogress, setIsUploadingInprogress] = useState(false);
  const [hasCustomImageSelected, setHasCustomImageSelected] = useState(false);
  const [customImageUrl, setCustomImageUrl] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [cancelAction, setCancelAction] = useState(undefined);
  const [percent, setPercent] = useState(0);
  const [uploadedBigFile, setUploadedBigFile] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(true);
  const [files, setFiles] = useState(undefined);
  const [sliderValue, setSliderValue] = useState(1);
  const [showSlider, setShowSlider] = useState(false);
  const [postUpload, setPostUpload] = useState(false);
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const [fileName, setFileName] = useState("");

  const projectId = "LS-" + props.params.projectkey;
  const {
    chatConfiguration,
    isUpdating,
    isConfigFetched,
  } = useChatConfigurationEntity(projectId);
  const [chatWidgetIconURL, setChatWidgetIconURL] = useState("DEFAULT");
  const cropperRef = useRef(null);
  const userImgRef = useRef(null);
  const { setShowWidgetIconPopUp } = props;
  const prevIsUpdating = usePrevious(isUpdating);
  const [hasUploadedImage, setHasUploadedImage] = useState(false);
  const [hasClickedSuggestion, setHasClickedSuggestions] = useState(false);
  const [openCropperWindow, setOpenCropperWindow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [uploadingNewImage, setUploadingNewImage] = useState(false);
  const [userUploadedData, setUserUploadedData] = useState(undefined);

  const [selectedIcon, setSelectedIcon] = useState("default");

  let timerInterval = undefined;
  let hasUploadedProperly = false;

  const setChatWidgetIcon = (iconUrl) => {
    setChatWidgetIconURL(iconUrl);
  };

  const defaultImages = [
    "/images/user1.png",
    "/images/user2.png",
    "/images/user3.png",
    "/images/user4.png",
    "/images/user5.png",
    "/images/user6.png",
    "/images/user7.png",
    "/images/user8.png",
    "default",
  ];

  const defaultImagesURLs = [
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user2.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user3.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user4.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user5.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user6.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user7.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user8.png",
    "DEFAULT",
  ];

  const defaultImageURLsMap = {
    "/images/user1.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png",
    "/images/user2.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user2.png",
    "/images/user3.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user3.png",
    "/images/user4.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user4.png",
    "/images/user5.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user5.png",
    "/images/user6.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user6.png",
    "/images/user7.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user7.png",
    "/images/user8.png":
      "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user8.png",
    default: "DEFAULT",
  };

  const defaultURLImageMap = {
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png":
      "/images/user1.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user2.png":
      "/images/user2.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user3.png":
      "/images/user3.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user4.png":
      "/images/user4.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user5.png":
      "/images/user5.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user6.png":
      "/images/user6.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user7.png":
      "/images/user7.png",
    "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user8.png":
      "/images/user8.png",
    DEFAULT: "default",
  };

  useEffect(() => {
    let iconUrl = chatConfiguration
      ? chatConfiguration.chatWidgetIconURL
      : chatWidgetIconURL;
    let previewImageUrl = defaultImagesURLs.includes(iconUrl)
      ? defaultURLImageMap[iconUrl]
      : iconUrl;
    setPreviewImageUrl(previewImageUrl);
  }, [isConfigFetched]);

  useEffect(() => {
    if (prevIsUpdating && !isUpdating) {
      setHasClickedSuggestions(false);
      setHasUploadedImage(false);
      setUploadPercentage(100);
      if (!uploadingNewImage) {
        dispatch(
          showVoicebox({ message: "Update Successful", dismissAfter: 2000 })
        );
      }

      clearInterval(timerInterval);
      setIsUploadingInprogress(false);
      setShowNotification(false);
      setShowLoader(false);
      EventEmitter.dispatch("handleUploadingImage", {
        value: false,
        imageData: undefined,
        imageOpacity: 1,
      });
    }
  }, [isUpdating]);

  useEffect(() => {
    window.addEventListener("keydown", onEscape);
    window.addEventListener("mouseup", closeSuggestedIcon);
  }, []);

  // useEffect(()=>{
  //     if(uploadPercentage == 100){
  //         console.log("uploaded")

  //     } else {

  //         if(uploadPercentage > 0 && uploadPercentage < 100){
  //             dispatch(showVoicebox( { message : `Uploading Image ${uploadPercentage}%` , showLoader : true  } ) );
  //         }

  //     }

  // } , [uploadPercentage])

  //Did mount

  //willunmount
  useEffect(() => {
    return () => {
      console.log("will unmount");
      window.removeEventListener("keydown", onEscape);
      window.removeEventListener("mouseup", closeSuggestedIcon);
    };
  }, []);

  const onEscape = (event) => {
    if (event.key === "Escape" && event.keyCode == 27) {
      toggleCropperWindowState(false);
      setHasClickedSuggestions(false);
    }
  };

  const closeSuggestedIcon = (event) => {
    let element = document.getElementById("suggestion_list");
    if (element && element.className.includes("active open")) {
      setHasClickedSuggestions(false);
    }
  };

  // useEffect(() => {
  //     // add when mounted
  //     document.addEventListener("mousedown", handleClick);
  //     // return function to be called when unmounted
  //     return () => {
  //       document.removeEventListener("mousedown", handleClick);
  //     };
  //   }, []);

  const getImageNameForShowingActiveState = () => {
    if (previewImageUrl) {
      return previewImageUrl.split("/").pop();
    } else {
      return "";
    }
  };

  const isValidImageSize = (files) => {
    var file = null;
    var type;
    var size;

    for (var i = 0; i < files.length; i++) {
      file = files[i];
      type = file && file.type ? file.type : "";
      size = file.size / 1024000;
      if (size > 10) return false;
    }
    return true;
  };

  const canShowSaveButton = () => {
    let cropper = cropperRef.current;
    let chatWidgetIconURL = chatConfiguration
      ? chatConfiguration.chatWidgetIconURL
      : "DEFAULT";
    let isSuggestedIcon = defaultImages.includes(previewImageUrl);

    if (
      isSuggestedIcon &&
      defaultImageURLsMap[previewImageUrl] == chatWidgetIconURL
    )
      return false;
    else if (!isSuggestedIcon && previewImageUrl == chatWidgetIconURL) {
      if (cropper.getImageData().width == cropper.getContainerData().width)
        return false;
    }

    return true;
  };

  const selectSuggestedImage = (event) => {
    Event(
      chatConfiguration.key,
      "Button Style",
      "Suggestion Clicked",
      "Customize Chat Widget"
    );
    let userId = event.target.dataset.userid;
    let isActive = event.target.className.includes("active");
    if (userId && !isActive) {
      dispatch(showVoicebox({ message: "Updating", dismissAfter: 2000 }));
      setPreviewImageUrl(iconUrl);
      let iconUrl = userId === "default" ? "default" : `/images/${userId}.png`;
      setPostUpload(false);
      cropImage(event, iconUrl);
      setSelectedIcon(iconUrl);
      setHasClickedSuggestions(false);
    }
    setHasClickedSuggestions(false);
  };

  const disableCropperState = () => {
    if (defaultImages.includes(previewImageUrl) && !isSelectingImage)
      cropperRef.current.disable();
  };

  const onFocus = () => {
    if (isSelectingImage) {
      setIsSelectingImage(false);
    }
  };

  const enableCropperState = () => {
    cropperRef.current.enable();
  };

  const clearFileInput = () => {
    Event(
      chatConfiguration.key,
      "Button Style",
      "Upload Clicked",
      "Customize Chat Widget"
    );
    setIsSelectingImage(true);
  };

  // const handleClick = (event) => {
  //     if(!(node && node.current.contains(event.target))){
  //         hideMoreOptions();
  //     }
  // }

  const onImageSelect = (event) => {
    event.preventDefault();

    if (!isValidImageSize(event.target.files)) {
      setUploadedBigFile(true);
      dispatch(
        showVoicebox({
          message: "Upload a file with size less than 10 MB",
          dismissAfter: 2000,
        })
      );
      return;
    }

    var files;

    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }

    if (
      ["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(
        files[0].type
      )
    ) {
      setHasUploadedImage(true);
      toggleCropperWindowState(true);

      setPreviewImageUrl(cropperWhiteBackground);
      setUploadedBigFile(false);
      setSelectedFileType(files[0].type);
      setFileName(files[0].name);
      setShowSlider(false);
      setIsSelectedImage(true);

      var reader = new FileReader();

      reader.onload = function setState() {
        setUserUploadedData(reader.result);
        setPreviewImageUrl(reader.result);
        setIsSelectingImage(false);
        setHasCustomImageSelected(true);
        setCustomImageUrl(reader.result);
        setFiles(files);
        setShowSlider(true);
        setSliderValue(1);
      }.bind(this);

      reader.readAsDataURL(files[0]);
    } else {
      dispatch(
        showVoicebox({
          message: "Upload a JPG, JPEG, PNG or WEBP file and try again",
          dismissAfter: 2000,
        })
      );
    }
  };

  const cropImage = async (event, previewImageUrl = "") => {
    if (isSelectedImage) {
      dispatch(showVoicebox({ message: `Uploading` }));
    }

    setUploadingNewImage(true);
    Event(
      chatConfiguration.key,
      "Button Style",
      "Save Button Clicked",
      "Customize Chat Widget"
    );

    if (isUploadingInprogress) return;

    event.preventDefault();

    if (defaultImages.includes(previewImageUrl)) {
      setUploadingNewImage(false);
      setIsUploadingInprogress(false);
      let chatConfig = Object.assign({}, chatConfiguration);
      chatConfig.chatWidgetIconURL = defaultImageURLsMap[previewImageUrl];
      dispatch(
        updateChatConfigRequest({
          key: chatConfig.key,
          chatWidgetIconURL: chatConfig.chatWidgetIconURL,
        })
      );
      //dispatch( showVoicebox( { message : 'Updating...' , showLoader : true } ) );
      setHasCustomImageSelected(false);
      setShowSaveButton(false);
      return;
    }

    setIsUploadingInprogress(true);

    setShowSaveButton(false);
    setHasUploadedImage(false);

    if (typeof cropperRef.current.getCroppedCanvas() == "undefined") return;

    let roundedCanvas = cropperRef.current.getCroppedCanvas();
    let formData = new FormData();
    let fileNameFormatted = files[0].name;
    const blob = await new Promise(resolve => roundedCanvas.toBlob(resolve));
    let fileFromBlob = createFileFromBlob(blob , fileNameFormatted , files[0].type , files[0].size);
    formData.append("file", fileFromBlob);
    setUserUploadedData(roundedCanvas.toDataURL());
    EventEmitter.dispatch("handleUploadingImage", {
      value: true,
      imageData: roundedCanvas.toDataURL(),
      imageOpacity: 0.4,
    });
    let cancelAction = axios.CancelToken.source();

    setCancelAction(cancelAction);
    setUploadedBigFile(false);

    let requestData = {};
    
    requestData["fileName"] = fileNameFormatted;
    requestData["id"] = generateMessageId();
    requestData["projectId"] = chatConfiguration.key;
    requestData["formData"] = formData;

    let customConfig = {
      onUploadProgress: (event) => {
        let uploadPercentageValue = Math.round(
          (event.loaded / event.total) * 100
        );
        const containerRef = document.getElementById("container");
        if (uploadPercentageValue == 100) {
          let counter = 0;
          userImgRef.current.style.opacity = 0.1;
          timerInterval = setInterval(() => {
            counter += 10;
            setUploadPercentage(counter);

            if (counter <= 40) {
              userImgRef.current.style.opacity = 0.4;
            } else if (counter <= 60) {
              userImgRef.current.style.opacity = 0.6;
              EventEmitter.dispatch("handleUploadingImage", {
                value: true,
                imageData: roundedCanvas.toDataURL(),
                imageOpacity: 0.6,
              });
            } else if (counter <= 99) {
              userImgRef.current.style.opacity = 0.8;
              EventEmitter.dispatch("handleUploadingImage", {
                value: true,
                imageData: roundedCanvas.toDataURL(),
                imageOpacity: 0.8,
              });
            } else if (counter === 100) {
              userImgRef.current.style.opacity = 1;
              EventEmitter.dispatch("handleUploadingImage", {
                value: true,
                imageData: roundedCanvas.toDataURL(),
                imageOpacity: 1,
              });
              clearInterval(timerInterval);
              dispatch(
                showVoicebox({
                  message: "Upload Successful",
                  dismissAfter: 2000,
                })
              );
            }
            console.log("upload Percentage : ", counter);
          }, 200);
        } else {
          // hasUploadedProperly = true;
          // setUploadPercentage(uploadPercentage);
        }
      },
      cancelToken: cancelAction.token,
    };
    requestData["customConfig"] = customConfig;
    dispatch(requestWidgetIconUploadUrl(requestData));
    setSelectedIcon(previewImageUrl);
  };

  const cancelFileUpload = () => {
    if (uploadPercentage !== 100) {
      cancelAction.cancel("upload_cancelled_by_user");

      // this.setState({isCanceled : true});
      setTimeout(() => {
        var reader = new FileReader();

        reader.onload = function setState() {
          setUserUploadedData(reader.result);
          setPreviewImageUrl(reader.result);
          setIsSelectingImage(false);
          setHasCustomImageSelected(true);
          setCustomImageUrl(reader.result);
          setIsUploadingInprogress(false);
          setUploadPercentage(0);
          setPostUpload(false);
        }.bind(this);

        if (files && files[0]) reader.readAsDataURL(files[0]);
        else {
          setPreviewImageUrl(chatConfiguration.chatWidgetIconURL);
          setIsSelectingImage(false);
          setIsUploadingInprogress(false);
          setUploadPercentage(0);
          setPostUpload(false);
        }
      }, 2000);
    }
  };

  const onZoom = (event) => {
    if (!isDefaultImagesSelected()) {
      let zoomValue = 0;
      let zoomLevel = parseInt(sliderValue);

      if (postUpload && !showSaveButton) setShowSaveButton(true);

      if (event.detail.oldRatio > event.detail.ratio) {
        zoomValue = zoomLevel - 1 >= 1 ? zoomLevel - 1 : 1;
        setSliderValue(zoomValue);
      } else if (event.detail.oldRatio < event.detail.ratio) {
        if (sliderValue >= 10) {
          event.preventDefault();
          return;
        }
        zoomValue = zoomLevel + 1 <= 10 ? zoomLevel + 1 : 1;
        setSliderValue(zoomValue);
      }
    }
  };

  const zoomIn = () => {
    let zoomValue = 0;

    if (postUpload && !showSaveButton) setShowSaveButton(true);

    if (sliderValue < 10) {
      cropperRef.current.zoom(0.1);
      zoomValue = sliderValue >= 1 ? +sliderValue : 1;
      zoomValue = zoomValue + 1;
      setSliderValue(parseInt(zoomValue));
      Event(
        chatConfiguration.key,
        "Slider Style",
        "Zoom in clicked",
        "Customize Chat Widget"
      );
    }
  };

  const zoomOut = () => {
    let zoomValue = 0;

    if (postUpload && !showSaveButton) setShowSaveButton(true);

    if (sliderValue > 1) {
      cropperRef.current.zoom(-0.1);
      zoomValue = sliderValue <= 10 ? +sliderValue : 10;
      zoomValue = zoomValue - 1;
      setSliderValue(parseInt(zoomValue));
      Event(
        chatConfiguration.key,
        "Slider Style",
        "Zoom out clicked",
        "Customize Chat Widget"
      );
    }
  };

  const zoomPreview = (event) => {
    let previousValue = parseInt(sliderValue) || 1;
    let currentValue = parseInt(event.target.value);
    let zoomLevel = 0;
    let displacement = 0;

    if (postUpload && !showSaveButton) setShowSaveButton(true);

    let isZoomingIn = currentValue > previousValue;
    let isZoomingOut = previousValue > currentValue;

    if (isZoomingIn) {
      displacement = currentValue - previousValue;
      if (displacement > 1) return;
      zoomLevel = 0.1 * displacement;
      setSliderValue(currentValue);
      cropperRef.current.zoom(zoomLevel);
      Event(
        chatConfiguration.key,
        "Slider Style",
        "Zoom in dragged",
        "Customize Chat Widget"
      );
    } else if (isZoomingOut) {
      displacement = previousValue - currentValue;
      if (displacement > 1) return;
      zoomLevel = -0.1 * displacement;
      setSliderValue(currentValue);
      cropperRef.current.zoom(zoomLevel);
      Event(
        chatConfiguration.key,
        "Slider Style",
        "Zoom out dragged",
        "Customize Chat Widget"
      );
    }

    setSliderValue(currentValue);
  };

  const onMove = (event) => {
    if (!showSaveButton || postUpload) {
      setPostUpload(false);
      setShowSaveButton(true);
    }
  };

  const getSuggestedIcons = () => {
    let toRender = [];
    let widgetThemeColor = chatConfiguration
      ? chatConfiguration.widgetThemeColor
      : "6699ff";
    for (let i = 1; i <= 8; i++) {
      toRender.push(
        <figure
          className={
            getImageNameForShowingActiveState() === `user${i}.png`
              ? "active"
              : ""
          }
          onClick={selectSuggestedImage}
          data-userid={`user${i}`}
          style={{
            backgroundColor: "#" + widgetThemeColor + "!important",
            borderColor: "#" + widgetThemeColor,
          }}
        >
          <img
            onClick={openSuggestionListHander}
            onKeyDown={ () => {} }
            data-userid={`user${i}`}
            src={`https://storage.googleapis.com/livesupport/chat/images/widgeticons/user${i}.png`}
          />
        </figure>
      );
    }
    return toRender;
  };

  const getOpacityForUserUploadedImage = (imgData = "") => {
    // if(( isConfigFetched && !isCustomImageUrl && !hasSelectedDefaultIcon || isUploadingInprogress   ))
    //     return {"display" : "block"}
    // else
    //     return {"display" : "none"}
    // let toLoad = getLoader();
    if (imgData.startsWith("http")) return { opacity: "1" };
    else if (userUploadedData) return { opacity: "0.1" };
    else return { opacity: "1" };
  };

  const getUserUploadedIconClassName = () => {
    if (!hasSelectedDefaultIcon && !isCustomImageUrl && !isUploadingInprogress)
      return "widget-default-pic widget-style-upload active";
    else return "widget-default-pic widget-style-upload";
  };

  const getUserUploadedImage = () => {
    let widgetThemeColor = chatConfiguration
      ? chatConfiguration.widgetThemeColor
      : "6699ff";
    let widgetIconURL = chatConfiguration
      ? chatConfiguration.chatWidgetIconURL
      : "DEFAULT";
    let widgeticon = userUploadedData ? userUploadedData : previewImageUrl;
    return (isConfigFetched && !isCustomImageUrl && !hasSelectedDefaultIcon) ||
      isUploadingInprogress ? (
      <code
        ref={userImgRef}
        style={getOpacityForUserUploadedImage(widgeticon)}
        className={getUserUploadedIconClassName()}
      >
        {" "}
        <figure id="container" style={{ backgroundColor: "#ffffff" }}>
          <img
            style={{
              display:
                widgeticon === "DEFAULT" && !isUploadingInprogress
                  ? "none"
                  : "block",
              borderColor: "#" + widgetThemeColor,
              cursor: "pointer",
            }}
            src={userUploadedData ? userUploadedData : previewImageUrl}
          />
        </figure>
      </code>
    ) : (
      ""
    );
  };

  const getDefaultIcon = () => {
    let isCustomImageUrl =
      hasCustomImageSelected &&
      isValidURL(customImageUrl) &&
      !defaultImages.includes(previewImageUrl) &&
      !defaultImagesURLs.includes(previewImageUrl);
    let widgetThemeColor = chatConfiguration
      ? chatConfiguration.widgetThemeColor
      : "6699ff";
    let widgeticon = chatConfiguration
      ? chatConfiguration.chatWidgetIconURL
      : "DEFAULT";
    let backGroundColor =
      widgeticon !== "DEFAULT" ? "#ffffff" : "#" + widgetThemeColor;
    let hasSelectedDefaultIcon =
      chatConfiguration && chatConfiguration.chatWidgetIconURL
        ? chatConfiguration.chatWidgetIconURL === "DEFAULT"
        : false;
    widgeticon =
      isCustomImageUrl && !hasSelectedDefaultIcon
        ? widgeticon
        : "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png";

    return isConfigFetched ? (
      <figure
        data-userid={"default"}
        style={{
          backgroundColor: backGroundColor,
          borderColor: "#" + widgetThemeColor,
        }}
      >
        {/* <img style={{"display" : ( widgeticon !== 'DEFAULT' ? 'block' : 'none' ) , "borderColor" : '#' + widgetThemeColor, "cursor" : "pointer" }} src={ widgeticon } />  */}
        <img
          style={{
            display: widgeticon !== "DEFAULT" ? "block" : "",
            borderColor: "#" + widgetThemeColor,
            cursor: "pointer",
          }}
          src={widgeticon}
        />
      </figure>
    ) : (
      ""
    );
  };

  const getPredefinedImages = () => {
    let customImageUrl =
      chatConfiguration && chatConfiguration.chatWidgetIconURL
        ? chatConfiguration.chatWidgetIconURL
        : "DEFAULT";
    let isCustomImageUrl =
      isValidURL(customImageUrl) &&
      !defaultImages.includes(customImageUrl) &&
      !defaultImagesURLs.includes(customImageUrl);
    let widgeticon = chatConfiguration
      ? chatConfiguration.chatWidgetIconURL
      : "DEFAULT";
    widgeticon =
      isCustomImageUrl || widgeticon === "DEFAULT"
        ? "https://storage.googleapis.com/livesupport/chat/images/widgeticons/user1.png"
        : widgeticon;
    return isConfigFetched ? (
      <figure data-userid={"default"}>
        {/* <img style={{"display" : ( widgeticon !== 'DEFAULT' ? 'block' : 'none' ) , "borderColor" : '#' + widgetThemeColor, "cursor" : "pointer" }} src={ widgeticon } />  */}
        <img src={widgeticon} />
      </figure>
    ) : (
      ""
    );
  };

  const getFileName = () => {
    return fileName ? <span>{fileName}</span> : "";
  };

  const isDefaultImagesSelected = (imageSrc) => {
    return (
      defaultImagesURLs.includes(imageSrc) ||
      defaultImages.includes(imageSrc) ||
      imageSrc === cropperWhiteBackground
    );
  };

  const getUploadImageText = () => {
    if (uploadedBigFile) {
      return <label style={{ color: "#dd2727" }}>File size too big.</label>;
    } else {
      return <label>Upload your image</label>;
    }
  };

  const openSuggestionListHander = (event) => {
    setHasClickedSuggestions(true);
  };

  const getClassNameForSuggestions = () => {
    if (isCustomImageUrl && hasClickedSuggestion)
      return "widget-default-pic active open";
    else if (hasClickedSuggestion) return "widget-default-pic active open";
    else if (
      isCustomImageUrl &&
      !hasClickedSuggestion &&
      !isUploadingInprogress &&
      !hasCustomImageSelected
    )
      return "widget-default-pic active";
    else if (
      isCustomImageUrl &&
      !hasClickedSuggestion &&
      !hasCustomImageSelected
    )
      return "widget-default-pic active";
    else return "widget-default-pic";
  };

  const toggleCropperWindowState = (state) => {
    setOpenCropperWindow(state);
    setUserUploadedData(undefined);
    setHasCustomImageSelected(false);
  };

  let imageSrc =
    previewImageUrl && previewImageUrl.toLowerCase() == "default"
      ? cropperWhiteBackground
      : previewImageUrl;
  let customImageUrltoLoad =
    chatConfiguration && chatConfiguration.chatWidgetIconURL
      ? chatConfiguration.chatWidgetIconURL
      : "DEFAULT";
  let isCustomImageUrl =
    isValidURL(customImageUrltoLoad) &&
    defaultImagesURLs.includes(customImageUrltoLoad);
  let defaultColor = chatConfiguration
    ? `#${chatConfiguration.widgetThemeColor}`
    : "#6699ff";
  let hasSelectedDefaultIcon =
    chatConfiguration && chatConfiguration.chatWidgetIconURL
      ? chatConfiguration.chatWidgetIconURL === "DEFAULT"
      : false;
  console.log("isCustomImageUrl ::", isCustomImageUrl);
  console.log("hasSelectedDefaultIcon ::", hasSelectedDefaultIcon);



  return (
    <div>
      {/* <div class="cs-widget-notification" style={ showNotification ? {} : {"display" : "none"} }>
        <div class="cs-loading-circle" style={showLoader ? {} :  {"display" : "none"}}>
          <div class="cs-circle1 cs-circle"></div>
          <div class="cs-circle2 cs-circle"></div>
          <div class="cs-circle3 cs-circle"></div>
          <div class="cs-circle4 cs-circle"></div>
          <div class="cs-circle5 cs-circle"></div>
          <div class="cs-circle6 cs-circle"></div>
          <div class="cs-circle7 cs-circle"></div>
          <div class="cs-circle8 cs-circle"></div>
          <div class="cs-circle9 cs-circle"></div>
          <div class="cs-circle10 cs-circle"></div>
          <div class="cs-circle11 cs-circle"></div>
          <div class="cs-circle12 cs-circle"></div>
        </div>
        { `Uploading Image ${uploadPercentage} %` } <span></span>
    </div> */}

      <div class="cs-widget-style">
        <label>Widget Icon</label>
        <div class="widget-style-icon">
          <code
            onClick={selectSuggestedImage}
            data-userid={"default"}
            class={
              hasSelectedDefaultIcon && !isUploadingInprogress
                ? "widget-default-pic active"
                : "widget-default-pic"
            }
            style={{ backgroundColor: defaultColor }}
          ></code>
          <code
            id="suggestion_list"
            onClick={openSuggestionListHander}
            style={{ backgroundColor: "#fff" }}
            class={getClassNameForSuggestions()}
          >
            {getPredefinedImages()}
            <div>{getSuggestedIcons()}</div>
          </code>
          {getUserUploadedImage()}

          <div class="cs-widget-upload">
            <input
              type="File"
              disabled={isUploadingInprogress}
              onClick={clearFileInput}
              onChange={onImageSelect}
              accept="image/png,image/jpeg,image/jpg,image/webp"
            />
            <CameraUploadButton />
          </div>
        </div>
      </div>
      <div
        class="cs-popupbg"
        style={{
          display: hasUploadedImage && openCropperWindow ? "block" : "none",
        }}
      ></div>
      <div
        class="cs-popup-model cs-popup-cropping"
        style={{
          display: hasUploadedImage && openCropperWindow ? "block" : "none",
        }}
      >
        <header>
          <h2>Crop Image</h2>
          <cite
            onClick={() => {
              toggleCropperWindowState(false);
            }}
          >
            &times;
          </cite>
        </header>
        <section>
          <div
            class="cs-crop-widget"
            onMouseOver={disableCropperState}
            onMouseOut={enableCropperState}
          >
            {/* <code className={previewImageUrl && previewImageUrl.toLowerCase() == 'default' ? "circle-crop default-icon" : "circle-crop"} style={{borderColor: '#'+chatConfiguration.widgetThemeColor , backgroundColor : ( previewImageUrl && previewImageUrl.toLowerCase() == 'default' ? '#'+chatConfiguration.widgetThemeColor : '' ) }}></code> */}
            <div style={{ display: "none" }} className="zoom-panel">
              <i onClick={zoomOut}>-</i>
              <i onClick={zoomIn}>+</i>
              <input
                type="range"
                value={sliderValue}
                min="1"
                max="10"
                onChange={zoomPreview}
                step="1"
                className="zoom-track-pad"
              />
            </div>
            <Cropper
              ref={cropperRef}
              src={ (typeof imageSrc !== 'undefined' && imageSrc.length === 17 ) ? defaultImageURLsMap[imageSrc] : imageSrc }
              style={{
                height: 350,
                width: 350,
                position: "absolute",
                right: 30,
                top: 84,
              }}
              cropBoxMovable={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              guides={false}
              background={false}
              highlight={false}
              dragMode={"move"}
              center={false}
              restore={false}
              minCropBoxHeight={350}
              minCropBoxWidth={350}
              minCanvasHeight={350}
              minCanvasWidth={350}
              minContainerHeight={350}
              minContainerWidth={350}
              viewMode={3}
              zoom={onZoom}
              cropmove={onMove}
              wheelZoomRatio={0.1}
            />
          </div>
        </section>
        <footer>
          <a
            style={{ opacity: !canShowSaveButton() ? "0.5" : "1.0" }}
            disabled={!canShowSaveButton() ? true : false}
            onClick={cropImage}
            href="#"
            className={
              canShowSaveButton()
                ? "cs-btn cs-primary-btn"
                : "cs-btn cs-disabled-btn"
            }
            href="javascript:void(0)"
            class="cs-btn cs-primary-btn"
          >
            Save
          </a>
        </footer>
      </div>
    </div>
  );

  return (
    <div>
      <div className="cs-popupbg"></div>
      <div className="cs-popup-container">
        <aside className="cs-popup customize-style-popup">
          <header>
            <h2>Customize Widget Style</h2>

            <cite
              onClick={() => {
                setShowWidgetIconPopUp(false);
              }}
            >
              <PopupCloseIcon />
            </cite>
          </header>
          <div>
            <div
              className={
                !isDefaultImagesSelected(imageSrc)
                  ? "cs-choos-widget active"
                  : "cs-choos-widget"
              }
            >
              {getUploadImageText()}
              <p>
                Upload a PNG, JPEG, or JPG with a max size of 10 mb to use as
                your widget
              </p>
              <a href="#">
                <input
                  type="file"
                  onClick={clearFileInput}
                  onChange={onImageSelect}
                  accept="image/png,image/jpeg,image/jpg"
                />
                {getFileName()}
                {imageSrc && !isDefaultImagesSelected(imageSrc)
                  ? "Upload another"
                  : "Upload Image"}
              </a>
            </div>

            <div
              className={
                isDefaultImagesSelected(imageSrc)
                  ? "cs-choos-widget active"
                  : "cs-choos-widget"
              }
            >
              <label>Choose from available defaults</label>
              <ul>
                {getSuggestedIcons()}
                {getDefaultIcon()}
              </ul>
            </div>
            <div
              onMouseOver={disableCropperState}
              onMouseOut={enableCropperState}
            >
              <code
                className={
                  previewImageUrl && previewImageUrl.toLowerCase() == "default"
                    ? "circle-crop default-icon"
                    : "circle-crop"
                }
                style={{
                  borderColor: "#" + chatConfiguration.widgetThemeColor,
                  backgroundColor:
                    previewImageUrl &&
                    previewImageUrl.toLowerCase() == "default"
                      ? "#" + chatConfiguration.widgetThemeColor
                      : "",
                }}
              ></code>
              <div style={{ display: "none" }} className="zoom-panel">
                <i onClick={zoomOut}>-</i>
                <i onClick={zoomIn}>+</i>
                <input
                  type="range"
                  value={sliderValue}
                  min="1"
                  max="10"
                  onChange={zoomPreview}
                  step="1"
                  className="zoom-track-pad"
                />
              </div>
              <Cropper
                ref={cropperRef}
                src={imageSrc}
                style={{
                  height: 350,
                  width: 350,
                  position: "absolute",
                  right: 30,
                  top: 84,
                }}
                cropBoxMovable={false}
                cropBoxResizable={false}
                toggleDragModeOnDblclick={false}
                guides={false}
                background={false}
                highlight={false}
                dragMode={"move"}
                center={false}
                restore={false}
                minCropBoxHeight={350}
                minCropBoxWidth={350}
                minCanvasHeight={350}
                minCanvasWidth={350}
                minContainerHeight={350}
                minContainerWidth={350}
                viewMode={3}
                zoom={onZoom}
                cropmove={onMove}
                wheelZoomRatio={0.1}
              />
              <div
                style={{ display: isUploadingInprogress ? "block" : "none" }}
                className="chat-ball-pulse"
              >
                <code></code>
                <code></code>
                <code></code>
              </div>
            </div>
            <a
              style={{ opacity: !canShowSaveButton() ? "0.5" : "1.0" }}
              disabled={!canShowSaveButton() ? true : false}
              onClick={cropImage}
              href="#"
              className={
                canShowSaveButton()
                  ? "cs-btn cs-primary-btn"
                  : "cs-btn cs-disabled-btn"
              }
            >
              Save Icon
            </a>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default WidgetStyle;
