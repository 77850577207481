import React, { Component } from "react";
import {
  TAKING_CHATS_STATUS_MAP,
  TAKING_CHATS_TEXT_MAP,
  AVAILABILITY_CLASS,
} from "../../commons/Constants";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import * as RolesUtility from "../../commons/RolesUtility";

class UserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatusDropdown: false,
      showImgTag: true,
      subscribedAvailability: false,
    };

    this.toggleStatusDropdown = this.toggleStatusDropdown.bind(this);
    this.switchStatus = this.switchStatus.bind(this);
    this.setUserStatusRef = this.setUserStatusRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onImgLoadError = this.onImgLoadError.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.getStyle = this.getStyle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // if( nextProps.currentStaff['takingChatStatus'] != undefined
    //         &&  this.state.subscribedAvailability == false) {
    //     this.state.subscribedAvailability = true;
    //     console.info( "Subscribed once:"+nextProps.currentStaff['takingChatStatus'] );
    //     this.props.handleStatusSwitch(nextProps.currentStaff['takingChatStatus']);
    // }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  toggleStatusDropdown(event) {
    if (
      event &&
      event.target.tagName != "U" &&
      !this.props.isLoadedAsAdmin() &&
      RolesUtility.isUserHavingPermission("OnlineStatusSwitch")
    ) {
      if (!this.state.showStatusDropdown) {
        this.setState({ showStatusDropdown: true });
        Event(
          "LS-" + this.props.projectId,
          "User Status",
          "Status Arrow",
          "Arrow Icon Clicked"
        );
      }
    }
  }

  handleClickOutside(event) {
    if (
      this.state.showStatusDropdown &&
      this.userStatusRef &&
      !this.userStatusRef.contains(event.target)
    )
      this.setState({ showStatusDropdown: false });
  }

  switchStatus(takingChatStatus) {
    if (!this.props.isLoadedAsAdmin()) {
      this.props.handleStatusSwitch(takingChatStatus);
      //console.log(event.target.innerHTML === "Go online?");
      if (event && event.target.innerHTML === "Go online?")
        Event(
          "LS-" + this.props.projectId,
          "User Status",
          "Go Online",
          takingChatStatus
        );
      else
        Event(
          "LS-" + this.props.projectId,
          "User Status",
          takingChatStatus,
          takingChatStatus + " Clicked"
        );
      this.setState({ showStatusDropdown: false });
    }
  }

  setUserStatusRef(node) {
    this.userStatusRef = node;
  }

  onImgLoadError() {
    this.setState({ showImgTag: false });
  }

  onImgLoad() {
    this.setState({ showImgTag: true });
  }

  getStyle(photoUrl) {
    let style = {};
    if (
      photoUrl &&
      !photoUrl.includes("no-image") &&
      !photoUrl.includes("null")
    )
      style.backgroundColor = "white";
    return style;
  }

  getUserStatusClassName(showStatusDropdown) {
    let className = "cs-user-status-list ";
    let currentLocation = document.location.href;
    className = showStatusDropdown ? className+ "open" : className+ "open1";
    className = currentLocation.includes("widget/embed/setup") || currentLocation.includes("app/install")  ? className+ " dn" : className;
    return className;
  }

  render() {
    let { currentStaff = {} } = this.props;
    let isTakingChat = currentStaff.isTakingChat;
    let takingChatStatus = currentStaff.takingChatStatus
      ? currentStaff.takingChatStatus
      : "";
    let isOffline = takingChatStatus == "OFFLINE";
    let { photoUrl, firstName, lastName } = currentStaff;
    let { showStatusDropdown } = this.state;

    if (!takingChatStatus) takingChatStatus = "OFFLINE";

    console.info("takingChatStatus:" + takingChatStatus);
    let restrictSwitchStatus =
      this.props.isLoadedAsAdmin() ||
      !RolesUtility.isUserHavingPermission("OnlineStatusSwitch")
        ? true
        : false;
    let userStatusStyle = this.props.isFullScreenMode ? {"display" : "none"} : {};

    return (
      <div className="cs-user-control" style={userStatusStyle}>
        <div className="cs-user-profile">
          <figure
            className="offline fs-exclude"
            style={this.getStyle(photoUrl)}
          >
            <img
              style={{
                display: photoUrl && this.state.showImgTag ? "block" : "none",
              }}
              src={photoUrl}
              onError={this.onImgLoadError}
              onLoad={this.onImgLoad}
            />
          </figure>
          <cite className={AVAILABILITY_CLASS[takingChatStatus]}></cite>
        </div>
        {RolesUtility.isUserHavingPermission("OnlineStatusSwitch") ? (
          <div
            className={
              this.getUserStatusClassName(showStatusDropdown)
            }
            ref={this.setUserStatusRef}
          >
            <div
              style={{
                cursor: restrictSwitchStatus
                  ? "not-allowed"
                  : this.state.showStatusDropdown
                  ? "auto"
                  : "pointer",
              }}
              className={
                takingChatStatus == "OFFLINE" && !showStatusDropdown
                  ? "offline-status"
                  : "offline-status1"
              }
              onClick={this.toggleStatusDropdown}
            >
              <figure className="offline">
                {this.state.showImgTag ? (
                  <img src={photoUrl} onError={this.onImgLoadError} />
                ) : (
                  ""
                )}
              </figure>
              <cite className={AVAILABILITY_CLASS[takingChatStatus]}></cite>
              <p>{TAKING_CHATS_STATUS_MAP[takingChatStatus]}</p>
              <span>
                {TAKING_CHATS_TEXT_MAP[takingChatStatus]}
                <u
                  onClick={() => this.switchStatus("AVAILABLE")}
                  onKeyDown={ () => {} }
                  style={{
                    display:
                      takingChatStatus == "OFFLINE" &&
                      !this.state.showStatusDropdown
                        ? "inline-block"
                        : "none",
                    cursor: restrictSwitchStatus ? "not-allowed" : "pointer"    
                  }}
                >
                  Go online?
                </u>
              </span>
              <i
                className="up-arrow"
                style={{
                  cursor: restrictSwitchStatus ? "not-allowed" : "pointer",
                }}
              ></i>
            </div>
            <ul>
              <li
                id="AVAILABLE"
                onClick={() => this.switchStatus("AVAILABLE")}
                className={takingChatStatus == "AVAILABLE" ? "active" : ""}
              >
                <cite className="available"></cite>
                <p>I'm Available</p>
                <span>Taking chats</span>
              </li>
              <li
                id="DND"
                onClick={() => this.switchStatus("DND")}
                className={takingChatStatus == "DND" ? "active" : ""}
              >
                <cite className="busy"></cite>
                <p>Do Not Disturb</p>
                <span>Not taking chats</span>
              </li>
              <li
                id="LUNCH"
                onClick={() => this.switchStatus("LUNCH")}
                className={takingChatStatus == "LUNCH" ? "active" : ""}
              >
                <cite className="busy"></cite>
                <p>At Lunch</p>
                <span>Not taking chats</span>
              </li>
              <li
                id="VACATION"
                onClick={() => this.switchStatus("VACATION")}
                className={takingChatStatus == "VACATION" ? "active" : ""}
              >
                <cite className="busy"></cite>
                <p>Vacation</p>
                <span>Not taking chats</span>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default UserStatus;
