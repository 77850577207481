import React, { Fragment, useState } from "react";
import VisitorQueueDetails from "../VisitorQueue/VisitorQueueDetails.jsx";
import NoVisitorPlaceholder from "../VisitorQueue/NoVisitorPlaceholder.jsx";
import { getUserPermissionUtility } from "../../commons/Utility.js";
import {
  ConversationQueueIcon,
  VisitorQueueIcon,
} from "../../commons/Icons.js";
import ConversationQueueDetails from "./ConversationQueueDetails.jsx";
import { useEffect } from "react";
import { ConversationQueueDetailsTabLoader, VisitorQueueDetailsTabLoader } from "../../settings/commons/loaders.js";
import { QUEUE_TYPE } from "../../commons/Constants.js";
import { useDispatch } from "react-redux";
import { enableConversationTabSwitching, firstTimeLanding } from "../../actions/VisitorQueueActions.js";

const QueueComponent = (props) => {
  const {
    projectList,
    visitorMap,
    createNewConversation,
    projectColorMap,
    isConversationQueue,
    queuedConversationInfo,
    isQueueFetched,
    visitorQueueReducer,
    getLastMessageOfConversation,
  } = props;
  const [queueType,setQueueType] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    if(isConversationQueue)
      setQueueType("conversation");
    else
      setQueueType("visitor");
  },[isConversationQueue]);

  const getVisitorQueueDetails = () => {
    let toRender = [];
    for (let visitorKey in visitorMap) {
      if (!visitorMap[visitorKey].isDeleted)
        toRender.push(
          <VisitorQueueDetails
            key={visitorKey}
            details={visitorMap[visitorKey]}
            createNewConversation={createNewConversation}
            projectColorMap={projectColorMap}
          />
        );
    }

    return toRender;
  };

  const getVisitorCount = () => {
    let count = 0;
    for (let visitorKey in visitorMap) {
      if (!visitorMap[visitorKey].isDeleted) count++;
    }
    return count;
  };

  const getConversationQueueDetails = () => {
    let toRender = [];
    queuedConversationInfo.forEach((conversation) => {
      toRender.push(
        <ConversationQueueDetails
          key={conversation.conversationInfo.key}
          conversationInfo={conversation.conversationInfo}
          contactInfo={conversation.contactInfo}
          acceptChat={props.acceptChat}
          getLastMessageOfConversation={getLastMessageOfConversation}
        />
      )
    })
    return toRender;
  };

  const conversationQueueLoader = () => {
    return (
      <React.Fragment>
        {getQueueContent()}
        <ul className="cs-proactive-list conversation">
          <ConversationQueueDetailsTabLoader loaderCount={2} />
        </ul>
      </React.Fragment>
    );
  };

  const visitorQueueLoader = () => {
    return (
      <React.Fragment>
        {getQueueContent()}
        <ul className="cs-proactive-list visitor">
          <VisitorQueueDetailsTabLoader loaderCount={2} />
        </ul>
      </React.Fragment>
    );
  };

  const getQueueContent = () => {
    return (
      <div class="cs-proactive-list-head">
        <label>Name</label>
        <label>{isConversationQueue ? "Wait Time" : "Visitor Status"}</label>
        <label>Actions</label>
      </div>
    );
  };
  
  const canShowTheQueuedDetails = () => {
    return (
      (getVisitorCount() > 0 && !isConversationQueue) ||
      (getConversationQueueCount() > 0 &&
        isConversationQueue
        )
    );
  };
  const pageSwitchingLoader = () => {
    if(visitorQueueReducer.isPageSwitching){
      dispatch(firstTimeLanding());
    }
    if(!visitorQueueReducer.showConversationList){
      dispatch(enableConversationTabSwitching());
    }
  }
  const getQueueDetails = () => {
    if (canShowTheQueuedDetails()) {
      pageSwitchingLoader();
      return (
        <div>
          {getQueueContent()}
          {queueType === QUEUE_TYPE.CONVERSATION ? (
            <ul className="cs-proactive-list conversation">
              {getConversationQueueDetails()}
            </ul>
          ) : (
            <ul className="cs-proactive-list visitor">
              {getVisitorQueueDetails()}
            </ul>
          )}
        </div>
      );
    }
    if (showVisitorPlaceHolder() || showConversationPlaceHolder()) {
      pageSwitchingLoader();
      return <NoVisitorPlaceholder isConversationQueue={isConversationQueue} />;
    }
  };

  const showVisitorPlaceHolder = () => {
    return getVisitorCount() == 0;
  }

  const showConversationPlaceHolder = () => {
    return (
      getConversationQueueCount() == 0
    );
  }

  const getVisitorOnlineCountStatus = () => {
    let count = getVisitorCount();
    let countStatus = "";

    if (count == 0) countStatus = "No Visitors Online";
    else if (count == 1) countStatus = "1 Visitor Online";
    else if (count > 1) countStatus = `${count} Visitors Online`;
    else countStatus = "No Visitors Online";

    return countStatus;
  };

  const getConversationQueueCount = () => {
    return queuedConversationInfo.length;
  };

  const getConversationQueueText = () => {
    let length = getConversationQueueCount();
    if (length > 0)
      return length == 1
        ? `${length} Chat in Queue`
        : `${length} Chats in Queue`;
    else return `No Chats in Queue`;
  }

  const showTheQueueHeader = () => {
    return !visitorQueueReducer.isPageSwitching;
  };

  const getQueueDefaultLoader = () => {
    return <section class="cs-conversation">
    <header>
      <figure
        className="cs-chat-group"
        style={{ backgroundColor: "rgb(244, 244, 244)" }}
      >
        <em
          className="progress"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "100%",
            backgroundColor: "#f4f4f4",
          }}
        ></em>
      </figure>
      <label>
        <em className="progress" style={{ width: "190px" }}></em>
      </label>
      <p>
        <em
          className="progress"
          style={{ width: "100px", margin: "6px 0px 0px" }}
        ></em>
      </p>
    </header>
    {visitorQueueReducer.isPageSwitching ? (
      <span />
    ) : (
      <section class="cs-proactive">
        {getQueueContentLoader()}
      </section>
    )}
  </section>;
  }
  const showQueueComponentDetails = () => {
    return (
      getUserPermissionUtility("OnlineStatusSwitch") &&
      isQueueFetched &&
      visitorQueueReducer.isInitialFetchComplete &&
      projectList &&
      projectList.length > 0
    );
  };
  const getQueueContentLoader = () =>
    queueType === QUEUE_TYPE.CONVERSATION
      ? conversationQueueLoader()
      : visitorQueueLoader();

  const getQueueHeaderLoader = () => {
    return <Fragment>
    <label>
      <em className="progress" style={{ width: "190px" }}></em>
    </label>
    <p>
      <em
        className="progress"
        style={{ width: "100px", margin: "6px 0px 0px" }}
      ></em>
    </p>
  </Fragment>;
  }
  const getQueueHeader = () => {
    if (showTheQueueHeader()) {
      return (
        <Fragment>
          <label>{isConversationQueue ? "In Queue" : "Visitors"}</label>
          <p>
            {isConversationQueue
              ? getConversationQueueText()
              : getVisitorOnlineCountStatus()}
          </p>
        </Fragment>
      );
    } else {
      return getQueueHeaderLoader();
    }
  };
  if (showQueueComponentDetails()) {
    return (
      <section class="cs-conversation">
        <header>
          {isConversationQueue ? (
            <ConversationQueueIcon />
          ) : (
            <VisitorQueueIcon />
          )}
          {getQueueHeader()}
        </header>
        <section class="cs-proactive">{getQueueDetails()}</section>
      </section>
    );
  }
  return getQueueDefaultLoader();
};

export default QueueComponent;