import React, { useState, useEffect } from "react";
import WidgetVisibilityComponent from "./WidgetVisibilityComponent.jsx";
import { useSelector, useDispatch } from "react-redux";
import { useChatConfigurationEntity } from "../hooks/useChatConfigurationEntity.js";
import { isValidObject, isValidURL } from "../../commons/Utility.js";
import * as ChatConfigActions from "../actions/ChatConfigurationAction.js";
import * as voiceboxActions from "../../actions/VoiceboxAction";
import { usePrevious } from "../hooks/usePrevious.js";

import DisconnectTimerComponent from "./DisconnectTimerComponent.jsx";

const DisconnectTimerContainer = (props) => {
  const { user, staffList } = useSelector((state) => ({
    user: state.UserReducer.data,
    // staffList: state.StaffReducer.dashboardAgents["LS-" + props.projectKey],
  }));

  const [updatedMessage, setUpdatedMessage] = useState("Update Successful");
  const [isUpdated, setIsUpdated] = useState(false);
  const dispatch = useDispatch();
  let projectId = "LS-" + props.projectKey;
  const {
    chatConfiguration: chatConfig,
    isUpdating,
  } = useChatConfigurationEntity(projectId);
  let prevIsUpdating = usePrevious(isUpdating);

  let chatConfiguration = chatConfig;
  let autoDisconnectOverflowChatTimer = chatConfiguration
    ? chatConfiguration.autoDisconnectOverflowChatTimer
    : 0;

  useEffect(() => {
    if (prevIsUpdating && !isUpdating) {
      dispatch(
        voiceboxActions.showVoicebox({
          message: "Update Successful",
          dismissAfter: 2000,
        })
      );
    }
  });

  const updateDisconnectTimer = (newTime) => {
    let autoDisconnectOverflowChatTimer = Number(newTime);
    let chatConfiguration = Object.assign({}, chatConfig);

    if (
      autoDisconnectOverflowChatTimer !=
      chatConfiguration.autoDisconnectOverflowChatTimer
    ) {
      dispatch(voiceboxActions.showVoicebox({ message: "Updating" }));
      chatConfiguration.autoDisconnectOverflowChatTimer = autoDisconnectOverflowChatTimer;
      dispatch(
        ChatConfigActions.updateChatConfigRequest({
          key: chatConfiguration.key,
          autoDisconnectOverflowChatTimer:
            chatConfiguration.autoDisconnectOverflowChatTimer,
        })
      );
      setIsUpdated(true);
      setUpdatedMessage("Update Successful");
    }
  };

  const addDomainToList = (e) => {
    let inputURL = e.target.value.trim();
    let chatConfiguration = chatConfig;
    let currentDomains = getDomainWithProtocol(
      chatConfiguration.whiteListedDomain
    );
    try {
      inputURL = new URL(inputURL);
      if (isOwner() && inputURL.origin) {
        if (
          currentDomains.indexOf(inputURL.origin) !== -1 &&
          chatConfiguration.isWhiteListEnabled
        ) {
          setError(true);
          setErrorMessage("URL Already Whitelisted");
          return false;
        } else {
          if (error) {
            setError(false);
            setErrorMessage("");
          }
          dispatch(voiceboxActions.showVoicebox({ message: "Updating" }));
          currentDomains.push(inputURL.origin); //= [inputURL.host].concat(currentDomains);
          chatConfiguration.whiteListedDomain = currentDomains;
          if (
            !chatConfiguration.isWhiteListEnabled &&
            chatConfiguration.whiteListedDomain.length > 0
          )
            chatConfiguration.isWhiteListEnabled = true;
          dispatch(
            ChatConfigActions.updateChatConfigRequest(chatConfiguration)
          );
          e.target.value = "";
          setIsUpdated(true);
          setUpdatedMessage("Update Successful");
        }
      } else {
        dispatch(
          voiceboxActions.showVoicebox({
            message: "You should be an owner to change the settings",
            dismissAfter: 3000,
          })
        );
      }
    } catch (e) {
      setError(true);
      setErrorMessage("Invalid URL");
      return false;
    }
  };

  return (
    <DisconnectTimerComponent
      currentTime={autoDisconnectOverflowChatTimer}
      isUpdated={isUpdated}
      setUpdated={setIsUpdated}
      updateDisconnectTimer={updateDisconnectTimer}
    />
  );
};
export default DisconnectTimerContainer;
