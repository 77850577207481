import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import Tags from "../common/Tags.jsx";

class NewProjectPopup extends Component {
  render() {
    return (
      <div
        className={"popup-container" + (this.props.display ? " show" : " dn")}
      >
        <aside className={"popup popupNewPro"}>
          <h3>
            New Project
            <small>Enter the information below to create a new project</small>
          </h3>
          <code className="popupClose" onClick={this.props.onClose}>
            &times;
          </code>
          <div className="formField">
            <div className="inputTxt inlineBlock">
              <label>Project Name</label>
              <input
                type="text"
                aria-label="Project Name"
                ref="project_name"
                placeholder="Provide a Project Name"
                onKeyUp={this.props.onKeyUp}
              />
              <p
                className="error"
                style={{
                  color: "red",
                  position: "absolute",
                  display: this.props.error.projectName ? "block" : "none",
                }}
              >
                {this.props.error.projectName}
              </p>
            </div>
            <div
              className="inputTxt inlineBlock"
              style={{ marginRight: "0px" }}
            >
              <label>Project URL</label>
              <input
                type="text"
                aria-label="Project Url"
                ref="project_url"
                placeholder="Enter the Project URL"
                onKeyUp={this.props.onKeyUp}
              />
              <p
                className="error"
                style={{
                  color: "red",
                  position: "absolute",
                  display: this.props.error.projectUrl ? "block" : "none",
                }}
              >
                {this.props.error.projectUrl}
              </p>
            </div>
            <div className="inputTxt">
              <label>
                Add Members{" "}
                <small>
                  ( Input the email address and hit "Enter" for each new member)
                </small>
              </label>
              <input
                type="text"
                aria-label="Add Members"
                onKeyPress={this.props.onKeyPress}
                ref="staffEmailId"
                placeholder="Enter member's email address"
                style={{ width: "100%" }}
                onKeyUp={this.props.onKeyUp}
              />
              <p
                className="error"
                style={{
                  color: "red",
                  display: this.props.error.projectStaff ? "block" : "none",
                }}
              >
                {this.props.error.projectStaff}
              </p>
            </div>
            <div className="chipHolder">
              {this.props.staffMap
                ? Object.keys(this.props.staffMap).map((key) => {
                    let staff = this.props.staffMap[key];
                    return (
                      <span key={staff} id={staff}>
                        {staff}
                        <code onClick={this.props.removeStaff}>&times;</code>
                      </span>
                    );
                  })
                : []}
            </div>
          </div>
          <div className="popupBtnHolder">
            <button
              className="blueBtn"
              style={{ marginTop: "10px" }}
              onClick={this.props.onClick}
            >
              Create
            </button>
          </div>
        </aside>
        <div className={"transprentBg db"}></div>
      </div>
    );
  }
}
export default NewProjectPopup;
