import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.jsx";
import Listing from "./Listing.jsx";
import { getViewportHeight } from "../../commons/Utility.js";
import { useSelector } from "react-redux";

const Grid = (props) => {
  const {user} = useSelector((state) => ({
    user: state.UserReducer
  }));
  const [columnsWidth, setColumnsWidth] = useState(260);
  const [gridWrapperWidth, setGridWrapperWidth] = useState(1010);
  const [gridWrapperHeight, setGridWrapperHeight] = useState(50);
  const [gridListingHeight, setGridListingHeight] = useState(50);
  const [gridHeaderBottom , setGridHeaderBottom] = useState(140);
  const gridWrapper = useRef(null);
  const {
    headers = [],
    listing = [],
    headerInfo = [],
    onScroll,
    onSort,
    headerHeight,
    isEmptyGroup,
    placholderComponent
  } = props;
  useEffect(() => {
    setDimensions();
  });

  const setDimensions = () => {
    let vhHeight = getViewportHeight();
    let wrapperWidth =
      gridWrapper && gridWrapper.clientWidth
        ? gridWrapper.clientWidth
        : gridWrapperWidth;
    let columnsWidth = 260 + (headers.length - 1) * 150;
    setGridWrapperWidth(wrapperWidth);
    setColumnsWidth(columnsWidth);
    setGridWrapperHeight(vhHeight - headerHeight - (user.planType == "FREE" && !user.planAcknowledged ? 45 : 0));
    setGridListingHeight(vhHeight - headerHeight - (user.planType == "FREE" && !user.planAcknowledged ? 45 : 0) - 53);
  };

  useEffect(() => {
    const resizeListener = () => {
      setDimensions();
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const getListingComponent = () => {
    if(isEmptyGroup){
      return placholderComponent;
    } else {
      return <React.Fragment>
         <Header
      {...props}
      setGridHeaderBottom={setGridHeaderBottom}
      headers={headers}
      onSort={onSort}
      gridWrapperWidth={gridWrapperWidth}
      columnsWidth={columnsWidth}
    />
    <Listing
                {...props}
                listing={listing}
                headerInfo={headerInfo}
                onScroll={onScroll}
                gridWrapperWidth={gridWrapperWidth}
                columnsWidth={columnsWidth}
                gridListingHeight={gridListingHeight}
                headerHeight={headerHeight}
                gridHeaderBottom={gridHeaderBottom}
              />
      </React.Fragment>
    }
  }

  console.info("gridWrapperHeight:" + gridWrapperHeight);
  return (
    <section className="people-grid-wrp">
      <div style={{ height: `${gridWrapperHeight}px` }}>
       
        {getListingComponent()}
      </div>
    </section>
  );
};

export default Grid;
