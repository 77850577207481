import {
  IS_URL_LOADABLE_REQUEST,
  IS_URL_LOADABLE_RESPONSE,
  RESET_PREVIEW_REDUCER,
} from "../actions/PreviewActions";

const PreviewReducer = (
  state = { isFetching: false, isFetched: false, previewUrl: "" },
  action
) => {
  switch (action.type) {
    case IS_URL_LOADABLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        previewUrl: "",
      };
    case IS_URL_LOADABLE_RESPONSE: {
      return {
        ...state,
        isUrlLoadable: action.isUrlLoadable,
        previewUrl: action.previewUrl,
        isFetching: false,
        isFetched: true,
      };
    }
    case RESET_PREVIEW_REDUCER:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        previewUrl: "",
        isUrlLoadable: undefined,
      };
  }
  return state;
};

export default PreviewReducer;
