import React, { Component } from "react";
import { browserHistory } from "react-router";
import {
  CUSTOMER_NAME_SEARCH,
  COMPANY_NAME_SEARCH,
  ADVANCED_SEARCH,
  TAG_NAME_SEARCH,
} from "../../../commons/Constants.js";
import { ClockIcon, TagSearchIcon } from "../../../commons/Icons.js";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import { hideConversationFilter, updateSearchTextCursor } from "../../../actions/ConversationInfoAction.js";
import {
  getRecentSearchHistoryFromLocalStorage,
  getRecentSearchTextBySearchType,
} from "../../../commons/Utility.js";

class RecentSearchHistory extends Component {
  constructor() {
    super();
    this.checkForRecentHistory = this.checkForRecentHistory.bind(this);
    this.state = {
      searchTextFilter: "",
      nonEncryptedSearchTextFilter: "",
    };
  }

  redirectToConversationPage(
    searchType,
    advancedFilterSearch,
    projectId,
    name
  ) {
    let searchTextFromLocalStorage = getRecentSearchTextBySearchType(
      searchType,
      projectId
    );
    browserHistory.push({
      pathname: `/app/${projectId}/chat/search/D/${advancedFilterSearch}/N/All`,
      state: {
        searchTextFilter: searchTextFromLocalStorage,
        nonEncryptedSearchTextFilter: searchTextFromLocalStorage,
      },
    });
    Event(
      `LS-${projectId}`,
      "Search",
      `Suggested ${name}`,
      `Suggested ${name}`
    );
  }

  checkForRecentHistory(projectId) {
    let localStorageResponse = getRecentSearchHistoryFromLocalStorage();
    let searchHistoryResponse = [];
    if (localStorageResponse.CUSTOMER_NAME_SEARCH)
      searchHistoryResponse.push(
        <label
          key={CUSTOMER_NAME_SEARCH}
          onClick={() => {
            this.props.dashboardData.dispatch(hideConversationFilter());
            this.redirectToConversationPage(
              CUSTOMER_NAME_SEARCH,
              ADVANCED_SEARCH.CUSTOMER,
              projectId,
              "Customer Name"
            );
          }}
          onKeyDown={ () => {} }
        >
          <ClockIcon />
          <span>Customer name is </span>
          {localStorageResponse.CUSTOMER_NAME_SEARCH}
        </label>
      );
    if (localStorageResponse.COMPANY_NAME_SEARCH)
      searchHistoryResponse.push(
        <label
          key={COMPANY_NAME_SEARCH}
          onClick={() => {
            this.props.dashboardData.dispatch(hideConversationFilter());
            this.redirectToConversationPage(
              COMPANY_NAME_SEARCH,
              ADVANCED_SEARCH.COMPANY,
              projectId,
              "Company Name"
            );
          }}
          onKeyDown={ () => {} }
        >
          <ClockIcon />
          <span>Company name is </span>
          {localStorageResponse.COMPANY_NAME_SEARCH}
        </label>
      );
    if (localStorageResponse.TAG_NAME_SEARCH)
      searchHistoryResponse.push(
        <label
          key={TAG_NAME_SEARCH}
          onClick={() => {
            this.props.dashboardData.dispatch(hideConversationFilter());
            this.props.dashboardData.dispatch(updateSearchTextCursor(new Date().getTime()));
            this.redirectToConversationPage(
              TAG_NAME_SEARCH,
              ADVANCED_SEARCH.TAG,
              projectId,
              "Tag Name"
            );
          }}
          onKeyDown={ () => {} }
        >
          <TagSearchIcon />#{localStorageResponse.TAG_NAME_SEARCH}
        </label>
      );
    return searchHistoryResponse;
  }

  render() {
    let projectId = this.props.dashboardData.params.projectkey;
    let suggestedHistory = this.checkForRecentHistory(projectId);
    if (suggestedHistory.length > 0)
      return (
        <React.Fragment>
          <h5>Suggested</h5>
          <div>{suggestedHistory}</div>
        </React.Fragment>
      );
    else return "";
  }
}

export default RecentSearchHistory;
