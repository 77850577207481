import React from "react";
import { NewSearchIcon } from "../../../commons/Icons.js";

const TagSearchBar = (props) => {
  const { setSearchTerm,searchInputRef } = props;
  return (
    <div className="tag-search">
      <input
        onChange={(e) => setSearchTerm(e.target.value)}
        type="text"
        placeholder="Search tags"
        autoFocus={true}
        ref={searchInputRef}
      />
      <NewSearchIcon />
    </div>
  ) 
};

export default TagSearchBar;
