import { takeLatest, takeEvery } from "redux-saga/effects";
// import { CHAT_CONFIGURATION_REQUESTED, UPDATE_CHAT_CONFIG_REQUEST }  from '../actions/ChatConfigurationAction'
import {
  LOGIN_REQUESTED,
  REQUEST_SESSION_BY_CODE,
  LOGOUT_REQUESTED,
} from "../actions/LoginAction";
import {
  USER_FETCH_REQUESTED,
  USER_UPDATE_REQUESTED,
  RESET_PASSWORD_REQUESTED,
  SWITCH_USER,
  SWITCH_USER_TO_SPECIFIC_PAGE,
  SWITCH_TO_OWN_ACCOUNT,
  STAFF_UPDATE_REQUESTED,
  UPLOAD_USER_IMAGE_URL_REQUESTED,
  UPLOAD_USER_IMAGE_REQUESTED,
} from "../actions/UserAction";
import { STAFF_CONTACT_REQUESTED } from "../components/myteam/Action/StaffContactAction";
import { CONNECT_ENABLED_PROJECTS_REQUESTED, UPDATE_CONNECT_CONFIG_REQUESTED, USER_PROJECT_REQUESTED } from "../components/myteam/Action/UserProjectMappingAction";
// import { PROJECTLIST_REQUESTED, DELETEPROJECT_REQUESTED , CREATE_PROJECT_REQUEST, UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_LOGO_REQUEST}  from '../actions/ProjectAction'
import {
  SIGN_UP_REQUESTED,
  CREATE_LEAD_REQUESTED,
  SETUP_ACCOUNT_REQUEST,
} from "../actions/SignUpAction";
import {
  GET_INVITATION_REQUEST,
  ACCEPT_INVITATION_REQUEST,
  SEND_INVITATION_REQUEST,
  DECLINE_INVITATION_REQUEST,
  GET_SIGNUP_DETAILS_REQUEST,
} from "../actions/InvitationAction";
// import ChatPromptConfigActions from '../actions/ChatPromptConfigurationActions'
import {
  CONTACT_UPDATE_REQUESTED,
  GET_VISITOR_TIMEZONE,
} from "../actions/ContactInfoAction";
import {
  CONVERSATION_LIST_INFO_REQUESTED,
  VISITOR_CONVERSATION_LIST_INFO_REQUESTED,
  CONVERSATION_INFO_REQUESTED,
  CONVERSATION_UPDATE_REQUESTED,
  CREATE_NEW_CONVERSATION_REQUESTED,
  SEND_TRANSCRIPT_EMAIL_REQUESTED,
  TRACK_CHAT_STATUS,
  CONVERSATION_COUNT_REQUESTED,
} from "../actions/ConversationInfoAction";
// import * as ChatConfigurationSagas from './ChatConfigurationSaga'
import * as LoginSagas from "./LoginSaga";
import {
  fetchEmailOptOutPreferences,
  updateEmailOptOutPreferences,
  deleteEmailOptOutPreferences,
} from "./EmailOptOutSaga";
import * as UserSagas from "./UserSaga";
//import * as ProjectSagas from './ProjectSaga'
import * as StaffSaga from "./StaffSaga";
import * as RTMSaga from "./RTMSaga";
import {
  STAFF_LIST_REQUESTED,
  UPDATE_STAFF_ROLE_REQUESTED,
  DELETE_STAFF_REQUESTED,
  DELETE_ACCOUNT_STAFF_REQUESTED,
  UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED,
  STAFF_REQUESTED,
} from "../actions/StaffAction";
import {
  PROJECT_EVENT_REQUEST,
  UPDATE_PROJECT_EVENT_REQUEST,
  DELETE_PROJECT_EVENT_REQUEST,
} from "../actions/ProjectEventsAction";
import { EVENT_ACTIVITY_REQUEST } from "../actions/EventsActivityAction";

import {
  SUBSCRIBE,
  MESSAGE_REQUEST,
  SEND_EMAIL_REPLY,
  SEND_HTTP_MESSAGE,
  UPDATE_SUBSCRIPTION,
} from "../actions/MessageAction";
import {
  REQUEST_PEOPLE_LIST_BY_SCROLL,
  CREATE_PEOPLE_GROUP_REQUEST,
  GET_PEOPLE_GROUPS_REQUEST,
  UPDATE_PEOPLE_GROUP_REQUEST,
  UPDATE_GROUP_SETTINGS_REQUEST,
  UPDATE_FIELD_SETTINGS_REQUEST,
  GET_PEOPLE_COUNT_REQUEST,
  GET_PEOPLE_GROUP_COUNT_REQUEST,
  EXPORT_CONTACT_REQUEST,
  SEND_BROADCAST_MESSAGE_REQUEST,
  SEND_BROADCAST_EMAIL_REQUEST,
  IMPORT_CONTACT_REQUEST,
  FETCH_PRESENCE_BY_USERIDS,
  FETCH_AND_UDPATE_USER_PRESENCE,
  SEND_TEST_EMAIL_REQUEST,
  DELETE_PEOPLE_GROUP_REQUEST,
  UPDATE_IP_ACTIVITY,
  GET_IP_STATUS_REQUEST, 
  GET_MATCHING_SEGMENTS_REQUEST,
  BROADCAST_EVENT_STATS_REQUEST
} from "../actions/PeopleAction";

import { GET_URL_PREVIEW_INFO_REQUEST } from "../actions/UrlPreviewAction";
import * as SignUpSagas from "./SignUpSaga";
import * as InvitationSagas from "./InvitationSaga";
// import {fetchChatPromptConfiguration ,  updateChatPromptConfiguration , deleteChatPromptConfiguration , createChatPromptConfiguration} from './ChatPromptConfigurationSaga'
import { getUrlPreviewInfo } from "./UrlPreviewSaga";
import * as ConversationInfoSagas from "./ConversationInfoSagas";
import * as MessageSagas from "./MessageSagas";
import * as PeopleInfoSaga from "./PeopleInfoSaga";
import {
  NOTIFICATION_SETTINGS_REQUESTED,
  NOTIFICATION_UPDATE_REQUESTED,
} from "../actions/NotificationSettingsAction";
import * as NotificationSettingsSaga from "./NotificationSettingsSaga";
import * as CustomFieldsSaga from "./CustomFieldsSagas";
import * as ProjectEventSaga from "./ProjectEventSaga";
import * as EventsActivitySaga from "./EventsActivitySaga";
import {
  CUSTOM_FIELDS_REQUESTED,
  UPDATE_CUSTOM_FIELD_REQUESTED,
  DELETE_CUSTOM_FIELD_REQUESTED,
} from "../actions/CustomFieldsAction";
import {
  UPDATE_PEOPLE_QUERY,
  DELETE_PEOPLE_SCROLL_ID,
} from "../actions/PeopleESQueryAction";

import { EVENT_REQUESTED } from "../actions/EventConfigurationAction";
import * as EventConfigurationSaga from "./EventConfigurationSaga";

import { DELETE_USER_REQUEST } from "../actions/DeleteUserAction";
import * as DeleteUserSaga from "./DeleteUserSaga";

import {
  UPLOAD_ATTACHMENT_REQUESTED,
  UPLOAD_FILE_URL_REQUESTED,
  UPLOAD_WIDGET_ICON_URL_REQUESTED,
  UPLOAD_WIDGET_ICON_REQUESTED,
} from "../actions/AttachmentAction";
import * as AttachmentSaga from "./AttachmentSaga";

import {
  TAG_LIST_REQUESTED,
  ADD_TAGS_REQUESTED,
  REMOVE_TAG_REQUESTED,
  REMOVE_TAG_FROM_CONVERSATION_REQUESTED,
  EDIT_TAG_REQUESTED,
  DELETE_TAG_REQUESTED,
  ADD_TAGS_FOR_FILTER_REQUESTED,
  ADD_TAGS_TO_CONVERSATION_REQUESTED,
  REMOVE_TAG_FROM_MESSAGE_REQUESTED
} from "../actions/TagActions";
import * as TagSagas from "./TagSagas";

import {
  FETCH_BROADCAST_MESSAGE_STATS_REQUEST,
  UPDATE_CAMPAIGN_STATUS_REQUEST,
  SEND_TEST_BROADCAST_EMAILS_REQUEST, SAVE_CAMPAIGN, DELETE_CAMPAIGN
} from "../actions/BroadcastMessageStatsAction";
import * as BroadcastMessageStatsSaga from "./BroadcastMessagesSaga";

import { FETCH_BROADCAST_EMAIL_STATS_REQUEST } from "../actions/BroadcastEmailStatsAction";
import * as BroadcastEmailStatsSaga from "./BroadcastEmailsSaga";

import { VIDEO_LINK_REQUESTED } from "../actions/VideoChatAction";
import * as VideoChatSaga from "./VideoChatSaga";

import {
  TOKENLIST_REQUESTED,
  CREATE_TOKEN_REQUEST,
  UPDATE_TOKEN_REQUEST,
  DELETE_TOKEN_REQUESTED,
} from "../actions/AuthTokenAction";
import * as AuthTokenSaga from "./AuthTokenSaga";

import { HANDLE_ERROR, MAIL_ERROR } from "../actions/GlobalErrorAction";
import * as GlobalErrorHandler from "./GlobalErrorHandler";
import { UPDATE_BROADCAST_PEOPLE_QUERY } from "../actions/BroadcastESQueryActions";
import {
  EMAIL_OPT_OUT_PREFERENCES_REQUEST,
  EMAIL_OPT_OUT_UPDATE_REQUEST,
  EMAIL_OPT_OUT_DELETE_REQUEST,
} from "../actions/EmailOptOutAction";

import {
  GET_STAFF_BY_PROJECT,
  GET_PROJECT_LIST_BY_EMAIL,
} from "../actions/AdminAction";
import {
  INITIALIZE_RESET_REQUEST,
  RESET_PASSWORD_REQUEST,
} from "../actions/ResetPasswordAction";

import * as adminSaga from "../sagas/AdminSaga";

import {
  SETUP_REQUEST,
  MAIL_CHAT_SCRIPT_REQUEST,
} from "../actions/SetupAction";
import * as setupSaga from "../sagas/SetupSaga";
import * as resetPasswordSagas from "../sagas/ResetPasswordSaga";
import * as previewSagas from "../sagas/PreviewSaga.js";
import { IS_URL_LOADABLE_REQUEST } from "../actions/PreviewActions.js";
import { FETCH_VISITOR_QUEUE } from "../actions/VisitorQueueActions.js";
import { fetchVisitorQueueRequest } from "../sagas/VisitorQueueSaga";

import { ACCEPT_CHAT } from "../actions/ChatAnsweringAction";
import { QUEUE_CONVERSATION_REQUEST } from "../actions/ConversationQueueAction";
import fetchQueueConversationList from "./ConversationQueueSaga";

export function* watchGlobleHandler() {
  yield takeLatest(HANDLE_ERROR, GlobalErrorHandler.globleHandler);
}

export function* watchGlobleExceptionMail() {
  yield takeLatest(MAIL_ERROR, GlobalErrorHandler.sendMail);
}

// export function* watchChatConfigurationRequest()
// {
// 	yield takeLatest( CHAT_CONFIGURATION_REQUESTED , ChatConfigurationSagas.getChatConfiguration);
// }

export function* watchUserLogin() {
  yield takeLatest(LOGIN_REQUESTED, LoginSagas.doUserLogin);
}

export function* watchUserLogout() {
  yield takeLatest(LOGOUT_REQUESTED, LoginSagas.doUserLogout);
}

export function* watchFetchUser() {
  yield takeLatest(USER_FETCH_REQUESTED, UserSagas.fetchUser);
}

export function* watchUpdateUser() {
  yield takeLatest(USER_UPDATE_REQUESTED, UserSagas.updateUser);
}

export function* watchGetUserImageUrl() {
  yield takeLatest(UPLOAD_USER_IMAGE_URL_REQUESTED, UserSagas.getUploadURL);
}

export function* watchUpdateUserImage() {
  yield takeLatest(UPLOAD_USER_IMAGE_REQUESTED, UserSagas.updateImageUrl);
}

export function* watchUpdateAccountStaff() {
  yield takeLatest(STAFF_UPDATE_REQUESTED, UserSagas.updateAccountStaff);
}

export function* watchResetPasseword() {
  yield takeLatest(RESET_PASSWORD_REQUESTED, UserSagas.resetPassword);
}

export function* watchFetchStaffContact() {
  yield takeLatest(STAFF_CONTACT_REQUESTED, UserSagas.fetchStaffContactById);
}

export function* watchFetchUserProjectMapping() {
  yield takeLatest(USER_PROJECT_REQUESTED, UserSagas.fetchUserProjectMapping);
}

export function* watchSwitchCustomerAccount() {
  yield takeLatest(SWITCH_USER, UserSagas.switchCustomerAccount);
}

export function* watchSwitchCustomerAccountByTask() {
  yield takeLatest(
    SWITCH_USER_TO_SPECIFIC_PAGE,
    UserSagas.switchCustomerAccount
  );
}

export function* watchSwitchToOwnAccount() {
  yield takeLatest(SWITCH_TO_OWN_ACCOUNT, UserSagas.switchToOwnAccount);
}

// export function* watchProjectList()
// {
// 	yield takeLatest( PROJECTLIST_REQUESTED , ProjectSagas.getProjectList );
// }

// export function* watchDeleteProject()
// {
// 	yield takeLatest( DELETEPROJECT_REQUESTED , ProjectSagas.deleteProject );
// }

// export function* watchCreateProject()
// {
// 	yield takeLatest( CREATE_PROJECT_REQUEST , ProjectSagas.createProject );
// }

export function* watchAccountSetup() {
  yield takeLatest(SETUP_ACCOUNT_REQUEST, SignUpSagas.setupAccount);
}

// export function* watchUpdateProject()
// {
// 	yield takeLatest( UPDATE_PROJECT_REQUEST , ProjectSagas.updateProject );
// }
// export function* watchProjectLogoUpdate()
// {
// 	yield takeLatest( UPDATE_PROJECT_LOGO_REQUEST, ProjectSagas.updateLogo );
// }
export function* watchStaffListRequested() {
  yield takeLatest(STAFF_LIST_REQUESTED, StaffSaga.doFetchStaffList);
}
export function* watchStaffRequested() {
  yield takeLatest(STAFF_REQUESTED, StaffSaga.doFetchStaff);
}
export function* watchUpdateStaffRoleRequested() {
  yield takeLatest(UPDATE_STAFF_ROLE_REQUESTED, StaffSaga.doChangeStaffRole);
}
export function* watchDeleteStaffRequested() {
  yield takeLatest(DELETE_STAFF_REQUESTED, StaffSaga.doDeleteStaff);
}

export function* watchDeleteAccountStaffRequested() {
  yield takeLatest(
    DELETE_ACCOUNT_STAFF_REQUESTED,
    StaffSaga.deleteAccountStaff
  );
}

export function* watchSignUp() {
  yield takeLatest(SIGN_UP_REQUESTED, SignUpSagas.doSignUp);
}

export function* watchCreateLead() {
  yield takeLatest(CREATE_LEAD_REQUESTED, SignUpSagas.createLead);
}

export function* watchGetInvitation() {
  yield takeLatest(GET_INVITATION_REQUEST, InvitationSagas.getInvitation);
}

export function* watchAcceptInvitation() {
  yield takeLatest(ACCEPT_INVITATION_REQUEST, InvitationSagas.acceptInvitation);
}

export function* watchDeclineInvitation() {
  yield takeLatest(
    DECLINE_INVITATION_REQUEST,
    InvitationSagas.declineInvitation
  );
}

export function* watchSendInvitation() {
  yield takeLatest(SEND_INVITATION_REQUEST, InvitationSagas.sendInvitation);
}

export function* watchGetSignupDetails() {
  yield takeLatest(
    GET_SIGNUP_DETAILS_REQUEST,
    InvitationSagas.getSignupDetails
  );
}

export function* watchChatStatusTracker() {
  yield takeLatest(TRACK_CHAT_STATUS, ConversationInfoSagas.updateChatStatus);
}
// export function* watchUpdateChatConfiguration()
// {
// 	yield takeLatest( UPDATE_CHAT_CONFIG_REQUEST , ChatConfigurationSagas.updateChatConfiguration);
// }

// export function* watchFetchChatPromptConfig()
// {
// 	yield takeLatest( ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_REQUEST , fetchChatPromptConfiguration);
// }

// export function* watchCreateChatPromptConfi()
// {
// 	yield takeLatest(ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_REQUEST , createChatPromptConfiguration);
// }

// export function* watchUpdateChatPromptConfig()
// {
// 	yield takeLatest( ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_REQUEST , updateChatPromptConfiguration);
// }

// export function* watchDeleteChatPromptConfi()
// {
// 	yield takeLatest( ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_REQUEST , deleteChatPromptConfiguration);
// }

export function* watchConversationListRequested() {
  yield takeLatest(
    CONVERSATION_LIST_INFO_REQUESTED,
    ConversationInfoSagas.getConversationList
  );
}

export function* watchVisitorConversationListRequested() {
  yield takeLatest(
    VISITOR_CONVERSATION_LIST_INFO_REQUESTED,
    ConversationInfoSagas.getConversationList
  );
}
export function* watchConversationRequested() {
  yield takeLatest(
    CONVERSATION_INFO_REQUESTED,
    ConversationInfoSagas.getConversation
  );
}

export function* watchAcceptChat() {
  yield takeLatest(ACCEPT_CHAT, ConversationInfoSagas.acceptChat);
}

export function* watchUpdateConversationRequested() {
  yield takeLatest(
    CONVERSATION_UPDATE_REQUESTED,
    ConversationInfoSagas.updateConversationDetails
  );
}

export function* watchContactUpdateRequested() {
  yield takeLatest(
    CONTACT_UPDATE_REQUESTED,
    ConversationInfoSagas.updateContactDetails
  );
}
export function* watchdoSubscription() {
  yield takeLatest(SUBSCRIBE, RTMSaga.doSubscription);
}
export function* watchdoUpdateSubscription() {
  yield takeLatest(UPDATE_SUBSCRIPTION, RTMSaga.doUpdateSubscription);
}
export function* watchGetMoreMessages() {
  yield takeEvery(MESSAGE_REQUEST, MessageSagas.getMoreMessages);
}

export function* watchsendHttpMessage() {
  yield takeEvery(SEND_HTTP_MESSAGE, MessageSagas.sendHttpMessage);
}

export function* watchFetchPeopleRequested() {
  yield takeLatest(UPDATE_PEOPLE_QUERY, PeopleInfoSaga.getPeoplesList);
}
export function* watchFetchPeopleRequestedForBroadcast() {
  yield takeLatest(
    UPDATE_BROADCAST_PEOPLE_QUERY,
    PeopleInfoSaga.getPeoplesList
  );
}

export function* watchGetDetailedStatsPeople() {
  yield takeLatest(BROADCAST_EVENT_STATS_REQUEST,
    PeopleInfoSaga.getDetailedStatsPeople
  );
}
export function* watchFetchNotificationSettings() {
  yield takeEvery(
    NOTIFICATION_SETTINGS_REQUESTED,
    NotificationSettingsSaga.fetchNotificationSettings
  );
}

export function* watchUpdateNotificationSettings() {
  yield takeLatest(
    NOTIFICATION_UPDATE_REQUESTED,
    NotificationSettingsSaga.updateNotificationSettings
  );
}
export function* watchCustomFieldsRequested() {
  yield takeLatest(
    CUSTOM_FIELDS_REQUESTED,
    CustomFieldsSaga.doFetchCustomFields
  );
}

export function* watchCustomFieldsUpdateRequested() {
  yield takeLatest(
    UPDATE_CUSTOM_FIELD_REQUESTED,
    CustomFieldsSaga.doUpdateCustomField
  );
}
export function* watchCustomFieldsDeleteRequested() {
  yield takeLatest(
    DELETE_CUSTOM_FIELD_REQUESTED,
    CustomFieldsSaga.doDeleteCustomField
  );
}

export function* watchgetPeoplesListByScroll() {
  yield takeLatest(
    REQUEST_PEOPLE_LIST_BY_SCROLL,
    PeopleInfoSaga.getPeoplesListByScroll
  );
}

export function* watchdeleteScrollId() {
  yield takeLatest(
    DELETE_PEOPLE_SCROLL_ID,
    PeopleInfoSaga.requestdeleteScrollId
  );
}

export function* watchProjectEventRequest() {
  yield takeLatest(
    PROJECT_EVENT_REQUEST,
    ProjectEventSaga.doFetchProjectEvents
  );
}

export function* watchProjectEventUpdate() {
  yield takeLatest(
    UPDATE_PROJECT_EVENT_REQUEST,
    ProjectEventSaga.updateProjectEvent
  );
}

export function* watchProjectEventDelete() {
  yield takeLatest(
    DELETE_PROJECT_EVENT_REQUEST,
    ProjectEventSaga.deleteProjectEvent
  );
}

export function* watchCreatePeopleGroup() {
  yield takeLatest(
    CREATE_PEOPLE_GROUP_REQUEST,
    PeopleInfoSaga.createPeopleGroup
  );
}

export function* watchUpdateGroupSettings() {
  yield takeLatest(
    UPDATE_GROUP_SETTINGS_REQUEST,
    PeopleInfoSaga.updateGroupSettings
  );
}

export function* watchUpdateFieldSettings() {
  yield takeLatest(
    UPDATE_FIELD_SETTINGS_REQUEST,
    PeopleInfoSaga.updateFieldSettings
  );
}

export function* watchGetPeopleGroups() {
  yield takeLatest(GET_PEOPLE_GROUPS_REQUEST, PeopleInfoSaga.getPeopleGroups);
}

export function* watchGetMatchingGroupsRequest() {
  yield takeLatest(GET_MATCHING_SEGMENTS_REQUEST, PeopleInfoSaga.getMatchingSegments);
}

export function* watchGetPeopleCount() {
  yield takeLatest(GET_PEOPLE_COUNT_REQUEST, PeopleInfoSaga.getPeopleCounts);
}

export function* watchGetPeopleGroupCount() {
  yield takeEvery(
    GET_PEOPLE_GROUP_COUNT_REQUEST,
    PeopleInfoSaga.getPeopleCountByGroup
  );
}

export function* watchEventRequest() {
  yield takeLatest(EVENT_REQUESTED, EventConfigurationSaga.fetchEvents);
}

export function* watchUpdatePeopleGroup() {
  yield takeLatest(
    UPDATE_PEOPLE_GROUP_REQUEST,
    PeopleInfoSaga.updatePeopleGroup
  );
}

export function* watchDeleteUserRequest() {
  yield takeLatest(DELETE_USER_REQUEST, DeleteUserSaga.deleteUsers);
}

export function* watchCreateNewConversationRequest() {
  yield takeLatest(
    CREATE_NEW_CONVERSATION_REQUESTED,
    ConversationInfoSagas.createNewConversation
  );
}

export function* watchEventActivityRequest() {
  yield takeLatest(
    EVENT_ACTIVITY_REQUEST,
    EventsActivitySaga.doFetchEventsActivity
  );
}

export function* watchTagListRequest() {
  yield takeLatest(TAG_LIST_REQUESTED, TagSagas.doFetchTagList);
}
export function* watchAddTagsRequested() {
  yield takeLatest(ADD_TAGS_REQUESTED, TagSagas.doAddTags);
}

export function* watchAddTagsForConversationRequested() {
  yield takeLatest(
    ADD_TAGS_TO_CONVERSATION_REQUESTED,
    TagSagas.doAddTagsToConversation
  );
}

export function* watchRemoveTagRequested() {
  yield takeLatest(REMOVE_TAG_REQUESTED, TagSagas.doRemoveTag);
}

export function* watchRemoveTagFromConversationRequested() {
  yield takeLatest(
    REMOVE_TAG_FROM_CONVERSATION_REQUESTED,
    TagSagas.doRemoveTagFromConversation
  );
}

export function* watchEditTagRequested() {
  yield takeLatest(EDIT_TAG_REQUESTED, TagSagas.doUpdateTag);
}
export function* watchDeleteTagRequested() {
  yield takeLatest(DELETE_TAG_REQUESTED, TagSagas.doDeleteTag);
}

export function* watchUploadAttachment() {
  yield takeEvery(UPLOAD_ATTACHMENT_REQUESTED, AttachmentSaga.uploadAttachment);
}

export function* watchWidgetIconUpload() {
  yield takeEvery(
    UPLOAD_WIDGET_ICON_REQUESTED,
    AttachmentSaga.uploadWidgetIcon
  );
}

export function* watchRequestFileUploadUrl() {
  yield takeEvery(
    UPLOAD_FILE_URL_REQUESTED,
    AttachmentSaga.getUploadAttachmentUrl
  );
}

export function* watchRequestWidgetIconUploadUrl() {
  yield takeEvery(
    UPLOAD_WIDGET_ICON_URL_REQUESTED,
    AttachmentSaga.getUploadWidgetIconUrl
  );
}

export function* watchExportContact() {
  yield takeLatest(EXPORT_CONTACT_REQUEST, PeopleInfoSaga.exportContacts);
}

export function* watchSendBroadcastMessage() {
  yield takeLatest(
    SEND_BROADCAST_MESSAGE_REQUEST,
    PeopleInfoSaga.sendBroadcastMessage
  );
}

export function* watchTestBroadcastEmailRequest() {
  yield takeLatest(
    SEND_TEST_BROADCAST_EMAILS_REQUEST,
    PeopleInfoSaga.sendTestBroadcastEmail
  );
}
export function* watchSendBroadcastEmail() {
  yield takeLatest(
    SEND_BROADCAST_EMAIL_REQUEST,
    PeopleInfoSaga.sendBroadcastEmail
  );
}

export function* watchSendTestEmail() {
  yield takeLatest(SEND_TEST_EMAIL_REQUEST, PeopleInfoSaga.sendTestEmail);
}

export function* watchImportContact() {
  yield takeLatest(IMPORT_CONTACT_REQUEST, PeopleInfoSaga.importContacts);
}

export function* watchAddTagsForFilter() {
  yield takeLatest(ADD_TAGS_FOR_FILTER_REQUESTED, TagSagas.doAddTagsForFilter);
}

export function* watchupdateConversationCount() {
  yield takeLatest(
    UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED,
    StaffSaga.doFetchStaffConversationCount
  );
}

export function* watchFetchBroadcastMessageStats() {
  yield takeLatest(
    FETCH_BROADCAST_MESSAGE_STATS_REQUEST,
    BroadcastMessageStatsSaga.fetchBroadcastMessagesEntities
  );
}

export function* watchFetchBroadcastEmailStats() {
  yield takeLatest(
    FETCH_BROADCAST_EMAIL_STATS_REQUEST,
    BroadcastEmailStatsSaga.fetchBroadcastEmailEntities
  );
}

export function* watchGetVideoLink() {
  yield takeLatest(VIDEO_LINK_REQUESTED, VideoChatSaga.getVideoLink);
}

export function* watchGetUrlPreviewInfo() {
  yield takeLatest(GET_URL_PREVIEW_INFO_REQUEST, getUrlPreviewInfo);
}

export function* watchPresenceByuserIds() {
  yield takeEvery(FETCH_PRESENCE_BY_USERIDS, RTMSaga.fetchPresenceByuserIds);
}

export function* watchFetchAndUpdatePresence() {
  yield takeEvery(
    FETCH_AND_UDPATE_USER_PRESENCE,
    RTMSaga.fetchAndUpdateUserPresence
  );
}

export function* watchSendEmailMessage() {
  yield takeEvery(SEND_EMAIL_REPLY, MessageSagas.sendEmailMessages);
}

export function* watchRequestSessionByCode() {
  yield takeLatest(REQUEST_SESSION_BY_CODE, LoginSagas.doRequestSession);
}

export function* watchGetTokenList() {
  yield takeLatest(TOKENLIST_REQUESTED, AuthTokenSaga.getTokenList);
}

export function* watchCreateToken() {
  yield takeLatest(CREATE_TOKEN_REQUEST, AuthTokenSaga.createToken);
}
export function* watchUpdateToken() {
  yield takeLatest(UPDATE_TOKEN_REQUEST, AuthTokenSaga.updateToken);
}
export function* watchDeleteToken() {
  yield takeLatest(DELETE_TOKEN_REQUESTED, AuthTokenSaga.deleteToken);
}

export function* watchSendTranscriptRequest() {
  yield takeLatest(
    SEND_TRANSCRIPT_EMAIL_REQUESTED,
    ConversationInfoSagas.sendTranscriptEmail
  );
}

export function* watchFetchOptOutPreferences() {
  yield takeLatest(
    EMAIL_OPT_OUT_PREFERENCES_REQUEST,
    fetchEmailOptOutPreferences
  );
}

export function* watchUpdateOptOutPreferences() {
  yield takeLatest(EMAIL_OPT_OUT_UPDATE_REQUEST, updateEmailOptOutPreferences);
}

export function* watchDeleteOptOutPreferences() {
  yield takeLatest(EMAIL_OPT_OUT_DELETE_REQUEST, deleteEmailOptOutPreferences);
}

export function* watchDeletePeopleGroup() {
  yield takeLatest(
    DELETE_PEOPLE_GROUP_REQUEST,
    PeopleInfoSaga.deletePeopleGroup
  );
}

export function* watchUpdateIPStatus() {
  yield takeLatest(UPDATE_IP_ACTIVITY, PeopleInfoSaga.updateIPActivityStatus)
}

export function* watchGetIPStatus() {
  yield takeLatest(GET_IP_STATUS_REQUEST, PeopleInfoSaga.getIPActivityStatus)
}

export function* watchGetStaffForProject() {
  yield takeLatest(GET_STAFF_BY_PROJECT, adminSaga.getStaffForProject);
}

export function* watchGetProjectForStaff() {
  yield takeLatest(GET_PROJECT_LIST_BY_EMAIL, adminSaga.getProjectForStaff);
}

export function* watchIsEmbedded() {
  yield takeLatest(SETUP_REQUEST, setupSaga.projectSetup);
}

export function* watchInitializeResetPassword() {
  yield takeLatest(
    INITIALIZE_RESET_REQUEST,
    resetPasswordSagas.initializeResetRequest
  );
}

export function* watchResetPasswordRequest() {
  yield takeLatest(
    RESET_PASSWORD_REQUEST,
    resetPasswordSagas.resetPasswordRequest
  );
}

export function* watchEmailChatScript() {
  yield takeLatest(MAIL_CHAT_SCRIPT_REQUEST, setupSaga.mailChatScript);
}

export function* watchVisitorTimezoneRequest() {
  yield takeLatest(
    GET_VISITOR_TIMEZONE,
    ConversationInfoSagas.getVisitorTimeZone
  );
}
export function* watchIsUrlLoadableRequest() {
  yield takeLatest(IS_URL_LOADABLE_REQUEST, previewSagas.isUrlLoadable);
}

export function* watchFetchVisitorQueueRequest() {
  yield takeEvery(FETCH_VISITOR_QUEUE, fetchVisitorQueueRequest);
}

export function* watchUpdateBroadcastStatus() {
  yield takeLatest(
    UPDATE_CAMPAIGN_STATUS_REQUEST,
    BroadcastMessageStatsSaga.updateBroadcastStatus
  );
}
export function* watchSaveCampaign() {
  yield takeLatest(
    SAVE_CAMPAIGN,
    BroadcastMessageStatsSaga.saveCampaign
  );
}

export function* watchDeleteCampaign() {
  yield takeLatest(
    DELETE_CAMPAIGN,
    BroadcastMessageStatsSaga.deleteCampaign
  );
}

export function* watchConversationCount() {
  yield takeLatest(
    CONVERSATION_COUNT_REQUESTED,
    ConversationInfoSagas.fetchConversationCount
  );
}

export function* watchRemoveTagFromMessageRequested() {
  yield takeLatest(
    REMOVE_TAG_FROM_MESSAGE_REQUESTED,
    TagSagas.doRemoveTagFromMessage
  );
}

export function* watchUpdateConnectConfigRequested() {
  yield takeLatest(
    UPDATE_CONNECT_CONFIG_REQUESTED,
    UserSagas.updateConnectConfiguration
  );
}

export function* watchGetConnectEnabledProjects() {
  yield takeLatest(
    CONNECT_ENABLED_PROJECTS_REQUESTED,
    UserSagas.getConnectEnabledProjects
  );
}
export function* watchQueueConversationFetch() {
  yield takeLatest(QUEUE_CONVERSATION_REQUEST, fetchQueueConversationList);
}
