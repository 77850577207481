import React, { useState , useRef , useEffect} from "react";
import { EditPopupIcon , EditIcon, DeleteIcon, MoreOptionsIcon , AddFieldIcon, ErrorIcon} from "../../commons/Icons.js";
import AddNewUrl from './addNewUrl.jsx'

const UrlListComponent = (props) => {

  const {url , onEdit , removeDomainFromList , getDomainsByType , getPreviousUrl} = props;

  let {handleEnter , popupType , handleKeyPress , hideURLConfigPopup , handleAddNewOnEnter} = props;

  const [isEdit , setIsEdit] = useState(false);
  const moreRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  });

  const handleMouseDown = (e) => {
      if(moreRef.current && !moreRef.current.contains(e.target)){
       setShowMoreOptions(false);
    }
  };


  const [showMoreOptions , setShowMoreOptions] = useState(false);

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  }

  const handleOutSideClick = () => {
    setShowMoreOptions(false);
  }

  const toggleEdit = (value) => {
      setIsEdit(!isEdit);
  }

  const getMoreOptions = (url) => {
    if(showMoreOptions) {
        return <ul ref={moreRef} class="edit-delete-container">
    <li onClick={toggleEdit} data-url={url}><EditIcon />Edit</li>
    <li data-url={url} onClick={removeDomainFromList} class="delete "><DeleteIcon />Delete</li>
  </ul>
    } else {
      console.log("The value here in else with empty value :" )
      return "";
    }
  }
  const getClassForMoreOptions = () => {
    if(showMoreOptions){
      return "my-team-more-btn whiteblackList-more-btn open"
    } else {
      return "my-team-more-btn whiteblackList-more-btn"
    }
  }

  const getDisplayStyle = () => {
    if(showMoreOptions){
      return {display : "block"}
    } else {
      return {}
    }
  }
  const getInputBoxComponent = () => {
    if( isEdit ){
      return <AddNewUrl getPreviousUrl={getPreviousUrl} getDomainsByType={getDomainsByType} toggleEdit={toggleEdit} url={url} isEdit={isEdit} hideURLConfigPopup={hideURLConfigPopup} handleKeyPress={handleKeyPress} addUrlsToMap={handleAddNewOnEnter}  popupType={popupType} handleEnter={handleEnter}/>
    } else {
        return ( <li>
          <span>{url}</span>
          <div style={getDisplayStyle()} onClick={toggleMoreOptions} class={getClassForMoreOptions()}>
            <a  href="javascript:void(0)" onClick={toggleMoreOptions}><MoreOptionsIcon onClick={toggleMoreOptions}/></a>
            {getMoreOptions(url)}
          </div>
        </li>)
    }

  }
 

  return getInputBoxComponent();
};

export default UrlListComponent;
