import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProactiveDetailsCardComponent from "./ProactiveDetailsCardComponent.jsx";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import {
  createChatPromptConfiguration,
  updateChatPromptConfigurationRequest,
  deleteChatPromptConfigurationRequest,
} from "../actions/PromptActions";
import ProactivePopUpComponent from "./ProactivePopupComponent.jsx";
import {
  getProjectId,
  isValidObject,
  isValidChatPromptURL,
  generateRandomPromptId,
  sortListByProperty,
} from "../../commons/Utility";
import * as EventsTracking from "../../commons/EventsTracking";
import { PopupCloseIcon, DeletePopupIcon } from "../../commons/Icons";

const ProactiveChatContainer = (props) => {
  const {
    visitorQueueState,
    userDetails,
    chatPromptConfigurationReducer,
    staffDetails,
    projectReducer,
  } = useSelector((state) => ({
    userDetails: state.UserReducer,
    chatPromptConfigurationReducer: state.ChatPromptConfigurationReducer,
    staffDetails: state.StaffReducer,
    projectReducer: state.ProjectReducer,
  }));

  const dispatch = useDispatch();
  const currentProjectId = getProjectId(props.projectKey);

  let promptList =
    (chatPromptConfigurationReducer &&
      chatPromptConfigurationReducer.data[currentProjectId] &&
      sortListByProperty(
        chatPromptConfigurationReducer.data[currentProjectId],
        "createdDate"
      )) ||
    [];
  let promptNo = 0;
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [isURLValid, setIsURLValid] = useState(true);
  const [isDuplicateURL, setIsDuplicateURL] = useState(false);
  const [deletePromptId, setDeletePromptId] = useState(undefined);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showProactivePopup, setShowProactivePopup] = useState(false);
  const [onEditMode, setOnEditMode] = useState(false);
  const [proactiveConfigObject, setProactiveConfigObject] = useState({
    promptName: "",
    enabledUrl: "",
    isEnabled: true,
    key: generateRandomPromptId(),
    message: "",
    projectKey: currentProjectId,
    createdBy: userDetails && userDetails.data ? userDetails.data.id : "",
    promptFrom: userDetails && userDetails.data ? userDetails.data.id : "",
    promptWaitingSeconds: 0,
    shouldConnectAgentOnTrigger: true,
    status: "ACTIVE",
  });

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showDeletePopup]);

  const handleEscapeKey = (e) => {
    if ((e.which === 27 || e.keyCode === 27) && showDeletePopup)
      setShowDeletePopup(false);
  };

  const isValidUser = () => {
    let staffMap = staffDetails.dashboardAgents
      ? staffDetails.dashboardAgents[getProjectId(props.projectKey)]
      : {};
    if (staffMap && userDetails.data && userDetails.data.id) {
      let currentStaff = staffMap[userDetails.data.id];
      let currentStaffPermission = currentStaff.userPermission
        ? currentStaff.userPermission
        : "RESTRICTED";

      return currentStaffPermission === "FULL_ACCESS";
    }
    return false;
  };

  const getNewProactiveConfigObject = () => {
    return {
      promptName: "",
      enabledUrl: "",
      isEnabled: true,
      key: generateRandomPromptId(),
      message: "",
      projectKey: currentProjectId,
      createdBy: userDetails && userDetails.data ? userDetails.data.id : "",
      promptFrom: userDetails && userDetails.data ? userDetails.data.id : "",
      promptWaitingSeconds: 0,
      shouldConnectAgentOnTrigger: true,
      status: "ACTIVE",
    };
  };

  const validatePrompt = (promptData) => {
    let { key: configId, enabledUrl, message } = promptData;
    let isMessageValid = message.length ? true : false;
    let isURLValid =
      enabledUrl.length > 0 ? isValidChatPromptURL(enabledUrl) : false;
    let isDuplicateURL = isDuplicatePromptURL(enabledUrl, configId);

    setIsMessageValid(isMessageValid);
    setIsURLValid(isURLValid);
    setIsDuplicateURL(isDuplicateURL);

    return isMessageValid && isURLValid && !isDuplicateURL;
  };

  const isDuplicatePromptURL = (url, configId) => {
    let hasDuplicateURLs = false;

    try {
      if (!promptList) return false;

      let matchedURLs = promptList.filter((chatPromptConfig) => {
        let currentUrl = url.replace(/(^\w+:|^)\/\//, "");
        let existingUrl = chatPromptConfig.enabledUrl.replace(
          /(^\w+:|^)\/\//,
          ""
        );
        if (currentUrl === existingUrl && chatPromptConfig.key !== configId)
          return chatPromptConfig;
      });
      hasDuplicateURLs = onEditMode
        ? matchedURLs.length >= 1
        : matchedURLs.length > 0;
    } catch (e) {
      console.error("Exception occured while finding duplicate URLs : ", e);
      return false;
    }

    return hasDuplicateURLs;
  };

  const deletePrompt = () => {
    if (isValidUser()) {
      let promptData = getChatConfigObject(promptList)[deletePromptId];
      dispatch(VoiceboxActions.showVoicebox({ message: "Deleting" }));
      dispatch(
        deleteChatPromptConfigurationRequest(
          deletePromptId,
          promptData.projectKey
        )
      );
      setShowDeletePopup(false);
    } else {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "You should be an owner to Delete the settings...",
          dismissAfter: 3000,
        })
      );
    }
  };

  const getChatConfigObject = (promptsList) => {
    let chatPromptConfig = {};
    if (promptList && promptList.length) {
      promptList.forEach((promptData) => {
        chatPromptConfig[promptData.key] = promptData;
        if (!chatPromptConfig[promptData.key].promptName) {
          promptData.promptIndexName = `Prompt - ${++promptNo}`;
          chatPromptConfig[promptData.key] = promptData;
        }
      });
    }

    return chatPromptConfig;
  };
  const toggleDeletePopup = (event) => {
    if (event && event.target.dataset.id) {
      setDeletePromptId(event.target.dataset.id);
    }
    setShowDeletePopup(true);
  };

  const getProactiveChatsList = () => {
    let chatPromptConfig = getChatConfigObject(promptList);
    let toRender = [];
    if (chatPromptConfig && Object.keys(chatPromptConfig).length) {
      for (let key in chatPromptConfig) {
        let config = chatPromptConfig[key];
        toRender.push(
          <ProactiveDetailsCardComponent
            proactiveConfig={config}
            toggleDeletePopup={toggleDeletePopup}
            onEditPrompt={onEditPrompt}
            isValidUser={isValidUser}
          />
        );
      }
    }
    return toRender;
  };

  const toggleProactivePopup = (value) => {
    setShowProactivePopup(value);
    setIsDuplicateURL(false);
    setIsURLValid(true);
    setIsMessageValid(true);
  };

  const getProactivePopupComponent = () => {
    if (showProactivePopup)
      return (
        <ProactivePopUpComponent
          disableContentEditableWarning
          saveChangesFromPopup={saveChangesFromPopup}
          onEditMode={onEditMode}
          toggleProactivePopup={toggleProactivePopup}
          proactiveConfigObject={proactiveConfigObject}
          isDuplicateURL={isDuplicateURL}
          isURLValid={isURLValid}
          isMessageValid={isMessageValid}
          setIsURLValid={setIsURLValid}
          setIsDuplicateURL={setIsDuplicateURL}
          setIsMessageValid={setIsMessageValid}
        />
      );
    else "";
  };

  const onEditPrompt = (event) => {
    let promptId = event.target.dataset.id;
    let promptDataToEdit = getChatConfigObject(promptList)[promptId] || {};
    if (isValidObject(promptDataToEdit)) {
      setOnEditMode(true);
      setProactiveConfigObject(promptDataToEdit);
      setShowProactivePopup(true);
    }
  };

  const onCreatePrompt = () => {
    let promptObject = getNewProactiveConfigObject();
    setOnEditMode(false);
    setProactiveConfigObject(promptObject);
    setShowProactivePopup(true);
  };

  const saveChangesFromPopup = (updatedPromptData) => {
    if (isValidUser()) {
      if (validatePrompt(updatedPromptData)) {
        if (onEditMode) {
          dispatch(updateChatPromptConfigurationRequest(updatedPromptData));
          dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
          setProactiveConfigObject(getNewProactiveConfigObject());
          setShowProactivePopup(false);
        } else {
          dispatch(createChatPromptConfiguration(updatedPromptData));
          EventsTracking.trackChatPromptCreation(updatedPromptData);
          dispatch(VoiceboxActions.showVoicebox({ message: "Creating" }));
          setProactiveConfigObject(getNewProactiveConfigObject());
          setShowProactivePopup(false);
        }
      }
    } else {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "You should be an owner to Create the settings...",
          dismissAfter: 3000,
        })
      );
    }
  };

  const getAddNewButton = () => {
    if (isValidUser()) {
      return (
        <li onClick={onCreatePrompt} className="add-new">
          <a>+ Add New</a>
        </li>
      );
    } else {
      return <span></span>;
    }
  };
  return (
    <div>
      <ul className="your-prompt">
        {getProactiveChatsList()}
        {getAddNewButton()}
      </ul>
      {getProactivePopupComponent()}
      <div
        className="cs-popupbg"
        style={showDeletePopup ? {} : { display: "none" }}
      ></div>
      <div
        className="cs-popup-model popup-new cs-popup-delete"
        style={showDeletePopup ? {} : { display: "none" }}
      >
        <header>
          <h2>
            <DeletePopupIcon />
            Delete
          </h2>
          <cite
            onClick={() => {
              setShowDeletePopup(false);
            }}
          >
            ×
          </cite>
        </header>
        <section>
          <div class="cs-model-msg">
            Deleting this configuration will permanently remove the proactive
            message from the widget. Do you want to continue deleting?
          </div>
        </section>
        <footer>
          <a
            href="javascript:void(0)"
            class="cs-btn cs-decline-btn"
            onClick={() => {
              setShowDeletePopup(false);
            }}
          >
            Cancel
          </a>
          <a
            href="javascript:void(0)"
            class="cs-btn cs-remove-btn"
            onClick={deletePrompt}
          >
            Delete
          </a>
        </footer>
      </div>
      {/* <aside className="cs-popup">
                <header>
                    <h2>Delete</h2>
                    <cite onClick={()=>{setShowDeletePopup(false)}}><PopupCloseIcon/></cite>
                </header>   
                <div>
                    <p>Deleting this configuration will permanently remove the proactive message from the widget. Do you want to continue deleting?</p>
                    <div className="input-form-button">
                        <button onClick={()=>{setShowDeletePopup(false)}} className="cs-btn cs-decline-btn">Cancel</button>
                        <button onClick={deletePrompt} className="cs-btn cs-primary-btn">Delete</button>
                    </div>
                </div>
    </aside> */}
    </div>
  );
};

export default ProactiveChatContainer;
