import { IWidgetConfigData } from 'src/features/wizard/setup/api/types'
import { IFormValues } from 'src/features/wizard/setup/forms/Setup/data'

export const normalizeWidgetDataToSetupValues = (
  data: IWidgetConfigData
): IFormValues => ({
  widgetType: data.widgetType,
  color: data.widgetThemeColor,
  icon: {
    color: data.widgetThemeColor,
    url: data.chatWidgetIconURL,
  },
  position: data.widgetPosition,
})
