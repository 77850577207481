import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Text1 } from 'src/features/ui/components/Typography'

export const Label = styled(Text1)<{ isSuccess: boolean; isError: boolean }>`
  margin-left: 1.6rem;

  ${is('isSuccess')`
    color: ${R.path(['theme', 'color', 'deepSea'])};
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.7rem;
  `}

  ${is('isError')`
    color: ${R.path(['theme', 'color', 'alizarinCrimson'])};
    background-color: transparent;
  `}
`

export const Container = styled.div<{ isSuccess: boolean; isError: boolean }>`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  border-radius: 0.3rem;

  ${is('isSuccess')`
    background-color: ${R.path(['theme', 'color', 'appleGreen'])};
  `}

  ${is('isError')`
    background-color: transparent;
    padding: 1.6rem 0;
  `}
`

export const IconContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
`

export const ContactLink = styled.a`
  text-decoration: underline;
  color: ${R.path(['theme', 'color', 'alizarinCrimson'])};
`
