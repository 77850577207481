import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ExtraFields from "./ExtraFields.jsx";
import { isEmpty } from "../../commons/Validators";
import {
  getCurrentFormattedTime,
  isValidName,
  getFormattedNumber,
  getProjectKey,
  getUnformattedPhoneNumber,
  isValidEmail,
  getDiffTimezoneTime,
  isDST,
  isValidString,
} from "../../commons/Utility";
import { browserHistory } from "react-router";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import * as NotificationSettingsActions from "../../actions/NotificationSettingsAction";
import * as UserActions from "../../actions/UserAction";
import OuterProfileComponent from "./OuterProfileComponent.jsx";
import { useGetWorkingHours } from "../hooks/useGetWorkingHours.js";
import { useUpdateWorkingHours } from "../hooks/useUpdateWorkingHours.js";
import { useCreateWorkingHours } from "../hooks/useCreateWorkingHours.js";
import { usePrevious } from "../hooks/usePrevious.js";
import { isValidNumber } from "libphonenumber-js";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics.js";
import { userNameErrorHandler } from "./ErrorHandlers.js";
var ctzc = require("country-tz-currency");

const ProfileContainer = (props) => {
  const {
    userReducer,
    staffs,
    userLocation,
    notifcationSettings,
  } = useSelector((state) => ({
    userReducer: state.UserReducer,
    userLocation: state.UserReducer.locationDetails,
    staffs: state.StaffReducer.dashboardAgents["LS-" + props.params.projectkey],
    notifcationSettings: state.NotificationSettingsReducer,
  }));

  const user = userReducer.data;
  const userId = user ? user.id : "";
  const accountId = user ? user.accountID : "";
  const isUserUpdate = userReducer.isUpdating;
  const [contactState, setContact] = useState({
    userState: {},
    photoUrl: "",
    linkedContactMethods: [],
    isUpdated: false,
  });
  const [initialState, setInitialState] = useState({});
  const [currentLocation, setCurrentLocation] = useState({
    latitude: "",
    longitude: "",
  });
  const [showImgTag, setShowImgTag] = useState(true);
  const [containsError, setContainsError] = useState(false);
  const [notificationState, setNotificationState] = useState({});
  const [imageChange, setImageChange] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [imageData, setImageData] = useState({});
  const [showCropper, setShowCropper] = useState(false);
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [uploadUrlData, setUploadUrlData] = useState({});
  const projectId = "LS-" + props.params.projectkey;
  const {
    workingHours: workingHoursFromreducer,
    isUpdating,
  } = useGetWorkingHours(userId, accountId, projectId);
  const updateWorkingHours = useUpdateWorkingHours();
  const createWorkingHours = useCreateWorkingHours();
  const prevIsUpdate = usePrevious(isUserUpdate);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userLocation && userLocation.latitude !== currentLocation.latitude)
      getPosition();
    if (user && user.contact && Object.keys(initialState).length <= 0) {
      let dcmContact = user.contact;
      let linkedContactMethods = dcmContact.linkedContactMethods;
      let mailIds = [];
      let phoneNumbers = [];
      linkedContactMethods.forEach((property) => {
        if (
          property.type === "email" &&
          mailIds.indexOf(property.value) === -1
        ) {
          mailIds.push(property.value);
        } else if (property.type === "phone" && property.value) {
          let number = getFormattedNumber(property.value, countryCode);
          if (phoneNumbers.indexOf(number) === -1) phoneNumbers.push(number);
        }
        if (
          user.originalId &&
          (!user.defaultProject ||
            getProjectKey(user.defaultProject) !== props.params.projectkey)
        ) {
          let user = {};
          user.defaultProject = props.params.projectkey;
          dispatch(
            UserActions.updateDefaultProjectKey(props.params.projectkey)
          );
          dispatch(UserActions.updateUserRequested(user, false));
          dispatch(
            UserActions.updateSelectedProjectKey(props.params.projectkey)
          );
          setDetailsSubmitted(false);
        }
      });
      setInitialState({ mailIds, phoneNumbers });
    }
  });

  useEffect(() => {
    if (notifcationSettings && notifcationSettings.isFetched) {
      setLocalState();
    }
  }, [notifcationSettings.data]);

  useEffect(() => {
    if (prevIsUpdate && !isUserUpdate && detailsSubmitted) {
      resetState();
      setImageChange(false);
    }
  }, [isUserUpdate]);

  const getUserName = () => {
    if (user.lastName) return user.firstName + " " + user.lastName;
    else return user.firstName;
  };

  let contact = user && staffs ? staffs[user.id] : "";
  let dcmContact = user ? user.contact : null;
  let title =
    dcmContact && dcmContact.title && dcmContact.title !== "null"
      ? dcmContact.title.trim()
      : "";
  let imgSrc =
    contact &&
    contact.photoUrl &&
    !contact.photoUrl.includes("null") &&
    !contact.photoUrl.includes("no-image")
      ? contact.photoUrl + "?timestamp=" + new Date().getTime()
      : null;
  let userName = user ? getUserName() : "";
  let emailId = contact && contact.login;
  let phoneNumber =
    contact &&
    contact.mobileNumber &&
    contact.mobileNumber !== "null" &&
    contact.mobileNumber.length > 5
      ? contact.mobileNumber
      : "";
  let latitude =
    userLocation && userLocation.latitude ? userLocation.latitude : null;
  let longitude =
    userLocation && userLocation.longitude ? userLocation.longitude : null;
  let city =
    userLocation && userLocation.city
      ? userLocation.city.charAt(0).toUpperCase() + userLocation.city.slice(1)
      : "";
  let countryCode =
    userLocation && userLocation.countryCode ? userLocation.countryCode : "IN";
  let timezoneId =
    userLocation && userLocation.timezoneId
      ? userLocation.timezoneId
      : "Asia/Calcutta";
  let offset = ctzc.getTzById(timezoneId)
    ? ctzc.getTzById(timezoneId).offset
    : 0;
  offset =
    isDST(timezoneId) && ctzc.getTzById(timezoneId)
      ? offset + ctzc.getTzById(timezoneId).dstOffset
      : offset;
  let linkedContactMethods = dcmContact ? dcmContact.linkedContactMethods : [];
  let isFromEditPage = props.route.path.includes("edit");
  imgSrc =
    contactState && contactState.userState.image
      ? contactState.userState.image.imageUrl
      : imgSrc;
  let time = getDiffTimezoneTime(offset);

  const setLocalState = () => {
    let notifications = Object.assign({}, notifcationSettings.data);
    if (
      notifications.conversations === "INACTIVE" &&
      notifications.onMention === "ACTIVE"
    ) {
      setFocusMode(notifications);
    } else if (
      notifications.conversations === "ACTIVE" &&
      notifications.messages === "ACTIVE"
    ) {
      setAllMode(notifications);
    } else {
      setNoneMode(notifications);
    }

    setNotificationState(notifications);
  };

  const updateNotification = (e) => {
    let currentSettings = Object.assign({}, notificationState);
    let newMode =
      event.target.nodeName === "DIV"
        ? event.target.id
        : event.target.parentElement.id;

    if (newMode === "All") {
      setAllMode(currentSettings);
    }
    if (newMode === "Focus") {
      setFocusMode(currentSettings);
    }
    if (newMode === "None") {
      setNoneMode(currentSettings);
    }
    setNotificationState(currentSettings);

    if (
      notifcationSettings.isFetched &&
      !notifcationSettings.isFetching &&
      currentSettings.mode !== notificationState.mode
    ) {
      dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
      dispatch(
        NotificationSettingsActions.updateNotificationSettings(currentSettings)
      );
      console.log("Settings to be updated is ", currentSettings);
    }
  };

  const setAllMode = (currentSettings) => {
    currentSettings.messages = "ACTIVE";
    currentSettings.conversations = "ACTIVE";
    currentSettings.assignment = "ACTIVE";
    currentSettings.onMention = "ACTIVE";
    currentSettings.mode = "All";
  };

  const setFocusMode = (currentSettings) => {
    currentSettings.messages = "INACTIVE";
    currentSettings.assignment = "ACTIVE";
    currentSettings.conversations = "INACTIVE";
    currentSettings.onMention = "ACTIVE";
    currentSettings.mode = "Focus";
  };

  const setNoneMode = (currentSettings) => {
    currentSettings.messages = "INACTIVE";
    currentSettings.conversations = "INACTIVE";
    currentSettings.assignment = "INACTIVE";
    currentSettings.onMention = "INACTIVE";
    currentSettings.mode = "None";
  };

  const handleNameChange = (e, errorText) => {
    let name = e.target.value.trim();
    let shouldUpdateChange = isValidString(errorText) || getUserName().trim() == name;
    if(shouldUpdateChange) {
      setHasChanged(false);
    } else {
      setHasChanged(true);
    }
    let fullNameArr = name.replace(/\s\s+/g, " ").split(" ");
    let lastName = fullNameArr.length > 1 ? fullNameArr.pop() : "";
    let firstName = fullNameArr.join(" ");
    setContact((prevState) => {
      return {
        ...prevState,
        userState: {
          ...prevState.userState,
          firstName: firstName,
          lastName: lastName,
        },
      };
    });
  };

  const removeErrors = () => {
    let errorFields = document.getElementsByClassName("cs-error-field");
    let errors = false;
    if (errorFields.length > 0) {
      errorFields.forEach((element) => {
        if (element.style.display !== "none") errors = true;
      });
    }
    if (errorFields.length <= 0 || !errors) setContainsError(false);
  };

  const isDuplicatePhoneNumber = (contactMethods, phoneNumber) => {
    phoneNumber = getFormattedNumber(phoneNumber);
    let initialPhoneNumbers = initialState.phoneNumbers;
    let changedPhoneNumbers = [];
    contactMethods.forEach((contactMethod) => {
      if (contactMethod.type === "phone")
        changedPhoneNumbers.push(getFormattedNumber(contactMethod.value));
    });
    if (
      changedPhoneNumbers.indexOf(phoneNumber) === -1 &&
      initialPhoneNumbers.indexOf(phoneNumber) === -1
    )
      return false;
    return true;
  };

  const handleNumberChange = (e) => {
    let number = e.target.value;
    removeActiveField(e);
    let contactMethodId = event.target.dataset.id;
    let title = event.target.dataset.title || "work";
    let contactMethods = contactState.linkedContactMethods || [];
    let formattedNumber = getFormattedNumber(number, countryCode);
    if (formattedNumber && isValidNumber(formattedNumber)) {
      e.target.value = formattedNumber;
      let rawPhoneNumber = getUnformattedPhoneNumber(formattedNumber);
      if (
        title === "mobilenumber" &&
        !isDuplicatePhoneNumber(contactMethods, formattedNumber)
      )
        setContact((prevState) => {
          return {
            ...prevState,
            userState: {
              ...prevState.userState,
              displayMobileNumber: rawPhoneNumber,
            },
          };
        });
      updateContactMethod(
        contactMethods,
        contactMethodId,
        rawPhoneNumber,
        title,
        "phone"
      );
    } else if (isEmpty(number) && contactMethodId.includes("new")) return;
    else addErrorClass(e);
  };

  const handleRoleChange = (e, errorText) => {
    let role = e.target.value.trim();
    if (isValidString(errorText) || (role === title))
      setHasChanged(false);
    else
      setHasChanged(true);

    setContact((prevState) => {
      return {
        ...prevState,
        userState: { ...prevState.userState, role: role },
      };
    });
  };

  const isDuplicateEmailId = (contactMethods, emailId) => {
    let initialEmailIds = initialState.mailIds;
    let changedMailIDs = [];
    contactMethods.forEach((contactMethod) => {
      if (contactMethod.type === "email")
        changedMailIDs.push(contactMethod.value);
    });
    if (
      changedMailIDs.indexOf(emailId) === -1 &&
      initialEmailIds.indexOf(emailId) === -1
    )
      return false;
    return true;
  };

  const handleEmailChange = (e) => {
    let mailId = e.target.value;
    let contactMethodId = event.target.dataset.id;
    let contactMethods = contactState.linkedContactMethods || [];
    let title = event.target.dataset.title || "work";
    removeActiveField(e);
    if (!isEmpty(mailId) && isValidEmail(mailId)) {
      updateContactMethod(
        contactMethods,
        contactMethodId,
        mailId,
        title,
        "email"
      );
    } else if (isEmpty(mailId) && contactMethodId.includes("new")) return;
    else addErrorClass(e);
  };

  const addErrorClass = (e) => {
    if (!e.target.parentElement.className.includes("cs-error-field")) {
      e.target.parentElement.className += "cs-error-field ";
      setContainsError(true);
    }
  };

  const setActiveField = (event) => {
    if (
      !event.target.parentElement.className.includes("active") &&
      !event.target.disabled
    )
      event.target.parentElement.className += "cs-active-field";
  };

  const removeActiveField = (e) => {
    if (e.target.parentElement.className.includes("active"))
      e.target.parentElement.className = "cs-input-field ";
  };

  const removeContactMethod = (contactMethodId, isMobileNumber) => {
    let contactMethods = contactState.linkedContactMethods || [];
    if (isMobileNumber)
      setContact((prevState) => {
        return {
          ...prevState,
          userState: { ...prevState.userState, deleted: true },
        };
      });

    for (let index = 0; index < contactMethods.length; index++) {
      if (
        contactMethods[index] &&
        contactMethods[index].id === contactMethodId
      ) {
        contactMethods.splice(index, 1);
      }
    }
    if (!contactMethodId.includes("new")) {
      contactMethods.push({ id: contactMethodId, deleted: true });
    }
    hasStateChanged(contactMethods);
    removeErrors();
    setContact((prevState) => {
      return {
        ...prevState,
        userState: {
          ...prevState.userState,
          linkedContactMethods: contactMethods,
        },
      };
    });
  };

  const updateContactMethod = (
    contactMethods,
    contactMethodId,
    value,
    title,
    type
  ) => {
    for (let index = 0; index < contactMethods.length; index++) {
      if (
        contactMethods[index].id === contactMethodId ||
        contactMethods[index].value === value
      ) {
        contactMethods.splice(index, 1);
      }
    }
    if (
      ((type === "phone" && !isDuplicatePhoneNumber(contactMethods, value)) ||
        (type === "email" && !isDuplicateEmailId(contactMethods, value))) &&
      value
    ) {
      contactMethods.push({
        id: contactMethodId,
        type: type,
        value: value,
        title: title,
      });
      console.log(contactMethods.map((item) => item.id));
      setContact((prevState) => {
        return {
          ...prevState,
          userState: {
            ...prevState.userState,
            linkedContactMethods: contactMethods,
          },
        };
      });
    }
    hasStateChanged(contactMethods);
  };

  const removeErrorField = () => {
    if (event.keyCode !== 9) {
      let className = event.target.parentElement.className.replace(
        "cs-error-field",
        ""
      );
      event.target.parentElement.className = className;
    }
    removeErrors();
  };

  //to get the email ids from linked contact method
  const getEmailIds = () => {
    let toRender = [];
    let mailIds = [];
    linkedContactMethods.forEach((property) => {
      if (
        !isFromEditPage &&
        property.type === "email" &&
        property.value !== "" &&
        property.value !== emailId &&
        mailIds.indexOf(property.value) === -1
      ) {
        mailIds.push(property.value);
        toRender.push(<p className="fs-exclude">{property.value}</p>);
      }
    });
    return toRender;
  };

  const isValidObjectContainsRespectivePhoneNumber = (
    { type, value },
    phoneNumbers
  ) => {
    let number = value ? value.replace("+", "") : "";
    let mobileNumber = phoneNumber.replace("+", "");
    return (
      type === "phone" &&
      value &&
      number !== mobileNumber &&
      value.length > 9 &&
      phoneNumbers.indexOf(value) === -1
    );
  };

  //to get the phone number from linked contact method
  const getPhoneNumbers = () => {
    let toRender = [];
    let phoneNumbers = [];
    linkedContactMethods.forEach((property) => {
      if (isValidObjectContainsRespectivePhoneNumber(property, phoneNumbers)) {
        phoneNumbers.push(property.value);
        toRender.push(
          <p className="fs-exclude">
            {getFormattedNumber(property.value, countryCode)}
          </p>
        );
      }
    });
    return toRender;
  };

  const addEmailField = () => {
    let id = `new${new Date().getTime()}`;
    Event(projectId, "Edit Profile", "Click", "Email field");
    return (
      <ExtraFields
        id={id}
        value=""
        type="Email"
        handleChange={handleEmailChange}
        removeErrorField={removeErrorField}
        singleField={true}
        setActiveField={setActiveField}
        setContactMethod={setContactMethod}
        removeContactMethod={removeContactMethod}
        setFocus={true}
      />
    );
  };

  const addPhoneField = () => {
    let id = `new${new Date().getTime()}`;
    Event(projectId, "Edit Profile", "Click", "Phone field");
    return (
      <ExtraFields
        id={id}
        value=""
        type="Phone"
        handleChange={handleNumberChange}
        removeErrorField={removeErrorField}
        singleField={true}
        setActiveField={setActiveField}
        setContactMethod={setContactMethod}
        removeContactMethod={removeContactMethod}
        setFocus={true}
      />
    );
  };

  const setContactMethod = (event) => {
    let value = event.target.value;
    let contactMethodId = event.target.dataset.id;
    let contactMethods = contactState.linkedContactMethods || [];
    let title = event.target.dataset.title || "work";
    let type = event.target.dataset.type.toLowerCase();
    if (type === "phone" && value)
      value = getFormattedNumber(value, countryCode);
    updateContactMethod(contactMethods, contactMethodId, value, title, type);
  };

  const getExtraField = () => {
    let phoneNumbers = [];
    let toRender = [];
    let mailIds = [];
    let contactMethodsValues = [];

    linkedContactMethods.forEach((property) => {
      if (
        property.type === "email" &&
        contactMethodsValues.indexOf(property.value) === -1
      ) {
        let mailId = property.value;
        let title = property.title;
        if (property.value === emailId)
          mailIds.unshift({ mailId: mailId, title: title, key: property.id });
        else mailIds.push({ mailId: mailId, title: title, key: property.id });
      } else if (
        property.type === "phone" &&
        property.value &&
        contactMethodsValues.indexOf(property.value) === -1 &&
        property.value.length > 9
      ) {
        let number = getFormattedNumber(property.value, countryCode);
        let title = property.title;
        phoneNumbers.push({ number: number, title: title, key: property.id });
      }
      contactMethodsValues.push(property.value);
    });

    if (contactMethodsValues.indexOf(emailId) === -1)
      mailIds.unshift({ mailId: emailId, title: "email", key: "" });

    for (
      let index = 0;
      index < Math.min(mailIds.length, phoneNumbers.length);

    ) {
      toRender.push(
        <ExtraFields
          disable={mailIds[index].mailId === emailId}
          email={mailIds[index]}
          phone={phoneNumbers[index]}
          handleEmailChange={handleEmailChange}
          handleNumberChange={handleNumberChange}
          removeErrorField={removeErrorField}
          setActiveField={setActiveField}
          setContactMethod={setContactMethod}
          removeContactMethod={removeContactMethod}
        />
      );
      mailIds.shift();
      phoneNumbers.shift();
    }

    if (mailIds.length > phoneNumbers.length) {
      for (let index in mailIds) {
        toRender.push(
          <ExtraFields
            id={mailIds[index].key}
            disable={mailIds[index].mailId === emailId}
            value={mailIds[index].mailId}
            type="Email"
            title={mailIds[index].title}
            handleChange={handleEmailChange}
            removeErrorField={removeErrorField}
            singleField={true}
            setActiveField={setActiveField}
            setContactMethod={setContactMethod}
            removeContactMethod={removeContactMethod}
          />
        );
      }
    } else {
      for (let index in phoneNumbers) {
        toRender.push(
          <ExtraFields
            id={phoneNumbers[index].key}
            value={phoneNumbers[index].number}
            title={phoneNumbers[index].title}
            type="Phone"
            handleChange={handleNumberChange}
            singleField={true}
            removeErrorField={removeErrorField}
            setActiveField={setActiveField}
            setContactMethod={setContactMethod}
            removeContactMethod={removeContactMethod}
          />
        );
      }
    }
    return toRender;
  };

  //if error occurs while loading an image
  const onImgLoadError = () => {
    setShowImgTag(false);
  };

  const handleImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (validateChangedImage(file)) {
      reader.onload = () => {
        let image = {
          imageData: reader.result.replace(
            /^data:image\/(png|jpg|jpeg);base64,/,
            ""
          ),
          imageUrl: reader.result,
          name: file.name,
          type: file.type,
        };
        setShowCropper(true);
        setImageData(image);
      };
      reader.readAsDataURL(file);
    }
  };

  const saveImage = (roundedCanvas) => {
    let image = {
      imageData: roundedCanvas
        .toDataURL()
        .replace(/^data:image\/(png|jpg|jpeg);base64,/, ""),
      imageUrl: roundedCanvas.toDataURL(),
      name: imageData.name,
      type: imageData.type,
    };
    let formData = new FormData();
    roundedCanvas.toBlob((blob) => {
      formData.append("file", blob);
    }, imageData.type);
    let imgUploadUrlData = {};
    imgUploadUrlData.contactID = userId;
    imgUploadUrlData.title = imageData.name;
    imgUploadUrlData.operation = "update";
    imgUploadUrlData.formData = formData;
    setUploadUrlData(imgUploadUrlData);
    setContact((prevState) => {
      return {
        ...prevState,
        userState: { ...prevState.userState, image: image },
      };
    });
    setShowCropper(false);
    setShowImgTag(true);
    setImageChange(true);
  };

  const toggleCropperWindowState = (state) => {
    setShowCropper(state);
  };

  const removeImage = () => {
    setShowImgTag(false);
    let imgUploadUrlData = {};
    imgUploadUrlData.contactID = userId;
    imgUploadUrlData.profile_pic_url = imgSrc;
    imgUploadUrlData.operation = "delete";
    setUploadUrlData(imgUploadUrlData);
    imgSrc = "";
    let image = {
      imageData: "null",
      imageUrl: "null",
      name: "null",
      type: "",
      removeImage: true,
    };
    setContact((prevState) => {
      return {
        ...prevState,
        userState: { ...prevState.userState, image: image },
      };
    });
    event.target.previousSibling.lastChild.value = "";
    setImageChange(true);
  };

  const hasStateChanged = (linkedContactMethods) => {
    let initialEmailIds = initialState.mailIds;
    let initialPhoneNumbers = initialState.phoneNumbers;
    let contactMethods = linkedContactMethods;
    let changedEmailIds = [];
    let changedPhoneNumbers = [];
    let hasChanged = false;
    if (contactMethods) {
      contactMethods.forEach((contactMethod) => {
        if (contactMethod.type === "phone")
          changedPhoneNumbers.push(getFormattedNumber(contactMethod.value));
        else if (contactMethod.type === "email")
          changedEmailIds.push(contactMethod.value);
        if (
          contactMethod.deleted &&
          contactMethod.id &&
          !contactMethod.id.includes("new")
        )
          hasChanged = true;
      });
      changedEmailIds.forEach((emailId) => {
        if (initialEmailIds.indexOf(emailId) === -1) hasChanged = true;
      });
      changedPhoneNumbers.forEach((phoneNumber) => {
        if (initialPhoneNumbers.indexOf(phoneNumber) === -1) hasChanged = true;
      });
    }
    setHasChanged(hasChanged);
  };

  const resetState = () => {
    setImageChange(false);
    setShowImgTag(true);
    setContact({
      userState: {},
      photoUrl: "",
      linkedContactMethods: [],
      isUpdated: false,
    });
    setInitialState({});
    setHasChanged(false);
    setDetailsSubmitted(false);
    browserHistory.push(
      "/app/" + props.params.projectkey + "/settings/myprofile"
    );
  };

  const validateChangedImage = (file) => {
    if (file) {
      if (
        !(
          file.type.includes("jpg") ||
          file.type.includes("jpeg") ||
          file.type.includes("png")
        )
      ) {
        dispatch(
          VoiceboxActions.showVoicebox({
            message: "Invalid File Format (.jpg, .jpeg, .png are supported )",
            dismissAfter: 2000,
            showError: true,
          })
        );
        return false;
      }

      if (file.size > 500000) {
        dispatch(
          VoiceboxActions.showVoicebox({
            message: "Image size Exceeded",
            dismissAfter: 2000,
            showError: true,
          })
        );
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const getPosition = () => {
    setCurrentLocation((prevState) => {
      return { ...prevState, latitude: latitude, longitude: longitude };
    });
  };

  const submitDetails = () => {
    let fullName = (contactState.userState.firstName + " " + contactState.userState.lastName).trim();
    if (!containsError && !isValidString(userNameErrorHandler(fullName)) ) {
      let contactMethods = contactState.userState.linkedContactMethods;
      if (contactMethods)
        contactMethods.forEach((contactMethod, index) => {
          if (contactMethod.id && contactMethod.id.includes("new"))
            delete contactMethod.id;
          if (contactMethod.value && contactMethod.value.trim() === "")
            contactMethods.splice(index, 1);
        });
      dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
      //To decide the action based profile image uploaded
      if (Object.keys(uploadUrlData).length === 0)
        dispatch(UserActions.updateUserRequested(contactState.userState, true));
      else {
        if (uploadUrlData.operation === "delete")
          dispatch(
            UserActions.updateUserRequested(
              { ...contactState.userState, ...uploadUrlData },
              true
            )
          );
        else
          dispatch(
            UserActions.requestUploadUrl(uploadUrlData, contactState.userState)
          );
      }
      setDetailsSubmitted(true);
    } else console.log("Check the details");
  };

  let formattedNumber = phoneNumber
    ? getFormattedNumber(phoneNumber, countryCode)
    : "";
  let imageUrl = Object.keys(imageData).length > 0 ? imageData.imageUrl : "";
  let addNewField = {
    addEmailField: addEmailField,
    addPhoneField: addPhoneField,
  };
  let handleChange = {
    handleNameChange: handleNameChange,
    handleRoleChange: handleRoleChange,
    handleNumberChange: handleNumberChange,
    handleEmailChange: handleEmailChange,
  };
  let userProps = {
    userName: userName,
    imgSrc: imgSrc,
    emailId: emailId,
    contact: contact,
    title: title,
    userId: userId,
    accountId: accountId,
    phoneNumber: formattedNumber,
  };
  let locationProps = {
    currentLocation: currentLocation,
    cityName: city,
    countryCode: countryCode,
  };
  let imageProps = {
    onImgLoadError: onImgLoadError,
    showImgTag: showImgTag,
    removeImage: removeImage,
    imageChange: imageChange,
    openCropperWindow: showCropper,
    uploadedImageUrl: imageUrl,
    toggleCropperWindowState: toggleCropperWindowState,
    saveImage: saveImage,
    handleImageChange: handleImageChange,
  };

  return (
    <OuterProfileComponent
      userProps={userProps}
      handleChange={handleChange}
      projectKey={props.params.projectkey}
      locationProps={locationProps}
      addNewField={addNewField}
      imageProps={imageProps}
      time={time}
      getEmailIds={getEmailIds}
      getPhoneNumbers={getPhoneNumbers}
      getExtraField={getExtraField}
      submitDetails={submitDetails}
      removeErrorField={removeErrorField}
      updateNotification={updateNotification}
      activeMode={notificationState.mode}
      setActiveField={setActiveField}
      resetState={resetState}
      workingHoursFromreducer={workingHoursFromreducer}
      updateWorkingHours={updateWorkingHours}
      createWorkingHours={createWorkingHours}
      isUpdating={isUpdating}
      hasStateChanged={hasChanged}
      detailsSubmitted={detailsSubmitted}
    />
  );
};
export default ProfileContainer;
