import * as connection from "./commons/RTMConnection";
import * as messageAction from "./actions/MessageAction";
import * as conversationInfoAction from "./actions/ConversationInfoAction";
import {
  updateOpenConversationCountRealtime,
  updateContactPresence,
} from "./actions/StaffAction";
import {
  updateRemovedProjects,
  updateUnassingedConversationCountRealtime,
  deleteProjectresponse,
  updateProjectResponse,
  requestNewlyAddedProjects,
} from "./settings/actions/ProjectAction";
import * as contactInfoAction from "./actions/ContactInfoAction";
import * as PeopleAction from "./actions/PeopleAction";
import { store } from "./app";
import {
  getAppMode,
  getApiKey,
  getOverflowApiKey,
  postWindowMessage,
  isOverflowConnection,
  isAwConnection,
  generateMessageId,
  getQueryParam,
  getStaffUserIdWithProjectId,
  getStaffUserIds,
  getProjectId,
  setFullNameAndInitial,
  getServerTime,
  generateHashCode,
  getCurrentTimeStamp,
  desktopNotifyPayloadTrigger,
  getAwFormattedUserIds,
  getIsAWRecentComponent,
  isFullScreenMode,
  getValidPeoplesEntity,
  constructMessageStoreObject,
  redirectToLoginPage,
  shouldSendDeliveredStatus,
  createUUId,
  sortObjectArrayInDescendingOrder
} from "./commons/Utility.js";
import {
  MESSAGE_TYPE,
  REALTIME_MESSAGES,
  MESSAGE_FROM,
  GUEST_EVENT,
  AR_WINDOW_MESSAGE,
  GUEST_EVENT_TYPE,
  PRESENCE_CHANNEL,
  GUEST_PRESENCE,
  CONNECT_SUBSCRIBE_SYNC,
  OVERFLOW_CHAT_DISCONNECTION_TYPES,
  AGENT_PRESENCE_CHANNEL_PREFIX,
  USER_SESSION_SYNC,
  DATE
} from "./commons/Constants.js";
import { showVoicebox, hideVoicebox } from "./actions/VoiceboxAction";
import {
  deleteStaffResponse,
  updateStaffRoleResponse,
  staffRequest,
} from "./actions/StaffAction";
import {
  addNewVisitorToQueue,
  removeVisitorToQueue,
} from "./actions/VisitorQueueActions";
import { enqueueChat, flushQueue } from "../app/actions/ChatAnsweringAction";
import { setupResponse } from "./actions/SetupAction";
import { getVisitorName } from "./aw_recents/components/RecentListTab/awRecentListUtility.js";
import ExternalBaseApis from "./aw_recents/ExternalBaseApis";
import { getConnectEnabledProjectsRequest } from "../../../www/js/app/components/myteam/Action/UserProjectMappingAction";
import { sendUnsubcriptionOnConnectConfigSync } from "../../js/app/aw_recents/AwRecentsUtils";
import { queueConversationRemove, queueConversationRequest } from "./actions/ConversationQueueAction";
import { validateConversationAndAddToConnectRecentList } from "./aw_recents/AwRecentsUtils";
import { initCampaignSync } from "./campaign/CampaignSocketConnection";

class SocketConnection {
  constructor() {
    this.RTMServer = {};
    this.isNetworkDisconnected = false;
    this.disconnectTimer = 0;
    this.onChatMessage = this._onChatMessage.bind(this);
    this.onConnect = this._onConnect.bind(this);
    this.onNewSubscriber = this._onNewSubscriber.bind(this);
    this.handleFailed = this.handleFailed.bind(this);
    this.onServerAcknowledge = this._onServerAcknowledge.bind(this);
    this.showReconnectInterval = this.showReconnectInterval.bind(this);
    this.handleInvalidPresence = this.handleInvalidPresence.bind(this);
    this.onSubscribe = this._onSubscribe.bind(this);
    this.onCurrentSubscribers = this._onCurrentSubscribers.bind(this);
    this.onSubscriberLeft = this._onSubscriberLeft.bind(this);
    this.onNetworkConnected = this._onNetworkConnected.bind(this);
    this.onRoutingInteractionEnded = this._onRoutingInteractionEnded.bind(this);
    this.onNetworkDisconnected = this.onNetworkDisconnected.bind(this);
    this.appendAbnormalDisconnectionMsg = this.appendAbnormalDisconnectionMsg.bind(this);
    this.onRoutingInteractionTakenover = this._onRoutingInteractionTakenover.bind(this);
    this.onRoutingInteractionInterrupted = this._onRoutingInteractionInterrupted.bind(this);
    this.onRoutingInteractionClosed = this._onRoutingInteractionClosed.bind(this);
    this.notifyConnectionBack = this.notifyConnectionBack.bind(this);
  }

  initialize(isOverflowConversation) {
    console.log("Connection initialized!!!!");
    const mode = getAppMode() === "live" ? "live" : "staging";
    let apiKey = "";
    if (isOverflowConversation) apiKey = getOverflowApiKey(mode);
    else apiKey = getApiKey(mode);


    this.RTMServer = new connection.default(apiKey, { mode: mode });

    this.RTMServer.off("chat", this.onChatMessage);
    this.RTMServer.off(GUEST_EVENT, this.onChatMessage);
    this.RTMServer.off("server-invalid-presence", this.handleInvalidPresence);
    this.RTMServer.connection.off("connected", this.onConnect);
    this.RTMServer.connection.off("failed", this.handleFailed);
    this.RTMServer.off("subscribed", this.onSubscribe);
    this.RTMServer.connection.off("disconnected", this.handleFailed);
    this.RTMServer.connection.off("reconnect", this.showReconnectInterval);
    this.RTMServer.off("server-subscriberleft", this.onSubscriberLeft);
    this.RTMServer.off("server-newsubscriber", this.onNewSubscriber);
    this.RTMServer.off("server-currentsubscribers", this.onCurrentSubscribers);
    this.RTMServer.off("server-ack", this.onServerAcknowledge);
    this.RTMServer.connection.off(
      "networkdisconnected",
      this.onNetworkDisconnected
    );
    this.RTMServer.connection.off(
      "abnormal_close",
      this.appendAbnormalDisconnectionMsg
    );
    this.RTMServer.connection.off("networkconnected", this.onNetworkConnected);
    this.RTMServer.off("routing-interaction-accepted", this.onChatMessage);
    this.RTMServer.off(
      "routing-interaction-ended",
      this.onRoutingInteractionEnded
    );
    this.RTMServer.off(
      "routing-interaction-takenover-by-staff",
      this.onRoutingInteractionTakenover
    );
    this.RTMServer.off(
      "routing-interaction-interrupted",
       this.onRoutingInteractionInterrupted
    );
    this.RTMServer.off(
      "routing-interaction-closed-by-staff",
      this.onRoutingInteractionClosed
    );

    this.RTMServer.connection.on("connected", this.onConnect);
    this.RTMServer.on("chat", this.onChatMessage);
    this.RTMServer.on(USER_SESSION_SYNC, this.onUserSessionSync);
    this.RTMServer.on(CONNECT_SUBSCRIBE_SYNC.SUBCRIBE_SYNC, this.onChatMessage);
    this.RTMServer.on(GUEST_EVENT, this.onChatMessage);
    this.RTMServer.off("server-invalid-presence", this.handleInvalidPresence);
    this.RTMServer.connection.on("failed", this.handleFailed);
    this.RTMServer.on("subscribed", this.onSubscribe);
    this.RTMServer.connection.on("disconnected", this.handleFailed);
    this.RTMServer.connection.on(
      "reconnect",
      this.showReconnectInterval
    );
    this.RTMServer.on(
      "server-subscriberleft",
      this.onSubscriberLeft
    );
    this.RTMServer.on("server-newsubscriber", this.onNewSubscriber);
    this.RTMServer.on("server-ack", this.onServerAcknowledge);
    this.RTMServer.on("server-currentsubscribers", this.onCurrentSubscribers);
    this.RTMServer.connection.on(
      "networkdisconnected",
      this.onNetworkDisconnected
    );
    this.RTMServer.connection.on(
      "abnormal_close",
      this.appendAbnormalDisconnectionMsg
    );
    this.RTMServer.connection.on(
      "networkconnected",
      this.onNetworkConnected
    );
    this.RTMServer.connection.on(
      "notifyConnectionBack",
      this.notifyConnectionBack
    );
    this.RTMServer.on(
      "routing-interaction-accepted",
      this.onChatMessage
    );
    this.RTMServer.on(
      "routing-interaction-ended",
      this.onRoutingInteractionEnded
    );
    this.RTMServer.on(
      "routing-interaction-takenover-by-staff",
      this.onRoutingInteractionTakenover
    );
    this.RTMServer.on(
      "routing-interaction-interrupted",
      this.onRoutingInteractionInterrupted
    );
    this.RTMServer.on(
      "routing-interaction-closed-by-staff",
      this.onRoutingInteractionClosed
    );
    if (!isOverflowConnection() && !isAwConnection())
      initCampaignSync(this.RTMServer);
  }
  
  onUserSessionSync(...message) {
    let data = message[1];
    if (data.isLogout) {
      redirectToLoginPage();
    }
  }

  getUserId () {
    return store.getState().UserReducer.data ? store.getState().UserReducer.data.id : "";
  }

  getStaffMap(projectId) {
    let {StaffReducer } = store.getState();
    return StaffReducer.dashboardAgents[projectId] || {};
  }

  handleConversationQueueMessage(messageData){
    if (messageData.type === MESSAGE_TYPE.add_conversation_queue) {
      store.dispatch(queueConversationRequest());
    }
    else if(messageData.type === MESSAGE_TYPE.remove_conversation_queue)
      store.dispatch(queueConversationRemove(messageData.conversationId));
  }

  isGuestPresenceMessage(channel, messagePayloadType) {
    return channel
      && channel.includes(PRESENCE_CHANNEL)
      && messagePayloadType === GUEST_PRESENCE
  }

  _onChatMessage(channel, data, userInfo) {
    if (this.isGuestPresenceMessage(channel, data.type)) {
      let guestInfo = data.status && data.status.applicationStatus ? data.status.applicationStatus : "";
      if (guestInfo)
        this.handleGuestMessages(guestInfo);
    }
    if(data.type === MESSAGE_TYPE.staff_joined_conversation) {
      let { conversationId, projectId } = data;
      console.log("triggered from _onChatMessage");
      if (getIsAWRecentComponent()) {
        store.dispatch(
          conversationInfoAction.conversationRequest({
            projectId,
            conversationId,
            isFromAWRecentsComponent: true
          })
        );
      }
    }
    if(data.type === MESSAGE_TYPE.add_conversation_queue || data.type === MESSAGE_TYPE.remove_conversation_queue){
      this.handleConversationQueueMessage(data);
    }
    if (data && data.type === "IMPORT_FINISH"){
      store.dispatch(
        showVoicebox({
          message: "Import Successful",
          dismissAfter: 3000,
        })
      );
      return;
    }
    let visitorQueueActionableTypes = [
      "CHAT",
      "VISITOR_INITIATED_CONVERSATION",
    ];
    if (data.type == "CHAT" && isOverflowConnection()) {
      let postData = {};
      postData.type = "ls-chat-notification";
      postWindowMessage(postData, "*");
    } else if (REALTIME_MESSAGES.includes(data.type)) {
      this.handleRealtimeMessages(data);
      return;
    }
    if (channel.startsWith(AGENT_PRESENCE_CHANNEL_PREFIX)) {
      let channelArray = channel.split("/");
      let projectId = channelArray[channelArray.length - 1];
      let presenceArr = [{ status: data.status, userInfo: userInfo }];
      if(!getIsAWRecentComponent()){
        store.dispatch(
          contactInfoAction.updateContactPresence(
            presenceArr,
            false,
            true,
            projectId,
            this.getUserId()
          )
        );
      }
    }
    // else if( channel && channel.includes("presence/agent/") )
    // {
    // 	let channelArray = channel.split('/');
    // 	let projectId = channelArray[channelArray.length-1];
    // 	let presenceArr = [{"status" : data.status , "userInfo" : userInfo}];
    // 	store.dispatch(contactInfoAction.updateContactPresence( presenceArr, false , false, projectId));
    // }
    else if (
      data.type == "FORWARDING_MESSAGE" ||
      data.type == "CHAT_ASSIGN" ||
      data.type == "CHAT_ACCEPTED" ||
      data.type == "CHAT_REJECTED"
    ) {
      this.hideChatAnsweringRings(
        data.type,
        store.getState(),
        userInfo,
        data.projectId,
        data
      );
    } else if (
      data.type == "BROADCAST_STATS_UPDATE" ||
      data.type == "BROADCAST_STATS_SYNC"
    ) {
      return;
    }

    console.log("Message From : ", userInfo.userId);
    //for sending the delivered report when a new message comes from the visitor
    const { UserReducer } = store.getState();
    let userId;
    if (UserReducer.data) {
      userId = UserReducer.data.id;
    } else if (isOverflowConnection()) {
      userId = getQueryParam("agent_id");
    }
    if (shouldSendDeliveredStatus(data, userId))
      this.sendDeliveredStatus(data, userId);
    var messageObject = constructMessageStoreObject(data);
    let { ConversationInfoReducer, StaffReducer, MessageReducer } = store.getState();
    let conversation = ConversationInfoReducer.conversationObject.conversationMap[data.conversationId];
    let staffMap = StaffReducer.dashboardAgents[data.projectId];
    if (messageObject.type === MESSAGE_TYPE.messages_validation) {
      if (getIsAWRecentComponent())
        store.dispatch(messageAction.onOldConversationRequested());
      let messageIds = data.messageIds;
      this.appenedMessagesValidationRealTimeSyncToReducer(messageIds, staffMap, ConversationInfoReducer);
      store.dispatch(messageAction.sortMessagesValidation(data.messageIds));
    }
    if (data.type === MESSAGE_TYPE.agent_messages_sync) {
      this.sendAgentMessagesValidation(userInfo);
    }
    if (messageObject.type === "FEEDBACK_SUBMITTED") {
      messageObject.rating = data.rating;
      messageObject.comment = data.comment;
    }

    if(conversation){
      let userId = this.getUserId();
      store.dispatch(updateOpenConversationCountRealtime(data, conversation));
      store.dispatch(updateUnassingedConversationCountRealtime(data));
      let isFullScreen = isFullScreenMode();
      store.dispatch(conversationInfoAction.updateConversationRealTime(data, userId, staffMap,isFullScreen));
    }
      
    if(data.conversationId) {
      store.dispatch(messageAction.onMessage(messageObject));
      if ((data.type === "CHAT" || data.type === "ATTACHMENT" || data.type === MESSAGE_TYPE.staff_joined_conversation) && data.messageId !== MessageReducer.awIntegrationMessageID) {
        store.dispatch(messageAction.sendAWDesktopNotification(data.messageId));
        let visitorName = ConversationInfoReducer.conversationObject.contactsMap[data.visitorId];
        visitorName = getVisitorName(visitorName);
        if (
          ConversationInfoReducer.conversationObject.conversationMap[
            data.conversationId
          ]
        ) {
          if (
            !window.location.href.includes("/connect") &&
            window.FrontOfficeService &&
            ExternalBaseApis.hasItemInFrontOffice(data.conversationId)
          ) {
            ExternalBaseApis.updateItemInConnect(data.conversationId, 1);
          }
          if (data.interactionType != "OVERFLOW") {
            desktopNotifyPayloadTrigger(
              messageObject.message,
              visitorName,
              data,
              messageObject.messageId
            );
          }
        }
      }
    }
    if (
      messageObject.messageFrom === "VISITOR" &&
      visitorQueueActionableTypes.includes(data.type)
    ) {
      store.dispatch(removeVisitorToQueue(userInfo.userId));
    }
    if (
      messageObject.type == "CHAT_ANSWERING_MESSAGE" &&
      !isAwConnection() &&
      (store.getState().UserReducer.data.login ==
        data.agentDetails.agentLogin ||
        store.getState().UserReducer.data.id == data.agentDetails.contactId)
    ) {
      store.dispatch(enqueueChat(data));
      if (!window.location.href.includes("/chat/dashboard") || getIsAWRecentComponent()) {
        let { conversationId, projectId } = data;
        console.log("triggerd from _onChatMessage 2");
        store.dispatch(
          conversationInfoAction.conversationRequest({
            projectId,
            conversationId,
          })
        );
      }
    }
    validateConversationAndAddToConnectRecentList(data, ConversationInfoReducer, StaffReducer);

    if (userInfo.messageFrom == MESSAGE_FROM.AGENT) {
      let staffReducer = store.getState().StaffReducer;
      let projectKey = userInfo.projectId;
      let projectStaffMap = staffReducer.dashboardAgents[projectKey];
      let overflowAgentMap = staffReducer.overflowAgents;
      if (
        projectStaffMap &&
        !projectStaffMap[userInfo.contactId] &&
        !overflowAgentMap[userInfo.contactId]
      ) {
        store.dispatch(staffRequest(userInfo.projectId, userInfo.contactId));
      }
    }
    if (data.connectProjectIds) {
      sendUnsubcriptionOnConnectConfigSync(CONNECT_SUBSCRIBE_SYNC.PRESENCE_UNSUBSCRIBE, false);
      store.dispatch(getConnectEnabledProjectsRequest());
      store.dispatch(messageAction.onConnectResubscribe());
    }
  }

  sendAgentMessagesValidation(userInfo) {
    try {
      let interactionId = getQueryParam("connection_id");
      let visitorId = userInfo.userId;
      let conversationId = getQueryParam("conversation_id");
      let messageMap = store.getState().MessageReducer.messageMap[conversationId];
      let visitorsMessages = Object.values(messageMap).filter(
        (message) => message.type == "CHAT" && message.messageFrom == "VISITOR"
      );
      let lastCreatedDate =
        visitorsMessages.length > 0
          ? sortObjectArrayInDescendingOrder(
              visitorsMessages,
              DATE.CREATED_DATE
            )[0][DATE.CREATED_DATE]
          : 0;
      let followupMessages = Object.values(messageMap).filter(
        (message) =>
          message.type == "CHAT" &&
          message.messageFrom == "AGENT" &&
          message[DATE.CREATED_DATE] >= lastCreatedDate
      );
      let validationMessage = {
        messageId: createUUId(),
        message: "",
        type: MESSAGE_TYPE.agent_messages_validation,
        channel: "routing/visitor/" + visitorId,
        conversationId: conversationId,
        messageFrom: MESSAGE_FROM.AGENT,
        senderId: getQueryParam("agent_id"),
        actionTo: "",
        visitorId: visitorId,
        projectId: getQueryParam("project_id"),
        messagesToValidate: followupMessages,
      };
      store.dispatch(
        messageAction.sendMessage(validationMessage, {
          interactionId: interactionId,
          triggerWebHook: false,
        })
      );
    } catch (error) {
      console.log('Error sending agent messages validation: ', error);
    }
  }

  sendDeliveredStatus(message, userId) {
    let interactionId = isOverflowConnection()
      ? getQueryParam("connection_id")
      : null;
    let visitorId = message.visitorId;
    let deliveredMessage = {
      messageId: message.messageId || message.key,
      message: "",
      type: "CHAT_DELIVERED",
      channel: isOverflowConnection()
        ? "routing/visitor/" + visitorId
        : "private/visitor/" +
          message.projectId +
          "/" +
          visitorId,
      conversationId: message.conversationId,
      messageFrom: MESSAGE_FROM.AGENT,
      senderId: userId,
      actionTo: "",
      visitorId: visitorId,
      projectId: message.projectId,
    };
    const metaOptions = isOverflowConnection()
      ? {
          interactionId: interactionId,
          triggerWebHook: true,
        }
      : {
          echoChannels: [
            "private/agent/" + message.projectId,
          ],
          triggerWebHook: true,
        };
    store.dispatch(
      messageAction.sendMessage(
        deliveredMessage,
        metaOptions
      )
    );
  }
  _onConnect(socketId) {
    console.log("Connection created with : ", socketId);
    store.dispatch(messageAction.onConnect(socketId));
  }

  handleFailed() {
    store.dispatch(messageAction.onFailed());
  }

  _onNetworkConnected() {
    let offlineMessages = this.getOffLineMessages();
    store.dispatch(messageAction.resetOfflineMessage());
    store.dispatch(messageAction.onNetworkUp());
    store.dispatch(messageAction.resetMessage());
    store.dispatch(conversationInfoAction.forceUpdateConversationList(true));
    clearTimeout(this.disconnectTimer);

    this.appendOfflineMessages(offlineMessages);
    let page = document.location.href;
    if (page.includes("chat/") || isOverflowConnection())
      store.dispatch(
        showVoicebox({
          message: "Network Connected",
          dismissAfter: 3000,
          showBelowHeader: !isOverflowConnection(),
          showFullScreenLoader: isOverflowConnection(),
        })
      );
    else if (
      page.includes("admin") ||
      page.includes("choose/profile") ||
      page.includes("embed/setup")
    )
      store.dispatch(
        showVoicebox({
          message: "Network Connected",
          dismissAfter: 3000,
          showFullScreenLoader: true,
        })
      );
    else
      store.dispatch(
        showVoicebox({ message: "Network Connected", dismissAfter: 3000 })
      );
  }

  getOffLineMessages() {
    let offlineMessages = Object.assign(
      [],
      store.getState().MessageReducer.offlineMessages
    );
    offlineMessages.forEach(function (offlineMessage, index) {
      store.dispatch(messageAction.sendHttpMessage(offlineMessage));
    });

    return offlineMessages;
  }

  appendOfflineMessages(offlineMessages) {
    offlineMessages.forEach(function (offlineMessage, index) {
      offlineMessage.data.key = offlineMessage.data.messageId;
      store.dispatch(messageAction.onMessage(offlineMessage.data));
    });
  }

  appendAbnormalDisconnectionMsg() {
    if (isOverflowConnection())
    {
      console.log("appendAbnormalDisconnectionMsg invoked inside");
      let conversationId = getQueryParam("conversation_id");
      let connectionId = getQueryParam("connection_id");
      let agent_id = getQueryParam("agent_id");
      let state = store.getState();
      let conversation =
        state.ConversationInfoReducer.conversationObject.conversationMap[
          conversationId
        ];
      let { visitorId = "", projectId = ""} = conversation ? conversation : {};
      let messageId = MESSAGE_TYPE.overflow_chat_disconnected_abnormally;
      let messageMap = state.MessageReducer.messageMap[conversationId];
      let message = Object.keys(messageMap).length > 0 ? messageMap[Object.keys(messageMap)[0]] : {};
      if (message.type !== MESSAGE_TYPE.overflow_chat_disconnected_abnormally) {
        let messageData = {
          actionTo: agent_id,
          channel: "channel",
          conversationId: conversationId,
          createdDate: getServerTime(),
          emailTranscript: false,
          key: messageId,
          message: "",
          messageFrom: "AGENT",
          messageId: messageId,
          projectId: projectId,
          senderId: agent_id,
          timestamp: getServerTime(),
          type: MESSAGE_TYPE.overflow_chat_disconnected_abnormally,
          visitorId: visitorId,
        };
        store.dispatch(messageAction.onMessage(messageData));
        let data = { value: { dataType: 'abnormal_chat_close', connectionId: connectionId }, type: AR_WINDOW_MESSAGE };
        postWindowMessage(data, '*');
        let userId = this.getUserId();
        let staffMap = this.getStaffMap(projectId);
        store.dispatch(conversationInfoAction.updateConversationRealTime(messageData, userId, staffMap));
      }
      } else {
        console.log("The connection has been closed now ");
        store.dispatch(messageAction.onAbnormalClose());
      }
  }

  onNetworkDisconnected() {
    console.log("_onNetworkDisconnected Initiated");
    this.handleFailed();
    let page = document.location.href;
    if (page.includes("chat/") || isOverflowConnection())
    {
      console.log("_onNetworkDisconnected invoked inside");
      store.dispatch(
        showVoicebox({
          message: "Network Disconnected!",
          showBelowHeader: !isOverflowConnection(),
          showError: true,
          showFullScreenLoader: isOverflowConnection(),
        })
      );
      if(isOverflowConnection())
        this.disconnectTimer = setTimeout(this.appendAbnormalDisconnectionMsg, 80000);
    }
    else if (
      page.includes("admin") ||
      page.includes("choose/profile") ||
      page.includes("embed/setup")
    )
      store.dispatch(
        showVoicebox({
          message: "Network Disconnected!",
          dismissAfter: 3000,
          showFullScreenLoader: true,
          showError: true,
        })
      );
    else
      store.dispatch(
        showVoicebox({ message: "Network Disconnected!", showError: true })
      );
  }

  _onSubscribe(obj) {
    store.dispatch(messageAction.onSubscribed());

    let projectKey = store.getState().StaffReducer.activeProjectId;
    let projectStaffMap = store.getState().StaffReducer.dashboardAgents[
      projectKey
    ];
    if (projectStaffMap && Object.keys(projectStaffMap).length) {
      let formattedUserIds = [];

      if (!store.getState().UserReducer.isInternalUser)
        formattedUserIds = getStaffUserIdWithProjectId(
          projectStaffMap,
          projectKey
        );
      else if (store.getState().UserReducer.isInternalUser)
        formattedUserIds = getStaffUserIds(projectStaffMap);

      if (getIsAWRecentComponent()) {
        formattedUserIds = getAwFormattedUserIds(formattedUserIds);
      }
      if (formattedUserIds.length > 0)
        store.dispatch(
          PeopleAction.fetchPresenceByUserIds(
            formattedUserIds,
            projectKey,
            isOverflowConnection(),
            isAwConnection()
          )
        );
    }
  }

  _onSubscriberLeft(channel, data, userInfo) {
    let channelArray = channel.split("/");
    let projectId = channelArray[channelArray.length - 1];
    let presenceArr = [{ status: data.status, userInfo: userInfo }];

    let state = store.getState();
    let conversationId = state.MessageReducer.newMessage.conversationId;
    let conversation =
      state.ConversationInfoReducer.conversationObject.conversationMap[
        conversationId
      ];
    let visitorId = conversation ? conversation.visitorId : "";
    let lastNewMessage = state.MessageReducer.newMessage;

    let incomingChat = Object.assign(
      {},
      state.ChatAnsweringReducer.incomingChatsQueue
    );
    let answerRing = Object.assign(
      {},
      state.ChatAnsweringReducer.answeringRing
    );

    let incomingChatsIds = Object.keys(incomingChat);

    for (let index in incomingChatsIds) {
      let conversationId = incomingChatsIds[index];
      let loggedInUserId = state.UserReducer.data.id;
      let user = state.StaffReducer.dashboardAgents[projectId]
        ? state.StaffReducer.dashboardAgents[projectId][loggedInUserId]
        : undefined;
      if (incomingChat[conversationId].visitorId == userInfo.userId) {
        try {
          let prescenseMessage = {
            type: MESSAGE_TYPE.post_user_prescence,
            channel: "presence/agent/" + projectId,
            lastAssignedTime: user.lastAssignedTime,
            lastSignOffTime: user.lastSignOffTime,
            lastClosedTime: user.lastClosedTime,
            conversationIds: user.conversationIds,
            takingChatStatus: user.takingChatStatus,
            isTakingChat: user.isTakingChat,
            role: user.role,
            skill: user.skill,
            isRinging: user.isRinging,
            lastRingTime: user.lastRingTime,
            shouldRouteChats: user.shouldRouteChats,
            eventTime: getCurrentTimeStamp(),
          };

          store.dispatch(
            messageAction.sendMessage(prescenseMessage, {
              triggerWebHook: true,
            })
          );

        } catch (err) {
          console.info("err:" + JSON.stringify(err));
        }
      }
    }

    if (lastNewMessage.type === "TYPING_START") {
      let typingEndMessage = {
        messageId: generateMessageId(),
        message: "",
        timestamp: new Date().getTime(),
        conversationId: conversationId,
        visitorId: visitorId,
        projectId: projectId,
        type: "TYPING_STOP",
        messageFrom: "VISITOR",
        assignedTo: "",
        read: false,
        interactionType: "OVERFLOW",
      };
      store.dispatch(messageAction.onMessage(typingEndMessage));
    }

    store.dispatch(
      contactInfoAction.updateContactPresence(
        presenceArr,
        false,
        true,
        projectId,
        this.getUserId()
      )
    );
    store.dispatch(removeVisitorToQueue(userInfo.userId));
  }

  checkAndUpdateSetupStatus(userInfo){
    if(userInfo.isVisitor){
      let setupReducer = store.getState().SetupReducer;
      let {setupState} = setupReducer;
      if(setupState) {
        setupState.isEmbedded = true;
        store.dispatch(setupResponse(setupState));
      }  
    }
  }

  _onNewSubscriber(channel, data, userInfo) {
    let channelArray = channel.split("/");
    this.checkAndUpdateSetupStatus(userInfo);
    let projectId = channelArray[channelArray.length - 1];
    let presenceArr = [{ status: data.status, userInfo: userInfo }];
    store.dispatch(
      contactInfoAction.updateContactPresence(
        presenceArr,
        true,
        false,
        projectId,
        this.getUserId()
      )
    );
  }

  _onCurrentSubscribers(channel, data, userInfo) {
    let channelArray = channel.split("/");
    let projectId = channelArray[channelArray.length - 1];
    store.dispatch(
      contactInfoAction.updateContactPresence(data, false, false, projectId , this.getUserId())
    );
  }

  _onRoutingInteractionEnded(channel, data, userInfo, meta) {
    let interactionId = getQueryParam("connection_id");
    if (meta.interactionId === interactionId && userInfo.isVisitor) {
      let conversationId = getQueryParam("conversation_id");
      let conversation = store.getState().ConversationInfoReducer
        .conversationObject.conversationMap[conversationId];
      let visitorId = conversation ? conversation.visitorId : "";
      this.dispatchEndMessage(
        channel,
        visitorId,
        MESSAGE_TYPE.overflow_chat_disconnected,
        "VISITOR"
      );
    } else if (meta.interactionId === interactionId && userInfo.isAgent) {
      let agentId = userInfo.userId;
      this.dispatchEndMessage(
        channel,
        agentId,
        MESSAGE_TYPE.overflow_chat_closed_on_available,
        "AGENT"
      );
    }
  }

  _onRoutingInteractionTakenover(channel, data, userInfo, meta) {
    let interactionId = getQueryParam("connection_id");
    if (meta.interactionId === interactionId) {
      this.dispatchEndMessage(
        channel,
        userInfo.userId,
        MESSAGE_TYPE.overflow_chat_takenover_by_staff,
        "AGENT"
      );
    }
  }

  _onRoutingInteractionInterrupted(channel, data, userInfo, meta) {
    let interactionId = getQueryParam("connection_id");
    if (meta.interactionId === interactionId) {
      this.dispatchEndMessage(
        channel,
        userInfo.userId,
        MESSAGE_TYPE.overflow_chat_interrupted,
        "AGENT"
      );
    }
  }

  _onRoutingInteractionClosed(channel, data, userInfo, meta) {
    let interactionId = getQueryParam("connection_id");
    if (meta.interactionId === interactionId) {
      this.dispatchEndMessage(
        channel,
        userInfo.userId,
        MESSAGE_TYPE.overflow_chat_closed_by_staff,
        "AGENT"
      );
    }
  }

  _onServerAcknowledge(channel, data, userInfo, meta) {
    console.info("server-ack:", channel, data, userInfo, meta);

    store.dispatch(
      messageAction.ackMessage(data.id, { messageStatus: "CHAT_SENT" })
    );
  }

  notifyConnectionBack() {
    // store.dispatch( showVoicebox( { message : 'Network Connected' , dismissAfter : 3000}));
    let page = document.location.href;
    if (page.includes("chat/") || isOverflowConnection())
      store.dispatch(
        showVoicebox({
          message: "Network Connected",
          dismissAfter: 3000,
          showBelowHeader: !isOverflowConnection(),
          showFullScreenLoader: isOverflowConnection(),
        })
      );
    else if (
      page.includes("admin") ||
      page.includes("choose/profile") ||
      page.includes("embed/setup")
    )
      store.dispatch(
        showVoicebox({
          message: "Network Connected",
          dismissAfter: 3000,
          showFullScreenLoader: true,
        })
      );
    else
      store.dispatch(
        showVoicebox({ message: "Network Connected", dismissAfter: 3000 })
      );

    let offlineMessages = Object.assign(
      [],
      store.getState().MessageReducer.offlineMessages
    );
    offlineMessages.forEach(function (offlineMessage, index) {
      store.dispatch(messageAction.sendHttpMessage(offlineMessage));
    });

    offlineMessages.forEach(function (offlineMessage, index) {
      offlineMessage.data.key = offlineMessage.data.messageId;
      store.dispatch(messageAction.onMessage(offlineMessage.data));
    });
  }

  dispatchEndMessage(channel, senderId, type, messageFrom) {
    let messageId = generateMessageId();
    let conversationId = getQueryParam("conversation_id");
    let agent_id = getQueryParam("agent_id");
    let state = store.getState();
    let conversation =
      state.ConversationInfoReducer.conversationObject.conversationMap[
        conversationId
      ];
    let visitorId = conversation ? conversation.visitorId : "";
    let projectId = conversation ? conversation.projectId : "";
    let messageData = {
      actionTo: agent_id,
      channel: channel,
      conversationId: conversationId,
      createdDate: getServerTime(),
      emailTranscript: false,
      key: messageId,
      message: "",
      messageFrom: messageFrom,
      messageId: messageId,
      projectId: projectId,
      senderId: senderId,
      timestamp: getServerTime(),
      type: type,
      visitorId: visitorId,
    };
    store.dispatch(messageAction.onMessage(messageData));
    store.dispatch(messageAction.closeConnection());
    if (OVERFLOW_CHAT_DISCONNECTION_TYPES.includes(type))
      store.dispatch(conversationInfoAction.updateVisitorOfflinePresence(visitorId));
  }

  sendMessage(data, meta) {
    try {
      console.log("Sending", data);
      data.timestamp = getServerTime();
      let type = "chat";
      if (data.type == "server-fetchalluserstatus") {
        type = "server-fetchalluserstatus";
      } else if (data.type == MESSAGE_TYPE.chat_auto_assign_on_overflow) {
        type = "routing-interaction-accepted";
        meta = { ...meta, timestamp: data.timestamp };
      } else if (
        data.type == MESSAGE_TYPE.overflow_chat_closed_no_response ||
        data.type == MESSAGE_TYPE.overflow_prompt_closed_on_ignore || 
        data.type == "FULLSCREEN_CHAT_DISCONNECT"
      ) {
        type = "routing-interaction-ended";
        meta = { ...meta, timestamp: data.timestamp };
      } else if (data.type == MESSAGE_TYPE.post_user_prescence) {
        data = {
          type: data.type,
          channel: data.channel,
          status: {
            lastAssignedTime: data.lastAssignedTime,
            isTakingChat: data.isTakingChat,
            takingChatStatus: data.takingChatStatus,
            lastSignOffTime: data.lastSignOffTime,
            isRinging: data.isRinging,
            lastRingTime: data.lastRingTime,
            lastClosedTime: data.lastClosedTime,
            conversationIds: data.conversationIds,
            shouldRouteChats: data.shouldRouteChats,
            eventTime: data.serverTime,
          },
        };
      }
      if (data.type != MESSAGE_TYPE.post_user_prescence)
        data.interactionType = isAwConnection()
          ? "ANYWHEREWORKS"
          : isOverflowConnection()
          ? "OVERFLOW"
          : "DASH_BOARD";

      if (
        isAwConnection() &&
        store.getState().ChatConfigurationReducer.containerInfo.isOverflowChat
      )
        data = {
          ...data,
          ...store.getState().ChatConfigurationReducer.containerInfo,
        };

      if (store.getState().MessageReducer.isConnected)
        this.RTMServer.sendMessage(data.channel, type, data, meta);

      if (
        data.type != MESSAGE_TYPE.realtime_visitor_update &&
        data.type != MESSAGE_TYPE.post_user_prescence
      ) {
        console.log("Add message dispatch: ", data);
        data.createdDate = data.timestamp;
        store.dispatch(messageAction.addMessage(data));
      }
    } catch (e) {
      console.log("Message failed", e);
    }
  }

  handleRealtimeMessages(data) {
    switch (data.type) {
      case MESSAGE_TYPE.realtime_visitor_update: {
        let contactsMap = store.getState().ConversationInfoReducer
          .conversationObject.contactsMap;
        let peopleMap = store.getState().PeopleReducer.peopleMap;
        if (
          (data.contact && contactsMap[data.contact.key]) ||
          peopleMap[data.contact.key]
        ) {
          let contact = data.contact;
          if(contact.sourceUrl && !contact.sourceUrl_keyword)
            contact.sourceUrl_keyword = contact.sourceUrl;
          if(contact.currentUrl && !contact.currentUrl_keyword)
            contact.currentUrl_keyword = contact.currentUrl;
          if(contact.lastPageUrl && !contact.lastPageUrl_keyword)
            contact.lastPageUrl_keyword = contact.lastPageUrl
          store.dispatch(
            contactInfoAction.receiveUpdateContact({ contact: contact })
          );
          contact = Object.assign(contact, contact.customFieldsMap);
          store.dispatch(PeopleAction.updatePeopleEntity(contact));
        }
        break;
      }
      case MESSAGE_TYPE.realtime_staff_update: {
        let staff = data.staff;
        staff = setFullNameAndInitial(staff);
        store.dispatch(
          updateStaffRoleResponse({ ...staff, projectId: data.projectKey })
        );
        break;
      }
      case MESSAGE_TYPE.realtime_staff_delete: {
        let user = store.getState().UserReducer.data;
        if (user.id === data.staff.key) {
          store.dispatch(deleteProjectresponse(data.projectKey));
        } else {
          store.dispatch(deleteStaffResponse(data.staff.key, data.projectKey));
        }
        break;
      }
      case MESSAGE_TYPE.realtime_staff_added: {
        store.dispatch(requestNewlyAddedProjects(data.widgetIds));
        break;
      }
      case MESSAGE_TYPE.realTime_staff_resubscription: {
        console.info("Resubscribing user...");
        let subscriptionData = {
          widgetIds: data.widgetIds,
          userId: data.userId,
          socketId: store.getState().MessageReducer.socketId,
        };
        store.dispatch(messageAction.updateSubscription(subscriptionData));
        break;
      }
      case MESSAGE_TYPE.realtime_staff_removed: {
        store.dispatch(updateRemovedProjects(data.widgetIds));
        break;
      }
      case MESSAGE_TYPE.realtime_project_delete: {
        break;
      }
      case MESSAGE_TYPE.realtime_project_update: {
        store.dispatch(updateProjectResponse(data.project));
        break;
      }
    }
  }

  destroy() {
    //To destroy whole RTMServer with its connection object
    this.RTMServer.destroy();
  }

  closeConnection() {
    this.RTMServer.connection.disconnect();
  }

  reconnect() {
    console.log("On reconnect is called:");
    this.RTMServer.connection.reconnect();
  }

  showReconnectInterval(interval) {
    if (this.reconnectTimer != undefined) {
      clearInterval(this.reconnectTimer);
      this.reconnectTimer = undefined;
    }
    interval = interval / 1000;
    if (interval) {
      this.reconnectTimer = setInterval(
        function () {
          if (interval === 0) {
            clearInterval(this.reconnectTimer);
            store.dispatch(hideVoicebox());
            return;
          } else {
            console.log("Reconnecting in " + interval + " seconds...");
            let page = document.location.href;
            if (page.includes("chat/"))
              store.dispatch(
                showVoicebox({
                  message: "Reconnecting in " + interval + " seconds",
                  showBelowHeader: true,
                })
              );
            else if (
              page.includes("admin") ||
              page.includes("choose/profile") ||
              page.includes("embed/setup") ||
              page.includes("overflow")
            )
              store.dispatch(
                showVoicebox({
                  message: "Reconnecting in " + interval + " seconds",
                  showFullScreenLoader: true,
                })
              );
            else
              store.dispatch(
                showVoicebox({
                  message: "Reconnecting in " + interval + " seconds",
                })
              );
            // store.dispatch( showVoicebox( { message : 'Reconnecting in '+interval+' seconds'}));
            interval--;
          }
        }.bind(this),
        1000
      );
    }
  }

  // close the answering ring when accepting/sending to reception in mutiple tabs
  hideChatAnsweringRings(type, state, userInfo, projectId, data) {
    let incomingChat = Object.assign(
      {},
      state.ChatAnsweringReducer.incomingChatsQueue
    );
    let incomingChatsIds = Object.keys(incomingChat);

    for (let index in incomingChatsIds) {
      let conversationId = incomingChatsIds[index];
      let loggedInUserId = state.UserReducer.data.id;

      if (
        (type == "server-subscriberleft" &&
          incomingChat[conversationId].visitorId == userInfo.userId) ||
        ((type == "FORWARDING_MESSAGE" ||
          type == "CHAT_ACCEPTED" ||
          type == "CHAT_REJECTED") &&
          data.senderId == loggedInUserId)
      ) {
        if (type == "server-subscriberleft")
          store.dispatch(flushQueue(userInfo.userId));
        else if (
          type == "FORWARDING_MESSAGE" ||
          type == "CHAT_REJECTED" ||
          type == "CHAT_ACCEPTED"
        )
          store.dispatch(flushQueue(data.visitorId));
      }
    }
  }

  handleInvalidPresence(channel, data, userInfo) {
    console.log("The invalid presence data Called :" , data);
  }

  populateCurrentUrl(contact) {
    if (contact.currentUrl && !contact.currentUrl_keyword)
      contact.currentUrl_keyword = contact.currentUrl;
    return contact;
  }

  handleGuestMessages(guestInfo) {
    if (GUEST_EVENT_TYPE.GUEST_REMOVE === guestInfo.eventType)
      store.dispatch(removeVisitorToQueue(guestInfo.key));
    else {
      store.dispatch(
        addNewVisitorToQueue(guestInfo, GUEST_EVENT_TYPE.GUEST_ADDED === guestInfo.eventType)
      );
      if (GUEST_EVENT_TYPE.GUEST_ADDED === guestInfo.eventType) {
        let contact = Object.assign({},getValidPeoplesEntity(guestInfo));
        contact = this.populateCurrentUrl(contact);
        store.dispatch(
          contactInfoAction.receiveUpdateContact({
            contact,
          })
        );
        store.dispatch(
          PeopleAction.fetchPresenceByUserIds(
            [guestInfo.key],
            guestInfo.projectName,
            isOverflowConnection(),
            isAwConnection()
          )
        );
      }
    }
  }
  appenedMessagesValidationRealTimeSyncToReducer(
    messageIds,
    staffMap,
    ConversationInfoReducer
  ) {
    for (let msgs of messageIds) {
      let messageData = msgs.data;
      let conversationId =
        ConversationInfoReducer.conversationObject.conversationMap[
          messageData.conversationId
        ];
      if (conversationId) {
        let userId = this.getUserId();
        store.dispatch(
          updateOpenConversationCountRealtime(messageData, conversationId)
        );
        store.dispatch(updateUnassingedConversationCountRealtime(messageData));
        let isFullScreen = isFullScreenMode();
        store.dispatch(
          conversationInfoAction.updateConversationRealTime(
            messageData,
            userId,
            staffMap,
            isFullScreen
          )
        );
      }
    }
  }
}

export default new SocketConnection();
