import React from "react";
import {ChatsIcon , ChatsBoxIcon} from '../../commons/Icons'

const NoChatsLoader = (props) => {
    let {placeHolderText , widgetState} = props;

    const getPlaceHolderClass = () => {
        return (widgetState.isEmbedded) ? "placeholder-wrp no-chats" : "placeholder-wrp";
    }

    const getPlaceHolderContent = () => {
        return (widgetState.isEmbedded) ? placeHolderText : "No chats to display";
    }

    return (
        <section className={getPlaceHolderClass()}>
            <div class="placeholder-content empty-chat">
                <ChatsIcon />
                <label>{getPlaceHolderContent()}</label>
		        <p>Having trouble installing? Try these articles below or start a chat with us. We would be happy to help!</p>
                <a href="https://chatsupport.co/support/dashboard/chatsupport-widget-code" target="_blank" class="steps-links">
                    <ChatsBoxIcon />
                    <strong>How do you install the widget on your site?</strong>
                    <span>Read now &gt;</span>
                </a>
                <a href="https://chatsupport.co/support/dashboard/answering-inbound-chats" target="_blank" class="steps-links">
                    <ChatsBoxIcon />
                    <strong>How do you answer incoming chats?</strong>
                    <span>Read now &gt;</span>
                </a>
            </div>
        </section>
    )
}

export default NoChatsLoader;