import React, { Component } from "react";
import { Link } from "react-router";
import { getLogoUrl } from "../commons/Utility";

class ResetPassword extends Component {
  render() {
    console.info("props:", this.props);

    let {
      ResetPassword: { isInitializingReset, error },
    } = this.props.parentProps;
    let {
      ResetPassword: { isResetting, passwordError, generalErrorMessage },
    } = this.props.parentProps;
    let { initializeReset, onKeyUp, resetPassword, onResetKeyUp } = this.props;
    let { pageToDisplay } = this.props.parentState;

    return (
      <div className="loginWrp">
        <a href="javascript:void(0)" className="loginLogo"><img src={getLogoUrl(document.location.origin)}/></a>
        <section
          className="resetPwdHolder"
          style={{ display: pageToDisplay == "mailedOut" ? "block" : "none" }}
        >
          {/* <h2>Password reset sent</h2> */}
          <span>
            Please check your inbox. If an account exists, you will receive password reset instructions.
          </span>
          <p href="javascript:void(0)" className="dntAccount">
            <Link to={{ pathname: "/login" }} tabIndex="5">
              Back to Login
            </Link>
          </p>
        </section>
        <section
          className="ForgotHolder"
          style={{ display: pageToDisplay == "initialize" ? "block" : "none" }}
        >
          <p
            className="error-txt"
            style={{ display: generalErrorMessage ? "block" : "none" }}
          >
            The link got expired, please try again.
          </p>
          <h2>Forgot your password?</h2>
          <span>We'll email you instructions on how to reset it.</span>
          <fieldset>
            <div className={"inputGroup " + (error ? "inputError" : "")}>
              <input
                className="fs-exclude"
                spellCheck="false"
                type="text"
                ref="login"
                onKeyUp={onKeyUp}
                placeholder="Email"
                aria-label="Email"
              />
              <p style={{ display: error ? "block" : "none" }}>
                {error ? error : ""}
              </p>
            </div>
            <button
              style={{ opacity: isInitializingReset ? "0.5" : "1" }}
              disabled={isInitializingReset}
              className="btnLogin"
              onClick={initializeReset}
            >
              {isInitializingReset ? "Sending Email..." : "Send Email"}
            </button>
            <p className="dntAccount">
              Remembered it?{" "}
              <Link to={{ pathname: "/login" }} tabIndex="5">
                Login
              </Link>
            </p>
          </fieldset>
        </section>
        <section
          className="ForgotHolder"
          style={{ display: pageToDisplay == "reset" ? "block" : "none" }}
        >
          <h2>
            Reset your password<span>.</span>
          </h2>
          <span>Almost done! Just enter your new password below.</span>
          <fieldset>
            <div
              className={"inputGroup " + (passwordError ? "inputError" : "")}
            >
              <input
                className="fs-exclude"
                spellCheck="false"
                type="password"
                ref="password"
                onKeyUp={onResetKeyUp}
                placeholder="Password"
                aria-label="Password"
              />
              <p style={{ display: passwordError ? "block" : "none" }}>
                {passwordError ? passwordError : ""}
              </p>
            </div>
            <button
              style={{ opacity: isResetting ? "0.5" : "1" }}
              disabled={isResetting}
              className="btnLogin"
              onClick={resetPassword}
            >
              {isResetting ? "Resetting password" : "Reset password"}
            </button>
          </fieldset>
        </section>
      </div>
    );
  }
}

export default ResetPassword;
