import { useEffect, useState } from 'react'
import { useStore } from 'react-redux'
import { IAppState } from 'src/utils/state/store'

export const useEffectSelector = <TValue extends unknown>(
  selector: (state: IAppState) => any
): TValue | undefined => {
  const [value, setValue] = useState<TValue>()
  const store = useStore()

  useEffect(() => {
    const appState = store.getState()

    setValue(selector(appState))
  }, [])

  return value
}
