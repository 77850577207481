import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  notificationSettingResponse,
  notificationSettingFailed,
  updateNotificationSettingResponse,
  updateNotificationSettingFailed,
} from "../actions/NotificationSettingsAction";
import { addNotification } from "../actions/NotificationAction";
import { showVoicebox } from "../actions/VoiceboxAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* fetchNotificationSettings() {
  const url = "/notification/get";

  try {
    let notificationSettings = yield call(Ajax.get, url);
    notificationSettings = notificationSettings.data;
    yield put(notificationSettingResponse(notificationSettings));
  } catch (e) {
    console.error(e);
    yield put(notificationSettingFailed());
    yield put(handleGlobalError(e));
  }
}

export function* updateNotificationSettings(notificationSettings) {
  const url = "/notification/update";
  console.log(
    "Notification settings:",
    notificationSettings.notificationSettings
  );
  try {
    let notificationResponse = yield call(
      Ajax.put,
      url,
      notificationSettings.notificationSettings
    );
    notificationResponse = notificationResponse.data;
    yield put(
      showVoicebox({ message: "Update Successful", dismissAfter: 3000 })
    );
    yield put(updateNotificationSettingResponse(notificationResponse));
  } catch (e) {
    console.error(e);
    yield put(updateNotificationSettingFailed());
    yield put(handleGlobalError(e));
  }
}
