import React, { FC } from 'react'

export const Google: FC<any> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M15.6835 8.18411C15.6835 7.64031 15.6394 7.09357 15.5453 6.55859H7.99976V9.63914H12.3208C12.1414 10.6327 11.5653 11.5116 10.7217 12.0701V14.0689H13.2996C14.8134 12.6756 15.6835 10.618 15.6835 8.18411Z"
      fill="#4285F4"
    />
    <path
      d="M7.99983 16C10.1574 16 11.9769 15.2916 13.3026 14.0688L10.7247 12.07C10.0075 12.5579 9.08155 12.8342 8.00277 12.8342C5.91576 12.8342 4.1462 11.4262 3.51128 9.5332H0.851074V11.5938C2.2091 14.2951 4.97513 16 7.99983 16Z"
      fill="#34A853"
    />
    <path
      d="M3.50824 9.53314C3.17314 8.5396 3.17314 7.46376 3.50824 6.47023V4.40967H0.850973C-0.283658 6.67011 -0.283658 9.33326 0.850973 11.5937L3.50824 9.53314Z"
      fill="#FBBC04"
    />
    <path
      d="M7.99983 3.16633C9.14034 3.1487 10.2426 3.57786 11.0686 4.36563L13.3526 2.08167C11.9064 0.723645 9.9869 -0.0229766 7.99983 0.000539092C4.97513 0.000539092 2.2091 1.70542 0.851074 4.40972L3.50834 6.47028C4.14033 4.57433 5.91282 3.16633 7.99983 3.16633Z"
      fill="#EA4335"
    />
  </svg>
)
