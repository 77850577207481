import React, { Component } from "react";
import ReactDOM from "react-dom";
class GuestsComponent extends Component {
  componentDidUpdate() {}
  render() {
    return (
      <section class="cs-conversation">
        <header>
          <figure class="cs-chat-group"></figure>
          <label>Visitors</label>
          <p>3 Guests online</p>
        </header>
        <section class="cs-proactive">
          <header>
            <label>Filter by Source:</label>
            <div class="cs-dropdown">
              <p>All Widgets</p>
            </div>
          </header>
          <div class="cs-proactive-list-head">
            <label>Name</label>
            <label>Source</label>
            <label>Current Page</label>
            <label>Visotor Status</label>
            <label>Number of visits</label>
            <label>Actions</label>
          </div>
          <ul class="cs-proactive-list">
            <li>
              <span>Visitor 172</span>
              <span>
                <cite></cite>Answerconnect
              </span>
              <span>https://www.answerconnect.com/plans</span>
              <span>Active</span>
              <span>1</span>
              <span>Start chat</span>
            </li>
            <li>
              <span>Lu Zhou</span>
              <span>
                <cite></cite>AnswerForce
              </span>
              <span>https://www.answerforce.com/support</span>
              <span>Active</span>
              <span>34</span>
              <span>Start chat</span>
            </li>
            <li>
              <span>Visitor 105</span>
              <span>
                <cite></cite>LexReception
              </span>
              <span>https://www.lexreception.com</span>
              <span>Inactive</span>
              <span>4</span>
              <span>Start chat</span>
            </li>
          </ul>
        </section>
      </section>
    );
  }
}
export default GuestsComponent;
