import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestCustomFields } from "../../actions/CustomFieldsAction";

const useCustomFields = (projectId) => {
  const customFields = useSelector((state) => state.CustomFieldsReducer) || {};
  const { isFetched, isFetching, projectId: previousProjectId } = customFields;
  const { customFieldsMap } = customFields;
  const dispatch = useDispatch();

  if ((!isFetching && !isFetched) || projectId != previousProjectId)
    dispatch(requestCustomFields(projectId));

  return { customFields: customFieldsMap, isFetched: isFetched };
};

export default useCustomFields;
