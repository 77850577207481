export const PEOPLE_LIST_REQUEST = "PEOPLE_LIST_REQUEST";
export const PEOPLE_NEW_LIST_RESPONSE = "PEOPLE_LIST_RESPONSE";
export const PEOPLE_LIST_FAILED = "PEOPLE_LIST_FAILED";
export const PEOPLE_SCROLL_RESPONSE = "PEOPLE_SCROLL_RESPONSE";
export const REQUEST_PEOPLE_LIST_BY_SCROLL = "REQUEST_PEOPLE_LIST_BY_SCROLL";
export const UPDATE_PEOPLE_ENTITY = "UPDATE_PEOPLE_ENTITY";
export const CREATE_PEOPLE_GROUP_REQUEST = "CREATE_PEOPLE_GROUP_REQUEST";
export const CREATE_PEOPLE_GROUP_RESPONSE = "CREATE_PEOPLE_GROUP_RESPONSE";
export const GET_PEOPLE_GROUPS_REQUEST = "GET_PEOPLE_GROUPS_REQUEST";
export const GET_PEOPLE_COUNT_REQUEST = "GET_PEOPLE_COUNT_REQUEST";
export const GET_PEOPLE_COUNT_RESPONSE = "GET_PEOPLE_COUNT_RESPONSE";
export const GET_PEOPLE_GROUP_COUNT_REQUEST = "GET_PEOPLE_GROUP_COUNT_REQUEST";
export const GET_PEOPLE_GROUP_COUNT_RESPONSE =
  "GET_PEOPLE_GROUP_COUNT_RESPONSE";
export const GET_PEOPLE_GROUPS_RESPONSE = "GET_PEOPLE_GROUPS_RESPONSE";
export const UPDATE_PEOPLE_GROUP_REQUEST = "UPDATE_PEOPLE_GROUP_REQUEST";
export const UPDATE_PEOPLE_GROUP_RESPONSE = "UPDATE_PEOPLE_GROUP_RESPONSE";
export const FLUSH_PEOPLE_ENTITY = "FLUSH_PEOPLE_ENTITY";
export const REMOVE_PEOPLE_ENTITY = "REMOVE_PEOPLE_ENTITY";
export const UPDATE_GROUP_SETTINGS_REQUEST = "UPDATE_GROUP_SETTINGS_REQUEST";
export const UPDATE_GROUP_SETTINGS_RESPONSE = "UPDATE_GROUP_SETTINGS_RESPONSE";
export const UPDATE_FIELD_SETTINGS_REQUEST = "UPDATE_FIELD_SETTINGS_REQUEST";
export const UPDATE_FIELD_SETTINGS_RESPONSE = "UPDATE_FIELD_SETTINGS_RESPONSE";
export const EXPORT_CONTACT_REQUEST = "EXPORT_CONTACT_REQUEST";
export const EXPORT_CONTACT_RESPONSE = "EXPORT_CONTACT_RESPONSE";
export const SEND_BROADCAST_MESSAGE_REQUEST = "SEND_BROADCAST_MESSAGE_REQUEST";
export const SEND_BROADCAST_MESSAGE_RESPONSE =
  "SEND_BROADCAST_MESSAGE_RESPONSE";
export const SEND_BROADCAST_MESSAGE_REQUEST_FAILED =
  "SEND_BROADCAST_MESSAGE_REQUEST_FAILED";
export const SEND_BROADCAST_EMAIL_REQUEST = "SEND_BROADCAST_EMAIL_REQUEST";
export const SEND_BROADCAST_EMAIL_RESPONSE = "SEND_BROADCAST_EMAIL_RESPONSE";
export const SEND_TEST_EMAIL_REQUEST = "SEND_TEST_EMAIL_REQUEST";
export const SEND_TEST_EMAIL_RESPONSE = "SEND_TEST_EMAIL_RESPONSE";
export const RESET_NAVIGATION_SETTINGS = "RESET_NAVIGATION_SETTINGS";

export const SEND_BROADCAST_EMAIL_REQUEST_FAILED =
  "SEND_BROADCAST_EMAIL_REQUEST_FAILED";
export const IMPORT_CONTACT_REQUEST = "IMPORT_CONTACT_REQUEST";
export const IMPORT_CONTACT_RESPONSE = "IMPORT_CONTACT_RESPONSE";
export const FETCH_PRESENCE_BY_USERIDS = "FETCH_PRESENCE_BY_USERIDS";
export const FETCH_AND_UDPATE_USER_PRESENCE = "FETCH_AND_UDPATE_USER_PRESENCE";
export const DELETE_PEOPLE_GROUP_REQUEST = "DELETE_PEOPLE_GROUP_REQUEST";
export const DELETE_PEOPLE_GROUP_RESPONSE = "DELETE_PEOPLE_GROUP_RESPONSE";
export const UPDATE_IS_LAST_BATCH = "UPDATE_IS_LAST_BATCH";
export const RESET_IS_FETCHED = "RESET_IS_FETCHED";
export const UPDATE_IP_ACTIVITY = "UPDATE_IP_ACTIVITY";
export const UPDATE_IP_ACTIVITY_RESPONSE = "UPDATE_IP_ACTIVITY_RESPONSE";
export const GET_IP_STATUS_REQUEST = "GET_IP_STATUS_REQUEST";
export const GET_IP_STATUS_RESPONSE = "GET_IP_STATUS_RESPONSE";
export const GET_IP_STATUS_FAILED = "GET_IP_STATUS_FAILED";
export const GET_MATCHING_SEGMENTS_REQUEST = "GET_MATCHING_SEGMENTS_REQUEST";
export const GET_MATCHING_SEGMENTS_RESPONSE = "GET_MATCHING_SEGMENTS_RESPONSE";
export const GET_MATCHING_SEGMENTS_FAILED = "GET_MATCHING_SEGMENTS_FAILED";
export const ADD_CARD_VISITOR_ID = "ADD_CARD_VISITOR_ID";
export const ADD_CARD_POSITION = "ADD_CARD_POSITION";
export const TOGGLE_IMPORT_CONTACT_POPUP = "TOGGLE_IMPORT_CONTACT_POPUP";
export const BROADCAST_EVENT_STATS_REQUEST = "BROADCAST_EVENT_STATS_REQUEST";
export const BROADCAST_EVENT_STATS_RESPONSE = "BROADCAST_EVENT_STATS_RESPONSE";
export const BROADCAST_EVENT_STATS_SCROLL = "BROADCAST_EVENT_STATS_SCROLL";
export const BROADCAST_EVENT_STATS_RESET = "BROADCAST_EVENT_STATS_RESET";

export const peopleNewListResponse = (peopleResponse) => ({
  type: PEOPLE_NEW_LIST_RESPONSE,
  peopleResponse,
});

export const broadcastEventStatsRequest = (dataResponse) => ({
  type: BROADCAST_EVENT_STATS_REQUEST,
   dataResponse,
})

export const broadcastEventStatsResponse = (dataRes) =>({
  type: BROADCAST_EVENT_STATS_RESPONSE,
  dataRes,
})

export const broadcastEventStatsScroll = (dataRes) => ({
  type: BROADCAST_EVENT_STATS_SCROLL,
  dataRes,
})

export const broadcastEventStatsReset = (dataRes) => ({
  type: BROADCAST_EVENT_STATS_RESET,
  dataRes,
})

export const peopleScrollResponse = (peopleResponse) => ({
  type: PEOPLE_SCROLL_RESPONSE,
  peopleResponse,
});

export const peopleListFailed = (peopleObject) => ({
  type: PEOPLE_LIST_FAILED,
  peopleObject,
});

export const getPeopleByScroll = (scrollReq) => ({
  type: REQUEST_PEOPLE_LIST_BY_SCROLL,
  scrollReq,
});

export const updatePeopleEntity = (peopleEntity) => ({
  type: UPDATE_PEOPLE_ENTITY,
  peopleEntity,
});

export const createPeopleGroupRequest = (groupInfo) => ({
  type: CREATE_PEOPLE_GROUP_REQUEST,
  groupInfo,
});

export const updatePeopleGroupRequest = (existingGroupInfo) => ({
  type: UPDATE_PEOPLE_GROUP_REQUEST,
  existingGroupInfo,
});

export const getPeopleGroupsRequest = (projectId) => ({
  type: GET_PEOPLE_GROUPS_REQUEST,
  projectId,
});

export const getPeopleGroupsResponse = (peopleGroupsMap) => ({
  type: GET_PEOPLE_GROUPS_RESPONSE,
  peopleGroupsMap,
});

export const createPeopleGroupResponse = (groupInfoResponse) => ({
  type: CREATE_PEOPLE_GROUP_RESPONSE,
  groupInfoResponse,
});

export const updatePeopleGroupResponse = (groupInfoResponse) => ({
  type: UPDATE_PEOPLE_GROUP_RESPONSE,
  groupInfoResponse,
});

export const getMatchingSegmentsRequest = (visitorId, projectId) => ({
  type : GET_MATCHING_SEGMENTS_REQUEST,
  visitorId,
  projectId
})

export const getMatchingSegmentsResponse = (matchingGroupsByVisitorId) => ({
  type : GET_MATCHING_SEGMENTS_RESPONSE,
  matchingGroupsByVisitorId
})

export const getMatchingSegmentsFailed = () => ({
  type : GET_MATCHING_SEGMENTS_FAILED  
})

export const removePeopleEntity = (visitorIds, currentGroupId) => ({
  type: REMOVE_PEOPLE_ENTITY,
  visitorIds,
  currentGroupId,
});

export const getPeopleCountRequest = (countRequestPayload) => ({
  type: GET_PEOPLE_COUNT_REQUEST,
  peopleGroupsList: countRequestPayload.peopleGroupsList,
  projectId: countRequestPayload.projectId,
});

export const getPeopleGroupCountRequest = (groupsSubList, projectId, isFetched) => ({
  type: GET_PEOPLE_GROUP_COUNT_REQUEST,
  groupsSubList,
  projectId,
  isFetched,
});

export const getPeopleGroupCountResponse = (peopleCountInfo , isFetched) => ({
  type: GET_PEOPLE_GROUP_COUNT_RESPONSE,
  peopleCountInfo,
  isFetched
});

export const updateIsLastBatch = (isFetched) => ({
  type: UPDATE_IS_LAST_BATCH,
  isFetched,
})

export const resetIsFetched = () => ({
  type: RESET_IS_FETCHED,
})

export const getPeopleCountResponse = (peopleGroupInfo) => ({
  type: GET_PEOPLE_COUNT_RESPONSE,
  peopleGroupInfo,
});

export const flushPeopleEntity = (currentGroupId) => ({
  type: FLUSH_PEOPLE_ENTITY,
  currentGroupId,
});

export const updateGroupSettingsRequest = (groupSettings) => ({
  type: UPDATE_GROUP_SETTINGS_REQUEST,
  groupSettings,
});

export const updateGroupSettingsResponse = (groupSettingsResponse) => ({
  type: UPDATE_GROUP_SETTINGS_RESPONSE,
  hiddenGroupIds: groupSettingsResponse.hiddenGroupIds,
});

export const updateFieldSettingsRequest = (fieldSettings) => ({
  type: UPDATE_FIELD_SETTINGS_REQUEST,
  fieldSettings,
});

export const updateFieldSettingsResponse = (fieldSettingsResponse) => ({
  type: UPDATE_FIELD_SETTINGS_RESPONSE,
  visibleFields: fieldSettingsResponse.visibleFields,
});

export const exportContactRequest = (esQueryInfo) => ({
  type: EXPORT_CONTACT_REQUEST,
  esQueryInfo,
});

export const exportContactResponse = (exportContactResponse) => ({
  type: EXPORT_CONTACT_RESPONSE,
  exportContactResponse,
});

export const toggleImportPopUp = (projectId,contacts,showPopUp) => ({
  type: TOGGLE_IMPORT_CONTACT_POPUP,
  projectId,
  contacts,
  showPopUp
});

export const sendBroadcastMessage = (sendBroadcastMessageData, projectId) => ({
  type: SEND_BROADCAST_MESSAGE_REQUEST,
  sendBroadcastMessageData,
  projectId,
});

export const sendBroadcastMessageFailed = () => ({
  type: SEND_BROADCAST_MESSAGE_REQUEST_FAILED,
});

export const sendBroadcastEmail = (sendBroadcastMessageData, projectId) => ({
  type: SEND_BROADCAST_EMAIL_REQUEST,
  sendBroadcastMessageData,
  projectId,
});

export const sendBroadcastEmailFailed = () => ({
  type: SEND_BROADCAST_EMAIL_REQUEST_FAILED,
});

export const importContactRequest = (contacts, projectId) => ({
  type: IMPORT_CONTACT_REQUEST,
  contacts,
  projectId,
});

export const importContactResponse = (importContactsResponse) => ({
  type: IMPORT_CONTACT_RESPONSE,
  importContactsResponse,
});

export const fetchPresenceByUserIds = (
  userIds,
  projectId,
  isFromOverflow,
  isAwWindow
) => ({
  type: FETCH_PRESENCE_BY_USERIDS,
  isFromOverflow,
  userIds,
  projectId,
  isAwWindow,
});

export const fetchAndUpdateUserPresence = (
  userId,
  takingChatStatus,
  isTakingChat,
  projectId
) => ({
  type: FETCH_AND_UDPATE_USER_PRESENCE,
  userId,
  takingChatStatus,
  isTakingChat,
  projectId,
});

export const sendTestEmailRequest = (sendMessageData, projectId) => ({
  type: SEND_TEST_EMAIL_REQUEST,
  sendMessageData,
  projectId,
});

export const deleteGroupRequest = (groupId, projectId) => ({
  type: DELETE_PEOPLE_GROUP_REQUEST,
  groupId,
  projectId,
});

export const deleteGroupResponse = (groupId) => ({
  type: DELETE_PEOPLE_GROUP_RESPONSE,
  groupId
});

export const updateIPActivity = (isBlocked, ip, projectId) => ({
  type: UPDATE_IP_ACTIVITY,
  isBlocked,
  ip,
  projectId,
});

export const updateIPActivityResponse = (ipStatus) => ({
  type: UPDATE_IP_ACTIVITY_RESPONSE,
  ipStatus
});

export const getIPActivity = (projectId) => ({
  type: GET_IP_STATUS_REQUEST,
  projectId
});

export const getIPActivityResponse = (projectId, ipStatus) => ({
  type: GET_IP_STATUS_RESPONSE,
  projectId,
  ipStatus
});

export const addCardVisitorId = (visitorId) => ({
  type : ADD_CARD_VISITOR_ID,
  visitorId
})

export const addCardPosition = (cardPosition) => ({
  type : ADD_CARD_POSITION,
  cardPosition
})
