import { UnControlled as CodeMirror } from "react-codemirror2";
import React from "react";

require("codemirror/addon/display/autorefresh");
require("codemirror/mode/htmlmixed/htmlmixed");

const CodeEditor = (props) => {
  function codeEditor() {
    return <CodeMirror {...props} />;
  }

  return <div className="cs-codeEditor">{codeEditor()}</div>;
};

export default CodeEditor;
