import React, { Component, Fragment } from "react";
import { SEARCH_LABEL, TAG_NAME_SEARCH } from "../../../commons/Constants";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import { TagSearchIcon } from "../../../commons/Icons";
import { getActiveProjectId } from '../../../commons/Utility.js';

class TagNameSearch extends Component {
  constructor() {
    super();
  }

  renderTags() {
    let recentSearchHistory = [];
    recentSearchHistory.push(
      <Fragment>
        <TagSearchIcon className={'filter-tag-icon translateY-1'}/>
        <strong>{SEARCH_LABEL.TAG}</strong>
      </Fragment>
    );
    const inputField = document.querySelector(
      ".cs-my-conversations-filter input"
    );
    inputField.focus();
    inputField.placeholder = "";
    this.props.recentSearchHistory(recentSearchHistory, TAG_NAME_SEARCH);
    Event(getActiveProjectId(), "Search", "Tag Search", "Tag search clicked by the user");
  }

  render() {
    return (
      <li id="tag-name-search" onClick={this.renderTags.bind(this)} onKeyDown={ () => {} }>
        <TagSearchIcon className={'translateY-1'}/>
        Tags
      </li>
    );
  }
}

export default TagNameSearch;
