import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  userResponse,
  authFailed,
  updateUserResponse,
  resetPasswordResponse,
  updateTimeDifference,
  updateLocationDetails,
  updateSelectedProjectKey,
  requestUploadImage,
  updateUserRequested,
} from "../actions/UserAction";
import * as staffContactAction from "../components/myteam/Action/StaffContactAction";
import * as UserProjectMapping from "../components/myteam/Action/UserProjectMappingAction";
import { addNotification } from "../actions/NotificationAction";
import { updateStaffWhenUserUpdate } from "../actions/StaffAction";
import { showVoicebox } from "../actions/VoiceboxAction";
import { NOTIFICATION_LEVEL, UPGRADE_PLAN, ANSWERING_MEDIUMS, ANSWERING_MEDIUMS_HEADER } from "../commons/Constants.js";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { getProjectKey, isAdminUserObj, getIsAWRecentComponent } from "../commons/Utility";

export function* fetchUser() {
  let url = "user/me/";
  let headers = {};
  if (getIsAWRecentComponent()) {
    headers[ANSWERING_MEDIUMS_HEADER] = ANSWERING_MEDIUMS.ANYWHERE;
  } else {
    headers[ANSWERING_MEDIUMS_HEADER] = ANSWERING_MEDIUMS.DASHBOARD; 
  }
  try {
    if(localStorage.getItem(UPGRADE_PLAN))
    {
      url = url+ "?planId="+localStorage.getItem(UPGRADE_PLAN);
      localStorage.removeItem(UPGRADE_PLAN);
    }
    let userObj = yield call(Ajax.get, url, null, null, headers);
    userObj = userObj?.data;
    if (userObj) {
      let timeDifference = userObj?.serverTime
      ? new Date().getTime() - parseInt(userObj.serverTime)
      : 0;
      let locationDetails = userObj?.locationDetails ?? {};
      delete userObj?.serverTime;
      delete userObj.locationDetails;
      yield put(userResponse(userObj));
      yield put(updateLocationDetails(locationDetails));
      if(window.opener) {
        let loginData = userObj.login;
        let accountId = userObj.accountID;
        window.opener.postMessage(JSON.stringify({success : true , accoutnId : accountId ,email : loginData}), "*");
      } else {
        console.log("window.opener not available");
      }
      if (isAdminUserObj(userObj))
        yield put(
          updateSelectedProjectKey(getProjectKey(userObj.defaultProject))
        );
      yield put(updateTimeDifference(timeDifference));
    }
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* updateUser(user) {
  const url = "/user/update";
  try {
    let userObj = yield call(Ajax.put, url, user.user);
    userObj = userObj.data;

    if (user.showVoicebox == true)
      yield put(
        showVoicebox({ message: "Update Successful", dismissAfter: 3000 })
      );
    yield put(updateUserResponse(userObj));
    if (isAdminUserObj(userObj))
      yield put(
        updateSelectedProjectKey(getProjectKey(userObj.defaultProject))
      );
    yield put(updateStaffWhenUserUpdate(userObj));
  } catch (e) {
    if (user.showVoicebox == true)
      yield put(
        showVoicebox({
          message: "User Updation Failed",
          dismissAfter: 3000,
          showError: true,
        })
      );
    // yield put( addNotification( { message : 'User Updation Failed' , level : NOTIFICATION_LEVEL.Error , dismissAfter : 3000 } ) );
    yield put(handleGlobalError(e));
  }
}

export function* resetPassword(user) {
  const url = "/user/password/update";

  try {
    let userObj = yield call(Ajax.post, url, user.user);

    if (userObj.success) {
      yield put(
        showVoicebox({
          message: "Updating Password SuccessFull ...",
          dismissAfter: 3000,
        })
      );
      yield put(resetPasswordResponse(userObj));
    } else {
      if (userObj.error === "Old Password doesn't match") {
        yield put(showVoicebox({ message: userObj.error, dismissAfter: 3000 }));
      } else {
        yield put(
          showVoicebox({
            message: "Updating Password Failed ...",
            dismissAfter: 3000,
            showError: true,
          })
        );
      }
    }
  } catch (e) {
    yield put(
      addNotification({
        message: "User Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}

export function* switchCustomerAccount(action) {
  const url = "/user/switch/" + action.customerLogin;
  try {
    yield put(
      showVoicebox({
        message: "Switching account",
        showFullScreenLoader: true,
        dismissAfter: 3000,
      })
    );
    let userObj = yield call(Ajax.get, url);
    if (action.projectId && action.hasOwnProperty("redirectPage"))
      window.location.href =
        window.location.origin +
        "/app/" +
        action.projectId +
        action.redirectPage;
    else if (action.projectId)
      window.location.href =
        window.location.origin + "/app/" + action.projectId + "/settings";
  } catch (error) {
    if (error && error.response && error.response.status == 400) {
      yield put(
        showVoicebox({
          message: "Invalid user",
          dismissAfter: 3000,
          showError: true,
        })
      );
    } else {
      yield put(handleGlobalError(error));
    }
  }
}

export function* switchToOwnAccount(action) {
  const url = "/user/myaccount";
  try {
    let userObj = yield call(Ajax.get, url);
    yield put(
      showVoicebox({
        message: "Switching account",
        showFullScreenLoader: true,
        dismissAfter: 3000,
      })
    );
    if (action.redirectPage) window.location.href = action.redirectPage;
    else window.location.href = window.location.origin + "/login";
  } catch (e) {
    yield put(
      addNotification({
        message: "User switch Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}

export function* fetchStaffContactById(action) {
  const url = "/user/contact/get/" + action.staffid;

  try {
    let userObj = {};
    let contactMap = {};
    userObj = yield call(Ajax.get, url);
    userObj = userObj.data.contact;
    contactMap[userObj["id"]] = userObj;

    yield put(staffContactAction.staffContactResponse(contactMap));
  } catch (e) {
    yield put(
      addNotification({
        message: "Staff Switch Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}

export function* fetchUserProjectMapping(action) {
  const url = "/user/userProject/get/" + action.staffid;

  try {
    let responseData = {};
    responseData = yield call(Ajax.get, url);
    if(responseData.data) {
      let projectEntities = responseData.data.projectEntities;
      let projectMap = {};
  
      if (projectEntities)
        for (var i = 0; i < projectEntities.length; i++) {
          projectMap[projectEntities[i]["key"]] = projectEntities[i];
        }
      responseData["projectMap"] = projectMap;
  
      let chatConfigurationEntities = responseData.data.chatConfigurationEntities;
      let chatConfigMap = {};
  
      if (chatConfigurationEntities)
        for (var i = 0; i < chatConfigurationEntities.length; i++) {
          chatConfigMap[chatConfigurationEntities[i]["key"]] =
            chatConfigurationEntities[i];
        }
      responseData["chatConfigMap"] = chatConfigMap;
  
      responseData["userProjectMapping"] = responseData.data.userProjectMapping;
      responseData.staffId = action.staffid;
  
      yield put(UserProjectMapping.userProjectResponse(responseData));
    }
  } catch (e) {
    yield put(
      addNotification({
        message: "Project Fetching Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}

export function* updateAccountStaff(action) {
  const url = "/user/update/" + action.user.id;

  try {
    let userObj = yield call(Ajax.put, url, action.user);
    userObj = userObj.data;

    if (action.showVoicebox == true)
      yield put(
        showVoicebox({ message: "Update Successful", dismissAfter: 3000 })
      );

    yield put(UserProjectMapping.updateUserResponse(userObj));
  } catch (e) {
    if (user.showVoicebox == true)
      yield put(
        addNotification({
          message: "User Updation Failed",
          level: NOTIFICATION_LEVEL.Error,
          dismissAfter: 3000,
        })
      );
    yield put(handleGlobalError(e));
  }
}

export function* getUploadURL(data) {
  const url = "/user/image/get/uploadUrl";
  let requestData = {};
  try {
    let response;
    requestData.title = data.fileDetails.title;
    requestData.contactID = data.fileDetails.contactID;
    response = yield call(Ajax.post, url, requestData);
    console.log("Resoponse JSON String " + JSON.stringify(response));
    if (response.data.success) {
      requestData.formData = data.fileDetails.formData;
      requestData.operation = data.fileDetails.operation;
      requestData.upload_url = response.data.upload_url;
      yield put(requestUploadImage(requestData, data.userDetails));
    }
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* updateImageUrl(data) {
  let formData = data.fileDetails.formData;
  let uploadData = new FormData();
  uploadData.append("file", formData.get("file"));
  let responseData = {};
  let url = data.fileDetails.upload_url;
  try {
    responseData = yield call(Ajax.postDataToFullStorage, url, uploadData);
    if (responseData.ok) {
      let requestData = {};
      let files = responseData.data.files;
      let file = files[0];
      let rawUrl = file.raw_url;
      requestData = data.userDetails;
      requestData.contactID = data.fileDetails.contactID;
      requestData.operation = data.fileDetails.operation;
      requestData.profile_pic_url = rawUrl;
      yield put(updateUserRequested(requestData, true));
    }
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* updateConnectConfiguration(data) {
  const url = "/project/staff/connect/config";
  try {
    let response = yield call(Ajax.put, url, data.connectConfigurationDetails);
    let success = response.response;
    if (success) {
      yield put(UserProjectMapping.updateConnectConfigRespone(response.data));
      yield put(
        showVoicebox({ message: "Update Successful", dismissAfter: 3000 })
      );
    }
  } catch (e) {
    yield put(
      showVoicebox({ message: "Update Unsuccessful", dismissAfter: 3000 })
    );
    yield put(handleGlobalError(e))
  }
}

export function* getConnectEnabledProjects() {
  const url = "/project/staff/get/connect/projects";
  try {
    let response = yield call(Ajax.get, url);
    let success = response.response;
    if (success)
      yield put(UserProjectMapping.connectEnabledProjectsResponse(response.data));
  } catch (e) {
    yield put(handleGlobalError(e))
  }
}