export const ADD_NEW_VISITOR_TO_QUEUE = "ADD_NEW_VISITOR_TO_QUEUE";
export const REMOVE_VISITOR_FROM_QUEUE = "REMOVE_VISITOR_FROM_QUEUE";
export const UPDATE_VISITOR_IN_QUEUE = "UPDATE_VISITOR_IN_QUEUE";
export const VISITOR_AWAY = "VISITOR_AWAY";
export const VISITOR_ONLINE = "VISITOR_ONLINE";
export const FETCH_VISITOR_QUEUE = "FETCH_VISITOR_QUEUE";
export const FETCH_VISITOR_QUEUE_RESPONSE = "FETCH_VISITOR_QUEUE_RESPONSE";
export const ENABLE_TAB_SWITCHING = "ENABLE_TAB_SWITCHING";
export const ENABLE_CONVERSATION_TAB_SWITCHING = "ENABLE_CONVERSATION_TAB_SWITCHING";
export const DISABLE_CONVERSATION_TAB_SWITCHING = "DISABLE_CONVERSATION_TAB_SWITCHING";
export const FIRST_TIME_LANDING = "FIRST_TIME_LANDING";

export const addNewVisitorToQueue = (guestInfo, isNewVisitor) => ({
  type: ADD_NEW_VISITOR_TO_QUEUE,
  guestInfo,
  isNewVisitor,
});

export const removeVisitorToQueue = (guestId) => ({
  type: REMOVE_VISITOR_FROM_QUEUE,
  guestId,
});

export const updateVisitorToQueue = (guestInfo) => ({
  type: UPDATE_VISITOR_IN_QUEUE,
  guestInfo,
});

export const updateVisitorAway = (guestInfo) => ({
  type: VISITOR_AWAY,
  guestInfo,
});

export const updateVisitorOnline = (guestInfo) => ({
  type: VISITOR_ONLINE,
  guestInfo,
});

export const fetchVisitorQueue = (payload) => ({
  type: FETCH_VISITOR_QUEUE,
  payload,
});

export const fetchVisitorQueueResponse = (
  peopleList,
  countResponse,
  projectColorMap
) => ({
  type: FETCH_VISITOR_QUEUE_RESPONSE,
  peopleList,
  countResponse,
  projectColorMap,
});

export const enableTabSwitching = ()=>({
  type:ENABLE_TAB_SWITCHING,
})

export const firstTimeLanding = () => ({
  type:FIRST_TIME_LANDING,
})

export const enableConversationTabSwitching = ()=> ({
  type: ENABLE_CONVERSATION_TAB_SWITCHING,
})

export const disableConversationTabSwitching = () => ({
  type: DISABLE_CONVERSATION_TAB_SWITCHING,
})