import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DisableIcon, ErrorIcon, RemoveFieldIcon } from "../../commons/Icons";

const ProfileTextArea = (props) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleFocus = () => {
    setIsFocused((focus) => !focus);
  };

  const handleInput = (e) => {
    let text = e?.target?.value;
    setInputValue(text);
    let errorText = props.errorHandler(text);
    setErrorMessage(errorText);
    props.handleChange(e, errorText);
  };

  // Memoized variables to optimize the component rendering.
  const getClassName = useMemo(() => {
    let className = "cs-input-field";
    if (props.isDisabled) {
      className += " cs-disabled-field";
    }
    if (errorMessage) {
      className += " cs-error-field";
    }
    if (isFocused) {
      className += " cs-active-field";
    }
    return className;
  }, [errorMessage, isFocused]);

  const DisabledIconMemoized = useMemo(() => {
    if (props?.isDisabled) {
      return <DisableIcon />;
    } else {
      return <span />;
    }
  }, [props.isDisabled]);

  const RemoveFieldIconMemoized = useMemo(() => {
    if (props?.isRemovable)
      return (
        <a onClick={props.handleRemoveField} 
          onKeyDown={() => {}}
        >
          <RemoveFieldIcon />
        </a>
      );
    else {
      return <span />;
    }
  }, [props.isRemovable]);

  return (
    <div className={getClassName}>
      <label>{props?.fieldName}</label>
      <input
        className="fs-exclude"
        ref={inputRef}
        type="text"
        value={inputValue}
        disabled={props?.isDisabled}
        onChange={handleInput}
        onBlur={handleFocus}
        onFocus={handleFocus}
      />
      {DisabledIconMemoized}
      {RemoveFieldIconMemoized}
      <span>
        <ErrorIcon />
        {errorMessage}
      </span>
    </div>
  );
};

ProfileTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  errorHandler: PropTypes.func,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isRemovable: PropTypes.bool,
  handleRemoveField: PropTypes.func,
}
export default ProfileTextArea;