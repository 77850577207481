import { requestUpdatePreview } from 'src/features/wizard/setup/store/actions'
export const REQUEST_TEST_CONNECTION = 'wizard/install/request-test-connection' as const
export const REQUEST_TEST_CONNECTION_SUCCESS = 'wizard/install/request-test-connection-success' as const
export const REQUEST_UPDATE_PREVIEW = 'wizard/preview/request-update-preview' as const

export const requestTestConnection = (url: string) => ({
  type: REQUEST_TEST_CONNECTION,
  payload: {
    url,
  },
})

export const requestTestConnectionSuccess = (ok: boolean) => ({
  type: REQUEST_TEST_CONNECTION_SUCCESS,
  payload: {
    ok,
  },
})

export const REQUEST_EMAIL_INSTRUCTIONS = 'wizard/install/request-email-instruction' as const
export const REQUEST_EMAIL_INSTRUCTIONS_FINISHED = 'wizard/install/request-email-instruction-finished' as const

export const requestEmailInstructions = (id: number, email: string) => ({
  type: REQUEST_EMAIL_INSTRUCTIONS,
  payload: {
    id,
    email,
  },
})

export const requestEmailInstructionsFinished = (
  id: number,
  ok: boolean,
  error?: string
) => ({
  type: REQUEST_EMAIL_INSTRUCTIONS_FINISHED,
  payload: {
    id,
    ok,
    error,
  },
})

export const SYNC_EMAIL_INSTRUCTIONS = 'wizard/install/sync-email-instructions' as const

export const syncEmailInstructions = (id: number, email?: string) => ({
  type: SYNC_EMAIL_INSTRUCTIONS,
  payload: {
    id,
    email,
  },
})

export const CLEAR_EMAIL_INSTRUCTIONS = 'wizard/install/clear-email-instructions' as const

export const clearEmailInstructions = () => ({
  type: CLEAR_EMAIL_INSTRUCTIONS,
})

export const INITIALIZE = 'wizard/install/initialize' as const
export const initializeWidgetInstallation = (domainUrl: string) => ({
  type: INITIALIZE,
  payload: {
    domainUrl,
  },
})

export type IInstallActions = ReturnType<
  | typeof requestTestConnection
  | typeof requestTestConnectionSuccess
  | typeof requestEmailInstructions
  | typeof requestEmailInstructionsFinished
  | typeof syncEmailInstructions
  | typeof clearEmailInstructions
  | typeof initializeWidgetInstallation
  | typeof requestUpdatePreview
>
