import React, { Component } from "react";
import ReactDom from "react-dom";
import { DEFAULT_ICON } from "../../commons/Constants";
import { getLogoUrl } from "../../commons/Utility";
import { SearchIcon } from "../../commons/Icons";
import adminLoadingIcon from "../../../../images/admin-loading-icon.gif";

class AdminAccessComponent extends Component {
  constructor() {
    super();
    this.onImageError = this.onImageError.bind(this);
  }
  onImageError(e) {
    let state = this.props.state ? this.props.state : {};
    if (state.searchedType == "EMAIL_ID") {
      e.target.src = DEFAULT_ICON.PROJECT_LOGO;
      e.target.class = "df-logo";
    } else e.target.src = DEFAULT_ICON.STAFF_ICON;
  }
  render() {
    let state = this.props.state ? this.props.state : {};
    return (
      <section class="amdin-wrp">
        <div class="admin-header">
          <a href="javascript:void(0)" class="chatsupport-logo"><img src={getLogoUrl(document.location.origin)} /></a>
          <div>
            <p className="fs-exclude">{state.userEmail}</p>
            <u onClick={this.props.logout}>Sign Out</u>
          </div>
        </div>
        <section class="admin-access-panel">
          <h4>Admin Access</h4>
          <h2>Find Account</h2>
          <div
            className={state.errorMessage ? "error-field" : ""}
            ref="serachInputDiv"
          >
            <p>{state.errorMessage ? state.errorMessage : ""}</p>
            <input
              className="fs-exclude"
              type="text"
              placeholder="Widget ID / Email Address"
              onChange={this.props.onSearchValueChange}
              onKeyPress={this.props.onSearchValueChange}
              value={state.searchValue}
              onFocus={this.props.showSearchList}
            />
            <div
              className={state.isFetching ? "loading" : ""}
              onClick={this.props.checkAndFetchAdminDetails}
              onKeyDown={() => {}}
            >
              <SearchIcon width={'20px'} height={'17px'} fill={'#FFFFFF'} />
              {state.isFetching ? <img src={adminLoadingIcon} alt="loading icon"/> : null}
            </div>
            <ul
              class={
                "email-access1" +
                (state.optionList.length > 0 &&
                state.searchValue == state.searchedValue &&
                state.showSearchList
                  ? " db"
                  : "")
              }
            >
              {state.optionList.map((option) => {
                return (
                  <li
                    key={option.key}
                    onClick={this.props.switchUser}
                    data-value={
                      state.searchedType == "EMAIL_ID"
                        ? option.key
                        : option.option
                    }
                  >
                    <img
                      class={
                        !option.imageUrl && state.searchedType == "EMAIL_ID"
                          ? "df-logo"
                          : ""
                      }
                      src={
                        option.imageUrl
                          ? option.imageUrl
                          : state.searchedType == "EMAIL_ID"
                          ? DEFAULT_ICON.PROJECT_LOGO
                          : DEFAULT_ICON.STAFF_ICON
                      }
                      onError={this.onImageError}
                    />
                    {option.option}
                  </li>
                );
              })}
            </ul>
            <span>or</span>
            <a onClick={this.props.myAccount} href="javascript:void(0)">
              Take me to my account
            </a>
          </div>
        </section>
      </section>
    );
  }
}

export default AdminAccessComponent;
