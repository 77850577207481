import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const MobileTitleContainer = styled.div`
  margin-bottom: 2.4rem;
  margin-left: 1.6rem;
  margin-top: 2.4rem;
  display: block;

  ${mq.sm} {
    display: none;
  }
`

export const WebsitePreviewContainer = styled.div`
  width: 42.6rem;
  margin-top: 5.1rem;
  display: none;
  position: fixed;

  ${mq.sm} {
    display: unset;
  }

  ${mq.lg} {
    height: unset;
    justify-content: unset;
    align-items: unset;
    width: 48.6rem;
    margin-top: 5.1rem;
  }
`
