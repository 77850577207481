import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import { Tabs } from 'src/features/ui/components/Tabs'
import { Text1 } from 'src/features/ui/components/Typography'
import { Myself } from 'src/features/wizard/install/components/ManualInstall/Myself'
import { IProps } from 'src/features/wizard/install/components/ManualInstall/Section/types'
import { SomeoneElse } from 'src/features/wizard/install/components/ManualInstall/SomeoneElse'
import { FooterPadding } from 'src/features/wizard/install/components/PluginInstall/Section/styled'

export const Section: FC<IProps> = ({ footer }) => (
  <>
    <Text1>Who is installing your widget?</Text1>
    <Margin top={{ base: 16, sm: 24 }} />
    <Tabs
      options={[
        {
          label: 'Myself',
          children: (
            <>
              <Myself />
              <FooterPadding>{footer}</FooterPadding>
            </>
          ),
        },
        {
          label: 'Someone else',
          children: (
            <>
              <SomeoneElse />
              {footer}
            </>
          ),
        },
      ]}
    />
  </>
)
