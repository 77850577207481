import React, { useEffect, useRef } from "react";

import { CustomPicker } from "react-color";
import {
  Saturation,
  EditableInput,
  Hue,
} from "react-color/lib/components/common";

export const CustomColorPicker = ({
  hex,
  hsl,
  hsv,
  onChange,
  rgb,
  onSave,
  closePicker,
}) => {
  const valueRef = useRef(null);
  const compRef = useRef(null);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    window.addEventListener("keydown", onEnter);
    window.addEventListener("keydown", onEscape);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
      window.removeEventListener("keydown", onEnter);
      window.removeEventListener("keydown", onEscape);
    };
  }, []);

  const handleClick = (event) => {
    if (!(compRef && compRef.current.contains(event.target))) {
      closePicker();
    }
  };

  const onEscape = (event) => {
    if (event.key === "Escape" && event.keyCode == 27) {
      closePicker();
    }
  };

  useEffect(() => {
    return () => {
      console.log("will unmount");
      window.removeEventListener("keydown", onEnter);
    };
  }, []);

  const onEnter = (event) => {
    if (event.key === "Enter" && event.keyCode == 13) {
      saveColor();
    }
  };

  const styles = {
    hue: {
      // height: 10,
      // position: 'relative',
    },
    // input: {
    //   border: `1px solid ${ hex }`,
    // },
    swatch: {
      background: hex,
    },
  };

  const saveColor = () => {
    let color = valueRef.current.props.value || hex;
    onSave(color);
  };

  return (
    <div className="color-picker-wrp" ref={compRef}>
      <div></div>
      <div>
        <Saturation hsv={hsv} hsl={hsl} onChange={onChange} />
      </div>

      <div>
        <EditableInput
          ref={valueRef}
          style={{ input: styles.input }}
          value={hex}
          onChange={onChange}
        />
        <div style={styles.swatch} />
        <div onClick={saveColor} className="cs-btn cs-primary-btn">
          Save
        </div>
      </div>
      <div className="color-bar">
        <Hue hsl={hsl} onChange={onChange} direction={"vertical"} />
      </div>
    </div>
  );
};

export default CustomPicker(CustomColorPicker);
