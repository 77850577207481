import React, { useState, useEffect, useRef } from "react";
import { TickIconBlue } from "../../commons/Icons.js";
import { FILTER_QUERY_INPUT_TYPES } from "../commons/ContactConstants.js";
import DateFilter from "./DateFilter.js";
import TagFilter from "./TagFilter.js";

const Filter = (props) => {
  const {
    selectedFilter = {},
    onOption,
    onEdit,
    onApply,
    onInputEnter,
  } = props;
  const [tagSearch, setTagSearch] = useState("");
  const [showTagFilter, setShowTagFilter] = useState(false);
  const { key, value, options = {}, type: propertyType } = selectedFilter;
  const QUERY_INFOS = FILTER_QUERY_INPUT_TYPES[propertyType] || [];
  const queryTypes = Object.keys(QUERY_INFOS);
  const {
    params: { projectkey },
  } = props;
  const filterRef = useRef(null);

  const onSearchChange = (event, queryType) => {
    let searchValue = event.target.value.trim();
    setTagSearch(searchValue);
    onEdit(event);
  };

  const onTagSelection = (key, name) => {
    setTagSearch(name);
    onEdit(null, key);
  };

  const getInput = (queryType) => {
    let inputBox = "";
    let queryInfo = QUERY_INFOS[queryType];
    let { type, name } = queryInfo;
    let trailingText = getTrailingText(queryType);
    let tagFilter = getTagFilter(queryType);

    if (type == "string" && options[queryType])
      inputBox = (
        <input
          autoFocus
          type="text"
          spellcheck="false"
          onChange={(event) => {
            onEdit(event);
          }}
          onKeyDown={onInputEnter}
          data-property={key}
          value={value}
        />
      );
    else if (type == "number" && options[queryType]) {
      let sanitizedValue =
        isNaN(value) || (!isNaN(value) && value > 365) ? 7 : value;
      inputBox = (
        <input
          autoFocus
          type="number"
          min="1"
          max="365"
          onChange={(event) => {
            onEdit(event);
          }}
          onKeyDown={onInputEnter}
          data-property={key}
          value={sanitizedValue}
        />
      );
    } else if (type == "tag" && options[queryType])
      inputBox = (
        <input
          autoFocus
          type="text"
          onFocus={() => setShowTagFilter(true)}
          onChange={(event) => {
            onSearchChange(event, queryType);
          }}
          data-property={key}
          value={tagSearch}
        />
      );
    else if (type == "date" && options[queryType])
      inputBox = <DateFilter {...selectedFilter} onEdit={onEdit} />;

    return (
      <li
        className={
          (options[queryType] ? "active" : "") +
          " " +
          (type == "number" ? "number-field" : "") +
          " " +
          (type == "tag" ? "tag-field" : "")
        }
      >
        <a
          onClick={() => {
            onOption(queryType, propertyType);
          }}
          href="#"
        >
          <cite>
            <TickIconBlue />
          </cite>
          {name}
        </a>
        {inputBox}
        {trailingText}
        {tagFilter}
      </li>
    );
  };

  const getTagFilter = (queryType) => {
    let tagFilter = "";
    if (queryType == "is_tagged" && options[queryType])
      tagFilter = (
        <TagFilter
          showTagFilter={showTagFilter}
          setShowTagFilter={setShowTagFilter}
          tagSearch={tagSearch}
          onTagSelection={onTagSelection}
          {...props}
        />
      );
    else if (queryType == "is_not_tagged" && options[queryType])
      tagFilter = (
        <TagFilter
          showTagFilter={showTagFilter}
          setShowTagFilter={setShowTagFilter}
          tagSearch={tagSearch}
          onTagSelection={onTagSelection}
          {...props}
        />
      );
    return tagFilter;
  };

  const getTrailingText = (queryType) => {
    if (QUERY_INFOS[queryType].trailingText)
      return <span>{QUERY_INFOS[queryType].trailingText}</span>;
    return "";
  };

  return (
    <div ref={filterRef}>
      <label>Constraints</label>
      <ul>
        {queryTypes.map((queryType) => {
          return getInput(queryType);
        })}
      </ul>
      <button className="cs-btn cs-btn-xs cs-primary-btn fr" onClick={onApply}>
        Apply
      </button>
    </div>
  );
};

export default Filter;
