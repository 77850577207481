export const COPY_PEOPLE_QUERY = "COPY_PEOPLE_QUERY";
export const UPDATE_BROADCAST_PEOPLE_QUERY = "UPDATE_BROADCAST_PEOPLE_QUERY";
export const RESET_BROADCAST_PEOPLE_ES_QUERY =
  "RESET_BROADCAST_PEOPLE_ES_QUERY";
export const ADD_FILTER_REFERENCE_FOR_BROADCAST =
  "ADD_FILTER_REFERENCE_FOR_BROADCAST";
export const REMOVE_FILTER_REFERENCE_FOR_BROADCAST =
  "REMOVE_FILTER_REFERENCE_FOR_BROADCAST";
export const SET_FILTER_TYPE_FOR_BROADCAST = "SET_FILTER_TYPE_FOR_BROADCAST";
export const UPDATE_PEOPLE_COUNT = "UPDATE_PEOPLE_COUNT";
export const REMOVE_SELECTED_PEOPLE = "REMOVE_SELECTED_PEOPLE";
export const copyPeopleQueryReducer = (esObj) => ({
  type: COPY_PEOPLE_QUERY,
  query: esObj.query,
  scrollId: esObj.scrollId,
  references: esObj.references,
  filterType: esObj.filterType,
  totalPeople: esObj.totalPeople,
  selectedPeople: esObj.selectedPeople,
});
export const updateBroadcastESQuery = (esObj) => ({
  type: UPDATE_BROADCAST_PEOPLE_QUERY,
  query: esObj.query,
  scrollId: esObj.scrollId,
  groupId: esObj.groupId,
  references: esObj.references,
  filterType: esObj.filterType,
  isSingleRequested: esObj.isSingleRequested,
  isForBroadcastPopup: esObj.isForBroadcastPopup,
});

export const resetBroadcastESQuery = () => ({
  type: RESET_BROADCAST_PEOPLE_ES_QUERY,
});

export const addBroadcastFilterReference = (referenceObj) => ({
  type: ADD_FILTER_REFERENCE_FOR_BROADCAST,
  referenceObj,
});

export const removeBroadcastFilterReference = (referenceObj) => ({
  type: REMOVE_FILTER_REFERENCE_FOR_BROADCAST,
  referenceObj,
});

export const setBroadcastFilterType = (filterType) => ({
  type: SET_FILTER_TYPE_FOR_BROADCAST,
  filterType,
});

export const updatePeopleCount = (peopleCount) => ({
  type: UPDATE_PEOPLE_COUNT,
  peopleCount,
});
export const removeSelectedPeopleFilter = () => ({
  type: REMOVE_SELECTED_PEOPLE,
});
