import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import svg from 'src/assets/plus.svg'

export const Container = styled.div``

export const InputContainer = styled.div`
  position: relative;
  width: 3.2rem;
  height: 3.2rem;

  &::after {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    transform: translate(-50%, -50%);
    width: 1.1rem;
    height: 1.1rem;
    background-image: url(${svg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`

export const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 3.2rem;
  height: 3.2rem;
  border: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  padding: 0;
  box-sizing: border-box;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    visibility: hidden;
  }

  &::-moz-color-swatch {
    visibility: hidden;
  }
`

export const Colors = styled.div`
  display: flex;
`

export const Color = styled.button<{ color: string; selected: boolean }>`
  background-color: ${R.prop('color')};
  border: none;
  padding: 0;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1.6rem;
  position: relative;
  outline-offset: 0.5rem;

  & + & {
    margin-left: 1.6rem;
  }

  ${is('selected')`
    &::after {
      position: absolute;
      width: 3.6rem;
      height: 3.6rem;
      left: -0.4rem;
      top: -0.4rem;
      border-radius: 50%;
      border: 0.2rem solid ${R.path(['theme', 'color', 'dodgerBlue'])};
      content: '';
    }
  `}
`
