import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { Container } from 'src/features/returningFlow/components/Perfect/styled'
import { ReturningFinished } from 'src/features/returningFlow/components/ReturningFinished'
import { IProps } from 'src/features/returningFlow/components/WelcomeBackSection/types'
import { Wizard } from 'src/features/returningFlow/components/Wizard'
import { selectIsFinished } from 'src/features/returningFlow/store/selectors'
import { SubTitle, Title } from 'src/features/ui/components/Typography'
import { IAppState } from 'src/utils/state/store'

export const WelcomeBackSectionComponent: FC<IProps> = ({
  hasFinished,
  isLoadingAfterSignup,
}) => {
  if (hasFinished) {
    return <ReturningFinished />
  }

  // trigger commit
  const getTitle = () =>
    isLoadingAfterSignup === 'true' ? 'One more thing...' : 'Welcome back 👋'

  return (
    <Container>
      <Title>{getTitle()}</Title>
      <Margin top={32} />
      <SubTitle color="raven">
        Seems like you haven’t finished your widget setup yet. Let’s dive in!
      </SubTitle>
      <Margin top={90} />
      <Wizard />
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  hasFinished: selectIsFinished(state.returning)!,
})

export const WelcomeBackSection = connect(mapStateToProps)(
  WelcomeBackSectionComponent
)
