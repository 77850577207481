import React, { Component } from "react";
import ReactDOM from "react-dom";
import ConversationTab from "./ConversationTab.jsx";
import {
  stripHTML,
  getProjectId,
  getUserPermissionUtility,
  isAdvancedFilterSearch,
  getConversationClass,
  getEmptyConversation,
  isMineActiveChatPage,
  isGuestPage,
  isOpenChatPage,
  shouldShowConversationList,
  isAssigneeIdExistInChatDashboardRoute,
  getUrlPathname,
} from "../../commons/Utility";
import { browserHistory } from "react-router";
import { LIVE_INTERNAL_PROJECT_ID_NAME_MAP, SEARCH_FILTERS_PLACEHOLDER, SHORT_CHAT_STATUS_MAP,MODIFICATION_STATUS,CHAT_STATUS } from "../../commons/Constants";
import { useVisitorOnlineCountStatus } from "../../hooks/useVisitorOnlineCountStatus.js";
import {
  setNewMessage,
  resetNewMessage,
  reSetUnreadCount,
} from "../../actions/ConversationInfoAction";
import { store } from "../../app";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import NoChatsList from '../../components/chat/NoChatsList.jsx'
import { ConversationQueueIcon, VisitorQueueIcon } from "../../commons/Icons.js";
import ConversationListLoader from "./ConversationListLoader.jsx";
import ConversationListOpenChatsPageLoader from "./ConversationListOpenChatsPageLoader.jsx";
class ConversationList extends Component {
  constructor() {
    super();
    this.conversationList = this.conversationList.bind(this);
    this.getProjectNameByKey = this.getProjectNameByKey.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getConversationListPlaceHolder = this.getConversationListPlaceHolder.bind(this);
    this.handleGuestsTabClick = this.handleGuestsTabClick.bind(this);
    this.handleConversationQueueTabClick = this.handleConversationQueueTabClick.bind(this);
    this.isMyChatFilter = this.isMyChatFilter.bind(this);
    this.state = {
      nextConversationId: "",
      isAnimated: false,
      atTop: true,
      atBottom: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    let oldConversationListData = this.props.isFromChatHistory
      ? this.props.chatHistoryData
      : this.props.dashboardData;
    let oldConversationState = oldConversationListData.state;
    let oldConversationObject = oldConversationState.conversationObject;
    let newConversationListData = nextProps.isFromChatHistory
      ? nextProps.chatHistoryData
      : nextProps.dashboardData;
    let newConversationState = newConversationListData.state;
    let newConversationObject = newConversationState.conversationObject;
    let isAnimated = this.state.isAnimated;
    let nextConversationId = this.state.nextConversationId;

    if (
      newConversationObject.nextConversationId !=
      oldConversationObject.nextConversationId
    ) {
      nextConversationId = newConversationObject.nextConversationId;
      isAnimated = false;
    }
    if (
      oldConversationState.params.conversationId !=
        newConversationState.params.conversationId &&
      oldConversationState.params.conversationId ==
        this.state.nextConversationId
    ) {
      isAnimated = true;
      nextConversationId = "";
    }
    this.setState({ ...this.state, nextConversationId, isAnimated });
  }

  getVisitorOnlineCountStatus = () => {
    let count = this.getVisitorCount();
    let countStatus = "No Visitors Online";

    if (count == 0) countStatus = "No Visitors Online";
    else if (count == 1) countStatus = "1 Visitor Online";
    else if (count > 1) countStatus = `${count} Visitors Online`;
    else countStatus = "No Visitors Online";

    return countStatus;
  };

  getVisitorOnlineProjectCount = () => {
    const visitorMap = this.props.dashboardData.VisitorQueueReducer.visitorMap;
    let projectCount = [];
    for (var key in visitorMap) {
      if (!visitorMap[key].isDeleted)
        projectCount.push(visitorMap[key].projectName);
    }
    let validProjectCount = new Set(projectCount);
    let responseString =
      validProjectCount.size === 0
        ? "Across all widgets"
        : `Across ${validProjectCount.size} widget`;
    responseString += validProjectCount.size > 1 ? "s" : "";
    return responseString;
  };

  getVisitorCount = () => {
    const { visitorMap } = this.props.dashboardData.VisitorQueueReducer;
    let count = 0;
    for (let visitorKey in visitorMap) {
      if (!visitorMap[visitorKey].isDeleted) count++;
    }
    return count;
  };

  getUnReadMessageCount(conversationMessageMap) {
    let unReadCount = 0;
    for (let key in conversationMessageMap) {
      let message = conversationMessageMap[key];

      if (message.type == "CHAT" || message.type == "ATTACHMENT")
        if (
          message.messageStatus != "CHAT_READ" &&
          message.messageFrom == "VISITOR"
        ) {
          unReadCount++;
        } else if (
          message.messageFrom == "VISITOR" &&
          message.messageStatus == "CHAT_READ"
        )
          break;
    }

    return unReadCount;
  }

  getMessageId(conversationMessageMap) {
    for (let key in conversationMessageMap) {
      let message = conversationMessageMap[key];
      if (message.type == "CHAT" || message.type == "ATTACHMENT")
        if (
          message.messageStatus != "CHAT_READ" &&
          message.messageFrom == "VISITOR"
        ) {
          return message;
        }
    }
  }

  handleGuestsTabClick(){
    this.handleClick("guests")
  }

  handleConversationQueueTabClick(){
    this.handleClick("queue")
  }

  handleClick(queueType) {
    let conversationListData = this.props.isFromChatHistory
      ? this.props.chatHistoryData
      : this.props.dashboardData;
    let conversationListState = conversationListData.state;
    let params = conversationListState.params;
    let pathQueryString = conversationListState.queryString;

    if (params.conversationId)
      store.dispatch(resetNewMessage(params.conversationId));

    browserHistory.push({
      pathname:
        "/app/" +
        params.projectkey +
        "/chat/" + queueType + "/" +
        params.visitorOption +
        "/" +
        params.chatStatus +
        "/" +
        params.sortBy +
        "/" +
        params.assignedTo,
      query: pathQueryString ? pathQueryString : "",
    });
    Event(
      "LS-" + params.projectKey,
      "Visitor Queue",
      "Visitor Queue",
      "Vistor Queue Tab Cliked"
    );
  }

  isMyChatFilter(){
    let conversationListState = this.props.dashboardData.state;
    let params = conversationListState.params;
    let assignedToFilter = params.assignedTo;
    let chatStatusParam = params.chatStatus;
    let userId =
      conversationListState.user.data && conversationListState.user.data.id
        ? conversationListState.user.data.id
        : "";
    return assignedToFilter === userId && chatStatusParam === "O";
  }

  conversationList() {
    let conversationListData = this.props.isFromChatHistory
      ? this.props.chatHistoryData
      : this.props.dashboardData;
    let conversationListState = conversationListData.state;
    let toRender = [];
    let params = conversationListState.params;
    let conversationId = params.conversationId;
    let conversationObject = Object.keys(conversationListState.conversationObject).length ? conversationListState.conversationObject : this.props.ConversationReducer.conversationObject;
    let conversationMap = conversationObject.conversationMap;
    let contactsMap = conversationObject.contactsMap;
    let user = conversationListState.user;
    let messageMap = conversationListState.messageMap;
    let pathQueryString = conversationListState.queryString;
    let staffMap = conversationListState.staffMap;
    let overflowAgents = conversationListState.overflowAgents;
    let userId =
      conversationListState.user.data && conversationListState.user.data.id
        ? conversationListState.user.data.id
        : "";
    let assignedToFilter = params.assignedTo;
    let isMyChatsSection = (userId == assignedToFilter && params.chatStatus === SHORT_CHAT_STATUS_MAP.Open);
    let projectName = "";
    let showUnReadCount = true;
    console.log("The logs before checking the flow :" , this.props.dashboardData);
    let conversationReducer = this.props.ConversationReducer;
    let conversationMapFromProps = conversationReducer ? conversationReducer.conversationObject.conversationMap : {}; 
    let emptyConversation = Object.keys(conversationMapFromProps).length === 0 && !document.location.pathname.includes("guests") && conversationReducer && !conversationReducer.isSingleFetching && !conversationReducer.isFetching && (conversationReducer.isFetched || conversationReducer.isSingleFetched) ;
    let isConvFetching = conversationObject.isConvFetching;


    if (user.data || this.props.isFromChatHistory) {
      for (let key in conversationMap) {
        let conversationMessageMap = messageMap[key] ? messageMap[key] : {};
        let lastMessage = "Initiated conversation...";
        let messageCount = 0;
        let messageKeys = Object.keys(conversationMessageMap);
        messageKeys.forEach((item) => {
          if (conversationMessageMap[item].type === "CHAT") messageCount++;
        });
        for (let key in conversationMessageMap) {
          let message = conversationMessageMap[key];

          if (message.type == "CHAT") {
            lastMessage = stripHTML(message.message ? message.message : "");
            break;
          } else if (message.type == "VISITOR_INITIATED_CONVERSATION") {
            lastMessage = "Initiated conversation...";
            break;
          } else if (message.type == "ATTACHMENT") {
            lastMessage =
              message && message.message && message.message.fileName
                ? message.message.fileName
                : "";
            break;
          }
        }
        let conversation = conversationMap[key];
        projectName = this.getProjectNameByKey(conversation.projectId);
        let contact = contactsMap[conversation.visitorId];
        let styles =
          ""; /* (( conversation.messageFrom == "VISITOR"  && conversation.chatStatus == "OPEN" ) ? "unread " : "") + ( conversationId == key ? "active" : "") + ( conversationMap[ key ].isModified && conversationMap[ key ].isModified===true ? ( key != conversationId ? ' dn' : ( conversationMap[ key ].modifiedType === "CLOSE" && conversationMap[key].isClosedByMe ? ' remove-item' : ' disable' )): '' ) + ' ripple'; */
        let unReadCount = 0;
        if(!conversation.key){
          console.log("the conversation is not having any key in conversation List :" )
        }
        if (!conversation.unReadCount && conversation.unReadCount !== 0 && conversation.key)
          store.dispatch(reSetUnreadCount(conversation.key));
        unReadCount =
          conversation.unReadCount +
          this.getUnReadMessageCount(conversationMessageMap);

        let newMessage = this.getMessageId(conversationMessageMap);
        if (
          newMessage &&
          conversation.newMessage !== newMessage.key &&
          unReadCount == 1 &&
          conversationId !== key &&
          !this.props.isFromChatHistory &&
          messageCount > 1 &&
          newMessage.coversationId == conversation.key
        ) {
          store.dispatch(setNewMessage(newMessage));
        }
        if (
          (conversation.messageFrom == "VISITOR" &&
            conversation.chatStatus == "OPEN") ||
          unReadCount > 0
        ) {
          styles += " new-chat";
        }

        if (conversationId == key && !this.props.isFromChatHistory) {
          styles += " active";
          showUnReadCount = false;
          unReadCount = 0;
          if (this.state.nextConversationId == key && !this.state.isAnimated) {
            styles += " nextConversation";
          }
        }

        if (
          conversationMap[key].isModified &&
          conversationMap[key].isModified === true &&
          ((conversationMap[key].assignedTo !== userId && conversationMap[key].modifiedType === MODIFICATION_STATUS.CHAT_ASSIGN) ||
          (conversationMap[key].modifiedType === MODIFICATION_STATUS.CLOSE || conversationMap[key].modifiedType === MODIFICATION_STATUS.MENTION_CLOSED)) && 
          !this.props.isFromChatHistory
        ) {
          styles += " disable ";
        }

        if (
          ((conversationMap[key].chatStatus === CHAT_STATUS.Closed&&
          !conversationMap[key].isModified ) || conversationMap[key].assignedTo !== params.assignedTo)&&
          params.chatStatus === SHORT_CHAT_STATUS_MAP.Open
        )
          styles += " disable ";

        if( conversationMap[key].isModified &&
          conversationMap[key].isModified === true && conversationMap[key].assignedTo !== userId && (!(conversationMap[key].assignedAgentsObject &&  conversationMap[key].assignedAgentsObject.open && conversationMap[key].assignedAgentsObject.open.includes(userId))  || 
          !(conversationMap[key].mentionedStaffs && conversationMap[key].mentionedStaffs.includes(userId)))){
            styles += " disable ";
          }

        if (contact && contact.isOnline) styles += " available";

        let assignedStaff = {};
        if (conversation.assignedTo) {
          assignedStaff = staffMap[conversation.assignedTo];
          assignedStaff = assignedStaff
            ? assignedStaff
            : overflowAgents[conversation.assignedTo];
        }

        if ((conversation.chatStatus == "CLOSED" || (conversationMap[key].closedMentionChats && conversationMap[key].closedMentionChats.includes(userId))) && conversationMap[key].mentionedStaffs && 
              !conversationMap[key].mentionedStaffs.includes(userId) && ( !conversationMap[key].assignedAgentsObject || !conversationMap[key].assignedAgentsObject.open.includes(userId) ) ) 
          styles += " resolved";
        toRender.push(
          <ConversationTab
            styles={styles}
            conversationListData={conversationListData}
            isFromChatHistory={this.props.isFromChatHistory}
            lastMessage={lastMessage}
            conversation={conversation}
            contact={contact}
            params={params}
            pathQueryString={pathQueryString}
            assignedStaff={assignedStaff}
            getOverflowAgentNameById={
              conversationListData.getOverflowAgentNameById
            }
            getAgentNameById={conversationListData.getAgentNameById}
            getAgentImageURLById={conversationListData.getAgentImageURLById}
            userId={
              conversationListState.user.data
                ? conversationListState.user.data.id
                : ""
            }
            projectName={projectName}
            showUnReadCount={showUnReadCount}
            unReadCount={unReadCount}
          />
        );
      }
      if((isConvFetching && !emptyConversation && !isGuestPage() && !isMyChatsSection) || (isConvFetching && !emptyConversation && !isGuestPage() && conversationListState.user.isFullScreenMode ) ){
        toRender.push(<li>
          <figure style={{backgroundColor: "#f4f4f4"}}>
          <em class="progress" style={{width: "100%", height: "100%" , "borderRadius": "100%"}}></em>
          </figure>
          <label><em class="progress"></em></label>
          <p><code></code><em class="progress"></em></p>
          <span><em class="progress"></em></span>
      </li>);
      }
    }

    return toRender;
  }
  
  getVisitorQueueTab() {
    return (
      <li
        className={
          document.location.pathname.includes("guests") ? "active" : ""
        }
        onClick={this.handleGuestsTabClick}
      >
        <VisitorQueueIcon class="icon"/>
        <label>{this.getVisitorOnlineCountStatus()}</label>
        <p>{this.getVisitorOnlineProjectCount()}</p>
      </li>
    );
  }

  showQueueTab(assignedToFilter) {
    let conversationListData = this.props.isFromChatHistory
      ? this.props.chatHistoryData
      : this.props.dashboardData;
    let conversationListState = conversationListData.state;
    let params = conversationListState.params;
    return (
      !this.props.isFromChatHistory &&
      assignedToFilter !== "All" &&
      params.chatStatus === SHORT_CHAT_STATUS_MAP.Open && 
      getUrlPathname().includes(assignedToFilter) &&
      getUserPermissionUtility("VisitorQueue")
    );
  }
  
  getConversationQueueProjectCount(conversationReducer){
    let {queuedConversations} = conversationReducer;
    let {conversationMap} = conversationReducer.conversationObject;
    let projectIds = new Set();
    queuedConversations.forEach((conversationId) => {
      if(conversationMap[conversationId])
        projectIds.add(conversationMap[conversationId].projectId);
    })
    return projectIds.size;
  }

  getConversationQueueTab(){
    let conversationQueueReducer = this.props.dashboardData.ConversationQueueReducer;
    let widgetCount = this.getConversationQueueProjectCount(conversationQueueReducer)
    let widgetText = widgetCount !== 1 ? "s" : "";
    return(
      <li className={
        document.location.pathname.includes("queue") ? "active" : ""
      }
      onClick={this.handleConversationQueueTabClick}
      >
        <ConversationQueueIcon class="icon"/>
        <label>
          {conversationQueueReducer.queuedConversations.length > 0
            ? conversationQueueReducer.queuedConversations.length
            : "No"}{" "}
          Chats in Queue
        </label>
        <p>Across {widgetCount === 0 ? " all" : widgetCount} widget{widgetText}</p>
      </li>
    )
  }

  getQueueTabs() {
    let toRender = [];
    toRender.push(this.getConversationQueueTab());
    toRender.push(this.getVisitorQueueTab());
    return toRender;
  }

  getConversationListPlaceHolder () {
    if (
      this.props.dashboardData &&
      this.props.dashboardData.props &&
      this.props.dashboardData.props.params
    ) {
      let chatStatus = this.props.dashboardData.props.params.chatStatus;
      let assignedTo = this.props.dashboardData.props.params.assignedTo;
      let pathName = document.location.pathname;
      if(chatStatus === "M") return SEARCH_FILTERS_PLACEHOLDER.MENTION_CHATS
      if (chatStatus === "C") return SEARCH_FILTERS_PLACEHOLDER.RESOLVED_CHATS;
      if (chatStatus === "A") return SEARCH_FILTERS_PLACEHOLDER.ALL_CHATS;
      if (chatStatus === "O") return SEARCH_FILTERS_PLACEHOLDER.OPEN_CHATS;
      if (chatStatus === "R") return SEARCH_FILTERS_PLACEHOLDER.RECEPTIONIST;
      if (chatStatus === "P") return SEARCH_FILTERS_PLACEHOLDER.PENDING_CHATS;
      if (
        (chatStatus === "T" || isAdvancedFilterSearch(chatStatus)) &&
        (this.props.dashboardData.props.location.state ||
          this.props.dashboardData.props.ConversationReducer.searchText)
      ) {
        // let searchTextVal = this.props.dashboardData.props.location.state
        //   ? this.props.dashboardData.props.location.state
        //       .nonEncryptedSearchTextFilter
        //   : this.props.dashboardData.props.ConversationReducer.searchText;
          return `No chats found`
      }
      if (assignedTo !== "All" && pathName.includes(assignedTo))
        return SEARCH_FILTERS_PLACEHOLDER.MY_CHATS;
    }
  }

  getProjectNameByKey(projectId) {
    let projects =
      this.props.dashboardData &&
      this.props.dashboardData.props.project &&
      this.props.dashboardData.props.project.projectList
        ? this.props.dashboardData.props.project.projectList
        : [];
    let projectName = " ";

    if (LIVE_INTERNAL_PROJECT_ID_NAME_MAP.hasOwnProperty(projectId)) {
      projectName = LIVE_INTERNAL_PROJECT_ID_NAME_MAP[projectId];
    } else if (projects) {
      let project = projects.find((project) => project.key == projectId);
      if (project) projectName = project.name;
    }

    return projectName;
  }
  handleScroll(e) {
    //to check where the scroll bar is.
    let conversationListData = this.props.isFromChatHistory
      ? this.props.chatHistoryData
      : this.props.dashboardData;
    conversationListData.loadMore(e);
    let containerDomNode = ReactDOM.findDOMNode(e.target);
    let atBottom = this.state.atBottom;
    let atTop = this.state.atTop;
    if (containerDomNode) {
      atBottom =
        containerDomNode.clientHeight ==
        containerDomNode.scrollHeight - containerDomNode.scrollTop
          ? true
          : false;
      atTop =
        containerDomNode.scrollTop == 0 ||
        containerDomNode.clientHeight == containerDomNode.scrollHeight
          ? true
          : false;
    }
    this.setState({ ...this.state, atBottom: atBottom, atTop: atTop });
  }
  render() {
    let conversationListData = this.props.isFromChatHistory
      ? this.props.chatHistoryData
      : this.props.dashboardData;
    let conversationListState = conversationListData.state;
    let conversationObject = this.props.ConversationReducer.conversationObject;
    let params = conversationListState.params;
    let assignedToFilter = params.assignedTo;
    console.log("The This . props before history click : ", this.props);
    const emptyConversation = getEmptyConversation(
      this.props.ConversationReducer
    );
    const conversationClass = getConversationClass(this.state);
    const conversationStyle = isMineActiveChatPage()
      ? { height: "calc(100vh - 335px)" }
      : {};
    const showConversationListOpenChatsPageLoader =
      isOpenChatPage() || isAssigneeIdExistInChatDashboardRoute();
    const showNoChats =
      emptyConversation &&
      !isMineActiveChatPage() &&
      !isAssigneeIdExistInChatDashboardRoute();
    
    if (this.props.isFromChatHistory) {
      return (
        <ul ref="conversationList" className="user-history-list">
          {this.conversationList()}
        </ul>
      );
    }
    if (showNoChats) {
      const placeHolderText = this.getConversationListPlaceHolder();
      return <NoChatsList message={placeHolderText} />;
    }
    if (
      shouldShowConversationList(this.props, conversationObject.conversationMap)
    ) {
      return (
        <React.Fragment>
          <ul className="cs-chat-lineup">
            {this.showQueueTab(assignedToFilter) ? this.getQueueTabs() : ""}
          </ul>
          <ul
            ref="conversationList"
            className={conversationClass}
            onScroll={this.handleScroll}
            style={conversationStyle}
          >
            {this.conversationList()}
          </ul>
        </React.Fragment>
      );
    }
    if (showConversationListOpenChatsPageLoader) {
      return (
        <ConversationListOpenChatsPageLoader
          conversationClass={conversationClass}
        />
      );
    }
    return (
      <ConversationListLoader conversationClass={conversationClass} count={1} />
    );
  }
}
export default ConversationList;
