import { useDispatch } from "react-redux";
import * as WorkingHoursAction from "../actions/WorkingHoursAction";

export const useUpdateWorkingHours = (workingHours, workingHoursId) => {
  const dispatch = useDispatch();

  return (workingHours, workingHoursId) => {
    if (workingHours && workingHoursId) {
      dispatch(
        WorkingHoursAction.updateWorkingHoursRequest(
          workingHours,
          workingHoursId
        )
      );
      return true;
    } else {
      return false;
    }
  };
};
