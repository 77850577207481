import React, { FC, useRef } from 'react'
import { Margin } from 'styled-components-spacing'
import { Icon } from 'src/features/ui/components/Icon'
import {
  Container,
  Top,
  Content,
  Bottom,
  Title,
  StyledIcon,
  StyledButton,
  TextMessage,
  Controls,
  Icons,
  SendButton,
  ButtonIcon,
  ChatDividerText,
  Bubble,
  BubbleContainer,
  Profile,
  MineBubble,
  Dot,
} from 'src/features/ui/components/WidgetExample/styled'
import { useClickOutside } from 'src/utils/hooks/useClickOutside'

export interface IProps {
  onClose: VoidFunction
}

export const WidgetExample: FC<IProps> = ({ onClose }) => {
  const containerRef = useRef(null)
  useClickOutside(containerRef, onClose)

  return (
    <Container ref={containerRef}>
      <Top>
        <Title>
          We’re <br /> happy to chat <Dot />
        </Title>
        <StyledButton type="button" onClick={onClose}>
          <StyledIcon type="close" width={6.15} height={6.15} />
        </StyledButton>
      </Top>
      <Content>
        <Bubble>
          We&apos;re real people here, not bots. Please ask your question and
          we&apos;ll be with you in a moment.
        </Bubble>
        <Margin top={13} />
        <ChatDividerText>- You are now chatting with Lewis -</ChatDividerText>
        <Margin top={13} />
        <BubbleContainer>
          <Profile />
          <Margin left={8} />
          <Bubble>Hi there!</Bubble>
        </BubbleContainer>
        <Margin top={12} />
        <BubbleContainer>
          <Profile />
          <Margin left={8} />
          <Bubble>I&apos;m Lewis. How can I help you today?</Bubble>
        </BubbleContainer>
        <Margin top={12} />
        <MineBubble>
          <Bubble isMine>
            Hey! I was wondering if you could help me understand your pricing
            plans a little better?
          </Bubble>
        </MineBubble>
      </Content>
      <Bottom>
        <TextMessage>Send your message to Lewis…</TextMessage>
        <Controls>
          <Icons>
            <Icon type="smiley" width={11} height={11} />
            <Margin left={7} />
            <Icon type="clip" width={7.09} height={12.79} />
          </Icons>
          <SendButton>
            <ButtonIcon width={11} height={8} type="send" />
          </SendButton>
        </Controls>
      </Bottom>
    </Container>
  )
}
