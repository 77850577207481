import React from "react";
import { InfoCircleIcon } from "../../../commons/Icons";

const ImportContactFooter = (props) => {
  const { importContacts,unmappedFieldCount,forceImport,closeImportPopUp,contactCount } = props;

  const getClassName = () => {
    let className = "cs-btn";
    if(contactCount <= 0)
      className += " cs-disabled-btn";
    else
      className += " cs-primary-btn"
    return className;
  }

  const handleClick = () => {
    if(contactCount > 0)
      importContacts();
  }

  return (
    <footer>
      <div className={forceImport ? "unmaped-list error" : "unmaped-list"}>
        {unmappedFieldCount ? <InfoCircleIcon /> : ""}
        {unmappedFieldCount
          ? `${unmappedFieldCount} Unmapped columns will be deleted. Are you sure?`
          : ""}
      </div>
      <a
        href="javascript:void(0)"
        class="cs-btn cs-decline-btn"
        onClick={closeImportPopUp}
      >
        Cancel
      </a>
      <a
        href="javascript:void(0)"
        className={getClassName()}
        onClick={handleClick}
      >
        {forceImport ? "Import anyaway" : "Import"}
      </a>
    </footer>
  );
};
export default ImportContactFooter;
