import React from "react";
import {
  CHAT_FILTERS,
  MY_CHAT_FILTERS,
  ALL_CHAT_FILTERS,
} from "../../../commons/Constants";
import {
  AllOpenChatIcon,
  MyOpenChatIcon,
  MentionedChatsIcon,
  ReceptionIcon,
  ResolvedFilterIcon,
  PendingIcon,
} from "../../../commons/Icons";
import { browserHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { conversationIsLoading, conversationListFailed, hideConversationFilter } from "../../../actions/ConversationInfoAction";
import { cancelPreviousRequest } from "../../../xhr/XhrRequestHandler";
import { getPathName, isCurrentFilterClicked } from "../../../commons/Utility";
const Filter = ({
  filterName,
  filterCount,
  isMyChatFilter,
  projectKey,
  userId,
}) => {
  const { conversationReducer } = useSelector((state) => ({
    conversationReducer: state.ConversationInfoReducer
  }));
  const dispatch = useDispatch();
  const getFilterIcon = () => {
    switch (filterName) {
      case CHAT_FILTERS.OPEN_CHATS:
        return isMyChatFilter ? <MyOpenChatIcon /> : <AllOpenChatIcon />;
      case CHAT_FILTERS.MENTION_CHATS:
        return <MentionedChatsIcon />;
      case CHAT_FILTERS.RECEPTION_CHATS:
        return <ReceptionIcon />;
      case CHAT_FILTERS.RESOLVED_CHATS:
        return <ResolvedFilterIcon />;
      case CHAT_FILTERS.PENDING_CHATS:
        return <PendingIcon />;
      default:
        return "";
    }
  };

  const cancelPreviousConversationRequest = () => {
    if (conversationReducer && conversationReducer.cancelToken)
      cancelPreviousRequest(conversationReducer.cancelToken);
    dispatch(conversationListFailed());
    dispatch(conversationIsLoading());
  }

  const redirectToFilter = () => {
    let pathName = "";
    let filterType = isMyChatFilter ? userId : "All";
    pathName = getPathName(filterName, projectKey, filterType);
    if (!isCurrentFilterClicked(pathName)) {
      cancelPreviousConversationRequest();
      browserHistory.push({
        pathname: pathName,
      });
    }
    dispatch(hideConversationFilter());
  };

  return (
    <label onClick={redirectToFilter}>
      {getFilterIcon()}
      {isMyChatFilter
        ? MY_CHAT_FILTERS[filterName]
        : ALL_CHAT_FILTERS[filterName]}
      <cite>{conversationReducer.isCountFetching?null:filterCount}</cite>
    </label>
  );
};
export default Filter;
