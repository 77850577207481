import React, { FC } from 'react'

export const CheckMark2: FC<any> = ({ fill, ...props }) => (
  <svg width="116" height="116" viewBox="0 0 116 116" fill="none" {...props}>
    <ellipse cx="57.9996" cy="57.9999" rx="57.5675" ry="57.5676" fill={fill} />
    <path
      d="M78 45.9167L50.5 73.4167L38 60.9167"
      stroke="white"
      strokeWidth="6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
