/**
 * Created by melvin on 25/04/17.
 */
import React, { Component } from "react";
import StaffRoleDropDown from "./StaffRoleDropDown.jsx";
import { getFullName } from "../../commons/Utility";
import {
  NOT_TAKING_CHAT_STATUSES,
  TAKING_CHAT_STATUSES,
  OFFLINE_CHAT_STATUSES,
} from "../../commons/Constants";

class StaffListItemComponent extends Component {
  constructor(props) {
    super(props);
    this.getPresenceClassName = this.getPresenceClassName.bind(this);
  }

  handleBrokenImage(e) {
    e.target.src = "https://app.chatsupport.co/images/profile_Icon.png";
  }

  getPresenceClassName() {
    let { takingChatStatus, isOnline } = this.props.staffItem;

    if (takingChatStatus) {
      if (OFFLINE_CHAT_STATUSES.includes(takingChatStatus)) return "offline";
      else if (NOT_TAKING_CHAT_STATUSES.includes(takingChatStatus))
        return "busy";
      else return "available";
    } else {
      return isOnline ? "available" : "offline";
    }
  }

  render() {
    const photoUrl =  "https://app.chatsupport.co/images/profile_Icon.png";
    const presenceClassName = this.getPresenceClassName();
    const staffName = getFullName(this.props.staffItem);
    return (
      <li className={presenceClassName}>
        <img
          src={
            this.props.staffItem.photoUrl &&
            this.props.staffItem.photoUrl != "null"
              ? this.props.staffItem.photoUrl
              : photoUrl
          }
          alt={staffName}
          onError={this.handleBrokenImage}
          width="40px"
        />
        <label
          ref="name"
          data-tip={staffName.length > 13 ? staffName : ""}
          data-class="tooltip info"
        >
          {staffName}
        </label>
        <span
          ref="email"
          data-tip={
            this.props.staffItem.login && this.props.staffItem.login.length > 26
              ? this.props.staffItem.login
              : ""
          }
          data-class="tooltip info"
        >
          {this.props.staffItem.login ? this.props.staffItem.login : ""}
        </span>

        <StaffRoleDropDown
          parentState={this.props.parentState}
          staffItem={this.props.staffItem}
          updateUserRoleHandler={this.props.updateUserRoleHandler}
        />
        {this.props.parentState.activeProject &&
        this.props.staffItem.key !==
          this.props.parentState.activeProject.createdBy &&
        this.props.parentState.loggedInUserPermission === "FULL_ACCESS" ? (
          <code
            data-staffKey={this.props.staffItem.key}
            onClick={this.props.showStaffDeletePopup}
            className="staffDelete"
          ></code>
        ) : (
          ""
        )}
        <div>
          <code></code>
          <code></code>
        </div>
      </li>
    );
  }
}
export default StaffListItemComponent;
