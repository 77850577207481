import React from "react";

const OnboardFooter = () => {
  return (
      <footer>
        <nav>
          <a>© 2022 chatsupport.co</a>
          <a href="https://chatsupport.co/terms" target="_blank">Terms</a>
          <a href="https://chatsupport.co/privacy-policy" target="_blank">Privacy Policy</a>
          <a href="https://chatsupport.co/cookie-policy" target="_blank">Cookie Policy</a>
          <a
            href="https://anywhereworks.com/blue-dot"
            rel="noopener noreferrer"
            target="_blank"
            class="txt-reg action-link"
          >
            Cherish the Pale Blue Dot <span />
          </a>
        </nav>
      </footer>
  );
};

export default OnboardFooter;
