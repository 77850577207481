import { useSelector, useDispatch } from "react-redux";
import { getStaffContact } from "../Action/StaffContactAction";

export const useStaffContact = (staffId) => {
  alert("StaffContact Hook Triggered");
  const { staffContactReducer } = useSelector((state) => ({
    staffContactReducer: state.StaffContactReducer,
  }));

  let staffContact = {};
  const dispatch = useDispatch();

  const { isFetched, isFetching } = staffContactReducer;

  if (
    !staffContactReducer ||
    (!!staffContactReducer &&
      !staffContactReducer.contactMap.hasOwnProperty(staffId) &&
      staffContactReducer.isFetching != true)
  ) {
    dispatch(getStaffContact(staffId));
  } else if (staffContactReducer.contactMap.hasOwnProperty(staffId)) {
    staffContact = staffContactReducer.contactMap[staffId];
  }

  return {
    staffContact: staffContact,
    isFetched: isFetched,
    isFetching: isConfigFetched,
  };
};

export const getEmailsFromStaffContact = (staffContact, staffId) => {
  let emailList = [];
  if (staffContact.hasOwnProperty("id")) {
    if (!!staffContact.login) emailList.push(staffContact.login);

    staffContact.linkedContactMethods.forEach(function (
      linkedContactMethods,
      index
    ) {
      if (
        linkedContactMethods.type == "email" &&
        emailList.indexOf(linkedContactMethods.value) == -1 &&
        !!linkedContactMethods.value
      )
        emailList.push(linkedContactMethods.value);
    });
  }
  return emailList;
};

export const getPhoneNumFromStaffContact = (
  staffContact,
  defaultMobileNumber
) => {
  let phoneList = [];
  if (staffContact.hasOwnProperty("id")) {
    if (!!defaultMobileNumber) phoneList.push(defaultMobileNumber);
    staffContact.linkedContactMethods.forEach(function (
      linkedContactMethods,
      index
    ) {
      if (
        linkedContactMethods.type == "phone" &&
        phoneList.indexOf(linkedContactMethods.value) == -1 &&
        !!linkedContactMethods.value
      )
        phoneList.push(linkedContactMethods.value);
    });
  }
  return phoneList;
};

export const getLocationIfLoggedInUser = (staffId) => {
  // locationDetails: {
  //     city: 'chennai',
  //     countryCode: 'IN',
  //     latitude: '13.082680',
  //     longitude: '80.270718'
  //   }
  // const {  userReducer } = useSelector(state => ({
  //     userReducer : state.UserReducer,
  // }));

  // let userReducer = store.getState().UserReducer;

  // if(userReducer.hasOwnProperty("data")
  //             && userReducer["data"].hasOwnProperty("id")
  //                 && (userReducer["data"]["id"] ==  staffId)
  //                         && userReducer.hasOwnProperty("locationDetails") )
  // {
  //     if( userReducer["locationDetails"].hasOwnProperty("latitude") && !!userReducer["locationDetails"]["latitude"])
  //         return userReducer["locationDetails"];
  // }
  return null;
};

export const getNextActiveStaffId = (accountStaffs) => {
  let staffId = "";

  for (let key in accountStaffs) {
    let staff = accountStaffs[key];
    if (staff.status === "ACTIVE") {
      if (staffId == "") {
        staffId = staff.key;
        return staffId;
      }
    }
  }
  return staffId;
};

export const isStaffActive = (staffid, accountStaffs) => {
  if (
    accountStaffs.hasOwnProperty(staffid) &&
    accountStaffs[staffid]["status"] == "ACTIVE"
  )
    return true;

  return false;
};
