import React from "react";
import useProperties from "../hooks/useProperties.js";

const Header = (props) => {
  let {
    headers = [],
    params: { projectkey },
  } = props;
  const { properties } = useProperties(projectkey);
  let { gridWrapperWidth, columnsWidth, onSort , setGridHeaderBottom} = props;
  let GridHeaderWidth =
    columnsWidth > gridWrapperWidth ? columnsWidth : gridWrapperWidth;

  const setBottom = (element) => {
    if (element) setGridHeaderBottom(element.getBoundingClientRect().bottom);
  };
  console.info(
    "GridHeaderWidth:" +
      GridHeaderWidth +
      ",columnsWidth:" +
      columnsWidth +
      ",gridWrapperWidth:" +
      gridWrapperWidth
  );

  return (
    <div
      className={"people-grid-head"} ref={setBottom}
      style={{ width: `${GridHeaderWidth + 24}px` }}
    >
      {headers.map((header) => (
        <label
          onClick={() => {
            onSort(header);
          }}
          style={{ cursor: "pointer" }}
        >
          {properties[header] || header}
        </label>
      ))}
    </div>
  );
};

export default Header;
