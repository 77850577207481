import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import checkmark from 'src/assets/checkmark.svg'
import checkmarkHollow from 'src/assets/checkmarkHollow.svg'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div<{ completed?: boolean }>`
  padding-left: 4rem;
  padding-right: 1rem;
  border-left: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  height: 100%;
  position: relative;
  margin-left: 1.6rem;
  padding-top: 0.2rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1.6rem;
    background-color: ${R.path(['theme', 'color', 'white'])};
    width: 3.2rem;
    height: 4.8rem;
    background-image: url(${checkmarkHollow});
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;

    ${is('completed')`
      background-image: url(${checkmark});
    `}
  }

  ${is('completed')`
    height: unset;
    border-left: 0.1rem solid ${R.path(['theme', 'color', 'codGray'])};
  `}

  ${mq.md} {
    padding-left: 10rem;
  }
`

export const Divider = styled.div`
  margin-top: 4rem;
  padding-bottom: 2.2rem;
  width: 100%;
  height: 0.1rem;
  border-top: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
`
