import React, { useState } from "react";
import CustomiseWidget from "./CustomiseWidget.jsx";

const CustomieWidgetContainer = (props) => {
  const [showWidgetColorPopUp, setShowWidgetColorPopUp] = useState(false);
  const [showWidgetIconPopUp, setShowWidgetIconPopUp] = useState(false);

  return (
    <CustomiseWidget
      {...props}
      showWidgetColorPopUp={showWidgetColorPopUp}
      setShowWidgetColorPopUp={setShowWidgetColorPopUp}
      showWidgetIconPopUp={showWidgetIconPopUp}
      setShowWidgetIconPopUp={setShowWidgetIconPopUp}
    />
  );
};

export default CustomieWidgetContainer;
