import {
  NOTIFICATION_SETTINGS_REQUESTED,
  NOTIFICATION_SETTINGS_RESPONSE,
  NOTIFICATION_SETTINGS_FAILED,
  NOTIFICATION_UPDATE_FAILED,
  NOTIFICATION_UPDATE_RESPONSE,
  NOTIFICATION_UPDATE_REQUESTED,
} from "../actions/NotificationSettingsAction";
const defaultSettings = {
  messages: "ACTIVE",
  conversations: "ACTIVE",
  assignment: "ACTIVE",
};

const NotificationSettingsReducer = (
  state = {
    isFetched: false,
    isFetching: false,
    isUpdating: false,
    data: defaultSettings,
  },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_SETTINGS_REQUESTED:
      return {
        ...state,
        isFetching: true,
      };
    case NOTIFICATION_SETTINGS_RESPONSE:
      return {
        ...state,
        data: action.notificationSettings,
        isFetching: false,
        isFetched: true,
      };
    case NOTIFICATION_SETTINGS_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case NOTIFICATION_UPDATE_REQUESTED:
      return {
        ...state,
        isUpdating: true,
      };
    case NOTIFICATION_UPDATE_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        data: action.notificationSettings,
      };
    case NOTIFICATION_UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
  }
  return state;
};

export default NotificationSettingsReducer;
