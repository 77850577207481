/**
 * Created by melvin on 06/04/17.
 */
export const SIGN_UP_REQUESTED = "SIGN_UP_REQUESTED";
export const SIGN_UP_RESPONSE = "SIGN_UP_RESPONSE";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const CREATE_LEAD_REQUESTED = "CREATE_LEAD_REQUESTED";
export const CREATE_LEAD_RESPONSE = "CREATE_LEAD_RESPONSE";
export const UPDATE_LEAD_RESPONSE = "UPDATE_LEAD_RESPONSE";
export const CREATE_LEAD_FAILED = "CREATE_LEAD_FAILED";
export const SETUP_ACCOUNT_REQUEST = "SETUP_ACCOUNT_REQUEST";
export const SETUP_ACCOUNT_RESPONSE = "SETUP_ACCOUNT_RESPONSE";

export const signUpRequest = (signUpData) => ({
  type: SIGN_UP_REQUESTED,
  signUpData,
});
export const signUpResponse = (signUpResponseData) => ({
  type: SIGN_UP_RESPONSE,
  signUpResponseData,
});
export const signUpFailed = (signUpFailedData) => ({
  type: SIGN_UP_FAILED,
  signUpFailedData,
});

export const createLeadRequest = (createLeadData) => ({
  type: CREATE_LEAD_REQUESTED,
  createLeadData,
});

export const setupAccountRequest = (accountData) => ({
  type: SETUP_ACCOUNT_REQUEST,
  accountData,
});

export const setupAccountResponse = (accountData) => ({
  type: SETUP_ACCOUNT_RESPONSE,
  accountData,
});

export const updateLeadResponse = (updateLeadResponseData) => ({
  type: UPDATE_LEAD_RESPONSE,
  updateLeadResponseData,
});

export const createLeadResponse = (createLeadResponseData) => ({
  type: CREATE_LEAD_RESPONSE,
  createLeadResponseData,
});
export const createLeadFailed = (createLeadFailedData) => ({
  type: CREATE_LEAD_FAILED,
  createLeadFailedData,
});
