import { call, put, fork } from "redux-saga/effects";
import cookie from "react-cookie";
import { sendMessage } from "../actions/MessageAction";
import * as contactInfoAction from "../actions/ContactInfoAction";
import * as ConnectionAction from "../actions/MessageAction";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  generateMessageId,
  getOverflowRTMBaseURL,
  getAppMode,
  getQueryParam,
  isAwConnection,
  getAvailabilityMessage,
  getPresenceMessage,
  getNonDefaultVersion,
  getIsAWRecentComponent,
  validateAndUpdateContactPresenceForConnectAndVisitors
} from "../commons/Utility";
import { MESSAGE_TYPE, MESSAGE_FROM } from "../commons/Constants";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { store } from "../app";

export function* doSubscription(request) {
  console.log(
    "doSubscription-------------------------------> ",
    request.subscription
  );
  let url = "";
  if (store.getState().UserReducer.isInternalUser)
    url = "/rtm/subscribe/agent/v2.0";
  else url = "/rtm/subscribe/agent";

  let visitorId = request.subscription.visitorId;
  let conversationId = request.subscription.conversationId;
  try {
    let response;
    if (request.subscription.isFromOverflow) {
      const mode = getAppMode() === "live" ? "live" : "staging";
      let baseUrl = getOverflowRTMBaseURL(mode);
      url =
        "/channels/overflow/subscribeToChatChannel?apiKey=" +
        request.subscription.apiKey;
      delete request.subscription["isFromOverflow"];
      delete request.subscription["apiKey"];
      response = yield call(
        Ajax.callWithRetry,
        (resp) => {
          return resp && resp.success == true;
        },
        Ajax.postToDifferentBaseUrl,
        baseUrl,
        url,
        request.subscription
      );
    } else if (request.subscription.isAwWindow) {
      url = "/rtm/subscribe/aw/agent";
      response = yield call(
        Ajax.callWithRetry,
        (resp) => {
          return resp && resp.data && resp.data.success == true;
        },
        Ajax.postUnauthenticated,
        url,
        request.subscription
      );
    } else {
      let activeProjectId = store.getState().StaffReducer.activeProjectId;
      request.subscription["activeProjectId"] = activeProjectId;
      request.subscription["nonDefaultVersion"] = getNonDefaultVersion();
      response = yield call(
        Ajax.callWithRetry,
        (resp) => {
          return resp && resp.data && resp.data.success == true;
        },
        Ajax.post,
        url,
        request.subscription
      );
    }
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* fetchPresenceByuserIds(request) {
  const url = "/rtm/get/user/presencebyuserids";
  let payload = { userIds: request.userIds };
  let response = {};
  try {
    if (request.isFromOverflow)
      response = yield call(
        Ajax.postUnauthenticated,
        url + "?connectionId=" + getQueryParam("connection_id"),
        payload
      );
    else if (request.isAwWindow)
      response = yield call(Ajax.postUnauthenticated, url, payload);
    else response = yield call(Ajax.post, url, payload);

    if (response.response) {
      if (
        response.data &&
        response.data.data &&
        response.data.data.userStatuses
      ) {
        let data = response.data.data.userStatuses;
        let userId = store.getState().UserReducer.data ? store.getState().UserReducer.data.id : ""
        if (validateAndUpdateContactPresenceForConnectAndVisitors(request, data)) {
          yield put(
            contactInfoAction.updateContactPresence(
              data,
              false,
              false,
              request.projectId,
              userId
            )
          );
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchAndUpdateUserPresence(request) {
  const url = "/rtm/get/user/presencebyuserids";

  let payload = {
    userIds: [request.userId],
    isServerTimeRequested: true
  };
  let response = {};
  try {
    response = yield call(Ajax.post, url, payload);
    let data = response.data.presenceData.data.userStatuses;
    let serverTime = response.data.serverTime;
    let userStatus = data[0] || {};
    let applicationstatus = userStatus.status
      ? userStatus.status.applicationStatus
      : {};

    let presenceMessage = getPresenceMessage(
      applicationstatus,
      request.projectId,
      request.takingChatStatus,
    );
    presenceMessage.serverTime = serverTime;
    yield put(
      ConnectionAction.sendMessage(presenceMessage, { triggerWebHook: false })
    );
    let availabilityMessage = getAvailabilityMessage(
      request.isTakingChat,
      request.userId,
      request.projectId
    );
    yield put(
      ConnectionAction.sendMessage(availabilityMessage, {
        triggerWebHook: true,
      })
    );
  } catch (error) {}
}

export function* doUpdateSubscription(request) {
  let url = "/rtm/subscribe/agent/update/" + request.subscriptionData.userId;
  let response = {};
  try {
    if (isAwConnection()) {
      url = "/rtm/subscribe/aw/agent/update/" + request.subscriptionData.userId;
      response = yield call(
        Ajax.postUnauthenticated,
        url,
        request.subscriptionData
      );
    } else {
      let activeProjectId = store.getState().StaffReducer.activeProjectId;
      if (getIsAWRecentComponent()) {
        request.subscriptionData["isFromAWIntegration"] = true;
      } 
      request.subscriptionData["activeProjectId"] = activeProjectId;
      response = yield call(Ajax.post, url, request.subscriptionData);
    }

    yield put(ConnectionAction.updateAvailabilityRequest());
    console.info("Response:" + response);
  } catch (e) {
    console.error(e);
  }
}
