import {
  UPDATE_PEOPLE_SCROLL_ID,
  UPDATE_PEOPLE_QUERY,
  RESET_PEOPLE_ES_QUERY,
  ADD_FILTER_REFERENCE,
  REMOVE_FILTER_REFERENCE,
  SET_FILTER_TYPE,
} from "../actions/PeopleESQueryAction";

const PeopleESQueryReducer = (
  state = {
    query: {
      projectId: "",
      filter: {},
      not_filter: {},
      match: {},
      not_match: {},
      exists: { field: [] },
      not_exist: { field: [] },
      sort: { lastSeenTime: "desc" },
      range: [],
      size: 50,
      aggr: {},
      scroll: "6h",
    },
    scrollId: "",
    references: {},
    filterType: "all",
  },
  action
) => {
  switch (action.type) {
    case UPDATE_PEOPLE_QUERY:
      return {
        ...state,
        query: action.query,
        references: action.references ? action.references : state.references,
        filterType: action.filterType ? action.filterType : state.filterType,
      };
    case UPDATE_PEOPLE_SCROLL_ID:
      return {
        ...state,
        scrollId: action.scroll_Id,
      };
    case RESET_PEOPLE_ES_QUERY:
      return {
        ...state,
        query: {
          projectId: "",
          filter: {},
          not_filter: {},
          match: {},
          not_match: {},
          exists: { field: [] },
          not_exist: { field: [] },
          sort: { lastSeenTime: "desc" },
          range: [],
          size: 50,
          aggr: {},
          scroll: "6h",
        },
        scrollId: "",
        references: {},
        filterType: "all",
      };

    case ADD_FILTER_REFERENCE:
      let referenceMap = Object.assign({}, state.references);
      referenceMap[action.referenceObj.key] = action.referenceObj;
      return {
        ...state,
        references: referenceMap,
      };

    case SET_FILTER_TYPE:
      return {
        ...state,
        filterType: action.filterType,
      };

    case REMOVE_FILTER_REFERENCE:
      let reference = Object.assign({}, state.references);
      delete reference[action.referenceObj.key];
      return {
        ...state,
        references: reference,
      };
  }
  return state;
};

export default PeopleESQueryReducer;
