import React, { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { DROPDOWN_TAG_CLASSNAME } from "../../../commons/Constants.js";
import {
  getValueFromNestedObject,
  isDownArrowKey,
  isUpArrowKey,
} from "../../../commons/Utility.js";
import TagMessageDropdownList from "./TagMessageDropdownList.jsx";

const TagDropdown = (props) => {
  const {
    tags,
    searchTerm,
    payload,
    openDropDown,
    searchInputRef,
    tagInputRef,
    messageId,
    conversationId,
    requestSingleConversationAndAddTagToIt,
    setTagKey,
    isMouseOverAddTag
  } = props;
  const ulRef = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.getElementById(messageId).addEventListener("wheel", handleWheel);
    resetActiveElement();
    setFirstChildAsActive();
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document
        .getElementById(messageId)
        .removeEventListener("wheel", handleWheel);
      ReactTooltip.hide();
    };
  }, [searchTerm]);

  useEffect(() => {
    if (isMouseOverAddTag) {
      resetActiveElement();
      ReactTooltip.hide();
    }
  }, [isMouseOverAddTag]);

  const handleWheel = (event) => {
    event.preventDefault();
    ulRef.current.scrollBy(0, event.deltaY);
  };

  const resetActiveElement = () => {
    let ul = ulRef.current;
    let activeElements = ul
      ? ul.getElementsByClassName(DROPDOWN_TAG_CLASSNAME.ACTIVE)
      : [];
    for (const element of activeElements) {
      element.className = DROPDOWN_TAG_CLASSNAME.DEFAULT;
    }
  };

  const setFirstChildAsActive = () => {
    let firstChild = getValueFromNestedObject(ulRef, "current.firstChild");
    if (firstChild) {
      firstChild.className = DROPDOWN_TAG_CLASSNAME.ACTIVE;
      firstChild.scrollIntoView({ block: "nearest" });
      ReactTooltip.show(firstChild);
    }
  };

  const getFilteredTags = () => {
    return tags.filter((tag) => {
      if (
        searchTerm === "" ||
        tag.name.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return tag;
      }
    });
  };

  const addClassNameAndScrollTo = (element, isMouseOver) => {
    resetActiveElement();
    element.className= DROPDOWN_TAG_CLASSNAME.ACTIVE;
    if (!isMouseOver) element.scrollIntoView({ block: "nearest" });
    ReactTooltip.show(element);
  };

  const handleKeyDown = (event) => {
    let unOrderedList = ulRef.current;
    let activeElement = unOrderedList.getElementsByClassName(
      DROPDOWN_TAG_CLASSNAME.ACTIVE
    )[0];
    let nextElement = activeElement ? activeElement.nextElementSibling : null;
    let previousElement = activeElement
      ? activeElement.previousElementSibling
      : null;
    let isDownArrow = isDownArrowKey(event);
    let isUpArrow = isUpArrowKey(event);
    if (isDownArrow || isUpArrow) event.preventDefault();
    if ((isDownArrow && nextElement) || (isUpArrow && previousElement)) {
      let element = isDownArrow ? nextElement : previousElement;
      addClassNameAndScrollTo(element, false);
    }
  };

  return (
    <ul className="tag-message-tags" ref={ulRef}>
      {getFilteredTags().map((tag) => (
        <TagMessageDropdownList
          key={tag.key}
          payload={payload}
          tag={tag}
          openDropDown={openDropDown}
          addClassNameAndScrollTo={addClassNameAndScrollTo}
          searchInputRef={searchInputRef}
          tagInputRef={tagInputRef}
          conversationId={conversationId}
          requestSingleConversationAndAddTagToIt={requestSingleConversationAndAddTagToIt}
          setTagKey={setTagKey}
        />
      ))}
    </ul>
  );
};

export default TagDropdown;
