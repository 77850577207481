import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const WidgetSetupContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 3.2rem;
  align-items: start;
  padding-bottom: 8rem;

  ${mq.sm} {
    grid-template-columns: 1fr 1fr;
  }
`

export const ContainerButtons = styled.div<{
  maxWidth?: number
  hasNoMargin?: boolean
  marginTop?: number
}>`
  display: flex;
  align-items: center;
`
