import React from 'react'
import {
  Content,
  Layout,
  Left,
  Right,
} from 'src/features/ui/components/Layout/styled'
import { Title } from 'src/features/ui/components/Typography'
import { MobileOnly } from 'src/features/ui/components/Visibility'
import { WebsitePreview } from 'src/features/wizard/setup/components/WebsitePreview'
import { WidgetIconPreview } from 'src/features/wizard/setup/components/WidgetIconPreview'
import { WidgetSetup } from 'src/features/wizard/setup/components/WidgetSetup'
import { WizardHeader } from 'src/features/wizard/shared/components/WizardHeader'
import {
  MobileTitleContainer,
  WebsitePreviewContainer,
} from 'src/pages/WidgetSetup/styled'

export const WidgetSetupPage = () => (
  <Layout>
    <WizardHeader currentStep={1} currentStepMobile={1} />
    <MobileTitleContainer>
      <Title>Personalize your widget</Title>
    </MobileTitleContainer>
    <Content>
      <Left>
        <WidgetSetup />
      </Left>
      <Right shouldShowOnMobile shouldCenterOnlyMobileContent>
        <WebsitePreviewContainer>
          <WebsitePreview />
        </WebsitePreviewContainer>
        <MobileOnly>
          <WidgetIconPreview />
        </MobileOnly>
      </Right>
    </Content>
  </Layout>
)
