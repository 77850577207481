import React from 'react';
import { SubFilterIcon } from "../../commons/Icons.js";
import {
    MAIN_GROUP_IDS
  } from "../commons/ContactConstants";
import {getGroupType, getGroupIconSrc} from '../commons/ContactsUtility.js';

const SeenSegments = (props) => {
    const {segments} = props;
    const getFilterIcon = (group) => {
        let groupId = group.key;
        if (group && group.references) {
          let iconColor = group.avatarColor || '#ff6836';
          let groupType = getGroupType(group);
          let imageSrc = getGroupIconSrc(groupType);
          let subIcon = !MAIN_GROUP_IDS.includes(groupId) ? (
            <i style={{ backgroundColor: iconColor }}>
              <SubFilterIcon />
            </i>
          ) : (
            ""
          );
          return (<figure><img src={imageSrc}/>{subIcon}</figure>);
        }
        return "";
      };
    const getSegments = () =>{
        let segmentItems = [];
        Object.keys(segments).forEach(segmentKey => {
          if(MAIN_GROUP_IDS.includes(segmentKey))
              segmentItems.unshift(<a href="#">{getFilterIcon(segments[segmentKey])}{segments[segmentKey].groupName}</a>);
          else {
              let groupType = getGroupType(segments[segmentKey]);
              if(groupType!="segment")
                segmentItems.push(<a href="#">{getFilterIcon(segments[segmentKey])}{segments[segmentKey].groupName}</a>);
          }
        })
        return segmentItems;
    }
    const peopleSegments = getSegments();
    return (
        <section class="segment-list">
            <label style={{"display" : (peopleSegments.length > 0 ? "block" : "none")}}>{peopleSegments.length == 1 ? `Seen in 1 segment` : `Seen in ${peopleSegments.length} segments`}</label>
            <div>
              { peopleSegments }
            </div>
        </section>
    )
}

export default SeenSegments;