import React, { useRef, useState, useEffect } from "react";
import TagMessageOption from "./TagMessageOption.jsx";
import { useSelector } from "react-redux";
import { sortObjectArrayInAscendingOrder } from "../../../commons/Utility.js";

const MessageOptions = (props) => {
  const { tags, messageData, showIcon, hideIcon } = props;
  const messageOptionsRef = useRef("");
  const [isActive, setIsActive] = useState(false);

  const toggleElement = () => {
    setIsActive(!isActive);
  };

  const getCsUserConversationElement = () => {
    return document.getElementsByClassName("cs-user-conversation")[0];
  };

  const hideDropdown = () => {
    setIsActive(false);
  };

  useEffect(() => {
    const CS_USER_CONVERSATION = getCsUserConversationElement();
    CS_USER_CONVERSATION.addEventListener("scroll", hideDropdown);
    return () => {
      CS_USER_CONVERSATION.removeEventListener("scroll", hideDropdown);
    };
  }, [isActive]);

  const { tagDetails } = useSelector((state) => ({
    tagDetails: state.TagReducer,
  }));
  const getUnusedTags = () => {
    let tagMap = tagDetails.tagMap;
    let unusedTags = [];
    let tempTagMap = {};
    if (tags.length) {
      tags.filter((tag) => {
        unusedTags = [];
        tempTagMap = {};
        for (const key in tagMap) {
          if (key !== tag) {
            unusedTags.push(tagMap[key]);
            tempTagMap[key] = tagMap[key];
          }
        }
        tagMap = tempTagMap;
      });
    } else {
      for (const key in tagMap) {
        unusedTags.push(tagMap[key]);
      }
    }
    return sortObjectArrayInAscendingOrder(unusedTags,"name");
  };
  return (
    <div
      className={`message-options ${isActive ? 'open' : ''}`}
      ref={messageOptionsRef}
    >
      <TagMessageOption
        showIcon={showIcon}
        messageId={messageData.key}
        conversationId={messageData.conversationId}
        tags={getUnusedTags()}
        messageOptionsRef={messageOptionsRef}
        hideIcon={hideIcon}
        isActive={isActive}
        toggleElement={toggleElement}
        setIsActive={setIsActive}
      />
    </div>
  );
};

export default MessageOptions;
