import { WidgetInstallationStatus } from 'src/features/wizard/setup/api/types'

export enum EnumPostEmailErrors {
  MISSING_REQUIRED_PARAM = 'Missing required param',
}

export interface IWebScrapResponse {
  isActive: boolean
  installationStatus: WidgetInstallationStatus
}

export interface IEmailResponse {
  data: {
    success: boolean
  }
  response: boolean
}
