import React from "react";
import { useSelector } from "react-redux";
import { Link, browserHistory } from "react-router";
import { ACTIVE, EMPTY_STRING } from "../../commons/Constants";
import { MyProfileActiveIcon, MyProfileIcon } from "../../commons/Icons";
import {
  getUserPermissionUtility,
  isUserRoleExist,
} from "../../commons/Utility";
import { SettingsLayoutLoader } from "../commons/loaders";
import MoreOptions from "./MoreOptions";
import MyAccountOptions from "./MyAccountOptions";

const SettingsMenu = (props) => {
  const { handleSubMenu, params, location } = props;
  const { userReducer } = useSelector((state) => ({
    userReducer: state.UserReducer,
  }));

  const isMyProfileActive = location.pathname.includes("/settings/myprofile")
    ? ACTIVE
    : EMPTY_STRING;

  if (isUserRoleExist(userReducer))
    return (
      <div class="settings-left-menu">
        <ul class="cs-nav-list">
          <li
            onClick={(event) => {
              browserHistory.push(
                "/app/" + params.projectkey + "/settings/myprofile"
              );
              handleSubMenu(event);
            }}
            onKeyDown={ () => {} }
            className={isMyProfileActive}
          >
            <Link
              to={"/app/" + params.projectkey + "/settings/myprofile"}
              onClick={handleSubMenu}
            >
              My Profile
            </Link>
            <figure>
              <MyProfileIcon />
              <MyProfileActiveIcon />
            </figure>
          </li>
        </ul>
        {getUserPermissionUtility("Notifications") ? (
          <div class="sub-title-menu">My Account</div>
        ) : (
          ""
        )}
        <MyAccountOptions
          params={params}
          location={location}
          handleSubMenu={handleSubMenu}
        />
        <div class="sub-title-menu">More</div>
        <MoreOptions />
      </div>
    );
  return <SettingsLayoutLoader />;
};

export default SettingsMenu;
