import React from "react";
import {
  getFormattedTimeFromMinutes,
  getConvertedTime,
  isDST,
} from "../../commons/Utility.js";
import WorkingHoursComponent from "./workingHours/WorkingHoursComponent.jsx";
import { useGetWorkingHours } from "../hooks/useGetWorkingHours.js";
var ctzc = require("country-tz-currency");

const WorkingHoursContainer = (props) => {
  const getShiftTimingsAndAvailablility = (timezoneId) => {
    let message = "";
    let offset = ctzc.getTzById(timezoneId)
      ? ctzc.getTzById(timezoneId).offset
      : 0;
    offset =
      isDST(timezoneId) && ctzc.getTzById(timezoneId)
        ? offset + ctzc.getTzById(timezoneId).dstOffset
        : offset;
    let days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
    let date = getConvertedTime(offset);
    let currentDay = date.getDay();
    if (weekDayConfig) {
      for (let day in weekDayConfig) {
        let endTime = weekDayConfig[day].endTime;
        let startTime = weekDayConfig[day].startTime;
        if (days[currentDay] === day && weekDayConfig[day].isChecked) {
          let currentTime = date.getHours() * 60 + date.getMinutes();
          if (currentTime > endTime)
            message =
              "Offline - Shift ended at " +
              getFormattedTimeFromMinutes(endTime);
          else if (currentTime < startTime)
            message =
              "Offline - Shift starts at " +
              getFormattedTimeFromMinutes(startTime);
          else
            message =
              "Online - Shift ends at " + getFormattedTimeFromMinutes(endTime);
        }
      }
      if (!message) {
        message = "Offline";
      }
    }
    return message;
  };
  const convertWorkingHoursResponse = (weekDayConfig, roundTheClock) => {
    let workingHours = {};
    if (weekDayConfig) {
      Object.keys(weekDayConfig).forEach((item) => {
        let hours = weekDayConfig[item].hours;
        if (hours) {
          let startTime = hours[0].start;
          let endTime = hours[0].end;
          workingHours[item] = { startTime: startTime, endTime: endTime };
          workingHours[item].startTime = hours[0].start;
          workingHours[item].endTime = hours[0].end;
          workingHours[item].isChecked = true;
        }
      });
    }

    if (Object.keys(workingHours).length <= 0) {
      let endTime = 0;
      if (roundTheClock) endTime = 1439;
      else endTime = 1410;
      let weekdays = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
      for (let day in weekdays) {
        workingHours[weekdays[day]] = { startTime: 0, endTime: endTime };
        workingHours[weekdays[day]].isChecked = roundTheClock
          ? roundTheClock
          : false;
      }
    }

    return workingHours;
  };

  let projectId = "LS-" + props.projectKey;
  let { userId, accountId } = props;
  const { workingHours } = useGetWorkingHours(userId, accountId, projectId);
  let createOrUpdate =
    workingHours &&
    (workingHours.weekDayConfig ||
      workingHours.roundTheClock ||
      (workingHours.hoursList && workingHours.hoursList[0]))
      ? "Edit"
      : "Create";
  let roundTheClock =
    (workingHours && workingHours.roundTheClock) ||
    (workingHours.hoursList &&
      workingHours.hoursList[0] &&
      workingHours.hoursList[0].roundTheClock)
      ? true
      : false;
  let weekDayConfig =
    workingHours.hoursList && workingHours.hoursList[0]
      ? convertWorkingHoursResponse(
          workingHours.hoursList[0].weekDayConfig,
          roundTheClock
        )
      : convertWorkingHoursResponse("");
  let timezone =
    workingHours && workingHours.hoursList && workingHours.hoursList[0]
      ? workingHours.hoursList[0].timezone
      : "";

  if (
    !workingHours.hoursList &&
    (workingHours.weekDayConfig || roundTheClock)
  ) {
    timezone = workingHours.timezone;
    weekDayConfig = convertWorkingHoursResponse(
      workingHours.weekDayConfig,
      roundTheClock
    );
  }
  let timezoneArray = timezone.split(" ");
  let timezoneId = timezoneArray[timezoneArray.length - 1];

  let shiftTimingsMessage = getShiftTimingsAndAvailablility(timezoneId);

  return (
    <WorkingHoursComponent
      createOrUpdate={createOrUpdate}
      message={shiftTimingsMessage}
      displayText={props.displayText}
      projectKey={props.projectKey}
      weekDayConfig={weekDayConfig}
      isFromTeamsPage={props.isFromTeamsPage}
    />
  );
};

export default WorkingHoursContainer;
