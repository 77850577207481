import React, { useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { FallBackIcon, TemplateActiveIcon } from "../../../app/commons/Icons";
import {
  isEmailDesktopType,
  isAnnoucementDesktopType,
  isAnnouncementCampaign,
} from "../../commons/Utility";

const CampaignTemplateButton = (props) => {
  const { updateFallback, decoratedText, updateWidthAndHeigt } = props;
  const { campaignDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
  }));
  const templateRef = useRef(null);
  useEffect(() => {
    console.log("on mount clicked");

    let fallbackText =
      (campaignDetails.selectedCampaign &&
        campaignDetails.selectedCampaign.fallBackValues &&
        campaignDetails.selectedCampaign.fallBackValues[
          fallBackValuesMap[decoratedText]
        ]) ||
      "";
    console.log("fallbackText length: " + fallbackText.length);

    if (fallbackText.length < 3 && decoratedText !== 'First Name') templateRef.current.click();
    return () => {
      updateFallback("");
    };
  }, []);

  console.log("props Childer :" + props.children);
  const fallBackValuesMap = {
    "First Name": "{{firstName}}",
    "Full Name": "{{fullName}}",
    "Last Name": "{{lastName}}",
    "Company Name": "{{companyName}}",
  };

  const getProperElement = (event) => {
    if (
      (event.target.nodeName === "SPAN" &&
        event.target.className === "fallback") ||
      event.target.className === "ide"
    ) {
      return event.target.children[1];
    } else if (
      event.target.nodeName === "SPAN" &&
      event.target.className === ""
    ) {
      return event.target.parentElement;
    } else if (
      event.target.className &&
      event.target.className.toString().includes &&
      event.target.className.toString().includes("SVG")
    ) {
      return event.target.nextSibling;
    } else {
      return event.target.parentElement;
    }
  };

  const updateValue = (e) => {
    var rect = getProperElement(e).getBoundingClientRect();

    let left = rect.right - 425;
    let documentScrollTop = document.documentElement.scrollTop;
    let top = rect.top + documentScrollTop;
    let allowRepliesHeight = 110.867;
    top =
      top -
      (campaignDetails.selectedCampaign.typeOfMessage === "EMAIL" ? 611 : 488);

    if (isAnnouncementCampaign(campaignDetails)) top = top - allowRepliesHeight;
    if (
      isEmailDesktopType(campaignDetails) ||
      isAnnoucementDesktopType(campaignDetails)
    )
      left += 240;
    updateFallback(decoratedText);
    updateWidthAndHeigt(left, top);
  };

  const getFallbackPillclassName = () => {
    let fallBackValueData =
      (campaignDetails.selectedCampaign &&
        campaignDetails.selectedCampaign &&
        campaignDetails.selectedCampaign.fallBackValues &&
        campaignDetails.selectedCampaign.fallBackValues[
          fallBackValuesMap[decoratedText]
        ]) ||
      "";

    return fallBackValueData.length >= 3 ? "ide" : "fallback";
  };

  const getIcon = () => {
    let fallBackValueData =
      (campaignDetails.selectedCampaign &&
        campaignDetails.selectedCampaign &&
        campaignDetails.selectedCampaign.fallBackValues &&
        campaignDetails.selectedCampaign.fallBackValues[
          fallBackValuesMap[decoratedText]
        ]) ||
      "";
    return fallBackValueData.length >= 3 ? (
      <TemplateActiveIcon
        contenteditable={false}
        style={{
          position: "absolute",
          left: "8px",
          width: "6px",
          top: "2px",
          opacity: "0.7",
        }}
      />
    ) : (
      <FallBackIcon
        contenteditable={false}
        style={{
          height: "20px",
          width: "10px",
          opacity: "1",
          top: "-1px",
          left: "5px",
          position: "absolute",
        }}
      />
    );
  };

  return (
    <span
      ref={templateRef}
      className={getFallbackPillclassName()}
      data-offset-key={props.offsetKey}
      onClick={updateValue}
    >
      {getIcon()}
      {/*<span contentEditable={false}>{fallBackValuesMap[decoratedText]}</span>*/}
      {props.children}
    </span>
  );
};

export default CampaignTemplateButton;
