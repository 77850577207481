import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Link } from "react-router";
import SearchableDropDown from "../common/SearchableDropdown.jsx";
import * as Utilities from "../../commons/Utility.js";
import Mousetrap from "mousetrap";
import { browserHistory } from "react-router";
import { SEARCH_FILTERS , SEARCH_FILTERS_PLACEHOLDER } from "../../commons/Constants";
import { RemoveIcon, BlueDownArrowIcon, TagSearchIcon } from "../../commons/Icons.js";
import { getConversationCount, showConversationFilter } from "../../actions/ConversationInfoAction.js";

class ConversationHeader extends Component {
  constructor() {
    super();
    this.state = {
      dropDownVisibilityClass: "",
      dropDownTypeClass: "dwn img-dwn search-dwn",
    };
    this.searchConversationShrtCut = this.searchConversationShrtCut.bind(this);
    this.getUserStaffEntity = this.getUserStaffEntity.bind(this);
    this.getCurrentFilterText = this.getCurrentFilterText.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
    this.openFilter = this.openFilter.bind(this);
  }

  componentWillMount() {
    Mousetrap.bind("s", this.searchConversationShrtCut);
  }

  componentWillUnmount() {
    Mousetrap.unbind("s");
  }
  searchConversationShrtCut(e) {
    this.openFilter();
    e.preventDefault();
  }

  getUserStaffEntity() {
    let conversationListData = this.props.dashboardData;
    let staffMap = conversationListData.state.staffMap;
    let userId =
      conversationListData.state.user && conversationListData.state.user.data
        ? conversationListData.state.user.data.id
        : "";
    let userStaffEntity = undefined;
    if (staffMap[userId]) {
      userStaffEntity = Object.assign({}, staffMap[userId]);
      userStaffEntity.firstName = "Yours";
      userStaffEntity.lastName = "";
      userStaffEntity.fullName = "Yours";
    }
    return userStaffEntity;
  }
  getCurrentFilterText() {
    if (
      this.props.dashboardData &&
      this.props.dashboardData.props &&
      this.props.dashboardData.props.params
    ) {
      let chatStatus = this.props.dashboardData.props.params.chatStatus;
      let assignedTo = this.props.dashboardData.props.params.assignedTo;
      let pathName = document.location.pathname;
      if (assignedTo !== "All" && chatStatus === "O" && pathName.includes(assignedTo))
        return SEARCH_FILTERS.MY_CHATS;
      if (chatStatus === "C" && pathName.includes(assignedTo))
        return SEARCH_FILTERS.MY_RESOLVED_CHATS;
      if (assignedTo !== "All" && chatStatus === "M" && pathName.includes(assignedTo))
        return SEARCH_FILTERS.MY_MENTIONED_CHATS;
      if (assignedTo !== "All" && chatStatus === "P" && pathName.includes(assignedTo))
        return SEARCH_FILTERS.MY_PENDING_CHATS;
      if (chatStatus === "A") return SEARCH_FILTERS.ALL_CHATS;
      if (chatStatus === "O") return SEARCH_FILTERS.OPEN_CHATS;
      if (chatStatus === "C") return SEARCH_FILTERS.RESOLVED_CHATS;
      if (chatStatus === "R") return SEARCH_FILTERS.RECEPTIONIST;
      if (chatStatus === "P")  return SEARCH_FILTERS.PENDING_CHATS;
      if (
        (chatStatus === "T" || Utilities.isAdvancedFilterSearch(chatStatus)) &&
        (this.props.dashboardData.props.location.state ||
          this.props.dashboardData.props.ConversationReducer.searchText)
      ) {
        let searchText = [];
        let searchTextVal = this.props.dashboardData.props.location.state
          ? this.props.dashboardData.props.location.state
              .nonEncryptedSearchTextFilter
          : this.props.dashboardData.props.ConversationReducer.searchText;
        if (chatStatus === "SN")
          searchText.push(
            <div>
              Customer name is <b>{searchTextVal}</b>
            </div>
          );
        else if (chatStatus === "SC")
          searchText.push(
            <div>
              Company name is <b>{searchTextVal}</b>
            </div>
          );
        else if (Utilities.isTagSearch(chatStatus))
          searchText.push(
            <div>
              <TagSearchIcon />
              <b>#{searchTextVal}</b>
            </div>
          );
        else searchText.push(searchTextVal);
        return searchText;
      }
    }
  }


  openFilter() {
    let projectKey =
      Utilities.isValidString(this.props.dashboardData.props.user.selectedProjectKey) 
      ? this.props.dashboardData.props.user.selectedProjectKey
      : this.props.dashboardData.props.params.projectkey;
      let prevPath = document.location.pathname;
    let pathName = document.location.pathname;
    this.props.dispatch(getConversationCount(`LS-${projectKey}`));
    if (pathName.includes("guests")){
      prevPath="guests"
    }
    else if (pathName.includes("queue")){
      prevPath="queue"
    }
    localStorage.setItem("prevPath", prevPath);
    this.props.dispatch(showConversationFilter());
  }

  closeSearch() {
    let projectKey = this.props.dashboardData.props.params.projectkey;
    let userId = this.props.dashboardData.props.user.data.id;
    browserHistory.push({
      pathname: "/app/" + projectKey + "/chat/dashboard/D/O/N/" + userId,
    });
  }

  render() {
    let currentFilteredText = this.getCurrentFilterText();
    return (
      <header>
        <BlueDownArrowIcon className="search-icon" onClick={this.openFilter}/> 
        {currentFilteredText && currentFilteredText !== "My Chats" ? (
          <RemoveIcon
            className="search-close-icon"
            onClick={this.closeSearch}
          />
        ) : (
          ""
        )}
        <h2>Conversations</h2>
        <span>{this.getCurrentFilterText()}</span>
      </header>
    );
  }
}
export default ConversationHeader;
