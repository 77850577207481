import React from "react";
import { useChatConfigurationEntity } from "../../../app/settings/hooks/useChatConfigurationEntity";

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  let projectId = "LS-" + props.projectkey;

  const chatConfiguration = useChatConfigurationEntity(projectId);
  let color = chatConfiguration.chatConfiguration.widgetThemeColor;
  return (
    <a target="_blank" href={url} style={{ color: `#${color}` }}>
      {props.children}
    </a>
  );
};

export default Link;
