import * as R from 'ramda'
import { EnumStatus, IAuthState } from 'src/features/authorization/store/types'

export const selectIsSigningUp = (state: IAuthState) =>
  R.contains<EnumStatus>(EnumStatus.signingUp)(state.status) ||
  R.contains<EnumStatus>(EnumStatus.registeringSessionStep1)(state.status)

export const selectIsLoggedIn = (state: IAuthState) => Boolean(state.userId)

export const selectAuthError = (state: IAuthState) => state.error?.message

export const selectWidgetId = (state: IAuthState) => state.widgetId

export const selectUserId = (state: IAuthState) => state.userId
