import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TeamWorkingHoursActiveIcon,
  TeamWorkingHoursIcon,
  TeamPermissionActiveIcon,
  TeamPermissionIcon,
  TeamProfileIcon,
  TeamProfileActiveIcon,
  TeamErrorIcon,
  TeamDropDownIcon,
  TickIconBlue,
  ConnectChatIcon,
  ConnectChatIconActive,
} from "../../../commons/Icons";
import { Link, browserHistory } from "react-router";
import MapComponent from "../../../components/chat/MapComponent.jsx";
import {
  getCurrentFormattedTime,
  isValidPhoneNumber,
  createUUId,
  isValidName,
  isValidString,
  sortListByProperty
} from "../../../commons/Utility";
import * as StaffContactHook from "../Hook/StaffContactHook";
import * as AccountProjectHook from "../Hook/AccountProjectHook";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import {
  ID_USER_ROLE_MAP,
  USER_ROLE_ID_MAP,
  ID_USER_SKILL_MAP,
  USER_SKILL_ID_MAP,
  ACTIVE_EDIT_TAB
} from "../../../commons/Constants";
import * as Utility from "../../../commons/Utility";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import TeamEditConnectConfigComponent from "./TeamEditConnectConfigComponent";
import { updateConnectConfigRequest } from "../Action/UserProjectMappingAction";
import * as VoiceboxActions from "../../../actions/VoiceboxAction";
import ReactTooltip from "react-tooltip";

const TeamEditProfileComponent = (props) => {
  const [activeEditTab, setActiveEditTab] = useState(ACTIVE_EDIT_TAB.PERMISSIONS);

  const [roleState, setRoleState] = useState({
    showRoleDropDown: false,
    selectedRole: "",
  });
  const [skillState, setSkillState] = useState({
    showSkillDropDown: false,
    selectedSkill: "",
  });
  const [projectListState, setProjectListState] = useState({
    showProjectDropDown: false,
    selectedProjectList: [],
    initialRender: false,
    selectedConnectProject: [],
    connectConfigurationPayload: {},
  });
  const [selectedPermission, setSelectedPermission] = useState("");
  const [dropDownIdx, setDropDownIdx] = useState(-1);
  const [inputState, setInputState] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const [isLocalStateChanged, setIsLocalStateChanged] = useState(false);
  const dispatch = useDispatch();
  const userNameRef = useRef(null);
  const designationRef = useRef(null);
  const rolesRef = useRef([]);
  const skillsRef = useRef([]);
  const projectsRef = useRef([]);
  const connectChatsRef = useRef([]);
  const emailRef = {};
  const phoneRef = {};
  const activeProjectId = props.parentProps.params.projectkey;

  useEffect(() => {
    if (
      !projectListState.initialRender &&
      props.activeUserProjects.length > 0
    ) {
      initialRenderProjectList();
      initialRenderConnectProjects();
    }
  });

  useEffect(() => {
    setDefaultProjectsList();
  }, [props.activeUserProjects]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    if (roleState.showRoleDropDown && rolesRef.current[dropDownIdx])
      rolesRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });
    else if (skillState.showSkillDropDown && skillsRef.current[dropDownIdx])
      skillsRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });
    else if (
      projectListState.showProjectDropDown &&
      projectsRef.current[dropDownIdx]
    )
      projectsRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });
    else if (projectListState.showProjectDropDown && connectChatsRef.current[dropDownIdx])
     connectChatsRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    dropDownIdx,
    roleState.showRoleDropDown,
    skillState.showSkillDropDown,
    projectListState.showProjectDropDown,
  ]);

  useEffect(() => {
      ReactTooltip.rebuild();
  })

  const handleHover = (e) => {
    if (dropDownIdx > -1 && dropDownIdx != e.target.dataset.index && activeEditTab !== ACTIVE_EDIT_TAB.CONNECT_CHATS )
      setDropDownIdx(e.target.dataset.index);
  };

  const handleKeyDown = (e) => {
    let isEscapeKey = e.keyCode === 27 || e.which === 27;
    if (
      roleState.showRoleDropDown ||
      skillState.showSkillDropDown ||
      projectListState.showProjectDropDown
    ) {
      let isUpArrow = e.keyCode === 38 || e.which === 38;
      let isDownArrow = e.keyCode === 40 || e.which === 40;
      let isEnterKey = e.keyCode === 13 || e.which === 13;
      let accountProjects = props.accountProjects || [];
      let index = dropDownIdx;
      let elements = [];
      let maxLength = 0;
      if (roleState.showRoleDropDown) {
        maxLength = Object.keys(ID_USER_ROLE_MAP).length;
        elements = rolesRef.current;
        if (isEscapeKey)
          setRoleState((prevState) => {
            return { ...prevState, showRoleDropDown: false };
          });
      } else if (skillState.showSkillDropDown) {
        maxLength = Object.keys(ID_USER_SKILL_MAP).length;
        elements = skillsRef.current;
        if (isEscapeKey)
          setSkillState((prevState) => {
            return { ...prevState, showSkillDropDown: false };
          });
      } else if (projectListState.showProjectDropDown) {
        maxLength = accountProjects.length;
        elements = projectsRef.current;
        if (isEscapeKey)
          setProjectListState((prevState) => {
            return { ...prevState, showProjectDropDown: false };
          });
      }
      if (isUpArrow && dropDownIdx > 0) index--;
      else if (isDownArrow && dropDownIdx < maxLength - 1) index++;
      else if (isEnterKey && elements[index]) {
        elements[index].click();
        e.preventDefault();
      }
      setDropDownIdx(index);
    }
    if (
      !roleState.showRoleDropDown &&
      !skillState.showSkillDropDown &&
      !projectListState.showProjectDropDown &&
      isEscapeKey
    )
      hideEditProfilePopup();
  };

  const initialRenderProjectList = () => {
    let projectList = projectListState.selectedProjectList;
    projectList = AccountProjectHook.getUserProjectIdList(
      props.activeUserProjects
    );
    setProjectListState((prevState) => {
      return {
        ...prevState,
        selectedProjectList: projectList,
        initialRender: true,
      };
    });
  };

  const initialRenderConnectProjects = () => {
    let projectList = AccountProjectHook.getConnectEnabledUserProjectMappings(props.activeUserProjects);
    setProjectListState((prevState) => {
      return {
        ...prevState,
        selectedConnectProject: projectList
      }
    })
  }

  const toggleAddWidget = () => {
    setProjectListState((prevState) => {
      return {
        ...prevState,
        showProjectDropDown: !projectListState.showProjectDropDown,
      };
    });
    setDropDownIdx(0);
  };

  const staffRoleRef = useRef(null);
  if (props.activeStaffContact.hasOwnProperty("linkedContactMethods")) {
    props.activeStaffContact.linkedContactMethods.forEach(function (
      linkedContactMethod,
      index
    ) {
      if (linkedContactMethod.type == "email") {
        if (emailRef.hasOwnProperty(linkedContactMethod.id))
          emailRef[linkedContactMethod.id] = useRef(null);
      }
    });
    props.activeStaffContact.linkedContactMethods.forEach(function (
      linkedContactMethod,
      index
    ) {
      if (linkedContactMethod.type == "phone") {
        if (phoneRef.hasOwnProperty(linkedContactMethod.id))
          phoneRef[linkedContactMethod.id] = useRef(null);
      }
    });
  }

  const setDefaultProjectsList = () => {
    let activeUserProjects = props.activeUserProjects;
    let projectList = projectListState.selectedProjectList;
    // if(activeUserProjects.length > 0 &&  projectList.length == 0 )
    // {
    //     projectList = AccountProjectHook.getUserProjectIdList(activeUserProjects);
    //     setProjectListState(prevState => {
    //         return { ...prevState, selectedProjectList: projectList }
    //     }) ;
    // }

    let role =
      roleState.selectedRole == "" && props.activeUserProjects.length > 0
        ? AccountProjectHook.getStaffRole(props.activeUserProjects)
        : roleState.selectedRole;
    let skill =
      skillState.selectedSkill == "" && props.activeUserProjects.length > 0
        ? AccountProjectHook.getStaffSkill(props.activeUserProjects)
        : skillState.selectedSkill;

    if (!!role && !roleState.selectedRole) {
      setRoleState((prevState) => {
        return { ...prevState, selectedRole: role };
      });
    }

    if (!!skill && !skillState.selectedSkill) {
      setSkillState((prevState) => {
        return { ...prevState, selectedSkill: skill };
      });
    }
  };

  const isPermissionsChanged = () => {
    if (activeEditTab === "permissions") {
      let role = roleState.selectedRole;
      let skill = skillState.selectedSkill;
      let projectList = AccountProjectHook.getUserProjectIdList(
        props.activeUserProjects
      );
      if (role != AccountProjectHook.getStaffRole(props.activeUserProjects))
        return true;
      if (skill != AccountProjectHook.getStaffSkill(props.activeUserProjects))
        return true;
      if (getSelectedProjectListDifference(projectList))
          return true;
    } else {
      if (Object.keys(projectListState.connectConfigurationPayload).length > 1) return true;
    }
  };

  const getSelectedProjectListDifference = (projectList) => {
    return (Utility.getArrayDifference(
      projectList,
      projectListState.selectedProjectList
    ).length > 0 ||
    Utility.getArrayDifference(
      projectListState.selectedProjectList,
      projectList
    ).length > 0)
  }
  const handleClickInComponent = (e) => {
    if (
      roleState.showRoleDropDown == true &&
      e.target.dataset.elementname != "role"
    )
      setRoleState((prevState) => {
        return { ...prevState, showRoleDropDown: false };
      });

    if (
      skillState.showSkillDropDown == true &&
      e.target.dataset.elementname != "skill"
    )
      setSkillState((prevState) => {
        return { ...prevState, showSkillDropDown: false };
      });

    if (
      projectListState.showProjectDropDown == true &&
      e.target.dataset.elementname != "project" &&
      e.target.parentElement.id != "white-tick-icon"
    )
      setProjectListState((prevState) => {
        return { ...prevState, showProjectDropDown: false };
      });
  };

  const toggleDropDown = (e) => {
    let role = "";
    if (e.target.dataset.elementname == "role") {
      setRoleState((prevState) => {
        return { ...prevState, showRoleDropDown: !roleState.showRoleDropDown };
      });
    }

    if (e.target.dataset.elementname == "skill") {
      setSkillState((prevState) => {
        return {
          ...prevState,
          showSkillDropDown: !skillState.showSkillDropDown,
        };
      });
    }
    setDropDownIdx(0);
  };

  const selectDropDownElement = (e) => {
    if (e.target.dataset.elementname == "role") {
      let role = "";
      role = ID_USER_ROLE_MAP[e.target.dataset.value];

      if (
        roleState.selectedRole == "Owner" &&
        role != "Owner" &&
        getIsCurrentStaffOnlyOwnerOfProject()
      ) {
        setShowWarning(true);

        setRoleState((prevState) => {
          return { ...prevState, showRoleDropDown: false };
        });
        return;
      }

      setRoleState((prevState) => {
        return { ...prevState, showRoleDropDown: false, selectedRole: role };
      });
      setDropDownIdx(0);
    }

    if (e.target.dataset.elementname == "skill") {
      let skill = "";
      skill = ID_USER_SKILL_MAP[e.target.dataset.value];
      setSkillState((prevState) => {
        return { ...prevState, showSkillDropDown: false, selectedSkill: skill };
      });
      setDropDownIdx(0);
    }
    console.info(
      "e.elementname:",
      e.target.dataset.elementname,
      "e.t:",
      e.target
    );

    if (
      e.target.dataset.elementname == "project" ||
      e.target.parentElement.id == "white-tick-icon"
    ) {
      if (activeEditTab === ACTIVE_EDIT_TAB.CONNECT_CHATS) {
        let projectId = e.target.dataset.value;
        projectId = e.target.parentElement.id === "white-tick-icon" ? e.target.parentElement.dataset.value : projectId;
        if (!projectId) return;
        let { selectedConnectProject, connectConfigurationPayload } = projectListState;    
        let projectIndex = selectedConnectProject.indexOf(projectId);
        let payload = {};
        if (props.activeUserProjects && props.activeUserProjects[0]) {
          let userId = props.activeUserProjects[0].userKey;
          payload["userId"] = userId;
        }
        if (projectIndex > -1) {
          selectedConnectProject.splice(projectIndex, 1);
          if (!connectConfigurationPayload[projectId])
            payload[projectId] = false;
          else delete connectConfigurationPayload[projectId];
        } else {
          selectedConnectProject.push(projectId);
          if (
            !!connectConfigurationPayload[projectId] ||
            connectConfigurationPayload[projectId] === undefined
          )
            payload[projectId] = true;
          else delete connectConfigurationPayload[projectId];
        }
        connectConfigurationPayload = Object.assign(connectConfigurationPayload, payload);
        setProjectListState((prevState) => {
          return {
            ...prevState,
            showProjectDropDown: true,
            selectedConnectProject: selectedConnectProject,
            connectConfigurationPayload: connectConfigurationPayload,
          };
        });
      }
      if (activeEditTab !== ACTIVE_EDIT_TAB.CONNECT_CHATS) {
        let { dataset : { value }, parentElement: {id} } = e.target;
        let projectId = value;
        if (id == "white-tick-icon")
          projectId = value;
        if (!projectId) return;
        let { selectedProjectList } = projectListState;
        let projectIndex = selectedProjectList.indexOf(projectId);
        if (projectIndex > -1 && selectedProjectList.length == 1) {
          return;
        }
        projectIndex > -1
          ? selectedProjectList.splice(projectIndex, 1)
          : selectedProjectList.push(projectId);
        setProjectListState((prevState) => {
          return {
            ...prevState,
            showProjectDropDown: true,
            selectedProjectList: selectedProjectList,
          };
        });
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickInComponent);
    return () => {
      document.removeEventListener("mousedown", handleClickInComponent);
    };
  });

  const getEventHandler = () => {
    let eventHandler = {};
    // eventHandler["switchActiveStaff"] = switchActiveStaff;
    return eventHandler;
  };

  const setRefs = (el) => {
    if (el && el.dataset) {
      let type = el.dataset.elementname;
      let newRef = {};
      if (type === "role") newRef = rolesRef;
      else if (type === "skill") newRef = skillsRef;
      else if (type === "project") newRef = projectsRef;
      else if (type === ACTIVE_EDIT_TAB.CONNECT_CHATS) newRef = connectChatsRef;
        newRef.current.push(el);
    }
  };

  const hideEditProfilePopup = () => {
    resetValues();
    browserHistory.push({
      pathname:
        "/app/" +
        props.parentProps.params.projectkey +
        "/directory/myteam/profile/" +
        props.parentProps.params.staffid,
    });
  };

  const resetValues = () => {
    setProjectListState((prevState) => {
      return { ...prevState, initialRender: false };
    });
  };

  const getUserName = () => {
    let activeStaff = props.activeStaff;
    if (activeStaff.lastName)
      return activeStaff.firstName + " " + activeStaff.lastName;
    else return activeStaff.firstName;
  };

  const getTeamEditProfileEmails = () => {
    let emailList = [];

    if (props.activeStaffContact.hasOwnProperty("linkedContactMethods"))
      props.activeStaffContact.linkedContactMethods.forEach(function (
        linkedContactMethod,
        index
      ) {
        if (linkedContactMethod.type == "email") {
          emailList.push(
            <div class="cs-input-field">
              <label>Email</label>
              <input
                className="fs-exclude"
                type="text"
                ref={emailRef[linkedContactMethod.id]}
                onInput={() => {
                  setInputState(emailRef[linkedContactMethod.id].current.value);
                }}
                data-id={linkedContactMethod.value}
                data-type="email"
                Value={linkedContactMethod.value}
              />
              <span>
                <TeamErrorIcon /> Email is not Valid{" "}
              </span>
            </div>
          );
        }
      });
    return emailList;
  };

  const getTeamEditProfilePhones = () => {
    let phoneList = [];

    if (props.activeStaffContact.hasOwnProperty("linkedContactMethods"))
      props.activeStaffContact.linkedContactMethods.forEach(function (
        linkedContactMethod,
        index
      ) {
        if (linkedContactMethod.type == "phone") {
          phoneList.push(
            <div
              class={
                index == 0 ? "cs-input-field private" : "cs-input-field public"
              }
            >
              <label>Phone</label>
              <input
                className="fs-exclude"
                type="text"
                ref={phoneRef[linkedContactMethod.id]}
                onInput={() => {
                  setInputState(phoneRef[linkedContactMethod.id].current.value);
                }}
                data-id={linkedContactMethod.value}
                data-type="email"
                Value={linkedContactMethod.value}
              />
              <span>
                <TeamErrorIcon />
                Phone Number is not Valid{" "}
              </span>
            </div>
          );
        }
      });
    return phoneList;

    // let emailList = [];

    // emailList.push(
    //             <div class="cs-input-inlineblock-field">
    //                     <div class="cs-input-field private">
    //                         <label>Phone</label>
    //                         <input type="text" value="1700 255 1485" />
    //                         <span><TeamErrorIcon />Phone is not Valid </span>
    //                     </div>
    //                     <div class="cs-input-field public">
    //                         <label>Phone</label>
    //                         <input type="text" value="1700 255 1485" />
    //                         <span><TeamErrorIcon />Phone is not Valid </span>
    //                     </div>
    //            </div>
    //     )
    //     return emailList;
  };

  const getTeamEditProfile = () => {
    let name = getUserName();
    let designation = isValidString(props.activeStaffContact.title)
      ? props.activeStaffContact.title
      : "";

    return (
      <section
        class="cs-popup-team-info"
        style={{ display: activeEditTab == "profile" ? "block" : "none" }}
      >
        <div class="cs-input-field">
          <label>Full Name</label>

          <input
            className="fs-exclude"
            ref={userNameRef}
            type="text"
            onInput={() => {
              setInputState(userNameRef.current.value);
            }}
            Value={name}
          />
          <span>
            {" "}
            <TeamErrorIcon /> Name is not Valid{" "}
          </span>
        </div>
        <div class="cs-input-field">
          <label>Designation</label>
          <input
            className="fs-exclude"
            ref={designationRef}
            type="text"
            onInput={() => {
              setInputState(designationRef.current.value);
            }}
            Value={designation}
          />
          <span>
            <TeamErrorIcon /> Role is not Valid{" "}
          </span>
        </div>
        <div class="cs-input-inlineblock-field">
          {getTeamEditProfileEmails()}
          {getTeamEditProfilePhones()}
        </div>
      </section>
    );
  };

  const getAddedWidgetsDisplay = () => {
    let addedWidgets;
    let selectedProjects = AccountProjectHook.fillMappingsByProjectIds(
      projectListState.selectedProjectList,
      props.accountProjects
    );
    let projectNameWidgetColorMap = {};
    let projectNameWidgetColorMapClone = {};
    projectNameWidgetColorMap = AccountProjectHook.getProjectNameWidgetColorMap(
      props.activeUserProjects,
      props.accountMappingProjects,
      props.accountMappingConfigs
    );
    projectNameWidgetColorMapClone = Object.assign(
      {},
      projectNameWidgetColorMap
    );
    let projectNameAry = [];
    selectedProjects.forEach((selectedProject) => {
      projectNameAry.push(selectedProject.name);
    });
    let projectName = projectNameAry.length > 0 ? projectNameAry[0] : "";
    let morenumElement =
      projectNameAry.length > 1 ? (
        <span
          className="cs-porject-more"
          data-elementname="project"
          onClick={toggleAddWidget}
        >
          {" "}
          {projectNameAry.length - 1} more
        </span>
      ) : (
        ""
      );
    addedWidgets = (
      <aside className="cs-project-wrp">
        <div className="cs-porject-clip">
          <i
            style={{
              backgroundColor: projectNameWidgetColorMapClone[projectName],
            }}
          ></i>
          <cite
            style={{
              backgroundColor: projectNameWidgetColorMapClone[projectName],
            }}
          ></cite>
          <span
            className="fs-exclude"
            data-elementname="project"
            onClick={toggleAddWidget}
            style={{ cursor: "pointer" }}
          >
            {projectName}
          </span>
        </div>
        {morenumElement}
      </aside>
    );
    return addedWidgets;
  };

  const getConnectAddedWidgetsDisplay = () => {
    let addedWidgets;
    let selectedProjects = AccountProjectHook.fillMappingsByProjectIds(
      projectListState.selectedConnectProject,
      props.accountProjects
    );
    let projectNameWidgetColorMap = {};
    let projectNameWidgetColorMapClone = {};
    projectNameWidgetColorMap = AccountProjectHook.getProjectNameWidgetColorMap(
      props.activeUserProjects,
      props.accountMappingProjects,
      props.accountMappingConfigs
    );
    projectNameWidgetColorMapClone = Object.assign(
      {},
      projectNameWidgetColorMap
    );
    let projectNameAry = [];
    selectedProjects.forEach((selectedProject) => {
      projectNameAry.push(selectedProject.name);
    });
    let projectName = projectNameAry.length > 0 ? projectNameAry[0] : "";
    let morenumElement =
      projectNameAry.length === 0 ? (
        <p
          className="cs-project-wrp-p cs-placeholder-txt"
          data-elementname="project"
          onClick={toggleAddWidget}
          onKeyDown={ () => {} }
        >
          {" "}
          Select Widgets
        </p>
      ) : projectNameAry.length > 1 ?  (
        <span
          className="cs-porject-more"
          data-elementname="project"
          onClick={toggleAddWidget}
          onKeyDown={ () => {} }
        >
          {" "}
          {projectNameAry.length - 1} more
        </span>
      ) : ""
    addedWidgets = projectNameAry.length != 0 ? (
      <aside className="cs-project-wrp">
        <div className="cs-porject-clip">
          <i
            style={{
              backgroundColor: projectNameWidgetColorMapClone[projectName],
            }}
          ></i>
          <cite
            style={{
              backgroundColor: projectNameWidgetColorMapClone[projectName],
            }}
          ></cite>
          <span
            className="fs-exclude"
            data-elementname="connectChats"
            onClick={toggleAddWidget}
            onKeyDown={ () => {} }
            style={{ cursor: "pointer" }}
          >
            {projectName}
          </span>
        </div>
        {morenumElement}
      </aside>
    ) : (<aside className="cs-project-wrp">
    {morenumElement}
  </aside>);
    return addedWidgets;
  };

  const getTeamEditPermissions = () => {
    let role =
      roleState.selectedRole == "" && props.activeUserProjects.length > 0
        ? AccountProjectHook.getStaffRole(props.activeUserProjects)
        : roleState.selectedRole;
    let skill =
      skillState.selectedSkill == "" && props.activeUserProjects.length > 0
        ? AccountProjectHook.getStaffSkill(props.activeUserProjects)
        : skillState.selectedSkill;
    let showProjectDropDown = projectListState.showProjectDropDown;
    let projectNameAry = AccountProjectHook.getStaffProjects(
      props.activeUserProjects,
      props.accountProjects
    );
    let activeStaffContact = props.activeStaffContact;
    let isOnlyOwnerOfProject = activeStaffContact.hasOwnProperty(
      "isOnlyOwnerOfProject"
    )
      ? activeStaffContact.isOnlyOwnerOfProject
      : false;

    let roleDDClass = "";
    let skillDDClass = "";

    let roleDDOpenClass = " cs-dropdwn ";

    if (!Utility.isValidString(role)) {
      role = "Select Role";
      roleDDClass = "cs-placeholder-txt";
    }

    if (!Utility.isValidString(skill)) {
      skill = "Select Skill";
      skillDDClass = "cs-placeholder-txt";
    }

    return (
      <section
        class="cs-popup-team-info cs-overflow-inherit"
        style={{
          display:
            activeEditTab == "" || activeEditTab == "permissions"
              ? "block"
              : "none",
        }}
      >
        <div class="cs-widget-config">
          <div>
            <label>Role</label>
            <p>A team members role determines what actions they can perform</p>
          </div>
          <div
            class={
              roleDDClass +
              " cs-dropdwn " +
              (roleState.showRoleDropDown ? " open " : "")
            }
          >
            <p onClick={toggleDropDown} data-elementname="role">
              {role}
            </p>
            <TeamDropDownIcon />
            <div>
              <ul>{getStaffRoleDropDown()}</ul>
            </div>
          </div>
        </div>
        <div class="cs-widget-config">
          <div>
            <label>Skill</label>
            <p>
              A team members skill determines the number of chats they can
              answer simultaneously
            </p>
          </div>
          <div
            class={
              skillDDClass +
              (skillState.showSkillDropDown
                ? " cs-dropdwn open"
                : " cs-dropdwn ")
            }
          >
            <p onClick={toggleDropDown} data-elementname="skill">
              {skill}
            </p>
            <TeamDropDownIcon />
            <div>
              <ul>{getStaffSkillDropDown()}</ul>
            </div>
          </div>
        </div>
        <div class="cs-widget-config">
          <div>
            <label>Assign Widgets</label>
            <p>Choose the widgets from which chats will be assigned</p>
          </div>
          <div
            class={
              "cs-dropdwn cs-dropdwn-checkbox " +
              (showProjectDropDown ? " open" : "")
            }
          >
            <p onClick={toggleAddWidget} data-elementname="project">
              .
            </p>
            <TeamDropDownIcon />
            {getAddedWidgetsDisplay()}
            <div>
              <ul>{getAddWidgetDropdown()}</ul>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const getTeamEditWorkingHours = () => {
    // return(<section class="cs-popup-team-info" style= { { visibility: activeEditTab == 'workinghours'? 'visible':'hidden'}}> Working Hours</section>);
    return (
      <section
        class="cs-popup-team-info"
        style={{ display: activeEditTab == "workinghours" ? "block" : "none" }}
      >
        <div class="cs-popup-team-wrk-hours">
          <div class="cs-dropdwn">
            <label>Working Hours</label>
            <span>
              Visible to your team members to understand your availability
            </span>
            <p>Weekdays - 08:00 AM to 05:00 PM</p>
            <TeamDropDownIcon />
            <div>
              <ul>
                <li>
                  <a href="javascript:void(0)">List Option1</a>
                </li>
                <li>
                  <a href="javascript:void(0)">List Option2</a>
                </li>
                <li>
                  <a href="javascript:void(0)">List Option3</a>
                </li>
                <li>
                  <a href="javascript:void(0)">List Option4</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="cs-working-hours-form">
            <a href="#">Apply All</a>
            <ul>
              <li>
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" checked />
                    <cite></cite>Monday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" checked="" />
                    <cite></cite>Tuesday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" checked="" />
                    <cite></cite>Wednesday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" checked="" />
                    <cite></cite>Thursday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" checked="" />
                    <cite></cite>Friday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="disable">
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" disabled />
                    <cite></cite>Saturday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="disable">
                <div class="cs-checkbox-field">
                  <label>
                    <TickIconBlue />
                    <input type="checkbox" name="checkbox" disabled />
                    <cite></cite>Sunday
                  </label>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cs-dropdwn">
                  <p>08:00 AM</p>
                  <TeamDropDownIcon />
                  <div>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">List Option1</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option2</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">List Option4</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  };

  const getFlagIcon =() => {
    let activeStaff = props.activeStaff;
    let countryCode = activeStaff.countryCode ? activeStaff.countryCode : "";
    if(countryCode) {
        let countryCodeClass = countryCode.toLowerCase();
        return <i className={"flag-ele "+countryCodeClass}></i>;
    }
    return "";
  }

  const getMapComponent = () => {
    let activeStaff = props.activeStaff;
    let activeStaffContact = props.activeStaffContact;
    let latitude = activeStaff.lattitude ? activeStaff.lattitude : 0;
    let longitude = activeStaff.longitude ? activeStaff.longitude : 0;
    let contact = { latitude: latitude, longitude: longitude };
    let city = activeStaff.city ? activeStaff.city : "";
    city = city.length > 0 ? city.charAt(0).toUpperCase() + city.slice(1) : "";
    let countryCode = activeStaff.countryCode ? activeStaff.countryCode : "";
    let time = "";
    let contentClass = "";
    let mapElement;

    if (!!latitude && !!longitude) {
      city = city + ", ";
      time = getCurrentFormattedTime();
      mapElement = (
        <MapComponent
          contact={contact}
          isFromProfilePage={true}
          latitude={latitude}
          longitude={longitude}
        />
      );
    } else {
      contentClass = "not-found";
      city = "";
      countryCode = "";
      mapElement = (
        <img src={"https://app.chatsupport.co/images/map-placeholder.png"} />
      );
    }

    return (
      <div class="cs-profile-map fs-exclude">
        {mapElement}
        <span className={contentClass}>
          {city}
          {countryCode} {getFlagIcon()}
          <br/>
          <p>{time}</p>
        </span>
      </div>
    );
  };

  const getStaffRoleDropDown = () => {
    let staffRoleToRender = [];
    let index = 0;
    for (let key in ID_USER_ROLE_MAP) {
      staffRoleToRender.push(
        <li>
          <a
            data-value={key}
            ref={setRefs}
            data-elementtype="dropdown"
            data-index={index}
            data-elementname="role"
            onClick={selectDropDownElement}
            href="javascript:void(0)"
            className={index == dropDownIdx ? "select" : ""}
            onMouseOver={handleHover}
          >
            {" "}
            {ID_USER_ROLE_MAP[key]}
          </a>
        </li>
      );
      index++;
    }

    return staffRoleToRender;
  };

  const getStaffSkillDropDown = () => {
    let staffSkilloRender = [];
    let index = 0;
    for (let key in ID_USER_SKILL_MAP) {
      staffSkilloRender.push(
        <li>
          <a
            data-value={key}
            data-index={index}
            ref={setRefs}
            data-elementtype="dropdown"
            data-elementname="skill"
            onClick={selectDropDownElement}
            href="javascript:void(0)"
            className={index == dropDownIdx ? "select" : ""}
            onMouseOver={handleHover}
          >
            {" "}
            {ID_USER_SKILL_MAP[key]}
          </a>
        </li>
      );
      index++;
    }

    return staffSkilloRender;
  };

  const saveUserChanges = () => {
    let { connectConfigurationPayload } = projectListState;
    let staffRole = USER_ROLE_ID_MAP[roleState.selectedRole];
    let staffSkill = USER_SKILL_ID_MAP[skillState.selectedSkill];
    let updatedMap = {
      id: props.activeStaffContact.id,
      staffRole: staffRole,
      staffSkill: staffSkill,
      widgetIds: projectListState.selectedProjectList,
    };
    let mappingsLength =
      props.activeUserProjects && props.activeUserProjects.length
        ? props.activeUserProjects.length
        : 0;
    let selectedProjects = projectListState.selectedProjectList.length;
    if (mappingsLength != selectedProjects) {
      if (mappingsLength > selectedProjects)
        Event("", "Team Assign Chats", "Widgets Removed", "");
      else Event("", "Team Assign Chats", "Widget Added", "");
    }
    if (activeEditTab === ACTIVE_EDIT_TAB.CONNECT_CHATS) {
      dispatch(updateConnectConfigRequest(connectConfigurationPayload));
      dispatch(VoiceboxActions.showVoicebox({ message: "Updating" }));
      setProjectListState((prevState) => {
        return {
          ...prevState,
          connectConfigurationPayload: {},
        };
      });
    }
    else
      props.updateUserProfile(updatedMap);
    hideEditProfilePopup();
  };

  const getClassName = (index, selectedProjectList, project) => {
    let className = "";
    if (activeEditTab === ACTIVE_EDIT_TAB.CONNECT_CHATS) {
      if (selectedProjectList.indexOf(project.projectKey) > -1) className += "active ";
      return className;
    }
    if (selectedProjectList.indexOf(project.key) > -1) className += "active ";
    if (index == dropDownIdx) className += "select ";
    return className;
  };

  const getProjectNameDataTip = (project) => {
    if(project.name && project.name.length > 24)
      return project.name;
    return "";
  }

  const getAddWidgetDropdown = () => {
    let addedWidgetsDropdown = [];
    let accountProjects = props.accountProjects || [];
    let selectedProjectList = projectListState.selectedProjectList;
    accountProjects = sortListByProperty(accountProjects, "name");
    accountProjects.forEach(function (project, index) {
      addedWidgetsDropdown.push(
        <li>
          <a
            style={{ zIndex: 1000 }}
            data-index={index}
            data-value={project.key}
            data-elementtype="dropdown"
            data-elementname="project"
            ref={setRefs}
            onClick={selectDropDownElement}
            href="javascript:void(0)"
            className={getClassName(index, selectedProjectList, project)}
            onMouseOver={handleHover}
            onFocus={ () => {} }
            data-tip={getProjectNameDataTip(project)}
            data-place="left"
          >
            <cite
              style={{ zIndex: 999 }}
              data-value={project.key}
              data-elementtype="dropdown"
              data-elementname="project"
            >
              <TickIconBlue
                style={{ zIndex: 999 }}
                data-value={project.key}
                data-elementtype="dropdown"
                data-elementname="project"
              />
            </cite>
            {project.name}
          </a>
        </li>
      );
    });
    return addedWidgetsDropdown;
  };

  

  const getConnectWidgetDropDown = () => {
    let addedWidgetsDropdown = [];
    let accountProjects = props.activeUserProjects || [];
    let selectedConnectProject = projectListState.selectedConnectProject;
    accountProjects = sortListByProperty(accountProjects, "name");
    accountProjects = AccountProjectHook.getSanitizedData(props.accountProjects, accountProjects);
    accountProjects.forEach(function (project, index) {
      addedWidgetsDropdown.push(
        <li>
          <a
            style={{ zIndex: 1000 }}
            data-index={index}
            data-value={project.projectKey}
            data-elementtype="dropdown"
            data-elementname="project"
            ref={setRefs}
            onClick={selectDropDownElement}
            onKeyDown={ () => {} }
            onFocus={ () => {} }
            className={getClassName(index, selectedConnectProject, project)}
            onMouseOver={handleHover}
            data-tip={getProjectNameDataTip(project)}
            data-place="left"
          >
            <cite
              style={{ zIndex: 999 }}
              data-value={project.projectKey}
              data-elementtype="dropdown"
              data-elementname="project"
            >
              <TickIconBlue
                style={{ zIndex: 999 }}
                data-value={project.projectKey}
                data-elementtype="dropdown"
                data-elementname="project"
              />
            </cite>
            {project.name}
          </a>
        </li>
      );
    });
    return addedWidgetsDropdown;
    
  }

  const getIsCurrentStaffOnlyOwnerOfProject = () => {
    let activeStaffContact = props.activeStaffContact;
    let isOnlyOwnerOfProject = activeStaffContact.hasOwnProperty(
      "isOnlyOwnerOfProject"
    )
      ? activeStaffContact.isOnlyOwnerOfProject
      : false;

    return isOnlyOwnerOfProject;
  };

  const getUserImageElement = () => {
    let activeStaff = props.activeStaff;
    let errorUrl =  "https://app.chatsupport.co/images/user-white-icon.svg";
    let staffName = getUserName();
    let firstLetter =
      staffName && staffName.length > 0 ? staffName.charAt(0) : "";
    let avatarColor =
      activeStaff && activeStaff.avatarColor
        ? activeStaff.avatarColor
        : "#A79580";
    let imageElement;

    if (
      activeStaff.photoUrl &&
      activeStaff.photoUrl.indexOf("null") == -1 &&
      Utility.isValidURL(activeStaff.photoUrl)
    )
      imageElement = (
        <figure className="cs-edit-prof fs-exclude">
          <img src={activeStaff.photoUrl} onError={errorUrl} />
        </figure>
      );
    else
      imageElement = (
        <figure className="cs-edit-prof fs-exclude">
          {" "}
          <cite style={activeStaff ? { backgroundColor: avatarColor } : {}}>
            {firstLetter}
          </cite>
        </figure>
      );

    return imageElement;
  };

  const getEditProfileOuterLayer = () => {
    let activeStaff = props.activeStaff;
    let activeStaffContact = props.activeStaffContact;

    let name = getUserName();
    let designation = isValidString(activeStaffContact.title)
      ? activeStaffContact.title
      : "";
    let photourl = "";
    photourl = activeStaff.photoUrl
      ? activeStaff.photoUrl
      : "https://app.chatsupport.co/images/user-white-icon.svg";

    return (
      <div>
        <div
          class="cs-popup-model cs-popup-edit-team"
          style={{ visibility: props.showEditProfile ? "visible" : "hidden" }}
        >
          <header>
            <h2>Edit</h2>
            <cite onClick={hideEditProfilePopup}>&times;</cite>
          </header>
          <section>
            {getMapComponent()}
            {getUserImageElement()}

            <div class="cs-edit-team-profile">
              <h3 className="fs-exclude">{name}</h3>
              <h4 className="fs-exclude">{designation}</h4>
              <ul class="cs-navigation-list">
                {/* <li class= {activeEditTab == "profile"? "active":""}  onClick={()=> setActiveEditTab("profile")}>
                                <figure>
                                    <TeamProfileIcon/>
                                    <TeamProfileActiveIcon/>
                                </figure>
                                <a href="#">Profile</a>
                            </li> */}
                <li
                  class={
                    activeEditTab == "" || activeEditTab == "permissions"
                      ? "active"
                      : ""
                  }
                  onClick={() => setActiveEditTab("permissions")}
                >
                  <figure>
                    <TeamPermissionIcon />
                    <TeamPermissionActiveIcon />
                  </figure>
                  <a href="#">Permissions</a>
                </li>
                <li id={ACTIVE_EDIT_TAB.CONNECT_CHATS}
                  class={
                    activeEditTab == "" || activeEditTab == ACTIVE_EDIT_TAB.CONNECT_CHATS
                      ? "active"
                      : ""
                  }
                  style={{
                    display: Utility.isConnectAuthAllowed(),
                  }}
                  onClick={() => setActiveEditTab(ACTIVE_EDIT_TAB.CONNECT_CHATS)}
                  onKeyDown={ () => {} }
                >
                  <figure>
                    <ConnectChatIcon />
                    <ConnectChatIconActive />
                  </figure>
                  <a href="#">Connect Chats</a>
                </li>
                {/* <li class= {activeEditTab == "workinghours"? "active":""} onClick={()=> setActiveEditTab("workinghours")}>
                                <figure>
                                    <TeamWorkingHoursIcon/>
                                    <TeamWorkingHoursActiveIcon/>
                                </figure>
                                <a href="#">Working hours</a>
                            </li> */}
              </ul>
            </div>
            {getTeamEditProfile()}
            {getTeamEditPermissions()}
            {getTeamEditWorkingHours()}
            <TeamEditConnectConfigComponent
              activeEditTab={activeEditTab}
              projectListState={projectListState}
              toggleAddWidget={toggleAddWidget}
              getConnectAddedWidgetsDisplay={getConnectAddedWidgetsDisplay}
              getConnectWidgetDropDown={getConnectWidgetDropDown}
            />
          </section>
          <footer>
            <button
              class="cs-btn cs-decline-btn"
              onClick={hideEditProfilePopup}
            >
              Cancel
            </button>
            <button
              className={
                isPermissionsChanged()
                  ? "cs-btn cs-primary-btn "
                  : "cs-btn cs-disabled-btn "
              }
              disabled={!isPermissionsChanged() ? true : false}
              onClick={saveUserChanges}
            >
              Save
            </button>
          </footer>
        </div>
        <div
          class="cs-popup-model cs-popup-delete"
          style={{ display: showWarning ? "block" : "none" }}
        >
          <header>
            <h2>Info</h2>
            <cite
              onClick={() => {
                setShowWarning(false);
              }}
            >
              &times;
            </cite>
          </header>
          <section>
            <div class="cs-model-msg">
              You're the only owner for this widget. Assign someone from your
              team member as a owner and try changing your role.
            </div>
          </section>
          <footer>
            <a
              href="javascript:void(0)"
              class="cs-btn cs-primary-btn"
              onClick={() => {
                setShowWarning(false);
              }}
            >
              Ok
            </a>
          </footer>
        </div>
      </div>
    );
  };
  return getEditProfileOuterLayer();
};
export default TeamEditProfileComponent;