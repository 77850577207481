import { call, put } from "redux-saga/effects";
import cookie from "react-cookie";
import axios from "axios";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { browserHistory } from "react-router";
import {
  responseEmailOptOutPreferences,
  optOutPreferencesFetchFailed,
  responseUpdateEmailOptOut,
  optOutPreferencesUpdateFailed,
  optOutPreferencesNotFound,
} from "../actions/EmailOptOutAction";

export function* fetchEmailOptOutPreferences(visitorInfo) {
  const optOutGetUrl = `/emailPreferences/get/${visitorInfo.visitorId}`;
  let apiResponse;
  try {
    yield put(
      VoiceboxActions.showVoicebox({
        message: "fetching preferences...",
        showFullScreenLoader: true,
      })
    );
    apiResponse = yield call(Ajax.getUnauthenticated, optOutGetUrl);
    let { Exception, data } = apiResponse;

    if (!Exception && data) {
      let emailOptOutpreferences = data;
      let { unsubscribedList } = emailOptOutpreferences;
      emailOptOutpreferences.unsubscribedList = new Set(unsubscribedList);
      yield put(responseEmailOptOutPreferences(emailOptOutpreferences));
    } else if (Exception == "NOT_FOUND") {
      yield put(optOutPreferencesNotFound());
    }

    yield put(VoiceboxActions.hideVoicebox());
  } catch (error) {
    yield put(optOutPreferencesFetchFailed());
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Error Fetching Preferences!",
        showFullScreenLoader: true,
        dismissAfter: 3000,
      })
    );
    console.error("Error while fetching preferences", error);
  }
}

export function* updateEmailOptOutPreferences(preferencesInfo) {
  let optOutUpdateUrl = "/emailPreferences/update";
  let { emailOptOutPreferences, optOutType } = preferencesInfo;

  let apiResponse;
  try {
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Updating...",
        showFullScreenLoader: true,
      })
    );
    apiResponse = yield call(
      Ajax.postUnauthenticated,
      optOutUpdateUrl,
      emailOptOutPreferences
    );
    let { Exception, data } = apiResponse;

    if (!Exception && data) {
      let emailOptOutpreferences = data;
      let { unsubscribedList } = emailOptOutpreferences;
      emailOptOutpreferences.unsubscribedList = new Set(unsubscribedList);
      yield put(responseUpdateEmailOptOut(emailOptOutpreferences));

      if (optOutType == "save")
        yield put(
          VoiceboxActions.showVoicebox({
            message: "Preferences Updated!",
            dismissAfter: 3000,
            showFullScreenLoader: true,
          })
        );
      else if (optOutType == "unsubscribe")
        yield put(
          VoiceboxActions.showVoicebox({
            message: "Unsubcribed Successfuly!",
            showFullScreenLoader: true,
            dismissAfter: 3000,
          })
        );
      else if (optOutType == "optOutAll")
        yield put(
          VoiceboxActions.showVoicebox({
            message: "Opted Out of all emails!",
            dismissAfter: 3000,
            showFullScreenLoader: true,
          })
        );
      else if (optOutType == "optBackIn")
        yield put(
          VoiceboxActions.showVoicebox({
            message: "Email preferences Restored!",
            dismissAfter: 5000,
            showFullScreenLoader: true,
          })
        );
    }
  } catch (error) {
    yield put(optOutPreferencesUpdateFailed());
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Error Updating Preferences!",
        dismissAfter: 3000,
        showFullScreenLoader: true,
      })
    );
    console.error("Error while Updating preferences", error);
  }
}

export function* deleteEmailOptOutPreferences(preferencesInfo) {
  var { emailOptOutPreferences } = preferencesInfo;
  const optOutDeleteUrl =
    "/emailPreferences/delete/" + emailOptOutPreferences.key;

  let apiResponse;
  try {
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Updating...",
        showFullScreenLoader: true,
      })
    );
    apiResponse = yield call(Ajax.deleteUnauthenticated, optOutDeleteUrl);
    let { Exception, data } = apiResponse;

    if (!Exception && data) {
      emailOptOutPreferences.unsubscribedList = new Set([]);
      emailOptOutPreferences.unsubscribeFromAll = false;

      yield put(responseUpdateEmailOptOut(emailOptOutPreferences));
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Preferences Updated!",
          showFullScreenLoader: true,
          dismissAfter: 3000,
        })
      );
    }
  } catch (error) {
    yield put(optOutPreferencesUpdateFailed());
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Error Updating Preferences!",
        showFullScreenLoader: true,
        dismissAfter: 3000,
      })
    );
    console.error("Error while fetching preferences", error);
  }
}
