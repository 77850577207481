import { IFormValues } from 'src/features/wizard/setup/forms/Setup/data'

export const UPDATE_VALUES = 'wizard/setup/update-values' as const

export const updateValues = (payload: IFormValues) => ({
  type: UPDATE_VALUES,
  payload,
})

export const REQUEST_UPDATE_PREVIEW = 'wizard/preview/request-update-preview' as const
export const REQUEST_UPDATE_PREVIEW_SUCCESS = 'wizard/preview/request-update-preview-success' as const

export const requestUpdatePreview = (websiteUrl: string) => ({
  type: REQUEST_UPDATE_PREVIEW,
  payload: {
    websiteUrl,
  },
})

export const requestUpdatePreviewSuccess = (previewUrl: string) => ({
  type: REQUEST_UPDATE_PREVIEW_SUCCESS,
  payload: {
    previewUrl,
  },
})

export enum EnumWidgetUpdateType {
  returningFlow = 'returningFlow',
}

export const REQUEST_UPDATE_WIDGET = 'wizard/setup/request-update-widget' as const
export const REQUEST_UPDATE_WIDGET_SUCCESS = 'wizard/setup/request-update-widget-success' as const

export const requestUpdateWidget = (
  values: IFormValues,
  type?: EnumWidgetUpdateType
) => ({
  type: REQUEST_UPDATE_WIDGET,
  payload: {
    values,
    type,
  },
})

export const requestUpdateWidgetSuccess = (type?: EnumWidgetUpdateType) => ({
  type: REQUEST_UPDATE_WIDGET_SUCCESS,
  payload: {
    type,
  },
})

export const REQUEST_UPLOAD_ICON = 'wizard/setup/request-upload-icon' as const
export const REQUEST_UPLOAD_ICON_SUCCESS = 'wizard/setup/request-upload-icon-success' as const

export const requestUploadIcon = (file: File, color: string) => ({
  type: REQUEST_UPLOAD_ICON,
  payload: {
    file,
    color,
  },
})

export const requestUploadIconSuccess = (
  iconUrl: string,
  iconColor: string
) => ({
  type: REQUEST_UPLOAD_ICON_SUCCESS,
  payload: {
    iconUrl,
    iconColor,
  },
})

export const SET_ERROR = 'wizard/setup/error' as const

export const setError = (message: string) => ({
  type: SET_ERROR,
  payload: {
    message,
  },
})

export const INITIALIZE = 'wizard/setup/initialize' as const
export const initializeWidgetSetup = (
  formValues: IFormValues,
  previewUrl: string,
  iconUrl: string,
  iconColor: string
) => ({
  type: INITIALIZE,
  payload: {
    formValues,
    previewUrl,
    iconUrl,
    iconColor,
  },
})

export type ISetupActions = ReturnType<
  | typeof updateValues
  | typeof requestUpdatePreview
  | typeof requestUpdatePreviewSuccess
  | typeof requestUpdateWidget
  | typeof requestUpdateWidgetSuccess
  | typeof requestUploadIcon
  | typeof requestUploadIconSuccess
  | typeof setError
  | typeof initializeWidgetSetup
>
