import React, { useState, useEffect } from "react";
import { getUserPermissionUtility } from "../../commons/Utility";

const NotificationSettingsComponent = (props) => {
  const [activeMode, setActiveMode] = useState("");

  useEffect(() => {
    if (props.activeMode) console.log("The active mode is ", props.activeMode);
    if (activeMode !== props.activeMode) setActiveMode(props.activeMode);
  });

  const handleClick = () => {
    if (!getUserPermissionUtility("Notifications")) return false;
    let newMode =
      event.target.nodeName === "DIV"
        ? event.target.id
        : event.target.parentElement.id;
    setActiveMode(newMode);
    props.updateNotification(event);
  };
  const getClassName = (mode) => {
    let style = "cs-radio-form ";
    if (mode === activeMode) style += "active";
    return style;
  };

  return (
    <section class="edit-profile">
      <h3>Notifications Preferences</h3>
      <p style={{ margin: "0px 0px 40px 0px" }}>
        Choose events that you should be notified of
      </p>
      <div
        className={getClassName("All")}
        id="All"
        onClick={() => handleClick()}
      >
        <label>All</label>
        <p>
          Receive notifications for all incoming messages, @mentions and chat
          assigns
        </p>
      </div>
      <div
        className={getClassName("Focus")}
        id="Focus"
        onClick={() => handleClick()}
      >
        <label>Focus Mode</label>
        <p>Receive notifications for @mentions and chat assigns only</p>
      </div>
      <div
        className={getClassName("None")}
        id="None"
        onClick={() => handleClick()}
      >
        <label>None</label>
        <p>You will not receive notifications for any activity</p>
      </div>
    </section>
  );
};
export default NotificationSettingsComponent;
