import { columnAlise } from "../../commons/PeopleESIndex.js";
import useEvents from "./useEvents.js";
import useCustomFields from "./useCustomFields.js";
import useTags from "./useTags.js";
import {PRODUCTION_SUPPORT_PROJECTS, UI_DROPPED_CUSTOM_FIELDS, UI_DROPPED_DEFAULT_FIELDS} from '../../commons/Constants'

const useProperties = (projectkey) => {
  const projectId = `LS-${projectkey}`;
  const { events } = useEvents(projectId);
  const { customFields } = useCustomFields(projectId);
  const { tags } = useTags(projectId);
  const customProperties = {};
  const customPropertiesIdByName = {};
  const eventProperties = {};
  let defaultColumns = {};
  let properties = {};
  let columns = {};

  for (let key in customFields) {
    customProperties[key] = customFields[key].name;
    customPropertiesIdByName[customFields[key].name] = key;
  }

  for (let key in events) eventProperties[key] = events[key].eventName;

  let columnAliseClone = {};

  //This if else has to be removed in future when we migrate the companyName information to default property
  if (!PRODUCTION_SUPPORT_PROJECTS.includes(projectId)) {
    columnAliseClone = Object.assign(columnAlise);
  } else {
    Object.keys(columnAlise).forEach((column, index) => {
      if (index == 3) {
        columnAliseClone[customPropertiesIdByName["companyName"]] =
          "Company Name";
        delete customProperties[customPropertiesIdByName["companyName"]];
      } else columnAliseClone[column] = columnAlise[column];

      UI_DROPPED_CUSTOM_FIELDS.forEach( droppedCustomField => {
        if(customPropertiesIdByName[droppedCustomField])
            delete customProperties[customPropertiesIdByName[droppedCustomField]];
      })
      UI_DROPPED_DEFAULT_FIELDS.forEach( droppedDefaultField => {
        if(columnAliseClone[droppedDefaultField])
          delete columnAliseClone[droppedDefaultField];
      })
    });
  }

  properties = { ...columnAliseClone, ...customProperties, ...eventProperties };
  columns = { ...columnAliseClone, ...customProperties };
  defaultColumns = { ...columnAliseClone };

  return { properties, columns, customFields, events, tags, defaultColumns };
};



export default useProperties;
