import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import CampaignSideBar from "./CampaignSideBar.jsx";
import Grid from "../../contacts/grid/Grid.jsx";
import * as PeopleActions from "../../actions/PeopleAction"
import { columnAlise } from "../../commons/PeopleESIndex";
import { browserHistory, Link } from "react-router";
import { BackArrowIcon } from "../../commons/Icons";
import Filters from "../../contacts/grid/Filters";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import NoCustomersLoader from "../../contacts/commons/NoCustomersLoader.jsx";
import * as loaders from "../../settings/commons/loaders";
import ReactTooltip from "react-tooltip";
import HeadingLoader from "../loaders/HeadingLoader";
import { BROADCAST_TYPE, TYPE_OF_CAMPAIGN_STATS } from "../../commons/Constants";

const DetailedCampaignStats = (props) => {
  const { type, projectkey, stats, campaignId } = props.params;
  const typeOfStats = window.atob(stats);
  const dispatch = useDispatch();
  const [showFilterSection, setShowFilterSection] = useState(true);
  const [showOptions] = useState(false)
  const [detailedStatsFetched, setDetailedStatsFetched] = useState(false);
  const [showMoreOptions] = useState(false);
  const { campaignDetails, PeopleReducer } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    PeopleReducer: state.PeopleReducer
  }));
  const { isCampaignFetched } = useBroadcastMessageStats(
    projectkey
  );
  const { contacts, query } = useSelector((state) => ({
    contacts: state.PeopleReducer,
    query: state.PeopleESQueryReducer,
  }));
  useEffect(() => {
    let statisticsPayload = {
      event: typeOfStats,
      broadcastMessageEntityId: campaignId,
      scroll_date: constructDateForPayload()
    }
    dispatch(PeopleActions.broadcastEventStatsRequest(statisticsPayload));
  }, []);

  useEffect(() => {
    dispatch(campaignActions.addSelectedCampaign({}));
  }, []);

  useEffect(() => {
    if (PeopleReducer.isBroadCastPeopleMapLoading === "loaded") {
      setDetailedStatsFetched(true);
    }

  }, [PeopleReducer.isBroadCastPeopleMapLoading]);


  useEffect(() => {
    return () => {
      dispatch(PeopleActions.broadcastEventStatsReset([]));
      dispatch(PeopleActions.broadcastEventStatsScroll(""));
    }
  }, []);

  const constructDateForPayload = () => {
    let currentDate = new Date();
    if (PeopleReducer.scrollDate === "" && campaignDetails.selectedCampaign.campaignType === "EMAIL") {
      currentDate = currentDate.getTime();
    } else if (PeopleReducer.scrollDate === "" && campaignDetails.selectedCampaign.campaignType !== "EMAIL") {
      currentDate = currentDate.getTime() * 1000;
    } else {
      currentDate = PeopleReducer.scrollDate;
    }
    return currentDate;
  }

  const getCampaignName = () => {
    if (detailedStatsFetched && isCampaignFetched) {
      const campaignName = campaignDetails.selectedCampaign.campaignName || "";
      return campaignName ? campaignName.trim() : "";
    } else {
      return "";
    }
  };


  const getHeaders = () => {
    let data = Object.keys(columnAlise);
    if (typeOfStats === "replied" && type === BROADCAST_TYPE.EMAIL) {
      data.push("reply");
    } else if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.NOT_DELIVERED && type === BROADCAST_TYPE.EMAIL) {
      data.push("Not Delivered");
    }
    return data;
  }

  const getHeaderInfo = () => {
    let columnValues = Object.keys(columnAlise);
    var headerInfo = [];
    if (typeOfStats === "replied" && type === BROADCAST_TYPE.EMAIL ) {
      columnValues.push("reply");
    } else if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.NOT_DELIVERED && type === BROADCAST_TYPE.EMAIL) {
      columnValues.push("Not Delivered");
    }
    headerInfo.push({ "name": "fullName", "prependImage": true, "imageSrc": "profileImage", "alias": "type" });
    for (let data of columnValues) {
      if (data !== "fullName") {
        headerInfo.push({ name: data });
      }
    }
    return headerInfo;
  }
  const getListing = () => {
    let broadcastPeopleArray = Object.assign({}, PeopleReducer.broadCastPeopleMap);
    let listing = [];
    if (campaignDetails.selectedCampaign.campaignName !== undefined) {
      listing = Object.values(broadcastPeopleArray);
      listing = listing.sort((a, b) => {
        return b.createdDate - a.createdDate;
      })
    }
    if (Object.keys(broadcastPeopleArray).length === 0 || PeopleReducer.isBroadCastPeopleMapLoading !== "loaded" || campaignDetails.selectedCampaign.campaignName === undefined) {
      listing.push({ isLoader: true });
      listing.push({ isLoader: true });
      listing.push({ isLoader: true });
    }
    return listing;
  }
  const loadMore = (e) => {
    let height = e.scrollTop + e.clientHeight;
    if (PeopleReducer.broadCastPeopleMap.length !== 0 &&
      height >= e.scrollHeight - 4 && PeopleReducer.scrollDate !== 0 && PeopleReducer.scrollDate !== ""
    ) {
      console.log("PeopleReducerscrollDate: " + PeopleReducer.scrollDate);
      let statisticsPayload = {
        event: typeOfStats,
        broadcastMessageEntityId: campaignId,
        scroll_date: PeopleReducer.scrollDate
      }
      dispatch(PeopleActions.broadcastEventStatsRequest(statisticsPayload));
    }
  };
  const onBack = () => {
    ReactTooltip.hide();
    browserHistory.push(
      "/app/" + props.params.projectkey + "/campaign/" + (campaignDetails.campaignSideBarType !== null ? campaignDetails.campaignSideBarType : "all")
    );
  };
  const getFetch = () => {
    console.log("VAlue return: " + ((PeopleReducer.isBroadCastPeopleMapLoading === "loaded") && PeopleReducer.broadCastPeopleMap.length === 0));
    return !((PeopleReducer.isBroadCastPeopleMapLoading === "loaded") && PeopleReducer.broadCastPeopleMap.length === 0)

  }
  const onSort = () => {
    console.log("Sorting");
  }
  const getLoader = () => {
    let groupLoaders = [];
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    groupLoaders.push(loaders.GroupCardLoader());
    return groupLoaders;
  };

  const getCampaignSideBar = () => {
    if (detailedStatsFetched && campaignDetails.selectedCampaign.campaignName) {
      return <CampaignSideBar {...props} />
    } else {
      return (
        <section className="cs-my-conversations people-search">
          <header>
            <h2>Campaigns</h2>
          </header>
          <ul className="cs-accordian-menu cs-campaigns-menu">{getLoader()}</ul>
        </section>
      );
    }
  }
  const getFilterLoader = () => {
    if (!getFetch()) {
      return <header></header>
    }
  }
  return (
    <div>
      <Fragment>
        {getCampaignSideBar()}
        <section className="placeholder-wrp">
          <div className='detailed-campaign-header'>
            <Link onClick={onBack}>
              {" "}
              <cite>{detailedStatsFetched && campaignDetails.selectedCampaign.campaignName ?
                <BackArrowIcon /> :
                <HeadingLoader></HeadingLoader>}</cite>{" "}
              <p>{getCampaignName()}</p>

            </Link>{" "}

            {getFetch() && detailedStatsFetched && campaignDetails.selectedCampaign.campaignName ?
              <div>

                <Filters {...props} contacts={contacts} query={query} showFilterSection={showFilterSection} setShowFilterSection={setShowFilterSection} showOptions={showOptions} showMoreOptions={showMoreOptions} typeOfStats={typeOfStats} />
              </div>
              : getFilterLoader()}

          </div>

          {getFetch() ?
            <Grid {...props} headers={getHeaders()} listing={getListing()} headerInfo={getHeaderInfo()} headerHeight={89} onScroll={loadMore} onSort={onSort} campaignId={campaignId} stats={stats} />
            : <NoCustomersLoader {...props} detailedStatsLoader={true} typeOfStats={typeOfStats} campaignStatsType={type} />}
        </section>
      </Fragment>
    </div>
  );
};

export default DetailedCampaignStats;
