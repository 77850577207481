import React, { Component } from "react";
import ReactDom from "react-dom";
import ChooseProfileComponent from "./ChooseProfileComponent.jsx";
import { connect } from "react-redux";
import { isAuthenticated } from "../../commons/Authenticator";
import { browserHistory } from "react-router";
import * as ProjectActions from "../actions/ProjectAction";
import * as UserActions from "../../actions/UserAction";
import { DEFAULT_PAGE_URL } from "../../commons/Constants";
import { getUserProject } from "../../components/myteam/Action/UserProjectMappingAction";

@connect((state) => ({
  projectReducer: state.ProjectReducer,
  userReducer: state.UserReducer,
  userProjectMappingReducer: state.UserProjectMappingReducer,
}))
class ChooseProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectReducer: {},
      isChatWidgetHidden: false,
      inviteeId: "",
    };
    this.checkAndHideWidget = this.checkAndHideWidget.bind(this);
    this.getFilteredProjects = this.getFilteredProjects.bind(this);
  }
  componentWillMount() {
    if (!this.props.userReducer.isFetched)
      this.props.dispatch(UserActions.getUser());

    this.checkAndRedirectPages(this.props);
    this.checkAndHideWidget(this.props);
    this.checkAndFetchUserProjectMappings(this.props);
  }
  componentDidMount() {
    if (!this.props.projectReducer.isFetched) {
      this.props.dispatch(ProjectActions.requestAvailableProject());
    }
    if (!this.state.inviteeId) {
      this.setState({
        ...this.state,
        inviteeId: this.props.location.query
          ? this.props.location.query.inviteeId
          : "",
      });
    }
    if (typeof _ls !== "undefined" && this.props.userReducer.data.id) {
      console.log("Getting old visitorId");
      _ls('update', { userId: this.props.userReducer.data.id });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkAndRedirectPages(nextProps);
    this.checkAndHideWidget(nextProps);
    this.checkAndFetchUserProjectMappings(nextProps);
  }
  checkAndHideWidget(props) {
    if (
      props.userReducer.data &&
      props.userReducer.data.isAdmin === "true" &&
      !this.state.isChatWidgetHidden &&
      typeof _ls !== "undefined"
    ) {
      _ls("showCsWidget", false);
      this.setState({ ...this.state, isChatWidgetHidden: true });
    }
  }
  checkAndRedirectPages(props) {
    if (props.userReducer.data) {
      if (
        props.userReducer.data.isAdmin === "true" &&
        !props.userReducer.data.isRedirectedToAdminPage &&
        !props.userReducer.data.originalId
      ) {
        browserHistory.push("/admin");
      } else if (
        props.userReducer.data.isAdmin === "true" &&
        props.userReducer.data.originalId &&
        this.getNumberOfProjects(props) === 1
      ) {
        browserHistory.push({
          pathname:
            "/app/" +
            props.projectReducer.projectList[0].key.replace("LS-", "") +
            "/settings",
        });
      } else if (this.getNumberOfProjects(props) === 1)
        browserHistory.push({
          pathname:
            "/app/" +
            props.projectReducer.projectList[0].key.replace("LS-", "") +
            (this.props.location.query.landingPath
              ? this.props.location.query.landingPath
              : DEFAULT_PAGE_URL),
        });
    }
  }
  checkAndFetchUserProjectMappings(props) {
    if (props.userReducer.isFetched) {
      let userData = props.userReducer.data;

      if (
        userData &&
        !userData.isInternalUser &&
        (!props.userProjectMappingReducer ||
          !props.userProjectMappingReducer.userProjectMapping.hasOwnProperty(
            userData.id
          )) &&
        props.userProjectMappingReducer.isFetching == false
      ) {
        props.dispatch(getUserProject(userData.id, userData.isInternalUser));
      }
    }
  }
  getNumberOfProjects = (props) => {
    if (props.projectReducer.isFetched) {
      return props.projectReducer.projectList.length;
    }
  };
  getFilteredProjects() {
    let filteredProjects = [];
    if (this.props.userReducer.isFetched) {
      let userData = this.props.userReducer.data;
      if (userData.isInternalUser)
        filteredProjects = this.props.projectReducer.projectList;
      else if (
        this.props.userProjectMappingReducer &&
        this.props.userProjectMappingReducer.userProjectMapping.hasOwnProperty(
          userData.id
        ) &&
        this.props.userProjectMappingReducer.isFetching == false
      ) {
        let userProjectMappings = this.props.userProjectMappingReducer
          .userProjectMapping[userData.id];
        filteredProjects = this.props.projectReducer.projectList.filter(
          (project) => {
            for (let mapping of userProjectMappings)
              if (mapping.projectKey == project.key) return true;
            return false;
          }
        );
      }
    }

    return filteredProjects;
  }
  render() {
    if (this.props.userReducer.isFetched)
      return (
        <ChooseProfileComponent
          projectList={this.getFilteredProjects()}
          inviteeId={this.state.inviteeId}
          userDetails={this.props.userReducer}
        />
      );
    else return <div></div>; //loading empty page while we fetch the user details and check if admin and redirect to admin panel
  }
}

export default ChooseProfileContainer;
