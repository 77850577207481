import React, { FC } from 'react'

export const Error: FC<any> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      fill="#E21C1C"
    />
    <path
      d="M12.0059 13.1484C12.647 13.1484 12.9771 12.7676 12.9961 12.1138L13.1104 8.16553C13.1167 8.06396 13.123 7.94971 13.123 7.86719C13.123 7.1626 12.7104 6.75 12.0122 6.75C11.3076 6.75 10.8887 7.1626 10.8887 7.86719C10.8887 7.94971 10.895 8.06396 10.895 8.16553L11.0156 12.1138C11.0347 12.7676 11.3584 13.1484 12.0059 13.1484Z"
      fill="white"
    />
    <path
      d="M12.003 17.4C12.6615 17.4 13.1998 16.8719 13.1998 16.1967C13.1998 15.5281 12.6615 15 12.003 15C11.3381 15 10.7998 15.5281 10.7998 16.1967C10.7998 16.8719 11.3381 17.4 12.003 17.4Z"
      fill="white"
    />
  </svg>
)
