import * as R from 'ramda'
import styled from 'styled-components'
import { is } from 'typescript-styled-is'

export const Container = styled.div<{ isLeft: boolean; isRight: boolean }>`
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-weight: 400;
  color: ${R.path(['theme', 'color', 'black'])};
  background-color: ${R.path(['theme', 'color', 'white'])};
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 0.7rem 1rem;
  border: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  box-sizing: border-box;
  box-shadow: 0 0.7rem 0.8rem rgba(22, 45, 61, 0.08);
  border-radius: 0.9rem;
  display: inline-flex;
  white-space: nowrap;

  ${is('isLeft')`
    border-bottom-right-radius: 0;
  `}

  ${is('isRight')`
    border-bottom-left-radius: 0;
  `}
`
