import React, { Component } from "react";
import { connect } from "react-redux";
import ResetPassword from "../components/ResetPassword.jsx";
import {
  initializeResetRequest,
  initializeResetFailed,
  clearError,
  updateVerificationId,
  resetPasswordRequest,
  resetPasswordFailed,
  generalErrorMessage,
} from "../actions/ResetPasswordAction";
import { isValidEmail, isAlphaNumeric } from "../commons/Utility";
import { browserHistory } from "react-router";
import { isAuthenticated } from "../commons/Authenticator";
import { showVoicebox } from "../actions/VoiceboxAction";

@connect((state) => ({
  login: state.LoginReducer,
  ResetPassword: state.ResetPasswordReducer,
}))
class ResetPasswordContainer extends Component {
  constructor() {
    super();
    this.initializeResetRequest = this.initializeResetRequest.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onResetKeyUp = this.onResetKeyUp.bind(this);
    this.state = { pageToDisplay: "initialize" };
  }

  componentWillMount() {
    if (isAuthenticated()) {
      this.props.dispatch(
        showVoicebox({ message: "Loading...", showFullScreenLoader: true })
      );
      browserHistory.push("/");
    }

    let { query } = this.props.location;

    if (query && query.error) {
      this.props.dispatch(
        generalErrorMessage("The link got expired, please try again.")
      );
      this.setState({ pageToDisplay: "initialize" });
    } else if (query && query.verificationId && query.contactId) {
      this.props.dispatch(
        updateVerificationId(query.verificationId, query.contactId)
      );
      this.setState({ pageToDisplay: "reset" });
    }
  }

  componentDidMount() {
    this.child.refs.login.focus();
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.ResetPassword.isInitializedReset &&
      this.props.ResetPassword.isInitializedReset
    )
      this.setState({ pageToDisplay: "mailedOut" });
    else if (
      !prevProps.ResetPassword.isResetted &&
      this.props.ResetPassword.isResetted
    ) {
      this.props.dispatch(
        showVoicebox({
          message: "Reset Successful, Please login!",
          dismissAfter: 2000,
          showFullScreenLoader: true,
        })
      );
      setTimeout(() => {
        browserHistory.push("/");
      }, 2100);
    }
  }

  onKeyUp(event) {
    let { error, generalErrorMessage } = this.props.ResetPassword;

    if (event.keyCode == 13) this.initializeResetRequest();
    else if (error || generalErrorMessage) this.props.dispatch(clearError());
  }

  onResetKeyUp(event) {
    let { passwordError } = this.props.ResetPassword;

    if (event.keyCode == 13) this.resetPassword();
    else if (passwordError) this.props.dispatch(clearError());
  }

  initializeResetRequest() {
    let login = this.child.refs.login.value.trim();

    if (!isValidEmail(login)) {
      this.props.dispatch(
        initializeResetFailed("Oops, Email doesn't look right")
      );
      return;
    }

    this.props.dispatch(initializeResetRequest(login));
  }

  resetPassword() {
    let password = this.child.refs.password.value.trim();
    let resetInfo = {};

    if (password.length < 6) {
      this.props.dispatch(
        resetPasswordFailed("Password must've a minimum of 6 characters")
      );
      return;
    } else if (!isAlphaNumeric(password)) {
      this.props.dispatch(
        resetPasswordFailed(
          "Password must contain atleast 1 number & 1 alphabet"
        )
      );
      return;
    } else if (password.length > 64) {
      this.props.dispatch(
        resetPasswordFailed("Password can't exceed 64 characters")
      );
      return;
    }

    let { verificationId, contactId } = this.props.ResetPassword;
    resetInfo = { password, verificationId, contactId };

    console.info("resetInfo : ", resetInfo);

    this.props.dispatch(resetPasswordRequest(resetInfo));
  }

  render() {
    console.info("Props : ", this.props);
    return (
      <ResetPassword
        ref={(component) => (this.child = component)}
        parentProps={this.props}
        parentState={this.state}
        initializeReset={this.initializeResetRequest}
        resetPassword={this.resetPassword}
        onEnter={this.onEnter}
        onKeyUp={this.onKeyUp}
        onResetKeyUp={this.onResetKeyUp}
      />
    );
  }
}

export default ResetPasswordContainer;
