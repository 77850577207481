import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  TeamMoreIcon,
  FilterIcon,
  DeleteIcon,
  PlusIcon,
  ExportIcon,
  ImportIcon,
} from "../../commons/Icons.js";
import {
  DEFAULT_GROUP_IDS,
  TYPE_MAIN_GROUP_ID_MAP,
  DASHBOARD_DEFAULT_GROUP_ID,
} from "../commons/ContactConstants.js";
import { getGroupType } from "../commons/ContactsUtility.js";
import { deleteGroupRequest } from "../actions/ContactsAction.js";
import ColumnsDropdown from "./ColumnsDropdown.jsx";
import useOnClickOutside from "../hooks/useOnClickOutside.js";
import { exportContactRequest, toggleImportPopUp } from "../../actions/PeopleAction.js";
import Papa from "papaparse";

const MoreOptions = (props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const {
    showFilterSection,
    setShowFilterSection,
    params: { groupId, projectkey },
  } = props;
  const {
    contacts: { peopleGroupMap = {}, fetchedCount },
  } = props;
  const dispatch = useDispatch();
  const optionsRef = useRef();

  useOnClickOutside(optionsRef, () => {
    setShowOptions(false);
    setShowColumns(false);
  });

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);

  useEffect(() => {
    setShowOptions(false);
  }, [groupId]);

  const onClickOptions = (event) => {
      setShowOptions(!showOptions);
    if (showColumns && !showOptions) setShowColumns(false);
  };

  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 27) {
      setShowOptions(false);
      setShowColumns(false);
    }
  };

  const onEdit = () => {
    setShowOptions(false);
    setShowFilterSection(true);
  };

  const onDelete = () => {
    if (DEFAULT_GROUP_IDS.includes(groupId)) {
      //dispatch(showVoicebox({message : 'Default Segments can\'t be deleted...', dismissAfter : 3000}));
      return;
    }
    const group = peopleGroupMap[groupId];
    const groupType = getGroupType(group);
    const mainGroupId =
      TYPE_MAIN_GROUP_ID_MAP[groupType] || DASHBOARD_DEFAULT_GROUP_ID;
    dispatch(deleteGroupRequest(groupId, `LS-${projectkey}`, mainGroupId));
    setShowOptions(false);
  };

  const onAddColumns = ({ target }) => {
    if (target.id == "add-column") setShowColumns(!showColumns);
  };

  const onExport = () => {
    dispatch(
      exportContactRequest({
        type: "keys",
        projectId: "LS-" + projectkey,
        query: props.query.query,
        currentGroupId: groupId,
      })
    );
    setShowOptions(false);
  };

  const parseComplete = (parsedContacts) => {
    let {data,meta} = parsedContacts;
    let contacts = {}
    contacts.contactColumns = meta.fields;
    contacts.information = data;
    dispatch(toggleImportPopUp(`LS-${projectkey}`,contacts,true));
  }
  const onImport = (event) => {
    if(event.target.files){
      let file = event.target.files[0];
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines:true,
        complete: parseComplete
      });
      // dispatch(toggleImportPopUp(`LS-${projectkey}`,event.target.files,true));
    }
  }

  return (
    <div
      ref={optionsRef}
      className={"my-team-more-btn " + (showOptions ? "open" : "")}
    >
      <a onClick={onClickOptions} onKeyDown={() => {}}>
        <TeamMoreIcon />
      </a>
      <ul className="edit-delete-container sub-dropdown">
        <li
          id="add-column"
          className={showColumns ? "active" : ""}
          onClick={onAddColumns}
        >
          <PlusIcon />
          Add Columns
          <ColumnsDropdown
            {...props}
            showColumns={showColumns}
            setShowColumns={setShowColumns}
          />
        </li>
        <li
          style={{ display: showFilterSection ? "none" : "block" }}
          onClick={onEdit}
        >
          <FilterIcon />
          Edit Filters
        </li>
        {/**<li>
                    <AnalyticsIcon/>
                    Analytics view
                </li>**/}
        <li
          style={{
            display: DEFAULT_GROUP_IDS.includes(groupId) ? "none" : "block",
          }}
          className="delete"
          onClick={onDelete}
        >
          <DeleteIcon />
          Delete Segment
        </li>
        <li className="import bar-line">
          <ImportIcon />
          Import CSV
          <input type="file" value="" onChange={onImport} accept=".csv" />
        </li>
        {fetchedCount ? (
          <li className="import" onClick={onExport}>
            <ExportIcon />
            Export CSV
          </li>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
};

export default MoreOptions;
