import React, { FC } from 'react'

export const Close: FC<any> = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.74177 0.805819C1.48024 0.602469 1.10209 0.620955 0.861766 0.861278C0.601417 1.12163 0.601417 1.54374 0.861766 1.80409L5.05599 5.99831L0.861766 10.1925L0.806307 10.2553C0.602957 10.5169 0.621443 10.895 0.861766 11.1353C1.12212 11.3957 1.54423 11.3957 1.80458 11.1353L5.9988 6.94112L10.193 11.1353L10.2558 11.1908C10.5173 11.3941 10.8955 11.3757 11.1358 11.1353C11.3962 10.875 11.3962 10.4529 11.1358 10.1925L6.9416 5.99831L11.1358 1.80409L11.1913 1.74128C11.3946 1.47975 11.3761 1.1016 11.1358 0.861278C10.8755 0.600928 10.4534 0.600928 10.193 0.861278L5.9988 5.0555L1.80458 0.861278L1.74177 0.805819Z"
      fill="#727C8F"
    />
  </svg>
)
