import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const PluginButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.6rem;

  ${mq.sm} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.2rem;
  }
`
