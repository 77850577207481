import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { selectIsLoggedIn } from 'src/features/authorization/store/selectors'
import { SessionStep2 } from 'src/pages/SessionStep2'
import { SESSION_STEP_2_ROUTE } from 'src/pages/SessionStep2/data'
import { SessionStep3 } from 'src/pages/SessionStep3'
import { SESSION_STEP_3_ROUTE } from 'src/pages/SessionStep3/data'
import { SignUpPage } from 'src/pages/SignUp'
import { SIGN_UP_ROUTE } from 'src/pages/SignUp/data'
import { SignUpSuccessPage } from 'src/pages/SignUpSuccess'
import { SIGN_UP_SUCCESS_ROUTE } from 'src/pages/SignUpSuccess/data'
import { WidgetInstallationPage } from 'src/pages/WidgetInstallation'
import { WIDGET_INSTALLATION_ROUTE } from 'src/pages/WidgetInstallation/data'
import { WidgetSetupPage } from 'src/pages/WidgetSetup'
import { WIDGET_SETUP_ROUTE } from 'src/pages/WidgetSetup/data'
import { PrivateGuardedRoute } from 'src/utils/router/components/PrivateRouteGuard/PrivateRouteGuard'
import { PublicGuardedRoute } from 'src/utils/router/components/PublicRouteGuard/PublicRouteGuard'
import { IAppState } from 'src/utils/state/store'

interface IProps {
  isLoggedIn: ReturnType<typeof selectIsLoggedIn>
}

export const RouterComponent: FC<IProps> = ({ isLoggedIn }) => (
  <Switch>
    <PublicGuardedRoute path={SIGN_UP_ROUTE} exact component={SignUpPage} />
    <PrivateGuardedRoute
      path={SIGN_UP_SUCCESS_ROUTE}
      exact
      component={SignUpSuccessPage}
      isLoggedIn={isLoggedIn}
    />
    <PrivateGuardedRoute
      path={WIDGET_SETUP_ROUTE}
      exact
      component={WidgetSetupPage}
      isLoggedIn={isLoggedIn}
    />
    <PrivateGuardedRoute
      path={WIDGET_INSTALLATION_ROUTE}
      exact
      component={WidgetInstallationPage}
      isLoggedIn={isLoggedIn}
    />
    <PrivateGuardedRoute
      path={SESSION_STEP_2_ROUTE}
      exact
      component={SessionStep2}
      isLoggedIn={isLoggedIn}
    />
    <PrivateGuardedRoute
      path={SESSION_STEP_3_ROUTE}
      exact
      component={SessionStep3}
      isLoggedIn={isLoggedIn}
    />
  </Switch>
)

const mapStateToProps = (state: IAppState) => ({
  isLoggedIn: selectIsLoggedIn(state.auth),
})

export const Routes = connect(mapStateToProps)(RouterComponent)
