import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getProjectNameByKey, getStyleOfPreviewTyping } from "../../../app/commons/Utility.js";

const AnnouncementPopupPreview = (props) => {
  const [projectName, setProjectName] = useState("");
  const { campaignDetails, projects } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    projects: state.ProjectReducer,
  }));
  const { showPopupPreview, setShowPopupPreview, previewContent } = props;
  const { color } = props;
  useEffect(() => {
    if (projects.isFetched) {
      let projectName = getProjectNameByKey(
        `LS-${props.params.projectkey}`,
        projects.projectList
      );
      setProjectName(projectName);
    }
  }, [projects.isFetched]);
  
  const getPreviewHtml = () => {
    let message =
      (campaignDetails.selectedCampaign.message &&
        campaignDetails.selectedCampaign.message) ||
      "";
    let replacedMessage = message.replace(
      "+_add_content",
      `<p>...</p>
      `
    );
    replacedMessage = replacedMessage.replace(
      "+_from_",
      `<span
      <span
      style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;color:#181818"
       >
        From
      </span>`
    );
    replacedMessage = replacedMessage.replace("+_sender_info", "");
    replacedMessage = getProperReplacedFallbackValues(replacedMessage);
    return { __html: replacedMessage };
  };
  const imageError = (e) => {
    e.target.style.display = "none";
  };

  const showImage = (e) => {
    e.target.style.display = "block";
  };

  const getStaffDetails = () => {
    let staff = props.randomStaffData() || {};
    if (props.showFallbackValues) {
      return {
        "{{firstName}}": staff.firstName || "Richard",
        "{{lastName}}": staff.lastName || "Tyler",
        "{{fullName}}": staff.fullName || "Richard Tyler",
        "{{companyName}}":
          campaignDetails.selectedCampaign.projectName || "CES Consulting",
      };
    } else {
      return {
        "{{firstName}}": "First Name",
        "{{lastName}}": "Last Name",
        "{{fullName}}": "Full Name",
        "{{companyName}}": "Company Name",
      };
    }
  };

  const getProperReplacedFallbackValues = (message) => {
    let fallbackStaffData = getStaffDetails();
    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `${fallbackStaffData[match]}`;
      }
    );

    return message;
  };

  const getClassNameOfAnnouncementPopupPreview = () => {
    return (
      "preview-popup cs-popup-model popup-new " +
      (isInteractableDisabled ? "disabled-reply" : "")
    );
  };

  let { campaignFromImage, campaignFromName, isHTMLTemplateEnabled, isInteractableDisabled } = campaignDetails.selectedCampaign;

  return (
    <div>
      <div
        className="cs-popupbg"
        style={{ display: showPopupPreview ? "block" : "none" }}
      ></div>
      <div
        className={getClassNameOfAnnouncementPopupPreview()}
        style={{ display: showPopupPreview ? "block" : "none" }}
      >
        <div className="preview-popup-content">
          <cite
            id="popup_close"
            onClick={() => {
              setShowPopupPreview(false);
            }}
          >
            &times;
          </cite>
          {isHTMLTemplateEnabled ? (
            <iframe srcDoc={getPreviewHtml().__html} />
          ) : (
            <React.Fragment>
              <div
                id="announcement_content"
                dangerouslySetInnerHTML={getPreviewHtml()}
              />
              <div className="preview-sender">
                <figure>
                  <img
                    id="sender_image"
                    onError={imageError}
                    onLoad={showImage}
                    src={campaignFromImage}
                  />
                </figure>
                <label id="sender_name">
                  {campaignFromName}
                </label>
                <span id="project_name">
                  {campaignDetails.selectedCampaign.projectName || projectName}
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
        <div
          className="prview-typing"
          style={getStyleOfPreviewTyping(isInteractableDisabled)}
        >
          <i></i>
          <i
            id="send_button_color"
            style={{ backgroundColor: color ? `#${color}` : "#4684ff" }}
          ></i>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementPopupPreview;
