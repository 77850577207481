import React, { useState, useEffect } from "react";
import { browserHistory  } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { resetPeopleESQuery } from "../../actions/PeopleESQueryAction";
import * as ProjectActions from "../actions/ProjectAction";
import * as UserActions from "../../actions/UserAction";
import YourWidget from "./YourWidget.jsx";
import { sortListByProperty } from "../../commons/Utility";
import YourWidgetComponent from "./YourWidgetComponent.jsx";
import * as ChatConfigurationActions from "../actions/ChatConfigurationAction";
import * as loaders from "../../../app/settings/commons/loaders";

const YourWidgetContainer = (props) => {
  // console.log("coming inside your widget :" , props);
  const { project, user, chatConfig } = useSelector((state) => ({
    project: state.ProjectReducer,
    user: state.UserReducer,
    chatConfig: state.ChatConfigurationReducer,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!project.isFetched) {
      dispatch(ProjectActions.requestAvailableProject());
    }
    if(!user.isFetched){
      dispatch(UserActions.getUser());
    }
  }, []);

  // useEffect(() => {
  //     if(!!project.createdProject)
  //     {
  //         let projectkey = project.createdProject.split('-')[1];
  //         switchWidget(projectkey);
  //         dispatch(ProjectActions.clearCreatedProjectKey());
  //         // window.location.href = window.location.origin + "/app/"+ projectkey + "/settings/widget";
  //     }
  // }, project.createdProject)

  const switchWidget = (projectkey) => {
    dispatch(resetPeopleESQuery());
    let settingsURL = `/app/${projectkey}/settings/widget`;
    let redirectTo = settingsURL;
    // console.log("The redirect url :" , redirectTo);
    browserHistory.push(redirectTo);

    dispatch(UserActions.updateSelectedProjectKey(projectkey));

    if (user.data && !user.data.originalId) {
      var currentUser = {};
      currentUser.defaultProject = projectkey;
      dispatch(UserActions.updateUserRequested(currentUser, false));
    }
  };

  const switchNewWidget = () => {
    // dispatch(resetPeopleESQuery());
    let projectId = props.params.projectkey || user.selectedProjectKey
    let settingsURL = '/app/' + projectId + '/settings/widget/newwidget'
    let redirectTo = settingsURL;
    browserHistory.push(redirectTo);
  };

  const onChooseProfile = (e) => {
    let projectkey = e.target.dataset.projectkey.split("-")[1];
    switchWidget(projectkey);
    //setShowLoader(true);

    // setTimeout(() => {
    //     setShowLoader(false)
    //
    // }, 3 * 1000);
  };

  const getWidgetList = () => {
    let toRenderList = [];
    let activeProjectId = "LS-" + props.params.projectkey;
    if (project.projectList && project.projectList.length) {
      sortListByProperty(project.projectList, "name").map((project) => {
        // let domainName  = chatConfig.data[project.key] && chatConfig.data[project.key].key === activeProjectId  ? chatConfig.data[project.key].domainName : null ;
        // let widgetColor = chatConfig.data[project.key] && chatConfig.data[project.key].key === activeProjectId  ? chatConfig.data[project.key].widgetThemeColor : null ;
        // let widgetIcon  = chatConfig.data[project.key] && chatConfig.data[project.key].key === activeProjectId  ? chatConfig.data[project.key].chatWidgetIconURL : null ;

        let domainName = chatConfig.data[project.key]
          ? chatConfig.data[project.key].domainName
          : null;
        let widgetColor = chatConfig.data[project.key]
          ? chatConfig.data[project.key].widgetThemeColor
          : null;
        let widgetIcon = chatConfig.data[project.key]
          ? chatConfig.data[project.key].chatWidgetIconURL
          : null;
        toRenderList.push(
          <YourWidget
            key={project.key}
            projectName={project.name}
            isActive={props.params.projectkey === project.key.split("-")[1]}
            projectKey={project.key}
            navigateProject={onChooseProfile}
            logoUrl={widgetIcon}
            domainName={domainName}
            widgetColor={widgetColor}
            widgetIcon={widgetIcon}
          />
        );
      });
    } else {
      toRenderList.push(loaders.WidgetCardLoader());
      toRenderList.push(loaders.WidgetCardLoader());
      toRenderList.push(loaders.WidgetCardLoader());
      toRenderList.push(loaders.WidgetCardLoader());
    }

    // toRenderList.push()

    return toRenderList;
  };

  return (
    <YourWidgetComponent
      getWidgetList={getWidgetList}
      switchNewWidget={switchNewWidget}
    />
  );
};

export default YourWidgetContainer;
