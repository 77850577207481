import React, { Component } from "react";
import ReactDOM from "react-dom";
import ChatMessageBox from "./ChatMessageBox.jsx";
import ChatInput from "./ChatInput.jsx";
import { stateToHTML } from "draft-js-export-html";
import { showVoicebox } from "../../actions/VoiceboxAction";
import PropTypes from 'prop-types';
import {
  isValidURL,
  stripHTML,
  getDropdownPayload,
  getDateOfMessage,
  getUserPermissionUtility,
  isHipaaCompliant,
  isOverflowAbnormalDisConnection,
  getAgentPresence,
  getIsAWRecentComponent,
  getStaffMap,
} from "../../commons/Utility";
import { getUrlPreviewInfoRequest } from "../../actions/UrlPreviewAction";
import NewMessagesNotifier from "./NewMessagesNotifier.jsx";
import Mousetrap from "mousetrap";
import {
  scrollToNewMessage,
  clearNewMessageCount,
} from "../../actions/NewMessageAction";
import ReactTooltip from "react-tooltip";
import {
  CHAT_STATUS,
  NON_DISPLAYABLE_TYPES,
  MAX_MESSAGE_COUNT,
  KEYS
} from "../../commons/Constants.js";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import SearchableDropDown from "../common/SearchableDropdownNew.jsx";
import {
  AttachmentIcon,
  AtIcon,
  ScreenShareIcon,
  MoreOptionsIcon,
  VideoIcon,
  EmailTranscriptIcon,
  NotesIcon,
  PendingIcon,
  ResolveIcon
} from "../../commons/Icons.js";
import DateTagLineComponent from "./DateTagLine.jsx";
import { setNewMessage } from "../../actions/ConversationInfoAction.js";
import ResolveChatDropDown from "./ResolveChatDropDown.jsx";
import LoaderComponent from "../common/LoaderComponent.jsx";

class ActiveChatView extends Component {
  constructor() {
    super();

    this.showNotesView = this.showNotesView.bind(this);
    this.processAndSendNotes = this.processAndSendNotes.bind(this);
    this.hideNotesView = this.hideNotesView.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onMessageKeyDown = this.onMessageKeyDown.bind(this);
    this.onMessageKeyUp = this.onMessageKeyUp.bind(this);
    this.getVideoLink = this.getVideoLink.bind(this);
    this.getChatInputBox = this.getChatInputBox.bind(this);
    this.isNewConversation = this.isNewConversation.bind(this);
    this.toggleToNotes = this.toggleToNotes.bind(this);
    this.sendVideoLinkShrtCut = this.sendVideoLinkShrtCut.bind(this);
    this.getChatViewProps = this.getChatViewProps.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.handleDivSize = this.handleDivSize.bind(this);
    this.getAssignmentDropDown = this.getAssignmentDropDown.bind(this);
    this.getCloseButton = this.getCloseButton.bind(this);
    this.getDefaultOptions = this.getDefaultOptions.bind(this);
    this.getUserStaffEntity = this.getUserStaffEntity.bind(this);
    this.getCurrentlyAssignedStaff = this.getCurrentlyAssignedStaff.bind(this);
    this.isWellReceivedBrand = this.isWellReceivedBrand.bind(this);
    this.toggleMoreOptions = this.toggleMoreOptions.bind(this);
    this.hideMoreOptions = this.hideMoreOptions.bind(this);
    this.closeButtonClick = this.closeButtonClick.bind(this);
    this.emailTranscriptButtonClick = this.emailTranscriptButtonClick.bind(
      this
    );
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onTeleportButtonsClicked = this.onTeleportButtonsClicked.bind(this);
    this.toggleAssignToDropDown = this.toggleAssignToDropDown.bind(this);
    this.hideAssignToDropDown = this.hideAssignToDropDown.bind(this);
    this.toggleSendIconColor = this.toggleSendIconColor.bind(this);
    this.shouldShowEmailReply = this.shouldShowEmailReply.bind(this);
    this.assignmentAgentOnClick = this.assignmentAgentOnClick.bind(this);
    this.focusInput = this.focusInput.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.isScrollable = this.isScrollable.bind(this);
    this.setDateTopBox = this.setDateTopBox.bind(this);
    this.getCurrentConversation = this.getCurrentConversation.bind(this);
    this.isEmailConversation = this.isEmailConversation.bind(this);
    this.getEditorState = this.getEditorState.bind(this);
    this.canShowTheCloseButton = this.canShowTheCloseButton.bind(this);
    this.showCloseButtonForUnavailableAgent = this.showCloseButtonForUnavailableAgent.bind(this);
    this.getSenderStaff = this.getSenderStaff.bind(this);
    this.hasConnectionStateChanged = this.hasConnectionStateChanged.bind(this);
    this.hasConversationStatusChanged = this.hasConversationStatusChanged.bind(this);
    this.state = {
      isNotesActive: false,
      chatListStyle: {},
      clearText: false,
      newMessagesCount: 0,
      inputDivHeight: 0,
      isCollapsed: false,
      showMoreOptions: false,
      showAssignToDropDown: false,
      editorState: undefined,
      showBlueSendIcon: false,
      dropDownTypeClass: "at-icon-btn-dwn",
      atBottom: false,
      atTop: false,
      stickyDate: "",
      sendIconClass:"sent-icon-btn"
    };
  }

  componentDidMount() {
    Mousetrap.bind("n", this.toggleToNotes);
    if(!this.props.state.isOverflowConversation && getUserPermissionUtility("CanCloseConversation"))
      Mousetrap.bind("alt+shift+c", this.closeButtonClick);
    Mousetrap.bind("enter", this.sendVideoLinkShrtCut);
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keyup", this.handleKeyDown);
    let stickyDate = this.setDateTopBox();
    if (stickyDate !== this.state.stickyDate) this.setState({ stickyDate });
  }
  componentWillUnmount() {
    Mousetrap.unbind("n");
    Mousetrap.unbind("enter");
    Mousetrap.unbind("alt+shift+c");
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keyup", this.handleKeyDown);
  }
  handleClickOutside(event) {
    let moreOptionsButton = document.getElementById("more-icon");
    let assignToButton = document.getElementById("at-icon");
    if (
      this.state.showMoreOptions &&
      this.moreOptionsDiv &&
      !this.moreOptionsDiv.contains(event.target) &&
      moreOptionsButton &&
      !moreOptionsButton.contains(event.target)
    )
      this.setState({ showMoreOptions: false });
    else if (
      this.state.showAssignToDropDown &&
      this.assignToDropDown &&
      !ReactDOM.findDOMNode(this.assignToDropDown).contains(event.target) &&
      assignToButton &&
      !assignToButton.contains(event.target)
    )
      this.setState({ showAssignToDropDown: false });
  }
  getChatViewProps() {
    let containerDomNode = ReactDOM.findDOMNode(this.refs.chat_History);
    let containerProps = {};

    if (containerDomNode)
      containerProps = {
        chatViewRect: containerDomNode.getBoundingClientRect(),
        chatViewScrollTop: containerDomNode.scrollTop,
        chatViewHeight: containerDomNode.clientHeight,
        chatViewElement: containerDomNode,
      };

    return containerProps;
  }

  hasConversationStatusChanged(props, nextProps) {
    let previousConversationState = this.getCurrentConversation(props);
    let recentConversationState = this.getCurrentConversation(nextProps);
    let hasConversationStatusChanged = previousConversationState != recentConversationState;
    if(hasConversationStatusChanged) {
      console.info("Conversation status changed");
    }
    return hasConversationStatusChanged;
  }

  hasConnectionStateChanged(props, nextProps) {
    let isConnectedToNetworkChanged =
      props.isConnectedToNetwork != nextProps.isConnectedToNetwork;
    let isSubscribedToChannelsChanged =
      props.isSubscribedToChannels != nextProps.isSubscribedToChannels;
    let hasConnectionStateChanged =
      isConnectedToNetworkChanged || isSubscribedToChannelsChanged;
    if (hasConnectionStateChanged) {
      console.info("Connection status changed");
    }
    return hasConnectionStateChanged;
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!nextProps.state.isOverflowConversation &&
        this.hasConversationStatusChanged(this.props, nextProps)) ||
      (nextProps.state.isOverflowConversation &&
        this.hasConnectionStateChanged(this.props, nextProps) &&
        this.state.isNotesActive == false)
    ) {
      this.props.hideTypingStatus();
    }
    if (
      this.props.state.conversationId != nextProps.state.conversationId &&
      this.state.isNotesActive
    )
      this.setState({ isNotesActive: false });

    if (isOverflowAbnormalDisConnection() && !this.state.clearText)
      this.setState(state => ({ ...state, clearText: true }));
      
  }

  componentDidUpdate(prevProps, prevState) {
    let stickyDate = this.setDateTopBox();
    if (stickyDate !== this.state.stickyDate) this.setState({ stickyDate });

    if (
      this.props.state.showVideoLinkPopup != undefined &&
      prevProps.state.showVideoLinkPopup !=
        this.props.state.showVideoLinkPopup &&
      this.props.state.showVideoLinkPopup == true
    ) {
      this.refs.connectLink.focus();
    }
    if (
      (this.state.isNotesActive && !prevState.isNotesActive) ||
      (!this.state.isNotesActive && prevState.isNotesActive)
    )
      this.child.refs.editor.focus();
    ReactTooltip.rebuild();
  }
  toggleToNotes(e) {
    if (!this.state.isNotesActive) {
      this.refs.notes_button.click();
      e.preventDefault();
    }
  }

  sendVideoLinkShrtCut(e) {
    if (
      this.props.state.showVideoLinkPopup == true ||
      this.props.state.showScreenShareLinkPopup == true
    )
      this.refs.sendVideoLinkRef.click();
  }
  isNewConversation() {
    let state = this.props.state;
    if (state.conversationId === state.tempConversationId) {
      this.props.dispatch(
        showVoicebox({ message: "Initiate a chat", dismissAfter: 3000 })
      );
      return true;
    }

    return false;
  }

  scrollToMessage() {
    this.props.dispatch(scrollToNewMessage(this.props.state.conversationId));
  }

  showNotesView() {
    if (!this.isNewConversation()) {
      this.setState({ isNotesActive: true, showMoreOptions: false });
      Event(
        "LS-" + this.props.state.projectKey,
        "Chat Text Area",
        "Notes",
        "Notes Icon Clicked"
      );
    }
  }
  hideNotesView() {
    if (!this.isNewConversation()) {
      this.setState({ isNotesActive: false });
    }
  }
  getEditorState() {
    return this.child &&
      this.child.refs.editor &&
      this.child.refs.editor.props.editorState
      ? this.child.refs.editor.props.editorState
      : undefined;
  }
  sendMessage(e) {
    Event(
      "LS-" + this.props.state.projectKey,
      "Chat Text Area",
      "Send",
      "Message Send Icon Clicked"
    );
    if (this.props.isConnectedToNetwork && this.props.isSubscribedToChannels) {
      let editorState = this.getEditorState();
      if (
        editorState &&
        editorState.getCurrentContent().getPlainText().trim().length > 0
      ) {
        let message = stateToHTML(editorState.getCurrentContent());
        this.props.processAndSend(null, message, false, undefined, this.isEmailConversation());
        this.setState({ ...this.state, clearText: true});
        this.toggleSendIconColor(false);
      }
    }
  }
  processAndSendNotes(e) {
    this.hideNotesView();
    Event(
      "LS-" + this.props.state.projectKey,
      "Chat Text Area",
      "Notes Sent",
      "Notes Send Icon Clicked"
    );
    this.props.processAndSendNotes(e);
    this.toggleSendIconColor(false);
  }
  onMessageKeyDown(e) {
    let editor =
      this.child && this.child.refs.editor ? this.child.refs.editor : {};
    let editorState =
      editor && editor.props.editorState ? editor.props.editorState : undefined;

    if (editorState) {
      if (this.state.clearText)
        this.setState({ ...this.state, clearText: false });
      this.props.onMessageKeyDown(
        e,
        editorState.getCurrentContent().getPlainText()
      );
    }
  }

  onMessageKeyUp(e) {
    let editor =
      this.child && this.child.refs.editor ? this.child.refs.editor : {};
    let editorState =
      editor && editor.props && editor.props.editorState
        ? editor.props.editorState
        : undefined;

    if (editorState) {
      if (this.state.clearText)
        this.setState({ ...this.state, clearText: false });

      this.props.onMessageKeyUp(editorState.getCurrentContent().getPlainText());
    }
  }
  handleKeyDown(e) {
    if (e.key === KEYS.ESCAPE) {
      this.hideAssignToDropDown();
      if(this.state.isNotesActive)
        this.hideNotesView();
    }
  }
  getVideoLink(e) {
    if (this.props.isConnectedToNetwork && this.props.isSubscribedToChannels)
      if (e.which == 13 || e.keyCode == 13 || e.type == "click")
        this.props.getVideoLink();
  }
  handleDivSize() {
    let containerDom = ReactDOM.findDOMNode(this.refs.footer);
    if (containerDom && containerDom.offsetHeight != this.state.inputDivHeight)
      this.setState(
        { ...this.state, inputDivHeight: containerDom.offsetHeight },
        () => {
          let chatBox = ReactDOM.findDOMNode(this.refs.chat_History);
          chatBox.scrollTop = chatBox.scrollHeight;
        }
      );
  }
  getCurrentConversation(props) {
    let state = props.state;
    let conversationMap = state.conversationObject.conversationMap;
    let conversationId = state.conversationId ? state.conversationId : "";
    let currentConversation = conversationMap[conversationId]
      ? conversationMap[conversationId]
      : {};
    return currentConversation;
  }
  isEmailConversation() {
    let currentConversation = this.getCurrentConversation(this.props);
    return currentConversation && currentConversation.conversationType == "EMAIL_CONVERSATION";
  }
  getChatInputBox() {
    let state = this.props.state;
    let conversationId = state.conversationId ? state.conversationId : "";
    let currentConversation = this.getCurrentConversation(this.props);
    let user = state.user;
    let contactsMap = state.conversationObject.contactsMap;
    let contact =
      currentConversation.visitorId &&
      contactsMap[currentConversation.visitorId]
        ? contactsMap[currentConversation.visitorId]
        : {};

    return (
      <ChatInput
        ref={(component) => (this.child = component)}
        overflowedAgent={this.props.overflowedAgent}
        staffMap={state.staffMap}
        isConnectedToNetwork={this.props.isConnectedToNetwork}
        isSubscribedToChannels={this.props.isSubscribedToChannels}
        clearText={this.state.clearText}
        isNotesActive={this.state.isNotesActive}
        isEmailConversation = {this.isEmailConversation()}
        visitorEmailId = {contact.email}
        chatStatus={currentConversation && currentConversation.chatStatus}
        isReopened={currentConversation && currentConversation.isReopened}
        onMessageKeyDown={this.onMessageKeyDown}
        onMessageKeyUp={this.onMessageKeyUp}
        processAndSend={this.props.processAndSend}
        closeChat={this.props.closeChat}
        user={user}
        handleDivSize={this.handleDivSize}
        conversationId={conversationId}
        toggleSendIconColor={this.toggleSendIconColor}
        showBlueSendIcon={this.state.showBlueSendIcon}
        staffMapData={this.props.staffMap}
      />
    );
  }
  toggleCollapse() {
    this.setState({ ...this.state, isCollapsed: !this.state.isCollapsed });
  }

  getUserStaffEntity() {
    let state = this.props.state;
    let userId = state.user && state.user.data ? state.user.data.id : "";
    let staffMap = state.staffMap;
    let userStaffEntity = {};
    if (staffMap[userId]) {
      userStaffEntity = Object.assign({}, staffMap[userId]);
      userStaffEntity.firstName = "You";
      userStaffEntity.lastName = "";
      userStaffEntity.fullName = "You";
      userStaffEntity = userStaffEntity
        ? getDropdownPayload(
            [userStaffEntity],
            "key",
            "photoUrl",
            ["fullName"],
            "isOnline",
            "openConversationCount",
            "avatarColor",
            "isTakingChat"
          )[0]
        : {};
    }
    return userStaffEntity;
  }
  getDefaultOptions() {
    let state = this.props.state;
    let userId = state.user && state.user.data ? state.user.data.id : "";
    let options = [];
    let conversationMap = state.conversationObject.conversationMap;
    let conversationId = state.conversationId ? state.conversationId : "";
    let conversation = conversationMap[conversationId]
      ? conversationMap[conversationId]
      : {};
    let assignedTo =
      conversation && conversation.assignedTo
        ? conversation.assignedTo
        : undefined;
    let userStaffEntity = this.getUserStaffEntity();

    // if (assignedTo) {
    //   let project = this.props.projects.projectList.filter(
    //     (project) => project.key == "LS-" + this.props.projectKey
    //   )[0];
    //   options.push({
    //     key: "None",
    //     img: "",
    //     text: "None",
    //     openConversationCount: project
    //       ? project.unAssignedConversationCount
    //       : 0,
    //   });
    // }

    options.push(userStaffEntity);
    return options;
  }
  isWellReceivedBrand() {
    let projectList = this.props.projects ? this.props.projects.projectList : [];
    return isHipaaCompliant(projectList, this.props.state.projectKey);
  }

  getCurrentlyAssignedStaff() {
    let state = this.props.state;
    let userId = state.user && state.user.data ? state.user.data.id : "";
    let conversationMap = state.conversationObject.conversationMap;
    let conversationId = state.conversationId ? state.conversationId : "";
    let conversation = conversationMap[conversationId]
      ? conversationMap[conversationId]
      : {};
    let assignedTo =
      conversation && conversation.assignedTo
        ? conversation.assignedTo
        : undefined;
    let userStaffEntity = this.getUserStaffEntity();
    let currentAssignedStaff = {};
    let staffMap = this.props.staffMap;
    let overflowAgents = this.props.state.overflowAgents;

    if (assignedTo) {
      if (assignedTo == userId) currentAssignedStaff = userStaffEntity;
      else {
        currentAssignedStaff = staffMap[assignedTo];
        currentAssignedStaff = Object.assign(
          {},
          currentAssignedStaff
            ? currentAssignedStaff
            : overflowAgents[assignedTo]
        );
        let isOverflowedAgent = currentAssignedStaff.staffType === "OVERFLOW";
        currentAssignedStaff = getDropdownPayload(
          [currentAssignedStaff],
          "key",
          "photoUrl",
          ["fullName"],
          "isOnline",
          "openConversationCount",
          "avatarColor",
          "isTakingChat"
        )[0];
        if (isOverflowedAgent) currentAssignedStaff.isOverflowedAgent = true;
      }
    } else currentAssignedStaff = { key: "None", img: "", text: "None" };
    return currentAssignedStaff;
  }

  getAssignmentDropDown() {
    let state = this.props.state;
    //if (Object.keys(messages).length > 0) {
      let conversationMap = state.conversationObject.conversationMap;
      let conversationId = state.conversationId ? state.conversationId : "";
      let conversation = conversationMap[conversationId]
        ? conversationMap[conversationId]
        : {};
      let isAwWindow = state.isAwWindow;

      if (
        this.props.tempConversationId ===
          (conversation && conversation.key ? conversation.key : "") ||
        this.props.isOverflowConversation ||
        this.props.isAwWindow
      )
        return "";

      let assignedTo =
        conversation && conversation.assignedTo
          ? conversation.assignedTo
          : undefined;
      let assignedAgent = assignedTo
        ? this.props.getAgentNameById(assignedTo)
        : "None";
      let staffMap = Object.assign({}, state.staffMap);
      let filteredStaffs = [];
      let options = this.getDefaultOptions();
      let userStaffEntity = this.getUserStaffEntity();
      let userId = userStaffEntity.key;
      /* let currentAssignedStaff = this.getCurrentlyAssignedStaff(); */
      let currentAssignedStaff = staffMap[assignedTo]
        ? staffMap[assignedTo]
        : {};
      // let currentStaff = staffMap[userId];
     
      delete staffMap[userId];
      // delete staffMap[assignedTo];
      // if(userId == assignedTo){
      //   delete staffMap[userId];
      //   filteredStaffs.push(currentStaff);
      // }

      for (let key in staffMap) {
        if (staffMap[key] && staffMap[key].status == "ACTIVE")
          filteredStaffs.push(staffMap[key]);
      }
      options.push(
        ...getDropdownPayload(
          filteredStaffs,
          "key",
          "photoUrl",
          ["fullName"],
          "isOnline",
          "openConversationCount",
          "avatarColor",
          "isTakingChat"
        )
      );

      if(options.length == 0){
        options.push({
              key: "None",
              img: "",
              text: "None",
              openConversationCount: 0,
            });
      }

      return (
        <SearchableDropDown
          appendToolTip="Assign Conversation (A)"
          appendToolTipClass="info"
          ref={(component) => (this.assignToDropDown = component)}
          triggerRef="assignToRef"
          options={options}
          defaultImage={"https://app.chatsupport.co/images/Staff-Avatar.svg"}
          selectedValue={assignedAgent}
          currentlySelected={currentAssignedStaff}
          showSelectedPhoto={
            currentAssignedStaff && currentAssignedStaff.text !== "None"
              ? true
              : false
          }
          onSelect={this.assignmentAgentOnClick}
          dropdownInfo={this.state}
        />
      );
    //} else return "";
  }
  assignmentAgentOnClick(e) {
    this.hideAssignToDropDown();
    Event(
      "LS-" + this.props.state.projectKey,
      "Chat Text Area",
      "Assign Agent",
      "@Mention Icon"
    );
    this.props.assignAgent(e);
  }
  getCloseButton() {
    let state = this.props.state;
    let userId = state.user && state.user.data ? state.user.data.id : "";
    let conversationMap = state.conversationObject.conversationMap;
    let conversationId = state.conversationId ? state.conversationId : "";
    let conversation = conversationMap[conversationId]
      ? conversationMap[conversationId]
      : {};
    let isAwWindow = this.props.state.isAwWindow;
    let staffMap = getStaffMap("LS-" + this.props.state.projectKey);
    let agentPresence = getAgentPresence(
      conversation.assignedTo,
      conversation.projectId,
      staffMap
    );
    
    const noCloseButton = "";

    const closeButton = <ResolveChatDropDown closeHandler={this.closeButtonClick}/>



    // const closeButton = (
    //   <ResolveIcon
    //     className="resolve-icon"
    //     onClick={this.closeButtonClick}
    //     ref="closeChat"
    //     data-tip="Close Conversation"
    //     data-class="tooltip info"
    //     tabIndex="-3"
    //     data-delay-hide={0}
    //   />
    // );

    if (
      this.props.tempConversationId ===
        (conversation && conversation.key ? conversation.key : "") ||
      this.props.isOverflowConversation
    )
      return noCloseButton;

    if (this.canShowTheCloseButton(conversation, userId)) {
      return closeButton;
    } else if(conversation && conversation.chatStatus === CHAT_STATUS.Pending) {
        return <ResolveChatDropDown closeHandler={this.closeButtonClick} type={"pending_closed"}/>
    } else if(conversation && conversation.chatStatus === CHAT_STATUS.Open && conversation.assignedTo === userId){
      return closeButton;
    } else if(conversation.mentionedStaffs && conversation.mentionedStaffs.includes(userId) && !conversation.hasClosedByMentionedStaff){
      return closeButton;
    } else if (this.showCloseButtonForUnavailableAgent(conversation, agentPresence)) {
      return closeButton;
    } else if (conversation && conversation.chatStatus === CHAT_STATUS.Closed ) {
      return noCloseButton;
    }

    return noCloseButton;
  }
  showCloseButtonForUnavailableAgent(
    { chatStatus, lastClosedBy },
    { isOnline, isTakingChat }
  ) {
    return (
      chatStatus === CHAT_STATUS.Closed &&
      lastClosedBy === "System" &&
      isOnline &&
      !isTakingChat
    );
  }
  canShowTheCloseButton(
    {
      chatStatus,
      mentionedStaffs,
      hasClosedByMentionedStaff,
      assignedAgentsObject,
      isOverflowChat,
    },
    userId
  ) {
    return (
      (chatStatus === CHAT_STATUS.Open &&
        mentionedStaffs &&
        mentionedStaffs.includes(userId) &&
        !hasClosedByMentionedStaff) ||
      (assignedAgentsObject &&
        assignedAgentsObject.open &&
        assignedAgentsObject.open.includes(userId) &&
        !isOverflowChat)
    );
  }
  closeButtonClick(e) {
    this.hideMoreOptions();
    Event(
      "LS-" + this.props.state.projectKey,
      "Chat Text Area",
      "Resolved",
      "Resolve chat"
    );
    this.props.closeChat(e);
  }
  emailTranscriptButtonClick(e) {
    if (!getIsAWRecentComponent()) {
      this.hideMoreOptions();
      Event(
        "LS-" + this.props.state.projectKey,
        "Chat Text Area",
        "Email Chat Transcript",
        "Chat Transcript Clicked"
      );
      this.props.showSendTranscript(e);
    }
 }
  onTeleportButtonsClicked(e) {
    this.hideMoreOptions();
    ReactTooltip.hide();
    if (e.target.tagName === "svg")
      Event(
        "LS-" + this.props.state.projectKey,
        "Chat Text Area",
        "Teleport Screen Share",
        "Teleport Screen Share Clicked"
      );
    else
      Event(
        "LS-" + this.props.state.projectKey,
        "Chat Text Area",
        "Teleport Video Call",
        "Teleport Video Call Clicked"
      );
    this.props.getVideoLink(e);
  }
  toggleAssignToDropDown(e) {
    let showAssignToDropDown = this.state.showAssignToDropDown;
    if (showAssignToDropDown) showAssignToDropDown = false;
    else {
      showAssignToDropDown = true;
      ReactTooltip.hide();
    }
    this.setState({ ...this.state, showAssignToDropDown });
  }
  hideAssignToDropDown(e) {
    this.setState({ ...this.state, showAssignToDropDown: false });
  }
  hideMoreOptions() {
    this.setState({ ...this.state, showMoreOptions: false });
  }
  toggleMoreOptions() {
    let showMoreOptions = this.state.showMoreOptions;
    if (showMoreOptions) showMoreOptions = false;
    else {
      showMoreOptions = true;
      ReactTooltip.hide();
      Event(
        "LS-" + this.props.state.projectKey,
        "Chat Text Area",
        "More",
        "More Options Clicked"
      );
    }
    this.setState({ ...this.state, showMoreOptions });
  }
  focusInput(e) {
    if (e.target.dataset.container == "textInputDiv") {
      this.child.refs.editor.focus();
    }
  }
  getMessageHistoryStyle() {
    let style = { bottom: this.state.inputDivHeight + "px" };
    const appBanner = document.getElementById("app-banner"); 
    if (this.props.isOverflowConversation) {
      style = {
        ...style,
        right: "0px",
        maxHeight:
          "calc(100vh - " + (112 + this.state.inputDivHeight - 113) + "px)",
      };
    } else if (this.props.isFromPeopleProfilePage) {
      style = { ...style, maxHeight: "calc(100vh - " + (478 + this.state.inputDivHeight - 113) + "px)" };
    } else if ( appBanner != null ) {
      style = { ...style, maxHeight: "calc(100vh - " + (478 + this.state.inputDivHeight - 350) + "px)" };
    } else {
      style = {
        ...style,
        maxHeight:
        "calc(100vh - " + (202 + this.state.inputDivHeight - 113 ) + "px)",
      }; //"calc(100vh - " + (202 + this.state.inputDivHeight - 113 + (!this.props.state.isOverflowConversation && this.props.state.user && this.props.state.user.planType == 'FREE' && this.props.state.user.planAcknowledged == false ? 45 : 0)) + "px)",
      if (this.props.state.isAwWindow) style.right = "0px";
    }
    return style;
  }
  handleScroll(e) {
    //to check where the scroll bar is.
    this.props.loadMessagesOnScroll(e);
    let state = this.props.state;

    let containerDomNode = ReactDOM.findDOMNode(this.refs.chat_History);
    let atBottom = this.state.atBottom;
    let atTop = this.state.atTop;
    if (containerDomNode) {
      atBottom =
        containerDomNode.clientHeight ==
        containerDomNode.scrollHeight - Math.floor(containerDomNode.scrollTop)
          ? true
          : false;
      atTop =
        containerDomNode.scrollTop == 0 ||
        containerDomNode.clientHeight == containerDomNode.scrollHeight
          ? true
          : false;
    }
    if (atBottom && this.props.state.conversationId) {
      this.props.dispatch(
        clearNewMessageCount(this.props.state.conversationId)
      );
    }

    this.setState({ ...this.state, atBottom: atBottom, atTop: atTop });
  }

  isScrollable() {
    let containerDomNode = ReactDOM.findDOMNode(this.refs.chat_History);
    let dateRef = ReactDOM.findDOMNode(this.refs.date_Tag);
    if (containerDomNode && dateRef) {
      if (
        containerDomNode &&
        containerDomNode.scrollHeight > containerDomNode.clientHeight &&
        containerDomNode.scrollTop > dateRef.clientHeight
      ) {
        return true;
      }
      return false;
    }
  }
  setDateTopBox() {
    let dateRef = ReactDOM.findDOMNode(this.refs.date_Tag);
    let date = document.getElementsByClassName("tag-msg");
    if (date && dateRef) {
      for (let index = date.length - 1; index >= 0; index--) {
        let dateText = date[index].firstElementChild.innerText;
        let top = date[index].getBoundingClientRect().top;
        if (
          dateRef &&
          Math.abs(top) <= dateRef.getBoundingClientRect().top &&
          dateText !== "New Message"
        ) {
          return dateText;
        }
        top = top * -1;
        if (
          dateRef &&
          top > dateRef.getBoundingClientRect().top &&
          dateText !== "New Message"
        ) {
          return dateText;
        }
      }
    }
  }
  trackEvent() {
    // if(this.props.isConnectedToNetwork &&
    //   this.props.isSubscribedToChannels &&
    //   this.state.conversationId !== this.state.tempConversationId
    //     ? false
    //     : true) {
    //       this.props.dispatch(
    //         showVoicebox({ message: "Initiate a chat", dismissAfter: 3000 })
    //       );
    //     }
    Event(
      "LS-" + this.props.state.projectKey,
      "Chat Text Area",
      "Attachment",
      "Attachment Icon Clicked"
    );
  }

  getLoader(indents) {
    let {
      messageReducer: { messageMap, isFetching, isOldConversationFetching },
      state: { conversationId },
    } = this.props;
    let messageCount = messageMap[conversationId]
      ? Object.keys(messageMap[conversationId]).length
      : 0;
    if (indents.length && (messageCount > MAX_MESSAGE_COUNT || !isFetching) && !isOldConversationFetching ) {
      return indents;
    } else {
      if (conversationId != this.props.state.tempConversationId || isFetching) {
        return <LoaderComponent loaderCount={3}/>;
      }
    }
   return "";
  }

  toggleSendIconColor(show) {
      let sendIconClass = "sent-icon-btn";
      sendIconClass += show ? " enable" : " disable";
      this.setState({sendIconClass , showBlueSendIcon : sendIconClass.includes("enable")});
  }

  shouldShowEmailReply() {
      return this.isEmailConversation() && !this.state.isNotesActive;
  }

  getCursorStyle() {
      return {cursor: this.state.showBlueSendIcon ? "pointer" : "auto"};
  }

  getAttachmentCursor() {
    if (this.props.isConnectedToNetwork &&
      this.props.isSubscribedToChannels &&
      !this.isEmailConversation() &&
      this.props.state.conversationId !== this.props.state.tempConversationId &&
      !isOverflowAbnormalDisConnection())
      return {};
    else
      return { cursor: "not-allowed" };
  }

  getSenderStaff(typingMessage, staffMap, overflowAgents) {
    return typingMessage.messageFrom == "AGENT"
      ? staffMap[typingMessage.senderId]
        ? staffMap[typingMessage.senderId]
        : overflowAgents[typingMessage.senderId]
        ? overflowAgents[typingMessage.senderId]
        : {}
      : {};
  }

  render() {
    let state = this.props.state;
    let contactsMap = state.conversationObject.contactsMap;
    let conversationId = state.conversationId ? state.conversationId : "";
    let conversationMap = state.conversationObject.conversationMap;
    let messages = state.messages;
    let newMessageInfo = state.newMessageInfo;
    let visitorId =
      conversationMap && conversationMap[conversationId]
        ? conversationMap[conversationId].visitorId
        : "";
    let user = state.user;
    let {
      unReadCount: { typingStatus, typingMessage },
    } = this.props;
    let projectKey = state.projectKey;
    let currentConversation = conversationMap[conversationId]
      ? conversationMap[conversationId]
      : {};
    let contact =
      currentConversation.visitorId &&
      contactsMap[currentConversation.visitorId]
        ? contactsMap[currentConversation.visitorId]
        : {};
    let fullName = contact && contact.firstName ? contact.firstName : "";
    fullName += contact && contact.lastName ? " " + contact.lastName : "";
    contact.fullName = fullName
      ? fullName
      : contact && contact.type
      ? contact.type.toLowerCase()
      : "";
    let indents = [];
    let tempConversationId = state.tempConversationId;
    let staffMap = state.staffMap;
    let overflowAgents = state.overflowAgents;
    let editorState =
      this.child &&
      this.child.refs.editor &&
      this.child.refs.editor.props.editorState
        ? this.child.refs.editor.props.editorState
        : undefined;
    let hideAssignChat =
      this.props.isOverflowConversation ||
        !getUserPermissionUtility("ShowAssignChat") ||
        getIsAWRecentComponent()
        ? true
        : false;
    let chatWrapperClass = this.props.isFromPeopleProfilePage
      ? "cs-user-conversation"
      : this.state.atTop
      ? "cs-user-conversation scroll scroll-top"
      : this.state.atBottom
      ? "cs-user-conversation scroll scroll-bottom"
      : "cs-user-conversation scroll";
    chatWrapperClass =
      chatWrapperClass +
      (getUserPermissionUtility("CanSendMessage")
        ? ""
        : " conversation-scroll");

      console.info("[ActiveChatView-render] State: isConnectedToNetwork - " + this.props.isConnectedToNetwork,
      "isSubscribedToChannels - " + this.props.isSubscribedToChannels,
      "conversationId - " + state.conversationId,
      "tempConversationId - " + state.tempConversationId);  

    //Messages should not be displayed if there is no conversation available
    if (
      Object.keys(conversationMap).length > 0 &&
      (user.data || state.isOverflowConversation || state.isAwWindow)
    ) {
      if (messages) {
        let { conversationType } = currentConversation;
        let urls = [];
        let isNewMessageSet = false;
        let lastMessageId = currentConversation.lastMessageId;
        let strippedMessage = "";
        let messageKeys = Object.keys(messages);
        let messageLength = messageKeys.length;
        for (let i = messageLength - 1; i >= 0; i--) {
          let messageKey = messageKeys[i];
          strippedMessage = stripHTML(messages[messageKey].message);
          if (
            strippedMessage &&
            isValidURL(strippedMessage) == true &&
            !this.props.urlPreviews.previewsMap.hasOwnProperty(strippedMessage)
          ) {
            urls.push(strippedMessage);
          }
          let senderStaff = undefined;
          let actionToStaff = undefined;

          if (
            messages[messageKey].messageFrom == "AGENT" ||
            messages[messageKey].type == "CS_ANSWERPHRASE"
          ) {
            if (
              conversationType == "CHAT_PROMPT" &&
              messages[messageKey].senderId == "" &&
              messages[messageKey].type == "CHAT" &&
              state.isOverflowConversation
            ) {
              messages[messageKey].senderId = this.props.overflowedAgent.key;
            }

            senderStaff = staffMap[messages[messageKey].senderId]
              ? staffMap[messages[messageKey].senderId]
              : overflowAgents[messages[messageKey].senderId]
              ? overflowAgents[messages[messageKey].senderId]
              : undefined;
            actionToStaff = staffMap[messages[messageKey].actionTo]
              ? staffMap[messages[messageKey].actionTo]
              : overflowAgents[messages[messageKey].actionTo]
              ? overflowAgents[messages[messageKey].actionTo]
              : undefined;
          }
          if (
            messages[messageKey].messageFrom == "VISITOR" &&
            messages[messageKey].type === "CHAT" &&
            lastMessageId !== messageKey &&
            messages[lastMessageId] &&
            messages[lastMessageId].messageStatus !== "CHAT_READ" &&
            messages[messageKey].messageStatus !== "CHAT_READ" &&
            currentConversation.newMessage === "" &&
            !isNewMessageSet &&
            messages[messageKey].conversationId == currentConversation.key
          ) {
            this.props.dispatch(setNewMessage(messages[messageKey]));
            isNewMessageSet = true;
          }

          let messageType = messages[messageKey].type;

          if (i === messageLength - 1 && !NON_DISPLAYABLE_TYPES.includes(messageType)) {
            this.state.firstDate = getDateOfMessage(
              messages[messageKey].createdDate
            );
            indents.push(
              <DateTagLineComponent
                createdDate={messages[messageKey].createdDate}
                isDate={true}
              />
            );
          } else if (
            messages[messageKeys[i + 1]] && getDateOfMessage(messages[messageKey].createdDate) !=
             getDateOfMessage(messages[messageKeys[i + 1]].createdDate) &&
            this.state.firstDate !=
              getDateOfMessage(messages[messageKey].createdDate) && 
              !NON_DISPLAYABLE_TYPES.includes(messageType)
          ) {
            indents.push(
              <DateTagLineComponent
                createdDate={messages[messageKeys[i]].createdDate}
                isDate={true}
              />
            );
          }
          if (
            currentConversation.newMessage &&
            currentConversation.newMessage === messageKey
          )
            indents.push(
              <DateTagLineComponent
                newMessage={currentConversation.newMessage}
                message={messageKey}
                createdDate={messages[messageKey].createdDate}
              />
            );
          indents.push(
            <ChatMessageBox
              resendFailedMessage={this.props.resendFailedMessage}
              dispatch={this.props.dispatch}
              newMessageInfo={newMessageInfo}
              getChatViewProps={this.getChatViewProps}
              chatContainerScrollTop={this.props.state.chatContainerScrollTop}
              newMessage={this.props.state.newMessage}
              agentNotifiableMessage={this.props.state.agentNotifiableMessage}
              urlPreviews={this.props.urlPreviews}
              scrollMessages={this.props.scrollMessages}
              currentConversation={currentConversation}
              overflowedAgent={this.props.overflowedAgent}
              key={messageKey}
              visitorId={visitorId}
              messageData={messages[messageKey]}
              contact={contact}
              user={user}
              senderStaff={senderStaff}
              actionToStaff={actionToStaff}
              getAgentNameById={this.props.getAgentNameById}
              getOverflowAgentNameById={this.props.getOverflowAgentNameById}
              getAgentImageURLById={this.props.getAgentImageURLById}
              isOverflowConversation={state.isOverflowConversation}
              isAwWindow={state.isAwWindow}
              uploadingMessageData={state.uploadingMessageData}
              isUploadingAttachment={state.isUploadingAttachment}
              atBottom={this.state.atBottom}
              nearBottom={this.props.nearBottom}
              isFromPeopleProfilePage={this.props.isFromPeopleProfilePage}
            />
          );
        }
        if (urls.length > 0) {
          urls = [...new Set(urls)];
          this.props.dispatch(
            getUrlPreviewInfoRequest(
              urls,
              state.isOverflowConversation,
              state.isAwWindow
            )
          );
        }
      }
    }
   
    return (
      <div
        id="active-chat"
        // className={this.props.isFromPeopleProfilePage ? "chat-box-wrp1" : ""}
        style={this.props.style ? this.props.style : {}}
      >
        <DateTagLineComponent
          ref="date_Tag"
          createdDate={this.state.stickyDate}
          stickTop={true}
          atTop={this.state.atTop}
          isScrollable={this.isScrollable}
          isFromPeopleProfilePage={this.props.isFromPeopleProfilePage}
          isOverflowConversation={this.props.isOverflowConversation}
          top={this.props.isFromPeopleProfilePage ? "15px" : undefined}
        />
        <section
          className={chatWrapperClass}
          style={this.getMessageHistoryStyle()}
          ref="chat_History"
          onScroll={this.handleScroll}
        >
          {/* show loader  */}
          {/* {indents ? indents :  this.getLoader()} */}
          {this.getLoader(indents)}
          <NewMessagesNotifier getNewMessages={this.props.getNewMessages} scrollToMessage={this.scrollToMessage}/>
          {typingStatus && conversationId === typingMessage.conversationId ? (
            <ChatMessageBox
              senderStaff={this.getSenderStaff(
                typingMessage,
                staffMap,
                overflowAgents
              )}
              currentConversation={currentConversation}
              resendFailedMessage={this.props.resendFailedMessage}
              scrollMessages={this.props.scrollMessages}
              visitorId={visitorId}
              messageData={typingMessage}
              contact={contact}
              user={user}
              getAgentNameById={this.props.getAgentNameById}
              getOverflowAgentNameById={this.props.getOverflowAgentNameById}
              getAgentImageURLById={this.props.getAgentImageURLById}
              isTyping={true}
              nearBottom={this.props.nearBottom}
              isFromPeopleProfilePage={this.props.isFromPeopleProfilePage}
            />
          ) : (
            ""
          )}
        </section>

        {getUserPermissionUtility("CanSendMessage") ? (
          <section
            className="cs-typing"
            style={
              this.props.state.isOverflowConversation ||
              this.props.isFromPeopleProfilePage ||
              this.props.state.isAwWindow
                ? { right: "0px" }
                : {}
            }
            onClick={this.focusInput}
            data-container="textInputDiv"
            ref="footer"
          >
            {this.getChatInputBox()}
            {/* <textarea placeholder="Type a message"></textarea> */}
            <div
              className={
                this.getCloseButton()
                  ? "cs-typing-footer"
                  : "cs-typing-footer cs-typing-stop"
              }
            >
              <div className="cs-typing-attachment">
                {getUserPermissionUtility("SendAttachments") ? (
                  <AttachmentIcon />
                ) : (
                  ""
                )}
                <input
                  data-tip={ !this.isEmailConversation()?"Attach Files (F)":"Coming soon"}
                  tabIndex="-1"
                  aria-label="Attach files"
                  data-class="tooltip info"
                  data-delay-hide={0}
                  accept="image/*, audio/*, video/*, .ppt , .xls , .doc , .docx , .xlsx , .pptx , .pdf , .txt , .rtf, .csv, .zip"
                  disabled={
                    this.props.isConnectedToNetwork &&
                    this.props.isSubscribedToChannels &&
                    !this.isEmailConversation() &&
                    state.conversationId !== state.tempConversationId &&
                    !isOverflowAbnormalDisConnection()
                      ? false
                      : true
                  }
                  onChange={this.props.uploadAttachment}
                  type="file"
                  name="file"
                  ref="uploadElement"
                  id="uploadAttachment"
                  multiple
                  onClick={this.trackEvent}
                  style = {this.getAttachmentCursor()}
                />
              </div>
              {hideAssignChat ? (
                ""
              ) : (
                <AtIcon
                  id="at-icon"
                  data-tip="Re-Assign Conversation"
                  tabIndex="-2"
                  data-class="tooltip info"
                  data-delay-hide={0}
                  onClick={this.toggleAssignToDropDown}
                  ref={(component) => (this.assignToButton = component)}
                  className={
                    "at-icon-btn" +
                    (this.state.showAssignToDropDown ? " active" : "")
                  }
                />
              )}
              {/* { getUserPermissionUtility("SendVideoLink") && this.props.isInternalUser || this.props.state.isOverflowConversation ?   <ScreenShareIcon data-tip="Teleport Screenshare" tabIndex="-3" data-class="tooltip info" data-delay-hide={0} data-teleport_type="screenshare" onClick={this.onTeleportButtonsClicked} className="screen-share-icon-btn" /> : "" } */}
              {this.getCloseButton()}
              <div
                className={
                  "more-icon-dwn" + (this.state.showMoreOptions ? " open" : "")
                }
              >
                {getUserPermissionUtility("CanCloseConversation") ? (
                  <MoreOptionsIcon
                    id="more-icon"
                    data-tip="More"
                    tabIndex="-4"
                    data-class="tooltip info"
                    data-delay-hide={0}
                    onClick={this.toggleMoreOptions}
                    ref={(component) => {
                      this.moreOptionsButton = component;
                    }}
                    className="more-icon-btn"
                  />
                ) : (
                  ""
                )}
                {this.state.showMoreOptions ? (
                  <div
                    className="more-icon-btn-dwn"
                    ref={(component) => {
                      this.moreOptionsDiv = component;
                    }}
                  >
                    <ul>
                      {/* { getUserPermissionUtility("SendScreenShareLink") && (this.props.isInternalUser || this.props.state.isOverflowConversation)  ? <li className={ this.props.isOverflowConversation && ( !this.props.isConnectedToNetwork || !this.props.isSubscribedToChannels ) ? "dn" : "" } data-teleport_type="videochat" onClick={this.onTeleportButtonsClicked}><VideoIcon className="video-icon"/>Teleport Video Call</li> : "" } */}
                      {getUserPermissionUtility("EmailTranscript") || !getIsAWRecentComponent()? (
                        <li  style={{
                          cursor: getIsAWRecentComponent()
                            ? "not-allowed"
                            : this.state.showMoreOptions
                            ? "auto"
                            : "pointer",
                        }} 
                        onClick={this.emailTranscriptButtonClick}
                        onKeyDown={ () => {} }
                        >
                          <EmailTranscriptIcon className="email-icon" />
                          Email Chat Transcript
                        </li>
                      ) : (
                        ""
                      )}
                      {getUserPermissionUtility("MakeNotes") ? (
                        <li
                          className={this.state.isNotesActive ? "dn" : ""}
                          onClick={this.showNotesView}
                        >
                          <NotesIcon className="notes-icon" />
                          Add Your Note
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <button
                className={
                  this.state.sendIconClass + (this.shouldShowEmailReply()
                    ? " send-mail-btn"
                    : "")
                }
                data-btn_type={"send_button"}
                onClick={
                  this.state.isNotesActive
                    ? this.processAndSendNotes
                    : this.sendMessage
                }
                style={this.getCursorStyle()}
              >
                {this.shouldShowEmailReply() ? "Send Email" : ""}
              </button>
              {this.state.showAssignToDropDown
                ? this.getAssignmentDropDown()
                : ""}
            </div>
          </section>
        ) : (
          ""
        )}
      </div>
    );
  }
}
ActiveChatView.propTypes = {
    isConnectedToNetwork: PropTypes.bool,
    isSubscribedToChannels: PropTypes.bool,
    state: PropTypes.shape({
      conversationId: PropTypes.string,
      tempConversationId: PropTypes.string
    })
}
export default ActiveChatView;

// style={this.props.isInternalUser ?  {} : {"left": 82}}
