import React, { FC, Fragment } from 'react'
import {
  Checkmark,
  Chevron,
  Container,
  Label,
  Step,
} from 'src/features/wizard/shared/components/Breadcrumb/styled'
import { IProps } from 'src/features/wizard/shared/components/Breadcrumb/types'

export const Breadcrumb: FC<IProps> = ({ steps, currentStep }) => (
  <Container>
    {steps.map((step, index) => {
      const isCompleted =
        currentStep === 0 && index === 0 ? true : currentStep > index
      const isEnabled = currentStep + 1 > index

      return (
        <Fragment key={step.link}>
          {index > 0 ? (
            <Chevron
              $incomplete={!isCompleted}
              width={8}
              height={13}
              type="chevron"
            />
          ) : null}
          <Step to={step.link} key={step.label}>
            {isCompleted ? (
              <Checkmark
                $notFirst={index > 0}
                width={16}
                height={16}
                type="checkMark"
              />
            ) : null}
            <Label enabled={isEnabled}>
              {isCompleted ? '' : `${index + 1}.\u00A0\u00A0`}
              {step.label}
            </Label>
          </Step>
        </Fragment>
      )
    })}
  </Container>
)
