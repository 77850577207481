import React, { FC } from 'react'

export const CheckMark: FC<any> = ({ fill, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.8182 6.82747C12.2087 6.43694 12.2087 5.80378 11.8182 5.41325C11.4277 5.02273 10.7945 5.02273 10.404 5.41325L6.83334 8.98392L5.596 7.74659C5.20548 7.35606 4.57231 7.35606 4.18179 7.74659C3.79126 8.13711 3.79126 8.77028 4.18179 9.1608L6.12623 11.1052C6.51676 11.4958 7.14992 11.4958 7.54045 11.1052L11.8182 6.82747Z"
      fill={fill}
    />
  </svg>
)
