import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  displayVisitorType,
  DISPLAY_VISITOR_PROPERTY_NAME,
  TIME_TYPES,
  URL_TYPES,
  INTERNAL_CUSTOM_FIELDS_MAP,
} from "../../commons/Constants.js";
import {
  getNewFormatedTime,
  initiateClipboard,
  getUserPermissionUtility,
  getDisplayPlace,
  getVisitorDisplayTime,
  isScrollReachedBottom,
  getCurrentVisitorPage,
  getIsAWRecentComponent,
  getRespectiveStyleforAWChat,
  visitorDetailsStyle,
  visitorDetailsClassName
} from "../../commons/Utility.js";
import CommonDropDown from "../common/CommonDropDown1.jsx";
import ReactTooltip from "react-tooltip";
import { browserHistory, Link } from "react-router";
import ChatHistory from "../../containers/ChatHistoryContainer";
import { SHORT_SORT_BY_MAP } from "../../commons/Constants";
import Mousetrap from "mousetrap";
import TimeAgo from "../../containers/TimeAgo";
import { store } from "../../app";
import { showVoicebox } from "../../actions/VoiceboxAction.js";
import { updateIPActivity } from "../../actions/PeopleAction"
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";

import {
  BuildingIcon,
  PhoneIcon,
  EmailIcon,
  ClockIcon,
  LocationIcon,
  CalanderIcon,
  WindowsIcon,
  IosIcon,
  AndroidIcon,
  ActivityLinkIcon,
  CopyIcon,
  AWChatHeaderSectionIcon,
} from "../../commons/Icons.js";
import MessageTags from "./TagMessages/MessageTags.jsx";
import VisitorDetailsTab from "./VisitorDetailsTab.jsx";

class VisitorDetails extends Component {
  constructor() {
    super();
    this.focusUserTag = this.focusUserTag.bind(this);
    this.tagOnClick = this.tagOnClick.bind(this);
    this.showAddTagsInput = this.showAddTagsInput.bind(this);
    this.hideAddTagsInput = this.hideAddTagsInput.bind(this);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.updateIPActivityStatus = this.updateIPActivityStatus.bind(this);
    this.getCurrentConversationTags = this.getCurrentConversationTags.bind(this);
    this.isBlocked = this.isBlocked.bind(this);
    this.getCurrentUrlPage = this.getCurrentUrlPage.bind(this);
    this.returnWidgetToActualPosition = this.returnWidgetToActualPosition.bind(this);
    this.setState = this.setState.bind(this);
    this.state = {
      showTagInput: false,
      showDetails: {
        activityDetails: false,
        tagDetails: false,
        otherDetails: false
      },
      isBlocked: false,
      isDetailSection: true
    };
  }
  componentDidMount() {
    Mousetrap.bind("t", this.focusUserTag);
    initiateClipboard(
      ".copy-link-icon, .copy-icon",
      () => {
        store.dispatch(
          showVoicebox({
            message: "Copied",
            dismissAfter: 3000,
            showBelowHeader: !this.props.isOverflowConversation,
            showFullScreenLoader: this.props.isOverflowConversation,
          })
        );
      },
      () => {
        store.dispatch(
          showVoicebox({
            message: "Please use CTRL/CMD+C to copy",
            dismissAfter: 3000,
            showBelowHeader: this.props.isOverflowConversation,
            showError: true,
            showFullScreenLoader: this.props.isOverflowConversation,
          })
        );
      }
    );
  }
  componentWillUnmount() {
    Mousetrap.unbind("t");
    this.returnWidgetToActualPosition();
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  componentWillUpdate(nextProps, nextState) {
    if (this.props.contact.key != nextProps.contact.key)
      this.setState({ showTagInput: false });
  }
  toggleDetails(e) {
    this.setState({
      ...this.state,
      showDetails: {
        ...this.state.showDetails,
        [e.target.dataset.option]: !this.state.showDetails[
          e.target.dataset.option
        ],
      },
    });
  }
  tagOnClick(e) {
    if (this.props.isAwWindow) return;
    let projectId = e.target.dataset["project_id"];
    let tagKey = e.target.dataset["tag_key"];
    ReactTooltip.hide();
    if (projectId && tagKey)
      browserHistory.push({
        pathname: "/app/" + projectId + "/people/" + tagKey,
      });
  }
  focusUserTag(e) {
    if (this.props.params.visitorOption == "D") {
      e.preventDefault();
      this.refs.add_tag_link.click();
    }
  }
  showAddTagsInput() {
    this.setState(
      {
        showDetails: { ...this.state.showDetails, tagDetails: true },
        showTagInput: true,
      },
      () => {
        this.child.refs.tags.focus();
      }
    );
  }
  hideAddTagsInput() {
    this.setState({ showTagInput: false });
  }
  renderTagsLi() {
    if (!this.props.isOverflowConversation) {
      let contact = this.props.contact;
      let tags = contact && contact.tags ? contact.tags : [];
      let tagsMap = this.props.tagsMap;
      let tagsDropdownValues = {};
      let tagsTypeMap = {};
      let toRender = [];
      for (let key in tagsMap)
        if (!tags.includes(key)) {
          tagsDropdownValues[tagsMap[key].name] = tagsMap[key].name;
          tagsTypeMap[tagsMap[key].name] = "TAG";
        }
      tags.map((tag) => {
        if (tagsMap[tag])
          toRender.push(
            <span
              key={tag}
              data-tag_key={tag}
              data-project_id={tagsMap[tag].projectId.split("-")[1]}
              onClick={this.tagOnClick}
            >
              {tagsMap[tag].name}
              <cite data-tag_id={tag} onClick={this.props.removeTag}>
                ×
              </cite>
            </span>
          );
        //toRender.push( <a key ={tag} data-class="tooltip info" data-tip={tagsMap[tag].name} data-tag_key={ tag } data-project_id={ tagsMap[tag].projectId.split("-")[1] } onClick={this.tagOnClick} href="javascript:void(0)" >{tagsMap[tag].name} <i data-tag_id={tag} onClick={this.props.removeTag}>&times;</i></a>)
      });
      toRender.length > 0 ? toRender.push(<br />) : "";
      if (this.state.showTagInput)
        toRender.push(
          <CommonDropDown
            ref={(component) => (this.child = component)}
            key="suggestiondropdown"
            placeHolder={"Enter Here..."}
            disableInputforEmpty={false}
            width="100%"
            valueMap={tagsDropdownValues}
            typeMap={tagsTypeMap}
            onSelect={this.props.onTagSelect}
            inputRefValue={"tags"}
            initialKey={this.props.initialKey}
            enterKeyHandler={this.props.addTags}
            selectOnBlur={false}
            isMultipleValue={true}
            enterOnBlur={true}
            showOnFocus={true}
            onBlur={this.hideAddTagsInput}
          />
        );
      else if (getUserPermissionUtility("EditVistorDetails"))
        toRender.push(
          <font ref="add_tag_link" onClick={this.showAddTagsInput}>
            <cite>+</cite> Add Tag
          </font>
        );

      return <div className="cs-tag-wrp">{toRender}</div>;
    }
  }
  renderCustomFields() {
    let { contact, customFieldsMap } = this.props;
    let customFields =
      contact && contact.customFields ? contact.customFields : {};
    customFieldsMap = customFieldsMap ? customFieldsMap : {};
    let customFieldElements = [];

    for (let key in customFieldsMap) {
      if (customFieldsMap[key] && customFields[key]) {
        let isTextShorted = this.getDataLength(
          INTERNAL_CUSTOM_FIELDS_MAP[customFieldsMap[key].name]
            ? INTERNAL_CUSTOM_FIELDS_MAP[customFieldsMap[key].name]
            : customFieldsMap[key].name,
          customFields[key]
        );
        customFieldElements.push(
          <li className={isTextShorted ? "txt-wrp" : ""} key={key} ref={key}>
            <label className="fs-exclude">
              {INTERNAL_CUSTOM_FIELDS_MAP[customFieldsMap[key].name]
                ? INTERNAL_CUSTOM_FIELDS_MAP[customFieldsMap[key].name]
                : customFieldsMap[key].name}
              :{" "}
            </label>
            <span
              className="fs-exclude"
              data-class={isTextShorted ? "tooltip info" : ""}
              data-tip={isTextShorted ? customFields[key] : ""}
            >
              {customFields[key]}
            </span>
            {isTextShorted ? (
              <CopyIcon
                data-clipboard-text={customFields[key]}
                className="copy-icon"
              />
            ) : (
              ""
            )}
          </li>
        );
      }
    }
    return customFieldElements;
  }

  getDataLength = (title, value) => {
    let length = 0;
    length += title ? title.length : length;
    length += value ? value.length : length;
    return length > 30 ? true : false;
  };
  getDisplayPropertyLi(propertyName) {
    let contact = this.props.contact;
    if (
      propertyName == "viewportDimensions" &&
      contact.viewportHeight &&
      contact.viewportWidth
    )
      return (
        <li>
          <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
          <span className="fs-exclude">
            {contact.viewportHeight + " x " + contact.viewportWidth}
          </span>
        </li>
      );
    else if (
      propertyName == "deviceDimensions" &&
      contact.deviceHeight &&
      contact.deviceWidth
    )
      return (
        <li>
          <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
          <span className="fs-exclude">
            {contact.deviceHeight + " x " + contact.deviceWidth}
          </span>
        </li>
      );
    else if (
      propertyName == "location" &&
      contact.latitude &&
      contact.longitude
    )
      return (
        <li>
          <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
          <span className="fs-exclude">
            {contact.latitude + " : " + contact.longitude}
          </span>
        </li>
      );
    else if (contact[propertyName]) {
      if (TIME_TYPES.includes(propertyName) && contact) {
        if (
          propertyName == "firstContacted" &&
          getNewFormatedTime(contact.firstContacted) ==
          getNewFormatedTime(contact.lastContacted)
        )
          // hiding if firstContacted and last contacted are same
          return "";
        else
          return (
            <li>
              <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
              <span className="fs-exclude">
                {getNewFormatedTime(contact[propertyName])}
              </span>
            </li>
          );
      } else if (propertyName == "type") {
        return (
          <li>
            <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
            <span className="fs-exclude">
              {displayVisitorType[contact[propertyName]]}
            </span>
          </li>
        );
      } else if (propertyName == "campaignSource") {
        let campaignSource = contact[propertyName];
        campaignSource =
          campaignSource[0].toUpperCase() + campaignSource.slice(1);
        let isTextShorted = this.getDataLength(
          DISPLAY_VISITOR_PROPERTY_NAME[propertyName],
          campaignSource
        );
        return (
          <li className={isTextShorted ? "txt-wrp" : ""}>
            <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
            <span
              className="fs-exclude"
              data-class={isTextShorted ? "tooltip info" : ""}
              data-tip={isTextShorted ? campaignSource : ""}
            >
              {campaignSource}
            </span>
            {isTextShorted ? (
              <CopyIcon
                data-clipboard-text={campaignSource}
                className="copy-icon"
              />
            ) : (
              ""
            )}
          </li>
        );
      } else {
        if (
          (propertyName == "lastPageTitle" &&
            contact.lastPageTitle == contact.pageTitle) || //hiding if the last page title and current page title are same
          (propertyName == "lastPageUrl" &&
            contact.lastPageUrl == contact.currentUrl)
        )
          //hiding if the last page url and current page url are same
          return "";
        else {
          if (URL_TYPES.includes(propertyName)) {
            return (
              <li className="txt-wrp">
                <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
                <span
                  className="fs-exclude"
                  data-class="tooltip info"
                  data-tip={contact[propertyName]}
                  data-place="left"
                >
                  {contact[propertyName]}
                </span>
                <CopyIcon
                  data-clipboard-text={contact[propertyName]}
                  className="copy-icon"
                  onClick={() =>
                    Event(
                      this.props.params.projectKey,
                      "Others",
                      "Copy",
                      "Copy Icon Clicked"
                    )
                  }
                />
              </li>
            );
          } else if (contact[propertyName] !== "none") {
            if (DISPLAY_VISITOR_PROPERTY_NAME[propertyName] === "User Agent") {
              return (
                <li>
                  <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
                  <span className="txt-line fs-exclude">
                    {contact[propertyName]}
                  </span>
                </li>
              );
            } else {
              let isTextShorted = this.getDataLength(
                DISPLAY_VISITOR_PROPERTY_NAME[propertyName],
                contact[propertyName]
              );
              return (
                <li className={isTextShorted ? "txt-wrp" : ""}>
                  <label>{DISPLAY_VISITOR_PROPERTY_NAME[propertyName]}: </label>
                  <span
                    className="fs-exclude"
                    data-class={isTextShorted ? "tooltip info" : ""}
                    data-tip={isTextShorted ? contact[propertyName] : ""}
                  >
                    {contact[propertyName]}
                  </span>
                  {isTextShorted ? (
                    <CopyIcon
                      data-clipboard-text={contact[propertyName]}
                      className="copy-icon"
                    />
                  ) : (
                    ""
                  )}
                </li>
              );
            }
          }
        }
      }
    } else return "";
  }

  updateIPActivityStatus() {
    let contact = this.props.contact;
    let ip = contact && contact.ip ? contact.ip : "";
    let ipStatusMap = this.props.peopleReducer['ipStatusMap'];
    let projectId = contact.projectId;
    let isBlocked = ipStatusMap[projectId] && ipStatusMap[projectId][ip]
      && ipStatusMap[projectId][ip]['isBlocked'];
    if (!ip) {
      store.dispatch(
        showVoicebox({
          message: "Unable to block the visitor because of IP address not found. Check with Dev team to block.",
          dismissAfter: 3000,
          showError: true
        })
      );
      return;
    }
    this.dispatchUpdate(isBlocked, ip, projectId);
  }

  dispatchUpdate(isBlocked, ip, projectId) {
    store.dispatch(showVoicebox({ message: "Updating" }));
    store.dispatch(updateIPActivity(!isBlocked, ip, projectId));
  }

  isBlocked() {
    let contact = this.props.contact;
    let ip = contact && contact.ip ? contact.ip : "";
    let ipStatusMap = this.props.peopleReducer['ipStatusMap'];
    let projectId = contact.projectId;
    let isBlocked = ipStatusMap[projectId] && ipStatusMap[projectId][ip]
      && ipStatusMap[projectId][ip]['isBlocked'];
    return isBlocked;
  }

  styleOfBlockVistor() {
    let styleObject = {};
    let displayValue = !this.props.isOverflowConversation ? "flex" : "none";
    return {
      ...styleObject,
      display: displayValue
    }
  }

  addClassTo(element) {
    if (element && !element.classList.contains('float-widget')) {
      element.classList.add('float-widget');
    }
  }

  removeClassFrom(element) {
    if (element && element.classList.contains('float-widget')) {
      element.classList.remove('float-widget');
    }
  }

  scrollHandler(event) {
    let { scrollTop } = event.target;
    const rootElement = document.getElementById('root');
    if (isScrollReachedBottom(event.target) && scrollTop !== 0) {
      this.addClassTo(rootElement);
    }
    else {
      this.removeClassFrom(rootElement);
    }
  }

  returnWidgetToActualPosition() {
    const rootElement = document.getElementById('root');
    this.removeClassFrom(rootElement);
  }

  getCurrentUrlPage(currentUrl) {
    if (currentUrl === "NA")
      return currentUrl;
    let currentPage = currentUrl.substring(currentUrl.lastIndexOf("/"), currentUrl.indexOf("?"));
    if (currentPage.length != 1)
      return currentPage;
    return getCurrentVisitorPage(currentUrl);
  }

  getCurrentConversationTags() {
    try {
      let {
        conversationObject: { conversationMap },
        currentConversationId,
      } = store.getState().ConversationInfoReducer;
      let currentConversationIdForOverflow = Object.keys(conversationMap)[0];
      let currentConversation = !this.props.isOverflowConversation
        ? conversationMap[currentConversationId]
        : conversationMap[currentConversationIdForOverflow];
      return currentConversation && currentConversation.tags
        ? currentConversation.tags
        : [];
    } catch (e) {
      console.error("Error while getting current conversation tags", e);
      return [];
    }
  }
  renderTags() {
    let tags = this.getCurrentConversationTags();
    return <MessageTags tags={tags} removeOption={false} isFromMessage={false} />;
  }

  renderDetailsOrHistory() {
    let contact = this.props.contact;
    if (!getIsAWRecentComponent() ? this.props.params.visitorOption == "H" : !this.state.isDetailSection) {
      return (
        <ChatHistory
          contact={contact}
          projectKey={contact.projectId}
          overflowedAgent={this.props.overflowedAgent}
          isOverflowConversation={this.props.isOverflowConversation}
          parentParams={this.props.params}
        />
      );
    }
    else {
      let updateContact = this.props.updateContact;
      let updateHandler = this.props.updateHandler;
      let companyName =
        contact && contact.companyName ? contact.companyName : "";
      let webSessions =
        contact && contact.webSessions ? contact.webSessions : 0;
      let iosWebSessions =
        contact && contact.iosWebSessions ? contact.iosWebSessions : 0;
      let androidWebSessions =
        contact && contact.androidWebSessions ? contact.androidWebSessions : 0;
      let currentUrl = contact && contact.currentUrl ? contact.currentUrl : "NA";
      currentUrl = !currentUrl && contact && contact.sourceUrl ? contact.sourceUrl : currentUrl;
      let fullName = contact && contact.fullName ? contact.fullName : "";
      let displayPhoneNumber =
        contact && contact.displayPhoneNumber ? contact.displayPhoneNumber : "";
      let email = contact && contact.email ? contact.email : "";
      let lastContacted =
        contact && contact.lastContacted ? contact.lastContacted : "";
      let country = contact && contact.country ? contact.country : "";
      let displayName = "";
      let timeZoneObject = contact && contact.timeZoneObject ? contact.timeZoneObject : undefined;
      let displayTime = getVisitorDisplayTime(timeZoneObject);
      let displayPlace = getDisplayPlace(timeZoneObject, country);
      let canEditVisitorDetails = getUserPermissionUtility("EditVistorDetails");
      let tags = this.getCurrentConversationTags();
      if (fullName == "" && contact && contact.email) {
        displayName = contact.email;
      }

      return (
        <section className="cs-user-details">
          <div className="cs-user-details-status">
            {getIsAWRecentComponent() ? (
              <figure
                className="fs-exclude"
                style={{ ...getRespectiveStyleforAWChat() }}
              >
                <AWChatHeaderSectionIcon />
              </figure>
            ) : (
              <figure
                className="fs-exclude"
                  style={contact ? { backgroundColor: contact.avatarColor } : {}}
              ></figure>
            )}
            <input
              className="fs-exclude"
              type="text"
              value={fullName}
              ref="name"
              onBlur={updateContact}
              onKeyPress={updateHandler}
              onChange={updateHandler}
              data-id="name"
              aria-label="Name"
              placeholder="Name"
              disabled={!canEditVisitorDetails}
            ></input>
            <cite
              className={contact && contact.isOnline ? "available" : "offline"}
            ></cite>
            {fullName && fullName.trim().length > 0 ? <CopyIcon className="copy-icon" data-clipboard-text={fullName} /> : ""}
          </div>

          <div className="user-info-input active">
            <LocationIcon />
            <input
              type="text"
              aria-label="location"
              value={displayPlace}
              className="txt-wrp fs-exclude"
            />
            {displayPlace && displayPlace.trim().length > 0 ? <CopyIcon className="copy-icon" data-clipboard-text={displayPlace} /> : ""}
          </div>
          <div className="user-info-input active">
            <ClockIcon />
            <input
              className="fs-exclude"
              type="text"
              value={displayTime}
              disabled
            />
          </div>
          <div
            className={"user-info-input email-input" + (email ? " active" : "")}
          >
            <EmailIcon />
            <input
              type="text"
              ref="email"
              aria-label="email"
              onBlur={updateContact}
              onKeyPress={updateHandler}
              onChange={updateHandler}
              value={email}
              data-id="email"
              placeholder="Email"
              className="txt-wrp fs-exclude"
              disabled={!canEditVisitorDetails}
            />
            {email && email.trim().length > 0 ? <CopyIcon className="copy-icon" data-clipboard-text={email} /> : ""}
          </div>
          <div
            className={
              "user-info-input" + (displayPhoneNumber ? " active" : "")
            }
          >
            <PhoneIcon />
            <input
              className="fs-exclude"
              type="text"
              ref="display_phone_number"
              aria-label="Phone no"
              onBlur={updateContact}
              onKeyPress={updateHandler}
              onChange={updateHandler}
              value={displayPhoneNumber}
              data-id="phone_number"
              aria-label="Phone No"
              placeholder="Phone Number"
              disabled={!canEditVisitorDetails}
            />
            {displayPhoneNumber && displayPhoneNumber.trim().length > 0 ? <CopyIcon className="copy-icon" data-clipboard-text={displayPhoneNumber} /> : ""}
          </div>
          <div className={"user-info-input" + (companyName ? " active" : "")}>
            <BuildingIcon className="building-icon" />
            <input
              type="text"
              ref="companyName"
              aria-label="Workspace"
              onBlur={updateContact}
              onKeyPress={updateHandler}
              onChange={updateHandler}
              value={companyName}
              data-id="company_name"
              placeholder="Company Name"
              className="txt-wrp fs-exclude"
              disabled={!canEditVisitorDetails}
            />
            {companyName && companyName.trim().length > 0 ? <CopyIcon className="copy-icon" data-clipboard-text={companyName} /> : ""}
          </div>
          <ul className="cs-user-details-list">
            <li
              className={this.state.showDetails.activityDetails ? "active" : ""}
            >
              <a
                data-option="activityDetails"
                onClick={this.toggleDetails}
                onKeyDown={ () => {} }
              >
                Activity
              </a>
              <ul>
                <li class="txt-wrp">
                  <label>Current Page: </label>
                  <span
                    className="fs-exclude"
                    data-class="tooltip info"
                    data-tip={currentUrl}
                  >
                    <ActivityLinkIcon
                      className="copy-link-icon"
                      // data-clipboard-text={currentUrl}
                      onClick={() =>
                        Event(
                          this.props.params.projectKey,
                          "Activity",
                          "Copy",
                          "Copy Icon Click"
                        )
                      }
                    />
                    {this.getCurrentUrlPage(currentUrl)}
                  </span>
                  <CopyIcon className="copy-icon" data-clipboard-text={currentUrl} />
                </li>
                <li>
                  <label>No. of Visits: </label>
                  <span className="fs-exclude">
                    <WindowsIcon className="windows-icon" />
                    {webSessions}
                  </span>
                  <span className="fs-exclude">
                    <IosIcon className="ios-icon" />
                    {iosWebSessions}
                  </span>
                  <span className="fs-exclude">
                    <AndroidIcon className="android-icon" />
                    {androidWebSessions}
                  </span>
                </li>
                <li>
                  <label>Last Contacted: </label>
                  <span className="fs-exclude">
                    {" "}
                    <CalanderIcon className="calander-icon" />
                    {lastContacted ? getNewFormatedTime(lastContacted) : ""}
                  </span>
                </li>
              </ul>
            </li>
            <li
              className={
                (this.props.isOverflowConversation ? "dn " : "") +
                (this.state.showDetails.tagDetails ? "tags-active" : "")
              }
            >
              <a data-option="tagDetails" onClick={this.toggleDetails} onKeyDown={ () => {} }>
                Conversation Tags
                {tags.length
                  ? " (" + tags.length + ")"
                  : ""}
              </a>
              {tags.length ? this.renderTags() : ""}
            </li>
            <li className={this.state.showDetails.otherDetails ? "active" : ""}>
              <a
                data-option="otherDetails"
                onClick={this.toggleDetails}
                onKeyDown={ () => {} }
              >
                Others
              </a>
              <ul>
                {this.renderCustomFields()}
                {Object.keys(DISPLAY_VISITOR_PROPERTY_NAME).map((property) => {
                  return this.getDisplayPropertyLi(property);
                })}
                <li id="cs-block-visitor-li"
                  style={this.styleOfBlockVistor()}>
                  <label>Block Visitor:</label>
                  <div
                    id="overflow_toggle"
                    className={"cs-switch-btn " + (this.isBlocked() ? "" : "off")}
                    onClick={this.updateIPActivityStatus}
                    onKeyDown={ () => {} }>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      );
    }
  }

  render() {
    return (
      <section
        className={visitorDetailsClassName(this.props)}
        style={visitorDetailsStyle(this.props)}
        onScroll={this.scrollHandler}
      >
        <VisitorDetailsTab
          state={this.state}
          props={this.props}
          returnWidgetToActualPosition={this.returnWidgetToActualPosition}
          setState={this.setState}
        />
        {this.renderDetailsOrHistory()}
      </section>
    );
  }
}
export default VisitorDetails;
