import { useField } from 'formik'
import * as R from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import { Margin } from 'styled-components-spacing'
import { ImageSelection } from 'src/features/form/components/ImageInput/ImageSelection'
import { ImageSelectionContainer } from 'src/features/form/components/ImageInput/ImageSelection/styled'
import {
  Container,
  IconButton,
  Images,
  InputContainer,
  SpinnerContainer,
  StyledInput,
  IconButtonContainer,
} from 'src/features/form/components/ImageInput/styled'
import { IProps } from 'src/features/form/components/ImageInput/types'
import { Spinner } from 'src/features/ui/components/Spinner'
import { WidgetIcon } from 'src/features/wizard/setup/components/WidgetIcon'
import { debounce } from 'src/utils/debounce'

// TODO: refactor this component was changed considerably in the last minute
// (it would make sense to split it into multiple smaller components)
export const ImageInput: FC<IProps> = ({
  color,
  name,
  imageSelection,
  isUploading,
  onFileUpload,
}): JSX.Element => {
  const [field, _meta, helpers] = useField(name)
  const [isSelectionVisible, setIsSelectionVisible] = useState(false)
  const [lastSelectedImageUrl, setLastSelectedImageUrl] = useState(
    R.head(imageSelection)
  )

  useEffect(() => {
    if (field.value?.url) {
      setLastSelectedImageUrl(field.value.url)
    }
  }, [field.value?.url])

  const debouncedSetter = debounce(setIsSelectionVisible, 150)

  useEffect(() => {
    helpers.setValue({ url: field.value?.url, color })
  }, [color])

  const handleClickColorButton = () =>
    helpers.setValue({ url: undefined, color })

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) =>
    onFileUpload(event.currentTarget.files?.[0]!, field.value?.color)

  const getIsImageSelected = () => Boolean(field.value?.url)

  const getIsImageDisabled = () => !imageSelection?.length

  const toggleImageSelection = () => {
    if (!isSelectionVisible) {
      debouncedSetter(true)
    }
  }

  const hideImageSelection = () => debouncedSetter(false)

  const handleClickImageButton = (imageUrl: string) => {
    helpers.setValue({ color: field.value?.color, url: imageUrl })
    hideImageSelection()
  }

  return (
    <Container>
      <Images>
        <IconButton
          color="#4684ff"
          selected={!field.value?.url}
          onClick={handleClickColorButton}
        >
          <WidgetIcon width="4rem" height="4rem" color={color} />
        </IconButton>
        <IconButtonContainer>
          <IconButton
            color="#4684ff"
            backgroundColor="#E6E9F0"
            onClick={toggleImageSelection}
            selected={getIsImageSelected()}
            disabled={getIsImageDisabled()}
          >
            <WidgetIcon
              width="4rem"
              height="4rem"
              imageUrl={field.value?.url || lastSelectedImageUrl}
              color={color}
              isInFormElement
            />
          </IconButton>
          {isSelectionVisible && (
            <ImageSelectionContainer>
              <ImageSelection
                selectedUrl={field.value?.url}
                selectImage={handleClickImageButton}
                onClickOutside={hideImageSelection}
                imageSelection={imageSelection}
              />
            </ImageSelectionContainer>
          )}
        </IconButtonContainer>
        <Margin left={16} />
        <InputContainer $disabled={isUploading}>
          {isUploading && (
            <SpinnerContainer>
              <Spinner size={15} fill="dodgerBlue" />
            </SpinnerContainer>
          )}
          <StyledInput
            $disabled={isUploading}
            onChange={handleUploadFile}
            disabled={isUploading}
            type="file"
            accept=".png,.jpg,.jpeg"
          />
        </InputContainer>
      </Images>
    </Container>
  )
}
