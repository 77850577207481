import React, { Component } from "react";
import ReactDOM from "react-dom";

class Popup extends Component {
  constructor() {
    super();
    this.renderContent = this.renderContent.bind(this);
    this.handleEscapeKey = this.handleEscapeKey.bind(this);
    this.getClassNameForOkButton = this.getClassNameForOkButton.bind(this);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleEscapeKey);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscapeKey);
  }
  handleEscapeKey(event) {
    if (event.which === 27 || event.keyCode === 27) this.props.onClose();
  }
  getClassNameForOkButton() {
    if (this.props.okButton && this.props.okButton.isEnable) {
      if (this.props.okButton.text.includes("Delete")) return "redBtn";
      else return "cs-btn cs-primary-btn";
    } else return "cs-btn cs-disabled-btn";
  }
  renderContent() {
    if (this.props.isReactComponent) {
      return (
        <div
          className={
            this.props.showContentClass === false ? "" : "popup-new-content"
          }
        >
          {this.props.popupContent}
        </div>
      );
    } else
      return (
        <div
          dangerouslySetInnerHTML={{ __html: this.props.popupContent }}
          className="popup-new-content"
        ></div>
      );
  }
  render() {
    if (this.props.okButton)
      console.log("this.props.okButton.isEnable", this.props.okButton.isEnable);
    return (
      <div
        className={"popup-container" + (this.props.display ? " show" : " dn")}
      >
        <aside
          className={
            "cs-popup-model popup-new" +
            (this.props.styleClass ? " " + this.props.styleClass : "")
          }
        >
          <header>
            <h2>
              {this.props.headerIcon ? this.props.headerIcon : ""}
              {this.props.popupHeader}
            </h2>
            <cite onClick={this.props.onClose}>×</cite>
          </header>
          {this.renderContent()}
          <div
            className={
              "popupBtnHolder " + (this.props.hideDecisionButtons ? "dn" : "db")
            }
          >
            <button
              className="cs-btn cs-decline-btn"
              style={{
                visibility:
                  this.props.cancelButton && this.props.cancelButton.display
                    ? "visible"
                    : "hidden",
              }}
              onClick={this.props.onCancel}
            >
              {this.props.cancelButton
                ? this.props.cancelButton.text
                : "cancel"}
            </button>
            <button
              className={
                this.getClassNameForOkButton()
              }
              style={{
                opacity:
                  this.props.okButton && this.props.okButton.isEnable == false
                    ? "0.5"
                    : "1.0",
              }}
              disabled={
                this.props.okButton && this.props.okButton.isEnable == false
                  ? true
                  : false
              }
              onClick={this.props.onOk}
            >
              {this.props.okButton ? this.props.okButton.text : "ok"}
            </button>
          </div>
        </aside>
        <div className={"transprentBg db"}></div>
      </div>
    );
  }
}
export default Popup;
