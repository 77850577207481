import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import VisitorDetails from "../components/chat/VisitorDetails.jsx";
import {
  requestUpdateContact,
  getVisitorTimeZone,
  visitorTimeZoneResponse,
  visitorTimeZoneFailed,
} from "../actions/ContactInfoAction";
import {
  isValidEmail,
  isValidPhoneNumber,
  getContactDetailsToPostToSBIFrame,
  getProjectId,
  getQueryParam,
  getUserPermissionUtility,
  capitalizeFirstLetter,
  getIsAWRecentComponent,
} from "../commons/Utility";
import { displayVisitorType } from "../commons/Constants.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { requestCustomFields } from "../actions/CustomFieldsAction";
import * as TagActions from "../actions/TagActions";
import * as EventsTracking from "../commons/EventsTracking.js";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";
import { visitorConversationListRequest } from "../actions/ConversationInfoAction.js";
import { isValidObject } from "../commons/Utility.js";
import { browserHistory } from "react-router";

@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  CustomFieldsReducer: state.CustomFieldsReducer,
  TagReducer: state.TagReducer,
  UserReducer: state.UserReducer,
  PeopleReducer : state.PeopleReducer
}))
export default class VisitorDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.updateContact = this.updateContact.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
    this.validate = this.validate.bind(this);
    this.setLocalState = this.setLocalState.bind(this);
    this.onTagSelect = this.onTagSelect.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.addTags = this.addTags.bind(this);
    this.messageFromSB = this.messageFromSB.bind(this);
    this.isUpdateOrFetchInProgress = this.isUpdateOrFetchInProgress.bind(this);
    this.isContactChanged = this.isContactChanged.bind(this);
    this.isContactStateNotInitialized = this.isContactStateNotInitialized.bind(this);
    this.canUpdateLocalState = this.canUpdateLocalState.bind(this);
    this.shouldSyncContactWithSB = this.shouldSyncContactWithSB.bind(this);
    this.onAddCustomPropertyClicked = this.onAddCustomPropertyClicked.bind(
      this
    );
    this.newCustomFieldNameOnBlur = this.newCustomFieldNameOnBlur.bind(this);
    this.handleAddCustomFields = this.handleAddCustomFields.bind(this);
    this.getVisitorSortedConversationList = this.getVisitorSortedConversationList.bind(
      this
    );
    this.state = {
      refContact: {
        fullName: "",
        newCustomFieldName: "",
        newCustomFieldValue: "",
        tags: [],
      },
      isOverflowConversation: false,
      customFieldsMap: {},
      tagsMap: {},
      initialKey: "",
      showAddCustomProperty: false,
      isAwWindow: false,
      visitorConversationMap: {},
    };
  }
  componentWillMount() {
    this.setLocalState(this.props, null, () => {
      if (this.props.params.visitorOption == "H" && !this.props.isAwWindow)
        browserHistory.push(
          this.props.isOverflowConversation
            ? "/overflow/D" + location.search
            : "/app/" +
                this.props.params.projectkey +
                "/chat/dashboard/D/" +
                this.props.params.chatStatus +
                "/" +
                this.props.params.sortBy +
                "/" +
                this.props.params.assignedTo +
                "/" +
                (this.props.params.conversationId
                  ? this.props.params.conversationId
                  : "") +
                location.search
        );

      if (
        !this.props.CustomFieldsReducer.isFetching &&
        !this.props.CustomFieldsReducer.isFetched &&
        this.state.refContact.projectId &&
        this.state.refContact.projectId !==
          this.props.CustomFieldsReducer.projectId
      )
        this.props.dispatch(
          requestCustomFields(
            this.state.refContact.projectId,
            this.props.isOverflowConversation,
            [],
            this.props.isAwWindow
          )
        );

      if (
        !this.props.TagReducer.isFetching &&
        !this.props.TagReducer.isFetched &&
        this.state.refContact.projectId &&
        this.state.refContact.projectId !== this.props.TagReducer.projectId &&
        !this.props.isOverflowConversation &&
        !this.props.isAwWindow
      )
        this.props.dispatch(
          TagActions.requestTagList(this.state.refContact.projectId)
        );
      if (
        ((!this.props.ConversationReducer.isVisitorConversationFetching &&
          !this.props.ConversationReducer.isVisitorConversationFetched) ||
          (this.props.ConversationReducer.visitorId !=
            this.state.refContact.key || getIsAWRecentComponent())) &&
        this.state.refContact.key &&
        this.state.refContact.projectId
      )
        this.props.dispatch(
          visitorConversationListRequest({
            isFromOverflow: this.props.isOverflowConversation,
            visitorId: this.state.refContact.key,
            projectId: this.state.refContact.projectId,
            isConversationStarted: true,
            cursor: "",
            desc: true,
            limit: 20,
            isNewList: true,
            orderBy: "-modifiedDate",
          })
        );
    });
  }
  componentDidMount() {
    document.addEventListener("click", this.handleAddCustomFields, false);
    document.addEventListener("focus", this.handleAddCustomFields, false);
    window.addEventListener("message", this.messageFromSB, false);
    if (this.state.actualContact && !this.state.actualContact.key) {
      let contact = this.props.ConversationReducer.conversationObject
        .contactsMap[this.props.visitorId];
      if (contact) this.setState({ actualContact: contact });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.debug("VisitorDetailsContainer: componentWillReceiveProps:", JSON.stringify(nextProps.params));
    if ((this.props.visitorId != nextProps.visitorId || (getIsAWRecentComponent() && (this.props.params.conversationId !== nextProps.params.conversationId))) && !this.props.isAwWindow) {
      nextProps.dispatch(
        visitorConversationListRequest({
          isFromOverflow: nextProps.isOverflowConversation,
          visitorId: nextProps.visitorId,
          projectId: getIsAWRecentComponent() ? this.state.refContact.projectId : getProjectId(nextProps.params.projectkey),
          isConversationStarted: true,
          cursor: "",
          desc: true,
          limit: 20,
          isNewList: true,
          orderBy: "-modifiedDate",
        })
      );
      browserHistory.push(
        this.props.isOverflowConversation
          ? "/overflow/D" + location.search
          : "/app/" +
              nextProps.params.projectkey +
              "/chat/dashboard/D/" +
              nextProps.params.chatStatus +
              "/" +
              nextProps.params.sortBy +
              "/" +
              nextProps.params.assignedTo +
              "/" +
              (nextProps.params.conversationId
                ? nextProps.params.conversationId
                : "") +
              location.search
      );
    }
    this.setLocalState(nextProps, this.props, () => {
      if (
        this.state.refContact.projectId &&
        nextProps.CustomFieldsReducer.projectId !=
          this.state.refContact.projectId &&
        !nextProps.CustomFieldsReducer.isFetching
      )
        nextProps.dispatch(
          requestCustomFields(
            this.state.refContact.projectId,
            this.props.isOverflowConversation,
            [],
            this.props.isAwWindow
          )
        );
      if (
        this.state.refContact.projectId &&
        nextProps.TagReducer.projectId != this.state.refContact.projectId &&
        !nextProps.TagReducer.isFetching &&
        !this.props.isOverflowConversation &&
        !this.props.isAwWindow
      )
        nextProps.dispatch(
          TagActions.requestTagList(this.state.refContact.projectId)
        );
    });
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleAddCustomFields, false);
    document.removeEventListener("focus", this.handleAddCustomFields, false);
    clearInterval(this.updateLastContactedTimer);
    clearInterval(this.updateFirstContactedTimer);
  }

  getVisitorSortedConversationList(conversationMap) {
    let conversationList = Object.values(conversationMap);
    let sortedConversationList = {};
    let currentConversationId =
      this.props.params && this.props.params.conversationId
        ? this.props.params.conversationId
        : getQueryParam("conversation_id")
        ? getQueryParam("conversation_id")
        : "";
    conversationList = conversationList.sort((a, b) => {
      return b["modifiedDate"] - a["modifiedDate"];
    });

    conversationList.map((conversation, i) => {
      if (currentConversationId != conversation.key)
        sortedConversationList[conversation.key] = conversation;
    });
    return sortedConversationList;
  }

  isUpdateOrFetchInProgress(props) {
    if(props.ConversationReducer.isFetching && props.ConversationReducer.isUpdating) {
      return true;
    }
  }
  
  isContactStateNotInitialized(props, contact) {
    if(isValidObject(contact) && props.visitorId && contact.key == props.visitorId && !this.state.refContact.key) {
      return true;
    }
  }
  
  canUpdateLocalState(props, contact) {
    if (
      this.isContactStateNotInitialized(props, contact) ||
      !this.isUpdateOrFetchInProgress(props)
    ) {
      return true;
    }
    return false;
  }
  isContactChanged(contact, oldContact) {
    console.debug("isContactChanged contact:", contact);
    return contact && oldContact &&
    ((contact.email && oldContact.email !== contact.email) ||
      (contact.displayPhoneNumber &&
        oldContact.displayPhoneNumber !== contact.displayPhoneNumber) ||
      (contact.fullName && oldContact.fullName !== contact.fullName) ||
      (contact.companyName && oldContact.companyName !== contact.companyName))
  }
  
  shouldSyncContactWithSB(contact, oldContact, props) {
    return (
      props.isOverflowConversation &&
      this.isContactChanged(contact, oldContact) &&
      !this.isUpdateOrFetchInProgress(props) 
    );
  }

  setLocalState(props, previousProps, callBack) {
    let contactsMap = props?.ConversationReducer?.conversationObject?.contactsMap
        ? props.ConversationReducer.conversationObject.contactsMap
        : {};
    let visitorId = props.visitorId ? props.visitorId : "";    
    let contact = Object.assign({},contactsMap[visitorId] ? contactsMap[visitorId] : {});
    if (this.canUpdateLocalState(props, contact)) {
      let timeZoneMap = props.ConversationReducer.timeZoneMap
        ? props.ConversationReducer.timeZoneMap
        : {};
      let oldContactsMap =
        previousProps &&
        previousProps.ConversationReducer.conversationObject.contactsMap
          ? previousProps.ConversationReducer.conversationObject.contactsMap
          : {};
      
      let oldVisitorId =
        previousProps && previousProps.visitorId ? previousProps.visitorId : "";
    
      contact.customFields = Object.assign({}, contact.customFields);
      let oldContact = Object.assign(
        {},
        oldContactsMap && oldContactsMap[oldVisitorId]
          ? oldContactsMap[oldVisitorId]
          : {}
      );
      oldContact.customFields = Object.assign({}, oldContact.customFields);
      oldContact.tags = Object.assign([], oldContact.tags);
      let fullName = contact && contact.firstName ? contact.firstName : "";
      fullName += contact && contact.lastName ? " " + contact.lastName : "";
      let initials = contact
        ? contact.firstName
          ? contact.firstName.charAt(0)
          : contact.type
          ? displayVisitorType[contact.type].charAt(0)
          : "V"
        : "V";
      initials += contact && contact.lastName ? contact.lastName.charAt(0) : "";

      contact.fullName = fullName;
      contact.newCustomFieldName = "";
      contact.newCustomFieldValue = "";
      contact.initials = initials;
      if (
        contact.key &&
        !contact.timeZoneObject &&
        !contact.isTimeZoneFetching &&
        !contact.isTimeZoneFetchFailed
      ) {
        if (
          contact.latitude &&
          contact.latitude != "null" &&
          contact.longitude &&
          contact.longitude != "null"
        ) {
          let latitude = parseFloat(contact.latitude).toFixed(2);
          let longitude = parseFloat(contact.longitude).toFixed(2);

          if (timeZoneMap[latitude + "," + longitude])
            this.props.dispatch(
              visitorTimeZoneResponse(contact.key, latitude, longitude, {
                ...timeZoneMap[latitude + "," + longitude],
              })
            );
          else{
            this.props.dispatch(
              getVisitorTimeZone(contact.key, latitude, longitude)
            );}
        } else this.props.dispatch(visitorTimeZoneFailed(contact.key));
      }
      let activeElement = document.activeElement;
      if (activeElement.tagName == "INPUT" && activeElement.dataset.id) {
        if (
          activeElement.dataset.id == "name" &&
          this.state.refContact.fullName &&
          this.state.refContact.fullName.trim() &&
          contact.fullName != this.state.refContact.fullName
        ) {
          contact.fullName = this.state.refContact.fullName;
        } else if (
          activeElement.dataset.id == "email" &&
          this.state.refContact.email &&
          this.state.refContact.email.trim() &&
          contact.email != this.state.refContact.email
        ) {
          contact.email = this.state.refContact.email;
        } else if (
          activeElement.dataset.id == "phone_number" &&
          this.state.refContact.displayPhoneNumber &&
          this.state.refContact.displayPhoneNumber.trim() &&
          contact.displayPhoneNumber != this.state.refContact.displayPhoneNumber
        ) {
          contact.displayPhoneNumber = this.state.refContact.displayPhoneNumber;
        } else if (
          activeElement.dataset.id == "company_name" &&
          this.state.refContact.companyName &&
          this.state.refContact.companyName.trim() &&
          contact.companyName != this.state.refContact.companyName
        ) {
          contact.companyName = this.state.refContact.companyName;
        }
      }
      this.setState(
        {
          ...this.state,
          refContact: {
            ...contact,
            tags:
              contact &&
              contact.tags &&
              contact.tags.length != oldContact.tags.length
                ? contact.tags
                : this.state.refContact.tags,
          },
          customFieldsMap: Object.assign(
            {},
            props.CustomFieldsReducer.customFieldsMap
          ),
          isOverflowConversation: props.isOverflowConversation,
          tagsMap: props.TagReducer.tagMap,
          actualContact: oldContact,
          present_CF_Ids: Object.keys(contact.customFields),
          isAwWindow: props.isAwWindow,
          visitorConversationMap: this.getVisitorSortedConversationList(
            props.ConversationReducer.visitorConversationObject.conversationMap
          ),
        },
        callBack
      );
      if (this.shouldSyncContactWithSB(contact, oldContact, props)) 
        this.postContactDetailsToSB(contact);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.ConversationReducer.isUpdating) return false;
    return true;
  }

  handleAddCustomFields(event) {
    if (
      (this.state.isOverflowConversation || this.state.isAwWindow) &&
      !ReactDOM.findDOMNode(this.child).contains(event.target) &&
      event.target.id !== "showVisitorDetailsButton" &&
      this.props.displayVisitorDetails
    ) {
      this.props.hideVisitorDetails();
    }
    if (
      this.child &&
      this.child.refs.new_custom_field_name &&
      !ReactDOM.findDOMNode(this.child.refs.new_custom_field_name).contains(
        event.target
      ) &&
      this.child.refs.new_custom_field_value &&
      !ReactDOM.findDOMNode(this.child.refs.new_custom_field_value).contains(
        event.target
      ) &&
      this.child.refs.add_property_button &&
      !ReactDOM.findDOMNode(this.child.refs.add_property_button).contains(
        event.target
      )
    ) {
      if (
        this.state.showAddCustomProperty &&
        this.child.refs.new_custom_field_name.value === "" &&
        this.child.refs.new_custom_field_value.value === ""
      )
        this.setState({ ...this.state, showAddCustomProperty: false });
    }
  }

  updateHandler(e) {
    if (e.type == "keypress" && e.key === "Enter") {
      if (e.target.dataset.id == "name") {
        let value = this.child.refs.email.value;
        this.child.refs.email.focus();
        this.child.refs.email.value = "";
        this.child.refs.email.value = value;
      } else if (e.target.dataset.id == "email") {
        let value = this.child.refs.display_phone_number.value;
        this.child.refs.display_phone_number.focus();
        this.child.refs.display_phone_number.value = "";
        this.child.refs.display_phone_number.value = value;
      } else if (e.target.dataset.id == "phone_number") {
        let value = this.child.refs.companyName.value;
        this.child.refs.companyName.focus();
        this.child.refs.companyName.value = "";
        this.child.refs.companyName.value = value;
      } else if (e.target.dataset.id == "company_name") {
        this.updateContact();
      }
    } else if (e.type == "change") {
      let contact = this.state.refContact;
      let updatedFullName = this.child.refs.name
        ? this.child.refs.name.value
        : this.state.refContact.fullName;
      let updatedEmail = this.child.refs.email
        ? this.child.refs.email.value
        : this.state.refContact.email;
      let updatedCompanyName = this.child.refs.companyName
        ? this.child.refs.companyName.value
        : this.state.refContact.companyName;
      let updatedDisplayPhoneNumber = this.child.refs.display_phone_number
        ? this.child.refs.display_phone_number.value
        : this.state.refContact.display_phone_number;
      let newCustomFieldName = this.child.refs.new_custom_field_name
        ? this.child.refs.new_custom_field_name.value
        : this.state.refContact.newCustomFieldName;
      let newCustomFieldValue = this.child.refs.new_custom_field_value
        ? this.child.refs.new_custom_field_value.value
        : this.state.refContact.newCustomFieldValue;
      console.log("fullName: ", updatedFullName);
      console.log("email: ", updatedEmail);
      console.log("displayPhoneNumber: ", updatedDisplayPhoneNumber);
      console.log("companyName: ", updatedCompanyName);

      this.setState({
        ...this.state,
        refContact: {
          ...contact,
          email: updatedEmail,
          displayPhoneNumber: /^[0-9-+(). extEXT]*$/gm.test(
            updatedDisplayPhoneNumber
          )
            ? updatedDisplayPhoneNumber
            : contact.displayPhoneNumber,
          fullName: updatedFullName,
          companyName: updatedCompanyName,
          newCustomFieldName: newCustomFieldName,
          newCustomFieldValue: newCustomFieldValue,
        },
      });
    }
  }
  updateContact(isSBContactUpdate, interactionId, sbEvent) {
    let contact = Object.assign({}, this.state.refContact);
    let customFields = Object.assign({}, contact.customFields);
    let updatedFullName = contact.fullName.trim();
    updatedFullName = capitalizeFirstLetter(updatedFullName);
    updatedFullName = updatedFullName.replace(/ +/g, " ").split(" ");
    let updatedLastName =
      updatedFullName.length > 1 ? updatedFullName.pop() : "";
    let updatedFirstName = updatedFullName.join(" ");
    let actualContact = Object.assign({}, this.state.actualContact);
    // if(Object.keys(actualContact).length==0 || !actualContact.key)
    //   actualContact = this.props.ConversationReducer.conversationObject.contactsMap[contact.key] || {};
    actualContact.customFields = Object.assign({}, actualContact.customFields);
    let refContact = Object.assign(contact, {
      firstName: updatedFirstName.trim() ? updatedFirstName.trim() : "",
      lastName: updatedLastName.trim() ? updatedLastName.trim() : "",
      email: contact.email ? contact.email.trim() : "",
      companyName: contact.companyName ? contact.companyName.trim() : "",
      displayPhoneNumber: contact.displayPhoneNumber
        ? contact.displayPhoneNumber.trim()
        : "",
      newCustomFieldName: contact.newCustomFieldName
        ? contact.newCustomFieldName.trim()
        : "",
      newCustomFieldValue: contact.newCustomFieldValue
        ? contact.newCustomFieldValue.trim()
        : "",
      customFields: customFields,
    });

    if (
      this.validate(actualContact, refContact) &&
      !this.props.ConversationReducer.isUpdating
    ) {
      for (let key in actualContact.customFields) {
        if (this.state.customFieldsMap[key]) {
          actualContact.customFields[this.state.customFieldsMap[key].name] =
            actualContact.customFields[key];
        }
        delete actualContact.customFields[key];
      }
      if (refContact.newCustomFieldName && refContact.newCustomFieldValue) {
        actualContact.customFields[refContact.newCustomFieldName] =
          refContact.newCustomFieldValue;
        let customField = {};
        customField[refContact.newCustomFieldName] =
          refContact.newCustomFieldValue;
        customField["projectId"] = refContact.projectId;
        EventsTracking.trackCustomFieldCreation(customField);
      } else if (this.state.isOverflowConversation) {
        this.postContactDetailsToSB(actualContact);
      }
      //Event("LS-" + refContact.projectId, "Visitor Details", event.target.dataset.id + " changed","Name Added");
      this.setState(
        {
          ...this.state,
          refContact: {
            ...refContact,
            newCustomFieldName: "",
            newCustomFieldValue: "",
          },
          showAddCustomProperty: false,
        },
        () => {
          this.props.dispatch(
            requestUpdateContact({
              contact: actualContact,
              interactionId: interactionId,
              present_CF_Ids: this.state.present_CF_Ids,
              isFromOverflow: this.state.isOverflowConversation,
              isSBContactUpdate: isSBContactUpdate === true ? true : false,
              sbEvent : sbEvent
            })
          );
        }
      );
    } else {
      this.setState({
        refContact: {
          ...actualContact,
          fullName:
            (actualContact.firstName ? actualContact.firstName : "") +
            (actualContact.lastName ? " " + actualContact.lastName : ""),
          newCustomFieldName: this.state.refContact.newCustomFieldName
            ? this.state.refContact.newCustomFieldName
            : "",
          newCustomFieldValue: this.state.refContact.newCustomFieldValue
            ? this.state.refContact.newCustomFieldValue
            : "",
        },
      });
    }
  }
  validate(actualContact, refContact) {
    actualContact.firstName =
      actualContact.firstName === null ? "" : actualContact.firstName;
    actualContact.lastName =
      actualContact.lastName === null ? "" : actualContact.lastName;
    actualContact.email =
      actualContact.email === null ? "" : actualContact.email;
    actualContact.companyName =
      actualContact.companyName === null ? "" : actualContact.companyName;
    actualContact.displayPhoneNumber =
      actualContact.displayPhoneNumber === null
        ? ""
        : actualContact.displayPhoneNumber;
    // let invalids                  =   [];
    let isDetailsChanged = false;
    if (actualContact) {
      if (
        actualContact.firstName !== refContact.firstName ||
        actualContact.lastName !== refContact.lastName
      ) {
        if (
          this.isValidName(refContact.firstName) &&
          this.isValidName(refContact.lastName)
        ) {
          actualContact.firstName = refContact.firstName;
          actualContact.lastName = refContact.lastName;
          isDetailsChanged = true;
        } else {
          if (this.props.isOverflowConversation)
            this.props.dispatch(
              VoiceboxActions.showVoicebox({
                message:
                  "Enter a valid name. Can’t include numbers or special characters.",
                dismissAfter: 3000,
                showError: true,
                showFullScreenLoader: true,
              })
            );
          else {
            this.props.dispatch(
              VoiceboxActions.showVoicebox({
                message:
                  "Enter a valid name. Can’t include numbers or special characters.",
                dismissAfter: 3000,
                showBelowHeader: true,
                showError: true,
              })
            );
          }

          this.child.refs.name.focus();
        }
      }
      if (
        ((actualContact.displayPhoneNumber === "" && refContact.email === "") ||
          (actualContact.email === "" &&
            refContact.displayPhoneNumber === "")) &&
        actualContact.type === "LEAD"
      ) {
        if (this.props.isOverflowConversation)
          this.props.dispatch(
            VoiceboxActions.showVoicebox({
              message: "Both phone number and email cannot be empty for a lead",
              dismissAfter: 3000,
              showError: true,
              showFullScreenLoader: true,
            })
          );
        else
          this.props.dispatch(
            VoiceboxActions.showVoicebox({
              message: "Both phone number and email cannot be empty for a lead",
              dismissAfter: 3000,
              showBelowHeader: true,
              showError: true,
            })
          );
      } else {
        if (actualContact.email !== refContact.email) {
          if (!refContact.email || isValidEmail(refContact.email)) {
            actualContact.email = refContact.email;
            isDetailsChanged = true;
          } else {
            // invalids.push("email");
            if (this.props.isOverflowConversation)
              this.props.dispatch(
                VoiceboxActions.showVoicebox({
                  message: "Enter a valid email address.",
                  dismissAfter: 3000,
                  showError: true,
                  showFullScreenLoader: true,
                })
              );
            else
              this.props.dispatch(
                VoiceboxActions.showVoicebox({
                  message: "Enter a valid email address.",
                  dismissAfter: 3000,
                  showBelowHeader: true,
                  showError: true,
                })
              );
            this.child.refs.email.focus();
          }
        }
        if (actualContact.companyName !== refContact.companyName) {
          actualContact.companyName = refContact.companyName;
          isDetailsChanged = true;
        }
        if (
          actualContact.displayPhoneNumber !== refContact.displayPhoneNumber
        ) {
          if (
            !refContact.displayPhoneNumber ||
            isValidPhoneNumber(refContact.displayPhoneNumber.toLowerCase())
          ) {
            actualContact.displayPhoneNumber = refContact.displayPhoneNumber.toLowerCase();
            isDetailsChanged = true;
          } else {
            // invalids.push("phone number")
            if (this.props.isOverflowConversation)
              this.props.dispatch(
                VoiceboxActions.showVoicebox({
                  message: "Enter a valid phone number.",
                  dismissAfter: 3000,
                  showError: true,
                  showFullScreenLoader: true,
                })
              );
            else
              this.props.dispatch(
                VoiceboxActions.showVoicebox({
                  message: "Enter a valid phone number.",
                  dismissAfter: 3000,
                  showBelowHeader: true,
                  showError: true,
                })
              );
            this.child.refs.display_phone_number.focus();
          }
        }
      }
      if (refContact.newCustomFieldValue !== "") {
        if (refContact.newCustomFieldName !== "") isDetailsChanged = true;
        else {
          if (this.props.isOverflowConversation)
            this.props.dispatch(
              VoiceboxActions.showVoicebox({
                message: "Please enter the name of custom field",
                dismissAfter: 3000,
                showError: true,
                showFullScreenLoader: true,
              })
            );
          else
            this.props.dispatch(
              VoiceboxActions.showVoicebox({
                message: "Please enter the name of custom field",
                dismissAfter: 3000,
                showBelowHeader: true,
                showError: true,
              })
            );
        }
      } else if (refContact.newCustomFieldName !== "") {
        if (this.props.isOverflowConversation)
          this.props.dispatch(
            VoiceboxActions.showVoicebox({
              message: "Please enter the value of custom field",
              dismissAfter: 3000,
              showError: true,
              showFullScreenLoader: true,
            })
          );
        else {
          this.props.dispatch(
            VoiceboxActions.showVoicebox({
              message: "Please enter the value of custom field",
              dismissAfter: 3000,
              showBelowHeader: true,
              showError: true,
            })
          );
        }
      }
    }
    // let invalids_length = invalids.length;
    // if( invalids_length >0 )
    // {
    //     this.props.dispatch( VoiceboxActions.showVoicebox( { message : 'Invalid '+ ( invalids.length < 2 ? invalids[0] : invalids.join(" and ")), dismissAfter : 3000, showBelowHeader : true, showError : true } ) );
    // }
    if(isDetailsChanged) {
      actualContact.contact = refContact.contact;
      return true;
    }
    return false;
  }
  postContactDetailsToSB(contact) {
    let data = getContactDetailsToPostToSBIFrame(contact);
    console.log("Posting Vistor Details To SB: " + JSON.stringify(data));
    window.parent.postMessage(data, "*");
  }
  messageFromSB(event) {
    let data = event && event.data;
    let type = data && data.type;
    if (type === "sb-contact-update") {
      console.log("SB Contact Update : " + JSON.stringify(event.data));
      let oldContact = Object.assign({}, this.state.refContact);
      let contact = data.data || {};
      if (contact.constructor === Object && Object.keys(contact).length > 0) {
        let firstName =
          contact.firstName && oldContact.firstName !== contact.firstName
            ? contact.firstName
            : oldContact.firstName;
        let lastName =
          contact.lastName && oldContact.lastName !== contact.lastName
            ? contact.lastName
            : oldContact.lastName;
        let fullName = firstName ? firstName : "";
        fullName += lastName ? " " + lastName : "";
        let email =
          contact.email && oldContact.email !== contact.email
            ? contact.email
            : oldContact.email;
        let formPhoneNumber =
          contact.phoneNumber && contact.phoneNumber.value
            ? contact.phoneNumber.value
            : "";
        let splitArray = formPhoneNumber.split("+");
        splitArray = splitArray.filter((a) => a);
        if (
          splitArray.length > 1 &&
          splitArray[1].trim().startsWith(splitArray[0].trim())
        ) {
          formPhoneNumber = formPhoneNumber.substring(
            formPhoneNumber.lastIndexOf("+"),
            formPhoneNumber.length
          ); //removing duplicate country number getting appended each and every time updated via v2 form.
        }
        let displayPhoneNumber =
          oldContact.displayPhoneNumber !== formPhoneNumber
            ? formPhoneNumber
            : oldContact.displayPhoneNumber;
        let companyName =
          contact.companyName && oldContact.companyName !== contact.companyName
            ? contact.companyName
            : oldContact.companyName;
        this.setState(
          {
            ...this.state,
            refContact: {
              ...oldContact,
              email,
              displayPhoneNumber,
              fullName,
              companyName,
            },
          },
          () => {
            this.updateContact(true, contact.interactionId, JSON.stringify(event.data));
          }
        );
      }
    }
  }
  onTagSelect(value, valueKey, type) {
    console.log("value: ", value);
    console.log("valueKey: ", valueKey);

    this.setState(
      {
        ...this.state,
        refContact: {
          ...this.state.refContact,
          tags: [...this.state.refContact.tags, valueKey],
        },
      },
      () => {
        this.props.dispatch(
          TagActions.addTagsRequest(
            [value],
            this.state.refContact.projectId,
            this.state.refContact.key
          )
        );
        Event(
          this.state.refContact.projectId,
          "Tags",
          "Existing Tag",
          "Existing tag added to a person"
        );
      }
    );
  }
  removeTag(e) {
    if (!getUserPermissionUtility("EditVistorDetails")) return;
    let tagId = e.currentTarget.dataset.tag_id;
    console.log("tagId: ", tagId);
    let tags = this.state.refContact.tags;
    var index = tags.indexOf(tagId);
    if (index > -1) tags.splice(index, 1);
    this.setState(
      { ...this.state, refContact: { ...this.state.refContact, tags: tags } },
      () => {
        this.props.dispatch(
          TagActions.removeTagRequest(tagId, this.state.refContact)
        );
      }
    );
  }
  addTags(tags) {
    let existing = false;
    if (tags && tags.trim()) {
      let tagNames = tags.split(",");
      let filteredTagNames = [];
      let duplicateTags = [];
      let invalidTags = [];
      let tagsToBeAdded = [];
      for (let tagName of tagNames) {
        let trimmedTagName = tagName.trim();
        let tagsMap = this.state.tagsMap;
        for (let key in tagsMap) {
          if (
            tagsMap[key].name.toLowerCase() === trimmedTagName.toLowerCase()
          ) {
            trimmedTagName = tagsMap[key].name;
            tagsToBeAdded.push(key);
            existing = true;
          }
        }
        let isDuplicate = false;
        if (this.state.refContact.tags) {
          for (let tagKey of this.state.refContact.tags) {
            if (
              tagsMap[tagKey] &&
              tagsMap[tagKey].name.toLowerCase() ===
                trimmedTagName.toLowerCase()
            )
              isDuplicate = true;
          }
        }
        if (isDuplicate) duplicateTags.push(trimmedTagName);
        else if (trimmedTagName && !this.isValidTag(trimmedTagName))
          invalidTags.push(trimmedTagName);
        else if (trimmedTagName) filteredTagNames.push(trimmedTagName);
      }
      if (filteredTagNames.length > 0 && !existing)
        this.setState(
          {
            ...this.state,
            initialKey:
              duplicateTags.length > 0
                ? duplicateTags.toString() + ", "
                : "" + invalidTags.toString(),
            refContact: {
              ...this.state.refContact,
              tags: [...this.state.refContact.tags, ...tagsToBeAdded],
            },
          },
          () => {
            if (!duplicateTags.length > 0)
              this.props.dispatch(
                VoiceboxActions.showVoicebox({
                  message: "adding tag..",
                  showBelowHeader: true,
                })
              );

            this.props.dispatch(
              TagActions.addTagsRequest(
                filteredTagNames,
                this.state.refContact.projectId,
                this.state.refContact.key
              )
            );
            Event(
              this.state.refContact.projectId,
              "Tags",
              "New Tag",
              "New Tag Created"
            );
          }
        );
      let notificationMessage = "";
      if (duplicateTags.length > 0)
        notificationMessage =
          duplicateTags.length === 1
            ? "Duplicate Tag ( " + duplicateTags.toString() + " )"
            : "Duplicate Tags ( " + duplicateTags.toString() + " )";

      if (invalidTags.length > 0)
        notificationMessage =
          (notificationMessage ? notificationMessage + " and " : "") +
          (invalidTags.length === 1
            ? "invalid tag ( " + invalidTags.toString() + " ) "
            : "invalid tags ( " + invalidTags.toString() + " )");

      if (notificationMessage)
        this.props.dispatch(
          VoiceboxActions.showVoicebox({
            message: notificationMessage,
            dismissAfter: 3000,
            showBelowHeader: true,
            showError: true,
          })
        );
    } else {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "Please enter the tag name.",
          dismissAfter: 3000,
          showBelowHeader: true,
          showError: true,
        })
      );
    }
  }
  isValidTag(tagName) {
    if (tagName.trim() !== "" && /[a-z]|[A-Z]|[0-9]+/g.test(tagName.trim()))
      return true;
    return false;
  }
  onAddCustomPropertyClicked() {
    this.setState({ ...this.state, showAddCustomProperty: true }, () => {
      this.child.refs.new_custom_field_name.focus();
    });
  }
  newCustomFieldNameOnBlur(e) {
    if ((e.type == "keypress" && e.key === "Enter") || e.type == "blur") {
      let actualContact = Object.assign({}, this.state.actualContact);
      let value = e.target.value.trim();
      if (value) {
        let isPresent = false;
        for (let key in actualContact.customFields)
          if (
            this.state.customFieldsMap[key] &&
            this.state.customFieldsMap[key].name.toLowerCase() ==
              value.toLowerCase().trim()
          ) {
            isPresent = true;
            break;
          }
        if (isPresent) {
          this.setState(
            {
              ...this.state,
              refContact: {
                ...this.state.refContact,
                newCustomFieldName: "",
                newCustomFieldValue: "",
              },
            },
            () => {
              if (this.props.isOverflowConversation)
                this.props.dispatch(
                  VoiceboxActions.showVoicebox({
                    message: "Custom Field already exists",
                    dismissAfter: 3000,
                    showBelowHeader: true,
                    showError: true,
                  })
                );
            }
          );
        }
        this.child.refs.new_custom_field_value.focus();
      }
    }
  }
  isValidName(name) {
    return /^(|[a-zά-ωΑ-ώͰ-Ͼἀ-῾ ,.'-]|\ud800[\udd40-\udd8e]|\ud834[\ude00-\ude4e])+$/i.test(name);
  }
  render() {
    let contact = this.state.refContact;
    let customFieldsMap = this.state.customFieldsMap;
    let tagsMap = this.state.tagsMap;
    let classString = "";
    let classNames = this.props.classNames ? this.props.classNames : [];
    let isAwWindow = this.state.isAwWindow;
    let visitorConversationMap = this.state.visitorConversationMap;
    for (let className of classNames) {
      classString = classString + " " + className;
    }
    return (
      <VisitorDetails
        overflowedAgent={this.props.overflowedAgent}
        isAwWindow={isAwWindow}
        peopleReducer={this.props.PeopleReducer}
        userReducer={this.props.UserReducer}
        visitorConversationMap={visitorConversationMap}
        classString={classString}
        ref={(component) => (this.child = component)}
        updateHandler={this.updateHandler}
        selectHistory={this.selectHistory}
        selectDetails={this.selectDetails}
        updateContact={this.updateContact}
        contact={contact}
        params={this.props.params}
        customFieldsMap={customFieldsMap}
        tagsMap={tagsMap}
        initialKey={this.state.initialKey}
        displayVisitorDetails={this.props.displayVisitorDetails}
        isOverflowConversation={this.props.isOverflowConversation}
        onTagSelect={this.onTagSelect}
        removeTag={this.removeTag}
        addTags={this.addTags}
        onAddCustomPropertyClicked={this.onAddCustomPropertyClicked}
        showAddCustomProperty={this.state.showAddCustomProperty}
        newCustomFieldNameOnBlur={this.newCustomFieldNameOnBlur}
        hideVisitorDetails={this.props.hideVisitorDetails}
      />
    );
  }
}

