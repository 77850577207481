import React, { Component } from "react";
import ReactDOM from "react-dom";
import DropDown from "../common/DropDown.jsx";
export default class AddTagsPopup extends Component {
  constructor() {
    super();
  }

  render() {
    console.log("this.props: ", this.props);
    let selectedTags = [];
    this.props.state.selectedTagNames.map((tag) => {
      selectedTags.push(
        <span className="chip" key={tag}>
          {tag}
          <cite data-tag_name={tag} onClick={this.props.removeFromSelectedTags}>
            x
          </cite>
        </span>
      );
    });
    this.props.state.presentTagNames.map((tag) => {
      selectedTags.push(
        <span className="chip" key={tag.key}>
          {tag.name}
          <cite
            data-tag_id={tag.key}
            onClick={this.props.removeFromPresentTags}
          >
            x
          </cite>
        </span>
      );
    });

    return (
      <div className="formField">
        <div className="inputTxt">
          <label>Tag Names</label>
          <input
            autoFocus
            onBlur={this.props.onTagsBlur}
            type="text"
            ref="tagNames"
            onKeyPress={this.props.keyPressHandler}
            onChange={this.props.isValidTag}
            placeholder="Enter the tag names here"
            style={{ width: "100%" }}
          />
          <DropDown
            classNames={["autosuggestion-popup"]}
            showDropDown={this.props.state.showTagsDropDown}
            valuesMap={this.props.state.dropDownValuesMap}
            onStaffSelect={this.props.onTagSelect}
          />
          <div>{selectedTags}</div>
        </div>
      </div>
    );
  }
}
