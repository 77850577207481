import { call, put } from "redux-saga/effects";
import * as Ajax from "../../xhr/XhrRequestHandler";
import * as WorkingHoursAction from "../actions/WorkingHoursAction";
import * as NotificationActions from "../../actions/NotificationAction";
import { handleGlobalError } from "../../actions/GlobalErrorAction";
import { showVoicebox } from "../../actions/VoiceboxAction";

export function* getWorkingHours(data) {
  try {
    const pathParams = data.userId + "/" + data.accountId;
    const userId = data.userId;
    const url = "/working/hours/get/user/" + pathParams;
    let response = yield call(Ajax.getUnauthenticated, url);
    if (response && !response.data.error && response.data.hoursList) {
      let workingHoursObject = {};
      let hoursList = [];
      hoursList = response.data.hoursList.filter((hours) => {
        if (hours.resourceId && hours.resourceId.startsWith("LS")) return hours;
      });
      workingHoursObject[userId] = hoursList[0]
        ? Object.assign({}, hoursList[0])
        : Object.assign({}, { hoursList: [] });
      yield put(
        WorkingHoursAction.receiveWorkingHours(workingHoursObject, userId)
      );
    } else {
      yield put(WorkingHoursAction.receiveWorkingHours(response.data, userId));
    }
  } catch (e) {
    console.error("Error occure while fetching working hours" + e);
    //yield put( handleGlobalError( e ) );
  }
}

export function* getBusinessHours(data) {
  try {
    let projectKey = data.projectKey;
    const pathParams = data.projectKey + "/" + data.accountId;
    const url = "working/hours/get/project/" + pathParams;
    let response = yield call(Ajax.getUnauthenticated, url);
    if (response && !response.data.error) {
      let projectId = response.data.resourceId;
      let workingHoursObject = {};
      workingHoursObject[projectId] = Object.assign({}, response.data);
      yield put(
        WorkingHoursAction.receiveBusinessHours(workingHoursObject, projectKey)
      );
    } else
      yield put(
        WorkingHoursAction.receiveBusinessHours(response.data, projectKey)
      );
  } catch (e) {
    console.error("Error occured while fetching business hours" + e);
  }
}

export function* createBusinessHours(data) {
  try {
    let projectKey = data.businessHours.resourceId;
    console.log("creating business hours", data);
    const url = "/working/hours/project/create";
    let response = yield call(
      Ajax.postUnauthenticated,
      url,
      data.businessHours
    );
    if (response && !response.data.error) {
      let projectId = response.data.resourceId;
      let workingHoursObject = {};
      workingHoursObject[projectId] = Object.assign({}, response.data);
      yield put(
        WorkingHoursAction.receiveBusinessHours(workingHoursObject, projectKey)
      );
    } else {
      yield put(
        WorkingHoursAction.createBusinessResponse(response.data, projectKey)
      );
    }
    yield put(
      showVoicebox({ message: "Update Successful", dismissAfter: 2000 })
    );
  } catch (e) {
    console.error("Error occured while updating business hours" + e);
  }
}

export function* updateWorkingHours(data) {
  try {
    let userId = "";
    let projectKey = "";
    console.log("Update working hours ", data.workingHours);
    const url = "/working/hours/update";
    let response = yield call(Ajax.putUnauthenticated, url, data.workingHours);
    if (response && !response.data.error) {
      userId = response.data.userId;
      let projectId = response.data.resourceId;
      let workingHoursObject = {};
      if (!userId) {
        projectKey = response.data.resourceId;
        workingHoursObject[projectId] = Object.assign({}, response.data);
        yield put(
          WorkingHoursAction.updateBusinessHoursResponse(
            workingHoursObject,
            projectKey
          )
        );
      } else {
        userId = response.data.userId;
        workingHoursObject[userId] = Object.assign({}, response.data);
        yield put(
          WorkingHoursAction.updateWorkingHoursResponse(
            workingHoursObject,
            userId
          )
        );
      }
      yield put(
        showVoicebox({ message: "Update Successful", dismissAfter: 2000 })
      );
    }
  } catch (e) {
    console.error("Error occure while updating working hours" + e);
  }
}

export function* createWorkingHours(data) {
  try {
    let userId = data.userId;
    const url = "/working/hours/user/create";
    let response = yield call(Ajax.postUnauthenticated, url, data.workingHours);
    if (response && !response.error) {
      let userId = response.data.userId;
      let workingHoursObject = {};
      workingHoursObject[userId] = Object.assign({}, response.data);
      yield put(
        WorkingHoursAction.createWorkingHoursResponse(
          workingHoursObject,
          userId
        )
      );
    } else {
      yield put(
        WorkingHoursAction.createWorkingHoursResponse(response.data, userId)
      );
    }
    yield put(
      showVoicebox({ message: "Update Successful", dismissAfter: 2000 })
    );
  } catch (e) {
    console.log("Error occure while updating working hours" + e);
  }
}
