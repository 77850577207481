import {
  EMAIL_OPT_OUT_PREFERENCES_REQUEST,
  EMAIL_OPT_OUT_PREFERENCES_RESPONSE,
  EMAIL_OPT_OUT_PREFERENCES_FAILED,
} from "../actions/EmailOptOutAction";
import {
  EMAIL_OPT_OUT_UPDATE_REQUEST,
  EMAIL_OPT_OUT_UPDATE_RESPONSE,
  EMAIL_OPT_OUT_UPDATE_FAILED,
  EMAIL_OPT_OUT_DELETE_REQUEST,
  PREFERENCE_NOT_FOUND,
} from "../actions/EmailOptOutAction";
const EmailOptOutReducer = (
  state = {
    emailOptOutPreferences: {
      unsubscribedList: new Set(),
      unsubscribeFromAll: false,
      projectId: "",
      key: "",
    },
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isUpdated: false,
    notFound: false,
  },
  action
) => {
  switch (action.type) {
    case EMAIL_OPT_OUT_PREFERENCES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case EMAIL_OPT_OUT_PREFERENCES_RESPONSE:
      return {
        ...state,
        emailOptOutPreferences: Object.assign(
          {},
          action.emailOptOutPreferences
        ),
        isFetching: false,
        isFetched: true,
      };
    case EMAIL_OPT_OUT_PREFERENCES_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case EMAIL_OPT_OUT_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case EMAIL_OPT_OUT_DELETE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case EMAIL_OPT_OUT_UPDATE_RESPONSE:
      return {
        ...state,
        emailOptOutPreferences: Object.assign(
          {},
          action.emailOptOutPreferences
        ),
        isUpdating: false,
        isUpdated: true,
      };
    case EMAIL_OPT_OUT_UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
      };
    case PREFERENCE_NOT_FOUND:
      return {
        ...state,
        notFound: true,
      };
  }
  return state;
};

export default EmailOptOutReducer;
