import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import * as ChatConfigurationAction from "../actions/ChatConfigurationAction";
import { staffListRequest } from "../../actions/StaffAction";
import {
  isValidObject,
  getProjectId,
  getWebappBaseUrl,
  getPaymentUrl,
} from "../../commons/Utility.js";
import { fetchChatPromptConfiguration } from "../actions/PromptActions";
import SettingsMenu from "./SettingsMenu";

@connect((state) => ({
  chatConfiguration: state.ChatConfigurationReducer,
  staffReducer: state.StaffReducer,
  promptConfiguration: state.ChatPromptConfigurationReducer,
  userDetails: state.UserReducer,
  projectReducer: state.ProjectReducer,
  LoginReducer: state.LoginReducer
}))
class SettingsLayout extends Component {
  constructor() {
    super();
    this.state = {
      showChatSettings: false,
    };

    this.handleSubMenu = this.handleSubMenu.bind(this);
    this.getSwitchAccountLink = this.getSwitchAccountLink.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if(!nextProps.LoginReducer.isLoggedIn)
      return;
    let projectKey = nextProps.params.projectkey;
    if (
      this.props.params.projectkey !== projectKey ||
      (!nextProps.chatConfiguration.isFetching &&
        !nextProps.chatConfiguration.data.hasOwnProperty(
          getProjectId(projectKey)
        ))
    )
      nextProps.dispatch(
        ChatConfigurationAction.requestChatConfiguration(
          projectKey,
          nextProps.chatConfiguration.data
        )
      );
    if (
      nextProps.staffReducer.dashboardAgents[getProjectId(projectKey)] ===
        undefined &&
      nextProps.staffReducer.isLoading != true &&
      !nextProps.staffReducer.isFetchFailed
    )
      nextProps.dispatch(staffListRequest(getProjectId(projectKey)));
    if (
      !nextProps.promptConfiguration.isFetching &&
      !nextProps.promptConfiguration.data.hasOwnProperty(
        getProjectId(projectKey)
      )
    )
      nextProps.dispatch(
        fetchChatPromptConfiguration(getProjectId(projectKey))
      );
  }

  componentWillMount() {
    let pathNames = this.props.location.pathname.split("/");
    let chat_Settigns = [
      "project",
      "widget",
      "preview",
      "domainwhitelist",
      "assignment",
      "prompts",
      "assignment",
    ];
    let off_hours = ["workinghours", "outofofficebot"];
    this.setState({
      showChatSettings: pathNames.some(
        (path) => chat_Settigns.indexOf(path) !== -1
      ),
    });
  }

  handleSubMenu(e) {
    this.setState({
      showChatSettings: e.target.dataset.id === "chat_settings",
    });
  }

  redirectToPaymentPage() {
    let { accountID, login } = this.props.userDetails.data;
    let { isInternalUser } = this.props.userDetails;
    let redirectUrl = getWebappBaseUrl();
    if (!isInternalUser && accountID && login)
      window.location = `${getPaymentUrl()}/payment?planId=4996&accountId=${accountID}&email=${login}&redirectUrl=${redirectUrl}`;
  }

  isSuperAdmin = () => {
    return isValidObject(this.props.userDetails.data)
      ? this.props.userDetails.data.isAdmin &&
          this.props.userDetails.data.isAdmin == "true"
      : false;
  };

  getOverflowLink() {
    if (this.isSuperAdmin()) {
      return (
        <li
          className={
            this.props.location.pathname.includes("settings/overflow")
              ? "active"
              : ""
          }
        >
          <Link
            to={"/app/" + this.props.params.projectkey + "/settings/overflow"}
            data-id="overflow"
            onClick={this.handleSubMenu}
          >
            Overflow Configuration
          </Link>
        </li>
      );
    } else {
      return "";
    }
  }
  getPreviewLink() {
    if (this.isSuperAdmin()) {
      return (
        <li
          className={
            this.props.location.pathname.includes("settings/widget/demo")
              ? "active"
              : ""
          }
        >
          <Link
            to={
              "/app/" + this.props.params.projectkey + "/settings/widget/demo"
            }
            data-id="Preview URL"
          >
            Widget Demo
          </Link>
        </li>
      );
    } else {
      return "";
    }
  }
  getSwitchAccountLink() {
    if (
      (this.props.projectReducer.projectList &&
        this.props.projectReducer.projectList.length > 1) ||
      this.isSuperAdmin()
    )
      return (
        <li
          className={
            this.props.location.pathname.includes("/settings/switchaccount")
              ? "active"
              : ""
          }
        >
          <Link
            to={
              "/app/" + this.props.params.projectkey + "/settings/switchaccount"
            }
            onClick={this.handleSubMenu}
          >
            Switch Account
          </Link>
        </li>
      );
  }
  render() {
    return (
      <div class={"cs-conversation-wrp "}>
        <section class="cs-navigation">
          <strong>Settings</strong>
          <SettingsMenu
            params={this.props.params}
            location={this.props.location}
            handleSubMenu={this.handleSubMenu}
          />
        </section>
        {this.props.children}
      </div>
    );
  }
}

export default SettingsLayout;
