import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { showVoicebox } from "../actions/VoiceboxAction";
import {
  getUrlPreviewInfoResponse,
  getUrlPreviewInfoFailed,
} from "../actions/UrlPreviewAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* getUrlPreviewInfo(urlInfo) {
  const url = "/urlPreview/get";
  console.info("URL Preview Payload :", urlInfo.urls);
  try {
    let urlPreviewInfo = {};
    if (urlInfo.isOverflowConversation)
      urlPreviewInfo = yield call(Ajax.postUnauthenticated, url, urlInfo.urls);
    else urlPreviewInfo = yield call(Ajax.post, url, urlInfo.urls);

    urlPreviewInfo = urlPreviewInfo.data;

    if (urlPreviewInfo && urlPreviewInfo.data && urlPreviewInfo.data.urlMeta) {
      let fetchedPreviews = {};
      let metaData = urlPreviewInfo.data.urlMeta;
      urlInfo.urls.map((url) => {
        fetchedPreviews[url] = url in metaData ? metaData[url] : {};
      });
      yield put(getUrlPreviewInfoResponse(fetchedPreviews));
    }
  } catch (e) {
    getUrlPreviewInfoFailed();
    console.error(e);
  }
}
