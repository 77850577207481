import React, { useRef, useEffect, useState } from "react";
import { ArrowIcon } from "../../commons/Icons";
import OutOfOfficeComponent from "./OutOfOfficeComponent.jsx";
import HoursTypeComponent from "./HoursTypeComponent.jsx";
import EditWorkingHoursComponent from "../myprofile/workingHours/EditWorkingHoursComponent.jsx";
var ctzc = require("country-tz-currency");

const BusinessHoursComponent = (props) => {
  const [showMoreOutOfOffice, setShowMoreOutOfOffice] = useState(false);
  const timezoneRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = () => {
    // if(!(timezoneRef && timezoneRef.current && timezoneRef.current.contains(event.target))){
    //     setIsDropDownVisible(false);
    //     timezoneRef.current.style.display = "none";
    // }
  };

  const getClassName = () => {
    if (showMoreOutOfOffice) return "showmore-link open";
    return "showmore-link";
  };

  return (
    <div class="cs-settings-wrp">
      <section class="widget-configuration">
        <h3>Business Hours</h3>
        <p>Update your time zone and business hours settings</p>

        <EditWorkingHoursComponent
          saveBusinessHours={props.saveBusinessHours}
          projectKey={props.projectKey}
          accountId={props.accountId}
          timezone={props.timezoneId}
          validateUser={props.validateUser}
          isFromBusinessHoursPage={true}
          workingHoursFromreducer={props.businessHoursFromReducer}
          updateBusinessHours={props.updateBusinessHours}
          createBusinessHours={props.createBusinessHours}
        />
        <a
          href="javascript:void(0)"
          class={getClassName()}
          onClick={() => setShowMoreOutOfOffice(!showMoreOutOfOffice)}
        >
          {showMoreOutOfOffice ? "Show less" : "Show more"}
          <ArrowIcon />
        </a>
        <OutOfOfficeComponent
          showMoreOutOfOffice={showMoreOutOfOffice}
          toggleOutOfOffice={props.toggleOutOfOffice}
          isEnabled={props.isEnabled}
          outOfOfficeMessasge={props.outOfOfficeMessasge}
          handleBlur={props.handleBlur}
          handleInputChange={props.handleInputChange}
          updateOutOfficeMessage={props.updateOutOfficeMessage}
          timezone={props.timezone}
        />
      </section>
    </div>
  );
};

export default BusinessHoursComponent;
