/**
 * Created by melvin on 06/04/17.
 */
import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { setupResponse, setupFailed } from "../actions/SetupAction";
import { addNotification } from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";

export function* projectSetup(request) {
  const url = "/setup/status/" + request.projectId;
  try {
    let response = yield call(Ajax.get, url);
    console.log("Setup state is", response);
    yield put(setupResponse(response.data));
  } catch (e) {
    yield put(setupFailed());
    yield put(handleGlobalError(e));
  }
}

export function* mailChatScript(request) {
  const url = "/setup/mail/chatscript/" + request.projectId;
  try {
    VoiceboxActions.showVoicebox({ message: "Mailing Chat Script..." });
    let response = yield call(Ajax.post, url, request);
    VoiceboxActions.showVoicebox({ message: "Sent Mail!", dismissAfter: 2000 });
  } catch (e) {
    VoiceboxActions.showVoicebox({ message: "Failed to Send Mail!" });
  }
}
