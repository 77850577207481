export const TOKENLIST_REQUESTED = "TOKENLIST_REQUESTED";
export const TOKENLIST_RESPONSE = "TOKENLIST_RESPONSE";
export const TOKENLIST_FAILED = "TOKENLIST_FAILED";

export const DELETE_TOKEN_REQUESTED = "DELETE_TOKEN_REQUESTED";
export const DELETE_TOKEN_RESPONSE = "DELETE_TOKEN_RESPONSE";
export const DELETE_TOKEN_FAILED = "DELETE_TOKEN_FAILED";

export const CREATE_TOKEN_REQUEST = "CREATE_TOKEN_REQUEST";
export const CREATE_TOKEN_RESPONSE = "CREATE_TOKEN_RESPONSE";
export const CREATE_TOKEN_FAILED = "CREATE_TOKEN_FAILED";

export const UPDATE_TOKEN_REQUEST = "UPDATE_TOKEN_REQUEST";
export const UPDATE_TOKEN_RESPONSE = "UPDATE_TOKEN_RESPONSE";
export const UPDATE_TOKEN_FAILED = "UPDATE_TOKEN_FAILED";

export const requestAvailableTokens = () => ({
  type: TOKENLIST_REQUESTED,
});

export const receiveAvailableTokens = (tokenList) => ({
  type: TOKENLIST_RESPONSE,
  tokenList,
});

export const availableTokenFailed = () => ({
  type: TOKENLIST_FAILED,
});

export const deleteTokenRequested = (projectKey) => ({
  type: DELETE_TOKEN_REQUESTED,
  projectKey,
});

export const deleteTokenresponse = (projectKey) => ({
  type: DELETE_TOKEN_RESPONSE,
  projectKey,
});

export const deleteTokenfailed = (projectKey) => ({
  type: DELETE_TOKEN_FAILED,
  projectKey,
});

export const createAuthToken = (tokenDetails) => ({
  type: CREATE_TOKEN_REQUEST,
  tokenDetails,
});

export const receiveCreatedToken = (tokenMap) => ({
  type: CREATE_TOKEN_RESPONSE,
  tokenMap,
});

export const createTokenFailed = () => ({
  type: CREATE_TOKEN_FAILED,
});

export const updateTokenRequest = (tokenMap) => ({
  type: UPDATE_TOKEN_REQUEST,
  tokenMap,
});

export const updateTokenResponse = (tokenMap) => ({
  type: UPDATE_TOKEN_RESPONSE,
  tokenMap,
});

export const updateTokenFailed = () => ({
  type: UPDATE_TOKEN_FAILED,
});
