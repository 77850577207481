import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import CampaignRecord from "../components/CampaignRecord";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import NewCompaignPopup from "../components/NewCampaignPopup";
import CampaignHeader from "../components/CampaignHeader";
import CampaignSideBar from "../components/CampaignSideBar.jsx";
import CampaignGridHeader from "../components/CampaignGridHeader";
import * as loaders from "../../settings/commons/loaders";
import DefaultNewCampaignSection from "../components/DefaultNewCampaignSection.jsx";
import { campaignTypes, CAMPAIGN_TYPE_TO_NAME, TYPE_OF_CAMPAIGN_STATS } from "../../commons/Constants";
import {getRandomColor} from "../../../app/commons/Utility.js";

const CampaignDashboard = (props) => {
  const { type, projectkey } = props.params;
  const [campaigns, setCampaigns] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const {
    campaignData,
    isCampaignFetched,
    isCampaignUpdated,
  } = useBroadcastMessageStats(projectkey);
  const [moreOptionsId, setMoreOptionsId] = useState("");
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(campaignActions.addSelectedCampaign({}));
  }, []);

  useEffect(() => {
    setCampaigns(getCampaigns());
  }, [isCampaignFetched]);

  useEffect(() => {
    setCampaigns(getCampaigns());
  }, [type]);

  useEffect(() => {
    setCampaigns(getCampaigns());
  }, [isCampaignUpdated]);


  useEffect(() => {
    setCampaigns(getCampaigns());
  }, [campaignData.length]);

  const getCampaigns = () => {
    if (type == "all")
      return campaignData.filter((campaign) => campaign.status === "ACTIVE");
    else
      return campaignData.filter(
        (campaign) =>
          campaign.typeOfMessage == type.toUpperCase() &&
          campaign.status === "ACTIVE"
      );
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  const getPercentage = (countData, totalCount, createdDate, typeOfStats) => {
    if (countData == 0 && totalCount == 0) return 0
    let result = Math.round((countData / totalCount) * 100)
    if (typeOfStats === TYPE_OF_CAMPAIGN_STATS.NOT_DELIVERED && countData != 0) {
      result = getNotDeliveredPercentage(countData,totalCount)
    }
    if (isNaN(result) || !isFinite(result)) {
      return 0
    }
    // return result > 100 && createdDate < 1599487521000 ? 100 : result;
    return result > 100 ? 100 : result
  };

  const getNotDeliveredPercentage = (countData, totalCount) => {
    let totalSentCount = countData + totalCount;
    if (totalCount === 0)
      return 100;
    else
      return Math.round((countData / totalSentCount) * 100);
  }

  const getCampaignListing = () => {
    if (isCampaignFetched) {
      return campaigns.map((data) => {
        return (
          <CampaignRecord
            campaignName={data.campaignName || CAMPAIGN_TYPE_TO_NAME[data.typeOfMessage]}
            campaignStatus={data.campaignStatus}
            campaignType={data.typeOfMessage}
            sentData={data.numberOfDeliveredMsgs}
            campaignContent={data.message}
            campaignId={data.key}
            projectkey={projectkey}
            campaignColor={data.color || getRandomColor()}
            openedData={getPercentage(
              data.numberOfOpenedMsgs,
              data.numberOfDeliveredMsgs, 
              data.createdDate
            )}
            clickedData={getPercentage(
              data.numberOfClickedMsgs,
              data.numberOfDeliveredMsgs,
              data.createdDate
            )}
            interactedData={getPercentage(
              data.numberOfRepliedMsgs,
              data.numberOfDeliveredMsgs,
              data.createdDate
            )}
            notDeliveredData={getPercentage(
              data.numberOfNotDeliveredMsgs,
              data.numberOfDeliveredMsgs,
              data.createdDate,
              TYPE_OF_CAMPAIGN_STATS.NOT_DELIVERED,
            )}
            moreOptionsId={moreOptionsId}
            setMoreOptionsId={setMoreOptionsId}
          />
        );
      });
    }
    return (
      <div>
        <div className="campaigns-grid-list">{getLoaderRow()}</div>
        <div className="campaigns-grid-list">{getLoaderRow()}</div>
        <div className="campaigns-grid-list">{getLoaderRow()}</div>
      </div>
    );
  };

  const getLoaderRow = () => {
    let listingLoader = [];
    listingLoader.push(loaders.GridImageLoader());
    listingLoader.push(loaders.GridDataLoader());
    listingLoader.push(loaders.GridDataLoader());
    listingLoader.push(loaders.GridDataLoader());
    listingLoader.push(loaders.GridDataLoader());
    listingLoader.push(loaders.GridDataLoader());
    listingLoader.push(loaders.GridDataLoader());
    return listingLoader;
  };

  const getCurrentFilterCount = () => {
    let compaignCount = "";
    let currentCampaigns = campaignData || [];
    if (isCampaignFetched) {
      compaignCount =
        type == "all"
          ? currentCampaigns.filter((campaign) => {
              return campaign.status === "ACTIVE";
            }).length
          : currentCampaigns.filter((campaign) => {
              return (
                campaign.typeOfMessage == campaignTypes[type] &&
                campaign.status === "ACTIVE"
              );
            }).length;
    }
    return compaignCount;
  };
  return (
    <div>
      {showPopup ? <NewCompaignPopup {...props} closePopup={closePopup} /> : ""}
      <CampaignSideBar {...props} />
      <section className="cs-conversation cs-people-wrp">
        <CampaignHeader {...props} openPopup={openPopup} getCurrentFilterCount={getCurrentFilterCount} />
        { isCampaignFetched && getCurrentFilterCount() <= 0 ?
          <DefaultNewCampaignSection id="DefaultNewCampaignSection" {...props}/> :
            (<section className="campaigns-grid-wrp">
            <div>
              <CampaignGridHeader />
              <div className="campaigns-grid-list-wrp">
                {getCampaignListing()}
              </div>
            </div>
          </section>)
        }
      </section>
    </div>
  );
};

export default CampaignDashboard;
