import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Text2 } from 'src/features/ui/components/Typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled(Text2).attrs({ as: 'label' })`
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 1.64rem;
`

export const Input = styled.input<{ hasError?: boolean }>`
  padding: 0.4rem 0;
  border: none;
  border-bottom: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
  color: ${R.path(['theme', 'color', 'codGray'])};
  font-size: 1.6rem;
  line-height: 1.92rem;
  border-radius: 0;
  filter: none;

  &::placeholder {
    color: ${R.path(['theme', 'color', 'casper'])};
    font-family: ${R.path(['theme', 'font', 'family'])};
    font-size: 1.6rem;
    line-height: 1.9rem;
  }

  ${is('hasError')`
    border-color: ${R.path(['theme', 'color', 'alizarinCrimson'])};
  `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    background-color: ${R.path(['theme', 'color', 'white'])};
    -webkit-box-shadow: 0 0 0 100rem ${R.path(['theme', 'color', 'white'])}
      inset;
  }
`

export const IconButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  width: fit-content;
  overflow: hidden;
  cursor: pointer;
`
