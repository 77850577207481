import React, { useEffect, useState , useRef } from "react";
import { EditPopupIcon , EditIcon, DeleteIcon, MoreOptionsIcon , AddFieldIcon, ErrorIcon} from "../../commons/Icons.js";
import UrlListComponent from './urllistcomponent.jsx'
import AddNewUrl from './addNewUrl.jsx'
import { isValidChatPromptURL } from "../../commons/Utility.js";

const WhiteBlackList = (props) => {

  const {hideURLConfigPopup , getUrls , getConfig , handleEnter , popupType , addUrlsToMap , removeDomainFromList , hidePopup , resetUrls , popupRef} = props;
  const {getDomainsByType , hasLocalStateChanged , getPreviousUrl } = props;
  const [showAddNew , setShowAddNew] = useState(true);
  const [showAddNewComponent , setShowAddNewComponent] = useState(false);
  const [canShowSaveButton , setCanShowSaveButton] = useState(false);
  const urlref = useRef(null);
  const hasChanged = hasLocalStateChanged();


  let urlsList = getUrls();
  urlsList = JSON.parse(urlsList);
  let hasUrls = urlsList.length;

  console.log("the urls i am getting in popup component :" , urlsList);
  useEffect(()=>{
    setShowAddNew(hasUrls);
    setShowAddNewComponent(!hasUrls);
    setCanShowSaveButton(false);
  } , [])

  useEffect(()=>{
    setShowAddNewComponent(!hasUrls);
  } , [hasUrls])

  useEffect(()=>{
    if(!hasChanged){
      setCanShowSaveButton(true)
    } else {
      setCanShowSaveButton(false);
    }
  } , [hasChanged])
  

  let componentConfig = getConfig();


  const showShowAddNewComponent = () => {
    setShowAddNewComponent(true);
    setShowAddNew(false);
    scrollToBottom();
  
  }

  const hideShowAddNewComponent = () => {
    setShowAddNewComponent(false);
  }

  const closePopup = () => {
    hideURLConfigPopup();
    resetUrls();
  }
 
  const getPopupTitle = () => {
    return componentConfig.title;
  } 

  const getPopupDescription = () => {
    return componentConfig.description;
  } 

  const getUrlsListComponent = () => {
    let toRender = []
    urlsList.map( (url) => {
      toRender.push(<UrlListComponent getPreviousUrl={getPreviousUrl} getDomainsByType={getDomainsByType} hideURLConfigPopup={hideURLConfigPopup} handleKeyPress={handleKeyPress} handleAddNewOnEnter={handleAddNewOnEnter}  popupType={popupType} handleEnter={handleEnter} url={url} removeDomainFromList={onDelete}/>)
    })
    return toRender;
  }

  const getAddNewButton = () => {
    return <a id="add_new_btn" href="javascript:void(0)"  onClick={showShowAddNewComponent} class="add-link"><AddFieldIcon/> Add Another</a>;
  }


  const handleKeyPress = (e) => {
    let url = "";
    if(e.type === 'paste') {
      url = e.clipboardData.getData("text")
    } else {
      url = e.target.value;
    }

    if(isValidChatPromptURL(url)) {
      setCanShowSaveButton(true);
    } else {
      setCanShowSaveButton(false);
    }
  };

  const handleAddNewOnEnter = (event , urlType) => {
      addUrlsToMap(event , urlType);
      if(event.key === 'Enter' && event.type === 'keydown')
        setShowAddNewComponent(false);
  }

  const onDelete = (event ) => {
    removeDomainFromList(event , popupType);
  }

  const onEnter = (event) => {
    handleEnter(popupType);
    hideURLConfigPopup();
    
  }

  const scrollToBottom = () => {
    let list = document.getElementById('list') || {}
    list.scrollTop = list.offsetHeight;
  }



  return (
    <div>
      <div class="cs-popupbg"></div>
      <div class="cs-popup-model popup-new ">
        <header ref={popupRef}>
          <h2>
            <EditPopupIcon /> 
           {getPopupTitle()}
            <cite id="close_btn" onClick={closePopup}>×</cite>
          </h2>
          <p>{getPopupDescription()}</p>
        </header>
        <div class="whiteblackList">
          <ul ref={urlref} id="list">
          {getUrlsListComponent()}
           { showAddNewComponent ? <AddNewUrl getPreviousUrl={getPreviousUrl} hideShowAddNewComponent={hideShowAddNewComponent} getDomainsByType={getDomainsByType} hideURLConfigPopup={hideURLConfigPopup} handleKeyPress={handleKeyPress} addUrlsToMap={handleAddNewOnEnter}  popupType={popupType} handleEnter={handleEnter}/> : ""}
          </ul>
         {getAddNewButton()}
        </div>
        <footer>
          <a  href="javascript:void(0)" onClick={closePopup} class="cs-btn cs-decline-btn">Cancel</a>
          <button id="save_btn" disabled={!canShowSaveButton ? true : false}  class={canShowSaveButton ? "cs-btn cs-primary-btn" : "cs-btn cs-disabled-btn"} onClick={onEnter}>Save</button>
        </footer>
      </div>
    </div>
  );
};

export default WhiteBlackList;

//disabled={!canShowSaveButton ? true : false}
//class={canShowSaveButton ? "cs-btn cs-primary-btn" : "cs-btn cs-disabled-btn"}
