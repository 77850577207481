import React from "react";

const FromSpan = (props) => {
  return (
    <div ref={props.blockProps.ref} onClick={props.blockProps.handleOnClick}>
      <span
        style={{
          display: "block",
          fontSize: "12px",
          opacity: "0.5",
          lineHeight: "1.67",
        }}
      >
        {`From`}
      </span>
      <span>{props.blockProps.agentName}</span>
    </div>
  );
};

export default FromSpan;
