import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Text3 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${mq.sm} {
    display: none;
  }
`

export const ProgressContainer = styled.div`
  display: flex;
`

export const Progress = styled.div<{
  widthPercent: number
  isComplete: boolean
}>`
  width: ${R.prop('widthPercent')}%;
  height: 0.4rem;

  ${is('isComplete')`
    background: ${R.path(['theme', 'color', 'dodgerBlue'])};
  `}
`

export const StepLabel = styled(Text3)`
  color: ${R.path(['theme', 'color', 'raven'])};
  margin-top: 2.2rem;
  margin-left: 1.6rem;
`
