import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  requestAttachmentUpload,
  successAttachmentUpload,
  UPLOAD_ATTACHMENT_FAILED,
  requestWidgetIcontUpload,
} from "../actions/AttachmentAction";
import { updateChatConfigRequest } from "../settings/actions/ChatConfigurationAction";
import * as NotificationActions from "../actions/NotificationAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { sendMessage } from "../actions/MessageAction";
import { store } from "../app";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { EventEmitter } from "../settings/commons/event";
import { constructAttachmentMessage } from "../commons/Utility";

export function* uploadAttachment(data) {
  console.log("The Data we Received to UploadAttachment :" + data.fileDetails);
  let url = "bucket/addFiles";
  url = data.fileDetails.uploadUrl;
  let entityKey = data.fileDetails.entityKey;
  let responseData = {};
  let formData = {};
  let uploadData = new FormData();
  try {
    let response;
    formData = store.getState().AttachmentReducer.messageData[
      data["fileDetails"].id
    ]["formData"];

    for (let key in data.fileDetails.formParams) {
      uploadData.append(key, data.fileDetails.formParams[key]);
    }
    uploadData.append("file", formData.get("file"));

    if (data.isOverflowConversation)
      response = yield call(
        Ajax.postDataToFullStorageUnauthenticated,
        url,
        uploadData
      );
    else response = yield call(Ajax.postDataToFullStorage, url, uploadData);

    console.log("Resoponse JSON String " + JSON.stringify(response));
    if (response.ok == true) {
      responseData = response.data;
      // let messageData = store.getState().AttachmentReducer.messageData[responseData.id];
      let messageData = store.getState().AttachmentReducer.messageData[
        data.fileDetails.id
      ];

      if (entityKey) {
        let uploadedFileDetails = responseData.file;
        EventEmitter.dispatch("replaceEntityImageUrl", {
          entityKey: entityKey,
          url: uploadedFileDetails.raw_url_download,
        });
      }

      responseData.id = data.fileDetails.id;

      yield put(successAttachmentUpload(responseData));

      if (messageData.publishMessage == true) {
        let message = {};
        let uploadedFileDetails = responseData.file;
        message = constructAttachmentMessage(uploadedFileDetails);
        messageData.chtMessage.message = JSON.stringify(message);
        yield put(sendMessage(messageData.chtMessage, messageData.type));
      }
    } else yield put({ type: "UPLOAD_ATTACHMENT_FAILED" });
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* getUploadAttachmentUrl(data) {
  console.log(
    "The Data we Received to getUploadAttachmentUrl :" +
      data.fileDetails.formData
  );
  const url = "/bucket/getUploadUrl";

  let requestData = {};
  let responseData = {};
  try {
    let response;
    requestData["fileName"] = data.fileDetails.fileName;
    requestData["id"] = data.fileDetails.id;
    requestData["projectId"] = data.fileDetails.projectId;

    if (data.isOverflowConversation)
      response = yield call(Ajax.postUnauthenticated, url, requestData);
    else response = yield call(Ajax.postUnauthenticated, url, requestData);

    console.log("Resoponse JSON String " + JSON.stringify(response));

    if (response.data.status == "success") {
      let requestData = {};

      requestData["id"] = response.data["id"];
      requestData["uploadUrl"] = response.data["fileUrl"];
      requestData["formParams"] = response.data["formParams"];
      if (data.fileDetails.entityKey)
        requestData["entityKey"] = data.fileDetails.entityKey;
      responseData = response.fileUrl;
      yield put(
        requestAttachmentUpload(requestData, data.isOverflowConversation)
      );
    }
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* getUploadWidgetIconUrl(data) {
  console.log(
    "The Data we Received to getUploadWidgetIconUrl :" +
      data.fileDetails.formData
  );
  const url = "/bucket/getUploadUrl";

  let requestData = {};
  let responseData = {};
  try {
    let response;
    requestData["fileName"] = data.fileDetails.fileName;
    requestData["id"] = data.fileDetails.id;
    requestData["projectId"] = data.fileDetails.projectId;

    response = yield call(Ajax.postUnauthenticated, url, requestData);

    console.log("Resoponse JSON String " + JSON.stringify(response));

    if (response.data.status == "success") {
      let requestData = {};

      requestData["id"] = response.data["id"];
      requestData["uploadUrl"] = response.data["fileUrl"];
      requestData["formParams"] = response.data["formParams"];
      requestData["formData"] = data.fileDetails.formData;
      requestData["projectId"] = data.fileDetails.projectId;
      requestData["customConfig"] = data.fileDetails.customConfig;

      responseData = response.fileUrl;
      yield put(requestWidgetIcontUpload(requestData));
    }
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* uploadWidgetIcon(data) {
  console.log(
    "The Data we Received to UploadAttachment :" + data.fileDetails.formData
  );
  let url = "bucket/addFiles";
  url = data.fileDetails.uploadUrl;
  let responseData = {};
  let formData = {};
  let uploadData = new FormData();
  try {
    let response;
    formData = data.fileDetails.formData;

    for (let key in data.fileDetails.formParams) {
      uploadData.append(key, data.fileDetails.formParams[key]);
    }
    uploadData.append("file", formData.get("file"));

    response = yield call(
      Ajax.postDataToFullStorage,
      url,
      uploadData,
      data.fileDetails.customConfig
    );

    console.log("Resoponse JSON String " + JSON.stringify(response));
    if (response.ok == true) {
      responseData = response.data;
      responseData.id = data.fileDetails.id;
      let chatConfiguration = store.getState().ChatConfigurationReducer.data[
        data.fileDetails.projectId
      ];

      chatConfiguration.chatWidgetIconURL = responseData.file.raw_url;
      store.dispatch(
        updateChatConfigRequest(
          {
            key: chatConfiguration.key,
            chatWidgetIconURL: chatConfiguration.chatWidgetIconURL,
          },
          true
        )
      );
    } else yield put({ type: "UPLOAD_WIDGET_ICON_FAILED" });
  } catch (e) {
    if (e && e.message == "upload_cancelled_by_user")
      console.log("User cancelled the upload.");
    else console.log("error:", e);
    // else
    // 	yield put( handleGlobalError( e ) );
  }
}
