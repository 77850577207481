import React, { useState, useEffect } from "react";
import WidgetVisibilityComponent from "./WidgetVisibilityComponent.jsx";
import { useSelector, useDispatch } from "react-redux";
import { useChatConfigurationEntity } from "../hooks/useChatConfigurationEntity.js";
import { isValidObject, isValidURL , arraysEqual, getProjectId } from "../../commons/Utility.js";
import * as ChatConfigActions from "../actions/ChatConfigurationAction.js";
import * as voiceboxActions from "../../actions/VoiceboxAction";
import { usePrevious } from "../hooks/usePrevious.js";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import { findAllByDisplayValue } from "@testing-library/react";

const WidgetVisibilityContainer = (props) => {
  const { user, staffList } = useSelector((state) => ({
    user: state.UserReducer.data,
    staffList: state.StaffReducer.dashboardAgents["LS-" + props.projectKey],
  }));
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [updatedMessage, setUpdatedMessage] = useState("Update Successful");
  const [isUpdated, setIsUpdated] = useState(false);
  const [allowedUrlList , setAllowedUrlList] = useState([])
  const [blockedUrlsList , setBlockedUrlsList] = useState([])
  const [allowedUrlsMap , setAllowedUrlsMap] = useState(new Map());
  const [blockedUrlsMap , setBlockedUrlsMap] = useState(new Map());
  const dispatch = useDispatch();
  let projectId = "LS-" + props.projectKey;
  const {
    chatConfiguration: chatConfig,
    isUpdating,
    isConfigFetched
  } = useChatConfigurationEntity(projectId);
  let prevIsUpdating = usePrevious(isUpdating);

  const getArrayAsObject = ( list = [] ) => {
    let obj = new Map();
    list.forEach(item=> obj.set(item , {url : item , previousUrl : item}));
    // list.forEach(item=> obj[item] = {url : item , previousUrl : item});
    return obj;
  }

  const getUrlsFromObject = ( obj )  => {
    let list = [];
    obj.forEach(( data )=>{
      list.push(data.url);
    })
    // Object.values(obj).forEach((data)=>{ 
    //   list.push(data.url);
    // })
    return list;

  }

  const getPreviousUrlByUrl = (url , obj)=>{
    let response = "";
    obj.forEach((data)=>{ 
      if(data.url == url) {
        response = data.previousUrl;
      }
    })
    return response;
  }


  let chatConfiguration = Object.assign({}, chatConfig);
  let currentDomains =
    chatConfiguration && chatConfiguration.isWhiteListEnabled
      ? chatConfiguration.whiteListedDomain
      : undefined;
  
  
  

  useEffect(() => {
    if (prevIsUpdating && !isUpdating) {
      dispatch(
        voiceboxActions.showVoicebox({
          message: updatedMessage,
          dismissAfter: 2000,
        })
      );
    }
  });

  useEffect(()=>{
    let allowedUrls = chatConfiguration && chatConfiguration.allowedUrlsList ? [...chatConfiguration.allowedUrlsList] : [];
    let blockedUrls = chatConfiguration && chatConfiguration.blockedUrlsList ? [...chatConfiguration.blockedUrlsList] : [];
    setAllowedUrlsMap(getArrayAsObject(allowedUrls));
    setBlockedUrlsMap(getArrayAsObject(blockedUrls));
  } , [chatConfiguration.allowedUrlsList , chatConfiguration.blockedUrlsList])


  useEffect( ()=> {
    setAllowedUrlList(getUrlsFromObject(allowedUrlsMap));
    setBlockedUrlsList(getUrlsFromObject(blockedUrlsMap));
  } , [ allowedUrlsMap.size , blockedUrlsMap.size])


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addDomainToList();
    } else {
      if (error) {
        setError(false);
        setErrorMessage("");
      }
    }
  };

  const addUrlsToMap = (event , urlType ) => {
    let {url : currentUrl , isedit: isEdit } = event.target.dataset;
    let url = event.target.value; 
    if(url){
      url = url.trim();
    }
    
    if(urlType === 'allowlist'){
      if(isEdit){
        // allowedUrlsMap[currentUrl] = {url : url , previousUrl : currentUrl}
        allowedUrlsMap.set(currentUrl ,  {url : url , previousUrl : currentUrl})
      } else {
        // allowedUrlsMap[url] = {url : url , previousUrl : url}; 
        allowedUrlsMap.set(url ,  {url : url , previousUrl : url}); 
      }
      
      setAllowedUrlsMap(allowedUrlsMap);
      setAllowedUrlList(getUrlsFromObject(allowedUrlsMap));
    }
    else if(urlType === 'blocklist'){
      if(isEdit){
        // blockedUrlsMap[currentUrl] = {url : url , previousUrl : currentUrl}
        blockedUrlsMap.set(currentUrl ,  {url : url , previousUrl : currentUrl})
      } else {
        // blockedUrlsMap[url] = {url : url , previousUrl : currentUrl}
        blockedUrlsMap.set(url ,  {url : url , previousUrl : url}); 
      }
      setBlockedUrlsMap(blockedUrlsMap);
      setBlockedUrlsList(getUrlsFromObject(blockedUrlsMap));
    }
    else 
      console.log("invalid type");
  }

  const onDeleteUrl = (event , urlType ) => {
    let {url  } = event.target.dataset;
    
    if(urlType === 'allowlist'){
      // delete allowedUrlsMap[url]; 
      allowedUrlsMap.delete(url);
      setAllowedUrlsMap(allowedUrlsMap);
      setAllowedUrlList(getUrlsFromObject(allowedUrlsMap));
    }
    else if(urlType === 'blocklist'){
      // delete blockedUrlsMap[url]
      blockedUrlsMap.delete(url); 
      setBlockedUrlsMap(blockedUrlsMap);
      setBlockedUrlsList(getUrlsFromObject(blockedUrlsMap));
    }
    else 
      console.log("invalid type");
  }

  const getDomainsByType = ( type ) => {
    if(type === 'allowlist') {
      return  getUrlsFromObject(allowedUrlsMap) || []
    } else if(type === 'blocklist') {
      return getUrlsFromObject(blockedUrlsMap) || []
    } else {
      return []
    }
  }

  const checkIfUrlAlreadyExists= ( inputURL , currentDomains ) => {
    if (
      currentDomains.indexOf(inputURL.origin) !== -1
    ) {
      setError(true);
      setErrorMessage("URL Already Exists");
      return false;
    } else {
      if (error) {
        setError(false);
        setErrorMessage("");
      }
  }
}

    const updateGA = (urlType)=>{
        let message = urlType === 'allowlist' ? "Widget URL whitelisted" : "Widget URL Blocklisted";
          Event(
            getProjectId(props.projectkey),
          "Widget Visibility",
          message,
          "Widget visibility Configuration"
          )
    }

  const getUpdatedConfigWithDomainsByType = (chatConfiguration , currentDomains , urlType ) => {
    let updatedDomains = [...currentDomains];
    chatConfiguration = { key: chatConfiguration.key };
      if(urlType === 'allowlist') {
        chatConfiguration.allowedUrlsList = updatedDomains;
        chatConfiguration.isAllowUrlsEnabled = false;
      } else if(urlType === 'blocklist'){
        chatConfiguration.blockedUrlsList = updatedDomains;
        chatConfiguration.isBlockListingUrlEnabled = false;
      } else {
        console.log("Not a valid update")
      }
      return chatConfiguration;
  }

  const addDomainToList = (urlType ) => {
    let chtConfiguration = Object.assign({}, chatConfig);
    
    try {
      // inputURL = new URL(inputURL);
      if (isOwner() ) {
        let currentDomains = getDomainsByType(urlType)
          dispatch(voiceboxActions.showVoicebox({ message: "Updating" }));
          chtConfiguration = getUpdatedConfigWithDomainsByType(chtConfiguration , currentDomains , urlType);
          dispatch(
            ChatConfigActions.updateChatConfigRequest(chtConfiguration)
          );
          // e.target.value = "";
          updateGA(urlType);
          setIsUpdated(true);
          setUpdatedMessage("Update Successful");
        
      } else {
        dispatch(
          voiceboxActions.showVoicebox({
            message: "You should be an owner to change the settings",
            dismissAfter: 3000,
          })
        );
      }
    } catch (e) {
      console.log(e);
      setError(true);
      setErrorMessage("Invalid URL");
      return false;
    }
  };

  const removeDomainFromList = (e) => {
    dispatch(voiceboxActions.showVoicebox({ message: "Deleting" }));
    let URL = e.target.id;
    let chatConfiguration = chatConfig;
    let currentDomains = new Set(
      getDomainWithProtocol(chatConfiguration.whiteListedDomain)
    );
    currentDomains.delete(URL);
    chatConfiguration.whiteListedDomain = [...currentDomains];
    if (!currentDomains.size) {
      chatConfiguration.isWhiteListEnabled = false;
      if (error) {
        setError(false);
        setErrorMessage("");
      }
    }
    setUpdatedMessage("Delete successful");
    dispatch(ChatConfigActions.updateChatConfigRequest(chatConfiguration));
  };

  const isOwner = () => {
    let agentId = (user && user.id) || "";
    let staffs = staffList;
    if (isValidObject(staffs) && agentId) {
      for (let staffId in staffs) {
        if (staffId === agentId) {
          return staffs[staffId].userPermission === "FULL_ACCESS";
        }
      }
    }
  };

  const clearError = () => {
    if (error) {
      setError(false);
      setErrorMessage("");
    }
  };

  const updateNewUrl = (newUrl, oldUrl) => {
    let inputURL = newUrl.trim();
    let chatConfiguration = chatConfig;
    let currentDomains = getDomainWithProtocol(
      chatConfiguration.whiteListedDomain
    );
    try {
      inputURL = new URL(newUrl);
      if (isOwner() && inputURL) {
        if (currentDomains.indexOf(inputURL.origin) !== -1) {
          dispatch(
            voiceboxActions.showVoicebox({
              message: "Url already exist",
              dismissAfter: 3000,
            })
          );
          return false;
        } else {
          dispatch(voiceboxActions.showVoicebox({ message: "Updating" }));
          let indexOfUrl = currentDomains.indexOf(oldUrl);
          currentDomains[indexOfUrl] = inputURL.origin || inputURL;
          chatConfiguration.whiteListedDomain = currentDomains;
          dispatch(
            ChatConfigActions.updateChatConfigRequest(chatConfiguration)
          );
          e.target.value = "";
          setIsUpdated(true);
          setUpdatedMessage("URL updated!");
        }
      } else {
        dispatch(
          voiceboxActions.showVoicebox({
            message: "You should be an owner to change the settings",
            dismissAfter: 3000,
          })
        );
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    let text = e.clipboardData.getData("text/plain");
    console.log("Text before pasting :", text);
    document.execCommand("insertHTML", false, text);
  };
  const getDomainWithProtocol = (domains) => {
    let domainsWithProtocol = [];
    domainsWithProtocol = domains.map((item) => {
      if (isValidURL(item)) return item;
      else return `https://${item}`;
    });
    return domainsWithProtocol;
  };
  currentDomains = currentDomains
    ? getDomainWithProtocol(currentDomains)
    : currentDomains;


  const resetUrls = () => {
    let allowedUrls = chatConfiguration && chatConfiguration.allowedUrlsList ? [...chatConfiguration.allowedUrlsList] : [];
    let blockedUrls = chatConfiguration && chatConfiguration.blockedUrlsList ? [...chatConfiguration.blockedUrlsList] : [];
    
    let allowMap = getArrayAsObject(allowedUrls);
    let blockMap = getArrayAsObject(blockedUrls);
    setAllowedUrlsMap(allowMap);
    setBlockedUrlsMap(blockMap);

    setAllowedUrlList(allowedUrls);
    setBlockedUrlsList(blockedUrls);

  }

  

  const hasLocalStateChanged = () => {
    let allowedUrls = chatConfiguration && chatConfiguration.allowedUrlsList ? [...chatConfiguration.allowedUrlsList] : [];
    let blockedUrls = chatConfiguration && chatConfiguration.blockedUrlsList ? [...chatConfiguration.blockedUrlsList] : [];
    return (arraysEqual(allowedUrls , allowedUrlList) 
      && arraysEqual(blockedUrls , blockedUrlsList)); 
  }

  const getPreviousUrl = ( url , urlType ) => {
      if(urlType === 'allowlist'){
        if(allowedUrlsMap.has(url)){
          return allowedUrlsMap.get(url).previousUrl;
        } else {
          return getPreviousUrlByUrl(url , allowedUrlsMap);
        }
        
      } else if(urlType === 'blocklist'){
        if(blockedUrlsMap.has(url)){
          return blockedUrlsMap.get(url).previousUrl;
        } else {
          return getPreviousUrlByUrl(url , blockedUrlsMap);
        }
       
      }
  }

  return (
    <WidgetVisibilityComponent
      urls={currentDomains}
      allowedUrls={JSON.stringify(allowedUrlList)}
      blockedUrls={JSON.stringify(blockedUrlsList)}
      allowedUrlListCount={allowedUrlList.length}
      blockedUrlsListCount={blockedUrlsList.length}
      error={error}
      errorMessage={errorMessage}
      addDomainToList={addDomainToList}
      removeDomainFromList={onDeleteUrl}
      handleKeyPress={handleKeyPress}
      clearError={clearError}
      isUpdated={isUpdated}
      setUpdated={setIsUpdated}
      updateNewUrl={updateNewUrl}
      onPasteHandler={onPasteHandler}
      addUrlsToMap={addUrlsToMap}
      resetUrls={resetUrls}
      getDomainsByType={getDomainsByType}
      hasLocalStateChanged={hasLocalStateChanged}
      getPreviousUrl={getPreviousUrl}
    />
  );
};
export default WidgetVisibilityContainer;
