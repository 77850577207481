import React, { useEffect } from "react";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import { useSelector } from "react-redux";
import {
  WidgetConfigurationIcon,
  CustomiseWidgetIcon,
  DomainWhiteListIcon,
  ChatAssignmentIcon,
  ChatAssignmentActiveIcon,
  ProactiveChatIcon,
  WidgetConfigurationActiveIcon,
  CustomiseWidgetActiveIcon,
  DomainWhiteListActiveIcon,
  ProactiveChatActiveIcon,
  BusinessHoursIcon,
  BusinessHoursActiveIcon,
  ManageWidgetIcon,
  ManageWidgetActiveIcon,
  BackArrowIcon,
  IntegrationsIcon, IntegrationsActiveIcon
} from "../../commons/Icons";
import { fetchChatPromptConfiguration } from "../actions/PromptActions";
import { getProjectId } from "../../commons/Utility.js";
import { useDispatch } from "react-redux";
import { staffListRequest } from "../../actions/StaffAction";
import { getUser } from "../../actions/UserAction";
import { requestAvailableProject } from "../actions/ProjectAction";

const WidgetSettingsLayout = (props) => {
  const { projects, promptConfiguration, staffReducer, userReducer } = useSelector(
    (state) => ({
      projects: state.ProjectReducer,
      promptConfiguration: state.ChatPromptConfigurationReducer,
      staffReducer: state.StaffReducer,
      userReducer: state.UserReducer
    })
  );
  const dispatch = useDispatch();

  useEffect(()=>{
    const element = document.querySelector('#addons-header');
    if(element){
      element.style.display = "none";
    }
    return () => {
      if(element){
        element.style.display = "block";
      }
    }
  } , [])

  useEffect(() => {
    let projectKey = props.params.projectkey;
    if (
      !promptConfiguration.isFetching &&
      !promptConfiguration.data.hasOwnProperty(getProjectId(projectKey))
    ) {
      dispatch(fetchChatPromptConfiguration(getProjectId(projectKey)));
    }
    if (
      staffReducer.dashboardAgents[getProjectId(projectKey)] === undefined &&
      staffReducer.isLoading != true
    ) {
      dispatch(staffListRequest(getProjectId(projectKey)));
    }
    if(!userReducer.isFetched){
      dispatch(getUser());
    }
    if(!projects.isFetched){
      dispatch(requestAvailableProject());
    }
  }, []);
  const getProjectName = () => {
    if (isNewWidgetConfiguration()) return "Widget";
    const projectKey = "LS-" + props.params.projectkey;
    let projectDetail = projects.projectList.find(
      (project) => project && project.key === projectKey
    );
    projectDetail = Object.assign({}, projectDetail);
    return projectDetail.name;
  };

  const isNewWidgetConfiguration = () => {
    let pathurl = document.location.pathname;

    if (pathurl.includes("/widget/newwidget")) return true;
    else return false;
  };

  const getWrapperClassNameWithCSNavigation = () => {
    let pathurl = document.location.pathname;

    if ((pathurl.includes("/integration")) && !process.env.isSettingsComponent){
      return "cs-conversation-wrp integration"
    } else {
      return "cs-conversation-wrp "
    }
  }
  return (
    <div className={getWrapperClassNameWithCSNavigation()}>
      <section class="cs-navigation">
        <label className="fs-exclude">
          <cite
            onClick={() => {
              browserHistory.push(
                "/app/" + props.params.projectkey + "/settings/manage/widget/"
              );
            }}
          >
            <BackArrowIcon />
          </cite>
          {getProjectName()}
        </label>
        <ul class="cs-navigation-list">
          <li
            className={
              props.location.pathname.includes("settings/widget/configuration")
                ? "active"
                : ""
            }
            onClick={() => {
              browserHistory.push(
                "/app/" +
                  props.params.projectkey +
                  "/settings/widget/configuration"
              );
            }}
          >
            <figure>
              <WidgetConfigurationIcon />
              <WidgetConfigurationActiveIcon />
            </figure>
            <Link
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/configuration"
              }
            >
              Widget Installation
            </Link>
          </li>
          <li
            className={
              props.location.pathname.includes("settings/widget/customize")
                ? "active"
                : ""
            }
            onClick={() => {
              browserHistory.push(
                "/app/" + props.params.projectkey + "/settings/widget/customize"
              );
            }}
          >
            <figure>
              <CustomiseWidgetIcon />
              <CustomiseWidgetActiveIcon />
            </figure>
            <Link
              to={
                "/app/" + props.params.projectkey + "/settings/widget/customize"
              }
            >
              Widget Style
            </Link>
          </li>
          <li
            className={
              props.location.pathname.includes("settings/widget/behaviour")
                ? "active"
                : ""
            }
            onClick={() => {
              browserHistory.push(
                "/app/" +
                  props.params.projectkey +
                  "/settings/widget/behaviour/prompts"
              );
            }}
          >
            <figure>
              {/* <ProactiveChatIcon/>
                    <ProactiveChatActiveIcon /> */}
              <ManageWidgetIcon />
              <ManageWidgetActiveIcon />
            </figure>
            <Link
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/behaviour/prompts"
              }
            >
              Widget Behaviour
            </Link>
          </li>
          {/* <li className={props.location.pathname.includes('settings/widget/domainwhitelist') ? 'active' : ''} onClick={()=>{browserHistory.push("/app/"+props.params.projectkey+"/settings/widget/domainwhitelist")}}>
                <figure>
                    <DomainWhiteListIcon/>
                    <DomainWhiteListActiveIcon />
                </figure>
                <Link to={"/app/"+props.params.projectkey+"/settings/widget/domainwhitelist"}>Domain Whitelist</Link>
            </li> */}

          <li
            className={
              props.location.pathname.includes("settings/widget/assignment")
                ? "active"
                : ""
            }
            onClick={() => {
              browserHistory.push(
                "/app/" +
                  props.params.projectkey +
                  "/settings/widget/assignment"
              );
            }}
          >
            <figure>
              {/* <ChatAssignmentIcon/>
                    <ChatAssignmentActiveIcon /> */}
              <DomainWhiteListIcon />
              <DomainWhiteListActiveIcon />
            </figure>
            <Link
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/assignment"
              }
            >
              Chat Routing
            </Link>
          </li>
          <li
                id="integrations_link"
                className={
                  (props.location.pathname.includes("settings/widget/integration")
                    ? "active"
                    : "")
                }
                onClick={() => {
                  browserHistory.push(
                    "/app/" + props.params.projectkey + "/settings/widget/integration"
                  );
                }}
              >
                <figure>
                  <IntegrationsIcon />
                  <IntegrationsActiveIcon />
                </figure>
                <Link
                  id="integrations_link_a_tag"
                  to={
                    "/app/" + props.params.projectkey + "/settings/widget/integration"
                  }
                >
                  Integration
                </Link>
              </li>
          <li
            className={
              props.location.pathname.includes("settings/widget/businesshours")
                ? "active"
                : ""
            }
            onClick={() => {
              browserHistory.push(
                "/app/" +
                  props.params.projectkey +
                  "/settings/widget/businesshours"
              );
            }}
          >
            <figure>
              <BusinessHoursIcon />
              <BusinessHoursActiveIcon />
            </figure>
            <Link
              to={
                "/app/" +
                props.params.projectkey +
                "/settings/widget/businesshours"
              }
            >
              Business Hours
            </Link>
          </li>
        </ul>
      </section>
      {props.children}
    </div>
  );
};

export default WidgetSettingsLayout;
