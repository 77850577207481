import {
  EVENT_ACTIVITY_REQUEST,
  EVENT_ACTIVITY_RESPONSE,
  EVENT_ACTIVITY_FAILED,
} from "../actions/EventsActivityAction.js";

const EventActivityReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    projectId: "",
    eventActivityMap: {},
    visitorId: "",
  },
  action
) => {
  switch (action.type) {
    case EVENT_ACTIVITY_REQUEST:
      return {
        ...state,
        projectId: action.requestParam.projectId,
        visitorId: action.requestParam.visitorId,
        isFetching: true,
        isFetched: false,
      };
    case EVENT_ACTIVITY_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        eventActivityMap: Object.assign(
          state.eventActivityMap,
          action.eventActivityMap
        ),
      };
    case EVENT_ACTIVITY_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
  }
  return state;
};

export default EventActivityReducer;
