export const STAFF_CONTACT_REQUESTED = "STAFF_CONTACT_REQUESTED";
export const STAFF_CONTACT_RECEIVED = "STAFF_CONTACT_RECEIVED";
export const STAFF_CONTACT_REQUEST_FAILED = "STAFF_CONTACT_REQUEST_FAILED";
export const STAFF_CONTACT_UPDATE_REQUEST = "STAFF_CONTACT_UPDATE_REQUEST";
export const STAFF_CONTACT_UPDATE_RESPONSE = "STAFF_CONTACT_UPDATE_RESPONSE";
export const STAFF_CONTACT_UPDATE_FAILED = "STAFF_CONTACT_UPDATE_FAILED";

export const getStaffContact = (staffid) => ({
  type: STAFF_CONTACT_REQUESTED,
  staffid,
});

export const staffContactResponse = (contactMap) => ({
  type: STAFF_CONTACT_RECEIVED,
  contactMap,
});

export const getStaffContactFailed = (contact) => ({
  type: STAFF_CONTACT_REQUEST_FAILED,
  contact,
});

export const updateStaffContactRequested = (contact) => ({
  type: STAFF_CONTACT_UPDATE_REQUEST,
  contact: contact,
});

export const updateStaffContactResponse = (contact) => ({
  type: STAFF_CONTACT_UPDATE_RESPONSE,
  contact,
});

export const updateStaffContactFailed = (contact) => ({
  type: STAFF_CONTACT_UPDATE_FAILED,
  contact,
});
