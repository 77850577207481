import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { requestSignUpSuccess } from 'src/features/authorization/store/actions'
import { searchParams } from 'src/utils/router/utils/params'

type hasLoggedIn = boolean

export const useLoginWithUrlParams = (): hasLoggedIn => {
  const dispatch = useDispatch()
  const location = useLocation()

  const widgetId = searchParams('widgetId', location.search)
  const userId = searchParams('userId', location.search)
  const ssoToken = searchParams('ssoToken', location.search)
  const login = searchParams('login', location.search)
  const accountId = searchParams('accountId', location.search)
  const code = searchParams('code', location.search)

  if (widgetId && userId && ssoToken && login && accountId) {
    localStorage.setItem('login', login)
    localStorage.setItem('accountId', accountId)
    localStorage.setItem('ls_user_id', userId)
  }

  useEffect(() => {
    if ((widgetId && userId && ssoToken) || (widgetId && userId && code)) {
      dispatch(requestSignUpSuccess({ widgetId, userId, code, ssoToken }))
    }
  }, [])

  return Boolean(widgetId && userId)
}
