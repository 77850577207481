import React, { useEffect, useRef } from "react";
import { browserHistory, Link } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  DesignActiveIcon,
  DesignIcon,
  PreviewActiveIcon,
  PreviewIcon,
  BackArrowIcon,
  PublishIcon,
  PublishActiveIcon,
} from "../../../app/commons/Icons";
import {
  isAnnoucementDesktopType,
  isEmailDesktopType,
  hasCampaignInfoUpdated,
} from "../../../app/commons/Utility.js";
import ReactTooltip from "react-tooltip";
import { addCampaign, deleteCampaign, saveCampaign, updateCampaignFromDashboard } from "../../actions/BroadcastMessageStatsAction";
import { useState } from "react";
import { showConfirmPopup } from "../../actions/ConfirmPopupAction";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import { CAMPAIGN_STATUS_TO_ID, IS_CAMPAIGN_FROM_DASHBOARD, STRINGIFIED_BOOL } from "../../commons/Constants";
import * as Utils from "../../commons/Utility.js";

const NewCampaignLayout = (props) => {
  console.log("NewCampaignLayout props:", props);
  const { campaignId, projectkey } = props.params;
  const { campaignDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
  }));
    const { campaignData, isCampaignFromDashboard } = useBroadcastMessageStats(
    projectkey,
    campaignId
  );
  const dispatch = useDispatch();
  const miniNavBar =
    isAnnoucementDesktopType(campaignDetails) ||
    isEmailDesktopType(campaignDetails);
  const campaignRef = useRef(null);

  const getCampaignName = () => {
    const campaignName = campaignDetails.selectedCampaign.campaignName || "";
    return campaignName ? campaignName.trim() : "New Campaign";
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [nextLocatn, setNextLocatn] = useState(null);

  useEffect(() => {
    ReactTooltip.rebuild();
    props.router.setRouteLeaveHook(props.route, routerWillLeaveCallback);
    return () => {
      ReactTooltip.hide();
      window.onbeforeunload = undefined;
    };
  }, []);
  useEffect(()=>{
  if(nextLocatn && showConfirmation)
    dispatch(showConfirmPopup({
      onClose: onPopupCancel, 
      onCancel: dontSaveAndBack, 
      onOk: saveAndBack,
      contentText: "Warning: Do you want to save changes before leaving the page?", 
      headerText: "Unsaved Changes", 
      okButtonText: "Save Changes",
      cancelButtonText: "Don't Save"
    }));
}, [nextLocatn, showConfirmation])
useEffect(()=>{
  props.router.setRouteLeaveHook(props.route, routerWillLeaveCallback);
  window.onbeforeunload = handleUnload;
  
}, [ showConfirmation, campaignDetails.selectedCampaign])
  const routerWillLeaveCallback = (nextLocation)=>{

    if(isNewUnchangedCampaign())
    {
      dispatch(deleteCampaign(campaignId));
      return true;
    }
    else if (hasCampaignInfoChanged() && !showConfirmation) {
        showConfirmationPopup(nextLocation);
        return false;
    }
    return true;
  }

  const handleUnload = () => {
    console.log("coming to handle unload")
      updateCampaignDataonRefresh();
  }

  const updateCampaignDataonRefresh = () => {
    console.log("Selected inside =" , typeof navigator.sendBeacon);
    console.log("Selected inside beacon call :" , campaignDetails.selectedCampaign);
    if (typeof navigator.sendBeacon !== "undefined") {
      if(Object.keys(campaignDetails.selectedCampaign).length > 1)
      {
        const saveCampaignUrl = Utils.getApiVersion() + "/broadcast/save";
        let selectedCampaign = campaignDetails.selectedCampaign;
        selectedCampaign.message = constructMessage();
        if (selectedCampaign.endDate === "Never")
          selectedCampaign.endDate = 0;
        console.log("sending to becon : " , selectedCampaign);
        selectedCampaign = {...selectedCampaign, filterQuery: JSON.stringify(Utils.getfilterQueryArray(selectedCampaign.filterQuery))}
        selectedCampaign.isNewCampaign = false;
        let campaignSent = navigator.sendBeacon(saveCampaignUrl, JSON.stringify(selectedCampaign));
        console.log("campaign becon sent from new campaign: ", campaignSent);
      }
    }
  }

  const isNewUnchangedCampaign = () =>{
    let { campaignName, senderId, subject, message } = campaignDetails.selectedCampaign;
    if( campaignName == "" 
        && senderId == "" 
        && subject == ""
        && message .includes("_add_content")
      )
        return true;
  }
 const hasCampaignInfoChanged = () => {
   if(!campaignDetails.selectedCampaign.key)
    return false;
  const initialCampaignInfo = campaignData.filter((campaignInfo) => {
    return campaignInfo.key === campaignDetails.selectedCampaign.key;
  });
  if (!campaignId || !initialCampaignInfo[0]) return true;

  const currentCampaignInfo = Object.assign(
    {},
    campaignDetails.selectedCampaign
  );
  if (
    hasCampaignInfoUpdated(currentCampaignInfo,initialCampaignInfo[0]) ||
    hasSegmentIdsChanged(initialCampaignInfo[0]) ||
    campaignDetails.selectedCampaign.isNewCampaign == true
  )
    return true;
  return false;
};


const hasSegmentIdsChanged = (initialCampaignInfo) => {
  
  if(initialCampaignInfo.segmentIds.length != campaignDetails.selectedCampaign.segmentIds.length)
    return true;
  for (let index in campaignDetails.selectedCampaign.segmentIds)
    if (!initialCampaignInfo.segmentIds.includes(campaignDetails.selectedCampaign.segmentIds[index])) 
      return true;
  return false;
};
  const onPopupCancel = (e)=>{
    setShowConfirmation(false);
  }
  const dontSaveAndBack = (e) => {
    if(localStorage.getItem('isCampaignFromDashboard') === "true"){
      dispatch(updateCampaignFromDashboard(false));
      browserHistory.push({
        pathname: "/app/" + props.params.projectkey + "/contacts/dashboard/" + campaignDetails.selectedCampaign.segmentIds[0],
      });
      localStorage.setItem('isCampaignFromDashboard', "false");
      ReactTooltip.hide();
    } else {
      if (campaignDetails.selectedCampaign.isNewCampaign == true)
        dispatch(deleteCampaign(campaignId));
      browserHistory.push(nextLocatn.pathname);
    }
  }

  const constructMessage = () => {
    let {
      fallBackValues = {},
      message = "",
    } = campaignDetails.selectedCampaign;
    let fallbackStripedMap = {
      "{{firstName}}": "firstName",
      "{{lastName}}": "lastName",
      "{{fullName}}": "fullName",
      "{{companyName}}": "companyName",
    };

    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `{{ ${fallbackStripedMap[match]} | ${
          fallBackValues[match] || ""
        } }}`;
      }
    );

    message = message.replace(
      "+_from_",
      `<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;">From</span>${campaignDetails.selectedCampaign.campaignFromName}</p>`
    );
    // message = message.replace(
    //   "+_sender_info_",
    //   `<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;">Sender Name</span>${campaignDetails.selectedCampaign.campaignFromName}</p>`
    // );
    return message;
  };
  const saveAndBack = (e)=>{
    campaignDetails.selectedCampaign.campaignStatus = CAMPAIGN_STATUS_TO_ID.DRAFT;
    campaignDetails.selectedCampaign.isNewCampaign = false;
    if (campaignDetails.selectedCampaign.endDate === "Never")
      campaignDetails.selectedCampaign.endDate = 0;

    dispatch(addCampaign(Object.assign({}, campaignDetails.selectedCampaign), true));
    campaignDetails.selectedCampaign.message = constructMessage();
    dispatch(saveCampaign(campaignDetails.selectedCampaign, false))
    browserHistory.push(nextLocatn.pathname);
  }
  const showConfirmationPopup = (nextLocation)=>
  {
    setShowConfirmation(true);
    setNextLocatn(nextLocation);
  }
  const routeToCampaignStep = (step) => {
    if (Utils.isCampaignDesignComplete(campaignDetails.selectedCampaign)) {
      browserHistory.push(
        campaignId
          ? "/app/" +
              props.params.projectkey +
              "/campaign/" +
              step +
              "/" +
              campaignId
          : "/app/" + props.params.projectkey + "/campaign/" + step
      );
    }
  };

  const onBack = () => {
    ReactTooltip.hide();
    if (props.location.pathname.includes("campaign/publish")) {
      routeToCampaignStep("preview");
    } else if (props.location.pathname.includes("campaign/preview")) {
      routeToCampaignStep("design");
    } else if (localStorage.getItem(IS_CAMPAIGN_FROM_DASHBOARD) !== STRINGIFIED_BOOL.FALSE && campaignDetails && campaignDetails.selectedCampaign && localStorage.getItem(IS_CAMPAIGN_FROM_DASHBOARD) !== null) {
      browserHistory.push("/app/" + props.params.projectkey + "/contacts/dashboard/" + localStorage.getItem(IS_CAMPAIGN_FROM_DASHBOARD));
      localStorage.setItem(IS_CAMPAIGN_FROM_DASHBOARD, STRINGIFIED_BOOL.FALSE);
    }else {
      browserHistory.push(
        "/app/" + props.params.projectkey + "/campaign/"+ (campaignDetails.campaignSideBarType !== null ? campaignDetails.campaignSideBarType : "all")
      );
    }

  };

  const getCSNavigationStyle = () => {
    return {"position" : "fixed" , "left" : "64px"};
  }

  return (
    <div className={"cs-conversation-wrp " + (miniNavBar ? "min-screen" : "")}>
      <section className={"cs-navigation " + (miniNavBar ? "slide-nav" : "")}>
        <label ref={campaignRef}>
          <Link onClick={onBack}>
            {" "}
            <cite>
              <BackArrowIcon />
            </cite>{" "}
          </Link>{" "}
          <p
            data-class={
              campaignRef.current &&
              campaignRef.current.scrollWidth > campaignRef.current.offsetWidth
                ? "tooltip info"
                : ""
            }
            data-tip={
              campaignRef.current &&
              campaignRef.current.scrollWidth > campaignRef.current.offsetWidth
                ? getCampaignName()
                : ""
            }
          >
            {getCampaignName()}
          </p>
        </label>
        <ul className="cs-navigation-list">
          <li
            onClick={() => routeToCampaignStep("design")}
            className={
              props.location.pathname.includes("campaign/design")
                ? "active"
                : ""
            }
          >
            <figure>
              <DesignIcon />
              <DesignActiveIcon />
            </figure>
            <a href="javascript:void(0)"> Design </a>
          </li>
          <li
            onClick={() => routeToCampaignStep("preview")}
            className={
              props.location.pathname.includes("campaign/preview")
                ? "active"
                : ""
            }
          >
            <figure>
              <PreviewIcon />
              <PreviewActiveIcon />
            </figure>
            <a href="javascript:void(0)"> Preview </a>
          </li>
          <li
            onClick={() => routeToCampaignStep("publish")}
            className={
              props.location.pathname.includes("campaign/publish")
                ? "active"
                : ""
            }
          >
            <figure>
              <PublishIcon />
              <PublishActiveIcon />
            </figure>
            <a href="javascript:void(0)"> Publish </a>
          </li>
        </ul>
      </section>
      {props.children}
      {/* <ReactTooltip
        delayHide={500}
        effect="solid"
        place="top"
        class="tooltip"
      /> */}
    </div>
  );
};

export default NewCampaignLayout;
