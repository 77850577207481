import styled from 'styled-components'
import { Icon } from 'src/features/ui/components/Icon'
import { Text1 } from 'src/features/ui/components/Typography'

export const Container = styled.div``

export const Top = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0.3rem;
`

export const Bottom = styled.div``

export const StyledIcon = styled(Icon)``

export const Label = styled(Text1)`
  margin-left: 0.95rem;
  display: block;
  margin-bottom: -0.1rem;
`
