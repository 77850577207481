import React, { FC } from 'react'
import {
  Logo,
  ImageButton,
} from 'src/features/wizard/setup/components/WidgetIcon/styled'
import { IProps } from 'src/features/wizard/setup/components/WidgetIcon/types'
import { useImageLoader } from 'src/utils/hooks/useImageLoader'

export const WidgetIcon: FC<IProps> = ({
  width,
  height,
  color,
  imageUrl,
  isInFormElement,
}): JSX.Element => {
  const isLoaded = useImageLoader(imageUrl)

  const getSize = () => ({
    width: width ?? '3.2rem',
    height: height ?? '3.2rem',
  })

  if (imageUrl || isInFormElement) {
    return (
      <ImageButton
        isInFormElement={isInFormElement}
        color={color}
        isLoaded={isLoaded}
        {...getSize()}
        imageUrl={imageUrl!}
      />
    )
  }

  return <Logo {...getSize()} color={color!} />
}
