import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTagsToConversationRequest,
  editTagRequest,
} from "../../../actions/TagActions";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import { CS_BUTTON, TAG_ERROR_MESSAGE } from "../../../commons/Constants";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import {
  getActiveProjectId,
  getPayloadOfShowVoicBoxForErrorMessage,
  isEnterKey,
  isValidTagName,
  isConversationExisted,
  getValueFromNestedObject,
} from "../../../commons/Utility";
import { ErrorIcon, TagBlueIcon } from "../../../commons/Icons";

function AddNewTag(props) {
  const {
    payload,
    openDropDown,
    tagInputRef,
    setTagInputValue,
    conversationId,
    requestSingleConversationAndAddTagToIt,
    tagOperationOrKey,
    doShowTagInput,
  } = props;
  const dispatch = useDispatch();
  const [tagName, setTagName] = useState("");
  const [invalidMessage, setInvalidMessage] = useState("");
  const { tagDetails } = useSelector((state) => ({
    tagDetails: state.TagReducer,
  }));

  useEffect(() => {
    const { value } = getPropertiesByOperations();
    tagInputRef.current.focus();
    tagInputRef.current.value = value;
    onChange({
      target: {
        value: value,
      },
    });
  }, [tagOperationOrKey]);

  const constructProperties = (
    action,
    value,
    idForBtn,
    spanTxt,
    buttonTxt,
    isAddNewTag
  ) => {
    return {
      action: action,
      value: value,
      id: idForBtn,
      spanText: spanTxt,
      buttonText: buttonTxt,
      isAddNewTag: isAddNewTag,
    };
  };

  const getPropertiesByOperations = () => {
    return tagOperationOrKey === "add"
      ? constructProperties(addTagToMessage, "", "add", "Add tag", "Save", true)
      : constructProperties(
          updateTagName,
          getTagNameById(tagOperationOrKey),
          "edit",
          "Edit tag",
          "Apply",
          false
        );
  };

  const handleAddNew = () => {
    const { action } = getPropertiesByOperations();
    if (tagName)
      if (isValidTagName(tagName)) {
        if (!isExistedTag()) action();
        else dispatchErrorVoiceBox(TAG_ERROR_MESSAGE.DUPLICATE);
      } else dispatchErrorVoiceBox(TAG_ERROR_MESSAGE.INVALID);
    else setInvalidMessage(`Enter Tag Name`);
  };

  const getTagEntityById = (tagId) => {
    return getValueFromNestedObject(tagDetails, `tagMap.${tagId}`);
  };

  const getTagNameById = (tagId) => {
    return getTagEntityById(tagId).name;
  };

  const updateTagName = () => {
    let tagEntity = getTagEntityById(tagOperationOrKey);
    dispatch(editTagRequest({ ...tagEntity, name: tagName }));
    Event(getActiveProjectId(), "Tags", "Edit Tag", "Tag has been edited");
    doShowTagInput(false);
  };

  const dispatchErrorVoiceBox = (errorMessage) => {
    dispatch(
      showVoicebox(getPayloadOfShowVoicBoxForErrorMessage(errorMessage))
    );
  };

  const addTagToMessage = () => {
    let tagNameList = [tagName];
    dispatch(
      showVoicebox({
        message: "Tagging message",
        showBelowHeader: true,
      })
    );
    if (!isConversationExisted(conversationId))
      requestSingleConversationAndAddTagToIt(tagNameList);
    else dispatch(addTagsToConversationRequest(tagNameList, ...payload));
    Event(getActiveProjectId(), "Tags", "New Tag", "New Tag Created");
    openDropDown(false);
  };

  const onChange = (event) => {
    setTagName(event.target.value.trim());
    setInvalidMessage("");
    setTagInputValue(event.target.value.trim());
  };

  const isTagToBeEdited = (tagNameFromMap) => {
    const { isAddNewTag } = getPropertiesByOperations();
    return (
      !isAddNewTag &&
      getTagNameById(tagOperationOrKey) === tagNameFromMap.toLowerCase()
    );
  };

  const isExistedTag = () => {
    const { tagMap } = tagDetails;
    let tagNameFromMap = "";
    for (const key in tagMap) {
      tagNameFromMap = tagMap[key].name;
      if (isTagToBeEdited(tagNameFromMap)) continue;
      if (tagNameFromMap.toLowerCase() === tagName.toLowerCase()) return true;
    }
    return false;
  };

  const handleKeyUp = (event) => {
    if (isEnterKey(event)) handleAddNew();
  };

  const getClassNameOfAddButton = () => {
    return tagName ? CS_BUTTON.PRIMARY : CS_BUTTON.DISABLED;
  };

  const getClassNameOfAddTag = () => {
    return `add-new-tag${invalidMessage ? " invalid" : ""}`;
  };

  const { spanText, buttonText, id } = getPropertiesByOperations();

  return (
    <div className={getClassNameOfAddTag()}>
      <div>
        <TagBlueIcon />
        <span>{spanText}</span>
      </div>
      <input
        type="text"
        autoComplete="off"
        id="add-new-tag"
        autoFocus={true}
        onKeyUp={handleKeyUp}
        onChange={onChange}
        ref={tagInputRef}
      />
      <div className="error-message">
        <ErrorIcon />
        <span>{invalidMessage}</span>
      </div>
      <button
        id={`tag-${id}-button`}
        onClick={handleAddNew}
        className={getClassNameOfAddButton()}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default AddNewTag;
