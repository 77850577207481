import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import {
  animateFadeIn,
  animateFadeOut,
} from 'src/features/ui/components/Animation/keyframes'
import {
  IFadeInProps,
  IFadeOutProps,
} from 'src/features/ui/components/Animation/types'

export const AnimationFadeOut = styled.div<IFadeOutProps>`
  ${is('shouldFadeOut')`
    animation-fill-mode: forwards;
    opacity: 0;
    animation: ${
      // @ts-ignore
      animateFadeOut
    } ${R.prop('durationMs')}ms linear;
  `}
`

export const AnimationFadeIn = styled.div<IFadeInProps>`
  opacity: 0;
  animation: ${animateFadeIn} ${R.prop('durationMs')}ms linear;
  animation-delay: ${R.prop('delayMs')}ms;
  animation-fill-mode: forwards;
`
