import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import SidebarLayout from "./SidebarLayout";
import AdminOptions from "./AdminOptions";
import { onTabFocus, onTabBlur } from "../actions/UserAction";
import { hideNotification } from "../actions/NotificationAction";
import { getNotificationFromLocalStorageAndRemove } from "../commons/Utility";

@connect((store) => ({
  project: store.ProjectReducer,
  exception: store.ExceptionReducer,
  notifications: store.NotificationReducer,
}))
class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.notificationBlinker = null;
    this.currentTitle = document.title;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.notifications.showNotification === true &&
      this.props.notifications.showNotification === false
    ) {
      this.showNotifications();
    }
  }

  onFocus() {
    this.props.dispatch(onTabFocus());
    this.hideNotifications();
    getNotificationFromLocalStorageAndRemove();
  }

  onBlur() {
    this.props.dispatch(onTabBlur());
  }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus.bind(this));
    window.addEventListener("blur", this.onBlur.bind(this));
    window.addEventListener(
      "visibilitychange",
      this.hideNotifications.bind(this)
    );
    window.addEventListener(
      "onbeforeunload",
      getNotificationFromLocalStorageAndRemove()
    );
    window.addEventListener("storage", this.storageChangeHandler.bind(this));
    this.setCurrentTitle();
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus.bind(this));
    window.removeEventListener("blur", this.onBlur.bind(this));
    window.removeEventListener(
      "visibilitychange",
      this.hideNotifications.bind(this)
    );
    window.removeEventListener("storage", this.storageChangeHandler.bind(this));
  }

  setCurrentTitle(){
    this.currentTitle = document.location.host.includes("answerforce") ? "AnswerForce": "ChatSupport";
  }

  storageChangeHandler(event) {
    if (event.key === "shouldClearNotifications" && event.newValue === "true") {
      getNotificationFromLocalStorageAndRemove(true);
      localStorage.setItem("shouldClearNotifications", false);
    }
  }

  showNotifications() {
    if (!this.notificationBlinker) {
      this.notificationBlinker = setInterval(() => {
        let title = document.title;
        document.title =
          title === this.currentTitle ? "New Message" : this.currentTitle;
      }, 3000);
    }
  }

  hideNotifications() {
    if (this.notificationBlinker && !document.hidden) {
      document.title = this.currentTitle;
      clearInterval(this.notificationBlinker);
      this.notificationBlinker = null;
      this.props.dispatch(hideNotification());
    }
  }

  render() {
    //return this.props.children;
    return (
      <div>
        <SidebarLayout
          setSwitchStatus={(handleStatusSwitch) => {
            this.handleRingingStatus = handleStatusSwitch;
          }}
          activeProjectKey={this.props.params.projectkey}
          location={this.props.location}
        />
        <AdminOptions activeProjectKey={this.props.params.projectkey} />
        {this.props.children}
      </div>
    );
  }
}

export default MainLayout;
