import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { Text1 } from 'src/features/ui/components/Typography'
import { EmailMultiple } from 'src/features/wizard/install/components/Email/EmailMultiple'

export const SomeoneElseComponent: FC = () => (
  <span>
    <Text1 color="raven">
      Enter the email address to whom you want to send the installation
      instructions.
    </Text1>
    <Margin top={{ base: 31, sm: 26 }} />
    <EmailMultiple />
  </span>
)

export const SomeoneElse = connect(null)(SomeoneElseComponent)
