import React, { useState, useEffect, useRef } from "react";
import useProperties from "../hooks/useProperties.js";
import useOnClickOutside from "../hooks/useOnClickOutside.js";
import { SearchIcon } from "../../commons/Icons.js";
import { searchKeys } from "../commons/ContactsUtility.js";

const FilterProperties = (props) => {
  const [search, setSearch] = useState("");
  const {
    params: { projectkey },
  } = props;
  const { properties } = useProperties(projectkey);
  const {
    showProperties,
    setShowProperties,
    onSelect,
    showAddProperty,
    showMoreOptions,
  } = props;
  const allProperties = Object.keys(properties);
  const allKeys = searchKeys(allProperties, search, properties);
  const aliases = Object.values(properties);
  const propertiesRef = useRef(null);
  const listRef = useRef(null);
  const searchRef = useRef(null);
  useOnClickOutside(propertiesRef, () => setShowProperties(false));

  useEffect(() => {
    if (showProperties && listRef.current)
      listRef.current.scrollIntoView({ block: "nearest" });
    else if (!showProperties) setSearch("");
  }, [showProperties]);

  useEffect(() => {
    if (showProperties) searchRef.current.focus();
  }, [showProperties]);

  const onSearch = ({ target }) => {
    let { value } = target;
    setSearch(value);
  };

  return (
    <div
      ref={propertiesRef}
      style={{ display: showAddProperty && showMoreOptions !== false ? "inline-block" : "none" }}
      className={"filter-dwn " + (showProperties ? "open" : "")}
    >
      <a
        href="#"
        onClick={() => {
          setShowProperties(!showProperties);
        }}
      >
        &#43; Add Filters
      </a>
      <div>
        <div>
          <input
            ref={searchRef}
            onChange={onSearch}
            type="text"
            placeholder="Search filters"
            value={search}
          ></input>
          <SearchIcon />
        </div>
        <p
          style={{ display: allKeys.length == 0 ? "block" : "none" }}
          className={"no-found"}
        >
          No results found
        </p>
        <ul>
          {allKeys.map((key, index) => {
            if (index == 0)
              return (
                <li ref={listRef} onClick={() => onSelect(key)} key={key}>
                  <a href="#">{properties[key]}</a>
                </li>
              );
            else
              return (
                <li onClick={() => onSelect(key)} key={key}>
                  <a href="#">{properties[key]}</a>
                </li>
              );
          })}
        </ul>
      </div>
    </div>
  );
};

export default FilterProperties;
