export const TAG_LIST_REQUESTED = "TAG_LIST_REQUESTED";
export const TAG_LIST_RESPONSE = "TAG_LIST_RESPONSE";
export const TAG_LIST_FAILED = "TAG_LIST_FAILED";
export const ADD_TAGS_REQUESTED = "ADD_TAG_REQUESTED";
export const ADD_TAGS_RESPONSE = "ADD_TAG_RESPONSE";
export const ADD_TAGS_FAILED = "ADD_TAG_FAILED";
export const ADD_TAGS_TO_CONVERSATION_REQUESTED =
  "ADD_TAG_TO_CONVERSATION_REQUESTED";
export const ADD_TAGS_TO_CONVERSATION_RESPONSE =
  "ADD_TAG_TO_CONVERSATION_RESPONSE";
export const ADD_TAGS_TO_CONVERSATION_FAILED = "ADD_TAG_TO_CONVERSATION_FAILED";
export const ADD_TAGS_FOR_FILTER_REQUESTED = "ADD_TAGS_FOR_FILTER_REQUESTED";
export const ADD_TAGS_FOR_FILTER_FAILED = "ADD_TAGS_FOR_FILTER_FAILED";
export const REMOVE_TAG_REQUESTED = "REMOVE_TAG_REQUESTED";
export const REMOVE_TAG_RESPONSE = "REMOVE_TAG_RESPONSE";
export const REMOVE_TAG_FAILED = "REMOVE_TAG_FAILED";
export const REMOVE_TAG_FROM_CONVERSATION_REQUESTED =
  "REMOVE_TAG_FROM_CONVERSATION_REQUESTED";
export const REMOVE_TAG_FROM_CONVERSATION_RESPONSE =
  "REMOVE_TAG_FROM_CONVERSATION_RESPONSE";
export const REMOVE_TAG_FROM_CONVERSATION_FAILED =
  "REMOVE_TAG_FROM_CONVERSATION_FAILED";
export const EDIT_TAG_REQUESTED = "EDIT_TAG_REQUESTED";
export const EDIT_TAG_RESPONSE = "EDIT_TAG_RESPONSE";
export const EDIT_TAG_FAILED = "EDIT_TAG_FAILED";
export const DELETE_TAG_REQUESTED = "DELETE_TAG_REQUESTED";
export const DELETE_TAG_RESPONSE = "DELETE_TAG_RESPONSE";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";
export const REMOVE_TAG_FROM_MESSAGE_REQUESTED = "REMOVE_TAG_FROM_MESSAGE_REQUESTED";

export const requestTagList = (projectId, isFromOverflow, isAwWindow) => ({
  type: TAG_LIST_REQUESTED,
  projectId,
  isFromOverflow,
  isAwWindow,
});
export const tagListResponse = (tagMap) => ({
  type: TAG_LIST_RESPONSE,
  tagMap,
});
export const tagListFailed = () => ({
  type: TAG_LIST_FAILED,
});
export const addTagsRequest = (names, projectId, peopleId) => ({
  type: ADD_TAGS_REQUESTED,
  names,
  projectId,
  peopleId,
});

export const addTagsResponse = (tagMap) => ({
  type: ADD_TAGS_RESPONSE,
  tagMap,
});

export const addTagsFailed = () => ({
  type: ADD_TAGS_FAILED,
});

export const addTagsToConversationRequest = (
  names,
  conversation,
  createdBy,
  messageId,
  isFromOverflow
) => ({
  type: ADD_TAGS_TO_CONVERSATION_REQUESTED,
  names,
  conversation,
  createdBy,
  messageId,
  isFromOverflow,
});

export const addTagsToConversationResponse = (tagMap) => ({
  type: ADD_TAGS_TO_CONVERSATION_RESPONSE,
  tagMap,
});

export const addTagsToConversationFailed = () => ({
  type: ADD_TAGS_TO_CONVERSATION_FAILED,
});

export const addTagsForFilterRequest = (projectId, query, tags) => ({
  type: ADD_TAGS_FOR_FILTER_REQUESTED,
  projectId,
  query,
  tags,
});

export const addTagsForFilterFailed = () => ({
  type: ADD_TAGS_FOR_FILTER_FAILED,
});

export const removeTagRequest = (tagId, peopleEntity) => ({
  type: REMOVE_TAG_REQUESTED,
  tagId,
  peopleEntity,
});

export const removeTagResponse = (tagId) => ({
  type: REMOVE_TAG_RESPONSE,
  tagId,
});

export const removeTagFailed = () => ({
  type: REMOVE_TAG_FAILED,
});
export const removeTagFromConversationRequest = (
  tagId,
  conversation,
  isOverflowConversation,
  isAwWindow
) => ({
  type: REMOVE_TAG_FROM_CONVERSATION_REQUESTED,
  tagId,
  conversation,
  isOverflowConversation,
  isAwWindow,
});

export const removeTagFromConversationResponse = (tagId) => ({
  type: REMOVE_TAG_FROM_CONVERSATION_RESPONSE,
  tagId,
});

export const removeTagFromConversationFailed = () => ({
  type: REMOVE_TAG_FROM_CONVERSATION_FAILED,
});

export const editTagRequest = (tagEntity) => ({
  type: EDIT_TAG_REQUESTED,
  tagEntity,
});

export const editTagResponse = (tagEntity) => ({
  type: EDIT_TAG_RESPONSE,
  tagEntity,
});

export const editTagFailed = () => ({
  type: EDIT_TAG_FAILED,
});

export const deleteTagRequest = (projectId, tagId) => ({
  type: DELETE_TAG_REQUESTED,
  tagId,
  projectId,
});

export const deleteTagResponse = (tagId) => ({
  type: DELETE_TAG_RESPONSE,
  tagId,
});

export const deleteTagFailed = () => ({
  type: DELETE_TAG_FAILED,
});

export const removeTagFromMessageRequest = (projectId,messageId,tagId) => ({
  type: REMOVE_TAG_FROM_MESSAGE_REQUESTED,
  projectId,
  messageId,
  tagId
})
