import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import {
  initiateClipboard,
  isURLValid,
  getEncrypted,
  getDecrypted,
  addHttps,
  isURL,
} from "../../commons/Utility";
import { showVoicebox } from "../../actions/VoiceboxAction";
import {
  isUrlLoadableRequest,
  resetPreviewReducer,
} from "../../actions/PreviewActions";

@connect((state) => ({
  voiceboxInfo: state.VoiceboxReducer,
  previewReducer: state.PreviewReducer,
}))
class DemoPageComponent extends Component {
  constructor() {
    super();
    this.state = {
      PREVIEW_TYPE: "DESKTOP",
      previewUrl: "",
      typedPreviewUrl: "",
      copyButtonText: "Copy Share Link",
      copyButtonEnabled: false,
    };
    this.onMobileViewClick = this.onMobileViewClick.bind(this);
    this.onDesktopViewClick = this.onDesktopViewClick.bind(this);
    this.onPreviewUrlChange = this.onPreviewUrlChange.bind(this);
    this.onPreviewKeyPress = this.onPreviewKeyPress.bind(this);
    this.refrestIframe = this.refrestIframe.bind(this);
    this.renderIframe = this.renderIframe.bind(this);
  }
  componentWillMount() {
    let viewOptions = this.props.params.viewOptions;
    if (viewOptions) {
      viewOptions = JSON.parse(getDecrypted(viewOptions));
      let previewUrl = viewOptions.previewUrl;
      let mode = viewOptions.mode ? viewOptions.mode : "agent";
      let expiryTime = viewOptions.expiryTime;
      if (
        mode == "agent" ||
        (expiryTime && new Date().getTime() < expiryTime)
      ) {
        if (previewUrl && isURLValid(previewUrl)) {
          this.setState({ mode });
          this.props.dispatch(isUrlLoadableRequest(previewUrl));
          return;
        }
      } else browserHistory.push("/login");
    } else browserHistory.push("/preview");
  }
  componentDidMount() {
    initiateClipboard(
      "#copyUrlBtn",
      () => {
        this.setState({ ...this.state, copyButtonText: "Copied" });
      },
      () => {
        this.props.dispatch(
          showVoicebox({
            message: "Please copy the url manually",
            dismissAfter: 3000,
          })
        );
      },
      { text: () => this.getCustomerLink(this.state.previewUrl) }
    );
  }
  componentWillReceiveProps(nextProps) {
    let viewOptions = nextProps.params.viewOptions;
    let previewUrl = "";
    let mode = "";
    if (viewOptions) {
      viewOptions = JSON.parse(getDecrypted(viewOptions));
      if (viewOptions.previewUrl && isURLValid(viewOptions.previewUrl)) {
        previewUrl = viewOptions.previewUrl;
        mode = viewOptions.mode ? viewOptions.mode : "agent";
      }
    }
    if (previewUrl != this.state.previewUrl || mode != this.state.mode) {
      if (
        nextProps.previewReducer.previewUrl == previewUrl &&
        nextProps.previewReducer.isFetched
      ) {
        let typedPreviewUrl = previewUrl
          ? this.getCustomerLink(previewUrl)
          : "";
        this.setState(
          {
            previewUrl,
            mode,
            isUrlLoadable: nextProps.previewReducer.isUrlLoadable,
            typedPreviewUrl,
            copyButtonText: "Copy Share Link",
            copyButtonEnabled: typedPreviewUrl ? true : false,
          },
          () => {
            this.refrestIframe();
          }
        );
      } else if (
        previewUrl &&
        !nextProps.previewReducer.isFetching &&
        (!nextProps.previewReducer.isFetched ||
          (nextProps.previewReducer.isFetched &&
            nextProps.previewReducer.previewUrl != previewUrl))
      ) {
        this.setState({ isUrlLoadable: undefined }, () => {
          this.props.dispatch(isUrlLoadableRequest(previewUrl));
        });
      } else if (!previewUrl) this.setState({ previewUrl: "" });
    }
  }
  getCustomerLink(previewUrl) {
    let viewOptions = getEncrypted(
      JSON.stringify({
        previewUrl,
        mode: "customer",
        expiryTime: new Date().getTime() + 604800000,
      })
    );
    return document.location.host + "/customer/preview/" + viewOptions;
  }
  onMobileViewClick() {
    if (this.state.previewUrl && this.state.isUrlLoadable)
      this.setState({ ...this.state, PREVIEW_TYPE: "MOBILE" });
  }
  onPreviewUrlChange(e) {
    this.setState({
      ...this.state,
      isUrlLoadable: undefined,
      typedPreviewUrl: e.target.value,
      copyButtonText: "Copy Share Link",
      copyButtonEnabled:
        e.target.value == this.getCustomerLink(this.state.previewUrl),
    });
  }
  onPreviewKeyPress(e) {
    let typedPreviewUrl = this.state.typedPreviewUrl.trim();
    if (e.key === "Enter" || e.type == "blur") {
      if (
        typedPreviewUrl.startsWith(
          document.location.host + "/customer/preview/"
        )
      ) {
        typedPreviewUrl = typedPreviewUrl.split("/");
        typedPreviewUrl = typedPreviewUrl[typedPreviewUrl.length - 1];
        typedPreviewUrl = JSON.parse(getDecrypted(typedPreviewUrl)).previewUrl;
      }

      if (
        typedPreviewUrl != this.state.previewUrl &&
        typedPreviewUrl != this.getCustomerLink(this.state.previewUrl)
      ) {
        if (typedPreviewUrl) {
          typedPreviewUrl = addHttps(typedPreviewUrl);
          if (isURLValid(typedPreviewUrl)) {
            let encryptedValue = getEncrypted(
              JSON.stringify({ previewUrl: typedPreviewUrl, mode: "agent" })
            );
            browserHistory.push("/preview/" + encryptedValue);
          } else
            this.props.dispatch(
              showVoicebox({ message: "Invalid URL", dismissAfter: 3000 })
            );
        } else {
          this.setState({ isUrlLoadable: undefined }, () => {
            this.props.dispatch(resetPreviewReducer());
            browserHistory.push("/preview");
          });
        }
      } else {
        let viewOptions = this.props.params.viewOptions;
        if (viewOptions) {
          viewOptions = JSON.parse(getDecrypted(viewOptions));
          let previewUrl = viewOptions.previewUrl;
          let mode = viewOptions.mode ? viewOptions.mode : "agent";
          if (previewUrl && isURLValid(previewUrl)) {
            this.setState({
              previewUrl,
              isUrlLoadable:
                this.props.previewReducer.previewUrl == previewUrl
                  ? this.props.previewReducer.isUrlLoadable
                  : undefined,
              typedPreviewUrl: this.getCustomerLink(previewUrl),
              mode,
              copyButtonEnabled: true,
            });
          }
        }
      }
    }
  }
  onDesktopViewClick() {
    if (this.state.previewUrl && this.state.isUrlLoadable)
      this.setState({ ...this.state, PREVIEW_TYPE: "DESKTOP" });
  }
  refrestIframe() {
    let demoIframe = this.refs.demoIframe;
    let demoIframeLoading = this.refs.demoIframeLoading;
    if (demoIframe) {
      demoIframe.style.display = "none";
      let doc = demoIframe.contentWindow.document;
      let body = doc.getElementsByTagName("body")[0];

      let presentIframe = doc.getElementsByTagName("iframe")[0];
      if (!presentIframe || presentIframe.src != this.state.previewUrl) {
        if (presentIframe && presentIframe.src != this.state.previewUrl) {
          body.removeChild(presentIframe);

          if (demoIframeLoading) demoIframeLoading.style.display = "block";
        }
        let customerPageIframe = doc.createElement("iframe");

        customerPageIframe.setAttribute("src", this.state.previewUrl);
        customerPageIframe.addEventListener("load", (e) => {
          console.log("loaded successfully");
          demoIframe.style.display = "block";
          demoIframeLoading.style.display = "none";
        });
        customerPageIframe.addEventListener("error", (e) => {
          console.log("error happened");
        });
        customerPageIframe.style.width = "100%";
        customerPageIframe.style.height = "100%";
        customerPageIframe.style.border = "0px";
        body.appendChild(customerPageIframe);
      }
    }
  }
  renderIframe() {
    if (this.state.previewUrl && this.state.isUrlLoadable)
      return (
        <div
          ref="iframeDiv"
          className={
            this.state.previewUrl && this.state.PREVIEW_TYPE == "MOBILE"
              ? "mobile-iframe-div"
              : ""
          }
        >
          <iframe
            ref="demoIframe"
            src={document.location.origin + "/blank"}
            onLoad={this.refrestIframe}
            className={
              this.state.PREVIEW_TYPE == "MOBILE"
                ? "mobile-iframe"
                : "desktop-iframe"
            }
          ></iframe>
          <div ref="demoIframeLoading" className="iframe-loading">
            Loading...
          </div>
        </div>
      );
    else if (this.state.isUrlLoadable == false) {
      return (
        <div
          ref="iframeDiv"
          className={
            this.state.previewUrl && this.state.PREVIEW_TYPE == "MOBILE"
              ? "mobile-iframe-div"
              : ""
          }
        >
          <h1 className={"demo-urltxt error-url"}>
            {"Unable to generate preview"}
          </h1>
        </div>
      );
    } else
      return (
        <div
          ref="iframeDiv"
          className={
            this.state.previewUrl && this.state.PREVIEW_TYPE == "MOBILE"
              ? "mobile-iframe-div"
              : ""
          }
        >
          <h1
            className={
              "demo-urltxt" +
              (this.props.previewReducer.isFetching ? " dn" : "")
            }
          >
            {"Enter a URL to simulate the Widget experience"}
          </h1>
          <div
            ref="demoIframeLoading"
            className={
              "iframe-loading" +
              (this.props.previewReducer.isFetching ? "" : " dn")
            }
          >
            Loading...
          </div>
        </div>
      );
  }
  render() {
    return (
      <section>
        <div
          className={
            "demo-header" +
            (this.state.mode == "customer"
              ? " customer-view"
              : this.state.isUrlLoadable == false
              ? " error-url"
              : "")
          }
        >
          <Link to="/login" className="chatsupport-logo" />
          <input
            type="text"
            placeholder="Enter URL here"
            onChange={this.onPreviewUrlChange}
            onKeyPress={this.onPreviewKeyPress}
            onBlur={this.onPreviewKeyPress}
            value={this.state.typedPreviewUrl}
          />
          <button
            id={
              this.state.previewUrl && this.state.isUrlLoadable
                ? "copyUrlBtn"
                : ""
            }
            className={
              this.state.copyButtonEnabled && this.state.isUrlLoadable
                ? "active"
                : ""
            }
          >
            {this.state.copyButtonText}
          </button>
          <i
            className="close-btn-icon"
            onClick={() => {
              browserHistory.push("/login");
            }}
          >
            &times;
          </i>
          <p className="">This is a preview of the chat widget experience</p>
          <div>
            <i
              onClick={this.onDesktopViewClick}
              className={
                "desktop-icon" +
                (this.state.PREVIEW_TYPE == "DESKTOP" ? " active" : "")
              }
            ></i>
            <i
              onClick={this.onMobileViewClick}
              className={
                "mobile-icon" +
                (this.state.PREVIEW_TYPE == "MOBILE" ? " active" : "")
              }
            ></i>
          </div>
        </div>
        {this.renderIframe()}
      </section>
    );
  }
}
export default DemoPageComponent;
