import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProjectId } from "../../commons/Utility";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import { getPeopleGroupsRequest } from "../../actions/PeopleAction";

export const useBroadcastMessageStats = (projectKey, campaignId) => {
  const { broadcastEntitiesReducer, staffDetails, peopleReducer } = useSelector((state) => ({
    broadcastEntitiesReducer: state.BroadcastMessageStatsReducer || {},
    staffDetails: state.StaffReducer || {},
    peopleReducer: state.PeopleReducer,
  }));
  const dispatch = useDispatch();
  const campaignEntities =
    broadcastEntitiesReducer &&
    broadcastEntitiesReducer.broadcastMessageEntities
      ? broadcastEntitiesReducer.broadcastMessageEntities
      : undefined;
  const isCampaignFetched = broadcastEntitiesReducer
    ? broadcastEntitiesReducer.isFetched
    : false;
  const isCampaignUpdated = broadcastEntitiesReducer
    ? broadcastEntitiesReducer.isUpdated
    : false;
  const campaignPopupProjectIds = broadcastEntitiesReducer
    ? broadcastEntitiesReducer.campaignPopupProjectIds
    : undefined;
  const fetchedProjectId = broadcastEntitiesReducer
    ? broadcastEntitiesReducer.fetchedProjectId
    : undefined;
  useEffect(() => {
    if(peopleReducer && !peopleReducer.isFetchingGroups && !peopleReducer.isGroupsFetched || peopleReducer && peopleReducer.groupsProjectId != getProjectId(projectKey))
      dispatch(getPeopleGroupsRequest(getProjectId(projectKey), false));

  }, [projectKey, broadcastEntitiesReducer.isFetched]);
  useEffect(() => {
    if (campaignId && isCampaignFetched && peopleReducer.isGroupsFetched) initializeFields(campaignId);
  }, [isCampaignFetched, staffDetails.isLoaded, peopleReducer.isGroupsFetched ]);
  
  const constructFilterQuery = (selectedCampaign) => {
    let { segmentIds = [], key } = selectedCampaign;
    let constructedFilterQuery = {};
    let count = 0;

    segmentIds.map((segmentId) => {
      let segmentDetails = peopleReducer.peopleGroupMap[segmentId] || {};
      if (segmentDetails.completeQuery) {
        constructedFilterQuery[segmentId] = JSON.parse(
          segmentDetails.completeQuery
        );
      }
      if (segmentDetails.count != undefined) {
        count = count + Number(segmentDetails.count);
      }
    });
    return constructedFilterQuery;
  };


  const initializeFields = (campaignId) => {
    if (staffDetails.isLoaded && isCampaignFetched && campaignEntities) {
      let campaign = campaignEntities.find(
        (campaignData) => campaignData.key == campaignId
      );
      campaign.message = campaign.message.value || campaign.message;
      const projectId = getProjectId(projectKey);
      let staffMap = staffDetails.dashboardAgents[projectId];
      let senderName = staffMap[campaign.senderId] ? staffMap[campaign.senderId].firstName : "Richard";
      let photoUrl = staffMap[campaign.senderId]
        ? staffMap[campaign.senderId].photoUrl
        : undefined;
      campaign.campaignFromName = senderName;
      campaign.campaignFromImage = photoUrl;
      let selectedCampaign = broadcastEntitiesReducer.selectedCampaign 
                                && broadcastEntitiesReducer.selectedCampaign.key == campaignId 
                                ? broadcastEntitiesReducer.selectedCampaign
                                : {};
      campaign.filterQuery = constructFilterQuery(campaign);
      let updatedCampaign = Object.assign({},campaign, selectedCampaign);
      dispatch(campaignActions.updateCampaignType(campaign.typeOfMessage));
      dispatch(campaignActions.addSelectedCampaign(updatedCampaign));
    }
  };
  return {
    campaignData: campaignEntities || [],
    isCampaignFetched: isCampaignFetched,
    isCampaignUpdated: isCampaignUpdated,
    fetchedProjectId : fetchedProjectId,
    campaignPopupProjectIds: campaignPopupProjectIds || [],
    isCampaignFromDashboard: broadcastEntitiesReducer.isCampaignFromDashboard,
  };
};
