import { call, put, fork } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as EventConfigurationActions from "../actions/EventConfigurationAction";
import { authFailed } from "../actions/LoginAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import * as CustomFieldsActions from "../actions/CustomFieldsAction";

export function* fetchEvents(customFieldsObject) {
  const url = "/events/getByProject";

  const requestParam = {
    projectId: customFieldsObject.projectId,
    limit: "",
    cursor: "",
    order: "",
  };

  try {
    let response = yield call(Ajax.post, url, requestParam);
    if (response.response == true) {
      let eventMap = {};
      response = response.data.entity;
      response.map((item, i) => {
        eventMap[item.key] = item;
      });
      yield put(EventConfigurationActions.eventResponse(eventMap));
    } else yield put(EventConfigurationActions.eventFailed());
  } catch (e) {
    console.error(e);
    yield put(CustomFieldsActions.customFieldsFailed());
    yield put(handleGlobalError(e));
  }
}
