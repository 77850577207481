export const USER_FETCH_REQUESTED = "USER_FETCH_REQUESTED";
export const USER_FETCH_RESPONSE = "USER_FETCH_RESPONSE";
export const USER_FETCH_FAILED = "USER_FETCH_FAILED";
export const UPDATE_TIME_DIFFERENCE = "UPDATE_TIME_DIFFERENCE";
export const UPDATE_LOCATION_DETAILS = "UPDATE_LOCATION_DETAILS";

export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";
export const USER_UPDATE_RESPONSE = "USER_UPDATE_RESPONSE";
export const USER_UPDATE_REQUESTED = "USER_UPDATE_REQUESTED";

export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const SWITCH_USER = "SWITCH_USER";
export const SWITCH_USER_TO_SPECIFIC_PAGE = "SWITCH_USER_TO_SPECIFIC_PAGE";
export const SWITCH_TO_OWN_ACCOUNT = "SWITCH_TO_MY_ACCOUNT";

export const UPDATE_SELECTED_PROJECT_KEY = "UPDATE_SELECTED_PROJECT_KEY";
export const UPDATE_DEFAULT_PROJECT_KEY = "UPDATE_DEFAULT_PROJECT_KEY";

export const ON_TAB_FOCUS = "ON_TAB_FOCUS";
export const ON_TAB_BLUR = "ON_TAB_BLUR";

export const STAFF_UPDATE_FAILED = "STAFF_UPDATE_FAILED";
export const STAFF_UPDATE_RESPONSE = "STAFF_UPDATE_RESPONSE";
export const STAFF_UPDATE_REQUESTED = "STAFF_UPDATE_REQUESTED";

export const ACKNOWLEDGE_PLAN_UPGRADE = "ACKNOWLEDGE_PLAN_UPGRADE";
export const TOGGLE_PLAN_UPGRADE_POPUP = "TOGGLE_PLAN_UPGRADE_POPUP";

export const UPLOAD_USER_IMAGE_URL_REQUESTED =
  "UPLOAD_USER_IMAGE_URL_REQUESTED";
export const UPLOAD_USER_IMAGE_REQUESTED = "UPLOAD_USER_IMAGE_REQUESTED";

export const UPDATE_USER_MODE = "UPDATE_USER_MODE";
export const UPDATE_SB_WINDOW_STATUS = "UPDATE_SB_WINDOW_STATUS";

export const getUser = (user) => ({
  type: USER_FETCH_REQUESTED,
  user,
});

export const userResponse = (user) => ({
  type: USER_FETCH_RESPONSE,
  user,
});

export const authFailed = (user) => ({
  type: USER_FETCH_FAILED,
  user,
});

export const updateUserRequested = (user, showVoicebox) => ({
  type: USER_UPDATE_REQUESTED,
  user: user,
  showVoicebox: showVoicebox,
});

export const updateUserResponse = (user) => ({
  type: USER_UPDATE_RESPONSE,
  user,
});

export const updateUserFailed = (user) => ({
  type: USER_UPDATE_FAILED,
  user,
});

export const resetPasswordRequested = (user) => ({
  type: RESET_PASSWORD_REQUESTED,
  user,
});

export const resetPasswordResponse = (user) => ({
  type: RESET_PASSWORD_RESPONSE,
  user,
});

export const resetPasswordFailed = (user) => ({
  type: RESET_PASSWORD_FAILED,
  user,
});

export const switchUser = (customerLogin, projectId) => ({
  type: SWITCH_USER,
  customerLogin,
  projectId,
});

export const switchUserProfileToSpecificPage = (
  customerLogin,
  projectId,
  redirectPage
) => ({
  type: SWITCH_USER_TO_SPECIFIC_PAGE,
  customerLogin,
  projectId,
  redirectPage,
});

export const switchToOwnAccount = (redirectPage) => ({
  type: SWITCH_TO_OWN_ACCOUNT,
  redirectPage,
});

export const updateTimeDifference = (timeDifference) => ({
  type: UPDATE_TIME_DIFFERENCE,
  timeDifference,
});
export const updateLocationDetails = (locationDetails) => ({
  type: UPDATE_LOCATION_DETAILS,
  locationDetails,
});
export const updateSelectedProjectKey = (projectKey) => ({
  type: UPDATE_SELECTED_PROJECT_KEY,
  projectKey,
});
export const updateDefaultProjectKey = (projectKey) => ({
  type: UPDATE_DEFAULT_PROJECT_KEY,
  projectKey,
});
export const onTabFocus = () => ({
  type: ON_TAB_FOCUS,
});

export const onTabBlur = () => ({
  type: ON_TAB_BLUR,
});

export const updateStaffRequested = (user, showVoicebox) => ({
  type: STAFF_UPDATE_REQUESTED,
  user: user,
  showVoicebox: showVoicebox,
});

export const updateStaffResponse = (user) => ({
  type: STAFF_UPDATE_RESPONSE,
  user,
});

export const updateStaffFailed = (user) => ({
  type: STAFF_UPDATE_FAILED,
  user,
});

export const requestUploadUrl = (fileDetails, userDetails) => ({
  type: UPLOAD_USER_IMAGE_URL_REQUESTED,
  fileDetails,
  userDetails,
});

export const requestUploadImage = (fileDetails, userDetails) => ({
  type: UPLOAD_USER_IMAGE_REQUESTED,
  fileDetails,
  userDetails,
});

export const acknowledgePlanUpgrade = () => ({
  type : ACKNOWLEDGE_PLAN_UPGRADE
});

export const togglePlanUpgradePopup = () => ({
  type : TOGGLE_PLAN_UPGRADE_POPUP
});

export const updateUserMode = (isFullScreenMode , fullscreenQuery) => ({
  type: UPDATE_USER_MODE,
  isFullScreenMode,
  fullscreenQuery
});

export const updateSBWindowStatus = (isSBFocused) => ({
  type: UPDATE_SB_WINDOW_STATUS,
  isSBFocused,
});