export const breakPoints = {
  sm: 768,
  md: 1120,
  lg: 1600,
}

export const mq = {
  sm: `@media (min-width: ${breakPoints.sm}px)`,
  md: `@media (min-width: ${breakPoints.md}px)`,
  lg: `@media (min-width: ${breakPoints.lg}px)`,
}
