import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  selectIsLoggedIn,
  selectWidgetId,
} from 'src/features/authorization/store/selectors'
import { createWidgetSetupRoute } from 'src/pages/WidgetSetup/data'
import { useEffectSelector } from 'src/utils/hooks/useEffectSelector'
import { IProps } from 'src/utils/router/components/PublicRouteGuard/types'

export const PublicGuardedRoute: FC<IProps> = ({
  component: Component,
  ...rest
}) => {
  const widgetId = useEffectSelector<string>((state) =>
    selectWidgetId(state.auth)
  )
  const isLoggedIn = useEffectSelector<boolean>((state) =>
    selectIsLoggedIn(state.auth)
  )

  if (isLoggedIn === undefined) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn || !widgetId ? (
          <Component {...props} />
        ) : (
          <Redirect to={createWidgetSetupRoute(widgetId)} />
        )
      }
    />
  )
}
