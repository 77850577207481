import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestTagList } from "../../actions/TagActions";

const useTags = (projectId) => {
  const tagsInfo = useSelector((state) => state.TagReducer) || {};
  const { isFetched, isFetching, projectId: previousProjectId } = tagsInfo;
  const { tagMap: tags } = tagsInfo;
  const dispatch = useDispatch();

  if ((!isFetched && !isFetching) || previousProjectId != projectId)
    dispatch(requestTagList(projectId));

  return { tags };
};

export default useTags;
