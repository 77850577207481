import React, { useEffect } from "react";
import { useUpdateChatConfigurationEntity } from "../hooks/useUpdateChatConfigurationEntity";
import { showVoicebox } from "../../actions/VoiceboxAction";
import * as util from "../../commons/Utility.js";
import { useSelector, useDispatch } from "react-redux";
import { usePrevious } from "../hooks/usePrevious";

const ChatRoutingComp = (props) => {
  const { user, staffReducer, chatConfigData } = useSelector((state) => ({
    user: state.UserReducer,
    staffReducer: state.StaffReducer,
    chatConfigData: state.ChatConfigurationReducer,
  }));
  let projectKey = "LS-" + props.params.projectkey;
  const { data, isUpdating } = chatConfigData;
  const chatConfig = data[projectKey] || {};
  const updateChatConfig = useUpdateChatConfigurationEntity();
  const dispatch = useDispatch();
  const prevIsUpdating = usePrevious(isUpdating);
  let isAutoAssingEnabled =
    chatConfig && chatConfig.isAutoAssigned ? true : false;
  let isOverFlowEnabled =
    chatConfig && chatConfig.isOverFlowEnabled ? true : false;

  const toggleAssignmentOption = () => {
    if (isValidUser()) {
      let chatConfiguration = Object.assign({}, chatConfig);
      chatConfiguration.isAutoAssigned = !chatConfiguration.isAutoAssigned;
      let { key, isAutoAssigned } = chatConfiguration;
      updateChatConfig({ key, isAutoAssigned });
      dispatch(showVoicebox({ message: "Updating" }));
    } else {
      //dispatch( showVoicebox( { message : 'You should be an owner to update the settings...' , dismissAfter : 3000} ) );
    }
  };

  const toggleOverflowOption = () => {
    if (isValidUser()) {
      let chatConfiguration = Object.assign({}, chatConfig);
      chatConfiguration.isOverFlowEnabled = !chatConfiguration.isOverFlowEnabled;
      updateChatConfig({ key: chatConfiguration.key, isOverFlowEnabled: chatConfiguration.isOverFlowEnabled });
      dispatch(showVoicebox({ message: "Updating", dismissAfter: 3000 }));
    } else {
      dispatch(
        showVoicebox({
          message: "You should be an owner to update the settings...",
          dismissAfter: 3000,
        })
      );
    }
  };

  const isValidUser = () => {
    let staffMap = staffReducer.dashboardAgents
      ? staffReducer.dashboardAgents[util.getProjectId(props.params.projectkey)]
      : {};
    if (staffMap && user.data && user.data.id) {
      let currentStaff = staffMap[user.data.id];
      let currentStaffPermission = currentStaff.userPermission
        ? currentStaff.userPermission
        : "RESTRICTED";

      return currentStaffPermission === "FULL_ACCESS";
    }
    return false;
  };

  const getClassName = (isAutoAssingEnabled) => {
    if (isAutoAssingEnabled) return "cs-switch-btn";
    return "cs-switch-btn off";
  };

  const getClassNameForOverflow = () => {
    if (isOverFlowEnabled) return "cs-switch-btn";
    return "cs-switch-btn off";
  };

  const getOverflowToggleButton = () => {
    if (user.data && user.data.isAdmin == "true") {
      return (
        <div id="overflow_div" className="cs-form-col cs-form-routing">
          <div className="col">
            <label>Overflow</label>
            <p>
              Route incoming chats to our receptionists when your team is busy
              or unavailable
            </p>
          </div>
          <div
            id="overflow_toggle"
            className={getClassNameForOverflow()}
            onClick={toggleOverflowOption}
          ></div>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <div className="cs-settings-wrp">
      <section className="widget-configuration">
        <h3>Chat Routing</h3>
        <p>Customize chat answering features</p>

        {getOverflowToggleButton()}

        <div className="cs-form-col cs-form-routing">
          <div className="col">
            <label>Auto Answer</label>
            <p>
              Enabling will auto assign chats to available team members.
              Disabling will send an incoming chat pop-up to available team
              members asking them to accept/reject the chat
            </p>
          </div>
          <div
            id="auto_answer_toggle"
            className={getClassName(isAutoAssingEnabled)}
            onClick={toggleAssignmentOption}
            onKeyDown={ () => {} }
          ></div>
        </div>
        {/* <div className="cs-form-col cs-form-routing">
                <div className="col">
                    <label>Sticky Routing</label>
                    <p>When turned on, chats from recurring visitors will route to same agent if available. If the agent is unavailable or turned off, chats will follow the normal routing flow.</p>
                </div>
                <div className="cs-switch-btn off"></div>
            </div> */}
      </section>
    </div>
  );
};
export default ChatRoutingComp;
