import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DesktopIcon,
  DesktopActiveIcon,
  MobileIcon,
  MobileActiveIcon,
} from "../../../app/commons/Icons";
import { getSelectedCampaignMessage, isEmailDesktopType } from "../../../app/commons/Utility.js";
import { updatePreviewType } from "../../actions/BroadcastMessageStatsAction";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";

const EmailQuickPreview = (props) => {
  const { campaignDetails, staffDetails, popupDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    staffDetails: state.StaffReducer,
    popupDetails: state.PopupReducer
  }));

  const [isHTMLTemplate, setisHTMLTemplate] = useState(false);
  const miniNavBar = isEmailDesktopType(campaignDetails);
  const dispatch = useDispatch();
  const { isPopupPreview } = props;

  useEffect(()=>{
    if(campaignDetails.selectedCampaign.isHTMLTemplateEnabled && !popupDetails.popupProps.display)
      setisHTMLTemplate(campaignDetails.selectedCampaign.isHTMLTemplateEnabled);
  },[popupDetails.popupProps]);

  const togglePreviewType = (previewType) => {
    const projectId = staffDetails.activeProjectId;
    dispatch(updatePreviewType(previewType));
    Event(
      projectId,
      "Campaigns",
      "CampaignPreview"+previewType[0].toUpperCase()+previewType.substring(1, previewType.length),+"View",
      previewType
    )
  };
  const getFromStaffEmailId = () => {
    const projectId = staffDetails.activeProjectId;
    const staffData = staffDetails.dashboardAgents[projectId];
    const senderId = campaignDetails.selectedCampaign.senderId;
    return senderId && staffData[senderId] ? staffData[senderId].login : "";
  };

  const getPreviewHtml = () => {
    let message = getSelectedCampaignMessage(campaignDetails.selectedCampaign)
    let replacedMessage = message.replace("+_add_content", "...");
    getStaffDetails();
    replacedMessage = replacedMessage.replace(
      "+_from_",
      `<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;"> From</span>${
        props.agentName || "Agent Name"
      }</p>`
    );
    replacedMessage = getProperReplacedFallbackValues(replacedMessage);
    if(isHTMLTemplate) return replacedMessage;
    return { __html: replacedMessage };
  };

  const getStaffDetails = () => {
    let staff = props.randomStaffData() || {};
    if (props.showFallbackValues) {
      return {
        "{{firstName}}": staff.firstName || "Richard",
        "{{lastName}}": staff.lastName || "Tyler",
        "{{fullName}}": staff.fullName || "Richard Tyler",
        "{{companyName}}":
          campaignDetails.selectedCampaign.projectName || "CES Consulting",
      };
    } else {
      return {
        "{{firstName}}": "First Name",
        "{{lastName}}": "Last Name",
        "{{fullName}}": "Full Name",
        "{{companyName}}": "Company Name",
      };
    }
  };

  const getProperReplacedFallbackValues = (message) => {
    let fallbackStaffData = getStaffDetails();
    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `${fallbackStaffData[match]}`;
      }
    );

    return message;
  };
  const imageError = (e) => {
    e.target.style.display = "none";
  };
  return (
    <section
      className={
        "cs-campaign-preview cs-email-preview quick-preview " +
        (miniNavBar ? "desktop" : "")
      }
    >
      <code>
        <i
          className={miniNavBar ? "active" : ""}
          onClick={() => {
            togglePreviewType("Desktop");
          }}
        >
          <DesktopIcon />
          <DesktopActiveIcon />
        </i>
        <i
          className={miniNavBar ? "" : "active"}
          onClick={() => {
            togglePreviewType("Mobile");
          }}
        >
          <MobileIcon />
          <MobileActiveIcon />
        </i>
      </code>
      <div>
        <div className="widget-camp-cont">
          {/* <svg className="icon"><use xlink:href="#email-temp-placeholder"></use></svg> */}
          <section>
            <div className="word-break">
              <h4>
                <code>From: {getFromStaffEmailId()}</code>
              </h4>
              <h4>Sub: {campaignDetails.selectedCampaign.subject}</h4>
              {isHTMLTemplate ? (
                <iframe srcDoc={getPreviewHtml()} />
              ) : (
                <div dangerouslySetInnerHTML={getPreviewHtml()} />
              )}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default EmailQuickPreview;
