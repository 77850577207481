import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { togglePlanUpgradePopup } from "../actions/UserAction.js";
import {getWebappBaseUrl, getPaymentUrl} from "../commons/Utility.js";
import Pricing from "./Pricing.jsx";

const UpgradePopup = () => {
    const { userReducer, staffReducer } = useSelector((state) => ({
        userReducer : state.UserReducer,
        staffReducer : state.StaffReducer
    }));
    const [showExtended, setShowExtended] = useState(false);
    const scrollRef = useRef(null);
    const popupRef = useRef(null);
    const dispatch = useDispatch();
    const toggleUpgradePopup = () => {
        dispatch(togglePlanUpgradePopup());
    }
    useEffect(() => {
        if (userReducer.showUpgradePopup && scrollRef.current) {
            setShowExtended(false);
            if( scrollRef.current.scrollIntoView)
                scrollRef.current.scrollIntoView({ block: "nearest" }); 
        }
    }, [userReducer.showUpgradePopup]);
    useEffect(() => {
        document.addEventListener("keydown", onEsc);
        return () => {
          document.removeEventListener("keydown", onEsc);
        };
    }, [userReducer.showUpgradePopup]);
    const onEsc = (event) => {
        if (userReducer.showUpgradePopup == true && event.key === "Escape" && event.keyCode == 27) {
            dispatch(togglePlanUpgradePopup());
        }
      };
    const redirectToPaymentPage = (planId) => {
        let {accountID,login} = userReducer.data;
        let {isInternalUser} = userReducer;
        if(!isInternalUser && accountID && login)
            window.location = `${getPaymentUrl()}/payment?planId=${planId}&accountId=${accountID}&email=${login}`;                      
    }
    return (
        <div ref={popupRef} style={{"display" : ( userReducer.showUpgradePopup ? "block" : "none") }}>
            <Pricing toggleUpgradePopup={toggleUpgradePopup} redirectToPaymentPage={redirectToPaymentPage}/>
        </div>
    )
}

export default UpgradePopup;