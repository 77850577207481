import React from "react";

const InputLoader = (props) => {
    return (<div class="cs-widget-config cs-business-hrs">
            <div>
                <h3><em class="progress" style={{width : 160}}></em></h3>
                    <p>
                    <em class="progress" style={{width : 280}}></em>
                    </p>
                </div>
            </div>)
}

export default InputLoader;