export const FETCH_BROADCAST_EMAIL_STATS_REQUEST =
  "FETCH_BROADCAST_EMAIL_STATS_REQUEST";
export const FETCH_BROADCAST_EMAIL_STATS_RESPONSE =
  "FETCH_BROADCAST_EMAIL_STATS_RESPONSE";

export const fetchBroadcastEmailStats = (projectKey) => ({
  type: FETCH_BROADCAST_EMAIL_STATS_REQUEST,
  projectKey,
});

export const broadcastEmailStatsResponse = (broadcastEmailStatsResponse) => ({
  type: FETCH_BROADCAST_EMAIL_STATS_RESPONSE,
  broadcastEmailStatsResponse,
});
