import * as R from 'ramda'
import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  max-width: 53rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${mq.sm} {
    display: block;
    text-align: unset;
    flex-direction: unset;
    justify-content: unset;
    align-items: unset;
  }
`

export const Highlight = styled.span`
  color: ${R.path(['theme', 'color', 'baliHai'])};
`
