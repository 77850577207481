import { call, put, select } from "redux-saga/effects";
import { store } from "../app.js";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  peopleScrollResponse,
  peopleNewListResponse,
  PEOPLE_LIST_FAILED,
  createPeopleGroupResponse,
  getPeopleGroupsResponse,
  updatePeopleGroupResponse,
  getPeopleGroupCountResponse,
  updateGroupSettingsResponse,
  updateFieldSettingsResponse,
  getPeopleCountResponse,
  exportContactResponse,
  sendBroadcastMessageResponse,
  importContactResponse,
  fetchPresenceByUserIds,
  getPeopleGroupCountRequest,
  deleteGroupResponse,
  PEOPLE_LIST_REQUEST,
  updateIsLastBatch,
  getIPActivityResponse,
  updateIPActivityResponse,
  getMatchingSegmentsResponse,
  getMatchingSegmentsFailed,
  broadcastEventStatsResponse,
  broadcastEventStatsScroll
} from "../actions/PeopleAction";
import {
  updatePeopleScrollId,
  updatePeopleESQuery,
} from "../actions/PeopleESQueryAction";
import { updatePeopleCount } from "../actions/BroadcastESQueryActions";
import { peopleESDefaultQuery, CAMPAIGN_STATUS_TO_ID, RATE_LIMIT_MESSAGE  } from "../commons/Constants.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import {
  changeGroupsQueryByReference,
  createGroupsFromTags,
  getProjectKey,
  downloadCSV,
  arraysEqual
} from "../commons/Utility.js";
import * as EventsTracking from "../commons/EventsTracking.js";
import { browserHistory } from "react-router";
import Papa from "papaparse";
import {
  sentTestMailsResponse,
  addCampaign,
  processingCampaign,
} from "../actions/BroadcastMessageStatsAction.js";
import { getReplacedMessage, getFallBackValues } from "../commons/Utility";

export function* getPeoplesList(esObj) {
  console.log("Inside getVisitorDetails", JSON.stringify(esObj.query));
  const url = "/contact/search/" + esObj.query.projectId;

  try {
    //yield put( VoiceboxActions.showVoicebox( { message : 'Fetching people'+( esObj.isForBroadcastPopup ? ' count' : '' )+'...' } )  );
    yield put({ type: "PEOPLE_LIST_REQUEST" });
    let response = yield call(Ajax.post, url, esObj.query);

    if (esObj.isForBroadcastPopup) {
      yield put(VoiceboxActions.hideVoicebox());
      response = response.data.peoples;
      yield put(updatePeopleCount(response.hits.total.value));
    } else {
      let peopleResponse = {};
      let peopleMap = {};
      let customField = {};
      let peopleKeys = [];
      let fieldSettings = response.data.fieldSettings;
      response = response.data.peoples;

      let scrollCursor = '';
      response.hits.hits.map((people, i) => {
        scrollCursor = JSON.stringify(people["sort"]);
        people = people["_source"];
        people = Object.assign(people, people.customFields);
        peopleMap[people.key] = people;
        peopleKeys.push(people.key);
      });
      console.log("Scroll_Id --->" + scrollCursor); 

      peopleResponse.peopleMap = peopleMap;
      peopleResponse.totalPeople = response.hits.total.value;
      peopleResponse.fieldSettings = fieldSettings;
      if (esObj.isSingleRequested) {
        peopleResponse.isSingleRequested = true;
        esObj.scrollId = "";
        esObj.query.projectId = "";
        esObj.query.match = {};
      }

      yield put(VoiceboxActions.hideVoicebox());
      yield put(updatePeopleScrollId(scrollCursor));
      yield put(peopleNewListResponse(peopleResponse));
      if (peopleKeys.length > 0)
        yield put(fetchPresenceByUserIds(peopleKeys, esObj.query.projectId));
    }
  } catch (e) {
    console.error(e);
    yield put({ type: "PEOPLE_LIST_FAILED" });
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* getDetailedStatsPeople(delivered) {
  const url = "/broadcast/statistics";
  let response = yield call(Ajax.post, url, delivered.dataResponse);
  yield put(broadcastEventStatsResponse(response.data.peopleEntities));
  yield put(broadcastEventStatsScroll(response.data.statsCursor));
}

export function* getPeoplesListByScroll(scrollObj) {
  console.log("Inside getPeoplesListByScroll", scrollObj);
  const url = "/contact/search/scroll/" + scrollObj.scrollReq.projectId;
  let projectId = scrollObj.scrollReq.projectId;

  try {
    let scrollQuery = {
      ...scrollObj.scrollReq.peopleESQuery.query,
    };
    console.log("scrollObj.scrollReq.scrollId", scrollObj.scrollReq.scroll_id);
    scrollQuery["search_after"] = JSON.parse(scrollObj.scrollReq.scroll_id);
    let response = yield call(Ajax.post, url, scrollQuery);
    let peopleResponse = {};
    let peopleMap = {};
    let peopleKeys = [];
    response = response.data;

    if ( response.error &&
        response.error.type == "illegal_argument_exception"
    ) {
      console.warn("Could not scroll due to malformed query:", scrollQuery);
      yield put(updatePeopleScrollId(''))
      yield put(peopleScrollResponse({ peopleMap : {}}));
      return;
    }

    let scrollCursor = {}
    response.hits.hits.map((people, i) => {
      scrollCursor = JSON.stringify(people["sort"]);
      people = people["_source"];
      people = Object.assign(people, people.customFields);
      peopleMap[people.key] = people;
      peopleKeys.push(people.key);
    });

    peopleResponse.peopleMap = peopleMap;
    peopleResponse.totalPeople = response.hits.total.value;
    console.log("scrollCursor --->" + scrollCursor); 

    if(scrollObj.scrollReq.scroll_id == scrollCursor)
      yield put(updatePeopleScrollId(''))
    else 
      yield put(updatePeopleScrollId(scrollCursor));
    yield put(peopleScrollResponse(peopleResponse));
    if (peopleKeys.length > 0)
      yield put(fetchPresenceByUserIds(peopleKeys, projectId));
  
  } catch (e) {
    console.log(e.name);
    yield put({ type: "PEOPLE_LIST_FAILED" });
    yield put(handleGlobalError(e));
  }
}

export function* requestdeleteScrollId(scrollReq) {
  console.log("Inside requestdeleteScrollId", scrollReq);
  const url =
    "/contact/search/scroll/" +
    scrollReq.scrollObj.projectId +
    "/" +
    scrollReq.scrollObj.scroll_id;
  console.log("Inside requestdeleteScrollId", url);
  try {
    let response = yield call(
      Ajax.deleteReq,
      url,
      scrollReq.scrollObj,
      "application/json"
    );
    console.log(" the Response is " + JSON.stringify(response));
  } catch (e) {
    console.error(e.name);
    yield put(handleGlobalError(e));
  }
}

export function* createPeopleGroup(request) {
  let groupCreationURL = "/group/create";
  let groupCreationResp = {};

  try {
    //yield put( VoiceboxActions.showVoicebox( { message : 'creating group...' } ) );
    groupCreationResp = yield call(
      Ajax.post,
      groupCreationURL,
      request.groupInfo
    );

    yield put(VoiceboxActions.hideVoicebox());
    yield put(createPeopleGroupResponse(groupCreationResp.data));

    let projectkey = getProjectKey(request.groupInfo.projectId);
    let groupId = Object.keys(groupCreationResp.data)[0];
    browserHistory.push(`/app/${projectkey}/contacts/dashboard/${groupId}`);

    EventsTracking.trackPeopleGroupCreation(request.groupInfo);

    yield* getPeopleCounts({
      peopleGroupsList: Object.values(groupCreationResp.data),
      projectId: request.groupInfo.projectId,
    });
  } catch (error) {
    yield put(VoiceboxActions.hideVoicebox());
    console.error(error);
    yield put(handleGlobalError(error));
  }
}

export function* updatePeopleGroup(request) {
  let groupUpdationURL = "/group/update";
  let groupUpdationResp = {};

  try {
    groupUpdationResp = yield call(
      Ajax.post,
      groupUpdationURL,
      request.existingGroupInfo
    );

    yield put(VoiceboxActions.hideVoicebox());
    yield put(updatePeopleGroupResponse(groupUpdationResp.data));

    yield* getPeopleCounts({
      peopleGroupsList: Object.values(groupUpdationResp.data),
      projectId: request.existingGroupInfo.projectId,
    });
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* getPeopleGroups(request) {
  const url = "/group/get/" + request.projectId;
  let peopleGroupsMap = {};
  let peopleGroupsList = [];

  try {
    //yield put( VoiceboxActions.showVoicebox( { message : 'Fetching Groups...' } ) );
    let response = yield call(Ajax.post, url, peopleESDefaultQuery);
    yield put(VoiceboxActions.hideVoicebox());

    response.data.peopleGroups.map((peopleGroup) => {
      peopleGroupsMap[peopleGroup.key] = peopleGroup;
    });
    //peopleGroupsMap = { ...peopleGroupsMap , ...createGroupsFromTags( response.data.peopleTags ) };
    peopleGroupsMap = { ...peopleGroupsMap };

    yield put(getPeopleGroupsResponse(peopleGroupsMap));
    peopleGroupsList = Object.values(peopleGroupsMap);

    yield* getPeopleCounts({
      peopleGroupsList,
      projectId: request.projectId,
      isForEmailCampaign: request.isForEmailCampaign,
    });
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
    yield put(handleGlobalError(e));
  }
}

export function* getMatchingSegments(request) {
  const url = `/contact/get/matching/segments/${request.visitorId}/${request.projectId}`
  let peopleGroupsMap = {};
  let matchingGroupsByVisitorId = {};
  try {
    let response = yield call(Ajax.post, url, {});
    yield put(VoiceboxActions.hideVoicebox());
    response.data.map((peopleGroup) => {
      peopleGroupsMap[peopleGroup.key] = peopleGroup;
    });
    peopleGroupsMap = { ...peopleGroupsMap };
    matchingGroupsByVisitorId = { [request.visitorId] : peopleGroupsMap}
    yield put(getMatchingSegmentsResponse(matchingGroupsByVisitorId));  
  } catch (e) {
    console.error(e);
    yield put(getMatchingSegmentsFailed());
    yield put(handleGlobalError(e));
  }
}

export function* getPeopleCounts(request) {
  let groupsSubList = [];
  let peopleGroupsMap = {};
  let peopleGroupsList = [];
  let isFetched = false;
  const isForEmailCampaign = request.isForEmailCampaign;

  try {
    peopleGroupsMap = changeGroupsQueryByReference(
      request.peopleGroupsList,
      store.getState().CustomFieldsReducer,
      request.projectId
    );
    peopleGroupsList = Object.values(peopleGroupsMap);
    if (isForEmailCampaign)
      peopleGroupsList = peopleGroupsList.map((group) => {
        let completeQuery = JSON.parse(group.completeQuery);
        completeQuery.exists.field.push("email");
        group.completeQuery = JSON.stringify(completeQuery);
        return group;
      });

    do {
      groupsSubList = peopleGroupsList.splice(0, 5);
      if (peopleGroupsList.length == 0) {
        isFetched = true;
        yield put(getPeopleGroupCountRequest(groupsSubList, request.projectId, isFetched));
      }
      else
        yield put(getPeopleGroupCountRequest(groupsSubList, request.projectId));
    } while (peopleGroupsList.length > 0);
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* getPeopleCountByGroup(requestMap) {
  const peopleCountAPI = "/contact/get/count/" + requestMap.projectId;

  try {
    let { groupsSubList, isFetched} = requestMap;
    let response = yield call(Ajax.post, peopleCountAPI, groupsSubList);
    let countResponse = response.data;

    yield put(getPeopleGroupCountResponse(countResponse , isFetched));

      // if (isFetched) 
      //   yield put(updateIsLastBatch(isFetched))
    
  } catch (e) {
    console.error(e);
    yield put(VoiceboxActions.hideVoicebox());
  }
}

export function* updateGroupSettings(settingsInfo) {
  console.log("Inside create poeple group", settingsInfo);
  const url = "/settings/group/update";

  try {
    let response = yield call(Ajax.post, url, settingsInfo.groupSettings);
    response = response.data;

    console.info("People group api response =>", response);

    yield put(VoiceboxActions.hideVoicebox());
    yield put(updateGroupSettingsResponse(response));
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* updateFieldSettings(settingsInfo) {
  console.log("Inside create poeple group", settingsInfo);
  const url = "/settings/field/update";

  try {
    let response = yield call(Ajax.post, url, settingsInfo.fieldSettings);
    response = response.data;

    console.info("People group api response =>", response);

    yield put(VoiceboxActions.hideVoicebox());
    yield put(updateFieldSettingsResponse(response));
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* exportContacts(exportContactInfo) {
  console.log("Inside export contacts", exportContactInfo.esQueryInfo);
  const url = "/contact/export/" + exportContactInfo.esQueryInfo.projectId;
  try {
    yield put(VoiceboxActions.showVoicebox({ message: "Exporting" }));
    let response = yield call(
      Ajax.post,
      url,
      exportContactInfo.esQueryInfo.query
    );
    response = response.data;

    console.info("export contact api response  =>", response);

    if (response && response.fields && response.data) {
      let csvData = Papa.unparse({
        fields: response.fields,
        data: response.data,
      });
      let csv = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      let csvUrl = window.URL.createObjectURL(csv);
      downloadCSV(csvUrl, "Contacts.csv");
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Exporting",
          dismissAfter: 300,
        })
      );
    } else if (response && response.message === "Too large data") {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "CSV has been sent to your mail",
          dismissAfter: 3000,
        })
      );
    }
    yield put(exportContactResponse(response));
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* sendTestBroadcastEmail(data) {
  console.log("sending test email broadcast ->", data);
  const url = "/broadcast/test/email/" + data.projectId;
  try {
    let response = yield call(Ajax.post, url, data.testMailData);
    console.log("response: ", response);
    yield put(sentTestMailsResponse());
  } catch (e) {
    yield put(sentTestMailsResponse());
    let { response } = e;
    if (response && response.data && response.data.message === RATE_LIMIT_MESSAGE.EXCEED_LIMIT) {
      console.log("Exceeded your Test Email Sending Limit");
    } else {
      console.error(e);
      yield put(handleGlobalError(e));
    }
  }
}

export function* sendBroadcastMessage(data) {
  console.log("sending broadcast message->", data);
  const url = "/broadcast/message/" + data.projectId;
  let projectkey = getProjectKey(data.projectId);
  yield put(processingCampaign(true));
  try {
    if (data.sendBroadcastMessageData.isPublishingForFirstTime) {
      yield put( VoiceboxActions.showVoicebox({ message: "Publishing Campaign" }) );
    } else {
      yield put( VoiceboxActions.showVoicebox({ message: "Saving Campaign" }) );
    }
    data.sendBroadcastMessageData['filterQuery'] = JSON.stringify(data.sendBroadcastMessageData['filterQuery']);
    let response = yield call(Ajax.post, url, data.sendBroadcastMessageData);
    EventsTracking.trackBroadCastMessageSent(data.sendBroadcastMessageData);
    response = response.data;
    let { message } = Object.assign({}, response);
    response.fallBackValues = getFallBackValues(message.value);
    response.message.value = getReplacedMessage(response.message.value);
    yield put(addCampaign(response, data.sendBroadcastMessageData.key));
    
    if (data.sendBroadcastMessageData.isPublishingForFirstTime) {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Campaign Published Successfully",
          dismissAfter: 3000,
        })
      );
    } else {
      yield put(
        VoiceboxActions.showVoicebox({
          message: "Campaign Saved Successfully",
          dismissAfter: 3000,
        })
      );
    }

    browserHistory.push(`/app/${projectkey}/campaign/all`);
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
  finally{
    yield put(processingCampaign(false));
  }
}

export function* sendBroadcastEmail(data) {
  console.log("sending broadcast message->", data);
  const url = "/broadcast/email/" + data.projectId;

  try {
    //showVoiceboxyield put( VoiceboxActions.showVoicebox( { message :  'sending message...' } )  );
    let response = yield call(Ajax.post, url, data.sendBroadcastMessageData);
    response = response.data;
    EventsTracking.trackBroadCastMailSent(data.sendBroadcastMessageData);
    //yield put( VoiceboxActions.showVoicebox( { message :  'Message Sent Successfully...' , dismissAfter : 3000} )  );
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}
export function* sendTestEmail(data) {
  console.log("sending test email->", data);
  const url = "/broadcast/email/test/" + data.projectId;

  try {
    //yield put( VoiceboxActions.showVoicebox( { message :  'Sending test email...' } )  );
    let response = yield call(Ajax.post, url, data.sendMessageData);
    response = response.data;
    // if(response.success == true)
    // 	yield put( VoiceboxActions.showVoicebox( { message : 'Email Sent Successfully...' , dismissAfter : 3000} )  );
    // else
    // 	yield put( VoiceboxActions.showVoicebox( { message : 'Failed Sending Email...' , dismissAfter : 3000} )  );
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* importContacts(importContactInfo) {
  const url = "/contact/import/" + importContactInfo.projectId;
  let payload = {};
  payload.contactList = importContactInfo.contacts;
  try {
    yield put(
      VoiceboxActions.showVoicebox({
        message:
          "Uploading",
        showCloseIcon: true
      })
    );
    let response = yield call(Ajax.post, url, payload);
    response = response.data;

    EventsTracking.trackImportContacts({
      projectId: importContactInfo.projectId,
    });
    console.info("import contact api response =>", response);

    if(payload.contactList.length <= 100)
      yield put(VoiceboxActions.showVoicebox({ message: "Import Successful", dismissAfter: 3000 }));
    yield put(importContactResponse(response));
  } catch (e) {
    console.error(e);
    yield put(handleGlobalError(e));
  }
}

export function* deletePeopleGroup(request) {
  let url = "/group/delete/" + request.groupId;

  try {
    //yield put( VoiceboxActions.showVoicebox( { message : 'Deleting Group...' } ) );
    let response = yield call(Ajax.deleteReq, url);
    response = response.data;

    yield put(VoiceboxActions.hideVoicebox());
    yield put(deleteGroupResponse(response.groupId));

    let mainGroupId = request.mainGroupId;
    let projectkey = getProjectKey(request.projectId);
    browserHistory.push(`/app/${projectkey}/contacts/dashboard/${mainGroupId}`);
  } catch (error) {
    yield put(VoiceboxActions.hideVoicebox());
    console.error(error);
    yield put(handleGlobalError(error));
  }
}

export function* updateIPActivityStatus(ipStatusInfo) {
  let url = "/contact/ip/status";
  try {
    let response = yield call(Ajax.put, url, ipStatusInfo);
    response = response.data;
    yield put(updateIPActivityResponse(response));
    yield put(VoiceboxActions.showVoicebox({ message: "Update Successful", dismissAfter: 3000 }));
  } catch(err) {
    console.error(err);
  }
}

export function* getIPActivityStatus({projectId}) {
  let url = "/contact/ip/status/"+projectId;
  try {
    let response = yield call(Ajax.get, url);
    response = response.data || [];
    let ipStatus = {};
    response.forEach( status => { ipStatus[status.ip] = status; });
    yield put(getIPActivityResponse(projectId, ipStatus))
  } catch(err) {
    console.error(err);
  }
}
