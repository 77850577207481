/**
 * Created by melvin on 06/04/17.
 */
import {
  SIGN_UP_REQUESTED,
  SIGN_UP_RESPONSE,
  SIGN_UP_FAILED,
  CREATE_LEAD_REQUESTED,
  CREATE_LEAD_RESPONSE,
  CREATE_LEAD_FAILED,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_RESPONSE,
  CHECK_EMAIL_FAILED,
  UPDATE_LEAD_RESPONSE,
  SETUP_ACCOUNT_REQUEST,
  SETUP_ACCOUNT_RESPONSE,
} from "../actions/SignUpAction";

const SignUpReducer = (
  state = {
    isLoading: false,
    isLoaded: false,
    data: {},
    isCreatingLead: false,
    isLeadCreated: false,
    leadData: {},
    isSettingUpAccount: false,
    hasSetUpAccount: false,
  },
  action
) => {
  switch (action.type) {
    //isLoaded-> whether the sign api call is success with signing up
    //isLoading-> Api call is in progress

    case SIGN_UP_REQUESTED:
      return {
        ...state,
        data: action.signUpData,
        isLoading: true,
      };
    case SIGN_UP_RESPONSE:
      return {
        ...state,
        data: action.signUpResponseData,
        isLoading: false,
        isLoaded: true,
      };
    case SIGN_UP_FAILED:
      return {
        ...state,
        data: action.signUpFailedData,
        isLoading: false,
        isLoaded: false,
      };
    case CREATE_LEAD_REQUESTED:
      return {
        ...state,
        leadData: action.createLeadData,
        isCreatingLead: true,
      };
    case CREATE_LEAD_RESPONSE:
      return {
        ...state,
        leadData: Object.assign(
          {},
          state.leadData,
          action.createLeadResponseData
        ),
        isCreatingLead: false,
        isLeadCreated: true,
      };
    case UPDATE_LEAD_RESPONSE:
      return {
        ...state,
        leadData: Object.assign({}, action.updateLeadResponseData),
        isCreatingLead: false,
        isLeadCreated: true,
      };
    case CREATE_LEAD_FAILED:
      return {
        ...state,
        leadData: Object.assign(
          {},
          state.leadData,
          action.createLeadFailedData
        ),
        isCreatingLead: false,
        isLeadCreated: false,
      };
    case SETUP_ACCOUNT_REQUEST:
      return {
        ...state,
        accountData: action.accountData,
        isSettingUpAccount: true,
        hasSetUpAccount: false,
      };
    case SETUP_ACCOUNT_RESPONSE:
      return {
        ...state,
        accountData: action.accountData,
        isSettingUpAccount: false,
        hasSetUpAccount: true,
      };
  }
  return state;
};
export default SignUpReducer;
