import * as actions from 'src/features/wizard/install/store/actions'
import {
  IEmailInstructions,
  IInstallState,
} from 'src/features/wizard/install/store/types'
import { upsertListItemById } from 'src/utils/state/operators'

export const initialState = {
  testConnectionResponse: {
    ok: undefined,
    isLoading: false,
  },
  domainUrl: 'https://',
  emailInstructions: [],
}

export const installReducer = (
  state: IInstallState = initialState,
  action: actions.IInstallActions
) => {
  switch (action.type) {
    case actions.CLEAR_EMAIL_INSTRUCTIONS:
      return {
        ...state,
        emailInstructions: [],
      }
    case actions.REQUEST_TEST_CONNECTION:
      return {
        ...state,
        testConnectionResponse: {
          ok: undefined,
          isLoading: true,
        },
        domainUrl: action.payload.url,
      }
    case actions.REQUEST_TEST_CONNECTION_SUCCESS:
      return {
        ...state,
        testConnectionResponse: {
          ok: action.payload.ok,
          isLoading: false,
        },
      }
    case actions.REQUEST_EMAIL_INSTRUCTIONS:
      return {
        ...state,
        emailInstructions: upsertListItemById<IEmailInstructions>(
          action.payload.id,
          {
            id: action.payload.id,
            ok: undefined,
            isLoading: true,
          }
        )(state.emailInstructions),
      }
    case actions.REQUEST_EMAIL_INSTRUCTIONS_FINISHED:
      return {
        ...state,
        emailInstructions: upsertListItemById<IEmailInstructions>(
          action.payload.id,
          {
            id: action.payload.id,
            ok: action.payload.ok,
            error: action.payload.error,
            isLoading: false,
          }
        )(state.emailInstructions),
      }
    case actions.INITIALIZE:
      return {
        ...state,
        domainUrl: action.payload.domainUrl,
      }
    case actions.SYNC_EMAIL_INSTRUCTIONS:
      return {
        ...state,
        emailInstructions: upsertListItemById<IEmailInstructions>(
          action.payload.id,
          {
            id: action.payload.id,
            ok: undefined,
            value: action.payload.email,
            error: undefined,
            isLoading: false,
          }
        )(state.emailInstructions),
      }
    case actions.REQUEST_UPDATE_PREVIEW:
      return {
        ...state,
        domainUrl: action.payload.websiteUrl,
      }
    default:
      return state
  }
}
