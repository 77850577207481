import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div``

export const InstallButtonsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.2rem;
  margin-top: 1.6rem;
  margin-bottom: 0.8rem;
`

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.4rem;

  ${mq.md} {
    margin-top: 3.2rem;
  }
`
