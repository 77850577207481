import React, { Component } from "react";
import { browserHistory } from "react-router";
import {
  CUSTOMER_NAME_SEARCH,
  COMPANY_NAME_SEARCH,
  ADVANCED_SEARCH,
  TAG_NAME_SEARCH,
  SEARCH_LABEL,
} from "../../../commons/Constants.js";
import { Event } from "../../../commons/EventsTrackingGoogleAnalytics";
import { store } from "../../../app.js";
import { hideConversationFilter, updateSearchTextCursor } from "../../../actions/ConversationInfoAction";
import {
  getValueFromNestedObject,
  setSearchTextAndStoreItInLocalStorage,
} from "../../../commons/Utility.js";

class SearchBar extends Component {
  constructor(props) {
    super();
    this.state = {
      searchTextFilter: "",
      nonEncryptedSearchTextFilter: "",
    };
    this.changeText = this.changeText.bind(this);
    this.triggerEvent = this.triggerEvent.bind(this);
    this.triggerGAEvent = this.triggerGAEvent.bind(this);
    this.autofocusRef = this.getRef();
  }
  getRef() {
    return React.createRef();
  }

  componentDidUpdate() {
    if( this.props.dashboardData.ConversationReducer && this.props.dashboardData.ConversationReducer.showConversationFilter === true){
        if (
          this.autofocusRef.current !== null &&
          typeof this.autofocusRef.current.focus === "function"
        ) {
          this.autofocusRef.current.focus();
        }
    }
  }

  changeText(event) {
    this.setState({
      searchTextFilter: event.target.value,
      nonEncryptedSearchTextFilter: event.target.value,
    });
  }

  getSearchTypeText() {
    const domContainer = document.querySelector(
      ".cs-my-conversations-filter strong"
    );
    return getValueFromNestedObject(domContainer, "textContent");
  }

  redirectToConversationPage(
    searchText,
    searchType,
    AdvancedFilterSearch,
    projectId
  ) {
    store.dispatch(hideConversationFilter());
    setSearchTextAndStoreItInLocalStorage(searchText, searchType);
    browserHistory.push({
      pathname:
        "/app/" + projectId + `/chat/search/D/${AdvancedFilterSearch}/N/All`,
      state: {
        searchTextFilter: this.state.searchTextFilter.trim(),
        nonEncryptedSearchTextFilter:
          this.state.nonEncryptedSearchTextFilter.trim(),
      },
    });
  }

  triggerGAEvent(projectId, name) {
    Event(
      `LS-${projectId}`,
      "Search",
      name,
      `${name} Filter Clicked`
    );
  }

  triggerEvent(event) {
    let searchText = this.state.searchTextFilter.trim();
    if (
      event.keyCode === 13 &&
      (searchText.length > 2 ||
        (this.props.isTagSearch && searchText.length > 1))
    ) {
      let projectId = this.props.dashboardData.params.projectkey;
      if (this.getSearchTypeText() === SEARCH_LABEL.CUSTOMER) {
        this.triggerGAEvent(projectId, "Customer Name");
        this.redirectToConversationPage(
          searchText,
          CUSTOMER_NAME_SEARCH,
          ADVANCED_SEARCH.CUSTOMER,
          projectId
        );
      } else if (this.getSearchTypeText() === SEARCH_LABEL.COMPANY) {
        this.triggerGAEvent(projectId, "Company Name");
        this.redirectToConversationPage(
          searchText,
          COMPANY_NAME_SEARCH,
          ADVANCED_SEARCH.COMPANY,
          projectId
        );
      } else if (this.getSearchTypeText() === SEARCH_LABEL.TAG) {
        store.dispatch(updateSearchTextCursor(new Date().getTime()));
        this.redirectToConversationPage(
          searchText,
          TAG_NAME_SEARCH,
          ADVANCED_SEARCH.TAG,
          projectId
        );
        this.triggerGAEvent(projectId, "Tag Name");
      } else {
        Event(
          "LS-" + projectId,
          "Search",
          "Global Search",
          "Global Search Clicked"
        );
        store.dispatch(hideConversationFilter());
        browserHistory.push({
          pathname: "/app/" + projectId + "/chat/search/D/T/N/All",
          state: this.state,
        });
      }
    }
  }

  render() {
    return (
      <input
        type="text"
        placeholder="Search all conversations"
        value={this.state.searchTextFilter}
        onChange={this.changeText}
        onKeyDown={this.triggerEvent}
        ref={this.autofocusRef}
        autoFocus
      />
    );
  }
}

export default SearchBar;
