import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createConversationId,
  generateMessageId,
  getDefaultConversationObject,
  getProjectKey,
  reArrangeVistiorList,
} from "../commons/Utility.js";
import {
  addTempConversation,
  updateConversationResponse,
} from "../actions/ConversationInfoAction";
import { browserHistory } from "react-router";
import { removeVisitorToQueue } from "../actions/VisitorQueueActions";
import QueueComponent from "../components/Queue/QueueComponent.jsx";
import { MESSAGE_FROM, MESSAGE_TYPE } from "../commons/Constants";
import { sendMessage } from "../actions/MessageAction";
import { updateOpenConversationCountRealtime } from "../actions/StaffAction";
import { updateUnassingedConversationCountRealtime } from "../settings/actions/ProjectAction";
import { usePrevious } from "../settings/hooks/usePrevious";

const QueueContainer = (props) => {
  const { conversationQueueReducer, projectReducer, visitorQueueState, conversationReducer, userReducer , messageReducer} = useSelector(
    (state) => ({
      visitorQueueState: state.VisitorQueueReducer,
      conversationReducer: state.ConversationInfoReducer,
      userReducer: state.UserReducer,
      messageReducer : state.MessageReducer,
      conversationQueueReducer: state.ConversationQueueReducer,
      projectReducer :state.ProjectReducer
    })
  );

  const dispatch = useDispatch();
  const prevIsFetching = usePrevious(conversationReducer.isFetching);

  const getQueuedConversationsInfo = () => {
    let {
      conversationMap,
      contactsMap,
    } = conversationQueueReducer.conversationObject;

    let { queuedConversations } = conversationQueueReducer;

    let conversationList = [];
    queuedConversations.forEach((conversationId) => {
      let conversation = conversationMap[conversationId];
      let visitorId =
        conversation && conversation.visitorId ? conversation.visitorId : null;
      let contact =
        visitorId && contactsMap[visitorId] ? contactsMap[visitorId] : {};
      if (contact && Object.keys(contact).length > 0)
        conversationList.push({
          conversationInfo: conversation || {},
          contactInfo: contact,
        });
    });
    return conversationList;
  };

  const { projectColorMap, isFetching } = visitorQueueState;


  const getIsConversationQueue = () => {
    let prevPath = localStorage.getItem("prevPath") || "";
    if(prevPath === "queue" && !(document.location.pathname.includes("guests"))){
      return true;
    }
    else 
      return document.location.pathname.includes("queue");
  }

  let isConversationQueue = getIsConversationQueue();
  let queuedConversationInfo = getQueuedConversationsInfo();
  const redirectToConverstion = (conversationKey, projectId) => {
    let { chatStatus, sortBy, assignedTo, visitorOption } = props.params;
    let projectKey = projectId.replace("LS-", "");
    browserHistory.push({
      pathname:
        "/app/" +
        projectKey +
        "/chat/dashboard/" +
        visitorOption +
        "/" +
        chatStatus +
        "/" +
        sortBy +
        "/" +
        assignedTo +
        "/" +
        conversationKey,
    });
  };

  const createNewConversation = (element) => {
    let projectId = element.currentTarget.dataset.project_id;
    let visitorId = element.currentTarget.dataset.visitor_id;
    let conversationMap = Object.assign(
      {},
      conversationReducer.conversationObject.conversationMap
    );
    let tempConversationId = createConversationId();
    while (conversationMap[tempConversationId])
      tempConversationId = createConversationId();
    console.log("tempConversationId : ", tempConversationId);
    let newConversation = getDefaultConversationObject(
      tempConversationId,
      projectId,
      visitorId,
      userReducer.data.id
    );
    newConversation.conversationType = "VISITOR_QUEUE_CONVERSATION";
    dispatch(addTempConversation(newConversation));
    dispatch(removeVisitorToQueue(visitorId));
    redirectToConverstion(newConversation.key, newConversation.projectId);
  };

  const acceptChat = (conversationId) => {
    let {conversationMap, contactsMap} = conversationQueueReducer.conversationObject;
    let conversation = conversationMap[conversationId];
    if (!conversation) return;
    let userInfo = userReducer.data;
    sentChatAssignMessage(conversation, userInfo);
    dispatch(updateConversationResponse(conversation, contactsMap));
    browserHistory.push({
      pathname:
        "/app/" +
        getProjectKey(conversation.projectId) +
        "/chat/dashboard/D/O/N/" +
        conversation.assignedTo +
        "/" +
        conversation.key,
    });
  };

  const sentChatAssignMessage = (conversation, userInfo) => {
    let assigningMessage = getChatMessage(
      conversation,
      userInfo,
      MESSAGE_TYPE.chat_assigned
    );
    sendStaffJoiningMessage(conversation, userInfo);
    assigningMessage["isAlreadyAssigned"] = false;
    conversation.assignedTo = userInfo.id;
    conversation.isModified = false;
    conversation.chatStatus = "OPEN";
    conversation.overflowStatus = "NOT_OVERFLOWED";
    dispatch(updateOpenConversationCountRealtime(assigningMessage));
    dispatch(updateUnassingedConversationCountRealtime(assigningMessage));
    let meta = {
      echoChannels: ["private/agent/" + conversation.projectId],
      triggerWebHook: true
    };
    dispatch(sendMessage(assigningMessage, meta, false));
  };

  const sendStaffJoiningMessage = (conversation, userInfo) => {
    let joiningMessage = getChatMessage(
      conversation,
      userInfo,
      MESSAGE_TYPE.staff_joined_conversation
    );
    dispatch(
      sendMessage(joiningMessage, {
        echoChannels: [
          "private/agent/LS-" + getProjectKey(joiningMessage.projectId),
        ],triggerWebHook: true,
      })
    );
  };

  const getChatMessage = (conversation, userData, type) => {
    let agentId = userData.id;
    let { projectId, visitorId } = conversation;

    return {
      type: type,
      channel: "private/visitor/" + projectId + "/" + visitorId,
      message: "",
      actionTo: agentId,
      senderId: agentId,
      conversationId: conversation.key,
      messageFrom: MESSAGE_FROM.AGENT,
      messageId: generateMessageId(),
      visitorId: visitorId,
      projectId: projectId,
      messageStatus: "CHAT_SENDING",
      interactionType: "DASH_BOARD",
      agentDetails: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        photoUrl: userData.photoID,
        aliasName: userData.aliasName,
      },
    };
  };



  const getLastMessageOfConversation = ( conversationId ) => {
    let {messageMap} = messageReducer;
    let messagesByConversation = messageMap[conversationId] || {};
    let key = Object.keys(messagesByConversation)[0]
    let lastMessage = messagesByConversation[key] || {};
    console.log("Getting the last message from the Queue :" , lastMessage.message);
    return lastMessage.message;
  }
  return (
    <QueueComponent
      visitorMap={reArrangeVistiorList(visitorQueueState.visitorMap)}
      visitorQueueReducer={visitorQueueState}
      createNewConversation={createNewConversation}
      projectColorMap={projectColorMap}
      isFetching={isFetching}
      isConversationQueue={isConversationQueue}
      queuedConversationInfo={queuedConversationInfo}
      acceptChat={acceptChat}
      isConversationFetching={conversationReducer.isFetching}
      isQueueFetched={conversationQueueReducer.isFetched}
      getLastMessageOfConversation={getLastMessageOfConversation}
      projectList={projectReducer.projectList}
    />
  );
};

export default QueueContainer;