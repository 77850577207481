import React, { FC } from 'react'

export const LogoMini: FC<any> = (props) => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.34824 0.785645C2.53309 0.785645 0.25 3.06873 0.25 5.88388V10.5202C0.25 13.3367 2.53309 15.6184 5.34824 15.6184H16.3666C16.4526 15.6184 16.5387 15.6171 16.6247 15.6118L16.6168 15.6224L20.6324 16.2048C21.0691 16.2683 21.4622 15.9268 21.4635 15.4834L21.4662 10.6724L21.4622 10.6764L21.4649 10.5215V5.88388C21.4649 3.06873 19.1818 0.785645 16.3666 0.785645H5.34824ZM10.15 11.3805C9.75956 11.3805 9.44324 11.0642 9.44324 10.6751C9.44324 10.2846 9.75956 9.96829 10.15 9.96829H18.6351C19.0256 9.96829 19.3432 10.2846 19.3432 10.6751C19.3432 11.0642 19.0256 11.3805 18.6351 11.3805H10.15ZM3.78515 5.73035C3.78515 6.12079 4.10279 6.43712 4.49324 6.43712H12.9784C13.3688 6.43712 13.6851 6.11947 13.6851 5.73035C13.6851 5.33991 13.3688 5.02359 12.9784 5.02359H4.49324C4.10279 5.02359 3.78515 5.34123 3.78515 5.73035Z"
      fill="white"
    />
  </svg>
)
