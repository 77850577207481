import { call, put, select } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import {
  successStaffForProject,
  failedStaffForProject,
  projectForStaffEmailResponse,
  projectForStaffEmailFailed,
} from "../actions/AdminAction";

export function* getStaffForProject(projectInfo) {
  console.log("Fetching staff for admin ->", projectInfo);
  const url = "project/staff/admin/get/" + projectInfo.projectId;

  try {
    yield put(
      showVoicebox({
        message: "Fetching staffs",
        dismissAfter: 2000,
        showFullScreenLoader: true,
      })
    );
    let response = yield call(Ajax.get, url);
    response = response.data;
    if (response.staffs.length > 0) {
      let resultData = [];
      for (let staff of response.staffs) {
        let activeStaffIds = response.activeStaffIds || [];
        if (activeStaffIds.includes(staff.key) && staff.status == "ACTIVE")
          resultData.push({
            option: staff.login,
            imageUrl: staff.photoUrl,
            key: staff.key,
          });
      }
      yield put(successStaffForProject(resultData));
      yield put(hideVoicebox());
    } else {
      //yield put( showVoicebox( { message : 'Invalid ProjectId' , dismissAfter : 3000 } ) );
      yield put(failedStaffForProject("No matched projectId!"));
    }
  } catch (e) {
    //yield put( showVoicebox( { message : 'Invalid ProjectId' , dismissAfter : 3000 } ) );
    yield put(failedStaffForProject("No matched projectId!"));
  }
}

export function* getProjectForStaff(staffInfo) {
  console.log("Fetching projects for staff ->", staffInfo);
  const url = "project/admin/get/" + staffInfo.staffEmail;

  try {
    yield put(
      showVoicebox({
        message: "Searching widgets",
        dismissAfter: 2000,
        showFullScreenLoader: true,
      })
    );
    let response = yield call(Ajax.get, url);
    let projectList = response.data;

    if (projectList.length > 0) {
      let resultData = [];
      for (let project of projectList) {
        if (project.status == "ACTIVE")
          resultData.push({
            option: project.name,
            imageUrl: project.logoUrl,
            key: project.key,
          });
      }
      yield put(projectForStaffEmailResponse(resultData));
      yield put(hideVoicebox());
    } else {
      //yield put( showVoicebox( { message : 'Invalid emailId' , dismissAfter : 3000 } ) );
      yield put(projectForStaffEmailFailed("No matched emailId!"));
    }
  } catch (e) {
    //yield put( showVoicebox( { message : 'Invalid emailId' , dismissAfter : 3000 } ) );
    yield put(projectForStaffEmailFailed("No matched emailId!"));
  }
}
