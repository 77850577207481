import * as R from 'ramda'
import styled from 'styled-components'
import {
  AnimationFadeIn,
  AnimationFadeOut,
} from 'src/features/ui/components/Animation'

export const Container = styled(AnimationFadeOut).attrs({ as: 'ul' })`
  padding: 0;
  margin: 0;
`

export const Item = styled(AnimationFadeIn)`
  display: flex;
  align-items: center;
  list-style-type: none;
  font-family: ${R.path(['theme', 'font', 'family'])};
  color: ${R.path(['theme', 'font', 'codGray'])};
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 4rem;
  font-weight: 700;
  line-height: 4.8rem;
  letter-spacing: 0;

  & + & {
    margin-top: 3.2rem;
  }
`
