import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {setBlockData , getSelectionCustomInlineStyle } from 'draftjs-utils';
import {
  ContentBlock,
  genKey,
  ContentState,
  EditorState,
  Entity,
  Modifier,
  CompositeDecorator,
  SelectionState,
  AtomicBlockUtils,
  KeyBindingUtil,
  getDefaultKeyBinding,
} from "draft-js";
// import { stateFromHTML } from "draft-js-import-html";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import { RichUtils , convertFromHTML  } from "draft-js";
import {convertFromHTML as convertHtml } from 'draft-convert'
import { stateToHTML } from "draft-js-export-html";
import AddContent from "./AddContent";
import { useChatConfigurationEntity } from "../../../app/settings/hooks/useChatConfigurationEntity";
import { showVoicebox } from "../../actions/VoiceboxAction";
const { Map } = require('immutable');
import {getProjectId, getSelectionColorBasedOnUserAgent} from '../../../app/commons/Utility.js';
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import {
  H1NormalIcon,
  H1ActiveIcon,
  BoldActiveIcon,
  BoldNormalIcon,
  ItalicActiveIcon,
  ItalicNormalIcon,
  H2ActiveIcon,
  H2NormalIcon,
  LinkActiveIcon,
  TagNormalIcon,
  TagActiveIcon,
  EditHtmlPopupIcon
} from "../../../app/commons/Icons";
import {
  TemplateActiveIcon,
  TemplateNormalIcon,
} from "../../../app/commons/Icons";
import {
  LinkNormalIcon,
  ImageActiveIcon,
  ImageNormalIcon,
  SlideAttachmentActiveIcon,
  SlideAttachmentNormalIcon,
} from "../../../app/commons/Icons";
import { hexToRgbA, createUUId, stripsHtmlContent } from "../../commons/Utility.js";

import CampaignTemplateButton from "./CampaignTemplateButton.jsx";
// import H1Content from "./H1Content";
import Link from "./Link.jsx";
import FromSpan from "./FromSpan.jsx";
import SenderCard from "./SenderCard.jsx";
import createImagePlugin from "draft-js-image-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import createResizeablePlugin from "draft-js-resizeable-plugin";
import createAlignmentPlugin from "draft-js-alignment-plugin";
import { requestFileUploadUrl } from "../../actions/AttachmentAction";
import { EventEmitter } from "../../settings/commons/event";
import * as PopupActions from "../../../app/actions/PopupAction"
import * as BroadCastMessageStatsActions from "../../../app/actions/BroadcastMessageStatsAction";
import CodeEditor from "./CodeEditor";

const alignmentPlugin = createAlignmentPlugin();
const { AlignmentTool } = alignmentPlugin;
import {
  CenterAlignActiveIcon,
  CenterAlignIcon,
  LeftAlignIcon,
  RightAlignIcon,
} from "../../commons/Icons";

import { StyleSheet , css  } from 'aphrodite';
import { updateCampaignContent } from "../../actions/BroadcastMessageStatsAction";
import { STYLE_TO_ACTIVE_STATUS_CLASSES } from "../../commons/Constants";
const alignmentBlockTypes = ["left", "center", "right" , "h1_left" , "h1_right" , "h1_center"];
const blockMutability = {
  MUTABLE   : "MUTABLE",
  IMMUTABLE : "IMMUTABLE"
}

const nodeNames = {
  link : "a",
  image : 'img',
  figure : 'figure',
  h1 : "h1",
  p: "p"
}

const blockTypes = {
  atomic : "atomic",
  link : "LINK",
  image :"IMAGE",
  "h1": "header-one",
  "h2": "header-two"
}

let imagePlugin;

const CampaignTextEditor = (props) => {

  const [hasFocus , setHasFocus ] = useState(false);
  const [hasSelectionOnBlur , setHasSelectionOnBlur ] = useState(false);
  const [blurEditorState , setBlurEditorState ] = useState(undefined);

  const { campaignDetails, popupDetails, userDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    popupDetails: state.PopupReducer,
    userDetails: state.UserReducer
  }));

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(compositeDecorator)
  );
  const [editorReadOnly, setEditorReadOnly] = useState(false);
  const fromSpanRef = useRef("");
  const senderCardRef = useRef("");
  const {
    onCampaignDataChange,
    updateFallback,
    updateWidthAndHeigt,
    selectedFallBackValue,
  } = props;

  const { isContentUpdated } = campaignDetails.selectedCampaign;
  const [showTemplate, setShowTemplate] = useState(false);

  const [urlValue, setUrlValue] = useState("");
  const [showLinkDropDown, setShowLinkDropDown] = useState(false);

  const dispatch = useDispatch();

  const chatConfiguration = useChatConfigurationEntity(
    "LS-" + props.params.projectkey
  );
  const resizeablePlugin = createResizeablePlugin({
    horizontal: "relative",
    vertical: "relative",
  });

  const [plugins, setPlugins] = useState([resizeablePlugin]);

  const [compositeDecorator, setCompositeDecorator] = useState(
    new CompositeDecorator([
      {
        strategy: handleStrategy,
        component: CampaignTemplateButton,
        props: {
          updateFallback: updateFallback,
          updateWidthAndHeigt: updateWidthAndHeigt,
        },
      },
      {
        strategy: findLinkEntities,
        component: Link,
        props: { projectkey: props.params.projectkey },
      },
    ])
  );

  const [showAlignmentPopup, setShowAlignmentPopup] = useState(false);

  const fallbackDropDownRef = useRef("");
  const linkBoxRef = useRef("");
  const editorRef = useRef(null);
  const linkRef = useRef(null);
  const alignmentRef = useRef(null);
  const [htmlCode, setHtmlCode] = useState("");
  const [htmlCodeInEditor, setHtmlCodeInEditor] = useState("");
  const [isHTMLContentEdited, setIsHTMLContentEdited] = useState(false);
  const [normalCampaignData,setNormalCampaignData] = useState("");
  const [isHTMLEditor, setIsHTMLEditor] = useState(false);
  const [showPasteContent,setShowPasteContent] = useState(true);

  useEffect(() => {
    document.addEventListener("keyup",handleKeyup);
    return () => {
      document.removeEventListener("keyup", handleKeyup);
    }
  },[editorRef.current]);
  useEffect(() => {
    if (showLinkDropDown && linkRef && linkRef.current) {
      linkRef.current.focus();
      onEditorBlur();
    } else {
      onEditorFocus();
    }
  }, [showLinkDropDown]);

  useEffect(() => {
    if (
      editorState &&
      editorState.getCurrentContent().getPlainText().includes("+_add_content")
    )
      setEditorReadOnly(true);
  }, [editorState]);

  useEffect(() => {
    if (htmlCode) {
      if (!isHTMLContentEdited) {
        setIsHTMLContentEdited(true);
        campaignDetails.selectedCampaign.isHTMLTemplateEnabled = true;
      }
      campaignDetails.selectedCampaign.message = htmlCode;
    } else {
      if (isHTMLContentEdited) setIsHTMLContentEdited(false);
    }
  }, [htmlCode]);

  useEffect(() => {
    if (!showPasteContent) reRenderPopup();
  }, [showPasteContent]);

  useEffect(() => {
    const { display, okButton } = popupDetails.popupProps;
    const {message,isHTMLTemplateEnabled} = campaignDetails.selectedCampaign;
    if (!display) {
      setHtmlCode("");
      setHtmlCodeInEditor(message);
      setIsHTMLEditor(isHTMLTemplateEnabled);
      if (!isHTMLEditor) setShowPasteContent(true);
      if (isHTMLContentEdited) {
        setIsHTMLContentEdited(false);
      }
    }
    if (okButton && okButton.isEnable) {
      campaignDetails.selectedCampaign.message = htmlCode;
    }
  }, [popupDetails.popupProps]);

  useEffect(() => {
    if (popupDetails.popupProps.display && htmlCode) {
      if (!userDetails.isActiveOnTab)
        isHTMLEditor
          ? blockHTMLContentFromUpdating()
          : onCampaignDataChange(normalCampaignData);
      else campaignDetails.selectedCampaign.message = htmlCode;
    }
  }, [userDetails.isActiveOnTab]);

  useEffect(() => {
    if (popupDetails.popupProps.display) {
      if (!isHTMLEditor) reRenderPopup();
      else reRenderUpdatedPopup();
    }
  }, [isHTMLContentEdited]);

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        editorState.getCurrentContent(),
        compositeDecorator
      )
    );
  }, [props.agentName]);

  const handleKeyup = (e) => {
    if (
      e.target.className &&
      e.target.className.includes("notranslate public-DraftEditor-content")
    ) {
      setIsContentEdited();
    }
  };

  const handleClickOutside = (e) => {
    if (
      fallbackDropDownRef.current &&
      !fallbackDropDownRef.current.contains(e.target)
    ) {
      setShowTemplate(false);
    }

    if (linkBoxRef.current && !linkBoxRef.current.contains(e.target)) {
      setShowLinkDropDown(false);
    }
    if (
      fromSpanRef.current &&
      !fromSpanRef.current.contains(e.target) &&
      editorReadOnly
    ) {
      if (
        ["H1", "H2", "TEMPLATE", "ALIGNMENT"].includes(
          e.target.dataset.style
        ) == false
      )
        setEditorReadOnly(false);
    }
    if (
      senderCardRef.current &&
      !senderCardRef.current.contains(e.target) &&
      editorReadOnly
    ) {
      setEditorReadOnly(false);
    }

    if (alignmentRef.current && !alignmentRef.current.contains(e.target)) {
      setShowAlignmentPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", onEsc);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", onEsc);
    };
  });

  const StylesList = {
    H1: "header-one",
    H2: "header-two",
    BOLD: "BOLD",
    ITALIC: "ITALIC",
    CODE: "code-block",
    RIGHT: "right",
    LEFT: "left",
    CENTER: "center",
  };

  const HANDLE_REGEX = /First Name|Last Name|Company Name|Full Name/g;

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  }

  const setIsContentEdited = () => {
    if (!isContentUpdated) dispatch(updateCampaignContent());
  };

  // const confirmLink = (linkTarget) => {
    
  //   let selection = editorState.getSelection();
  //   let selectedText = getSelectionText(editorState);

  //   if (blurEditorState) {
  //     // const entityRange = getEntityRange(editorState, currentEntity);
  //     const isBackward = blurEditorState.getSelection().getIsBackward();
  //     if (isBackward) {
  //       selection = selection.merge({
  //         anchorOffset: blurEditorState.getSelection().getEndOffset(),
  //         focusOffset: blurEditorState.getSelection().getStartOffset(),
  //       });
  //     } else {
  //       selection = selection.merge({
  //         anchorOffset:  blurEditorState.getSelection().getStartOffset(),
  //         focusOffset: blurEditorState.getSelection().getEndOffset(),
  //       });
  //     }
  //   }
  //   const entityKey = editorState
  //     .getCurrentContent()
  //     .createEntity('LINK', 'MUTABLE', {
  //       url: linkTarget,
  //     })
  //     .getLastCreatedEntityKey();

  //   let contentState = Modifier.replaceText(
  //     editorState.getCurrentContent(),
  //     selection,
  //     `${selectedText}`,
  //     editorState.getCurrentInlineStyle(),
  //     entityKey
  //   );
  //   let newEditorState = EditorState.push(
  //     editorState,
  //     contentState,
  //     'insert-characters'
  //   );

  //   // insert a blank space after link
  //   selection = newEditorState.getSelection().merge({
  //     anchorOffset: selection.get('anchorOffset') + selectedText.length,
  //     focusOffset: selection.get('anchorOffset') + selectedText.length,
  //   });
  //   newEditorState = EditorState.acceptSelection(newEditorState, selection);
  //   contentState = Modifier.insertText(
  //     newEditorState.getCurrentContent(),
  //     selection,
  //     ' ',
  //     newEditorState.getCurrentInlineStyle(),
  //     undefined
  //   );
  //   onChange(
  //     EditorState.push(newEditorState, contentState, 'insert-characters')
  //   );
    
  // };

  // const confirmLink = (urlValueLink) => {
  //   let selection = editorState.getSelection();
  //   let selectedText = getSelectionText(editorState);
  //   if (urlValueLink) {
  //     const contentState = editorState.getCurrentContent();
  //     const contentStateWithEntity = contentState.createEntity(
  //       "LINK",
  //       "MUTABLE",
  //       { url: urlValueLink }
  //     );
  //     const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  //     const newEditorState = EditorState.set(editorState, {
  //       currentContent: contentStateWithEntity,
  //     });
  //     let newState = RichUtils.toggleLink(
  //       newEditorState,
  //       newEditorState.getSelection(),
  //       entityKey
  //     );

  //   selection = newState.getSelection().merge({
  //       anchorOffset: selection.get('anchorOffset') + selectedText.length,
  //       focusOffset: selection.get('anchorOffset') + selectedText.length,
  //     });
  //     newState = EditorState.acceptSelection(newState, selection);
  //     let contentState_new = Modifier.insertText(
  //       newState.getCurrentContent(),
  //       selection,
  //       ' ',
  //       newState.getCurrentInlineStyle(),
  //       undefined
  //     );

  
      
  //     onChange(
  //       EditorState.push(newState, contentState_new, 'insert-characters')
  //     );
  //   }

  // };

  const confirmLink = (urlValueLink) => {
    let selection = editorState.getSelection();
    if (urlValueLink) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "LINK",
        "MUTABLE",
        { url: urlValueLink }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      let newState = RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      );

      let updatedContentState = Modifier.removeInlineStyle(newState.getCurrentContent() , editorState.getSelection() , "selection_mock");
      let updatedEditorState = EditorState.push(editorState,  updatedContentState , 'change-inline-style');
  
  
  
      let collapsed = selection.merge({
                          anchorOffset: selection.getEndOffset(), 
                          focusOffset: selection.getEndOffset()
                        });
  
      let new_EditorState = EditorState.forceSelection(updatedEditorState, collapsed);
  
      
        setTimeout(() => {
          let newSelection = new_EditorState.getSelection();
  
          let newContentState = Modifier.insertText(
            new_EditorState.getCurrentContent(),
            newSelection,
            ' '
          );
  
          const newEditorStateWithSpace = EditorState.push(
            new_EditorState,
            newContentState,
            'insert-text'
          );
          setEditorState(newEditorStateWithSpace);
         }, 10);
   
  }
}

  // const clearInlineStyles = editorState => {
  //   const styles = [
  //     'selection_mock'
  //   ];
    
  //   const contentWithoutStyles = styles.reduce((newContentState, style) => (
  //     Modifier.removeInlineStyle(
  //       newContentState,
  //       editorState.getSelection(),
  //       style
  //     )
  //   ), editorState.getCurrentContent());
    
  //   return EditorState.push(
  //     editorState,
  //     contentWithoutStyles,
  //     'change-inline-style'
  //   );
  // };

//   const confirmLink = (urlLink) => {
    
//     const entityKey = Entity.create(
//       'LINK',
//       'MUTABLE',
//       {url: urlLink}
//     );

//     let selection = editorState.getSelection();

//     const contentState = Modifier.applyEntity(
//       editorState.getCurrentContent(),
//       selection,
//       entityKey
//     );

//     let linked = EditorState.push(
//       editorState,
//       contentState,
//       'apply-entity'
//     );

//     let updatedContentState = Modifier.removeInlineStyle(linked.getCurrentContent() , editorState.getSelection() , "selection_mock");
//     let updatedEditorState = EditorState.push(editorState,  updatedContentState , 'change-inline-style');



//     let collapsed = selection.merge({
//                         anchorOffset: selection.getEndOffset(), 
//                         focusOffset: selection.getEndOffset()
//                       });

//     let newEditorState = EditorState.forceSelection(updatedEditorState, collapsed);

    
//       setTimeout(() => {
//         let newSelection = newEditorState.getSelection();

//         let newContentState = Modifier.insertText(
//           newEditorState.getCurrentContent(),
//           newSelection,
//           ' '
//         );

//         const newEditorStateWithSpace = EditorState.push(
//           newEditorState,
//           newContentState,
//           'insert-text'
//         );
//         setEditorState(newEditorStateWithSpace);
//        }, 10);
    
// }

  const addLink = () => {
    if (editorReadOnly) return;
    setIsContentEdited();
    // onEditorBlur();
    setShowLinkDropDown(true);
  };

  const setIntialEditorState = (showExpandedContent) => {
    let { typeOfMessage } = campaignDetails.selectedCampaign;
    let defaultHtml = "";
    if (showExpandedContent)
      defaultHtml = `<p><br/></p> <p><br/><p><br/></p> <p><br/></p>`;
    else defaultHtml = `<div> <p>+_add_content</p></div>`;

    if (typeOfMessage == "CHAT" || typeOfMessage == "EMAIL")
      defaultHtml = `${defaultHtml}<div> <p>+_from_</p></div>`;
    else if (typeOfMessage == "ANNOUNCEMENT")
      defaultHtml = `${defaultHtml}<div> <p>+_sender_info</p></div>`;

    const blocksFromHTML = convertFromHTML(defaultHtml);

    if (showExpandedContent) {
      blocksFromHTML.contentBlocks.splice(1, 0, getNewBlock());
      blocksFromHTML.contentBlocks.splice(1, 0, getNewBlock());
    }

    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    let initialEditonState = EditorState.createWithContent(
      content,
      compositeDecorator
    );

    let hiText = Modifier.insertText(
      initialEditonState.getCurrentContent(),
      initialEditonState.getSelection(),
      "Hi ",
      null,
      null
    );
    initialEditonState = EditorState.push(
      initialEditonState,
      hiText,
      "insert-characters"
    );

    let value = typeOfMessage == "ANNOUNCEMENT" ? "there," : "First Name,";
    let mutability = typeOfMessage == "ANNOUNCEMENT" ? "MUTABLE" : "IMMUTABLE";

    let FirstNameEntity = Modifier.insertText(
      initialEditonState.getCurrentContent(),
      initialEditonState.getSelection(),
      value,
      null,
      Entity.create("div", mutability, {
        text: value,
        value: value,
      })
    );
    initialEditonState = EditorState.push(
      initialEditonState,
      FirstNameEntity,
      "insert-characters"
    );
    setEditorState(initialEditonState);
    return initialEditonState;
  };

  const getDefaultHTML = () => {
    let defaultHtml = "";
    let welcomeMessage =
      campaignDetails.selectedCampaign.typeOfMessage == "ANNOUNCEMENT"
        ? "Hi there,"
        : "Hi First Name,";
    if (campaignDetails.selectedCampaign.key) {
      defaultHtml = getFallBackPillsforExisitingMessage(
        campaignDetails.selectedCampaign.message
      );
    } else {
      if (campaignDetails.selectedCampaign.message) {
        defaultHtml = campaignDetails.selectedCampaign.message;
        defaultHtml = getFallBackPillsforExisitingMessage(defaultHtml);
      } else {
        defaultHtml = `<p>${welcomeMessage}<div>+_add_content</div></p>`;
        if (
          campaignDetails.selectedCampaign.typeOfMessage == "CHAT" ||
          campaignDetails.selectedCampaign.typeOfMessage == "EMAIL"
        )
          defaultHtml = `${defaultHtml}<div><p>+_from_</p></div>`;
        else if (
          campaignDetails.selectedCampaign.typeOfMessage == "ANNOUNCEMENT"
        )
          defaultHtml = `${defaultHtml}<p>+_sender_info</p>`;
      }
    }
    return defaultHtml;
  };

  const getFallBackPillsforExisitingMessage = (message = "") => {
    let fallbackStripedMap = {
      "{{firstName}}": "First Name",
      "{{lastName}}": "Last Name",
      "{{fullName}}": "Full Name",
      "{{companyName}}": "Company Name",
    };
    message = message.replace(
      /{{firstName}}|{{lastName}}|{{companyName}}|{{fullName}}/gi,
      function (match) {
        return `${fallbackStripedMap[match]}`;
      }
    );
    return message;
  };

  const getEntityConfigForImage = (node) => {
    let entityConfig = {};
    let { height, width } = node.style;
    entityConfig.alignment = node.align;
    entityConfig.src = node.src;
    entityConfig.height = height ?  height.replace("%", "") : "100";
    entityConfig.width = width ? width.replace("%", "") : "100";
    return entityConfig;
  }

  const htmlToStyleHandler = (nodeName, node, inlineStyle) => {
    // if (
    //   node && node.style && node.style.textAlign
    // ) {
    //   if(node.parentElement.nodeName === 'H1' || node.parentElement.nodeName === 'H2'){
    //     return inlineStyle.add(node.parentElement.nodeName.toLowerCase()+"_"+node.style.textAlign);
    //   }
    //   if(node.parentElement.nodeName === 'A'){
    //     if(node.parentElement.parentElement.nodeName === 'H1' || node.parentElement.parentElement.nodeName === 'H2'){
    //       return inlineStyle.add(node.parentElement.parentElement.nodeName.toLowerCase()+"_"+node.style.textAlign);
    //     }
    //   }
    //   // return inlineStyle.add(node.style.textAlign);
    // }
    return inlineStyle;
  }

  const htmlToBlockHandler = (nodeName , node) => {
    if(node.align && nodeName !== nodeNames.image){
      return {
        type: blockTypes[nodeName],
        data: {
          'text-align' : node.align
        }
    };
    }
    if (nodeName === nodeNames.figure && node.firstChild.nodeName === nodeNames.image.toUpperCase()) {
     return blockTypes.atomic;
    }
    return null;
  }

  const htmlToEntityHandler = (nodeName, node, createEntity) => {
    if (nodeName === nodeNames.link) {
      return createEntity( blockTypes.link, blockMutability.MUTABLE, { url: node.href });
    }

    if (nodeName === nodeNames.image) {
      let entityConfig = getEntityConfigForImage(node);
      return createEntity( blockTypes.image , blockMutability.IMMUTABLE , entityConfig);
    }
    return null;
  }

  const contentStateFromExisting = ( html ) => {
    return convertHtml({
      htmlToStyle: function (nodeName, node, inlineStyle) {
        return htmlToStyleHandler(nodeName, node, inlineStyle)
      },
      htmlToEntity: (nodeName, node, createEntity) => {
          return htmlToEntityHandler(nodeName, node, createEntity);
      },
      htmlToBlock: (nodeName , node) => {
        return htmlToBlockHandler(nodeName , node);
      }
  })(html);
  }

  const getContent = () => {
    let content;
    let defaultHtml = getDefaultHTML();
    // if (campaignDetails.selectedCampaign.key) {
    //   content = contentStateFromExisting(defaultHtml);
    // } else {
    //   const blocksFromHTML = convertFromHTML(defaultHtml);
    //   content = ContentState.createFromBlockArray(
    //     blocksFromHTML.contentBlocks,
    //     blocksFromHTML.entityMap
    //   );
    // }
    content = contentStateFromExisting(defaultHtml);
    return content;
  };

  useEffect(() => {
    const focusPlugin = createFocusPlugin();

    const decorator = composeDecorators(
      resizeablePlugin.decorator,
      alignmentPlugin.decorator,
      focusPlugin.decorator
    );
    imagePlugin = createImagePlugin({ decorator });

    let currentPlugins = plugins;
    currentPlugins.push(imagePlugin);
    currentPlugins.push(focusPlugin);
    currentPlugins.push(alignmentPlugin);
    setPlugins(currentPlugins);
    //setIntialEditorState(false);
  }, []);

  useEffect(() => {
    let content = getContent();
    setEditorState(EditorState.createWithContent(content, compositeDecorator));
  }, [campaignDetails.selectedCampaign.key]);

  const updateStyleByAlignment = (alignment, response) => {
    response.style.position = "relative";
    response.style.cursor = "default";
    response.style.display = "block";
    if (alignment === "center") {
      response.style["margin-left"] = "auto";
      response.style["margin-right"] = "auto";
    }

    if (alignment === "right") {
      // response.style.float = "right";
      response.style["margin-left"] = "auto";
    }
  };

  const getProperBlockAlignment = ( alignment ) => {
     return alignment && alignment.startsWith('h1_') ? alignment.split('h1_')[1] : alignment;
  }


  const handleBlockAlignment = ( block ) => {
    let blockAlignment = block.getData() && block.getData().get('text-align');
    blockAlignment = getProperBlockAlignment(blockAlignment);
    let blockType = block.get("type"); 
    if(blockType === 'header-one' && !blockAlignment){
      return {
        element: "h1",
        style: {
          color:  getWidgetColor(),
          '-webkit-text-fill-color': getWidgetColor()
        },
      };
    }
    if(alignmentBlockTypes.includes(blockAlignment)){
      let alignedStyle =  {
        element: "p",
        attributes : {
          align : blockAlignment
        },
        style: {
          textAlign: blockAlignment,
          display: "block",
        },
      };
      if(blockType === 'header-one'){
        alignedStyle.style.color = getWidgetColor()
      }
      if(blockType === 'header-one' || blockType === 'header-two'){
        if(block.getInlineStyleAt(0).has("ITALIC"))
        {
          alignedStyle.style.fontStyle = "italic";
        }
      }
      return alignedStyle;
  }
}

  const handleAlignment = (entity) => {
    let blockType = entity.get("type"); 
    if(blockType === blockTypes.h1){
      return {
        element: nodeNames.h1,
        style: {
          color:  getWidgetColor(),
          '-webkit-text-fill-color': getWidgetColor()
        },
      };
    }
    if(alignmentBlockTypes.includes(blockType)){
      return {
        element: nodeNames.p,
        attributes : {
          align : blockType
        },
        style: {
          textAlign: blockType,
          display: "block",
        },
      };
    }
  }

  useEffect(() => {
    let options = {
      blockStyleFn: (block) => {
        return handleBlockAlignment(block);
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get("type").toLowerCase();
        handleAlignment(entity);
        if( entityType === blockTypes.link.toLowerCase()){
          const data = entity.getData();
          return {
            element: nodeNames.link,
            attributes: {
              target: "_blank",
              href: data.url
            },
            style: {
              color:  getWidgetColor(),
              '-webkit-text-fill-color': getWidgetColor()
            },
          };
        }
        if (entityType === "image") {
          const data = entity.getData();
          let {src , width = "100%"} = data;
          let response = {
            element: nodeNames.image,
            attributes: {
              src: src,
            },
            style: {
              width: width,
            },
          };
          if (data.alignment) {
            response.style.position = "relative";
            response.style.cursor = "default";
            response.style.display = "block";
            response.attributes.align = data.alignment
            updateStyleByAlignment(data.alignment, response);
          }

          if (data.height) response.style.height = `${data.height}%`;
          if (data.width) response.style.width = `${data.width}%`;

          return response;
        }
      },
      inlineStyles: {
        left: {
          style: {
            display: "block",
            textAlign: "left",
          },
        },
        center: {
          style: {
            display: "block",
            textAlign: "center",
          },
        },
        right: {
          style: {
            display: "block",
            textAlign: "right",
          },
        }
      },
    };
    if (!campaignDetails.selectedCampaign.isHTMLTemplateEnabled) {
      let campaignData = stateToHTML(editorState.getCurrentContent(), options);
      campaignData = campaignData.replace("&nbsp;", "");
      campaignData = campaignData.replace("a</img>", "</img>");
      setNormalCampaignData(campaignData);
      onCampaignDataChange(campaignData);
    }
  }, [editorState]);

  useEffect(() => {
    EventEmitter.subscribe("replaceEntityImageUrl", replaceServerURlForImage);
  }, []);

  useEffect(() => {
    if(hasSelectionOnBlur){
      let contentState = Modifier.applyInlineStyle(editorState.getCurrentContent() , editorState.getSelection() , "selection_mock");
      let updatedEditorState = EditorState.push(editorState, contentState, 'change-inline-style');
      // let updatedEditorState = RichUtils.toggleInlineStyle(editorState, "selection_mock");
      onChange(updatedEditorState);
    }
  }, [hasSelectionOnBlur]);

  // useEffect(() => {
  //   if(hasFocus && blurEditorState && hasSelectionOnBlur){
  //    let currentContent = editorState.getCurrentContent();
  //     const noSelectionContentState = Modifier.removeInlineStyle(
  //       currentContent,
  //       blurEditorState.getSelection(),
  //       "selection_mock"
  //     );

  //     let updatedEditorState = EditorState.push(editorState, noSelectionContentState, 'change-inline-style');

  //     onChange(updatedEditorState);
  //     // let updatedEditorState = RichUtils.toggleInlineStyle(editorState, "selection_mock");
  //     // onChange(updatedEditorState);
     
  //     setHasFocus(false);
  //     setHasSelectionOnBlur(false);
  //     setBlurEditorState(undefined);
  //   }
  // }, [hasFocus]);

  const onChange = (newEditorState) => {
    if(hasFocus && blurEditorState && hasSelectionOnBlur){
      console.log(getSelectionCustomInlineStyle(newEditorState));
      console.log(getSelectionCustomInlineStyle(blurEditorState));
      let updatedContentState = Modifier.removeInlineStyle(newEditorState.getCurrentContent() , blurEditorState.getSelection() , "selection_mock");
      let updatedEditorState = EditorState.push(newEditorState,  updatedContentState , 'change-inline-style');

      let collapsed = newEditorState.getSelection().merge({
        anchorOffset: newEditorState.getSelection().getEndOffset(), 
        focusOffset: newEditorState.getSelection().getEndOffset()
      });

      let newSelectedState = EditorState.forceSelection(updatedEditorState, collapsed);

      setEditorState(newSelectedState);
      setHasFocus(false);
      setHasSelectionOnBlur(false);
      setBlurEditorState(undefined);
    } else {
      setEditorState(newEditorState);
    }
    
  };

  // const removeInlineStyles = (newEditorState) => {
  //   let selection = newEditorState.getSelection();
  //   const nextContentState = ["h1_center" , "h1_left" , "h1_right" , "h2_center" , "h2_left" , "h2_right" ].reduce(
  //     (contentState, alignment) => {
  //       return Modifier.removeInlineStyle(contentState, selection, alignment);
  //     },
  //     newEditorState.getCurrentContent()
  //   );
  //   return nextContentState;
  // }

  // const _toggleAlignmentData = (event) => {
  //   if (editorReadOnly) return;
  //   const selection = editorState.getSelection();
  //   const anchorKey = selection.getAnchorKey();
  //   const currentContent = editorState.getCurrentContent();
  //   const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  //   const blockType = currentContentBlock.getType();
  //   let updatedState = undefined;
  //   let styleType = event.target.dataset.style;
  //   if (
  //     blockType === "unstyled" ||
  //     alignmentBlockTypes.includes(blockType)
  //   ) {
  //     if (styleType.toLowerCase() === blockType) return;
  //     toggleBlockStyle(event);
  //   } else {
  //     let contentStateWithoutInlineStyle = removeInlineStyles( editorState );
  //     if(blockType === blockTypes.h1 && alignmentBlockTypes.includes(styleType.toLowerCase())){
  //       let combinedStyle = "h1_"+styleType.toLowerCase();
  //       updatedState = Modifier.applyInlineStyle(contentStateWithoutInlineStyle , selection , combinedStyle);
  //     } else if(blockType === blockTypes.h2 && alignmentBlockTypes.includes(styleType.toLowerCase())){
  //       let combinedStyle = "h2_"+styleType.toLowerCase();
  //       updatedState = Modifier.applyInlineStyle(contentStateWithoutInlineStyle , selection , combinedStyle);
  //     } else {
  //       updatedState = currentContent;
  //     }
      
  //     let newState = Modifier.applyInlineStyle(updatedState , selection , StylesList[styleType]);
  //     onChange(EditorState.push(editorState , newState , 'change-inline-style'));
  //   }
  // };

  const _handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }

    return false;
  };

  const handleImageSelectionAndReplacing = (newState) => {
    const selectionstate = newState.getSelection();
    if (selectionstate.isCollapsed()) {
      let blockType = getSelectedBlockType(newState);
      if (blockType === "atomic") {
        let selectedBlockKey = getSelectedBlockKey(newState);

        const newContentState = newState.getCurrentContent();
        let blockMap = newContentState.getBlockMap();

        blockMap = blockMap.filter((block) => {
          if (block.getKey() !== selectedBlockKey) {
            return block;
          }
        });
        const updatedContentState = newContentState.set("blockMap", blockMap);

        let nextEditorState = EditorState.createWithContent(
          updatedContentState
        );
        nextEditorState = EditorState.push(
          nextEditorState,
          updatedContentState,
          "remove-range"
        );
        setEditorState(nextEditorState);
        return nextEditorState;
      }
    }
    return newState;
  };
  const myKeyBindingFn = (event) => {
    const { hasCommandModifier } = KeyBindingUtil;

    if (event.keyCode === 32 && !hasCommandModifier(event)) {
      handleImageSelectionAndReplacing(editorState);
    }
    return getDefaultKeyBinding(event);
  };
  const _onTab = (e) => {
    const maxDepth = 4;
    onChange(RichUtils.onTab(e, editorState, maxDepth));
  };

  const getWidgetColor = () => {
    return chatConfiguration.chatConfiguration.widgetThemeColor
      ? `#${chatConfiguration.chatConfiguration.widgetThemeColor}`
      : `#6699ff`;
  };
  const styleData = StyleSheet.create({
    widgetColor: {
      color: getWidgetColor(),
    },
    h1_right: {
      display: "table",
      marginLeft: "auto",
      color: getWidgetColor(),
    },
    h1_center: {
      display: "table",
      margin: "auto",
      color: getWidgetColor(),
    },
    h1_left: {
      display: "table",
      margin: "0px",
      color: getWidgetColor(),
    },
  });
  const colorStyleMap = {
    "selection_mock" : {
      backgroundColor: getSelectionColorBasedOnUserAgent(navigator.userAgent)
    }
  };

  const widgetcolor = getWidgetColor();
  colorStyleMap[widgetcolor] = {
    color: hexToRgbA(widgetcolor),
  };
  colorStyleMap["#000000"] = {
    color: hexToRgbA("#000000"),
  };

  const _toggleColor = () => {
    const selectedBlockType = getSelectedBlockType(editorState);
    const toggledColor =
      selectedBlockType == "unstyled" ? getWidgetColor() : "#000000";
    const selection = editorState.getSelection();

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(colorStyleMap).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      },
      editorState.getCurrentContent()
    );

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );

    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style overxde for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor
      );
    }
    nextEditorState = RichUtils.toggleBlockType(nextEditorState, "header-one");
    onChange(nextEditorState);
  };
  const getSelectedBlockKey = (newEditorState) => {
    var startKey = newEditorState.getSelection().getStartKey();
    return startKey;
  };

  const getSelectedBlock = (newEditorState) => {
    var startKey = newEditorState.getSelection().getStartKey();
    return newEditorState
      .getCurrentContent()
      .getBlockForKey(startKey);
  }

  const getSelectedBlockType = (newEditorState) => {
    return getSelectedBlock(newEditorState).getType();
  };

//   const updateBlockType = ( style ) => {
//     const selection = editorState.getSelection()
//     const contentState = editorState.getCurrentContent()
//     // const styles = editorState.getCurrentInlineStyle()

//     // const removeStyles = styles.reduce((state, style) => {
//     //   return Modifier.removeInlineStyle(state, selection, style) }, contentState)
//     const updateBlock = Modifier.setBlockType(contentState, selection, style)

//     return EditorState.push(
//       editorState,
//       updateBlock
//     );
// }

// const updateBlockTypeWithAlignment = (currentStyle, style ) => {
//   let selectionState = editorState.getSelection();
//   let lowerCaseStyle = style.toLowerCase();
//   let newEditorState = RichUtils.toggleBlockType(
//     editorState,
//     StylesList[style]
//   );
//   let stateWithoutStyles = removeInlineStyles(newEditorState)
//   let alignedInlineStyle = Modifier.applyInlineStyle(stateWithoutStyles, selectionState , lowerCaseStyle+"_"+currentStyle);
//   return EditorState.push(newEditorState , alignedInlineStyle , 'change-inline-style');
// }

const addBlockAlignmentData = (event) => {
  let style = event.target.dataset.style;
  let action = style.toLowerCase();

  Event(
    getProjectId(props.params.projectkey),
  "Campaigns",
  "CampaignTool"+action[0].toUpperCase()+action.substring(1, action.length),
  "alignment"
  )
  if (getSelectedBlockType(editorState) === "atomic") {
    toggleImageAlignment(style);
  } else {
    style = StylesList[style];
    let editorStateWithAlignment = setBlockData(editorState, { 'text-align': style });
    onChange(editorStateWithAlignment);

  }
  }

  const _toggleBlockType = (style) => {
    if (!editorReadOnly && style) {
      // let currentStyle = getSelectedBlockType(editorState);
      // let editorStateToUpdate = undefined;
      // if(alignmentBlockTypes.includes(currentStyle) && ["H1" , "H2" ].includes(style)){
      //     editorStateToUpdate = updateBlockTypeWithAlignment(currentStyle , style);
      // }else if(alignmentBlockTypes.includes(currentStyle)){
      //   editorStateToUpdate = updateBlockType(StylesList[style]);
      // }else {
      //   editorStateToUpdate = RichUtils.toggleBlockType(
      //     editorState,
      //     StylesList[style]
      //   );
      // }
      let editorStateToUpdate = RichUtils.toggleBlockType(
        editorState,
        StylesList[style]
      );
      setEditorState(editorStateToUpdate);
    }
  };

  
  const checkAndPostToolOptionsToGA = (style)=>{
    if(!checkIfElementActive(STYLE_TO_ACTIVE_STATUS_CLASSES[style]))
    {
      let action = style.toLowerCase();
      Event(
        getProjectId(props.params.projectkey),
      "Campaigns",
      "CampaignTool"+action[0].toUpperCase()+action.substring(1, action.length),
      style.toLowerCase()
      ) 
    }
  }
  const toggleBlockStyle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsContentEdited();
    let style = event.target.dataset.style;
    checkAndPostToolOptionsToGA(style);
    _toggleBlockType(style);
  };
  const toggleInlineStyle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsContentEdited();
    let style = event.target.dataset.style;
    checkAndPostToolOptionsToGA(style);
    _toggleInlineStyle(style);
  };

  const _toggleInlineStyle = (style) => {
    if (!editorReadOnly && style) {
      if (style == "H1") _toggleColor();
      else if (["center", "left", "right" ].includes(style.toLowerCase())) {
        if (getSelectedBlockType(editorState) === "atomic") {
          toggleImageAlignment(style);
        } else {
          toggleAlignment(style);
        }
      } else {
        onChange(RichUtils.toggleInlineStyle(editorState, StylesList[style]));
      }
    }
  };

  const removeAlignmentStyles = (style = "") => {
    const alignments = ["left", "right", "center"];
    const focusKey = editorState.getSelection().getFocusKey();
    const currentBlock = editorState
      .getCurrentContent()
      .getBlockForKey(focusKey);
    const selectionState = SelectionState.createEmpty();
    var anchorOffset = selectionState.getAnchorOffset();
    var focusOffset = selectionState.getFocusOffset();
    var currentTextLength = currentBlock.getText().length;
    const entireBlockSelectionState = selectionState.merge({
      anchorKey: editorState.getSelection().getAnchorKey(),
      anchorOffset: anchorOffset - focusOffset <= 0 ? 0 : currentTextLength,
      focusKey: editorState.getSelection().getFocusKey(),
      focusOffset: anchorOffset - focusOffset <= 0 ? currentTextLength : 0,
    });
    const updatedSelection = EditorState.forceSelection(
      editorState,
      entireBlockSelectionState
    );
    const nextContentState = alignments.reduce(
      (contentState, alignmentStyle) => {
        return Modifier.removeInlineStyle(
          contentState,
          updatedSelection.getSelection(),
          alignmentStyle
        );
      },
      editorState.getCurrentContent()
    );

    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );

    return nextEditorState;
  };

  const toggleAlignment = (style = "") => {
    let modifiedEditorState = removeAlignmentStyles(style);
    onChange(
      RichUtils.toggleInlineStyle(modifiedEditorState, StylesList[style])
    );
  };

  const toggleImageAlignment = (style = "") => {
    let alignmentType = {
      CENTER: 2,
      RIGHT: 3,
      LEFT: 0,
    };
    let selectedAlignmentType = alignmentType[style];
    let alignElement = document.getElementsByClassName(
      "draftJsEmojiPlugin__alignmentTool__2mkQr"
    );
    if (
      alignElement[0] &&
      alignElement[0].children &&
      alignElement[0].children[selectedAlignmentType] &&
      alignElement[0].children[selectedAlignmentType].children &&
      alignElement[0].children[selectedAlignmentType].children[0]
    ) {
      alignElement[0].children[selectedAlignmentType].children[0].click();
    }
  };

  // const handleReturn = (e, editorState) => {
  //   if (
  //     isLastWordAMention(
  //       editorState.getCurrentContent().getPlainText()
  //     ) /* && this.state.isSuggestionShowing */
  //   )
  //     return "not-handled";

  //   onChange(RichUtils.insertSoftNewline(editorState));
  //   return "handled";
  // };


  const toggleShowTemplate = () => {
    if (editorReadOnly) return;
    setIsContentEdited();
    if(!showTemplate)
    {
      Event(
        getProjectId(props.params.projectkey),
      "Campaigns",
      "CampaignToolTemplate",
      "Template"
      )
    }
    setShowTemplate(!showTemplate);
  };

  

  const toggleAlignmentPopup = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsContentEdited();
    // let selection = editorState.getSelection();
    // let list = getSelectedBlocksList(editorState);
    // console.log("selected block :" , getSelectedBlocksType());
    // console.log("list " , list);
    setEditorState(
      EditorState.forceSelection(editorState, editorState.getSelection())
    );
    if (editorReadOnly) return;
    setShowAlignmentPopup(!showAlignmentPopup);
  };

  const getTemplateDropDown = () => {
    return showTemplate ? (
      <div className="child-dwn" ref={fallbackDropDownRef}>
        <ul>
          <li data-value="Full Name" onClick={addComp}>
            <a data-value="Full Name" href="javascript:void(0)">
              Full Name
            </a>
          </li>
          <li data-value="First Name" onClick={addComp}>
            <a data-value="First Name" href="javascript:void(0)">
              First Name
            </a>
          </li>
          <li data-value="Last Name" onClick={addComp}>
            <a data-value="Last Name" href="javascript:void(0)">
              Last Name
            </a>
          </li>
          <li data-value="Company Name" onClick={addComp}>
            <a data-value="Company Name" href="javascript:void(0)">
              Company Name
            </a>
          </li>
        </ul>
      </div>
    ) : (
      ""
    );
  };

  const getAlignmentDropDown = () => {
    return showAlignmentPopup ? (
      <div className="child-dwn" id="alignment_options" ref={alignmentRef}>
        <ul>
          <li id="center_align_btn" data-style="CENTER" onMouseDown={addBlockAlignmentData}>
            <a data-style="CENTER">
              <CenterAlignIcon data-style="CENTER" />
              Center
            </a>
          </li>
          <li data-style="LEFT" onMouseDown={addBlockAlignmentData}>
            <a data-style="LEFT">
              <LeftAlignIcon data-style="LEFT" />
              Left
            </a>
          </li>
          <li data-style="RIGHT" onMouseDown={addBlockAlignmentData}>
            <a data-style="RIGHT">
              <RightAlignIcon data-style="RIGHT" />
              Right
            </a>
          </li>
        </ul>
      </div>
    ) : (
      ""
    );
  };

  const linkInputHandler = (e) => {
    setUrlValue(e.target.value);
    // e.preventDefault();
    // e.stopPropagation()
    // e.stopImmediatePropagation();
  };

  const onEnter = (e) => {
    if (e.which === 13) {
      confirmLink(e.target.value);
      setShowLinkDropDown(false);
      Event(
        getProjectId(props.params.projectkey),
        "Campaigns",
        "CampaignToolLink",
        "Link"
      )
      setUrlValue("");
    }
  };

  const onEsc = (e) => {
    if (event.key === "Escape" && event.keyCode == 27) {
      setShowLinkDropDown(false);
      setUrlValue("");
      setShowTemplate(false);
      setShowAlignmentPopup(false);
    }
  };

  const getLinkBox = () => {
    return showLinkDropDown ? (
      <div className="child-dwn link-child" ref={linkBoxRef}>
        <p>Paste a link</p>
        <input
          id="link_input"
          onKeyDown={onEnter}
          onChange={linkInputHandler}
          type="text"
          placeholder="Link"
          value={urlValue}
          name=""
          autoFocus={true}
          ref={linkRef}
          onBlur={onLinkBlur}
        />
      </div>
    ) : (
      ""
    );
  };

  // const blockRenderMap = Map({
  //   'h1_center': {
  //     element: 'h1'
  //   },
  // });

  var blockRendererFn = (contentBlock) => {
    const type = contentBlock.getType();
    const text = contentBlock.getText();

    if (type === "unstyled" && text === "+_add_content") {
      return {
        component: AddContent,
        props: { name: text, handleOnClick: handleAddContentClick },
        editable: false,
      };
    }

    if (type === "unstyled" && text === "+_from_") {
      return {
        component: FromSpan,
        props: {
          name: text,
          handleOnClick: handleFromSpanClick,
          agentName: props.agentName,
          ref: fromSpanRef,
        },
        editable: false,
      };
    }
    if (type === "unstyled" && text === "+_sender_info") {
      return {
        component: SenderCard,
        props: {
          name: text,
          handleOnClick: handleSenderCardClick,
          agentName: props.agentName,
          agentImage: props.agentImage,
          projectName: props.projectName,
          ref: senderCardRef,
        },
        editable: false,
      };
    }
    // if (type === "header-one") {
    //   return {
    //     component: H1Content,
    //     props: { name: text, params: props.params },
    //     editable: true,
    //   };
    // }
  };

  const getBlockStyle = (contentBlock) => {
    const type = contentBlock.getType();
    const blockAlignment = contentBlock.getData() && contentBlock.getData().get('text-align');
    if (type === "header-one" && !blockAlignment) {
      return css(styleData.widgetColor);
    }

    if (type === "header-one" && blockAlignment) {
      let combinedStyle = `h1_${blockAlignment}`
      return css(styleData[combinedStyle]);
    }

    if (type !== "header-one" && blockAlignment) {
      return blockAlignment;
    }
    return '';
  };

  const addComp = (event) => {
    if (editorReadOnly) return;
    let value = event.target.dataset.value;
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const entityKey = Entity.create("div", "IMMUTABLE", {
      text: value,
      value: value,
    });
    const textWithEntity = Modifier.insertText(
      currentContent,
      selection,
      `${value} `,
      null,
      entityKey
    );
    let newEditorState = EditorState.push(
      editorState,
      textWithEntity,
      "insert-characters"
    );
    setEditorState(
      EditorState.forceSelection(
        newEditorState,
        textWithEntity.getSelectionAfter()
      )
    );
  };

  const handleAddContentClick = () => {
    /* let modifiedHtml = `<p>Hi First Name</p><p><br/></p> <p><br/><p><br/></p> <p><br/></p>`;
    if (campaignDetails.selectedCampaign.typeOfMessage == "CHAT")
      modifiedHtml = `${modifiedHtml}<p>+_from_</p>`;
    const blocksFromHTML = convertFromHTML(modifiedHtml);
    blocksFromHTML.contentBlocks.splice(1, 0, getNewBlock());
    blocksFromHTML.contentBlocks.splice(1, 0, getNewBlock());
    console.info("blocksFromHTML:", blocksFromHTML);
    let content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    console.info("content:", content);
    let editorState = EditorState.createWithContent(
      content,
      compositeDecorator
    ); */
    setEditorReadOnly(false);
    props.toggleAddContent(false);
    let newEditorState = setIntialEditorState(true);
    forceSelection(newEditorState);
  };

  const handleFromSpanClick = () => {
    setEditorReadOnly(true);
  };
  const handleSenderCardClick = () => {
    setEditorReadOnly(true);
  };
  const forceSelection = (newEditorState) => {
    const content = newEditorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const blockMapArray = Array.from(blockMap);
    const key = blockMapArray[1][1].getKey();
    const length = blockMapArray[1][1].getLength();

    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
    setEditorState(EditorState.forceSelection(newEditorState, selection));
  };

  function getNewBlock(contentState) {
    const newBlock = new ContentBlock({
      key: genKey(),
      text: "",
      type: "unstyled",
    });
    return newBlock;
  }

  const replaceServerURlForImage = ({ entityKey, url }) => {
    const contentState = editorState.getCurrentContent();
    contentState.mergeEntityData(entityKey, {
      src: url,
    });
  };

  const handleImageUploadOnServer = (fileData, entityKey) => {
    let randomUploadId = createUUId();
    let formData = new FormData();
    formData.append("file", fileData);
    formData.append("fileName", fileData.name);

    let requestData = {
      formData: formData,
      id: randomUploadId,
      fileName: fileData.name,
      projectId: "LS-" + props.params.projectkey,
      entityKey: entityKey,
      message: {},
    };
    requestData["message"][randomUploadId] = { formData: formData };
    dispatch(requestFileUploadUrl(requestData, false));
  };

  function handleFileSelect(event) {
    if (editorReadOnly) return;
    setIsContentEdited();
    var fileData = event.target.files[0];
    if (
      ["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(
        fileData.type
      )
    ) {
      var reader = new FileReader();
      reader.onload = (function (data) {
        return function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData);
          let imgSrc = "data:image/png;base64," + base64String;

          onAddImage(imgSrc, fileData);
        };
      })(fileData);
      reader.readAsBinaryString(fileData);
      Event(
        getProjectId(props.params.projectkey),
        "Campaigns",
        "CampaignToolImage",
        "Link"
      )
    } else {
      dispatch(
        showVoicebox({
          message: "Upload a JPG, JPEG, PNG or WEBP file and try again",
          dismissAfter: 2000,
        })
      );
    }
  }

  const getEditorStateWithUploadedImage = (newAtomicBlock, entityKey) => {
    const newContentState = newAtomicBlock.getCurrentContent();
    const blockMap = newContentState.getBlockMap();
    const imageAtomicBlock = blockMap.find((block) => {
      if (block.getEntityAt(0) === entityKey) {
        return block;
      }
    });

    const atomicBlockKey = imageAtomicBlock.getKey();

    const blockBefore =
      newContentState.getBlockBefore(atomicBlockKey) &&
      newContentState.getBlockBefore(atomicBlockKey).getText().trim().length
        ? ""
        : newContentState.getBlockBefore(atomicBlockKey).getKey();

    const blockAfter =
      newContentState.getBlockAfter(atomicBlockKey) &&
      newContentState.getBlockAfter(atomicBlockKey).getText().trim().length
        ? ""
        : newContentState.getBlockAfter(atomicBlockKey).getKey();

    let newBlockMap = blockMap.filter((block) => {
      if (
        block.getType() === "atomic" ||
        (block.getText().trim().length && block.getKey() !== blockAfter)
      ) {
        return block;
      }
    });
    const properMap = newBlockMap.filter((block) => {
      if (
        block.getType() === "atomic" ||
        (block.getText().trim().length && block.getKey() !== blockBefore)
      ) {
        return block;
      }
    });
    const updatedContentState = newContentState.set("blockMap", properMap);

    return EditorState.createWithContent(updatedContentState);
  };

  const insertImage = (base64, fileData) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: base64 }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    handleImageUploadOnServer(fileData, entityKey);

    const newAtomicBlock = AtomicBlockUtils.insertAtomicBlock(
      newEditorState,
      entityKey,
      " "
    );
    return getEditorStateWithUploadedImage(newAtomicBlock, entityKey);
  };

  const onAddImage = (imageUrl, fileData) => {
    const newEditorState = insertImage(imageUrl, fileData);
    onChange(newEditorState);
  };

  const checkIfElementActive = (type) => {
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return blockType === type;
  };
  const getIsReadOnly = () => {
    return editorReadOnly || !!selectedFallBackValue;
  };

  const onEditorBlur = (e) => {
    if (editorState.getSelection().isCollapsed() === false) {
      setHasSelectionOnBlur(true);
      setBlurEditorState(editorState);
    }
    setHasFocus(false);
  };

  const onEditorFocus = (e) => {
      setHasFocus(true);
  };

  const onLinkBlur = () => {
    let updatedContentState = Modifier.removeInlineStyle(editorState.getCurrentContent() , editorState.getSelection() , "selection_mock");
    setEditorState(EditorState.push(editorState,  updatedContentState , 'change-inline-style'));
  }

  const handleBeforeInput = ( chars ) => {
    const currentContentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    onChange(EditorState.push(
        editorState,
        Modifier.replaceText(
            currentContentState,
            selectionState,
            chars
        )
    ));
    return "handled";
};

  
  const popupOnChange = (editor, data, value) => {
    if (value && value.trim()) setHtmlCode(stripsHtmlContent(value));
    else setHtmlCode("");
  };

  const disableHTMLTemplate = () => {
    campaignDetails.selectedCampaign.isHTMLTemplateEnabled = false;
    onCampaignDataChange(normalCampaignData);
    dispatch(
      BroadCastMessageStatsActions.addCampaign(
        Object.assign({}, campaignDetails.selectedCampaign),
        true
      )
    );
  };

  const updateHTMLContent = () => {
    props.toggleAddContent(false);
    dispatch(
      BroadCastMessageStatsActions.addCampaign(
        Object.assign({}, campaignDetails.selectedCampaign),
        true
      )
    );
    dispatch(updateCampaignContent());
    dispatch(PopupActions.hidePopup());
  };

  const blockHTMLContentFromUpdating = () => {
    campaignDetails.selectedCampaign.message = htmlCodeInEditor;
    dispatch(
      BroadCastMessageStatsActions.addCampaign(
        Object.assign({}, campaignDetails.selectedCampaign),
        true
      )
    );
  };

  const reRenderPopup = () => {
    onCampaignDataChange(normalCampaignData);
    dispatch(
      BroadCastMessageStatsActions.addCampaign(
        Object.assign({}, campaignDetails.selectedCampaign),
        true
      )
    );
    dispatch(PopupActions.showPopup(popupPropsForPopup));
  };

  const reRenderUpdatedPopup = () => {
    campaignDetails.selectedCampaign.message = htmlCodeInEditor;
    dispatch(
      BroadCastMessageStatsActions.addCampaign(
        Object.assign({}, campaignDetails.selectedCampaign),
        true
      )
    );
    dispatch(PopupActions.showPopup(popupPropsForUpdatedPopup));
  };

  const togglePasteContent = () => {
    setShowPasteContent(false);
  };

  const getPopupContent = () => {
    return showPasteContent ? (
      <div className="wrapper">
        <div className="edit-html-code" onClick={togglePasteContent}>
          Paste HTML code here
        </div>
        <CodeEditor options={{ readOnly: true }} />
      </div>
    ) : (
      <CodeEditor {...propsForPopupContent} />
    );
  };

  const getPopupHeaderContent = () => {
    return (
      "Create your " + campaignDetails.campaignType.toLowerCase() + " with HTML"
    );
  };

  const propsForPopupContent = {
    value: "",
    onChange: popupOnChange,
    options: {
      mode: "htmlmixed",
      autofocus: true,
      readOnly: false,
      lineWrapping: true,
    },
    className: "popup-codeMirrorEditor",
  };

  let popupPropsForPopup = {
    popupProps: {
      popupHeader: getPopupHeaderContent(),
      popupContent: getPopupContent(),
      hideDecisionButtons: false,
      headerIcon: <EditHtmlPopupIcon />,
      isReactComponent: true,
      styleClass: "htmlCode-popup",
      showContentClass: true,
      okButton: {
        action: updateHTMLContent,
        text: "Save",
        isEnable: isHTMLContentEdited,
      },
      cancelButton: {
        action: disableHTMLTemplate,
        text: "Cancel",
        display: true,
      },
      closeButton: {
        action: disableHTMLTemplate,
      },
    },
  };

  const propsForUpdatedPopupContent = {
    value: htmlCodeInEditor,
    autoFocus: true,
    onChange: popupOnChange,
    options: {
      mode: "htmlmixed",
      autofocus: true,
      readOnly: false,
      lineWrapping: true,
    },
    className: "updatedPopup-codeMirrorEditor",
  };

  let popupPropsForUpdatedPopup = {
    popupProps: {
      popupHeader: getPopupHeaderContent(),
      popupContent: <CodeEditor {...propsForUpdatedPopupContent} />,
      headerIcon: <EditHtmlPopupIcon />,
      hideDecisionButtons: false,
      isReactComponent: true,
      styleClass: "htmlCode-popup",
      showContentClass: true,
      okButton: {
        action: updateHTMLContent,
        text: "Save Changes",
        isEnable: isHTMLContentEdited,
      },
      cancelButton: {
        action: blockHTMLContentFromUpdating,
        text: "Cancel",
        display: true,
      },
      closeButton: {
        action: blockHTMLContentFromUpdating,
      },
    },
  };

  const openPopup = () => {
    if (isHTMLSupportedCampaign()) {
      if(selectedFallBackValue)
        updateFallback(false);
      dispatch(PopupActions.showPopup(popupPropsForPopup));
    }
  };

  const isHTMLSupportedCampaign = () => {
    return (
      campaignDetails.campaignType === "EMAIL" ||
      campaignDetails.campaignType === "ANNOUNCEMENT"
    );
  };

  const propsForCodeMirror = {
    value: htmlCodeInEditor,
    autoFocus: false,
    options: {
      mode: "htmlmixed",
      autofocus: false,
      readOnly: "nocursor",
      lineWrapping: true,
    },
    className: "normal-codeMirrorEditor",
  };

  const getEditor = () => {
    return isHTMLEditor ? (
      <div>
        <div className="cs-normalCodeEditor">
          <div className="edit-hover-div" onClick={openUpdatedPopup}>
            Edit / View HTML Code
          </div>
          <CodeEditor {...propsForCodeMirror} />
        </div>
      </div>
    ) : (
      <div className="cs-draftEditor">
        <Editor
          handleBeforeInput={handleBeforeInput}
          // onBlur={onEditorBlur}
          // onFocus={onEditorFocus}
          customStyleMap={colorStyleMap}
          editorState={editorState}
          handleKeyCommand={_handleKeyCommand}
          onChange={onChange}
          onTab={_onTab}
          spellCheck={true}
          blockRendererFn={blockRendererFn}
          // blockRenderMap={blockRenderMap}
          blockStyleFn={getBlockStyle}
          plugins={plugins}
          ref={editorRef}
          readOnly={getIsReadOnly()}
          keyBindingFn={myKeyBindingFn}
        />
      </div>
    );
  };

  const openUpdatedPopup = (event) => {
    dispatch(PopupActions.showPopup(popupPropsForUpdatedPopup));
  };

  return (
    <div>
      <div className="cs-campaignEditor">{getEditor()}</div>
      <div
        className="cs-AligmentSettings"
        style={{
          display: isHTMLEditor ? "none" : "block",
        }}
      >
      <AlignmentTool />
      <div className="compaign-content-text">
        <div
          data-style="BOLD"
          className={
            editorState.getCurrentInlineStyle().has("BOLD")
              ? "active img-center b-tag"
              : "img-center b-tag"
          }
          onMouseDown={toggleInlineStyle}
        >
          <BoldNormalIcon data-style="BOLD" />
          <BoldActiveIcon data-style="BOLD" />
        </div>
        <div
          data-style="ITALIC"
          className={
            editorState.getCurrentInlineStyle().has("ITALIC")
              ? "active img-center b-tag"
              : "img-center b-tag"
          }
          onMouseDown={toggleInlineStyle}
        >
          <ItalicNormalIcon data-style="ITALIC" />
          <ItalicActiveIcon data-style="ITALIC" />
        </div>
        <div
        id="h1-btn"
          className={checkIfElementActive("header-one") ? "active" : ""}
          data-style="H1"
          onMouseDown={toggleBlockStyle}
        >
          <H1NormalIcon data-style="H1" />
          <H1ActiveIcon data-style="H1" />
        </div>
        <div
          className={checkIfElementActive("header-two") ? "active" : ""}
          data-style="H2"
          onMouseDown={toggleBlockStyle}
        >
          <H2NormalIcon data-style="H2" />
          <H2ActiveIcon data-style="H2" />
        </div>
        <div
          id="alignment_div"
          data-style="ALIGNMENT"
          onClick={toggleAlignmentPopup}
          className={
            showAlignmentPopup
              ? "align-dwn img-center active"
              : "align-dwn img-center"
          }
        >
          <CenterAlignIcon data-style="ALIGNMENT" />
          <CenterAlignActiveIcon data-style="ALIGNMENT" />
          {getAlignmentDropDown()}
        </div>
        <div className="divid-bar"></div>
        <div
          onClick={toggleShowTemplate}
          data-style="TEMPLATE"
          className={showTemplate ? "active" : ""}
        >
          <TemplateNormalIcon data-style="TEMPLATE" />
          <TemplateActiveIcon data-style="TEMPLATE" />
          {getTemplateDropDown()}
        </div>
        <div id="link_btn" onMouseUp={addLink} className={showLinkDropDown ? "active" : ""}>
          <LinkNormalIcon onMouseUp={addLink} />
          <LinkActiveIcon onMouseUp={addLink} />
          {getLinkBox()}
        </div>
        {/* <div data-style="CODE" onClick={_toggleBlockType}>
                    <TagNormalIcon data-style="CODE" />
                    <TagActiveIcon data-style="CODE" />
                </div > */}

        <div id="image_upload_icon">
          <ImageNormalIcon />
          <ImageActiveIcon />
          <input
            type="file"
            value=""
            onChange={handleFileSelect}
            disabled={editorReadOnly}
            accept="image/png,image/jpeg,image/jpg,image/webp"
          />
        </div>
          <div
            className={
              isHTMLSupportedCampaign() ? "code-btn" : "disabled"
            }
            onMouseUp={openPopup}
          >
            <TagNormalIcon onMouseUp={openPopup} />
            <TagActiveIcon onMouseUp={openPopup} />
          </div>
          <div className="disabled">
            <SlideAttachmentNormalIcon />
            <SlideAttachmentActiveIcon />
          </div>
          {/* <div>
                    <VideoNormalIcon />
                    <VideoActiveIcon />
                    <input type="file" value="" />
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default CampaignTextEditor;
