import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignQuickPreview from "./CampaignQuickPreview";
import { Link, browserHistory } from "react-router";
import { usePrevious } from "../hooks/usePrevious";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import { useBroadcastMessageStats } from "../hooks/useBroadcastMessageStats";
import CampaignTextEditor from "./CampaignTextEditor";
import {
  isAnnoucementDesktopType,
  isEmailDesktopType,
  hasMatchingElements,
  getArrayDifference,
  getProjectId,
  isAnnouncementCampaign,
} from "../../../app/commons/Utility.js";
import { SearchIcon, ErrorIcon } from "../../../app/commons/Icons";
import { useChatConfigurationEntity } from "../../../app/settings/hooks/useChatConfigurationEntity";
import { getFullName } from "../../../app/commons/Utility";
import ReactTooltip from "react-tooltip";
import { getProjectNameByKey ,  getReplacedMessage, getFallBackValues} from "../../../app/commons/Utility.js";
import HeadingLoader from '../loaders/HeadingLoader.jsx';
import ParaLoader from '../loaders/ParaLoader.jsx';
import InputLoader from '../loaders/InputLoader.jsx';
import { STRINGIFIED_BOOL, IS_CAMPAIGN_FROM_DASHBOARD } from "../../commons/Constants";

const CampaignDesign = (props) => {
  const {campaignId} = props.params;
  const [showAddContent, setShowAddContent] = useState(true);
  const [campaignName, setCampaignName] = useState("");
  const [campaignFrom, setCampaignFrom] = useState("");
  const [campaignFromName, setCampaignFromName] = useState("");
  const [campaignFromImage, setCampaignFromImage] = useState("");
  const [isFromFocused, setIsFromFocused] = useState(false);
  const [subjectFieldIsFocused, setSubjectFieldIsFocused] = useState(false);
  const [subject, setSubject] = useState("");
  const [campaignNameIsFocused, setCampaignNameIsFocused] = useState(false);
  const [invalids, setInvalids] = useState({});
  const [showStaffDropDown, setShowStaffDropDown] = useState(false);
  const [senderName, setSenderName] = useState("");
  const [senderImage, setSenderImage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMintimeElapsed, setIsMinTimeElapsed] = useState(false);
  const campaignNameRef = useRef("");
  const campaignContentRef = useRef("");
  const campaignSubjectRef = useRef("");

  const [selectedFallBackValue, setSelectedFallBackValue] = useState("");
  const fallbackPopupRef = useRef();
  const [leftValue, setLeftValue] = useState(0);
  const [topValue, setTopValue] = useState(0);
  const [canEnableNextButton, setCanEnableNextButton] = useState(false);
  const [isHTMLTemplate, setIsHTMLTemplate] = useState(false);
  const { campaignData, isCampaignFetched } = useBroadcastMessageStats(
    props.params.projectkey,
    campaignId
  );
  const chatConfiguration = useChatConfigurationEntity(
    "LS-" + props.params.projectkey
  );
  let reducerColor = chatConfiguration.chatConfiguration.widgetThemeColor;
  const fallbackInputRef = useRef(null);

  const { campaignDetails, staffDetails, projects, popupDetails } = useSelector((state) => ({
    campaignDetails: state.BroadcastMessageStatsReducer,
    staffDetails: state.StaffReducer,
    projects: state.ProjectReducer,
    popupDetails: state.PopupReducer
  }));
  const miniNavBar =
    isAnnoucementDesktopType(campaignDetails) ||
    isEmailDesktopType(campaignDetails);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const staffDropdownRef = useRef(null);
  const staffSearchRef = useRef(null);
  const projectName = getProjectNameByKey(
    "LS-" + props.params.projectkey,
    projects.projectList
  );
  let isStaffLoaded = usePrevious(staffDetails.isLoaded);

  useEffect(() => {
    if (campaignId && campaignDetails.selectedCampaign.key) initializeFields();
  }, [campaignDetails.selectedCampaign.key]);

  useEffect(() => {
    let { isHTMLTemplateEnabled } = campaignDetails.selectedCampaign;
    if (isHTMLTemplateEnabled && !popupDetails.popupProps.display)
      setIsHTMLTemplate(isHTMLTemplateEnabled);
  }, [popupDetails.popupProps]);

  useEffect(() => {
    const minTimer = setTimeout(() => {
      setIsMinTimeElapsed(true);
    }, 1000);
    return () => clearTimeout(minTimer);
  }, []);

  useEffect(() => {
    resetFields();
    ReactTooltip.rebuild();
    if (campaignNameRef && campaignNameRef.current && isLoaded) {
      campaignNameRef.current.focus();
    }
  }, [isLoaded]);
  useEffect(() => {
    if((campaignId && Object.keys(campaignDetails.selectedCampaign).length > 0 && isMintimeElapsed)
      || (!campaignId && staffDetails.isLoaded && isMintimeElapsed)) 
        setIsLoaded(true);
  },[staffDetails.isLoaded, campaignDetails.selectedCampaign.key, isMintimeElapsed])

  useEffect(() => {
    if(campaignDetails.selectedCampaign.invalids && Object.keys(campaignDetails.selectedCampaign.invalids).length >0)
      setInvalids(campaignDetails.selectedCampaign.invalids)
  },[campaignDetails.selectedCampaign.invalids])

  useEffect(()=>{
    if(campaignDetails.selectedCampaign.message && !campaignDetails.selectedCampaign.message.includes("add_content"))
        toggleAddContent(false);
    else
      toggleAddContent(true);
  }, [campaignDetails.selectedCampaign.message]);

  const resetFields = () => {
    let {
      campaignFromName = "",
      campaignFromImage = "",
      senderId = "",
      campaignName = "",
      subject = "",
      message = ""
    } = campaignDetails.selectedCampaign;
    setCampaignFromName(campaignFromName);
    setCampaignFromImage(campaignFromImage);
    setCampaignName(campaignName);
    setCampaignFrom(senderId);
    setSubject(subject);
  };

  const initializeFields = () => {
    if (isCampaignFetched && campaignData && Object.keys(campaignDetails.selectedCampaign).length > 0) {
      let {
        campaignFromName,
        campaignFromImage,
        senderId,
        campaignName,
        subject,
        fallBackValues,
        message
      } = campaignDetails.selectedCampaign;
      console.info("Initializing ", campaignFromName);
      setCampaignFromName(campaignFromName);
      setCampaignFromImage(campaignFromImage);
      setCampaignName(campaignName);
      setCampaignFrom(senderId);
      setSubject(subject);
    }
    if (localStorage.getItem(IS_CAMPAIGN_FROM_DASHBOARD) !== STRINGIFIED_BOOL.FALSE) {
      dispatch(campaignActions.updateCampaignFromDashboard(true));
    } else {
      dispatch(campaignActions.updateCampaignFromDashboard(false));
    }
  };

  useEffect(() => {
    if (!isStaffLoaded && staffDetails.isLoaded && Object.keys(campaignDetails.selectedCampaign).length>0) {
      // campaignDetails.selectedCampaign.fallBackValues = getFallBackValues(
      //   campaignDetails.selectedCampaign.message
      // );
      campaignDetails.selectedCampaign.message = getReplacedMessage(
        campaignDetails.selectedCampaign.message
      );
      dispatch(
        campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
      );
    }
  }, [staffDetails.isLoaded]);
  useEffect(() => {
    if (
      campaignName &&
      campaignName.trim() &&
      campaignFrom &&
      campaignFrom.trim() &&
      !showAddContent &&
      (isAllFallbacksAvailable() ||
        isHTMLTemplate) &&
      !hasMatchingElements(
        campaignData,
        "campaignName",
        campaignName,
        campaignId
      ) &&
      (!isEmailTypeCampaign() || (subject && subject.trim()))
    )
      setCanEnableNextButton(true);
    else setCanEnableNextButton(false);
  }, [
    campaignFrom,
    campaignName,
    subject,
    campaignDetails.selectedCampaign.fallBackValues
      ? Object.keys(campaignDetails.selectedCampaign.fallBackValues)
      : "",
    campaignDetails.selectedCampaign.fallBackValues
      ? Object.values(campaignDetails.selectedCampaign.fallBackValues)
      : "",
  ]);
  const getStaffDropdownData = () => {
    const projectId = getProjectId(props.params.projectkey);
    const staffData = staffDetails.dashboardAgents[projectId];
    const currentStaffdetails = staffDetails.currentStaffStatus;
    let staffListToRender = [];
    let currentAgentOption = getCurrentAgentOption(
      "",
      staffData,
      currentStaffdetails.key
    );
    staffListToRender.push(currentAgentOption);
    for (const key in staffData) {
      staffData[key].fullName = getFullName(staffData[key]);
      let staff = staffData[key];
      if (
        staff.status === "ACTIVE" &&
        staff.key !== campaignFrom &&
        key != currentStaffdetails.key
      ) {
        staffListToRender.push(
          <li>
            <a
              data-staff_key={key}
              data-photo_url={staff.photoUrl}
              data-staff_name={staff.firstName}
              key={key}
              onClick={selectStaff}
            >
              <figure>
                <img onError={imageError} src={staff.photoUrl} />
              </figure>
              {`${staff.fullName} ${
                key === currentStaffdetails.key ? "(Me)" : ""
              }`}
            </a>
          </li>
        );
      }
    }
    return <ul>{staffListToRender}</ul>;
  };
  const isAllFallbacksAvailable = () => {
    let fallBackKeys = Object.keys(
      campaignDetails.selectedCampaign.fallBackValues
    );
    for (let key of fallBackKeys)
      if (
        !campaignDetails.selectedCampaign.fallBackValues[key] ||
        campaignDetails.selectedCampaign.fallBackValues[key].length < 3
      )
        return false;
    return true;
  };
  const getProperDropDownData = () => {
    return searchValue.length ? getFilteredStaffList() : getStaffDropdownData();
  };

  const randomStaffData = () => {
    const projectId = getProjectId(props.params.projectkey);
    const staffData = staffDetails.dashboardAgents[projectId];
    let randomStaff = undefined;

    for (const key in staffData) {
      let staff = staffData[key];
      if (key !== campaignFrom && staff.status === "ACTIVE" && !randomStaff) {
        randomStaff = staff;
      }
    }
    return randomStaff;
  };

  const selectStaff = (e) => {
    const { staff_name, staff_key, photo_url } = e.target.dataset;
    setCampaignFrom(staff_key);
    setCampaignFromName(staff_name);
    setCampaignFromImage(photo_url);
    setSearchValue("");

    campaignDetails.selectedCampaign.campaignFrom = staff_key;
    campaignDetails.selectedCampaign.campaignFromName = staff_name;
    campaignDetails.selectedCampaign.senderId = staff_key;
    campaignDetails.selectedCampaign.campaignFromImage = photo_url;
    dispatch(
      campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
    );

    e.preventDefault();
    setShowStaffDropDown(false);
  };

  const toggleStaffDropDown = (e) => {
    if(!isFromFocused)
      return;
    if (
      !staffSearchRef.current.contains(e.target) &&
      !e.target.dataset.element != "searchInput"
    ) {
      setShowStaffDropDown(!showStaffDropDown);
      if ("campaignFrom" in invalids) {
        delete invalids["campaignFrom"];
        setInvalids(invalids);
      }
    }
  };

  const getSelectedAgentName = () => {
    let { key } = staffDetails.currentStaffStatus;
    if (campaignFrom) {
      if (key === campaignFrom) return `${campaignFromName} (Me)`;
      else return campaignFromName || "";
    }
    return "";
  };

  const getSenderFullname = () => {
    let projectId = getProjectId(props.params.projectkey);
    let staffMap = staffDetails.dashboardAgents[projectId];
    let { key } = staffDetails.currentStaffStatus;
    if (campaignFrom) {
      if (key === campaignFrom) return   `${getFullName(staffMap[campaignFrom])} (Me)`;
      else return `${getFullName(staffMap[campaignFrom])}` || "";
    }
    return "";
  };

  const getSelectedAgentImage = () => {
    let { photoUrl = "" } = staffDetails.currentStaffStatus;
    let { campaignFrom : from , campaignFromImage : fromImage , senderId } = campaignDetails.selectedCampaign;
    if (from || senderId) {
      return fromImage || photoUrl;
    } else {
      if (photoUrl) {
        return `${photoUrl}`;
      }
    }
  };

  const imageError = (e) => {
    e.target.style.display = "none";
  };  

  const toggleAddContent = (shwAddContent) => {
    setShowAddContent(shwAddContent);
  };

  const getAddContentDiv = () => {
    return showAddContent ? (
      <div onClick={toggleAddContent} className="upload-btn">
        + Add content
      </div>
    ) : (
      ""
    );
  };

  const onCampaignNamechange = () => {
    let campaignNameFromRef = campaignNameRef.current.value;
    campaignDetails.selectedCampaign.campaignName = campaignNameFromRef;
    delete campaignDetails.selectedCampaign.invalids;
    setCampaignName(campaignNameFromRef);
    dispatch(
      campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
    );
  };

  const onCampaignDataChange = (currentCampaignData) => {
    
    if(currentCampaignData === '<p><br></p>')
      return;

    const fallBackValuesMap = {
      "First Name": "{{firstName}}",
      "Full Name": "{{fullName}}",
      "Last Name": "{{lastName}}",
      "Company Name": "{{companyName}}",
    };

    if (typeof currentCampaignData === "string") {
      let newFallBackValues = {};
      currentCampaignData = currentCampaignData.replace(
        /First Name|Last Name|Company Name|Full Name/gi,
        function (match) {
          newFallBackValues[fallBackValuesMap[match]] = "";
          return fallBackValuesMap[match];
        }
      );
      if (!newFallBackValues["{{firstName}}"])
        newFallBackValues["{{firstName}}"] = "there";

      for (let key in newFallBackValues) {
        if (
          campaignDetails.selectedCampaign &&
          campaignDetails.selectedCampaign.fallBackValues &&
          campaignDetails.selectedCampaign.fallBackValues[key]
        )
          newFallBackValues[key] =
            campaignDetails.selectedCampaign.fallBackValues[key];
      }

      let newFallBackKeys = Object.keys(newFallBackValues);
      let oldFallBackKeys = campaignDetails.selectedCampaign.fallBackValues
        ? Object.keys(campaignDetails.selectedCampaign.fallBackValues)
        : [];
      let removedFallbacks = getArrayDifference(
        oldFallBackKeys,
        newFallBackKeys
      );

      if (removedFallbacks.includes(fallBackValuesMap[selectedFallBackValue]))
        setSelectedFallBackValue("");

      campaignDetails.selectedCampaign.fallBackValues = newFallBackValues;

      campaignDetails.selectedCampaign.message = currentCampaignData;

      dispatch(
        campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
      );
    }
  };

  const getCampaignFromFieldCssClass = () => {
    let className = "cs-dropdwn cs-dropdwn-search";
    if (showStaffDropDown) className += " open";
    if (!getSelectedAgentName()) className += " cs-placeholder-txt";
    if ("campaignFrom" in invalids) className += " cs-error-field";
    return className;
  };

  const change = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let mapping = {
      "First Name": "{{firstName}}",
      "Full Name": "{{fullName}}",
      "Last Name": "{{lastName}}",
      "Company Name": "{{companyName}}",
    };
    let currentFallBackValues = campaignDetails.selectedCampaign.fallBackValues;
    let selectedValue = mapping[selectedFallBackValue];
    currentFallBackValues[selectedValue] = fallbackInputRef.current.value;
    campaignDetails.selectedCampaign.fallBackValues = currentFallBackValues;
    dispatch(
      campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
    );
  };

  const onCampaignSubjectChange = (e) => {
    let value = e.target.value;
    campaignDetails.selectedCampaign.subject = value;
    setSubject(value);
    dispatch(
      campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign)
    );
  };

  const isEmailTypeCampaign = () => {
    return campaignDetails.selectedCampaign.typeOfMessage === "EMAIL";
  };
  const handleKeyPressForFallBack = (event) => {
    if (
      event.which === 13 ||
      event.keyCode === 13 ||
      event.which === 27 ||
      event.keyCode === 27
    ) {
      if (fallbackInputRef.current.value.length >= 3){
        setSelectedFallBackValue("");
      } else {
        setInvalids(validateFallbackValues());
      }
      
    }
  };

  const updateFallback = (val) => {
    setSelectedFallBackValue(val);
    // let currentFallBackValues = fallBackValues;
    // if (!currentFallBackValues.hasOwnProperty(val))
    //   currentFallBackValues[val] = "";
    // setFallBackValues(currentFallBackValues);
  };

  const updateWidthAndHeigt = (left, top) => {
    setLeftValue(left);
    setTopValue(top);
  };

  const handleClickOutside = (e) => {
    if (
      fallbackPopupRef.current &&
      !fallbackPopupRef.current.contains(e.target) &&
      fallbackInputRef.current
    ) {
      if (
        !Object.keys(campaignDetails.selectedCampaign.fallBackValues).includes(
          fallbackInputRef.current.dataset["fallbackValue"]
        )
      ) {
        setSelectedFallBackValue("");
      } else if (fallbackInputRef.current.value.length >= 3) {
        setSelectedFallBackValue("");
      } else {
        setInvalids(validateFallbackValues());
      }
    }

    if (
      staffDropdownRef.current &&
      !staffDropdownRef.current.contains(e.target) &&
      staffSearchRef.current &&
      !staffSearchRef.current.contains(e.target)
    ) {
      setShowStaffDropDown(false);
    }
  };

  const handleKeyDown = (e) => {
    if (event.key === "Escape" && event.keyCode == 27) {
      if (campaignNameIsFocused) campaignNameRef.current.blur();
      if (subjectFieldIsFocused) campaignSubjectRef.current.blur();
      if (showStaffDropDown) setShowStaffDropDown(false);
      if (selectedFallBackValue) setSelectedFallBackValue(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const getFallbackComponent = () => {
    let mapping = {
      "First Name": "{{firstName}}",
      "Full Name": "{{fullName}}",
      "Last Name": "{{lastName}}",
      "Company Name": "{{companyName}}",
    };
    let selectedValue = mapping[selectedFallBackValue];
    return selectedFallBackValue ? (
      <code style={{ left: leftValue, top: topValue }} ref={fallbackPopupRef}>
        <span>
          {`Fallback in case there isn’t a `}
          <span
            style={{ textTransform: "lowercase" }}
          >{`${selectedFallBackValue}`}</span>
        </span>

        <input
          autoFocus
          ref={fallbackInputRef}
          onChange={change}
          className={invalids[selectedValue] ? "fallback-error" : ""}
          onKeyPress={handleKeyPressForFallBack}
          type="text"
          name=""
          value={
            campaignDetails.selectedCampaign.fallBackValues[selectedValue] || ""
          }
          onFocus={fallbackValueOnFocus}
          placeholder="Fallback value"
          data-fallback-value={selectedValue || ""}
        />
      </code>
    ) : (
      ""
    );
  };

  useEffect(() => {
    let agentName = getSelectedAgentName() || "";
    if (agentName && agentName.trim()) {
      let properName = agentName.replace("(Me)", "");
      agentName = properName;
    } else agentName = getCurrentStaffName() || "Agent Name";
    setSenderName(agentName);
  }, [getSelectedAgentName()]);

  useEffect(() => {
    let agentImage = getSelectedAgentImage() || "";
    setSenderImage(agentImage);
  }, [getSelectedAgentImage()]);

  const getCurrentStaffName = () => {
    const currentStaffdetails = staffDetails.currentStaffStatus;
    let { firstName } = currentStaffdetails;
    return firstName || "";
  };

  const getCurrentStaffImage = () => {
    const currentStaffdetails = staffDetails.currentStaffStatus;
    let { photoUrl } = currentStaffdetails;
    return photoUrl && !photoUrl.includes("null") ? photoUrl : "";
  };
  const getCurrentAgentOption = (value, staffMap, currentStaffId) => {
    if (staffMap && currentStaffId && staffMap[currentStaffId]) {
      staffMap[currentStaffId].fullName = getFullName(staffMap[currentStaffId]);
      if (
        staffMap[currentStaffId].status == "ACTIVE" &&
        staffMap[currentStaffId].fullName
          .toLowerCase()
          .includes(value.toLowerCase())
      ) {
        let toBeReturned = (
          <li>
            <a
              data-staff_key={currentStaffId}
              data-photo_url={staffMap[currentStaffId].photoUrl}
              data-staff_name={staffMap[currentStaffId].firstName}
              key={currentStaffId}
              onClick={selectStaff}
            >
              <figure>
                <img
                  onError={imageError}
                  src={staffMap[currentStaffId].photoUrl}
                />
              </figure>
              {`${staffMap[currentStaffId].fullName} (Me)`}
            </a>
          </li>
        );
        return toBeReturned;
      }
    }
  };
  const getFilteredStaffList = () => {
    let value = searchValue;
    let options = [];
    const projectId = getProjectId(props.params.projectkey);
    let staffMap = staffDetails.dashboardAgents[projectId];
    const currentStaffdetails = staffDetails.currentStaffStatus;
    let currentAgentOption = getCurrentAgentOption(
      value,
      staffMap,
      currentStaffdetails.key
    );
    options.push(currentAgentOption);
    for (let staffId in staffMap) {
      staffMap[staffId].fullName = getFullName(staffMap[staffId]);
      if (
        staffMap[staffId].status == "ACTIVE" &&
        staffMap[staffId].fullName
          .toLowerCase()
          .includes(value.toLowerCase()) &&
        staffId != currentStaffdetails.key
      ) {
        options.push(
          <li>
            <a
              data-staff_key={staffId}
              data-photo_url={staffMap[staffId].photoUrl}
              data-staff_name={staffMap[staffId].firstName}
              key={staffId}
              onClick={selectStaff}
            >
              <figure>
                <img onError={imageError} src={staffMap[staffId].photoUrl} />
              </figure>
              {`${staffMap[staffId].fullName} `}
            </a>
          </li>
        );
      }
    }
    return <ul>{options}</ul>;
  };

  const updateSearchValue = (event) => {
    let value = event.target.value;
    setSearchValue(value);
  };

  const onSearchClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const getSearchTextBox = () => {
    const projectId = getProjectId(props.params.projectkey);
    let staffMap = staffDetails.dashboardAgents[projectId];
    let staffLength = 0;
    for (const key in staffMap) {
      let staff = staffMap[key];
      if (staff.status === "ACTIVE") {
        staffLength++;
      }
    }

    return staffLength >= 6 ? (
      <div onClick={onSearchClick}>
        <input
          data-element="searchInput"
          onChange={updateSearchValue}
          type="text"
          placeholder="Search"
          value={searchValue}
        />
        <SearchIcon />
      </div>
    ) : (
      ""
    );
  };
  const validateCampaignName = () => {
    let invalidFields = Object.assign({}, invalids);
    if (!campaignName.trim())
      invalidFields["campaignName"] = "Enter campaign name";
    else if (campaignName.trim() && campaignName.trim().length > 250)
      invalidFields["campaignName"] =
        "Campaign name is too big. Please limit within 250 Characters";
    else if (
      hasMatchingElements(
        campaignData,
        "campaignName",
        campaignName.trim(),
        campaignId
      )
    )
      invalidFields["campaignName"] =
        "Campaign with the same name already exists";
    return invalidFields;
  };
  const validateCampaignFrom = () => {
    let invalidFields = Object.assign({}, invalids);
    if (!campaignFrom) invalidFields["campaignFrom"] = "Please select an agent";
    return invalidFields;
  };
  const validateSubjectFields = () => {
    let invalidFields = Object.assign({}, invalids);
    if (!subject.trim()) invalidFields["subject"] = "Please enter subject";
    return invalidFields;
  };
  const validateFallbackValues = () => {
    let invalidFields = Object.assign({}, invalids);
    for (let key in campaignDetails.selectedCampaign.fallBackValues)
      if (
        !campaignDetails.selectedCampaign.fallBackValues[key] ||
        campaignDetails.selectedCampaign.fallBackValues[key].length < 3
      )
        invalidFields[key] = true;
    return invalidFields;
  };
  const validateFields = () => {
    let invalidFields = {};
    invalidFields = Object.assign({}, validateCampaignName());
    invalidFields = Object.assign(invalidFields, validateCampaignFrom());
    invalidFields = Object.assign(invalidFields, validateFallbackValues());
    if (isEmailTypeCampaign())
      Object.assign(invalidFields, validateSubjectFields());
    setInvalids(invalidFields);
    if (
      Object.keys(invalidFields).length == 0 &&
      isEmailDesktopType(campaignDetails)
    )
      dispatch(campaignActions.updatePreviewType("Mobile"));
  };
  const nextButtonClick = ()=>{
    validateFields();
  }
  const getCampaignNameFieldCssClass = () => {
    let campaignNameFieldCssClass = "cs-input-field";
    if (campaignNameIsFocused) campaignNameFieldCssClass += " cs-active-field";
    else if ("campaignName" in invalids)
      campaignNameFieldCssClass += " cs-error-field";
    return campaignNameFieldCssClass;
  };
  const campaignNameOnFocus = () => {
    setCampaignNameIsFocused(true);
    if ("campaignName" in invalids) {
      delete invalids["campaignName"];
      setInvalids(invalids);
    }
  };
  const campaignNameOnBlur = (event) => {
    setCampaignNameIsFocused(false);
    let invalidFields = validateCampaignName();
    setInvalids(invalidFields);
  };
  const fallbackValueOnFocus = (e) => {
    let fallbackValue = e.target.dataset.fallbackValue;
    if (fallbackValue in invalids) {
      delete invalids[fallbackValue];
      setInvalids(invalids);
    }
  };
  const onCancel = () => {
    if (campaignDetails.isCampaignFromDashboard && campaignDetails.selectedCampaign.segmentIds && campaignDetails.selectedCampaign.segmentIds[0] ) {
      browserHistory.push({
        pathname: "/app/" + props.params.projectkey + "/contacts/dashboard/" + campaignDetails.selectedCampaign.segmentIds[0],
      });
    } else {
    browserHistory.push({
      pathname: "/app/" + props.params.projectkey + "/campaign/" + (campaignDetails.campaignSideBarType !== null ? campaignDetails.campaignSideBarType : "all"),
    });
    }
    ReactTooltip.hide();
    //dispatch(campaignActions.addSelectedCampaign({}));
  };
  const getSubjectFieldCssClass = () => {
    let subjectFieldCssClass = "cs-input-field";
    if (subjectFieldIsFocused) subjectFieldCssClass += " cs-active-field";
    else if ("subject" in invalids) subjectFieldCssClass += " cs-error-field";
    return subjectFieldCssClass;
  };

  const subjectOnFocus = () => {
    setSubjectFieldIsFocused(true);
    if ("subject" in invalids) {
      delete invalids["subject"];
      setInvalids(invalids);
    }
  };
  const subjecteOnBlur = () => {
    setSubjectFieldIsFocused(false);
    let invalidsFields = validateSubjectFields();
    setInvalids(invalidsFields);
  };
  const campaignFromFocused = () => {
    setIsFromFocused(true);
    if (!showStaffDropDown) setShowStaffDropDown(true);
    if ("campaignFrom" in invalids) {
      delete invalids["campaignFrom"];
      setInvalids(invalids);
    }
  };

  const getCampaignTitle = () => {
    if(!props.params.campaignId)
      return "New Campaign Title"
    return "Campaign Title"
  }

  const toggleReplyOption = () =>{
    campaignDetails.selectedCampaign.isInteractableDisabled = !campaignDetails.selectedCampaign.isInteractableDisabled;
    dispatch(campaignActions.addSelectedCampaign(campaignDetails.selectedCampaign));
  }

  const getClassNameOfToggle = () => {
    return (
      "reply-option-toggle cs-campaign cs-switch-btn " +
      (!campaignDetails.selectedCampaign.isInteractableDisabled ? "" : "off")
    );
  };

  const getStyleOfReplyOption = () => {
    return {
      display: isAnnouncementCampaign(campaignDetails) ? "flex" : "none",
    };
  };

  const getCSSettingsWrpStyle = () => {
    return {"height" : "auto" , "top" : "0" , "bottom" : "0"};
  }

  if(isLoaded) {
    return (
      <div className={"cs-settings-wrp campaign-wrp " + (miniNavBar ? "slide-col3" : "")}>
        <section className="widget-configuration">
          <h3>Design your campaign</h3>
          <p>Fine-tune the elements of your campaign</p>
          <div className="cs-widget-config">
            <div>
              <label>{getCampaignTitle()}</label>
              <p>Name your campaign to identify it easily</p>
              <div className={getCampaignNameFieldCssClass()}>
                <input
                  tabIndex={1}
                  id={"campaignName"}
                  onChange={onCampaignNamechange}
                  onFocus={campaignNameOnFocus}
                  onBlur={campaignNameOnBlur}
                  spellCheck="false"
                  ref={campaignNameRef}
                  type="text"
                  value={campaignName}
                  placeholder={getCampaignTitle()}
                  autoComplete="off"
                />
                {"campaignName" in invalids ? (
                  <span id="campaignNameError">
                    <ErrorIcon />
                    {invalids["campaignName"]}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div
            className="cs-widget-config"
            style={isEmailTypeCampaign() ? {} : { display: "none" }}
          >
            <div>
              <label>Subject</label>
              <p>The subject line of your email</p>
              <div className={getSubjectFieldCssClass()}>
                <input
                  type="text"
                  tabIndex={2}
                  onFocus={subjectOnFocus}
                  onBlur={subjecteOnBlur}
                  onChange={onCampaignSubjectChange}
                  ref={campaignSubjectRef}
                  value={subject}
                  placeholder="Please enter the subject"
                />
                {"subject" in invalids ? (
                  <span>
                    <ErrorIcon />
                    {invalids["subject"]}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="cs-widget-config">
            <div>
              <label>From</label>
              <p>Specify the sender of the campaign</p>
              <div
                ref={staffDropdownRef}
                className={getCampaignFromFieldCssClass()}
                onMouseDown={toggleStaffDropDown}
                tabIndex={3}
                onFocus={campaignFromFocused}
              >
                <p id={"campaignFromName"} data-elementname="selected-timer">
                  {getSenderFullname() || "Select From Name"}
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 9 6"
                  id="dwn-uparrow-icon"
                >
                  <path
                    fill="none"
                    fill-rule="evenodd"
                    stroke="#595959"
                    d="M9 5L4.68 1 0 5"
                  ></path>
                </svg>
                <div ref={staffSearchRef}>
                  {getSearchTextBox()}
                  {/* <ul>{getStaffDropdownData()}</ul> */}
                  {getProperDropDownData()}
                </div>
                {"campaignFrom" in invalids ? (
                  <span>
                    <ErrorIcon />
                    {invalids["campaignFrom"]}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="disable-reply" style={getStyleOfReplyOption()}>
            <div>
              <label>Allow Replies</label>
              <p>
                Enable/disable the reply option to allow/deny recipients to
                respond to your announcement.
              </p>
            </div>
            <div
              className={getClassNameOfToggle()}
              onClick={toggleReplyOption}
              onKeyDown={() => {}}
            ></div>
          </div>
          <div className="cs-widget-config cs-btn-fixed">
            <div>
              <label>Campaign content</label>
              <p>Enter the message that users will see</p>
              <div
                onInput={onCampaignDataChange}
                ref={campaignContentRef}
                className={
                  "compaign-content " +
                  (campaignDetails.campaignType == "ANNOUNCEMENT"
                    ? "announcement-content"
                    : "")
                }
              >
                {/* {getAddContentDiv()} */}
                {/* <TemplateComponent /> */}
                {getFallbackComponent()}
                <CampaignTextEditor
                  params={props.params}
                  onCampaignDataChange={onCampaignDataChange}
                  updateFallback={updateFallback}
                  updateWidthAndHeigt={updateWidthAndHeigt}
                  agentName={senderName}
                  agentImage={senderImage}
                  projectName={projectName}
                  toggleAddContent={toggleAddContent}
                  selectedFallBackValue={selectedFallBackValue}
                />
              </div>
            </div>
          </div>
          <div className="campaign-btn-wrp cs-btn-bottom">
            <Link className="cs-btn cs-decline-btn" onClick={onCancel}>
              {" "}
              Cancel{" "}
            </Link>
            <Link
              id={"nextButton"}
              className={
                canEnableNextButton
                  ? "cs-btn cs-primary-btn"
                  : "cs-btn cs-disabled-btn"
              }
              style={{ opacity: !canEnableNextButton ? "0.5" : "1.0" }}
              to={
                canEnableNextButton
                  ? props.params.campaignId
                    ? "/app/" +
                      props.params.projectkey +
                      "/campaign/preview/" +
                      props.params.campaignId
                    : "/app/" + props.params.projectkey + "/campaign/preview"
                  : ""
              }
              onClick={nextButtonClick}
            >
              {" "}
              Next{" "}
            </Link>
          </div>
        </section>
        <CampaignQuickPreview
          color={reducerColor}
          agentName={senderName}
          agentImage={senderImage}
          randomStaffData={randomStaffData}
          showFallbackValues={true}
          params={props.params}
        />
      </div>
    );
  }
  return (
    <div className={"cs-settings-wrp " + (miniNavBar ? "slide-col3" : "")}>
    <section class="widget-configuration">
        <HeadingLoader></HeadingLoader>
        <ParaLoader></ParaLoader>
        <InputLoader></InputLoader>
        <InputLoader></InputLoader>
        <InputLoader></InputLoader>
    </section>
  </div>
  //end of the first part)
   )
};

export default CampaignDesign;
