import axios from 'axios'
import { config } from 'src/config'
import { handleServerApiError } from 'src/utils/api/interceptor/error'
import { provideXOrigin } from 'src/utils/api/interceptor/origin'

export const appClient = axios.create({ baseURL: config.chatsupportApiUrl })
appClient.interceptors.request.use(provideXOrigin)
appClient.interceptors.response.use(undefined, handleServerApiError)

export const authClient = axios.create({ baseURL: config.authApiUrl })

export const rtmClient = axios.create({ baseURL: config.rtmApiUrl })

export const googleClient = axios.create({ baseURL: config.googleApiUrl })
