import {
  App as Wizard , ReturningFlow
} from "@adaptavant/chatsupport-onboarding";
import React from "react";
import { IndexRedirect, IndexRoute, Redirect, Route } from "react-router";
import CampaignDesign from "./../app/campaign/components/CampaignDesign.jsx";
import CampaignLayout from "./../app/campaign/components/CampaignLayout";
import CampaignPreview from "./../app/campaign/components/CampaignPreview";
import NewCampaignLayout from "./../app/campaign/components/NewCampaignLayout";
import PublishCampaign from "./../app/campaign/components/PublishCampaign";
import CampaignDashboard from "./../app/campaign/containers/CampaignDashboard";
import AuthenticatedContainer from "./commons/AuthenticatedContainer.js";
import { Authenticator } from "./commons/Authenticator.js";
import ChatSection from "./components/chat/ChatSection.jsx";
import ConversationSection from "./components/chat/ConversationSection.jsx";
import ConversationFilterComponent from "./components/chat/filters/ConversationFilterComponent.jsx";
import DemoComponent from "./components/demo/DemoComponent.jsx";
import DirectoryContainerHooks from "./components/myteam/DirectoryContainerHooks";
import TeamEditProfileContainer from "./components/myteam/editpopup/TeamEditProfileContainer";
import InviteStaffContainer from "./components/myteam/InviteStaffContainer";
import TeamProfileContainer from "./components/myteam/TeamProfileContainer";
// import { WidgetGuide } from "./components/setup/WidgetGuide.jsx";
// import { EmbedInfo } from "./components/setup/EmbedInfo.jsx";
import { EmbedSetup } from "./components/setup/EmbedSetup.jsx";
import SetupComponent from "./components/widgetsetup/SetupComponent.jsx";
import ContactsLayout from "./contacts/layouts/ContactsLayout";
import ContactsWrapper from "./contacts/wrapper/ContactsWrapper.js";
import AnywhereWorksContainer from "./containers/AnywhereworksContainer.js";
import Login from "./containers/LoginContainer";
import ManageEmailOptOutContainer from "./containers/ManageEmailOptOutContainer";
import Onboard from "./containers/OnboardingContainer";
import OptedOutAllContainer from "./containers/OptedOutAllContainer";
import OverflowComponent from "./containers/OverflowContainer.js";
import ResetPassword from "./containers/ResetPasswordContainer";
//import ChatLayout from './layouts/ChatLayout';
import StaffList from "./containers/StaffListContainer";
import SwitchProfileComponent from "./containers/SwitchProfileContainer";
import AdminAccessComponent from "./containers/AdminAccessContainer";
import GuestsComponent from "./components/chat/GuestsComponent.jsx";
import UnsubscribeEmailContainer from "./containers/UnsubscribeEmailContainer";
import VisitorQueueListContainer from "./containers/VisitorQueueListContainer";
import AppLayout from "./layouts/AppLayout";
import ChatLayout from "./layouts/ChatLayout";
import SetupLayout from "./layouts/SetupLayout";
import BusinessHoursContainer from "./settings/businessHours/BusinessHoursContainer";
import ChatRoutingComp from "./settings/chatRouting/ChatRoutingComp.jsx";
import CustomiseWidget from "./settings/customisewidget/CustomiseWidgetContainer";
import IntegrationPage from "./settings/customisewidget/IntegrationContainer";
import NewSettingsLayout from "./settings/layouts/SettingsLayout";
import WidgetSettingsLayout from "./settings/layouts/WidgetSettingsLayout";
import ChooseProfileComponent from "./settings/manageProfile/ChooseProfileContainer";
import ManageProfileComponent from "./settings/manageProfile/ManageProfileComponent.jsx";
import ProfileContainer from "./settings/myprofile/ProfileContainer";
import OverflowSettingsComp from "./settings/overflowConfiguration/OverflowConfigurationContainer";
import PreviewUrlGeneratorComponent from "./settings/previewUrl/PreviewUrlGeneratorComponent.jsx";
import WidgetBehaviourContainer from "./settings/widgetbehaviour/WidgetBehaviourContainer";
import WidgetConfiguration from "./settings/widgetconfigurationsettings/WidgetConfiguration.jsx";
import YourWidgetContainer from "./settings/yourwidget/YourWidgetsContainer";
import BillingComponent from "./settings/billing/BillingComponent.jsx";

import QueueContainer from "./containers/QueueContainer";
import DetailedCampaignStats from "./campaign/components/DetailedCampaignStats.js";
import ConversationComponent from "./aw_recents/components/ConversationComponent.jsx";
import SearchWrapperComponent from "./components/chat/SearchWrapperComponent.jsx";

console.log("The comp :" , ReturningFlow);

console.log("process.env.NODE_ENV=====>>>", process.env.NODE_ENV);
console.log("process.env.NODE_ENV=====>>>", process.env.isSettingsComponent);

// Define routes
export default (
  <Route path="/">
    <IndexRoute component={Login} />
    <Route path="signup-success" component={Wizard} />
    <Route path=":widgetId/widget-setup" component={Wizard} />
    <Route path="app/setup/:widgetId" component={Wizard} />
    <Route path="app/install/:widgetId" component={Wizard} />

    <Route path="session-step-2" component={Wizard} />
    <Route path="session-step-3" component={Wizard} />

    <Route path="overflow" component={OverflowComponent} />
    <Route path="overflow/:visitorOption" component={OverflowComponent} />
    
    <Route path="anywhereworks" component={AnywhereWorksContainer} />
    <Route
      path="anywhereworks/:visitorOption"
      component={AnywhereWorksContainer}
    />

    <Route path="signup" component={Wizard} />
    <Route path="login" component={Login} />
    <Route path="onboard" component={Onboard} />
    <Route path="reset/password" component={ResetPassword} />
    <Route
      path="email/optout/:projectId/:visitorInfo"
      component={ManageEmailOptOutContainer}
    />
    <Route
      path="email/unsubscribe/:projectId/:visitorInfo"
      component={UnsubscribeEmailContainer}
    />
    <Route
      path="email/optedOutAll/:projectId/:visitorInfo"
      component={OptedOutAllContainer}
    />
    <Route path="customer/preview/:viewOptions" component={DemoComponent} />
    
    <Route path="" component={AuthenticatedContainer} onEnter={Authenticator}>
    <Route path="aw/recent" component={ConversationComponent} />
      <Route path="choose/profile" component={ChooseProfileComponent} />
      <Route path="switch/profile" component={SwitchProfileComponent} />
      <Route path="admin" component={AdminAccessComponent} />
      <Route path="preview" component={DemoComponent}>
        <Route path=":viewOptions" component={DemoComponent} />
      </Route>
      <Route path="app/:projectkey" component={AppLayout}>
        <IndexRedirect to="chat" />
        <Route path="widget" component={SetupLayout}>
          <IndexRedirect to="embed/setup" />
          <Route path="embed/setup" component={SetupComponent} />
        </Route>
        <Route path="campaign" component={CampaignLayout}>
          <IndexRedirect to="all" />
          <Route path=":type" component={CampaignDashboard} />
        </Route>
        <Route path="campaign" component={NewCampaignLayout}>
          <IndexRedirect to="design" />
          <Route path="design" component={CampaignDesign} />
          <Route path="preview" component={CampaignPreview} />
          <Route path="publish" component={PublishCampaign} />
          <Route path="design/:campaignId" component={CampaignDesign} />
          <Route path="preview/:campaignId" component={CampaignPreview} />
          <Route path="publish/:campaignId" component={PublishCampaign} />
          <Route path=":campaignId/:stats/:type" component={DetailedCampaignStats}/>
        </Route>
        <Route path="settings">
          <IndexRedirect to="myprofile" />
          <Route path="manage/widget" component={NewSettingsLayout}>
            <IndexRedirect to="yours" />
            <Route path="yours" component={YourWidgetContainer} />
          </Route>
        </Route>
        <Route path="settings">
          <IndexRedirect to="myprofile" />
          <Route path="widget" component={WidgetSettingsLayout}>
            <IndexRedirect to="configuration" />
            <Route path="configuration" component={WidgetConfiguration} />
            <Route path="newwidget" component={WidgetConfiguration} />
            <Route path="customize" component={CustomiseWidget} />
            <Route path="integration" component={IntegrationPage} />
            <Route path="behaviour" component={WidgetBehaviourContainer}>
              <IndexRedirect to="prompts" />
              <Route path="prompts" component={WidgetBehaviourContainer} />
              <Route path="visibility" component={WidgetBehaviourContainer} />
              <Route path="surveyform" component={WidgetBehaviourContainer} />
              <Route
                path="disconnecttimer"
                component={WidgetBehaviourContainer}
              />
            </Route>
            <Route path="assignment" component={ChatRoutingComp} />
            <Route path="businesshours" component={BusinessHoursContainer} />
            <Route path="demo" component={PreviewUrlGeneratorComponent} />
          </Route>
        </Route>
        <Route path="settings" component={NewSettingsLayout}>
          <IndexRedirect to="myprofile" />
          <Route path="myprofile" component={ProfileContainer} />
          <Route path="myprofile/edit" component={ProfileContainer} />
          <Route path="myprofile/workinghours" component={ProfileContainer} />
          <Route
            path="myprofile/workinghours/edit"
            component={ProfileContainer}
          />
          <Route path="notifications" component={ProfileContainer} />
          <Route path="billing" component={BillingComponent} />
          <Route path="team" component={StaffList} />
          <Route path="overflow" component={OverflowSettingsComp} />
          <Route path="switchaccount" component={ManageProfileComponent} />
        </Route>
        <Route path="directory" component={DirectoryContainerHooks}>
          {/* <IndexRedirect to='myteam'/>							
						<Route path='myteam' component={TeamProfileContainer} />										 */}
          {/* <Route path='myteam/:staffid' component={TeamProfileContainer} /> */}
          <Route path="myteam/invite" component={InviteStaffContainer} />
          <Route
            path="myteam/profile/:staffid"
            component={TeamProfileContainer}
          >
            <Route path="teamedit" component={TeamEditProfileContainer} />
          </Route>
        </Route>
        <Route path="contacts/dashboard" component={ContactsLayout}>
          <IndexRedirect to="all-lead" />
          <Route path=":groupId" component={ContactsWrapper} />
          <Route path=":groupId/:filters" component={ContactsWrapper} />
          <Route
            path=":groupId/detail/:section/:visitorId"
            component={ContactsWrapper}
          />
          <Route path=":groupId/detail/:section/:visitorId/:campaignId/:stats/:type" component={ContactsWrapper} />
          <Route path=":groupId/detail/:section/:visitorId/:campaignId/:stats" component={ContactsWrapper}/>
        </Route>

        <Route path="chat" component={ChatLayout}>
          <IndexRedirect to="dashboard" />
          
          <Route
            path="guests"
            components={{
              leftComponent: ConversationSection,
              rightComponent: QueueContainer,
            }}
            props={{ replaceDefaultFilters: false }}
          />
          <Route
            path="queue"
            components={{
              leftComponent: ConversationSection,
              rightComponent: QueueContainer,
            }}
            props={{ replaceDefaultFilters: false }}
          />
          <Route
            path="dashboard"
            components={{
              leftComponent: ConversationSection,
              rightComponent: ChatSection,
            }}
            props={{ replaceDefaultFilters: true }}
          />
          <Route
            path="search"
            components={{
              leftComponent: ConversationFilterComponent,
              rightComponent: ChatSection,
            }}
            props={{ replaceDefaultFilters: true }}
          />
          <Route
            path="dashboard/:visitorOption/:chatStatus/:sortBy/:assignedTo"
            components={{
              leftComponent: ConversationSection,
              rightComponent: ChatSection,
            }}
            props={{ replaceDefaultFilters: true }}
          />
          <Route
            path="dashboard/:visitorOption/:chatStatus/:sortBy/:assignedTo/:conversationId"
            components={{
              leftComponent: ConversationSection,
              rightComponent: ChatSection,
            }}
            props={{ replaceDefaultFilters: true }}
          />
          <Route
            path="search/:visitorOption/:chatStatus/:sortBy/:assignedTo"
            components={{
              leftComponent: SearchWrapperComponent,
              rightComponent: ChatSection,
            }}
            props={{ replaceDefaultFilters: true }}
          />
          <Route
            path="search/:visitorOption/:chatStatus/:sortBy/:assignedTo/:conversationId"
            components={{
              leftComponent: SearchWrapperComponent,
              rightComponent: ChatSection,
            }}
            props={{ replaceDefaultFilters: true }}
          />
          <Route
            path="guests/:visitorOption/:chatStatus/:sortBy/:assignedTo"
            components={{
              leftComponent: ConversationSection,
              rightComponent: QueueContainer,
            }}
            props={{ replaceDefaultFilters: false }}
          />
          <Route
            path="queue/:visitorOption/:chatStatus/:sortBy/:assignedTo"
            components={{
              leftComponent: ConversationSection,
              rightComponent: QueueContainer,
            }}
            props={{ replaceDefaultFilters: false }}
          />
          <Route
            path="guests/:visitorOption/:chatStatus/:sortBy/:assignedTo/:conversationId"
            components={{
              leftComponent: ConversationSection,
              rightComponent: QueueContainer,
            }}
            props={{ replaceDefaultFilters: true }}
          />
        </Route>
        {/* 
        <Route path="widget" component={WidgetGuide} />
        <Route path="widget/embed/info" component={EmbedInfo} />
        <Route path="widget/embed/setup" component={EmbedSetup} />
        <Route path="widget/customise/info" component={WidgetInfo} />
        <Route path="widget/customise/setup" component={WidgetSetupContainer} />
        <Route path="widget/prompt/info" component={PromptInfo} />
        <Route
          path="widget/prompt/setup"
          component={WidgetPromptSetupContainer}
        /> */}

        <Redirect from="*" to="chat" />
      </Route>
    </Route>

    <Route path="login/*" component={Login} />
    <Route path="login" component={Login} />
  </Route>
);
