import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updatePeopleESQuery,
  deleteScrollId,
  updatePeopleScrollId,
} from "../actions/SearchAction.js";
import { getPeopleByScroll } from "../actions/ContactsAction.js";
import {
  getSearchQuery,
  getContactHeader,
  decodeReferences,
  getSortProperty,
} from "../commons/ContactsUtility.js";
import { deepClone } from "../../commons/Utility.js";
import useProperties from "../hooks/useProperties.js";
import Grid from "./Grid.jsx";

const ContactsGrid = (props) => {
  console.log("props",props);
  const { groupId, projectkey, filters } = props.params;
  const { customFields, columns, defaultColumns } = useProperties(projectkey);
  const {
    contacts: {
      isGroupsFetched,
      isFetchingGroups,
      peopleGroupMap,
      visibleFields,
      initialLoadComplete,
      isLoadingMore,
      isLoading,
    },
    groups:{
      leads,
    }
  } = props;
  const {
    contacts: { peopleMap = {}, totalPeople, fetchedCount },
    query,
  } = props;
  const listing = Object.values(peopleMap);
  const { headerInfo, headers } = getContactHeader(
    defaultColumns,
    visibleFields,
    columns, 
    customFields
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isGroupsFetched && peopleGroupMap[groupId] && !filters) {
      let references = JSON.parse(peopleGroupMap[groupId].references);
      let searchQuery = getSearchQuery({ references, groupId, projectkey });
      dispatch(updatePeopleESQuery(searchQuery));
    }
  }, [groupId, isGroupsFetched]);

  useEffect(() => {
    if (filters) {
      let { references } = decodeReferences(filters);
      let searchQuery = getSearchQuery({ references, groupId, projectkey });
      dispatch(updatePeopleESQuery(searchQuery));
    }
  }, [filters]);

  const onSort = (property) => {
    let sortProperty = getSortProperty(property, customFields);
    let searchQuery = deepClone(query);
    searchQuery["query"]["sort"] = {};
    let direction =
      query["query"]["sort"] && query["query"]["sort"][sortProperty] == "desc"
        ? "asc"
        : "desc";
    searchQuery["query"]["sort"] = { [sortProperty]: direction };
    dispatch(updatePeopleESQuery(searchQuery));
  };

  const loadMore = (e) => {
    let { scrollId } = query;
    let height = e.scrollTop + e.clientHeight;
    if (
      height >= e.scrollHeight - 4 &&
      totalPeople > fetchedCount &&
      !isLoading &&
      !isLoadingMore
    ) {
      dispatch(
        getPeopleByScroll({
          projectId: `LS-${projectkey}`,
          scroll_id: scrollId,
          scroll: "6h",
          peopleESQuery: query,
        })
      );
    } else if (totalPeople == fetchedCount && scrollId) {
      dispatch(updatePeopleScrollId(''));
    }
  };

  const getListing = () => {
    let gridListing = [];
    if (leads && initialLoadComplete)
      gridListing = listing;
    if (isFetchingGroups || isLoading || isLoadingMore) {
      gridListing.push({ isLoader: true });
      gridListing.push({ isLoader: true });
      gridListing.push({ isLoader: true });
    }
    return gridListing;
  };

  return (
    <Grid
      {...props}
      headers={headers}
      listing={getListing()}
      headerInfo={headerInfo}
      onSort={onSort}
      onScroll={loadMore}
    />
  );
};

export default ContactsGrid;
