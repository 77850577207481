import React from "react";
import { browserHistory } from "react-router";
import { getIconUrl } from "../../commons/Utility";

const YourWidgetComponent = (props) => {
  return (
    <div className="cs-settings-wrp">
      <div className={props.showLoader ? "loading-bg" : "dn"}></div>
      <aside className={props.showLoader ? "loading-data" : "dn"}>
        <img src={getIconUrl(document.location.origin)} />
        <p>Loading Your Data...</p>
      </aside>
      <section className="widget-configuration">
        <h3>Manage widgets</h3>
        <p>Choose a widget to view and edit its settings</p>
        <ul className="your-widget">
          {props.getWidgetList()}
          <li className="add-new" onClick={props.switchNewWidget}>
            <a href="javascript:void(0)">+ Add New</a>
          </li>
        </ul>
        <div className="clear"></div>
      </section>
    </div>
  );
};

export default YourWidgetComponent;
