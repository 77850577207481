import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import {
  initiateClipboard,
  isURLValid,
  getEncrypted,
  getDecrypted,
  addHttps,
  isURL,
} from "../../commons/Utility";
import { showVoicebox } from "../../actions/VoiceboxAction";

class PreviewUrlGeneratorComponent extends Component {
  constructor() {
    super();
    this.state = { previewUrl: "" };
    this.onValueChange = this.onValueChange.bind(this);
    this.redirectToPreviewPage = this.redirectToPreviewPage.bind(this);
  }
  onValueChange(e) {
    this.setState({
      ...this.state,
      previewUrl: e.target.value,
      isInvalidUrl: false,
    });
  }
  redirectToPreviewPage(e) {
    let typedPreviewUrl = this.state.previewUrl.trim();
    typedPreviewUrl = addHttps(typedPreviewUrl);
    if (isURLValid(typedPreviewUrl)) {
      this.setState({ ...this.state, previewUrl: "" });
      let encryptedValue = getEncrypted(
        JSON.stringify({ previewUrl: typedPreviewUrl, mode: "agent" })
      );
      window.open(
        document.location.origin + "/preview/" + encryptedValue,
        "_newtab"
      );
      //browserHistory.push( "/preview/"+encryptedValue );
    } else {
      this.setState({ isInvalidUrl: true });
    }
  }
  render() {
    return (
      <div>
        <section className="settings-content">
          <div>
            <h3>Demo the widget</h3>
            <span className="text">
              Enter customer's website URL below to simulate the Widget UI on
              their site.
            </span>
            <div
              className={
                "input-fields" + (this.state.isInvalidUrl ? " errorField" : "")
              }
            >
              {/* this.props.state.error ? 'input-fields errorField' : 'input-fields' */}
              <label>Enter URL</label>
              <input
                type="text"
                placeholder="Customer's URL"
                className="input-url"
                value={this.state.previewUrl}
                onChange={this.onValueChange}
              />
              {this.state.isInvalidUrl ? (
                <p style={{ margin: "0px 0px 0px 170px" }}>Invalid URL</p>
              ) : (
                ""
              )}
              <div style={{ margin: "20px 0px 0px 170px" }}>
                <button
                  className={this.state.previewUrl ? "" : "disable"}
                  disabled={!this.state.previewUrl}
                  onClick={this.redirectToPreviewPage}
                >
                  Open in new tab
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default PreviewUrlGeneratorComponent;
