import React, { useEffect } from "react";
import GridRow from "./GridRow.jsx";
import { List, AutoSizer } from "react-virtualized";
import { GRID_POSITIONING_INFO } from "../commons/ContactConstants.js";
import ReactTooltip from "react-tooltip";
import { useDispatch } from "react-redux";
import {addCardVisitorId} from '../../actions/PeopleAction.js';
import { revalueFilteredPeopleData } from "../../commons/Utility.js";

const Listing = (props) => {
  let { listing = [], headerInfo = [], columnsWidth, gridWrapperWidth, headerHeight, gridHeaderBottom } = props;
  let { gridListingHeight } = props;
  let { onScroll } = props;
  const dispatch = useDispatch();
  let rowHeight = 49;
  let maxHeight = listing && listing.length ? listing.length * rowHeight : 0;
  let rowCount = listing && listing.length ? listing.length : 0;
  let GridHeaderWidth =
    columnsWidth > gridWrapperWidth ? columnsWidth : gridWrapperWidth;
  let GriderTableWidth = GridHeaderWidth;

  useEffect(() => {
    ReactTooltip.rebuild();
  });
  useEffect(() => {
    return () => {
      dispatch(addCardVisitorId(''));
    }
  }, [])
  const getRow = ({ index, style }) => {
    return (
      <GridRow {...props} style={style} gridListingHeight ={gridListingHeight} gridHeaderBottom={gridHeaderBottom} headerHeight={headerHeight} headerInfo={headerInfo} rowData={ revalueFilteredPeopleData(listing[index])} />
    );
  };

  return (
    <div
      style={{
        width: `${GriderTableWidth + 24}px`,
        height: `${gridListingHeight}px`,
      }}
      className={"people-grid-list-wrp " + (maxHeight < gridListingHeight ? "people-scroll-height" : "")}
    >
      <AutoSizer>
        {({ width, height }) => {
          return (
            <List
              width={GriderTableWidth +24 }
              height={gridListingHeight}
              rowCount={rowCount}
              rowHeight={rowHeight}
              rowRenderer={getRow}
              columnWidth={150}
              onScroll={onScroll}
              style={{ maxHeight: maxHeight }}
            />
          );
        }}
      </AutoSizer>
    </div>
  );
};

export default Listing;
