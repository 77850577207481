import React, { Component } from "react";
import * as ConnectionAction from "../actions/MessageAction";
import * as LoginActions from "../actions/LoginAction";
import * as Utils from "../commons/Utility.js";
import cookie from "react-cookie";
import {
  LIVE_LOGOUT_URL,
  STAGING_LOGOUT_URL,
  CLIENT_LOGIN_REFERENCE,
} from "../commons/Constants";

import { connect } from "react-redux";

@connect((store) => ({ exception: store.ExceptionReducer }))
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidCatch(error, info) {
    console.log(`ERROR MESSAGE  ---> `, JSON.stringify(error.message));
    console.log(`ERROR STACK TRACE ---> `, JSON.stringify(error.stack));
    Utils.sendErrorMail(error);
  }

  componentDidUpdate() {
    if (this.props.exception.userAuthenticationFailed) {
      cookie.remove(CLIENT_LOGIN_REFERENCE, { path: "/" });
      let logoutUrl =
        Utils.getAppMode() === "live" ? LIVE_LOGOUT_URL : STAGING_LOGOUT_URL;
      console.log("Logout URL:", logoutUrl);
      window.location.href = logoutUrl + Utils.getOrigin() + "/login";
    }
  }

  refreshPage() {
    window.location.reload();
  }
  logout(e) {
    if (e) e.preventDefault();
    cookie.remove(CLIENT_LOGIN_REFERENCE, { path: "/" });
    // let logoutUrl = Utils.getAppMode() === 'live' ? LIVE_LOGOUT_URL : STAGING_LOGOUT_URL;
    // window.location.href = logoutUrl + Utils.getOrigin()+"/login";
    // this.props.dispatch( ConnectionAction.destroyRtmServer() );
    this.props.dispatch(LoginActions.logout());
  }

  render() {
    return (
      <div id="error-bound">
        {this.props.children}
        <div
          className={
            "popup-container " +
            (this.props.exception.userAuthenticationFailed ? "show" : "dn")
          }
        >
          <aside className={"popup error-popup"}>
            <h3>Your session got expired!</h3>
            <div className="formField">
              <div>
                <p>You are signed out, pls sign in back!</p>
              </div>
            </div>
            <div className="popupBtnHolder">
              <button className="blueBtn" tabIndex="4" onClick={this.logout}>
                Login
              </button>
            </div>
          </aside>
          <div className="transprentBg db"></div>
        </div>

        {/* <div style={{display:"none"}} className={ "popup-container " + ( this.props.exception.userAuthenticationChanged || this.props.exception.apiInternalException ? "show" : "dn" ) }> */}
        <div
          style={{ display: "none" }}
          className={
            "popup-container " +
            (this.props.exception.userAuthenticationChanged ||
            this.props.exception.apiInternalException
              ? "show"
              : "dn")
          }
        >
          <aside className={"popup error-popup"}>
            <h3>Something went wrong!</h3>
            <div className="formField">
              <div>
                <p>Refresh to connect!</p>
              </div>
            </div>
            <div className="popupBtnHolder">
              <button
                className="blueBtn"
                tabIndex="4"
                onClick={this.refreshPage}
              >
                Refresh
              </button>
            </div>
          </aside>
          <div className="transprentBg dn"></div>
        </div>
      </div>
    );
  }
}
export default ErrorBoundary;
