export const CHAT_CONFIGURATION_REQUESTED = "CHAT_CONFIGURATION_REQUESTED";
export const CHAT_CONFIGURATION_RECEIVED = "CHAT_CONFIGURATION_RECEIVED";
export const CHAT_CONFIGURATION_FETCH_FAILED =
  "CHAT_CONFIGURATION_FETCH_FAILED";
export const UPDATE_CHAT_CONFIG_REQUEST = "UPDATE_CHAT_CONFIG_REQUEST";
export const UPDATE_CHAT_CONFIG_RESPONSE = "UPDATE_CHAT_CONFIG_RESPONSE";
export const UPDATE_CHAT_CONFIG_FAILED = "UPDATE_CHAT_CONFIG_FAILED";
export const UPDATE_WEBSITE_URL_REQUEST = "UPDATE_WEBSITE_URL_REQUEST";
export const ADD_CONTAINER_INFO = "ADD_CONTAINER_INFO";
export const CHAT_CONFIGURATION_LIST_RESPONSE =
  "CHAT_CONFIGURATION_LIST_RESPONSE";

export const requestChatConfiguration = (
  projectKey,
  chatConfigList,
  isOverflowConversation
) => ({
  type: CHAT_CONFIGURATION_REQUESTED,
  projectKey,
  chatConfigList,
  isOverflowConversation,
});

export const receiveChatConfiguration = (chatConfiguration) => {
  return {
    type: CHAT_CONFIGURATION_RECEIVED,
    chatConfiguration,
  };
};

export const failedToReceiveChatConfiguration = (chatConfiguration) => {
  return {
    type: CHAT_CONFIGURATION_FETCH_FAILED,
    chatConfiguration,
  };
};

export const updateChatConfigRequest = (chatConfiguration, hideVoiceBox) => ({
  type: UPDATE_CHAT_CONFIG_REQUEST,
  chatConfiguration,
  hideVoiceBox,
});

export const updateChatConfigResopnse = (chatConfiguration) => ({
  type: UPDATE_CHAT_CONFIG_RESPONSE,
  chatConfiguration,
});

export const updateChatConfigFailed = () => ({
  type: UPDATE_CHAT_CONFIG_FAILED,
});

export const updateWebsiteUrlRequest = (requestObj) => ({
  type: UPDATE_WEBSITE_URL_REQUEST,
  requestObj,
});

export const addContainerInfo = (containerInfo) => ({
  type: ADD_CONTAINER_INFO,
  containerInfo,
});

export const updateChatConfigurationListResponse = (configurationResponse) => ({
  type: CHAT_CONFIGURATION_LIST_RESPONSE,
  configurationResponse,
});
