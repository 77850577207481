import { flushSync } from "react-dom";
import {
  addCampaign,
  updateCampaignStatsCount,
  removeCampaign,
  updateCampaign,
  fetchBroadcastMessageStats,
  updatingCampaign,
} from "../actions/BroadcastMessageStatsAction";
import { store } from "../app";
import { SOCKET_EVENTS } from "../commons/Constants";
import {
  getFallBackValues,
  getReplacedMessage,
  getActiveProjectId,
} from "../commons/Utility";

export const initCampaignSync = (RTMServer) => {
  bindEvents(RTMServer, SOCKET_EVENTS.CHAT, onChatMessage);
  bindEvents(RTMServer, SOCKET_EVENTS.CAMPAIGN_UPDATE, syncCampaignUpdate);
  bindEvents(RTMServer, SOCKET_EVENTS.CAMPAIGN_NEW, syncNewCampaign);
  bindEvents(RTMServer, SOCKET_EVENTS.CAMPAIGN_DELETE, deleteCampaign);
  bindEvents(RTMServer, SOCKET_EVENTS.SUBSCRIBED, fetchCampaigns);
};

const bindEvents = (listener, event, callback) => {
  listener.off(event, callback);
  listener.on(event, callback);
};

export const syncCampaignUpdate = (channel, campaign) => {
  if (isProcessingCampaign()) return;
  flushSync(() => {
    store.dispatch(updatingCampaign());
  })
  campaign.fallBackValues = getFallBackValues(campaign.message.value);
  campaign.message.value = getReplacedMessage(campaign.message.value);
  store.dispatch(updateCampaign(campaign));
};

export const syncNewCampaign = (channel, campaign) => {
  if (isProcessingCampaign()) return;
  campaign.fallBackValues = getFallBackValues(campaign.message.value);
  campaign.message.value = getReplacedMessage(campaign.message.value);
  campaign.filterQuery = JSON.parse(campaign.filterQuery);
  campaign.isHTMLTemplateEnabled = campaign.isHTMLTemplateEnabled ? true : false;
  campaign.isInteractableDisabled = campaign.isInteractableDisabled ? true : false;
  campaign.isNewCampaign = true;
  store.dispatch(addCampaign(campaign, false));
};

export const isProcessingCampaign = () => {
  return store.getState().BroadcastMessageStatsReducer.isProcessingCampaign;
};

export const deleteCampaign = (channel, campaignId) => {
  store.dispatch(removeCampaign(campaignId));
};

export const onChatMessage = (channel, data) => {
  if (data.type === SOCKET_EVENTS.BROADCAST_STATS_SYNC) {
    store.dispatch(updateCampaignStatsCount(data.stats));
  }
};

export const fetchCampaigns = () => {
  let {
    BroadcastMessageStatsReducer: { isFetching },
    MessageReducer: { isReSubscribed },
  } = store.getState();
  if (isFetching || isReSubscribed) return;
  store.dispatch(fetchBroadcastMessageStats(getActiveProjectId()));
};
