import { call, put, select } from "redux-saga/effects";
import * as PopupActions from "../actions/PopupAction";
import * as Ajax from "../xhr/XhrRequestHandler";
import * as InvitationActions from "../actions/InvitationAction";
import { requestAvailableProject } from "../settings/actions/ProjectAction";
import { addNotification } from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL, EVENTS_TRACKING } from "../commons/Constants.js";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import {
  getFailedInvitesPopupProps,
  setFullNameAndInitial,
  getProjectKey,
  getNonDefaultVersion,
} from "../commons/Utility.js";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import * as EventsTracking from "../commons/EventsTracking.js";
import { store } from "../app";
import { staffListResponse } from "../actions/StaffAction";
import { browserHistory } from "react-router";

export function* getInvitation(invitationObject) {
  const url = "/invitation/get";
  let invitation;
  try {
    invitation = yield call(Ajax.postUnauthenticated, url, invitationObject);
    invitation = invitation.data;

    yield put(InvitationActions.getInvitationResponse(invitation));
  } catch (error) {
    yield* notifyInvitationApiError(error);
    yield put({ type: "GET_INVITATION_FAILED" });
  }
}

export function* getSignupDetails(invitationObject) {
  const url = "/invitation/getSignupDetails";
  let invitation;
  try {
    invitation = yield call(Ajax.postUnauthenticated, url, invitationObject);
    invitation = invitation.data;

    yield put(InvitationActions.getInvitationResponse(invitation));
  } catch (error) {
    yield* notifyInvitationApiError(error);
    yield put({ type: "GET_INVITATION_FAILED" });
  }
}

export function* acceptInvitation(invitationObject) {
  const url = "/invitation/accept";
  try {
    let invitation = yield call(
      Ajax.postUnauthenticated,
      url,
      invitationObject
    );
    invitation = invitation.data;
    yield put(InvitationActions.acceptInvitationResponse(invitation));

    yield put(
      addNotification({
        message: "Accepted Invitation Successfully",
        level: NOTIFICATION_LEVEL.Success,
        dismissAfter: 3000,
      })
    );
    EventsTracking.trackAcceptInvitation(invitationObject);
    yield put(requestAvailableProject());
    console.info("I am the seven");
  } catch (error) {
    yield* notifyInvitationApiError(error);
    yield put({ type: "ACCEPT_INVITATION_FAILED" });
  }
}

export function* declineInvitation(invitationObject) {
  const url = "/invitation/decline";
  try {
    let invitation = yield call(
      Ajax.postUnauthenticated,
      url,
      invitationObject
    );
    invitation = invitation.data;
    yield put(InvitationActions.declineInvitationResponse(invitation));
    EventsTracking.trackDeclineInvitation(invitationObject);
    yield put(
      addNotification({
        message: "Invitation Declined!",
        level: NOTIFICATION_LEVEL.Success,
        dismissAfter: 3000,
      })
    );
  } catch (error) {
    yield* notifyInvitationApiError(error);
    yield put({ type: "DECLINE_INVITATION_FAILED" });
  }
}

/* export function* sendInvitation( invitationObject )
{
	const url 			=	'/invitation/send';
	try
	{
		yield put( showVoicebox( { message : 'Inviting staff...' } ) );

		let invitationInfo 	  = invitationObject.invitationInfo;
		let { staffList } = invitationInfo;
		let emailPermissionMap = {}

		staffList.forEach( ( userAccess , email ) => {
			emailPermissionMap[ email ] = userAccess == 'Staff' ? 'RESTRICTED' : "FULL_ACCESS";
		});

		console.info( "email Permission Map:" , emailPermissionMap );
		invitationInfo.staffList = emailPermissionMap;

		invitationInfo = yield call( Ajax.postUnauthenticated , url , invitationObject.invitationInfo );
		let lsInvitations	= invitationInfo.data.lsInvitations;
		let awInvitations   = invitationInfo.data.awInvitations; 
		let failedInvitations   = [];
		
		if(awInvitations) {
			awInvitations.forEach( (awInvitation) => {
				if(awInvitation.hasOwnProperty('msg') && !awInvitation.hasOwnProperty('id') ) {
					failedInvitations.push(awInvitation);
				} else if( awInvitation.hasOwnProperty('id') ) {
					lsInvitations.push(awInvitation);
				}
			})
		}
		
		if( failedInvitations.length > 0 ) {
			let popupProps = getFailedInvitesPopupProps( failedInvitations );
			yield put( PopupActions.showPopup( { popupProps : popupProps } ) );
		}

		if( lsInvitations && lsInvitations.length > 0 ) {

			EventsTracking.trackMemberInvited(lsInvitations);				
            yield put( showVoicebox( { message : 'Invitation sent!' , dismissAfter : 3000  } ) );
		}
			
		else 
			yield put( hideVoicebox() );
			
		yield put( InvitationActions.sendInvitationResponse( lsInvitations ) );
	}
	catch( error )
	{
		console.log(error);
		
		yield put( hideVoicebox() );
		yield* notifyInvitationApiError( error );
		yield put( { type : 'SEND_INVITATION_FAILED' } );
	}
} */
export function* sendInvitation(invitationObject) {
  const url = "/invitation/multiple/send";
  try {
    let nonDefaultVersion = getNonDefaultVersion();
    let payload = { invitationMap: invitationObject.invitationInfo };

    if (nonDefaultVersion) payload["webappOrigin"] = document.location.origin;

    let resposne = yield call(Ajax.postUnauthenticated, url, payload);
    console.log("Response:" + resposne);

    let lsInvitations = resposne.data.lsInvitations;
    let awInvitations = resposne.data.awInvitations;
    let staffEntities = resposne.data.staffEntities;
    let failedInvitations = [];

    if (awInvitations) {
      awInvitations.forEach((awInvitation) => {
        if (
          awInvitation.hasOwnProperty("msg") &&
          !awInvitation.hasOwnProperty("id")
        ) {
          failedInvitations.push(awInvitation);
        } else if (awInvitation.hasOwnProperty("id")) {
          lsInvitations.push(awInvitation);
        }
      });
    }

    if (failedInvitations.length > 0) {
      /* let isDifferentAccountIdError = false;
			for(let failedInvitation of failedInvitations)
			{
				if( failedInvitation.msg == "contact can't have two accounts" )
					isDifferentAccountIdError = true;
			}
			if(isDifferentAccountIdError)
				yield put( showVoicebox( { message : 'Email part of different account' , dismissAfter : 3000  } ) );
			else */
      yield put(
        showVoicebox({
          message: "Invitation failed",
          dismissAfter: 3000,
          showError: true,
        })
      );
    } else if (
      (lsInvitations && lsInvitations.length > 0) ||
      (staffEntities && staffEntities.length > 0)
    ) {
      yield put(
        showVoicebox({ message: "Invitation sent", dismissAfter: 3000 })
      );
    } else yield put(hideVoicebox());

    if (lsInvitations && lsInvitations.length > 0) {
      EventsTracking.trackMemberInvited(lsInvitations);
    }

    yield put(InvitationActions.sendInvitationResponse(lsInvitations));

    let dashboardAgents = {};
    for (let staff of staffEntities) {
      staff = setFullNameAndInitial(staff);
      dashboardAgents[staff.key] = staff;
    }
    let projectId = store.getState().StaffReducer.activeProjectId;
    yield put(staffListResponse(dashboardAgents, {}, projectId));
    browserHistory.push({
      pathname: "/app/" + getProjectKey(projectId) + "/directory",
    });
  } catch (error) {
    console.log(error);

    yield put(
      showVoicebox({
        message: "Invitation failed",
        dismissAfter: 3000,
        showError: true,
      })
    );
    yield* notifyInvitationApiError(error);
    yield put({ type: "SEND_INVITATION_FAILED" });
  }
}

function* notifyInvitationApiError(error) {
  try {
    if (error.data && error.data.Exception == "INVALID_INVITATION") {
      yield put(
        addNotification({
          message: "Invalid Invitation!",
          level: NOTIFICATION_LEVEL.Error,
          dismissAfter: 3000,
        })
      );
    } else if (error.data && error.data.Exception == "EXPIRED_INVITATION") {
      yield put(
        addNotification({
          message: "Invitation Already Accepted!",
          level: NOTIFICATION_LEVEL.Warning,
          dismissAfter: 3000,
        })
      );
    } else if (error.data && error.data.message == "Access Denied") {
      yield put(
        showVoicebox({
          message: "Access denied!",
          dismissAfter: 3000,
          showError: true,
        })
      );
    } else {
      yield put(handleGlobalError(error));
    }
  } catch (e) {
    yield put(handleGlobalError(error));
  }
}
