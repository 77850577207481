import React, { Component } from "react";
import { connect } from "react-redux";
@connect((state) => ({
  UserReducer: state.UserReducer
}))
class ContactsLayout extends Component {
  render() {
    return <div class={"cs-conversation-wrp "}>{this.props.children}</div>;
  }
}

export default ContactsLayout;
