export const config = {
  chatsupportUrl: process.env.CHATSUPPORT_URL!,
  chatsupportAppUrl: process.env.CHATSUPPORT_APP_URL!,
  chatsupportApiUrl: process.env.CHATSUPPORT_API_URL!,
  authApiUrl: process.env.AUTH_API_URL!,
  googleApiUrl: process.env.GOOGLE_API_URL!,
  rtmApiUrl: process.env.RTM_API_URL!,
  authClientId: process.env.AUTH_CLIENT_ID!,
  authProject: process.env.AUTH_PROJECT!,
  storagePath: process.env.STORAGE_PATH!,
}
