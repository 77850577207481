import React from "react";
import { List } from "react-virtualized";
import { WhiteTickIcon } from "../../../commons/Icons";

const ImportContactRows = (props) => {
  const { importedColumns, importedContactsInfo,setRowIsSelected,headerToFieldMap,initialImportedColumns } = props;

  const getContactRow = (row,rowIndex) => {
    let toRender = [];
    // let oldImportedColumns = Object.keys(headerToFieldMap);
    initialImportedColumns.forEach((columnName, index) => {
      let displayValue = row[columnName] ? row[columnName] :"-";
      toRender.push(
        <div className={getClassName(row.isSelected,index)}>
          {index == 0 ? (
            <cite onClick={() => setRowIsSelected(rowIndex)}>
              <WhiteTickIcon />
            </cite>
          ) : (
            ""
          )}
          <span>{displayValue}</span>
        </div>
      );
    });
    return toRender;
  };

  const getClassName = (isSelected,index) => {
    return isSelected && index == 0 ? "active" : "";
  };

  const getContactRows = ({ index, style }) => {
    return(
      <div class="import-grid-list" style={style}>{getContactRow(importedContactsInfo[index],index)}</div>
    )
  }

  return (
    <div
      class="import-grid-list-wrp"
      style={{ width: `${importedColumns.length * 180}px` }}
    >
      <List
        width={importedColumns.length * 180}
        height={270}
        rowCount={importedContactsInfo.length}
        rowHeight={49}
        rowRenderer={getContactRows}
        columnWidth={150}
        style={{ maxWidth: `${importedColumns.length * 180}px` }}
      />
    </div>
  );
};
export default ImportContactRows;
