import * as R from 'ramda'
import styled from 'styled-components'
import { Text2 } from 'src/features/ui/components/Typography'

export const Label = styled(Text2)`
  color: ${R.path(['theme', 'color', 'alizarinCrimson'])};
  margin-left: 0.8rem;
`

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
`
