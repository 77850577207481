import React from "react";

const CampaignGridHeader = (props) => {
  return (
    <div class="campaigns-grid-head">
      <label>Title</label>
      <label>State</label>
      <label>Sent</label>
      <label>Opened</label>
      <label>Clicked</label>
      <label>Interacted</label>
      <label>Not Delivered</label>
    </div>
  );
};

export default CampaignGridHeader;
