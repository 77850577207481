import * as R from 'ramda'
import styled from 'styled-components'
import is, { isNot } from 'typescript-styled-is'
import { Text1 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const Spacer = styled.div<{ hasChildren: boolean }>`
  height: 5.9rem;

  ${is('hasChildren')`
    height: 12.7rem;
  `}

  ${mq.sm} {
    height: 7.1rem;
  }
`

export const Container = styled.header<{
  hasSignIn?: boolean
  hasShadow?: boolean
}>`
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: ${R.path(['theme', 'color', 'white'])};
  padding-top: 1.8rem;
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${isNot('hasSignIn')`
    flex-direction: column;
  `}

  ${is('hasSignIn')`
    justify-content: space-between;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  `}

  ${mq.sm} {
    padding: 1.2rem;
    justify-content: space-between;

    ${isNot('hasSignIn')`
      flex-direction: row;
    `}

    ${is('hasShadow')`
      box-shadow: inset 0 -0.1rem 0 ${R.path(['theme', 'color', 'mystic'])};
    `}
  }
`

export const Left = styled.div``

export const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1.8rem;

  ${mq.sm} {
    padding-top: 0;
  }
`

export const Right = styled.span`
  display: block;
  white-space: nowrap;
`

export const SignInLabel = styled(Text1)`
  color: ${R.path(['theme', 'color', 'baliHai'])};
  display: none;

  ${mq.sm} {
    display: inline-block;
  }
`
