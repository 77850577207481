import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { selectCreatedDate } from 'src/features/returningFlow/store/selectors'
import { SubTitle, Text1 } from 'src/features/ui/components/Typography'
import { IAppState } from 'src/utils/state/store'

const JoinedAtConfirmationComponent: FC<{ createdAt: string }> = ({
  createdAt,
}) => (
  <>
    <SubTitle bolderer>Account created.</SubTitle>
    <Margin top={4} />
    <Text1 color="raven">{createdAt}</Text1>
  </>
)

const mapStateToProps = (state: IAppState) => ({
  createdAt: selectCreatedDate(state.returning),
})

export const JoinedAtConfirmation = connect(mapStateToProps)(
  JoinedAtConfirmationComponent
)
