import * as actions from 'src/features/authorization/store/actions'
import { IAuthState, EnumStatus } from 'src/features/authorization/store/types'
import { addListItem, removeListItem } from 'src/utils/state/operators'

export const initialState = {
  status: [],
  widgetId: undefined,
  userId: undefined,
  ssoToken: null,
  code: null,
  error: undefined,
}

export const authReducer = (
  state: IAuthState = initialState,
  action: actions.IAuthActions
) => {
  switch (action.type) {
    case actions.REQUEST_SIGN_UP:
      return {
        ...state,
        error: undefined,
        status: addListItem(EnumStatus.signingUp, state.status),
      }
    case actions.REQUEST_SIGN_UP_SUCCESS:
      return {
        ...state,
        status: addListItem(
          EnumStatus.registeringSessionStep1,
          removeListItem(EnumStatus.signingUp, state.status)
        ),
        widgetId: action.payload.widgetId,
        userId: action.payload.userId,
        code: action.payload.code,
        ssoToken: action.payload.ssoToken,
      }
    case actions.REQUEST_SESSION_STEP_2:
      return {
        ...state,
        status: addListItem(EnumStatus.registeringSessionStep2, state.status),
      }
    case actions.REQUEST_SESSION_STEP_3:
      return {
        ...state,
        status: addListItem(EnumStatus.registeringSessionStep3, state.status),
        code: action.payload.code,
      }
    case actions.INITIALIZE:
      return {
        ...state,
        widgetId: action.payload.widgetId,
        userId: action.payload.userId,
      }
    case actions.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        status: [],
      }
    default:
      return state
  }
}
