import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers";
import thunk from "redux-thunk";
import createLogger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import Sagas from "../sagas";
import SocketConnection from "../Connection";
import * as ConnectionMiddleware from "../commons/ConnectionMiddleware";
import DesktopNotificationMiddleware from "../commons/DesktopNotificationMiddleware";
import { getAppMode } from "../commons/Utility.js";

const ConfigureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const SocketMiddleware = function (connection) {
    return (store) => (next) => (action) => {
      if (action.type == "RTM") {
        if (!ConnectionMiddleware.action(connection, action))
          throw new Error("invalid RTM action");
      }
      next(action);
    };
  };

  const NotificationMiddleware = function (DesktopNotificationMiddleware) {
    return (store) => (next) => (action) => {
      DesktopNotificationMiddleware.processNotification(action);
      next(action);
    };
  };

  const middleware = [
    sagaMiddleware,
    SocketMiddleware(SocketConnection),
    NotificationMiddleware(DesktopNotificationMiddleware),
  ];

  let store = createStore(reducers, applyMiddleware(...middleware));

  if (getAppMode() !== "live") {
    middleware.push(createLogger());

    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      reducers,
      composeEnhancers(applyMiddleware(...middleware))
    );
  }

  const autoRestartSaga = function (middleWare) {
    middleWare.done.catch(function (err) {
      console.log("Error occured during saga operation :: ", err);
      autoRestartSaga(sagaMiddleware.run(Sagas));
    });
  };

  autoRestartSaga(sagaMiddleware.run(Sagas));

  return store;
};

export default ConfigureStore;
