import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";

import { fetchVisitorQueueResponse } from "../actions/VisitorQueueActions";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { getGuestInfoFromPeopleEntity, getValidPeoplesEntity } from "../commons/Utility.js";
import { receiveUpdateContact } from "../actions/ContactInfoAction";
import { fetchPresenceByUserIds } from "../actions/PeopleAction";

export function* fetchVisitorQueueRequest(requestData) {
  console.log("Going to fetch the visitor Queue :", requestData);

  try {
    const url = "contact/visitor/presence";
    const visitorQueueResponse = yield call(Ajax.post, url, requestData);
    console.log(
      "The response we got from visitor queue :",
      visitorQueueResponse
    );
    const { searchResponse, projectColorMap } = visitorQueueResponse.data;
    const peopleList = [];
    const peopleIds = [];
    let count = searchResponse.totalVisitorCount;
    const peopleListResponse = searchResponse.hits.reduce(
      (responseObj, peopleEntity) => {
        let { key } = peopleEntity;
        peopleEntity.isOnline = true;
        if (key) {
          responseObj[key] = getGuestInfoFromPeopleEntity(peopleEntity);
          peopleList.push(peopleEntity);
          peopleIds.push(key);
        } else
          count--;
        return responseObj;
      },
      {}
    );
    for (let index = 0; index < peopleList.length; index++) {
      let peopleData = peopleList[index];
      peopleData = constructValidContactPayload(peopleData);
      yield put(receiveUpdateContact({ contact: peopleData }));
    }
    yield put(fetchPresenceByUserIds(peopleIds, "", false));

    yield put(
      fetchVisitorQueueResponse(peopleListResponse, count, projectColorMap)
    );
  } catch (e) {
    console.log("Error fetching Visitor Queue :", e);
    yield put(handleGlobalError(e));
  }
}

function constructValidContactPayload(peopleData) {
  if (peopleData) {
    peopleData = Object.assign({}, getValidPeoplesEntity(peopleData));
    let { sourceUrl, currentUrl, lastPageUrl } = peopleData;
    if (sourceUrl && !peopleData.sourceUrl_keyword)
      peopleData.sourceUrl_keyword = sourceUrl;
    if (currentUrl && !peopleData.currentUrl_keyword)
      peopleData.currentUrl_keyword = currentUrl;
    if (lastPageUrl && !peopleData.lastPageUrl_keyword)
      peopleData.lastPageUrl_keyword = lastPageUrl
  }
  return peopleData
}
