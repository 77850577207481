import React, { Component } from "react";
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import ActiveChatView from "../components/chat/ActiveChatView.jsx";
import { connect } from "react-redux";
import * as messageAction from "../actions/MessageAction";
import {
  CHAT_MESSAGE_LIMIT,
  CURSOR_STATUS,
  MESSAGE_FROM,
  MESSAGE_TYPE,
  CHAT_STATUS,
  SHORT_CHAT_STATUS_MAP,
  MODIFICATION_STATUS,
  MANUAL_ASSIGNMENT_TYPES,
  TAG_TYPES,
  displayVisitorType,
  taggingType,
  ACTIONABLE_TYPES,
  filterTypes,
  OVERFLOW_STATUS,
  SCROLL_HEIGHT_FROM_BOTTOM,
  CHAT_ASSIGN_TYPES,
  SB_WINDOW_EVENTS
} from "../commons/Constants";
import {
  generateMessageId,
  getProjectId,
  getHostName,
  getBucketHostName,
  isValidEmail,
  extractMentionedStaffs,
  getConnectUrl,
  isOverflowConnection,
  postWindowMessage,
  isAwWindow,
  isValidTagName,
  getFullName,
  getContactDetailsToPostToSBIFrame,
  isValidObject,
  getProjectKey,
  getServerTime,
  getUserPermissionUtility,
  generateHashCode,
  getTypingStopMessage,
  isClosedByMentionedAgent,
  sendMentionedCloseMessage,
  sendSingleAgentConversationClosedMessage,
  addToAssignedObject,
  removeFromAssignedObject,
  isSingleAgentConversationClosed,
  checkAndGetPendingClosedMessage,
  processAndSendEmailMessage,
  getCursor,
  resetConversationMessagesIfNotOpenChat,
  removeRecentComponentFromAW,
  isOpenChat,
  isScrollReachedUptoThePixelsFromBottom,
  areNewMessagesAdded,
  scrollElementToBottom,
  getUserReducerFromProps,
  getStaffMap,
  getIsAWRecentComponent,
} from "../commons/Utility";
import {
  messageRequest,
  sendMessage,
  addMessageToToBeSentList,
  addMessage,
  closeConnection,
  sendEmailMessage,
  /* sendEmailMessage, */
  onMessage,
  sendHttpMessage,
  queueUnackMessage,
  addOfflineMessage,
  resetMessageCursor
} from "../actions/MessageAction";
import { requestVideoLink, resetLink } from "../actions/VideoChatAction";
import * as ConversationInfoAction from "../actions/ConversationInfoAction";
import * as AttachmentAction from "../actions/AttachmentAction";
import * as NotificationActions from "../actions/NotificationAction";
import * as TagActions from "../actions/TagActions";
import * as PopupActions from "../actions/PopupAction";
import { showVoicebox, hideVoicebox } from "../actions/VoiceboxAction";
import { updateOpenConversationCountRealtime } from "../actions/StaffAction";
import { updateUnassingedConversationCountRealtime } from "../settings/actions/ProjectAction";
import AddTagsPopup from "./AddTagsPopupContainer.js";
import MailTranscriptPopup from "../containers/MailTranscriptPopupContainer.js";
import Mousetrap from "mousetrap";
import ReactTooltip from "react-tooltip";
import { browserHistory } from "react-router";
import { receiveUpdateContact } from "../actions/ContactInfoAction";
import { updatePeopleEntity } from "../actions/PeopleAction";
import { clearNewMessageCount } from "../actions/NewMessageAction";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";
import { updateSBWindowStatus } from "../actions/UserAction.js";

@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  MessageReducer: state.MessageReducer,
  UserReducer: state.UserReducer,
  StaffReducer: state.StaffReducer,
  AttachmentReducer: state.AttachmentReducer,
  PeopleReducer: state.PeopleReducer,
  projects: state.ProjectReducer,
  VideoChat: state.VideoChatReducer,
  tagsReducer: state.TagReducer,
  urlPreviews: state.UrlPreviewReducer,
  NewMessageReducer: state.NewMessageReducer,
}))
class ActiveChatViewContainer extends Component {
  constructor() {
    super();
    this.state = {
      conversationObject: {
        conversationMap: {},
        contactsMap: {},
      },
      convMsgCursor: "",
      messages: {},
      staffMap: {},
      user: {},
      conversationId: "",
      projectKey: "",
      chatStatus: undefined,
      assignedTo: undefined,
      scrollToLstMsg: false,
      isOverflowConversation: false,
      interactionId: "",
      isUploadingAttachment: false,
      isContactDetailsPosted: false,
      showVideoLinkPopup: false,
      showScreenShareLinkPopup: false,
      showInputBox: true,
      isAwWindow: false,
      chatContainerScrollTop: 0,
      newMessageInfo: {},
      isTakingOverByDashboardMsgSent: false,
      hasConnectionClosed: false,
      nearBottom: false,
      resentFailedOnConnect: false,
      isAcceptMessageSent: false,
      currentConversationId: ""
    };
    this.setLocalState = this.setLocalState.bind(this);
    this.getAgentImageURLById = this.getAgentImageURLById.bind(this);
    this.getAgentNameById = this.getAgentNameById.bind(this);
    this.getVisitorNameById = this.getVisitorNameById.bind(this);
    this.loadMessagesOnScroll = this.loadMessagesOnScroll.bind(this);
    this.fetchMessages = this.fetchMessages.bind(this);
    this.sendTypingStatus = this.sendTypingStatus.bind(this);
    this.onMessageKeyDown = this.onMessageKeyDown.bind(this);
    this.onMessageKeyUp = this.onMessageKeyUp.bind(this);
    this.processAndSendNotes = this.processAndSendNotes.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.closeChat = this.closeChat.bind(this);
    this.assignAgent = this.assignAgent.bind(this);
    this.getChatMessage = this.getChatMessage.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.hideVideoLinkPopup = this.hideVideoLinkPopup.bind(this);
    this.showVideoLinkPopup = this.showVideoLinkPopup.bind(this);
    this.showChatInput = this.showChatInput.bind(this);
    this.toggleVideoLinkPopup = this.toggleVideoLinkPopup.bind(this);
    this.getVideoLink = this.getVideoLink.bind(this);
    this.processAndSend = this.processAndSend.bind(this);
    this.isValidAttachment = this.isValidAttachment.bind(this);
    this.shouldEmailTranscript = this.shouldEmailTranscript.bind(this);
    this.disconnectOverflowChat = this.disconnectOverflowChat.bind(this);
    this.showTagPopup = this.showTagPopup.bind(this);
    this.encodeMentionedAgents = this.encodeMentionedAgents.bind(this);
    this.uploadAttachmentShrtCut = this.uploadAttachmentShrtCut.bind(this);
    this.toggleVideoLinkShrtCut = this.toggleVideoLinkShrtCut.bind(this);
    this.showTranscriptPopupShrtCut =
      this.showTranscriptPopupShrtCut.bind(this);
    this.showSendTranscript = this.showSendTranscript.bind(this);
    this.toggleScreenShareLinkPopup =
      this.toggleScreenShareLinkPopup.bind(this);
    this.scrollMessages = this.scrollMessages.bind(this);
    this.toggleScreenShareLinkShrtCut =
      this.toggleScreenShareLinkShrtCut.bind(this);
    this.tagConversation = this.tagConversation.bind(this);
    this.getOverflowAgentNameById = this.getOverflowAgentNameById.bind(this);
    this.isOverflowTakingCareByDashboardAgent =
      this.isOverflowTakingCareByDashboardAgent.bind(this);
    this.sendReadReportOnLoad = this.sendReadReportOnLoad.bind(this);
    this.updateContainerScrollTop = this.updateContainerScrollTop.bind(this);
    this.sendStaffJoinedTag = this.sendStaffJoinedTag.bind(this);
    this.getStaffId = this.getStaffId.bind(this);
    this.handleWindowClose = this.handleWindowClose.bind(this);
    this.resendFailedMessage = this.resendFailedMessage.bind(this);
    this.getProjectId = this.getProjectId.bind(this);

    this.routeToCorrectProject = this.routeToCorrectProject.bind(this);
    this.findScrollBar = this.findScrollBar.bind(this);
    this.resendFailedMessages = this.resendFailedMessages.bind(this);
    this.hideTypingStatus = this.hideTypingStatus.bind(this);
    this.getNewMessages = this.getNewMessages.bind(this);
    this.isActiveChat = this.isActiveChat.bind(this);
    this.messageFromSB = this.messageFromSB.bind(this);
    this.getLastMessageCreatedDate = this.getLastMessageCreatedDate.bind(this);
    this.getProjectIdFromConversationOrState = this.getProjectIdFromConversationOrState.bind(this);
  }

  componentWillMount() {
    if (getUserPermissionUtility("SendAttachments"))
      Mousetrap.bind("alt+f", this.uploadAttachmentShrtCut);
    if (getUserPermissionUtility("EmailTranscript"))
      Mousetrap.bind("alt+e", this.showTranscriptPopupShrtCut);
    if (!this.props.isOverflowConversation && !this.props.isAwWindow) {
      Mousetrap.bind("alt+v", this.toggleVideoLinkShrtCut);
      Mousetrap.bind("alt+h", this.toggleScreenShareLinkShrtCut);
    }

    this.setLocalState(this.props, () => {
      this.props.dispatch(ConversationInfoAction.updateCurrentConversationId(this.props.params.conversationId));
      if (
        (this.state.conversationId &&
          this.state.projectKey &&
          this.props.MessageReducer.isConnected &&
          this.props.MessageReducer.isSubscribedToChannels &&
          !this.props.isOverflowConversation) ||
        (this.props.isOverflowConversation &&
          this.props.MessageReducer.isAcceptedMessageSent)
      )
        this.fetchMessages();
      this.prevState = this.state;
    });
  }
  componentWillUnmount() {
    this.handleWindowClose();
    Mousetrap.unbind("alt+f");
    Mousetrap.unbind("alt+v");
    Mousetrap.unbind("alt+h");
    Mousetrap.unbind("alt+e");
    window.removeEventListener("resize", this.updateContainerScrollTop);
    window.removeEventListener("beforeunload", this.handleWindowClose);
    resetConversationMessagesIfNotOpenChat(this.state.conversationId);
    if (
      this.props.params.chatStatus === SHORT_CHAT_STATUS_MAP.Open &&
      !isOpenChat(this.state.conversationId)
    )
      this.props.dispatch(resetMessageCursor(this.state.conversationId));
  }
  componentDidMount() {
    let containerDomNode = ReactDOM.findDOMNode(this.child.refs.chat_History);
    containerDomNode.scrollTop = containerDomNode.scrollHeight;

    window.addEventListener("resize", this.updateContainerScrollTop);
    window.addEventListener("beforeunload", this.handleWindowClose);
    window.addEventListener("message", this.messageFromSB, false);
  }

  routeToCorrectProject() {
    if (this.props.isOverflowConversation || this.props.isAwWindow) return;

    let { projectkey: projectKey, chatStatus, sortBy } = this.props.params;
    let { assignedTo, visitorOption, conversationId } = this.props.params;
    let pathQueryString = this.props.pathQueryString
      ? this.props.pathQueryString
      : "";

    if (conversationId) {
      let conversation =
        this.props.ConversationReducer.conversationObject.conversationMap[
          conversationId
        ];
      let projectId =
        conversation && conversation.projectId ? conversation.projectId : "";
      let userProjectIds = [];

      this.props.projects.projectList.map((project) => {
        userProjectIds.push(project.key);
      });

      if (
        projectId &&
        getProjectId(projectKey) != projectId &&
        userProjectIds.includes(projectId)
      ) {
        browserHistory.push(
          "/app/" +
            getProjectKey(projectId) +
            "/chat/dashboard/" +
            visitorOption +
            "/" +
            chatStatus +
            "/" +
            sortBy +
            "/" +
            assignedTo +
            "/" +
            conversationId +
            pathQueryString
        );
        return;
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.prevState = prevState;
    this.scrollMessages();
    this.routeToCorrectProject();
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.ConversationReducer.currentConversationId !== this.props.params.conversationId) {
       this.setState(prevState => ({ ...prevState, currentConversationId: this.props.params.conversationId }));
      this.props.dispatch(ConversationInfoAction.updateCurrentConversationId(this.props.params.conversationId));
      this.props.dispatch(ConversationInfoAction.updateAwUnreadCount(this.props.params.conversationId));
    }
    let oldConversationId = this.props.conversationId
      ? this.props.conversationId
      : this.props.params.conversationId
      ? this.props.params.conversationId
      : "";
    let currentConversationMessages =
      nextProps.NewMessageReducer.data && nextProps.params
        ? nextProps.NewMessageReducer.data[nextProps.params.conversationId]
        : "";
    let msgIds = currentConversationMessages
      ? currentConversationMessages.messageIds
      : "";
    this.setLocalState(nextProps, () => {
      let conversationId = this.state.conversationId;
      let conversation =
        this.state.conversationObject.conversationMap[conversationId];

      if (
        this.state &&
        this.state.conversationObject.conversationMap &&
        !nextProps.ConversationReducer.isFetching
      ) {
        let cursor =
          nextProps.MessageReducer.convMsgCursorMap[this.state.conversationId];
        if (
          Object.keys(this.state.messages).length < CHAT_MESSAGE_LIMIT &&
          ((nextProps.isOverflowConversation &&
            (nextProps.MessageReducer.isAcceptedMessageSent ||
              (conversation &&
                conversation.overflowStatus !== OVERFLOW_STATUS.OVERFLOWED))) ||
            (conversationId &&
              cursor != CURSOR_STATUS.NO_CURSOR &&
              this.state.projectKey &&
              nextProps.MessageReducer.isConnected &&
              nextProps.MessageReducer.isSubscribedToChannels && 
              !nextProps.isOverflowConversation))
        ) {
          this.fetchMessages();
        }
      }
      if (nextProps.MessageReducer.newMessage.type === "CHAT_LEAD_CAPTURE") {
        let contact =
          this.state.conversationObject.contactsMap[conversation.visitorId];
        contact.email = nextProps.MessageReducer.newMessage.message;
        this.props.dispatch(receiveUpdateContact({ contact: contact }));
        this.props.dispatch(updatePeopleEntity(contact));

        if (this.props.isOverflowConversation) {
          contact.fullName = contact.firstName ? contact.firstName : "";
          contact.fullName += contact.lastName ? " " + contact.lastName : "";

          this.postContactDetailsToSB(contact);
        }
      }
      if (
        nextProps.MessageReducer.newMessage.type == "CHAT_PROMPT_IGNORED" &&
        nextProps.isOverflowConversation &&
        isValidObject(conversation) &&
        nextProps.MessageReducer.isConnected &&
        !this.state.closeConnectionDispatched &&
        nextProps.MessageReducer.isSubscribedToChannels &&
        !this.state.hasConnectionClosed
      ) {
        console.info("Disconnect chat on prompt ignore..");
        this.disconnectOverflowChat(
          MESSAGE_TYPE.overflow_prompt_closed_on_ignore
        );
      }

      if (
        nextProps.MessageReducer.newMessage.type === "TYPING_START" &&
        !this.props.ConversationReducer.typingStatus
      ) {
        if (
          this.state.conversationId ===
          nextProps.MessageReducer.newMessage.conversationId
        ) {
          if (this.props.isOverflowConversation) this.setChatDisconnectTimer();
          this.showOrHideTypingStatus(
            true,
            nextProps.MessageReducer.newMessage
          );
        }
      } else if (
        nextProps.MessageReducer.newMessage.type === "TYPING_STOP" &&
        this.props.ConversationReducer.typingStatus
      ) {
        if (
          this.state.conversationId ===
          nextProps.MessageReducer.newMessage.conversationId
        )
          this.showOrHideTypingStatus(
            false,
            nextProps.MessageReducer.newMessage
          );
      } else if (
        (nextProps.MessageReducer.newMessage.type === "CHAT" ||
          nextProps.MessageReducer.newMessage.type === "ATTACHMENT" ||
          nextProps.MessageReducer.newMessage.type === "CHAT_LEAD_CAPTURE") &&
        nextProps.MessageReducer.newMessage.messageFrom ===
          MESSAGE_FROM.VISITOR &&
        (!this.props.isOverflowConversation ||
          this.props.MessageReducer.isAcceptedMessageSent)
      ) {
        if (
          (this.state.conversationId ===
            nextProps.MessageReducer.newMessage.conversationId ||
            nextProps.ConversationReducer.expandedConversationIds.indexOf(
              nextProps.MessageReducer.newMessage.conversationId
            ) != -1) &&
          (msgIds
            ? msgIds.indexOf(nextProps.MessageReducer.newMessage.key)
            : -1) === -1 &&
          this.findScrollBar()
        ) {
          //Send Read report
          this.sendMessageStatus(
            nextProps.MessageReducer.newMessage,
            "CHAT_READ"
          );
        }
        if (this.props.isOverflowConversation) {
          this.setChatDisconnectTimer();
        }
      } else if (
        nextProps.isOverflowConversation &&
        nextProps.MessageReducer.newMessage.messageFrom === "VISITOR"
      ) {
        this.setChatDisconnectTimer();
      }

      if (oldConversationId != conversationId && this.isTypingStarted) {
        this.isTypingStarted = false;
        this.sendTypingStatus(false, oldConversationId);
      }
      if (
        (nextProps.MessageReducer.newMessage.type ===
          MESSAGE_TYPE.overflow_chat_disconnected ||
          nextProps.MessageReducer.newMessage.type ===
            MESSAGE_TYPE.overflow_chat_disconnected_abnormally) &&
        this.disconnectTimer
      ) {
        clearTimeout(this.disconnectTimer);
      }
      if (
        nextProps.isOverflowConversation &&
        isValidObject(conversation) &&
        nextProps.MessageReducer.isConnected &&
        !this.state.closeConnectionDispatched &&
        nextProps.MessageReducer.isSubscribedToChannels
      ) {
        let messageType = "";
        if (conversation.overflowStatus == OVERFLOW_STATUS.NOT_OVERFLOWED)
            messageType = MESSAGE_TYPE.overflow_chat_takenover_by_staff;
        else if (conversation.chatStatus == CHAT_STATUS.Closed)
            messageType = MESSAGE_TYPE.overflow_chat_closed_by_staff;

        if (messageType)
          this.setState(
            { ...this.state, closeConnectionDispatched: true },
            () => {
              this.props.dispatch(closeConnection());
              let messageData = this.getChatMessage(messageType);
              if (!conversation.lastClosedBy && conversation.chatStatus != CHAT_STATUS.Closed) {
                this.props.dispatch(onMessage(messageData));
              }
            }
          );
      }
    });

    if (
      nextProps.VideoChat.videoLink &&
      nextProps.VideoChat.videoLink != this.props.VideoChat.videoLink
    ) {
      this.sendVideoLink(nextProps.VideoChat.videoLink);
      window.open(nextProps.VideoChat.videoLink);
      this.props.dispatch(resetLink());
    }
    //Add read report function call here
    this.sendReadReportOnLoad();
  }

  getCurrentConversationInfo( conversationId ) {
    let conversationObject = Object.assign(
      {},
      this.props.ConversationReducer.conversationObject);
    return conversationObject.conversationMap[conversationId] || {};
  }

  sendAcceptedMessage(nextProps) {
    
    if (
      nextProps.MessageReducer.isConnected &&
      nextProps.MessageReducer.isSubscribedToChannels &&
      nextProps.query && nextProps.query.isFullscreen
    ) {
      let conversationId = nextProps.query.conversation_id;
      let currentConversationInfo = this.getCurrentConversationInfo(conversationId); 
      let acceptedMessage = {};
      if ( currentConversationInfo.assignedTo === "" && 
      currentConversationInfo.chatStatus === "OPEN") {
        let agentInfo = {};
        agentInfo.key = nextProps.query.agent_id;
        agentInfo.userId = nextProps.query.agent_id;
        agentInfo.firstName = nextProps.query.agent_first_name;
        agentInfo.lastName = nextProps.query.agent_last_name;
        agentInfo.name = agentInfo.firstName + " " + agentInfo.lastName;
        agentInfo.photoUrl = nextProps.query.agent_photo_url;
        agentInfo.agentLogin = nextProps.query.agent_login;
        agentInfo.login = nextProps.query.agent_login;
        agentInfo.messageFrom = "AGENT";
        agentInfo.projectId = nextProps.query.project_id;
        agentInfo.contactId = nextProps.query.agent_id;
        agentInfo.aliasName = nextProps.query.alias_name;

        console.log("The agent Info :", agentInfo);

        let visitorId = nextProps.query.visitor_id;
        acceptedMessage = {
          channel: "server/routing",
          type: MESSAGE_TYPE.chat_auto_assign_on_overflow,
          message: "",
          actionTo: agentInfo.userId,
          senderId: agentInfo.userId,
          conversationId: conversationId,
          messageFrom: "AGENT",
          messageId: generateMessageId(),
          visitorId: visitorId,
          projectId: "LS-"+agentInfo.projectId,
          timestamp: getServerTime(),
          isFullScreen : true
        };
        this.props.dispatch(
          messageAction.sendMessage(acceptedMessage, {
            interactionId: nextProps.query.connection_id,
          })
        );
        console.log("Accepted signal sent :" , acceptedMessage);
      
      let statusTrackerInfo = {
        connectionId: nextProps.query.connection_id,
        tool: ["Overflow"],
        isConnected: true,
        loadedDate: getServerTime(),
      };

      this.props.dispatch(
        ConversationInfoAction.trackChatStatus(statusTrackerInfo)
      );
      let userId = this.getStaffId();
      let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
        this.props.dispatch(
          ConversationInfoAction.updateConversationRealTime(
            acceptedMessage,
            userId,
            staffMap
          )
        );
    }
    }
  }

  setLocalState(props, callback) {
    let conversationObject = Object.assign(
      {},
      props.ConversationReducer.conversationObject
    );
    let visitorConversationObject = Object.assign(
      {},
      props.ConversationReducer.visitorConversationObject
    );
    let oldConversationId = this.props.conversationId
      ? this.props.conversationId
      : this.props.params.conversationId
      ? this.props.params.conversationId
      : "";
    let currentConversationId = props.conversationId
      ? props.conversationId
      : props.params.conversationId
      ? props.params.conversationId
      : "";
    let newMessageInfo = Object.assign(
      {},
      props.NewMessageReducer.data[currentConversationId]
    );
    let conversation =
      conversationObject.conversationMap[currentConversationId];
    let projectKey = props.projectKey
      ? props.projectKey
      : props.params && props.params.projectkey
      ? props.params.projectkey
      : conversation && conversation.projectId
      ? conversation.projectId.replace("LS-", "")
      : "";
    let isContactDetailsPosted = this.state.isContactDetailsPosted;
    let chatContainerScrollTop = this.state.chatContainerScrollTop;
    let resentFailedOnConnect = this.state.resentFailedOnConnect;
    let newDimensionsAdded =
      props.urlPreviews.previewsMap.requestUrl &&
      this.props.urlPreviews.previewsMap.requestUrl &&
      this.props.urlPreviews.previewsMap.requestUrl.requestUrl !=
        props.urlPreviews.previewsMap.requestUrl.requestUrl
        ? true
        : false;

    if (
      !props.ConversationReducer.isFetching &&
      !this.state.isContactDetailsPosted &&
      conversation &&
      conversationObject.contactsMap[conversation.visitorId]
    ) {
      if (isOverflowConnection()) {
        let contact = conversationObject.contactsMap[conversation.visitorId];
        var data = {};

        if (contact.type == "LEAD" || contact.type == "USER") {
          data.type = contact.email ? "email" : "phoneNumber";
          data.value = contact[data.type];
        } else {
          data.type = "sourceUrl";
          data.value = contact["currentUrl"];
        }

        postWindowMessage(data, "*");
        isContactDetailsPosted = true;
      }
      if (!conversation.lastClosedBy && conversation.chatStatus != CHAT_STATUS.Closed) {
        this.setChatDisconnectTimer();
      }
    }
    if (
      props.MessageReducer.isSubscribedToChannels &&
      this.props.isOverflowConversation &&
      this.props.MessageReducer.isAcceptedMessageSent &&
      !resentFailedOnConnect
    ) {
      resentFailedOnConnect = true;
      this.resendFailedMessages();
    }
    this.setState(
      {
        ...this.state,
        conversationObject: this.props.isFromChatHistory
          ? {
              contactsMap: conversationObject.contactsMap,
              conversationMap: visitorConversationObject.conversationMap,
            }
          : this.props.isFromPeopleProfilePage
          ? {
              contactsMap: props.PeopleReducer.peopleMap,
              conversationMap: conversationObject.conversationMap,
            }
          : conversationObject,
        tempConversationId: props.ConversationReducer.tempConversationId,
        convMsgCursor: props.MessageReducer.convMsgCursorMap,
        conversationId: currentConversationId,
        projectKey: projectKey,
        chatStatus:
          props.params && props.params.chatStatus
            ? props.params.chatStatus
            : undefined,
        assignedTo:
          props.params && props.params.assignedTo
            ? props.params.assignedTo
            : undefined,
        scrollToLstMsg: oldConversationId != currentConversationId,
        isTakingOverByDashboardMsgSent:
          oldConversationId != currentConversationId ||
          (this.props.MessageReducer.newMessage.key !=
            props.MessageReducer.newMessage.key &&
            props.MessageReducer.newMessage.interactionType == "OVERFLOW")
            ? false
            : true,
        messages: props.MessageReducer.messageMap[currentConversationId]
          ? props.MessageReducer.messageMap[currentConversationId]
          : {},
        user: props.UserReducer ? Object.assign({}, props.UserReducer) : {},
        staffMap: props.StaffReducer.dashboardAgents[getProjectId(projectKey)]
          ? Object.assign(
              {},
              props.StaffReducer.dashboardAgents[getProjectId(projectKey)]
            )
          : {},
        overflowAgents: props.StaffReducer.overflowAgents
          ? Object.assign({}, props.StaffReducer.overflowAgents)
          : {},
        isOverflowConversation: props.isOverflowConversation,
        interactionId: props.interactionId,
        isUploadingAttachment: props.AttachmentReducer.isUploading,
        uploadingMessageData: props.AttachmentReducer.messageData,
        videoChatLink: getConnectUrl() + "/" + currentConversationId,
        isContactDetailsPosted: isContactDetailsPosted,
        showVideoLinkPopup:
          oldConversationId != currentConversationId
            ? false
            : this.state.showVideoLinkPopup,
        showScreenShareLinkPopup:
          oldConversationId != currentConversationId
            ? false
            : this.state.showScreenShareLinkPopup,
        showInputBox:
          oldConversationId != currentConversationId
            ? true
            : this.state.showScreenShareLinkPopup,
        isAwWindow: props.isAwWindow,
        newDimensionsAdded,
        newMessage: props.MessageReducer.newMessage,
        agentNotifiableMessage: props.MessageReducer.agentNotifiableMessage,
        newMessageInfo,
        chatContainerScrollTop,
        resentFailedOnConnect,
      },
      callback
    );
  }

  getProjectId(props) {
    let currentConversation =
      this.state.conversationObject.conversationMap[this.state.conversationId];
    let projectId;

    if (currentConversation) {
      projectId = currentConversation.projectId.replace("LS-", "");
    } else {
      projectId = props.projectKey
        ? props.projectKey
        : props.params && props.params.projectkey
        ? props.params.projectkey
        : currentConversation && currentConversation.projectId
        ? currentConversation.projectId.replace("LS-", "")
        : "";
    }

    console.info("projectId:", projectId);

    return projectId;
  }

  hideTypingStatus() {
    if (this.props.ConversationReducer.typingStatus)
      this.showOrHideTypingStatus(
        false,
        getTypingStopMessage(
          this.props.conversationId,
          this.props.visitorId,
          getProjectId(this.state.projectKey) || this.props.overflowedAgent.projectId
        )
      );
  }

  postContactDetailsToSB(contact) {
    let data = getContactDetailsToPostToSBIFrame(contact);
    console.log(
      "Posting Vistor Details To SB for realtime visitor update message : " +
        JSON.stringify(data)
    );
    window.parent.postMessage(data, "*");
  }

  getNewMessages() {
    let converstionId =
      this.props.params && this.props.params.conversationId
        ? this.props.params.conversationId
        : this.state.conversationId;
    let currentConversationMessages = this.props.NewMessageReducer.data
      ? this.props.NewMessageReducer.data[converstionId]
      : "";
    let msgIds = currentConversationMessages
      ? currentConversationMessages.messageIds
      : [];
    return msgIds;
  }

  sendReadReportOnLoad() {
    //Get the current conversation's all messages and iterate and send Read report
    let currentConversationMsgs = this.state.messages;
    let message = {};
    let currentConversationMessages =
      this.props.NewMessageReducer.data && this.props.params
        ? this.props.NewMessageReducer.data[this.props.params.conversationId]
        : "";
    let msgIds = currentConversationMessages
      ? currentConversationMessages.messageIds
      : "";

    if (
      currentConversationMsgs &&
      Object.keys(currentConversationMsgs).length &&
      (!this.props.isOverflowConversation ||
        this.props.MessageReducer.isAcceptedMessageSent)
    ) {
      for (let key in currentConversationMsgs) {
        message = currentConversationMsgs[key];
        if (
          message.messageStatus !== "CHAT_READ" &&
          message.messageFrom === MESSAGE_FROM.VISITOR &&
          (msgIds ? msgIds.indexOf(message.key) : -1) === -1 &&
          this.findScrollBar() &&
          message.type !== MESSAGE_TYPE.chat_prompt_ignored &&
          message.type !== MESSAGE_TYPE.messages_validation
        ) {
          this.sendMessageStatus(message, "CHAT_READ");
          message.messageStatus = "CHAT_READ";
        }
      }
    }
  }

  scrollMessages() {
    let cursor =
      this.prevState.convMsgCursor &&
      this.prevState.convMsgCursor[this.state.conversationId]
        ? this.prevState.convMsgCursor[this.state.conversationId]
        : CURSOR_STATUS.NOT_INITIALIZED;
    let containerDomNode =
      this.child && this.child.refs
        ? ReactDOM.findDOMNode(this.child.refs.chat_History)
        : undefined;

    //To make sure element is available for scrolling
    if (containerDomNode && containerDomNode.scrollHeight) {
      let newMessageData = this.props.NewMessageReducer.data
        ? this.props.NewMessageReducer.data
        : undefined;

      //Scroll to bottom if conversation changed OR First fetch response of selected conversation
      if (
        this.state.scrollToLstMsg ||
        cursor == CURSOR_STATUS.NOT_INITIALIZED
      ) {
        containerDomNode.scrollTop = containerDomNode.scrollHeight;

        if (
          newMessageData &&
          newMessageData[this.state.conversationId] &&
          newMessageData[this.state.conversationId].messageIds &&
          newMessageData[this.state.conversationId].messageIds.length > 0
        )
          this.props.dispatch(clearNewMessageCount(this.state.conversationId));
      }
      //This is to scroll to bottom for each time Message is received and scroll bar is near to bottom.
      else if (
        areNewMessagesAdded(this.prevState.messages, this.state.messages) ||
        (this.props.ConversationReducer.typingStatus &&
          this.state.conversationId ===
            this.props.ConversationReducer.typingMessage.conversationId) ||
        this.state.newDimensionsAdded
      ) {
        if (
          isScrollReachedUptoThePixelsFromBottom(containerDomNode, SCROLL_HEIGHT_FROM_BOTTOM)
        ) {
          scrollElementToBottom(containerDomNode);
          if (!this.state.nearBottom) {
            this.setState({ ...this.state, nearBottom: true });
          }
          if (
            newMessageData &&
            newMessageData[this.state.conversationId] &&
            newMessageData[this.state.conversationId].messageIds &&
            newMessageData[this.state.conversationId].messageIds.length > 0
          )
            this.props.dispatch(
              clearNewMessageCount(this.state.conversationId)
            );
        }
      } else if (this.state.nearBottom && containerDomNode.scrollTop !== 0)
        this.setState({ ...this.state, nearBottom: false });
      //To bring the scrollbar to middle whenever messages getting loaded(appended)
      else if (
        cursor != CURSOR_STATUS.NO_CURSOR &&
        cursor != CURSOR_STATUS.NOT_INITIALIZED &&
        containerDomNode.scrollTop < containerDomNode.scrollHeight / 3
      ) {
        containerDomNode.scrollTop = containerDomNode.scrollHeight / 3 + 100;
      }
    }
  }

  findScrollBar() {
    let containerDomNode =
      this.child && this.child.refs
        ? ReactDOM.findDOMNode(this.child.refs.chat_History)
        : undefined;
    let isAtBottom = false;
    if (containerDomNode && containerDomNode.scrollHeight) {
      isAtBottom =
        Math.floor(containerDomNode.clientHeight) ===
        Math.floor(containerDomNode.scrollHeight - containerDomNode.scrollTop)
          ? true
          : false;
    }
    return isAtBottom;
  }
  updateContainerScrollTop() {
    let containerDomNode = ReactDOM.findDOMNode(this.child.refs.chat_History);
    this.setState({
      ...this.state,
      chatContainerScrollTop: containerDomNode.scrollTop,
    });
  }
  handleWindowClose() {
    if (this.isTypingStarted) {
      this.isTypingStarted = false;
      this.sendTypingStatus(false);
    }
  }

  uploadAttachmentShrtCut() {
    if (this.child && this.child.refs.uploadElement)
      this.child.refs.uploadElement.click();
  }
  toggleVideoLinkShrtCut() {
    if (this.child && this.child.refs.videoLinkRef)
      this.child.refs.videoLinkRef.click();
  }
  toggleScreenShareLinkShrtCut() {
    if (this.child && this.child.refs.screenShareLinkRef)
      this.child.refs.screenShareLinkRef.click();
  }
  showTranscriptPopupShrtCut() {
    if (this.props.isFromChatHistory) return;
    this.showSendTranscript();
    return false;
  }
  setChatDisconnectTimer() {
    if (
      !this.props.autoDisconnectOverflowChatTimer ||
      this.state.hasConnectionClosed
    )
      return;

    if (this.disconnectTimer) clearTimeout(this.disconnectTimer);

    this.disconnectTimer = setTimeout(() => {
      let currentConversation =
        this.state.conversationObject.conversationMap[
          this.state.conversationId
        ];
      if (
        currentConversation &&
        currentConversation.conversationType == "EMAIL_CONVERSATION"
      ) {
        console.debug(
          "not closing for lack of response as it is email conversation"
        );
        return;
      }
      this.disconnectOverflowChat(
        MESSAGE_TYPE.overflow_chat_closed_no_response
      );
    }, this.props.autoDisconnectOverflowChatTimer * 1000);
  }
  disconnectOverflowChat(type) {
    console.debug("hasConnectionClosed:", this.state.hasConnectionClosed);
    if (!this.state.hasConnectionClosed) {
      console.debug("Chat disconnected due to lack of response or ignored");
      let endedMessage = {
        channel: "server/routing",
        type: type,
        message: "",
        actionTo: this.props.overflowedAgent ? this.props.overflowedAgent.userId : this.props.UserReducer.data.id,
        senderId: this.props.overflowedAgent ? this.props.overflowedAgent.userId : this.props.UserReducer.data.id,
        conversationId: this.props.conversationId,
        messageFrom: MESSAGE_FROM.AGENT,
        messageId: generateMessageId(),
        visitorId: this.props.visitorId,
        projectId: getProjectId(this.state.projectKey),
        timestamp: getServerTime(),
      };

      let typingEndMessage = {
        messageId: generateMessageId(),
        message: "",
        timestamp: new Date().getTime(),
        conversationId: this.props.conversationId,
        visitorId: this.props.visitorId,
        projectId: getProjectId(this.state.projectKey),
        type: "TYPING_STOP",
        messageFrom: "VISITOR",
        assignedTo: "",
        read: false,
        interactionType: "OVERFLOW",
      };

      this.props.dispatch(
        sendMessage(endedMessage, {
          interactionId: this.props.interactionId,
        })
      );
      this.props.dispatch(messageAction.onMessage(typingEndMessage));
      this.props.dispatch(closeConnection());

      console.debug("Explicitly setting the connection closed...");
      this.state.hasConnectionClosed = true;
    }
  }
  getAgentNameById(agentId) {
    let assignedAgentName = "";
    let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
    let overflowAgents = this.props.StaffReducer.overflowAgents
      ? this.props.StaffReducer.overflowAgents
      : {};
    let assignedAgent = undefined;
    if (agentId && staffMap) {
      if (!staffMap[agentId] && overflowAgents) {
        //Lets check in the overflow agents.
        assignedAgent = overflowAgents[agentId];
      }

      if (staffMap[agentId]) {
        assignedAgentName = getFullName({ ...staffMap[agentId] });
      } else if (agentId === "System") {
        assignedAgentName = "System";
      } else if (agentId && !this.props.StaffReducer.isLoading) {
        assignedAgentName = "Agent";
      } else {
        assignedAgentName = agentId;
      }
    }

    return assignedAgentName;
  }
  getOverflowAgentNameById(agentId) {
    let agentName = "";
    let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
    let overflowAgents = this.props.StaffReducer.overflowAgents
      ? this.props.StaffReducer.overflowAgents
      : {};
    if (agentId && staffMap) {
      let assignedAgent = staffMap[agentId]
        ? Object.assign({}, staffMap[agentId])
        : undefined;

      if (!assignedAgent && overflowAgents) {
        assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
      }

      if (
        assignedAgent &&
        assignedAgent.aliasName &&
        assignedAgent.aliasName != "null"
      ) {
        agentName = assignedAgent.aliasName;
        if (assignedAgent.lastName && assignedAgent.lastName != "null")
          agentName += " " + assignedAgent.lastName.charAt(0);
      } else if (
        assignedAgent &&
        assignedAgent.firstName &&
        assignedAgent.firstName != "null"
      ) {
        agentName = assignedAgent.firstName;
        if (assignedAgent.lastName && assignedAgent.lastName != "null")
          agentName += " " + assignedAgent.lastName.charAt(0);
      }
    }
    return agentName;
  }

  getAgentImageURLById(agentId) {
    let agentImageURL =  "https://app.chatsupport.co/images/Staff-Avatar.svg";

    let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
    let overflowAgents = this.props.StaffReducer.overflowAgents
      ? this.props.StaffReducer.overflowAgents
      : {};

    if (agentId && staffMap) {
      let assignedAgent = staffMap[agentId]
        ? Object.assign({}, staffMap[agentId])
        : undefined;
      if (!assignedAgent && overflowAgents) {
        //Lets check in the agents
        assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
      }
      agentImageURL =
        assignedAgent &&
        assignedAgent.photoUrl &&
        assignedAgent.photoUrl != "null"
          ? assignedAgent.photoUrl
          : agentImageURL;
    }

    return agentImageURL;
  }

  getVisitorNameById(visitorId) {
    let visitorDetails =
      this.props.ConversationReducer.conversationObject.contactsMap[visitorId];
    let visitorName = "V";
    if (visitorDetails) {
      if (visitorDetails.firstName || visitorDetails.lastName) {
        visitorName = visitorDetails.firstName
          ? visitorDetails.firstName.charAt(0)
          : "" + visitorDetails.lastName
          ? visitorDetails.lastName.charAt(0)
          : "";
      }
    }
    return visitorName || "V";
  }

  shouldEmailTranscript(visitorId) {
    let shouldEmailTranscript = false;
    let visitorDetails =
      this.props.ConversationReducer.conversationObject.contactsMap[visitorId];

    if (
      visitorDetails &&
      visitorDetails.email &&
      isValidEmail(visitorDetails.email) &&
      !visitorDetails.isOnline
    )
      shouldEmailTranscript = true;

    return shouldEmailTranscript;
  }

  loadMessagesOnScroll(e) {
    let scrollTo = e.currentTarget.scrollTop;
    let scrollHeight = e.currentTarget.scrollHeight;
    let triggerPoint = e.currentTarget.scrollHeight / 3;

    let newMessagesCount =
      this.state.newMessageInfo &&
      this.state.newMessageInfo.messageIds &&
      this.state.newMessageInfo.messageIds.length;

    if (newMessagesCount > 0)
      this.setState({ ...this.state, chatContainerScrollTop: scrollTo });

    if (scrollTo < triggerPoint || scrollTo < 200) {
      this.fetchMessages();

      /**
       * To allow scroll to stick on more fetches after moved the scrollbar to last message on conversation at first response
       */
      this.setState({ scrollToLstMsg: false });
    }
  }

  isActiveChat(){
    return this.props.isOverflowConversation || this.props.params.chatStatus === SHORT_CHAT_STATUS_MAP.Open;
  }

  getLastMessageCreatedDate(conversationMessages) {
    if (conversationMessages) {
      let messageKeys = Object.keys(conversationMessages);
      let lastFetchedMessage =
        conversationMessages[messageKeys[messageKeys.length - 1]];
      return lastFetchedMessage.createdDate;
    }
  }

  getProjectIdFromConversationOrState(conversation) {
    return conversation && conversation.projectId
    ? conversation.projectId
    : "LS-" + this.state.projectKey;
  }

  fetchMessages() {
    if (
      !this.props.MessageReducer.isFetching &&
      this.state.conversationId !=
        this.props.ConversationReducer.tempConversationId
    ) {
      let cursor =
        this.props.MessageReducer.convMsgCursorMap[this.state.conversationId];
      console.log("cursor => ", cursor);

      //To Stop fetching messages if there is no cursor
      if (cursor != CURSOR_STATUS.NO_CURSOR) {
        let currentConversationMsgs =
          this.props.MessageReducer.messageMap[this.state.conversationId];
        let currentConversation =
          this.props.ConversationReducer.conversationObject.conversationMap[
            this.state.conversationId
          ];
        console.log("The log current Conversation :", currentConversation);
        let projectId = this.getProjectIdFromConversationOrState(currentConversation);
        let lastMsgCreatedDate = this.getLastMessageCreatedDate(currentConversationMsgs);

        if (this.state.conversationId != "main_tab_APP") {
          this.props.dispatch(
            messageRequest({
              isFromOverflow: this.props.isOverflowConversation,
              isAwWindow: this.props.isAwWindow,
              queryParam: {
                conversationId: this.state.conversationId,
                projectId: "LS-" + this.getProjectId(this.props),
                errorProjectId: projectId === "LS-" ? projectId : undefined, //we can remove this property once we confirmed that the project id is passed properly
                createdDate: lastMsgCreatedDate,
                cursor: getCursor(this.state.convMsgCursor[this.state.conversationId]),
                isActiveChat: this.isActiveChat(),
                limit: CHAT_MESSAGE_LIMIT,
              },
            })
          );
        }
      }
    }
  }
  onMessageKeyUp(message) {
    if (message) {
      let isTypingNotes =
        this.child && this.child.state && this.child.state.isNotesActive == true
          ? true
          : false;
      if (!isTypingNotes) {
        if (this.timer) {
          clearInterval(this.timer);
        }

        this.timer = setTimeout(
          function () {
            if (this.isTypingStarted) {
              this.isTypingStarted = false;
              this.sendTypingStatus(false);
            }
          }.bind(this),
          2500
        );
      }
    }
  }

  onMessageKeyDown(e, message) {
    let currentConversation =
      this.state.conversationObject.conversationMap[this.state.conversationId];
    if (
      currentConversation &&
      currentConversation.newMessage &&
      currentConversation.newMessage !== ""
    )
      this.props.dispatch(
        ConversationInfoAction.resetNewMessage(currentConversation.key)
      );
    if (message) {
      let isTypingNotes =
        this.child && this.child.state && this.child.state.isNotesActive == true
          ? true
          : false;
      let invalidKeys = [8, 9, 17, 18, 20, 91, 27];
      if (!isTypingNotes) {
        if ((e.which || e.keyCode) == 13) {
          if (this.timer) {
            clearInterval(this.timer);
          }
          this.isTypingStarted = false;
          this.sendTypingStatus(false);
        } else if (invalidKeys.indexOf(e.which || e.keyCode) === -1) {
          if (this.timer) {
            clearInterval(this.timer);
          }
          if (!this.isTypingStarted) {
            this.isTypingStarted = true;
            this.sendTypingStatus(true);
          }
        }
      }
    }
  }

  sendTypingStatus(isTyping, conversatoinId) {
    let currentConversation =
      this.state.conversationObject.conversationMap[
        conversatoinId ? conversatoinId : this.state.conversationId
      ];
    if (currentConversation) {
      let visitorId = currentConversation.visitorId;
      let typingMessage = {
        messageId: generateMessageId(),
        message: "",
        type: isTyping ? "TYPING_START" : "TYPING_STOP",
        channel: this.props.isOverflowConversation
          ? "routing/visitor/" + visitorId
          : "private/visitor/LS-" + this.state.projectKey + "/" + visitorId,
        conversationId: currentConversation.key,
        messageFrom: MESSAGE_FROM.AGENT,
        senderId:
          this.props.isOverflowConversation || this.props.isAwWindow
            ? this.props.overflowedAgent.key
            : this.props.UserReducer.data.id,
        actionTo: "",
        visitorId: visitorId,
        projectId: "LS-" + this.state.projectKey,
      };
      this.props.dispatch(
        sendMessage(
          typingMessage,
          this.props.isOverflowConversation
            ? {
                interactionId:
                  this.props.interactionId /* triggerWebHook : false */,
              }
            : {
                echoChannels: ["private/agent/LS-" + this.state.projectKey],
                /* triggerWebHook : false */
              }
        )
      );
    }
  }

  sendMessageStatus(message, messageType) {
    let visitorId = message.senderId;
    let typingMessage = {
      messageId: message.messageId || message.key,
      message: "",
      type: messageType,
      channel: this.props.isOverflowConversation
        ? "routing/visitor/" + visitorId
        : "private/visitor/LS-" + this.state.projectKey + "/" + visitorId,
      conversationId: message.conversationId,
      messageFrom: MESSAGE_FROM.AGENT,
      senderId:
        this.props.isOverflowConversation || this.props.isAwWindow
          ? this.props.overflowedAgent.key
          : getUserReducerFromProps(this.props.UserReducer),
      actionTo: "",
      visitorId: visitorId,
      projectId: "LS-" + this.state.projectKey,
    };
    this.props.dispatch(
      sendMessage(
        typingMessage,
        this.props.isOverflowConversation
          ? { interactionId: this.props.interactionId, triggerWebHook: true }
          : {
              echoChannels: ["private/agent/LS-" + this.state.projectKey],
              triggerWebHook: true,
            }
      )
    );
  }
  showVideoLinkPopup(e) {
    this.setState({ showVideoLinkPopup: true, showInputBox: false });
  }
  hideVideoLinkPopup(e) {
    this.setState({ showVideoLinkPopup: false, showInputBox: true });
  }
  toggleVideoLinkPopup(e) {
    if (this.props.MessageReducer.isConnected)
      this.setState({
        ...this.state,
        showInputBox: this.state.showVideoLinkPopup,
        showVideoLinkPopup: !this.state.showVideoLinkPopup,
        showScreenShareLinkPopup: false,
      });
  }
  toggleScreenShareLinkPopup(e) {
    if (this.props.MessageReducer.isConnected)
      this.setState({
        ...this.state,
        showInputBox: this.state.showScreenShareLinkPopup,
        showScreenShareLinkPopup: !this.state.showScreenShareLinkPopup,
        showVideoLinkPopup: false,
      });
  }
  showChatInput(e) {
    if (this.props.MessageReducer.isConnected)
      this.setState({
        ...this.state,
        showInputBox: true,
        showScreenShareLinkPopup: false,
        showVideoLinkPopup: false,
      });
  }
  showSendTranscript() {
    let popupProps = {
      popupHeader: "Email this Conversation",
      popupContent: <MailTranscriptPopup state={this.state} />,
      hideDecisionButtons: true,
      isReactComponent: true,
      styleClass: "email-popup",
      showContentClass: false,
      closeOnOkButton: true,
    };

    this.props.dispatch(PopupActions.showPopup({ popupProps }));
  }
  getVideoLink(e) {
    let type = e.currentTarget.dataset.teleport_type;
    this.props.dispatch(
      requestVideoLink({
        projectId: "LS-" + this.state.projectKey,
        conversationId: this.state.conversationId,
        isOverflowConversation: this.props.isOverflowConversation,
        type,
      })
    );
  }

  sendVideoLink(videoLink) {
    this.processAndSend(null, videoLink, true);
  }

  uploadAttachment(element) {
    console.log("Entered UploadAttachMent Function");
    if (!!element && !!element.target && element.target.files.length > 0) {
      if (!this.isValidAttachment(element.target.files)) {
        this.props.dispatch(
          NotificationActions.addNotification({
            message: "File Size Should be less then 32MB",
            level: "warningNty",
            dismissAfter: 3000,
          })
        );
        element.target.value = "";
        return;
      }
      let elementId = element.target.id;
      let isLastAttachment = false;

      for (let i = 0; i < element.target.files.length; i++) {
        let file = element.target.files[i];
        let data = new FormData();
        data.append("file", file);

        if (i == element.target.files.length - 1) isLastAttachment = true;
        else isLastAttachment = false;
        data.append("isLastAttachment", isLastAttachment);
        this.processAndSend(data);
      }
      element.target.value = "";
      //this.props.dispatch(AttachmentAction.requestAttachmentUpload(data));
    }
  }

  isValidAttachment(files) {
    var file = null;
    var type;
    var size;
    let label = {
      xls: "Excel",
      pptx: "Power Point",
      csv: "Comma Seperated Values",
      doc: "Document",
      docx: "Word Document",
      pdf: "Poratable Document Format",
      zip: "Compressed File",
      docx: "Excel",
      txt: "Text File",
      ppt: "Power Point",
      rtf: "Rich Text Format",
      image: "Image",
      audio: "Audio",
      video: "Video",
    };
    for (var i = 0; i < files.length; i++) {
      file = files[i];
      type = file && file.type ? file.type : "";
      size = file.size / 1000000;
      if (size > 32) {
        return false;
      }
    }
    let fileType = file.type.includes("application")
      ? file.type.split("/")[1]
      : file.type.split("/")[0];
    Event("LS-" + this.state.projectKey, fileType + " sent", label[fileType]);
    return true;
  }

  sendMessage(e) {
    if (
      (e.key == "Enter" && !e.shiftKey) ||
      e.target.dataset.btn_type === "send_button"
    ) {
      e.preventDefault();
      this.processAndSend();
    }
  }

  encodeMentionedAgents(message, mentionedAgents) {
    let staffMap =
      this.props.StaffReducer.dashboardAgents[
        getProjectId(this.state.projectKey)
      ];

    for (var mentionedAgent of mentionedAgents) {
      let names = mentionedAgent.split(" ");
      let firstName = names[0];
      let lastName = names[1];
      for (var key in staffMap) {
        let staff = staffMap[key];
        if (staff["fullName"].trim() == mentionedAgent.trim()) {
          if (new RegExp(`@${mentionedAgent}`).test(message))
            message = message.replace(
              new RegExp(`@${mentionedAgent}`, "g"),
              `<|${staff.key}|>`
            );
          else if (new RegExp(`@${firstName}`).test(message))
            message = message.replace(
              new RegExp(`@${firstName}`, "g"),
              `<|${staff.key}|>`
            );
          else if (new RegExp(`@${lastName}`).test(message))
            message = message.replace(
              new RegExp(`@${lastName}`, "g"),
              `<|${staff.key}|>`
            );
        }
      }
    }

    console.info("Encoded Message:", message);
    return message;
  }

  processAndSend(
    formData,
    message,
    isVideoLink,
    mentionedAgents,
    isEmailConversation
  ) {
    let currentConversation =
      this.state.conversationObject.conversationMap[this.state.conversationId];
    let visitorId = currentConversation.visitorId;
    let isReopenedChat = false;

    if (isEmailConversation) {
      processAndSendEmailMessage(
        isEmailConversation,
        this.child.state.isNotesActive,
        this.getChatMessage(MESSAGE_TYPE.email_reply),
        message,
        this.props.isOverflowConversation,
        this.props.interactionId
      );
      return;
    }

    if (message || formData) {
      let chatType = MESSAGE_TYPE.chat;
      if (this.child.state.isNotesActive) {
        this.child.hideNotesView();
        chatType = MESSAGE_TYPE.notes;
      }
      if (
        currentConversation.chatStatus === CHAT_STATUS.Closed &&
        !currentConversation.isReopened &&
        chatType == MESSAGE_TYPE.chat
      ) {
        isReopenedChat = true;
        let reopenMessage = this.getChatMessage(MESSAGE_TYPE.chat_reopen);
        this.props.dispatch(
          sendMessage(
            reopenMessage,
            this.props.isOverflowConversation
              ? { interactionId: this.props.interactionId }
              : {
                  echoChannels: ["private/agent/LS-" + this.state.projectKey],
                }
          )
        );
        if (this.state.chatStatus) {
          if (this.state.chatStatus !== SHORT_CHAT_STATUS_MAP.Open)
            this.props.ConversationReducer.conversationObject.conversationMap[
              this.state.conversationId
            ].isModified = true;
          else
            this.props.ConversationReducer.conversationObject.conversationMap[
              this.state.conversationId
            ].isModified = false;
        }

        this.state.conversationObject.conversationMap[
          this.state.conversationId
        ].modifiedType = MODIFICATION_STATUS.RE_OPEN;
        currentConversation.chatStatus = CHAT_STATUS.Open;
        this.props.dispatch(updateOpenConversationCountRealtime(reopenMessage));
        this.props.dispatch(
          updateUnassingedConversationCountRealtime(reopenMessage)
        );
        let userId = this.getStaffId();
        let staffMap = this.props.StaffReducer.dashboardAgents
        ? this.props.StaffReducer.dashboardAgents[
            getProjectId(this.state.projectKey)
          ]
        : {};
        this.props.dispatch(
          ConversationInfoAction.updateConversationRealTime(
            reopenMessage,
            userId,
            staffMap
          )
        );
      }

      if (mentionedAgents && mentionedAgents.length > 0)
        message = this.encodeMentionedAgents(message, mentionedAgents);

      let chtMessage = {
        messageId: generateMessageId(),
        message: message,
        createdDate: getServerTime(),
        type: chatType,
        channel: this.props.isOverflowConversation
          ? "routing/visitor/" + visitorId
          : "private/visitor/LS-" + this.state.projectKey + "/" + visitorId,
        conversationId: this.state.conversationId,
        messageFrom: MESSAGE_FROM.AGENT,
        senderId:
          this.props.isOverflowConversation || this.props.isAwWindow
            ? this.props.overflowedAgent.key
            : this.props.UserReducer.data.id,
        actionTo: "",
        visitorId: visitorId,
        projectId: "LS-" + this.state.projectKey,
        emailTranscript: this.shouldEmailTranscript(visitorId),
        mentionedStaffs: mentionedAgents ? extractMentionedStaffs(message) : [],
        messageStatus: "CHAT_SENDING",
        interactionType: this.props.isOverflowConversation
          ? "OVERFLOW"
          : "DASH_BOARD",
      };

      if (isVideoLink && message.indexOf("s&a") != -1) {
        chtMessage["type"] = MESSAGE_TYPE.screen_share_link;
        chtMessage["timestamp"] = getServerTime();
      } else if (isVideoLink) {
        chtMessage["type"] = MESSAGE_TYPE.video_chat_link;
        chtMessage["timestamp"] = getServerTime();
      }

      if (
        (!currentConversation.assignedTo && !isReopenedChat) ||
        (isReopenedChat &&
          currentConversation.assignedTo !=
            (this.props.isOverflowConversation || this.props.isAwWindow
              ? this.props.overflowedAgentId
              : this.props.UserReducer.data.id))
      )
        chtMessage["actionTo"] =
          this.props.isOverflowConversation || this.props.isAwWindow
            ? this.props.overflowedAgent.key
            : this.props.UserReducer.data.id;

      let type = this.props.isOverflowConversation
        ? { interactionId: this.props.interactionId }
        : {
            echoChannels: ["private/agent/LS-" + this.state.projectKey],
          };
      if (!!formData) {
        let publishMessage = true;
        let fileDetails = {};
        chtMessage["type"] = MESSAGE_TYPE.attachment;
        chtMessage["timestamp"] = getServerTime();
        chatType = MESSAGE_TYPE.attachment;

        fileDetails.fileName = formData.get("file").name;
        fileDetails.filePath =
          getBucketHostName() +
          "/" +
          getHostName() +
          "/" +
          encodeURI(formData.get("file").name);
        fileDetails.size = formData.get("file").size;
        fileDetails.type = formData.get("file").type;

        chtMessage["message"] = JSON.stringify(fileDetails);
        chtMessage["interactionType"] = this.props.isOverflowConversation
          ? "OVERFLOW"
          : "DASH_BOARD";
        type["needServerAck"] = true;
        type["id"] = chtMessage.messageId;

        let requestData = {};
        formData.append("id", chtMessage.messageId);
        formData.append("chtMessage", chtMessage);
        formData.append("fileName", fileDetails.name);
        requestData["formData"] = formData;
        requestData["message"] = {};
        if (
          this.props.ConversationReducer.tempConversationId ==
          currentConversation.key
        )
          publishMessage = false;

        requestData["id"] = chtMessage.messageId;
        requestData["fileName"] = fileDetails.fileName;
        requestData["projectId"] = "LS-" + this.state.projectKey;
        requestData["message"][chtMessage.messageId] = {
          chtMessage: Object.assign({}, chtMessage),
          type: type,
          publishMessage: publishMessage,
          formData: formData,
        };
        this.props.dispatch(
          AttachmentAction.requestFileUploadUrl(
            requestData,
            this.props.isOverflowConversation
          )
        );

        if (
          this.props.ConversationReducer.tempConversationId !=
          currentConversation.key
        )
          this.props.dispatch(addMessage(chtMessage));
      }

      console.info("attachment debugger:", chtMessage);

      if (
        this.props.ConversationReducer.tempConversationId ===
        currentConversation.key
      ) {
        if (this.props.ConversationReducer.isCreating == false)
        {
          let userId = this.getStaffId();
          let staffMap = getStaffMap(getProjectId(this.state.projectKey));
          this.props.dispatch(
            ConversationInfoAction.createNewConversationRequested(
              currentConversation,
              userId,staffMap
            )
          );
        }
         

        this.props.dispatch(addMessageToToBeSentList(chtMessage));
      } else {
        if (!formData) {
          //TODO: If overlfow and chat already closed then send notes via http to dashboard channel.
          if (
            this.props.isOverflowConversation &&
            !this.props.MessageReducer.isConnected &&
            chtMessage.type === MESSAGE_TYPE.notes
          ) {
            let chatTempMessage = { ...chtMessage };
            chatTempMessage.channel =
              "private/agent/LS-" + this.state.projectKey;
            chatTempMessage.createdDate = getServerTime();
            chatTempMessage.timestamp = getServerTime();

            let notesToHttp = {
              channel: chatTempMessage.channel,
              type: "chat",
              data: chatTempMessage,
              userInfo: {
                userId: this.props.overflowedAgent.userId,
                contactId: this.props.overflowedAgent.contactId,
                name: this.props.overflowedAgent.name,
                email: this.props.overflowedAgent.agentLogin,
                messageFrom: this.props.overflowedAgent.messageFrom,
                isOverflowAgent: true,
              },
            };

            if (navigator.onLine)
              this.props.dispatch(sendHttpMessage(notesToHttp));
            else this.props.dispatch(addOfflineMessage(notesToHttp));
            chatTempMessage.key = chtMessage.messageId;
            this.props.dispatch(onMessage(chatTempMessage));
          } else {
            type["id"] = chtMessage.messageId;
            type["needServerAck"] = true;
            //this.sendStaffJoinedTag( currentConversation , chtMessage );
            this.props.dispatch(
              queueUnackMessage(
                this.getUnackMessage(
                  chtMessage.conversationId,
                  chtMessage.messageId,
                  type,
                  chtMessage.type
                )
              )
            );
            this.props.dispatch(sendMessage(chtMessage, type)); //Add meta data
          }
        }

        this.props.dispatch(updateOpenConversationCountRealtime(chtMessage));
        this.props.dispatch(
          updateUnassingedConversationCountRealtime(chtMessage)
        );
        let userId = this.getStaffId();
        let staffMap = getStaffMap(getProjectId(this.state.projectKey));
        this.props.dispatch(
          ConversationInfoAction.updateConversationRealTime(
            chtMessage,
            userId,
            staffMap
          )
        );

        if (
          (this.isOverflowTakingCareByDashboardAgent(chtMessage) ||
          (currentConversation.assignedTo != chtMessage.senderId &&
            (chatType == MESSAGE_TYPE.chat ||
              chatType == MESSAGE_TYPE.attachment) &&
            !this.props.isOverflowConversation &&
            !(formData && formData.get("isLastAttachment") != "true")) && !this.props.UserReducer.isFullScreenMode)
        ) {
          chtMessage = this.getChatMessage(
            MESSAGE_TYPE.chat_auto_assigned_on_reply
          );
          console.log("chat_auto_assigned_on_reply is set", chtMessage);

          this.sendStaffJoinedTag(chtMessage);

          let meta = this.props.isOverflowConversation
            ? { interactionId: this.props.interactionId }
            : {
                echoChannels: ["private/agent/LS-" + this.state.projectKey],
                needServerAck: true,
                id: chtMessage.id,
              };

          this.props.dispatch(
            queueUnackMessage(
              this.getUnackMessage(
                chtMessage.conversationId,
                chtMessage.messageId,
                meta
              )
            )
          );
          this.props.dispatch(sendMessage(chtMessage, meta));
          this.props.dispatch(updateOpenConversationCountRealtime(chtMessage));
          this.props.dispatch(
            updateUnassingedConversationCountRealtime(chtMessage)
          );
          staffMap = this.props.StaffReducer.dashboardAgents
          ? this.props.StaffReducer.dashboardAgents[
              getProjectId(this.state.projectKey)
            ]
          : {};
          this.props.dispatch(
            ConversationInfoAction.updateConversationRealTime(
              chtMessage,
              userId,
              staffMap
            )
          );
        }
      }

      //To bring the scroll bar to bottom on sending new message
      let containerDomNode = ReactDOM.findDOMNode(this.child.refs.chat_History);
      setTimeout(() => {
        containerDomNode.scrollTop = containerDomNode.scrollHeight;
      }, 200);
    }
  }

  getUnackMessage(conversationId, messageId, meta, chattype) {
    return {
      conversationId,
      messageId,
      meta,
      retries: 1,
      ackTime: getServerTime() + 3000,
    };
  }

  resendFailedMessages() {
    let {
      failedMessages = {},
      unackMessages = {},
      messageMap,
    } = this.props.MessageReducer;
    let message = {};
    console.info("Attempting to resend failed messages...");
    Object.keys(failedMessages).map((messageId) => {
      message = failedMessages[messageId];
      console.info("Resending failed message:", message);
      this.resendFailedMessage(message);
    });
    Object.keys(unackMessages).map((messageId) => {
      message = unackMessages[messageId];
      console.info("Resending unacknowledged message:", message);
      this.resendFailedMessage(message);
    });
  }

  resendFailedMessage(failedMessage) {
    console.info("failedMessage:", failedMessage);
    let { messageMap } = this.props.MessageReducer;
    let { meta, conversationId, messageId } = failedMessage;
    let message = messageMap[conversationId][messageId];
    if (!message) return;
    console.info("message being resent:", message);
    message.messageStatus = "CHAT_SENDING";
    message.createdDate = getServerTime();
    this.props.dispatch(
      queueUnackMessage({ ...failedMessage, ackTime: getServerTime() + 3000 })
    );
    this.props.dispatch(sendMessage(message, meta));
  }

  sendStaffJoinedTag(message) {
    if (message.type != MESSAGE_TYPE.chat_auto_assigned_on_reply) return;

    let joiningMessage = this.getChatMessage(
      MESSAGE_TYPE.staff_joined_conversation
    );

    this.props.dispatch(
      sendMessage(
        joiningMessage,
        this.props.isOverflowConversation
          ? { interactionId: this.props.interactionId }
          : {
              echoChannels: ["private/agent/LS-" + this.state.projectKey],
              needServerAck: false,
              id: message.id,
            }
      )
    );
  }

  getStaffId() {
    return this.props.isOverflowConversation || this.props.isAwWindow
      ? this.props.overflowedAgent.key
      : this.props.UserReducer.data.id;
  }

  isOverflowTakingCareByDashboardAgent(chatMessage) {
    let messages = this.state.messages;
    let messageKeys = Object.keys(messages);
    let lastMessage = messages[messageKeys[0]];

    if (
      ACTIONABLE_TYPES.includes(chatMessage.type) &&
      chatMessage.interactionType == "DASH_BOARD" &&
      lastMessage &&
      lastMessage.interactionType === "OVERFLOW" &&
      this.state.isTakingOverByDashboardMsgSent == false
    ) {
      this.state.isTakingOverByDashboardMsgSent = true;
      return true;
    }
    return false;
  }
  closeChat(e) {
    let conversationMap =
      this.props.ConversationReducer.conversationObject.conversationMap;
    let conversationId = this.state.conversationId;
    let currentConversationObject = Object.assign(
      {},
      conversationMap[conversationId]
    );
    let currentStaffId = this.getStaffId();
    if (isClosedByMentionedAgent(currentStaffId, currentConversationObject)) {
      let mentionCloseMessage = this.getChatMessage(
        MESSAGE_TYPE.mention_closed
      );
      sendMentionedCloseMessage(
        mentionCloseMessage,
        this.props.isOverflowConversation
      );
      currentConversationObject.hasClosedByMentionedStaff = true;
      currentConversationObject.isModified = true;
      currentConversationObject.modifiedType =
        MODIFICATION_STATUS.MENTION_CLOSED;
      currentConversationObject.mentionedStaffs =
        currentConversationObject.mentionedStaffs.filter(
          (staff) => staff != currentStaffId
        );
      this.props.dispatch(
        ConversationInfoAction.updateConversationResponse(
          currentConversationObject
        )
      );
      let userId = this.getStaffId();
      let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
      this.props.dispatch(
        ConversationInfoAction.updateConversationRealTime(
          {
            ...mentionCloseMessage,
            isMentionClosedByMe: true,
          },
          userId,
          staffMap
        )
      );
      if (currentConversationObject.assignedTo !== currentStaffId) return;
    }
    if (
      isSingleAgentConversationClosed(currentStaffId, currentConversationObject)
    ) {
      let singleAgentConversationClosedMessage = this.getChatMessage(
        MESSAGE_TYPE.single_agent_conversation_closed
      );
      sendSingleAgentConversationClosedMessage(
        singleAgentConversationClosedMessage,
        this.props.isOverflowConversation
      );
      currentConversationObject.isModified = true;
      currentConversationObject.modifiedType = MODIFICATION_STATUS.CLOSE;
      currentConversationObject.assignedAgentsObject = removeFromAssignedObject(
        currentConversationObject,
        currentStaffId
      );
      this.props.dispatch(
        ConversationInfoAction.updateConversationResponse(
          currentConversationObject
        )
      );
      let userId = this.getStaffId();
      let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
      this.props.dispatch(
        ConversationInfoAction.updateConversationRealTime(
          {
            ...singleAgentConversationClosedMessage,
          },
          userId,
          staffMap
        )
      );
      if (
        singleAgentConversationClosedMessage.type ===
          MESSAGE_TYPE.single_agent_conversation_closed &&
        getIsAWRecentComponent()
      ) {
        removeRecentComponentFromAW(currentConversationObject.key);
      }
      return;
    }

    let closingMessageToAgents = this.getChatMessage(MESSAGE_TYPE.chat_closed);
    closingMessageToAgents = checkAndGetPendingClosedMessage(
      closingMessageToAgents,
      e
    );
    let nextConversationId = "";
    let shouldAnimate = true;
    if (currentConversationObject) {
      if (currentConversationObject.chatStatus == CHAT_STATUS.Closed && ((currentConversationObject.lastClosedBy !== "System" && this.isAgentOnline(currentStaffId).isTakingChat)  || this.isAssignedConversationClosed(currentConversationObject, currentStaffId)) && !this.props.UserReducer.isFullScreenMode ) return;

      ReactTooltip.hide();
      if (
        this.state.chatStatus &&
        this.state.chatStatus === SHORT_CHAT_STATUS_MAP.Closed
      ) {
        if (
          currentConversationObject.modifiedType &&
          (currentConversationObject.modifiedType ===
            MODIFICATION_STATUS.RE_OPEN ||
            currentConversationObject.modifiedType ===
              MODIFICATION_STATUS.RE_OPEN_ON_ASSIGN)
        ) {
          currentConversationObject.isModified = false;
        } else {
          currentConversationObject.isModified = true;
        }
      } else {
        currentConversationObject.isModified = true;
      }
      currentConversationObject.chatStatus =
        closingMessageToAgents.type === MESSAGE_TYPE.chat_closed_as_pending
          ? CHAT_STATUS.Pending
          : CHAT_STATUS.Closed;
      if (
        currentConversationObject.modifiedType !=
        MODIFICATION_STATUS.MENTION_CLOSED
      )
        currentConversationObject.modifiedType = MODIFICATION_STATUS.CLOSE;
      currentConversationObject.isClosedByMe = true;
      this.props.dispatch(
        updateUnassingedConversationCountRealtime(closingMessageToAgents)
      );
      this.props.dispatch(
        updateOpenConversationCountRealtime(closingMessageToAgents)
      );

      if(closingMessageToAgents.type === MESSAGE_TYPE.chat_closed || MESSAGE_TYPE.chat_closed_as_pending) {
        removeRecentComponentFromAW(closingMessageToAgents.conversationId);
      }

      //Move to next conversation on close
      if (this.props.isFromChatDashboard && !this.state.isAwWindow) {
        let filteredConversationMap = {};
        for (let key in conversationMap) {
          if (
            conversationMap[key].chatStatus ===
            (this.props.params.chatStatus
              ? CHAT_STATUS[SHORT_CHAT_STATUS_MAP[this.props.params.chatStatus]]
              : "")
          ) {
            if (
              (this.props.params.assignedTo === "All" ||
                (this.props.params.assignedTo === "None" &&
                  conversationMap[key].assignedTo === "") ||
                (this.props.params.assignedTo === "@Mentioned" &&
                  conversationMap[key].mentionedStaffs &&
                  this.props.UserReducer.data &&
                  conversationMap[key].mentionedStaffs.includes(
                    this.props.UserReducer.data.id
                  )) ||
                (this.props.params.assignedTo != "All" &&
                  this.props.params.assignedTo != "None" &&
                  this.props.params.assignedTo != "@Mentioned" &&
                  conversationMap[key].assignedTo ==
                    this.props.params.assignedTo)) &&
              (!this.props.query.search_type ||
                (this.props.query.search_type &&
                  this.props.query.search_value &&
                  this.props.query.search_type === "TAG" &&
                  conversationMap[key].tags.includes(
                    this.props.query.search_value
                  )))
            )
              filteredConversationMap[key] = conversationMap[key];
          }
        }

        let conversationKeys = Object.keys(filteredConversationMap);
        let conversationIndex = conversationKeys.indexOf(conversationId);
        let params = this.props.params;
        if (conversationKeys.length >= 2) {
          //To check if its the last conversation
          if (conversationIndex == conversationKeys.length - 1) {
            //taking previous conversation id.
            nextConversationId = conversationKeys[conversationKeys.length - 2];
            shouldAnimate = false;
          }
          //
          else {
            //taking next conversation id.
            nextConversationId = conversationKeys[conversationIndex + 1];
          }
        }

        //To remove the conversation immediately from the "My chats" afer it is resolved.
        // if( this.props.params.chatStatus !== "C" )
        // {
        //     let timeout = setTimeout(
        //     ()=>{
        //         browserHistory.push("/app/" + projectKey + "/chat/dashboard/"+ visitorOption + "/" + chatStatus  +"/"+ sortBy +"/"+ assignedTo + ( nextConversationId ? "/" + nextConversationId : "" ) + ( this.props.pathQueryString ? this.props.pathQueryString : ""))
        //     },400);
        // }
      }
      if (
        !this.props.isOverflowConversation &&
        !currentConversationObject.lastClosedBy
      ) {
        let statusTrackerInfo = {
          webAppId: generateHashCode(
            conversationId + currentConversationObject.createdDate
          ),
          completedDate: getServerTime(),
          isDisconnected: true,
        };
        this.props.dispatch(
          ConversationInfoAction.trackChatStatus(statusTrackerInfo)
        );
      }
      /* this.props.dispatch( ConversationInfoAction.updateConversationRequest(
                {
                    nextConversationId: shouldAnimate ? nextConversationId : "",
                    currentConversationObject,
                    isFromOverflow : this.props.isOverflowConversation,
                    closingMessageToAgents : closingMessageToAgents,
                    updationType: MESSAGE_TYPE.chat_closed,
                    //isModified : true,
                    //modifiedType : MODIFICATION_STATUS.CLOSE
                })) */

      let meta = {
        echoChannels: ["private/agent/" + currentConversationObject.projectId],
      };
      this.props.dispatch(
        sendMessage(
          closingMessageToAgents,
          meta,
          this.props.isOverflowConversation
        )
      ); // closing message
      currentConversationObject.assignedAgentsObject = removeFromAssignedObject(
        currentConversationObject,
        currentConversationObject.assignedTo
      );
      this.props.dispatch(
        ConversationInfoAction.updateConversationResponse(
          currentConversationObject
        )
      );
      let userId = this.getStaffId()
      let staffMap = this.props.StaffReducer.dashboardAgents
      ? this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ]
      : {};
      this.props.dispatch(
        ConversationInfoAction.updateConversationRealTime(
          {
            ...closingMessageToAgents,
            isClosedByMe: true,
          },
          userId,
          staffMap
        )
      );
      if(this.props.UserReducer.isFullScreenMode && currentConversationObject.chatStatus === CHAT_STATUS.Closed ){
        this.disconnectOverflowChat("FULLSCREEN_CHAT_DISCONNECT");
      }
    }
  }

  isAssignedConversationClosed(currentConversationObject, currentStaffId) {
    return (
      currentConversationObject.assignedAgentsObject &&
      currentConversationObject.assignedAgentsObject.closed.includes(
        currentStaffId
      ) && this.isAgentOnline(currentStaffId).isTakingChat
    );
  }

  assignAgent(event) {
    if (event.currentTarget.dataset.id == "None") return;
    if (this.state.conversationId === this.state.tempConversationId) {
      this.props.dispatch(
        showVoicebox({ message: "Initiate a chat", dismissAfter: 3000 })
      );
      return;
    }

    this.setState({ dropDownVisibilityClass: "" });
    let assignedAgentId = "";
    let assignType = "";
    let assigningMessageToAgents = {};

    let currentConversationObject =
      this.props.ConversationReducer.conversationObject.conversationMap[
        this.state.conversationId
      ];
    let currentlyAssignedAgentId = currentConversationObject.assignedTo;

    assignedAgentId =
      event.currentTarget.dataset.id == "None"
        ? ""
        : event.currentTarget.dataset.id;
    assignType =
      event.currentTarget.dataset.id == "None"
        ? MESSAGE_TYPE.chat_unassigned
        : currentConversationObject.chatStatus === "OPEN"
        ? MESSAGE_TYPE.chat_assigned
        : MESSAGE_TYPE.chat_assign_and_reopen;

    if (assignedAgentId === currentlyAssignedAgentId) {
      return;
    }

    assigningMessageToAgents = this.getChatMessage(
      assignType,
      assignedAgentId,
      currentlyAssignedAgentId
    );
    assigningMessageToAgents["isManualAssignment"] =
      assigningMessageToAgents.actionTo !== assigningMessageToAgents.senderId;
    assigningMessageToAgents["isAlreadyAssigned"] = currentlyAssignedAgentId
      ? true
      : false;

    this.props.dispatch(
      updateOpenConversationCountRealtime(assigningMessageToAgents)
    );
    this.props.dispatch(
      updateUnassingedConversationCountRealtime(assigningMessageToAgents)
    );

    currentConversationObject.assignedAgentsObject = removeFromAssignedObject(
      currentConversationObject,
      currentConversationObject.assignedTo
    );

    currentConversationObject.assignedTo = assignedAgentId;

    if (currentConversationObject.chatStatus == "CLOSED") {
      currentConversationObject.isModified =
        currentConversationObject.isModified === true ? false : true;
      currentConversationObject.modifiedType =
        MODIFICATION_STATUS.RE_OPEN_ON_ASSIGN;
    } else if (this.state.assignedTo) {
      currentConversationObject.isModified =
        this.props.params.assignedTo !== "All" &&
        this.props.params.assignedTo !== "@Mentioned" &&
        this.props.params.assignedTo !== currentConversationObject.assignedTo
          ? true
          : false;
      currentConversationObject.modifiedType =
        this.props.params.assignedTo !== "All" &&
        this.props.params.assignedTo !== "@Mentioned" &&
        this.props.params.assignedTo !== currentConversationObject.assignedTo
          ? MODIFICATION_STATUS.CHAT_ASSIGN
          : "";
    }

    if (assignType == MESSAGE_TYPE.chat_assign_and_reopen)
      currentConversationObject.chatStatus = "OPEN";

    /* this.props.dispatch( ConversationInfoAction.updateConversationRequest(  {
            currentConversationObject : currentConversationObject ,
            isFromOverflow : this.props.isOverflowConversation,
            assigningMessageToAgents  : assigningMessageToAgents ,
            updationType              : assignType,
        } ) ); */

    let meta = {
      echoChannels: ["private/agent/" + currentConversationObject.projectId],
    };
    this.props.dispatch(
      sendMessage(
        assigningMessageToAgents,
        meta,
        this.props.isOverflowConversation
      )
    );
    currentConversationObject.assignedAgentsObject = addToAssignedObject(
      currentConversationObject,
      currentConversationObject.assignedTo
    );
    this.props.dispatch(
      ConversationInfoAction.updateConversationResponse(
        currentConversationObject
      )
    );
  }
  isAgentOnline(agentId) {
    let staffMap =
      this.props.StaffReducer.dashboardAgents[
        getProjectId(this.state.projectKey)
      ];
    let staff = staffMap[agentId] || {};
    let isOnline = staff.isOnline || false;
    let isTakingChat = staff.isTakingChat || false;
    return isOnline && isTakingChat;
  }

  getChatMessage(messageType, agentId, currentAgentId) {
    let visitorId =
      this.props.ConversationReducer.conversationObject.conversationMap[
        this.state.conversationId
      ].visitorId;
    let chatMessage = {};

    chatMessage = {
      type: messageType,
      channel: this.props.isOverflowConversation
        ? "routing/visitor/" + visitorId
        : "private/visitor/LS-" + this.state.projectKey + "/" + visitorId,
      message: "",
      actionTo:
        this.props.isOverflowConversation || this.props.isAwWindow
          ? this.props.overflowedAgent.key
          : this.props.UserReducer.data.id,
      senderId:
        this.props.isOverflowConversation || this.props.isAwWindow
          ? this.props.overflowedAgent.key
          : this.props.UserReducer.data.id,
      conversationId: this.state.conversationId,
      messageFrom: MESSAGE_FROM.AGENT,
      messageId: generateMessageId(),
      visitorId: visitorId,
      projectId: "LS-" + this.state.projectKey,
      messageStatus: "CHAT_SENDING",
      interactionType: this.props.isOverflowConversation
        ? "OVERFLOW"
        : "DASH_BOARD",
      createdDate: getServerTime(),
    };

    if (MANUAL_ASSIGNMENT_TYPES.includes(messageType)) {
      let staffMap =
        this.props.StaffReducer.dashboardAgents[
          getProjectId(this.state.projectKey)
        ];
      let assignedAgent = {};

      if (agentId && staffMap) {
        assignedAgent = staffMap[agentId] ? staffMap[agentId] : {};
      }
      if (this.props.isOverflowConversation || this.props.isAwWindow) {
        let overflowedAgent = this.props.overflowedAgent;
        chatMessage = {
          ...chatMessage,
          actionTo: overflowedAgent.key,
          agentDetails: {
            firstName: overflowedAgent.firstName,
            lastName: overflowedAgent.lastName,
            photoUrl: overflowedAgent.photoUrl,
            alaisName: overflowedAgent.aliasName,
            aliasPhotoUrl: overflowedAgent.aliasPhotoUrl,
          },
        };
      } else
        chatMessage = {
          ...chatMessage,
          actionTo:
            CHAT_ASSIGN_TYPES.includes(messageType) ||
            messageType === MESSAGE_TYPE.chat_assign_and_reopen
              ? agentId
              : currentAgentId,
          agentDetails: {
            firstName: assignedAgent.firstName,
            lastName: assignedAgent.lastName,
            photoUrl: assignedAgent.photoUrl,
            alaisName: assignedAgent.aliasName,
            aliasPhotoUrl: assignedAgent.aliasPhotoUrl,
          },
        };
    }

    console.info("Chat Message =>", chatMessage);

    return chatMessage;
  }

  showOrHideTypingStatus(typingStatus, typingMessage) {
    this.props.dispatch(
      ConversationInfoAction.updateTypingStatus(typingStatus, typingMessage)
    );
  }

  showTagPopup() {
    if (this.props.MessageReducer.isConnected) {
      if (this.state.tempConversationId) {
        this.props.dispatch(
          showVoicebox({
            message: "Send a message first!",
            showBelowHeader: true,
            showError: true,
          })
        );
      } else {
        let contactsMap = this.state.conversationObject.contactsMap;
        let conversationMap = this.state.conversationObject.conversationMap;
        let conversation = conversationMap[this.state.conversationId];
        let visitorId = conversation.visitorId;
        let contact = contactsMap[visitorId];
        let fullName = contact
          ? contact.firstName
            ? contact.firstName
            : contact.type
            ? displayVisitorType[contact.type]
            : "Visitor"
          : "Visitor";
        fullName += contact && contact.lastName ? " " + contact.lastName : "";

        let popupProps = {
          popupHeader: "Tag <div>" + fullName + "</div>'s Conversation",
          popupContent: (
            <AddTagsPopup
              getChildRef={(ref) => (this.addTagsPopupContainer = ref)}
              conversationId={this.state.conversationId}
              taggingType={taggingType.conversation_tag}
              requestAddTags={this.tagConversation}
              isOverflowConversation={this.props.isOverflowConversation}
            />
          ),
          hideDecisionButtons: false,
          isReactComponent: true,
          okButton: { text: "Tag Conversation", action: this.tagConversation },
          styleClass: "broadcastPopup tag-conv-popup",
          closeOnOkButton: false,
        };

        this.props.dispatch(PopupActions.showPopup({ popupProps }));
      }
    }
  }
  tagConversation(e) {
    let tagNameString =
      this.addTagsPopupContainer.addTagsPopup.refs.tagNames.value;
    let tagNames = tagNameString.split(",");
    let state = this.addTagsPopupContainer.returnState();
    tagNames = Object.assign(tagNames, state.selectedTagNames);
    let filteredTagNames = [];
    let invalidTags = [];
    for (let tagName of tagNames) {
      let trimmedTagName = tagName.trim();
      let tagsMap = state.tagsMap;
      for (let key in tagsMap) {
        if (tagsMap[key].name.toLowerCase() === trimmedTagName.toLowerCase())
          trimmedTagName = tagsMap[key].name;
      }
      if (!trimmedTagName || !isValidTagName(trimmedTagName))
        invalidTags.push(trimmedTagName);
      else if (trimmedTagName) filteredTagNames.push(trimmedTagName);
    }
    if (filteredTagNames.length > 0) {
      if (invalidTags.length > 0) {
        this.props.dispatch(
          showVoicebox({
            message: "Invalid Tag names ( " + invalidTags.toString() + " )",
            dismissAfter: 3000,
            showBelowHeader: true,
            showError: true,
          })
        );
        this.addTagsPopupContainer.addTagsPopup.refs.tagNames.value =
          invalidTags.toString();
        this.props.dispatch(
          TagActions.addTagsToConversationRequest(
            filteredTagNames,
            state.currentConversation,
            this.props.isOverflowConversation || this.props.isAwWindow
              ? this.props.overflowedAgent.key
              : this.props.UserReducer.data.id,
            this.props.isOverflowConversation
          )
        );
        Event(
          state.currentConversation.projectId,
          "Tag conversation",
          "Tags added to a conversation",
          "Conversations view"
        );
      } else {
        this.props.dispatch(PopupActions.hidePopup());

        this.props.dispatch(
          showVoicebox({
            message: "Tagging conversation !",
            showBelowHeader: true,
          })
        );
        this.props.dispatch(
          TagActions.addTagsToConversationRequest(
            filteredTagNames,
            state.currentConversation,
            this.props.isOverflowConversation || this.props.isAwWindow
              ? this.props.overflowedAgent.key
              : this.props.UserReducer.data.id,
            this.props.isOverflowConversation
          )
        );
        Event(
          state.currentConversation.projectId,
          "Tag conversation",
          "Tags added to a conversation",
          "Conversations view"
        );
      }
    } else if (!tagNameString.trim())
      this.props.dispatch(
        showVoicebox({
          message: "Please input Tag Name",
          dismissAfter: 3000,
          showBelowHeader: true,
          showError: true,
        })
      );
    else if (invalidTags.length > 0)
      this.props.dispatch(
        showVoicebox({
          message: "Invalid Tag names ( " + invalidTags.toString() + " )",
          dismissAfter: 3000,
          showBelowHeader: true,
          showError: true,
        })
      );
  }
  processAndSendNotes() {
    this.child.child.handleReturn(
      this.child.child.refs.editor,
      this.child.child.refs.editor.props.editorState
    );
  }
  messageFromSB(event) {
    let data = event && event.data;
    let type = data && data.type;
    if (type === SB_WINDOW_EVENTS.close) {
      this.props.dispatch(closeConnection());
      console.log("SB Close Event fired and closing the connection");
    }
    if (type === SB_WINDOW_EVENTS.focus) {
      this.props.dispatch(updateSBWindowStatus(data.isSBFocused));
    }
  }
  render() {
    let staffMap =
      this.props.StaffReducer.dashboardAgents[
        getProjectId(this.state.projectKey)
      ];
    return (
      <ActiveChatView
        getNewMessages={this.getNewMessages}
        staffMap={staffMap}
        overflowedAgent={this.props.overflowedAgent}
        shrinkConversation={this.props.shrinkConversation}
        ref={(component) => (this.child = component)}
        isConnectedToNetwork={this.props.MessageReducer.isConnected}
        isSubscribedToChannels={
          this.props.MessageReducer.isSubscribedToChannels
        }
        closeChat={this.closeChat}
        assignAgent={this.assignAgent}
        sendMessage={this.sendMessage}
        onMessageKeyUp={this.onMessageKeyUp}
        onMessageKeyDown={this.onMessageKeyDown}
        processAndSendNotes={this.processAndSendNotes}
        loadMessagesOnScroll={this.loadMessagesOnScroll}
        getVisitorNameById={this.getVisitorNameById}
        getAgentImageURLById={this.getAgentImageURLById}
        getAgentNameById={this.getAgentNameById}
        getOverflowAgentNameById={this.getOverflowAgentNameById}
        uploadAttachment={this.uploadAttachment}
        hideVideoLinkPopup={this.hideVideoLinkPopup}
        showVideoLinkPopup={this.showVideoLinkPopup}
        showChatInput={this.showChatInput}
        toggleVideoLinkPopup={this.toggleVideoLinkPopup}
        getVideoLink={this.getVideoLink}
        processAndSend={this.processAndSend}
        resendFailedMessage={this.resendFailedMessage}
        projects={this.props.projects}
        state={this.state}
        dispatch={this.props.dispatch}
        isFromPeopleProfilePage={this.props.isFromPeopleProfilePage}
        showTagPopup={this.showTagPopup}
        isFromChatHistory={this.props.isFromChatHistory}
        urlPreviews={this.props.urlPreviews}
        toggleScreenShareLinkPopup={this.toggleScreenShareLinkPopup}
        showSendTranscript={this.showSendTranscript}
        scrollMessages={this.scrollMessages}
        isOverflowConversation={this.props.isOverflowConversation}
        unReadCount={this.props.ConversationReducer}
        nearBottom={this.state.nearBottom}
        isInternalUser={this.state.user.isInternalUser}
        hideTypingStatus={this.hideTypingStatus}
        messageReducer={this.props.MessageReducer}
      />
    );
  }
}

ActiveChatViewContainer.propTypes = {
  params: {
    conversationId: PropTypes.string.isRequired,
  }
}

export default ActiveChatViewContainer;
