/**
 * Created by melvin on 25/04/17.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { USER_ROLE_TO_STAFF_ROLE_MAP } from "../../commons/Constants";

class StaffRoleDropDown extends Component {
  constructor(props) {
    super(props);
    this.toggleDropDownMenu = this.toggleDropDownMenu.bind(this);
    this.hideDropDownMenu = this.hideDropDownMenu.bind(this);

    this.state = {
      showDropdowns: false,
    };
  }

  toggleDropDownMenu() {
    this.setState({ ...this.state, showDropdown: !this.state.showDropdown });
  }

  hideDropDownMenu() {
    this.setState({ ...this.state, showDropdown: false });
  }

  render() {
    let availableRoles = ["Owner", "Staff"];
    let staffRole =
      USER_ROLE_TO_STAFF_ROLE_MAP[this.props.staffItem.userPermission];

    return (
      <div
        tabIndex="0"
        className={
          "roleDwn roleDwnapply " +
          ((this.props.parentState.activeProject &&
            this.props.parentState.activeProject.createdBy ===
              this.props.staffItem.key) ||
          this.props.parentState.loggedInUserPermission !== "FULL_ACCESS"
            ? "disabled"
            : "")
        }
        onClick={this.toggleDropDownMenu}
        onBlur={this.hideDropDownMenu}
      >
        <p>{staffRole}</p>
        <ul
          className={
            this.state.showDropdown === true &&
            this.props.parentState.activeProject.createdBy !==
              this.props.staffItem.key &&
            this.props.parentState.loggedInUserPermission !== "RESTRICTED"
              ? "db"
              : "dn"
          }
        >
          {availableRoles.map((role, i) => {
            if (staffRole !== role)
              return (
                <li key={i}>
                  <a
                    href="javascript:void(0)"
                    onMouseDown={this.props.updateUserRoleHandler}
                    data-staffkey={this.props.staffItem.key}
                  >
                    {role}
                  </a>
                </li>
              );
          })}
        </ul>
      </div>
    );
  }
}
export default StaffRoleDropDown;
