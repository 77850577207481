import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    CampaignBack,
} from "../../../app/commons/Icons";
import { updateCampaignFromDashboard } from "../../actions/BroadcastMessageStatsAction";
import { hideConfirmPopup } from "../../actions/ConfirmPopupAction";
const ConfirmPopup = (props) => {
    const { ConfirmPopupReducer, campaignDetails } = useSelector((state) => ({
        ConfirmPopupReducer: state.ConfirmPopupReducer,
        campaignDetails: state.BroadcastMessageStatsReducer,

      }));

        useEffect(() => {
            document.addEventListener("keydown", handleEscapeKey);
            return () => {
            document.removeEventListener("keydown", handleEscapeKey);
            };
        }, []);
        
        const handleEscapeKey = (e) => {
            if ((e.which === 27 || e.keyCode === 27))
                closeButton.current.click();
        };
        const closeButton = useRef("");

        const dispatch = useDispatch();
        const { onClose, onCancel, onOk, contentText, headerText, okButtonText, cancelButtonText } = ConfirmPopupReducer.popupData || {};
        const onPopupClose = (e)=>{
            dispatch(hideConfirmPopup());
            if(onClose){
                onClose(e);
            }
            
        }
        const onPopupCancel = (e)=>{
            dispatch(hideConfirmPopup());
            if (campaignDetails.isCampaignFromDashboard) {
                localStorage.setItem('isCampaignFromDashboard', "false");
                dispatch(updateCampaignFromDashboard(false));
            }
            onCancel(e);
        }
        const onPopupOk = (e)=>{
            dispatch(hideConfirmPopup());
            if (campaignDetails.isCampaignFromDashboard) {
                localStorage.setItem('isCampaignFromDashboard', "false");
                dispatch(updateCampaignFromDashboard(false));
            }
            onOk(e);
        }
        return (<div id="confirm_popup" className={ConfirmPopupReducer.displayPopup ? "" : "dn"}>
                    <div class="cs-popupbg"></div>
                    <div class="cs-popup-model popup-new cs-popup-delete">
                    <header>
                    <h2>
                        <CampaignBack />
                        {headerText}
                    </h2>
                    <cite id="close_button" ref={closeButton} onClick={onPopupClose}>
                        &times;
                    </cite>
                    </header>
                    <section>
                    <div class="cs-model-msg">
                        {contentText}
                    </div>
                    </section>
                    <footer>
                    <a
                        id="cancel_button"
                        href="javascript:void(0)"
                        class="cs-btn cs-decline-btn"
                        onClick={onPopupCancel}
                    >
                        {cancelButtonText}
                    </a>
                    <a
                        id="ok_button"
                        href="javascript:void(0)"
                        class="cs-btn cs-primary-btn"
                        onClick={onPopupOk}
                    >{okButtonText}
                    </a>
                    </footer>
                </div>
            </div>
        );
};

export default ConfirmPopup;
