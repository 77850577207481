import {
  DELETE_USER_REQUEST,
  DELETE_USER_RESPONSE,
  DELETE_USER_FAILED,
} from "../actions/DeleteUserAction";

const DeleteUserReducer = (
  state = { isFetching: false, isSuccessFul: false },
  action
) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isSuccessFul: false,
        isFetching: true,
      };
    case DELETE_USER_RESPONSE:
      return {
        ...state,
        isSuccessFul: true,
        isFetching: false,
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        isSuccessFul: false,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default DeleteUserReducer;
