import React, { useEffect } from "react";
import GroupsSearch from "./GroupsSearch.jsx";
import Groups from "./Groups.jsx";

const GroupsHolder = (props) => {
  const { peopleGroupMap: groupsMap } = props.contacts;
  const groupsList = groupsMap ? Object.values(groupsMap) : [];
  const count = Object.keys(groupsList).length;

  useEffect(() => {
    props.setCategories(groupsList);
  }, [count, groupsMap]);

  return (
    <section className="cs-my-conversations people-search">
      <GroupsSearch />
      <label>All</label>
      <Groups {...props} />
    </section>
  );
};

export default GroupsHolder;
