import React, { useEffect, useRef, useState } from "react";
import { useDispatch  } from "react-redux";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import {
  DEFAULT_WIDGET_BUBBLE_CONTENT,
  DEFAULT_WIDGET_HEADER,
  WIDGET_TYPE,
} from "../../../commons/Constants";
import { ErrorIcon } from "../../../commons/Icons";
import { isValidWidgetText } from "../../../commons/Utility";
import { useChatConfigurationEntity } from "../../hooks/useChatConfigurationEntity";
const WidgetTextComponent = ({
  chatConfiguration,
  updateChatConfig,
  isConfigFetched,
  updateWidgetBubbleContent,
  updateWidgetHeader,
  widgetHeader,
  widgetBubbleContent,
  showAdvanceSettings
}) => {
  const headerRef = useRef({});
  const contentRef = useRef({});
  const [isValidHeader, setIsValidHeader] = useState(true);
  const [isValidBubbleContent, setIsValidBubbleContent] = useState(true);
  const dispatch = useDispatch();
  const chatConfigEntity = useChatConfigurationEntity(chatConfiguration.key);

  useEffect(() => {
    if(headerRef.current && showAdvanceSettings)
      headerRef.current.scrollIntoView({block: "start"});
  },[showAdvanceSettings]);

  useEffect(() => {
    if (isConfigFetched) {
      let header = chatConfiguration.widgetHeaderText || DEFAULT_WIDGET_HEADER;
      let content =
        chatConfiguration.widgetBubbleContent || DEFAULT_WIDGET_BUBBLE_CONTENT;
      updateWidgetHeader(header);
      updateWidgetBubbleContent(content);
    }
  }, [isConfigFetched]);

  const canUpdateHeader = () => {
    return (
      (chatConfiguration.widgetHeaderText !== widgetHeader)
    );
  };

  const canUpdateBubbleContent = () => {
    return (chatConfiguration.widgetBubbleContent !== widgetBubbleContent)
  };

  const updateWidgetHeaderText = (e) => {
    removeActiveField(e);
    setIsValidHeader(true);
    if (widgetHeader === "") {
      updateWidgetHeader(DEFAULT_WIDGET_HEADER);
    }
    if (canUpdateHeader() && isValidWidgetText(widgetHeader)) {
      chatConfiguration.widgetHeaderText = widgetHeader;
      dispatch(showVoicebox({ message: "Updating" }));
      updateChatConfig({
        key: chatConfiguration.key,
        widgetHeaderText: chatConfiguration.widgetHeaderText,
      });
    }
  };

  const updateWidgetBubbleText = (e) => {
    removeActiveField(e);
    setIsValidBubbleContent(true);
    if (widgetBubbleContent === "") {
      updateWidgetBubbleContent(DEFAULT_WIDGET_BUBBLE_CONTENT);
    }
    if (canUpdateBubbleContent() && isValidWidgetText(widgetBubbleContent)) {
      chatConfiguration.widgetBubbleContent = widgetBubbleContent;
      dispatch(showVoicebox({ message: "Updating" }));
      updateChatConfig({
        key: chatConfiguration.key,
        widgetBubbleContent: chatConfiguration.widgetBubbleContent,
      });
    }
  };

  const setActiveField = (e) => {
    if (!e.target.parentElement.className.includes("active"))
      e.target.parentElement.className += "cs-active-field ";
  };

  const removeActiveField = (e) => {
    if (e.target.parentElement.className.includes("active"))
      e.target.parentElement.className = e.target.parentElement.className.replace(
        "cs-active-field ",
        ""
      );
  };

  const openWidgetPreview = () => {
    let chatButton = document.querySelector(".widget-icon");
    if (chatButton) chatButton.click();
  };

  const closeWidgetPreview = () => {
    let closeButton = document.getElementById("close-btn");
    if (closeButton) closeButton.click();
  };

  const handleHeaderChange = (e) => {
    let widgetText = e.target.value;
    openWidgetPreview();
    if (widgetText.length <= 24) {
      updateWidgetHeader(widgetText);
      setIsValidHeader(isValidWidgetText(widgetText) && widgetText.length != 0);
    } else setIsValidHeader(false);
  };

  const handleContentChange = (e) => {
    let widgetText = e.target.value;
    closeWidgetPreview();
    if (widgetText.length <= 24) {
      updateWidgetBubbleContent(widgetText);
      setIsValidBubbleContent(isValidWidgetText(widgetText) && widgetText.length != 0);
    } else setIsValidBubbleContent(false);
  };

  const getErrorMessage = (widgetText) => {
    if (!widgetText || widgetText.trim().length < 2)
      return "should contain atleast 2 characters";
    return "should not exceed 24 characters";
  };

  const getClasses = (element, isValidText) => {
    let classNames = "cs-input-field ";
    const activeElement = document.activeElement;
    if (element == activeElement) classNames += "cs-active-field ";
    if (!isValidText) classNames += "cs-error-field ";
    return classNames;
  };

  const handleHandleKeyDown = (e) => {
    if(e.keyCode === 13)
      if(e.target === headerRef.current)
        updateWidgetHeaderText(e);
      else if(e.target === contentRef.current)
        updateWidgetBubbleText(e);
  }

  return (
    <React.Fragment>
      <div className={getClasses(headerRef.current, isValidHeader)}>
        <label>Widget header</label>
        <input
          type="text"
          ref={headerRef}
          onBlur={updateWidgetHeaderText}
          value={widgetHeader}
          onChange={handleHeaderChange}
          placeholder="Enter a message to display"
          onFocus={setActiveField}
          onKeyDown={handleHandleKeyDown}
        />
        {!isValidHeader ? (
          <span>
            <ErrorIcon />
            {`Widget header ${getErrorMessage(widgetHeader)}`}
          </span>
        ) : (
          ""
        )}
      </div>
      {chatConfiguration.widgetType !== WIDGET_TYPE.ICON ? (
        <div className={getClasses(contentRef.current, isValidBubbleContent)}>
          <label>Chat bubble content</label>
          <input
            type="text"
            ref={contentRef}
            onBlur={updateWidgetBubbleText}
            onChange={handleContentChange}
            placeholder="Enter a message to display"
            value={widgetBubbleContent}
            onFocus={setActiveField}
            onKeyDown={handleHandleKeyDown}
          />
          {!isValidBubbleContent ? (
            <span>
              <ErrorIcon />
              {`Chat bubble ${getErrorMessage(widgetBubbleContent)}`}
            </span>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
export default WidgetTextComponent;
