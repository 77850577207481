import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";
import { switchToOwnAccount } from "../actions/UserAction";
import { WIDGET_STATUS } from "../commons/Constants";
import * as Utils from "../commons/Utility.js";
import {ExitIcon, AdminCloseIcon, AdminMoreIcon} from "../commons/Icons.js";


@connect((store) => ({
  login: store.LoginReducer,
  user: store.UserReducer,
  project: store.ProjectReducer,
  messageReducer: store.MessageReducer,
  invitationReducer: store.InvitationReducer,
  notificationSettings: store.NotificationSettingsReducer,
  exception: store.ExceptionReducer,
  authTokenReducer: store.AuthTokenReducer,
  chatConfiguration: store.ChatConfigurationReducer,
  setupReducer: store.SetupReducer,
  staffReducer: store.StaffReducer,
  adminReducer: store.AdminReducer,
}))
class AdminOptions extends Component {
  constructor() {
    super();
    this.toggleAdminTools = this.toggleAdminTools.bind(this);
    this.takeToAdminPanel = this.takeToAdminPanel.bind(this);
    this.state = {
      isAdminToolsMinimised: false,
    };
  }
  toggleAdminTools() {
    this.setState({
      ...this.state,
      isAdminToolsMinimised: !this.state.isAdminToolsMinimised,
    });
  }
  takeToAdminPanel() {
    if (this.props.user.data && this.props.user.data.originalId)
      this.props.dispatch(switchToOwnAccount("/admin"));
    else browserHistory.push({ pathname: "/admin" });
  }

  getClassName(activeProject) {
    let widgetConfig = this.props.chatConfiguration.data[activeProject.key];
    if (widgetConfig && !widgetConfig.isChatEnabled)
      return WIDGET_STATUS.INACTIVE;
    return WIDGET_STATUS[activeProject.widgetStatus];
  }

  hideAdminButtonStyleForFullScreenMode() {
    if(this.props.user.isFullScreenMode)
      return { display: "none" };
    else 
      return {};
  }

  render() {
    let isAdmin =
      this.props.user.data && this.props.user.data.isAdmin === "true"
        ? true
        : false;
    if (isAdmin) {
      let projectId =
        this.props.user.isInternalUser && this.props.user.selectedProjectKey
          ? Utils.getProjectId(this.props.user.selectedProjectKey)
          : Utils.getProjectId(this.props.activeProjectKey);
      //let projectId = Utils.getProjectId( this.props.activeProjectKey)
      let activeProject = {};
      if (
        this.props.project.projectList &&
        this.props.project.projectList.length > 0
      )
        activeProject = this.props.project.projectList.find(
          (project) => project.key == projectId
        );
      if (Utils.isValidObject(activeProject) && !Utils.isAwWindow())
        return (
          <div
            className={
              "admin-assess-switch" +
              (this.state.isAdminToolsMinimised ? " close" : " open") +
              (" " + this.props.adminReducer.styleClasses.join(" "))
            }

            style={this.hideAdminButtonStyleForFullScreenMode()}
          >
            <div /* className={this.state.isAdminToolsMinimised ? "dn" : ""} */>
              <span className={this.getClassName(activeProject)}>
                {activeProject.key ? "(" + activeProject.key + ")" : ""}
              </span>
              <label className="fs-exclude">
                {activeProject.name ? activeProject.name : ""}
              </label>
              <i onClick={this.takeToAdminPanel} onKeyDown={ () => {} }>
                <ExitIcon />
              </i>
            </div>
            <figure>
              <code
                className={"admin-btn"}
                onClick={this.toggleAdminTools}
                onKeyDown={ () => {} }
              >
                <AdminCloseIcon />
                <AdminMoreIcon />
              </code>
            </figure>
          </div>
        );
    }
    return <span></span>;
  }
}
export default AdminOptions;
             
           
           
           