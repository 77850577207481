 import { useSelector, useDispatch } from "react-redux";

import {
  ID_USER_ROLE_MAP,
  USER_ROLE_ID_MAP,
  ID_USER_SKILL_MAP,
  USER_SKILL_ID_MAP,
  FALLBACK_VALUES,
  USER_STATUS,
  ACTIVE_EDIT_TAB
} from "../../../commons/Constants";
export const getStaffRole = (userProjectMapping) => {
  let role = "";

  for (var i = 0; i < userProjectMapping.length; i++) {
    if (!!userProjectMapping[i] && userProjectMapping[i].status == "ACTIVE") {
      role = userProjectMapping[i].role
        ? ID_USER_ROLE_MAP[userProjectMapping[i].role]
        : "";
      role = role ? role : FALLBACK_VALUES.ROLE;
      return role;
    }
  }

  return role;
};

export const getStaffSkill = (userProjectMapping) => {
  let skillLevel = "";

  for (var i = 0; i < userProjectMapping.length; i++) {
    if (!!userProjectMapping[i] && userProjectMapping[i].status == "ACTIVE") {
      skillLevel = userProjectMapping[i].role
        ? ID_USER_SKILL_MAP[userProjectMapping[i].skillLevel]
        : "";
      skillLevel = skillLevel ? skillLevel : FALLBACK_VALUES.SKILL;
      return skillLevel;
    }
  }

  return skillLevel;
};

export const getAnyOneActiveProjectId = (userProjectMapping) => {
  for (var i = 0; i < userProjectMapping.length; i++) {
    if (!!userProjectMapping[i] && userProjectMapping[i].status == "ACTIVE") {
      return userProjectMapping.projectKey;
    }
  }
  return "";
};

export const fillMappingsByProjectIds = (projectIds, projects) => {
  let selectedProjects = [];
  projects.forEach((project) => {
    if (projectIds.includes(project.key)) {
      selectedProjects.push(project);
    }
  });
  return selectedProjects;
};

export const getStaffProjects = (
  userProjectMappingList,
  accountProjectList
) => {
  let projectIdAry = [];
  let projectNameAry = [];

  if (userProjectMappingList && userProjectMappingList.length > 0)
    userProjectMappingList.forEach(function (userProject, index) {
      if (userProject.status == "ACTIVE")
        projectIdAry.push(userProject.projectKey);
    });

  if (accountProjectList && accountProjectList.length > 0)
    accountProjectList.forEach(function (projectEntity, index) {
      if (projectIdAry.indexOf(projectEntity.key) != -1)
        projectNameAry.push(projectEntity.name);
    });
  return projectNameAry;
};

export const getStaffProjectsFromMap = (userProjectMappingList, projectMap) => {
  let projectIdAry = [];
  let projectNameAry = [];

  if (userProjectMappingList && userProjectMappingList.length > 0)
    userProjectMappingList.forEach(function (userProject, index) {
      if (userProject.status == "ACTIVE")
        projectIdAry.push(userProject.projectKey);
    });

  projectIdAry.forEach(function (projectId, index) {
    if (projectMap.hasOwnProperty(projectId))
      projectNameAry.push(projectMap[projectId].name);
  });
  return projectNameAry;
};

export const getWidgetColorByProjectId = (chatConfigMap, projectId) => {
  let widgetColor = "#2dccd2";
  if (chatConfigMap.hasOwnProperty(projectId)) {
    widgetColor = "#" + chatConfigMap[projectId].widgetThemeColor;
  }

  return widgetColor;
};

export const getProjectNameWidgetColorMap = (
  userProjectMappingList,
  projectMap,
  chatConfigMap,
  connectChats
) => {
  let projectIdAry = [];
  let projectNameAry = [];
  let nameColorMap = {};
  let projectId = {};
  if (userProjectMappingList && userProjectMappingList.length > 0)
    userProjectMappingList.forEach(function (userProject, index) {
      projectId = userProject.projectKey;
      if (connectChats != ACTIVE_EDIT_TAB.CONNECT_CHATS && userProject.status == USER_STATUS.ACTIVE) {
        if (
          projectMap.hasOwnProperty(projectId) &&
          chatConfigMap.hasOwnProperty(projectId)
        )
          nameColorMap[projectMap[projectId].name] =
            "#" + chatConfigMap[projectId].widgetThemeColor;
      } else if (connectChats === ACTIVE_EDIT_TAB.CONNECT_CHATS && userProject.isConnectIntegrationEnabled === true && userProject.status === USER_STATUS.ACTIVE) {
        if (
          projectMap.hasOwnProperty(projectId) &&
          chatConfigMap.hasOwnProperty(projectId)
        )
          nameColorMap[projectMap[projectId].name] =
            "#" + chatConfigMap[projectId].widgetThemeColor;
      }
    });

  return nameColorMap;
};

export const isStaffPartOfProjects = (accountProjectList, projectId) => {
  let projectIdAry = [];
  let projectNameAry = [];

  for (var i = 0; i < accountProjectList.length; i++) {
    if (accountProjectList[i].key == projectId) return true;
  }
  return false;
};

export const getUserProjectIdList = (userProjectMappingList) => {
  let projectIdAry = [];
  userProjectMappingList.forEach(function (userProjectMapping, index) {
    projectIdAry.push(userProjectMapping.projectKey);
  });
  return projectIdAry;
};

export const isHavingSameUserProjectMapping = (
  userProjectMappingList,
  staffId
) => {
  let projectIdAry = [];

  if (
    userProjectMappingList.length > 0 &&
    userProjectMappingList[0].userKey == staffId
  )
    return true;
  return false;
};

export const getUserProjectIds = (projects) => {
  let projectIdAry = [];
  projects.forEach(function (project, index) {
    projectIdAry.push(project.key);
  });
  return projectIdAry;
};

export const getProjectNameById = (accountProjectList, projectId) => {
  for (var i = 0; i < accountProjectList.length; i++) {
    if (accountProjectList[i].key == projectId)
      return accountProjectList[i].name;
  }
  return "";
};

export const isProjectNameAvailable = (projectList, projectName) => {
  for (var i = 0; i < projectList.length; i++) {
    if (projectList[i].name == projectName) return false;
  }
  return true;
};

export const getConnectEnabledUserProjectMappings = (activeUser) => {
  let projectIdAry = [];
  activeUser.forEach( (active, index)=> {
    if (active.isConnectIntegrationEnabled === true) {
      projectIdAry.push(active.projectKey);
    }
  });
  return projectIdAry;
};

const checkAndReturnValidProjects = (projects) => {
  let projectList = projects.length != 0 ? projects[0] : [];
   return projectList.filter((projectList) => projectList.name); 
}

export const getSanitizedData = (projectEntities, userActive) => {
  let projects = [];
  for (const element of userActive) {
    let projectEntity = 0;
    while(projectEntity < projectEntities.length) {
      if (projectEntities[projectEntity].key === element.projectKey) {
        element.name = projectEntities[projectEntity].name;
        projects.push(userActive);
      }
      projectEntity++;
    }
  }
  return checkAndReturnValidProjects(projects);
};
