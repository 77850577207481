import {
  ADD_NEW_VISITOR_TO_QUEUE,
  REMOVE_VISITOR_FROM_QUEUE,
  UPDATE_VISITOR_IN_QUEUE,
  VISITOR_AWAY,
  VISITOR_ONLINE,
  FETCH_VISITOR_QUEUE_RESPONSE,
  FETCH_VISITOR_QUEUE,
  FIRST_TIME_LANDING,
  ENABLE_TAB_SWITCHING,
  ENABLE_CONVERSATION_TAB_SWITCHING,
  DISABLE_CONVERSATION_TAB_SWITCHING,
} from "../actions/VisitorQueueActions";
import { updatePeopleEntity } from "../actions/PeopleAction";
import { reArrangeVistiorList } from "../commons/Utility";
export const initialState = {
  visitorMap: {},
  visitorQueueTotalCount: 0,
  projectColorMap: {},
  isFetching: false,
  isPageSwitching: false,
  showConversationList: false,
  isInitialFetchComplete: false,
};
const VisitorQueueReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case ADD_NEW_VISITOR_TO_QUEUE:
    case UPDATE_VISITOR_IN_QUEUE:
    case VISITOR_AWAY:
    case VISITOR_ONLINE:
      let visitorMap = Object.assign({}, state.visitorMap);
      let isOldVisitor = visitorMap[action.guestInfo.key] || {};
      if (action.isNewVisitor || (Object.keys(isOldVisitor).length > 0 && !isOldVisitor.isDeleted)) {
        visitorMap[action.guestInfo.key] = action.guestInfo;
        if (action.guestInfo.eventType === VISITOR_AWAY) {
          visitorMap[action.guestInfo.key] = Object.assign(action.guestInfo, {
            lastAvailableTime: new Date().getTime(),
          });
        }
      }
      return {
        ...state,
        visitorMap,
        visitorQueueTotalCount: Object.keys(visitorMap).length || 0,
      };

    case REMOVE_VISITOR_FROM_QUEUE:
      let visitorMapQueue = Object.assign({}, state.visitorMap);
      let visitorToRemove = visitorMapQueue[action.guestId] || {};
      let visitorQueueLength = Object.assign({}, state.visitorQueueTotalCount);
      if (Object.keys(visitorToRemove).length > 0) {
        visitorToRemove = Object.assign(visitorToRemove, { isDeleted: true });
        visitorMapQueue[action.guestId] = visitorToRemove;
        visitorQueueLength = visitorQueueLength - 1;
      }
      return {
        ...state,
        visitorMap: visitorMapQueue,
        visitorQueueTotalCount: visitorQueueLength || 0,
      };

    case FETCH_VISITOR_QUEUE:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VISITOR_QUEUE_RESPONSE:
      let visitorMapResponse = Object.assign({}, state.visitorMap);
      visitorMapResponse = Object.assign(visitorMapResponse, action.peopleList);
      return {
        ...state,
        visitorMap: reArrangeVistiorList(visitorMapResponse),
        visitorQueueTotalCount: action.countResponse,
        projectColorMap: action.projectColorMap,
        isFetching: false,
        isInitialFetchComplete: true,
      };
    case ENABLE_TAB_SWITCHING:
      return {
        ...state,
        isPageSwitching:true
      }
    case FIRST_TIME_LANDING:
      return {
        ...state,
        isPageSwitching:false
      }
    case ENABLE_CONVERSATION_TAB_SWITCHING:
      return {
        ...state,
        showConversationList:true,
    }
    case DISABLE_CONVERSATION_TAB_SWITCHING:
      return {
        ...state,
        showConversationList: false,
      }
    default:
      return state;
  }
};

export default VisitorQueueReducer;
