import { call, put, select } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { broadcastEmailStatsResponse } from "../actions/BroadcastEmailStatsAction.js";
import { stripHTML } from "../commons/Utility";

export function* fetchBroadcastEmailEntities(data) {
  console.log("Fetching broadcast email details ->", data);
  const url = "/broadcast/email/get/" + data.projectKey;

  try {
    let refinedResponse = [];
    let response = yield call(Ajax.post, url);
    response = response.data;
    //response 		=  response.hits.hits;
    if (response && response.length) {
      for (let broadcastEmailEntity of response) {
        broadcastEmailEntity.message.value = stripHTML(
          broadcastEmailEntity.message.value
        );
        refinedResponse.push(broadcastEmailEntity);
      }
    }
    yield put(broadcastEmailStatsResponse(refinedResponse));
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}
