import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { ISignUpDTO } from 'src/features/authorization/api/types'
import { GoogleButton } from 'src/features/authorization/components/GoogleButton'
import {
  Content,
  DividerDesktop,
  DividerMobile,
  Container,
} from 'src/features/authorization/components/Join/styled'
import { SignUp } from 'src/features/authorization/forms/SignUp'
import { requestSignUp } from 'src/features/authorization/store/actions'
import {
  selectAuthError,
  selectIsSigningUp,
} from 'src/features/authorization/store/selectors'
import { Title } from 'src/features/ui/components/Typography'
import { IAppState } from 'src/utils/state/store'

export interface IProps {
  isLoading: boolean
  error?: string
  signUp: (data: ISignUpDTO) => void
}

export const JoinComponent: FC<IProps> = ({ isLoading, error, signUp }) => {
  const handleSignUp = (data: ISignUpDTO) => {
    signUp(data)
  }

  return (
    <Container>
      <Title>
        Free live chat to grow <br /> your business.
      </Title>
      <Margin top={38} />
      <Content>
        <GoogleButton />
        <Margin top={32} />
        <DividerMobile>Or, sign up using your email</DividerMobile>
        <DividerDesktop>Or</DividerDesktop>
        <Margin top={32} />
        <SignUp
          serverErrorMessage={error}
          handleSubmit={handleSignUp}
          isLoading={isLoading}
        />
      </Content>
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  isLoading: selectIsSigningUp(state.auth),
  error: selectAuthError(state.auth),
})

const mapDispatchToProps = {
  signUp: requestSignUp,
}

export const Join = connect(mapStateToProps, mapDispatchToProps)(JoinComponent)
