import { useField } from 'formik'
import React, { ChangeEvent, FC, useState } from 'react'
import { Margin } from 'styled-components-spacing'
import { ErrorMessage } from 'src/features/form/components/ErrorMessage'
import { Eye } from 'src/features/form/components/TextInput/components/Eye'
import {
  Container,
  Input,
  Label,
} from 'src/features/form/components/TextInput/styled'
import { IProps } from 'src/features/form/components/TextInput/types'

export const TextInput: FC<IProps> = ({
  name,
  label,
  shouldAlwaysShowError,
  type,
  ...inputProps
}): JSX.Element => {
  const [isPasswordReadable, setIsPasswordReadable] = useState(false)
  const [field, meta, helpers] = useField(name)

  const getIsError = () =>
    shouldAlwaysShowError
      ? Boolean(meta.error)
      : meta.touched && Boolean(meta.error)

  const getIsPasswordType = () => type === 'password'

  const getIsTextSecure = () => !isPasswordReadable

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    helpers.setValue(event.target.value)

  return (
    <Container>
      <Label color="baliHai">
        {Boolean(label) && (
          <>
            {label}
            <Margin top={4} />
          </>
        )}
        <Input
          {...inputProps}
          value={field.value}
          onChange={handleInputChange}
          hasError={getIsError()}
          name={name}
          type={getIsPasswordType() && getIsTextSecure() ? 'password' : 'text'}
        />
        {getIsPasswordType() && <Eye onClick={setIsPasswordReadable} />}
      </Label>
      <Margin top={8} />
      {getIsError() && meta.error && <ErrorMessage message={meta.error} />}
    </Container>
  )
}
