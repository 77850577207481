import {
  SETUP_REQUEST,
  SETUP_RESPONSE,
  SETUP_FAILED,
  UPDATE_APP_VERSION,
} from "../actions/SetupAction";

const SetupReducer = (
  state = {
    setupState: undefined,
    isFetching: false,
    isFetched: false,
    isBeta: false,
  },
  action
) => {
  switch (action.type) {
    case SETUP_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: true,
      };
    case SETUP_RESPONSE:
      return {
        ...state,
        setupState: action.setupState,
        isFetching: false,
        isFetched: true,
      };
    case SETUP_FAILED:
      return {
        ...state,
        setupState: {
          isEmbedded: false,
          isChatConfigured: false,
          isPromptConfigured: false,
        },
        isFetching: false,
        isFetched: false,
      };
    case UPDATE_APP_VERSION:
      return {
        ...state,
        isBeta: action.isBeta,
      };
    default:
      return state;
  }
};
export default SetupReducer;
