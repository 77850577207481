import React, { FC, useState } from 'react'
import { IProp } from 'src/features/form/components/TextInput/components/types'
import { IconButton } from 'src/features/form/components/TextInput/styled'
import { Icon } from 'src/features/ui/components/Icon'

export const Eye: FC<IProp> = ({ onClick }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleToggleVisible = () => {
    setIsVisible(!isVisible)

    if (onClick) {
      onClick(!isVisible)
    }
  }

  if (isVisible) {
    return (
      <IconButton onClick={handleToggleVisible} type="button">
        <Icon width={24} height={24} type="crossedEye" />
      </IconButton>
    )
  }

  return (
    <IconButton onClick={handleToggleVisible} type="button">
      <Icon width={24} height={24} type="eye" />
    </IconButton>
  )
}
