export const WORKING_HOURS_REQUESTED = "WORKING_HOURS_REQUESTED";
export const WORKING_HOURS_RESPONSE = "WORKING_HOURS_RESPONSE";
export const WORKING_HOURS_FETCH_FAILED = "WORKING_HOURS_FETCH_FAILED";
export const CREATE_WORKING_HOURS_REQUESTED = "CREATE_WORKING_HOURS_REQUESTED";
export const CREATE_WORKING_HOURS_RESPONSE = "CREATE_WORKING_HOURS_RESPONSE";
export const CREATE_WORKING_HOURS_FAILED = "CREATE_WORKING_HOURS_FAILED";
export const UPDATE_WORKING_HOURS_REQUESTED = "UPDATE_WORKING_HOURS_REQUESTED";
export const UPDATE_WORKING_HOURS_RESPONSE = "UPDATE_WORKING_HOURS_RESPONSE";
export const UPDATE_WORKING_HOURS_FAILED = "UPDATE_WORKING_HOURS_FAILED";
export const BUSINESS_HOURS_REQUESTED = "BUSINESS_HOURS_REQUESTED";
export const BUSINESS_HOURS_RESPONSE = "BUSINESS_HOURS_RESPONSE";
export const BUSINESS_HOURS_FETCH_FAILED = "BUSINESS_HOURS_FETCH_FAILED";
export const CREATE_BUSINESS_HOURS_REQUESTED =
  "CREATE_BUSINESS_HOURS_REQUESTED";
export const CREATE_BUSINESS_HOURS_RESPONSE = "CREATE_BUSINESS_HOURS_RESPONSE";
export const CREATE_BUSINESS_HOURS_FAILED = "CREATE_BUSINESS_HOURS_FAILED";
export const UPDATE_BUSINESS_HOURS_REQUESTED =
  "UPDATE_BUSINESS_HOURS_REQUESTED";
export const UPDATE_BUSINESS_HOURS_RESPONSE = "UPDATE_BUSINESS_HOURS_RESPONSE";
export const UPDATE_BUSINESS_HOURS_FAILED = "UPDATE_BUSINESS_HOURS_FAILED";

export const getWorkingHours = (userId, accountId, projectKey) => ({
  type: WORKING_HOURS_REQUESTED,
  userId: userId,
  accountId: accountId,
  projectKey: projectKey,
});

export const receiveWorkingHours = (workingHours, userId) => ({
  type: WORKING_HOURS_RESPONSE,
  workingHours,
  userId,
});

export const failedToFetchWorkingHours = (workingHours) => ({
  type: WORKING_HOURS_FETCH_FAILED,
  workingHours,
});

export const createWorkingHoursRequest = (workingHours) => ({
  type: CREATE_WORKING_HOURS_REQUESTED,
  workingHours,
});

export const createWorkingHoursResponse = (workingHours, userId) => ({
  type: CREATE_WORKING_HOURS_RESPONSE,
  workingHours,
  userId,
});

export const createWorkingHoursFailed = () => ({
  type: CREATE_WORKING_HOURS_FAILED,
});

export const updateWorkingHoursRequest = (workingHours, workingHoursId) => ({
  type: UPDATE_WORKING_HOURS_REQUESTED,
  workingHours,
  workingHoursId,
});

export const updateWorkingHoursResponse = (workingHours, userId) => ({
  type: UPDATE_WORKING_HOURS_RESPONSE,
  workingHours,
  userId,
});

export const updateWorkingHoursFailed = () => ({
  type: UPDATE_WORKING_HOURS_FAILED,
});

export const getBusinessHours = (projectKey, accountId) => ({
  type: BUSINESS_HOURS_REQUESTED,
  projectKey,
  accountId,
});

export const receiveBusinessHours = (businessHours, projectKey) => ({
  type: BUSINESS_HOURS_RESPONSE,
  businessHours,
  projectKey,
});

export const failedToFetchBusinessHours = (businessHours) => ({
  type: BUSINESS_HOURS_FETCH_FAILED,
  businessHours,
});

export const createBusinessHoursRequest = (businessHours) => ({
  type: CREATE_BUSINESS_HOURS_REQUESTED,
  businessHours,
});

export const createBusinessResponse = (businessHours, projectKey) => ({
  type: CREATE_BUSINESS_HOURS_RESPONSE,
  businessHours,
  projectKey,
});

export const createBusinessHoursFailed = () => ({
  type: CREATE_BUSINESS_HOURS_FAILED,
});

export const updateBusinessHoursRequest = (businessHours) => ({
  type: UPDATE_BUSINESS_HOURS_REQUESTED,
  businessHours,
});

export const updateBusinessHoursResponse = (businessHours, projectKey) => ({
  type: UPDATE_BUSINESS_HOURS_RESPONSE,
  businessHours,
  projectKey,
});

export const updateBusinessHoursFailed = () => ({
  type: UPDATE_BUSINESS_HOURS_FAILED,
});
