import * as R from 'ramda'
import styled from 'styled-components'
import { Button } from 'src/features/form/components/Button'
import { Text3 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const StyledButton = styled(Button)``

export const ButtonLabel = styled.span`
  white-space: nowrap;
`

export const Container = styled.div`
  background-color: ${R.path(['theme', 'color', 'athensGray'])};
  padding: 2.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 0.9rem;
  word-break: break-word;

  ${mq.sm} {
    flex-direction: row;
    padding: 2.1rem 2.4rem;
  }
`

export const StyledCode = styled(Text3)`
  display: inline-block;
  color: ${R.path(['theme', 'color', 'raven'])};
  max-height: 9.6rem;
  overflow: hidden;
  position: relative;

  ${mq.sm} {
    max-height: 2.4rem;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      to top,
      rgb(240, 242, 245) 1%,
      rgb(240, 242, 245, 0)
    );

    ${mq.sm} {
      background: linear-gradient(
        to right,
        rgb(240, 242, 245, 0) 60%,
        rgb(240, 242, 245, 0.9) 99%
      );
    }
  }
`
