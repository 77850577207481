import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { EMAIL_OPT_OUT_DESCRIPTION } from "../commons/Constants.js";
import { getDecrypted, getProjectId } from "../commons/Utility";
import {
  requestEmailOptOutPreferences,
  requestUpdateEmailOptOut,
  requestDeleteEmailOptOut,
} from "../actions/EmailOptOutAction";
import ManageEmailOptOut from "../components/emailoptout/ManageEmailOptOut.jsx";

@connect((state) => ({ emailOptOut: state.EmailOptOutReducer }))
class ManageEmailOptOutContainer extends Component {
  constructor() {
    super();
    this.state = {
      emailOptOutPreferences: {
        unsubscribedList: new Set(),
        unsubscribeFromAll: false,
        projectId: "",
        key: "",
      },
      emailId: "",
      hasFetchedPreferences: false,
    };

    this.changeOptOutPreferences = this.changeOptOutPreferences.bind(this);
    this.saveOptOutPreferences = this.saveOptOutPreferences.bind(this);
    this.routeToOptedOutAllPage = this.routeToOptedOutAllPage.bind(this);
    this.optOutAllPreferences = this.optOutAllPreferences.bind(this);
    this.hideChatBoxWhenLoaded = this.hideChatBoxWhenLoaded.bind(this);
  }

  componentWillMount() {
    let {
      params: { projectId, visitorInfo: base64VisitorInfo },
    } = this.props;
    let {
      emailOptOut: {
        isFetched: preferencesAlreadyFetched,
        emailOptOutPreferences,
      },
    } = this.props;

    projectId = getProjectId(projectId);
    let { visitorId, emailId } = JSON.parse(getDecrypted(base64VisitorInfo));

    if (!preferencesAlreadyFetched) {
      this.setState({
        emailOptOutPreferences: {
          ...this.state.emailOptOutPreferences,
          key: visitorId,
          projectId,
        },
        emailId,
        hasFetchedPreferences: false,
      });
      this.props.dispatch(requestEmailOptOutPreferences(visitorId));
    } else {
      if (emailOptOutPreferences.unsubscribeFromAll)
        this.routeToOptedOutAllPage();

      this.setState({
        emailOptOutPreferences,
        emailId,
        hasFetchedPreferences: true,
      });
    }
  }

  componentDidMount() {
    window.onload = () => {
      this.hideChatBoxWhenLoaded();
    };
  }

  hideChatBoxWhenLoaded() {
    var chatbox = document.getElementById("ls-chat");

    if (chatbox) chatbox.style.display = "none";
    else setTimeout(this.hideChatBoxWhenLoaded, 100); // call myself again in 50 msecs
  }

  componentWillReceiveProps(nextProps) {
    let {
      isFetching: wasFetchingPreferences,
      isUpdating: wasUpdatingPreferences,
      notFound: wasNotFound,
    } = this.props.emailOptOut;
    let {
      isFetched: hasFetchedPreferences,
      isUpdated: hasUpdatedPreferences,
      notFound: isNotFound,
    } = nextProps.emailOptOut;
    let { emailOptOutPreferences } = nextProps.emailOptOut;

    if (!wasNotFound && isNotFound)
      this.setState({ hasFetchedPreferences: true });

    if (hasFetchedPreferences && emailOptOutPreferences.unsubscribeFromAll)
      this.routeToOptedOutAllPage();

    if (wasFetchingPreferences && hasFetchedPreferences) {
      if (emailOptOutPreferences.unsubscribeFromAll)
        this.routeToOptedOutAllPage();

      this.setState({ emailOptOutPreferences, hasFetchedPreferences: true });
    }

    if (wasUpdatingPreferences && hasUpdatedPreferences) {
      if (emailOptOutPreferences.unsubscribeFromAll)
        this.routeToOptedOutAllPage();

      this.setState({ emailOptOutPreferences, hasFetchedPreferences: true });
    }
  }

  changeOptOutPreferences(element) {
    let {
      target: { id, className },
    } = element;
    let { emailOptOutPreferences } = this.state;
    let { unsubscribedList } = emailOptOutPreferences;

    if (className.includes("switchNo")) unsubscribedList.add(id);
    else unsubscribedList.delete(id);

    this.setState({
      emailOptOutPreferences: { ...emailOptOutPreferences, unsubscribedList },
    });
  }

  saveOptOutPreferences() {
    let { emailOptOutPreferences } = this.state;
    let { unsubscribedList } = emailOptOutPreferences;

    if (unsubscribedList && unsubscribedList.size == 0)
      this.props.dispatch(
        requestDeleteEmailOptOut(emailOptOutPreferences, "save")
      );
    else
      this.props.dispatch(
        requestUpdateEmailOptOut(emailOptOutPreferences, "save")
      );
  }

  optOutAllPreferences() {
    let { emailOptOutPreferences } = this.state;

    emailOptOutPreferences.unsubscribeFromAll = true;
    this.props.dispatch(
      requestUpdateEmailOptOut(emailOptOutPreferences, "optOutAll")
    );
  }

  routeToOptedOutAllPage() {
    let { visitorInfo, projectId } = this.props.params;
    browserHistory.push({
      pathname: "/email/optedOutAll/" + projectId + "/" + visitorInfo,
    });
  }

  render() {
    return (
      <ManageEmailOptOut
        ref={(component) => (this.child = component)}
        changeOptOutPreferences={this.changeOptOutPreferences}
        saveOptOutPreferences={this.saveOptOutPreferences}
        optOutAllPreferences={this.optOutAllPreferences}
        localState={this.state}
      />
    );
  }
}

export default ManageEmailOptOutContainer;
