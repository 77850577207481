import React from "react";
import { getDisplayTime } from "../../commons/Utility";

const ConversationQueueDetails = ({conversationInfo,contactInfo,acceptChat , getLastMessageOfConversation}) => {

  const getImageStyle = () => {
    let style = {};
    if (contactInfo && contactInfo.avatarColor && !contactInfo.profileImage) {
      style = {
        backgroundImage: "url(https://app.chatsupport.co/images/user-icon.svg)",
        backgroundColor: contactInfo.avatarColor,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      };
    }
    return style;
  };

  const getTimeDifference = () => {
    let displayTime = "";
    if(conversationInfo && conversationInfo.createdDate)
      displayTime = getDisplayTime(conversationInfo.createdDate).displayTime; 
    if(displayTime === "just now")
      displayTime = "Just now";
    else if(displayTime.includes("a minute"))
      displayTime = "1m";
    else if(displayTime.includes("minutes"))
      displayTime = displayTime.replace(" minutes ago","m");
    else if(displayTime.includes("an hour"))
      displayTime = displayTime.replace("an hour","1h");
    else if(displayTime.includes(" hours"))
      displayTime = displayTime.replace(" hours","h");
    return displayTime;
    
  }


  const getVisitorFullName = () => {
    let firstName = contactInfo && contactInfo.firstName ? contactInfo.firstName : "Visitor";
    let lastName = contactInfo && contactInfo.lastName ? contactInfo.lastName : "";
    if(lastName == "null")
      lastName = "";

      return `${firstName} ${lastName}`;
  }

  const getLastMessageText = () => {
    let lastMessage = conversationInfo && conversationInfo.lastMessage  ? conversationInfo.lastMessage : getLastMessageOfConversation(conversationInfo.key);
    return lastMessage ? lastMessage : "Visitor Initiated Conversation"
  }

    return (
      <li>
        <span class="queue-info">
          <figure style={getImageStyle()}>
            {contactInfo && contactInfo.profileImage ? <img src={contactInfo.profileImage}></img> : ""}
          </figure>
          <code className={contactInfo && contactInfo.isOnline && contactInfo.isOnline ? "available" : "offline"}></code>
          <p>{getVisitorFullName()}</p>
          <p class="queue-msg">{getLastMessageText()}</p>
          {contactInfo && contactInfo.currentUrl ? <a>{contactInfo.currentUrl}</a> : ""}
        </span>
        <span>{getTimeDifference()}</span>
        <span onClick={() => acceptChat(conversationInfo.key)}>Answer</span>
      </li>
    );
};

export default ConversationQueueDetails;