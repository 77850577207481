import React from 'react'
import { Margin } from 'styled-components-spacing'
import { SubTitle, Text1 } from 'src/features/ui/components/Typography'

export const WidgetInstallationConfirmation = () => (
  <>
    <SubTitle bolderer>Your widget is installed</SubTitle>
    <Margin top={4} />
    <Text1 color="raven">
      You can find the installation details in your widget settings.
    </Text1>
  </>
)
