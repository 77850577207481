import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import Info from "./Info.jsx";
import History from './History.jsx';
import Activity from './ActivityContainer.js';
import {getInitials} from '../commons/ContactsUtility.js';
import MapComponent from '../../components/chat/MapComponent.jsx';
import {browserHistory} from "react-router";
import { displayVisitorType } from "../../commons/Constants.js";
import {addTempConversation} from "../../actions/ConversationInfoAction.js";
import {getVisitorTimeZone, visitorTimeZoneResponse} from '../../actions/ContactInfoAction.js';
import useCustomFields from "../../contacts/hooks/useCustomFields.js";
import { useBroadcastMessageStats } from "../../campaign/hooks/useBroadcastMessageStats.js";
import {getProjectId, createConversationId, getDefaultConversationObject, isValidString, getDisplayPlace, getVisitorDisplayTime} from "../../commons/Utility.js";

const Detail = (props) => {
    const {people={}} = props;
    const {section} = props.params;
    const { campaignId, stats } = props.params;
    const { customFields } = useCustomFields(getProjectId(props.params.projectkey));
    const [selectedView, setSelectedView] = useState('history');
    const {conversationReducer, userReducer, staffRedcuer} = useSelector((state) => ({
        conversationReducer : state.ConversationInfoReducer,
        userReducer : state.UserReducer, 
        staffRedcuer : state.StaffReducer
    }));
    const dispatch = useDispatch();
    const { campaignData } = useBroadcastMessageStats(
        props.params.projectkey,
        campaignId
      );
    useEffect(() => {
        setSelectedView(section);
    }, [section]);
    useEffect(() => {
        if(!people) return;
        console.info("Getting timezone");
        let timeZoneMap = conversationReducer.timeZoneMap || {};
            if (people.key && !people.timeZoneObject && !people.isTimeZoneFetching && !people.isTimeZoneFetchFailed) {
              if (people.latitude && people.latitude != "null" && people.longitude && people.longitude != "null") {
                let latitude = parseFloat(people.latitude).toFixed(2);
                let longitude = parseFloat(people.longitude).toFixed(2);
                if (timeZoneMap[latitude + "," + longitude]) {
                    dispatch(visitorTimeZoneResponse(people.key, latitude, longitude, {
                      ...timeZoneMap[latitude + "," + longitude],
                    },people));
                }
                else {
                    dispatch(getVisitorTimeZone(people.key, latitude, longitude, people));
                }
              }
             }
      }, [people.key]);
    const switchView = (view) => {
        let {params} = props;
        browserHistory.push({
            pathname: `/app/${params.projectkey}/contacts/dashboard/${params.groupId}/detail/${view}/${params.visitorId}/${campaignId}/${stats}`
          });
    }
    const goToPeopleListing = () => {   
        let {params} = props;
        if (params.groupId !== "campaign") {
            browserHistory.push({
                pathname: `/app/${params.projectkey}/contacts/dashboard/${params.groupId}`
            });
        } else {
            browserHistory.push({ pathname: `/app/${params.projectkey}/campaign/${campaignId}/${stats}/${campaignData.campaignSideBarType != null ? campaignData.campaignSideBarType.toUpperCase() : "ALL"  }` });
        }
    }
    const redirectToConverstion = (conversationKey) => {
        let { projectkey } = props.params;
        browserHistory.push({
          pathname:
            "/app/" +
            projectkey +
            "/chat/dashboard/D/O/N" +
            "/" +
            userReducer.data.id +
            "/" +
            conversationKey
        });
      };

    const getInitialOrPhoto = () => {
        try {
        if(people && people['type'] && !isValidString(people['profileImage']))
            return getInitials(people['firstName']) || displayVisitorType[people['type']].charAt(0) || "V";
        else if(people && people['type'] && isValidString(people['profileImage']))
            return <img src={people['profileImage']}/>;
        else 
            return "V";
        }catch(error){
            return "V";
        }
    }

    const createNewConversation = () => {
        let {projectkey, visitorId} = props.params;
        let projectId = getProjectId(projectkey);
        let conversationMap = Object.assign({},conversationReducer.conversationObject.conversationMap);
        let tempConversationId = createConversationId();
        while (conversationMap[tempConversationId])
            tempConversationId = createConversationId();
        let newConversation = getDefaultConversationObject(tempConversationId,projectId,visitorId,userReducer.data.id);
        newConversation.conversationType = "VISITOR_QUEUE_CONVERSATION";
        dispatch(addTempConversation(Object.assign({}, newConversation), people)); 
        setTimeout(() => {redirectToConverstion(newConversation.key)}, 500);
    };
    const getMapComponent = () => {
        let country = people && people.country ? people.country : "";
        let contactsMap = Object.assign({},conversationReducer.conversationObject.contactsMap);
        let contact = contactsMap[people['key']];
        let timeZoneObject = contact && contact.timeZoneObject ? contact.timeZoneObject : undefined;
        timeZoneObject = timeZoneObject ? timeZoneObject : (people.timeZoneObject ? people.timeZoneObject : undefined); 
        let displayTime = getVisitorDisplayTime(timeZoneObject);
        let displayPlace = getDisplayPlace(timeZoneObject, country);
        let contentClass="";
        let mapElement = "";
      if ((contact && contact.isTimeZoneFetchFailed) || (people && (!people.longitude ||  !people.latitude))) {
            contentClass = "not-found";
            displayPlace = "";
            displayTime = "";
            mapElement = (
            <img src={ "https://app.chatsupport.co/images/map-placeholder.png"} />
        );
      } else {
        mapElement = (<MapComponent contact={people} isFromProfilePage={true} latitude={ people.latitude } longitude={ people.longitude } />);
      }
      const getFlagIcon =() => {
        let visitorCountry = people && people.country ? people.country : "";
        let countryCode = visitorCountry != 'ZZ' && visitorCountry ? visitorCountry : "";
        if(countryCode) {
            let countryCodeClass = countryCode.toLowerCase();
            return <i className={"flag-ele "+countryCodeClass}></i>;
        }
        return "";
      }
      return (
        <div class="cs-profile-map cs-dashboard-map fs-exclude">
          {mapElement}
          <span className={contentClass}>
            {displayPlace} {getFlagIcon()}
            <br />
            <p>{displayTime}</p>
          </span>
        </div>
      );
    }

    return (
        <section className="cs-conversation cs-people-wrp">
            <header>
                <code onClick={goToPeopleListing}></code>
                {getMapComponent()}                
            </header>
            <section className="dashboard-list-wrp">
            <aside>
                <div className="dashboard-list-title">
                <figure className={people['isOnline'] ? "online" : ""} style={people['avatarColor'] ? { backgroundColor: people['avatarColor'] } : {}}>{getInitialOrPhoto()}<cite></cite></figure>
                <label>{ (`${people.firstName || ''} ${people.lastName || ''}` ).trim() ||  displayVisitorType[people.type] || "Visitor" }</label>
                <cite onClick={createNewConversation} style={{"display" : (Object.keys(conversationReducer.conversationObject.conversationMap).length != 0 ? "block" : "none")}}>Start Conversation</cite>
                </div>
                <ul className="tab-nav">
                    <li onClick={() => {switchView('history')}} className= { (selectedView == "history" ? "active" : "")}><a href="javascript:void(0)" style={{pointer : "cursor"}}>History</a>
                        <History {...props}/>
                    </li>
                    <li onClick={() => {switchView('info')}} className= { (selectedView == "info" ? "active" : "")}><a href="javascript:void(0)" style={{pointer : "cursor"}} >Details</a>
                        <Info customFields={customFields} {...props}/>
                    </li>
                    <li onClick={() => {switchView('activity')}}  className= { (selectedView == "activity" ? "active" : "")}><a href="javascript:void(0)" style={{pointer : "cursor"}}>Activity</a>
                        <Activity {...props} staff={staffRedcuer} conversations={conversationReducer}/>
                    </li>
                </ul>
            </aside>
            </section>
        </section>
    )
}

export default Detail;