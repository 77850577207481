import {
  CHAT_CONFIGURATION_REQUESTED,
  UPDATE_CHAT_CONFIG_REQUEST,
  UPDATE_WEBSITE_URL_REQUEST,
} from "../actions/ChatConfigurationAction";
import {
  PROJECTLIST_REQUESTED,
  NEW_PROJECTLIST_REQUESTED,
  DELETEPROJECT_REQUESTED,
  CREATE_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_LOGO_REQUEST,
  PROJECT_REQUESTED,
  UPDATE_WEBSITE_SCREENSHOT,
} from "../actions/ProjectAction";
import {
  WORKING_HOURS_REQUESTED,
  CREATE_WORKING_HOURS_REQUESTED,
  UPDATE_WORKING_HOURS_REQUESTED,
  BUSINESS_HOURS_REQUESTED,
  CREATE_BUSINESS_HOURS_REQUESTED,
  UPDATE_BUSINESS_HOURS_REQUESTED,
} from "../actions/WorkingHoursAction";
import ChatPromptConfigActions from "../actions/PromptActions";
import * as ChatConfigurationSagas from "./ChatConfigurationSaga";
import * as ProjectSagas from "./ProjectSaga";
import * as PromptConfigurationSagas from "./PromptConfigurationSaga";
import * as WorkingHoursSaga from "./WorkingHoursSaga";
import { takeLatest } from "redux-saga/effects";

export function* watchChatConfigurationRequest() {
  yield takeLatest(
    CHAT_CONFIGURATION_REQUESTED,
    ChatConfigurationSagas.getChatConfiguration
  );
}

export function* watchUpdateChatConfiguration() {
  yield takeLatest(
    UPDATE_CHAT_CONFIG_REQUEST,
    ChatConfigurationSagas.updateChatConfiguration
  );
}

export function* watchProjectList() {
  yield takeLatest(PROJECTLIST_REQUESTED, ProjectSagas.getProjectList);
}

export function* watchNewProjectList() {
  yield takeLatest(
    NEW_PROJECTLIST_REQUESTED,
    ProjectSagas.getNewlyAddedProjectsList
  );
}

export function* watchProjectRequest() {
  yield takeLatest(PROJECT_REQUESTED, ProjectSagas.getProjectOverflow);
}

export function* watchDeleteProject() {
  yield takeLatest(DELETEPROJECT_REQUESTED, ProjectSagas.deleteProject);
}

export function* watchCreateProject() {
  yield takeLatest(CREATE_PROJECT_REQUEST, ProjectSagas.createProject);
}

export function* watchUpdateProject() {
  yield takeLatest(UPDATE_PROJECT_REQUEST, ProjectSagas.updateProject);
}
export function* watchProjectLogoUpdate() {
  yield takeLatest(UPDATE_PROJECT_LOGO_REQUEST, ProjectSagas.updateLogo);
}

export function* watchFetchChatPromptConfig() {
  yield takeLatest(
    ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_REQUEST,
    PromptConfigurationSagas.fetchChatPromptConfiguration
  );
}

export function* watchCreateChatPromptConfi() {
  yield takeLatest(
    ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_REQUEST,
    PromptConfigurationSagas.createChatPromptConfiguration
  );
}

export function* watchUpdateChatPromptConfig() {
  yield takeLatest(
    ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_REQUEST,
    PromptConfigurationSagas.updateChatPromptConfiguration
  );
}

export function* watchDeleteChatPromptConfi() {
  yield takeLatest(
    ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_REQUEST,
    PromptConfigurationSagas.deleteChatPromptConfiguration
  );
}

export function* watchFetchWorkingHours() {
  yield takeLatest(WORKING_HOURS_REQUESTED, WorkingHoursSaga.getWorkingHours);
}

export function* watchCreateWorkingHours() {
  yield takeLatest(
    CREATE_WORKING_HOURS_REQUESTED,
    WorkingHoursSaga.createWorkingHours
  );
}

export function* watchUpdateWorkingHours() {
  yield takeLatest(
    UPDATE_WORKING_HOURS_REQUESTED,
    WorkingHoursSaga.updateWorkingHours
  );
}

export function* watchFetchBusinessHours() {
  yield takeLatest(BUSINESS_HOURS_REQUESTED, WorkingHoursSaga.getBusinessHours);
}

export function* watchCreateBusinessHours() {
  yield takeLatest(
    CREATE_BUSINESS_HOURS_REQUESTED,
    WorkingHoursSaga.createBusinessHours
  );
}

export function* watchUpdateBusinessHours() {
  yield takeLatest(
    UPDATE_BUSINESS_HOURS_REQUESTED,
    WorkingHoursSaga.updateWorkingHours
  );
}
export function* watchUpdateUrlRequest() {
  yield takeLatest(
    UPDATE_WEBSITE_URL_REQUEST,
    ChatConfigurationSagas.updateWebsiteUrl
  );
}

export function* watchWebsiteScreenshotRequest() {
  yield takeLatest(
    UPDATE_WEBSITE_SCREENSHOT,
    ProjectSagas.captureWebsiteScreenShot
  );
}
