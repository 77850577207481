import React, { FC } from 'react'

export const Spinner: FC<any> = ({ fill, ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M18 10C18 8.32845 17.4764 6.69889 16.5028 5.34018C15.5291 3.98147 14.1544 2.96186 12.5715 2.42456C10.9887 1.88726 9.27729 1.85925 7.67772 2.34448C6.07815 2.8297 4.67075 3.80378 3.65317 5.12991C2.6356 6.45604 2.05897 8.06759 2.00428 9.73825C1.94959 11.4089 2.41958 13.0547 3.34824 14.4446C4.2769 15.8344 5.61759 16.8984 7.182 17.4872C8.74641 18.0761 10.456 18.16 12.0706 17.7274"
      stroke={fill ?? '#4684FF'}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)
