import { config } from 'src/config'
import {
  IFullStorageResponse,
  IPostStorageLocateResponse,
  IWebsitePreviewDTO,
  IWebsitePreviewResponse,
  IWidgetConfigData,
} from 'src/features/wizard/setup/api/types'
import { googleClient, appClient } from 'src/utils/api'

export const putWebsitePreview = (values: IWebsitePreviewDTO) =>
  appClient.put<IWebsitePreviewResponse>(
    '/widget/config/website/preview',
    {
      ...values,
      projectId: `LS-${values.projectId}`,
    },
    {
      withCredentials: true,
    }
  )

export const getWidgetConfig = (widgetId: string) =>
  appClient.get<IWidgetConfigData>(`/widget/config/LS-${widgetId}`, {
    withCredentials: true,
  })

export const putWidgetConfig = (data: {
  widgetId: string
  values: IWidgetConfigData
}) =>
  appClient.put<IWidgetConfigData>(
    `/widget/config/LS-${data.widgetId}`,
    data.values,
    {
      withCredentials: true,
    }
  )

export const postStorageLocate = (data: {
  fileName: string
  projectId: string
}) =>
  appClient.post<IPostStorageLocateResponse>('/v1/storage/locate', data, {
    withCredentials: true,
  })

export const postGoogleStorage = (data: {
  file: File
  policy: string
  signature: string
  key: string
  GoogleAccessId: string
  success_action_redirect: string
}) => {
  const formData = new FormData()

  formData.append('policy', data.policy)
  formData.append('signature', data.signature)
  formData.append('key', data.key)
  formData.append('GoogleAccessId', data.GoogleAccessId)
  formData.append('success_action_redirect', data.success_action_redirect)
  formData.append('file', data.file)

  return googleClient.post<IFullStorageResponse>(config.storagePath, formData, {
    withCredentials: false,
  })
}
