import React, { useRef } from "react";
import Cropper from "react-cropper";

const CropperComponent = (props) => {
  const cropperRef = useRef(null);

  const saveImage = () => {
    let roundedCanvas = cropperRef.current.getCroppedCanvas();
    props.saveImage(roundedCanvas);
  };
  return (
    <div>
      <section>
        <div className="cs-crop-widget">
          {/* <code className={previewImageUrl && previewImageUrl.toLowerCase() == 'default' ? "circle-crop default-icon" : "circle-crop"} style={{borderColor: '#'+chatConfiguration.widgetThemeColor , backgroundColor : ( previewImageUrl && previewImageUrl.toLowerCase() == 'default' ? '#'+chatConfiguration.widgetThemeColor : '' ) }}></code> */}
          <Cropper
            ref={cropperRef}
            src={props.imgSrc}
            style={{
              height: 350,
              width: 350,
              position: "absolute",
              right: 30,
              top: 84,
            }}
            cropBoxMovable={false}
            cropBoxResizable={false}
            toggleDragModeOnDblclick={false}
            guides={false}
            background={false}
            highlight={false}
            dragMode={"move"}
            center={false}
            restore={false}
            minCropBoxHeight={350}
            minCropBoxWidth={350}
            minCanvasHeight={350}
            minCanvasWidth={350}
            minContainerHeight={350}
            minContainerWidth={350}
            viewMode={3}
            wheelZoomRatio={0.1}
          />
        </div>
      </section>
      <footer>
        <a
          onClick={saveImage}
          className="cs-btn cs-primary-btn"
          href="javascript:void(0)"
        >
          Save
        </a>
      </footer>
    </div>
  );
};
export default CropperComponent;
