import ChatPromptConfigActions from "../actions/PromptActions";
import * as util from "../../commons/Utility";
let promptState = {
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  isUpdated: false,
  isDeleting: false,
  isDeleted: false,
  onEdit: false,
  data: {},
  error: "",
};
const ChatPromptConfigurationReducer = (state = promptState, action) => {
  switch (action.type) {
    case ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_RESPONSE:
      state.data[action.projectKey] = action.data;
      return {
        ...state,
        isFetching: false,
        isFetched: true,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_RESPONSE:
      let data1 = util.deepClone(state.data);
      data1[action.data.projectKey] = [
        action.data,
        ...state.data[action.data.projectKey],
      ];
      return {
        ...state,
        data: data1,
        isUpdating: false,
        isUpdated: true,
        onEdit: false,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_REQUEST:
      return {
        ...state,
        onEdit: true,
        isUpdating: true,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_RESPONSE:
      util.updatePromptsFromResponse(
        state.data[action.data.projectKey],
        action.data
      );
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_RESPONSE:
      let data = util.deleteProjectFromArray(
        state.data[action.response.projectKey],
        action.response
      );
      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
      };

    default:
      return state;
  }
};

export default ChatPromptConfigurationReducer;
