import React, { Component } from "react";
import ReactDOM from "react-dom";
import TimeAgo from "../../containers/TimeAgo";
import {
  stripHTML,
  isValidURL,
  getNewFormatedTime,
  getDateOfMessage,
  isOverflowConnection
} from "../../commons/Utility";
import ChatMessageBox from "./ChatMessageBox.jsx";
import { getUrlPreviewInfoRequest } from "../../actions/UrlPreviewAction";
import { TAG_LINE_TYPES, displayVisitorType } from "../../commons/Constants";
import DateTagLineComponent from "./DateTagLine.jsx";
import { store } from "../../app";

class ExpandedConversationHistoryComponent extends Component {
  constructor() {
    super();
    this.state = {};
  }
  getChatViewProps() {
    let containerDomNode = ReactDOM.findDOMNode(this.refs.chat_History);
    let containerProps = {};

    if (containerDomNode)
      containerProps = {
        chatViewRect: containerDomNode.getBoundingClientRect(),
        chatViewScrollTop: containerDomNode.scrollTop,
        chatViewHeight: containerDomNode.clientHeight,
      };

    return containerProps;
  }
  componentDidMount() {
    this.scrollMessagesToBottom();
  }
  componentDidUpdate(previousProps, previousState) {
    if (
      Object.keys(previousProps.messages).length == 0 &&
      Object.keys(this.props.messages) != 0
    ) {
      this.scrollMessagesToBottom();
    }
  }
  scrollMessagesToBottom() {
    let containerDomNode = this.refs.chat_History
      ? ReactDOM.findDOMNode(this.refs.chat_History)
      : undefined;
    //To make sure element is available for scrolling
    if (containerDomNode && containerDomNode.scrollHeight) {
      containerDomNode.scrollTop = containerDomNode.scrollHeight;
    }
  }
  render() {
    let indents = [];
    let messages = this.props.messages;
    let conversation = this.props.selectedConversation
      ? this.props.selectedConversation
      : {};
    let userId = this.props.user.data ? this.props.user.data.id : "";
    if (messages) {
      let urls = [];
      let strippedMessage = "";
      let messageKeys = Object.keys(messages);
      let messageLength = messageKeys.length;
      for (let i = messageLength - 1; i >= 0; i--) {
        let messageKey = messageKeys[i];
        if (
          messages[messageKey] &&
          conversation.key !== messages[messageKey].conversationId
        )
          continue;
        strippedMessage = stripHTML(messages[messageKey].message);
        if (
          strippedMessage &&
          isValidURL(strippedMessage) == true &&
          !this.props.urlPreviews.previewsMap.hasOwnProperty(strippedMessage)
        ) {
          urls.push(strippedMessage);
        }
        let state = this.props.state;
        let staffMap = state.staffMap;
        let overflowAgents = state.overflowAgents;
        let senderStaff = undefined;
        let actionToStaff = undefined;
        if (messages[messageKey].messageFrom == "AGENT") {
          senderStaff = staffMap[messages[messageKey].senderId]
            ? staffMap[messages[messageKey].senderId]
            : overflowAgents[messages[messageKey].senderId]
            ? overflowAgents[messages[messageKey].senderId]
            : undefined;
          actionToStaff = staffMap[messages[messageKey].actionTo]
            ? staffMap[messages[messageKey].actionTo]
            : overflowAgents[messages[messageKey].actionTo]
            ? overflowAgents[messages[messageKey].actionTo]
            : undefined;
        }

        if (i === messageLength - 1) {
          this.state.firstDate = getDateOfMessage(
            messages[messageKey].createdDate
          );
          indents.push(
            <DateTagLineComponent
              createdDate={messages[messageKey].createdDate}
            />
          );
        } else if (
          getDateOfMessage(messages[messageKey].createdDate) !=
            getDateOfMessage(messages[messageKeys[i + 1]].createdDate) &&
          this.state.firstDate !=
            getDateOfMessage(messages[messageKey].createdDate)
        )
          indents.push(
            <DateTagLineComponent
              createdDate={messages[messageKeys[i]].createdDate}              
            />
          );

        indents.push(
          <ChatMessageBox
            dispatch={this.props.dispatch}
            isOverflowConversation={isOverflowConnection()}
            overflowedAgent={this.props.overflowedAgent}
            getChatViewProps={this.getChatViewProps}
            urlPreviews={this.props.urlPreviews}
            currentConversation={this.props.selectedConversation}
            key={messageKey}
            visitorId={this.props.contact.key}
            messageData={messages[messageKey]}
            contact={this.props.contact}
            user={this.props.user}
            senderStaff={senderStaff}
            actionToStaff={actionToStaff}
            getAgentNameById={this.props.getAgentNameById}
            getOverflowAgentNameById={this.props.getOverflowAgentNameById}
            getAgentImageURLById={this.props.getAgentImageURLById}
            uploadingMessageData={this.props.uploadingMessageData}
            isUploadingAttachment={this.props.isUploadingAttachment}
            isFromChatHistory={true}
          />
        );
      }
      if (urls.length > 0) {
        urls = [...new Set(urls)];
        this.props.dispatch(getUrlPreviewInfoRequest(urls, false));
      }
    }
    let contact = this.props.contact;
    let fullName = contact.fullName
      ? contact.fullName
      : contact.email
      ? contact.email
      : contact.type
      ? displayVisitorType[contact.type]
      : " ";
    return (
      <section
        class="cs-user-detail-history"
        style={isOverflowConnection() ? { height: "100vh" } : {}}
      >
        <header>
          <label className="fs-exclude">{fullName}</label>
          <span>
            {conversation.createdDate
              ? getNewFormatedTime(conversation.createdDate + store.getState().UserReducer.timeDifference)
              : ""}
            <cite
              class="history-close-icon"
              onClick={this.props.backButtonClick}
            ></cite>
          </span>
        </header>
        <div
          class="cs-user-conversation"
          style={
            isOverflowConnection() ? { maxHeight: "calc(100vh - 50px)" } : {}
          }
        >
          {indents}
        </div>
      </section>
    );
  }
}
export default ExpandedConversationHistoryComponent;
