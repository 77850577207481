import { store } from "../app.js";
import { ID_USER_ROLE_MAP, USER_ROLE_ID_MAP } from "./Constants";

const rolesMap = {
  OnlineStatusSwitch: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  VisitorQueue: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  ShowAssignChat: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  CanSendMessage: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  EmailTranscript: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  MakeNotes: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  EditVistorDetails: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  SendAttachments: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  SendVideoLink: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  SendScreenShareLink: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  CanCloseConversation: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  ManageWidget: {
    Owner: true,
    Admin: true,
    Member: false,
    "Restricted Member": false,
    Billing: false,
  },
  Notifications: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  EditDeleteOption: {
    Owner: true,
    Admin: true,
    Member: false,
    "Restricted Member": false,
    Billing: false,
  },
  InvitePeople: {
    Owner: true,
    Admin: true,
    Member: false,
    "Restricted Member": false,
    Billing: false,
  },
  ProjectSetUp: {
    Owner: true,
    Admin: true,
    Member: false,
    "Restricted Member": false,
    Billing: false,
  },
  PeopleDelete: {
    Owner: true,
    Admin: true,
    Member: false,
    "Restricted Member": false,
    Billing: false,
  },
  ConversationQueue: {
    Owner: true,
    Admin: true,
    Member: true,
    "Restricted Member": false,
    Billing: false,
  },
  Billing:{
    Owner: true,
    Admin: true,
    Member: false,
    "Restricted Member": false,
    Billing: true,
  }
};

export const isUserHavingPermission = (feature) => {
  let data = store.getState().UserReducer.data;
  // let userRole = !! data && data.hasOwnProperty("userRole") ? data.userRole : "";
  let userRole = "";
  userRole = store.getState().UserReducer.userRole;

  let oldUserPermission = store.getState().UserReducer.oldUserPermission;

  let isAdmin =
    !!data && data.hasOwnProperty("isAdmin") ? data.isAdmin : "false";
  let role = "Restricted Member";
  let pathname = document.location.pathname;

  if (isAdmin == "true" || pathname.includes("overflow")) return true;
  if (!!userRole && userRole != "null") {
    role = ID_USER_ROLE_MAP[userRole];
  } else if (!!oldUserPermission && oldUserPermission != "null") {
    role = oldUserPermission == "FULL_ACCESS" ? "Owner" : "Admin";
  }

  if (rolesMap.hasOwnProperty(feature)) return rolesMap[feature][role];
  return false;
};

export const isUserHavingPermissionByRole = (feature, userRole) => {
  let data = store.getState().UserReducer.data;
  let role = "Restricted Member";
  if (!!userRole) {
    role = ID_USER_ROLE_MAP[userRole];
  }

  if (rolesMap.hasOwnProperty(feature)) return rolesMap[feature][role];
  return false;
};
