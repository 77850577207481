import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { isAuthenticated } from "../commons/Authenticator";
import {
  DEFAULT_PAGE_URL,
  LIVE_SSO_URL,
  STAGING_SSO_URL,
  SIGNUP_SUCCESS,
  UPGRADE_PLAN,
  STAGING_AUTH_PARAMS,
  LIVE_AUTH_PARAMS,
  PLAN_ID
} from "../commons/Constants";
import * as LoginActions from "../actions/LoginAction";
import * as InvitationActions from "../actions/InvitationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as NotificationsActions from "../actions/NotificationAction";
import * as ProjectActions from "../settings/actions/ProjectAction";
import * as UserActions from "../actions/UserAction";
import Login from "../components/Login.jsx";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { CLIENT_LOGIN_REFERENCE } from "../commons/Constants.js";
import * as Utils from "../commons/Utility.js";
import axios from "axios";
import cookie from "react-cookie";

@connect((state) => ({
  login: state.LoginReducer,
  user: state.UserReducer,
  projectReducer: state.ProjectReducer,
  invitationReducer: state.InvitationReducer,
}))
class LoginContainer extends Component {
  // static propTypes	=	{
  // 						login 			   : PropTypes.object.isRequired,
  // 						user 			   : PropTypes.object.isRequired,
  // 						projectReducer 	   : PropTypes.object.isRequired,
  // 						invitationReducer  : PropTypes.object.isRequired
  // 					}
  constructor() {
    super();

    this.doLogin = this.doLogin.bind(this);
    this.doGoogleLogin = this.doGoogleLogin.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
    this.isValidCredential = this.isValidCredential.bind(this);
    this.state = {
      emailId: "",
      isLoggingIn: false,
      isGoogleLoggingIn: false,
      isInvitationLogin: false,
      error: {},
      isAuthenticated: false,
    };
  }

  componentWillMount() {
    if (isAuthenticated()) {
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "Loading",
          showFullScreenLoader: true,
        })
      );
      this.setState({ isAuthenticated: true });
      let planId = this.props.location.query.planId;
      let userId = this.props.location.query.userId;
      if(planId && userId)
        localStorage.setItem(UPGRADE_PLAN, planId);
      this.redirectToDefaultPage(this.props);
    } else if (this.props.location.query.clientToken) {
      //for google login
      this.props.dispatch(
        VoiceboxActions.showVoicebox({
          message: "Loading",
          showFullScreenLoader: true,
        })
      );
      this.setState({ isAuthenticated: true });

      let clientToken = this.props.location.query.clientToken;

      cookie.save(CLIENT_LOGIN_REFERENCE, "true", { path: "/" });

      this.props.dispatch(LoginActions.loginResponse());

      if (this.props.location.query.ssoToken) {
        //Make person login to all the apps if user accidently click google signup.
        let ssoUrl =
          Utils.getAppMode() == "live" ? LIVE_SSO_URL : STAGING_SSO_URL;
        window.location.href =
          ssoUrl +
          this.props.location.query.ssoToken +
          "?continue=" +
          Utils.getOrigin() +
          "/login";
      } else {
        let tokenRemovedQuery = Object.assign({}, this.props.location.query);
        delete tokenRemovedQuery.clientToken;

        browserHistory.push({ query: tokenRemovedQuery });

        this.redirectToDefaultPage(this.props);
      }
    } else if (this.props.location.query.error) {
      //when google login fails.
      let errorRemovedQuery = Object.assign({}, this.props.location.query);
      delete errorRemovedQuery.error;

      let error = {
        email: "",
        password: "",
        general: "Gmail Authentication Failed!",
      };
      this.setState({ error });

      browserHistory.push({ pathname: "/login", query: errorRemovedQuery });
    } else if (this.props.location.query.code) {
      let code = this.props.location.query.code;
      let state = this.props.location.query.state
        ? this.props.location.query.state
        : "";
      console.log("The location is ", this.props.location);
      // let landingPath = SIGNUP_SUCCESS;
      let landingPath = this.props.location.query.landingPath;
      let action = (landingPath === `/${SIGNUP_SUCCESS}`) ? "signup" : "";
      console.log("The action for this access is inside code :" + action );
      state = state ? Utils.getDecrypted(state) : "";
      state = state ? JSON.parse(state) : "";
      let inviteeId = state.inviteeId ? state.inviteeId : "";
      let planId = localStorage.getItem(PLAN_ID);
      this.props.dispatch(
        LoginActions.requestSessionByCode({
          code,
          inviteeId,
          state,
          planId,
          redirectUri: this.getRedirectUrl(action),
        })
      );
    } else {
      let stateObj = this.getStateObj();
      let planId = Utils.getPlanIdFromStateObj(stateObj);
      let action = (this.props.location.query.action) ? this.props.location.query.action : stateObj.action;
      console.log("The action for this access is :" + action);
      console.log('House of brands domain : ' , Utils.getDomainBasedOnBrand());
      let authUrl = Utils.getDomainBasedOnBrand() + '/o/oauth2/auth?';
      let authParams = Utils.getAppMode() === "live" ? LIVE_AUTH_PARAMS : STAGING_AUTH_PARAMS;
      let redirectUri = "&redirect_uri=" + this.getRedirectUrl(action, planId);
      authUrl += authParams + redirectUri;
      let state = this.getAuthStateFromProps(this.props);
      if (
        this.props.location.query.inviteeId &&
        !this.props.invitationReducer.isFetched
      ) {
        this.props.dispatch(
          InvitationActions.getSignupDetailsRequest(
            this.props.location.query.inviteeId
          )
        );
      } else if (
        this.props.location.query.inviteeId &&
        this.props.invitationReducer.isFetched &&
        this.props.invitationReducer.invitation.invitationStatus == "PENDING"
      ) {
        this.setState({
          emailId: this.props.invitationReducer.invitation.emailId,
          isInvitationLogin: true,
        });
      }
      if (state) {
        authUrl = authUrl + "&state=" + state;
      }
      window.location.href = authUrl;
    }
  }

  getStateObj() {
    try {
      let encodedData = this.props.location.query.state;
      let decodedData = window.atob(encodedData);
      return JSON.parse(decodedData);
    } catch (error) {
      return {
        planId: "",
        action: "",
        success: false,
        userId: "",
        source: ""
      };
    }
  }

  getRedirectUrl(action, planId) {
    console.log("The values : ", action);
     if(action === 'signup'){
       if(Utils.getQueryParam("state"))
       {
         let state = this.getStateObj();
         if(state.source=="wordpress" || state.source=="joomla" || state.source=="shopify")
          return Utils.getOrigin() + "/login"
       }
       if (planId)
         localStorage.setItem(PLAN_ID, planId);
       return Utils.getOrigin() + `/login?landingPath=/${SIGNUP_SUCCESS}`;
     } else {
      return Utils.getOrigin() + "/login";
     }
  }

  getAuthStateFromProps(props) {
    let loginRedirectURI =
      props.location &&
      props.location.state &&
      props.location.state.nextPathname
        ? props.location.state.nextPathname
        : "";
    loginRedirectURI =
      loginRedirectURI +
      (props.location && props.location.state && props.location.state.search
        ? props.location.state.search
        : "");
    let state = {};
    if(props.location.query.state)
    {
      state = this.getStateObj();
    }
    if (loginRedirectURI) state.loginRedirectURI = loginRedirectURI;
    if (props.location.query.inviteeId)
      state.inviteeId = props.location.query.inviteeId;
    if(props.location.query.source == "wordpress" || props.location.query.source == "joomla" || props.location.query.source == "shopify")
    {
      state.siteUrl = props.location.query.siteUrl;
      state.source = props.location.query.source;
    }
    if (Object.keys(state).length === 0) return undefined;
    else return Utils.getEncrypted(JSON.stringify(state));
  }

  componentDidMount() {
    if (!this.props.location.query.inviteeId)
      this.child.refs.login_email.focus();
    else this.child.refs.login_password.focus();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.invitationReducer.isFetching &&
      nextProps.invitationReducer.isFetched
    ) {
      if (
        nextProps.invitationReducer.invitation.invitationStatus == "PENDING"
      ) {
        this.setState({
          emailId: nextProps.invitationReducer.invitation.emailId,
          isInvitationLogin: true,
        });
      } else {
        browserHistory.push({
          pathname: this.props.location.pathname,
          search: "",
        });
      }
    }
  }

  componentWillUpdate(nextProps) {
    if (
      (!this.props.login.isLoggedIn && nextProps.login.isLoggedIn) ||
      Utils.comparePrevious(this.props.user.isFetching,nextProps.user.isFetched) ||
      Utils.comparePrevious(this.props.projectReducer.isFetching,nextProps.projectReducer.isFetched)
    ) {
      if (nextProps.projectReducer.projectList[0] && nextProps.user.isFetched) {
        let defaultProjKey = "";

        if (
          nextProps.user.data.defaultProject &&
          nextProps.user.data.defaultProject != "null"
        )
          defaultProjKey = nextProps.user.data.defaultProject.replace(
            "LS-",
            ""
          );
        else
          defaultProjKey = nextProps.projectReducer.projectList[0].key.replace(
            "LS-",
            ""
          );
        this.redirectToDefaultPage(nextProps, defaultProjKey);
      }
    } else if (
      nextProps.login.isAuthencationFailed &&
      this.props.login.isLoggingIn
    ) {
      this.setState({
        isLoggingIn: false,
        error: { general: "Invalid login credentials" },
      });
    }
  }

  getInviteeIdFromState() {
    let state = this.props.location.query.state
      ? this.props.location.query.state
      : "";
    state = state ? Utils.getDecrypted(state) : "";
    state = state ? JSON.parse(state) : "";
    return state.inviteeId ? state.inviteeId : "";
  }

  redirectToDefaultPage(props, defaultProjKey) {
    if (!this.props.user.isFetched && !this.props.user.isFetching) {
      this.props.dispatch(UserActions.getUser());
    }
    let state = {};
    if(this.props.location.query.state && Utils.getDecrypted(this.props.location.query.state))
      state = JSON.parse(Utils.getDecrypted(this.props.location.query.state));
    if (
      !this.props.projectReducer.isFetched &&
      !this.props.projectReducer.isFetching
    ) {
      this.props.dispatch(ProjectActions.requestAvailableProject());
      console.info("I am the three");
    } else {
      const location = this.props.location;
      let inviteeId = this.getInviteeIdFromState()
        ? this.getInviteeIdFromState()
        : this.props.location.query.inviteeId;
      let projectId = defaultProjKey
        ? defaultProjKey
        : this.props.projectReducer.projectList[0].key.replace("LS-", "");

      if (
        this.props.location.query.inviteeId &&
        !this.props.location.query.loginRedirectURI &&
        defaultProjKey
      )
        browserHistory.push({
          pathname: "/app/" + projectId + DEFAULT_PAGE_URL,
          search: "?inviteeId=" + this.props.location.query.inviteeId,
        });
      else if (
        this.props.location.query.inviteeId &&
        this.props.location.query.loginRedirectURI
      )
        browserHistory.push({
          pathname: this.props.location.query.loginRedirectURI,
          search: "?inviteeId=" + this.props.location.query.inviteeId,
        });
      else if (this.props.location.query.loginRedirectURI)
        browserHistory.push(this.props.location.query.loginRedirectURI);
      else if (location.state && location.state.nextPathname)
        browserHistory.push(location.state.nextPathname);
      else if (inviteeId && this.getNumberOfProjects() > 1)
        browserHistory.push({
          pathname: "choose/profile",
          search: "?inviteeId=" + inviteeId,
        });
      else if (inviteeId && this.getNumberOfProjects() === 1 && projectId)
        browserHistory.push({
          pathname: "/app/" + projectId + DEFAULT_PAGE_URL,
          search: "?inviteeId=" + inviteeId,
        });
      else if(this.props.location.query.source == "wordpress" 
            || this.props.location.query.source == "joomla" ||  this.props.location.query.source === "shopify" || state.source == "joomla" || state.source == "wordpress" || state.source === 'shopify')
      {
        let siteUrl = this.props.location.query.siteUrl ? this.props.location.query.siteUrl : state.siteUrl;
        if(props.user.data && props.user.data.accountID)
        {
          this.props.dispatch(UserActions.acknowledgePlanUpgrade());
          document.location.href = siteUrl + "?success=true&accountID="+props.user.data.accountID+"&email="+props.user.data.login
          //browserHistory.push({pathname: siteUrl + "?success=true&accountID="+props.user.data.accountID+"&email="+props.user.data.login})
          // if(window.opener)
          //   window.opener.postMessage(JSON.stringify({success : true , accoutnId : props.user.data.accountID ,email : props.user.data.login}), "*");
        }
      }
      else
        browserHistory.push({
          pathname: "choose/profile",
          search: location.query.landingPath
            ? `?landingPath=/${SIGNUP_SUCCESS}`
            : "",
        });
    }

    this.props.dispatch(VoiceboxActions.hideVoicebox());
  }

  updateEmail(event) {
    this.setState({ emailId: event.target.value });
  }

  keyUpHandler(event) {
    if (event.key == "Enter") this.doLogin();

    if (this.state.error.email || this.state.error.password) {
      if (document.activeElement == this.child.refs.login_email) {
        if (Utils.isValidEmail(this.child.refs.login_email.value.trim()))
          this.setState({ error: { ...this.state.error, email: "" } });
      } else if (document.activeElement == this.child.refs.login_password) {
        if (this.child.refs.login_password.value.trim().length > 0)
          this.setState({ error: { ...this.state.error, password: "" } });
      }
    }
  }

  doLogin() {
    let loginId = this.child.refs.login_email.value.trim();
    let password = this.child.refs.login_password.value.trim();

    if (!this.isValidCredential(loginId, password)) return;

    const loginInfo = { loginId, password };

    if (this.props.location.query.inviteeId)
      loginInfo["inviteeId"] = this.props.location.query.inviteeId;

    this.setState({ isLoggingIn: true });

    this.props.dispatch(LoginActions.loginRequest(loginInfo));
  }

  isValidCredential(email, password) {
    let isEmailEmpty = email.length == 0;
    let isPasswordEmpty = password.length == 0;
    let isValidEmail = Utils.isValidEmail(email);

    if (!isEmailEmpty && !isPasswordEmpty && isValidEmail) {
      this.setState({ error: {} });
      return true;
    }

    let error = { general: "", email: "", password: "" };

    this.child.refs.login_email.focus();

    if (isEmailEmpty && isPasswordEmpty)
      error = {
        ...error,
        general: "Oops, email address and password can't be empty",
      };
    else if (!isValidEmail && isPasswordEmpty)
      error = {
        ...error,
        general: "Oops, email address and password doesn't look right",
      };
    else if (isEmailEmpty) error = { ...error, email: "Email can't be empty" };
    else if (!isValidEmail)
      error = { ...error, email: "Oops, email doesn't look right" };
    else if (isPasswordEmpty) {
      error = { ...error, password: "Password can't be empty" };
      this.child.refs.login_password.focus();
    }

    this.setState({ error });

    return false;
  }

  doGoogleLogin() {
    this.setState({ isGoogleLoggingIn: true, error: {} });

    let googleSignInUrl =
      axios.defaults.baseURL + "/user/signinWithGoogleOauth";

    if (window.location.search)
      googleSignInUrl = googleSignInUrl + window.location.search;

    const location = this.props.location;

    if (location.state && location.state.nextPathname) {
      if (window.location.search)
        googleSignInUrl =
          googleSignInUrl +
          "&loginRedirectURI=" +
          encodeURI(location.state.nextPathname);
      else
        googleSignInUrl =
          googleSignInUrl +
          "?loginRedirectURI=" +
          encodeURI(location.state.nextPathname);
    }

    console.info("googleSignInUrl =>", googleSignInUrl);
    window.location = googleSignInUrl;
  }

  getNumberOfProjects = () => {
    if (this.props.projectReducer.isFetched) {
      return this.props.projectReducer.projectList.length;
    }
  };

  render() {
    return (
      <Login
        ref={(component) => (this.child = component)}
        localState={this.state}
        handlers={{
          onChange: this.updateEmail,
          onKeyUp: this.keyUpHandler,
          onLogin: this.doLogin,
          onGoogleLogin: this.doGoogleLogin,
        }}
        location={this.props.location}
      />
    );
  }
}

export default LoginContainer;
