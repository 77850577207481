import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  messageResponse,
  onMessageListFailed,
  onOldConversationResponse
} from "../actions/MessageAction";
import {
  receiveAvailableContacts,
  receiveUpdateContact,
  updateContactFailed,
} from "../actions/ContactInfoAction";
import { CHAT_STATUS, MAIN_TAB_APP } from "../commons/Constants";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import {
  getQueryParam,
  isValidResponse,
  getIsAWRecentComponent
} from "../commons/Utility";

export function* sendHttpMessage(request) {
  const url = "/rtm/sendMessage";
  console.log("Inside sendHttpMessage Saga");
  try {
    let response = yield call(Ajax.postUnauthenticated, url, request.message);
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}

export function* getMoreMessages(request) {
  console.log("request-->>", JSON.stringify(request));

  let url = "";
  try {
    let response;
    if (request.message.isFromOverflow) {
      url =
        "/message/overflow/agent/get?connectionId=" +
        getQueryParam("connection_id");
      response = yield call(
        Ajax.callWithRetry,
        isValidResponse,
        Ajax.postUnauthenticated,
        url,
        request.message.queryParam
      );
    } else if (request.message.isAwWindow) {
      url = "/message/overflow/agent/get";
      response = yield call(
        Ajax.callWithRetry,
        isValidResponse,
        Ajax.postUnauthenticated,
        url,
        request.message.queryParam
      );
    } else if (request.message.queryParam.conversationId !== MAIN_TAB_APP) {
      url = "/message/agent/get";
      response = yield call(
        Ajax.callWithRetry,
        isValidResponse,
        Ajax.post,
        url,
        request.message.queryParam);
    }

    let messageData = response ? response.data : "";
    let payload = request.message;
    if(request.message.queryParam.conversationId !== MAIN_TAB_APP) {
      yield put(messageResponse(messageData, payload.queryParam.conversationId));
      if (getIsAWRecentComponent())
        yield put(onOldConversationResponse());
    }
  } catch (e) {
    console.log("error occurred while fetching message -->", JSON.stringify(e));
    yield put(onMessageListFailed());
    yield put(onOldConversationResponse());
    yield put(handleGlobalError(e));
  }
}

export function* sendEmailMessages(request) {
  console.log("sending this data-->>", request.message);
  try {
    let response;
    request.message.key = request.message.messageId;
    let url = "/message/emailReply/" + request.message.projectId;
    response = yield call(Ajax.postUnauthenticated, url, request.message);
    console.log("response-->>", response);
  } catch (e) {
    yield put(handleGlobalError(e));
  }
}