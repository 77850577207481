import React, { Component } from "react";
import ReactDOM from "react-dom";

class Tags extends Component {
  render() {
    return (
      <ul className="memberEmailList">
        {this.props.tags.map((tag) => (
          <li key={tag} id={tag}>
            {tag}
            <code
              onClick={this.props.onClose}
              style={{ display: "block", marginTop: 0, color: "#2A333D" }}
            >
              ×
            </code>
          </li>
        ))}
      </ul>
    );
  }
}

export default Tags;
