import { useDispatch } from "react-redux";
import * as ChatConfigurationAction from "../actions/ChatConfigurationAction";

export const useUpdateChatConfigurationEntity = (chatConfiguration) => {
  const dispatch = useDispatch();

  return (chatConfiguration) => {
    if (chatConfiguration) {
      dispatch(
        ChatConfigurationAction.updateChatConfigRequest(chatConfiguration)
      );
      return true;
    } else {
      return false;
    }
  };
};
