import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  AddFieldIcon,
  EmailIcon,
  PhoneIcon,
} from "../../commons/Icons.js";
import ProfileTextArea from "./ProfileTextArea.jsx";
import {designationErrorHandler, userNameErrorHandler} from "./ErrorHandlers.js";

const EditProfileComponent = (props) => {
  const [extraFields, setExtraFields] = useState([]);
  const [showAddField, setShowAddField] = useState(false);
  const popupRef = useRef(null);

  const { addNewField, handleChange } = props;

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [showAddField]);

  const handleClick = (e) => {
    if (
      !(popupRef && popupRef.current.contains(event.target)) &&
      showAddField
    ) {
      togglePopup(e);
    }
  };

  const togglePopup = (e) => {
    setShowAddField(!showAddField);
  };

  const getEmailField = () => {
    let newField = addNewField.addEmailField();
    setExtraFields([...extraFields, newField]);
  };

  const getPhoneField = () => {
    let newField = addNewField.addPhoneField();
    setExtraFields([...extraFields, newField]);
  };

  const canShowSaveButton = () => {
    return props?.hasStateChanged || props?.imageChange
  };

  return (
    <section class="edit-profile">
      <h3>Edit Profile</h3>
      <p>
        Update your profile information, contact details and profile picture
      </p>
      <ProfileTextArea
        fieldName={"Full Name"}
        value={props.userName}
        handleChange={handleChange.handleNameChange}
        errorHandler={userNameErrorHandler}
      />
      <ProfileTextArea
        fieldName={"Designation"}
        value={props.title}
        handleChange={handleChange.handleRoleChange}
        errorHandler={designationErrorHandler}
      />
      {props.getExtraField()}
      {extraFields}
      <div className={"input-form-button bottom-space "}>
        <a
          href="Javascript:void(0)"
          className={showAddField ? "open" : ""}
          onClick={togglePopup}
          ref={popupRef}
        >
          <AddFieldIcon />
          Add field
          <div>
            <ul>
              <li onClick={getEmailField}>
                <EmailIcon />
                Email
              </li>
              <li onClick={getPhoneField}>
                <PhoneIcon />
                Phone
              </li>
            </ul>
          </div>
        </a>
        <button class="cs-btn cs-decline-btn" onClick={props.resetState}>
          Cancel
        </button>
        <button
          className={
            canShowSaveButton() && !props.detailsSubmitted
              ? "cs-btn cs-primary-btn "
              : "cs-btn cs-disabled-btn "
          }
          disabled={
            !canShowSaveButton() && !props.detailsSubmitted ? true : false
          }
          onClick={() => props.submitDetails()}
        >
          Save
        </button>
      </div>
    </section>
  );
};

EditProfileComponent.propTypes = {
  hasStateChanged: PropTypes.bool,
  imageChange: PropTypes.bool,
  userName: PropTypes.string,
  title: PropTypes.string,
  getExtraField: PropTypes.func,
  handleChange: PropTypes.shape({
    handleNameChange: PropTypes.func,
    handleRoleChange: PropTypes.func,
  })
}
export default EditProfileComponent;
