import React, { useEffect } from "react";
import { getAppMode, getIsAWRecentComponent } from "../../commons/Utility";


import { useSelector } from "react-redux";
import {CHATSUPPORT_BRAND_ID} from "../../commons/Constants";

const BillingComponent = (props) => {
    const { userDetails } = useSelector((state) => ({
      userDetails: state.UserReducer,
    }));
    useEffect(() => {
      showBillingSection();
    }, [userDetails.isFetched]);

    const getBillingObject = (accountPin, brandId, appMode, role, login) => {
      let billingObj = {
        accountPin: accountPin,
        brandId: brandId,
        elementId: "cs-billing",
        environment: appMode,
        role: role,
        login: login,
        accountType: "",
        seatCount: "",
        callback: (object) => {
          console.log("rendered component", object);
        },
      };
      return billingObj;
    };
    
    const showBillingSection = async () => {
      const appMode = getAppMode() == "live" ? "production" : "development";
      const user =
        userDetails.data && userDetails.data.hasOwnProperty("contact")
          ? userDetails.data.contact
          : {};
      const login = user.hasOwnProperty("login") ? user.login : "";
      const brandId = user.hasOwnProperty("brandID") ? user.brandID : "";
      const accountPin =
        userDetails.data &&
        userDetails.data.hasOwnProperty("accountID") &&
        userDetails.data.accountID
          ? userDetails.data.accountID
          : "";
      const role = "user";

      let billingObj = getBillingObject(
        accountPin,
        brandId,
        appMode,
        role,
        login
      );
      if (CHATSUPPORT_BRAND_ID == brandId && accountPin !== '' && !getIsAWRecentComponent()) { 
        const billingComponent = await import("@adaptavant/billing-component/dist/billing");
        billingComponent.default.init(billingObj);
      }
    };

    return (
      <div className="cs-settings-wrp" id="cs-billing">
      </div>
    );
};
export default BillingComponent;
