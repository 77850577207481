export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const ADD_DESKTOP_NOTIFICATION = "ADD_DESKTOP_NOTIFICATION";
export const REMOVE_DESKTOP_NOTIFICATION = "REMOVE_DESKTOP_NOTIFICATION";
export const LAST_NOTIFY_MESSAGE_ID = "LAST_NOTIFY_MESSAGE_ID";

export const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  notification: {
    ...notification,
    notificationId: new Date().getTime().toString(),
  },
});

export const addDesktopNotification = (notification) => ({
  type: ADD_DESKTOP_NOTIFICATION,
  notification: notification,
});

export const removeDesktopNotification = () => ({
  type: REMOVE_DESKTOP_NOTIFICATION,
});

export const removeNotification = (notificationId) => ({
  type: REMOVE_NOTIFICATION,
  notificationId,
});

export const showNotification = () => ({
  type: SHOW_NOTIFICATION,
});

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});

export const updateLastNotifyMessageId = (messageID) => ({
  type: LAST_NOTIFY_MESSAGE_ID,
  messageID 
})
