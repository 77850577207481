import React, { FC, useState } from 'react'
import { Margin } from 'styled-components-spacing'
import {
  ButtonLabel,
  Container,
  StyledButton,
  StyledCode,
} from 'src/features/ui/components/Code/styled'
import { IProps } from 'src/features/ui/components/Code/types'
import { Icon } from 'src/features/ui/components/Icon'

export const Code: FC<IProps> = ({ code }) => {
  const initialLabel = 'Copy to clipboard'
  const [label, setLabel] = useState(initialLabel)

  const handleCopyToClipboard = () => {
    const $temporaryElement = document.createElement('textarea')
    $temporaryElement.value = code
    document.body.appendChild($temporaryElement)
    $temporaryElement.select()

    document.execCommand('copy')
    document.body.removeChild($temporaryElement)

    setLabel('Copied')

    setTimeout(() => setLabel(initialLabel), 1000)
  }

  return (
    <Container>
      <StyledCode>{code}</StyledCode>
      <StyledButton
        onClick={handleCopyToClipboard}
        paddingSize="small"
        type="button"
        colorType="hollow"
        color="primary"
      >
        <Icon type="copy" fill="dodgerBlue" width={24} height={24} />
        <Margin left={8} />
        <ButtonLabel>{label}</ButtonLabel>
      </StyledButton>
    </Container>
  )
}
