import React, { Component } from "react";
import ReactDom from "react-dom";
import ProfileListComponent from "./ProfileListComponent.jsx";
import { getLogoUrl, sortListByProperty } from "../../commons/Utility";

class ChooseProfileComponent extends Component {
  getProjectList() {
    let toRenderList = [];
    if (this.props.projectList && this.props.projectList.length) {
      sortListByProperty(this.props.projectList, "name").map((project) => {
        toRenderList.push(
          <ProfileListComponent
            projectDetails={project}
            inviteeId={this.props.inviteeId}
            userDetails={this.props.userDetails}
          />
        );
      });
    }

    return toRenderList;
  }


  render() {
    return (
      <section className="select_project">
        <div class="header">
          <img
            src={getLogoUrl(document.location.origin)}
          />
        </div>
        <ul className="projects-list">{this.getProjectList()}</ul>
      </section>
    );
  }
}

export default ChooseProfileComponent;
