import React, { FC } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { List } from 'src/features/ui/components/List'
import { Text1 } from 'src/features/ui/components/Typography'
import { IProps } from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginGeneric/types'
import { TestConnection } from 'src/features/wizard/install/forms/TestConnection'
import { IFormValues } from 'src/features/wizard/install/forms/TestConnection/data'
import { requestTestConnection } from 'src/features/wizard/install/store/actions'
import {
  selectIsTestingConnection,
  selectTestingConnectionStatus,
} from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export const PluginGenericComponent: FC<IProps> = ({
  isTestingConnection,
  connectionStatus,
  testConnection,
  steps,
}) => {
  const handleSubmitForm = (values: IFormValues) => testConnection(values.url)

  return (
    <List
      items={[
        ...steps,
        <React.Fragment key={99}>
          <Text1 color="raven">
            Enter your website URL to test your widget has been successfully
            installed
          </Text1>
          <Margin top={24} />
          <TestConnection
            status={connectionStatus}
            handleSubmit={handleSubmitForm}
            isLoading={isTestingConnection}
          />
        </React.Fragment>,
      ]}
    />
  )
}

const mapStateToProps = (state: IAppState) => ({
  isTestingConnection: selectIsTestingConnection(state.install),
  connectionStatus: selectTestingConnectionStatus(state.install),
})

const mapDispatchToProps = {
  testConnection: requestTestConnection,
}

export const PluginGeneric = connect(
  mapStateToProps,
  mapDispatchToProps
)(PluginGenericComponent)
