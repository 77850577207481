import {
  COPY_PEOPLE_QUERY,
  UPDATE_BROADCAST_PEOPLE_QUERY,
  UPDATE_PEOPLE_COUNT,
  RESET_BROADCAST_PEOPLE_ES_QUERY,
  ADD_FILTER_REFERENCE_FOR_BROADCAST,
  REMOVE_FILTER_REFERENCE_FOR_BROADCAST,
  SET_FILTER_TYPE_FOR_BROADCAST,
  REMOVE_SELECTED_PEOPLE,
} from "../actions/BroadcastESQueryActions";

const PeopleESQueryReducer = (
  state = {
    query: {
      projectId: "",
      filter: {},
      not_filter: {},
      match: {},
      not_match: {},
      exists: { field: [] },
      not_exist: { field: [] },
      sort: { lastSeenTime: "desc" },
      range: [],
      size: 50,
      aggr: {},
      scroll: "6h",
    },
    scrollId: "",
    references: {},
    filterType: "all",
    selectedPeople: [],
    totalPeople: 0,
    fetchCount: false,
  },
  action
) => {
  switch (action.type) {
    case COPY_PEOPLE_QUERY: {
      return {
        ...state,
        query: action.query,
        reference: action.reference,
        references: action.references,
        scrollId: action.scrollId,
        filterType: action.filterType,
        totalPeople: action.totalPeople,
        selectedPeople: action.selectedPeople,
        fetchCount: false,
      };
    }
    case UPDATE_BROADCAST_PEOPLE_QUERY:
      return {
        ...state,
        query: action.query,
        references: action.references ? action.references : state.references,
        filterType: action.filterType ? action.filterType : state.filterType,
        fetchCount: true,
      };
    case UPDATE_PEOPLE_COUNT:
      return {
        ...state,
        totalPeople: action.peopleCount,
        fetchCount: true,
      };
    case RESET_BROADCAST_PEOPLE_ES_QUERY:
      return {
        ...state,
        query: {
          projectId: "",
          filter: {},
          not_filter: {},
          match: {},
          not_match: {},
          exists: { field: [] },
          not_exist: { field: [] },
          sort: { lastSeenTime: "desc" },
          range: [],
          size: 50,
          aggr: {},
          scroll: "6h",
        },
        scrollId: "",
        references: {},
        filterType: "all",
        selectedPeople: [],
        totalPeople: 0,
        fetchCount: false,
      };

    case ADD_FILTER_REFERENCE_FOR_BROADCAST:
      let referenceMap = Object.assign({}, state.references);
      referenceMap[action.referenceObj.key] = action.referenceObj;
      return {
        ...state,
        references: referenceMap,
        fetchCount: true,
      };

    case SET_FILTER_TYPE_FOR_BROADCAST:
      return {
        ...state,
        filterType: action.filterType,
        fetchCount: true,
      };

    case REMOVE_FILTER_REFERENCE_FOR_BROADCAST:
      let reference = Object.assign({}, state.references);
      delete reference[action.referenceObj.key];
      return {
        ...state,
        references: reference,
        fetchCount: true,
      };
    case REMOVE_SELECTED_PEOPLE:
      return {
        ...state,
        selectedPeople: [],
        fetchCount: true,
      };
  }
  return state;
};

export default PeopleESQueryReducer;
