import { Form } from 'formik'
import * as R from 'ramda'
import styled from 'styled-components'
import { Button } from 'src/features/form/components/Button'

export const StyledForm = styled(Form)`
  max-width: 40.8rem;
`

export const InputContainer = styled.div`
  position: relative;
  margin-top: 2.4rem;
`

export const SubmitButton = styled(Button)`
  position: absolute;
  top: 0.2rem;
  right: 0.3rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding: 0.2rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.6rem;
  background-color: ${R.path(['theme', 'color', 'white'])};
`
