import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import svg from 'src/assets/logo-mini.svg'

export const Logo = styled.div<{
  color: string
  width: string
  height: string
}>`
  background-color: ${R.prop('color')};
  padding: 0;
  width: ${R.prop('width')};
  height: ${R.prop('height')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${svg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 38% auto;
`

export const ImageButton = styled.div<{
  imageUrl: string
  width: string
  height: string
  isLoaded: boolean
  color: string
  isInFormElement?: boolean
}>`
  width: ${R.prop('width')};
  height: ${R.prop('height')};
  padding: 0;
  border-radius: 50%;
  background-image: url(${R.prop('imageUrl')});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 400ms linear;
  background-color: ${R.path(['theme', 'color', 'white'])};
  border: 0.2rem solid ${R.prop('color')};

  ${is('isLoaded')`
    opacity: 1;
  `}

  ${is('isInFormElement')`
    border: none;
  `}
`
