import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import useProperties from "../hooks/useProperties.js";
import { TickIconBlue, SearchIcon } from "../../commons/Icons.js";
import { DEFAULT_COLUMN_FIELDS } from "../commons/ContactConstants.js";
import { updateFieldSettingsRequest } from "../actions/ContactsAction.js";
import { searchKeys, insertCompanyName} from "../commons/ContactsUtility.js";
import { PRODUCTION_SUPPORT_PROJECTS } from "../../commons/Constants.js";
import { getProjectId } from "../../commons/Utility.js";

const ColumnsDropdown = (props) => {
  const [search, setSearch] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const {
    params: { projectkey },
  } = props;
  const {
    contacts: { visibleFields = [] },
    showColumns,
  } = props;
  const { columns = {}, customFields } = useProperties(projectkey);
  const names = Object.keys(columns);
  const keys = searchKeys(names, search, columns);
  const dispatch = useDispatch();
  const ref = useRef();
  const listingRef = useRef();

  useEffect(() => {
    setSelectedFields(visibleFields);
  }, [visibleFields]);

  useEffect(() => {
    if (showColumns && listingRef.current)
      listingRef.current.scrollIntoView({ block: "nearest" });
  }, [showColumns]);

  useEffect(() => {
    if (showColumns) ref.current.focus();
  }, [showColumns]);

  const onClick = (property) => {
    if (DEFAULT_COLUMN_FIELDS.includes(property)) return;
    let selection = false;
    let prevSelectedFields = new Set(selectedFields);
    if (prevSelectedFields.has(property)) prevSelectedFields.delete(property);
    else {
      prevSelectedFields.add(property);
      selection = true;
    }
    let updatedSelectedFields = Array.from(prevSelectedFields);
    //This if statement goes away when we migrate the companyname to default property for support projects
    if (
      PRODUCTION_SUPPORT_PROJECTS.includes(getProjectId(projectkey)) &&
      selection && 
      customFields[property] &&
      customFields[property].name == "companyName" 
    ) {
      updatedSelectedFields = insertCompanyName(updatedSelectedFields, property);
    }
    setSelectedFields(updatedSelectedFields);
    dispatch(
      updateFieldSettingsRequest({
        projectId: `LS-${projectkey}`,
        visibleFields: updatedSelectedFields,
      })
    );
  };

  const onEdit = ({ target }) => {
    let value = target.value;
    setSearch(value);
  };

  const getEntries = () => {
    let entries = [];
    keys.forEach((name, index) => {
      if (index == 0)
        entries.push(
          <li ref={listingRef} key={name}>
            <a
              href="javascript:void(0)"
              onClick={() => onClick(name)}
              className={
                selectedFields.includes(name) ||
                DEFAULT_COLUMN_FIELDS.includes(name)
                  ? "active"
                  : ""
              }
            >
              <cite>
                <TickIconBlue />
              </cite>
              {columns[name]}
            </a>
          </li>
        );
      else
        entries.push(
          <li key={name}>
            <a
              href="javascript:void(0)"
              onClick={() => onClick(name)}
              className={
                selectedFields.includes(name) ||
                DEFAULT_COLUMN_FIELDS.includes(name)
                  ? "active"
                  : ""
              }
            >
              <cite>
                <TickIconBlue />
              </cite>
              {columns[name]}
            </a>
          </li>
        );
    });
    return entries;
  };

  return (
    <div className="cs-dropdwn-checkbox-sub">
      <div>
        <input
          ref={ref}
          type="text"
          onChange={onEdit}
          value={search}
          placeholder="Search columns"
        />
        <SearchIcon />
      </div>
      <p
        style={{
          "text-align": keys.length == 0 ? "center" : "left",
          color: keys.length == 0 ? "grey" : "black",
        }}
      >
        {keys.length == 0 ? "No results found" : "Available columns"}
      </p>
      <ul>{getEntries()}</ul>
    </div>
  );
};

export default ColumnsDropdown;
