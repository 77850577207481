import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Text1 } from 'src/features/ui/components/Typography'

export const Container = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.9rem;
  border: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  background-color: ${R.path(['theme', 'color', 'white'])};
  box-sizing: border-box;
  box-shadow: 0 0.7rem 0.8rem rgba(22, 45, 61, 0.08);
  padding: 2.3rem;

  ${is('isActive')`
    border-color: ${R.path(['theme', 'color', 'dodgerBlue'])};
  `}
`

export const Label = styled(Text1)`
  font-weight: 500;
  color: ${R.path(['theme', 'color', 'black'])};
  margin-left: 1.6rem;
`

export const StyledImage = styled.img<{ $width: number; $height: number }>`
  width: ${({ $width }) => $width / 10}rem;
  height: ${({ $height }) => $height / 10}rem;
`
