import * as R from 'ramda'
import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ReturningFlow } from 'src/features/returningFlow/components/ReturningFlow'
import { GlobalStyles } from 'src/features/ui/styles/global'
import { theme } from 'src/features/ui/styles/theme'
import { IWidgetConfigData } from 'src/features/wizard/setup/api/types'
import { rootSagas } from 'src/utils/state/sagas'
import { storeFactory } from 'src/utils/state/store'

const { store, sagaMiddleware } = storeFactory()

sagaMiddleware.run(rootSagas)

export interface IProps {
  // This makes it easier for the website to use the component
  widgetData?: any
  userId?: string
  isLoadingAfterSignup: string
}

export const ReturningFlowApp: FC<IProps> = ({
  widgetData,
  userId,
  isLoadingAfterSignup,
}) => {
  const getWidgetData = () => widgetData as IWidgetConfigData

  // On the first render we may expect an empty object
  if (R.isEmpty(getWidgetData()) || !userId) {
    return null
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ReturningFlow
          widgetData={getWidgetData()}
          userId={userId}
          isLoadingAfterSignup={isLoadingAfterSignup}
        />
      </ThemeProvider>
    </Provider>
  )
}
