import React, { FC } from 'react'

export const Plugin: FC<any> = ({ fill, ...props }) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5711 10.4675L3.46749 20.5711C1.51084 22.5277 1.51084 25.7001 3.46749 27.6567L13.5711 37.7604C15.5277 39.717 18.7001 39.717 20.6567 37.7604L23.9417 34.4754L27.2266 37.7604C29.1833 39.717 32.3556 39.717 34.3123 37.7604L44.4159 27.6567C46.3725 25.7001 46.3725 22.5277 44.4159 20.5711L34.3123 10.4675C32.3556 8.51084 29.1833 8.51084 27.2266 10.4675L23.9417 13.7524L20.6567 10.4675C18.7001 8.51084 15.5277 8.51084 13.5711 10.4675ZM22.1046 15.5896L18.8195 12.3045C17.8774 11.3624 16.35 11.3624 15.4079 12.3045L5.30431 22.4081C4.36222 23.3502 4.36222 24.8776 5.30431 25.8197L15.4079 35.9233C16.35 36.8654 17.8774 36.8654 18.8195 35.9233L22.1046 32.6383L17.123 27.6567C15.1664 25.7001 15.1664 22.5277 17.123 20.5711L22.1046 15.5896ZM23.9415 30.8014L18.9598 25.8197C18.0178 24.8776 18.0178 23.3502 18.9598 22.4081L23.9415 17.4265L28.9231 22.4081C29.8652 23.3502 29.8652 24.8776 28.9231 25.8197L23.9415 30.8014ZM25.7786 32.6385L30.7604 27.6567C32.717 25.7001 32.717 22.5277 30.7604 20.5711L25.7786 15.5893L29.0634 12.3045C30.0055 11.3624 31.533 11.3624 32.4751 12.3045L42.5787 22.4081C43.5208 23.3502 43.5208 24.8776 42.5787 25.8197L32.4751 35.9233C31.533 36.8654 30.0055 36.8654 29.0635 35.9233L25.7786 32.6385Z"
      fill={fill ?? '#4684FF'}
    />
  </svg>
)
