import {
  TOKENLIST_REQUESTED,
  TOKENLIST_RESPONSE,
  TOKENLIST_FAILED,
  CREATE_TOKEN_RESPONSE,
  UPDATE_TOKEN_RESPONSE,
  DELETE_TOKEN_RESPONSE,
} from "../actions/AuthTokenAction.js";

const AuthTokenReducer = (
  state = {
    authTokenList: [],
    isFetching: false,
    isFetched: false,
    showCreateTokenPopup: false,
  },
  action
) => {
  switch (action.type) {
    case TOKENLIST_REQUESTED:
      return {
        ...state,
        isFetching: true,
        authTokenList: [],
        isFetched: false,
      };
    case TOKENLIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        authTokenList: action.tokenList,
      };
    case TOKENLIST_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        authTokenList: [],
      };
    case CREATE_TOKEN_RESPONSE:
      return {
        ...state,
        authTokenList: state.authTokenList.concat([action.tokenMap]),
      };
    case UPDATE_TOKEN_RESPONSE:
      return {
        ...state,
        authTokenList: state.authTokenList
          .filter((token) => token.key != action.tokenMap.key)
          .concat([action.tokenMap]),
      };
    case DELETE_TOKEN_RESPONSE:
      return {
        ...state,
        authTokenList: state.authTokenList.filter(
          (token) => token.key != action.projectKey
        ),
      };
    default:
      return state;
  }
};
export default AuthTokenReducer;
