import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MapComponent from "../../components/chat/MapComponent.jsx";
import {
  TeamMoreIcon,
  TeamEditIcon,
  TeamDeleteIcon,
  DeletePopupIcon,
} from "../../commons/Icons";
import { Link, browserHistory } from "react-router";
import {
  getCurrentFormattedTime,
  isValidPhoneNumber,
  createUUId,
  isValidName,
  isValidString,
} from "../../commons/Utility";
import * as StaffContactHook from "./Hook/StaffContactHook";
import * as Utility from "../../commons/Utility.js";
import WorkingHoursContainer from "../../settings/myprofile/WorkingHoursContainer";
import * as AccountProjectHook from "./Hook/AccountProjectHook";
import * as VoiceboxActions from "../../actions/VoiceboxAction";

import { usePrevious } from "../../../app/settings/hooks/usePrevious";
import { deleteAccountStaffRequested } from "../../actions/StaffAction";
import {
  ProfileNameLoader,
  TeamMemberPermissionTabLoader,
  TeamMemberProfileTabLoader,
} from "../../settings/commons/loaders.js";
import {
  EMPTY_STRING,
  ACTIVE_EDIT_TAB,
  MAX_CONNECT_WIDGET_TO_SHOW,
  CONNECT_CHATS_NOT_ASSIGNED,
} from "../../commons/Constants.js";

const TeamProfileComponent = (props) => {
  const {staffContactReducer} = useSelector((state)=>({staffContactReducer: state.StaffContactReducer}));

  const activeStaffId = props.activeStaff ? props.activeStaff.key : "";

  const [showMore, setShowMore] = useState(false);
  const [showAllProject, setShowAllProject] = useState(false);
  const [showDeleteProfile, setShowDeleteProfile] = useState(false);

  const [showPermission, setShowPermission] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const prevStaffId = usePrevious(activeStaffId);
  const prevShowMore = usePrevious(showMore);
  const dispatch = useDispatch();

  useEffect(() => {
    if (prevStaffId != activeStaffId) setShowAllProject(false);
    document.addEventListener("mousedown", handleClickInComponent);
    return () => {
      document.removeEventListener("mousedown", handleClickInComponent);
    };
  });

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [showDeleteProfile]);

  const handleEscapeKey = (e) => {
    if (e.which === 27 || e.keyCode === 27) setShowDeleteProfile(false);
  };
  const setDefaultState = () => {};
  const handleClickInComponent = (e) => {
    if (showMore == true && e.target.dataset.moreelement != "true")
      setShowMore(false);
  };

  const showMoreOption = (e) => {
    if (showMore == false && e.target.dataset.moreelement == "true")
      setShowMore(true);
    else setShowMore(false);
  };

  const showProfileDeletePopup = (e) => {
    setShowDeleteProfile(!showDeleteProfile);
  };

  const showPermissionTab = (e) => {
    setShowPermission(true);
  };

  const showProfileTab = (e) => {
    setShowPermission(false);
  };

  const deleteStaffFromAccount = () => {
    let activeStaff = props.activeStaff;
    dispatch(
      VoiceboxActions.showVoicebox({
        message: "Deleting... " + activeStaff.firstName,
      })
    );
    dispatch(
      deleteAccountStaffRequested(
        activeStaff.key,
        props.teamProfileProps.params.projectkey,
        true
      )
    );
    setShowDeleteProfile(false);
  };

  const showEditProfilePopup = () => {
    browserHistory.push({
      pathname:
        "/app/" +
        props.teamProfileProps.params.projectkey +
        "/directory/myteam/profile/" +
        props.teamProfileProps.params.staffid +
        "/teamedit",
    });
  };

  const getUserName = () => {
    let activeStaff = props.activeStaff;
    if (activeStaff.lastName)
      return activeStaff.firstName + " " + activeStaff.lastName;
    else return activeStaff.firstName;
  };

  const getFlagIcon =(isLocationDetailsAvailable) => {
    let activeStaff = props.activeStaff;
    let countryCode = activeStaff.countryCode ? activeStaff.countryCode : "";
    if(countryCode && isLocationDetailsAvailable) {
        let countryCodeClass = countryCode.toLowerCase();
        return <i className={"flag-ele "+countryCodeClass}></i>;
    }
    return "";
  }

  const getMapComponent = () => {
    let activeStaff = props.activeStaff;
    let activeStaffContact = props.activeStaffContact;
    let latitude = activeStaff.lattitude ? activeStaff.lattitude : "";
    let longitude = activeStaff.longitude ? activeStaff.longitude : "";
    let contact = { latitude: latitude, longitude: longitude };
    let city = activeStaff.city ? activeStaff.city : "";
    city = city.length > 0 ? city.charAt(0).toUpperCase() + city.slice(1) : "";
    let countryCode = activeStaff.countryCode ? activeStaff.countryCode : "";
    let mapElement;
    let contentClass = "";
    let time = getCurrentFormattedTime();
    let isLocationDetailsAvailable = true;

    if (!!latitude && !!longitude) {
      city = city + ", ";
      mapElement = (
        <MapComponent
          contact={contact}
          isFromProfilePage={true}
          latitude={latitude}
          longitude={longitude}
        />
      );
    } else {
      // checking current user location can be removed later
      let locationDetails = StaffContactHook.getLocationIfLoggedInUser(
        activeStaff.key
      );

      if (locationDetails != null) {
        city = locationDetails.city;
        city = city + ", ";
        countryCode = locationDetails.countryCode;
        latitude = locationDetails.latitude;
        longitude = locationDetails.longitude;
        contact = { latitude: latitude, longitude: longitude };
        mapElement = (
          <MapComponent
            contact={contact}
            isFromProfilePage={true}
            latitude={latitude}
            longitude={longitude}
          />
        );
      } else isLocationDetailsAvailable = false;

      if (isLocationDetailsAvailable == false) {
        contentClass = "not-found";
        city = "";
        countryCode = "";
        time = "";
        mapElement = (
          <img src={"https://app.chatsupport.co/images/map-placeholder.png"} />
        );
      }
    }

    return (
      <div class="cs-profile-map fs-exclude">
        {mapElement}
        <span className={contentClass}>
          {city}
          {countryCode} {getFlagIcon(isLocationDetailsAvailable)}
          <br />
          <p>{time}</p>
        </span>
      </div>
    );
  };

  const getImageElement = () => {
    let activeStaff = props.activeStaff;
    let errorUrl =  "https://app.chatsupport.co/images/user-white-icon.svg";
    let staffName = getUserName();
    let firstLetter =
      staffName && staffName.length > 0 ? staffName.charAt(0) : "";
    firstLetter = firstLetter.toUpperCase();
    let avatarColor =
      activeStaff && activeStaff.avatarColor
        ? activeStaff.avatarColor
        : "#A79580";
    let imageElement;

    if (
      activeStaff.photoUrl &&
      activeStaff.photoUrl.indexOf("null") == -1 &&
      Utility.isValidURL(activeStaff.photoUrl)
    )
      imageElement = (
        <figure className="fs-exclude">
          <img src={activeStaff.photoUrl} onError={errorUrl} />{" "}
        </figure>
      );
    else
      imageElement = (
        <figure>
          {" "}
          <cite style={activeStaff ? { backgroundColor: avatarColor } : {}}>
            {firstLetter}
          </cite>
        </figure>
      );

    return imageElement;
  };
  const getProfileName = (name) => {
    if (name) {
      return <h2 className="cs-caps-txt fs-exclude">{name}</h2>;
    }
    return <ProfileNameLoader />;
  };
  const canShowDesignation = (designation) =>
    designation &&
    !staffContactReducer.isFetching &&
    Object.keys(Utility.getTeamMembersList()).length > 0;
  const getDesignation = (designation) => {
    const showDesignation = canShowDesignation(designation);
    designation = showDesignation ? designation : EMPTY_STRING;
    return <p className="cs-caps-txt fs-exclude">{designation}</p>;
  };
  const getStaffNameDetails = () => {
    let activeStaff = props.activeStaff;
    let activeStaffContact = props.activeStaffContact;

    let name = Utility.getFullName(activeStaff);
    let designation = isValidString(activeStaffContact.title)
      ? activeStaffContact.title
      : "";
    let isOnlyOwnerOfProject = activeStaffContact.isOnlyOwnerOfProject;
    let isSameUser =
      activeStaffContact.id == props.loggedInUserId ? true : false;

    let showDeleteOption = isOnlyOwnerOfProject || isSameUser ? false : true;
    let showEditOption = showPermission ? true : false;
    let showMoreBlock =
      showDeleteOption == false && showEditOption == false ? false : true;

    let showMoreClass = "my-team-more-btn " + (showMore ? "open" : "");

    return (
      <div class="cs-profile fs-exclude">
        {getImageElement()}
        {getProfileName(name)}
        {getDesignation(designation)}
        {Utility.getUserPermissionUtility("EditDeleteOption") &&
        showMoreBlock ? (
          <div class={showMoreClass}>
            {/* <TeamMoreIcon  showMoreOption={showMoreOption} moreelement="true"/> */}
            <a
              onClick={showMoreOption}
              onKeyDown={() => {}}
              data-moreelement="true"
            >
              {" "}
              <TeamMoreIcon />
            </a>
            <ul class="edit-delete-container">
              <li
                onClick={showEditProfilePopup}
                data-moreelement="true"
                style={{ display: showPermission == true ? "block" : "none" }}
              >
                <TeamEditIcon />
                Edit
              </li>
              <li
                className={"delete " + (showDeleteOption ? "" : "dn")}
                onClick={showProfileDeletePopup}
                data-moreelement="true"
                disabled={isOnlyOwnerOfProject}
              >
                <TeamDeleteIcon />
                Delete
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  const getStaffWorkingHours = () => {
    let userId =
      props.activeStaff && props.activeStaff.key ? props.activeStaff.key : "";
    let accountId =
      props.activeStaff && props.activeStaff.accountId
        ? props.activeStaff.accountId
        : "";
    return (
      <WorkingHoursContainer
        displayText="Working Hours"
        projectKey={props.teamProfileProps.params.projectKey}
        userId={userId}
        accountId={accountId}
        isFromTeamsPage={true}
      />
    );
  };
  const getProfileDetails = () => {
    if (
      getStaffEmailsToDisplay().length > 1 &&
      !staffContactReducer.isFetching &&
      Object.keys(Utility.getTeamMembersList()).length>0
    ) {
      return (
        <div>
          {getStaffEmailsToDisplay()}
          {getStaffPhoneNumToDisplay()}
          {getStaffWorkingHours()}
        </div>
      );
    }
    return <TeamMemberProfileTabLoader />;
  };
  const getStaffProfile = () => {
    let profileclass = showPermission == true ? "" : "active";

    return (
      <li class={profileclass}>
        <a onClick={showProfileTab} onKeyDown={ () => {} }>
          Profile
        </a>
        {getProfileDetails()}
      </li>
    );
  };

  const getUserProjectNameToDisplay = () => {
    let toRender;
    let projectElementList = [];

    let projectNameWidgetColorMap = AccountProjectHook.getProjectNameWidgetColorMap(
      props.activeUserProjects,
      props.projectEntitiesMap,
      props.chatConfigMap
    );
    let projectNameAry = Object.keys(projectNameWidgetColorMap);
    projectNameAry.sort();

    for (let i = 0; i < projectNameAry.length; i++) {
      if (i < 3 || showAllProject == true) {
        projectElementList.push(
          <div className="cs-porject-clip">
            <i
              style={{
                backgroundColor: projectNameWidgetColorMap[projectNameAry[i]],
              }}
            ></i>
            <cite
              style={{
                backgroundColor: projectNameWidgetColorMap[projectNameAry[i]],
              }}
            ></cite>
            <span className="fs-exclude">{projectNameAry[i]}</span>
          </div>
        );
      } else if (i >= 3) {
        projectElementList.push(
          <span
            className="cs-porject-more"
            onClick={() => {
              setShowAllProject(true);
            }}
          >
            {" "}
            {projectNameAry.length - 3} more
          </span>
        );
        break;
      }
    }

    toRender = <aside className="cs-project-wrp">{projectElementList}</aside>;

    return toRender;
  };

  const sortedConnectWidgetsToShow = (projectNameAry,projectElementList,projectNameWidgetColorMap) => {
    for (let i = 0; i < projectNameAry.length; i++) {
      if (i < MAX_CONNECT_WIDGET_TO_SHOW || showAllProject) {
        projectElementList.push(
          <div className="cs-porject-clip">
            <i
              style={{
                backgroundColor: projectNameWidgetColorMap[projectNameAry[i]],
              }}
            ></i>
            <cite
              style={{
                backgroundColor: projectNameWidgetColorMap[projectNameAry[i]],
              }}
            ></cite>
            <span className="fs-exclude">{projectNameAry[i]}</span>
          </div>
        );
      } else if (i >= MAX_CONNECT_WIDGET_TO_SHOW) {
        projectElementList.push(
          <span
            className="cs-porject-more"
            onClick={() => {
              setShowAllProject(true);
            }}
            onKeyDown={ () => {} }
          >
            {" "}
            {projectNameAry.length - 3} more
          </span>
        );
        break;
      }
    }
  }
  const getConnectUserProjectNameToDisplay = () => {
    let toRender;
    let projectElementList = [];
    let projectNameWidgetColorMap =
      AccountProjectHook.getProjectNameWidgetColorMap(
        props.activeUserProjects,
        props.projectEntitiesMap,
        props.chatConfigMap,
        ACTIVE_EDIT_TAB.CONNECT_CHATS
      );
    let projectNameAry = Object.keys(projectNameWidgetColorMap);
    projectNameAry.sort((a, b) => a.localeCompare(b));
    if (projectNameAry.length !== 0) {
      sortedConnectWidgetsToShow(projectNameAry,projectElementList,projectNameWidgetColorMap );
    } else {
      projectElementList.push(CONNECT_CHATS_NOT_ASSIGNED);
    }
    if (!projectElementList.includes(CONNECT_CHATS_NOT_ASSIGNED)) {
      toRender = <aside className="cs-project-wrp">{projectElementList}</aside>;
    } else {
      toRender = CONNECT_CHATS_NOT_ASSIGNED;
    }
    return toRender;
  };
  const getPermissionDetails = () => {
    let staffSkill = AccountProjectHook.getStaffSkill(props.activeUserProjects);
    let staffRole = AccountProjectHook.getStaffRole(props.activeUserProjects);
    if (
      staffRole &&
      !staffContactReducer.isFetching &&
      Object.keys(Utility.getTeamMembersList()).length>0
    )
      return (
        <div>
          <label>Role</label>
          <p>{staffRole}</p>
          <label>Skill</label>
          <p>{staffSkill}</p>
          <label>Widgets Assigned</label>
          {getUserProjectNameToDisplay()}
          {getConnectWidgetSection()}
        </div>
      );
    return <TeamMemberPermissionTabLoader />;
  };

  const getConnectWidgetSection = () => {
    if (getConnectUserProjectNameToDisplay() === CONNECT_CHATS_NOT_ASSIGNED) {
      return <span />
    }
    return (
      <span>
        <label>Connect</label>
        {getConnectUserProjectNameToDisplay()}
      </span>
    );
  };
  const getStaffPermission = () => {
    let permissionClass = showPermission == true ? "active" : "";
    return (
      <li className={permissionClass}>
        <a onClick={showPermissionTab} onKeyDown={() => {}}>
          Permissions
        </a>
        {getPermissionDetails()}
      </li>
    );
  };

  const getPopupBackground = () => {
    let displayStyle = "none";
    if (document.location.pathname.includes("teamedit") || showDeleteProfile)
      displayStyle = "block";
    return <div class="cs-popupbg" style={{ display: displayStyle }}></div>;
  };

  const getStaffEmailsToDisplay = () => {
    let emailToRender = [];
    let emailList = StaffContactHook.getEmailsFromStaffContact(
      props.activeStaffContact
    );
    emailToRender.push(<label>Email</label>);
    emailList.forEach(function (email, index) {
      emailToRender.push(<p className="fs-exclude">{email}</p>);
    });
    return emailToRender;
  };

  const getStaffPhoneNumToDisplay = () => {
    let phoneToRender = [];
    let defaultMobileNumber =
      props.activeStaff &&
      props.activeStaff.hasOwnProperty("displayMobileNumber")
        ? props.activeStaff["displayMobileNumber"]
        : "";
    let phoneList = StaffContactHook.getPhoneNumFromStaffContact(
      props.activeStaffContact,
      defaultMobileNumber
    );
    phoneList.forEach(function (phone, index) {
      if (phone.length > 9 && !!props.activeStaff.countryCode) {
        if (phoneToRender.length == 0) phoneToRender.push(<label>Phone</label>);

        let phoneNumber = Utility.getFormattedNumber(
          phone,
          props.activeStaff.countryCode
        );
        if (!!phoneNumber)
          phoneToRender.push(<p className="fs-exclude">{phoneNumber}</p>);
      }
    });
    return phoneToRender;
  };

  const getStaffProfileProjects = () => {
    let projectList = AccountProjectHook.getStaffProjects(
      props.activeUserProjects,
      props.accountProjects
    ).join(", ");
    let moreElement =
      projectList.length > 2 ? <span>{projectList.length - 2} more</span> : "";
    let projectsToRender = [];

    if (projectList.length > 0)
      projectsToRender.push(
        <span style="background-color: rgba(45, 204, 210, 0.1);">
          <cite style="color:#2dccd2;">.</cite>projectList[0]
        </span>
      );

    if (projectList.length > 1)
      projectsToRender.push(
        <span style="background-color: rgba(45, 204, 210, 0.1);">
          <cite style="color:#2dccd2;">.</cite>projectList[1]
        </span>
      );

    return (
      <aside class="cs-dropdwn-select">
        {projectsToRender}
        {moreElement}
      </aside>
    );
  };

  const getEventHandler = () => {
    let eventHandler = {};
    // eventHandler["switchActiveStaff"] = switchActiveStaff;
    return eventHandler;
  };

  setDefaultState();
  return (
    <div class="cs-settings-wrp">
      <section>
        {getMapComponent()}
        {getStaffNameDetails()}
        <ul class="tab-nav">
          {getStaffProfile()}
          {getStaffPermission()}
        </ul>
      </section>
      {getPopupBackground()}
      <div
        class="cs-popup-model popup-new cs-popup-delete"
        style={{ display: showDeleteProfile ? "block" : "none" }}
      >
        <header>
          <h2>
            <DeletePopupIcon />
            Delete member
          </h2>
          <cite
            onClick={() => {
              setShowDeleteProfile(false);
            }}
          >
            &times;
          </cite>
        </header>
        <section>
          <div class="cs-model-msg">
            Are you sure you would like to delete this member from your team?
          </div>
        </section>
        <footer>
          <a
            class="cs-btn cs-decline-btn"
            onClick={() => {
              setShowDeleteProfile(false);
            }}
            onKeyDown={() => {}}
          >
            Cancel
          </a>
          <a
            class="cs-btn cs-remove-btn"
            onClick={deleteStaffFromAccount}
            onKeyDown={() => {}}
          >
            Delete
          </a>
        </footer>
      </div>

      {props.teamProfileProps.children}
    </div>
  );
};
export default TeamProfileComponent;
