import React from "react";
import {
  appendScript,
  getProjectIdsFromList,
  getAppMode
} from "../../../commons/Utility";
import {
  UserNameWrapper,
  WrapperDiv,
  UserName,
  RecentMessage,
  UserPresence,
  UserAvatarFigure,
  UserAvatarImage,
  ImagePlaceHolder,
} from "./styled";
import {
  AW_CHATSUPPORT_ASSETS,
  displayVisitorType,
  MAIN_TAB_APP,
  VISITOR_INITIATED_CONVERSATION,
  MESSAGE_TYPE,
  AW_CHATSUPPORT_ASSETS_LIVE,
  CONNECT_USER_AVATAR_COLOR,
  LIVE
} from "../../../commons/Constants";
export const hideParenListElement = () => {
  let listElement = document.getElementById(MAIN_TAB_APP);
  if (listElement) {
    listElement.style.display = "none";
  }
}

export const getProjectIds = (projectReducer) => {
  let { projectList } = projectReducer;
  return getProjectIdsFromList(projectList) || [];
}

export const initRequiredCSSScript = () => {
  appendScript( getAppMode() == LIVE ? AW_CHATSUPPORT_ASSETS_LIVE  :  AW_CHATSUPPORT_ASSETS);
}

export const getConversationInfo = (itemId, conversationReducer) => {
  if (itemId !== MAIN_TAB_APP) {
    const { conversationObject } = conversationReducer;
    const conversationInfo = conversationObject && conversationObject.conversationMap && conversationObject.conversationMap[itemId];
    if (conversationInfo) {
      let { visitorId } = conversationInfo;
      const visitorInfo = conversationObject && conversationObject.contactsMap && conversationObject.contactsMap[visitorId] || {};
      return {
        conversationInfo,
        visitorInfo
      }
    }
  }
  return {
    conversationInfo: undefined,
    visitorInfo: undefined
  }
}

export const getVisitorName = (contact) => {
  let visitorName = contact && contact.firstName ? contact.firstName : "";
  visitorName += contact && contact.lastName ? ` ${contact.lastName}` : "";
  let visitorNameFromType =
    contact && contact.type
      ? displayVisitorType[contact.type]
      : displayVisitorType.VISITOR;
  return visitorName || visitorNameFromType;
};

export const getUserDetailsWithRecentMessage = (visitorInfo, conversationInfo, unreadCount) => {
  return (
    <UserNameWrapper>
      <WrapperDiv>
        <UserName unreadCount={unreadCount}>{getVisitorName(visitorInfo)}</UserName>
      </WrapperDiv>
      <WrapperDiv>
        <RecentMessage unreadCount={unreadCount}>{conversationInfo && conversationInfo.lastMessage ? conversationInfo.lastMessage : VISITOR_INITIATED_CONVERSATION}</RecentMessage>
      </WrapperDiv>
    </UserNameWrapper>
  )
}

const getPlaceholderBackgroundColor = (conversationId) => {
  let element = document.getElementById(conversationId);
  if(element){
    let isElementActive = element.classList.contains("active");
    return isElementActive ? CONNECT_USER_AVATAR_COLOR.ACTIVE : CONNECT_USER_AVATAR_COLOR.INACTIVE;
  } else {
    return CONNECT_USER_AVATAR_COLOR.INACTIVE;
  }
}

export const getVisitorImage = (visitorInfo, conversationId) => {
  if (visitorInfo.profileImage) {
    return (
      <UserAvatarFigure>
        <UserAvatarImage src={visitorInfo.profileImage} alt="Visitor Image"></UserAvatarImage>
        <UserPresence isOnline={visitorInfo.isOnline} />
      </UserAvatarFigure>
    )
  } else {
    let avatarColor = getPlaceholderBackgroundColor(conversationId);
    return (<ImagePlaceHolder avatarColor={avatarColor}>
      <UserPresence isOnline={visitorInfo.isOnline}></UserPresence>
    </ImagePlaceHolder>)
  }
}

const getConversationMessageMap = ({ messageMap }, itemId) => {
  return messageMap && messageMap[itemId]
    ? messageMap[itemId]
    : {};
}

export const getUnreadMessageCount = (messageReducer, itemId, conversationReducer) => {
  let conversationMessageMap = getConversationMessageMap(messageReducer, itemId);
  let unReadCount = 0;
  for (let key in conversationMessageMap) {
    let message = conversationMessageMap[key];
    if (message.type == MESSAGE_TYPE.chat || message.type == MESSAGE_TYPE.attachment)
      if (
        message.messageStatus != "CHAT_READ" &&
        message.messageFrom == "VISITOR"
      ) {
        unReadCount++;
      } else if (
        message.messageFrom == "VISITOR" &&
        message.messageStatus == "CHAT_READ"
      ){
        break;
      }
  }
  return unReadCount;
}

export const filterConnectEnabledProjects = (
  userProjectMapping,
  connectEnabledProjects,
  isConnectProjectsFetched
) => {
  console.log(
    "connectEnabledProjects: ",
    connectEnabledProjects,
    isConnectProjectsFetched
  );
  if (Object.keys(connectEnabledProjects).length > 0) {
    return connectEnabledProjects.map(
      (projects) => projects.projectKey
    );
  } else if (isConnectProjectsFetched) {
    return [];
  } else {
    let projectIds = userProjectMapping.filter((connectProjects) => {
      if (connectProjects.isConnectIntegrationEnabled) {
        return connectProjects.projectKey;
      }
    });
    projectIds = projectIds.map((projects) => projects.projectKey);
    return projectIds;
  }
};

export const isResubscribedFromConnect = (messageReducer) => {
  return messageReducer && messageReducer.resubscribeSyncFromConnect
    ? messageReducer.resubscribeSyncFromConnect
    : undefined;
};