import { ButtonHTMLAttributes } from 'react'

export enum ButtonColor {
  default = 'default',
  primary = 'primary',
}

export enum ButtonPaddingSize {
  default = 'default',
  small = 'small',
}

export enum ButtonColorType {
  full = 'full',
  hollow = 'hollow',
}

export interface IButtonProps {
  color?: keyof typeof ButtonColor
  colorType?: keyof typeof ButtonColorType
  hasFullWidth?: boolean
  hasFullWidthMobile?: boolean
  disabled?: boolean
  isLoading?: boolean
  paddingSize?: keyof typeof ButtonPaddingSize
  width?: number // TODO: refactor to enum 16.8rem 26.8rem keep only one prop
  maxWidth?: number // TODO: refactor to enum 16.8rem 26.8rem keep only one prop
}

export type IFullButtonProps = IButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>
