import styled from 'styled-components'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  width: 100%;

  & + & {
    margin-top: 2.8rem;

    ${mq.md} {
      margin-top: 3.6rem;
    }
  }
`
