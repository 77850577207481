import { call, put } from "redux-saga/effects";
import * as Ajax from "../../xhr/XhrRequestHandler";
import {
  CHAT_CONFIGURATION_FETCH_FAILED,
  receiveChatConfiguration,
  updateChatConfigResopnse,
  UPDATE_CHAT_CONFIG_FAILED,
} from "../actions/ChatConfigurationAction";
import * as NotificationActions from "../../actions/NotificationAction";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import { NOTIFICATION_LEVEL } from "../../commons/Constants.js";
import { handleGlobalError } from "../../actions/GlobalErrorAction";
import {
  getQueryParam,
  getProjectKey,
  showVoiceboxIfForbiddenRequest,
} from "../../commons/Utility";
import { updateWebsiteScreenshot } from "../actions/ProjectAction";

export function* getChatConfiguration(data) {
  console.log("The Data we Received is", data);
  let projectId = getProjectKey(data.projectKey);
  const url = "/chat/configuration/get/LS-" + projectId;

  try {
    let chatConfigList = {};
    const response = yield call(
      Ajax.getUnauthenticated,
      url +
        (data.isOverflowConversation
          ? "?connectionId=" + getQueryParam("connection_id")
          : "")
    );

    if (data.chatConfigList !== {}) chatConfigList = data.chatConfigList;

    if (response.response == true)
      chatConfigList[response.data.key] = response.data;

    yield put(receiveChatConfiguration(chatConfigList));
  } catch (e) {
    yield put({ type: CHAT_CONFIGURATION_FETCH_FAILED });
    yield put(handleGlobalError(e));
  }
}

export function* updateChatConfiguration(data) {
  const url = "/chat/configuration/update";
  console.log("The Data we put to update Chat is" + JSON.stringify(data));
  try {
    //yield put( VoiceboxActions.showVoicebox( { message : 'Updating' } ) );
    let chatConfig = yield call(Ajax.put, url, data.chatConfiguration);
    chatConfig = chatConfig.data;

    yield put(
      VoiceboxActions.showVoicebox({
        message: "Update Successful",
        dismissAfter: 2000,
      })
    );
    yield put(updateChatConfigResopnse(chatConfig));
  } catch (e) {
    showVoiceboxIfForbiddenRequest(e);
    yield put(
      NotificationActions.addNotification({
        message: "Chat Configuration Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put({ type: UPDATE_CHAT_CONFIG_FAILED });
    yield put(handleGlobalError(e));
  }
}
//UPDATE_WEBSITE_URL_REQUEST updateWebsiteUrl

export function* updateWebsiteUrl(data) {
  const url = "/client/update/chatconfig";
  console.log(
    "The Data we put to update website url  is" + JSON.stringify(data)
  );
  try {
    yield put(VoiceboxActions.showVoicebox({ message: "Updating" }));
    let chatConfigResponse = yield call(Ajax.put, url, data.requestObj);
    let chatConfig = JSON.parse(chatConfigResponse.data);
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Update Successful",
        dismissAfter: 2000,
      })
    );
    yield put(updateChatConfigResopnse(chatConfig));
    yield put(updateWebsiteScreenshot(data.requestObj));
  } catch (e) {
    yield put(
      NotificationActions.addNotification({
        message: "Website url Updation Failed",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
    yield put(handleGlobalError(e));
  }
}
