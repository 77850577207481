import React, { useState, useRef } from "react";
import {
  isOverflowConnection,
} from "../../commons/Utility.js";
import MessageTags from "./TagMessages/MessageTags.jsx";
import MessageOptions from "./TagMessages/MessageOptions.jsx";

const MessageWrapper = (props) => {
  const {
    tags,
    messageData,
    getMessageContentByType,
    isFromChatHistory,
    isFromPeopleProfilePage,
  } = props;

  const [showIcon, setShowIcon] = useState(false);
  const [hideIcon, setHideIcon] = useState(true);
  const messageWrapperRef = useRef("");

  const updateIconVisibility = (doHideIcon) => {
    setHideIcon(doHideIcon);
  };

  const doShowIcon = () =>{
    return !isOverflowConnection() && !isFromChatHistory && !isFromPeopleProfilePage;
  }

  const getClassNameOfMessageWrapper = () => {
    return "message-wrapper" + (doShowIcon() ? " webapp" : "");
  };

  const getPayloadForRemoveTags = () => {
    const {key, projectId} = messageData;
    return [projectId, key];
  }

  return (
    <div
      className={getClassNameOfMessageWrapper()}
      onMouseLeave={(event) => {
        if (hideIcon) setShowIcon(false);
      }}
      onMouseEnter={(event) => {
        setShowIcon(doShowIcon());
      }}
      ref={messageWrapperRef}
    >
      {getMessageContentByType()}
      {showIcon ? (
        <MessageOptions
          showIcon={showIcon}
          tags={tags}
          messageData={messageData}
          hideIcon={updateIconVisibility}
        />
      ) : (
        ""
      )}
      {tags.length && !isOverflowConnection() ? (
        <MessageTags
          tags={tags}
          removeOption={true}
          isFromMessage={true}
          payload={getPayloadForRemoveTags()}
          messageDiv={messageWrapperRef.current.parentElement}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default MessageWrapper;
