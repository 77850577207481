import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { Margin } from 'styled-components-spacing'
import { Button } from 'src/features/form/components/Button'
import { DesktopOnly } from 'src/features/ui/components/Visibility'
import { TypeButton } from 'src/features/wizard/install/components/InstallTypeSelection/TypeButton'
import { TypeSection } from 'src/features/wizard/install/components/InstallTypeSelection/TypeSection'
import { EnumOpenType } from 'src/features/wizard/install/components/InstallTypeSelection/TypeSection/types'
import { Section } from 'src/features/wizard/install/components/ManualInstall/Section'
import { Selection } from 'src/features/wizard/install/components/PluginInstall/Selection'
import {
  Container,
  InstallButtonsGrid,
} from 'src/features/wizard/install/components/WidgetInstall/styled'
import { selectWasConnectionTested } from 'src/features/wizard/install/store/selectors'
import { IAppState } from 'src/utils/state/store'

export interface IProps {
  hasBottomBorder?: boolean
  continueButtonProps: {
    handleOnClick: VoidFunction
    label: string
  }
  wasConnectionTested: boolean
  hasFixedHeader: boolean
}

export const WidgetInstallComponent: FC<IProps> = ({
  hasBottomBorder,
  continueButtonProps,
  wasConnectionTested,
  hasFixedHeader,
}) => {
  const [currentSection, setCurrentSection] = useState<
    undefined | EnumOpenType
  >(undefined)

  const toggleSection = (type: EnumOpenType) => {
    if (currentSection === type) {
      return setCurrentSection(undefined)
    }

    return setCurrentSection(type)
  }

  const handleClickManual = () => toggleSection(EnumOpenType.widgetType)

  const handleClickPlugin = () => toggleSection(EnumOpenType.plugin)

  const renderTabsFooter = () =>
    wasConnectionTested && (
      <>
        <Margin top={{ base: 32, sm: 40 }} />
        <Button
          color="primary"
          colorType="full"
          onClick={continueButtonProps.handleOnClick}
          hasFullWidthMobile
        >
          {continueButtonProps.label}
        </Button>
      </>
    )

  return (
    <Container>
      <DesktopOnly>
        <InstallButtonsGrid>
          <TypeButton
            onClick={handleClickManual}
            icon="widgetType"
            label="Install widget manually"
            isActive={currentSection === EnumOpenType.widgetType}
          />
          <TypeButton
            onClick={handleClickPlugin}
            icon="plugin"
            label="Install via plugin"
            isActive={currentSection === EnumOpenType.plugin}
          />
        </InstallButtonsGrid>
      </DesktopOnly>
      <TypeSection
        hasBottomBorder={hasBottomBorder}
        onToggle={toggleSection}
        label="Install widget manually"
        icon="widgetType"
        isOpen={currentSection === EnumOpenType.widgetType}
      >
        <Section footer={renderTabsFooter()} />
      </TypeSection>
      <TypeSection
        hasBottomBorder={hasBottomBorder}
        onToggle={toggleSection}
        label="Install via plugin"
        icon="plugin"
        isOpen={currentSection === EnumOpenType.plugin}
      >
        <Selection
          hasFixedHeader={hasFixedHeader}
          footer={renderTabsFooter()}
        />
      </TypeSection>
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  wasConnectionTested: selectWasConnectionTested(state.install)!,
})

export const WidgetInstall = connect(mapStateToProps)(WidgetInstallComponent)
