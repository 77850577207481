import React, { useEffect, useState } from "react";
import { ReturningFlow } from "@adaptavant/chatsupport-onboarding";
import { useChatConfigurationEntity } from "../../settings/hooks/useChatConfigurationEntity";
import { getProjectId } from "../../commons/Utility";
import { useSelector } from "react-redux";

const SetupComponent = (props) => {
    const {  userReducer } = useSelector(
        (state) => ({
          userReducer: state.UserReducer
        })
      );

    const getUserId = () => {
        let userId = userReducer && userReducer.data && userReducer.data.id || "";
        console.log("Passing the user id to the Returning user Flow :" + userId);
        return userId; 
    }

    const getIsFromSignup = () => {
      return localStorage.getItem("isFromSignup");
    }


    const [config , setConfig] = useState({});
    let {projectkey} = props.params;
    projectkey = getProjectId(projectkey);
    const { chatConfiguration, isConfigFetched } = useChatConfigurationEntity(projectkey);
    useEffect(() => {
        if (isConfigFetched) 
            setConfig(chatConfiguration);
    }, [isConfigFetched]);
    
    return <ReturningFlow widgetData={config} userId={getUserId()} isLoadingAfterSignup={getIsFromSignup()}/>
}

export default SetupComponent;