import { put, takeLatest } from 'redux-saga/effects'
import * as actions from 'src/features/returningFlow/store/actions'
import * as wizardSetupActions from 'src/features/wizard/setup/store/actions'

export function* sagaHandleWidgetUpdate(
  action: ReturnType<typeof wizardSetupActions.requestUpdateWidgetSuccess>
) {
  if (
    action.payload.type ===
    wizardSetupActions.EnumWidgetUpdateType.returningFlow
  ) {
    yield put(actions.nextStep())
    window.scrollTo(0, 0)
  }
}

/* istanbul ignore next */
export function* returningFlowSagas() {
  yield takeLatest(
    wizardSetupActions.REQUEST_UPDATE_WIDGET_SUCCESS,
    sagaHandleWidgetUpdate
  )
  // TODO: implement error handling
  yield takeLatest(wizardSetupActions.SET_ERROR, sagaHandleWidgetUpdate)
}
