import { SHOW_POPUP, HIDE_POPUP } from "../actions/PopupAction";

const PopupReducer = (state = { popupProps: { display: false } }, action) => {
  switch (action.type) {
    
    case SHOW_POPUP:
      return {
        popupProps: action.popupProps,
      };
    case HIDE_POPUP:
      return {
        popupProps: action.popupProps,
      };
    default:
      return state;
  }
};

export default PopupReducer;
