import * as R from 'ramda'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { Icon } from 'src/features/ui/components/Icon'
import { Text1 } from 'src/features/ui/components/Typography'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  display: none;
  align-items: center;

  ${mq.sm} {
    display: flex;
  }
`

export const Step = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  position: relative;
  margin-right: 2.45rem;
`

export const Label = styled(Text1)<{ enabled: boolean }>`
  color: ${R.path(['theme', 'color', 'raven'])};

  ${is('enabled')`
    color: ${R.path(['theme', 'color', 'black'])};
  `}
`

export const Chevron = styled(Icon)<{ $incomplete: boolean }>`
  ${is('$incomplete')`
    margin-left: 1rem;
    margin-right: 2.55rem;
  `}
`

export const Checkmark = styled(Icon)<{ $notFirst: boolean }>`
  margin-right: 1.2rem;

  ${is('$notFirst')`
    margin-left: 2.95rem;
  `}
`
