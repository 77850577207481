export const INITIALIZE_RESET_REQUEST = "INITIALIZE_RESET_REQUEST";
export const INITIALIZE_RESET_RESPONSE = "INITIALIZE_RESET_RESPONSE";
export const INITIALIZE_RESET_FAILED = "INITIALIZE_RESET_FAILED";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const UPDATE_VERIFICATION_ID = "UPDATE_VERIFICATION_ID";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RESPONSE = "RESET_PASSWORD_RESPONSE";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const GENERAL_ERROR_MESSAGE = "GENERAL_ERROR_MESSAGE";

export const initializeResetRequest = (login) => ({
  type: INITIALIZE_RESET_REQUEST,
  login,
});

export const initializeResetResponse = () => ({
  type: INITIALIZE_RESET_RESPONSE,
});

export const initializeResetFailed = (error) => ({
  type: INITIALIZE_RESET_FAILED,
  error,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const updateVerificationId = (verificationId, contactId) => ({
  type: UPDATE_VERIFICATION_ID,
  verificationId,
  contactId,
});

export const resetPasswordRequest = (resetInfo) => ({
  type: RESET_PASSWORD_REQUEST,
  resetInfo,
});

export const resetPasswordResponse = () => ({
  type: RESET_PASSWORD_RESPONSE,
});

export const resetPasswordFailed = (passwordError) => ({
  type: RESET_PASSWORD_FAILED,
  passwordError,
});

export const generalErrorMessage = (errorMessage) => ({
  type: GENERAL_ERROR_MESSAGE,
  errorMessage,
});
