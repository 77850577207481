import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ConversationList from "../components/chat/ConversationList.jsx";
import {
  getFullName,
  isOverflowConnection,
  getQueryParam,
  isOpenChat
} from "../commons/Utility";
import * as ConversationActions from "../actions/ConversationInfoAction";
import { messageRequest } from "../actions/MessageAction";
import { CHAT_MESSAGE_LIMIT, CURSOR_STATUS } from "../commons/Constants";
import ReactTooltip from "react-tooltip";
import ExpandedConversationHistoryComponent from "../components/chat/ExpandedConversationHistoryComponent.jsx";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";
@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  UserReducer: state.UserReducer,
  MessageReducer: state.MessageReducer,
  tagReducer: state.TagReducer,
  staffReducer: state.StaffReducer,
  urlPreviews: state.UrlPreviewReducer,
  attachmentReducer: state.AttachmentReducer,
  VisitorQueueReducer: state.VisitorQueueReducer,
}))
class ChatHistoryContainer extends Component {
  constructor() {
    super();
    this.setLocalState = this.setLocalState.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.expandConversation = this.expandConversation.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
    this.navigateConversation = this.navigateConversation.bind(this);
    this.getAgentNameById = this.getAgentNameById.bind(this);
    this.getOverflowAgentNameById = this.getOverflowAgentNameById.bind(this);
    this.getAgentImageURLById = this.getAgentImageURLById.bind(this);
    this.getVisitorNameById = this.getVisitorNameById.bind(this);
    this.fetchMessages = this.fetchMessages.bind(this);
    this.loadMessagesOnScroll = this.loadMessagesOnScroll.bind(this);
    this.showAllConversations = this.showAllConversations.bind(this);
    this.state = {
      ...this.state,
      displayShowAllButton: false,
      selectedConversationId: "",
      messageMap: {},
      user: {},
      staffMap: {},
      conversationObject: {
        isConvFetched: false,
        isConvFetching: false,
      },
      params: {
        projectkey: "",
      },
      showLimit: 3,
    };
  }
  componentWillMount() {
    this.setLocalState(this.props);
  }
  componentDidMount() {
    ReactTooltip.rebuild();
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  componentWillReceiveProps(nextProps) {
    this.setLocalState(nextProps, this.props);
  }
  setLocalState(props, previousProps) {
    let currentVisitorId =
      previousProps && previousProps.contact && previousProps.contact.key
        ? previousProps.contact.key
        : "";
    let nextVisitorId =
      props && props.contact && props.contact.key ? props.contact.key : "";

    let conversationId =
      currentVisitorId != nextVisitorId
        ? ""
        : this.state.selectedConversationId;
    let conversationMap = Object.assign(
      {},
      props.ConversationReducer.visitorConversationObject.conversationMap
    );

    let currentConversationId =
      props.parentParams && props.parentParams.conversationId
        ? props.parentParams.conversationId
        : getQueryParam("conversation_id")
        ? getQueryParam("conversation_id")
        : "";
    if (currentConversationId) delete conversationMap[currentConversationId];

    let sortedConversationMap = this.getSortedConversationList(conversationMap);
    let conversationIds = Object.keys(sortedConversationMap);
    let currentIndex = conversationIds.indexOf(conversationId);
    let nextConversationId = conversationIds[currentIndex + 1];
    let previousConversationId = conversationIds[currentIndex - 1];

    this.setState({
      ...this.state,
      messages: props.MessageReducer.messageMap[
        this.state.selectedConversationId
      ]
        ? props.MessageReducer.messageMap[this.state.selectedConversationId]
        : {},
      user: props.UserReducer,
      visitorId: props.contact.key,
      contact: props.contact,
      conversationObject: {
        ...props.ConversationReducer.visitorConversationObject,
        conversationMap: sortedConversationMap,
        contactsMap: props.ConversationReducer.conversationObject.contactsMap,
        isConvFetched: props.ConversationReducer.isVisitorConversationFetched,
        isConvFetching: props.ConversationReducer.isVisitorConversationFetching,
      },
      tagMap: props.tagReducer.tagMap,
      params: {
        projectkey: props.projectKey,
      },
      staffMap: props.staffReducer.dashboardAgents[props.projectKey]
        ? Object.assign(
            {},
            props.staffReducer.dashboardAgents[props.projectKey]
          )
        : {},
      overflowAgents: props.staffReducer.overflowAgents
        ? Object.assign({}, props.staffReducer.overflowAgents)
        : {},
      selectedConversationId: conversationId,
      nextConversationId,
      previousConversationId,
      showLoading:
        props.MessageReducer.isFetching &&
        !props.MessageReducer.messageMap[this.state.selectedConversationId],
      displayShowAllbutton:
        this.state.showLimit < Object.keys(conversationMap).length, //
    });
  }
  getSortedConversationList(conversationMap) {
    let conversationList = Object.values(conversationMap);
    let sortedConversationList = {};

    conversationList = conversationList.sort((a, b) => {
      return b["modifiedDate"] - a["modifiedDate"];
    });
    conversationList = conversationList.slice(0, this.state.showLimit);
    conversationList.map((conversation, i) => {
      sortedConversationList[conversation.key] = conversation;
    });
    return sortedConversationList;
  }
  loadMore(e) {
    let sum = e.target.scrollTop + e.target.clientHeight + 5;

    if (
      sum >= e.target.scrollHeight &&
      !this.state.conversationObject.isConvFetching &&
      !this.state.conversationObject.isConvFetched
    ) {
      let conversationMap = this.props.ConversationReducer
        .visitorConversationObject.conversationMap;
      let conversationKeys = Object.keys(conversationMap);
      let lastConversation =
        conversationMap[conversationKeys[conversationKeys.length - 1]];
      this.props.dispatch(
        ConversationActions.visitorConversationListRequest({
          isFromOverflow: isOverflowConnection(),
          visitorId: this.props.contact.key,
          projectId: this.props.projectKey,
          isConversationStarted: true,
          cursor: this.props.ConversationReducer.visitorConversationCursor,
          limit: 20,
          desc: true,
          isNewList: false,
          modifiedDate: lastConversation.modifiedDate,
          orderBy: "-modifiedDate",
        })
      );
    }
  }

  expandConversation(e) {
    let conversationId = e.currentTarget.dataset.conversation_id;
    let conversationIds = Object.keys(
      this.state.conversationObject.conversationMap
    );
    let currentIndex = conversationIds.indexOf(conversationId);
    let nextConversationId = conversationIds[currentIndex + 1];
    let previousConversationId = conversationIds[currentIndex - 1];

    setTimeout(() => {
      //This timeout is added temporarily because onblur hide visitor details handler for overflow/aw window is not working properly. Need to find a better way.
      this.setState(
        {
          ...this.state,
          selectedConversationId: conversationId,
          nextConversationId,
          previousConversationId,
          messages: this.props.MessageReducer.messageMap[conversationId]
            ? this.props.MessageReducer.messageMap[conversationId]
            : {},
        },
        () => {
          if (
            !this.props.MessageReducer.messageMap[conversationId] &&
            !this.props.MessageReducer.isFetching
          )
            this.fetchMessages(conversationId);
        }
      );
    }, 100);
  }
  backButtonClick(e) {
    setTimeout(() => {
      this.setState({
        ...this.state,
        selectedConversationId: "",
        nextConversationId: "",
        previousConversationId: "",
      });
    }, 100);
  }
  navigateConversation(e) {
    let selectedConversationId = this.state.selectedConversationId;
    let nextConversationId = e.target.dataset.next_conversation_id;
    let previousConversationId = e.target.dataset.previous_conversation_id;

    let conversationIds = Object.keys(
      this.state.conversationObject.conversationMap
    );

    if (e.target.dataset.option == "next" && nextConversationId) {
      let currentNextIndex = conversationIds.indexOf(nextConversationId) + 1;
      previousConversationId = selectedConversationId;
      selectedConversationId = nextConversationId;
      nextConversationId = conversationIds[currentNextIndex];
    } else if (
      e.target.dataset.option == "previous" &&
      previousConversationId
    ) {
      let currentPreviousIndex =
        conversationIds.indexOf(previousConversationId) - 1;
      nextConversationId = selectedConversationId;
      selectedConversationId = previousConversationId;
      previousConversationId = conversationIds[currentPreviousIndex];
    }
    if (
      this.state.selectedConversationId != selectedConversationId &&
      selectedConversationId
    )
      this.setState(
        {
          ...this.state,
          selectedConversationId,
          nextConversationId,
          previousConversationId,
          messages: this.props.MessageReducer.messageMap[selectedConversationId]
            ? this.props.MessageReducer.messageMap[selectedConversationId]
            : {},
        },
        () => {
          if (!this.props.MessageReducer.messageMap[selectedConversationId])
            this.fetchMessages(selectedConversationId);
        }
      );
  }

  showAllConversations() {
    let historyContainerHeight = this.refs["chat-history"].clientHeight;

    Event(
      "LS-" + this.props.projectKey,
      "History",
      "Show All",
      "Show All Clicked"
    );
    this.setState(
      { ...this.state, displayShowAllbutton: false, showLimit: 100 },
      () => {
        let conversationMap = this.props.ConversationReducer
          .visitorConversationObject.conversationMap;
        let conversationKeys = Object.keys(conversationMap);
        let lastConversation =
          conversationMap[conversationKeys[conversationKeys.length - 1]];
        this.props.dispatch(
          ConversationActions.visitorConversationListRequest({
            isFromOverflow: isOverflowConnection(),
            visitorId: this.props.contact.key,
            projectId: this.props.projectKey,
            isConversationStarted: true,
            cursor: this.props.ConversationReducer.visitorConversationCursor,
            limit: 20,
            desc: true,
            isNewList: false,
            modifiedDate: lastConversation.modifiedDate,
            orderBy: "-modifiedDate",
          })
        );
      }
    ); //100 is a just for a max value
  }

  fetchMessages(conversationId) {
    let cursor = this.props.MessageReducer.convMsgCursorMap[conversationId];
    console.log("cursor => ", cursor);

    //To Stop fetching messages if there is no cursor
    if (cursor != CURSOR_STATUS.NO_CURSOR) {
      let currentConversationMsgs = this.props.MessageReducer.messageMap[
        conversationId
      ];
      let lastMsgCreatedDate = new Date().getTime() - this.props.UserReducer.timeDifference; //To set current time to fetch old messages
      if (currentConversationMsgs) {
        let messageKeys = Object.keys(currentConversationMsgs);
        let lastFetchedMessage =
          currentConversationMsgs[messageKeys[messageKeys.length - 1]];
        lastMsgCreatedDate = lastFetchedMessage.createdDate;
      }

      if (!this.props.MessageReducer.isFetching) {
        this.props.dispatch(
          messageRequest({
            isFromOverflow: isOverflowConnection(),
            queryParam: {
              isActiveChat: isOpenChat(conversationId),
              conversationId: conversationId,
              projectId: this.props.projectKey,
              createdDate: lastMsgCreatedDate,
              limit: CHAT_MESSAGE_LIMIT,
            },
          })
        );
      }
    }
  }

  loadMessagesOnScroll(e) {
    let scrollTo = e.currentTarget.scrollTop;
    let scrollHeight = e.currentTarget.scrollHeight;
    let triggerPoint = e.currentTarget.scrollHeight / 3;

    if (scrollTo < triggerPoint || scrollTo < 200) {
      this.fetchMessages(this.state.selectedConversationId);

      /**
       * To allow scroll to stick on more fetches after moved the scrollbar to last message on conversation at first response
       */
      this.setState({ scrollToLstMsg: false });
    }
  }
  getAgentNameById(agentId) {
    let assignedAgentName = "";
    let staffMap = this.props.staffReducer.dashboardAgents
      ? this.props.staffReducer.dashboardAgents[this.props.projectKey]
      : {};
    let overflowAgents = this.props.staffReducer.overflowAgents
      ? this.props.staffReducer.overflowAgents
      : {};
    let assignedAgent = undefined;
    if (agentId && staffMap) {
      if (!staffMap[agentId] && overflowAgents) {
        //Lets check in the overflow agents.
        assignedAgent = overflowAgents[agentId];
        if (
          assignedAgent &&
          assignedAgent.aliasName &&
          assignedAgent.aliasName != "null"
        ) {
          if (assignedAgent.lastName && assignedAgent.lastName !== "null") {
            assignedAgentName =
              assignedAgent.aliasName +
              " " +
              assignedAgent.lastName.charAt(0).toUpperCase();
          } else {
            assignedAgentName = assignedAgent.aliasName;
          }
        } else {
          assignedAgentName = assignedAgent ? assignedAgent.firstName : "";
        }
      }

      if (staffMap[agentId]) {
        assignedAgent = staffMap[agentId]
          ? Object.assign({}, staffMap[agentId])
          : undefined;
        if (
          assignedAgent &&
          assignedAgent.aliasName &&
          assignedAgent.aliasName != "null"
        ) {
          if (assignedAgent.lastName && assignedAgent.lastName !== "null") {
            assignedAgentName =
              assignedAgent.aliasName +
              " " +
              assignedAgent.lastName.charAt(0).toUpperCase();
          } else {
            assignedAgentName = assignedAgent.aliasName;
          }
        } else {
          assignedAgentName = assignedAgent ? assignedAgent.firstName : "";
        }
      } else if (agentId === "System") {
        assignedAgentName = "System";
      } else if (agentId && !this.props.staffReducer.isLoading) {
        assignedAgentName = "Agent";
      } else {
        assignedAgentName = agentId;
      }
    }

    return assignedAgentName;
  }
  getOverflowAgentNameById(agentId) {
    let agentName = "";
    let staffMap = this.props.staffReducer.dashboardAgents
      ? this.props.staffReducer.dashboardAgents[this.props.projectKey]
      : {};
    let overflowAgents = this.props.staffReducer.overflowAgents
      ? this.props.staffReducer.overflowAgents
      : {};
    if (agentId && staffMap) {
      let assignedAgent = staffMap[agentId]
        ? Object.assign({}, staffMap[agentId])
        : undefined;

      if (!assignedAgent && overflowAgents) {
        assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
      }

      if (
        assignedAgent &&
        assignedAgent.aliasName &&
        assignedAgent.aliasName != "null"
      ) {
        agentName = assignedAgent.aliasName;
      } else if (
        assignedAgent &&
        assignedAgent.firstName &&
        assignedAgent.firstName != "null"
      ) {
        agentName = assignedAgent.firstName;
        if (assignedAgent.lastName && assignedAgent.lastName != "null")
          agentName += " " + assignedAgent.lastName.charAt(0);
      }
    }
    return agentName;
  }

  getAgentImageURLById(agentId) {
    let agentImageURL = "https://app.chatsupport.co/images/Staff-Avatar.svg";

    let staffMap = this.props.staffReducer.dashboardAgents
      ? this.props.staffReducer.dashboardAgents[this.props.projectKey]
      : {};
    let overflowAgents = this.props.staffReducer.overflowAgents
      ? this.props.staffReducer.overflowAgents
      : {};

    if (agentId && staffMap) {
      let assignedAgent = staffMap[agentId]
        ? Object.assign({}, staffMap[agentId])
        : undefined;
      if (!assignedAgent && overflowAgents) {
        //Lets check in the agents
        assignedAgent = overflowAgents[agentId]
          ? Object.assign({}, overflowAgents[agentId])
          : undefined;
      }
      agentImageURL =
        assignedAgent &&
        assignedAgent.photoUrl &&
        assignedAgent.photoUrl != "null"
          ? assignedAgent.photoUrl
          : agentImageURL;
    }

    return agentImageURL;
  }

  getVisitorNameById(visitorId) {
    let visitorDetails = this.props.ConversationReducer.conversationObject
      .contactsMap[visitorId];
    let visitorName = "V";
    if (visitorDetails) {
      if (visitorDetails.firstName || visitorDetails.lastName) {
        visitorName = visitorDetails.firstName
          ? visitorDetails.firstName.charAt(0)
          : "" + visitorDetails.lastName
          ? visitorDetails.lastName.charAt(0)
          : "";
      }
    }
    return visitorName || "V";
  }
  render() {
    let state = this.state;
    let chatHistoryData = {
      state: state,
      loadMore: this.loadMore,
      expandConversation: this.expandConversation,
      getAgentImageURLById: this.getAgentImageURLById,
      getOverflowAgentNameById: this.getOverflowAgentNameById,
      getAgentNameById: this.getAgentNameById,
      VisitorQueueReducer: this.props.VisitorQueueReducer,
    };

    console.info("displayShowAllbutton:" + this.state.displayShowAllbutton);

    if (!state.selectedConversationId) {
      if (Object.keys(this.state.conversationObject.conversationMap) < 1)
        return <span></span>;
      else
        return (
          <section
            ref="chat-history"
            className="cs-user-history"
            style={
              isOverflowConnection() ? { height: "calc( 100vh - 50px )" } : {}
            }
          >
            <ConversationList
              ref={(el) => (this.child = el)}
              chatHistoryData={chatHistoryData}
              isFromChatHistory={true}
              dispatch={this.props.dispatch}
              ConversationReducer={this.props.ConversationReducer}
            />
            <a
              style={{
                display: this.state.displayShowAllbutton ? "block" : "none",
              }}
              onClick={this.showAllConversations}
              onKeyDown={ () => {} }
            >
              Show All
            </a>
          </section>
        );
    } else {
      let conversationMap = state.conversationObject.conversationMap;
      let conversation = conversationMap[state.selectedConversationId];
      let assignedAgent =
        conversation && state.staffMap[conversation.assignedTo]
          ? state.staffMap[conversation.assignedTo]
          : undefined;
      return (
        <ExpandedConversationHistoryComponent
          overflowedAgent={this.props.overflowedAgent}
          state={state}
          dispatch={this.props.dispatch}
          messages={this.state.messages}
          contact={state.contact}
          user={state.user}
          assignedAgent={assignedAgent}
          selectedConversation={conversation}
          nextConversationId={state.nextConversationId}
          previousConversationId={state.previousConversationId}
          backButtonClick={this.backButtonClick}
          navigateConversation={this.navigateConversation}
          urlPreviews={this.props.urlPreviews}
          getVisitorNameById={this.getVisitorNameById}
          getAgentImageURLById={this.getAgentImageURLById}
          getOverflowAgentNameById={this.getOverflowAgentNameById}
          getAgentNameById={this.getAgentNameById}
          isUploadingAttachment={this.props.attachmentReducer.isUploading}
          uploadingMessageData={this.props.attachmentReducer.messageData}
          loadMessagesOnScroll={this.loadMessagesOnScroll}
          showLoading={this.state.showLoading}
        />
      );
    }
  }
}

export default ChatHistoryContainer;
