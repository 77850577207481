import { call, put } from "redux-saga/effects";
import { queueConversationResponse } from "../actions/ConversationQueueAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { fetchPresenceByUserIds } from "../actions/PeopleAction";
import {
  constructConversationInReducer,
  getActiveProjectId,
  isOverflowConnection,
} from "../commons/Utility";
import { post } from "../xhr/XhrRequestHandler";

export function* fetchQueueConversationList() {
  try {
    if (isOverflowConnection()) {
      return;
    }
    const url = "/conversation/queue/get";
    const payload = {
      isConversationStarted: true,
      isQueuedConversation: true,
      projectId: getActiveProjectId(),
    };
    const response = yield call(post, url, payload);
    const { responseObj, contactKeys } = constructConversationInReducer(
      url,
      response,
      { conversationObject: payload }
    );
    yield put(queueConversationResponse(responseObj));
    if (contactKeys.length > 0) {
      yield put(fetchPresenceByUserIds(contactKeys, getActiveProjectId()));
    }
  } catch (error) {
    yield put(handleGlobalError(error));
  }
}

export default fetchQueueConversationList;
