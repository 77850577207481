import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createUUId,
  getProjectId,
  isValidEmail,
  isValidPersonName,
} from "../../commons/Utility.js";
import InviteStaffComponent from "./InviteStaffComponent.jsx";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import { sendInvitationRequest } from "../../actions/InvitationAction.js";

const InviteStaffContainer = (props) => {
  const { projectList, projectStaffMap, isInviting } = useSelector((state) => ({
    projectList: state.ProjectReducer.projectList,
    projectStaffMap:
      state.StaffReducer.dashboardAgents[getProjectId(props.params.projectkey)],
    isInviting: state.InvitationReducer.isInviting,
  }));

  const getDefaultStaffObject = () => ({
    key: createUUId(),
    email: "",
    fullName: "",
    skill: "",
    role: "",
    projects:
      projectList.length > 1 ? [] : projectList[0] ? [projectList[0].key] : [],
    invalids: [],
  });
  const getExistingEmails = () => {
    let existingEmailList = [];
    if (projectStaffMap)
      for (let staffId in projectStaffMap)
        if (projectStaffMap[staffId].status == "ACTIVE")
          existingEmailList.push(projectStaffMap[staffId].login);
    return existingEmailList;
  };
  const dispatch = useDispatch();
  let defaultFirstStaff = getDefaultStaffObject();
  const [staffMap, setStaffMap] = useState({
    [defaultFirstStaff.key]: defaultFirstStaff,
  });
  const [existingEmails, setExistingEmails] = useState(getExistingEmails());
  const [inviteButtonEnabled, setInviteButtonEnabled] = useState(false);

  const addStaff = () => {
    if (validateStaffs()) {
      let staff = getDefaultStaffObject();
      setStaffMap({ ...staffMap, [staff.key]: staff });
    }
  };
  const removeStaff = (key) => {
    delete staffMap[key];
    setStaffMap({ ...staffMap });
  };
  const updateStaff = (staff) => {
    if (staff.invalids.length > 0) staff = checkInvalid(staff);
    setStaffMap({ ...staffMap, [staff.key]: staff });
    if (staff.invalids.length == 0 && !inviteButtonEnabled)
      setInviteButtonEnabled(true);
  };

  useEffect(() => {
    setExistingEmails(getExistingEmails());
  }, [projectStaffMap]);
  useEffect(() => {
    let defaultFirstStaff = getDefaultStaffObject();
    setStaffMap({ [defaultFirstStaff.key]: defaultFirstStaff });
  }, [projectList.length]);
  const checkInvalid = (staff) => {
    let invalids = [];
    if (!staff.email.trim()) invalids.push("EMPTY_EMAIL");
    else if (!isValidEmail(staff.email.trim())) invalids.push("INVALID_EMAIL");
    else if (existingEmails.includes(staff.email.toLowerCase().trim()))
      invalids.push("EXISTING_EMAIL");
    else {
      let firstIndex = 0;
      for (let key in staffMap) {
        let existingStaff = staffMap[key];
        if (
          existingStaff.email.toLowerCase().trim() ==
          staff.email.toLowerCase().trim()
        ) {
          if (firstIndex > 0 && key == staff.key)
            invalids.push("EXISTING_EMAIL");
          firstIndex++;
        }
      }
    }

    if (!staff.fullName.trim()) invalids.push("EMPTY_FULLNAME");
    else if (!isValidPersonName(staff.fullName.trim()))
      invalids.push("INVALID_FULLNAME");

    if (!staff.role) invalids.push("EMPTY_ROLE");

    if (!staff.skill) invalids.push("EMPTY_SKILL");

    if (staff.projects.length < 1) invalids.push("EMPTY_PROJECTS");

    staff.invalids = invalids;
    return staff;
  };
  const validateStaffs = () => {
    let isValid = true;
    for (let key in staffMap) {
      let staff = checkInvalid(staffMap[key]);
      if (staff.invalids.length > 0) isValid = false;
    }
    setStaffMap({ ...staffMap });
    return isValid;
  };
  const getInvitePayload = () => {
    let projectStaffMap = {};
    for (let staffId in staffMap) {
      let staff = staffMap[staffId];
      for (let projectId of staff.projects) {
        let toBeAddedStaff = {
          email: staff.email.toLowerCase().trim(),
          fullName: staff.fullName.trim(),
          staffRole: staff.role,
          skillLevel: staff.skill,
        };
        if (!projectStaffMap[projectId])
          projectStaffMap[projectId] = {
            [staff.email.toLowerCase().trim()]: toBeAddedStaff,
          };
        else
          projectStaffMap[projectId] = {
            ...projectStaffMap[projectId],
            [staff.email.toLowerCase().trim()]: toBeAddedStaff,
          };
      }
    }
    return projectStaffMap;
  };
  const inviteStaffs = () => {
    if (!isInviting && inviteButtonEnabled && validateStaffs()) {
      let inviteMap = getInvitePayload();
      console.log(inviteMap);
      dispatch(sendInvitationRequest(inviteMap));
      dispatch(VoiceboxActions.showVoicebox({ message: "Sending invitation" }));
    }
  };

  return (
    <InviteStaffComponent
      staffMap={staffMap}
      addStaff={addStaff}
      removeStaff={removeStaff}
      updateStaff={updateStaff}
      projectList={projectList}
      inviteStaffs={inviteStaffs}
      inviteButtonEnabled={inviteButtonEnabled}
    />
  );
};
export default InviteStaffContainer;
