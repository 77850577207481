import React from "react";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import { browserHistory } from "react-router";
import {
  constructPathnameInVisitorDetails,
  detailsTabClassName,
  historyTabClassName,
} from "../../commons/Utility";
import PropTypes from "prop-types";

const VisitorDetailsTab = ({
  props,
  state,
  setState,
  returnWidgetToActualPosition,
}) => {
  const { visitorConversationMap, isOverflowConversation, params } = props;
  const { visitorOption, projectkey, chatStatus, sortBy, assignedTo, conversationId } = params;

  const onClickHistoryHandler = () => {
    setState({ isDetailSection: false });
    if (window.FrontOfficeService) {
      return;
    }
    browserHistory.push(
      constructPathnameInVisitorDetails(
        isOverflowConversation,
        projectkey,
        "H",
        chatStatus,
        sortBy,
        assignedTo,
        conversationId
      )
    );
    returnWidgetToActualPosition();
    Event("LS-" + projectkey, "History", "History", "History Page");
  };

  const onClickDetailsHandler = () => {
    setState({ isDetailSection: true });
    if (window.FrontOfficeService) {
      return;
    }
    browserHistory.push(
      constructPathnameInVisitorDetails(
        isOverflowConversation,
        projectkey,
        "D",
        chatStatus,
        sortBy,
        assignedTo,
        conversationId
      )
    );
    returnWidgetToActualPosition();
  };

  return (
    <section className="cs-user-info-tab">
      <button
        id="cs-user-info-detailsTab"
        className={detailsTabClassName(state.isDetailSection)}
        onClick={onClickDetailsHandler}
      >
        Details
      </button>
      <button
        id="cs-user-info-historyTab"
        className={historyTabClassName(
          visitorOption,
          state.isDetailSection,
          visitorConversationMap
        )}
        onClick={onClickHistoryHandler}
      >
        History
      </button>
    </section>
  );
};

VisitorDetailsTab.propTypes = {
  props: PropTypes.shape({
    params: PropTypes.shape({
      visitorOption: PropTypes.string.isRequired,
      projectkey: PropTypes.string.isRequired,
      chatStatus: PropTypes.string.isRequired,
      sortBy: PropTypes.string.isRequired,
      assignedTo: PropTypes.string.isRequired,
      conversationId: PropTypes.string.isRequired,
    }),
    isOverflowConversation: PropTypes.bool,
    visitorConversationMap: PropTypes.object.isRequired,
  }),
  state: PropTypes.shape({
    isDetailSection: PropTypes.bool.isRequired,
  }),
  setState: PropTypes.func.isRequired,
  returnWidgetToActualPosition: PropTypes.func.isRequired,
};

export default VisitorDetailsTab;
