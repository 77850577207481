import React, { FC } from 'react'

export const ErrorHollow: FC<any> = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8583e-08 7.07713C-0.000343589 3.25492 3.09753 0.155938 6.91973 0.154907C10.7418 0.159022 13.8391 3.25635 13.8432 7.07837C13.8422 10.9006 10.7432 13.9985 6.92098 13.9981C3.09877 13.9978 0.000343704 10.8993 2.8583e-08 7.07713ZM1.30667 7.07713C1.30598 10.1779 3.81893 12.6923 6.91973 12.6933C10.0198 12.6902 12.5324 10.1784 12.5365 7.07837C12.5365 3.97757 10.023 1.46378 6.92222 1.46344C3.82142 1.4631 1.30735 3.97633 1.30667 7.07713ZM7.57307 3.53171V7.82317H6.2664V3.53171H7.57307ZM7.57307 10.4533V9.09997H6.2664V10.4533H7.57307Z"
      fill="#DF0D29"
    />
  </svg>
)
