import React, { useRef, useState } from "react";
import { useEffect } from "react";
import SearchBar from "../search/SearchBar.jsx";
import Filter from "./Filter.jsx";
import { ALL_CHAT_FILTERS, MY_CHAT_FILTERS } from "../../../commons/Constants";
import {
  BlueDownArrowSettings,
  BlueUpArrowIcon,
} from "../../../commons/Icons.js";
import { useDispatch, useSelector } from "react-redux";
import { conversationIsLoading, conversationListFailed, getConversationCount, hideConversationFilter } from "../../../actions/ConversationInfoAction.js";
import { browserHistory } from "react-router";
import { isValidString, getProjectId, isCurrentFilterClicked } from "../../../commons/Utility.js";
import SearchCmp from "../Search.jsx";
import { cancelPreviousRequest } from "../../../xhr/XhrRequestHandler.js";

const ConversationFilterComponent = (props) => {
  const { conversationReducer, userReducer } = useSelector((state) => ({
    conversationReducer: state.ConversationInfoReducer,
    userReducer: state.UserReducer,
  }));

  const [myChatCountData, setMyChatCountData] = useState({});
  const [allChatCountData, setAllChatCountData] = useState({});
  const [myCount, setMyCount] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const [isAdvanceMode, setIsAdvanceMode] = useState(false);
  const filterRef = useRef(null);

  const dispatch = useDispatch();
  const userId = userReducer.data ? userReducer.data.id : "";
  let projectKey = isValidString(userReducer.selectedProjectKey) ?  userReducer.selectedProjectKey : props.params.projectkey;
  useEffect(() => {

    if (!conversationReducer.isCountFetching)
      dispatch(getConversationCount(getProjectId(projectKey)));
    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  }, []);

  const handleKeypress = (e) => {
    if (e.keyCode === 27 || e.which === 27) closeFilter();
  };

  useEffect(() => {
    if (conversationReducer.isCountFetched) {
      let { allChats, myChats } = conversationReducer.conversationCount;
      setAllCount(allChats["ALL_CHATS"]);
      setMyCount(myChats["ALL_CHATS"]);
      delete allChats["ALL_CHATS"];
      delete myChats["ALL_CHATS"];
      setMyChatCountData(myChats);
      setAllChatCountData(allChats);
    }
  }, [conversationReducer.isCountFetching]);

  useEffect(() => {
    if (conversationReducer.showConversationFilter) {
      document.addEventListener("click", handleOutSideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutSideClick, true);
    }
  }, [conversationReducer.showConversationFilter]);

  const handleOutSideClick = (e) => {
    if ((filterRef.current && !filterRef.current.contains(e.target)) || filterRef.current === null ) {
      closeFilter();
    }
  };
  const closeFilter = () => {
    document.removeEventListener("click", handleOutSideClick, true);
    dispatch(hideConversationFilter());
  };
  const filterClassName = () =>{
    return `cs-conversations-filter ${
      conversationReducer.showConversationFilter ? "open" : ""
    }`;
  }

  const handleClickAllChats = () => {
    const AllChatsPathName = `/app/${projectKey}/chat/dashboard/D/A/N/All/`;
    if (!isCurrentFilterClicked(AllChatsPathName)) {
      if (conversationReducer && conversationReducer.cancelToken)
        cancelPreviousRequest(conversationReducer.cancelToken);
      dispatch(conversationListFailed());
      dispatch(conversationIsLoading());
      browserHistory.push({
        pathname: AllChatsPathName,
      });
    }
    closeFilter();
  };
  
  const getChatCount = (countData, isMyChatFilter) => {
    let toRender = [];
    let filters = isMyChatFilter ? MY_CHAT_FILTERS : ALL_CHAT_FILTERS;
    for (let filterName in filters) {
      toRender.push(
        <Filter
          filterName={filterName}
          filterCount={countData[filterName]}
          isMyChatFilter={isMyChatFilter}
          projectKey={projectKey}
          userId={userId}
        />
      );
    }
    return toRender;
  };

if (!isAdvanceMode)
    return (
      <section
        ref={filterRef}
        className={filterClassName()}
      >
        <SearchBar
          conversationReducer={conversationReducer}
          dispatch={dispatch}
          dashboardData={props}
        />
        <code class="open1" onClick={closeFilter}>
          <BlueUpArrowIcon />
        </code>
        <p style={{ cursor: "default" }}>
          Mine
          <cite>{conversationReducer.isCountFetching ? null : myCount}</cite>
        </p>
        {getChatCount(myChatCountData, true)}
        <p onClick={handleClickAllChats} onKeyDown={ () => {} }>
            All
            <cite>{conversationReducer.isCountFetching ? null : allCount}</cite>
        </p>
        {getChatCount(allChatCountData, false)}
        <a onClick={() => setIsAdvanceMode(true)} onKeyDown={()=> {}}>
          Advanced Filters
          <BlueDownArrowSettings />
        </a>
      </section>
    );
  else return <SearchCmp {...props} dispatch={dispatch} setIsAdvanceMode={setIsAdvanceMode}/>;
};

export default ConversationFilterComponent;
