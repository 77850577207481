import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookie";
import { browserHistory, Link } from "react-router";
import {
  LIVE_LOGOUT_URL,
  STAGING_LOGOUT_URL,
  CLIENT_LOGIN_REFERENCE,
  MESSAGE_TYPE,
  BETA_ACCESS_URL,
  NOT_TAKING_CHAT_STATUSES,
  TAKING_CHAT_STATUSES,
  AVAILABILITY_STATUS,
} from "../commons/Constants";
import * as LoginActions from "../actions/LoginAction";
import * as ProjectActions from "../settings/actions/ProjectAction";
import * as ConnectionAction from "../actions/MessageAction";
import * as ConversationInfoAction from "../actions/ConversationInfoAction";
import * as InvitationActions from "../actions/InvitationAction";
import {
  fetchAndUpdateUserPresence,
  RESET_NAVIGATION_SETTINGS,
} from "../actions/PeopleAction";
import * as ChatConfigurationAction from "../settings/actions/ChatConfigurationAction";
import * as PopupActions from "../actions/PopupAction";
import * as SetupActions from "../actions/SetupAction";
import * as BroadcastActions from "../actions/BroadcastMessageStatsAction";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { requestAvailableTokens } from "../actions/AuthTokenAction";
import * as UserActions from "../actions/UserAction";
import * as NotificationSettingsAction from "../actions/NotificationSettingsAction";
import { staffListRequest } from "../actions/StaffAction";
import * as Utils from "../commons/Utility.js";
import ReactTooltip from "react-tooltip";
import UserStatusContainer from "../containers/UserStatusContainer";
import {
  PeopleIcon,
  ChatIcon,
  ChatActiveIcon,
  SettingsIcon,
  PeopleActiveIcon,
  ProjectSetupIcon,
  ManageTeamIcon,
  SettingsActiveIcon,
  ManageTeamsActiveIcon,
  SetupIcon,
  SetupActiveIcon,
  DirectoryIcon,
  DirectoryActiveIcon,
  CampaignActiveIcon,
  CampaignIcon,
  ProNormalIcon,
  ProActiveIcon,
  ChatSupportCircleIcon
} from "../commons/Icons";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";
import { resetPeopleESQuery } from "../actions/PeopleESQueryAction";

@connect((store) => ({
  login: store.LoginReducer,
  user: store.UserReducer,
  project: store.ProjectReducer,
  messageReducer: store.MessageReducer,
  invitationReducer: store.InvitationReducer,
  visitorQueueReducer: store.VisitorQueueReducer,
  notificationSettings: store.NotificationSettingsReducer,
  exception: store.ExceptionReducer,
  authTokenReducer: store.AuthTokenReducer,
  chatConfiguration: store.ChatConfigurationReducer,
  setupReducer: store.SetupReducer,
  staffReducer: store.StaffReducer,
  conversationInfoReducer: store.ConversationInfoReducer,
  chatAnsweringReducer: store.ChatConfigurationReducer,
  BroadcastMessageStatsReducer: store.BroadcastMessageStatsReducer
}))
class SidebarLayout extends Component {
  constructor(props) {
    super(props);
    console.log("The props from sidebar layout :", this.props);
    this.logout = this.logout.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.toggleUserProfileTooltip = this.toggleUserProfileTooltip.bind(this);
    this.toggleUserOptions = this.toggleUserOptions.bind(this);
    this.showInvitationPopup = this.showInvitationPopup.bind(this);
    this.handleStatusSwitch = this.handleStatusSwitch.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.markMessageFailed = this.markMessageFailed.bind(this);
    this.handleAckTimer = this.handleAckTimer.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    this.sendAvailabilityMessage = this.sendAvailabilityMessage.bind(this);
    this.handleUserStatus = this.handleUserStatus.bind(this);
    this.notificationSound = Utils.getChatAnsweringPopupAudio();
    this.playAndPauseThePopupSound = this.playAndPauseThePopupSound.bind(this);
    this.state = {
      isLScalled: false,
      showUserProfileTooltip: false,
      showUserOptions: false,
      currentStaff: {},
      isLSHidden: false,
      ackTimer: undefined,
      hideUserStatusPaths: [
        "widget",
        "widget/embed/info",
        "widget/embed/setup",
        "widget/customise/info",
        "widget/customise/setup",
        "widget/prompt/info",
        "widget/prompt/setup",
      ],
      isAcceptMessageSent: false,
      hasStatusSwitchedToAvailableOnAppLoad: false
    };
  }

  componentDidMount() {
    console.log("The props from sidebar layout :", this.props);
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("click", this.playAndPauseThePopupSound, { once: true });
    Utils.addEventListener("beforeunload", this.handleUnload);
    this.props.setSwitchStatus(this.handleStatusSwitch);
    this.handleUserStatus();
    window.addEventListener("resize", this.handleUserStatus);
    let isFullscreen = Utils.searchToObject().isFullscreen;
    if ( isFullscreen && !!isFullscreen) {
      let queryObj = Utils.searchToObject();
      this.setFullScreenClassToRoot();
      this.props.dispatch(UserActions.updateUserMode(true , queryObj));
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("beforeunload", this.handleUnload);
    window.removeEventListener("resize", this.handleUserStatus);
  }

  playAndPauseThePopupSound() {
    if (this.notificationSound) {
      this.notificationSound.play();
      this.notificationSound.pause();
    }
  }

  setFullScreenClassToRoot() {
    let rootElement = document.getElementById("root");
    if(rootElement){
      rootElement.classList.add("fullscreen");
    }
  }

  handleUnload() {
    if(this.props.conversationInfoReducer.isUnloadEventBlock){
      this.props.dispatch(ConversationInfoAction.unloadEventBlocked());
      return;
    }
    if (!this.isLoadedAsAdmin()) {
      let lastClosedTime = this.state.currentStaff.lastClosedTime || 0;
      let projectId = Utils.getProjectId(this.props.activeProjectKey);
      let isInternalUser = this.props.user.isInternalUser
        ? this.props.user.isInternalUser
        : false;
      if (this.props.user.data) {
        let url =
          Utils.getAPIBaseUrl() +
          "/staff/makeAgentOffline?userId=" +
          this.props.user.data.id +
          "&lastClosedTime=" +
          lastClosedTime +
          "&projectId=" +
          projectId +
          "&socketId=" +
          this.props.messageReducer.socketId +
          "&isInternalUser=" +
          isInternalUser;
        if (typeof navigator.sendBeacon !== "undefined") {
          let successfullySent = navigator.sendBeacon(url);
          console.info("successfullySent:", successfullySent);
        }
      }
    }
  }

  handleUserStatus() {
    let pathName = document.location.pathname;
    pathName = pathName.substring(14, pathName.length);
    let userStatusDiv = document.getElementsByClassName(
      "cs-user-status-list"
    )[0];

    if (
      (this.state.hideUserStatusPaths.includes(pathName) &&
        window.innerHeight < 780) ||
      this.props.user.isFullScreenMode
    ) {
      if (userStatusDiv && userStatusDiv.style.display != "none")
        userStatusDiv.style.display = "none";
    } else if (userStatusDiv) userStatusDiv.style.display = "";
  }

  handleClickOutside(event) {
    if (
      this.refs.user_profile_container &&
      this.refs.user_icon &&
      !this.refs.user_profile_container.contains(event.target) &&
      !this.refs.user_icon.contains(event.target) &&
      this.state.showUserProfileTooltip
    ) {
      this.setState({ showUserProfileTooltip: false, showUserOptions: false });
    } else if (
      this.refs.userOptions &&
      this.refs.showUserOptionsIcon &&
      !this.refs.userOptions.contains(event.target) &&
      !this.refs.showUserOptionsIcon.contains(event.target) &&
      this.state.showUserOptions
    ) {
      this.setState({ showUserOptions: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleAckTimer();
    this.handleUserStatus();
    ReactTooltip.rebuild();

    if (this.props.location != prevProps.location) {
      this.handleDashboardSwitch(this.props.location, prevProps.location);
    }
  }

  handleDashboardSwitch(location, prevLocation) {
    if (
      prevLocation.pathname.includes("contacts") &&
      !location.pathname.includes("contacts")
    ) {
      this.props.dispatch({ type: "RESET_NAVIGATION_SETTINGS" });
      this.props.dispatch(resetPeopleESQuery());
    }
  }

  handleAckTimer() {
    let { unackMessages = {} } = this.props.messageReducer;
    if (Object.keys(unackMessages).length) {
      if (!this.state.ackTimer) {
        let ackTimer = setInterval(this.markMessageFailed.bind(this), 1000);
        this.setState({ ackTimer });
      }
    } else {
      if (this.state.ackTimer) {
        clearInterval(this.state.ackTimer);
        this.setState({ ackTimer: undefined });
      }
    }
  }

  markMessageFailed() {
    let { unackMessages = {}, messageMap } = this.props.messageReducer;

    Object.keys(unackMessages).map((messageId) => {
      let { conversationId, retries, ackTime, meta } = unackMessages[messageId];
      let message = messageMap[conversationId][messageId];

      if (Date.now() > ackTime && retries > 2) {
        console.debug("Marking message failed...");
        this.props.dispatch(
          ConnectionAction.failMessage({
            message,
            ...unackMessages[messageId],
            retries,
            ackTime,
          })
        );
      } else if (Date.now() > ackTime) {
        console.debug("retry count:" + retries);
        retries++;
        ackTime = Date.now() + 3000;
        this.props.dispatch(
          ConnectionAction.queueUnackMessage({
            message,
            ...unackMessages[messageId],
            retries,
            ackTime,
          })
        );
        this.props.dispatch(ConnectionAction.sendMessage(message, meta));
      }
    });
  }

  componentWillMount() {
    console.log("finally:", this.props);
    let domain = window.location.hostname;

    if (!this.props.user.isFetched) this.props.dispatch(UserActions.getUser());

    if (
      this.props.staffReducer.dashboardAgents[
        Utils.getProjectId(this.props.activeProjectKey)
      ] === undefined &&
      this.props.staffReducer.isLoading != true
    )
      this.props.dispatch(
        staffListRequest(Utils.getProjectId(this.props.activeProjectKey))
      );

    if (!this.props.setupReducer.isFetched)
      this.props.dispatch(
        SetupActions.setupRequest("LS-" + this.props.activeProjectKey)
      );

    if (
      !this.props.chatConfiguration.isFetching &&
      !this.props.chatConfiguration.data.hasOwnProperty(
        "LS-" + this.props.activeProjectKey
      )
    )
      this.props.dispatch(
        ChatConfigurationAction.requestChatConfiguration(
          this.props.activeProjectKey,
          this.props.chatConfiguration.data
        )
      );

    if (!this.props.notificationSettings.isFetched)
      this.props.dispatch(NotificationSettingsAction.getNotificationSettings());

    this.props.dispatch(VoiceboxActions.hideVoicebox());
    if (!this.props.project.isFetched)
      this.props.dispatch(ProjectActions.requestAvailableProject());

    if (!this.props.authTokenReducer.isFetched)
      this.props.dispatch(requestAvailableTokens());

    this.props.dispatch(
      SetupActions.updateAppVersion(domain.indexOf(BETA_ACCESS_URL) > -1)
    );

    if (
      this.props.location.query.inviteeId &&
      !this.props.invitationReducer.isFetched
    ) {
      this.props.dispatch(
        InvitationActions.getInvitationRequest(
          this.props.location.query.inviteeId
        )
      );
    } else if (
      this.props.location.query.inviteeId &&
      this.props.invitationReducer.isFetched &&
      this.props.invitationReducer.invitation.invitationStatus == "PENDING"
    ) {
      browserHistory.push({
        pathname: this.props.location.pathname,
        search: "",
      });

      if (
        this.props.invitationReducer.invitation.invitationStatus == "PENDING"
      ) {
        this.showInvitationPopup(
          this.props.invitationReducer.invitation.key,
          this.props.invitationReducer.invitation.projectName
        );
      }
    }
    console.log("@@@@@@@@@@@@ ConnectionAction.init() @@@@@@@@@@@@@");
    this.props.dispatch(ConnectionAction.init());
  }

  sendAvailabilityMessage(nextProps) {
    let projectId = Utils.getProjectId(this.props.activeProjectKey);
    let userId = nextProps.user.data.id;
    let staffs = nextProps.staffReducer.dashboardAgents[projectId];
    if (staffs && staffs[userId]) {
      let updatedStaff = staffs[userId];
      let takingChatStatus = updatedStaff.takingChatStatus;
      let isTakingChat = TAKING_CHAT_STATUSES.includes(takingChatStatus)
        ? true
        : false;
      // if( updatedStaff.role != "RESTRICTED_MEMBER" ) {
      let availabilityMessage = Utils.getAvailabilityMessage(
        isTakingChat,
        updatedStaff.key,
        projectId
      );
      this.props.dispatch(
        ConnectionAction.sendMessage(availabilityMessage, {
          triggerWebHook: true,
        })
      );
      //}
    }
  }

  componentWillReceiveProps(nextProps) {
    //Update the routing availability presence realtime when a staff is newly added to the project
    if (
      this.props.messageReducer.updateAvailability == false &&
      nextProps.messageReducer.updateAvailability == true
    ) {
      this.props.dispatch(ConnectionAction.updateAvailabilityResponse());
      let currentStaff = this.state.currentStaff;
      let projectId = Utils.getProjectId(this.props.activeProjectKey);
      let presenceUserId = this.props.user.isInternalUser
        ? currentStaff.key
        : projectId + "-" + currentStaff.key;
      this.props.dispatch(
        fetchAndUpdateUserPresence(
          presenceUserId,
          currentStaff.takingChatStatus,
          currentStaff.isTakingChat,
          projectId
        )
      );
    }

    if (
      !this.props.setupReducer.setupState &&
      nextProps.setupReducer.setupState
    ) {
      // if( nextProps.setupReducer.setupState.isEmbedded == false )
      // 	browserHistory.push( {  pathname : "/app/"+nextProps.activeProjectKey+"/widget/embed/info" } );
    }

    //Remove client token from cookie on logout
    if (!nextProps.login.isLoggedIn && !nextProps.login.clientToken) {
      //Remove client token from cookie
      cookie.remove(CLIENT_LOGIN_REFERENCE, { path: "/" });
      // let logoutUrl = Utils.getAppMode() === 'live' ? LIVE_LOGOUT_URL : STAGING_LOGOUT_URL;
      // window.location.href = logoutUrl + Utils.getOrigin()+"/login";
    }

    if (
      this.props.invitationReducer.isFetching &&
      nextProps.invitationReducer.isFetched
    ) {
      let inviteeId = this.props.location.query.inviteeId;
      browserHistory.push({
        pathname: this.props.location.pathname,
        search: "",
      });

      if (
        nextProps.invitationReducer.invitation.invitationStatus == "PENDING"
      ) {
        this.showInvitationPopup(
          nextProps.invitationReducer.invitation.key,
          nextProps.invitationReducer.invitation.projectName
        );
      }
    }
    let projectKey = nextProps.user.selectedProjectKey
      ? nextProps.user.selectedProjectKey
      : nextProps.activeProjectKey;
    let oldProjectKey = this.props.user.selectedProjectKey
      ? this.props.user.selectedProjectKey
      : this.props.activeProjectKey;
    if (Utils.isValidString(projectKey) && projectKey != oldProjectKey) {
      this.props.dispatch(
        SetupActions.setupRequest("LS-" + nextProps.activeProjectKey)
      );
      //Reconnect socket on project change only for external users.
      this.props.dispatch(BroadcastActions.markFetched(false));

      if (!this.props.user.isInternalUser) {
        this.props.dispatch(ConnectionAction.closeConnection());

        setTimeout(() => {
          console.info("Reconnecting sidebar...");
          this.props.dispatch(ConnectionAction.reconnect());
          this.setState(prevState => ({...prevState, hasStatusSwitchedToAvailableOnAppLoad: false }));
        }, 2000);
      }
    }

    if (
      nextProps.project.isFetched &&
      nextProps.project.projectList.filter(
        (project) => project.key == "LS-" + nextProps.activeProjectKey
      ).length <= 0
    ) {
      let projectList = nextProps.project.projectList;
      if (projectList.length > 0) {
        let path = nextProps.location.pathname;
        path = path.split("/");
        path[2] = projectList[0].key.replace("LS-", "");
        browserHistory.push({ pathname: path.join("/") });
      } else this.logout();
    }
    if (
      nextProps.staffReducer.dashboardAgents[
        Utils.getProjectId(this.props.activeProjectKey)
      ] !== undefined &&
      nextProps.staffReducer.isLoading != true &&
      nextProps.user.data != undefined
    ) {
      let projectId = Utils.getProjectId(this.props.activeProjectKey);
      let staffReducer = nextProps.staffReducer;
      let userDetails = nextProps.user.data;
      let currentStaff =
        staffReducer.dashboardAgents[projectId][userDetails.id] || {};
      if (!this.state.hasStatusSwitchedToAvailableOnAppLoad)
        this.setState(prevState => ({ ...prevState, hasStatusSwitchedToAvailableOnAppLoad: currentStaff.takingChatStatus === AVAILABILITY_STATUS.AVAILABLE }));
      if (Utils.shouldSwitchStatusToAvailable(this.props, currentStaff, this.state.hasStatusSwitchedToAvailableOnAppLoad)) {
        this.handleStatusSwitch(AVAILABILITY_STATUS.AVAILABLE, currentStaff.key);
        this.setState(prevState => ({ ...prevState, hasStatusSwitchedToAvailableOnAppLoad: true }));
      }
      this.setState({ currentStaff });
    }
    if (
      !nextProps.chatConfiguration.isFetching &&
      !nextProps.chatConfiguration.data.hasOwnProperty(
        "LS-" + nextProps.activeProjectKey
      )
    )
      nextProps.dispatch(
        ChatConfigurationAction.requestChatConfiguration(
          nextProps.activeProjectKey,
          nextProps.chatConfiguration.data
        )
      );

    this.checkAndSubscribeAgents(nextProps, this.props);

    //this.checkAndFetchPrecenseDetails( nextProps , this.props);

    if (
      this.props.user.data &&
      this.props.user.data.isAdmin === "true" &&
      typeof _ls !== "undefined" &&
      !this.state.isLSHidden
    ) {
      this.setState({ isLSHidden: true });
      _ls("showCsWidget", false);
    }

    if (
      nextProps.messageReducer.isSubscribedToChannels &&
      !nextProps.messageReducer.isAcceptedMessageSent && 
      nextProps.user.isFullScreenMode
    ){
      console.log("Sending accepted from sidebar");
      this.sendAcceptedMessage(nextProps);
    }

  }

  checkAndSubscribeAgents(nextProps, props) {
    console.log("The next props before subscribing :", nextProps);
    console.log("The props before subscribing :", props);
    if (
      nextProps.messageReducer.isConnected &&
      nextProps.project.isFetched &&
      !nextProps.messageReducer.isSubscribingToChannels &&
      !nextProps.messageReducer.isSubscribedToChannels &&
      nextProps.user.isInternalUser !== undefined && 
      nextProps.messageReducer.socketId
    ) {
      let isLoadedAsAdmin =
        this.props.user.data && this.props.user.data.originalId != null;
      let { projectList } = nextProps.project;
      let projectIds = projectList.map((project) => project.key);
      nextProps.dispatch(
        ConnectionAction.subscribe({
          socketId: nextProps.messageReducer.socketId,
          projectId: "LS-" + nextProps.activeProjectKey,
          projectIds: projectIds,
          isAdminSubscription: isLoadedAsAdmin,
        })
      );
    }
  }

  checkAndSubscribeAgentToOverflow(nextProps) {
    console.log("Coming to subscribe for overflow : ", nextProps);
    console.log("Coming to subscribe for overflow state : ", this.state);
    if (
      nextProps.messageReducer.isConnected &&
      !this.state.isSubscriptionRequested
    ) {
      console.log("going to subscribe for overflow : ", nextProps);
      let agentInfo = {};
      agentInfo.key = nextProps.location.query.agent_id;
      agentInfo.userId = nextProps.location.query.agent_id;
      agentInfo.firstName = nextProps.location.query.agent_first_name;
      agentInfo.lastName = nextProps.location.query.agent_last_name;
      agentInfo.name = agentInfo.firstName + " " + agentInfo.lastName;
      agentInfo.photoUrl = nextProps.location.query.agent_photo_url;
      agentInfo.agentLogin = nextProps.location.query.agent_login;
      agentInfo.login = nextProps.location.query.agent_login;
      agentInfo.messageFrom = "AGENT";
      agentInfo.projectId = nextProps.location.query.project_id;
      agentInfo.contactId = nextProps.location.query.agent_id;
      agentInfo.aliasName = nextProps.location.query.alias_name;

      console.log("The agent Info :", agentInfo);
      const mode = Utils.getAppMode() === "live" ? "live" : "staging";
      let { isNondefault, nonDefaulthost } = Utils.getWebhookVersion();
      if (isNondefault) {
        agentInfo.nonDefaultHooks =
          Utils.constructNonDefaultHooks(nonDefaulthost);
        agentInfo.apiKey = Utils.getApiKey(mode);
      } else {
        console.log("Its loading in default version ");
      }

      this.props.dispatch(
        ConnectionAction.subscribe({
          isFromOverflow: true,
          apiKey: Utils.getOverflowApiKey(mode),
          interactionId: nextProps.location.query.connection_id,
          socketId: nextProps.messageReducer.socketId,
          agentInfo: agentInfo,
        })
      );
      this.setState(prevState => ({...prevState, isSubscriptionRequested: true}));
    }
  }

  //call this method for logout button click
  logout(e) {
    if (e) e.preventDefault();
    this.props.dispatch(ConnectionAction.destroyRtmServer());
    this.props.dispatch(LoginActions.logout());
  }

  refreshPage() {
    window.location.reload();
  }

  toggleUserProfileTooltip() {
    this.setState({
      ...this.state,
      showUserProfileTooltip: !this.state.showUserProfileTooltip,
    });
  }
  toggleUserOptions() {
    this.setState({
      ...this.state,
      showUserOptions: !this.state.showUserOptions,
    });
  }

  showInvitationPopup(invitationId, projectName) {
    let popupProps = {
      okButton: {
        text: "Accept",
        action: InvitationActions.acceptInvitationRequest(invitationId),
      },
      cancelButton: {
        text: "Decline",
        action: InvitationActions.declineInvitationRequest(invitationId),
        display: true,
      },
      popupHeader: "Project Invitation",
      popupContent:
        "<p>Do you want to be part of " + projectName + " project?</p>",
      closeOnOkButton: true,
    };

    this.props.dispatch(PopupActions.showPopup({ popupProps }));
  }

  getFullName() {
    if (this.props.user.isFetched)
      return (
        (this.props.user.data.firstName ? this.props.user.data.firstName : "") +
        " " +
        (this.props.user.data.lastName ? this.props.user.data.lastName : "")
      );
    return "";
  }

  handleStatusSwitch(takingChatStatus, userId) {
    let projectId = Utils.getProjectId(this.props.activeProjectKey);
    let currentStaff = this.state.currentStaff;
    userId = userId || currentStaff.key;
    takingChatStatus = takingChatStatus || currentStaff.takingChatStatus;

    let isTakingChat = TAKING_CHAT_STATUSES.includes(takingChatStatus)
      ? true
      : false;

    let presenceUserId = this.props.user.isInternalUser
      ? userId
      : projectId + "-" + userId;
    this.props.dispatch(
      fetchAndUpdateUserPresence(
        presenceUserId,
        takingChatStatus,
        isTakingChat,
        projectId
      )
    );
  }

  togglePlanUpgradePopup() {
    this.props.dispatch(UserActions.togglePlanUpgradePopup());
  }

  getMenuBasedOnSkill() {
    let isSetupExpired = this.props.setupReducer.setupState
      ? this.props.setupReducer.setupState.expired
      : true;
    isSetupExpired = Utils.getUserPermissionUtility("ProjectSetUp")
      ? isSetupExpired
      : true;
    let projectKey =
      this.props.user.isInternalUser && this.props.user.selectedProjectKey
        ? this.props.user.selectedProjectKey
        : this.props.activeProjectKey;
    let subpath = document.location.pathname;
    subpath = subpath.substring(14, subpath.length);
    let showUpgradePopup = this.props.user.showUpgradePopup;

    console.info("projectKey:" + projectKey);

    return (
      <ul>
        <li class="cs-logo">
          <a href="javascript:void(0)">
            <img src={Utils.getIconUrl(document.location.origin, true)} />
          </a>
        </li>
        <li>
          <Link
            onClick={() => {
              ReactTooltip.hide();
              Event(
                "LS-" + projectKey,
                "Tabs",
                "Conversation",
                "Conversation Page"
              );
            }}
            data-tip="Conversations"
            data-class="tooltip info"
            data-delay-hide={0}
            to={
              location.pathname.includes("chat")
                ? location.pathname + location.search
                : "/app/" + projectKey + "/chat"
            }
            activeClassName={!showUpgradePopup ? "active" : ""}
          >
            <ChatIcon />
            <ChatActiveIcon />
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              ReactTooltip.hide();
              Event("LS-" + projectKey, "Tabs", "Settings", "Settings Page");
            }}
            data-tip="Settings"
            data-class="tooltip info"
            data-delay-hide={0}
            to={"/app/" + projectKey + "/settings"}
            activeClassName={!showUpgradePopup ? "active" : ""}
          >
            <SettingsIcon />
            <SettingsActiveIcon />
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              ReactTooltip.hide();
              Event(
                "LS-" + projectKey,
                "Tabs",
                "Team Members",
                "Team Members Page"
              );
            }}
            data-tip="Team Members"
            data-class="tooltip info"
            data-delay-hide={0}
            to={"/app/" + projectKey + "/directory"}
            activeClassName={!showUpgradePopup ? "active" : ""}
          >
            <DirectoryIcon />
            <DirectoryActiveIcon />
          </Link>
        </li>
        {/**<li>
						<Link onClick={()=>{ReactTooltip.hide();Event("LS-" + projectKey,"Tabs","People","People Page" )}}  data-tip="Contacts" data-class="tooltip info" data-delay-hide={0} to={"/app/"+projectKey+"/people"} activeClassName="active">
							<PeopleIcon />
							<PeopleActiveIcon/>
						</Link>
                    </li>**/}
        <li>
          <Link
            onClick={() => {
              ReactTooltip.hide();
              Event("LS-" + projectKey, "Tabs", "People", "People Page");
            }}
            data-tip="Dashboard"
            data-class="tooltip info"
            data-delay-hide={0}
            to={"/app/" + projectKey + "/contacts/dashboard"}
            activeClassName={!showUpgradePopup ? "active" : ""}
          >
            <PeopleIcon />
            <PeopleActiveIcon />
          </Link>
        </li>
        <li
          style={{
            display: Utils.isHipaaCompliant(
              this.props.project.projectList,
              projectKey
            )
              ? "none"
              : "block",
          }}
        >
          <Link
            onClick={() => {
              ReactTooltip.hide();
              Event("LS-" + projectKey, "Tabs", "Campaign", "Campaign page");
            }}
            data-tip="Campaign"
            data-class="tooltip info"
            data-delay-hide={0}
            to={"/app/" + projectKey + "/campaign"}
            activeClassName={!showUpgradePopup ? "active" : ""}
          >
            <CampaignIcon />
            <CampaignActiveIcon />
          </Link>
        </li>
        <li style={{"cursor":"pointer", "display": ( Utils.canShowSideBarProIcon(this.props) ? "block":"none")}}>
          <Link
            onClick={() => {
              this.togglePlanUpgradePopup();
              ReactTooltip.hide();
              Event("LS-" + projectKey, "Tab", "Upgrade", "Upgrade Plan");
            }}
            data-tip="Upgrade"
            data-class="tooltip info"
            data-delay-hide={0}
            className={ showUpgradePopup ? "pro-icon active" : "pro-icon"}
          >
            <ProNormalIcon/>
            <ProActiveIcon/>
          </Link>
        </li>
        <li>
          <Link
            onClick={() => {
              ReactTooltip.hide();
              Event(
                "LS-" + projectKey,
                "Tab",
                "Project Setup",
                "Project Setup Page"
              );
            }}
            data-tip="Widget Setup"
            data-class="tooltip info"
            data-delay-hide={0}
            to={"/app/" + projectKey + "/widget/embed/setup"}
            className={
              isSetupExpired == true
                ? "setup-icon font-zero dn"
                : "setup-icon font-zero" +
                  (this.state.hideUserStatusPaths.includes(subpath)
                    ? " active"
                    : "")
            }
            activeClassName="active"
          >
            <SetupIcon />
            <SetupActiveIcon />
          </Link>
        </li>
      </ul>
    );
  }

  isLoadedAsAdmin = () => {
    return this.props.user.data && this.props.user.data.originalId != null;
  };

  setAcceptedMessageStatusToLocalStorage = (conversation_id) => {
    let acceptedStatusObj = localStorage.getItem("accepted_stauts_object");
    let parsedAcceptedStatus = {};
    if(acceptedStatusObj) {
      parsedAcceptedStatus = JSON.parse(acceptedStatusObj);
      parsedAcceptedStatus[conversation_id] = true;
    } else {
      parsedAcceptedStatus[conversation_id] = true;
    }
    
    localStorage.setItem("accepted_stauts_object" , JSON.stringify(parsedAcceptedStatus) );
  }

  getAcceptedStatusFromLocalStorage = (conversation_id) => {
    let acceptedStatusObj = localStorage.getItem("accepted_stauts_object");
    if(acceptedStatusObj) {
      let parsedAcceptedStatus = JSON.parse(acceptedStatusObj);
      return parsedAcceptedStatus[conversation_id];
    } else {
      return false;
    }
  }

  sendAcceptedMessage = (nextProps) => {
    
    let query = Utils.searchToObject();
    query = Object.keys(query).length ? query : this.props.user.fullscreenQuery;
    if (
      nextProps.messageReducer.isConnected &&
      nextProps.messageReducer.isSubscribedToChannels &&
      query && query.isFullscreen === "true" && (this.getAcceptedStatusFromLocalStorage(query.conversation_id) !== true)
    ) {
      let conversationId = query.conversation_id;
      let acceptedMessage = {};
      
        let agentInfo = {};
        agentInfo.key = query.agent_id;
        agentInfo.userId = query.agent_id;
        agentInfo.firstName = query.agent_first_name;
        agentInfo.lastName = query.agent_last_name;
        agentInfo.name = agentInfo.firstName + " " + agentInfo.lastName;
        agentInfo.photoUrl = query.agent_photo_url;
        agentInfo.agentLogin = query.agent_login;
        agentInfo.login = query.agent_login;
        agentInfo.messageFrom = "AGENT";
        agentInfo.projectId = query.project_id;
        agentInfo.contactId = query.agent_id;
        agentInfo.aliasName = query.alias_name;

        console.log("The agent Info :", agentInfo);

        let visitorId = query.visitor_id;
        acceptedMessage = {
          channel: "server/routing",
          type: MESSAGE_TYPE.chat_auto_assign_on_overflow,
          message: "",
          actionTo: agentInfo.userId,
          senderId: agentInfo.userId,
          conversationId: conversationId,
          messageFrom: "AGENT",
          messageId: Utils.generateMessageId(),
          visitorId: visitorId,
          projectId: "LS-"+agentInfo.projectId,
          timestamp: Utils.getServerTime(),
          isFullScreen : true
        };
        this.props.dispatch(
          ConnectionAction.sendMessage(acceptedMessage, {
            interactionId: query.connection_id,
          })
        );
        console.log("Accepted signal sent :" , acceptedMessage);
        this.setAcceptedMessageStatusToLocalStorage(query.conversation_id);
      
      let statusTrackerInfo = {
        connectionId: query.connection_id,
        tool: ["Overflow"],
        isConnected: true,
        loadedDate: Utils.getServerTime(),
      };

      this.props.dispatch(
        ConversationInfoAction.trackChatStatus(statusTrackerInfo)
      );
      let userId = Utils.getUserReducerFromProps(this.props.user);
      let staffMap = this.props.StaffReducer.dashboardAgents[
        Utils.getProjectId(this.state.projectKey)
      ];
    this.props.dispatch(
      ConversationInfoAction.updateConversationRealTime(acceptedMessage,userId,staffMap)
    );
    } else {
      console.log("Not Sending the acceptioned signal because messageReducer:" , nextProps.messageReducer);
      console.log("Not Sending the acceptioned signal because Search Object :" , Utils.searchToObject() );
      console.log("Not Sending the acceptioned signal because fullscreenQuery :" , this.props.user.fullscreenQuery );
      console.log("Not Sending the acceptioned signal because query :" , query );
      console.log("Not Sending the acceptioned signal because LocalStorage :" , this.getAcceptedStatusFromLocalStorage(query.conversation_id) );
    }

    
  }

  render() {
    let projectKey =
      this.props.user.isInternalUser && this.props.user.selectedProjectKey
        ? this.props.user.selectedProjectKey
        : this.props.activeProjectKey;
    console.info(
      "currentStaff render:" + JSON.stringify(this.state.currentStaff)
    );
    return (
      <aside className={Utils.isAwWindow() ? "dn" : "cs-menu"}>
        {this.getMenuBasedOnSkill()}
        <UserStatusContainer
          currentStaff={this.state.currentStaff}
          handleStatusSwitch={this.handleStatusSwitch}
          isLoadedAsAdmin={this.isLoadedAsAdmin}
          projectId={projectKey}
          isFullScreenMode={this.props.user.isFullScreenMode}
        />
      </aside>
    );
  }
}
export default SidebarLayout;
