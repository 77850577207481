import { Formik } from 'formik'
import React, { FC } from 'react'
import { TextInput } from 'src/features/form/components/TextInput'
import { Spinner } from 'src/features/ui/components/Spinner'
import { Text1 } from 'src/features/ui/components/Typography'
import {
  initialValues,
  validationSchema,
} from 'src/features/wizard/setup/forms/Preview/data'
import {
  InputContainer,
  StyledForm,
  SubmitButton,
} from 'src/features/wizard/setup/forms/Preview/styled'
import { IProps } from 'src/features/wizard/setup/forms/Preview/types'

export const PreviewForm: FC<IProps> = ({
  defaultWebsiteUrl,
  handleSubmit,
  isLoading,
}) => (
  <Formik
    onSubmit={handleSubmit}
    initialValues={{
      url: defaultWebsiteUrl ?? initialValues.url,
    }}
    validationSchema={validationSchema}
  >
    <StyledForm>
      <Text1 block>Add your website URL for a live widget preview</Text1>
      <InputContainer>
        <TextInput placeholder="https://" name="url" />
        <SubmitButton
          disabled={isLoading}
          type="submit"
          colorType="hollow"
          color="primary"
        >
          {isLoading ? <Spinner size={15} fill="dodgerBlue" /> : 'Apply'}
        </SubmitButton>
      </InputContainer>
    </StyledForm>
  </Formik>
)
