import React, { FC } from 'react'

export const Send: FC<any> = (props) => (
  <svg width="9" height="8" viewBox="0 0 9 8" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.44318 7.7301L2.67374 5.4965L4.35936 3.32472L1.86111 4.47071L0.0916748 2.23711L8.54324 0.00695801L4.44318 7.7301Z"
      fill="white"
    />
  </svg>
)
