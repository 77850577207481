import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useChatConfigurationEntity } from "../hooks/useChatConfigurationEntity";
import { useUpdateChatConfigurationEntity } from "../hooks/useUpdateChatConfigurationEntity";
import {
  PREVIEW_WIDGET_TYPES,
  DEFAULT_ICON,
  PRE_CHAT_SURVEY_STATUS,
  SURVEY_TYPE,
} from "../../commons/Constants";
import { showVoicebox } from "../../actions/VoiceboxAction";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import { WhiteTickIcon } from "../../commons/Icons";
import { usePrevious } from "../hooks/usePrevious";

const SurveyFormComponent = (props) => {
  const { isValidUser } = props;

  const projectId = "LS-" + props.projectKey;
  const {
    isConfigFetched,
    chatConfiguration: chatConfig,
    isUpdating,
  } = useChatConfigurationEntity(projectId);
  const dispatch = useDispatch();
  const updateChatConfigurationEntity = useUpdateChatConfigurationEntity();
  const [activeSurveyForm, setActiveSurveyForm] = useState(SURVEY_TYPE.PRE);
  const [isPostChatFormEnabled, setIsPostChatFormEnabled] = useState(false);
  const [showFeedbackComment, setShowFeedbackComment] = useState(false);
  let prevIsUpdating = usePrevious(isUpdating);

  useEffect(() => {
    if(isConfigFetched && !isUpdating){
      setIsPostChatFormEnabled(chatConfig.postChatSurveyEnabled);
      setShowFeedbackComment(chatConfig.showFeedbackComment);
      if(prevIsUpdating)
        return;
      if(chatConfig.postChatSurveyEnabled && chatConfig.preChatSurveyStatus === PRE_CHAT_SURVEY_STATUS.INACTIVE)
        setActiveSurveyForm(SURVEY_TYPE.POST);
      else if(!chatConfig.postChatSurveyEnabled)
        setActiveSurveyForm(SURVEY_TYPE.PRE);
    }
  },[isConfigFetched,isUpdating,prevIsUpdating]);

  const togglePreChatServey = () => {
    if (!isValidUser()) {
      dispatch(
        showVoicebox({
          message: "You should be an owner to update the chat configuration!",
          dismissAfter: 3000,
        })
      );
      return;
    }
    let chatConfiguration = Object.assign({}, chatConfig);
    if (
      chatConfiguration.preChatSurveyStatus !== PRE_CHAT_SURVEY_STATUS.ACTIVE
    ) {
      Event(projectId, "Lead Capture", "Enabled", "Enalbed");
      chatConfiguration.preChatSurveyStatus = PRE_CHAT_SURVEY_STATUS.ACTIVE;
      setActiveSurveyForm(SURVEY_TYPE.PRE);
    } else {
      Event(projectId, "Lead Capture", "Disabled", "Disabled");
      chatConfiguration.preChatSurveyStatus = PRE_CHAT_SURVEY_STATUS.INACTIVE;
      if(isPostChatFormEnabled && !chatConfig.isOverFlowEnabled)
        setActiveSurveyForm(SURVEY_TYPE.POST);
    }
    dispatch(showVoicebox({ message: "Updating" }));
    updateChatConfigurationEntity({
      key: chatConfiguration.key,
      preChatSurveyStatus: chatConfiguration.preChatSurveyStatus,
    });
  };

  const getClassName = (isPreChatSurveyEnabled) => {
    if (isPreChatSurveyEnabled) return "cs-switch-btn";
    return "cs-switch-btn off";
  };
  const getSurveyForms = () => {
    let isPreChatSurveyEnabled =
      chatConfig &&
      chatConfig.preChatSurveyStatus === PRE_CHAT_SURVEY_STATUS.ACTIVE
        ? true
        : false;

    let toRender = [];
    toRender.push(
      <section>
        <div class="cs-form-col">
          <div class="col">
            <label>Pre-chat Survey Form</label>
            <p>
              Collect user information before assigning the chat to an agent
            </p>
          </div>
          <div
            className={getClassName(isPreChatSurveyEnabled)}
            onClick={togglePreChatServey}
          ></div>
        </div>
        {getSurveyFormImage()}
        {!chatConfig.isOverFlowEnabled ? getPostChatSurveyComponent() : ""}
       {!chatConfig.isOverFlowEnabled ? <div class="pre-post-switch">
          <span className={activeSurveyForm === SURVEY_TYPE.PRE ? "active" : ""} data-type={SURVEY_TYPE.PRE} onClick={toggleSurveyType}>Pre Chat</span>
          <span className={activeSurveyForm === SURVEY_TYPE.POST ? "active" : ""}   data-type={SURVEY_TYPE.POST} onClick={toggleSurveyType}>Post Chat</span>
        </div>: ""}
      </section>
    );
    return toRender;
  };

  const toggleSurveyType = (e) => {
    let type = e.target.dataset.type;
    if(type)
      setActiveSurveyForm(type);
  }

  const getFeedbackCheckBox = () => {
    return (
      <div class="cs-checkbox-field">
        <label>
          <WhiteTickIcon />
          <input
            type="checkbox"
            name="checkbox"
            checked={showFeedbackComment}
            onClick={updatePostChatSettings}
          />
          <cite></cite>
        </label>
        <small>Show feedback comment box</small>
      </div>
    )
  }

  const updatePostChatSettings = () => {
    setShowFeedbackComment(!showFeedbackComment);
    let chatConfiguration = Object.assign({}, chatConfig);
    chatConfiguration.showFeedbackComment = !showFeedbackComment;
    if (
      !chatConfiguration.postChatFormEnabled &&
      chatConfiguration.showFeedbackComment
    ) {
      setIsPostChatFormEnabled(true);
      chatConfiguration.postChatSurveyEnabled = true;
      setActiveSurveyForm(SURVEY_TYPE.POST);
    }
    dispatch(showVoicebox({ message: "Updating" }));
    updateChatConfigurationEntity({
      key: chatConfiguration.key,
      showFeedbackComment: chatConfiguration.showFeedbackComment,
    });
  };

  const togglePostChatSurvey = () => {
    setIsPostChatFormEnabled(!isPostChatFormEnabled);
    let chatConfiguration = Object.assign({}, chatConfig);
    chatConfiguration.postChatSurveyEnabled = !isPostChatFormEnabled;
    if (chatConfiguration.showFeedbackComment) {
      setShowFeedbackComment(false);
      chatConfiguration.showFeedbackComment = false;
    }
    if(!isPostChatFormEnabled)
      setActiveSurveyForm(SURVEY_TYPE.POST);
    else
      setActiveSurveyForm(SURVEY_TYPE.PRE);
    dispatch(showVoicebox({ message: "Updating" }));
    updateChatConfigurationEntity({
      key: chatConfiguration.key,
      postChatSurveyEnabled: chatConfiguration.postChatSurveyEnabled,
    });
  };

  const getCurrentActiveForm = () => {
    if(activeSurveyForm === SURVEY_TYPE.POST && showFeedbackComment)
      return "post-chatfeedback";
    else if(activeSurveyForm === SURVEY_TYPE.POST)
      return "post-chat";
    return "";
  }

  const getSurveyFormImage = () => {
    let widgetColor = "#" + chatConfig.widgetThemeColor;
    return(
      <div
      className={"prechat-form-temp " + getCurrentActiveForm()}
      style={
        widgetColor.length < 1
          ? { display: "none" }
          : { display: "block" }
      }
    >
      <span style={{ backgroundColor: widgetColor }}>Start</span>
    </div>
    )
  }

  const getPostChatSurveyComponent = () => {
    return (
      <div class="cs-form-col">
        <div class="col">
          <label>Post-chat Survey Form</label>
          <p>Prompt user to provide feedback once the chat is resolved.</p>
        </div>
        <div className={isPostChatFormEnabled ? "cs-switch-btn" : "cs-switch-btn off"} onClick={togglePostChatSurvey}></div>
        {getFeedbackCheckBox()}
      </div>
    );
  };

  return (
    <section>
      {getSurveyForms()}
    </section>

  )
};

export default SurveyFormComponent;
