import React, { FC } from 'react'
import { CheckMark } from 'src/features/ui/components/Icon/components/CheckMark'
import { CheckMark2 } from 'src/features/ui/components/Icon/components/CheckMark2'
import { Chevron } from 'src/features/ui/components/Icon/components/Chevron'
import { Clip } from 'src/features/ui/components/Icon/components/Clip'
import { Close } from 'src/features/ui/components/Icon/components/Close'
import { Copy } from 'src/features/ui/components/Icon/components/Copy'
import { CrossedEye } from 'src/features/ui/components/Icon/components/CrossedEye'
import { Error } from 'src/features/ui/components/Icon/components/Error'
import { ErrorHollow } from 'src/features/ui/components/Icon/components/ErrorHollow'
import { Eye } from 'src/features/ui/components/Icon/components/Eye'
import { Google } from 'src/features/ui/components/Icon/components/Google'
import { Logo } from 'src/features/ui/components/Icon/components/Logo'
import { LogoMini } from 'src/features/ui/components/Icon/components/LogoMini'
import { Next } from 'src/features/ui/components/Icon/components/Next'
import { Plugin } from 'src/features/ui/components/Icon/components/Plugin'
import { Plus } from 'src/features/ui/components/Icon/components/Plus'
import { Send } from 'src/features/ui/components/Icon/components/Send'
import { ServerError } from 'src/features/ui/components/Icon/components/ServerError'
import { ServerSuccess } from 'src/features/ui/components/Icon/components/ServerSuccess'
import { Smiley } from 'src/features/ui/components/Icon/components/Smiley'
import { Spinner } from 'src/features/ui/components/Icon/components/Spinner'
import { Widget } from 'src/features/ui/components/Icon/components/Widget'
import { theme } from 'src/features/ui/styles/theme'

const iconsMap = {
  checkMark: CheckMark,
  checkMark2: CheckMark2,
  chevron: Chevron,
  clip: Clip,
  close: Close,
  copy: Copy,
  crossedEye: CrossedEye,
  error: Error,
  errorHollow: ErrorHollow,
  eye: Eye,
  google: Google,
  logo: Logo,
  logoMini: LogoMini,
  next: Next,
  plugin: Plugin,
  plus: Plus,
  send: Send,
  serverSuccess: ServerSuccess,
  smiley: Smiley,
  serverError: ServerError,
  spinner: Spinner,
  widgetType: Widget,
}

export interface IProps {
  type: keyof typeof iconsMap
  width?: number
  height?: number
  fill?: keyof typeof theme.color
}

export const Icon: FC<IProps> = ({ type, width, height, fill, ...props }) => {
  const Component = iconsMap[type]

  return (
    <Component
      {...(fill ? { fill: theme.color[fill] } : undefined)}
      height={height}
      width={width}
      {...props}
    />
  )
}

Icon.defaultProps = {
  width: 24,
  height: 24,
}
