import * as BroadcastMessageStatsActions from "../actions/BroadcastMessageStatsAction.js";

const replaceElement = (sourceArray, key, elementToReplace) => {
  sourceArray[
    sourceArray
      .map((element, index) => [index, element])
      .filter((element) => element[1].key == key)[0][0]
  ] = elementToReplace;

  return sourceArray;
};

export const initialState = {
  isFetching: false,
  isFetched: false,
  broadcastMessageEntities: [],
  searchValue: "",
  selectedCampaign: {},
  campaignType: "CHAT",
  isCreatingCampaign: false,
  previewType: "Mobile",
  isUpdating: false,
  isUpdated: false,
  campaignPopupProjectIds: [],
  isCampaignFromDashboard : false,
  campaignSideBarType: null,
  isProcessingCampaign: false,
};

const BroadcastMessageStatsReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    broadcastMessageEntities: [],
    searchValue: "",
    selectedCampaign: {},
    campaignType: "CHAT",
    isCreatingCampaign: false,
    previewType: "Mobile",
    isUpdating: false,
    isUpdated: false,
    activeProjectId : '',
    campaignPopupProjectIds: [],
    isCampaignFromDashboard : false,
    campaignSideBarType: null,
    isProcessingCampaign: false,
  },
  action
) => {
  switch (action.type) {
    case BroadcastMessageStatsActions.FETCH_BROADCAST_MESSAGE_STATS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };

    case BroadcastMessageStatsActions.FETCH_BROADCAST_MESSAGE_STATS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        fetchedProjectId : action.projectKey,
        broadcastMessageEntities: action.broadcastMessageStatsResponse,
      };
    case BroadcastMessageStatsActions.ADD_CAMPAIGN:
      let broadcasts = [];
      if (action.isUpdate) {
        broadcasts = updateCampaign(state.broadcastMessageEntities, action.campaign)
      } else {
        if (
          !state.broadcastMessageEntities?.[0] ||
          action.campaign.key !== state.broadcastMessageEntities?.[0].key
        )
          state.broadcastMessageEntities.unshift(action.campaign);
        broadcasts = state.broadcastMessageEntities;
      }
      return {
        ...state,
        broadcastMessageEntities: broadcasts
      };
    case BroadcastMessageStatsActions.ADD_SELECTED_CAMPAIGN:
      return {
        ...state,
        selectedCampaign: Object.assign({}, action.selectedCampaign),
      };

    case BroadcastMessageStatsActions.UPDATE_CAMPAIGN_TYPE:
      return {
        ...state,
        campaignType: action.campaignType,
        isCreatingCampaign: true,
      };
    case BroadcastMessageStatsActions.UPDATE_PREVIEW_TYPE:
      return {
        ...state,
        previewType: action.previewType,
      };

    case BroadcastMessageStatsActions.UPDATE_CAMPAIGN_STATUS_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };

    case BroadcastMessageStatsActions.UPDATE_CAMPAIGN_STATUS_RESPONSE:
      let response = action.updateResponse;
      let updateBroadcastEntites = replaceElement(
        state.broadcastMessageEntities,
        response.key,
        response
      );
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        broadcastMessageEntities: updateBroadcastEntites,
      };
    case BroadcastMessageStatsActions.SEND_TEST_BROADCAST_EMAILS_REQUEST:
      return {
        ...state,
        selectedCampaign: {
          ...state.selectedCampaign,
          isTestMailSending: true,
          isTestMailSent: false,
        },
      };
    case BroadcastMessageStatsActions.REMOVE_CAMPAIGN:
    case BroadcastMessageStatsActions.DELETE_CAMPAIGN:
      let filteredBroadcastEntities = state.broadcastMessageEntities.filter(entity=>entity.key!=action.campaignId)
      return {
        ...state,
        broadcastMessageEntities: filteredBroadcastEntities,
      };
  
    case BroadcastMessageStatsActions.SEND_TEST_BROADCAST_EMAILS_RESPONSE:
      return {
        ...state,
        selectedCampaign: {
          ...state.selectedCampaign,
          isTestMailSending: false,
          isTestMailSent: true,
        },
      };
    case BroadcastMessageStatsActions.ADD_CAMPAIGN_POPUP_PROJECTID:
      return {
        ...state,
        campaignPopupProjectIds: [
          ...state.campaignPopupProjectIds,
          action.projectKey,
        ],
      };

    case BroadcastMessageStatsActions.MARK_FETCHED:
      return {
        ...state,
        isFetched: action.isFetched,
        broadcastMessageEntities: [],
      };

      case BroadcastMessageStatsActions.UPDATE_CAMPAIGN_CONTENT:
        return {
          ...state,
          selectedCampaign: {
            ...state.selectedCampaign,
            isContentUpdated: true,
          },
        }
    case BroadcastMessageStatsActions.CAMPAIGN_FROM_DASHBOARD:
      return {
        ...state,
        isCampaignFromDashboard: action.isCampaignFromDashboard,
      }
   case BroadcastMessageStatsActions.CAMPAIGN_SIDEBAR_TYPE:
      return {
        ...state,
        campaignSideBarType: action.sideBar
      }   
    case BroadcastMessageStatsActions.UPDATE_CAMPAIGN_STATS_COUNT:
      return {
        ...state,
        broadcastMessageEntities: updateBroadcastMessageStats(state.broadcastMessageEntities, action.campaignStats),
      };

    case BroadcastMessageStatsActions.UPDATE_CAMPAIGN:
      return {
        ...state,
        broadcastMessageEntities: updateCampaign(
          state.broadcastMessageEntities,
          action.campaign
        ),
        isUpdated: true,
        isUpdating: false,
      };

    case BroadcastMessageStatsActions.UPDATING_CAMPAIGN:
      return {
        ...state,
        isUpdated: false,
        isUpdating: true,
      };

    case BroadcastMessageStatsActions.PROCESSING_CAMPAIGN:
      return {
        ...state,
        isProcessingCampaign: action.isProcessing,
      };

    default:
      return state;
  }
};

const updateBroadcastMessageStats = (
  broadcastMessageEntities,
  campaignStats
) => {
  return broadcastMessageEntities.map((campaign) => {
    if (campaignStats[campaign.key]) {
      let {
        deliveredCount,
        openCount,
        repliedCount,
        clickedCount,
        notDeliveredCount,
      } = campaignStats[campaign.key];
      campaign.numberOfDeliveredMsgs = deliveredCount || 0;
      campaign.numberOfOpenedMsgs = openCount || 0;
      campaign.numberOfRepliedMsgs = repliedCount || 0;
      campaign.numberOfClickedMsgs = clickedCount || 0;
      campaign.numberOfNotDeliveredMsgs = notDeliveredCount || 0;
    }
    return campaign;
  });
};

const updateCampaign = (broadcastMessageEntities, campaignTobeUpdated) => {
  return broadcastMessageEntities.map((campaign) => {
    if (campaign.key == campaignTobeUpdated.key) {
      campaign = campaignTobeUpdated;
    }
    return campaign;
  });
};

export default BroadcastMessageStatsReducer;
