export const INCREMENT_NEW_MESSAGE_COUNT = "INCREMENT_NEW_MESSAGE_COUNT";
export const DECREMENT_NEW_MESSAGE_COUNT = "DECREMENT_NEW_MESSAGE_COUNT";
export const CLEAR_NEW_MESSAGE_COUNT = "CLEAR_NEW_MESSAGE_COUNT";
export const CLEAR_ALL_NEW_MESSAGE_COUNT = "CLEAR_ALL_NEW_MESSAGE_COUNT";
export const SCROLL_TO_NEW_MESSAGE = "SCROLL_TO_NEW_MESSAGE";

export const incrementNewMessageCount = (conversationId, messageId) => ({
  type: INCREMENT_NEW_MESSAGE_COUNT,
  conversationId,
  messageId,
});

export const decrementNewMessageCount = (conversationId, messageId) => ({
  type: DECREMENT_NEW_MESSAGE_COUNT,
  conversationId,
  messageId,
});

export const clearNewMessageCount = (conversationId) => ({
  type: CLEAR_NEW_MESSAGE_COUNT,
  conversationId,
});

export const clearAllNewMessageCount = () => ({
  type: CLEAR_ALL_NEW_MESSAGE_COUNT,
});

export const scrollToNewMessage = (conversationId) => ({
  type: SCROLL_TO_NEW_MESSAGE,
  conversationId,
});
