import React, { FC } from 'react'
import { connect } from 'react-redux'
import { selectWidgetId } from 'src/features/authorization/store/selectors'
import { Header } from 'src/features/ui/components/Header'
import { Breadcrumb } from 'src/features/wizard/shared/components/Breadcrumb'
import { steps } from 'src/features/wizard/shared/components/Breadcrumb/data'
import { BreadcrumbMobile } from 'src/features/wizard/shared/components/BreadcrumbMobile'
import { IProps } from 'src/features/wizard/shared/components/WizardHeader/types'
import { IAppState } from 'src/utils/state/store'

export const WizardHeaderComponent: FC<IProps> = ({
  widgetId,
  currentStep,
  currentStepMobile,
}) => (
  <Header hasShadow>
    <Breadcrumb steps={steps(widgetId)} currentStep={currentStep} />
    {Boolean(currentStepMobile) && (
      <BreadcrumbMobile stepsTotal={2} currentStep={currentStepMobile!} />
    )}
  </Header>
)

const mapStateToProps = (state: IAppState) => ({
  widgetId: selectWidgetId(state.auth),
})

export const WizardHeader = connect(mapStateToProps)(WizardHeaderComponent)
