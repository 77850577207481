import React, { FC } from 'react'

export const Copy: FC<any> = ({ fill, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1497 5.16707C15.0712 3.9571 14.0649 3 12.8351 3H5.31959L5.16707 3.00493C3.9571 3.08347 3 4.08976 3 5.31959V12.8351L3.00493 12.9876C3.08347 14.1975 4.08976 15.1546 5.31959 15.1546H6.15464L6.24277 15.1487C6.55978 15.1057 6.80412 14.834 6.80412 14.5052C6.80412 14.1465 6.51334 13.8557 6.15464 13.8557H5.31959L5.20838 13.8497C4.69702 13.7943 4.29897 13.3611 4.29897 12.8351V5.31959L4.30496 5.20838C4.36037 4.69702 4.79349 4.29897 5.31959 4.29897H12.8351L12.9463 4.30496C13.4576 4.36037 13.8557 4.79349 13.8557 5.31959V6.15464L13.8616 6.24277C13.9046 6.55978 14.1763 6.80412 14.5052 6.80412C14.8639 6.80412 15.1546 6.51334 15.1546 6.15464V5.31959L15.1497 5.16707ZM18.4948 8.84536H11.3505C9.96696 8.84536 8.84536 9.96696 8.84536 11.3505V18.4948C8.84536 19.8784 9.96696 21 11.3505 21H18.4948C19.8784 21 21 19.8784 21 18.4948V11.3505C21 9.96696 19.8784 8.84536 18.4948 8.84536ZM11.3505 10.1443H18.4948C19.161 10.1443 19.701 10.6844 19.701 11.3505V18.4948C19.701 19.161 19.161 19.701 18.4948 19.701H11.3505C10.6844 19.701 10.1443 19.161 10.1443 18.4948V11.3505C10.1443 10.6844 10.6844 10.1443 11.3505 10.1443Z"
          fill={fill ?? 'white'}
        />
      </g>
    </g>
  </svg>
)
