/**
 * Created by melvin on 11/04/17.
 */
import {    STAFF_LIST_REQUESTED,
    STAFF_LIST_RESPONSE,
    STAFF_LIST_FAILED,
    DELETE_STAFF_REQUESTED,
    DELETE_STAFF_RESPONSE,
    DELETE_STAFF_FAILED,
    UPDATE_STAFF_ROLE_FAILED,
    UPDATE_STAFF_ROLE_RESPONSE,
    UPDATE_STAFF_ROLE_REQUESTED,
    UPDATE_STAFF_OPEN_CONVERSATION_COUNT_RESPONSE,
    UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED,
    UPDATE_STAFF_CONVERSATION_COUNT_FAILED,
    UPDATE_STAFF_WHEN_USER_UPDATE,
    DELETE_ACCOUNT_STAFF_REQUESTED,
    DELETE_ACCOUNT_STAFF_RESPONSE,
    UPDATE_CURRENT_STAFF_PROJECT_ID
} from '../actions/StaffAction'
import {
  MESSAGE_TYPE,
  MESSAGE_FROM,
  DASHBOARD_ROLE,
  DASHBOARD_SKILL,
} from "../commons/Constants";
import { incrementConversationCount , decrementConversationCount , getArrayDifference, validateConnectAndChatconsolePresenceUpdate  } from '../commons/Utility'
import { store } from '../app'        
import {UPDATE_CONTACT_PRESENCE } from '../actions/ContactInfoAction'
import {UPDATE_STAFF_OPEN_CONVERSATION_COUNT_REALTIME } from '../actions/StaffAction'
import { isValidObject } from '../commons/Utility';

export const initialState = {
  activeProjectId: "",
  isFetchFailed: false,
  isLoading: false,
  isLoaded: false,
  isDeleting: false,
  isDeleted: false,
  isUpdating: false,
  isUpdated: false,
  dashboardAgents: {},
  overflowAgents: {},
  accountStaffs: {},
  currentStaffStatus: {},
  currentStaffProjectId: "",
};
const StaffListReducer		=	( state = initialState , action,  ) => {
switch( action.type )
{
case STAFF_LIST_REQUESTED:
    return {
        ...state,
        isLoaded : false,
        isLoading : true,
        activeProjectId : action.projectId
    }
case STAFF_LIST_RESPONSE:
{
    let dashboardAgents = {}
    if(state.dashboardAgents[action.projectId])
    {
        dashboardAgents = { ...state.dashboardAgents[action.projectId], ...action.dashboardAgents }
    }
    else
    {
        dashboardAgents = action.dashboardAgents
    }

    if( isValidObject( action.dashboardAgents ) ) {
            
       Object.entries( action.dashboardAgents ).forEach( entry => {

            let staff = entry[1];
            if( staff.photoUrl ) staff.photoUrl = staff.photoUrl + "?=" + new Date().getTime();
       });
    }

    return {
        ...state,
        isLoaded : true,
        isLoading : false,
        dashboardAgents : {
                                ...state.dashboardAgents,
                                [ action.projectId ] : dashboardAgents

        },
        accountStaffs : Object.assign( state.accountStaffs , dashboardAgents),
        overflowAgents : { ...state.overflowAgents, ...action.overflowAgents }
    }
}
case STAFF_LIST_FAILED :
    return {
        ...state,
        isLoaded:false,
        isLoading:false,
        isFetchFailed : true,
    }
case DELETE_STAFF_REQUESTED:
    return{
        ...state,
        isDeleting:true,
        isDeleted:false
    }
case DELETE_STAFF_RESPONSE:
{
    let staffId = action.staffId;
    let dashboardAgents = Object.assign({}, state.dashboardAgents);
    let userId    =  store.getState().UserReducer.data.id;

    if(userId == action.staffId && state.activeProjectId == action.projectId)
    {
        delete dashboardAgents[action.projectId];
    }
    else if(dashboardAgents[action.projectId] && dashboardAgents[action.projectId][staffId])
    {
        dashboardAgents[action.projectId][staffId].status = "INACTIVE";
    }
    return{
        ...state,
        isDeleted:true,
        isDeleting:false,
        dashboardAgents : dashboardAgents
    }
}
case DELETE_ACCOUNT_STAFF_REQUESTED:
    return{
        ...state,
        isDeleting:true,
        isDeleted:false
    }
case DELETE_ACCOUNT_STAFF_RESPONSE:
{
    let staffId = action.staffId;
    let dashboardAgents = Object.assign({}, state.dashboardAgents);
    let accountStaffs = Object.assign({}, state.accountStaffs);
    let userId    =  store.getState().UserReducer.data.id;

    // if(userId == action.staffId && state.activeProjectId == action.projectId)
    // {
    //     delete dashboardAgents[action.projectId];
    // }

    if(dashboardAgents[action.projectId] && dashboardAgents[action.projectId][staffId])
    {
        dashboardAgents[action.projectId][staffId].status = "INACTIVE";
    }
    if(accountStaffs && accountStaffs[staffId])
    {
        accountStaffs[staffId].status = "INACTIVE";
    }

    return{
        ...state,
        isDeleted:true,
        isDeleting:false,
        dashboardAgents : dashboardAgents
    }
}
case DELETE_STAFF_FAILED:
    return{
        ...state,
        isDeleted:false,
        isDeleting:false
    }
case UPDATE_STAFF_ROLE_REQUESTED:
    return {
        ...state,
        isUpdated:false,
        isUpdating:true
    }
case UPDATE_STAFF_ROLE_RESPONSE:
{
    let projectId = action.staff.projectId;
    delete action.staff.projectId;
    let dashboardAgents = state.dashboardAgents[projectId]
    let staff = {}
    if(dashboardAgents && dashboardAgents[action.staff.key])
    {
        staff = dashboardAgents[action.staff.key];
        staff = { ...staff, ...action.staff };
    }
    else
    {
        staff = action.staff;
    }

    return {
        ...state,
        isUpdated:true,
        isUpdating:false,
        dashboardAgents : {
                            ...state.dashboardAgents,
                            [ projectId ] : { 
                                                        ...state.dashboardAgents[projectId], 
                                                        [staff.key] : staff 
                                                    }
        }
    }
}
case UPDATE_STAFF_ROLE_FAILED :
    return {
        ...state,
        isUpdating:false,
        isUpdated:false
    }
case UPDATE_STAFF_CONVERSATION_COUNT_FAILED :
    return {
        ...state,
        isUpdating:false,
        isUpdated:false
    }
case UPDATE_STAFF_CONVERSATION_COUNT_REQUESTED : 
    return {
        ...state,
        isUpdating:true,
        isUpdated:false
    }
case UPDATE_STAFF_OPEN_CONVERSATION_COUNT_RESPONSE : 
{
    if(action.data)
    {
        let dashboardAgents = Object.assign({}, state.dashboardAgents[action.data.projectKey]);

        for(let key in dashboardAgents)
        {
            let staff = dashboardAgents[key];
            if( action.data.userid = staff.key )
                staff.mentionedConversationCount = action.data.mentionedConversationCount;

            staff.openConversationCount = action.data.staffCountMap[ staff.key ] != undefined ? action.data.staffCountMap[ staff.key ] : 0;
            dashboardAgents[key] = staff;
        }
        
    return {
            ...state,
            dashboardAgents :   { 
                                    ...state.dashboardAgents,
                                    [ action.data.projectKey ] : dashboardAgents
                                },
            isUpdated:true,
            isUpdating:false
            
        }
    }
}
case UPDATE_STAFF_OPEN_CONVERSATION_COUNT_REALTIME :
{
        let message         =  action.message;
        let projectId      =  state.activeProjectId;    
        let conversation    =  action.conversation
        let dashboardAgents =  Object.assign({}, state.dashboardAgents);
        dashboardAgents     =  dashboardAgents[ projectId ] || {};

        if(conversation)
        {
            switch( message.type )
            {
                case MESSAGE_TYPE.chat_auto_assigned_on_reply :
                    incrementConversationCount( dashboardAgents , message.actionTo );
                    break;
                case MESSAGE_TYPE.chat_closed :
                    decrementConversationCount( dashboardAgents , conversation.assignedTo , conversation.mentionedStaffs );
                    break;
                case MESSAGE_TYPE.chat_reopen :
                    let userId = message.actionTo ? message.actionTo : conversation.assignedTo;
                    incrementConversationCount( dashboardAgents , userId , conversation.mentionedStaffs );
                    break;
                case MESSAGE_TYPE.chat_unassigned :
                    if( conversation.chatStatus == "OPEN" )
                        decrementConversationCount( dashboardAgents , message.actionTo , conversation.mentionedStaffs );
                    break;
                case MESSAGE_TYPE.chat_assigned :
                case MESSAGE_TYPE.chat_assigned_on_connect :
                    if( conversation.chatStatus == "OPEN" )
                    {
                        if( conversation.assignedTo )
                            decrementConversationCount( dashboardAgents , conversation.assignedTo );
        
                        incrementConversationCount( dashboardAgents , message.actionTo );
                    }
                    break;
                case MESSAGE_TYPE.initiated_new_conversation : 
                    incrementConversationCount( dashboardAgents , message.senderId );
                    break;
                case MESSAGE_TYPE.notes : 
                    let mentionedStaffs   	   = message.mentionedStaffs ?  message.mentionedStaffs : [] ;
                    let alreadyMentionedStaffs = conversation.mentionedStaffs ? conversation.mentionedStaffs : [];
                    let newlyMentionedStaffs   = getArrayDifference( mentionedStaffs , alreadyMentionedStaffs );
                        
                    if( newlyMentionedStaffs && newlyMentionedStaffs.length > 0 ) 
                        incrementConversationCount( dashboardAgents , "" , newlyMentionedStaffs );
                    break;
                case MESSAGE_TYPE.chat_assign_and_reopen :
                    if(conversation.chatStatus == "CLOSED")
                        incrementConversationCount( dashboardAgents , message.actionTo , conversation.mentionedStaffs );
                    break;
                case MESSAGE_TYPE.chat_auto_assigned_available :
                    incrementConversationCount( dashboardAgents, message.actionTo );
                    break;
            }
        }
        
        return {
                ...state,
                dashboardAgents : {
                                    ...state.dashboardAgents,
                                    [state.activeProjectId] : dashboardAgents
                                }
            }
}
case UPDATE_CONTACT_PRESENCE :
{
    let userId    =  action.userId; 
    let currentStaff = state.currentStaffStatus;
    if(action.presenceDetails && action.presenceDetails.length)
    {
        let dashboardAgents = Object.assign({}, state.dashboardAgents);

            for( let projectId in dashboardAgents ) {

                let staffsUnderProject = dashboardAgents[ projectId ];
                    for(var data of action.presenceDetails)
                    {
                        let { userInfo } = data;
                        let {
                          projectId: projectIdFromUserInfo,
                          messageFrom,
                          contactId,
                          dashboardSkill,
                          dashboardRole,
                          shouldRouteChats,
                        } = userInfo;
                        if (
                            messageFrom === MESSAGE_FROM.AGENT &&
                            projectIdFromUserInfo.includes(projectId) &&
                            validateConnectAndChatconsolePresenceUpdate(
                              userInfo
                            )
                          )
                        {
                            let staff = staffsUnderProject[contactId];
                            if(staff && staff.status == "ACTIVE")
                            {
                                staff.isOnline = (data.status.connectionStatus === 'online');
                                staff.lastAssignedTime = data.status.applicationStatus.lastAssignedTime;
                                staff.isTakingChat = data.status.applicationStatus.isTakingChat;
                                staff.takingChatStatus = data.status.applicationStatus.takingChatStatus;
                                staff.lastSignOffTime  = data.status.applicationStatus.lastSignOffTime;
                                staff.isRinging = data.status.applicationStatus.isRinging;
                                staff.lastRingTime = data.status.applicationStatus.lastRingTime;
                                staff.lastClosedTime = data.status.applicationStatus.lastClosedTime;
                                staff.conversationIds = data.status.applicationStatus.conversationIds;
                                staff.skill = dashboardSkill || DASHBOARD_SKILL.MEMBER;
                                staff.role = dashboardRole || DASHBOARD_ROLE.EXPERT
                                staff.shouldRouteChats = shouldRouteChats;
                                if(action.isSubscriberLeft)
                                    staff.isOnline = (data.status.connectionStatus === 'online');
                                    staffsUnderProject[staff.key] = staff;    
                                    
                                if( userId && staff.key == userId ) {
                                    currentStaff = Object.assign( {} , staff );
                                }
                            }   
                        }
                    }
                    dashboardAgents[ projectId ] = staffsUnderProject;
            }
        return {
            ...state,
            dashboardAgents : dashboardAgents, 
            currentStaffStatus : currentStaff
        }

    }
}
case UPDATE_STAFF_WHEN_USER_UPDATE : 
{
    let dashboardAgents = Object.assign({}, state.dashboardAgents);
    for ( var projectId in dashboardAgents ) 
    {
        let staffsUnderProject = dashboardAgents[ projectId ];
        if( action.data && action.data.id && staffsUnderProject[action.data.id] )
        {
            let staff = staffsUnderProject[action.data.id]
            staff.firstName = action.data.firstName;
            staff.lastName  = action.data.lastName;
            staff.photoUrl  = action.data.photoID + "?=" + new Date().getTime();
            staff.displayMobileNumber = action.data.displayMobileNumber;
            staff.mobileNumber = action.data.mobileNumber;
            staffsUnderProject[staff.key] = staff;
        }
        dashboardAgents[projectId] = staffsUnderProject;
      }        
    return {
        ...state,
        dashboardAgents : dashboardAgents
    }
}

    case UPDATE_CURRENT_STAFF_PROJECT_ID:
        console.log("This is action.projectId: ", action.currentStaffProjectId);
        return {
            ...state,
            currentStaffProjectId : action.currentStaffProjectId
        }

}
return state;
};

export default StaffListReducer;