import styled from 'styled-components'

const styles = {
    userActiveColor : "#78d700",
    userOfflineColor : "#bbb"
}
export const UserAvatarFigure = styled.figure`
    border-radius: 50%;
    display: inline-block;
    background-color: "#d3d9e5";
    width: 42px;
    height: 42px;
    position: relative;
    line-height: 0;
    opacity: 1;
    margin-right: 12px;
    flex-shrink: 0
`

export const UserAvatarImage = styled.img`
    display: inline-block;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
    vertical-align: middle
`

export const UserPresence = styled.span`
    width: 10px;
    height: 10px;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 50%;
    border: ${props => props.isOnline ? `2px solid ${styles.userActiveColor}`  : `2px solid ${styles.userOfflineColor}`} ;
    background-color: ${props => props.isOnline ? styles.userActiveColor  :  styles.userOfflineColor} ;
    position: absolute;
    right: 0;
    bottom: 0
`

export const UserNameWrapper = styled.div`
    min-width: 0;
    margin-right: 4px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width:50%;
`
export const UserName = styled.h4`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    line-height: 1.3;
    font-weight: ${props => props.unreadCount === 0 ?  '600' : '700'};
`

export const RecentMessage = styled.span`
    font-size: 13px;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${props => props.unreadCount === 0 ?  '#727c8f' : '#181818'};
    font-weight: ${props => props.unreadCount === 0 ? '400' : '700'};
`

export const UnreadCountWrapper = styled.div`
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center
`

export const UnreadCount = styled.i`
    display: inline-block;
    border-radius: 50%;
    padding: 0 2px;
    min-width: 18px;
    min-height: 18px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 1.6;
    background-color: #60677a;
    color: #fff;
`

export const ImagePlaceHolder = styled.figure`
    width: 42px;
    height: 42px;
    border-radius: 100%;
    position: relative;
    margin-right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 13H13C14.3808 13 15.5 11.8808 15.5 10.5V3.83331C15.5 2.45248 14.3808 1.33331 13 1.33331H3C1.61917 1.33331 0.5 2.45248 0.5 3.83331V10.5C0.5 11.8808 1.61917 13 3 13H4.25V15.5L8 13Z' stroke='%231A1F23' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${props => props.avatarColor ? props.avatarColor  : 'green'};
`

export const WrapperDiv = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`