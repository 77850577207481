import { useField } from 'formik'
import React, { FC } from 'react'
import { Margin } from 'styled-components-spacing'
import {
  Circle,
  Container,
  IconsContainer,
  Input,
  Label,
  OptionContainer,
  OptionLabel,
  Options,
  Square,
} from 'src/features/wizard/setup/components/WidgetTypeRadio/styled'
import { IProps } from 'src/features/wizard/setup/components/WidgetTypeRadio/types'
import { WidgetType } from 'src/features/wizard/setup/store/types'

export const WidgetTypeRadio: FC<IProps> = ({
  name,
  label,
  ...inputProps
}): JSX.Element => {
  const [field, _meta, helpers] = useField({ name })

  const handleSetDefault = () => helpers.setValue(WidgetType.DEFAULT)

  const handleSetIcon = () => helpers.setValue(WidgetType.ICON)

  return (
    <Container>
      {label && <Label bolder>{label}</Label>}
      <Options>
        <OptionContainer
          checked={field.value === WidgetType.DEFAULT}
          role="button"
        >
          <Input
            {...inputProps}
            type="radio"
            name={name}
            value={WidgetType.DEFAULT}
            onChange={handleSetDefault}
          />
          <IconsContainer>
            <Square />
            <Margin left={4} />
            <Circle />
          </IconsContainer>
          <Margin top={8} />
          <OptionLabel>Default</OptionLabel>
        </OptionContainer>
        <OptionContainer checked={field.value === WidgetType.ICON}>
          <Input
            {...inputProps}
            type="radio"
            name={name}
            value={WidgetType.ICON}
            onChange={handleSetIcon}
          />
          <Circle />
          <Margin top={8} />
          <OptionLabel>Icon only</OptionLabel>
        </OptionContainer>
      </Options>
    </Container>
  )
}
