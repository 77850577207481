export enum StepViewType {
  preview = 'preview',
  content = 'content',
  confirmation = 'confirmation',
}

export interface IStep {
  name: string
  wasSkipped: boolean
  viewType: StepViewType
}

export interface IReturningFlowState {
  createdAt?: number
  currentStepIndex: number
  steps: IStep[]
}
