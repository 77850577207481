import * as R from 'ramda'
import styled from 'styled-components'
import is, { isNot } from 'typescript-styled-is'
import { mq } from 'src/features/ui/styles/mq'

export const ImageSelectionContainer = styled.div`
  position: absolute;
  left: -5rem;
  top: 5.8rem;
  z-index: 10;
`

export const Container = styled.div`
  background-color: ${R.path(['theme', 'color', 'white'])};
  padding: 2.3rem 1.9rem;
  border: 0.05rem solid ${R.path(['theme', 'color', 'geyser'])};
  box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.119);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: fit-content;
  position: relative;

  ${mq.sm} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 6rem;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 1rem solid transparent;
    border-bottom-color: ${R.path(['theme', 'color', 'geyser'])};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 6.1rem;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 0.9rem solid transparent;
    border-bottom-color: ${R.path(['theme', 'color', 'white'])};
  }
`

export const Image = styled.div<{ backgroundUrl: string; isSelected: boolean }>`
  background-image: url(${R.prop('backgroundUrl')});
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-position: center;
  box-sizing: border-box;
  background-size: contain;
  position: relative;

  ${isNot('isSelected')`
    border: 0.1rem solid ${R.path(['theme', 'color', 'casper'])};
  `}
`

export const ImageButton = styled.button<{ isSelected: boolean }>`
  margin: 0;
  padding: 0;
  background: transparent;
  transition: opacity 250ms linear;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  ${is('isSelected')`
    &:after {
      content: '';
      position: absolute;
      width: 5.4rem;
      height: 5.4rem;
      top: -0.4rem;
      left: -0.4rem;
      border: 0.2rem solid ${R.path(['theme', 'color', 'dodgerBlue'])};
      border-radius: 50%;
    }
  `}
`
