import React, { Component } from "react";
import { getChatAnsweringPopupAudio, isValidURL } from "../../commons/Utility";
import {
  WIDGETS_WITH_EXTENDED_AUTO_OVERFLOW_TIME,
  AUTO_OVERFLOW_TIME,
  AUTO_OVERFLOW_TIME_EXTENDED,
} from "../../commons/Constants";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";

class ChatAnsweringPopup extends Component {
  constructor(props) {
    super(props);
    console.info("props:",props);
    this.state = {
      autoOverflowTimer: undefined,
    };

    this.setAutoOverflowTimer = this.setAutoOverflowTimer.bind(this);
    this.autoOverflowChat = this.autoOverflowChat.bind(this);
    this.getSecondaryButton = this.getSecondaryButton.bind(this);
    this.pushPopupEvent = this.pushPopupEvent.bind(this);
    this.getAutoOverflowTime = this.getAutoOverflowTime.bind(this);
    this.notificationSound = getChatAnsweringPopupAudio();
  }

  componentDidMount() {
    this.setAutoOverflowTimer();
    this.pushPopupEvent();
    if(this.notificationSound)
      this.notificationSound.play(); 
  }

  pushPopupEvent() {
    try {
      let { conversationId, projectId } = this.props.incomingLiveChat;
      let { userReducer } = this.props;
      let { id } = userReducer.data;
      Event(
        projectId,
        "Chat Answering",
        "Answering Ring Shown",
        (conversationId +" of " +projectId+ " loaded to "+id)
      );
    }catch(error) {}
  }

  componentWillUnmount() {
    if (this.state.autoOverflowTimer)
      clearTimeout(this.state.autoOverflowTimer);

    if(this.notificationSound){
      this.notificationSound.pause(); 
      this.notificationSound.currentTime = 0;
    }
  }

  setAutoOverflowTimer() {
    let autoOverflowTimer = setTimeout(() => {
      this.autoOverflowChat();
    }, this.getAutoOverflowTime());
    this.setState({ autoOverflowTimer });
  }

  getAutoOverflowTime() {
    if (
      WIDGETS_WITH_EXTENDED_AUTO_OVERFLOW_TIME.includes(
        this.props.incomingLiveChat.projectId
      )
    )
      return AUTO_OVERFLOW_TIME_EXTENDED;
    return AUTO_OVERFLOW_TIME;
  }

  autoOverflowChat() {
    let { conversationId } = this.props.incomingLiveChat;
    this.props.onReception(conversationId, true);

    Event("", "Chat Answering", "Auto Overflowed", "Chat Auto Overflowed");
  }

  getSecondaryButton(isOverflowEnabled, conversationId) {
    if (isOverflowEnabled)
      return (
        <button
          className="recption-btn"
          onClick={() => {
            this.props.onReception(conversationId);
          }}
        ></button>
      );
    else
      return (
        <button
          className="reject-btn"
          onClick={() => {
            this.props.onReject(conversationId);
          }}
        ></button>
      );
  }

  render() {
    let {
      firstName,
      lastName,
      profileImage,
    } = this.props.incomingLiveChat.visitorDetails;
    let isValidUrl = isValidURL(profileImage);
    let { conversationId, isOverFlowEnabled } = this.props.incomingLiveChat;
    let visitorName =
      firstName && firstName != "null"
        ? firstName + (lastName && lastName != "null" ? " " + lastName : "")
        : "Webpage Visitor";
    let projects = this.props.projects;
    let project = projects
      ? projects.find(
          (project) => project.key == this.props.incomingLiveChat.projectId
        )
      : {};
    let projectName = project && project.name ? project.name : "Customer Chat";

    return (
      <div className="cs-popup-wrp">
        <section className="cs-incoming-chat">
          <div className="incoming-user">
            <img
              style={{ display: isValidUrl ? "block" : "none" }}
              src={profileImage}
            />
          </div>
          <h4>{visitorName}</h4>
          <p>{projectName}</p>
          <div>
            {this.getSecondaryButton(isOverFlowEnabled, conversationId)}
            <button
              className="accept-btn"
              onClick={() => {
                this.props.onAccept(conversationId);
              }}
            ></button>
          </div>
        </section>
        <div className="cs-popupbg incoming"></div>
      </div>
    );
  }
}

export default ChatAnsweringPopup;
