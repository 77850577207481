import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  REMOVE_DESKTOP_NOTIFICATION,
  ADD_DESKTOP_NOTIFICATION,
  LAST_NOTIFY_MESSAGE_ID
} from "../actions/NotificationAction";

const NotificationReducer = (
  state = {
    notifications: [],
    progress: {},
    showNotification: false,
    desktopNotifications: [],
    lastNotifiedMessageIdInAW: "",
  },
  action
) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat([action.notification]),
      };
    case ADD_DESKTOP_NOTIFICATION:
      return {
        ...state,
        desktopNotifications: state.desktopNotifications.concat([
          action.notification,
        ]),
      };
    case REMOVE_DESKTOP_NOTIFICATION:
      return {
        ...state,
        desktopNotifications: [],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.notificationId != action.notificationId
        ),
      };
    case SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
      };
    case LAST_NOTIFY_MESSAGE_ID:
      return {
        ...state,
        lastNotifiedMessageIdInAW: action.messageID
      };
    default:
      return state;
  }
};

export default NotificationReducer;
