/**
 * Created by melvin on 25/04/17.
 */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Cards from "../../components/common/Cards.jsx";
import StaffListItemComponent from "./StaffListItemComponent.jsx";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router";

class StaffListComponent extends Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  render() {
    let { invitedStaffs, parentState } = this.props;

    console.info("invitedStaffs:", invitedStaffs.size);
    return (
      <section className="rightSide">
        <header className="old-header" style={{ margin: "0px 20px 0px 415px" }}>
          <div className="tab">
            <ul>
              <li>
                <Link
                  to={
                    "/app/" +
                    this.props.parentState.projectKey +
                    "/settings/team"
                  }
                  activeClassName="active"
                >
                  Team
                </Link>
              </li>
            </ul>
          </div>
        </header>

        <div className="formSection staff-section">
          <button
            className={
              "blueBtn staffAddBtn" +
              (this.props.parentState.loggedInUserPermission &&
              this.props.parentState.loggedInUserPermission == "FULL_ACCESS"
                ? ""
                : " dn")
            }
            onClick={this.props.showAddStaffPopup}
          >
            Add team member
          </button>
          <div className="staffHeading">
            <table cellSpacing="0" cellPadding="0" width="100%">
              <tbody>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Email Id</th>
                  <th>Role</th>
                  <th></th>
                </tr>
              </tbody>
            </table>
          </div>

          <ul className="staffList">
            {Object.keys(this.props.parentState.activeProjectStaffMap).map(
              (key, i) => {
                let staff = this.props.parentState.activeProjectStaffMap[key];
                if (staff.status === "ACTIVE")
                  return (
                    <StaffListItemComponent
                      key={staff.key}
                      staffItem={staff}
                      parentState={this.props.parentState}
                      updateUserRoleHandler={this.props.updateUserRoleHandler}
                      showStaffDeletePopup={this.props.showStaffDeletePopup}
                      deleteStaffHandler={this.props.deleteStaffHandler}
                    />
                  );
              }
            )}
          </ul>

          <div
            className={
              "popup-container" + (this.props.showPopUpClass ? " show" : " dn")
            }
          >
            <aside className={"popup staffPopup"}>
              <h3>Add Team Members</h3>
              <code
                className="popupClose"
                onClick={this.props.hideAddStaffPopup}
              >
                ×
              </code>
              <div className="formField">
                <div className="inputTxt inlineBlock">
                  <label>Email</label>
                  <input
                    className="fs-exclude"
                    type="text"
                    aria-label="Email"
                    onKeyDown={this.props.onInviteeEmailKeydown}
                    onKeyUp={this.props.onEnterHandler}
                    ref="staffEmailId"
                    placeholder="Enter Email ID"
                  />
                </div>
                <div className="inputTxt inlineBlock">
                  <label>Permission</label>
                  <div className="dropdownCustome">
                    <select required="" ref="staff_permission">
                      <option value="RESTRICTED" defaultValue>
                        Staff
                      </option>
                      <option value="FULL_ACCESS">Owner</option>
                    </select>
                  </div>
                </div>
                <a
                  href="javascript:void(0)"
                  onClick={this.props.addStaffToInvite}
                  className="blueBtnLine"
                >
                  Add
                </a>
                <Cards
                  ref="staffToInvite"
                  cards={invitedStaffs}
                  onClose={this.props.removeStaff}
                />
              </div>
              <div className="popupBtnHolder">
                <button
                  className="blueBtn"
                  style={{
                    width: "90px",
                    opacity: parentState.showInviteButton ? "1.0" : "0.5",
                  }}
                  onClick={this.props.inviteStaffs}
                  disabled={!parentState.showInviteButton}
                >
                  Invite
                </button>
              </div>
            </aside>
            <div className={"transprentBg"}></div>
          </div>

          <div
            className={
              "popup-container" +
              (this.props.parentState.showStaffDeletePopup ? " show" : " dn")
            }
          >
            <aside className={"popup " + " deleteStaffPopup"}>
              <h3>
                Delete{" "}
                {this.props.parentState.staffToDelete
                  ? this.props.getAgentNameById(
                      this.props.parentState.staffToDelete
                    )
                  : "Staff"}
              </h3>
              <code
                className="popupClose"
                onClick={this.props.hideStaffDeletePopup}
              >
                ×
              </code>
              <div className="formField">
                <p>
                  To delete the staff, you need to reassign the conversations
                  which are all associated with the staff to some other staff.
                  staff will be permanently deleted. This process can't be
                  undone, are you sure you want to do delete staff?
                </p>
                <div style={{ height: "75px", position: "relative" }}>
                  <label
                    style={{
                      fontSize: "13px",
                      display: "block",
                      margin: "18px 10px 0px 0px",
                      color: "rgb(126, 142, 164)",
                    }}
                  >
                    Update Assigned and Mentioned Conversation from{" "}
                    {this.props.parentState.staffToDelete
                      ? this.props.getAgentNameById(
                          this.props.parentState.staffToDelete
                        )
                      : "Staff"}{" "}
                    to
                  </label>

                  {this.props.parentState.staffToDelete
                    ? this.props.getStaffList()
                    : ""}
                </div>
              </div>
              <div className="popupBtnHolder">
                <button
                  className="redBtn"
                  disabled={
                    this.props.parentState.staffToDelete ===
                    this.props.parentState.currentlySelectedStaffId
                      ? true
                      : false
                  }
                  style={{
                    opacity:
                      this.props.parentState.staffToDelete ===
                      this.props.parentState.currentlySelectedStaffId
                        ? "0.5"
                        : "1.0",
                  }}
                  onClick={this.props.deleteStaffHandler}
                >
                  Confirm and Delete
                </button>
              </div>
            </aside>
            <div className={"transprentBg"}></div>
          </div>

          <div
            className={
              "transprentBg " +
              (this.props.showPopUpClass ||
              this.props.parentState.showStaffDeletePopup
                ? "db"
                : "dn")
            }
          ></div>
        </div>
      </section>
    );
  }
}
export default StaffListComponent;
