import React, { useEffect, useState, useRef } from "react";
import {
  TeamDropDownIcon,
  TickIcon,
  ErrorIcon,
  SearchIcon,
} from "../../commons/Icons";
import { ID_USER_ROLE_MAP, ID_USER_SKILL_MAP } from "../../commons/Constants";
import { sortListByProperty, truncate } from "../../commons/Utility";
import ReactTooltip from "react-tooltip";

const InviteStaffListItem = ({
  staff,
  updateStaff,
  removeStaff,
  projectList,
  showRemoveIcon,
}) => {
  const roleRef = useRef(null);
  const skillRef = useRef(null);
  const projectRef = useRef(null);
  const emailRef = useRef(null);
  const projectSearchRef = useRef(null);
  const rolesRef = useRef([]);
  const skillsRef = useRef([]);
  const projectsRef = useRef([]);
  let sortedProjectList = sortListByProperty(projectList, "name");

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    emailRef.current.focus();
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (event) => {
    if (
      !(roleRef && roleRef.current && roleRef.current.contains(event.target))
    ) {
      hideRoleDropdown();
    }
    if (
      !(skillRef && skillRef.current && skillRef.current.contains(event.target))
    ) {
      hideSkillDropdown();
    }
    if (
      !(
        projectRef &&
        projectRef.current &&
        projectRef.current.contains(event.target)
      )
    ) {
      hideProjectDropdown();
    }
  };
  const removeThisStaff = () => {
    removeStaff(staff.key);
  };

  const getFilteredProjectMap = () => {
    let searchValue = "";
    if (projectSearchRef.current != null)
      searchValue = projectSearchRef.current.value;
    let projectMap = {};
    for (let project of sortedProjectList)
      if (project.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
        projectMap[project.key] = project;
    return projectMap;
  };

  const [showRoleDropdown, setShowRoleDropdown] = useState(false);
  const [showSkillDropdown, setShowSkillDropdown] = useState(false);
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const [dropDownIdx, setDropDownIdx] = useState(-1);
  const [projectMap, setProjectMap] = useState(getFilteredProjectMap());
  const [emailFocused, setEmailFocused] = useState(false);
  const [nameFocused, setNameFocused] = useState(false);
  const [roleFocused, setRoleFocused] = useState(false);
  const [skillFocused, setSkillFocused] = useState(false);
  const [widgetsFocused, setWidgetsFocused] = useState(false);
  const [projectSearchvalue, setProjectSearchvalue] = useState("");

  useEffect(() => {
    sortedProjectList = sortListByProperty(projectList, "name");
    setProjectMap(getFilteredProjectMap());
    if (projectsRef && projectsRef.current) projectsRef.current = [];
  }, [projectList, projectSearchvalue]);

  useEffect(() => {
    if (showProjectDropdown && projectsRef.current[dropDownIdx])
      projectSearchRef.current.focus();
  }, [showProjectDropdown]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    if (showRoleDropdown && rolesRef.current[dropDownIdx])
      rolesRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });
    else if (showSkillDropdown && skillsRef.current[dropDownIdx])
      skillsRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });
    else if (showProjectDropdown && projectsRef.current[dropDownIdx]) {
      projectsRef.current[dropDownIdx].scrollIntoView({ block: "nearest" });
      ReactTooltip.show(projectsRef.current[dropDownIdx].parentElement);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      ReactTooltip.hide();
    };
  }, [
    dropDownIdx,
    showRoleDropdown,
    showSkillDropdown,
    showProjectDropdown,
    roleFocused,
    skillFocused,
    widgetsFocused,
  ]);

  const toggleRoleDropdown = () => {
    setShowRoleDropdown(!showRoleDropdown);
    setDropDownIdx(0);
  };

  const handleHover = (e) => {
    if (dropDownIdx > -1 && dropDownIdx != e.target.dataset.index)
      setDropDownIdx(e.target.dataset.index);
  };

  const handleKeyDown = (e) => {
    let isEnterKey = e.keyCode === 13 || e.which === 13;
    if (showRoleDropdown || showSkillDropdown || showProjectDropdown) {
      let isUpArrow = e.keyCode === 38 || e.which === 38;
      let isDownArrow = e.keyCode === 40 || e.which === 40;
      let isEscapeKey = e.keyCode === 27 || e.which === 27;
      let index = dropDownIdx;
      let elements = [];
      let maxLength = 0;
      if (showRoleDropdown) {
        maxLength = Object.keys(ID_USER_ROLE_MAP).length;
        elements = rolesRef.current;
        if (isEscapeKey) setShowRoleDropdown(false);
      } else if (showSkillDropdown) {
        maxLength = Object.keys(ID_USER_SKILL_MAP).length;
        elements = skillsRef.current;
        if (isEscapeKey) setShowSkillDropdown(false);
      } else if (showProjectDropdown) {
        maxLength = Object.keys(projectMap).length;
        elements = projectsRef.current;
        if (isEscapeKey) setShowProjectDropdown(false);
      }
      if (isUpArrow && dropDownIdx > 0) {
        index--;
        e.preventDefault();
      } else if (isDownArrow && dropDownIdx < maxLength - 1) {
        index++;
        e.preventDefault();
      } else if (isEnterKey && elements[index]) {
        elements[index].click();
        e.preventDefault();
      }
      setDropDownIdx(index);
    } else if (roleFocused && isEnterKey) onEnter("role");
    else if (skillFocused && isEnterKey) onEnter("skill");
    else if (widgetsFocused && isEnterKey) onEnter("project");
  };

  const setRefs = (el) => {
    if (el && el.dataset) {
      let type = el.dataset.type;
      let newRef = {};
      if (type === "role") newRef = rolesRef;
      else if (type === "skill") newRef = skillsRef;
      else if (type === "project") newRef = projectsRef;
      if (el && newRef.current && !newRef.current.includes(el))
        newRef.current.push(el);
    }
  };

  const onEnter = (type) => {
    if (type === "role") {
      setShowRoleDropdown(true);
    } else if (type === "skill") {
      setShowSkillDropdown(true);
    } else if (type === "project") {
      setShowProjectDropdown(true);
    }
    setDropDownIdx(0);
  };

  const hideRoleDropdown = () => {
    setShowRoleDropdown(false);
  };

  const getErrorMessage = (message) => {
    return (
      <span>
        <ErrorIcon />
        {message}
      </span>
    );
  };
  const getRoleDropdown = () => {
    const onRoleFocused = () => {
      setRoleFocused(true);
    };
    const onRoleBlurred = () => {
      setRoleFocused(false);
    };
    const getRoleError = () => {
      if (staff.invalids.includes("EMPTY_ROLE"))
        return getErrorMessage("Please select a role");
      return "";
    };
    const updateRole = (e) => {
      staff.role = e.currentTarget.dataset.role_id;
      updateStaff(staff);
      hideRoleDropdown();
    };

    const getRoleItems = () => {
      let toRender = [];
      let index = 0;
      for (let id in ID_USER_ROLE_MAP) {
        toRender.push(
          <li data-role_id={id} onClick={updateRole}>
            <a
              href="javascript:void(0)"
              data-index={index}
              data-type={"role"}
              ref={setRefs}
              className={index == dropDownIdx ? "select" : ""}
              onMouseOver={handleHover}
            >
              {ID_USER_ROLE_MAP[id]}
            </a>
          </li>
        );
        index++;
      }
      return toRender;
    };

    const getParentDivClass = () => {
      let className = "cs-dropdwn";
      if (showRoleDropdown) className += " open";
      if (staff.invalids.includes("EMPTY_ROLE")) className += " cs-error-field";
      if (staff.role == "") className += " cs-placeholder-txt";
      if (roleFocused) className += " focus";
      return className;
    };

    return (
      <div
        tabIndex={0}
        onFocus={onRoleFocused}
        onBlur={onRoleBlurred}
        className={getParentDivClass()}
        ref={roleRef}
      >
        <label>Role</label>
        <p onClick={toggleRoleDropdown}>
          {staff.role ? ID_USER_ROLE_MAP[staff.role] : "Select Role"}
        </p>
        <TeamDropDownIcon />
        {getRoleError()}
        <div>
          <ul>{getRoleItems()}</ul>
        </div>
      </div>
    );
  };

  const toggleSkillDropdown = () => {
    setShowSkillDropdown(!showSkillDropdown);
    setDropDownIdx(0);
  };

  const hideSkillDropdown = () => {
    setShowSkillDropdown(false);
  };
  const getSkillDropdown = () => {
    const onSkillFocused = () => {
      setSkillFocused(true);
    };
    const onSkillBlurred = () => {
      setSkillFocused(false);
    };

    const getSkillError = () => {
      if (staff.invalids.includes("EMPTY_SKILL"))
        return getErrorMessage("Please select a skill");
      return "";
    };
    const updateSkill = (e) => {
      staff.skill = e.currentTarget.dataset.skill_id;
      updateStaff(staff);
      hideSkillDropdown();
    };

    const getSkillItems = () => {
      let toRender = [];
      let index = 0;
      for (let id in ID_USER_SKILL_MAP) {
        toRender.push(
          <li data-skill_id={id} onClick={updateSkill}>
            <a
              href="javascript:void(0)"
              ref={setRefs}
              data-index={index}
              data-type={"skill"}
              className={index == dropDownIdx ? "select" : ""}
              onMouseOver={handleHover}
            >
              {ID_USER_SKILL_MAP[id]}
            </a>
          </li>
        );
        index++;
      }
      return toRender;
    };

    const getParentDivClass = () => {
      let className = "cs-dropdwn";
      if (showSkillDropdown) className += " open";
      if (staff.invalids.includes("EMPTY_SKILL"))
        className += " cs-error-field";
      if (staff.skill == "") className += " cs-placeholder-txt";
      if (skillFocused) className += " focus";
      return className;
    };

    return (
      <div
        tabIndex={0}
        className={getParentDivClass()}
        onFocus={onSkillFocused}
        onBlur={onSkillBlurred}
        ref={skillRef}
      >
        <label>Skill</label>
        <p onClick={toggleSkillDropdown}>
          {staff.skill ? ID_USER_SKILL_MAP[staff.skill] : "Select Skill"}
        </p>
        <TeamDropDownIcon />
        {getSkillError()}
        <div>
          <ul>{getSkillItems()}</ul>
        </div>
      </div>
    );
  };

  const toggleProjectDropdown = () => {
    setShowProjectDropdown(!showProjectDropdown);
    setDropDownIdx(0);
  };

  const hideProjectDropdown = () => {
    setShowProjectDropdown(false);
    setProjectSearchvalue("");
    setDropDownIdx(0);
  };
  const getProjectDropdown = () => {
    const onWidgetsFocused = () => {
      setWidgetsFocused(true);
    };
    const onWidgetBlurred = () => {
      setWidgetsFocused(false);
    };

    if (projectList.length > 1) {
      const getProjectError = () => {
        if (staff.invalids.includes("EMPTY_PROJECTS"))
          return getErrorMessage("Please select a widget");
        return "";
      };
      const toggleProjectToStaff = (e) => {
        const projectId = e.currentTarget.dataset.project_id;
        let indexOfProject = staff.projects.indexOf(projectId);
        if (indexOfProject == -1) staff.projects.push(projectId);
        else staff.projects.splice(indexOfProject, 1);

        updateStaff(staff);
        e.stopPropagation();
      };
      const projectSearchOnChange = (e) => {
        setDropDownIdx(0);
        let searchedvalue = e.target.value;
        setProjectSearchvalue(searchedvalue);
      };

      const getClassName = (staff, projectId, index) => {
        let className = "";
        if (staff.projects.includes(projectId)) className += "active ";
        if (index == dropDownIdx) className += "select ";
        return className;
      };

      const getProjectItems = () => {
        let toRender = [];
        let index = 0;
        let projectName ='';
        for (let projectId in projectMap) {
          projectName = projectMap[projectId].name;
          toRender.push(
            <li
              data-project_id={projectId}
              onClick={toggleProjectToStaff}
              data-tip={projectName.length > 10 ? projectName: ''}
              data-place="left"
              data-delay-hide='0'
              onKeyDown={ () => {} }
            >
              <a
                href="javascript:void(0)"
                className={getClassName(staff, projectId, index)}
                data-index={index}
                data-type="project"
                ref={setRefs}
                onMouseOver={handleHover}
              >
                <cite>
                  <TickIcon />
                </cite>
                { truncate(projectName,10)}
              </a>
            </li>
          );
          index++;
        }
        return toRender;
      };

      let projectsLength = staff.projects.length;

      const getParentDivClass = () => {
        let className = "cs-dropdwn cs-dropdwn-checkbox";
        if (showProjectDropdown) className += " open";
        if (staff.invalids.includes("EMPTY_PROJECTS"))
          className += " cs-error-field";
        if (projectsLength == 0) className += " cs-placeholder-txt";
        if (widgetsFocused == true) className += " focus";
        if (projectList.length > 6) className += " cs-dropdwn-search-txt";
        return className;
      };

      return (
        <div
          tabIndex={0}
          onFocus={onWidgetsFocused}
          onBlur={onWidgetBlurred}
          className={getParentDivClass()}
          ref={projectRef}
        >
          <label>Widget</label>
          <p onClick={toggleProjectDropdown}>
            {projectsLength > 0 ? "." : "Select Widget"}
          </p>
          <TeamDropDownIcon />
          {getProjectError()}
          {/* <aside className="cs-dropdwn-select">x
                            <span style="display:none;"><cite>.</cite>Answerconnect</span>
                            <span>2 selected</span>
                        </aside> */}
          <aside className="cs-project-wrp">
            <span
              style={projectsLength > 0 ? {} : { display: "none" }}
              className="cs-porject-more"
              onClick={toggleProjectDropdown}
            >
              {projectsLength} selected
            </span>
          </aside>
          <div>
            <div style={projectList.length > 6 ? {} : { display: "none" }}>
              <input
                ref={projectSearchRef}
                type="text"
                onChange={projectSearchOnChange}
                placeholder="Search"
                value={projectSearchvalue}
              />
              <SearchIcon />
            </div>
            <ul>{getProjectItems()}</ul>
          </div>
        </div>
      );
    } else return "";
  };

  const getEmailDiv = () => {
    const onEmailFocused = () => {
      setEmailFocused(true);
    };
    const onEmailBlurred = () => {
      setEmailFocused(false);
    };
    const onEmailChange = (e) => {
      staff.email = e.target.value;
      updateStaff(staff);
    };
    const getEmailErrorMessage = () => {
      if (staff.invalids.includes("EMPTY_EMAIL"))
        return getErrorMessage("Please enter an email");
      else if (staff.invalids.includes("EXISTING_EMAIL"))
        return getErrorMessage("Email already exists");
      else if (staff.invalids.includes("INVALID_EMAIL"))
        return getErrorMessage("Please enter a valid email");
      return "";
    };
    const getParentDivClass = () => {
      let className = "cs-input-field";
      if (
        staff.invalids.includes("EMPTY_EMAIL") ||
        staff.invalids.includes("EXISTING_EMAIL") ||
        staff.invalids.includes("INVALID_EMAIL")
      )
        className += " cs-error-field";
      if (emailFocused == true) className += " cs-active-field";
      return className;
    };
    return (
      <div className={getParentDivClass()}>
        <label>Email</label>
        <input
          className="fs-exclude"
          type="text"
          onFocus={onEmailFocused}
          onBlur={onEmailBlurred}
          onChange={onEmailChange}
          value={staff.email}
          placeholder="Enter Email"
          ref={emailRef}
        />
        {getEmailErrorMessage()}
      </div>
    );
  };
  const getNameDiv = () => {
    const onNameFocused = () => {
      setNameFocused(true);
    };
    const onNameBlurred = () => {
      setNameFocused(false);
    };

    const onNameChange = (e) => {
      staff.fullName = e.target.value;
      updateStaff(staff);
    };
    const getNameErrorMessage = () => {
      if (staff.invalids.includes("EMPTY_FULLNAME"))
        return getErrorMessage("Please enter a name");
      else if (staff.invalids.includes("INVALID_FULLNAME"))
        return getErrorMessage("Please enter a valid name");
      return "";
    };
    const getParentDivClass = () => {
      let className = "cs-input-field";
      if (
        staff.invalids.includes("EMPTY_FULLNAME") ||
        staff.invalids.includes("INVALID_FULLNAME")
      )
        className += " cs-error-field";
      if (nameFocused == true) className += " cs-active-field";
      return className;
    };
    return (
      <div className={getParentDivClass()}>
        <label>Full Name</label>
        <input
          type="text"
          className="cs-caps-txt fs-exclude"
          onFocus={onNameFocused}
          onBlur={onNameBlurred}
          onChange={onNameChange}
          value={staff.fullName}
          placeholder="Enter Name"
        />
        {getNameErrorMessage()}
      </div>
    );
  };

  return (
    <li>
      <code
        style={showRemoveIcon ? {} : { display: "none" }}
        onClick={removeThisStaff}
      >
        ×
      </code>
      {getEmailDiv()}
      {getNameDiv()}
      {getRoleDropdown()}
      {getSkillDropdown()}
      {getProjectDropdown()}
    </li>
  );
};
export default InviteStaffListItem;
