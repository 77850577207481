import React, { FC } from 'react'

export const Widget: FC<any> = (props) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.2994 10.3341C30.1423 7.91419 28.1298 6 25.6701 6H10.6392L10.3341 6.00987C7.91419 6.16694 6 8.17952 6 10.6392V25.6701L6.00987 25.9751C6.16694 28.3951 8.17952 30.3093 10.6392 30.3093H12.3093L12.4855 30.2974C13.1196 30.2114 13.6082 29.6679 13.6082 29.0103C13.6082 28.2929 13.0267 27.7113 12.3093 27.7113H10.6392L10.4168 27.6994C9.39404 27.5885 8.59794 26.7223 8.59794 25.6701V10.6392L8.60992 10.4168C8.72074 9.39404 9.58699 8.59794 10.6392 8.59794H25.6701L25.8925 8.60992C26.9152 8.72074 27.7113 9.58699 27.7113 10.6392V12.3093L27.7232 12.4855C27.8092 13.1196 28.3527 13.6082 29.0103 13.6082C29.7277 13.6082 30.3093 13.0267 30.3093 12.3093V10.6392L30.2994 10.3341ZM36.9894 17.6904H22.7007C19.9336 17.6904 17.6904 19.9336 17.6904 22.7007V36.9894C17.6904 39.7565 19.9336 41.9997 22.7007 41.9997H36.9894C39.7565 41.9997 41.9997 39.7565 41.9997 36.9894V22.7007C41.9997 19.9336 39.7565 17.6904 36.9894 17.6904ZM22.7005 20.2881H36.9891C38.3214 20.2881 39.4015 21.3681 39.4015 22.7005V36.9891C39.4015 38.3214 38.3214 39.4015 36.9891 39.4015H22.7005C21.3681 39.4015 20.2881 38.3214 20.2881 36.9891V22.7005C20.2881 21.3681 21.3681 20.2881 22.7005 20.2881Z"
      fill="#4684FF"
    />
    <path
      d="M32.3003 34L35.3003 30L32.3003 26"
      stroke="#4684FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3003 34L24.3003 30L27.3003 26"
      stroke="#4684FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
