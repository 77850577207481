import React, { Component } from "react";

class TypingStatusComponent extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const { getMessageContentByType } = this.props;
    return (
      <div>
        <label>Typing...</label>
        {getMessageContentByType()}
      </div>
    );
  }
}
export default TypingStatusComponent;
