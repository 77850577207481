import {
  VISITOR_CONVERSATION_LIST_INFO_REQUESTED,
  VISITOR_CONVERSATION_LIST_INFO_RESPONSE,
  VISITOR_CONVERSATION_LIST_INFO_FAILED,
  CONVERSATION_LIST_INFO_REQUESTED,
  CONVERSATION_LIST_INFO_RESPONSE,
  CONVERSATION_LIST_INFO_FAILED,
  CONVERSATION_INFO_REQUESTED,
  CONVERSATION_INFO_RESPONSE,
  CONVERSATION_INFO_FAILED,
  CONVERSATION_UPDATE_REQUESTED,
  CONVERSATION_UPDATE_RESPONSE,
  CONVERSATION_UPDATE_FAILED,
  CONVERSATION_UPDATE_REALTIME,
  CONVERSATION_LIST_FORCE_UPDATE,
  ADD_TEMP_CONVERSATION,
  REMOVE_TEMP_CONVERSATION,
  CREATE_NEW_CONVERSATION_REQUESTED,
  CREATE_NEW_CONVERSATION_RESPONSE,
  CREATE_NEW_CONVERSATION_FAILED,
  ADD_CONVERSATION_TO_EXPANDEDLIST,
  REMOVE_CONVERSATION_FROM_EXPANDEDLIST,
  SEARCH_INFO_REQUESTED,
  RESET_UNREAD_COUNT,
  SET_NEW_MESSAGE,
  RESET_NEW_MESSAGE,
  SET_UNREAD_COUNT,
  UPDATE_CURRENT_CONVERSATION_ID,
  CONVERSATION_COUNT_RESPONSE,
  CONVERSATION_COUNT_REQUESTED,
  ADD_CONVERSATION_QUEUE,
  REMOVE_CONVERSATION_QUEUE,
  RESET_CONVERSATION_QUEUE,
  REMOVE_ALL_CONVERSATION_FROM_EXPANDEDLIST,
  UPDATE_SEARCH_TEXT_CURSOR,
  SHOW_CONVERSATION_FILTER,
  HIDE_CONVERSATION_FILTER,
  UPDATE_CANCEL_TOKEN,
  CONVERSATION_IS_LOADING,
  CONVERSATION_STOPPED_LOADING,
  UPDATE_AW_UNREAD_COUNT,
  UPDATE_TYPING_STATUS,
  CLEAR_CONVERSATION,
  UPDATE_VISITOR_OFFLINE_PRESENCE,
  BLOCK_UNLOAD_EVENT,
  UNBLOCK_UNLOAD_EVENT
} from "../actions/ConversationInfoAction";
import { SEND_TRANSCRIPT_EMAIL_REQUESTED } from "../actions/ConversationInfoAction";
import {
  CONTACT_UPDATE_REQUESTED,
  CONTACT_UPDATE_RESPONSE,
  CONTACT_UPDATE_FAILED,
  UPDATE_CONTACT_PRESENCE,
  VISITOR_TIMEZONE_RESPONSE,
  GET_VISITOR_TIMEZONE,
  VISITOR_TIMEZONE_FAILED,
} from "../actions/ContactInfoAction";
import {
  getVisitorFirstMessageTime,
  getFirstReplyTime,
  getLastClosedTime,
  getParticipants,
  getDecrypted,
  addToAssignedObject,
  removeFromAssignedObject,
  getAgentPresence,
  getChatAssignedTypeByComponent,
  isChatClosedAsPendingByMessageType,
  getProjectId,
} from "../commons/Utility.js";
import {
  MESSAGE_TYPE,
  MESSAGE_STATUSES,
  MODIFICATION_STATUS,
  CURSOR_STATUS,
  CHAT_ASSIGN_TYPES,
  CHAT_STATUS,
} from "../commons/Constants";
import { store } from "../app";

import { stripHTML, getMentionedStaffs } from "../commons/Utility.js";
export const initialState = {
  conversationObject: {
    conversationMap: {},
    isConversationMapEmpty: false,
    contactsMap: {},
  },
  visitorConversationObject: { conversationMap: {} },
  cursor: "",
  searchFilters: {
    projectId: "",
    assignedTo: "",
    sortBy: "",
    chatStatus: "",
    overflowStatus: "",
  },
  currentConversationId: "",
  tempConversationId: "",
  currentTempConversationId : "",
  expandedConversationIds: [],
  isFetching: false,
  isFetched: false,
  isUpdating: false,
  isCreating: false,
  isQueueFetched: false,
  isVisitorConversationFetching: false,
  isVisitorConversationFetched: false,
  isCountFetching: false,
  isCountFetched: false,
  modifiedDateCursor: "",
  queuedConversations: [],
  searchText:"",
  searchTextCursor: "",
  showConversationFilter: false,
  cancelToken: "",
  isLoading: false,
  awUnreadCount: 0, 
  typingStatus: false,
  typingMessage: {}
}
const ConversationInfoReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CONVERSATION_LIST_INFO_REQUESTED: {
      let conversationObject = Object.assign({}, state.conversationObject);
      let searchFilters = Object.assign({}, state.searchFilters);
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        //check whether any change is there in the search filters or the projectId,
        //if so, then replace the maps else append with the existing one
        conversationObject:
          action.conversationObject.isNewList === true
            ? { conversationMap: {}, contactsMap: {} }
            : conversationObject,
        tempConversationId:
          action.conversationObject.isNewList === true
            ? ""
            : state.tempConversationId,
        expandedConversationIds:
          action.conversationObject.isNewList === true
            ? []
            : state.expandedConversationIds,
        cursor: action.conversationObject.cursor,
        searchFilters: {
          ...searchFilters,
          projectId: action.conversationObject.projectId,
          assignedTo: action.conversationObject.assignedTo,
          sortBy: action.conversationObject.orderBy,
          chatStatus: action.conversationObject.chatStatus,
          visitorId: action.conversationObject.visitorId,
          overflowStatus: action.conversationObject.overflowStatus,
        },
      };
    }
    case CONVERSATION_LIST_INFO_RESPONSE: {
      let conversationObject = Object.assign({}, state.conversationObject);
      let conversationMap = conversationObject.conversationMap;
      let contactsMap = conversationObject.contactsMap;
      let queuedConversations = state.queuedConversations;
      queuedConversations = [...new Set([...queuedConversations,...action.conversationObject.queuedConversations])];
      return {
        ...state,
        isFetching: false,
        forceUpdate: false,
        isFetched: action.conversationObject.isMaxLimitReached ? true : false,
        isQueueFetched: action.conversationObject.isQueueFetch === true,
        cursor: action.conversationObject.cursor
          ? action.conversationObject.cursor
          : "",
        //appending the existing maps with the newly fetched ones.
        conversationObject: {
          conversationMap: Object.assign(
            conversationMap,
            action.conversationObject.conversationsInfo.conversationMap
          ),
          contactsMap: Object.assign(
            contactsMap,
            action.conversationObject.conversationsInfo.contactsMap
          ),
          isConversationMapEmpty: action.conversationObject.conversationsInfo.isConversationMapEmpty,
        },
        modifiedDateCursor: action.conversationObject.modifiedDateCursor,
        searchText: action.conversationObject.searchText
          ? getDecrypted(action.conversationObject.searchText)
          : state.searchText,
          queuedConversations: queuedConversations,
      };
    }
    case CONVERSATION_LIST_INFO_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        forceUpdate: false,
      };
    case UPDATE_CANCEL_TOKEN:
      return {
        ...state,
        cancelToken: action.cancelToken
      }
    case VISITOR_CONVERSATION_LIST_INFO_REQUESTED: {
      let visitorConversationObject = Object.assign(
        {},
        state.visitorConversationObject
      );
      return {
        ...state,
        isVisitorConversationFetching: true,
        isVisitorConversationFetched: false,
        visitorConversationCursor: action.conversationObject.cursor,
        visitorConversationObject:
          action.conversationObject.isNewList === true
            ? { ...state.visitorConversationObject, conversationMap: {} }
            : visitorConversationObject,
        visitorId: action.conversationObject.visitorId,
      };
    }
    case VISITOR_CONVERSATION_LIST_INFO_RESPONSE: {
      let visitorConversationObject = Object.assign(
        {},
        state.visitorConversationObject
      );
      let conversationMap = visitorConversationObject.conversationMap;
      return {
        ...state,
        isVisitorConversationFetching: false,
        isVisitorConversationFetched: action.conversationObject
          .visitorConversationCursor
          ? false
          : true,
        visitorConversationCursor: action.conversationObject.cursor
          ? action.conversationObject.cursor
          : "",
        visitorConversationObject: {
          ...state.visitorConversationObject,
          conversationMap: Object.assign(
            conversationMap,
            action.conversationObject.conversationsInfo.conversationMap
          ),
        },
      };
    }
    case VISITOR_CONVERSATION_LIST_INFO_FAILED:
      return {
        ...state,
        isVisitorConversationFetching: false,
        isVisitorConversationFetched: false,
      };

    case CONVERSATION_INFO_REQUESTED:
      return {
        ...state,
        isSingleFetching: true,
      };
    case CONVERSATION_INFO_RESPONSE: {
      console.log("action*******>>>> ", action);
      let newConversation = {};
      let newContact = {};
      let selectedProjectId = getProjectId(action.userReducer.selectedProjectKey);
      console.info("User Reducer:", action.userReducer);
      if (
        state.searchFilters.projectId ===
          action.conversationObject.conversation.projectId ||
        (action.userReducer && action.userReducer.isInternalUser) ||
        action.conversationObject.isFromOverflow ||
        action.conversationObject.isAwWindow ||
        action.conversationObject.isFromAWIntegration ||
        selectedProjectId === action.conversationObject.conversation.projectId
      ) {
        newContact[action.conversationObject.visitor.key] =
          action.conversationObject.visitor;
        newConversation[action.conversationObject.conversation.key] =
          action.conversationObject.conversation;
      }

      return {
        ...state,
        isSingleFetching: false,
        isSingleFetched : true,
        conversationObject: {
          conversationMap: Object.assign(
            newConversation,
            state.conversationObject.conversationMap
          ),
          contactsMap: Object.assign(
            newContact,
            state.conversationObject.contactsMap
          ),
        },
        visitorConversationObject: {
          ...state.visitorConversationObject,
          conversationMap: Object.assign(
            state.visitorId == action.conversationObject.conversation.visitorId
              ? {
                  [action.conversationObject.conversation.key]:
                    action.conversationObject.conversation,
                }
              : {},
            state.visitorConversationObject.conversationMap
          ),
        },
      };
    }
    case CONVERSATION_INFO_FAILED:
      return {
        ...state,
        isSingleFetching: false,
      };
    case CONVERSATION_LIST_FORCE_UPDATE:
      return {
        ...state,
        forceUpdate: action.forceUpdate,
      };
    case CONVERSATION_UPDATE_REQUESTED:
      return {
        ...state,
        isUpdating: true,
        nextConversationId: action.conversationObject.nextConversationId,
      };
    case CONVERSATION_UPDATE_RESPONSE: {
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      conversationMap[action.conversation.key] = action.conversation;
      let visitorConversationMap = Object.assign(
        {},
        state.visitorConversationObject.conversationMap
      );
      if (action.contactsMap)
        contactsMap = {
          ...state.conversationObject.contactsMap,
          ...action.contactsMap,
        };
      if (visitorConversationMap[action.conversation.key])
        visitorConversationMap[action.conversation.key] = action.conversation;
      return {
        ...state,
        isUpdating: false,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: conversationMap,
          contactsMap: contactsMap,
        },
        visitorConversationObject: {
          ...state.visitorConversationObject,
          conversationMap: visitorConversationMap,
        },
      };
    }
    case CONVERSATION_UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
      };
    case CONTACT_UPDATE_REQUESTED:
      return {
        ...state,
        isUpdating: true,
      };
    case CONTACT_UPDATE_RESPONSE: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      let oldContact = contactsMap[action.contactObj.contact.key];
      let newContact = action.contactObj.contact;
      let newContactOnline = newContact.isOnline || "";
      newContact.isOnline =
        oldContact && oldContact.isOnline
          ? oldContact.isOnline
          : newContactOnline;
      contactsMap[action.contactObj.contact.key] = {
        ...oldContact,
        ...newContact,
      };
      return {
        ...state,
        isUpdating: false,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
      };
    }

    case UPDATE_CONTACT_PRESENCE: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      if (action.presenceDetails) {
        for (var data of action.presenceDetails) {
          if (contactsMap[data.userInfo.userId]) {
            contactsMap[data.userInfo.userId].isOnline =
              data.status.connectionStatus === "online";
            if (action.isSubscriberLeft)
              contactsMap[
                data.userInfo.userId
              ].lastSeenTime = new Date().getTime();
          }
        }
      }
      return {
        ...state,
        isUpdating: false,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
      };
    }
    case CONTACT_UPDATE_FAILED:
      return {
        ...state,
        isUpdating: false,
      };

    case RESET_UNREAD_COUNT: {
      let conversationId = action.conversationId;
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      let conversation = Object.assign({}, conversationMap[conversationId]);
      let visitorConversationMap = Object.assign(
        {},
        state.visitorConversationObject.conversationMap
      );
      let visitorConversation = visitorConversationMap[conversationId];
      conversation["unReadCount"] = 0;
      conversationMap[conversationId] = conversation;
      if (visitorConversation) {
        visitorConversation["unReadCount"] = 0;
        visitorConversationMap[conversationId] = visitorConversation;
      }
      return {
        ...state,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: conversationMap,
        },
        visitorConversationObject: {
          ...state.visitorConversationObject,
          conversationMap: visitorConversationMap,
        },
      };
    }

    case CONVERSATION_UPDATE_REALTIME: {
      let message = action.message;
      let status = message.type;
      let {isManualAssignment} = message;
      let conversationId = message.conversationId;
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      let visitorConversationObject = Object.assign(
        {},
        state.visitorConversationObject
      );
      let visitorConversationMap = visitorConversationObject.conversationMap;
      let isNewConversation = conversationMap[conversationId] ? false : true;
      let userId = action.userId || "";

      if (!isNewConversation) {
        let conversation = Object.assign({}, conversationMap[conversationId]);
        if(isManualAssignment){
          conversation.isManualyAssignedAgent = true;
        }
        if (
          (!message.type.includes("TYPING") &&
            !MESSAGE_STATUSES.includes(message.type) &&
            !conversation.isClosedByMe &&
            !message.isClosedByMe) ||
          (conversation.modifiedType == "CLOSE" &&
            message.type == "CHAT_REOPEN" &&
            state.searchFilters.chatStatus == "OPEN") ||
          (conversation.modifiedType == "RE_OPEN" &&
            message.type == "CHAT_CLOSED" &&
            state.searchFilters.chatStatus == "CLOSED") ||
          (CHAT_ASSIGN_TYPES.includes(conversation.modifiedType) &&
            CHAT_ASSIGN_TYPES.includes(message.type) &&
            state.searchFilters.assignedTo == message.senderId)
        ) {
          conversation["modifiedDate"] = message.timestamp;
          conversation["interactionType"] = message.interactionType;
        }

        switch (status) {
          case MESSAGE_TYPE["overflow_chat_closed"]:
          case MESSAGE_TYPE["chat_closed"]:
          case MESSAGE_TYPE["chat_closed_as_pending"]:
          case MESSAGE_TYPE["overflow_chat_closed_on_available"]:
          case MESSAGE_TYPE["dashboard_agent_disconnected"]:
          case MESSAGE_TYPE["dashboard_chat_closed_no_response"]:
          case MESSAGE_TYPE["dashboard_visitor_left"]:
            userId = action.userId || "";
            let staffMap = message.staffMap ? message.staffMap : action.staffMap;
            let agentPresence = getAgentPresence(
              conversation.assignedTo,
              conversation.projectId,
              staffMap
            );
            conversation["chatStatus"] = isChatClosedAsPendingByMessageType(
              status
            )
              ? CHAT_STATUS.Pending
              : CHAT_STATUS.Closed;
            conversation["modifiedType"] = "CLOSE";
            conversation["lastClosedTime"] = getLastClosedTime(
              conversation,
              message
            );
            conversation["lastClosedBy"] = message.senderId;
            conversation["isClosedByMe"] = message.isClosedByMe;
            if (!agentPresence.isOnline ||
              ((agentPresence.isOnline && userId === conversation.assignedTo) && (!action.isFullScreenMode))
            ) {
              conversation["assignedAgentsObject"] = removeFromAssignedObject(
                conversation,
                conversation.assignedTo,
                agentPresence
              );
            }
            break;
          case MESSAGE_TYPE["overflow_chat_disconnected"]:
          case MESSAGE_TYPE["overflow_chat_closed_no_response"]:
          case MESSAGE_TYPE["overflow_prompt_closed_on_ignore"]:
          case MESSAGE_TYPE["overflow_chat_disconnected_abnormally"]:
            conversation["chatStatus"] = "CLOSED";
            conversation["modifiedType"] = "CLOSE";
            conversation["assignedAgentsObject"] = removeFromAssignedObject(
              conversation,
              conversation.assignedTo
            );
            break;
          case MESSAGE_TYPE["chat_reopen"]:
            conversation["chatStatus"] = "OPEN";
            conversation["assignedTo"] =
              message.messageFrom === "AGENT"
                ? message.actionTo
                : message.assignedTo;
            conversation["assignedAgentsObject"] = addToAssignedObject(
              conversation,
              conversation.assignedTo
            );
            conversation["modifiedType"] = "RE_OPEN";
            break;
          case MESSAGE_TYPE["chat_assign_and_reopen"]:
            conversation["assignedTo"] =
              message.messageFrom === "AGENT"
                ? message.actionTo
                : message.assignedTo;
            conversation["modifiedType"] =
              state.searchFilters.assignedTo !== "" ? "RE_OPEN_ON_ASSIGN" : "";
            conversation["chatStatus"] = "OPEN";
            conversation["assignedAgentsObject"] = addToAssignedObject(
              conversation,
              conversation.assignedTo
            );
            break;
          case MESSAGE_TYPE["chat_auto_assigned_available"]:
          case MESSAGE_TYPE["chat_auto_assigned_on_reply"]:
          case MESSAGE_TYPE["chat_assigned"]:
          case MESSAGE_TYPE["chat_assigned_on_connect"]:
          case MESSAGE_TYPE["chat_auto_assign_on_overflow"]:
            conversation["chatStatus"] = "OPEN";
            if  (MESSAGE_TYPE["chat_auto_assign_on_overflow"] !== status)  {
              conversation["overflowStatus"] = "NOT_OVERFLOWED";
            }
            conversation["assignedAgentsObject"] = removeFromAssignedObject(
              conversation,
              conversation.assignedTo
            );
            conversation["assignedTo"] =
              message.messageFrom === "AGENT"
                ? message.actionTo
                : message.assignedTo;
            if (
              userId === conversation.assignedTo &&
              MESSAGE_TYPE["chat_auto_assign_on_overflow"] !== status
            ) {
              conversation.isModified = true;
            } else {
              conversation.isModified = false;
            }
            conversation["modifiedType"] =
              state.searchFilters.assignedTo !== "" ? getChatAssignedTypeByComponent() : "";
            conversation["assignedAgentsObject"] = addToAssignedObject(
              conversation,
              conversation.assignedTo
            );

            break;
          case MESSAGE_TYPE["chat_unassigned"]:
            conversation["assignedAgentsObject"] = removeFromAssignedObject(
              conversation,
              conversation.assignedTo
            );
            conversation["assignedTo"] = "";
            break;
          case MESSAGE_TYPE["chat"]:
          case MESSAGE_TYPE["video_chat_link"]:
            conversation["lastMessageId"] = message.messageId;
            conversation["lastMessage"] = stripHTML(message.message);
            conversation["lastMessageSenderId"] = message.senderId;
            conversation["messageFrom"] = message.messageFrom;
            conversation["visitorFirstMessageTime"] =
              getVisitorFirstMessageTime(conversation, message);
            conversation["firstReplyTime"] = getFirstReplyTime(
              conversation,
              message
            );
            conversation["participants"] = getParticipants(
              conversation,
              message
            );
            break;
          case MESSAGE_TYPE["attachment"]:
            conversation["lastMessageId"] = message.messageId;
            conversation["lastMessage"] = JSON.parse(message.message).fileName;
            conversation["lastMessageSenderId"] = message.senderId;
            conversation["messageFrom"] = message.messageFrom;
            break;
          case MESSAGE_TYPE["notes"]:
            conversation["mentionedStaffs"] = getMentionedStaffs(
              conversation["mentionedStaffs"],
              message.mentionedStaffs
            );
            break;
          case MESSAGE_TYPE["chat_lead_capture"]:
            conversation["leadCaptured"] = true;
            break;
          case MESSAGE_TYPE["mention_closed"]:
            if (
              conversation.key === state.currentConversationId &&
              message.senderId === userId
            ) {
              conversation.isModified = true;
              conversation.modifiedType = MODIFICATION_STATUS.MENTION_CLOSED;
            }
            let updatedMentionedStaffs = conversation.mentionedStaffs
              ? conversation.mentionedStaffs.filter(
                  (staff) => staff != message.senderId
                )
              : [];
            conversation.mentionedStaffs = updatedMentionedStaffs;

            break;
          case MESSAGE_TYPE["single_agent_conversation_closed"]:
            conversation["assignedAgentsObject"] = removeFromAssignedObject(conversation, conversation.assignedTo);
            conversation["lastClosedBy"] = message.senderId;
            break;
        }
/* 
        if(message.type == MESSAGE_TYPE["notes"])
          conversation["isModified"] = false;
        else */ 
        if (
          ((state.searchFilters.assignedTo &&
            state.searchFilters.assignedTo !== "@Mentioned" &&
            state.searchFilters.assignedTo != conversation.assignedTo &&
            (userId != state.searchFilters.assignedTo ||
              (conversation.key == state.currentConversationId &&
                conversation.mentionedStaffs && !conversation.mentionedStaffs.includes(userId) &&
                (!conversation.assignedAgentsObject ||
                  conversation.assignedAgentsObject && conversation.assignedAgentsObject.open && !conversation.assignedAgentsObject.open.includes(
                    userId
                  ))))) ||
            (state.searchFilters.chatStatus !== conversation.chatStatus &&
              conversation.mentionedStaffs && !conversation.mentionedStaffs.includes(userId) &&
              (!conversation.assignedAgentsObject ||
                conversation.assignedAgentsObject && conversation.assignedAgentsObject.open && !conversation.assignedAgentsObject.open.includes(userId)))) &&
          action.message.type !== "REMOVE_CONVERSATION_QUEUE" &&
          action.message.type !== "ADD_CONVERSATION_QUEUE" && 
          !action.message.type.includes("TYPING")
        )
          conversation["isModified"] = true;
        else if (
          (!["CHAT_READ", "CHAT_DELIVERED"].includes(status) &&
            status === MESSAGE_TYPE.chat_auto_assigned_on_reply &&
            userId === conversation.assignedTo) ||
          conversation.isModified
        )
          conversation["isModified"] = true;
        else conversation["isModified"] = false;

        conversationMap[conversationId] = conversation;
        if (conversation.visitorId === state.visitorId)
          visitorConversationMap[conversationId] = conversation;
        return {
          ...state,
          conversationObject: {
            ...state.conversationObject,
            conversationMap: conversationMap,
          },
          visitorConversationObject: {
            ...state.visitorConversationObject,
            conversationMap: visitorConversationMap,
          },
        };
      } 
      /* else if( message.type == "NOTES" && message.mentionedStaffs && message.mentionedStaffs.length )
      {
        let mentionedStaffs = getMentionedStaffs(
          [],
          message.mentionedStaffs
        );
        let conversation = Object.assign({}, { mentionedStaffs });
        conversationMap[message.conversationId] = conversation;
        return {
          ...state,
          conversationObject: {
            ...state.conversationObject,
            conversationMap: conversationMap,
          }
        }
      } */
      else return { ...state };
    }
    case SET_NEW_MESSAGE: {
      let message = action.message;
      let conversationId = message.conversationId;
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      let conversation = Object.assign({}, conversationMap[conversationId]);

      conversation.newMessage = message.key;
      conversationMap[conversationId] = conversation;
      return {
        ...state,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: conversationMap,
        },
      };
    }
    case RESET_NEW_MESSAGE: {
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      let conversation = Object.assign(
        {},
        conversationMap[action.conversationId]
      );

      conversation.newMessage = "";
      conversationMap[action.conversationId] = conversation;
      return {
        ...state,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: conversationMap,
        },
      };
    }
    case ADD_CONVERSATION_TO_EXPANDEDLIST:
      return {
        ...state,
        expandedConversationIds: [
          action.conversationId,
        ],
      };
    case REMOVE_CONVERSATION_FROM_EXPANDEDLIST: {
      let expandedConversationList = Object.assign(
        [],
        state.expandedConversationIds
      );
      expandedConversationList.splice(
        expandedConversationList.indexOf(action.conversationId),
        1
      );
      return {
        ...state,
        expandedConversationIds: expandedConversationList,
      };
    }
    case REMOVE_ALL_CONVERSATION_FROM_EXPANDEDLIST: {
       return {
         ...state,
         expandedConversationIds : []
       } 
    }
    case ADD_TEMP_CONVERSATION: {
      let contactMap = state.conversationObject.contactsMap;
      if(!contactMap[action.tempConversation.visitorId])
        contactMap[action.tempConversation.visitorId] = action.contact;
      let tempConversation = {};
      tempConversation[action.tempConversation.key] = Object.assign( {}, action.tempConversation);
      return {
        ...state,
        tempConversationId: action.tempConversation.key,
        expandedConversationIds: [
          ...state.expandedConversationIds       
        ],
        conversationObject: {
          ...state.conversationObject,
          conversationMap: {
            ...tempConversation,
            ...state.conversationObject.conversationMap
          },
          contactsMap : contactMap 
        },
      };
    }
    case REMOVE_TEMP_CONVERSATION: {
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      delete conversationMap[action.tempConversationId];
      let expandedConversationList = Object.assign(
        [],
        state.expandedConversationIds
      );
      expandedConversationList.splice(
        expandedConversationList.indexOf(action.tempConversationId),
        1
      );
      return {
        ...state,
        tempConversationId: "",
        expandedConversationIds: expandedConversationList,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: conversationMap,
        },
      };
    }
    case CREATE_NEW_CONVERSATION_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isCreating: true,
      };
    case CREATE_NEW_CONVERSATION_RESPONSE: {
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      delete conversationMap[action.tempConversationId];
      let expandedConversationIds = Object.assign(
        [],
        state.expandedConversationIds
      );
      expandedConversationIds.splice(
        expandedConversationIds.indexOf(action.tempConversationId),
        1,
        action.conversation.key
      );
      let newConversation = {};
      newConversation[action.conversation.key] = action.conversation;

      return {
        ...state,
        isFetching: false,
        tempConversationId: "",
        expandedConversationIds: expandedConversationIds,
        isCreating: false,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: Object.assign(newConversation, conversationMap),
        },
      };
    }
    case CREATE_NEW_CONVERSATION_FAILED:
      return {
        ...state,
        isFetching: false,
        isCreating: false,
      };
    case SEND_TRANSCRIPT_EMAIL_REQUESTED:
      return {
        ...state,
      };
    case GET_VISITOR_TIMEZONE: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      let contact = contactsMap[action.peopleKey] || action.people;
      contact.isTimeZoneFetching = true;
      contact.isTimeZoneFetchFailed = false;
      contactsMap[action.peopleKey] = contact;
      return {
        ...state,
        isUpdating: false,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
      };
    }
    case VISITOR_TIMEZONE_RESPONSE: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      let contact = contactsMap[action.peopleKey] || action.people;
      contact.timeZoneObject = action.timeZoneObject;
      contact.isTimeZoneFetching = false;
      contact.isTimeZoneFetchFailed = false;
      contactsMap[action.peopleKey] = contact;
      return {
        ...state,
        isUpdating: false,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
        timeZoneMap: {
          ...state.timeZoneMap,
          [action.latitude + "," + action.longitude]: action.timeZoneObject,
        },
      };
    }
    case VISITOR_TIMEZONE_FAILED: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      let contact = contactsMap[action.peopleKey] || action.people;
      contact.isTimeZoneFetchFailed = true;
      contact.isTimeZoneFetching = false;
      contactsMap[action.peopleKey] = contact;
      return {
        ...state,
        isUpdating: false,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
      };
    }
    case UPDATE_CURRENT_CONVERSATION_ID: {
      return {
        ...state,
        currentConversationId : action.currentConversationId
      }
    }

    case CONVERSATION_COUNT_RESPONSE: {      
      return{
        ...state,
        isCountFetching:false,
        isCountFetched:true,
        conversationCount:action.conversationCount
      }
    }

    case ADD_CONVERSATION_QUEUE: {
      let queuedConversationIds = action.conversationId ? [...new Set([...state.queuedConversations,action.conversationId])] : state.queuedConversations;
      return{
        ...state,
        queuedConversations: queuedConversationIds
      }
    }

    case REMOVE_CONVERSATION_QUEUE: {
      let queuedConversationIds = state.queuedConversations.filter((conversationId) => conversationId != action.conversationId);
      return{
        ...state,
        queuedConversations: queuedConversationIds
      }
    }

    case RESET_CONVERSATION_QUEUE: {
      return{
        ...state,
        isQueueFetched: false,
        queuedConversations: []
      }
    }

    case CONVERSATION_COUNT_REQUESTED: {
      return {
        ...state,
        isCountFetching:true
      }
    }
      
    case SHOW_CONVERSATION_FILTER: {
      return {
        ...state,
        showConversationFilter: true
      }
    }
      
    case HIDE_CONVERSATION_FILTER: {
      return {
        ...state,
        showConversationFilter: false
      }
    }

    case SEARCH_INFO_REQUESTED: {
      console.error("Search Invoked");
      break;
    }

    case UPDATE_SEARCH_TEXT_CURSOR: {
      return {
        ...state,
        searchTextCursor:
          action.searchTextCursor === null
            ? CURSOR_STATUS.NO_CURSOR
            : action.searchTextCursor,
      };
    }

    case CONVERSATION_IS_LOADING:
      return {
        ...state,
        isLoading: true,
      }

    case CONVERSATION_STOPPED_LOADING:
      return {
        ...state,
        isLoading: false,
      }
      case UPDATE_AW_UNREAD_COUNT:
        {
          let conversationId = action.currentConversationId;
          let conversationMap = Object.assign(
            {},
            state.conversationObject.conversationMap
          );
          let conversation = Object.assign({}, conversationMap[conversationId]);
  
          conversation.awUnreadCount = 0;
          conversationMap[conversationId] = conversation;
          return {
            ...state,
            conversationObject: {
              ...state.conversationObject,
              conversationMap: conversationMap,
            },
          };
        }
  
    case UPDATE_TYPING_STATUS: {
      return {
        ...state,
        typingStatus: action.typingStatus,
        typingMessage: action.typingMessage
      }
    }
    
    case CLEAR_CONVERSATION: {
      let conversationMap = Object.assign(
        {},
        state.conversationObject.conversationMap
      );
      let visitorConversationMap = Object.assign(
        {},
        state.visitorConversationObject.conversationMap
      );
      delete conversationMap[action.conversationId];
      delete visitorConversationMap[action.conversationId];
      return {
        ...state,
        conversationObject: {
          ...state.conversationObject,
          conversationMap: Object.assign({}, conversationMap),
        },
        visitorConversationObject: {
          ...state.visitorConversationObject, 
          conversationMap: Object.assign({}, visitorConversationMap),
        }
      }
    }
    case BLOCK_UNLOAD_EVENT:{
      return {
        ...state,
        isUnloadEventBlock : true,
      } 
    } 
    case UNBLOCK_UNLOAD_EVENT:{
      return {
        ...state,
        isUnloadEventBlock : false,
      }
    }
    case UPDATE_VISITOR_OFFLINE_PRESENCE: {
      let contactsMap = Object.assign({}, state.conversationObject.contactsMap);
      if (contactsMap[action.visitorId])
        contactsMap[action.visitorId].isOnline = false;
      return {
        ...state,
        conversationObject: {
          ...state.conversationObject,
          contactsMap: contactsMap,
        },
      };
    }
  }
  return state;
};

export default ConversationInfoReducer;
