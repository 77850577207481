/* istanbul ignore next */
import { all, fork } from 'redux-saga/effects'
import { authorizationSagas } from 'src/features/authorization/store/sagas'
import { returningFlowSagas } from 'src/features/returningFlow/store/sagas'
import { installSagas } from 'src/features/wizard/install/store/sagas'
import { setupSagas } from 'src/features/wizard/setup/store/sagas'

export function* rootSagas() {
  yield all([fork(authorizationSagas)])
  yield all([fork(setupSagas)])
  yield all([fork(installSagas)])
  yield all([fork(returningFlowSagas)])
}
