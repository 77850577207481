// Note: nullish produces different logic in this case
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import { Container } from 'src/features/form/components/Button/styled'
import {
  ButtonColor,
  ButtonColorType,
  ButtonPaddingSize,
  IFullButtonProps,
} from 'src/features/form/components/Button/types'
import { Spinner } from 'src/features/ui/components/Spinner'

export const Button: React.FC<IFullButtonProps> = ({
  color = ButtonColor.default,
  colorType = ButtonColorType.full,
  hasFullWidth,
  hasFullWidthMobile,
  disabled,
  children,
  isLoading,
  paddingSize,
  maxWidth,
  width,
  ...props
}) => (
  <Container
    hasFullWidth={hasFullWidth}
    hasFullWidthMobile={hasFullWidthMobile}
    disabled={isLoading || disabled}
    primary={color === ButtonColor.primary}
    hollow={colorType === ButtonColorType.hollow}
    hasSmallerPadding={paddingSize === ButtonPaddingSize.small}
    width={width}
    maxWidth={maxWidth}
    {...props}
  >
    {isLoading ? (
      <Spinner
        size={16}
        fill={colorType === ButtonColorType.hollow ? 'dodgerBlue' : 'white'}
      />
    ) : (
      children
    )}
  </Container>
)
