import React, { FC, useState } from 'react'
import {
  Container,
  Content,
  Highlight,
  Label,
} from 'src/features/ui/components/Tabs/styled'
import { IProps } from 'src/features/ui/components/Tabs/types'

export const Tabs: FC<IProps> = ({ options }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const getLabelWidth = () => 100 / options.length

  const getLastIndex = () => options.length - 1

  const handleSelect = (index: number) => () => setSelectedIndex(index)

  return (
    <>
      <Container>
        {options.map((option, index) => (
          <Label
            onClick={handleSelect(index)}
            isActive={index === selectedIndex}
            key={option.label}
            $width={getLabelWidth()}
          >
            {option.label}
          </Label>
        ))}
        <Highlight
          isLastSelected={selectedIndex === getLastIndex()}
          $left={getLabelWidth() * selectedIndex}
          $width={getLabelWidth()}
        />
      </Container>
      {options.map((option, index) => {
        if (index !== selectedIndex) {
          return null
        }

        return <Content key={option.label}>{option.children}</Content>
      })}
    </>
  )
}
