import React, { FC } from 'react'
import { Text1, Link1 } from 'src/features/ui/components/Typography'
import { PluginGeneric } from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginGeneric'
import {
  PluginStoreButton,
  PluginStoreLink,
} from 'src/features/wizard/install/components/PluginInstall/Plugins/PluginGeneric/styled'

const INSTRUCTIONS_LINK = 'https://chatsupport.co/support/plugins/joomla'
const PLUGIN_STORE_LINK =
  'https://extensions.joomla.org/instant-search/?jed_live%5Bquery%5D=chatsupport'

export const PluginJoomla: FC = () => (
  <PluginGeneric
    steps={[
      <React.Fragment key={1}>
        <Text1 color="raven">
          Install and activate the ChatSupport plugin to your Joomla dashboard
        </Text1>
        <PluginStoreLink
          target="_blank"
          rel="noopener noreferrer"
          href={PLUGIN_STORE_LINK}
        >
          <PluginStoreButton>Go to Joomla Plugin Store</PluginStoreButton>
        </PluginStoreLink>
        <Text1 color="raven">
          Need help? Follow the{' '}
          <Link1
            underline
            href={INSTRUCTIONS_LINK}
            color="raven"
            target="_blank"
            rel="noopener noreferrer"
          >
            installation instructions
          </Link1>
          .
        </Text1>
      </React.Fragment>,
    ]}
  />
)
