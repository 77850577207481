import {
  CUSTOM_FIELDS_REQUESTED,
  CUSTOM_FIELDS_RESPONSE,
  CUSTOM_FIELDS_FAILED,
  UPDATE_CUSTOM_FIELD_REQUESTED,
  UPDATE_CUSTOM_FIELD_RESPONSE,
  UPDATE_CUSTOM_FIELD_FAILED,
  DELETE_CUSTOM_FIELD_REQUESTED,
  DELETE_CUSTOM_FIELD_RESPONSE,
  DELETE_CUSTOM_FIELD_FAILED,
} from "../actions/CustomFieldsAction.js";

const CustomFieldsReducer = (
  state = {
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    isUpdated: false,
    isDeleting: false,
    isDeleted: false,
    projectId: "",
    customFieldsMap: {},
  },
  action
) => {
  switch (action.type) {
    case CUSTOM_FIELDS_REQUESTED:
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        customFieldsMap:
          action.projectId != state.projectId ? {} : state.customFieldsMap,
        projectId: action.projectId,
      };
    case CUSTOM_FIELDS_RESPONSE:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        customFieldsMap: Object.assign(
          state.customFieldsMap,
          action.customFieldsMap
        ),
      };
    case CUSTOM_FIELDS_FAILED:
      return {
        ...state,
        isFetching: false,
        isFetched: false,
      };
    case UPDATE_CUSTOM_FIELD_REQUESTED:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false,
      };
    case UPDATE_CUSTOM_FIELD_RESPONSE: {
      let customFieldsMap = Object.assign({}, state.customFieldsMap);
      customFieldsMap[action.customField.customPropId] = action.customField;
      console.log("settimg:", customFieldsMap[action.customField.customPropId]);
      return {
        ...state,
        isUpdating: false,
        isUpdated: true,
        customFieldsMap: customFieldsMap,
      };
    }
    case UPDATE_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isUpdating: false,
        isUpdated: false,
      };
    case DELETE_CUSTOM_FIELD_REQUESTED:
      return {
        ...state,
        isDeleting: true,
        isDeleted: false,
      };
    case DELETE_CUSTOM_FIELD_RESPONSE: {
      let customFieldsMap = Object.assign({}, state.customFieldsMap);
      console.log("action.customFieldId", action.customFieldId);
      console.log(
        "customFieldsMap[action.customFieldId];",
        customFieldsMap[action.customFieldId]
      );
      delete customFieldsMap[action.customFieldId];

      return {
        ...state,
        isDeleting: false,
        isDeleted: true,
        customFieldsMap: customFieldsMap,
      };
    }
    case DELETE_CUSTOM_FIELD_FAILED:
      return {
        ...state,
        isDeleting: false,
        isDeleted: false,
      };
  }
  return state;
};

export default CustomFieldsReducer;
