import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAppMode, getProjectId } from "../../commons/Utility";
import { useEffect } from "react";
import { CHATSUPPORT_BRAND_ID } from "../../commons/Constants";
const IntegrationContainer = (props) => {
  const { user } = useSelector((state) => ({
    user: state.UserReducer,
  }));
  const { projectkey } = props.params;

  const [isInitiated, setInitiated] = useState(false);
  useEffect(() => {
    if (
      !isInitiated &&
      user.data &&
      projectkey &&
      user.data.login && 
      window.integrations
    ) {
      const configuration = {
        renderId: "integration-div",
        projectID: getProjectId(projectkey),
        mode: getAppMode() == "live" ? "live" : "staging",
        email: user.data.login,
        accountVerified: true,
        accessLevel: "User",
        brandId: CHATSUPPORT_BRAND_ID,
      };

      //const init = integrations.init({ configuration });
      integrations.init({ configuration });
      setInitiated(true);
    }
  }, [user.data, projectkey, window.integrations]);

  const settingsStyle = () => {
    if(process.env.isSettingsComponent){
      return {"margin" : "0px 0px 0px 320px"}
    } else {
      return {};
    }
  } 

  return (
    <div className="cs-integrations-wrp" style={settingsStyle()}>
      <div id="integration-div"></div>
    </div>
  );
};

export default IntegrationContainer;
