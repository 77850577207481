import React, { Component } from "react";
import { connect } from "react-redux";
import AnywhereworksComponent from "../components/chat/AnywhereworksComponent.jsx";
import * as ConnectionAction from "../actions/MessageAction";
import * as ConversationInfoAction from "../actions/ConversationInfoAction";
import {
  requestChatConfiguration,
  addContainerInfo,
} from "../settings/actions/ChatConfigurationAction";
import { acceptChat } from "../actions/ChatAnsweringAction";
import { requestProjectById } from "../settings/actions/ProjectAction";
import { staffListRequest } from "../actions/StaffAction";
import { requestTagList } from "../actions/TagActions";
import { MESSAGE_FROM, MESSAGE_TYPE } from "../commons/Constants.js";
import {
  generateMessageId,
  isValidObject,
  getProjectKey,
} from "../commons/Utility.js";
import { showVoicebox } from "../actions/VoiceboxAction";
import { sendMessage } from "../actions/MessageAction";

@connect((state) => ({
  ConversationReducer: state.ConversationInfoReducer,
  messageReducer: state.MessageReducer,
  ChatConfigurationReducer: state.ChatConfigurationReducer,
  staffReducer: state.StaffReducer,
  tagsReducer: state.TagReducer,
  projectReducer: state.ProjectReducer,
}))
class AnywhereworksContainer extends Component {
  constructor() {
    super();
    this.state = {
      conversationId: "",
      conversationMap: {},
      interactionId: "",
      projectId: "",
      agentInfo: {},
      socketId: "",
      isConnected: false,
      params: {},
      isSubscriptionRequested: false,
      displayVisitorDetails: false,
      autoDisconnectOverflowChatTimer: 0,
      isAcceptedMessageSent: false,
    };
    this.checkAndSubscribeAgent = this.checkAndSubscribeAgent.bind(this);
    this.showVisitorDetails = this.showVisitorDetails.bind(this);
    this.hideVisitorDetails = this.hideVisitorDetails.bind(this);
    this.sendAcceptedMessage = this.sendAcceptedMessage.bind(this);
    this.getChatMessage = this.getChatMessage.bind(this);
  }
  componentWillMount() {
    this.setLocalState(this.props, undefined, () => {
      console.log("this.props:::::: ", this.props);
      this.props.dispatch(ConnectionAction.init());
      this.props.dispatch(requestProjectById(this.state.projectId));
      if (
        !this.props.ConversationReducer.isSingleFetching &&
        this.props.messageReducer.isSubscribedToChannels
      )
        this.props.dispatch(
          ConversationInfoAction.conversationRequest({
            isAwWindow: true,
            conversationId: this.state.conversationId,
          })
        );

      if (
        !this.props.ChatConfigurationReducer.isFetching &&
        !this.props.ChatConfigurationReducer.isFetched &&
        this.state.projectId.length
      )
        this.props.dispatch(
          requestChatConfiguration(this.state.projectId.slice(3), {}, true)
        );
      if (!this.props.staffReducer.dashboardAgents[this.state.projectId])
        this.props.dispatch(
          staffListRequest(
            this.state.projectId,
            false,
            this.state.agentInfo,
            true
          )
        );

      if (
        !this.props.tagsReducer.isFetching &&
        !this.props.tagsReducer.isFetched
      )
        this.props.dispatch(requestTagList(this.state.projectId, false, true));
    });
    this.checkAndSubscribeAgent();
  }
  componentWillReceiveProps(nextProps) {
    this.setLocalState(nextProps, this.props, () => {
      if (
        !this.state.conversationMap[this.state.conversationId] &&
        !nextProps.ConversationReducer.isSingleFetching &&
        nextProps.messageReducer.isSubscribedToChannels
      )
        nextProps.dispatch(
          ConversationInfoAction.conversationRequest({
            isAwWindow: true,
            conversationId: this.state.conversationId,
          })
        );
      this.checkAndSubscribeAgent();
      if (
        !this.state.isAcceptedMessageSent &&
        nextProps.messageReducer.isSubscribedToChannels &&
        !nextProps.messageReducer.isAcceptedMessageSent &&
        this.state.conversationMap[this.state.conversationId]
      )
        this.sendAcceptedMessage();
    });
  }
  setLocalState(nextProps, props, callback) {
    let conversationId = nextProps.location.query.conversation_id
      ? nextProps.location.query.conversation_id
      : "";
    let interactionId = nextProps.location.query.connection_id
      ? nextProps.location.query.connection_id
      : "";
    let isOverflowChat = nextProps.location.query.isOverflowChat;
    let overflowSkill = nextProps.location.query.overflowSkill;
    let subAccountNumber = nextProps.location.query.subAccountNumber;
    let conversationMap =
      nextProps.ConversationReducer.conversationObject.conversationMap;
    let autoDisconnectOverflowChatTimer =
      nextProps.ChatConfigurationReducer.data &&
      nextProps.ChatConfigurationReducer.data[this.state.projectId] &&
      nextProps.ChatConfigurationReducer.data[this.state.projectId][
        "autoDisconnectOverflowChatTimer"
      ]
        ? nextProps.ChatConfigurationReducer.data[this.state.projectId][
            "autoDisconnectOverflowChatTimer"
          ]
        : 0;
    let projectId = nextProps.location.query.project_id;
    let agentInfo = {};
    let contactsMap =
      nextProps.ConversationReducer.conversationObject.contactsMap;
    let conversation = conversationMap[conversationId];
    let contact = conversation ? contactsMap[conversation.visitorId] : {};
    agentInfo.key = nextProps.location.query.agent_id;
    agentInfo.userId = nextProps.location.query.agent_id;
    agentInfo.firstName = nextProps.location.query.agent_first_name;
    agentInfo.lastName = nextProps.location.query.agent_last_name;
    agentInfo.name = agentInfo.firstName + " " + agentInfo.lastName;
    agentInfo.photoUrl = nextProps.location.query.agent_photo_url;
    agentInfo.agentLogin = nextProps.location.query.agent_login;
    agentInfo.login = nextProps.location.query.agent_login;
    agentInfo.messageFrom = MESSAGE_FROM.AGENT;
    agentInfo.projectId = nextProps.location.query.project_id;
    agentInfo.contactId = nextProps.location.query.agent_id;
    agentInfo.aliasName = nextProps.location.query.alias_name;
    let currentStaff = {};
    if (
      nextProps.staffReducer.dashboardAgents[this.state.projectId] &&
      nextProps.staffReducer.dashboardAgents[this.state.projectId][
        agentInfo.key
      ]
    )
      currentStaff =
        nextProps.staffReducer.dashboardAgents[this.state.projectId][
          agentInfo.key
        ];
    else if (
      nextProps.staffReducer.overflowAgents &&
      nextProps.staffReducer.overflowAgents[agentInfo.key]
    )
      currentStaff = nextProps.staffReducer.overflowAgents[agentInfo.key];

    this.setState(
      {
        ...this.state,
        conversationId: conversationId,
        conversationMap: conversationMap,
        interactionId: interactionId,
        projectId,
        agentInfo: agentInfo,
        isOverflowChat: isOverflowChat,
        overflowSkill: overflowSkill,
        subAccountNumber: subAccountNumber,
        currentStaff,
        contact: contact,
        //project         : nextProps.projectReducer.projectList.find(project=>project.key == projectId),
        socketId: nextProps.messageReducer.socketId,
        autoDisconnectOverflowChatTimer: autoDisconnectOverflowChatTimer,
        params: { ...nextProps.params, projectkey: getProjectKey(projectId) },
        isConnected:
          (nextProps.messageReducer.isConnected &&
            props &&
            !props.messageReducer.isConnected) ||
          this.state.isConnected
            ? true
            : false,
      },
      callback
    );
  }
  checkAndSubscribeAgent() {
    if (
      this.state.isConnected &&
      this.state.projectId &&
      !this.state.isSubscriptionRequested &&
      isValidObject(this.state.currentStaff) /* && this.state.project */
    ) {
      let agentInfo = Object.assign({}, this.state.agentInfo);
      agentInfo.staffType = this.state.currentStaff.staffType;
      let { isOverflowChat, overflowSkill, subAccountNumber } = this.state;

      this.props.dispatch(
        ConnectionAction.subscribe({
          isAwWindow: true,
          socketId: this.state.socketId,
          agentInfo: agentInfo,
          projectIds: [this.state.projectId],
          isOverflowChat: this.state.isOverflowChat,
        })
      );
      this.props.dispatch(
        addContainerInfo({ isOverflowChat, overflowSkill, subAccountNumber })
      );
      this.state.isSubscriptionRequested = true;
    }
  }
  sendAcceptedMessage() {
    let conversationId = this.state.conversationId;

    let agentId = this.state.agentInfo.key;
    let conversationObject = this.state.conversationMap[conversationId];

    if (conversationObject.assignedTo == agentId) return;

    /* this.props.dispatch( showVoicebox( { message : 'Assigning Chat...', dismissAfter : 2000 } ) ); */

    let assigningMessage = this.getChatMessage(
      conversationObject,
      agentId,
      MESSAGE_TYPE.chat_assigned
    );
    assigningMessage["isAlreadyAssigned"] = false;

    let joiningMessage = this.getChatMessage(
      conversationObject,
      agentId,
      MESSAGE_TYPE.staff_joined_conversation
    );

    if (conversationObject) {
      conversationObject.assignedTo = agentId;
      conversationObject.isModified = false;
    }

    this.props.dispatch(
      acceptChat({
        conversationObject: conversationObject,
        assigningMessage: assigningMessage,
        joiningMessage: joiningMessage,
        answeredAgent: agentId,
      })
    );

    this.props.dispatch(
      sendMessage(joiningMessage, {
        echoChannels: [
          "private/agent/LS-" + getProjectKey(joiningMessage.projectId),
        ],
      })
    );
  }

  getChatMessage(conversation, agentId, type) {
    let userData = this.state.agentInfo;
    let { projectId, visitorId, key } = conversation;

    return {
      type: type,
      channel: "private/visitor/" + projectId + "/" + visitorId,
      message: "",
      actionTo: agentId,
      senderId: agentId,
      conversationId: key,
      messageFrom: MESSAGE_FROM.AGENT,
      messageId: generateMessageId(),
      visitorId: visitorId,
      projectId: projectId,
      messageStatus: "CHAT_SENDING",
      interactionType: "DASH_BOARD",
      agentDetails: {
        firstName: userData.firstName,
        lastName: userData.lastName,
        photoUrl: userData.photoUrl,
        aliasName: userData.aliasName,
      },
    };
  }

  showVisitorDetails() {
    this.setState({ ...this.state, displayVisitorDetails: true });
  }
  hideVisitorDetails() {
    this.setState({ ...this.state, displayVisitorDetails: false });
  }

  render() {
    let conversationId = this.state.conversationId;
    let conversationMap = this.state.conversationMap;
    let interactionId = this.state.interactionId;
    let contact = this.state.contact;
    let visitorId =
      conversationMap && conversationMap[conversationId]
        ? conversationMap[conversationId].visitorId
        : "";
    let overflowedAgent = this.state.agentInfo;
    let autoDisconnectOverflowChatTimer = this.state
      .autoDisconnectOverflowChatTimer;
    return (
      <AnywhereworksComponent
        conversationId={conversationId}
        visitorId={visitorId}
        interactionId={interactionId}
        params={this.state.params}
        contact={contact}
        overflowedAgent={overflowedAgent}
        showVisitorDetails={this.showVisitorDetails}
        hideVisitorDetails={this.hideVisitorDetails}
        displayVisitorDetails={this.state.displayVisitorDetails}
        autoDisconnectOverflowChatTimer={autoDisconnectOverflowChatTimer}
      />
    );
  }
}
export default AnywhereworksContainer;
