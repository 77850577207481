import React, { useRef, useEffect } from "react";
import {
  ErrorIcon,
  DisableIcon,
  RemoveFieldIcon,
} from "../../commons/Icons.js";

const ExtraFields = (props) => {
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.setFocus) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({ block: "start" });
    }
  }, []);

  const removeEmail = () => {
    if (emailRef && emailRef.current) {
      emailRef.current.style.display = "none";
      let id = "";
      if (props.singleField) id = props.id;
      else id = props.email.key;
      props.removeContactMethod(id, false);
    }
  };

  const removePhoneNumber = () => {
    if (phoneRef && phoneRef.current) {
      phoneRef.current.style.display = "none";
      let isMobileNumber = false;
      let id = "";
      if (props.singleField) {
        isMobileNumber = props.title === "mobilenumber" ? true : false;
        id = props.id;
      } else {
        isMobileNumber = props.phone.title === "mobilenumber" ? true : false;
        id = props.phone.key;
      }
      props.removeContactMethod(id, isMobileNumber);
    }
  };

  const validateInput = (e) => {
    if (
      e.target.dataset.type === "phone" ||
      e.target.dataset.type === "Phone"
    ) {
      let updatedNumber = e.target.value;
      let validNumber = /^[0-9-+() ]*$/gm.test(updatedNumber);
      if (!validNumber) {
        e.preventDefault();
        e.stopPropagation();
      } else props.setContactMethod(e);
    } else props.setContactMethod(e);
  };

  const handleKeyPress = (e) => {
    if (
      e.target.dataset.type === "phone" ||
      e.target.dataset.type === "Phone"
    ) {
      let updatedNumber = `${e.target.value}${e.key}`;
      let validNumber = /^[0-9-+() ]*$/gm.test(updatedNumber);
      if (!validNumber) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  if (!props.singleField) {
    let email = props.email;
    let phoneNumber = props.phone;
    let emailKey = props.email.key;
    let phoneKey = props.phone.key;
    return (
      <div className="cs-input-inlineblock-field">
        <div
          className={
            "cs-input-field " + (props.disable ? "cs-disabled-field" : "")
          }
          ref={emailRef}
        >
          <label>Email</label>
          <input
            className="fs-exclude"
            type="text"
            data-id={emailKey}
            data-type={"email"}
            data-title={email.title}
            Value={email ? email.mailId : ""}
            onInput={props.setContactMethod}
            onBlur={props.handleEmailChange}
            onKeyDown={props.removeErrorField}
            disabled={props.disable}
            onFocus={props.setActiveField}
          />
          {props.disable ? (
            <DisableIcon />
          ) : (
            <a href="javascript:void(0)" onClick={removeEmail}>
              <RemoveFieldIcon />
            </a>
          )}
          <span>
            <ErrorIcon />
            Enter a valid email id.
          </span>
        </div>
        <div className="cs-input-field " ref={phoneRef}>
          <label>Phone</label>
          <input
            className="fs-exclude"
            type="text"
            data-id={phoneKey}
            data-type={"phone"}
            data-title={phoneNumber ? phoneNumber.title : ""}
            Value={phoneNumber ? phoneNumber.number : ""}
            onInput={validateInput}
            onBlur={props.handleNumberChange}
            onKeyPress={handleKeyPress}
            onKeyDown={props.removeErrorField}
            onFocus={props.setActiveField}
          />
          <a href="javascript:void(0)" onClick={removePhoneNumber}>
            <RemoveFieldIcon />
          </a>
          <span>
            <ErrorIcon />
            Enter a valid phone number.
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={
          "cs-input-field " + (props.disable ? "cs-disabled-field" : "")
        }
        ref={props.type === "Phone" ? phoneRef : emailRef}
      >
        <label>{props.type}</label>
        <input
          className="fs-exclude"
          type="text"
          ref={inputRef}
          data-id={props.id}
          data-type={props.type}
          data-title={props.title}
          Value={props.value ? props.value : ""}
          onInput={validateInput}
          onBlur={props.handleChange}
          onKeyDown={props.removeErrorField}
          onKeyPress={handleKeyPress}
          disabled={props.disable}
          onFocus={props.setActiveField}
        />
        {props.disable ? (
          <DisableIcon />
        ) : (
          <a
            href="javascript:void(0)"
            onClick={props.type === "Phone" ? removePhoneNumber : removeEmail}
          >
            <RemoveFieldIcon />
          </a>
        )}
        <span>
          <ErrorIcon />
          Enter a valid {props.type === "Phone" ? "phone number" : "email id"}.
        </span>
      </div>
    );
  }
};

export default ExtraFields;
