export const NOTIFICATION_SETTINGS_REQUESTED =
  "NOTIFICATION_SETTINGS_REQUESTED";
export const NOTIFICATION_SETTINGS_RESPONSE = "NOTIFICATION_SETTINGS_RESPONSE";
export const NOTIFICATION_SETTINGS_FAILED = "NOTIFICATION_SETTINGS_FAILED";

export const NOTIFICATION_UPDATE_FAILED = "NOTIFICATION_UPDATE_FAILED";
export const NOTIFICATION_UPDATE_RESPONSE = "NOTIFICATION_UPDATE_RESPONSE";
export const NOTIFICATION_UPDATE_REQUESTED = "NOTIFICATION_UPDATE_REQUESTED";

export const getNotificationSettings = () => ({
  type: NOTIFICATION_SETTINGS_REQUESTED,
});

export const notificationSettingResponse = (notificationSettings) => ({
  type: NOTIFICATION_SETTINGS_RESPONSE,
  notificationSettings,
});

export const notificationSettingFailed = () => ({
  type: NOTIFICATION_SETTINGS_FAILED,
});

export const updateNotificationSettings = (notificationSettings) => ({
  type: NOTIFICATION_UPDATE_REQUESTED,
  notificationSettings,
});

export const updateNotificationSettingResponse = (notificationSettings) => ({
  type: NOTIFICATION_UPDATE_RESPONSE,
  notificationSettings,
});

export const updateNotificationSettingFailed = () => ({
  type: NOTIFICATION_UPDATE_FAILED,
});
