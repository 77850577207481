import { connect } from 'formik'
import { FormikContextType } from 'formik/dist/types'
import { ComponentType, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IFormValues } from 'src/features/wizard/setup/forms/Setup/data'
import { updateValues } from 'src/features/wizard/setup/store/actions'
import { selectValues } from 'src/features/wizard/setup/store/selectors'
import { IAppState } from 'src/utils/state/store'

const SyncValuesComponent = ({
  formik,
}: {
  formik: FormikContextType<IFormValues>
}) => {
  const { values, setFieldValue } = formik
  const dispatch = useDispatch()
  const setupValues = useSelector((state: IAppState) =>
    selectValues(state.setup)
  )

  useEffect(() => {
    dispatch(updateValues(values))
  }, [values])

  useEffect(() => {
    if (values.icon.url !== setupValues.icon.url) {
      setFieldValue('icon', {
        color: setupValues.icon.color,
        url: setupValues.icon.url,
      })
    }
  }, [setupValues.icon.url])

  return null
}

export const SyncValues = connect(SyncValuesComponent) as ComponentType
