import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { initializeAuth } from 'src/features/authorization/store/actions'
import { IProps } from 'src/features/returningFlow/components/ReturningFlow/types'
import { WelcomeBackSection } from 'src/features/returningFlow/components/WelcomeBackSection'
import { initializeReturningFlow } from 'src/features/returningFlow/store/actions'
import { selectIsInitialised } from 'src/features/returningFlow/store/selectors'
import { initializeWidgetInstallation } from 'src/features/wizard/install/store/actions'
import { WidgetInstallationStatus } from 'src/features/wizard/setup/api/types'
import { initializeWidgetSetup } from 'src/features/wizard/setup/store/actions'
import { normalizeWidgetDataToSetupValues } from 'src/features/wizard/setup/store/normalize'
import { IAppState } from 'src/utils/state/store'

export const ReturningFlowComponent: FC<IProps> = ({
  userId,
  widgetData,
  isLoadingAfterSignup,
  isInitialized,
  dispatchInitializeReturningFlow,
  dispatchInitializeWidgetSetup,
  dispatchInitializeWidgetInstallation,
  dispatchInitializeAuth,
}) => {
  const getIsWidgetInstalled = () =>
    widgetData?.installationStatus === WidgetInstallationStatus.active

  const getWidgetId = () => widgetData.key?.split('-')[1]

  useEffect(() => {
    dispatchInitializeReturningFlow(
      widgetData.createdDate,
      getIsWidgetInstalled()
    )
    dispatchInitializeWidgetSetup(
      normalizeWidgetDataToSetupValues(widgetData),
      widgetData.websiteScreenshotURL,
      widgetData.chatWidgetIconURL === 'DEFAULT'
        ? ''
        : widgetData.chatWidgetIconURL,
      `#${widgetData.widgetThemeColor}`
    )
    dispatchInitializeWidgetInstallation(
      widgetData.domainName ? widgetData.domainName : 'https://'
    )
    dispatchInitializeAuth(getWidgetId(), userId)
  }, [])

  if (!isInitialized) {
    return null
  }

  return <WelcomeBackSection isLoadingAfterSignup={isLoadingAfterSignup} />
}

const mapStateToProps = (state: IAppState) => ({
  isInitialized: selectIsInitialised(state.returning),
})

const mapDispatchToProps = {
  dispatchInitializeReturningFlow: initializeReturningFlow,
  dispatchInitializeWidgetSetup: initializeWidgetSetup,
  dispatchInitializeWidgetInstallation: initializeWidgetInstallation,
  dispatchInitializeAuth: initializeAuth,
}

export const ReturningFlow = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturningFlowComponent)
