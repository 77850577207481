import { ADD_ERROR_STACK } from "../actions/GlobalErrorAction.js";

const GlobalErrorReducer = (state = { errorCapturedList: [] }, action) => {
  // console.log("came till global reducer" + JSON.stringify(action));
  switch (action.type) {
    case ADD_ERROR_STACK:
      return {
        ...state,
        errorCapturedList: [...state.errorCapturedList, action.error.stack],
      };
  }
  return state;
};

export default GlobalErrorReducer;
