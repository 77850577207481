import * as R from 'ramda'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${R.path(['theme', 'color', 'mystic'])};
  font-family: ${R.path(['theme', 'font', 'family'])};
  font-style: normal;
  font-weight: 700;
  font-size: 40rem;
  line-height: 48rem;
  letter-spacing: -2.6rem;
  opacity: 0.7;
  margin-left: -2.9rem;
  margin-bottom: -14rem;
  white-space: nowrap;
`
