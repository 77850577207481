import React, { useState , Fragment} from "react";
import Filters from "./Filters.js";
import MoreOptions from "./MoreOptions.jsx";
import FilterHeader from "../filters/FilterHeader.jsx";
import NewCompaignPopup from "../../campaign/components/NewCampaignPopup";//"../components/NewCampaignPopup";
import { CampaingnIcon } from "../../commons/Icons.js";
import {  useDispatch } from "react-redux";
import * as campaignActions from "../../actions/BroadcastMessageStatsAction";
import { IS_CAMPAIGN_FROM_DASHBOARD, STRINGIFIED_BOOL } from "../../commons/Constants.js";

const ContactsFilter = (props) => {
  const { setHeaderHeight } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [showMoreOptions] = useState(true);
  const dispatch = useDispatch();
  const {groupId} = props.params;

  const openPopup = () => {
    setShowPopup(true);
    localStorage.setItem(IS_CAMPAIGN_FROM_DASHBOARD, groupId);
    dispatch(campaignActions.updateCampaignFromDashboard(true));
  };

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem(IS_CAMPAIGN_FROM_DASHBOARD, STRINGIFIED_BOOL.FALSE);
    dispatch(campaignActions.updateCampaignFromDashboard(false));
  };
  const setHeight = (element) => {
    if (element) setHeaderHeight(element.clientHeight);
  };
  return (
    <Fragment>
      <header ref={setHeight} id="filter-header">
        <FilterHeader {...props} />
        <Filters {...props} />
        <button
            id="runCampaignButton"
            onClick={openPopup}
            className="cs-btn cs-btn-xs cs-primary-btn run-btn"
          >
            <CampaingnIcon />
            Run Campaign
        </button>
        <MoreOptions {...props} showMoreOptions={showMoreOptions} />
        </header>
      <div>
        {showPopup ? <NewCompaignPopup {...props} closePopup={closePopup} /> : ""}
      </div>
    </Fragment>
  );
};

export default ContactsFilter;
