import React, { useEffect, useState, useRef } from "react";
import WidgetColorPopup from "./WidgetColor/WidgetColor.jsx";
import WidgetStylePopup from "./widgetstyle/WidgetStyle.jsx";
import PrechatSurveyComponent from "./prechatsurvey/PrechatSurvey.jsx";
import { useChatConfigurationEntity } from "../hooks/useChatConfigurationEntity";
import { useSelector, useDispatch } from "react-redux";
import * as VoiceboxActions from "../../actions/VoiceboxAction";
import * as util from "../../commons/Utility.js";
import { CameraUploadButton, ErrorIcon } from "../../commons/Icons";
import PreviewWidget from "../previewWidget/PreviewWidget.jsx";
import { EventEmitter } from "../../settings/commons/event";
import { updateWebsiteUrlRequest } from "../actions/ChatConfigurationAction";
import WidgetPosition from "./WidgetPosition/WidgetPosition.jsx";
import WidgetType from "./widgetType/WidgetType.jsx";
import { WIDGET_TYPE } from "../../commons/Constants.js";
import { Fragment } from "react";

const CustomiseWidget = (props) => {
  const { showWidgetColorPopUp, setShowWidgetColorPopUp } = props;
  const { showWidgetIconPopUp, setShowWidgetIconPopUp } = props;
  const projectId = "LS-" + props.params.projectkey;
  const { chatConfiguration, isUpdating } =
    useChatConfigurationEntity(projectId) || {};
  const dispatch = useDispatch();
  let { user, staffReducer, chatConfigurationReducer } = useSelector(
    (state) => ({
      user: state.UserReducer,
      staffReducer: state.StaffReducer,
      chatConfigurationReducer: state.ChatConfigurationReducer,
    })
  );

  const [isLoadingWidgetIcon, setIsLoadingWidgetIcon] = useState(false);
  const [userUploadedImage, setUserUploadedImage] = useState(undefined);
  const [opacityValue, setOpacityValue] = useState(1);

  const [widgetHeader, setWidgetHeader] = useState("");
  const [widgetBubbleContent, setWidgetBubbleContent] = useState("");

  const [isUrlBoxfocused, setIsUrlBoxfocused] = useState(false);
  const [isUrlError, setIsUrlError] = useState(false);
  const [domainName, setDomainName] = useState("");

  const urlRef = useRef(null);

  const getWidgetColorPopUp = () => {
    return showWidgetColorPopUp ? (
      <WidgetColorPopup
        {...props}
        setShowWidgetColorPopUp={setShowWidgetColorPopUp}
      />
    ) : (
      ""
    );
  };

  const getWidgetStylePopUp = () => {
    return showWidgetIconPopUp ? (
      <WidgetStylePopup
        {...props}
        setShowWidgetIconPopUp={setShowWidgetIconPopUp}
      />
    ) : (
      ""
    );
  };

  const getWidgteStyleIcon = () => {
    if (chatConfiguration) {
      let backGroundColor =
        chatConfiguration.chatWidgetIconURL !== "DEFAULT"
          ? "#ffffff"
          : "#" + chatConfiguration.widgetThemeColor;
      return (
        <figure
          className={
            chatConfiguration.chatWidgetIconURL !== "DEFAULT" ? "" : "chat-icon"
          }
          style={{
            borderColor: "#" + chatConfiguration.widgetThemeColor,
            backgroundColor: backGroundColor,
          }}
        >
          <img
            style={{
              display:
                chatConfiguration.chatWidgetIconURL !== "DEFAULT"
                  ? "block"
                  : "none",
              borderColor: "#" + chatConfiguration.widgetThemeColor,
              cursor: "pointer",
            }}
            src={chatConfiguration.chatWidgetIconURL}
          />
        </figure>
      );
    } else {
      return "";
    }
  };

  const isValidUser = () => {
    let staffMap = staffReducer.dashboardAgents
      ? staffReducer.dashboardAgents[util.getProjectId(props.params.projectkey)]
      : {};
    if (staffMap && user.data && user.data.id) {
      let currentStaff = staffMap[user.data.id];
      let currentStaffPermission = currentStaff.userPermission
        ? currentStaff.userPermission
        : "RESTRICTED";

      return currentStaffPermission === "FULL_ACCESS";
    }
    return false;
  };

  const openColorPopup = () => {
    if (isValidUser()) {
      setShowWidgetColorPopUp(true);
    } else {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "You should be an owner to update the chat configuration!",
          dismissAfter: 3000,
        })
      );
    }
  };

  const openStylePopup = () => {
    if (isValidUser()) {
      setShowWidgetIconPopUp(true);
    } else {
      dispatch(
        VoiceboxActions.showVoicebox({
          message: "You should be an owner to update the chat configuration!",
          dismissAfter: 3000,
        })
      );
    }
  };

  const getSelectedColor = () => {
    let configColor = chatConfiguration
      ? chatConfiguration.widgetThemeColor
      : "6699ff";
    return configColor;
  };

  const getSelectedIcon = () => {
    let configIcon = chatConfiguration
      ? chatConfiguration.chatWidgetIconURL
      : "default";
    configIcon =
      isLoadingWidgetIcon && userUploadedImage ? userUploadedImage : configIcon;
    return configIcon;
  };

  const getSelectedType = () => {
    return chatConfiguration ? chatConfiguration.widgetType : WIDGET_TYPE.DEFAULT;
  }

  const loadingImageHandler = ({ value, imageData, imageOpacity }) => {
    //    console.log("Value :" , value);
    //    console.log("Image Opacity  :" , imageOpacity);
    setIsLoadingWidgetIcon(value);
    setUserUploadedImage(imageData);
    setOpacityValue(imageOpacity);
  };

  // url functionality ---------------

  useEffect(() => {
    window.addEventListener("keyup", onEnter);
    return () => {
      window.removeEventListener("keyup", onEnter);
    };
  }, []);

  const onEnter = (event) => {
    if (event.key === "Enter" && event.keyCode == 13) {
      if (event.target.dataset.field === "url") {
        saveUrl();
      }
    }
  };

  useEffect(() => {
    // const domainName = chatConfiguration.data.hasOwnProperty(projectKey) ? chatConfiguration.data[projectKey].domainName : "";
    const domainName =
      chatConfiguration.hasOwnProperty("domainName") &&
      chatConfiguration.domainName != "nourl"
        ? chatConfiguration.domainName
        : "";
    setDomainName(domainName);
    urlRef.current.previousValue = domainName;
    // setChatConfig(chatConfiguration.data.hasOwnProperty(projectKey) ? chatConfiguration.data[projectKey] : {});
  }, [chatConfiguration, chatConfigurationReducer.data]);

  const getUrlBoxClassName = () => {
    if (isUrlBoxfocused) return "cs-input-field cs-active-field";
    if (isUrlError) return "cs-input-field cs-error-field";
    else return "cs-input-field";
  };

  const saveUrl = () => {
    if (!urlRef) return;
    let domainName = urlRef ? urlRef.current.value : "";

    //Allow Empty Urls
    // if(!domainName || domainName.trim().length === 0){
    //     setIsUrlError(true);
    //     setIsUrlBoxfocused(false)
    // } else
    {
      setIsUrlError(false);
      setIsUrlBoxfocused(false);
      if (
        !domainName ||
        (domainName.startsWith("http") && isValidChatPromptURL(domainName))
      ) {
        if (canSaveWebsiteUrl(domainName)) {
          domainName = domainName.toLowerCase();
          urlRef.current.previousValue = domainName;
          setDomainName(domainName);
          if (domainName == "") domainName = "nourl";
          let websiteDetailsToUpdate = {
            domainName: domainName,
            projectId: "LS-" + props.params.projectkey,
          };

          dispatch(updateWebsiteUrlRequest(websiteDetailsToUpdate));
        }
      } else {
        setIsUrlError(true);
        setIsUrlBoxfocused(false);
      }
    }
  };

  const setUrlValue = (event) => {
    let domainName = urlRef.current.value;
    setDomainName(domainName);
  };

  const canSaveWebsiteUrl = (widgetUrl) => {
    // if(!widgetUrl) return false; // Allow empty urls
    return urlRef.current.previousValue !== widgetUrl.trim();
  };

  const isValidChatPromptURL = (url) => {
    try {
      let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
      return pattern.test(url);
    } catch (e) {
      return false;
    }
  };

  const updateWidgetHeader = (header) => {
    setWidgetHeader(header);
  }

  const updateWidgetBubbleContent = (bubbleText) => {
    setWidgetBubbleContent(bubbleText);
  }

  EventEmitter.subscribe("handleUploadingImage", loadingImageHandler);
  return (
    <Fragment>
      <div class="cs-settings-wrp">
        <section class="widget-configuration">
          <h3>Customize your widget</h3>
          <p>Customize your widget to suit your brand</p>
          <div className="customize-scroll">
            <div>
              <div className={getUrlBoxClassName()}>
                <label>Website URL</label>
                <input
                  className="fs-exclude"
                  ref={urlRef}
                  data-field="url"
                  onFocus={() => {
                    setIsUrlBoxfocused(true);
                  }}
                  onBlur={saveUrl}
                  type="text"
                  value={domainName}
                  onChange={setUrlValue}
                  placeholder="Website URL"
                />
                <span>
                  <ErrorIcon />
                  Please enter a valid URL (https://yourwebsite.com)
                </span>
              </div>
            </div>
            <WidgetColorPopup {...props} />
            <WidgetStylePopup {...props} />
            <PreviewWidget
              projectId={projectId}
              widgetColor={getSelectedColor()}
              widgetIcon={getSelectedIcon()}
              opacity={opacityValue}
              widgetPosition={chatConfiguration.widgetPosition}
              widgetType={getSelectedType()}
              widgetHeader={widgetHeader}
              widgetBubbleContent={widgetBubbleContent}
            />
            <WidgetType {...props} />
            <WidgetPosition
              projectId={projectId}
              updateWidgetHeader={updateWidgetHeader}
              updateWidgetBubbleContent={updateWidgetBubbleContent}
              widgetBubbleContent={widgetBubbleContent}
              widgetHeader={widgetHeader}
            />
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default CustomiseWidget;
