export const columnAlise = {
  fullName: "Name",
  email: "Email",
  phoneNumber: "Phone",
  companyName: "Company Name",
  createdDate: "Created Date",
  firstContacted: "First Contacted",
  lastSeenTime: "Last Seen",
  lastContacted: "Last Contacted",
  totalVisit: "Web Sessions",
  os: "Operating System",
  state: "State",
  country: "Country",
  campaignName: "Campaign Name",
  campaignMedium: "Campaign Medium",
  campaignSource: "Campaign Source",
  campaignUrl_keyword: "Campaign URL",
  iosDevice: "Ios Device",
  iosAppVersion: "Ios App Version",
  iosOsVersion: "Ios Os Version",
  iosWebSessions: "Ios Web Sessions",
  androidDevice: "Android Device",
  androidAppVersion: "Android App Version",
  androidOSVersion: "Android Os Version",
  androidWebSessions: "Android Web Sessions",
  companyIndustry: "Company Industry",
  companyKey: "Company Key",
  companySize: "Company Size",
  pageTitle: "Page Title",
  lastPageTitle: "Last Page Title",
  sourceUrl_keyword: "Landing Page URL",
  currentUrl_keyword: "Current Page URL",
  lastPageUrl_keyword: "Last Page URL",
  browserName: "Browser Name",
  browserVersion: "Browser Version",
  browserLanguage: "Browser Language",
  webSessions: "Browser Web Sessions",
  tags: "Tags",
  emailSubscription: "Email Subscription"
};

export const fieldDetails = {
  properties: {
    companyIndustry: {
      type: "text",
    },
    companyKey: {
      type: "text",
    },
    companyName: {
      type: "text",
    },
    companySize: {
      type: "Number",
    },
    country: {
      type: "text",
    },
    createdDate: {
      type: "date",
    },
    displayPhoneNumber: {
      type: "text",
    },
    email: {
      type: "text",
    },
    firstContacted: {
      type: "date",
    },
    firstName: {
      type: "text",
    },
    ip: {
      type: "ip",
    },
    key: {
      type: "text",
    },
    lastContacted: {
      type: "date",
    },
    lastName: {
      type: "text",
    },
    last_seen_user_agent_keyword: {
      type: "text",
    },
    latitude: {
      type: "float",
    },
    location: {
      type: "geo_point",
    },
    longitude: {
      type: "float",
    },
    modifiedDate: {
      type: "date",
    },
    phoneNumber: {
      type: "text",
    },
    projectId: {
      type: "text",
    },
    sourceUrl_keyword: {
      type: "text",
    },
    currentUrl_keyword: {
      type: "text",
    },
    fullName: {
      type: "text",
    },
    state: {
      type: "text",
    },
    totalVisit: {
      type: "Number",
    },
    type: {
      type: "peopleType",
    },
    userId: {
      type: "text",
    },
    browserName: {
      type: "text",
    },
    browserVersion: {
      type: "text",
    },

    os: {
      type: "text",
    },
    browserLanguage: {
      type: "text",
    },
    lastPageTitle: {
      type: "text",
    },
    lastPageUrl_keyword: {
      type: "text",
    },
    pageTitle: {
      type: "text",
    },
    androidDevice: {
      type: "text",
    },
    androidAppVersion: {
      type: "text",
    },
    androidOSVersion: {
      type: "text",
    },
    lastSeenTime: {
      type: "date",
    },
    iosDevice: {
      type: "text",
    },
    iosAppVersion: {
      type: "text",
    },
    iosOsVersion: {
      type: "text",
    },
    iosWebSessions: {
      type: "Number",
    },
    androidWebSessions: {
      type: "Number",
    },
    webSessions: {
      type: "Number",
    },
    deviceHeight: {
      type: "Number",
    },
    deviceWidth: {
      type: "Number",
    },
    viewportHeight: {
      type: "Number",
    },
    viewportWidth: {
      type: "Number",
    },
    campaignName: { type: "text" },
    campaignMedium: { type: "text" },
    campaignSource: { type: "text" },
    campaignUrl_keyword: { type: "text" },
    tags: {
      type: "Tags",
    },
    profileImage: {
      type: "Image",
    },
  },
};
