import * as R from 'ramda'
import styled from 'styled-components'
import is from 'typescript-styled-is'
import { mq } from 'src/features/ui/styles/mq'

export const Container = styled.div`
  border: 0.1rem solid ${R.path(['theme', 'color', 'mystic'])};
  box-sizing: border-box;
  border-radius: 0.9rem;
  display: flex;
  position: relative;
`

export const Label = styled.button<{ $width: number; isActive: boolean }>`
  display: inline-block;
  width: ${R.prop('$width')}%;
  text-align: center;
  padding: 0.9rem 0;
  font-weight: 500;
  z-index: 9;
  font-family: ${R.path(['theme', 'font', 'family'])};
  color: ${(props) => props.theme.color[props.color ?? 'codGray']};
  font-size: 1.6rem;
  line-height: 2.4rem;
  background-color: transparent;
  border: none;

  ${is('isActive')`
    color: ${R.path(['theme', 'color', 'dodgerBlue'])};
    font-weight: 700;
  `}

  ${mq.md} {
    padding: 1.8rem 0;
  }
`

export const Highlight = styled.div<{
  $width: number
  $left: number
  isLastSelected: boolean
}>`
  position: absolute;
  left: calc(${R.prop('$left')}% + 0.4rem);
  top: 0;
  background-color: ${R.path(['theme', 'color', 'solitude'])};
  border-radius: 0.9rem;
  margin: 0.4rem 0;
  padding: 0.5rem 0;
  box-sizing: border-box;
  z-index: 5;
  width: calc(${R.prop('$width')}% - 0.8rem);
  height: calc(100% - 0.8rem);
  transition: all 200ms linear;

  ${is('isLastSelected')`
    width: calc(${R.prop('$width')}% - 0.8rem);
  `}

  ${mq.md} {
    padding: 1.4rem 0;
  }
`

export const Content = styled.div`
  padding-top: 1.6rem;

  ${mq.md} {
    padding: 3.2rem 0;
  }
`
