export const GET_STAFF_BY_PROJECT = "GET_STAFF_BY_PROJECT";
export const SET_STAFF_BY_PROJECT = "SET_STAFF_BY_PROJECT";
export const FAILED_STAFF_BY_PROJECT = "FAILED_STAFF_BY_PROJECT";
export const GET_PROJECT_LIST_BY_EMAIL = "GET_PROJECT_LIST_BY_EMAIL";
export const PROJECT_LIST_BY_EMAIL_RESPONSE = "PROJECT_LIST_BY_EMAIL_RESPONSE";
export const PROJECT_LIST_BY_EMAIL_FAILED = "PROJECT_LIST_BY_EMAIL_FAILED";
export const ADD_STYLE_TO_ADMIN_CONTAINER = "ADD_STYLE_TO_ADMIN_CONTAINER";
export const REMOVE_STYLE_FROM_ADMIN_CONTAINER =
  "REMOVE_STYLE_FROM_ADMIN_CONTAINER";
export const requestStaffForProject = (projectId) => ({
  type: GET_STAFF_BY_PROJECT,
  projectId,
});

export const successStaffForProject = (staffList) => ({
  type: SET_STAFF_BY_PROJECT,
  staffList,
});

export const failedStaffForProject = (errorMessage) => ({
  type: FAILED_STAFF_BY_PROJECT,
  errorMessage,
});

export const requestProjectForEmail = (staffEmail) => ({
  type: GET_PROJECT_LIST_BY_EMAIL,
  staffEmail,
});
export const projectForStaffEmailResponse = (projectList) => ({
  type: PROJECT_LIST_BY_EMAIL_RESPONSE,
  projectList,
});

export const projectForStaffEmailFailed = (errorMessage) => ({
  type: PROJECT_LIST_BY_EMAIL_FAILED,
  errorMessage,
});
export const addStyleToAdminContainer = (styleClasses) => ({
  type: ADD_STYLE_TO_ADMIN_CONTAINER,
  styleClasses,
});
export const removeStyleFromAdminContainer = (styleClasses) => ({
  type: REMOVE_STYLE_FROM_ADMIN_CONTAINER,
  styleClasses,
});
