import React, { Component } from "react";
import ReactDom from "react-dom";
import reactCSS from "reactcss";

class ProfileComponent extends Component {
  getProjectImage() {
    return this.props.logoUrl && this.props.logoUrl !== null
      ? this.props.logoUrl
      : "https://storage.googleapis.com/livesupport/chat/images/logo-placeholder.svg";
  }

  render() {
    let { isActive, projectName, projectKey } = this.props;
    return (
      <li className={isActive ? "active" : ""}>
        <div>
          <img src={this.getProjectImage()} />
          <h5>{projectName}</h5>
          <button
            data-projectkey={projectKey}
            className={isActive ? "disable" : ""}
            disabled={isActive}
            onClick={isActive ? "" : this.props.navigateProject}
          >
            {isActive ? "active" : "Switch Profile"}
          </button>
        </div>
      </li>
    );
  }
}

export default ProfileComponent;
