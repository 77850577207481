import React from "react";
import {CompaniesEmptyIcon} from '../../commons/Icons'

const NoCompaniesLoader = (props) => {
    return (
        <section class="placeholder-wrp">
            <div class="placeholder-content">
                <CompaniesEmptyIcon />
                <label>No companies to display</label>
		        <p>Use company insights to provide the best support for your customers.</p>
            </div>
        </section>
    )
}

export default NoCompaniesLoader;