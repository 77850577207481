import React, { Component } from "react";
import ReactDOM from "react-dom";
import Clipboard from "clipboard";
import {
  initiateClipboard,
  isOverflowConnection,
} from "../../commons/Utility.js";
import { showVoicebox } from "../../actions/VoiceboxAction";
import {
  updateUrlPreviewDimensions,
  requestUrlPreviewDimensions,
} from "../../actions/UrlPreviewAction";
import { store } from "../../app";
import { LinkIcon } from "../../commons/Icons.js";

class UrlPreviewNew extends Component {
  constructor() {
    super();
    this.getMeta = this.getMeta.bind(this);
    this.onSourceIconLoadFail = this.onSourceIconLoadFail.bind(this);
    this.loadUrlInNewTab = this.loadUrlInNewTab.bind(this);
    this.state = { showSourceIcon: true };
  }

  componentWillMount() {
    initiateClipboard(
      ".copy-link",
      () => {
        store.dispatch(
          showVoicebox({
            message: "Copied",
            dismissAfter: 1000,
            showBelowHeader: !isOverflowConnection(),
            showFullScreenLoader: isOverflowConnection(),
          })
        );
        Event("", "Others", "Copy", "Copy Icon Clicked");
      },
      () => {
        store.dispatch(
          showVoicebox({
            message: "Please use CTRL/CMD+C to copy",
            dismissAfter: 3000,
            dismissAfter: 1000,
            showBelowHeader: !isOverflowConnection(),
            showFullScreenLoader: isOverflowConnection(),
          })
        );
      }
    );
  }

  onSourceIconLoadFail() {
    this.setState({ showSourceIcon: false });
  }

  getMeta(url) {
    let self = this;
    store.dispatch(
      requestUrlPreviewDimensions({ url: self.props.urlPreviewInfo.requestUrl })
    );
    var img = new Image();
    img.onload = function () {
      let width = this.width;
      let height = this.height;
      store.dispatch(
        updateUrlPreviewDimensions({
          requestUrl: self.props.urlPreviewInfo.requestUrl,
          fetchFailed: false,
          fetchedDimensions: true,
          dimensions: { width, height },
        })
      );
    };
    img.onerror = function () {
      store.dispatch(
        updateUrlPreviewDimensions({
          requestUrl: self.props.urlPreviewInfo.requestUrl,
          fetchFailed: true,
          fetchedDimensions: true,
          dimensions: {},
        })
      );
    };
    img.src = url;
  }
  loadUrlInNewTab(e) {
    let copyButton = document.getElementById(
      "copy_button" + this.props.messageId
    );
    if (
      !ReactDOM.findDOMNode(copyButton).contains(e.target) &&
      e.target.dataset.button_type != "copy_button"
    )
      if (e.currentTarget.dataset.type == "url") {
        let urlPreview = this.props.urlPreviewInfo;
        window.open(urlPreview.requestUrl);
      }
  }
  render() {
    let urlPreview = this.props.urlPreviewInfo;
    let isImageUrlPresent = urlPreview.imageUrl ? true : false;
    let isSquareImg = false;

    if (
      isImageUrlPresent &&
      !this.props.isFromChatHistory &&
      this.state.showSourceIcon
    )
      return (
        <div ref="preview-li"
          onClick={this.loadUrlInNewTab}
          style={{ cursor: "pointer" }}
          data-type="url"
        >
          <section className="preview-file-icon">
            <img
              src={urlPreview.imageUrl}
              onError={this.onSourceIconLoadFail}
            />
            <strong>
              {urlPreview.title}{" "}
              <code
                id={"copy_button" + this.props.messageId}
                data-button_type="copy_button"
                className="copy-link"
                data-clipboard-text={urlPreview.requestUrl}
              >
                <LinkIcon
                  data-delay-hide={0}
                  data-tip="copy"
                  data-class="tooltip info"
                />
              </code>
            </strong>
            <p>{urlPreview.desc}</p>
            <span>{urlPreview.requestUrl}</span>
          </section>
          <div>
            <a href={urlPreview.requestUrl} target="_blank">
              View
            </a>
            {/* <a href="javascriptvoid:(0)">Add to MyQ</a>
                            <a href="javascriptvoid:(0)">Share</a> */}
          </div>
        </div>
      );
    else
      return (
        <div ref="preview-li"
          onClick={this.loadUrlInNewTab}
          style={{ cursor: "pointer" }}
          data-type="url"
        >
          <section class="link-file-icon">
            <strong>
              {urlPreview.title}{" "}
              <code
                id={"copy_button" + this.props.messageId}
                data-button_type="copy_button"
                className="copy-link"
                data-clipboard-text={urlPreview.requestUrl}
              >
                <LinkIcon
                  data-delay-hide={0}
                  data-tip="copy"
                  data-class="tooltip info"
                />
              </code>
            </strong>
            <p>{urlPreview.desc}</p>
            <span>{urlPreview.requestUrl}</span>
          </section>
          <div>
            <a href={urlPreview.requestUrl} target="_blank">
              View
            </a>
            {/* <a href="javascriptvoid:(0)">Add to MyQ</a>
                            <a href="javascriptvoid:(0)">Share</a> */}
          </div>
        </div>
      );
  }
}

export default UrlPreviewNew;
