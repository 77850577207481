import React, { FC, useState } from 'react'
import { connect } from 'react-redux'
import { Perfect } from 'src/features/returningFlow/components/Perfect'
import { Container } from 'src/features/returningFlow/components/ReturningFinished/styled'
import { IProps } from 'src/features/returningFlow/components/ReturningFinished/types'
import { SuccessBullets } from 'src/features/returningFlow/components/SuccessBullets'
import { selectStepsNames } from 'src/features/returningFlow/store/selectors'
import { AnimationFadeIn } from 'src/features/ui/components/Animation'
import { IAppState } from 'src/utils/state/store'

export const ReturningFinishedComponent: FC<IProps> = ({ stepsNames }) => {
  const [shouldRenderBullets, setShouldRenderBullets] = useState(true)

  const handleAnimationFinished = () => setShouldRenderBullets(false)

  if (shouldRenderBullets) {
    return (
      <Container>
        <SuccessBullets
          onComplete={handleAnimationFinished}
          items={stepsNames}
        />
      </Container>
    )
  }

  return (
    <Container>
      <AnimationFadeIn durationMs={400} delayMs={0}>
        <Perfect />
      </AnimationFadeIn>
    </Container>
  )
}

const mapStateToProps = (state: IAppState) => ({
  stepsNames: selectStepsNames(state.returning)!,
})

export const ReturningFinished = connect(mapStateToProps)(
  ReturningFinishedComponent
)
