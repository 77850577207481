import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import MessageTagItem from "./MessageTagItem.jsx";
import {
  getValueFromNestedObject,
  sortObjectArrayInAscendingOrder,
} from "../../../commons/Utility.js";

function MessageTags({ tags, removeOption, isFromMessage, messageDiv, payload }) {
  const { tagDetails } = useSelector((state) => ({
    tagDetails: state.TagReducer,
  }));

  useEffect(()=>{
    if(tags && messageDiv) messageDiv.scrollIntoView({block:'nearest'})
  },[tags])

  const getMessageTags = () => {
    let tagMap = tagDetails.tagMap;
    let messageTags = [];
    let tagsArray = [];
    tags.map((tagKey) => {
      let tagName = getValueFromNestedObject(tagMap, `${tagKey}.name`);
      if (tagName) {
        tagsArray.push({ tagKey, tagName });
      }
    });
    tagsArray = sortObjectArrayInAscendingOrder(tagsArray, "tagName");
    tagsArray.map(({ tagKey, tagName }) => {
      messageTags.push(
        <MessageTagItem
          tagKey={tagKey}
          tagName={tagName}
          removeOption={removeOption}
          payload={payload}
        />
      );
    });
    return messageTags;
  };

  const getClassNameOfTags = () => {
    return isFromMessage ? "message-tags" : "conversation-tags";
  };

  return <ul className={getClassNameOfTags()}>{getMessageTags()}</ul>;
}

export default MessageTags;