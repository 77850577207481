import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { ChatBubble } from 'src/features/ui/components/ChatBubble'
import { WidgetExample } from 'src/features/ui/components/WidgetExample'
import {
  BubbleContainer,
  BubbleContent,
  Container,
  IconContainer,
  Preview,
} from 'src/features/wizard/setup/components/WebsitePreview/styled'
import { IProps } from 'src/features/wizard/setup/components/WebsitePreview/types'
import { WidgetIcon } from 'src/features/wizard/setup/components/WidgetIcon'
import {
  PreviewButton,
  PreviewContainer,
  WidgetContainer,
} from 'src/features/wizard/setup/components/WidgetIconPreview/styled'
import {
  selectIcon,
  selectPosition,
  selectPreviewUrl,
  selectWidgetType,
} from 'src/features/wizard/setup/store/selectors'
import {
  WidgetPosition,
  WidgetType,
} from 'src/features/wizard/setup/store/types'
import { IAppState } from 'src/utils/state/store'

// TODO: connect to redux - can't do that right now because styled-components fail for random reason
export const WebsitePreview: FC<IProps> = ({ maxWidth }) => {
  const [isExample, setIsExample] = useState(false)

  const { previewUrl, icon, position, widgetType } = useSelector(
    (state: IAppState) => ({
      previewUrl: selectPreviewUrl(state.setup),
      icon: selectIcon(state.setup),
      position: selectPosition(state.setup),
      widgetType: selectWidgetType(state.setup),
    })
  )

  const toggleExample = () => setIsExample(!isExample)

  // bubble position is inverse to widget position
  const isLeft =
    position === WidgetPosition.BOTTOM_RIGHT ||
    position === WidgetPosition.TOP_RIGHT
  const isRight =
    position === WidgetPosition.BOTTOM_LEFT ||
    position === WidgetPosition.TOP_LEFT

  return (
    <Container maxWidth={maxWidth}>
      <svg viewBox="0 0 86 97" />
      <IconContainer
        topLeft={position === WidgetPosition.TOP_LEFT}
        topRight={position === WidgetPosition.TOP_RIGHT}
        bottomLeft={position === WidgetPosition.BOTTOM_LEFT}
        bottomRight={position === WidgetPosition.BOTTOM_RIGHT}
      >
        <PreviewContainer>
          <PreviewButton onClick={toggleExample}>
            {widgetType === WidgetType.DEFAULT && (
              <BubbleContainer>
                <BubbleContent
                  isPositionedLeft={isLeft}
                  isPositionedRight={isRight}
                >
                  <ChatBubble
                    isPositionedLeft={isLeft}
                    isPositionedRight={isRight}
                  >
                    Chat with us
                  </ChatBubble>
                </BubbleContent>
              </BubbleContainer>
            )}
            <WidgetIcon
              color={icon.color}
              imageUrl={icon.url}
              width="4.5rem"
              height="4.5rem"
            />
          </PreviewButton>
          {isExample && (
            <WidgetContainer
              topLeft={position === WidgetPosition.TOP_LEFT}
              topRight={position === WidgetPosition.TOP_RIGHT}
              bottomLeft={position === WidgetPosition.BOTTOM_LEFT}
              bottomRight={position === WidgetPosition.BOTTOM_RIGHT}
            >
              <WidgetExample onClose={toggleExample} />
            </WidgetContainer>
          )}
        </PreviewContainer>
      </IconContainer>
      {previewUrl && <Preview imageUrl={previewUrl} />}
    </Container>
  )
}
