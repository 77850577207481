export const USER_AUTHENTICATION_FAILED = "USER_AUTHENTICATION_FAILED";
export const USER_AUTHENTICATION_CHANGED = "USER_AUTHENTICATION_CHANGED";
export const API_INTERNAL_EXCEPTION = "API_INTERNAL_EXCEPTION";

export const userAuthFailed = () => ({
  type: USER_AUTHENTICATION_FAILED,
});

export const userAuthChanged = () => ({
  type: USER_AUTHENTICATION_CHANGED,
});

export const apiInternalException = () => ({
  type: API_INTERNAL_EXCEPTION,
});
