import { useSelector, useDispatch } from "react-redux";
import { eventRequest } from "../../actions/EventConfigurationAction";

const useEvents = (projectId) => {
  const eventsInfo =
    useSelector((state) => state.EventConfigurationReducer) || {};
  const dispatch = useDispatch();
  const { isFetched, isFetching, projectId: previousProjectId } = eventsInfo;
  const eventMap = eventsInfo.eventMap || {};
  const events = {};

  if ((!isFetched && !isFetching) || previousProjectId != projectId)
    dispatch(eventRequest(projectId));

  for (let key in eventMap) {
    let event = eventMap[key];
    events[event.eventId] = event;
  }

  return { events };
};

export default useEvents;
