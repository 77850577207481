const ChatPromptConfigActions = {
  CHAT_PROMPT_CREATE_CONFIG_REQUEST: "CHAT_PROMPT_CREATE_CONFIG_REQUEST",
  CHAT_PROMPT_CREATE_CONFIG_REQUEST_FAILED:
    "CHAT_PROMPT_CREATE_CONFIG_REQUEST_FAILED",
  CHAT_PROMPT_CREATE_CONFIG_RESPONSE: "CHAT_PROMPT_CREATE_CONFIG_RESPONSE",

  CHAT_PROMPT_FETCH_CONFIG_REQUEST: "CHAT_PROMPT_FETCH_CONFIG_REQUEST",
  CHAT_PROMPT_FETCH_CONFIG_REQUEST_FAILED:
    "CHAT_PROMPT_FETCH_CONFIG_REQUEST_FAILED",
  CHAT_PROMPT_FETCH_CONFIG_RESPONSE: "CHAT_PROMPT_FETCH_CONFIG_RESPONSE",

  CHAT_PROMPT_UPDATE_CONFIG_REQUEST: "CHAT_PROMPT_UPDATE_CONFIG_REQUEST",
  CHAT_PROMPT_UPDATE_CONFIG_REQUEST_FAILED:
    "CHAT_PROMPT_UPDATE_CONFIG_REQUEST_FAILED",
  CHAT_PROMPT_UPDATE_CONFIG_RESPONSE: "CHAT_PROMPT_UPDATE_CONFIG_RESPONSE",

  CHAT_PROMPT_DELETE_CONFIG_REQUEST: "CHAT_PROMPT_DELETE_CONFIG_REQUEST",
  CHAT_PROMPT_DELETE_CONFIG_REQUEST_FAILED:
    "CHAT_PROMPT_DELETE_CONFIG_REQUEST_FAILED",
  CHAT_PROMPT_DELETE_CONFIG_RESPONSE: "CHAT_PROMPT_DELETE_CONFIG_RESPONSE",
};

export default ChatPromptConfigActions;

export const createChatPromptConfiguration = (chatPromptconfiguration) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_REQUEST,
  chatPromptconfiguration,
});

export const createChatPromptConfigurationFailed = (error) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_REQUEST_FAILED,
  error,
});

export const createChatPromptConfigurationResponse = (
  createConfigResponse
) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_CREATE_CONFIG_RESPONSE,
  data: createConfigResponse,
});

export const fetchChatPromptConfiguration = (projectKey) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_REQUEST,
  projectKey,
});

export const fetchChatPromptConfigurationFailed = (error) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_REQUEST_FAILED,
  error,
});

export const fetchChatPromptConfigurationResponse = (
  configResponse,
  projectKey
) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_FETCH_CONFIG_RESPONSE,
  data: configResponse,
  projectKey: projectKey,
});

export const updateChatPromptConfigurationRequest = (
  chatPromptConfiguration
) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_REQUEST,
  chatPromptConfiguration,
});

export const updateChatPromptConfigurationRequestFailed = (error) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_REQUEST_FAILED,
  error,
});

export const updateChatPromptConfigurationResponse = (chatConfiguration) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_UPDATE_CONFIG_RESPONSE,
  data: chatConfiguration,
});

export const deleteChatPromptConfigurationRequest = (id, projectKey) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_REQUEST,
  promptId: id,
  projectKey: projectKey,
});

export const deleteChatPromptConfigurationRequestFailed = (error) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_REQUEST_FAILED,
  error,
});

export const deleteChatPromptConfigurationResponse = (deleteResponse) => ({
  type: ChatPromptConfigActions.CHAT_PROMPT_DELETE_CONFIG_RESPONSE,
  response: deleteResponse,
});
