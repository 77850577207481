import React, { useState, useEffect } from "react";
import { SendIconForPopup, SentIcon } from "../../../commons/Icons";
import { useChatConfigurationEntity } from "../../hooks/useChatConfigurationEntity";
import { useUpdateChatConfigurationEntity } from "../../hooks/useUpdateChatConfigurationEntity";
import { usePrevious } from "../../hooks/usePrevious";
import { ChromePicker } from "react-color";
import { showVoicebox } from "../../../actions/VoiceboxAction";
import { useDispatch } from "react-redux";
import { PopupCloseIcon, AddColorIcon } from "../../../commons/Icons";
import CustomColorPicker from "./CustomColorPicker";

const WidgetColorPopup = (props) => {
  let colorList = [ "2355F3" , "03c599", "646178", "3c4859"];
  const projectId = "LS-" + props.params.projectkey;
  const {
    chatConfiguration,
    isUpdating,
    isConfigFetched,
  } = useChatConfigurationEntity(projectId);
  const { setShowWidgetColorPopUp } = props;

  const [widgetThemeColor, setWidgetThemeColor] = useState("6699ff");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const updateChatConfigurationEntity = useUpdateChatConfigurationEntity();
  const [isUpdatingColor, setIsUpdatingColor] = useState(false);

  let prevIsUpdating = usePrevious(isUpdating);
  let dispatch = useDispatch();

  const updateWidgetThemeColor = (color) => {
    setWidgetThemeColor(color);
  };

  useEffect(() => {
    let color = chatConfiguration
      ? chatConfiguration.widgetThemeColor
      : widgetThemeColor;
    updateWidgetThemeColor(color);
  }, [isConfigFetched]);

  useEffect(() => {
    if (prevIsUpdating && !isUpdating && isUpdatingColor) {
      setIsUpdatingColor(false);
      setShowWidgetColorPopUp && setShowWidgetColorPopUp(false);
      dispatch(
        showVoicebox({ message: "Update Successful", dismissAfter: 2000 })
      );
    }
  }, [isUpdating]);

  const getAdditionalColor = () => {
    //return <cite style={{ "backgroundColor" :"#"+widgetThemeColor }}></cite>
    if (!isDefaultColorSelected() && widgetThemeColor)
      return (
        <code
          style={{ backgroundColor: "#" + widgetThemeColor }}
          className="active"
        ></code>
      );
    else return "";
  };

  const isDefaultColorSelected = () => {
    return colorList.includes(widgetThemeColor);
  };

  const getColorPicker = () => {
    if (showColorPicker) {
      return (
        <CustomColorPicker
          color={widgetThemeColor}
          onSave={saveCustomColor}
          closePicker={closeColorPicker}
          onChange={onChange}
        />
      );
    }
    return "";
  };

  const onChange = (event) => {
    console.log(event);
    if (event.hex) {
      setWidgetThemeColor(event.hex);
    }
  };

  const closeColorPicker = () => {
    setShowColorPicker(false);
  };

  const showColorPickerHandler = (event) => {
    setShowColorPicker(true);
    event.stopPropagation();
    return false;
  };

  const selectColor = (event) => {
    if (
      event.target.dataset.color &&
      event.target.dataset.color !== widgetThemeColor
    ) {
      setWidgetThemeColor(event.target.dataset.color);
      updateChatConfigEntity(event.target.dataset.color);
    }
  };

  const saveCustomColor = (color) => {
    if (color) {
      color = color.split("#")[1];
      setWidgetThemeColor(color);
      updateChatConfigEntity(color);
    }
  };

  const updateChatConfigEntity = (colorToUpdate) => {
    setIsUpdatingColor(true);
    dispatch(showVoicebox({ message: "Updating" }));
    let chatCofigToUpdate = {
      key: chatConfiguration.key,
      widgetThemeColor: colorToUpdate,
    };
    updateChatConfigurationEntity(chatCofigToUpdate);
    closeColorPicker();
  };

  const getColorFromPicker = () => {
    if (!showColorPicker && !isDefaultColorSelected() && widgetThemeColor)
      return `#${widgetThemeColor.toUpperCase()}`;
    else return "Pick a colour";
  };

  const handleColorChange = ({ hex }) => console.log(hex);

  return (
    <div>
      <div className="cs-widget-style">
        <label>Widget Colour</label>

        <div className="widget-style-color">
          <code
            data-color={"2355F3"}
            className={"2355F3" === widgetThemeColor ? "active" : ""}
            onClick={selectColor}
          ></code>
          <code
            data-color={"03c599"}
            className={"03c599" === widgetThemeColor ? "active" : ""}
            onClick={selectColor}
          ></code>
          <code
            data-color={"646178"}
            className={"646178" === widgetThemeColor ? "active" : ""}
            onClick={selectColor}
          ></code>
          <code
            data-color={"3c4859"}
            className={"3c4859" === widgetThemeColor ? "active" : ""}
            onClick={selectColor}
          ></code>
          {getAdditionalColor()}
          <div className="cs-widget-color" onClick={showColorPickerHandler}>
            <AddColorIcon />
          </div>
        </div>
        {getColorPicker()}
      </div>
    </div>
  );

  return (
    <div>
      <div className="cs-popupbg"></div>
      <div className="cs-popup-container" onClick={closeColorPicker}>
        <aside className="cs-popup customize-popup">
          <header>
            <h2>Customize Widget Style</h2>
            <cite
              onClick={() => {
                setShowWidgetColorPopUp(false);
              }}
            >
              <PopupCloseIcon />
            </cite>
          </header>
          <div>
            <div
              className={
                !isDefaultColorSelected()
                  ? "cs-choos-widget"
                  : "cs-choos-widget active"
              }
            >
              <label>Choose from available defaults</label>
              <ul>
                {colorList.map((color) => {
                  return (
                    <li onClick={selectColor}>
                      <figure
                        data-color={color}
                        style={{
                          backgroundColor: "#" + color,
                          cursor: "pointer",
                        }}
                      ></figure>
                      <cite
                        data-color={color}
                        className={color === widgetThemeColor ? "active" : ""}
                      >
                        <SentIcon data-color={color} />
                      </cite>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                isDefaultColorSelected()
                  ? "cs-choos-widget"
                  : "cs-choos-widget active"
              }
            >
              <label>Advanced color Picker</label>
              <p>Enter your own HEX value to choose the color of your widget</p>
              <div className="color-picker" onClick={showColorPickerHandler}>
                {getColorPicker()}
                {getAdditionalColor()}
                {getColorFromPicker()}
              </div>
            </div>
            <div className="input-form-button">
              <button
                className="cs-btn cs-primary-btn"
                onClick={() => {
                  updateChatConfigEntity();
                }}
              >
                Change Theme Color
              </button>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default WidgetColorPopup;
