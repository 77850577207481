import React, { useRef, useState } from "react";
import { ArrowIcon } from "../../../commons/Icons";
import { useEffect } from "react";
import { HOURS_TYPE } from "../../../commons/Constants";

const TimeInputFieldComponent = (props) => {
  const dropDownRef = useRef(null);
  const timeFieldRef = useRef([]);
  const searchRef = useRef(0);
  const timeRef = useRef(null);
  const indexRef = useRef(-1);
  const [dropDown, setDropDown] = useState(false);
  const [searchIndex, setSearchIndex] = useState(-1);
  const [searchValue, setSearchValue] = useState(0);
  const time = props.timeInMin;

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dropDown, searchValue]);

  useEffect(() => {
    if (timeFieldRef && timeFieldRef.current && dropDown) {
      let elements = timeFieldRef.current;
      let element = elements.filter((item) => item.dataset.value == time + 30);
      let searchIndexValue = 0;
      element = element.length > 0 ? element : [elements[0]];
      elements.forEach((item, index) => {
        if (item.dataset.value == time + 30) searchIndexValue = index;
      });
      indexRef.current = searchIndexValue;
      setSearchIndex(searchIndexValue);
      if (element[0]) element[0].scrollIntoView({ block: "start" });
    }
  }, [dropDown]);

  useEffect(() => {
    let searchIndexValue = indexRef.current;
    let element = timeFieldRef.current[searchIndexValue];
    if (element) element.scrollIntoView({ block: "nearest" });
  }, [searchIndex]);

  const searchTime = (value) => {
    if (
      searchValue == value &&
      searchRef.current !== 1 &&
      searchRef.current !== 13
    )
      searchRef.current = searchValue + 12;
    else searchRef.current = searchRef.current * 10 + parseInt(value);
    let elements = timeFieldRef.current;
    let element = elements.filter((item) => {
      if (item.dataset.value / 60 == searchRef.current) {
        return item;
      }
    });
    setSearchValue(searchRef.current);
    let searchIndexValue = 0;
    elements.forEach((item, index) => {
      if (item.dataset.value / 60 == searchRef.current) {
        searchIndexValue = index;
        return;
      }
    });

    if (element[0]) {
      setSearchIndex(searchIndexValue);
      indexRef.current = searchIndexValue;
      element[0].scrollIntoView({ block: "start" });
    }
    setTimeout(() => {
      searchRef.current = 0;
    }, 500);
  };

  const handleKeyDown = (e) => {
    let value = e.key;
    let searchIndexValue = indexRef.current;
    let elements = timeFieldRef.current;
    if (
      value >= 0 &&
      value < 10 &&
      dropDown &&
      (e.keyCode !== 32 || e.which !== 32)
    )
      searchTime(value);
    else if (dropDown) {
      if ((e.which === 38 || e.keyCode === 38) && searchIndexValue > 0) {
        searchIndexValue--;
      } else if (
        (e.which === 40 || e.keyCode === 40) &&
        searchIndexValue < elements.length - 1
      ) {
        searchIndexValue++;
      } else if (
        e.which === 13 ||
        e.keyCode === 13 ||
        e.which === 32 ||
        e.keyCode === 32
      ) {
        if (elements[searchIndexValue]) elements[searchIndexValue].click();
      } else if (e.which === 27 || e.keyCode === 27) {
        showDropDown();
      }
      e.preventDefault();
      if (searchIndexValue >= 0) {
        indexRef.current = searchIndexValue;
        setSearchIndex(searchIndexValue);
      }
    }
  };

  const handleOutsideClick = () => {
    if (
      !(
        dropDownRef &&
        dropDownRef.current &&
        dropDownRef.current.contains(event.target)
      ) &&
      dropDown
    ) {
      if (timeRef && timeRef.current && !timeRef.current.contains(event.target))
        hideDropDown(dropDownRef);
    }
  };

  const showDropDown = () => {
    if (
      !(
        props.hoursType === HOURS_TYPE.weekdays &&
        (props.day === "SA" || props.day === "SU")
      )
    ) {
      setDropDown(!dropDown);
      indexRef.current = 0;
      setSearchIndex(0);
      event.stopPropagation();
    }
  };

  const hideDropDown = () => {
    indexRef.current = 0;
    setSearchIndex(0);
    setDropDown(false);
    event.stopPropagation();
  };

  const addToRefs = (el) => {
    if (el && !timeFieldRef.current.includes(el)) {
      timeFieldRef.current.push(el);
    }
  };

  const hoverHandler = (e) => {
    if (searchIndex != e.target.dataset.index) {
      setSearchIndex(parseInt(e.target.dataset.index));
      indexRef.current = parseInt(e.target.dataset.index);
    }
  };

  const renderHourOptions = () => {
    let options = [];
    let index = 0;
    for (let hours = 0; hours < 24; hours++)
      for (let mins = 0; mins <= 30; mins += 30) {
        let inMinutes = hours * 60 + mins;
        let hour = Math.floor(inMinutes / 60);
        hour = hour % 12 == 0 ? 12 : hour % 12;
        let amOrPm = Math.floor(inMinutes / 60) >= 12 ? " PM" : " AM";
        let minute = inMinutes % 60;
        if (props.startTime === undefined || props.startTime < inMinutes) {
          if (timeFieldRef.current.length > 0 && searchIndex < 0)
            timeFieldRef.current = [];
          options.push(
            <li
              onClick={hideDropDown}
              data-index={index}
              onMouseOver={hoverHandler}
            >
              <a
                href="javascript:void(0)"
                data-index={index}
                data-value={inMinutes}
                data-type={props.type}
                data-day={props.day}
                onClick={props.handleTimeChange}
                ref={addToRefs}
                className={searchIndex == index ? "select" : ""}
              >
                {(hour < 10 ? "0" + hour : hour) +
                  ":" +
                  (minute < 10 ? "0" + minute : minute) +
                  amOrPm}
              </a>
            </li>
          );
          index++;
        }
      }
    return options;
  };

  return (
    <div className={"cs-dropdwn " + (dropDown ? "open" : "")}>
      <p onClick={showDropDown} ref={timeRef}>
        {props.time}
      </p>
      <ArrowIcon />
      <div ref={dropDownRef}>
        <ul>{renderHourOptions()}</ul>
      </div>
    </div>
  );
};
export default TimeInputFieldComponent;
