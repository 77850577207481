/**
 * Created by melvin on 06/04/17.
 */
import { call, put } from "redux-saga/effects";
import * as Ajax from "../xhr/XhrRequestHandler";
import {
  signUpResponse,
  signUpFailed,
  createLeadFailed,
  createLeadResponse,
  setupAccountResponse,
} from "../actions/SignUpAction";
import { addNotification } from "../actions/NotificationAction";
import { NOTIFICATION_LEVEL } from "../commons/Constants.js";
import * as VoiceboxActions from "../actions/VoiceboxAction";
import { handleGlobalError } from "../actions/GlobalErrorAction";
import { Event } from "../commons/EventsTrackingGoogleAnalytics";

export function* doSignUp(signUpDataObj) {
  const url = "/account/signup";

  try {
    const signUpResp = yield call(Ajax.signUp, url, signUpDataObj.signUpData);

    Event(undefined, "Signup", "Successful signup", "Signup view", {
      emailId: signUpDataObj.signUpData.email,
    });
    yield put(signUpResponse(signUpResp.data));
  } catch (error) {
    yield put(signUpFailed({ error: error }));
    //yield put( handleGlobalError( error ) );
  }
}

export function* createLead(checkLeadObj) {
  const url = "/account/create/lead";

  try {
    let leadData = {};
    leadData.email = checkLeadObj.createLeadData.email;

    const createLeadResp = yield call(Ajax.postUnauthenticated, url, leadData);

    yield put(createLeadResponse(createLeadResp.data));
  } catch (error) {
    if (
      error &&
      error.data &&
      error.data.Exception == "RESOURCE_ALREADY_EXISTS"
    )
      yield put(createLeadFailed(error.data));
    else yield put(createLeadFailed({ Exception: "Signup Failed" }));
  }
}

export function* setupAccount(accountObject) {
  const url = "/account/setup";
  let failedInvitations = [];
  let error = "";
  console.log("Inside account setup Saga");
  try {
    yield put(
      VoiceboxActions.showVoicebox({ message: "setting up account..." })
    );
    let accountData = yield call(
      Ajax.postUnauthenticated,
      url,
      accountObject.accountData
    );

    console.info("Account Information =>", accountData.data.data);

    yield put(setupAccountResponse(accountData));
    yield put(
      VoiceboxActions.showVoicebox({
        message: "Account setup successfully!",
        dismissAfter: 3000,
      })
    );
  } catch (e) {
    console.info("Error during project creation", e);
    yield put(VoiceboxActions.hideVoicebox());

    //yield put( handleGlobalError( e ) );
  }
}

function* notifySignupApiError(error) {
  if (error.data && error.data.Exception == "RESOURCE_ALREADY_EXISTS") {
    yield put(
      addNotification({
        message: "Contact Already Exists! Please Log in!",
        level: NOTIFICATION_LEVEL.Warning,
        dismissAfter: 3000,
      })
    );
  } else {
    yield put(
      addNotification({
        message: "Sign up failed!",
        level: NOTIFICATION_LEVEL.Error,
        dismissAfter: 3000,
      })
    );
  }
}
