import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import {
  CHAT_HISTORY_CLASSES,
  MESSAGE_TYPE,
  TAG_LINE_TYPES,
  IMAGE_TYPES,
  AUDIO_TYPES,
  VIDEO_TYPES,
  displayVisitorType,
  NON_DISPLAYABLE_TYPES,
  DISPLAY_TEXT_TYPE_MESSAGES,
  SPREAD_SHEET_TYPES,
  COMPRESSED_TYPES,
  DOCUMENT_TYPES,
  CAMPAIGN_TYPES,
  FEEDBACK_MESSAGE_TYPES,
  EMOJI_URLS,
  HTML_SUPPORTED_CAMPAIGN,
  ABNORMAL_DISCONNECTION,
  SCROLL_HEIGHT_FROM_BOTTOM,
  ACTIONABLE_TYPES,
  OVERFLOW_CHAT_CLOSED_TYPES,
} from "../../commons/Constants";
import {
  formatDateWithYearAndSeconds,
  stripHTML,
  isValidURL,
  getFullName,
  getImagePreviewUrl,
  getFormattedDate,
  initiateClipboard,
  getConnectUrl,
  isOverflowConnection,
  isHTMLContent,
  isHTMLTemplateEnabled,
  getValidMessage,
  isScrollReachedUptoThePixelsFromBottom,
  getIsAWRecentComponent,
  getRespectiveStyleforAWChat,
  playNotifierAudio
} from "../../commons/Utility.js";
import UrlPreviewNew from "../common/UrlPreviewNew.jsx";
import renderHTML from "react-render-html";
import Autolinker from "autolinker";
import sanitizer from "sanitizer";
import {
  MESSAGE_STATUS,
  MESSAGE_STATUS_CLASS,
  MESSAGE_FROM,
} from "../../commons/Constants.js";
import {
  incrementNewMessageCount,
  decrementNewMessageCount,
} from "../../actions/NewMessageAction";
import Clipboard from "clipboard";
import { store } from "../../app";
import { showVoicebox } from "../../actions/VoiceboxAction";
import {
  SentIcon,
  LinkIcon,
  EmailIcon,
  AWChatMessageBoxIcon,
} from "../../commons/Icons.js";
import {
  setNewMessage,
  resetNewMessage,
  blockUnloadEvent,
} from "../../actions/ConversationInfoAction";
import { sendMessage } from "../../actions/MessageAction";
import { Event } from "../../commons/EventsTrackingGoogleAnalytics";
import TypingStatusComponent from "./TypingStatusComponent.jsx";
import MessageComponent from "./MessageComponent.jsx";
class ChatMessageBox extends Component {
  constructor() {
    super();
    this.state = { isNewMessageInViewPort: true };
    this.getActionToAgetName = this.getActionToAgetName.bind(this);
    this.getSenderAgetName = this.getSenderAgetName.bind(this);
    this.getMessageContentByType = this.getMessageContentByType.bind(this);
    this.loadUrlInNewTab = this.loadUrlInNewTab.bind(this);
    this.sendReadStatus = this.sendReadStatus.bind(this);
    this.getSentIcon = this.getSentIcon.bind(this);
    this.canIncrementMessageCount = this.canIncrementMessageCount.bind(this);
    this.getTypingComponent = this.getTypingComponent.bind(this);
    this.getMessageComponent = this.getMessageComponent.bind(this);
    this.isChatDisconnectedOrClosedInOverflow =
      this.isChatDisconnectedOrClosedInOverflow.bind(this);
    this.canRenderChatBox = this.canRenderChatBox.bind(this);
  }

  componentDidMount() {
    let {
      newMessage,
      messageData,
      agentNotifiableMessage,
      isOverflowConversation,
      user,
    } = this.props;
    let messages =
      store.getState().MessageReducer.messageMap[
        this.props.currentConversation.key
      ];
    if (
      this.isEligibleForAudioNotification(
        isOverflowConversation,
        user,
        newMessage,
        agentNotifiableMessage
      )
    )
      this.playAudioForIncomingMessage(messageData);
    if (
      messageData.type == MESSAGE_TYPE.video_chat_link ||
      messageData.type == MESSAGE_TYPE.screen_share_link
    )
      initiateClipboard(
        ".copy-link",
        () => {
          store.dispatch(
            showVoicebox({
              message: "Copied",
              dismissAfter: 1000,
              showBelowHeader: !isOverflowConnection(),
              showFullScreenLoader: isOverflowConnection(),
            })
          );
        },
        () => {
          store.dispatch(
            showVoicebox({
              message: "Please use CTRL/CMD+C to copy",
              dismissAfter: 3000,
              showBelowHeader: !isOverflowConnection(),
              showFullScreenLoader: isOverflowConnection(),
            })
          );
        }
      );

    if (!newMessage || Object.keys(newMessage).length == 0) return;

    if (this.canIncrementMessageCount(newMessage, messageData)) {
      this.props.dispatch(
        incrementNewMessageCount(messageData.conversationId, messageData.key)
      );

      if (
        newMessage &&
        newMessage.messageFrom === "VISITOR" &&
        (!this.props.currentConversation.newMessage ||
          this.props.currentConversation.newMessage === "" ||
          (messages[this.props.currentConversation.newMessage] &&
            messages[this.props.currentConversation.newMessage]
              .messageStatus === //fix this undefined
              "CHAT_READ")) &&
        !this.props.nearBottom
      ) {
        if (newMessage.type === "CHAT" || newMessage.type === "ATTACHMENT")
          this.props.dispatch(setNewMessage(messageData));
      }
      this.setState({ isNewMessageInViewPort: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { messageData, newMessageInfo } = this.props;
    let messageIds = newMessageInfo ? newMessageInfo.messageIds : [];
    let decrementCount = false;
    if (
      !prevState.isNewMessageInViewPort &&
      newMessageInfo.messageToScrollTo &&
      newMessageInfo.messageToScrollTo == messageData.key
    ) {
      this.setState({ ...this.state, isNewMessageInViewPort: true });
      decrementCount = true;

      if (this.child) {
        if (typeof this.child.scrollIntoView == "function")
          this.child.scrollIntoView({ behavior: "smooth", block: "end" });
        else
          ReactDOM.findDOMNode(this.child.refs["preview-li"]).scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
      } else {
        let msgElement = document.getElementById(
          newMessageInfo.messageToScrollTo
        );
        if (msgElement) {
          msgElement.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      }
    }

    if (
      !prevState.isNewMessageInViewPort &&
      messageIds &&
      messageIds.includes(messageData.key)
    ) {
      if (
        this.getReactReferenceBasedOnReactVersion &&
        this.getReactReferenceBasedOnReactVersion().child &&
        this.getReactReferenceBasedOnReactVersion().child.stateNode &&
        this.isElementInViewport(
          this.getReactReferenceBasedOnReactVersion().child.stateNode
        )
      )  {
        this.setState({ ...this.state, isNewMessageInViewPort: true });
        decrementCount = true;
      }
    }

    if (decrementCount) {
      this.props.dispatch(
        decrementNewMessageCount(messageData.conversationId, messageData.key)
      );
      if (
        messageData.messageFrom === "VISITOR" &&
        messageData.messageStatus !== "CHAT_READ"
      )
        this.sendReadStatus(messageData, "CHAT_READ");
    }
  }

  isEligibleForAudioNotification(
    isOverflowConversation,
    { isFullScreenMode, isSBFocused },
    newMessage,
    agentNotifiableMessage
  ) {
    return (
      isOverflowConversation &&
      !isFullScreenMode &&
      !isSBFocused &&
      ((newMessage && Object.keys(newMessage).length != 0) ||
        (agentNotifiableMessage &&
          Object.keys(agentNotifiableMessage).length != 0))
    );
  }

  playAudioForIncomingMessage(messageData) {
    if (this.isValidMessageToPlayAudio(messageData))
      playNotifierAudio();
  }

  isValidMessageToPlayAudio({ messageFrom, type }) {
    return (
      (messageFrom === MESSAGE_FROM.VISITOR &&
        ACTIONABLE_TYPES.includes(type)) ||
      OVERFLOW_CHAT_CLOSED_TYPES.includes(type)
    );
  }

  canIncrementMessageCount(newMessage, messageData) {
    const { chatViewElement } = this.props.getChatViewProps();
    if (
      newMessage &&
      messageData.key === newMessage.key &&
      this.getReactReferenceBasedOnReactVersion &&
      this.getReactReferenceBasedOnReactVersion().child &&
      !this.isElementInViewport(
        this.getReactReferenceBasedOnReactVersion().child.stateNode
      ) &&
      !isScrollReachedUptoThePixelsFromBottom(
        chatViewElement,
        SCROLL_HEIGHT_FROM_BOTTOM
      ) &&
      !TAG_LINE_TYPES.includes(newMessage.type) &&
      !NON_DISPLAYABLE_TYPES.includes(newMessage.type)
    )
      return true;
    return false;
  }

  getReactReferenceBasedOnReactVersion() {
      return this._reactInternals;
  }

  sendReadStatus(message, messageType) {
    let visitorId = message.senderId;
    if (visitorId && this.props.currentConversation.projectId) {
      let typingMessage = {
        messageId: message.messageId || message.key,
        message: "",
        type: messageType,
        channel: this.props.isOverflowConversation
          ? "routing/visitor/" + visitorId
          : "private/visitor/" +
            this.props.currentConversation.projectId +
            "/" +
            visitorId,
        conversationId: message.conversationId,
        messageFrom: MESSAGE_FROM.AGENT,
        senderId: this.props.isOverflowConversation
          ? this.props.overflowedAgent.key
          : store.getState().UserReducer.data.id,
        actionTo: "",
        visitorId: visitorId,
        projectId: this.props.currentConversation.projectId,
      };
      this.props.dispatch(
        sendMessage(
          typingMessage,
          this.props.isOverflowConversation
            ? { interactionId: this.props.interactionId, triggerWebHook: true }
            : {
                echoChannels: [
                  "private/agent/" + this.props.currentConversation.projectId,
                ],
                triggerWebHook: true,
              }
        )
      );
    }
  }

  getTagLineMessage(message) {
    let taglineMessage = [];
    let assignerName = this.getAssignerNameByContext(
      this.props.isOverflowConversation || this.props.isAwWindow
        ? this.props.overflowedAgent.key
        : this.props.user.data.id,
      message
    );
    let assigneeName = this.getAssigneeNameByContext(
      this.props.isOverflowConversation || this.props.isAwWindow
        ? this.props.overflowedAgent.key
        : this.props.user.data.id,
      message
    );
    let createdDate =
      message.createdDate + store.getState().UserReducer.timeDifference;
    let visitorName = this.getVisitorName();
    switch (message.type) {
      case MESSAGE_TYPE.cs_visitornavigation:
        taglineMessage.push(renderHTML(this.getSanatizedMessage(message)));
        break;
      case MESSAGE_TYPE.visitor_initiated_conversation:
        taglineMessage.push(
          // <p data-tips="23 Aug 2020, 8:25:22 AM" className="g-data-tips right-tip">
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{visitorName}</b> has initiated a conversation
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_prompt_triggered:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            Chat prompt triggered to <b>{visitorName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_prompt_unread:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            Chat prompt left unread by <b>{visitorName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_prompt_ignored:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            Chat prompt got ignored by <b>{visitorName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_assigned:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> assigned this conversation to{" "}
            <b>{assigneeName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_assigned_on_connect:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> assigned this conversation to{" "}
            <b>{assigneeName}</b> in Connect
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_unassigned:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> unassigned <b>{assigneeName}</b> from this
            conversation
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_closed:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> closed this conversation
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_reopen:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> replied and reopened this conversation
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_auto_assigned_on_reply:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> replied and auto-assigned this conversation to{" "}
            <b>{assigneeName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_auto_assigned_available:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This conversation has been auto assigned to <b>{assigneeName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_auto_assigned_available_on_connect:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This conversation has been auto assigned to <b>{assigneeName}</b> in
            Connect
          </p>
        );
        break;
      case MESSAGE_TYPE.initiated_new_conversation:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> initiated a new conversation with{" "}
            <b>{visitorName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_auto_assign_on_overflow:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This conversation has been overflowed and auto assigned to{" "}
            <b>{assignerName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_chat_closed:
      case MESSAGE_TYPE.overflow_chat_closed_on_available:
        taglineMessage.push(
          message.messageFrom === "AGENT" ? (
            <p
              id={this.props.messageData.key}
              data-class="tag-tooltip"
              data-place={isOverflowConnection() ? "top" : "right"}
              data-tip={this.getDisplayTimeWithDate(createdDate)}
            >
              <code></code>
              <b>{assignerName}</b>
              {MESSAGE_TYPE.overflow_chat_closed_on_available == message.type
                ? " closed this conversation by going Available"
                : " closed this overflowed conversation"}
            </p>
          ) : (
            <p
              id={this.props.messageData.key}
              data-class="tag-tooltip"
              data-place={isOverflowConnection() ? "top" : "right"}
              data-tip={this.getDisplayTimeWithDate(createdDate)}
            >
              <code></code>
              <b>{visitorName}</b> left and hence overflow conversation is
              closed
            </p>
          )
        );
        break;
      case MESSAGE_TYPE.visitor_left_before_chat_routed:
      case MESSAGE_TYPE.overflow_chat_disconnected:
      case MESSAGE_TYPE.dashboard_visitor_left:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{visitorName}</b> left and hence chat has been closed
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_chat_disconnected_abnormally: {
        let disconnectMsg = "";
        if (message.messageFrom === "AGENT")
          disconnectMsg = !navigator.onLine
            ? ABNORMAL_DISCONNECTION.NETWORK
            : ABNORMAL_DISCONNECTION.SOCKET;
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            {disconnectMsg}
          </p>
        );
        break;
      }
      case MESSAGE_TYPE.chat_lead_capture:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{visitorName}</b> updated their email id to{" "}
            <b>{this.getVisitorEmail() || message.message}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_chat_closed_no_response:
      case MESSAGE_TYPE.dashboard_chat_closed_no_response:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This chat has been closed due to lack of response from{" "}
            <b>{visitorName}</b>
          </p>
        );
        break;
      case MESSAGE_TYPE.dashboard_agent_disconnected:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This chat has been closed since <b>{assigneeName}</b> left the chat
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_prompt_closed_on_ignore:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This chat has been closed since <b>{visitorName}</b> has ignored the
            prompt
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_assign_and_reopen:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> assigned this conversation to{" "}
            <b>{assigneeName}</b> and reopened this conversation
          </p>
        );
        break;
      case MESSAGE_TYPE.out_of_office_lead_capture:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            EmailId <b>{this.getVisitorEmail() || message.message}</b> captured
            through out of office form
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_chat_closed_by_staff:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This chat has been closed by client
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_chat_takenover_by_staff:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This chat has been taken over by client
          </p>
        );
        break;
      case MESSAGE_TYPE.overflow_chat_interrupted:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            This chat has been closed due to no response from{" "}
            {visitorName || "Visitor"} and an incoming chat campaign.
          </p>
        );
        break;
      case MESSAGE_TYPE.cs_chatdisconnect:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            Visitor has left. Chat has been closed.
          </p>
        );
        break;
      case MESSAGE_TYPE.initiate_new_chat_by_email_reply:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{visitorName}</b> has replied to an email campaign
          </p>
        );
        break;
      case MESSAGE_TYPE.cs_offlineform:
      case MESSAGE_TYPE.cs_prechatsurvey:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{visitorName}</b> updated their{" "}
            <b>{this.getSurveyFormInfo(message.message)}</b> through survey
            form.
          </p>
        );
        break;
      case MESSAGE_TYPE.feedback_sumitted:
        taglineMessage.push(
          <React.Fragment>
            <span
              data-class="tag-tooltip"
              data-place={isOverflowConnection() ? "top" : "right"}
              data-tip={this.getDisplayTimeWithDate(createdDate)}
            >
              {visitorName} has left a feedback
            </span>
            <cite>
              <img src={EMOJI_URLS[message.rating]} />
            </cite>
            {message.comment ? <code>"{message.comment}"</code> : ""}
          </React.Fragment>
        );
        break;
      case MESSAGE_TYPE.chat_closed_as_pending:
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assignerName}</b> closed this conversation as Pending
          </p>
        );
        break;
      case MESSAGE_TYPE.chat_answering_message:
        assigneeName = assigneeName === "you" ? "You" : assigneeName;
        taglineMessage.push(
          <p
            id={this.props.messageData.key}
            data-class="tag-tooltip"
            data-place={isOverflowConnection() ? "top" : "right"}
            data-tip={this.getDisplayTimeWithDate(createdDate)}
          >
            <code></code>
            <b>{assigneeName}</b> received the incoming chat pop-up
          </p>
        );
        break;
      default:
        console.info("Tagline type is unknown!");
    }
    return taglineMessage;
  }

  isElementInViewport(element) {
    if (!element) return;

    let elementRect = {};
    if (typeof element.getBoundingClientRect == "function")
      elementRect = element.getBoundingClientRect();
    else if (this.child)
      elementRect = ReactDOM.findDOMNode(
        this.child.refs["preview-li"]
      ).getBoundingClientRect();

    let isTotal = false;

    let { chatViewRect } = this.props.getChatViewProps();

    if (chatViewRect && chatViewRect.height) {
      let contHeight = chatViewRect.height;
      let elementTop = elementRect.top - chatViewRect.top;
      let elementBottom = elementTop + elementRect.height;
      isTotal = elementTop >= 0 && elementBottom <= contHeight + 2;
    } else {
      isTotal = true;
    }

    return isTotal;
  }

  getAssignerNameByContext(userId, message) {
    let assignerName = "You";
    if (
      message.messageFrom === "VISITOR" &&
      message.type == MESSAGE_TYPE.chat_reopen
    )
      assignerName = "Visitor";
    else if (userId != message.senderId)
      assignerName = this.getSenderAgetName();

    return assignerName;
  }

  decodeMentionedAgents(message) {
    var regex = /\<\|(.*?)\|\>/g;
    let match;
    let agentId = [];
    let agentName = "";

    do {
      match = regex.exec(message);
      if (match) {
        agentId = match[1];
        agentName = this.props.getAgentNameById(agentId);
        message = message.replace(
          `<|${match[1]}|>`,
          this.getMentionedHtml(agentName)
        );
      }
    } while (match);
    return message;
  }

  getMentionedHtml(agentName) {
    return `<span style={{ 'fontWeight' : 'bold' }}>@${agentName}</span>`;
  }

  getAssigneeNameByContext(userId, message) {
    //All context combinations should be given a thorough thought before modifying this method, else we end up ruining the tagline messages

    let assigneeName = "";

    if (userId == message.senderId && userId == message.actionTo)
      assigneeName = "yourself";
    else if (message.senderId == message.actionTo) assigneeName = "themselves";
    else if (userId == message.actionTo) assigneeName = "you";
    else assigneeName = this.getActionToAgetName();

    return assigneeName;
  }

  getVisitorName() {
    let currentContact = this.props.contact ? this.props.contact : {};
    let name = getFullName(currentContact);
    if (!name)
      name = currentContact.type
        ? displayVisitorType[currentContact.type]
        : "Visitor";
    return name;
  }
  getVisitorEmail() {
    let currentContact = this.props.contact ? this.props.contact : {};
    let email = currentContact ? currentContact.email : "";
    return email;
  }
  getAttachmentsBasedOnTypeNew(messageData) {
    let img = [];
    if (!!messageData.message) {
      if (messageData.type.toUpperCase() == MESSAGE_TYPE.attachment) {
        let fileName = this.getFileName(messageData.message);
        let fileLocation = this.getFileLocation(messageData.message);
        let fileSize = this.getFileSize(messageData.message);
        let displayFileSize = this.getDisplayFileSize(fileSize);
        let downloadUrl = this.getFileDownloadUrl(messageData.message);
        let ary = fileName.split(".");
        let attachmentType = ary[ary.length - 1].toLowerCase();

        if (
          !!this.props.isUploadingAttachment &&
          this.props.uploadingMessageData.hasOwnProperty(messageData.key)
        ) {
          img.push(
            <span key={messageData.key} style={{ width: "100px" }}>
              <img
                src="https://app.chatsupport.co/images/uploading.gif"
                alt="uploading"
                style={{ width: "60px" }}
              />
            </span>
          );
        } else if (IMAGE_TYPES.includes(attachmentType)) {
          let imageDownloadUrl = getImagePreviewUrl(fileLocation);

          if (downloadUrl) imageDownloadUrl = downloadUrl;

          img.push(
            <div className="chat-img">
              <figure>
                <a target="_blank" href={fileLocation}>
                  <img src={fileLocation} alt="File attachment" />
                </a>
              </figure>
              <p>{fileName}</p>
              <a target="_blank" href={imageDownloadUrl} download={fileName}>
                <i className="icon icon-download"></i>
              </a>
              <i className="icon icon-share dn"></i>
            </div>
          );
        } else if (VIDEO_TYPES.includes(attachmentType)) {
          img.push(
            <div className="chat-video">
              <video
                width="100%"
                onLoad={this.props.scrollMessages}
                data-delay-hide={0}
                src={fileLocation}
                className="atchmt embd upload-video"
                controls
                key={messageData.key + "attachment"}
              />
            </div>
          );
        } else if (AUDIO_TYPES.includes(attachmentType)) {
          img.push(
            <div className="chat-audio">
              <audio
                onLoad={this.props.scrollMessages}
                data-delay-hide={0}
                src={fileLocation}
                controls
                key={messageData.key + "attachment"}
              />
            </div>
          );
        } else {
          let attachmentLink = fileLocation;
          if (downloadUrl) attachmentLink = downloadUrl;
          img.push(
            <div className="chat-attachment">
              <img src="https://app.chatsupport.co/images/commonfile.png" />
              <div>
                <p>{fileName}</p>
                <p>{displayFileSize}</p>
                <a href={attachmentLink} download={fileName}>
                  Download
                </a>
              </div>
            </div>
          );
        }
      }
    }
    return img;
  }

  getDisplayFileSize(bytesSize) {
    if (!bytesSize || bytesSize == "0") return "";

    if (bytesSize < 1000) return `${bytesSize} B`;
    else if (bytesSize > 1000 && bytesSize < 1000000)
      return `${Math.floor(bytesSize / 1000)} KB`;
    else if (bytesSize >= 1000000)
      return `${Math.floor(bytesSize / 1000000)} MB`;
  }

  getFileSize(message) {
    let fileSize = 0;
    try {
      let messageObject = JSON.parse(message);
      fileSize = messageObject.size;
    } catch (err) {
      fileSize = 0;
    }
    return fileSize;
  }

  getFileName(message) {
    let fileName = "";
    let messageObject = {};

    try {
      messageObject = JSON.parse(message);
      fileName = messageObject.hasOwnProperty("fileName")
        ? messageObject.fileName
        : "";
    } catch (err) {
      fileName = message;
      fileName = decodeURI(fileName);
      fileName = fileName.split("/").pop();
    }

    return fileName;
  }

  getFileLocation(message) {
    let fileLocation = "";
    let messageObject = {};
    try {
      messageObject = JSON.parse(message);
      fileLocation = messageObject.filePath;
    } catch (err) {
      fileLocation = message;
    }

    return fileLocation;
  }
  getFileDownloadUrl(message) {
    let fileDownloadUrl = "";
    let messageObject = {};
    try {
      messageObject = JSON.parse(message);
      if (messageObject.hasOwnProperty("raw_url_download"))
        fileDownloadUrl = messageObject.raw_url_download;
    } catch (err) {}

    return fileDownloadUrl;
  }
  getSanatizedMessage(messageData) {
    let message = messageData.message;
    let sanatizedMessage = "";
    if (!CAMPAIGN_TYPES.includes(messageData.type) && message) {
      sanatizedMessage = sanitizer.sanitize(message);
    } else {
      sanatizedMessage = message;
    }

    return Autolinker.link(sanatizedMessage, {
      truncate: { length: 70, location: "smart" },
    });
  }

  getInitials(firstName, lastName) {
    return (
      (firstName ? firstName.charAt(0) : "") +
      (lastName ? lastName.charAt(0) : "")
    ).toUpperCase();
  }

  getSenderAgetName() {
    let senderStaff = this.props.senderStaff;
    let { messageData, currentConversation } = this.props;
    if (
      messageData.isFirstMessage &&
      currentConversation &&
      currentConversation.conversationType === "CHAT_PROMPT"
    )
      return "System";

    if (
      senderStaff &&
      senderStaff.aliasName &&
      senderStaff.aliasName !== "null"
    ) {
      if (senderStaff.lastName && senderStaff.lastName !== "null") {
        return (
          senderStaff.aliasName +
          " " +
          senderStaff.lastName.charAt(0).toUpperCase()
        );
      } else {
        return senderStaff.aliasName;
      }
    }
    /*if(senderStaff && senderStaff.fullName)
            return senderStaff.fullName;*/
    let senderName = "";
    if (senderStaff && senderStaff.firstName) {
      senderName += senderStaff.firstName;
      if (
        senderStaff.lastName &&
        senderStaff.lastName.length > 0 &&
        senderStaff.lastName !== "null"
      )
        senderName += " " + senderStaff.lastName.charAt(0).toUpperCase();
      return senderName;
    } else {
      //let messageData 	= this.props.messageData;
      return "Agent";
    }
  }

  getActionToAgetName() {
    let actionToStaff = this.props.actionToStaff;
    if (
      actionToStaff &&
      actionToStaff.aliasName &&
      actionToStaff.aliasName !== "null"
    ) {
      if (actionToStaff.lastName && actionToStaff.lastName !== "null") {
        return (
          actionToStaff.aliasName +
          " " +
          actionToStaff.lastName.charAt(0).toUpperCase()
        );
      } else {
        return actionToStaff.aliasName;
      }
    }

    let staffName = "";
    if (actionToStaff && actionToStaff.firstName) {
      staffName += actionToStaff.firstName;
      if (
        actionToStaff.lastName &&
        actionToStaff.lastName.length > 0 &&
        actionToStaff.lastName !== "null"
      )
        staffName += " " + actionToStaff.lastName.charAt(0).toUpperCase();
      return staffName;
    } else {
      let messageData = this.props.messageData;
      return messageData.actionTo;
    }
  }
  getAgentIcon() {
    let senderStaff = this.props.senderStaff;
    let agentName = this.getSenderAgetName();
    if (
      (senderStaff &&
        senderStaff.staffType == "OVERFLOW" &&
        !isOverflowConnection()) ||
      agentName == "System"
    ) {
      return <figure></figure>;
    } else {
      return (
        <figure>
          {senderStaff && senderStaff.photoUrl ? (
            <img
              className="user-img"
              onError={(e) => (e.target.style.display = "none")}
              src={senderStaff.photoUrl}
              alt={agentName}
              data-tip={agentName || ""}
              data-class="tooltip info"
            />
          ) : (
            ""
          )}
        </figure>
      );
    }
  }

  getSenderIcon() {
    if (this.props.isTyping && this.isChatDisconnectedOrClosedInOverflow())
      return <span />;
    let messageData = this.props.messageData;
    if (messageData.messageFrom == "AGENT") return this.getAgentIcon();
    else if (messageData.messageFrom == "VISITOR") return this.getVisitorIcon();
    else if (messageData.messageFrom == "BOT") return this.getBotIcon();
  }
  getBotIcon() {
    return <figure></figure>;
  }

  getVisitorIcon() {
    let currentContact = this.props.contact ? this.props.contact : {};
    let visitorName = this.getVisitorName();
    if (currentContact && !!currentContact.profileImage) {
      return (
        <figure>
          <img
            className="user-img"
            src={currentContact.profileImage}
            ref={(img) => (this.img = img)}
            onError={() =>
              (this.img.src =
                "https://app.chatsupport.co/images/profile_Icon.png")
            }
            data-tip={visitorName}
            data-class="tooltip info"
          />
        </figure>
      );
    } else {
      return this.getVisitorIconByMode(currentContact);
    }
  }

  getVisitorIconByMode(contact) {
    let typingStatus = this.props.isTyping;
    if (getIsAWRecentComponent()) {
      return (
        <figure
          style={{
            ...getRespectiveStyleforAWChat(),
          }}
        >
          <AWChatMessageBoxIcon isTyping={typingStatus} />
        </figure>
      );
    } else {
      return <figure style={{ backgroundColor: contact.avatarColor }}></figure>;
    }
  }

  getSurveyFormInfo(message) {
    let surveyFormInfo = "";
    try {
      let surveyForm = JSON.parse(message);
      if (surveyForm) {
        if (surveyForm.Email)
          surveyFormInfo = surveyFormInfo + "email to " + surveyForm.Email;
        else surveyFormInfo = surveyFormInfo + "name";

        if (surveyForm.Phone)
          surveyFormInfo =
            surveyFormInfo + "& phone number to " + surveyForm.Phone;
      }
    } catch (e) {}
    return surveyFormInfo;
  }

  getDisplayTime(timeStamp) {
    return getFormattedDate(timeStamp, "hh:mm A");
  }
  getDisplayTimeWithDate(timestamp) {
    let date = new Date(timestamp);
    return formatDateWithYearAndSeconds(date);
  }

  getClassNameForAttachmentType(type) {
    if (IMAGE_TYPES.includes(type)) return "video-file-icon";
    else if (AUDIO_TYPES.includes(type)) return "music-file-icon";
    else if (VIDEO_TYPES.includes(type)) return "video-file-icon";
    else if (SPREAD_SHEET_TYPES.includes(type)) return "xls-file-icon";
    else if (DOCUMENT_TYPES.includes(type)) return "docx-file-icon";
    else if (COMPRESSED_TYPES.includes(type)) return "zip-file-icon";
    else if (type === "pdf") return "pdf-file-icon";
    else return "other-file-icon";
  }
  getFileTypeContentByType(type) {
    if (IMAGE_TYPES.includes(type)) return "Image";
    else if (AUDIO_TYPES.includes(type)) return "Music";
    else if (VIDEO_TYPES.includes(type)) return "Video";
    else if (SPREAD_SHEET_TYPES.includes(type)) return "Excel Spreadsheet";
    else if (DOCUMENT_TYPES.includes(type)) return "Word Document";
    else if (COMPRESSED_TYPES.includes(type)) return "Zip";
    else if (type === "pdf") return "Pdf Document";
    else return "Other";
  }
  attachmentHandleClick(e){
    store.dispatch(blockUnloadEvent());
  }
  getMessageContentByType() {
    let messageData = this.props.messageData;
    let strippedMessage = stripHTML(messageData.message);
    let renderHtml = "";
    let isNotes = messageData.type == MESSAGE_TYPE.notes;
    let message =
      messageData.type === MESSAGE_TYPE.announcement
        ? getValidMessage(messageData.message)
        : messageData.message;
    messageData.message = message;
    if (this.props.isTyping == true) {
      return "";
    }
    if (
      HTML_SUPPORTED_CAMPAIGN.includes(messageData.type) &&
      (isHTMLTemplateEnabled(message) || isHTMLContent(message))
    )
      return <iframe srcDoc={message} />;
    if (DISPLAY_TEXT_TYPE_MESSAGES.includes(messageData.type)) {
      if (
        isValidURL(strippedMessage) == true &&
        this.props.urlPreviews.previewsMap.hasOwnProperty(strippedMessage) &&
        Object.keys(this.props.urlPreviews.previewsMap[strippedMessage])
          .length > 0 &&
        messageData.type == "CHAT"
      )
        return (
          <UrlPreviewNew
            ref={(el) => (this.child = el)}
            messageId={messageData.key}
            scrollMessages={this.props.scrollMessages}
            urlPreviewInfo={this.props.urlPreviews.previewsMap[strippedMessage]}
            isFromChatHistory={this.props.isFromChatHistory}
          />
        );
      else {
        if (
          this.props.currentConversation &&
          this.props.currentConversation.conversationType ===
            "EMAIL_CONVERSATION" &&
          messageData &&
          messageData.message &&
          messageData.messageFrom != "AGENT"
        ) {
          renderHtml = renderHTML(messageData.message);
        } else {
          messageData.message = isNotes
            ? this.decodeMentionedAgents(messageData.message)
            : this.formatMessage(messageData.message, messageData.type);
          renderHtml = renderHTML(this.getSanatizedMessage(messageData));
        }

        return isNotes ? (
          renderHtml
        ) : (
          <p className="fs-exclude"> {renderHtml} </p>
        );
      }
    } else if (messageData.type == MESSAGE_TYPE.attachment) {
      let fileName = this.getFileName(messageData.message);
      let fileLocation = this.getFileLocation(messageData.message);
      let fileSize = this.getFileSize(messageData.message);
      let displayFileSize = this.getDisplayFileSize(fileSize);
      let downloadUrl = this.getFileDownloadUrl(messageData.message);
      let ary = fileName.split(".");
      let attachmentType = ary[ary.length - 1].toLowerCase();
      let attachmentLink = fileLocation;
      if (downloadUrl) attachmentLink = downloadUrl;
      return (
          <div>
              <section
                  className={this.getClassNameForAttachmentType(attachmentType)}
              >
                  <strong>{fileName}</strong>
                  <p>{this.getFileTypeContentByType(attachmentType)}</p>
                  <span>{displayFileSize}</span>
              </section>
              <div>
                  <a
                      href={attachmentLink}
                      download={fileName}
                      onClick={this.attachmentHandleClick}
                  >
                      Download
                  </a>
                  {/* <a href="javascriptvoid:(0)">Share with</a> */}
              </div>
          </div>
      );
    } else if (messageData.type == MESSAGE_TYPE.notes) {
      strippedMessage = this.decodeMentionedAgents(strippedMessage);
      return <p>{renderHTML(strippedMessage)}</p>;
    } else if (messageData.type == MESSAGE_TYPE.video_chat_link) {
      return (
        <div
          style={{
            minHeight: "80px",
            padding: "20px 20px 0px",
            cursor: "pointer",
          }}
          onClick={this.loadUrlInNewTab}
          data-type="video_chat_link"
        >
          <section className="video-share-link">
            <strong>
              Video link{" "}
              <code
                className="copy-link"
                data-clipboard-text={
                  getConnectUrl() + "/" + messageData.conversationId
                }
              >
                <LinkIcon
                  data-delay-hide={0}
                  data-tip="copy"
                  data-class="tooltip info"
                  id={"copy_button" + messageData.key}
                  data-button_type="copy_button"
                />
              </code>
            </strong>

            <p>
              A screen share would not need access to your camera and would only
              display what is on your device’s display.
            </p>
          </section>
          <div className="dn">
            <a href="javascriptvoid:(0)">
              {/* getConnectUrl() + "/"+ messageData.conversationId + "?s&a" */}
              {"Ongoing - 00:05"}
            </a>
          </div>
        </div>
      );
    } else if (messageData.type == MESSAGE_TYPE.screen_share_link) {
      return (
        <div
          style={{
            minHeight: "80px",
            padding: "20px 20px 0px",
            cursor: "pointer",
          }}
          onClick={this.loadUrlInNewTab}
          data-type="screen_share_link"
        >
          <section className="screen-share-link">
            <strong>
              Share Screen{" "}
              <code
                className="copy-link"
                data-clipboard-text={
                  getConnectUrl() + "/" + messageData.conversationId + "?s&a"
                }
              >
                <LinkIcon
                  data-delay-hide={0}
                  data-tip="copy"
                  data-class="tooltip info"
                  id={"copy_button" + messageData.key}
                  data-button_type="copy_button"
                />
              </code>
            </strong>
            <p>
              A screen share would not need access to your camera and would only
              display what is on your device’s display.
            </p>
          </section>
          <div className="dn">
            <a href="javascriptvoid:(0)">
              {/* getConnectUrl() + "/"+ messageData.conversationId  */}
              {"Ongoing - 00:05"}
            </a>
          </div>
        </div>
      );
    }
  }
  formatMessage(message = {}, type = {}) {
    if (type == MESSAGE_TYPE.announcement)
      return message
        .replace("<p>+_add_content</p>", "")
        .replace("<p>+_sender_info</p>", "");
    return message;
  }
  getCssClassForDiv(messageData) {
    let strippedMessage = stripHTML(messageData.message);
    let chatReadStatus = MESSAGE_STATUS_CLASS[messageData.messageStatus] || "";
    if (
      this.props.currentConversation.conversationType == "FACEBOOK_CONVERSATION"
    ) {
      chatReadStatus = MESSAGE_STATUS_CLASS.CHAT_READ;
    }
    let conversationClass = "";
    if (
      messageData.type == MESSAGE_TYPE.attachment ||
      messageData.type == MESSAGE_TYPE.video_chat_link ||
      messageData.type == MESSAGE_TYPE.screen_share_link ||
      (isValidURL(strippedMessage) == true &&
        this.props.urlPreviews.previewsMap.hasOwnProperty(strippedMessage) &&
        Object.keys(this.props.urlPreviews.previewsMap[strippedMessage])
          .length > 0 &&
        messageData.type == "CHAT")
    )
      conversationClass = "file-chat";
    else if (messageData.type == MESSAGE_TYPE.notes)
      conversationClass = "notes-msg";

    if (messageData.messageFrom === "AGENT")
      conversationClass = conversationClass + " " + chatReadStatus;
    if (this.props.isTyping) {
      conversationClass = "typing-msg";
    }
    return conversationClass;
  }

  getSentIcon(messageData) {
    if (
      ((messageData.messageFrom === "AGENT" ||
        messageData.messageFrom === "BOT") &&
        messageData.type !== "NOTES" &&
        !this.props.isFromChatHistory &&
        !this.props.isTyping) ||
      messageData.type == "EMAIL_REPLY"
    ) {
      if (messageData.messageStatus == "CHAT_SENDING")
        return (
          <span className={"blink"}>
            <SentIcon />
          </span>
        );
      else if (
        messageData.type == "EMAIL_REPLY" ||
        messageData.type == "CAMPAIGN_EMAIL"
      )
        return <EmailIcon className="email-icon" />;
      else return <SentIcon />;
    } else return "";
  }
  loadUrlInNewTab(e) {
    let messageData = this.props.messageData;
    let copyButton = document.getElementById("copy_button" + messageData.key);
    if (
      !ReactDOM.findDOMNode(copyButton).contains(e.target) &&
      e.target.dataset.button_type != "copy_button"
    )
      if (e.currentTarget.dataset.type == "screen_share_link") {
        window.open(
          getConnectUrl() + "/" + messageData.conversationId + "?s&a"
        );
      } else if (e.currentTarget.dataset.type == "video_chat_link") {
        let messageData = this.props.messageData;
        window.open(getConnectUrl() + "/" + messageData.conversationId);
      }
  }
  isChatDisconnectedOrClosedInOverflow() {
    return (
      isOverflowConnection() &&
      (this.props.currentConversation.overflowStatus == "DISCONNECTED" ||
        this.props.currentConversation.chatStatus == "CLOSED")
    );
  }
  getTypingComponent() {
    if (this.props.isTyping && !this.isChatDisconnectedOrClosedInOverflow()) {
      return (
        <TypingStatusComponent
          getMessageContentByType={this.getMessageContentByType}
        />
      );
    } else {
      return <span />;
    }
  }
  canRenderChatBox() {
    if (this.props.isTyping && this.isChatDisconnectedOrClosedInOverflow())
      return false;
    return true;
  }
  getMessageComponent(messageData, senderName, timeStamp, messageTooltipTime) {
    if (!this.props.isTyping) {
      return (
        <MessageComponent
          getSenderIcon={this.getSenderIcon}
          getMessageContentByType={this.getMessageContentByType}
          messageData={messageData}
          isFromChatHistory={this.props.isFromChatHistory}
          senderName={senderName}
          timeStamp={timeStamp}
          messageTooltipTime={messageTooltipTime}
          isFromPeopleProfilePage={this.props.isFromPeopleProfilePage}
        />
      );
    }
  }
  render() {
    let messageData = this.props.messageData;
    let currentContact = this.props.contact ? this.props.contact : {};
    let classN =
      messageData.type.toUpperCase() === MESSAGE_TYPE.chat ||
      messageData.type.toUpperCase() == MESSAGE_TYPE.attachment
        ? CHAT_HISTORY_CLASSES[messageData.messageFrom]
        : CHAT_HISTORY_CLASSES[messageData.type];
    if (messageData.type.toUpperCase() == MESSAGE_TYPE.attachment)
      classN =
        classN + " " + CHAT_HISTORY_CLASSES[messageData.type.toUpperCase()];
    let initials = this.getInitials(
      currentContact && currentContact.firstName,
      currentContact && currentContact.lastName
    );
    initials = initials
      ? initials
      : currentContact.type
      ? currentContact.type.charAt(0)
      : "V";
    let strippedMessage = stripHTML(messageData.message);

    let visitorIcon = this.getVisitorIcon(currentContact);
    let timeStamp = messageData.createdDate
      ? this.getDisplayTime(
          new Date(
            messageData.createdDate +
              store.getState().UserReducer.timeDifference
          ),
          true
        )
      : "";
    let messageTooltipTime = this.getDisplayTimeWithDate(
      messageData.createdDate + store.getState().UserReducer.timeDifference
    );
    let chatReadStatus = MESSAGE_STATUS_CLASS[messageData.messageStatus] || "";
    let senderName = "";
    senderName =
      messageData.messageFrom === "VISITOR"
        ? this.getVisitorName()
        : this.getSenderAgetName();
    let senderStaff = this.props.senderStaff ? this.props.senderStaff : {};

    if (NON_DISPLAYABLE_TYPES.includes(messageData.type)) {
      return "";
    } else if (TAG_LINE_TYPES.includes(messageData.type)) {
      let tagLineValue = this.getTagLineMessage(messageData);
      return tagLineValue && tagLineValue.length > 0 ? (
        <div
          id={this.props.messageData.key}
          className={
            FEEDBACK_MESSAGE_TYPES.includes(messageData.type)
              ? "feedback-tag"
              : "conversation-msg tag-line-msg fs-exclude"
          }
        >
          {tagLineValue}
        </div>
      ) : (
        ""
      );
    } else
      return (
        <div
          style={{ display: this.canRenderChatBox() ? "block" : "none" }}
          id={this.props.messageData.key}
          className={
            "conversation-msg " +
            this.getCssClassForDiv(messageData) +
            " fs-exclude"
          }
        >
          {this.getSenderIcon()}
          {this.getTypingComponent()}
          {this.getMessageComponent(
            messageData,
            senderName,
            timeStamp,
            messageTooltipTime
          )}
          {this.getSentIcon(messageData)}
        </div>
      );
  }
}
export default ChatMessageBox;
