import { config } from 'src/config'
import {
  ISignUpDTO,
  ISignUpResponse,
} from 'src/features/authorization/api/types'
import { SESSION_STEP_3_ROUTE } from 'src/pages/SessionStep3/data'
import { appClient } from 'src/utils/api'

export const postAccount = (values: ISignUpDTO) =>
  appClient.post<ISignUpResponse>('/v1/account', values)

export const postSessionStep3RedirectUrl = `${config.chatsupportAppUrl}${SESSION_STEP_3_ROUTE}`
export const postSessionStep3 = (code: string) =>
  appClient.post(
    '/user/authcode',
    {
      code,
      redirectUri: postSessionStep3RedirectUrl,
    },
    {
      withCredentials: true,
    }
  )
