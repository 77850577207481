import * as R from 'ramda'
import styled from 'styled-components'
import is, { isNot } from 'typescript-styled-is'
import {
  ILayoutProps,
  ILeftProps,
  IRightProps,
} from 'src/features/ui/components/Layout/types'
import { mq } from 'src/features/ui/styles/mq'

export const Layout = styled.div<ILayoutProps>`
  background: ${R.path(['theme', 'color', 'white'])};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;

  ${mq.sm} {
    ${isNot('shouldHideGradient')`
      background: linear-gradient(
        90deg,
        ${R.path(['theme', 'color', 'white'])} 50%,
        ${R.path(['theme', 'color', 'athensGray'])} 50%
      );
    `}
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  max-width: 170rem;
  margin: 0 auto;

  ${mq.sm} {
    flex-direction: row;
    justify-content: unset;
    align-items: unset;
  }
`

export const Left = styled.article<ILeftProps>`
  background-color: ${R.path(['theme', 'color', 'white'])};
  padding: 3.2rem 1.6rem;
  flex: 8;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;

  ${is('shouldCenterContent')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: unset;
  `}

  ${mq.md} {
    padding: 8rem 13.6rem;
  }
`

export const Right = styled.aside<IRightProps>`
  background-color: ${R.path(['theme', 'color', 'athensGray'])};
  display: none;
  position: relative;
  padding: 3.2rem 3rem;
  width: 100%;
  height: 12.5rem;
  box-sizing: border-box;

  ${is('shouldCenterContent')`
    justify-content: center;
    align-items: center;
  `}

  ${is('shouldCenterOnlyMobileContent')`
    justify-content: center;
    align-items: center;
  `}

  ${is('shouldShowOnMobile')`
    display: flex;
  `}

  ${mq.sm} {
    height: unset;
    width: unset;
    display: flex;
    padding: 3.2rem 4.35rem;
    flex: 6;
    min-height: 100%;

    ${is('shouldCenterOnlyMobileContent')`
      justify-content: unset;
      align-items: unset;
    `}
  }

  ${mq.md} {
    max-height: unset;
    width: unset;
    display: flex;
    padding: 3.2rem 8.7rem;
  }
`
