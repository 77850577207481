import React, { FC } from 'react'

export const Chevron: FC<any> = ({ fill, ...props }) => (
  <svg width="8" height="13" viewBox="0 0 8 13" fill="none" {...props}>
    <path
      d="M1.5 11.5L6.5 6.5L1.5 1.5"
      stroke={fill || '#8F99AD'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
