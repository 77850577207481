import React, { Component } from "react";
import UserStatus from "../components/chat/UserStatus.jsx";

class UserStatusContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <UserStatus
        currentStaff={this.props.currentStaff}
        isLoadedAsAdmin={this.props.isLoadedAsAdmin}
        handleStatusSwitch={this.props.handleStatusSwitch}
        projectId={this.props.projectId}
        isFullScreenMode={this.props.isFullScreenMode}
      />
    );
  }
}

export default UserStatusContainer;
