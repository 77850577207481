/**
 * Created by arun on 20/02/2020.
 */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TeamListItemComponent from "./TeamListItemComponent.jsx";
import * as StaffContactHook from "./Hook/StaffContactHook";

import * as RolesUtility from "../../commons/RolesUtility";
import { useRef } from "react";
import { TeamMemberListLoader } from "../../settings/commons/loaders.js";

const TeamListComponent = (props) => {
  const listContainerRef = useRef(null);
  const [top, setTop] = useState(false);
  const [bottom, setBottom] = useState(false);
  let staffReducer = props.staffReducer;
  let userReducer = props.userReducer;
  let projectkey =
    !!props.directoryProps && !!props.directoryProps.params.projectkey
      ? props.directoryProps.params.projectkey
      : "";

  const handleScroll = (e) => {
    //to check where the scroll bar is.
    let containerDomNode = listContainerRef.current;
    let atBottom = bottom;
    let atTop = top;
    if (containerDomNode) {
      atBottom =
        containerDomNode.clientHeight ==
        containerDomNode.scrollHeight - Math.floor(containerDomNode.scrollTop)
          ? true
          : false;
      atTop =
        containerDomNode.scrollTop == 0 ||
        containerDomNode.clientHeight == containerDomNode.scrollHeight
          ? true
          : false;
    }
    setTop(atTop);
    setBottom(atBottom);
  };

  const getStaffListItemElemnts = () => {
    let torender = [];
    let activeStaffId = props.directoryEventHandler.getActiveStaffId();
    activeStaffId =
      !!activeStaffId &&
      !StaffContactHook.isStaffActive(activeStaffId, staffReducer.accountStaffs)
        ? ""
        : activeStaffId;

    let accountStaffsMap = {};

    if (
      !!staffReducer &&
      staffReducer.hasOwnProperty("accountStaffs") &&
      staffReducer.accountStaffs &&
      userReducer.isInternalUser != true
    )
      accountStaffsMap = staffReducer.accountStaffs;

    if (
      userReducer.isInternalUser == true &&
      staffReducer.hasOwnProperty("dashboardAgents") &&
      staffReducer.dashboardAgents.hasOwnProperty("LS-" + projectkey)
    )
      accountStaffsMap = staffReducer.dashboardAgents["LS-" + projectkey];

    Object.keys(accountStaffsMap).map((key, i) => {
      let staff = staffReducer.accountStaffs[key];
      if (staff.status === "ACTIVE") {
        if (
          activeStaffId == "" &&
          !props.directoryProps.location.pathname.includes("myteam/invite")
        ) {
          //consider the staff key from url if it has value (low priority)
          if (
            location.href.split("/profile/").length > 1 &&
            location.href.split("/profile/")[1].split("/").length > 0
          ) {
            props.directoryEventHandler.setActiveStaff(
              location.href.split("/profile/")[1].split("/")[0]
            );
          } else {
            props.directoryEventHandler.setActiveStaff(key);
          }
        } else {
          let urlStaffid = props.directoryProps.params.hasOwnProperty("staffid")
            ? props.directoryProps.params.staffid
            : "";
          if (
            urlStaffid != activeStaffId &&
            !!activeStaffId &&
            !props.directoryProps.location.pathname.includes("myteam/invite")
          ) {
            props.directoryEventHandler.setActiveStaff(activeStaffId);
          }

          torender.push(
            <TeamListItemComponent
              key={staff.key}
              staffItem={staff}
              directoryProps={props.directoryProps}
              directoryEventHandler={props.directoryEventHandler}
            />
          );
        }
      }
    });

    return torender;
  };

  const getClassName = () => {
    let className = "";
    if (top) className += "scroll scroll-top";
    else if (bottom) className += "scroll scroll-bottom";
    else className += "scroll ";
    return className;
  };
  if (getStaffListItemElemnts().length > 0)
    return ( 
      <section class="cs-navigation">
        <h2>Team Members</h2>
        <ul class="tab-nav">
          <li class="active">
            <a href="javascript:void(0)">My Team</a>
          </li>
        </ul>
        <ul
          className={"cs-navigation-list cs-my-team-list " + getClassName()}
          ref={listContainerRef}
          onScroll={handleScroll}
        >
          {RolesUtility.isUserHavingPermission("InvitePeople") ? (
            <li
              onClick={props.directoryEventHandler.gotoInvitePeople}
              className={
                props.directoryProps.location.pathname.includes("myteam/invite")
                  ? "active"
                  : ""
              }
              key="invite"
            >
              <figure className="invite-icon"></figure>
              <a>Invite people</a>
            </li>
          ) : (
            ""
          )}
          {getStaffListItemElemnts()}
        </ul>
      </section>
    );
  return <TeamMemberListLoader loaderCount={4}/>;
};

export default TeamListComponent;
